/* eslint-disable import/prefer-default-export */
import i18n from '@shared/i18n/vue-i18n'

/**
 * Check if a translation exists for a specific locale
 *
 * @param {string} key
 * @param {string} locale
 *
 * @returns {boolean}
 */
export const translationExists = (key, locale) => (
  // "te" method check if a translation exist in a specific locale, but do not fall back
  // if a translation exists in a "fr" it will still return false for the "fr-FR" check
  // whereas "t" method will fall back

  // If a translation does not exist, the key will be return by "t"
  i18n.global.t(key, {}, { locale }) !== key
)
