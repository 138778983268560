<template>
  <form
    novalidate
    @submit.prevent="onSubmit"
  >
    <form-global-errors />

    <form-label
      :label="t('ttmt.payments.checkout.tip_amount')"
      class="mb-3 block text-lg"
    />

    <div :class="`grid grid-cols-${amountChoices.length} gap-4`">
      <app-button
        v-for="(choice, i) in amountChoices"
        :key="i"
        :emphasis="isAmountDisplayedAsSelected(choice) ? 'high' : 'medium'"
        :color="isAmountDisplayedAsSelected(choice) ? 'theme' : 'gray'"
        :label="formatAmount(choice)"
        font-size="sm"
        rounded="rounded-2lg"
        border-width="1"
        :gradient="false"
        shadow=""
        padding="py-4 px-5"
        @click="handleSelectAmount(choice)"
      />
    </div>

    <form-group
      class="mt-5 mx-auto form-group-center max-w-2xs"
      :placeholder="formatAmount(amountChoices[0])"
      name="custom_amount"
      type="number"
      :submittable="false"
      hide-errors
      :form-control-props="{
        min: 0,
        inputmode: 'decimal'
      }"
    />

    <template v-if="mode === 'extranet'">
      <form-group
        class="mx-auto max-w-2xs"
        name="payable_type"
        type="select"
        :initial-value="null"
        :label="t('ttmt.form.labels.payment.payable_type')"
        :form-control-props="{
          clearable: true,
          options: payableOptions,
        }"
      />

      <form-group
        v-if="form.values.payable_type"
        name="payable_id"
        rules="required"
        class="mx-auto max-w-2xs"
        type="select"
        :form-control-props="{
          options: handleReservationsSearch,
        }"
        :label="false"
      >
        <template #control-vue-multiselect-option="option">
          <div>
            <strong>
              {{ option.label }}
            </strong>
            <br>
            <em class="text-sm">
              {{ option.subLabel }}
            </em>
          </div>
        </template>
      </form-group>
    </template>

    <form-group
      name="payee_id"
      :initial-value="payeeResource.id"
      type="hidden"
    />

    <form-group
      name="items.0.name"
      :initial-value="t('ttmt.common.tip')"
      type="hidden"
    />

    <form-group
      name="items.0.quantity"
      :initial-value="1"
      type="hidden"
    />

    <form-group
      name="items.0.currency"
      :initial-value="currency"
      type="hidden"
    />

    <form-group
      name="items.0.amount"
      type="hidden"
    />

    <app-button
      feature="confirm"
      width="1/2"
      type="submit"
      :loading="submitting"
      :disabled="!anAmountIsDefined || submitting || invalid"
    />
  </form>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import {
  isNil,
  uniqBy,
  compact,
} from 'lodash'

import {
  fetchReservations as fetchApiReservations,
} from '@shared/http/api'
import useForm from '@shared/hooks/form/form'
import { displayName } from '@shared/helpers/user'
import useDatetime from '@shared/hooks/datetime'
import useLocale from '@shared/hooks/locale'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormLabel from '@shared/components/form/FormLabel.vue'

const props = defineProps({
  // JSON API resource
  payeeResource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
  // Mode used to display form
  mode: {
    type: String,
    required: true,
    validator(value) {
      // The value must match one of these strings
      return [
        'app',
        'extranet',
      ].includes(value)
    },
  },
})

const emits = defineEmits([
  'submitted',
])

const store = useStore()
const { t, locale } = useI18n()
const {
  handleSubmit,
  invalid,
  form,
} = useForm(props, { emits })
const {
  formatDateShort,
  formatTimeSimple,
} = useDatetime()
const { getAttributeTranslation } = useLocale()
const { payableOptions } = useSelectOptions()

const amountSelected = ref(null)
const amountChoices = [2, 5, 10]

function onSubmit() {
  form.setFieldValue('items.0.amount', amountToPay.value)
  handleSubmit()
}

const anAmountIsDefined = computed(() => (
  !isNil(amountToPay.value) && amountToPay.value > 0
))

// User's currency or default one
const currency = computed(() => (
  store.state.auth.user.relationships?.currency?.attributes?.code
    || import.meta.env.VITE_DEFAULT_CURRENCY
))

function formatAmount(amount) {
  return new Intl.NumberFormat(
    locale,
    {
      style: 'currency',
      currency: currency.value,
      minimumFractionDigits: 0,
    },
  )
    .format(amount)
}

const amountToPay = computed(() => (
  customAmount.value ?? amountSelected.value
))

// ---------- SELECT AMOUNT ----------

function handleSelectAmount(amount) {
  // cancel custom amount
  form.setFieldValue('custom_amount', null)

  amountSelected.value = amount
}

// display an amount as selected if it has been selected and there's no custom amount
function isAmountDisplayedAsSelected(amount) {
  return isNil(customAmount.value) && amountSelected.value === amount
}

// ---------- CUSTOM AMOUNT ----------

const customAmount = computed(() => (
  form.values.custom_amount
))

// ---------- RESERVATIONS ----------

// Return reservations options used in select control,
// based on reservations resources
function formatReservationsOptions(reservationsResources) {
  return reservationsResources.map((reservationResource) => {
    const subLabel = `
      ${getAttributeTranslation(reservationResource.relationships.point_of_interest?.attributes?.title)}
      -
      ${formatDateShort(reservationResource.attributes.reservation_date)}
      ${formatTimeSimple(reservationResource.attributes.reservation_time)}
    `

    return {
      label: displayName(reservationResource.relationships.traveller),
      subLabel,
      value: reservationResource.id,
    }
  })
}

function handleReservationsSearch() {
  return new Promise((resolve) => {
    let options = []

    const params = {
      'mode': 'manager',
      'butlerId': props.payeeResource?.id,
    }

    fetchApiReservations(params)
      .then((response) => {
        const apiOptions = formatReservationsOptions(response.data.data)
        options = compact(uniqBy(options.concat(apiOptions), 'value'))
      })
      .finally(() => {
        resolve(options)
      })
  })
}
</script>
