<template>
  <div class="flex space-x-4">
    <template
      v-if="resource.attributes.state === 'pending'"
    >
      <app-button
        emphasis="low"
        feature="confirm"
        :icon-props="{ size: bigIcons ? 'xl' : null }"
        :label="t('ttmt.common.actions.approve')"
        :hide-icon="false"
        :hide-text="true"
        @click="handleModalAction('approve')"
      />

      <app-button
        emphasis="low"
        feature="close"
        :icon-props="{ size: bigIcons ? 'text-3xl' : null }"
        :label="t('ttmt.common.actions.decline')"
        :hide-icon="false"
        :hide-text="true"
        @click="handleModalAction('decline')"
      />
    </template>

    <app-button
      v-if="resource.attributes.state !== 'archived'"
      emphasis="low"
      :icon-props="{ size: bigIcons ? 'text-3xl' : null }"
      feature="archive"
      :hide-icon="false"
      :hide-text="true"
      @click="handleModalAction('archive')"
    />

    <!-- Action modal -->
    <app-overlay
      :show="confirmModalDisplayed"
      @clicked="handleCancel"
    >
      <app-modal @closed="handleCancel">
        <app-confirm
          :confirm-color="colorButton"
          @cancelled="handleCancel"
          @confirmed="handleActionConfirm"
        >
          <p>
            {{ handleTextConfirm }}
          </p>
        </app-confirm>
      </app-modal>
    </app-overlay>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import {
  approveTravellerFolder,
  declineTravellerFolder,
  archiveTravellerFolder,
} from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Display texts
  showLabels: {
    type: Boolean,
    default: false,
  },
  // Display icons bigger
  bigIcons: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'folderUpdated',
])

const { t } = useI18n()
const store = useStore()
const handleActionConfirm = ref()
const handleTextConfirm = ref()
const confirmModalDisplayed = ref(false)
const actionConfirm = ref()
const colorButton = ref()

// ---------- APPROVE ----------

function approve() {
  store.commit('spinner/ENABLE')

  approveTravellerFolder(props.resource.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('ttmt.traveller_folders.messages.approve.success'),
          type: 'success',
        },
      )
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
      store.dispatch('folders/getActiveFolder')
      emits('folderUpdated')
    })
}

// ---------- DECLINE ----------

function decline() {
  store.commit('spinner/ENABLE')

  declineTravellerFolder(props.resource.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('ttmt.traveller_folders.messages.decline.success'),
          type: 'success',
        },
      )
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
      emits('folderUpdated')
    })
}

// ---------- ARCHIVE ----------

function archive() {
  store.commit('spinner/ENABLE')

  archiveTravellerFolder(props.resource.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('ttmt.traveller_folders.messages.archive.success'),
          type: 'success',
        },
      )
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
      store.dispatch('folders/getActiveFolder')
      emits('folderUpdated')
    })
}

// Define action according which button is clicked
function handleModalAction(action) {
  colorButton.value = action === 'approve' ? 'blue' : 'red'
  handleTextConfirm.value = t(`ttmt.traveller_folders.messages.${action}.confirm`)
  actionConfirm.value = action
  handleActionConfirm.value = handleConfirm
  confirmModalDisplayed.value = true
}

function handleConfirm() {
  switch (actionConfirm.value) {
    case 'archive':
      archive()
      break
    case 'approve':
      approve()
      break
    case 'decline':
      decline()
      break
  }
  confirmModalDisplayed.value = false
}

// Cancel modal
function handleCancel() {
  confirmModalDisplayed.value = false
}
</script>
