import i18n from '@shared/i18n/vue-i18n'
import { head, compact, last } from 'lodash'
import { languagesAll } from 'countries-list'

export const translateAttributeName = (name) => {
  name = attributeNameWithoutLocale(name)
  name = attributeNameWithoutIdSuffix(name)

  // In case of nested attributes,
  // only use last part of the key for the translation
  name = attributeNameLastKey(name)

  return i18n.global.t(`validation.attributes.${name}`)
}

export const translateAttributeErrorLabel = (name) => {
  const lastKey = attributeNameLastKey(name)
  let translatedAttribute = translateAttributeName(name)

  if (keyIsALocale(lastKey)) {
    const localeTranslated = i18n.global.t(`ttmt.locales.${lastKey}`)
    translatedAttribute = `${translatedAttribute} (${localeTranslated.toLowerCase()})`
  }

  return translatedAttribute
}

// Remove locale in attributes name
// e.g.: "title.en" will become "title"
export const attributeNameWithoutLocale = (name) => {
  const locale = attributeNameLastKey(name)

  if (keyIsALocale(locale)) {
    const attrArray = name.split('.')
    attrArray.pop()
    return attrArray.join('.')
  }
  return name
}

// If attribute is for a relation, remove '_id(s)'
// from the name to only keep the relation name
// e.g.:
// - "points_of_interest_ids" will become "points_of_interest"
// - "category_id" will become "category"
export const attributeNameWithoutIdSuffix = (name) => (
  name.replace(/_(ids?)$/, '')
)

// e.g.:
// - "user.address.city", will return "city"
// - "title.en", will return "en"
export const attributeNameLastKey = (name) => (
  last(name.split('.'))
)

export const keyIsALocale = (key) => (
  Object.keys(languagesAll).includes(key)
)

/**
 * Check if an attribute's translation is processable
 *
 * @param {object | null} attribute
 * @returns {boolean}
 */
export const attributeProcessable = (attribute) => (
  attribute !== null && typeof attribute === 'object'
)

/**
 * Check if attribute has any translation
 *
 * @param {object | null} attribute
 * @returns {boolean}
 */
export const attributeHasAnyTranslation = (attribute) => (
  attributeProcessable(attribute) && Object.keys(attribute).length > 0
)

/**
 * Fetch translation for a specific attribute
 *
 * @param {object} attribute
 * @param {string | null} locale
 * @returns {string | null}
 */
export const getAttributeTranslation = (attribute, locale = null) => {
  if (!attributeHasAnyTranslation(attribute)) {
    return null
  }
  return head(compact([
    attribute[locale ?? i18n.global.locale.value], // First, search for provided locale or current user's locale
    attribute[i18n.global.locale.value?.substring(0, 2)], // Then, try with the short locale,
    attribute[i18n.global.fallbackLocale.value], // If no translation found, search for fallback
    attribute[Object.keys(attribute)[0]], // Next, search for the first available translation
  ]))
}
