<template>
  <app-overlay
    :show="show"
    @clicked="handleClose"
  >
    <app-modal
      padding="pt-4 px-4"
      @closed="handleClose"
    >
      <app-qr-code
        route-name="show-users"
        :route-params="{ id: store.state.auth.user.id }"
        :size="250"
      />

      <p class="font-bold">
        {{ t('ttmt.users.profile.qr_code') }}
      </p>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppQrCode from '@shared/components/ui/AppQrCode.vue'

defineProps({
  // Show modal
  show: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'closed',
])

const { t } = useI18n()
const store = useStore()

function handleClose() {
  emits('closed')
}
</script>
