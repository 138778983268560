import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

export default function useSelectOptions() {
  const { t } = useI18n()

  // ---------- COMMON ----------

  /**
   * Report reason type options
   *
   * @type {[{label: *, value: string}]}
   */
  const reasonTypeOptions = [
    { value: 'incorrect_data', label: t('enums.App\\Enums\\Report\\ReasonTypeEnum.incorrect_data') },
    { value: 'shocking', label: t('enums.App\\Enums\\Report\\ReasonTypeEnum.shocking') },
    { value: 'illegal', label: t('enums.App\\Enums\\Report\\ReasonTypeEnum.illegal') },
    { value: 'spam', label: t('enums.App\\Enums\\Report\\ReasonTypeEnum.spam') },
    { value: 'other', label: t('enums.App\\Enums\\Report\\ReasonTypeEnum.other') },
  ]

  /**
   * Geoloc settings measure unit options
   *
   * @type {[{label: string, value: string}]}
   */
  const measureUnitOptions = [
    { value: 'metric', label: t('ttmt.common.units.kilometer') },
    { value: 'imperial', label: t('ttmt.common.units.mile') },
  ]

  /**
   * Reservation service options
   *
   * @type {[{label: string, value: string|null}]}
   */
  const serviceOptions = [
    { value: null, label: capitalize(t('ttmt.common.none')) },
    { value: 'breakfast', label: t('enums.App\\Enums\\ReservationServiceEnum.breakfast') },
    { value: 'lunch', label: t('enums.App\\Enums\\ReservationServiceEnum.lunch') },
    { value: 'dinner', label: t('enums.App\\Enums\\ReservationServiceEnum.dinner') },
  ]

  /**
   * Point of interest certified options
   *
   * @type {[{label: string, value: string}]}
   */
  const certifiedOptions = [
    { value: 'yes', label: capitalize(t('ttmt.common.yes')) },
    { value: 'no', label: capitalize(t('ttmt.common.no')) },
    { value: 'all', label: capitalize(t('ttmt.common.all')) },
  ]

  /**
   * Point of interest creator ID options
   *
   * @type {[{label: string, value: string}]}
   */
  const creatorIdOptions = [
    { value: 'yes', label: capitalize(t('ttmt.common.yes')) },
    { value: 'no', label: capitalize(t('ttmt.common.no')) },
  ]

  /**
   * Privacy settings options
   *
   * @type {[{label: string, value: string}]}
   */
  const privacySettingOptions = [
    { value: 'everyone', label: t('enums.App\\Enums\\PrivacySettingsEnum.everyone') },
    { value: 'contacts', label: t('enums.App\\Enums\\PrivacySettingsEnum.contacts') },
    { value: 'nobody', label: t('enums.App\\Enums\\PrivacySettingsEnum.nobody') },
  ]

  /**
   * Tip payable options
   *
   * @type {[{label: string, value: string}]}
   */
  const payableOptions = [
    { value: 'App\\Models\\Reservation', label: t('ttmt.models.reservation') },
  ]

  /**
   * Audio player speed options
   *
   * @type {[{label: string, value: number}]}
   */
  const speedOptions = [
    { value: 0.75, label: t('ttmt.common.slow') },
    { value: 1, label: t('ttmt.common.normal') },
    { value: 1.25, label: t('ttmt.common.fast') },
  ]

  // ---------- STATES ----------

  /**
   * Point of interest state options
   *
   * @type {[{label: string, value: string}]}
   */
  const poiStateOptions = [
    { value: 'draft', label: t('ttmt.points_of_interest.states.draft') },
    { value: 'pending', label: t('ttmt.points_of_interest.states.pending') },
    { value: 'published', label: t('ttmt.points_of_interest.states.published') },
    { value: 'all', label: capitalize(t('ttmt.common.all')) },
  ]

  /**
   * Support request state options
   *
   * @type {[{label: string, value: string}]}
   */
  const supportRequestStateOptions = [
    { value: 'pending-support', label: t('ttmt.support_requests.states.pending-support') },
    { value: 'pending-user', label: t('ttmt.support_requests.states.pending-user') },
    { value: 'canceled', label: t('ttmt.support_requests.states.canceled') },
    { value: 'archived', label: t('ttmt.support_requests.states.archived') },
    { value: 'resolved', label: t('ttmt.support_requests.states.resolved') },
    { value: 'all', label: capitalize(t('ttmt.common.all')) },
  ]

  /**
   * Excursion kind options
   *
   * @type {[{label: string, value: string}]}
   */
  const excursionKindOptions = [
    { value: 'customized', label: t('enums.App\\Enums\\Excursion\\KindEnum.customized') },
    { value: 'root', label: t('enums.App\\Enums\\Excursion\\KindEnum.root') },
  ]

  /**
   * Excursion kind options
   *
   * @type {[{label: string, value: string}]}
   */
  const excursionKindFilterOptions = [
    { value: 'customized', label: t('enums.App\\Enums\\Excursion\\KindEnum.customized') },
    { value: 'root', label: t('enums.App\\Enums\\Excursion\\KindEnum.root') },
    { value: 'ugozer_pro', label: t('enums.App\\Enums\\Excursion\\KindEnum.ugozer_pro') },
  ]

  /**
   * Claim authorized countries
   *
   * @type {[{label: string, value: string}]}
   */
  const claimCountriesOptions = [
    { value: 'FR', label: t('enums.App\\Enums\\Claim\\ClaimCountriesEnum.FR') },
    { value: 'DE', label: t('enums.App\\Enums\\Claim\\ClaimCountriesEnum.DE') },
    { value: 'ES', label: t('enums.App\\Enums\\Claim\\ClaimCountriesEnum.ES') },
    { value: 'IT', label: t('enums.App\\Enums\\Claim\\ClaimCountriesEnum.IT') },
    { value: 'TH', label: t('enums.App\\Enums\\Claim\\ClaimCountriesEnum.TH') },
  ]

  /**
   * Claim authorized countries
   *
   * @type {[{label: string, value: string}]}
   */
  const claimCompanyTypesOptions = [
    { value: 'individual', label: t('enums.App\\Enums\\Claim\\ClaimCompanyTypeEnum.individual') },
    { value: 'company', label: t('enums.App\\Enums\\Claim\\ClaimCompanyTypeEnum.company') },
    { value: 'association', label: t('enums.App\\Enums\\Claim\\ClaimCompanyTypeEnum.association') },
  ]

  /**
   * Tags filter mode
   *
   * @type {[{label: string, value: string}]}
   */
  const tagsModeOptions = [
    { value: 'all', label: t('ttmt.points_of_interest.search.tags_mode.all') },
    { value: 'one', label: t('ttmt.points_of_interest.search.tags_mode.one') },
  ]

  /**
   * Yes / no options
   *
   * @type {[{label: string, value: string}]}
   */
  const yesNoOptions = [
    { value: 'true', label: capitalize(t('ttmt.common.yes')) },
    { value: 'false', label: capitalize(t('ttmt.common.no')) },
  ]

  const defaultAvatars = [
    {
      value: 'male',
      label: capitalize(t('ttmt.users.profile.avatar.defaults.male')),
      url: '/images/shared/placeholders/avatars/male.jpg',
    },
    {
      value: 'female',
      label: capitalize(t('ttmt.users.profile.avatar.defaults.female')),
      url: '/images/shared/placeholders/avatars/female.jpg',
    },
  ]

  return {
    // Common
    reasonTypeOptions,
    measureUnitOptions,
    serviceOptions,
    certifiedOptions,
    creatorIdOptions,
    privacySettingOptions,
    payableOptions,
    speedOptions,
    tagsModeOptions,
    yesNoOptions,

    // Excursion
    excursionKindOptions,
    excursionKindFilterOptions,

    // States
    poiStateOptions,
    supportRequestStateOptions,

    // Claim
    claimCountriesOptions,
    claimCompanyTypesOptions,

    // User
    defaultAvatars,
  }
}
