<template>
  <button
    :disabled="isDisabled"
    :type="type"
    :class="`${classes} justify-center items-center duration-300`"
    :title="labelToUse"
  >
    <app-spinner v-if="loading" />

    <app-icon
      v-else-if="iconPropsToUse?.name && !hideIcon"
      v-bind="iconPropsToUse"
    />

    <span
      v-if="labelToUse && !hideText"
      :class="`${iconPresent ? 'mx-1' : ''}`"
    >
      {{ labelToUse }}
    </span>
  </button>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useOnline from '@shared/hooks/online'
import useLocale from '@shared/hooks/locale'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Determine color and icon applied
  // to the button (e.g.: delete, block...)
  feature: {
    type: String,
    default: null,
  },
  // Force color to use
  // (whatever the feature is)
  color: {
    type: String,
    default: null,
  },
  // Force background classes
  // (whatever the main color is)
  backgroundClasses: {
    type: String,
    default: null,
  },
  // Force font color classes
  // (whatever the main color is)
  fontColorClasses: {
    type: String,
    default: null,
  },
  // Force whitespace classes
  whitespaceClasses: {
    type: String,
    default: 'whitespace-nowrap',
  },
  // Force display classes
  displayClasses: {
    type: String,
    default: 'inline-flex',
  },
  // Force the icon to use
  // (whatever the feature is)
  iconProps: {
    type: Object,
    default: () => ({}),
  },
  // HTML type to use
  type: {
    type: String,
    default: 'button',
  },
  // Determine width class apply
  // to the button
  width: {
    type: String,
    default: null,
  },
  // Determine height class apply
  // to the button
  height: {
    type: String,
    default: null,
  },
  // Determine border width class apply
  // to the button
  borderWidth: {
    type: String,
    default: '2',
  },
  // Determine font size class apply
  // to the button
  fontSize: {
    type: String,
    default: 'base',
  },
  // HTML disabled or not,
  // determine some classes too
  disabled: {
    type: Boolean,
    default: false,
  },
  // Replace used icon with
  // a spinner
  loading: {
    type: Boolean,
    default: false,
  },
  // Button label
  label: {
    type: String,
    default: null,
  },
  // Is the button available
  // offline
  availableOffline: {
    type: Boolean,
    default: false,
  },
  // Determine if button is text,
  // outline or contained
  emphasis: {
    type: String,
    default: 'high',
  },
  // Determine button padding
  padding: {
    type: String,
    default: 'py-2 px-5',
  },
  // Label uppercase
  uppercase: {
    type: Boolean,
    default: false,
  },
  // Label font weight
  fontWeight: {
    type: String,
    default: 'font-semibold',
  },
  // Border radius
  rounded: {
    type: String,
    default: 'rounded-full',
  },
  // shadow
  shadow: {
    type: String,
    default: 'shadow-lg shadow-theme-500/10',
  },
  // Gradient background or not
  gradient: {
    type: Boolean,
    default: true,
  },
  // Hide icon or not
  hideIcon: {
    type: Boolean,
    default: true,
  },
  // Hide text or not
  hideText: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const { translationExists } = useLocale()

const { offline } = useOnline()

// Build button's classes
const classes = computed(() => {
  const classes = []

  // Cursor
  if (isDisabled.value) {
    classes.push('cursor-not-allowed')
  }

  // Width
  if (props.width) {
    classes.push(`w-${props.width}`)
  }
  // Height
  if (props.height) {
    classes.push(`h-${props.height}`)
  }

  // Border
  if (props.emphasis === 'medium') {
    classes.push(`border-${colorToUse.value}-500`, `border-${props.borderWidth}`)
  }

  // Background
  if (props.backgroundClasses) {
    // Force classes
    classes.push(props.backgroundClasses)
  } else if (props.emphasis === 'high') {
    // High emphasis
    if (isDisabled.value || !props.gradient) {
      classes.push(`bg-${colorToUse.value}-500`)
    } else {
      classes.push('bg-gradient-to-br')
      classes.push(`from-${colorToUse.value}-500`, `to-${colorToUse.value}-700`, `hover:from-${colorToUse.value}-600`, `hover:to-${colorToUse.value}-800`)
    }
  } else if (['low', 'medium'].includes(props.emphasis)) {
    // Low & medium emphasis
    classes.push('bg-transparent')
  }

  // Font color
  if (props.fontColorClasses) {
    // Force classes
    classes.push(props.fontColorClasses)
  } else if (props.emphasis === 'high') {
    classes.push('text-white')
    // High emphasis
  } else if (['low', 'medium'].includes(props.emphasis)) {
    // Low & medium emphasis
    classes.push(`text-${colorToUse.value}-500`, `hover:text-${colorToUse.value}-600`)
  }

  // Shadow
  if (
    props.emphasis === 'high'
    && !isDisabled.value
    && props.shadow
  ) {
    classes.push(props.shadow)
  }

  // Low emphasis button don't need padding
  if (props.padding && props.emphasis !== 'low') {
    classes.push(props.padding)
  }

  // Font case
  if (props.uppercase) {
    classes.push('uppercase')
  }

  // Font weight
  if (props.fontWeight) {
    classes.push(props.fontWeight)
  }

  // Border radius
  if (props.rounded) {
    classes.push(props.rounded)
  }

  // Font size
  if (props.fontSize) {
    classes.push(`text-${props.fontSize}`)
  }

  // Whitespace
  classes.push(props.whitespaceClasses)

  // Display
  classes.push(props.displayClasses)

  return classes.join(' ')
})

const isDisabled = computed(() => (
  props.disabled || (offline.value && !props.availableOffline)
))

const labelToUse = computed(() => {
  if (props.label) {
    return props.label
  }

  if (props.feature && translationExists(`ttmt.common.actions.${props.feature}`)) {
    // check if a translation exists for this feature and use it
    return t(`ttmt.common.actions.${props.feature}`)
  }
  return null
})

// Get the button's main color
const colorToUse = computed(() => {
  if (isDisabled.value) {
    return 'gray'
  }
  if (props.color) {
    return props.color
  }
  if ([
    'archive',
    'delete',
    'block',
    'cancel',
    'close',
    'quit',
    'remove',
    'report',
  ].includes(props.feature)) {
    return 'red'
  }

  return 'theme'
})

// Build the icon component's props
const iconPropsToUse = computed(() => {
  const builtIconProps = {}

  switch (props.feature) {
    case 'add':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'plus'
      break
    case 'archive':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'folder'
      builtIconProps.iconStyle = 'fas'
      break
    case 'confirm':
      builtIconProps.library = 'fontawesome'
      builtIconProps.name = 'check'
      builtIconProps.iconStyle = 'fas'
      break
    case 'attach':
      builtIconProps.library = 'fontawesome'
      builtIconProps.name = 'paperclip'
      builtIconProps.iconStyle = 'fas'
      break
    case 'block':
      builtIconProps.library = 'fontawesome'
      builtIconProps.name = 'ban'
      builtIconProps.iconStyle = 'fas'
      break
    case 'cancel':
      builtIconProps.library = 'fontawesome'
      builtIconProps.name = 'undo'
      builtIconProps.iconStyle = 'fas'
      break
    case 'close':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'close_big'
      break
    case 'delete':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'trash_empty'
      break
    case 'edit':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'edit'
      break
    case 'list':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'list_ul'
      break
    case 'logout':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'log_out'
      break
    case 'mail':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'mail'
      break
    case 'play':
      builtIconProps.library = 'local'
      builtIconProps.name = 'play'
      break
    case 'share':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'share_outline'
      break
    case 'record':
      builtIconProps.library = 'local'
      builtIconProps.name = 'record'
      break
    case 'remove':
      builtIconProps.library = 'fontawesome'
      builtIconProps.name = 'minus'
      builtIconProps.iconStyle = 'fas'
      break
    case 'show':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'show'
      break
    case 'hide':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'hide'
      break
    case 'report':
      builtIconProps.library = 'coolicon'
      builtIconProps.name = 'flag_outline'
      break
    case 'help':
      builtIconProps.library = 'fontawesome'
      builtIconProps.name = 'life-ring'
      builtIconProps.iconStyle = 'far'
      break
    case 'pdf':
      builtIconProps.library = 'fontawesome'
      builtIconProps.name = 'file-pdf'
      builtIconProps.iconStyle = 'far'
      break
  }

  if (labelToUse.value) {
    // Use button label as icon title
    builtIconProps.title = labelToUse.value
  }

  // Use build icon props, but use the ones provided from props in priority
  return {
    ...builtIconProps,
    ...props.iconProps,
  }
})

// An icon is present if there is icon props, or if loading is enabled
const iconPresent = computed(() => (
  (
    props.loading
    || (iconPropsToUse.value?.name && !props.hideIcon)
  )
))
</script>
