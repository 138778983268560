<template>
  <div>
    <!-- Heading -->
    <div :class="`flex justify-between items-center ${marginTop}`">
      <app-sub-heading
        size="text-base"
      >
        {{ title }}
      </app-sub-heading>

      <slot name="header-right" />
    </div>

    <div
      ref="slidable"
      class="slidable snap-x flex flex-no-wrap content-center justify-start overflow-x-scroll items-center"
    >
      <!-- Slidable content -->
      <template
        v-if="list.length > 0"
      >
        <div
          v-for="(item, i) in list"
          :key="i"
          :class="`snap-start my-4 min-w-${itemMinWidth} mr-4 bg-white rounded-2lg shadow-lg shadow-theme-500/10 overflow-hidden`"
          @click="emits('itemClicked', item)"
        >
          <component
            :is="component"
            v-bind="item"
          />
        </div>

        <slot name="list-end" />
      </template>

      <!-- Empty cards while loading -->
      <template v-else-if="loading">
        <component
          :is="emptyComponent"
          v-for="(emptyCard, i) in 5"
          :key="i"
          :class="`snap-align my-4 min-w-${itemMinWidth} mr-4`"
        />
      </template>

      <!-- No records -->
      <template v-if="!loading && list.length === 0">
        <slot name="no-records">
          <p class="mb-10 text-xs">
            {{ t('ttmt.common.messages.no_records') }}
          </p>
        </slot>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AppSubHeading from '@app/components/ui/AppSubHeading.vue'
import AppVerticalCardEmpty from '@shared/components/ui/card/AppVerticalCardEmpty.vue'

defineProps({
  // List main title
  title: {
    type: String,
    required: true,
  },
  // Props for each
  // rendered components
  list: {
    type: Array,
    default: () => [],
  },
  // Vue Component that
  // will be rendered
  component: {
    type: Object,
    default: () => ({}),
  },
  // Component used when the list is loading
  emptyComponent: {
    type: Object,
    default: AppVerticalCardEmpty,
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: true,
  },
  // A slidable item min width class
  itemMinWidth: {
    type: String,
    default: '9/10',
  },
  // Margin top
  marginTop: {
    type: String,
    default: 'mt-4',
  },
})

const emits = defineEmits([
  'itemClicked',
])

const slidable = ref() // template ref

const { t } = useI18n()
</script>

<style scoped>
  .slidable::-webkit-scrollbar {
    @apply hidden;
  }
</style>
