<template>
  <div class="flex">
    <div class="w-28 mr-3">
      <Multiselect
        v-model="countryCode"
        :name="`${name}_country_code`"
        :classes="{ options: 'multiselect-options w-max', dropdown: 'multiselect-dropdown w-max' }"
        :options="countries"
        :can-clear="false"
        :searchable="true"
        autocomplete="new-country_code"
        :no-options-text="t('ttmt.form.select.no_results')"
        :no-results-text="t('ttmt.form.select.no_results')"
        @change="handleCountryCodeChange"
        @open="handleFocus(true)"
        @close="handleFocus(false)"
      >
        <template #option="{ option }">
          <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
        </template>

        <template #singlelabel="{ value: option }">
          <div class="multiselect-single-label text-sm">
            <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
          </div>
        </template>
      </Multiselect>
    </div>

    <input
      :id="name"
      ref="control"
      v-model="displayValue"
      class="form-control flex-1"
      type="tel"
      :required="required"
      :placeholder="capitalize(placeholder)"
      :name="name"
      @input="handlePhoneNumberInput"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
    >
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import 'country-flag-icons/3x2/flags.css'
import Multiselect from '@vueform/multiselect'
import { capitalize } from 'lodash'

import useFormControlTel from '@shared/hooks/form/formControlTel'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Placeholder HTML attribute
  placeholder: {
    type: String,
    default: null,
  },
  // Required HTML attribute
  required: {
    type: Boolean,
    default: false,
  },
  // Currently used as credential control
  // (useful for login or register form
  // which use email or tel control)
  credential: {
    type: Boolean,
    default: false,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const { t } = useI18n()

const control = ref(null) // template ref to input

const {
  countryCode,
  displayValue,
  countries,
  handlePhoneNumberInput,
  handleCountryCodeChange,
  handleFocus,
} = useFormControlTel(props, {
  emits,
  refs: { control },
})
</script>
