<template>
  <vue-qrcode
    :value="value"
    tag="img"
    class="mb-4 mx-auto"
  />
</template>

<script setup>
import { useRouter } from 'vue-router'
import VueQrcode from '@chenfengyuan/vue-qrcode'

const props = defineProps({
  // URL name
  routeName: {
    type: String,
    default: null,
  },
  // URL params
  routeParams: {
    type: Object,
    default: null,
  },
  // QR code size
  size: {
    type: Number,
    default: 150,
  },
})

const router = useRouter()

const domain = window.location.origin
const route = router.resolve({
  name: props.routeName,
  params: props.routeParams,
})
const value = `${domain}${route.href}`
</script>
