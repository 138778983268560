<template>
  <div class="p-3">
    <router-link
      :to="{ name: 'show-traveller-folders', params: { id: resource.id } }"
    >
      <div class="flex items-center ml-2">
        <img
          v-if="logoUrl"
          :src="logoUrl"
          class="mr-3"
        >

        <div class="w-9/12 truncate mb-2">
          <p
            v-if="poiL10nTitle"
            class="text-md font-bold truncate"
          >
            {{ poiL10nTitle }}
          </p>
        </div>
      </div>
    </router-link>

    <traveller-folder-actions
      v-if="!hideActions"
      class="flex justify-end items-center mt-1"
      :resource="resource"
      :big-icons="true"
      @folder-updated="handleFolderUpdated"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import useLocale from '@shared/hooks/locale'
import TravellerFolderActions from '@app/components/resources/traveller_folder/TravellerFolderActions.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on update handling
  updateCallback: {
    type: Function,
    default: () => ({}),
  },
  // Hide actions
  hideActions: {
    type: Boolean,
    default: false,
  },
})

// ---------- POINT OF INTEREST ----------

const { getAttributeTranslation } = useLocale()

const pointOfInterest = computed(() => (
  props.resource.relationships.point_of_interest
))

const poiL10nTitle = computed(() => (
  getAttributeTranslation(pointOfInterest.value?.attributes?.title)
))

const logoUrl = computed(() => (
  pointOfInterest.value?.relationships.logo?.attributes?.urls?.xs
))

// ---------- FOLDER ----------

function handleFolderUpdated() {
  props.updateCallback()
}
</script>
