<template>
  <div :class="`bg-white rounded-3xl ${textAlignClasses} w-11/12 w-[95vw] ${maxWidthClasses} max-h-[95vh] mx-auto overflow-hidden`">
    <div
      class="flex justify-between items-center"
      :class="padding"
    >
      <div>
        <slot
          name="title"
        />
      </div>

      <!-- Close -->
      <div>
        <!-- Use slot, or generated close button -->
        <div
          v-if="$slots.close"
          @click="handleCloseClick"
        >
          <slot
            name="close"
          />
        </div>

        <app-icon
          v-else-if="withCloseButton"
          library="coolicon"
          name="close_big"
          class="text-theme-500"
          size="text-4xl cursor-pointer"
          @click="handleCloseClick"
        />
      </div>
    </div>

    <div
      ref="contentWrapper"
      class="max-h-[85vh] overflow-auto"
    >
      <div class="px-4 pb-4 clear-both">
        <div
          v-if="loading"
          class="text-theme-500 h-full w-full flex flex-col items-center justify-center fixed top-0 left-0"
        >
          <app-spinner size="3x" />
        </div>

        <slot v-else />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'

defineProps({
  // Whether a close
  // button will be displayed
  withCloseButton: {
    type: Boolean,
    default: true,
  },
  // Overwrite text align classes
  textAlignClasses: {
    type: String,
    default: 'text-center',
  },
  // Overwrite max width classes
  maxWidthClasses: {
    type: String,
    default: 'max-w-screen-md',
  },
  // Loading or not
  loading: {
    type: Boolean,
    default: false,
  },
  // Padding
  padding: {
    type: String,
    default: 'p-4',
  },
})

const emits = defineEmits([
  'closed',
])

function handleCloseClick() {
  emits('closed')
}

const contentWrapper = ref()
</script>
