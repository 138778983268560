<template>
  <div class="flex flex-col form-control">
    <textarea
      :id="name"
      v-model="innerValue"
      :required="required"
      :placeholder="capitalize(placeholder)"
      :name="name"
      :rows="rows"
      :readonly="readonly"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
    />

    <span
      v-if="limitChars > 0"
      :class="`block text-right font-semibold text-xs mt-2 ${counterColor}`"
    >
      {{
        t('ttmt.common.characters_limit', {
          length: length || 0,
          limit: limitChars
        })
      }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

import useFormControl from '@shared/hooks/form/formControl'

const props = defineProps({
  // Value used to fill
  // inner value at mount
  initialValue: {
    type: null,
    default: undefined,
  },
  // Name HTML attribute
  name: {
    type: String,
    required: true,
  },
  // Placeholder HTML attribute
  placeholder: {
    type: String,
    default: null,
  },
  // Readonly HTML attribute
  readonly: {
    type: Boolean,
    default: false,
  },
  // Required HTML attribute
  required: {
    type: Boolean,
    default: false,
  },
  // CSS classes
  classes: {
    type: String,
    default: null,
  },
  // Textarea rows
  rows: {
    type: Number,
    default: 2,
  },
  // Characters limit
  limitChars: {
    type: Number,
    default: 0,
  },
})

const emits = defineEmits([
  'focused',
  'changed',
])

const {
  innerValue,
  length,
  handleFocus,
} = useFormControl(props, { emits })

const { t } = useI18n()

const counterColor = computed(() => (
  length.value > props.limitChars
    ? 'text-red-500'
    : 'text-gray-500'
))
</script>
