<template>
  <app-button
    v-if="resource.authorizations.share"
    feature="share"
    :emphasis="emphasis"
    :hide-text="hideText"
    :hide-icon="hideIcon"
    :icon-props="{ size: bigIcons ? 'text-3xl' : null }"
    class="my-1"
    @click="handleModalAction"
  />

  <!-- Modal -->
  <app-overlay
    :show="confirmModalDisplayed"
    @clicked="handleCancel"
  >
    <app-modal @closed="handleCancel">
      <template #title>
        <h4 class="text-2xl font-semibold">
          {{ t('ttmt.excursions.share.heading') }}
        </h4>
      </template>

      <p class="text-left mb-4">
        {{ t(
          'ttmt.excursions.share.select_which_users_alt',
          { excursion_title: l10nTitle }
        ) }}
      </p>

      <excursion-share-form
        :resource="resource"
        :additional-errors="formErrors"
        :submitting="formSubmitting"
        @submitted="handleSubmit"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import useLocale from '@shared/hooks/locale'
import { shareExcursion } from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import ExcursionShareForm from '@app/components/resources/excursion/ExcursionShareForm.vue'

const store = useStore()
const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

const confirmModalDisplayed = ref(false)
const formErrors = ref({})
const formSubmitting = ref(false)

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Determine if button is text,
  // outline or contained
  emphasis: {
    type: String,
    default: 'high',
  },
  // Hide text or not
  hideText: {
    type: Boolean,
    default: false,
  },
  // Hide icon or not
  hideIcon: {
    type: Boolean,
    default: true,
  },
  // Display icons bigger
  bigIcons: {
    type: Boolean,
    default: false,
  },
})

function handleSubmit(attributes) {
  if (!formSubmitting.value) {
    formSubmitting.value = true

    const params = {
      data: {
        type: 'excursions',
        attributes: { ...attributes },
      },
    }

    shareExcursion(props.resource.id, params)
      .then(() => {
        store.commit(
          'flashes/ADD_FLASH',
          { message: t('ttmt.excursions.share.success'), type: 'success' },
        )
        confirmModalDisplayed.value = false
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// ---------- POI ----------

const l10nTitle = computed(() => (
  getAttributeTranslation(props.resource.attributes.title)
))

// ---------- MODAL ----------

function handleModalAction() {
  confirmModalDisplayed.value = true
}

function handleCancel() {
  confirmModalDisplayed.value = false
}
</script>
