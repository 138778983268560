import { compact, head } from 'lodash'

import i18n from '@shared/i18n/vue-i18n'

/**
 * Username to display (full real name, name or public id)
 *
 * @param {object | null} userResource
 * @returns {string}
 */
export const displayName = (userResource) => {
  if (!userResource) {
    return i18n.global.t('ttmt.common.errors.deleted_user')
  }

  // Pick the first non falsy value
  return head(compact([
    fullName(userResource),
    userResource.attributes?.name,
    userResource.attributes?.email,
  ]))
}

/**
 * User's full name
 *
 * @param {object | null} userResource
 * @returns {string}
 */
export const fullName = (userResource) => (
  compact([
    userResource?.attributes?.first_name,
    userResource?.attributes?.last_name,
  ]).join(' ')
)
