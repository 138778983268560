export default {
    "ar": {
        "The {attribute} must be at least {length} characters and contain at least one number.": "يجب أن يتكون {attribute} من حرفين {length} على الأقل ويحتوي على رقم واحد على الأقل.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "يجب أن يتكون {attribute} من حرفين {length} على الأقل ويحتوي على حرف خاص واحد ورقم واحد على الأقل.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "يجب أن يتكون {attribute} من حرفين {length} على الأقل ويحتوي على حرف خاص واحد على الأقل.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "يجب أن يتكون {attribute} من حرفين {length} على الأقل ويحتوي على حرف كبير واحد ورقم واحد على الأقل.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "يجب أن يتكون {attribute} من حرفين {length} على الأقل ويحتوي على حرف كبير واحد على الأقل وحرف خاص واحد.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "يجب أن يتكون {attribute} من حرفين {length} على الأقل ويحتوي على حرف كبير واحد ورقم واحد وحرف خاص واحد على الأقل.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "يجب أن يتكون {attribute} من حرفين {length} على الأقل ويحتوي على حرف كبير واحد على الأقل.",
        "The {attribute} must be at least {length} characters.": "يجب أن يتكون {attribute} من حرفين {length} على الأقل.",
        "The {attribute} must contain at least one letter.": "يجب أن يحتوي {attribute} على حرف واحد على الأقل.",
        "The {attribute} must contain at least one number.": "يجب أن يحتوي {attribute} على رقم واحد على الأقل.",
        "The {attribute} must contain at least one symbol.": "يجب أن يحتوي {attribute} على رمز واحد على الأقل.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "يجب أن يحتوي {attribute} على حرف كبير واحد وحرف صغير واحد على الأقل.",
        "auth": {
            "failed": "بيانات الاعتماد هذه لا تتطابق مع سجلاتنا. هل أنت متأكد من أنك لم تستخدم هذا البريد الإلكتروني لحساب {type}؟",
            "throttle": "عدد كبير جدًا من محاولات تسجيل الدخول. يرجى المحاولة مرة أخرى خلال {seconds} ثانية.",
            "throttle_cascade": "عدد كبير جدًا من محاولات تسجيل الدخول. يرجى المحاولة مرة أخرى في {remaining}."
        },
        "documents": {
            "display": {
                "description": "العرض بصيغة A6 مع ربط QRCode وشعار مؤسستك.",
                "title": "العرض - بطاقة A6"
            },
            "qrcode": {
                "description": "QRCode وحده يسمح للمسافر بالارتباط المباشر بمؤسستك.",
                "filename": "رمز الاستجابة السريعة",
                "title": "رابط QRCode"
            }
        },
        "enums": {
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "مخلوق",
                "deleted": "تم الحذف",
                "updated": "محدث",
                "validated": "تم التحقق من صحتها"
            },
            "App\\Enums\\BinaryChoiceEnum": [
                "رفض",
                "قبلت"
            ],
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "association": "الجمعية (أو المؤسسة)",
                "company": "شركة (عامة/خاصة)",
                "individual": "فردي",
                "undefined": "غير معرف"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "DE": "ألمانيا",
                "ES": "إسبانيا",
                "FR": "فرنسا",
                "IT": "إيطاليا",
                "TH": "تايلاند",
                "undefined": "غير معرف"
            },
            "App\\Enums\\ContextsEnum": {
                "city_hall": "بلدية",
                "hotel": "الفندق",
                "manager": "مدير",
                "tourist_office": "مكتب سياحة",
                "traveller": "مسافر"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "اليورو",
                "usd": "دولار"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Friday": "جمعة",
                "Monday": "الاثنين",
                "Saturday": "السبت",
                "Sunday": "الأحد",
                "Thursday": "يوم الخميس",
                "Tuesday": "يوم الثلاثاء",
                "Wednesday": "الأربعاء"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "حسب الطلب",
                "root": "قوالبي",
                "ugozer_app": "تطبيق يوغوزر",
                "ugozer_pro": "يوغوزر برو"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "إمبراطوري",
                "metric": "قياس"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "في التطبيق",
                "email": "بريد إلكتروني"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "customer": "عميل",
                "partner": "شريك"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "easytransac": "EasyTransac",
                "paypal": "باي بال",
                "stripe": "شريط"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "subscription": "الاشتراك",
                "tip": "نصيحة"
            },
            "App\\Enums\\PrivacySettingsEnum": {
                "contacts": "جهات الاتصال الخاصة بي",
                "everyone": "الجميع",
                "nobody": "لا أحد"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "illegal": "غير قانوني",
                "incorrect_data": "معلومات غير صحيحة",
                "other": "آخر",
                "shocking": "مروع",
                "spam": "رسائل إلكترونية مزعجة"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "إفطار",
                "dinner": "عشاء",
                "lunch": "غداء",
                "null": "لا يوجد"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "فيسبوك",
                "google": "جوجل",
                "instagram": "انستغرام",
                "linkedin": "ينكدين",
                "tiktok": "تيك توك",
                "twitter": "اكس (تويتر)",
                "wechat": "وي شات"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "شهر واحد",
                "one year": "1 سنة"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "شهر واحد",
                "one year": "1 سنة",
                "six months": "6 اشهر",
                "three months": "3 اشهر"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "expired": "انتهى الاشتراك",
                "free period ended": "انتهت الفترة المجانية",
                "payment default": "التقصير في الدفع"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "برنامج",
                "extranet": "الشبكة الخارجية",
                "front": "أمام"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "مؤرشف",
                "canceled": "ألغيت",
                "pending_support": "في انتظار الدعم",
                "pending_user": "في انتظار المستخدم",
                "resolved": "تم الحل"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "حولي",
                "customized": "حسب الطلب",
                "staying_hotel": "بالقرب من فندق الإقامة"
            },
            "App\\Enums\\UserRoleEnum": {
                "butler": "كبير الخدم",
                "contributor": "مساهم",
                "hotelier": "صاحب فندق",
                "manager": "مدير",
                "moderator": "مشرف",
                "superadmin": "المشرف الفائق",
                "touristOffice": "مكتب سياحة",
                "touristOfficeEmployee": "إلى الموظف",
                "traveller": "مسافر"
            }
        },
        "nova": {
            "lens": {
                "user": {
                    "user_origin": "أصل المستخدمين"
                }
            },
            "menu": {
                "administration": "إدارة",
                "content": "محتوى",
                "excursions": "مسارات",
                "import_poi": "استيراد النقاط المهمة",
                "miscellaneous": "متنوع",
                "parameters": "حدود",
                "pois": "النقاط المثيرة للاهتمام",
                "subscriptions": "الاشتراكات",
                "support": "يدعم",
                "users": "المستخدمين"
            },
            "s3_private_download": {
                "link": {
                    "expiration": "ستنتهي صلاحية الرابط بعد 30 دقيقة، إذا كان الأمر كذلك، فما عليك سوى تحديث الصفحة.",
                    "open": "عرض الوثيقة"
                },
                "no_document": "لا توجد وثائق ذات صلة",
                "not_found": "تعذر العثور على المستند على محرك الأقراص S3..."
            },
            "user_menu": {
                "profile": "ملفي"
            }
        },
        "pagination": {
            "go_page": "انتقل إلى الصفحة ×1",
            "next": "التالي \"",
            "next_alt": "التالي",
            "of": "ل",
            "pagination_navigation": "التنقل بين الصفحات",
            "previous": "\" سابق",
            "previous_alt": "سابق",
            "results": "نتائج",
            "showing": "عرض",
            "to": "ل"
        },
        "passwords": {
            "reset": "تم إعادة تعيين كلمة المرور الخاصة بك!",
            "sent": "لقد أرسلنا عبر البريد الإلكتروني رابط إعادة تعيين كلمة المرور الخاصة بك!",
            "throttled": "الرجاء الانتظار قبل إعادة المحاولة.",
            "token": "رمز إعادة تعيين كلمة المرور هذا غير صالح.",
            "user": "لا يوجد مستخدم المقابلة."
        },
        "ttmt": {
            "account_deletion": {
                "confirmation": "في الصفحة التي تفتح، قم بتأكيد الحذف عن طريق إدخال كلمة <strong>حذف</strong>\n        وانقر على <strong>تأكيد</strong>.",
                "content": {
                    "asking": "في حالة طلب حذف الحساب، ستقوم UgoZER بحذف حساب المستخدم وبياناته،\n            إلا إذا كان يجب الاحتفاظ بها بسبب متطلبات قانونية أو تنظيمية، أو لأغراض أمنية، أو احتيال\n            الوقاية أو بسبب مشكلة تتعلق بحساب المستخدم مثل مطالبة غير مدفوعة أو لم يتم حلها أو\n            ينازع.",
                    "conservation": {
                        "link": "سياسة الخصوصية.",
                        "part_1": "يحتفظ UgoZER ببيانات المستخدم طالما كان ذلك ضروريًا للأغراض الموضحة في",
                        "part_2": "وفقًا لتوصيات CNIL، سيتم حذف الحساب الذي لم يتم استخدامه لمدة 3 سنوات.",
                        "part_3": "يمكن للمستخدمين طلب حذف حساباتهم في أي وقت. قد تحتفظ UgoZER ببيانات المستخدم بعد أ\n                طلب الحذف وفقاً للمتطلبات القانونية أو التنظيمية، أو للأسباب المذكورة في ذلك\n                إفادة."
                    },
                    "instructions": "إذا كنت ترغب في حذف حساب UgoZER الخاص بك، يرجى اتباع التعليمات أدناه."
                },
                "logout": "سيتم تسجيل خروجك وسيتم حذف الحساب والبيانات المرتبطة به على الفور.",
                "pro": {
                    "instructions": {
                        "delete": "ثم انقر فوق الزر <strong>حذف</strong> الموجود أمام <strong>حذف الحساب\n                </strong>،",
                        "profile": {
                            "link": "حساب تعريفي",
                            "part_1": "إذهب إلى ....... الخاص بك",
                            "path": "(بالنقر على الصورة الرمزية الخاصة بك > ملفي الشخصي)،"
                        }
                    },
                    "subtitle": "لحذف حسابك المهني:",
                    "title": "حساب احترافي"
                },
                "title": "حذف الحساب",
                "traveller": {
                    "instructions": {
                        "delete": "ثم انقر فوق الزر <strong>حذف</strong> الموجود أمام <strong>هل تريد حذف حسابي؟\n                </قوي>",
                        "profile": {
                            "link": "حساب تعريفي",
                            "part_1": "إذهب إلى ....... الخاص بك",
                            "path": "(قائمة أيقونة الهامبرغر > الإعدادات > الملف الشخصي)،"
                        }
                    },
                    "subtitle": "لحذف حساب المسافر الخاص بك:",
                    "title": "حساب المسافر"
                }
            },
            "activity_logs": {
                "summary": {
                    "created": {
                        "intro": "تم إنشاء الموضوع بهذه الخصائص:"
                    },
                    "deleted": {
                        "intro": "تم حذف الموضوع بهذه الخصائص:"
                    },
                    "updated": {
                        "attribute_from_to": "تم تحديث {attribute} من \"{old_value}\" إلى \"{new_value}\"",
                        "intro": "تم تحديث الموضوع:"
                    },
                    "validated": {
                        "intro": "تم التحقق من صحة الموضوع:"
                    }
                }
            },
            "auth": {
                "credential_verification": {
                    "intro": "أدخل بيانات الاعتماد الخاصة بك، وسيتم إرسال رابط التأكيد إليك.",
                    "intro2": "سيتم تحديث بيانات الاعتماد الخاصة بك بمجرد وصولك إلى الرابط."
                },
                "expiration": {
                    "session_expired": "لقد انتهت جلستك، يرجى إعادة المصادقة"
                },
                "extranet_register": {
                    "form": {
                        "choose_establishment": "اختر نوع مؤسستك",
                        "form_title": "أنشئ حسابك",
                        "validate_registration": "الانتهاء من تسجيلي"
                    },
                    "success": "تم التسجيل بنجاح، وتم إرسال رابط التحقق إلى بريدك الإلكتروني"
                },
                "login": {
                    "already_logged": "انت بالفعل داخل",
                    "logging_in": "تسجيل الدخول...",
                    "success": "تم تسجيل الدخول",
                    "use_email": "تسجيل الدخول باستخدام البريد الإلكتروني",
                    "use_social_networks": "استخدم الشبكات الاجتماعية"
                },
                "logout": {
                    "logging_out": "تسجيل الخروج...",
                    "switch": "التبديل إلى"
                },
                "messages": {
                    "continue": "تواصل مع",
                    "extranet_here": "انقر هنا",
                    "have_account": "هل لديك حساب؟",
                    "login": "تسجيل الدخول",
                    "login_subtitle": "(التسجيل أو الدخول)",
                    "login_title": "إنه أفضل مع الشبكات الاجتماعية",
                    "no_account": "لا تملك حسابا حتى الآن؟",
                    "or": "أو",
                    "professional": "هل أنت محترف؟",
                    "register": "يسجل",
                    "register_mail": "سجل باستخدام البريد الإلكتروني",
                    "register_title": "قم بالتسجيل لفتح جميع الميزات",
                    "reset_password": "إعادة تعيين كلمة المرور",
                    "send_magic_link": "إرسال رابط بدون كلمة مرور"
                },
                "password_forgotten": {
                    "intro": "أدخل بيانات الاعتماد الخاصة بك وسنرسل لك رابطًا لإعادة تعيين كلمة المرور الخاصة بك.",
                    "submit": "احصل على رابط إعادة التعيين",
                    "success": {
                        "correct_credential": "بيانات الاعتماد الصحيحة",
                        "email_1": "سيتم إرسال بريد إلكتروني إليك على {email}، إذا كان هذا العنوان مسجلاً بشكل جيد.",
                        "email_2": "تحتوي هذه الرسالة الإلكترونية على رابط يسمح لك بإعادة تعيين كلمة المرور الخاصة بك.",
                        "send_again": "أعد الإرسال",
                        "title": "تم إرسال رابط إعادة تعيين كلمة المرور",
                        "wait_before": "يرجى الانتظار ×1 ثانية قبل الإرسال مرة أخرى"
                    }
                },
                "password_reset": {
                    "submit": "اعد ضبط كلمه السر",
                    "success": "تمت إعادة تعيين كلمة المرور"
                },
                "register": {
                    "form": {
                        "hide_privacy_policy": "إخفاء سياسة الخصوصية",
                        "hide_terms_conditions": "إخفاء الشروط والأحكام",
                        "privacy_policy": "أوافق على سياسة الخصوصية",
                        "show_privacy_policy": "إظهار سياسة الخصوصية",
                        "show_terms_conditions": "عرض الشروط والأحكام",
                        "terms_conditions": "أوافق على الشروط"
                    },
                    "staying_hotel": "بمجرد قيامك بالتسجيل، سيتم الاعتناء بك من قبل الفندق الذي\n            لقد قمت للتو بمسح رمز الاستجابة السريعة ضوئيًا.",
                    "success": {
                        "email_1": "سيتم إرسال بريد إلكتروني إلى {email}.",
                        "email_2": "تحتوي هذه الرسالة الإلكترونية على رابط يسمح لك بتأكيد عنوانك والاتصال.",
                        "paired": {
                            "email_1": "تم إرسال رسالتين بالبريد الإلكتروني إلى العناوين التالية",
                            "email_2": "يحتوي كل بريد إلكتروني على رابط لتأكيد العنوان وربط الحساب المرتبط."
                        },
                        "title": "سجلت بنجاح"
                    }
                },
                "social_networks": {
                    "authenticate": "تسجيل الدخول مع",
                    "facebook": "فيسبوك",
                    "google": "جوجل",
                    "no_email": "لا يوجد بريد إلكتروني مرتبط بشبكتك الاجتماعية، ولا يمكنك المصادقة عليه",
                    "twitter": "تويتر"
                },
                "travel_assistant": "مساعد سفر مجاني"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "لم يتم تقديم الحساب البنكي"
                    },
                    "form": {
                        "placeholders": {
                            "bank_city": "مدينة فرع البنك الخاص بك.",
                            "bank_name": "اسم البنك",
                            "bank_transfer_reason": "مثال: \"نصائح UgoZER\"",
                            "bic": "أدخل BIC الخاص بك",
                            "iban": "أدخل رقم الحساب المصرفي الدولي / BBAN الخاص بك",
                            "payee_name": "اسم المستفيد أو اسم المؤسسة",
                            "types": "رقم الحساب المصرفي الدولي/رقم الحساب المصرفي الدولي"
                        }
                    },
                    "lines": {
                        "no_account_yet": "سيمكن حسابك البنكي مؤسستك من تلقي المدفوعات على UgoZER."
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "amount": "كمية",
                    "amount_to_pay": "المبلغ الواجب دفعه",
                    "concierge": "خدمات الاستقبال والإرشاد",
                    "currency": "عملة",
                    "customer": "عميل",
                    "date": "تاريخ",
                    "repartition_key": "مفتاح التوزيع",
                    "tip_sharing_form": "نموذج مشاركة النصائح",
                    "tips_received": "النصائح الواردة",
                    "total_received": "مجموع الواردة"
                }
            },
            "breadcrumbs": {
                "changelogs": {
                    "changelogs": "سجلات التغيير"
                },
                "claims": {
                    "claims": "المطالبات"
                },
                "common": {
                    "add": "يضيف",
                    "claim": "مطالبة",
                    "create": "جديد",
                    "create_f": "جديد",
                    "delete": "يمسح",
                    "download_pdf": "تحميل PDF",
                    "edit": "يحرر",
                    "report": "تقرير",
                    "share": "يشارك",
                    "show": "يعرض"
                },
                "contacts": {
                    "contacts": "جهات الاتصال"
                },
                "cookie": {
                    "cookie": "إدارة ملفات تعريف الارتباط"
                },
                "credit": {
                    "credit": "الاعتمادات"
                },
                "dashboard": {
                    "dashboard": "لوحة القيادة"
                },
                "errors": {
                    "404": {
                        "label": "خطأ 404",
                        "title": "لم يعثر على الخطأ 404"
                    }
                },
                "excursions": {
                    "create": "خط سير جديد",
                    "excursions": "مسارات"
                },
                "faq": {
                    "faq": "أسئلة مكررة"
                },
                "finances": {
                    "finances": "الشؤون المالية"
                },
                "institutions": {
                    "institution": "مؤسسة",
                    "institutions": "المؤسسات"
                },
                "institutions_employees": {
                    "institutions_employees": "موظفيني",
                    "invite": "دعوة موظف"
                },
                "interactions": {
                    "interactions": "التفاعلات"
                },
                "invitations": {
                    "invitations": "الدعوات"
                },
                "notification_settings": {
                    "notification_settings": "إعدادات الإخطارات"
                },
                "notifications": {
                    "notifications": "إشعارات"
                },
                "partners": {
                    "customers": "عملاء",
                    "partners": "الشركاء"
                },
                "points_of_interest": {
                    "add_excursion": "أضف إلى خط سير الرحلة",
                    "points_of_interest": "النقاط المثيرة للاهتمام"
                },
                "privacy_policy": {
                    "privacy_policy": "سياسة الخصوصية"
                },
                "privacy_settings": {
                    "privacy_settings": "إعدادات الخصوصية"
                },
                "reservations": {
                    "reservations": "التحفظات"
                },
                "search": {
                    "search": "يبحث"
                },
                "subscriptions": {
                    "subscription": "الاشتراك"
                },
                "support_messages": {
                    "add_message": "اضف رسالة",
                    "support_messages": "رسائل"
                },
                "support_requests": {
                    "add_request": "إضافة طلب دعم جديد",
                    "support_requests": "طلبات الدعم"
                },
                "terms_condition": {
                    "terms_condition": "الأحكام والشروط"
                },
                "travellers": {
                    "folders": "مجلدات المسافر",
                    "travellers": "المسافرون"
                },
                "tutorials": {
                    "begin": "ابدء"
                },
                "users": {
                    "edit_credential": "تحرير بيانات الاعتماد",
                    "profile": "ملفي",
                    "users": "المستخدمين"
                }
            },
            "changelogs": {
                "archive": {
                    "success": "تمت أرشفة سجل (سجلات) التغيير (إذا كانت مؤهلة)"
                },
                "draft": {
                    "success": "تمت صياغة (سجلات) التغيير (إذا كانت مؤهلة)"
                },
                "index": {
                    "sections": {
                        "all": "جميع التحديثات",
                        "last": "اخر تحديث"
                    }
                },
                "publish": {
                    "success": "تم نشر (سجلات) التغيير (إذا كانت مؤهلة)"
                },
                "show": {
                    "version": "إصدار"
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "تم إنشاء نزاع حول المطالبة"
                },
                "rejected": {
                    "success": "تم رفض النزاع حول المطالبة"
                },
                "validated": {
                    "success": "تم التحقق من صحة نزاع المطالبة"
                }
            },
            "claims": {
                "form_wizard": {
                    "documents": {
                        "DE": {
                            "association_legal_representative_identity_piece": "Reisepass، Personalausweis، Führerschein (neere\n                    Version mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "association_statutes": "Handelsregisterauszug",
                            "company_legal_representative_identity_piece": "Reisepass، Personalausweis، Führerschein (neere\n                    Version mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "company_registration_document": "Handelsregisterauszug",
                            "company_statutes": "Aktuelle und unterschriebene (von der Geschäftsführung) Gesellschaftverträge",
                            "individual_first_identity_piece": "Reisepass, Personalausweis, Führerschein (إصدار جديد mit\n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_registration_document": "Gewerbeschein، Handlungsbevollmächtigung، Handelsregisterauszug،\n                    بروكورا، خطاب إسناد Steuernummer",
                            "individual_second_identity_piece": "Reisepass, Personalausweis, Führerschein (إصدار جديد mit\n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)"
                        },
                        "ES": {
                            "association_legal_representative_identity_piece": "جواز السفر, وثيقة الهوية الوطنية,\n                    تصريح القيادة (الإصدار الحديث مع خطوط MRZ) أو تصريح الإقامة (فقط إذا كان من un\n                    الدولة الأوروبية)",
                            "association_statutes": "كتب الدستور أو الحالات الاجتماعية",
                            "company_legal_representative_identity_piece": "جواز السفر، وثيقة الهوية الوطنية، الإذن\n                    القيادة (الإصدار الحديث مع خطوط MRZ) أو تصريح الإقامة (فقط إذا كان من بلد واحد)\n                    أوروبا)",
                            "company_registration_document": "مقتطف من السجل التجاري المركزي، ملاحظة بسيطة من السجل\n                    mercantil local Sellado o numerico، Extracto desde los Registradores de España (المعلومات\n                    General Mercantil) مع مذكرة صالحة لمدة تقل عن 3 أشهر أو Modelo 600 مع مذكرة صالحة\n                    أقل من 3 أشهر (منفردًا لمجتمع مدني)",
                            "company_statutes": "كتب الدستور أو الحالات الاجتماعية",
                            "individual_first_identity_piece": "جواز السفر، وثيقة الهوية الوطنية، تصريح التوصيل\n                    (الإصدار الحديث مع خطوط MRZ) أو تصريح الإقامة (فقط إذا كان من دولة أوروبية)",
                            "individual_registration_document": "Agencia Tributaria - Certificado de Situacion Censal\n                    (ألتا سينسال)",
                            "individual_second_identity_piece": "جواز السفر، وثيقة الهوية الوطنية، تصريح التوصيل\n                    (الإصدار الحديث مع خطوط MRZ) أو تصريح الإقامة (فقط إذا كان من دولة أوروبية)"
                        },
                        "FR": {
                            "association_legal_representative_identity_piece": "قطعة من الهوية في مسار صلاحية du\n                    الممثل القانوني للرابطة: بطاقة الهوية (الظهر والظهر)، جواز السفر، عنوان\n                    séjour (يمين وخلف)، إذن بالتوصيل البيومتري (يمين وخلف)",
                            "association_statutes": "قوانين الجمعية",
                            "company_legal_representative_identity_piece": "قطعة من الهوية في مسار صلاحية du\n                    الممثل القانوني للشركة: بطاقة الهوية (اليمين والظهر)، جواز السفر، عنوان الإقامة\n                    (اليسار واليسار)، السماح بالتوصيل البيومتري (اليسار واليسار)",
                            "company_registration_document": "Un extrait KBIS",
                            "company_statutes": "القوانين",
                            "individual_first_identity_piece": "أول قطعة هوية خلال فترة الصلاحية: بطاقة\n                    الهوية (اليسار والخلف)، جواز السفر، عنوان الإقامة (اليسار والخلف)، إذن القيادة\n                    القياسات الحيوية (اليمين والظهر)",
                            "individual_registration_document": "شهادة تسجيل في INSEE أو مستند\n                    شهادة تسجيل ابنه بعد غرفة التجارة (لصالح رجل أعمال ممارس\n                    نشاط تجاري) أو auprès de la Chambre des Métiers (من أجل رجل أعمال متدرب)\n                    نشاط حرفي)",
                            "individual_second_identity_piece": "قطعة ثانية من الهوية في دورة الصلاحية: بطاقة\n                    الهوية (اليسار والخلف)، جواز السفر، عنوان الإقامة (اليسار والخلف)، إذن القيادة\n                    (اليمين والظهر)، آخر موعد لفرض تاريخ أقل من سنة، كتاب العائلة أو\n                    وصفة تسجيل ميثاق التضامن المدني"
                        },
                        "IT": {
                            "association_legal_representative_identity_piece": "باسابورتو، كارتا الهوية الوطنية، لا\n                    براءة اختراع الدليل (الإصدار الأخير مع خط MRZ) أو تصريح الإقامة (فقط في بلد واحد)\n                    أوروبا)",
                            "association_statutes": "Tatuto Firmato dal Legale Rappresentante (لا يشمل كل شيء داخليًا\n                    فيسورا كاميرال",
                            "company_legal_representative_identity_piece": "جواز السفر، كارتا الهوية الوطنية، براءة الاختراع\n                    دليل (الإصدار الأخير مع خط MRZ) أو تصريح الإقامة (فقط في دولة أوروبية)",
                            "company_registration_document": "فيسورا كاميرال",
                            "company_statutes": "النظام الأساسي للممثل القانوني (لا يشمل كل شيء داخليًا).\n                    فيسورا كاميرالي)",
                            "individual_first_identity_piece": "جواز السفر، كارتا الهوية الوطنية، براءة الاختراع الإرشادية\n                    (الإصدار الأخير مع خط MRZ) أو تصريح الإقامة (فقط في دولة أوروبية)",
                            "individual_registration_document": "Estratto dal registro delle imprese (\"Visura Camerale\") على الأقل\n                    لمدة 3 أشهر",
                            "individual_second_identity_piece": "جواز السفر، كارتا الهوية الوطنية، براءة الاختراع الإرشادية\n                    (الإصدار الأخير مع خط MRZ) أو تصريح الإقامة (فقط في دولة أوروبية)"
                        },
                        "TH": {
                            "association_legal_representative_identity_piece": "لا داعي للقلق بشأن هذا الأمر ك:\n                    فنادق فخمة (متنوعة ومبتكرة),\n                    في الحقيقة, هذا هو السبب في ذلك (السبب في ذلك) ودانتيل)،\n                    بوليتستريسي (المدينة المنورة والمدينة)",
                            "association_statutes": "شكرا جزيلا",
                            "company_legal_representative_identity_piece": "لا داعي للقلق بشأن هذا الأمر الموقع:\n                    بريتش بتر تشاين (دونتا نوفو ودانتل)، بنسلفانيا جيد,\n                    فينيستا تينيتي (دانتا ودانتيل),\n                    بوليميريسيس (دانتا ودانتيل)",
                            "company_registration_document": "نظام KBIS",
                            "company_statutes": "شكرا",
                            "individual_first_identity_piece": "أفضل ما في الأمر: مغامرة رائعة ن\n                    (بين دنتا ودانتيل)، فينيسيا، بنسلفانيا تيني إيت (يوجد هنا ودانتيل),\n                    بوليتستريسي (المدينة المنورة والمدينة)",
                            "individual_registration_document": "أفضل ما يمكن أن يكون عليه الأمر مغامرة رائعة\n                    (تم تصميمه خصيصًا لك) ه)\n                    مغامرة رائعة (ممتعة للغاية) جاكارتي بيس)\n                    هاشمي)",
                            "individual_second_identity_piece": "أفضل ما في الأمر: التسوق ها\n                    (้าหห้าและด้าหลัง) نعم\n                    (دانتا فنت ودانتيل) بولينج (دانتا فنت ودانتيل)\n                    لا يوجد شيء أفضل من هذا مغامرة رائعة هكذا\n                    لا داعي للقلق بشأن هذا الأمر"
                        }
                    },
                    "steps": {
                        "claim": "مطالبة",
                        "context": "سياق",
                        "create": "يخلق",
                        "documents": "وثائق",
                        "info": "معلومة",
                        "search": "يبحث"
                    }
                },
                "missing_info": {
                    "success": "تم رفض المطالبة بسبب المعلومات المفقودة"
                },
                "notice": {
                    "actions": {
                        "edit": "تعديل طلبي"
                    },
                    "already_claimed": {
                        "description": "وقد تم بالفعل المطالبة بهذه النقطة المثيرة للاهتمام. إذا كنت تعتقد أن هذا خطأ، يمكنك ذلك\n                املأ نموذج النزاع أدناه.",
                        "title": "تمت المطالبة بنقطة الاهتمام بالفعل!"
                    },
                    "find_or_new": {
                        "description": "ابحث عن نقطة اهتمام في القائمة أدناه أو قم بإنشاء نقطة اهتمام جديدة.",
                        "title": "بحث أو إنشاء نقطة اهتمام"
                    },
                    "missing-info": {
                        "infos": "يرجى إكمال طلبك بالضغط على الزر أدناه.",
                        "state": "مطالبتك تنتظر حاليًا معلومات أو وثائق إضافية:",
                        "title": "في انتظار معلومات إضافية"
                    },
                    "pending": {
                        "state": "طلب المطالبة الخاص بك قيد المعالجة حاليًا",
                        "title": "طلب المطالبة في انتظار المراجعة"
                    },
                    "rejected": {
                        "state": "تم رفض طلب المطالبة الخاص بك للسبب التالي:",
                        "title": "تم رفض طلب المطالبة"
                    }
                },
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "مطالبة",
                            "poi": "نقطة الأهتمام",
                            "user": "مستخدم"
                        }
                    }
                },
                "rejected": {
                    "success": "تم رفض المطالبة"
                },
                "revoked": {
                    "success": "تم إلغاء المطالبة"
                },
                "states": {
                    "missing-info": "معلومات مفقودة",
                    "pending": "قيد الانتظار",
                    "rejected": "مرفوض",
                    "revoked": "إلغاء - فسخ",
                    "validated": "تم التحقق من صحتها"
                },
                "update": {
                    "success": "تم تحديث المطالبة"
                },
                "valid_identity_pieces": "بطاقة هوية سارية المفعول أو جواز السفر أو رخصة القيادة",
                "validated": {
                    "success": "تم التحقق من صحة المطالبة"
                }
            },
            "common": {
                "actions": {
                    "accept": "يقبل",
                    "accept_contact": "قبول طلب الاتصال",
                    "add": "يضيف",
                    "add_contact": "أضف إلى جهات الاتصال",
                    "add_excursion": "أضف إلى خط سير الرحلة",
                    "add_message": "اضف رسالة",
                    "apply": "يتقدم",
                    "approve": "يعتمد",
                    "archive": "أرشيف",
                    "attach": "يربط",
                    "block_contact": "حظر الاتصال",
                    "cancel": "يلغي",
                    "check": "يفحص",
                    "claim": "مطالبة",
                    "close": "يغلق",
                    "configure": "تهيئة",
                    "confirm": "يتأكد",
                    "contest": "منافسة",
                    "create": "يخلق",
                    "decline": "ينكر",
                    "decline_contact": "رفض طلب الاتصال",
                    "delete": "يمسح",
                    "download": "تحميل",
                    "edit": "يحرر",
                    "file_preview": "معاينة الملف",
                    "filters": "المرشحات",
                    "give_a_tip": "قدم نصيحة",
                    "google_maps": "افتح على خرائط جوجل",
                    "help": "يساعد",
                    "hide": "يخفي",
                    "ignore": "يتجاهل",
                    "interactions": "إظهار التفاعلات",
                    "invite": "يدعو",
                    "load_more": "تحميل المزيد",
                    "login": "تسجيل الدخول",
                    "logout": "تسجيل خروج",
                    "manage": "يدير",
                    "next": "التالي",
                    "pause": "يوقف",
                    "pay": "يدفع",
                    "play": "يلعب",
                    "print": "مطبعة",
                    "quit": "يترك",
                    "record": "سِجِلّ",
                    "register": "يسجل",
                    "report": "تقرير",
                    "request_to_join": "طلب الانضمام",
                    "reset": "إعادة ضبط",
                    "resume": "سيرة ذاتية",
                    "retry": "أعد المحاولة",
                    "return": "خلف",
                    "save": "يحفظ",
                    "save_alt": "يحفظ",
                    "search": "يبحث",
                    "see_plans": "انظر الخطط",
                    "see_website": "انظر الموقع",
                    "send": "يرسل",
                    "send_link": "إرسال رابط التطبيق",
                    "share": "يشارك",
                    "show": "يعرض",
                    "start": "يبدأ",
                    "stop": "قف",
                    "subscribe": "يشترك",
                    "thank": "شكر",
                    "thank_for_stay": "شكرا على الاقامة",
                    "traveller_show": "استشارة المسافر",
                    "unblock_contact": "إلغاء حظر جهة الاتصال",
                    "unblock_this_user": "قم بإلغاء حظر هذا المستخدم",
                    "update": "تحديث",
                    "validate": "التحقق من صحة"
                },
                "all": "الجميع",
                "and": "و",
                "app_name": "UgoZER",
                "back_to_dashboard": "العودة إلى لوحة القيادة",
                "back_to_home": "العودة إلى المنزل",
                "characters_limit": "أحرف ×1 / ×2",
                "counter": "×1 على ×2",
                "country": "دولة",
                "dashboard": "لوحة القيادة",
                "errors": {
                    "deleted_user": "مستخدم محذوف",
                    "internal": "هناك خطأ ما!",
                    "not_found": "غير معثور عليه"
                },
                "false": "لا",
                "fast": "سريع",
                "home": "بيت",
                "later": "لاحقاً",
                "loading": "تحميل",
                "messages": {
                    "application_update": {
                        "if_ignore": "إذا تجاهلت التحديث، فسيتم ذلك في المرة التالية التي تقوم فيها بتشغيل التطبيق.",
                        "new_version_available": "إصدار جديد متوفر.",
                        "updating": "جارٍ التحديث...",
                        "want_to_refresh": "هل تريد إعادة تشغيل التطبيق لتحديثه؟"
                    },
                    "clipboard": {
                        "error": "حدث خطأ أثناء النسخ إلى الحافظة...",
                        "success": "نسخ إلى الحافظة!"
                    },
                    "no_records": "لا توجد سجلات"
                },
                "missing_title": "عنوان مفقود",
                "more_options": "المزيد من الخيارات",
                "no": "لا",
                "none": "لا أحد",
                "normal": "طبيعي",
                "not_specified": "غير محدد",
                "online": {
                    "connected": "أنت متصل بالإنترنت مرة أخرى",
                    "disconnected": "لم تعد متصلاً بالإنترنت"
                },
                "oops": "أُووبس",
                "or": "أو",
                "qr_code": "رمز الاستجابة السريعة",
                "search": "يبحث",
                "slow": "بطيء",
                "someone": "شخص ما",
                "sort": "نوع",
                "tip": "نصيحة",
                "tip_received": "تم استلام النصيحة (إذا كانت ممكّنة)",
                "true": "نعم",
                "units": {
                    "kilometer": "كيلومتر | كيلومترات",
                    "km": "كم",
                    "mi": "mi",
                    "mile": "ميل | اميال"
                },
                "welcome": "مرحباً",
                "yes": "نعم"
            },
            "cookies": {
                "details": {
                    "content": "فيما يلي قائمة بملفات تعريف الارتباط الموضوعة في هذا القسم من تطبيق UgoZER بناءً على\n            الفئات المحددة أعلاه:",
                    "table": {
                        "headers": {
                            "category": "فئة الاستخدام",
                            "description": "وصف",
                            "lifespan": "حياة",
                            "mandatory": "إلزامي",
                            "name": "اسم ملف تعريف الارتباط (أو النمط)"
                        },
                        "lines": {
                            "alphanumeric": {
                                "content": "يحتوي على معلومات حول الجلسة.",
                                "lifespan": "ساعاتين",
                                "title": "[[{alnum}:]] (تسلسل الحروف الأبجدية الرقمية)"
                            },
                            "gmaps": {
                                "content": "يستخدم لعرض iframe لخرائط Google.",
                                "lifespan": "180-400 يوم",
                                "title": "الموافقة، AEC، SOCS... (أو ملفات تعريف الارتباط الأخرى لنطاق google.com)"
                            },
                            "remember": {
                                "content": "تستخدم لحفظ مصادقة المستخدم.",
                                "lifespan": "400 يوم",
                                "title": "تذكر_ويب_*"
                            },
                            "session": {
                                "content": "يحتوي على معلومات حول الجلسة.",
                                "lifespan": "ساعاتين",
                                "title": "*_ugozer_session"
                            },
                            "tarteaucitron": {
                                "content": "يسمح لك بالحفاظ على إعدادات ملفات تعريف الارتباط الخاصة بالمستخدم.",
                                "lifespan": "365 يوما",
                                "title": "تارتوسيترون"
                            },
                            "xsrf": {
                                "content": "يسمح بتأمين التطبيق (الطلبات، النماذج...).",
                                "lifespan": "ساعاتين",
                                "title": "XSRF-TOKEN-*"
                            }
                        }
                    },
                    "title": "تفاصيل ملفات تعريف الارتباط المودعة"
                },
                "management": {
                    "content": {
                        "cta": "تغيير تفضيلاتي",
                        "line_1": "تعتمد بعض ميزات هذا الموقع على استخدام ملفات تعريف الارتباط.",
                        "line_2": "لا يظهر شعار ملفات تعريف الارتباط على الصفحة الرئيسية أثناء التنقل إذا لزم الأمر فقط\n                يتم إيداعها لعمل الموقع.",
                        "line_3": "يمكنك تغيير تفضيلاتك بالضغط على الزر أدناه:"
                    },
                    "title": "إدارة ملفات تعريف الارتباط"
                },
                "notice": {
                    "needed": "لرؤية هذا القسم، يجب عليك قبول ملفات تعريف الارتباط {name}.",
                    "refresh": "إذا لم تشاهده بعد قبول ملفات تعريف الارتباط، فيرجى تحديث الصفحة.",
                    "title": "بسكويت"
                },
                "services": {
                    "gmaps": "خرائط جوجل",
                    "youtube": "موقع YouTube"
                },
                "usage": {
                    "content": "يمكن استخدام ملفات تعريف الارتباط للتعرف عليك عند زيارتك لـ UgoZER وتذكر تفضيلاتك وتقديمها\n            لك تجربة مخصصة تتماشى مع إعداداتك. تعمل ملفات تعريف الارتباط أيضًا على تفاعلاتك مع\n            UgoZER أسرع وأكثر أمانًا. بالإضافة إلى ذلك، تسمح لنا ملفات تعريف الارتباط بتقديم إعلانات لك داخل وخارج الموقع\n            مواقع UgoZER.",
                    "table": {
                        "headers": {
                            "category": "فئة الاستخدام",
                            "description": "وصف"
                        },
                        "lines": {
                            "ads": {
                                "content": "قد نستخدم ملفات تعريف الارتباط لنعرض لك الإعلانات ذات الصلة داخل موقع UgoZER وخارجه.\n                        قد نستخدم أيضًا ملف تعريف الارتباط لمعرفة ما إذا كان الشخص الذي شاهد إعلانًا قد زاره لاحقًا وأخذه\n                        الإجراء (على سبيل المثال، تنزيل مستند تقني أو إجراء عملية شراء) على موقع المعلن.\n                        وبالمثل، قد يستخدم شركاؤنا ملف تعريف الارتباط لتحديد ما إذا كنا قد عرضنا إعلانًا وكيفية عرضه\n                        تنفيذها أو تزويدنا بمعلومات حول كيفية تفاعلك مع الإعلانات. قد نعمل أيضا\n                        مع شريك ليعرض لك إعلانًا على UgoZER أو خارجه، على سبيل المثال بعد زيارتك لشريك\n                        الموقع أو التطبيق.",
                                "title": "دعاية"
                            },
                            "authentication": {
                                "content": "إذا قمت بتسجيل الدخول إلى UgoZER، فإن ملفات تعريف الارتباط تساعدنا في إظهار المعلومات الصحيحة لك\n                        إضفاء الطابع الشخصي على تجربتك.",
                                "title": "المصادقة"
                            },
                            "security": {
                                "content": "نحن نستخدم ملفات تعريف الارتباط لتمكين ودعم ميزات الأمان الخاصة بنا، ولمساعدتنا في الكشف\n                        النشاط الضار والانتهاكات لدينا",
                                "cta": "الأحكام والشروط.",
                                "title": "حماية"
                            },
                            "services": {
                                "content": "تزودك ملفات تعريف الارتباط بالميزات والرؤى والمحتوى المخصص.",
                                "title": "التفضيلات والميزات والخدمات"
                            }
                        }
                    },
                    "title": "ما هي استخدامات ملفات تعريف الارتباط؟"
                }
            },
            "countries": {
                "AD": "أندورا",
                "AE": "الإمارات العربية المتحدة",
                "AF": "أفغانستان",
                "AG": "أنتيغوا وبربودا",
                "AI": "أنغيلا",
                "AL": "ألبانيا",
                "AM": "أرمينيا",
                "AO": "أنغولا",
                "AQ": "القارة القطبية الجنوبية",
                "AR": "الأرجنتين",
                "AS": "ساموا الأمريكية",
                "AT": "النمسا",
                "AU": "أستراليا",
                "AW": "أروبا",
                "AX": "جزر آلاند",
                "AZ": "أذربيجان",
                "BA": "البوسنة والهرسك",
                "BB": "بربادوس",
                "BD": "بنغلاديش",
                "BE": "بلجيكا",
                "BF": "بوركينا فاسو",
                "BG": "بلغاريا",
                "BH": "البحرين",
                "BI": "بوروندي",
                "BJ": "بنين",
                "BL": "سانت بارتيليمي",
                "BM": "برمودا",
                "BN": "بروناي",
                "BO": "بوليفيا",
                "BQ": "هولندا الكاريبية",
                "BR": "البرازيل",
                "BS": "جزر البهاما",
                "BT": "بوتان",
                "BV": "جزيرة بوفيه",
                "BW": "بوتسوانا",
                "BY": "بيلاروسيا",
                "BZ": "بليز",
                "CA": "كندا",
                "CC": "جزر كوكوس (كيلينغ).",
                "CD": "الكونغو - كينشاسا",
                "CF": "جمهورية افريقيا الوسطى",
                "CG": "الكونغو - برازافيل",
                "CH": "سويسرا",
                "CI": "كوت ديفوار",
                "CK": "جزر كوك",
                "CL": "شيلي",
                "CM": "الكاميرون",
                "CN": "الصين",
                "CO": "كولومبيا",
                "CR": "كوستا ريكا",
                "CU": "كوبا",
                "CV": "الرأس الأخضر",
                "CW": "كوراساو",
                "CX": "جزيرة عيد الميلاد",
                "CY": "قبرص",
                "CZ": "التشيك",
                "DE": "ألمانيا",
                "DJ": "جيبوتي",
                "DK": "الدنمارك",
                "DM": "دومينيكا",
                "DO": "جمهورية الدومينيكان",
                "DZ": "الجزائر",
                "EC": "الاكوادور",
                "EE": "إستونيا",
                "EG": "مصر",
                "EH": "الصحراء الغربية",
                "ER": "إريتريا",
                "ES": "إسبانيا",
                "ET": "أثيوبيا",
                "FI": "فنلندا",
                "FJ": "فيجي",
                "FK": "جزر فوكلاند",
                "FM": "ميكرونيزيا",
                "FO": "جزر فاروس",
                "FR": "فرنسا",
                "GA": "الجابون",
                "GB": "المملكة المتحدة",
                "GD": "غرينادا",
                "GE": "جورجيا",
                "GF": "غيانا الفرنسية",
                "GG": "غيرنسي",
                "GH": "غانا",
                "GI": "جبل طارق",
                "GL": "الأرض الخضراء",
                "GM": "غامبيا",
                "GN": "غينيا",
                "GP": "جوادلوب",
                "GQ": "غينيا الإستوائية",
                "GR": "اليونان",
                "GS": "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                "GT": "غواتيمالا",
                "GU": "غوام",
                "GW": "غينيا بيساو",
                "GY": "غيانا",
                "HK": "منطقة هونغ كونغ الإدارية الخاصة الصينية",
                "HM": "هيرد وجزر ماكدونالد",
                "HN": "هندوراس",
                "HR": "كرواتيا",
                "HT": "هايتي",
                "HU": "هنغاريا",
                "ID": "إندونيسيا",
                "IE": "أيرلندا",
                "IL": "إسرائيل",
                "IM": "جزيرة آيل أوف مان",
                "IN": "الهند",
                "IO": "إقليم المحيط البريطاني الهندي",
                "IQ": "العراق",
                "IR": "إيران",
                "IS": "أيسلندا",
                "IT": "إيطاليا",
                "JE": "جيرسي",
                "JM": "جامايكا",
                "JO": "الأردن",
                "JP": "اليابان",
                "KE": "كينيا",
                "KG": "قيرغيزستان",
                "KH": "كمبوديا",
                "KI": "كيريباتي",
                "KM": "جزر القمر",
                "KN": "سانت كيتس ونيفيس",
                "KP": "كوريا الشمالية",
                "KR": "كوريا الجنوبية",
                "KW": "الكويت",
                "KY": "جزر كايمان",
                "KZ": "كازاخستان",
                "LA": "لاوس",
                "LB": "لبنان",
                "LC": "شارع لوسيا",
                "LI": "ليختنشتاين",
                "LK": "سيريلانكا",
                "LR": "ليبيريا",
                "LS": "ليسوتو",
                "LT": "ليتوانيا",
                "LU": "لوكسمبورغ",
                "LV": "لاتفيا",
                "LY": "ليبيا",
                "MA": "المغرب",
                "MC": "موناكو",
                "MD": "مولدوفا",
                "ME": "الجبل الأسود",
                "MF": "سانت مارتن",
                "MG": "مدغشقر",
                "MH": "جزر مارشال",
                "MK": "مقدونيا الشمالية",
                "ML": "مالي",
                "MM": "ميانمار (بورما)",
                "MN": "منغوليا",
                "MO": "منطقة ماكاو الإدارية الخاصة في الصين",
                "MP": "جزر مريانا الشمالية",
                "MQ": "مارتينيك",
                "MR": "موريتانيا",
                "MS": "مونتسيرات",
                "MT": "مالطا",
                "MU": "موريشيوس",
                "MV": "جزر المالديف",
                "MW": "ملاوي",
                "MX": "المكسيك",
                "MY": "ماليزيا",
                "MZ": "موزمبيق",
                "NA": "ناميبيا",
                "NC": "كاليدونيا الجديدة",
                "NE": "النيجر",
                "NF": "جزيرة نورفولك",
                "NG": "نيجيريا",
                "NI": "نيكاراغوا",
                "NL": "هولندا",
                "NO": "النرويج",
                "NP": "نيبال",
                "NR": "ناورو",
                "NU": "نيوي",
                "NZ": "نيوزيلندا",
                "OM": "سلطنة عمان",
                "PA": "بنما",
                "PE": "بيرو",
                "PF": "بولينيزيا الفرنسية",
                "PG": "بابوا غينيا الجديدة",
                "PH": "فيلبيني",
                "PK": "باكستان",
                "PL": "بولندا",
                "PM": "سانت بيير وميكلون",
                "PN": "جزر بيتكيرن",
                "PR": "بورتوريكو",
                "PS": "الاراضي الفلسطينية",
                "PT": "البرتغال",
                "PW": "بالاو",
                "PY": "باراجواي",
                "QA": "دولة قطر",
                "RE": "جمع شمل",
                "RO": "رومانيا",
                "RS": "صربيا",
                "RU": "روسيا",
                "RW": "رواندا",
                "SA": "المملكة العربية السعودية",
                "SB": "جزر سليمان",
                "SC": "سيشيل",
                "SD": "السودان",
                "SE": "السويد",
                "SG": "سنغافورة",
                "SH": "سانت هيلانة",
                "SI": "سلوفينيا",
                "SJ": "سفالبارد وجان ماين",
                "SK": "سلوفاكيا",
                "SL": "سيرا ليون",
                "SM": "سان مارينو",
                "SN": "السنغال",
                "SO": "الصومال",
                "SR": "سورينام",
                "SS": "جنوب السودان",
                "ST": "ساو تومي وبرينسيبي",
                "SV": "السلفادور",
                "SX": "سينت مارتن",
                "SY": "سوريا",
                "SZ": "إيسواتيني",
                "TC": "جزر تركس وكايكوس",
                "TD": "تشاد",
                "TF": "المناطق الجنوبية لفرنسا",
                "TG": "توجو",
                "TH": "تايلاند",
                "TJ": "طاجيكستان",
                "TK": "توكيلاو",
                "TL": "تيمور الشرقية",
                "TM": "تركمانستان",
                "TN": "تونس",
                "TO": "تونغا",
                "TR": "ديك رومى",
                "TT": "ترينيداد وتوباجو",
                "TV": "توفالو",
                "TW": "تايوان",
                "TZ": "تنزانيا",
                "UA": "أوكرانيا",
                "UG": "أوغندا",
                "UM": "جزر الولايات المتحدة النائية",
                "US": "الولايات المتحدة",
                "UY": "أوروغواي",
                "UZ": "أوزبكستان",
                "VA": "مدينة الفاتيكان",
                "VC": "سانت فنسنت وجزر غرينادين",
                "VE": "فنزويلا",
                "VG": "جزر فيرجن البريطانية",
                "VI": "جزر فيرجن الأمريكية",
                "VN": "فيتنام",
                "VU": "فانواتو",
                "WF": "واليس وفوتونا",
                "WS": "ساموا",
                "XK": "كوسوفو",
                "YE": "اليمن",
                "YT": "مايوت",
                "ZA": "جنوب أفريقيا",
                "ZM": "زامبيا",
                "ZW": "زيمبابوي"
            },
            "credits": {
                "images": "اعتمادات الصور"
            },
            "csv": {
                "common": {
                    "generating": "جاري إنشاء ملف CSV ...",
                    "waiting": "انتظر من فضلك..."
                },
                "download": "تصدير CSV"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "المفضلة من الخبير المحلي الخاص بك"
                },
                "links": {
                    "closest_pois": "أقرب النقاط",
                    "excursions": "مسارات والمشي",
                    "shares": "تشارك"
                },
                "no_dashboard": {
                    "contact": {
                        "link": "اتصل بنا.",
                        "text": "إذا كنت تعتقد أن هذا خطأ، فلا تتردد في ذلك"
                    },
                    "content": "لم يتم إعداد أي لوحة تحكم لدورك أو أنك متصل كمسؤول.",
                    "title": "لا توجد لوحة تحكم متاحة"
                },
                "points_of_interest": {
                    "categories": "نقاط فئات الاهتمام"
                }
            },
            "documents": {
                "display": {
                    "description": "العرض بصيغة A6 مع ربط QRCode وشعار مؤسستك.",
                    "title": "العرض - بطاقة A6"
                },
                "qrcode": {
                    "description": "QRCode وحده يسمح للمسافر بالارتباط المباشر بمؤسستك.",
                    "filename": "رمز الاستجابة السريعة",
                    "title": "رابط QRCode"
                }
            },
            "easy_transac": {
                "account": {
                    "create": {
                        "button": "قم بإنشاء حساب EasyTransac",
                        "fail": "فشل الاتصال بـ EasyTransac، يرجى المحاولة مرة أخرى لاحقًا",
                        "success": "لقد تم إنشاء حساب EasyTransac الخاص بك بنجاح"
                    },
                    "show": {
                        "account_usage": "سيسمح حساب EasyTransac الخاص بك لمؤسستك بتلقي المدفوعات على UgoZER.",
                        "can_receive_payments": "يمكن لعملائك الآن إكرامية مؤسستك\n                من ملف تعريف UgoZER الخاص بالكونسيرج الخاص بك.",
                        "can_retrieve_payments": {
                            "description": "يجب عليك إكمال حساب EasyTransac الخاص بك بالمعلومات المطلوبة بالترتيب\n                    لاستخدام الأموال المتاحة في حسابك.",
                            "title": "حساب EasyTransac الخاص بك غير مكتمل"
                        },
                        "cannot_receive_payments": "لا يمكنك تلقي المدفوعات",
                        "credentials_sent_mail": "لقد تم إرسال بيانات اعتماد EasyTransac إليك عبر البريد الإلكتروني.",
                        "fee_percentage": "سيتم تطبيق رسوم على كل دفعة تتلقاها.",
                        "go_to_easy_transac": "الوصول إلى لوحة تحكم EasyTransac",
                        "must_subscribe": "يجب أن تكون مشتركًا لإنشاء حساب EasyTransac الخاص بك.",
                        "no_account_yet": "ليس لديك حساب EasyTransac حتى الآن.",
                        "should_complete_kyc": "يرجى إرسال المستندات التي طلبتها EasyTransac،\n                من أجل الحصول على المدفوعات."
                    }
                },
                "easy_transac": "EasyTransac"
            },
            "exceptions": {
                "default": "حدث خطأ",
                "error_texts": {
                    "403": {
                        "link": {
                            "title": "قد ترغب في الوصول إلى",
                            "to_app_label": "قسم المسافر؟",
                            "to_extranet_label": "القسم المهني؟"
                        },
                        "message": "ليس لديك حق الوصول إلى هذه الصفحة.",
                        "title": "أُووبس! الوصول ممنوع."
                    },
                    "credential_not_verified": {
                        "title": "لم يتم التحقق من بيانات الاعتماد الخاصة بك",
                        "verify_mail": "تلقي رابط التحقق لبريدي الإلكتروني",
                        "verify_phone": "تلقي رابط التحقق لرقم هاتفي"
                    },
                    "opening_hours": {
                        "invalid": "القيمة المحددة ليست مثيل OpeningHours."
                    },
                    "password_missing": {
                        "app": "يرجى تعيين كلمة المرور.",
                        "extranet": "يرجى تعيين كلمة مرور للوصول إلى التطبيق."
                    }
                },
                "spam_detected": "تم اكتشاف بريد عشوائي"
            },
            "excursions": {
                "destroy": {
                    "confirm": "هل تريد حقًا حذف خط سير الرحلة هذا: {excursion_name}؟",
                    "success": "تم حذف خط سير الرحلة"
                },
                "direction": {
                    "bicycling": "ركوب الدراجات",
                    "display_mode": "وضع العرض",
                    "driving": "القيادة",
                    "walking": "المشي"
                },
                "form": {
                    "all_points_of_interest": "جميع النقاط المثيرة للاهتمام",
                    "create_new_point_of_interest": "إنشاء نقطة اهتمام جديدة",
                    "existing_excursions": "مسارات الرحلة الموجودة",
                    "from_existing_excursions": "من خط سير الرحلة الحالي",
                    "order_hint": "يتوافق ترتيب النقاط المثيرة للاهتمام مع ترتيب الزيارة.",
                    "points_of_interest": "النقاط المثيرة للاهتمام",
                    "select_from_points_of_interest": "اختر من النقاط المثيرة للاهتمام",
                    "your_excursion": "خط سير الرحلة الخاص بك"
                },
                "index": {
                    "alert": {
                        "content": "يمكنك عرض الطرق فقط. إذا كنت تريد إنشاء خط سير الرحلة أو مشاركته، فيجب عليك تسجيل الدخول باستخدام\n                <strong>حساب بواب/موظف استقبال</strong>",
                        "title": "مسارات في التشاور فقط"
                    },
                    "no_results": "ليس لديك أي مسارات حتى الآن",
                    "no_results_here": "لا توجد مسارات متاحة في منطقة البحث الخاصة بك.",
                    "read_only": "التشاور فقط",
                    "search": "ابحث عن خط سير الرحلة",
                    "try_more_filters": "يمكنك توسيع نطاق البحث أو إنشاء خط سير الرحلة.",
                    "your_excursions": "مسارات الرحلات الخاصة بك"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "مسار الرحلة",
                            "pois": "النقاط المثيرة للاهتمام"
                        }
                    }
                },
                "search": {
                    "no_results": "لم يتم العثور على خط سير الرحلة"
                },
                "share": {
                    "contains_private": "يحتوي خط سير الرحلة هذا على نقاط اهتمام خاصة. ولا يجوز إدراجهم في الحصة.",
                    "heading": "شارك خط سير الرحلة هذا",
                    "select_which_contacts": "حدد جهات الاتصال التي تريد مشاركة خط سير الرحلة \"{excursion_title}\" معها",
                    "select_which_users": "حدد المستخدمين الذين تريد مشاركة خط سير الرحلة \"{excursion_title}\" معهم أو أدخل\n            عنوان بريد إلكتروني لدعوة مسافر جديد",
                    "select_which_users_alt": "أدخل عنوان البريد الإلكتروني للشخص الذي تريد مشاركة الملف معه\n            خط سير الرحلة \"{excursion_title}\".",
                    "success": "تمت مشاركة خط سير الرحلة!"
                },
                "store": {
                    "create": "إنشاء خط سير الرحلة",
                    "heading": "إنشاء خط سير الرحلة",
                    "success": "تم إنشاء خط سير الرحلة"
                },
                "update": {
                    "heading": "قم بتحديث خط سير الرحلة هذا",
                    "success": "تم تحديث خط سير الرحلة"
                }
            },
            "extranet": {
                "dashboard": {
                    "last_claims": {
                        "none": "ليس لديك أي مطالبات حتى الآن.",
                        "see_all": "رؤية جميع المطالبات الخاصة بك",
                        "see_index": "انتقل إلى صفحة المطالبات"
                    },
                    "last_payments": {
                        "none": "ليس لديك أي دفعات حتى الآن.",
                        "see_all": "رؤية جميع المدفوعات الخاصة بك",
                        "see_index": "انتقل إلى صفحة الدفع"
                    },
                    "last_reservations": {
                        "none": "ليس لديك أي تحفظات حتى الآن."
                    },
                    "last_traveller_folders": {
                        "none": "ليس لديك أي مسافر في رعايتك حتى الآن.",
                        "see_all": "رؤية كل ما تبذلونه من المسافرين",
                        "take_care": "رعاية المسافر"
                    },
                    "manager": {
                        "sections": {
                            "butlers_institutions": "حراس مؤسساتي",
                            "butlers_interactions": "الكونسيرج مع التفاعلات"
                        }
                    },
                    "sections": {
                        "last_claims": "آخر مطالباتك",
                        "last_payments": "دفعاتك الأخيرة",
                        "last_reservations": "تحفظاتك الأخيرة",
                        "last_travellers": "آخر المسافرين لديك",
                        "search_butler": "ابحث عن بواب",
                        "stats": "احصائيات"
                    },
                    "stats": {
                        "butlers": "الكونسيرج| الكونسيرج",
                        "employees": "الموظف|الموظفون",
                        "excursions": "خط سير الرحلة|خطوط سير الرحلة",
                        "folders": "المجلد|المجلدات",
                        "institutions": "مؤسسة مرتبطة|مؤسسات مرتبطة",
                        "processing_claims": "معالجة المطالبة|معالجة المطالبات",
                        "reservations": "الحجز|الحجوزات"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "دعوة موظف",
                        "new-hotelier": "إنشاء حساب استقبال",
                        "new-tourist-office": "إنشاء حساب مكتبي"
                    }
                },
                "excursions": {
                    "actions": {
                        "new": "إنشاء خط سير الرحلة"
                    },
                    "sections": {
                        "excursions": "مسارات"
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "فئات"
                    }
                },
                "filters": {
                    "closed": "إغلاق المرشحات",
                    "open": "افتح المرشحات"
                },
                "finances": {
                    "alerts": {
                        "easytransac": {
                            "cta": "الوصول إلى ملفي الشخصي لإنشاء حساب EasyTransac",
                            "description": "لم تقم بإنشاء حساب EasyTransac بعد! لذلك لا يمكنك تلقي المدفوعات.",
                            "title": "حساب EasyTransac غير موجود!"
                        }
                    },
                    "sections": {
                        "issued_payments": "المدفوعات الصادرة",
                        "received_by_institution_payments": "المدفوعات التي تتلقاها المؤسسات الخاصة بك",
                        "received_payments": "المدفوعات المستلمة"
                    }
                },
                "institution": {
                    "messages": {
                        "no_records": "لا توجد مؤسسة مرتبطة في الوقت الراهن"
                    },
                    "sections": {
                        "butler": {
                            "institution": "مؤسستي"
                        },
                        "manager": {
                            "institution": "مؤسساتي"
                        }
                    }
                },
                "navbar": {
                    "contact": "اتصل بنا",
                    "contacts": "جهات الاتصال",
                    "dashboard": "لوحة القيادة",
                    "excursions": "مسارات",
                    "faq": "التعليمات",
                    "finances": "الشؤون المالية",
                    "invitations": "الدعوات",
                    "my_employees": "موظفيني",
                    "my_institution": "مؤسستي",
                    "my_institutions": "مؤسساتي",
                    "points_of_interest": "النقاط المثيرة للاهتمام",
                    "travellers": "المسافرون",
                    "tutorials": "يبدأ",
                    "users": "المستخدمين"
                },
                "pages": {
                    "excursions": {
                        "edit": "تحرير خط سير الرحلة",
                        "new": "خط سير جديد"
                    },
                    "folders": {
                        "edit": "تحرير مجلد المسافر",
                        "new": "مجلد المسافر الجديد"
                    },
                    "institutions": {
                        "new": "ربط مؤسسة"
                    },
                    "pois": {
                        "edit": "تحرير نقطة الاهتمام",
                        "new": "نقطة اهتمام جديدة"
                    },
                    "profile": {
                        "edit": "تعديل الملف الشخصي"
                    },
                    "reservations": {
                        "edit": "تحرير الحجز",
                        "new": "حجز جديد"
                    }
                },
                "points_of_interest": {
                    "actions": {
                        "new": "إنشاء نقطة اهتمام"
                    },
                    "sections": {
                        "points_of_interest": "النقاط المثيرة للاهتمام"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "لا توجد أسئلة وأجوبة في الوقت الراهن"
                    }
                },
                "professional_section": "القسم المهني",
                "profile": {
                    "sections": {
                        "currency": "عملة",
                        "delete_account": "حذف الحساب",
                        "infos": "معلومات عامة",
                        "locale": "لغة",
                        "notification_settings": "إعدادات الإخطارات",
                        "payment": "قسط",
                        "privacy_settings": "إعدادات الخصوصية",
                        "shares": "تشارك"
                    }
                },
                "support_messages": {
                    "actions": {
                        "back": "العودة إلى طلب الدعم",
                        "new": "أضف رسالة جديدة"
                    },
                    "sections": {
                        "support_messages": "رسائل"
                    }
                },
                "support_requests": {
                    "actions": {
                        "back": "خلف",
                        "new": "إنشاء طلب دعم"
                    },
                    "sections": {
                        "support_requests": "طلبات الدعم"
                    }
                },
                "travellers": {
                    "buttons": {
                        "new_folder": "إضافة مجلد مسافر جديد"
                    },
                    "sections": {
                        "folders": "مجلدات المسافرين الخاصة بي"
                    }
                },
                "user_dropdown": {
                    "changelogs": "سجلات التغيير",
                    "cookies": "إدارة ملفات تعريف الارتباط",
                    "credits": "الاعتمادات",
                    "customers": "عملاء",
                    "partners": "الشركاء",
                    "privacy_policy": "سياسة الخصوصية",
                    "profile": "ملفي",
                    "sign_out": "خروج",
                    "subscription": "الاشتراك",
                    "terms_condition": "الأحكام والشروط"
                }
            },
            "form": {
                "file": {
                    "current_file": "الملف الفعلي: {name}"
                },
                "hints": {
                    "auth": {
                        "register": {
                            "email": "يجب ألا يكون عنوان البريد الإلكتروني هذا مرتبطًا بالفعل بحساب UgoZER",
                            "employee": {
                                "not_duplicate_email": "يجب أن يكون عنوان البريد الإلكتروني مختلفًا عن عنوان البريد الإلكتروني الخاص بالشركة",
                                "not_duplicate_password": "يجب أن تكون كلمة المرور مختلفة عن تلك الموجودة في"
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "مخصص: خط سير الرحلة الذي أنشأه الكونسيرج\n                        لمسافر معين",
                                "root": "قوالبي: خط سير الرحلة \"الجاهز للمشاركة\" الذي يتكون من نقاط الاهتمام التي يجب رؤيتها،\n                        أو النقاط المثيرة للاهتمام حسب الموضوع\n                        (مثال: قائمة المطاعم المدرجة بشكل متكرر في مسارات الرحلة)\n                        ليتم اختياره لإنشاء خط سير جديد.",
                                "ugozer_app": "تطبيق UgoZER: خط سير الرحلة الذي تم إنشاؤه واقتراحه بواسطة UgoZER\n                        وإتاحتها للمسافرين.",
                                "ugozer_pro": "UgoZER Pro: خط سير الرحلة الذي تم إنشاؤه واقتراحه بواسطة UgoZER\n                        وإتاحتها للمحترفين لتسهيل عملهم."
                            },
                            "pro": {
                                "customized": "خط سير الرحلة الذي تم إنشاؤه لمسافر معين",
                                "root": "خط سير الرحلة \"جاهز للمشاركة\" الذي يتكون من نقاط الاهتمام التي يجب مشاهدتها،\n                        أو النقاط المثيرة للاهتمام حسب الموضوع\n                        (مثال: قائمة المطاعم المدرجة بشكل متكرر في مسارات الرحلة)\n                        ليتم اختياره لإنشاء خط سير جديد.",
                                "ugozer": "خط سير الرحلة الذي تم إنشاؤه واقتراحه بواسطة UgoZER."
                            }
                        },
                        "points_of_interest_search": "ابحث عن نقطة الاهتمام وحددها لإضافتها إلى القائمة"
                    },
                    "excursions_filters": {
                        "radius": "سوف ترى فقط مسارات الرحلة التي تحتوي على نقطة اهتمام واحدة على الأقل تقع ضمن هذا النطاق."
                    },
                    "geoloc_setting": {
                        "filter_header": "ابحث عن بلد أو منطقة أو مدينة أو حي",
                        "kind": {
                            "admin": {
                                "around_me": "من حولي: سيتم تحديد الموقع الجغرافي\n                        عن طريق متصفح المستخدم",
                                "customized": "مخصص: سيتمكن المستخدم من البحث يدويًا عن مكان ما.",
                                "staying_hotel": "الإقامة في الفندق: سيتم تحديد الموقع الجغرافي\n                        من الفندق الذي يقيم فيه المسافر."
                            },
                            "app": {
                                "around_me": "سيتم تحديد الموقع الجغرافي من موقعك.",
                                "customized": "سيتم تحديد الموقع الجغرافي من الموقع الذي تختاره.",
                                "staying_hotel": "سيتم تحديد الموقع الجغرافي من الفندق الذي تقيم فيه: {title}."
                            }
                        }
                    },
                    "locale": {
                        "dynamic_translations_enabled": "يسمح للمستخدمين بعرض أو ترجمة الموارد\n                (النقاط المثيرة للاهتمام، مسارات...) بتلك اللغة",
                        "fallback_locale": "يعرض العنوان تلقائيًا باللغة الثانية (قبل الوصف).\n                ملف النقاط المهمة.",
                        "static_translations_enabled": "يسمح للمستخدمين باختيار هذه اللغة لعرض التطبيق\n                (يتطلب إضافة الترجمات إلى الكود المصدري)"
                    },
                    "permission": {
                        "name": "الاسم المستخدم في الكود المصدري"
                    },
                    "plan": {
                        "countries": "اتركه فارغًا لجعل الخطة متاحة في جميع البلدان",
                        "information": "معلومات إضافية عن الخطة (المتطلبات، الشروط...)",
                        "name": "سيكون هذا الاسم مرئيًا للمشتركين المحتملين\n                عندما يختارون اشتراكهم",
                        "private_title": "هذا العنوان يظهر فقط في الإدارة"
                    },
                    "plan_price": {
                        "description": "سيكون هذا الوصف مرئيًا للمشتركين المحتملين\n                عندما يختارون اشتراكهم",
                        "price": "الرجاء الدخول باليورو",
                        "private_title": "هذا العنوان يظهر فقط في الإدارة"
                    },
                    "point_of_interest": {
                        "audio_refreshing_1": "سيؤدي تمكين تحديث الملف الصوتي إلى إنشاء ملف صوتي تلقائيًا\n                باللغة محل الاهتمام ({language}).",
                        "audio_refreshing_2": "وسوف يذكر عنوان وعنوان نقطة الاهتمام.",
                        "audio_refreshing_3": "سيتم تحديثه بمجرد اللغة، العنوان\n                أو يتم تحديث عنوان نقطة الاهتمام.",
                        "audio_refreshing_4": "سيتم تعطيل التحديث إذا قمت بإضافة أو تسجيل ملف صوتي بنفسك،\n                أو إذا قمت بحذف الملف الصوتي الذي تم إنشاؤه.",
                        "cant_have_employees": "هنا ترى فقط المؤسسات المرتبطة بالفئات التي قد تكون موجودة\n                موظفين",
                        "expiry_date": "ستنتهي صلاحية نقطة اهتمامك في {date}",
                        "expiry_date_details_1": "نقطة اهتمامك هي جزء من فئة \"{category}\".",
                        "expiry_date_details_2": "وبالتالي ستنتهي صلاحيته في {date}، حيث أن هذا هو آخر تاريخ قمت بإدخاله.",
                        "expiry_date_details_3": "اعتبارًا من هذا التاريخ، سيكون أمام نقطة اهتمامك 21 يومًا متبقية\n                قبل أن يتم حذفه تلقائيا..",
                        "expiry_date_details_4": "يمكنك دائمًا إضافة تاريخ جديد\n                لتأجيل انتهاء الصلاحية، أو عدم تحديد تاريخ حتى لا تنتهي صلاحية نقطة اهتمامك.",
                        "images": "سيتم عرض الصور في معرض نقطة الاهتمام بالترتيب المختار،\n                يمكنك إعادة تنظيمها عن طريق سحبها.",
                        "is_geocoding": "قم بإلغاء التنشيط إذا تم إدخال الموقع الجغرافي بشكل صحيح ولكن الخريطة لا تزال غير موجودة.",
                        "line1": "يمكنك إدخال عنوان أو اسم المكان الذي تبحث عنه. اختر من\n                قائمة لملء العنوان تلقائيا",
                        "locale": "اللغة الرئيسية المستخدمة في هذه النقطة من الاهتمام",
                        "opening_dates": "اتركه فارغًا إذا كانت الجداول الزمنية تنطبق على أي تاريخ",
                        "opening_days": "لا تحدد أي شيء إذا كانت الجداول الزمنية تنطبق على أي يوم",
                        "opening_hours": "لا تضف أي شيء إذا كانت نقطة الاهتمام مفتوحة في أي وقت",
                        "sync_1": "يتيح لك تنشيط التحديث التلقائي استرداد البيانات من جهاز {import_source}\n                يستورد",
                        "sync_2": "سيتم الكتابة فوق الإدخالات يدويًا"
                    },
                    "points_of_interest_filters": {
                        "order_distance": "الرجاء اختيار مرشح المسافة لتحديد هذا الخيار",
                        "order_relevance": "الرجاء إدخال كلمات البحث الرئيسية لتحديد هذا الخيار",
                        "radius": "سوف ترى فقط النقاط المثيرة للاهتمام الموجودة داخل هذا النطاق."
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "butler": "بغض النظر عن الخيار الذي تختاره، اسمك الأخير واسمك الأول\n                    ستظل مرئية من قبل مدير مؤسستك",
                            "traveller": "بغض النظر عن الخيار الذي تختاره، اسمك الأخير واسمك الأول\n                  ستظل مرئية من قبل حراس الفندق الذي تقيم فيه."
                        }
                    },
                    "profile": {
                        "contact_email": "يمكن عرض هذا البريد الإلكتروني في ملفك الشخصي العام",
                        "contact_phone": "يمكن عرض هذا الهاتف في ملفك الشخصي العام",
                        "name": "اللقب عام ويسمح لمستخدمي التطبيق الآخرين بالتعرف عليك بسهولة أكبر.",
                        "password_template": {
                            "digit": "1 رقم",
                            "length": "أحرف ×1",
                            "lowercase": "1 حرف صغير",
                            "uppercase": "1 حرف كبير"
                        },
                        "password_unchanged": "اتركه فارغا ليبقى دون تغيير"
                    },
                    "role": {
                        "description": "يتم عرض وصف الدور للمستخدمين عندما يختارون الدور\n                سجل في القسم المهني .",
                        "name": "الاسم الرمزي للدور، لن يتم عرضه للمستخدمين."
                    },
                    "support_request": {
                        "support_categories_search": "بحث واختيار فئة"
                    },
                    "translatable": {
                        "pro_global": "يمكن ملء حقل واحد أو أكثر من هذا النموذج بلغات مختلفة.",
                        "pro_global_2": "سيتم ترجمة الحقول التي لم يتم ملؤها بلغات معينة تلقائيًا.",
                        "pro_global_title": "إدخال متعدد اللغات"
                    },
                    "traveller_folder": {
                        "expired_on": "افتراضيًا، يتم تعيين تاريخ الانتهاء خلال أسبوع"
                    },
                    "user": {
                        "bank_account_number": "أدخل رقم الحساب الذي سيتم استخدامه لإجراء التحويل"
                    }
                },
                "labels": {
                    "geoloc_setting": {
                        "distance_filter": "مرشح المسافة",
                        "limit_to_a_radius": "الحد إلى دائرة نصف قطرها",
                        "limit_to_a_radius_from_center": "الحد إلى نصف قطر من المركز",
                        "measure_unit": "وحدة قياس",
                        "other_filters": "مرشحات أخرى",
                        "where_does_the_traveller_go": "أين يذهب المسافر؟",
                        "where_would_you_like_to_go": "أين تريد أن تذهب؟",
                        "within_a_radius": "داخل دائرة نصف قطرها"
                    },
                    "payment": {
                        "payable_type": "الدفع مرتبط ب"
                    },
                    "plan": {
                        "authorized_roles": "الأدوار المصرح بها",
                        "tipping_fees": "رسوم البقشيش (%)"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "السعر باستثناء الضرائب"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "تحديث الملف الصوتي تلقائيا",
                        "opening": "افتتاح",
                        "opening_date": "على",
                        "opening_date_from": "من",
                        "opening_date_range": "تحديد تاريخ الانتهاء",
                        "opening_date_to": "ل",
                        "opening_hours": "في",
                        "opening_hours_from": "من",
                        "opening_hours_range": "تحديد ساعة النهاية",
                        "opening_hours_to": "ل",
                        "opening_schedule_add": "تحديد أيام أو ساعات",
                        "opening_schedule_add_alt": "تحديد ساعات",
                        "opening_specification_add": "أضف فترة"
                    },
                    "points_of_interest_filters": {
                        "geoloc": "مكان",
                        "order_distance": "عن طريق المسافة",
                        "order_relevance": "حسب الصلة (كلمات البحث الرئيسية)",
                        "sort": "نوع",
                        "tags": "صقل"
                    },
                    "register": {
                        "communication_consent": "أرغب في تلقي اتصالات شخصية، بما في ذلك العروض،\n                المعلومات الترويجية والمنتجات المتعلقة بالسفر من UgoZER عبر البريد الإلكتروني.",
                        "privacy_policy": {
                            "part1": "أنا أوافق على",
                            "part2": "سياسة الخصوصية"
                        },
                        "terms_conditions": {
                            "part1": "أنا أوافق على",
                            "part2": "الأحكام والشروط"
                        }
                    },
                    "support_category": {
                        "title": "اختر فئة"
                    },
                    "support_message": {
                        "content": "رسالة",
                        "email": "عنوان البريد الإلكتروني"
                    },
                    "support_request": {
                        "title": "موضوع الطلب"
                    },
                    "traveller_invitation": {
                        "locale": "اللغة التي يتحدث بها هذا المسافر"
                    },
                    "user": {
                        "credential": "بريد إلكتروني",
                        "email": "بريد إلكتروني",
                        "image": {
                            "_destroy": "حذف الصورة الشخصية"
                        },
                        "new_password": "كلمة المرور الجديدة",
                        "notes": "مزيد من المعلومات"
                    },
                    "vat_rate": {
                        "vat_rate": "معدل ضريبة القيمة المضافة (النسبة المئوية)"
                    }
                },
                "placeholders": {
                    "audio_file_name": "اسم الملف الصوتي",
                    "name": "كنية",
                    "support_message": {
                        "create_message": "رسالتك لطلب الدعم"
                    }
                },
                "select": {
                    "no_options": "لا يوجد خيارات",
                    "no_results": "عذرا، لا توجد خيارات مطابقة.",
                    "type_to_search": "يبحث..."
                },
                "titles": {
                    "verifications": "التحقق من المعلومات الخاصة بك"
                }
            },
            "front": {
                "access_pro": "انتقل إلى الوصول المهني",
                "app": "تطبيق المسافرين",
                "brand_name": "UgoZER",
                "captcha": {
                    "and": "و",
                    "apply": "يتقدم.",
                    "privacy_policy": "سياسة الخصوصية",
                    "protected": "هذا الموقع محمي بواسطة hCaptcha و",
                    "terms_service": "شروط الخدمة"
                },
                "contact": {
                    "cancel": "إلغاء طلب الدعم",
                    "contact": "اتصال",
                    "send": "يرسل"
                },
                "cookies": {
                    "title": "إدارة ملفات تعريف الارتباط"
                },
                "customers": {
                    "see_customers": "رؤية عملائنا",
                    "title": "إنهم يثقون بنا"
                },
                "footer": {
                    "account_deletion": "حذف الحساب",
                    "app": "تطبيق المسافرين",
                    "contact": "اتصال",
                    "cookies": "إدارة ملفات تعريف الارتباط",
                    "copyright": "© {year} UgoZER - جميع الحقوق محفوظة.",
                    "facebook": "فيسبوك",
                    "faq": "التعليمات",
                    "home": "بيت",
                    "instagram": "انستغرام",
                    "legal_notice": "إشعار قانوني",
                    "linkedin": "ينكدين",
                    "privacy_policy": "سياسة الخصوصية",
                    "pro": "الوصول المهني",
                    "sitemap": "خريطة الموقع",
                    "terms_conditions": "الأحكام والشروط",
                    "twitter": "تويتر"
                },
                "header": {
                    "app": "تطبيق المسافرون",
                    "contact": "اتصال",
                    "faq": "التعليمات",
                    "home": "بيت",
                    "pro": "الوصول المهني",
                    "you_are": "أنت"
                },
                "intro": {
                    "intro_1": "مع UgoZER، احتفظ دائمًا في هاتفك الذكي بعناوينك المفضلة في ملفات متعددة اللغات،\n            مسموعة أو مقروءة، بنقرة واحدة.",
                    "intro_2": "عندما تكون بالخارج، اجعل من السهل على سائقي سيارات الأجرة والأشخاص الآخرين فهم وجهتك.",
                    "intro_3": "موظف استقبال ومدير الفندق وتصميم وتقديم البرامج السياحية. شاركها مع عملائك\n            على أوغوزر.",
                    "intro_title_pro": "المهنيين",
                    "intro_title_traveller": "المسافر",
                    "title_homepage": "الدليل السياحي متعدد اللغات، مجانا."
                },
                "navigation": {
                    "next": "التالي",
                    "previous": "سابق"
                },
                "outro": {
                    "description": "السفر في 2.0"
                },
                "partners": {
                    "no_records": "لا يوجد شركاء في الوقت الراهن",
                    "read_more": "اقرأ أكثر",
                    "see_partners": "انظر شركائنا",
                    "title": "الشركاء"
                },
                "posts": {
                    "no_records": "لا توجد اي منشورات فى هذه اللحظه",
                    "title": "التعليمات."
                },
                "pro": "الوصول المهني",
                "professionals": {
                    "argument_1": {
                        "part_1": "يوفر UgoZER حلاً رقميًا لضيوفك.",
                        "part_2": "تعزيز إقامتهم وتجربة ضيوفهم.",
                        "part_3": "إنه يمكّن الفندق أو المنتجع أو مركز المعلومات الخاص بك من إنشاء معلومات شخصية وسهلة الاستخدام\n                رحلات متنقلة متعددة اللغات.",
                        "part_4": "شارك الرحلات المخصصة من قاعدة بيانات واحدة للنقاط المهمة في الوقت الفعلي من خلال منصة UgoZER الاحترافية\n                وتطبيق الهاتف المحمول الخاص به."
                    },
                    "argument_2": {
                        "part_1": "يمكنك اختيار مسارات الرحلات المعبأة مسبقًا لضيوفك.",
                        "part_2": "أنشئ موقعًا خاصًا بك مع أفضل الأماكن أو المطاعم في المدينة، والجواهر المخفية التي يمكنك اكتشافها، المثالية\n                مسارات المشي للاستمتاع...",
                        "part_3": "أو اختر كل خطوة من خطوات زيارتهم ضمن قائمة شاملة للنقاط المهمة لدمجهم فيها\n                سوف ثم مشاركة خط سير الرحلة الشخصية."
                    },
                    "argument_3": {
                        "part_1": "تحتوي كل نقطة اهتمام على وصف تفصيلي وموقعها على الخريطة التفاعلية وتفاصيلها\n                تتم ترجمة العنوان تلقائيًا باللغة المحلية.",
                        "part_2": "لن يضيع ضيفك أبدًا. سوف يجد دائمًا طريقه ويعود إليك بسهولة\n                الفندق."
                    },
                    "argument_4": {
                        "part_1": "في نهاية رحلته، وبعد الاستمتاع بخط سير الرحلة المصمم خصيصًا، سيكون ضيفك قادرًا على ذلك\n                اشكر الكونسيرج أو موظف الاستقبال على توصيته الفريدة من خلال إعطائه إكرامية.",
                        "part_2": "باستخدام UgoZER، سوف تقوم بتحسين معدل الاحتفاظ بالموظفين من خلال زيادة الإيرادات وتقليل عدد الموظفين\n                دوران.",
                        "part_3": "سوف تمنحهم دافعًا أكبر للمشاركة في الحياة اليومية لفندقك\n                ومنحهم الشعور بالانتماء."
                    },
                    "intro": {
                        "main_title": "UgoZER، مساعد السفر الرقمي والشخصي متعدد اللغات. خذ ضيفك\n                تجربة إلى المستوى التالي وزيادة الإيرادات الخاصة بك."
                    },
                    "outro": {
                        "catchphrase": "سوف يساعدك UgoZER على تعزيز الرضا ودفع النمو."
                    }
                },
                "startup": {
                    "description_1": "UgoZER هي شركة فرنسية مقرها في ستراسبورغ، عاصمة أوروبا. تم إنشاؤها عن طريق السفر\n            أيها الأحباء، فهو يسهل رحلاتكم إلى البلدان التي لا تتحدثون لغتها.",
                    "description_2": "موصى به من قبل المكاتب السياحية.",
                    "title": "بدء التشغيل UgoZER"
                },
                "support_request": "طلب دعم",
                "travellers": {
                    "argument_1": {
                        "arguments": {
                            "audio": "<strong>عنوان صوتي</strong> باللغة المحلية للاستماع إلى سيارة أجرة أو أحد المارة",
                            "desc": "<strong>وصف تفصيلي</strong>",
                            "languages": "تتم ترجمة جميع المعلومات إلى <strong>الفرنسية، الإنجليزية، الألمانية، الهولندية، البرتغالية،\n                    الإسبانية والإيطالية والكورية والصينية واليابانية والعربية.</strong>",
                            "map": "<strong>الموقع على خريطة تفاعلية، إمكانية الإخراج على Google Maps™</strong>"
                        },
                        "part_1": "قم بالوصول إلى جميع النقاط المهمة (POI) المحدثة في الوقت الفعلي على هاتفك الذكي.",
                        "part_2": "النقاط المهمة مصنفة حسب الفئات: الآثار، المتاحف، المعارض، المحلات التجارية، المطاعم،\n                الحانات والأحداث المؤقتة...</strong>",
                        "part_3": "النقاط المهمة المزودة بـ:"
                    },
                    "argument_2": {
                        "part_1": "استمتع برحلتك مع إمكانية الوصول الدائم إلى المعلومات الكاملة. كل ذلك على هاتفك الذكي،\n                الحق في جيبك."
                    },
                    "argument_3": {
                        "part_1": "مع UgoZER، احصل على مسارات رحلات مجانية تلقائيًا. يمكنك أيضًا إنشاء الرحلات ومشاركتها\n                لهم مع أصدقائك. حقق أقصى استفادة من رحلتك من خلال الحصول على جولات مخصصة من منطقتك المحلية\n                خبير: حارس الفندق أو موظف الاستقبال، مكتب المعلومات السياحية.",
                        "part_2": "إذا أعجبتك رحلتك، يمكنك أيضًا أن تشكر خبيرك المحلي من خلال خدمة رقمية وآمنة\n                خيار نصيحة."
                    },
                    "intro": {
                        "main_title": "دليلك السياحي المجاني متعدد اللغات"
                    },
                    "outro": {
                        "catchphrase": "مع UgoZER، اسمح لنفسك بالتوجيه بأمان والسفر براحة البال."
                    }
                },
                "you_are": {
                    "professional": "احترافي",
                    "traveller": "مسافر"
                },
                "youtube": {
                    "directed_by": "إخراج",
                    "producer": "استوديو سوبرهيد"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "اختر مؤسستك"
                    },
                    "sections": {
                        "pending_claims": "في انتظار التحقق من صحة",
                        "requests": "الطلبات المعلقة",
                        "validated_claims": "ادعى"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "تم إرسال الطلب"
                },
                "approve": {
                    "confirm": {
                        "employee": "هل تريد حقاً الموافقة على الطلب؟",
                        "employer": "هل تريد حقًا الموافقة على {user_name}؟"
                    },
                    "success": "تمت الموافقة على الطلب"
                },
                "cancel": {
                    "confirm": {
                        "employee": "هل تريد حقًا إلغاء هذا الطلب؟",
                        "employer": "هل تريد حقًا إلغاء طلب {user_name}؟"
                    },
                    "success": "تم إلغاء الطلب"
                },
                "decline": {
                    "confirm": {
                        "change_institution": "سيتم تغيير مؤسستك المرتبطة!",
                        "employee": "هل تريد حقًا رفض الطلب؟",
                        "employer": "هل تريد حقًا رفض {user_name}؟"
                    },
                    "success": "تم رفض الطلب"
                },
                "feature": {
                    "confirm": "هل تريد حقا أن تتميز {user_name}؟",
                    "folder": "بالنسبة للمسافر الذي لم يتفاعل مع أحد موظفيك، فهو المميز\n           الذي سيتم عرضه في مجلد المسافر.",
                    "only_one": "يمكن إبراز موظف واحد فقط.",
                    "success": "لقد تم تمييز الموظف"
                },
                "index": {
                    "my_employees": "موظفيني"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "حساب",
                        "invitation": "دعوة"
                    }
                },
                "invite": {
                    "invite": "دعوة موظف"
                },
                "quit": {
                    "confirm": "هل تريد حقًا إنهاء هذه المؤسسة {poi_name}؟",
                    "success": "لقد تركت المؤسسة"
                },
                "remove": {
                    "confirm": "هل تريد حقا إزالة {user_name}؟",
                    "success": "تمت إزالة الموظف"
                },
                "show": {
                    "pending": "طلبك للانضمام إلى هذه المؤسسة معلق حاليًا."
                },
                "states": {
                    "approved": "موافقة",
                    "canceled": "ألغيت",
                    "declined": "انخفض",
                    "invited": "مدعو",
                    "pending": "قيد الانتظار",
                    "removed": "إزالة"
                }
            },
            "interactions": {
                "description": {
                    "contact approved": "تمت الموافقة على طلب الاتصال بواسطة {sender_name}",
                    "contact blocked": "تم حظر طلب الاتصال بواسطة {sender_name}",
                    "contact created": "تم إنشاء طلب الاتصال بواسطة {sender_name}",
                    "contact declined": "تم رفض طلب الاتصال بواسطة {sender_name}",
                    "invitation accepted": "تم قبول الدعوة بواسطة {sender_name}",
                    "invitation created": "تم إرسال الدعوة بواسطة {sender_name}",
                    "payment succeeded": "أرسل {sender_name} دفعة إلى {recipient_name}",
                    "share created": "قام {sender_name} بمشاركة {share}",
                    "traveller_folder created": "تم إنشاء مجلد المسافر بواسطة {sender_name}",
                    "traveller_folder thanked": "لقد شكر {sender_name} المسافر على إقامته"
                },
                "share_type": {
                    "excursions": "خط سير الرحلة",
                    "pointsofinterest": "نقطة الاهتمام"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "يدعو"
                },
                "states": {
                    "authenticated_once": {
                        "false": "قيد الانتظار",
                        "true": "تم التحقق من صحتها"
                    }
                },
                "success": {
                    "professional": {
                        "sent_1": "سيتم إرسال بريد إلكتروني إلى {email}، إذا لم يكن هذا العنوان مستخدمًا بالفعل.",
                        "sent_2": "يحتوي هذا البريد الإلكتروني على رابط للتسجيل في القسم المهني.",
                        "title": "دعوة التي وجهت"
                    },
                    "traveller": {
                        "email_1": "سيتم إرسال بريد إلكتروني إلى {email}، إذا لم يكن هذا العنوان قيد الاستخدام بالفعل.",
                        "email_2": "يحتوي هذا البريد الإلكتروني على رابط يسمح لك بالاتصال بالتطبيق.",
                        "title": "دعوة التي وجهت"
                    }
                },
                "texts": {
                    "already_have_account": "هل لدى المستخدم حساب بالفعل على التطبيق؟",
                    "description": "يمكنك دعوة شخص ما للانضمام إلى تطبيقنا باستخدام عنوان بريد إلكتروني أو رقم هاتف.",
                    "employee_already_have_account": "هل لدى الموظف حساب بالفعل على التطبيق؟",
                    "geoloc_settings": {
                        "description": "سيتم ضبط إعدادات الموقع الجغرافي تلقائيًا على إحداثيات فندقك\n                (إذا كنت مرتبطًا بمؤسسة).",
                        "title": "إعدادات تحديد الموقع الجغرافي"
                    },
                    "invite_link": {
                        "instructions": "انسخ هذا الرابط لدعوة أحد المسافرين",
                        "title": "الدعوة عن طريق الرابط"
                    },
                    "invite_professional": "قم بدعوة محترف",
                    "invite_traveller": "دعوة مسافر",
                    "new_traveller": "مسافر جديد",
                    "professional_description": "يمكنك دعوة أحد المحترفين للانضمام إلى القسم الاحترافي في موقعنا\n            التطبيق مع البريد الإلكتروني",
                    "title": "قم بدعوة شخص ما"
                }
            },
            "locales": {
                "aa": "بعيد",
                "ab": "الأبخازية",
                "af": "الأفريكانية",
                "af_NA": "الأفريكانية (ناميبيا)",
                "af_ZA": "الأفريكانية (جنوب أفريقيا)",
                "ak": "اكان",
                "ak_GH": "أكان (غانا)",
                "am": "الأمهرية",
                "am_ET": "الأمهرية (إثيوبيا)",
                "an": "أراغون",
                "ar": "عربي",
                "ar_AE": "العربية (الإمارات العربية المتحدة)",
                "ar_BH": "العربية (البحرين)",
                "ar_DJ": "العربية (جيبوتي)",
                "ar_DZ": "العربية (الجزائر)",
                "ar_EG": "العربية (مصر)",
                "ar_EH": "العربية (الصحراء الغربية)",
                "ar_ER": "العربية (إريتريا)",
                "ar_IL": "العربية (إسرائيل)",
                "ar_IQ": "العربية (العراق)",
                "ar_JO": "العربية (الأردن)",
                "ar_KM": "العربية (جزر القمر)",
                "ar_KW": "العربية (الكويت)",
                "ar_LB": "العربية (لبنان)",
                "ar_LY": "العربية (ليبيا)",
                "ar_MA": "العربية (المغرب)",
                "ar_MR": "العربية (موريتانيا)",
                "ar_OM": "العربية (عُمان)",
                "ar_PS": "العربية (الأراضي الفلسطينية)",
                "ar_QA": "العربية (قطر)",
                "ar_SA": "العربية (المملكة العربية السعودية)",
                "ar_SD": "العربية (السودان)",
                "ar_SO": "العربية (الصومال)",
                "ar_SS": "العربية (جنوب السودان)",
                "ar_SY": "العربية (سوريا)",
                "ar_TD": "العربية (تشاد)",
                "ar_TN": "العربية (تونس)",
                "ar_YE": "العربية (اليمن)",
                "as": "الأسامية",
                "as_IN": "الأسامية (الهند)",
                "av": "افاريك",
                "ay": "ايمارا",
                "az": "أذربيجاني",
                "az_AZ": "الأذربيجانية (أذربيجان)",
                "az_Cyrl": "الأذربيجانية (السيريلية)",
                "az_Cyrl_AZ": "الأذربيجانية (السيريلية، أذربيجان)",
                "az_Latn": "الأذربيجانية (اللاتينية)",
                "az_Latn_AZ": "الأذربيجانية (اللاتينية، أذربيجان)",
                "ba": "بشكير",
                "be": "البيلاروسية",
                "be_BY": "البيلاروسية (بيلاروسيا)",
                "bg": "البلغارية",
                "bg_BG": "البلغارية (بلغاريا)",
                "bh": "البيهاري (لغات)",
                "bi": "بيسلاما",
                "bm": "بامبارا",
                "bm_Latn": "بامبارا (لاتينية)",
                "bm_Latn_ML": "بامبارا (لاتينية، مالي)",
                "bn": "البنغالية",
                "bn_BD": "البنغالية (بنغلاديش)",
                "bn_IN": "البنغالية (الهند)",
                "bo": "التبتية",
                "bo_CN": "التبتية (الصين)",
                "bo_IN": "التبتية (الهند)",
                "br": "بريتون",
                "br_FR": "بريتون (فرنسا)",
                "bs": "البوسنية",
                "bs_BA": "البوسنية (البوسنة والهرسك)",
                "bs_Cyrl": "البوسنية (السيريلية)",
                "bs_Cyrl_BA": "البوسنية (السيريلية، البوسنة والهرسك)",
                "bs_Latn": "البوسنية (اللاتينية)",
                "bs_Latn_BA": "البوسنية (اللاتينية، البوسنة والهرسك)",
                "ca": "الكاتالونية",
                "ca_AD": "الكاتالونية (أندورا)",
                "ca_ES": "الكتالونية (إسبانيا)",
                "ca_FR": "الكاتالونية (فرنسا)",
                "ca_IT": "الكتالونية (إيطاليا)",
                "ce": "الشيشانية",
                "ch": "تشامورو",
                "co": "كورسيكية",
                "cr": "كري",
                "cs": "التشيكية",
                "cs_CZ": "التشيكية (الجمهورية التشيكية)",
                "cu": "البلغارية القديمة",
                "cv": "تشوفاش",
                "cy": "تهرب من دفع الرهان",
                "cy_GB": "الويلزية (المملكة المتحدة)",
                "da": "دانماركي",
                "da_DK": "الدنماركية (الدنمارك)",
                "da_GL": "الدنماركية (جرينلاند)",
                "de": "ألمانية",
                "de_AT": "الألمانية (النمسا)",
                "de_BE": "الألمانية (بلجيكا)",
                "de_CH": "الألمانية (سويسرا)",
                "de_DE": "الألمانية ألمانيا)",
                "de_LI": "الألمانية (ليختنشتاين)",
                "de_LU": "الألمانية (لوكسمبورغ)",
                "dv": "المالديفية",
                "dz": "دزونغخا",
                "dz_BT": "دزونغخا (بوتان)",
                "ee": "نعجة",
                "ee_GH": "إيوي (غانا)",
                "ee_TG": "إيوي (توغو)",
                "el": "اليونانية",
                "el_CY": "اليونانية (قبرص)",
                "el_GR": "اليونانية (اليونان)",
                "en": "إنجليزي",
                "en_AG": "الإنجليزية (أنتيغوا وبربودا)",
                "en_AI": "الإنجليزية (أنغيلا)",
                "en_AS": "الإنجليزية (ساموا الأمريكية)",
                "en_AU": "الإنجليزية (أستراليا)",
                "en_BB": "الإنجليزية (بربادوس)",
                "en_BE": "الإنجليزية (بلجيكا)",
                "en_BM": "الإنجليزية (برمودا)",
                "en_BS": "الإنجليزية (جزر البهاما)",
                "en_BW": "الإنجليزية (بوتسوانا)",
                "en_BZ": "الإنجليزية (بليز)",
                "en_CA": "الإنجليزية (كندا)",
                "en_CC": "الإنجليزية (جزر كوكوس (كيلينغ))",
                "en_CK": "الإنجليزية (جزر كوك)",
                "en_CM": "الإنجليزية (الكاميرون)",
                "en_CX": "الإنجليزية (جزيرة الكريسماس)",
                "en_DG": "الإنجليزية (دييغو جارسيا)",
                "en_DM": "الإنجليزية (دومينيكا)",
                "en_ER": "الإنجليزية (إريتريا)",
                "en_FJ": "الإنجليزية (فيجي)",
                "en_FK": "الإنجليزية (جزر فوكلاند)",
                "en_FM": "الإنجليزية (ميكرونيزيا)",
                "en_GB": "الإنجليزية (المملكة المتحدة)",
                "en_GD": "الإنجليزية (غرينادا)",
                "en_GG": "الإنجليزية (غيرنسي)",
                "en_GH": "الإنجليزية (غانا)",
                "en_GI": "الإنجليزية (جبل طارق)",
                "en_GM": "الإنجليزية (غامبيا)",
                "en_GU": "الإنجليزية (غوام)",
                "en_GY": "الإنجليزية (غيانا)",
                "en_HK": "الإنجليزية (منطقة هونج كونج الإدارية الخاصة الصينية)",
                "en_IE": "الإنجليزية (أيرلندا)",
                "en_IM": "الإنجليزية (جزيرة مان)",
                "en_IN": "الإنجليزية (الهند)",
                "en_IO": "الإنجليزية (إقليم المحيط الهندي البريطاني)",
                "en_JE": "الإنجليزية (جيرسي)",
                "en_JM": "الإنجليزية (جامايكا)",
                "en_KE": "الإنجليزية (كينيا)",
                "en_KI": "الإنجليزية (كيريباتي)",
                "en_KN": "الإنجليزية (سانت كيتس ونيفيس)",
                "en_KY": "الإنجليزية (جزر كايمان)",
                "en_LC": "الإنجليزية (سانت لوسيا)",
                "en_LR": "الإنجليزية (ليبيريا)",
                "en_LS": "الإنجليزية (ليسوتو)",
                "en_MG": "الإنجليزية (مدغشقر)",
                "en_MH": "الإنجليزية (جزر مارشال)",
                "en_MO": "الإنجليزية (منطقة ماكاو الإدارية الخاصة بالصين)",
                "en_MP": "الإنجليزية (جزر ماريانا الشمالية)",
                "en_MS": "الإنجليزية (مونتسيرات)",
                "en_MT": "الإنجليزية (مالطا)",
                "en_MU": "الإنجليزية (موريشيوس)",
                "en_MW": "الإنجليزية (ملاوي)",
                "en_MY": "الإنجليزية (ماليزيا)",
                "en_NA": "الإنجليزية (ناميبيا)",
                "en_NF": "الإنجليزية (جزيرة نورفولك)",
                "en_NG": "الإنجليزية (نيجيريا)",
                "en_NR": "الإنجليزية (ناورو)",
                "en_NU": "الإنجليزية (نييوي)",
                "en_NZ": "الإنجليزية (نيوزيلندا)",
                "en_PG": "الإنجليزية (بابوا غينيا الجديدة)",
                "en_PH": "الإنجليزية (الفلبين)",
                "en_PK": "الإنجليزية (باكستان)",
                "en_PN": "الإنجليزية (جزر بيتكيرن)",
                "en_PR": "الإنجليزية (بورتوريكو)",
                "en_PW": "الإنجليزية (بالاو)",
                "en_RW": "الإنجليزية (رواندا)",
                "en_SB": "الإنجليزية (جزر سليمان)",
                "en_SC": "الإنجليزية (سيشيل)",
                "en_SD": "الإنجليزية (السودان)",
                "en_SG": "الإنجليزية (سنغافورة)",
                "en_SH": "الإنجليزية (سانت هيلانة)",
                "en_SL": "الإنجليزية (سيراليون)",
                "en_SS": "الإنجليزية (جنوب السودان)",
                "en_SX": "الإنجليزية (سينت مارتن)",
                "en_SZ": "الإنجليزية (سوازيلاند)",
                "en_TC": "الإنجليزية (جزر تركس وكايكوس)",
                "en_TK": "الإنجليزية (توكيلاو)",
                "en_TO": "الإنجليزية (تونغا)",
                "en_TT": "الإنجليزية (ترينيداد وتوباغو)",
                "en_TV": "الإنجليزية (توفالو)",
                "en_TZ": "الإنجليزية (تنزانيا)",
                "en_UG": "الإنجليزية (أوغندا)",
                "en_UM": "الإنجليزية (الجزر الأمريكية النائية)",
                "en_US": "انجليزية الولايات المتحدة)",
                "en_VC": "الإنجليزية (سانت فنسنت وجزر غرينادين)",
                "en_VG": "الإنجليزية (جزر فيرجن البريطانية)",
                "en_VI": "الإنجليزية (جزر فيرجن الأمريكية)",
                "en_VU": "الإنجليزية (فانواتو)",
                "en_WS": "الإنجليزية (ساموا)",
                "en_ZA": "الإنجليزية (جنوب أفريقيا)",
                "en_ZM": "الإنجليزية (زامبيا)",
                "en_ZW": "الإنجليزية (زيمبابوي)",
                "eo": "الاسبرانتو",
                "es": "الأسبانية",
                "es_AR": "الإسبانية (الأرجنتين)",
                "es_BO": "الإسبانية (بوليفيا)",
                "es_CL": "الإسبانية (تشيلي)",
                "es_CO": "الإسبانية (كولومبيا)",
                "es_CR": "الإسبانية (كوستاريكا)",
                "es_CU": "الإسبانية (كوبا)",
                "es_DO": "الإسبانية (جمهورية الدومينيكان)",
                "es_EA": "الإسبانية (سبتة ومليلية)",
                "es_EC": "الإسبانية (الإكوادور)",
                "es_ES": "الإسبانية (إسبانيا)",
                "es_GQ": "الإسبانية (غينيا الاستوائية)",
                "es_GT": "الإسبانية (غواتيمالا)",
                "es_HN": "الإسبانية (هندوراس)",
                "es_IC": "الإسبانية (جزر الكناري)",
                "es_MX": "الإسبانية (المكسيك)",
                "es_NI": "الإسبانية (نيكاراغوا)",
                "es_PA": "الإسبانية (بنما)",
                "es_PE": "الإسبانية (بيرو)",
                "es_PH": "الإسبانية (الفلبين)",
                "es_PR": "الإسبانية (بورتوريكو)",
                "es_PY": "الإسبانية (باراجواي)",
                "es_SV": "الإسبانية (السلفادور)",
                "es_US": "الإسبانية (الولايات المتحدة)",
                "es_UY": "الإسبانية (أوروغواي)",
                "es_VE": "الإسبانية (فنزويلا)",
                "et": "الإستونية",
                "et_EE": "الإستونية (إستونيا)",
                "eu": "الباسك",
                "eu_ES": "الباسك (إسبانيا)",
                "fa": "اللغة الفارسية",
                "fa_AF": "الفارسية (أفغانستان)",
                "fa_IR": "الفارسية (ايران)",
                "ff": "فولا",
                "ff_CM": "فولاه (الكاميرون)",
                "ff_GN": "فولاه (غينيا)",
                "ff_MR": "فولة (موريتانيا)",
                "ff_SN": "فولاه (السنغال)",
                "fi": "الفنلندية",
                "fi_FI": "الفنلندية (فنلندا)",
                "fj": "فيجي",
                "fo": "جزر فارو",
                "fo_FO": "جزر فارو (جزر فارو)",
                "fr": "فرنسي",
                "fr_BE": "الفرنسية (بلجيكا)",
                "fr_BF": "الفرنسية (بوركينا فاسو)",
                "fr_BI": "الفرنسية (بوروندي)",
                "fr_BJ": "الفرنسية (بنين)",
                "fr_BL": "الفرنسية (سانت بارتيليمي)",
                "fr_CA": "الفرنسية (كندا)",
                "fr_CD": "الفرنسية (الكونغو - كينشاسا)",
                "fr_CF": "الفرنسية (جمهورية أفريقيا الوسطى)",
                "fr_CG": "الفرنسية (الكونغو - برازافيل)",
                "fr_CH": "الفرنسية (سويسرا)",
                "fr_CI": "الفرنسية (كوت ديفوار)",
                "fr_CM": "الفرنسية (الكاميرون)",
                "fr_DJ": "الفرنسية (جيبوتي)",
                "fr_DZ": "الفرنسية (الجزائر)",
                "fr_FR": "فرنسا الفرنسية)",
                "fr_GA": "الفرنسية (الجابون)",
                "fr_GF": "الفرنسية (غويانا الفرنسية)",
                "fr_GN": "الفرنسية (غينيا)",
                "fr_GP": "الفرنسية (جوادلوب)",
                "fr_GQ": "الفرنسية (غينيا الاستوائية)",
                "fr_HT": "الفرنسية (هايتي)",
                "fr_KM": "الفرنسية (جزر القمر)",
                "fr_LU": "الفرنسية (لوكسمبورغ)",
                "fr_MA": "الفرنسية (المغرب)",
                "fr_MC": "الفرنسية (موناكو)",
                "fr_MF": "الفرنسية (سانت مارتن)",
                "fr_MG": "الفرنسية (مدغشقر)",
                "fr_ML": "الفرنسية (مالي)",
                "fr_MQ": "الفرنسية (المارتينيك)",
                "fr_MR": "الفرنسية (موريتانيا)",
                "fr_MU": "الفرنسية (موريشيوس)",
                "fr_NC": "الفرنسية (كاليدونيا الجديدة)",
                "fr_NE": "الفرنسية (النيجر)",
                "fr_PF": "الفرنسية (بولينيزيا الفرنسية)",
                "fr_PM": "الفرنسية (سان بيير وميكلون)",
                "fr_RE": "الفرنسية (ريونيون)",
                "fr_RW": "الفرنسية (رواندا)",
                "fr_SC": "الفرنسية (سيشيل)",
                "fr_SN": "الفرنسية (السنغال)",
                "fr_SY": "الفرنسية (سوريا)",
                "fr_TD": "الفرنسية (تشاد)",
                "fr_TG": "الفرنسية (توغو)",
                "fr_TN": "الفرنسية (تونس)",
                "fr_VU": "الفرنسية (فانواتو)",
                "fr_WF": "الفرنسية (واليس وفوتونا)",
                "fr_YT": "الفرنسية (مايوت)",
                "fy": "الفريزية الغربية",
                "fy_NL": "الفريزية الغربية (هولندا)",
                "ga": "الأيرلندية",
                "ga_IE": "الأيرلندية (أيرلندا)",
                "gd": "الغيلية الاسكتلندية",
                "gd_GB": "الغيلية الاسكتلندية (المملكة المتحدة)",
                "gl": "الجاليكية",
                "gl_ES": "الجاليكية (إسبانيا)",
                "gn": "الغواراني",
                "gu": "الغوجاراتية",
                "gu_IN": "الغوجاراتية (الهند)",
                "gv": "مانكس",
                "gv_IM": "مانكس (جزيرة مان)",
                "ha": "الهوسا",
                "ha_GH": "الهوسا (غانا)",
                "ha_Latn": "الهوسا (اللاتينية)",
                "ha_Latn_GH": "الهوسا (اللاتينية، غانا)",
                "ha_Latn_NE": "الهوسا (اللاتينية، النيجر)",
                "ha_Latn_NG": "الهوسا (اللاتينية، نيجيريا)",
                "ha_NE": "الهوسا (النيجر)",
                "ha_NG": "الهوسا (نيجيريا)",
                "he": "اللغة العبرية",
                "he_IL": "العبرية (إسرائيل)",
                "hi": "الهندية",
                "hi_IN": "الهندية (الهند)",
                "ho": "هيري موتو",
                "hr": "الكرواتية",
                "hr_BA": "الكرواتية (البوسنة والهرسك)",
                "hr_HR": "الكرواتية (كرواتيا)",
                "ht": "هايتي",
                "hu": "المجرية",
                "hu_HU": "المجرية (المجر)",
                "hy": "الأرمينية",
                "hy_AM": "الأرمينية (أرمينيا)",
                "hz": "هيريرو",
                "ia": "إنترلينغوا",
                "id": "الاندونيسية",
                "id_ID": "الإندونيسية (إندونيسيا)",
                "ie": "لغة مشتركة",
                "ig": "الإيغبو",
                "ig_NG": "الإيغبو (نيجيريا)",
                "ii": "سيتشوان يي",
                "ii_CN": "سيتشوان يي (الصين)",
                "ik": "اينوبياك",
                "io": "أفعل",
                "is": "الأيسلندية",
                "is_IS": "الأيسلندية (أيسلندا)",
                "it": "ايطالي",
                "it_CH": "الإيطالية (سويسرا)",
                "it_IT": "الإيطالية (إيطاليا)",
                "it_SM": "الإيطالية (سان مارينو)",
                "iu": "إنكتيتوت",
                "ja": "اليابانية",
                "ja_JP": "اليابانية (اليابان)",
                "jv": "الجاوية",
                "ka": "الجورجية",
                "ka_GE": "الجورجية (جورجيا)",
                "kg": "الكونغو",
                "ki": "كيكويو",
                "ki_KE": "كيكويو (كينيا)",
                "kj": "كوانياما",
                "kk": "الكازاخستانية",
                "kk_Cyrl": "الكازاخستانية (السيريلية)",
                "kk_Cyrl_KZ": "الكازاخستانية (السيريلية، كازاخستان)",
                "kk_KZ": "كازاخستان (كازاخستان)",
                "kl": "كالاليسوت",
                "kl_GL": "كالاليسوت (جرينلاند)",
                "km": "الخمير",
                "km_KH": "الخمير (كمبوديا)",
                "kn": "الكانادا",
                "kn_IN": "الكانادا (الهند)",
                "ko": "الكورية",
                "ko_KP": "الكورية (كوريا الشمالية)",
                "ko_KR": "الكورية (كوريا الجنوبية)",
                "kr": "كانوري",
                "ks": "الكشميري",
                "ks_Arab": "الكشميرية (العربية)",
                "ks_Arab_IN": "الكشميرية (العربية، الهند)",
                "ks_IN": "الكشميري (الهند)",
                "ku": "كردي",
                "kv": "كومي",
                "kw": "الكورنيش",
                "kw_GB": "الكورنيش (المملكة المتحدة)",
                "ky": "قيرغيزستان",
                "ky_Cyrl": "القيرغيزية (السيريلية)",
                "ky_Cyrl_KG": "قيرغيزستان (السيريلية، قيرغيزستان)",
                "ky_KG": "قيرغيزستان (قيرغيزستان)",
                "la": "اللاتينية",
                "lb": "اللوكسمبرجية",
                "lb_LU": "اللوكسمبرجية (لوكسمبورغ)",
                "lg": "غاندا",
                "lg_UG": "غاندا (أوغندا)",
                "li": "ليمبورغان",
                "ln": "اللينجالا",
                "ln_AO": "اللينغالا (أنغولا)",
                "ln_CD": "اللينغالا (الكونغو - كينشاسا)",
                "ln_CF": "لينغالا (جمهورية أفريقيا الوسطى)",
                "ln_CG": "اللينغالا (الكونغو - برازافيل)",
                "lo": "لاو",
                "lo_LA": "لاو (لاوس)",
                "lt": "الليتوانية",
                "lt_LT": "الليتوانية (ليتوانيا)",
                "lu": "لوبا كاتانغا",
                "lu_CD": "لوبا-كاتانغا (الكونغو - كينشاسا)",
                "lv": "لاتفيا",
                "lv_LV": "اللاتفية (لاتفيا)",
                "mg": "مدغشقر",
                "mg_MG": "مدغشقر (مدغشقر)",
                "mh": "المارشالية",
                "mi": "الماوري",
                "mk": "المقدونية",
                "mk_MK": "المقدونية (مقدونيا)",
                "ml": "المالايالامية",
                "ml_IN": "المالايالامية (الهند)",
                "mn": "المنغولية",
                "mn_Cyrl": "المنغولية (السيريلية)",
                "mn_Cyrl_MN": "المنغولية (السيريلية، منغوليا)",
                "mn_MN": "المنغولية (منغوليا)",
                "mo": "ماكاو",
                "mr": "المهاراتية",
                "mr_IN": "المهاراتية (الهند)",
                "ms": "لغة الملايو",
                "ms_BN": "الماليزية (بروناي)",
                "ms_Latn": "الماليزية (اللاتينية)",
                "ms_Latn_BN": "الماليزية (اللاتينية، بروناي)",
                "ms_Latn_MY": "الماليزية (اللاتينية، ماليزيا)",
                "ms_Latn_SG": "الماليزية (اللاتينية، سنغافورة)",
                "ms_MY": "الماليزية (ماليزيا)",
                "ms_SG": "الماليزية (سنغافورة)",
                "mt": "المالطية",
                "mt_MT": "المالطية (مالطا)",
                "my": "البورمية",
                "my_MM": "البورمية (ميانمار (بورما))",
                "na": "ناورو",
                "nb": "البوكمال النرويجية",
                "nb_NO": "النرويجية بوكمال (النرويج)",
                "nb_SJ": "البوكمال النرويجية (سفالبارد وجان ماين)",
                "nd": "شمال نديبيلي",
                "nd_ZW": "شمال نديبيلي (زيمبابوي)",
                "ne": "النيبالية",
                "ne_IN": "النيبالية (الهند)",
                "ne_NP": "النيبالية (نيبال)",
                "ng": "ندونجا",
                "nl": "هولندي",
                "nl_AW": "الهولندية (أروبا)",
                "nl_BE": "الهولندية (بلجيكا)",
                "nl_BQ": "الهولندية (هولندا الكاريبية)",
                "nl_CW": "الهولندية (كوراساو)",
                "nl_NL": "الهولندية (هولندا)",
                "nl_SR": "الهولندية (سورينام)",
                "nl_SX": "الهولندية (سينت مارتن)",
                "nn": "نينورسك النرويجي",
                "nn_NO": "نينورسك النرويجي (النرويج)",
                "no": "النرويجية",
                "no_NO": "النرويجية (النرويج)",
                "nr": "جنوب نديبيلي",
                "nv": "نافاهو",
                "ny": "تشيشيوا",
                "oc": "الأوكيتانية",
                "oj": "اوجيبوا",
                "om": "الأورومو",
                "om_ET": "الأورومو (إثيوبيا)",
                "om_KE": "الأورومو (كينيا)",
                "or": "أوريا",
                "or_IN": "الأوريا (الهند)",
                "os": "أوسيتيك",
                "os_GE": "أوسيتيك (جورجيا)",
                "os_RU": "أوسيتيك (روسيا)",
                "pa": "البنجابية",
                "pa_Arab": "البنجابية (العربية)",
                "pa_Arab_PK": "البنجابية (العربية، باكستان)",
                "pa_Guru": "البنجابية (الغورموخية)",
                "pa_Guru_IN": "البنجابية (الغورموخية، الهند)",
                "pa_IN": "البنجابية (الهند)",
                "pa_PK": "البنجابية (باكستان)",
                "pi": "بالي",
                "pl": "تلميع",
                "pl_PL": "البولندية (بولندا)",
                "ps": "الباشتو",
                "ps_AF": "الباشتو (أفغانستان)",
                "pt": "البرتغالية",
                "pt_AO": "البرتغالية (أنجولا)",
                "pt_BR": "البرتغالية (البرازيل).",
                "pt_CV": "البرتغالية (الرأس الأخضر)",
                "pt_GW": "البرتغالية (غينيا بيساو)",
                "pt_MO": "البرتغالية (منطقة ماكاو الإدارية الخاصة الصينية)",
                "pt_MZ": "البرتغالية (موزمبيق)",
                "pt_PT": "البرتغالية (البرتغال)",
                "pt_ST": "البرتغالية (ساو تومي وبرينسيبي)",
                "pt_TL": "البرتغالية (تيمور الشرقية)",
                "qu": "الكيشوا",
                "qu_BO": "الكيشوا (بوليفيا)",
                "qu_EC": "الكيشوا (الإكوادور)",
                "qu_PE": "الكيشوا (بيرو)",
                "rm": "الرومانشية",
                "rm_CH": "الرومانشية (سويسرا)",
                "rn": "روندي",
                "rn_BI": "روندي (بوروندي)",
                "ro": "روماني",
                "ro_MD": "الرومانية (مولدوفا)",
                "ro_RO": "الرومانية (رومانيا)",
                "ru": "الروسية",
                "ru_BY": "الروسية (بيلاروسيا)",
                "ru_KG": "الروسية (قيرغيزستان)",
                "ru_KZ": "الروسية (كازاخستان)",
                "ru_MD": "الروسية (مولدوفا)",
                "ru_RU": "الروسية (روسيا)",
                "ru_UA": "الروسية (أوكرانيا)",
                "rw": "الكينيارواندية",
                "rw_RW": "كينيارواندا (رواندا)",
                "sa": "السنسكريتية",
                "sc": "سردينيا",
                "sd": "السندية",
                "se": "سامي الشمالي",
                "se_FI": "سامي الشمالية (فنلندا)",
                "se_NO": "سامي الشمالية (النرويج)",
                "se_SE": "سامي الشمالية (السويد)",
                "sg": "سانجو",
                "sg_CF": "سانغو (جمهورية أفريقيا الوسطى)",
                "sh": "الصربية الكرواتية",
                "sh_BA": "الصربية الكرواتية (البوسنة والهرسك)",
                "si": "السنهالية",
                "si_LK": "السنهالية (سريلانكا)",
                "sk": "السلوفاكية",
                "sk_SK": "السلوفاكية (سلوفاكيا)",
                "sl": "السلوفينية",
                "sl_SI": "السلوفينية (سلوفينيا)",
                "sm": "ساموا",
                "sn": "شونا",
                "sn_ZW": "شونا (زيمبابوي)",
                "so": "الصومالية",
                "so_DJ": "الصومالية (جيبوتي)",
                "so_ET": "الصومالية (إثيوبيا)",
                "so_KE": "الصومالية (كينيا)",
                "so_SO": "الصومال (الصومال)",
                "sq": "الألبانية",
                "sq_AL": "الألبانية (ألبانيا)",
                "sq_MK": "الألبانية (مقدونيا)",
                "sq_XK": "الألبانية (كوسوفو)",
                "sr": "الصربية",
                "sr_BA": "الصربية (البوسنة والهرسك)",
                "sr_Cyrl": "الصربية (السيريلية)",
                "sr_Cyrl_BA": "الصربية (السيريلية، البوسنة والهرسك)",
                "sr_Cyrl_ME": "الصربية (السيريلية، الجبل الأسود)",
                "sr_Cyrl_RS": "الصربية (السيريلية، صربيا)",
                "sr_Cyrl_XK": "الصربية (السيريلية، كوسوفو)",
                "sr_Latn": "الصربية (اللاتينية)",
                "sr_Latn_BA": "الصربية (اللاتينية، البوسنة والهرسك)",
                "sr_Latn_ME": "الصربية (اللاتينية، الجبل الأسود)",
                "sr_Latn_RS": "الصربية (اللاتينية، صربيا)",
                "sr_Latn_XK": "الصربية (اللاتينية، كوسوفو)",
                "sr_ME": "الصربية (الجبل الأسود)",
                "sr_RS": "الصربية (صربيا)",
                "sr_XK": "الصربية (كوسوفو)",
                "ss": "سواتي",
                "st": "سوتو، جنوب",
                "su": "السودانية",
                "sv": "السويدية",
                "sv_AX": "السويدية (جزر آلاند)",
                "sv_FI": "السويدية (فنلندا)",
                "sv_SE": "السويدية (السويد)",
                "sw": "السواحلية",
                "sw_KE": "السواحلية (كينيا)",
                "sw_TZ": "السواحلية (تنزانيا)",
                "sw_UG": "السواحلية (أوغندا)",
                "ta": "التاميل",
                "ta_IN": "التاميل (الهند)",
                "ta_LK": "التاميل (سريلانكا)",
                "ta_MY": "التاميل (ماليزيا)",
                "ta_SG": "التاميل (سنغافورة)",
                "te": "التيلجو",
                "te_IN": "التيلجو (الهند)",
                "tg": "الطاجيكية",
                "th": "التايلاندية",
                "th_TH": "التايلاندية (تايلاند)",
                "ti": "التغرينية",
                "ti_ER": "التغرينية (إريتريا)",
                "ti_ET": "التغرينية (إثيوبيا)",
                "tk": "التركمان",
                "tl": "التاغالوغية",
                "tl_PH": "التاغالوغية (الفلبين)",
                "tn": "تسوانا",
                "to": "تونجا",
                "to_TO": "تونجا (تونغا)",
                "tr": "اللغة التركية",
                "tr_CY": "التركية (قبرص)",
                "tr_TR": "التركية (تركيا)",
                "ts": "تسونجا",
                "tt": "التتار",
                "tw": "توي",
                "ty": "تاهيتي",
                "ug": "الأويغور",
                "ug_Arab": "الأويغورية (العربية)",
                "ug_Arab_CN": "الأويغور (العربية، الصين)",
                "ug_CN": "الأويغور (الصين)",
                "uk": "الأوكرانية",
                "uk_UA": "الأوكرانية (أوكرانيا)",
                "ur": "الأردية",
                "ur_IN": "الأردية (الهند)",
                "ur_PK": "الأردية (باكستان)",
                "uz": "الأوزبكية",
                "uz_AF": "الأوزبكية (أفغانستان)",
                "uz_Arab": "الأوزبكية (العربية)",
                "uz_Arab_AF": "الأوزبكية (العربية، أفغانستان)",
                "uz_Cyrl": "الأوزبكية (السيريلية)",
                "uz_Cyrl_UZ": "الأوزبكية (السيريلية، أوزبكستان)",
                "uz_Latn": "الأوزبكية (اللاتينية)",
                "uz_Latn_UZ": "الأوزبكية (اللاتينية، أوزبكستان)",
                "uz_UZ": "الأوزبكية (أوزبكستان)",
                "ve": "فيندا",
                "vi": "الفيتنامية",
                "vi_VN": "الفيتنامية (فيتنام)",
                "vo": "فولابوك",
                "wa": "الوالون",
                "wo": "الولوف",
                "xh": "خوسا",
                "yi": "اليديشية",
                "yo": "اليوروبا",
                "yo_BJ": "اليوروبا (بنين)",
                "yo_NG": "اليوروبا (نيجيريا)",
                "za": "تشوانغ",
                "zh": "صينى",
                "zh_CN": "الصينية (الصين)",
                "zh_HK": "الصينية (منطقة هونج كونج الإدارية الخاصة الصينية)",
                "zh_Hans": "الصينية المبسطة)",
                "zh_Hans_CN": "الصينية (المبسطة، الصين)",
                "zh_Hans_HK": "الصينية (المبسطة، منطقة هونغ كونغ الإدارية الخاصة الصينية)",
                "zh_Hans_MO": "الصينية (المبسطة، منطقة ماكاو الإدارية الخاصة في الصين)",
                "zh_Hans_SG": "الصينية (المبسطة، سنغافورة)",
                "zh_Hant": "تقاليد صينية)",
                "zh_Hant_HK": "الصينية (التقليدية، منطقة هونغ كونغ الإدارية الخاصة الصينية)",
                "zh_Hant_MO": "الصينية (التقليدية، منطقة ماكاو الإدارية الخاصة الصينية)",
                "zh_Hant_TW": "الصينية (التقليدية، تايوان)",
                "zh_MO": "الصينية (منطقة ماكاو الإدارية الخاصة الصينية)",
                "zh_SG": "الصينية (سنغافورة)",
                "zh_TW": "الصينية (تايوان)",
                "zu": "الزولو",
                "zu_ZA": "الزولو (جنوب أفريقيا)"
            },
            "mail": {
                "action": "إذا كنت تواجه مشكلة في النقر على الزر \"{actionText}\"، فانسخ عنوان URL أدناه والصقه في متصفح الويب الخاص بك:",
                "auth": {
                    "deleted": {
                        "line1": "نبلغك أنه قد تم حذف حساب UgoZER الخاص بك.",
                        "reason": {
                            "intro": "تم حذف هذا الموضوع للأسباب التالية:",
                            "never_authenticated_for_too_long": "تم إنشاء حسابك منذ أكثر من أسبوع\n                    ولم تقم بتسجيل الدخول مطلقًا:\n                    لذلك يعتبر هذا الحساب غير نشط.",
                            "not_logged_for_too_long": "لم تقم بتسجيل الدخول منذ أكثر من عامين:\n                    يعتبر حسابك غير نشط."
                        },
                        "subject": "حذف حساب UgoZER الخاص بك"
                    },
                    "login_attempt_unverified_credential": {
                        "action": "التحقق من عنوان البريد الإلكتروني",
                        "line1": "كانت هناك محاولة لتسجيل الدخول إلى حساب UgoZER الخاص بك باستخدام عنوان البريد الإلكتروني {credential}.",
                        "line2": "إذا بدأت محاولة تسجيل الدخول هذه،\n                الرجاء الضغط على الزر أدناه للتحقق من عنوان بريدك الإلكتروني\n                وتكون قادرة على الاتصال.",
                        "line3": "ستنتهي صلاحية هذا الرابط خلال أسبوع واحد.",
                        "line4": "إذا لم تقم ببدء محاولة تسجيل الدخول هذه، فلا يلزم اتخاذ أي إجراء آخر.",
                        "subject": "حاول تسجيل الدخول إلى حساب UgoZER الخاص بك"
                    },
                    "login_magic_link": {
                        "action": "تسجيل الدخول",
                        "line1": "انقر على هذا الرابط لتسجيل الدخول",
                        "line2": "شكرا لك على استخدام التطبيق لدينا!",
                        "subject": "رابط تسجيل الدخول بدون كلمة مرور"
                    },
                    "not_logged": {
                        "intro": "لقد مر عامان تقريبًا منذ آخر مرة قمت فيها بتسجيل الدخول إلى حساب UgoZER الخاص بك.",
                        "outro": "ما عليك سوى تسجيل الدخول إلى حسابك حتى لا يتم حذفه.",
                        "remaining": {
                            "two_days": "أمامك يومان قبل أن يتم حذف حسابك.",
                            "two_months": "أمامك شهرين قبل أن يتم حذف حسابك.",
                            "two_weeks": "أمامك أسبوعان قبل أن يتم حذف حسابك."
                        },
                        "subject": "سيتم حذف حساب UgoZER الخاص بك قريبًا"
                    },
                    "registration": {
                        "action": "التحقق من عنوان البريد الإلكتروني",
                        "line1": "الرجاء النقر فوق الزر أدناه للتحقق من عنوان بريدك الإلكتروني والتمكن من تسجيل الدخول.",
                        "line2": "ستنتهي صلاحية هذا الرابط خلال أسبوع واحد.",
                        "line3": "إذا لم تقم بإنشاء حساب، فلا يلزم اتخاذ أي إجراء آخر.",
                        "subject": "تحقق من عنوان بريدك الإلكتروني"
                    },
                    "registration_attempt": {
                        "line1": "البريد الإلكتروني <strong>{credential}</strong> مستخدم بالفعل لحساب <strong>{role_type}</strong> في تطبيقنا.",
                        "line2": "لإنشاء حساب، يرجى بدء التسجيل مرة أخرى باستخدام بريد إلكتروني آخر.",
                        "subject": "حاول التسجيل باستخدام بيانات اعتماد UgoZER الخاصة بك"
                    },
                    "registration_attempt_unverified_credential": {
                        "line1": "البريد الإلكتروني <strong>{credential}</strong> مستخدم بالفعل لحساب <strong>{role_type}</strong> في تطبيقنا.",
                        "line2": "لإنشاء حساب، يرجى بدء التسجيل مرة أخرى باستخدام بريد إلكتروني آخر.",
                        "subject": "حاول التسجيل باستخدام بيانات اعتماد UgoZER الخاصة بك"
                    },
                    "reset_password": {
                        "action": "إعادة تعيين كلمة المرور",
                        "line1": "لقد تلقيت هذا البريد الإلكتروني لأننا تلقينا طلبًا لإعادة تعيين كلمة المرور لحسابك.",
                        "line2": "ستنتهي صلاحية رابط إعادة تعيين كلمة المرور هذا خلال دقيقة واحدة.",
                        "line3": "إذا لم تطلب إعادة تعيين كلمة المرور، فلا يلزم اتخاذ أي إجراء آخر.",
                        "subject": "إعادة تعيين إشعار كلمة المرور"
                    }
                },
                "copyright": "© {year} UgoZER - جميع الحقوق محفوظة.",
                "credential_verification": {
                    "verify": {
                        "action": "التحقق من عنوان البريد الإلكتروني",
                        "line1": "الرجاء الضغط على الزر أدناه للتحقق من عنوان بريدك الإلكتروني.",
                        "line2": "ستنتهي صلاحية هذا الرابط خلال أسبوع واحد.",
                        "line3": "إذا لم تطلب التحقق، فلا يلزم اتخاذ أي إجراء آخر.",
                        "subject": "تأكيد البريد الإلكتروني الخاص بك"
                    }
                },
                "hello": "مرحبًا!",
                "invitation": {
                    "attempt": {
                        "action": "الوصول إلى التطبيق",
                        "line1": "لقد تمت دعوتك للانضمام إلى طلبنا",
                        "line1_by": "لقد تمت دعوتك بواسطة {name} للانضمام إلى تطبيقنا",
                        "line1_by_from": "لقد تمت دعوتك بواسطة {name} من مؤسسة {poi_title} للانضمام إلى تطبيقنا",
                        "line2": "لديك بالفعل حساب موجود في تطبيقنا",
                        "line3": "الرجاء الضغط على الزر أدناه للوصول إلى التطبيق.",
                        "subject": "دعوة للانضمام إلى UgoZER"
                    },
                    "verification": {
                        "action": "التحقق من عنوان البريد الإلكتروني",
                        "excursion_shared": "تمت مشاركة خط سير الرحلة معك. تجدونه مرفقا بصيغة PDF،\n                    وكذلك في التطبيق في أسهمك.",
                        "line1": "لقد تمت دعوتك للانضمام إلى طلبنا",
                        "line1_by": "لقد تمت دعوتك بواسطة {name} للانضمام إلى تطبيقنا",
                        "line1_by_from": "لقد تمت دعوتك بواسطة {name} من مؤسسة {poi_title} للانضمام إلى تطبيقنا",
                        "line2": "الرجاء الضغط على الزر أدناه للتحقق من عنوان بريدك الإلكتروني.",
                        "line3": "ستنتهي صلاحية هذا الرابط خلال أسبوع واحد.",
                        "line4": "شكرا لك على استخدام التطبيق لدينا!",
                        "point_of_interest_shared": "تمت مشاركة نقطة اهتمام معك. يمكن ان تجدها\n                في التطبيق في أسهمك.",
                        "subject": "دعوة للانضمام إلى UgoZER"
                    }
                },
                "invitation_professional": {
                    "content": "لقد تمت دعوتك للانضمام إلى القسم الاحترافي في تطبيقنا UgoZER",
                    "cta": "سجل في القسم المهني",
                    "subject": "UgoZER - وصول احترافي للدعوة",
                    "thanks": "شكرًا،",
                    "title": "دعوة إلى القسم الاحترافي لتطبيق UgoZER"
                },
                "preview": {
                    "default": {
                        "subject": "موضوع البريد الإلكتروني",
                        "user": {
                            "name": "UgoZER"
                        }
                    },
                    "from": "من:",
                    "mails": {
                        "auth/account-deleted-notification": "حذف الحساب",
                        "auth/not-logged-notification": "احذف إذا لم يكن متصلاً",
                        "auth/registration-attempt-notification": "تمت محاولة التسجيل",
                        "auth/registration-notification": "تسجيل",
                        "auth/reset-password-notification": "غير كلمة المرور الخاصة بك",
                        "invitation/invited-notification": "دعوة",
                        "share/created-notification": "جديد",
                        "support-message/message-notification": "إجابة جديدة",
                        "support-request/archived-notification": "الطلب المؤرشف",
                        "support-request/created-notification": "الطلب الذي تم إنشاؤه",
                        "user-credential-verification/login-attempt-notification": "محاولة الاتصال",
                        "user-credential-verification/registration-attempt-notification": "تمت محاولة التسجيل",
                        "user-credential-verification/verify-notification": "تأكيد الحساب",
                        "user/welcome-notification": "مرحباً"
                    },
                    "main_title": "معاينة البريد الإلكتروني",
                    "sections": {
                        "auth": "المصادقة",
                        "invitation": "الدعوات",
                        "share": "تشارك",
                        "support-message": "رسائل الدعم",
                        "support-request": "طلبات الدعم",
                        "user": "مستخدم",
                        "user-credential-verification": "بيانات اعتماد المستخدم"
                    }
                },
                "salutations": "يعتبر",
                "share": {
                    "created": {
                        "action": "يعرض",
                        "excursion": {
                            "gotoapp": "انتقل إلى التطبيق لمشاهدته.",
                            "line": "قام {username} من مؤسسة {poi_title} بمشاركة برنامج الرحلة معك.",
                            "line_alt": "تمت مشاركة برنامج رحلة معك.",
                            "line_alt_2": "تمت مشاركة خط سير الرحلة معك.",
                            "subject": "تمت مشاركة خط سير الرحلة معك."
                        },
                        "line": "قام {username} من مؤسسة {poi_title} بمشاركة عنصر معك.",
                        "line_alt": "قام {username} بمشاركة عنصر معك.",
                        "line_alt_2": "تمت مشاركة عنصر معك.",
                        "point_of_interest": {
                            "line": "قام {username} من مؤسسة {poi_title} بمشاركة نقطة اهتمام معك.",
                            "line_alt": "قام {username} بمشاركة نقطة اهتمام معك.",
                            "line_alt_2": "تمت مشاركة نقطة اهتمام معك.",
                            "subject": "تمت مشاركة نقطة اهتمام معك."
                        },
                        "subject": "تمت مشاركة عنصر معك."
                    }
                },
                "subscription": {
                    "not_continue_after_free_period": {
                        "cta": "انظر التفاصيل",
                        "line1": "{name} لم يستمر بعد انتهاء الفترة المجانية",
                        "line2": "الرجاء الضغط على الزر أدناه للوصول إلى التفاصيل",
                        "subject": "لم يستمر المشترك بعد انتهاء الفترة المجانية"
                    },
                    "suspended": {
                        "line": "لقد تم تعليق اشتراكك للسبب التالي: {suspension_reason}.",
                        "line_alt": "لقد تم تعليق اشتراكك.",
                        "subject": "تم تعليق الاشتراك"
                    },
                    "switched_from_paid_to_free": {
                        "cta": "انظر التفاصيل",
                        "line1": "تحول {name} إلى الخطة المجانية",
                        "line2": "الرجاء الضغط على الزر أدناه للوصول إلى التفاصيل",
                        "subject": "تحول المشترك إلى الخطة المجانية"
                    }
                },
                "support_request": {
                    "archived": {
                        "subject": "UgoZER - تمت أرشفة طلب الدعم",
                        "title": "تمت أرشفة طلب الدعم الخاص بك"
                    },
                    "message": {
                        "action": "الوصول إلى طلب الدعم",
                        "line1": "الرجاء الضغط على الزر أدناه للوصول إلى طلبك.",
                        "subject": "أوغوزر - لديك إجابة",
                        "title": "تم نشر إجابة على طلب الدعم الخاص بك"
                    },
                    "request": {
                        "action": "الوصول إلى طلب الدعم",
                        "line1": "سيتم الرد على دعم الاتصال خلال 48 ساعة.",
                        "line2": "الرجاء الضغط على الزر أدناه للوصول إلى طلبك.",
                        "subject": "UgoZER - تم إنشاء طلب الدعم الخاص بك"
                    }
                },
                "user_send_link": {
                    "content": "أعد توصيلك بتطبيقنا UgoZER! إذا كنت لا تتذكر كلمة المرور الخاصة بك، فيمكنك إعادة تعيينها.",
                    "cta": "تسجيل الدخول التطبيق",
                    "subject": "UgoZER - التطبيق",
                    "thanks": "شكرًا،",
                    "title": "لديك حساب على تطبيقنا، قم بتسجيل الدخول!"
                },
                "welcome": {
                    "action": "الوصول إلى التطبيق",
                    "line1": "يسعدنا أن نعدك بين مستخدمينا.",
                    "line2": "انقر على الزر أدناه للوصول إلى التطبيق.",
                    "subject": "مرحبا بكم في UgoZER",
                    "title": "مرحبًا بك في UgoZER، تطبيق المساعدة المجاني في السفر متعدد اللغات."
                }
            },
            "media": {
                "audio": {
                    "generated_audio": "الصوت المولد",
                    "recording": {
                        "errors": {
                            "default": "فشل التسجيل",
                            "not_allowed": "فشل التسجيل. اسمح لمتصفحك وUgoZER باستخدام الميكروفون وحاول مرة أخرى."
                        }
                    }
                },
                "default": {
                    "alt": "خريطة خرائط جوجل الثابتة",
                    "caption": "انقر على الخريطة لإظهار ذلك على خرائط جوجل"
                },
                "image": {
                    "credits": "الاعتمادات: ×1"
                }
            },
            "models": {
                "activity_log": "سجل النشاط|سجلات النشاط",
                "address": "العنوان|العناوين",
                "bank_transfer": "تحويل بنكي|تحويل بنكي",
                "bank_transfer_institution": "مؤسسة التحويل البنكي|مؤسسات التحويل البنكي",
                "category": "الفئة|الفئات",
                "changelog": "سجل التغيير|سجلات التغيير",
                "claim": "مطالبة|مطالبات",
                "claim_dispute": "نزاع المطالبة|نزاعات المطالبة",
                "currency": "العملة|العملات",
                "data_tourisme_category": "فئة سياحة البيانات|فئات سياحة البيانات",
                "data_tourisme_tag": "علامة DataTourisme|علامات DataTourisme",
                "excursion": "خط سير الرحلة|خطوط سير الرحلة",
                "institution_employee": "الموظف|الموظفون",
                "interaction": "التفاعل|التفاعلات",
                "locale": "اللغة|اللغات",
                "medium": "متوسط|وسائل الإعلام",
                "notification": "إعلام|الإخطارات",
                "partner": "شريك|شركاء",
                "payment": "الدفع|المدفوعات",
                "permission": "إذن|أذونات",
                "plan": "خطة الاشتراك|خطط الاشتراك",
                "plan_price": "سعر الخطة|أسعار الخطة",
                "point_of_interest": "نقطة الاهتمام|النقاط المثيرة للاهتمام",
                "point_of_interest_category": "الفئة|الفئات",
                "post": "مشاركة|المشاركات",
                "post_category": "فئة المشاركات|فئات المشاركات",
                "privacy_policy": "سياسة الخصوصية|سياسات الخصوصية",
                "report": "تقرير|تقارير",
                "reservation": "الحجز|الحجوزات",
                "role": "الدور|الأدوار",
                "share": "مشاركة|الأسهم",
                "subscription": "الاشتراك|الاشتراكات",
                "support_category": "فئة الدعم|فئات الدعم",
                "support_message": "الرسالة|الرسائل",
                "support_request": "طلب|الطلبات",
                "tag": "العلامة|العلامات",
                "terms_condition": "الشروط والأحكام|الشروط والأحكام",
                "traveller_email": "البريد الإلكتروني للمسافر|رسائل البريد الإلكتروني للمسافرين",
                "traveller_folder": "مجلد المسافر|مجلدات المسافر",
                "user": "المستخدم|المستخدمون",
                "user_credential_verification": "التحقق من بيانات الاعتماد|التحقق من بيانات الاعتماد",
                "user_geoloc_setting": "إعداد Geoloc|إعدادات Geoloc",
                "user_notification_setting": "إعداد الإخطارات|إعدادات الإخطارات",
                "user_password_reset": "إعادة تعيين كلمة مرور المستخدم|إعادة تعيين كلمات مرور المستخدم",
                "user_privacy_setting": "إعدادات الخصوصية|إعدادات الخصوصية",
                "users_relationship": "الاتصال|جهات الاتصال",
                "vat_rate": "معدل ضريبة القيمة المضافة|معدلات ضريبة القيمة المضافة"
            },
            "navigations": {
                "items": {
                    "changelogs": "سجلات التغيير",
                    "contact_us": "اتصل بنا",
                    "contacts": "جهات الاتصال",
                    "cookies": "إدارة ملفات تعريف الارتباط",
                    "credits": "الاعتمادات",
                    "customers": "إنهم يثقون بنا",
                    "faqs": "التعليمات",
                    "folders": "مجلدات المسافر",
                    "invite_professional": "قم بدعوة محترف",
                    "invite_traveller": "دعوة مسافر",
                    "partners": "الشركاء",
                    "payments": "المدفوعات",
                    "privacy_policy": "سياسة الخصوصية",
                    "settings": "حدود",
                    "stays": "يبقى",
                    "terms_conditions": "الأحكام والشروط",
                    "users": "المستخدمين"
                }
            },
            "notifications": {
                "changelog": {
                    "created": {
                        "text": "تم نشر الإصدار {version}. يمكنك التحقق من الأخبار من خلال النقر على الإخطار.",
                        "title": "سجل التغيير الجديد"
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "text": "إن نزاع المطالبة المتعلق بنقطة الاهتمام {poi} في انتظار التحقق من قبل فريقنا.",
                        "title": "نزاع المطالبة في انتظار التحقق"
                    },
                    "rejected": {
                        "no_reason": "لا يوجد سبب معين",
                        "text": "تم رفض نزاع المطالبة بنقطة الاهتمام {poi} للسبب التالي: {reason}.",
                        "title": "تم رفض الاعتراض على المطالبة"
                    },
                    "validated": {
                        "text": "تم قبول نزاع المطالبة المتعلق بنقطة الاهتمام {poi}!",
                        "title": "تم التحقق من صحة نزاع المطالبة!"
                    }
                },
                "index": {
                    "no_results": "لا إشعارات!"
                },
                "institution_employee": {
                    "approved": {
                        "text": "تمت الموافقة على طلبك للانضمام إلى {poi}",
                        "title": "تمت الموافقة على طلب الموظف"
                    },
                    "declined": {
                        "text": "لقد تم رفض طلبك للانضمام إلى {poi}",
                        "title": "تم رفض طلب الموظف"
                    },
                    "pending": {
                        "text": "{user} يطلب التسجيل كموظف في مؤسستك {poi}",
                        "title": "تم استلام طلب الموظف"
                    },
                    "removed": {
                        "text": "تمت إزالة حالة الموظف الخاصة بك من {poi}",
                        "title": "تمت إزالة حالة الموظف"
                    }
                },
                "invitation": {
                    "accepted": {
                        "email": {
                            "text": "لقد تم قبول دعوتك للانضمام إلى الطلب المرسل إلى {credential}"
                        },
                        "phone": {
                            "text": "لقد تم قبول دعوتك للانضمام إلى الطلب المرسل إلى {credential}"
                        },
                        "title": "تم قبول الدعوة"
                    },
                    "pro": {
                        "text": "لقد تم إرسال دعوتك المهنية إلى البريد الإلكتروني {email}",
                        "title": "تم إرسال الدعوة المهنية"
                    }
                },
                "office": {
                    "claim": {
                        "content": {
                            "line_1": "لقد تمت المطالبة للتو بنقطة اهتمام.",
                            "line_2": "انقر على الزر أدناه لرؤية التفاصيل في واجهة المسؤول."
                        },
                        "cta": "انظر المطالبة",
                        "subject": "مطالبة جديدة!"
                    },
                    "claim_dispute": {
                        "content": {
                            "line_1": "لقد تم التنازع على نقطة الاهتمام للتو.",
                            "line_2": "انقر على الزر أدناه لرؤية التفاصيل في واجهة المسؤول."
                        },
                        "cta": "انظر النزاع",
                        "subject": "نزاع جديد!"
                    },
                    "report": {
                        "content": {
                            "line_1": "تم الإبلاغ عن {type} للتو."
                        },
                        "cta": "انظر التقرير",
                        "subject": "تقرير جديد!",
                        "type": {
                            "App\\Models\\PointOfInterest": "نقطة اهتمام",
                            "App\\Models\\User": "مستخدم"
                        }
                    },
                    "support_request": {
                        "content": {
                            "line_1": "تمت إضافة طلب دعم جديد للتو."
                        },
                        "cta": "انظر طلب الدعم",
                        "subject": "طلب دعم جديد!"
                    }
                },
                "payment": {
                    "proposal": {
                        "text": "أشكر {username} من مؤسسة {poi_title} من خلال ترك إكرامية",
                        "text_alt": "أشكر الكونسيرج من مؤسسة {poi_title} من خلال ترك إكرامية",
                        "text_alt_2": "أشكر {username} عن طريق ترك نصيحة",
                        "text_alt_3": "أشكر الكونسيرج من خلال ترك بقشيش",
                        "title": "أترك نصيحة"
                    },
                    "received": {
                        "text": "لقد تلقيت نصيحة {amount} من {payer} من مؤسسة {poi_title}",
                        "text_alt": "لقد تلقيت نصيحة {amount} من {payer}",
                        "text_alt_2": "لقد تلقيت نصيحة ×1",
                        "title": "تلقى نصيحة"
                    },
                    "thanks": {
                        "text": "{payee} من مؤسسة {poi_title} شكرًا لك على النصيحة!",
                        "text_alt": "×1 شكرًا لك على النصيحة!",
                        "text_alt_2": "لقد تم شكرك على النصيحة",
                        "title": "شكرا على الاكرامية!"
                    }
                },
                "point_of_interest": {
                    "event": {
                        "expired": {
                            "text": "انتهت صلاحية الحدث {title}، المضمن في برامج رحلاتك",
                            "title": "انتهت صلاحية الحدث"
                        }
                    },
                    "moderation": {
                        "denied": {
                            "text": "لقد تم رفض نقطة اهتمامك {title} بعد الإشراف عليها",
                            "title": "تم رفض نقطة الاهتمام"
                        },
                        "published": {
                            "text": "لقد تم نشر نقطة اهتمامك {title} بعد الإشراف عليها",
                            "title": "تم نشر نقطة الاهتمام"
                        }
                    }
                },
                "reservation": {
                    "created": {
                        "text": "قام {butler_name} من المؤسسة {butler_poi_title} بالحجز\n                في مؤسستك {poi_title}",
                        "text_alt": "قام بواب من المؤسسة {butler_poi_title} بالحجز\n                في مؤسستك {poi_title}",
                        "text_alt_2": "قام {butler_name} بالحجز\n                في مؤسستك {poi_title}",
                        "text_alt_3": "لقد تم الحجز\n                في مؤسستك {poi_title}",
                        "title": "الحجز في مؤسستك"
                    }
                },
                "share": {
                    "created": {
                        "excursion": {
                            "text": "قام {username} من مؤسسة {poi_title} بمشاركة برنامج الرحلة معك",
                            "text_alt": "تمت مشاركة برنامج رحلة معك",
                            "text_alt_2": "تمت مشاركة خط سير الرحلة معك",
                            "title": "مشاركة خط سير الرحلة"
                        },
                        "point_of_interest": {
                            "text": "قام {username} من مؤسسة {poi_title} بمشاركة نقطة اهتمام معك",
                            "text_alt": "قام {username} بمشاركة نقطة اهتمام معك",
                            "text_alt_2": "تمت مشاركة نقطة اهتمام معك",
                            "title": "مشاركة نقطة الاهتمام"
                        },
                        "text": "قام {username} من مؤسسة {poi_title} بمشاركة عنصر معك",
                        "text_alt": "قام {username} بمشاركة عنصر معك",
                        "text_alt_2": "تمت مشاركة عنصر معك"
                    }
                },
                "stripe": {
                    "account_requirements": {
                        "text": "بعد التحقق من حساب Stripe الخاص بك، يتم طلب معلومات جديدة",
                        "title": "متطلبات حساب الشريط"
                    },
                    "payments_enabled": {
                        "text": "يمكنك الآن تلقي المدفوعات من خلال Stripe",
                        "title": "تم تمكين الدفعات الشريطية"
                    }
                },
                "support_request": {
                    "archived": {
                        "text": "تمت أرشفة طلب الدعم الخاص بك",
                        "title": "تم أرشفة طلب الدعم"
                    },
                    "message": {
                        "text": "لقد تم نشر إجابة على طلب الدعم الخاص بك",
                        "title": "وصلت الرساله"
                    }
                },
                "traveller_folder": {
                    "approved": {
                        "text": "وافق {username} على مجلد المسافر الخاص به",
                        "text_alt": "لقد وافق المسافر على مجلده",
                        "title": "تمت الموافقة على مجلد المسافر"
                    },
                    "archived": {
                        "text": "قام {username} بأرشفة مجلد المسافر الخاص به",
                        "text_alt": "لقد قام المسافر بأرشفة مجلده",
                        "title": "تمت أرشفة مجلد المسافر"
                    },
                    "created": {
                        "text": "تم تسجيل إقامة لحسابك في الفندق {poi_title}.",
                        "title": "ابقَ مخلوقًا"
                    },
                    "pending": {
                        "text": "لقد سجل {username} في حسابك إقامة في الفندق {poi_title}.\n                يرجى تأكيد ذلك.",
                        "text_alt": "تم تسجيل إقامة لحسابك في الفندق {poi_title}.\n                يرجى تأكيد ذلك.",
                        "title": "البقاء في انتظار التأكيد"
                    },
                    "thanks": {
                        "text": "{username} من مؤسسة {poi_title} شكرًا لك على إقامتك",
                        "text_alt": "يشكرك الكونسيرج من مؤسسة {poi_title} على إقامتك",
                        "title": "شكرا على إقامتك"
                    }
                }
            },
            "nova": {
                "actions": {
                    "bank_transfer": {
                        "transfer": "إجراء تحويل مصرفي"
                    },
                    "changelog": {
                        "archive": "أرشيف",
                        "draft": "مسودة",
                        "publish": "ينشر"
                    },
                    "claim": {
                        "missing_info": "معلومات مفقودة",
                        "reject": "يرفض",
                        "revoke": "سحب او إبطال",
                        "validate": "التحقق من صحة"
                    },
                    "claim_dispute": {
                        "reject": "يرفض",
                        "validate": "التحقق من صحة"
                    },
                    "payment": {
                        "download_invoice": "تحميل فاتورة"
                    },
                    "plan": {
                        "archive": "أرشيف",
                        "draft": "مسودة",
                        "publish": "ينشر"
                    },
                    "point_of_interest": {
                        "deny": "ينكر",
                        "draft": "مسودة",
                        "publish": "التحقق من صحة",
                        "submit": "اطلب الاعتدال"
                    },
                    "post": {
                        "archive": "أرشيف",
                        "draft": "مسودة",
                        "publish": "ينشر",
                        "set_coming_soon": "ضبط على \"قريبًا\""
                    },
                    "privacy_policy": {
                        "archive": "أرشيف",
                        "confirmation": {
                            "confirm_button_text": "ينشر",
                            "confirm_text": "هل أنت متأكد أنك تريد نشر سياسة الخصوصية هذه؟ سيُطلب من جميع المستخدمين التحقق من صحتهم مرة أخرى!"
                        },
                        "errors": {
                            "resources_count": "لا يمكنك نشر أكثر من سياسة خاصة واحدة!"
                        },
                        "publish": "ينشر"
                    },
                    "report": {
                        "process": "عملية",
                        "reject": "يرفض"
                    },
                    "support_request": {
                        "answer": "إجابة",
                        "archive": "أرشيف",
                        "cancel": "يلغي",
                        "pending_support": "في انتظار الدعم",
                        "pending_user": "في انتظار المستخدم",
                        "resolve": "حل"
                    },
                    "terms_condition": {
                        "archive": "أرشيف",
                        "confirmation": {
                            "confirm_button_text": "ينشر",
                            "confirm_text": "هل أنت متأكد أنك تريد نشر هذه الشروط والأحكام؟ سيُطلب من جميع المستخدمين التحقق من صحتهم مرة أخرى!"
                        },
                        "errors": {
                            "resources_count": "لا يمكنك نشر أكثر من شروط وأحكام واحدة!"
                        },
                        "publish": "ينشر"
                    },
                    "user": {
                        "finish": "إنهاء البرنامج التعليمي"
                    },
                    "user_credential_verification": {
                        "validate": "التحقق من صحة"
                    }
                },
                "cards": {
                    "new_excursions": "مسارات جديدة",
                    "new_points_of_interest": "نقاط اهتمام جديدة",
                    "new_users": "مستخدمون جدد"
                },
                "filters": {
                    "app": "طلب",
                    "archived_at_gte": "مؤرشفة بعد",
                    "archived_at_lte": "مؤرشفة من قبل",
                    "canceled_at_gte": "ألغيت بعد",
                    "canceled_at_lte": "ألغيت من قبل",
                    "created_at_gte": "تم إنشاؤها بعد",
                    "created_at_lte": "تم إنشاؤها من قبل",
                    "dynamic_translations_enabled": "تم تمكين الترجمات الديناميكية",
                    "extranet": "الشبكة الخارجية",
                    "front": "أمام",
                    "group": "مجموعة",
                    "published_at_gte": "نشرت بعد",
                    "published_at_lte": "نشرت من قبل",
                    "reservation_date_gte": "الحجز بعد",
                    "reservation_date_lte": "الحجز قبل",
                    "resolved_at_gte": "حل بعد",
                    "resolved_at_lte": "تم حلها من قبل",
                    "static_translations_enabled": "تم تمكين الترجمات الثابتة",
                    "to_validate": "للتحقق من صحة"
                },
                "labels": {
                    "institutions": "المؤسسات",
                    "point_of_interest_categories": "فئات"
                }
            },
            "opening_hours": {
                "additional_info": "معلومات إضافية",
                "content": {
                    "date": "×1",
                    "date_range_from": "من ×1",
                    "date_range_from_to": "من {from} إلى {to}",
                    "date_range_to": "إلى ×1",
                    "hours_range_from": "من ×1",
                    "hours_range_from_to": "من {from} إلى {to}",
                    "hours_range_to": "إلى ×1"
                }
            },
            "partners": {
                "customers": {
                    "title": "عملائنا"
                },
                "index": {
                    "no_results": "لم يتم العثور على شركاء",
                    "our_last_partners": "آخر شركائنا",
                    "title": "شركاؤنا"
                },
                "show": {
                    "link": "زيارة الموقع"
                }
            },
            "payments": {
                "checkout": {
                    "cancel": "تم إلغاء الدفع",
                    "custom_amount": "أدخل المبلغ المخصص",
                    "success": "تم الدفع بنجاح",
                    "tip_amount": "مبلغ النصيحة"
                },
                "index": {
                    "no_results": "لم يتم العثور على أي دفعات"
                },
                "labels": {
                    "payee": "المستفيد:",
                    "payment_service": "يدفع بواسطة"
                },
                "payable": {
                    "reservation": "رقم الحجز: ID"
                },
                "thanks": {
                    "success": "شكرا أرسلت!"
                }
            },
            "pdf": {
                "common": {
                    "generating": "جارٍ إنشاء ملف PDF...",
                    "waiting": "انتظر من فضلك..."
                },
                "payments": {
                    "period": "الفترة من {from} إلى {to}",
                    "title": "المدفوعات المستلمة"
                },
                "receipt": {
                    "debited_amount": "المبلغ المدين",
                    "paid_amount": "المبلغ المدفوع",
                    "payment_date": "تاريخ الدفع",
                    "payment_method": "طريقة الدفع او السداد",
                    "receipt_for": "إيصال الدفع الذي تم إجراؤه إلى",
                    "receipt_number": "إيصال الدفع رقم {id}",
                    "summary": "ملخص"
                },
                "share": {
                    "cta": "استشر خط سير الرحلة",
                    "details": {
                        "line1": "يسعدنا أن نشارككم خط سير الرحلة المخصص لكم.",
                        "line2": "ستجد محتوى خط سير الرحلة الخاص بك أدناه.",
                        "line3": "للوصول إلى المعلومات التفصيلية (الصوت مع العنوان باللغة المحلية، الخريطة التفاعلية،\n                الصور، الوصف الكامل...)، انقر على الزر أدناه."
                    }
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "ADMIN": "الوصول إلى الإدارة",
                        "APP": "الوصول إلى التطبيق",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالوصول",
                        "EXTRANET": "ادخل إلى قسم الاختصاصيين",
                        "HORIZON": "أفق الوصول",
                        "TELESCOPE": "تلسكوب الوصول",
                        "VIEW_BUTLER_DASHBOARD": "عرض لوحة القيادة للخادم الشخصي",
                        "VIEW_HOTELIER_DASHBOARD": "عرض لوحة القيادة الخاصة بالفندق",
                        "VIEW_MANAGER_DASHBOARD": "عرض لوحة تحكم المدير",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "عرض لوحة تحكم مكتب سياحي",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "عرض لوحة تحكم موظف مكتب السياحة",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "تسجيل الدخول دون الحاجة إلى التحقق من بيانات الاعتماد الخاصة بك"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بسجلات النشاط",
                        "CREATE": "قم بإنشاء سجل نشاط",
                        "DELETE": "حذف سجل النشاط",
                        "FORCE_DELETE": "فرض حذف سجل النشاط",
                        "RESTORE": "استعادة سجل النشاط",
                        "UPDATE": "قم بتحديث سجل النشاط",
                        "VIEW": "استشر سجل النشاط",
                        "VIEW_ANY": "استشر فهرس سجلات النشاط"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالعناوين",
                        "CREATE": "أنشئ عنوانًا",
                        "DELETE": "احذف العنوان",
                        "FORCE_DELETE": "فرض حذف عنوان",
                        "RESTORE": "استعادة العنوان",
                        "UPDATE": "تحديث العنوان",
                        "VIEW": "استشر أحد العناوين",
                        "VIEW_ANY": "راجع فهرس العناوين"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بمؤسسات التحويلات المصرفية",
                        "VIEW_ANY": "راجع فهرس مؤسسات التحويلات المصرفية"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "ARCHIVE": "أرشفة سجل التغيير",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بسجلات التغيير",
                        "CREATE": "قم بإنشاء سجل التغيير",
                        "DELETE": "حذف سجل التغيير",
                        "DRAFT": "قم بصياغة سجل التغيير",
                        "FORCE_DELETE": "فرض حذف سجل التغيير",
                        "PUBLISH": "انشر سجل التغيير",
                        "RESTORE": "استعادة التغيير",
                        "UPDATE": "تحديث سجل التغيير",
                        "VIEW": "استشر سجل التغيير",
                        "VIEW_ANY": "استشر فهرس التغيير"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالمطالبة بالنزاعات",
                        "CREATE": "إنشاء نزاع مطالبة",
                        "DELETE": "حذف نزاع مطالبة",
                        "FORCE_DELETE": "فرض حذف نزاع مطالبة",
                        "REJECT": "رفض نزاع مطالبة",
                        "RESTORE": "استعادة نزاع مطالبة",
                        "UPDATE": "تحديث نزاع مطالبة",
                        "VALIDATE": "التحقق من صحة نزاع المطالبة",
                        "VIEW": "استشارة نزاع مطالبة",
                        "VIEW_ANY": "راجع فهرس دعاوى المنازعات"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالمطالبات",
                        "CREATE": "إنشاء مطالبة",
                        "DELETE": "حذف مطالبة",
                        "FORCE_DELETE": "فرض حذف مطالبة",
                        "MISSING_INFO": "رفض مطالبة باعتبارها معلومات مفقودة",
                        "REJECT": "رفض مطالبة",
                        "RESTORE": "استعادة المطالبة",
                        "REVOKE": "إبطال مطالبة",
                        "SHOW_FILE": "إظهار ملف مطالبة",
                        "UPDATE": "تحديث مطالبة",
                        "VALIDATE": "التحقق من صحة المطالبة",
                        "VIEW": "استشر مطالبة",
                        "VIEW_ANY": "راجع فهرس المطالبات"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالبلدان",
                        "USE_ANY": "استخدم أي بلد"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالعملات",
                        "CREATE": "قم بإنشاء عملة",
                        "DELETE": "حذف العملة",
                        "FORCE_DELETE": "فرض حذف العملة",
                        "RESTORE": "استعادة العملة",
                        "UPDATE": "تحديث العملة",
                        "VIEW": "استشر العملة",
                        "VIEW_ANY": "استشر مؤشر العملات"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بحسابات EasyTransac",
                        "HAVE": "لديك حساب EasyTransac"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بخطوط الرحلات",
                        "CREATE": "إنشاء خط سير الرحلة",
                        "DELETE": "حذف خط سير الرحلة",
                        "FORCE_DELETE": "فرض حذف خط سير الرحلة",
                        "RESTORE": "استعادة خط سير الرحلة",
                        "SHARE": "مشاركة خط سير الرحلة",
                        "UPDATE": "تحديث خط سير الرحلة",
                        "VIEW": "استشارة خط سير الرحلة",
                        "VIEW_ANY": "راجع فهرس مسارات الرحلات",
                        "VIEW_KIND_UGOZER_APP": "عرض مسارات الرحلات باستخدام تطبيق UgoZER",
                        "VIEW_KIND_UGOZER_PRO": "عرض مسارات مع نوع UgoZER Pro"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالروابط بين مسارات الرحلة ونقاط الاهتمام",
                        "CREATE": "أنشئ رابطًا بين خط سير الرحلة ونقطة الاهتمام",
                        "DELETE": "حذف الرابط بين خط سير الرحلة ونقطة الاهتمام",
                        "FORCE_DELETE": "فرض حذف الرابط بين خط سير الرحلة ونقطة الاهتمام",
                        "RESTORE": "استعادة الرابط بين خط سير الرحلة ونقطة الاهتمام",
                        "UPDATE": "قم بتحديث الرابط بين خط سير الرحلة ونقطة الاهتمام",
                        "VIEW": "راجع الرابط بين خط سير الرحلة ونقطة الاهتمام",
                        "VIEW_ANY": "راجع فهرس الروابط بين مسارات الرحلات والنقاط المثيرة للاهتمام"
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "APPROVE": "الموافقة على طلب موظف المؤسسة",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بموظفي المؤسسات",
                        "CANCEL": "إلغاء طلب موظف مؤسسة",
                        "CREATE": "إنشاء موظف مؤسسة",
                        "DECLINE": "رفض طلب موظف مؤسسة",
                        "DELETE": "حذف موظف مؤسسة",
                        "FEATURE": "تمييز موظف من إحدى المؤسسات",
                        "FORCE_DELETE": "فرض حذف موظف مؤسسة",
                        "REMOVE": "إزالة موظف من مؤسسة",
                        "RESTORE": "إعادة موظف بالمؤسسة",
                        "UPDATE": "تحديث موظف مؤسسة",
                        "VIEW": "استشر موظف مؤسسة",
                        "VIEW_ANY": "استشر مؤشر موظفي المؤسسات"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "ARCHIVE": "أرشفة تفاعل",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالتفاعلات",
                        "CREATE": "خلق تفاعل",
                        "DELETE": "حذف تفاعل",
                        "DRAFT": "صياغة تفاعل",
                        "FORCE_DELETE": "فرض حذف تفاعل",
                        "PUBLISH": "انشر تفاعلًا",
                        "RESTORE": "استعادة التفاعل",
                        "UPDATE": "تحديث تفاعل",
                        "VIEW": "استشر تفاعلًا",
                        "VIEW_ANY": "استشر فهرس التفاعلات"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة باللغات",
                        "CREATE": "ابتكر لغة",
                        "DELETE": "احذف لغة",
                        "FORCE_DELETE": "فرض حذف لغة",
                        "RESTORE": "استعادة اللغة",
                        "UPDATE": "قم بتحديث اللغة",
                        "VIEW": "استشر لغة",
                        "VIEW_ANY": "استشر فهرس اللغات"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالوسائط",
                        "CREATE": "قم بإنشاء وسائط",
                        "DELETE": "احذف الوسائط",
                        "FORCE_DELETE": "فرض حذف الوسائط",
                        "RESTORE": "استعادة الوسائط",
                        "UPDATE": "تحديث الوسائط",
                        "VIEW": "استشر أحد وسائل الإعلام",
                        "VIEW_ANY": "استشر فهرس الوسائط"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "تلقي إشعار عند قبول دعوة",
                        "PAYMENT_RECEIVED": "تلقي إشعار عند استلام دفعة",
                        "PAYMENT_THANKS": "تلقي إخطارًا عند الإقرار بالدفع",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "تلقي إشعار عند انتهاء صلاحية حدث في نقطة الاهتمام",
                        "RESERVATION_CREATED": "تلقي إشعار عند إنشاء حجز",
                        "SHARE_CREATED": "تلقي إشعار عند إنشاء مشاركة",
                        "SUPPORT_REQUEST_ARCHIVED": "تلقي إشعار عند أرشفة طلب الدعم",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "تلقي إشعار عند استلام رسالة من الدعم\n                طلب",
                        "TRAVELLER_FOLDER_APPROVED": "تلقي إشعار عند الموافقة على ملف المسافر",
                        "TRAVELLER_FOLDER_ARCHIVED": "استلام اشعار عند تخزين ملف المسافر",
                        "TRAVELLER_FOLDER_PENDING": "تلقي إعلام أثناء انتظار ملف ليتم التحقق من صحته",
                        "TRAVELLER_FOLDER_THANKS": "تلقي إشعارًا لشكر البواب المسؤول عن ملف مسافر"
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالشركاء",
                        "CREATE": "أنشئ شريكًا",
                        "DELETE": "حذف شريك",
                        "FORCE_DELETE": "فرض حذف شريك",
                        "RESTORE": "استعادة الشريك",
                        "UPDATE": "تحديث الشريك",
                        "VIEW": "استشر شريكًا",
                        "VIEW_ANY": "استشر مؤشر الشركاء"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالمدفوعات",
                        "CREATE": "إنشاء دفعة",
                        "DELETE": "حذف دفعة",
                        "FORCE_DELETE": "فرض حذف دفعة",
                        "GET_RECEIPT": "الحصول على إيصال للدفع",
                        "RECEIVE": "تلقي المدفوعات",
                        "RESTORE": "استعادة دفعة",
                        "THANK": "تلقي شكراً للدفع",
                        "UPDATE": "تحديث الدفع",
                        "VIEW": "استشر الدفع",
                        "VIEW_ANY": "استشر مؤشر المدفوعات"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالأذونات",
                        "CREATE": "إنشاء إذن",
                        "DELETE": "حذف إذن",
                        "FORCE_DELETE": "فرض حذف إذن",
                        "RESTORE": "استعادة الإذن",
                        "UPDATE": "تحديث إذن",
                        "VIEW": "استشر إذنًا",
                        "VIEW_ANY": "استشر فهرس الأذونات"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "ARCHIVE": "أرشفة خطة الاشتراك",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بخطط الاشتراك",
                        "CREATE": "إنشاء خطة الاشتراك",
                        "DELETE": "حذف خطة الاشتراك",
                        "DRAFT": "صياغة خطة الاشتراك",
                        "FORCE_DELETE": "فرض حذف خطة الاشتراك",
                        "PUBLISH": "نشر خطة الاشتراك",
                        "RESTORE": "استعادة خطة الاشتراك",
                        "SUBSCRIBE": "اشترك في خطة",
                        "UPDATE": "تحديث خطة الاشتراك",
                        "VIEW": "راجع خطة الاشتراك",
                        "VIEW_ANY": "راجع فهرس خطط الاشتراك"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بفئات نقاط الاهتمام",
                        "CREATE": "قم بإنشاء فئة نقطة اهتمام",
                        "DELETE": "احذف فئة نقطة اهتمام",
                        "FORCE_DELETE": "فرض حذف فئة نقطة اهتمام",
                        "RESTORE": "استعادة فئة نقطة اهتمام",
                        "UPDATE": "قم بتحديث فئة نقطة اهتمام",
                        "VIEW": "استشر إحدى نقاط الاهتمام",
                        "VIEW_ANY": "راجع فهرس نقاط فئات الاهتمام"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "ADMIN_ANY": "إدارة أي نقطة من الاهتمام",
                        "APPLY": "تقدم لتصبح موظفًا",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بنقاط الاهتمام",
                        "CLAIM": "المطالبة بنقطة اهتمام",
                        "CONTEST": "تنافس على نقطة اهتمام",
                        "CONTRIBUTE": "المساهمة في نقطة اهتمام",
                        "CREATE": "أنشئ نقطة اهتمام",
                        "DELETE": "حذف نقطة الاهتمام",
                        "DENY": "رفض نقطة الاهتمام",
                        "DRAFT": "قم بصياغة نقطة اهتمام",
                        "FORCE_DELETE": "فرض حذف نقطة الاهتمام",
                        "IMPORT": "استيراد نقاط الاهتمام",
                        "MODERATE": "اعتدال في نقطة اهتمام",
                        "PUBLISH": "نشر نقطة الاهتمام",
                        "REPORT": "الإبلاغ عن نقطة اهتمام",
                        "RESTORE": "استعادة نقطة الاهتمام",
                        "SHARE": "مشاركة نقطة الاهتمام",
                        "SUBMIT": "إرسال نقطة الاهتمام",
                        "UPDATE": "تحديث نقطة الاهتمام",
                        "UPDATE_CERTIFIED": "قم بتحديث السمة المعتمدة لنقطة اهتمام",
                        "VIEW": "استشر نقطة اهتمام",
                        "VIEW_ANY": "راجع فهرس النقاط المثيرة للاهتمام"
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بفئات المشاركات",
                        "CREATE": "قم بإنشاء فئة مشاركات",
                        "DELETE": "حذف فئة المشاركات",
                        "FORCE_DELETE": "فرض حذف فئة المشاركات",
                        "RESTORE": "استعادة فئة المشاركات",
                        "UPDATE": "تحديث فئة المشاركات",
                        "VIEW": "استشر فئة الوظائف",
                        "VIEW_ANY": "استشر فهرس فئات المشاركات"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "ARCHIVE": "أرشفة مشاركة",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالمشاركات",
                        "CREATE": "أنشئ منشورًا",
                        "DELETE": "حذف نشرة",
                        "DRAFT": "مسودة منشور",
                        "FORCE_DELETE": "فرض حذف آخر",
                        "PUBLISH": "انشر تدوينة",
                        "RESTORE": "استعادة آخر",
                        "UPDATE": "تحديث المنشور",
                        "VIEW": "استشر وظيفة",
                        "VIEW_ANY": "استشر فهرس المشاركات"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "ARCHIVE": "أرشفة سياسة الخصوصية",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بسياسات الخصوصية",
                        "CREATE": "قم بإنشاء سياسة الخصوصية",
                        "DELETE": "حذف نهج الخصوصية",
                        "DRAFT": "صياغة سياسة الخصوصية",
                        "FORCE_DELETE": "فرض حذف سياسة الخصوصية",
                        "PUBLISH": "نشر سياسة الخصوصية",
                        "RESTORE": "استعادة سياسة الخصوصية",
                        "UPDATE": "قم بتحديث سياسة الخصوصية",
                        "VIEW": "استشر سياسة الخصوصية",
                        "VIEW_ANY": "استشر سياسات الخصوصية"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "قم بالتسجيل في القسم الاحترافي بأي دور لديه هذا الإذن"
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "ARCHIVE": "أرشفة تقرير",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالتقارير",
                        "CREATE": "قم بإنشاء تقرير",
                        "DELETE": "حذف تقرير",
                        "DRAFT": "صياغة تقرير",
                        "FORCE_DELETE": "فرض حذف تقرير",
                        "PROCESS_ANY": "معالجة أي تقرير",
                        "PUBLISH": "انشر تقريرًا",
                        "REJECT_ANY": "ارفض أي تقرير",
                        "RESTORE": "استعادة تقرير",
                        "UPDATE": "تحديث تقرير",
                        "VIEW": "استشر تقريرًا",
                        "VIEW_ANY": "استشر فهرس التقارير"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "ARCHIVE": "أرشفة الحجز",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالحجوزات",
                        "CANCEL": "إلغاء الحجز",
                        "CONFIRM": "تأكيد الحجز",
                        "CREATE": "إنشاء حجز",
                        "DELETE": "حذف حجز",
                        "FORCE_DELETE": "فرض حذف الحجز",
                        "RESTORE": "استعادة الحجز",
                        "UPDATE": "تحديث الحجز",
                        "VIEW": "استشر الحجز",
                        "VIEW_ANY": "راجع فهرس الحجوزات"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالأدوار",
                        "CREATE": "أنشئ دورًا",
                        "DELETE": "حذف دور",
                        "FORCE_DELETE": "فرض حذف دور",
                        "RESTORE": "استعادة دور",
                        "UPDATE": "تحديث دور",
                        "VIEW": "استشر دورًا",
                        "VIEW_ANY": "استشر فهرس الأدوار"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالمشاركة",
                        "CREATE": "أنشئ مشاركة",
                        "DELETE": "حذف حصة",
                        "FORCE_DELETE": "فرض حذف مشاركة",
                        "RESTORE": "استعادة حصة",
                        "UPDATE": "تحديث حصة",
                        "VIEW": "استشر سهم",
                        "VIEW_ANY": "استشر مؤشر الأسهم"
                    },
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بحسابات Stripe",
                        "HAVE": "لديك حساب Stripe"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالاشتراكات",
                        "CANCEL": "إلغاء الاشتراك",
                        "CREATE": "قم بإنشاء اشتراك",
                        "DELETE": "حذف الاشتراك",
                        "FORCE_DELETE": "فرض حذف الاشتراك",
                        "PAY": "دفع الاشتراك",
                        "RESTORE": "استعادة الاشتراك",
                        "UPDATE": "تحديث الاشتراك",
                        "VIEW": "استشارة الاشتراك",
                        "VIEW_ANY": "راجع فهرس الاشتراكات"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بطلب فئات الدعم",
                        "CREATE": "أنشئ فئة دعم",
                        "DELETE": "احذف فئة الدعم",
                        "FORCE_DELETE": "فرض حذف فئة الدعم",
                        "RESTORE": "استعادة فئة الدعم",
                        "UPDATE": "قم بتحديث فئة الدعم",
                        "VIEW": "استشر فئة الدعم",
                        "VIEW_ANY": "راجع فهرس فئات الدعم"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالرسائل",
                        "CREATE": "قم بإنشاء رسالة",
                        "DELETE": "احذف رسالة",
                        "FORCE_DELETE": "فرض حذف رسالة",
                        "RESTORE": "قم باستعادة رسالة",
                        "UPDATE": "قم بتحديث رسالة",
                        "VIEW": "استشر رسالة",
                        "VIEW_ANY": "استشر فهرس الرسائل"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "ADD_MESSAGE": "اضف رسالة",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بطلبات الدعم",
                        "CANCEL": "إلغاء طلب دعم",
                        "CREATE": "أنشئ طلب دعم",
                        "DELETE": "حذف طلب دعم",
                        "FORCE_DELETE": "فرض حذف طلب دعم",
                        "RESTORE": "استعادة طلب الدعم",
                        "UPDATE": "قم بتحديث طلب دعم",
                        "VIEW": "استشر طلب دعم",
                        "VIEW_ANY": "راجع فهرس طلبات الدعم"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالعلامات",
                        "CREATE": "قم بإنشاء علامة",
                        "DELETE": "حذف علامة",
                        "FORCE_DELETE": "فرض حذف علامة",
                        "RESTORE": "استعادة علامة",
                        "UPDATE": "تحديث علامة",
                        "VIEW": "استشر علامة",
                        "VIEW_ANY": "استشر فهرس العلامات"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "ARCHIVE": "أرشفة الشروط والأحكام",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالشروط والأحكام",
                        "CREATE": "إنشاء الشروط والأحكام",
                        "DELETE": "حذف الشروط والأحكام",
                        "DRAFT": "صياغة الشروط والأحكام",
                        "FORCE_DELETE": "فرض حذف الشروط والأحكام",
                        "PUBLISH": "نشر الشروط والأحكام",
                        "RESTORE": "استعادة الشروط والأحكام",
                        "UPDATE": "تحديث الشروط والأحكام",
                        "VIEW": "راجع الشروط والأحكام",
                        "VIEW_ANY": "راجع الشروط والأحكام"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالسجلات القابلة للترجمة",
                        "REQUIRE_ANY": "السماح بترجمة حقل مطلوب إلى لغة ليست بالضرورة الإنجليزية"
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة برسائل البريد الإلكتروني للمسافرين",
                        "CREATE": "أنشئ بريدًا إلكترونيًا للمسافر",
                        "DELETE": "حذف البريد الإلكتروني الخاص بالمسافر",
                        "FORCE_DELETE": "فرض حذف بريد إلكتروني للمسافر",
                        "RESTORE": "استعادة البريد الإلكتروني الخاص بالمسافر",
                        "UPDATE": "تحديث البريد الإلكتروني للمسافر",
                        "VIEW": "استشر بريدًا إلكترونيًا للمسافر",
                        "VIEW_ANY": "راجع فهرس رسائل البريد الإلكتروني للمسافرين"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "APPROVE": "اعتماد مجلد المسافر",
                        "ARCHIVE": "أرشفة مجلد المسافر",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بمجلدات المسافر",
                        "CREATE": "قم بإنشاء مجلد المسافر",
                        "DECLINE": "رفض مجلد المسافر",
                        "DELETE": "حذف مجلد المسافر",
                        "FORCE_DELETE": "فرض حذف حافظة المسافر",
                        "RESTORE": "استعادة مجلد المسافر",
                        "THANK": "شكرا للمسافر على الإقامة",
                        "UPDATE": "قم بتحديث مجلد المسافر",
                        "VIEW": "استشر مجلد المسافر",
                        "VIEW_ANY": "راجع فهرس مجلدات المسافر"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بعمليات التحقق من بيانات اعتماد المستخدم",
                        "CREATE": "إنشاء التحقق من بيانات اعتماد المستخدم",
                        "DELETE": "حذف التحقق من بيانات اعتماد المستخدم",
                        "FORCE_DELETE": "فرض حذف التحقق من بيانات اعتماد المستخدم",
                        "RESTORE": "استعادة التحقق من بيانات اعتماد المستخدم",
                        "UPDATE": "تحديث التحقق من بيانات اعتماد المستخدم",
                        "VIEW": "استشر التحقق من بيانات اعتماد المستخدم",
                        "VIEW_ANY": "راجع فهرس عمليات التحقق من بيانات اعتماد المستخدم"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "ADD_CONTACT": "إضافة مستخدم كجهة اتصال",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بالمستخدمين",
                        "CREATE": "قم بإنشاء مستخدم",
                        "DELETE": "حذف مستخدم",
                        "FORCE_DELETE": "فرض حذف مستخدم",
                        "INVITE": "قم بدعوة مستخدم",
                        "REPORT": "أبلغ عن مستخدم",
                        "RESTORE": "استعادة مستخدم",
                        "TIP": "نصيحة المستخدم",
                        "UPDATE": "تحديث مستخدم",
                        "UPDATE_BANK_ACCOUNT": "تحديث الحساب البنكي",
                        "VIEW": "استشر المستخدم",
                        "VIEW_ANY": "استشر فهرس المستخدمين",
                        "VIEW_AVATAR": "عرض الصورة الرمزية للمستخدم",
                        "VIEW_CONTACT_EMAIL": "عرض البريد الإلكتروني لجهة اتصال المستخدم",
                        "VIEW_CONTACT_PHONE": "عرض هاتف جهة اتصال المستخدم",
                        "VIEW_REAL_NAME": "عرض الاسم الحقيقي للمستخدم"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بإعدادات المستخدم الجغرافي",
                        "CREATE": "قم بإنشاء إعدادات تحديد الموقع الجغرافي للمستخدم",
                        "DELETE": "حذف أحد إعدادات تحديد الموقع الجغرافي للمستخدم",
                        "FORCE_DELETE": "فرض حذف إعداد تحديد الموقع الجغرافي للمستخدم",
                        "RESTORE": "استعادة إعدادات تحديد الموقع الجغرافي للمستخدم",
                        "UPDATE": "تحديث إعدادات تحديد الموقع الجغرافي للمستخدم",
                        "VIEW": "استشر إعدادات تحديد الموقع الجغرافي للمستخدم",
                        "VIEW_ANY": "راجع فهرس إعدادات المستخدم الجغرافي"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بإعدادات إعلام المستخدم",
                        "CREATE": "إنشاء إعداد إعلام المستخدم",
                        "DELETE": "حذف إعداد إعلام المستخدم",
                        "FORCE_DELETE": "فرض حذف إعداد إعلام المستخدم",
                        "RESTORE": "استعادة إعداد إعلام المستخدم",
                        "UPDATE": "تحديث إعداد إعلام المستخدم",
                        "VIEW": "راجع إعداد إعلام المستخدم",
                        "VIEW_ANY": "راجع فهرس إعدادات إعلام المستخدم"
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بإعدادات خصوصية المستخدم",
                        "CREATE": "قم بإنشاء إعداد خصوصية المستخدم",
                        "DELETE": "حذف إعداد خصوصية المستخدم",
                        "FORCE_DELETE": "فرض حذف إعداد خصوصية المستخدم",
                        "RESTORE": "استعادة إعدادات خصوصية المستخدم",
                        "UPDATE": "قم بتحديث إعداد خصوصية المستخدم",
                        "VIEW": "استشر إعدادات خصوصية المستخدم",
                        "VIEW_ANY": "راجع فهرس إعدادات خصوصية المستخدم"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "APPROVE_CONTACT": "الموافقة على علاقة المستخدم",
                        "BLOCK_CONTACT": "حظر علاقة المستخدم",
                        "BYPASS_PERMISSIONS": "تجاهل الأذونات المتعلقة بعلاقات المستخدمين",
                        "CREATE": "أنشئ علاقة مستخدم",
                        "DECLINE_CONTACT": "رفض علاقة المستخدم",
                        "DELETE": "حذف علاقة مستخدم",
                        "FORCE_DELETE": "فرض حذف علاقة مستخدم",
                        "RESTORE": "استعادة علاقة المستخدم",
                        "UNBLOCK_CONTACT": "قم بإلغاء حظر علاقة المستخدم",
                        "UPDATE": "قم بتحديث علاقة المستخدم",
                        "VIEW": "استشر علاقة المستخدم",
                        "VIEW_ANY": "استشر فهرس علاقات المستخدمين"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\AccessEnum": "وصول",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "سجلات النشاط",
                    "App\\Enums\\Permissions\\AddressEnum": "عناوين",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "مؤسسات التحويلات المصرفية",
                    "App\\Enums\\Permissions\\ChangelogEnum": "سجلات التغيير",
                    "App\\Enums\\Permissions\\ClaimEnum": "المطالبات",
                    "App\\Enums\\Permissions\\CountryEnum": "بلدان",
                    "App\\Enums\\Permissions\\CurrencyEnum": "العملات",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "حساب EasyTransac",
                    "App\\Enums\\Permissions\\ExcursionEnum": "مسارات",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "الربط بين مسارات الرحلات والنقاط المثيرة للاهتمام",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "موظفو المؤسسات",
                    "App\\Enums\\Permissions\\InteractionEnum": "التفاعلات",
                    "App\\Enums\\Permissions\\LocaleEnum": "اللغات",
                    "App\\Enums\\Permissions\\MediumEnum": "وسائط",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "تلقي الإخطارات",
                    "App\\Enums\\Permissions\\PartnerEnum": "شركاء",
                    "App\\Enums\\Permissions\\PaymentEnum": "المدفوعات",
                    "App\\Enums\\Permissions\\PermissionEnum": "أذونات",
                    "App\\Enums\\Permissions\\PlanEnum": "خطط الاشتراك",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "فئات نقاط الاهتمام",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "النقاط المثيرة للاهتمام",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "فئات المشاركات",
                    "App\\Enums\\Permissions\\PostEnum": "دعامات",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "سياسات الخصوصية",
                    "App\\Enums\\Permissions\\RegistrationEnum": "تسجيل",
                    "App\\Enums\\Permissions\\ReportEnum": "التقارير",
                    "App\\Enums\\Permissions\\ReservationEnum": "التحفظات",
                    "App\\Enums\\Permissions\\RoleEnum": "الأدوار",
                    "App\\Enums\\Permissions\\ShareEnum": "تشارك",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "حساب Stripe",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "الاشتراكات",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "فئات طلب الدعم",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "رسائل الدعم",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "طلبات الدعم",
                    "App\\Enums\\Permissions\\TagEnum": "العلامات",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "الأحكام والشروط",
                    "App\\Enums\\Permissions\\TranslatableEnum": "السجلات المترجمة",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "رسائل البريد الإلكتروني للمسافرين",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "مجلدات المسافر",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "عمليات التحقق من اعتماد المستخدم",
                    "App\\Enums\\Permissions\\UserEnum": "المستخدمون",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "إعدادات تحديد الموقع الجغرافي للمستخدم",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "إعدادات إخطار المستخدم",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "إعدادات خصوصية المستخدم",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "علاقات المستخدمين"
                }
            },
            "plans": {
                "archive": {
                    "success": "تمت أرشفة خطة (خطط) الاشتراك (إذا كانت مؤهلة)"
                },
                "confirmation": {
                    "benefit_current_subscription": "سوف تستمر في الاستفادة من اشتراكك الحالي\n            حتى نهاية الشهر المدفوع بالفعل.",
                    "engagement_period": "يتضمن هذا العرض فترة خطوبة تبلغ\n            ×1 بعد الدفعة الأولى.",
                    "fee": "يخضع هذا العرض لرسوم قدرها {fee_percentage}% ويؤهلك لذلك\n            احصل على {tip_percentage}% من إكرامياتك.",
                    "free": "هذا الاشتراك مجاني، ويخضع لإنشاء حساب لجمع الإكراميات.",
                    "free_period": "ستستفيد من فترة مجانية ×1.",
                    "free_period_used": "لقد استهلكت فترة فراغك بالفعل ولن تفعل ذلك\n            قادر على الاستفادة من {free_period} الذي يقترحه هذا العرض.",
                    "name": "أنت على وشك الاشتراك في عرض \"{name}\".",
                    "name_launch": "أنت على وشك الاشتراك في عرض الإطلاق \"{name}\".",
                    "no_fee": "يخضع هذا العرض لرسوم بنسبة 0%، وسيسمح لك بتلقي إكرامياتك بالكامل.",
                    "please_confirm": "يرجى تأكيد اشتراكك بالضغط على الزر أدناه.",
                    "price_summary": "هذا الاشتراك سوف يكلفك\n            ×1 باستثناء. الضرائب / {price_incl_taxes} شاملة. الضرائب شهريا.",
                    "replace_current_subscription": "سيحل هذا الاشتراك محل اشتراكك الحالي تلقائيًا."
                },
                "draft": {
                    "success": "تمت صياغة خطة (خطط) الاشتراك (إذا كانت مؤهلة)"
                },
                "free_period_ended_in": "تنتهي الفترات المجانية ×1",
                "publish": {
                    "success": "تم نشر خطة (خطط) الاشتراك (إذا كانت مؤهلة)"
                },
                "set_coming_soon": {
                    "success": "تم ضبط خطة (خطط) الاشتراك على \"قريبًا\" (إذا كانت مؤهلة)"
                },
                "your_current_plan": "خطتك الحالية"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "من خلال تحديد هذا المربع، أشهد أنني ممثل معتمد لـ\n            ادعى إنشاء. أي بيان كاذب أو انتحال شخصية سيعرضني للمحاكمة المدنية والجنائية\n            العقوبات وفقا للقوانين المعمول بها.",
                    "success": "لقد تم إرسال مطالبتك للتحقق منها."
                },
                "denied": {
                    "for_the_reason": "مرفوض للسبب التالي",
                    "moderation_again": "يمكنك تعديل نقطة الاهتمام، والتي سوف ترسلها مرة أخرى للإشراف، حتى يمكن نشرها."
                },
                "deny": {
                    "success": "تم رفض النقطة (النقاط) المثيرة للاهتمام (إذا كانت مؤهلة)"
                },
                "destroy": {
                    "confirm": "هل تريد حقًا حذف نقطة الاهتمام هذه: {point_of_interest_name}؟",
                    "success": "تم حذف نقطة الاهتمام"
                },
                "draft": {
                    "success": "تمت صياغة النقطة (النقاط) المثيرة للاهتمام (إذا كانت مؤهلة)"
                },
                "duplicates": {
                    "check_explanation1": "من أجل إنشاء نقطة اهتمامك،\n            يرجى ملء الحقول الرئيسية أدناه.",
                    "check_explanation2": "سيتم إجراء فحص لتجنب\n            إدخال نقطة موجودة بالفعل.",
                    "check_explanation3": "بعد التحقق، سوف تتمكن من الإكمال\n            معلومات نقطتك وحفظها.",
                    "check_explanation_title": "تَحَقّق",
                    "continue_creation": "مواصلة الإنشاء",
                    "found_explanation1": "واحد أو أكثر من النقاط المثيرة للاهتمام المقابلة\n            معايير الإدخال الخاصة بك موجودة بالفعل.",
                    "found_explanation2": "إذا كنت تعتقد أن هذه ليست نسخة مكررة،\n            ثم يمكنك المتابعة لإدخال نقطة اهتمامك الجديدة.",
                    "match_percentage": "مطابقة لـ {percentage}%",
                    "select_duplicate": "انقر على إحدى نقاط الاهتمام المقترحة لمعرفة التفاصيل",
                    "use_poi": "استخدم هذه النقطة المثيرة للاهتمام",
                    "view_poi": "عرض هذه النقطة المثيرة للاهتمام"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "أضف نقطة الاهتمام هذه إلى خط سير الرحلة",
                    "already_in_excursions": "نقطة الاهتمام هذه هي بالفعل جزء من مسارات الرحلات تلك",
                    "create_excursion": "إنشاء خط سير الرحلة",
                    "or_create_from": "أو قم بإنشاء خط سير جديد من نقطة الاهتمام هذه",
                    "select_which": "حدد خط سير الرحلة الحالي الذي تريد إضافة نقطة الاهتمام \"{point_of_interest_name}\" إليه",
                    "success": "تمت إضافة نقطة الاهتمام إلى خط سير الرحلة"
                },
                "form": {
                    "help": {
                        "stars": "فقط إذا كان من الممكن الإشارة إلى الفئة بالنجوم (مثل فندق، مطعم...)"
                    }
                },
                "import_source": {
                    "DataTourisme": "السياحة البيانات"
                },
                "index": {
                    "no_results": "لم يتم العثور على النقاط المثيرة للاهتمام",
                    "no_results_here": "لا توجد نقاط اهتمام متوفرة في منطقة البحث الخاصة بك.",
                    "no_tags": "لم يتم تحديد أي علامة",
                    "refresh_delayed": "يرجى الانتظار ×1 قبل التحديث",
                    "search": "ابحث عن نقطة اهتمام",
                    "try_more_filters": "يمكنك تغيير عوامل التصفية النشطة وتوسيع نطاق البحث."
                },
                "pages": {
                    "show": {
                        "automatic_update": "تحديث أوتوماتيكي",
                        "belongs_to_you": "ينتمي إليك",
                        "claim_dispute_pending": {
                            "details": "تفاصيل الطلب: {message}",
                            "notice": "النزاع المستمر بشأن المطالبة",
                            "title": "لديك نزاع مستمر حول المطالبة بنقطة الاهتمام هذه."
                        },
                        "deletion": {
                            "causes": {
                                "event": "السبب: حدث منتهي الصلاحية"
                            },
                            "title": "في انتظار الحذف"
                        },
                        "dt_required_datatourisme_fields": "تم التحديث بواسطة {dt_created_by} من {import_source} إلى {dt_last_update}",
                        "excursions_counter": "تمت إضافته ×1 مرات إلى مسارات الرحلات",
                        "is_geocoding_1": "يتم تحديد الموقع الجغرافي لنقطة الاهتمام.",
                        "is_geocoding_2": "يرجى مراجعة هذه الصفحة لاحقًا لمعرفة نقطة الاهتمام\n                على الخريطة.",
                        "is_refreshing_audio_1": "يتم الآن إنشاء ملف صوتي.",
                        "is_refreshing_audio_2": "يرجى مراجعة هذه الصفحة لاحقًا للاستماع إلى هذا الصوت.",
                        "not_current_locale": {
                            "change_locale": "يمكنك تغيير اللغة المعروضة باستخدام المحدد أعلاه",
                            "incomplete_translation": "بعض المعلومات الخاصة بنقطة الاهتمام هذه (العنوان و/أو الوصف و/أو العنوان) غير متوفرة باللغة المحددة.",
                            "missing_translations": "ترجمات مفقودة"
                        },
                        "opening_hours": {
                            "expired": "انتهت ساعات العمل",
                            "is_deleting": "سيتم حذف نقطة الاهتمام خلال {days} يومًا",
                            "show": "انظر ساعات العمل"
                        },
                        "sections": {
                            "audios": "صوتيات",
                            "gallery": "صالة عرض",
                            "logo": "شعار",
                            "main_image": "الصورة الرئيسية",
                            "map": "خريطة",
                            "media": "وسائط",
                            "poi": "نقطة الأهتمام",
                            "videos": "أشرطة فيديو"
                        },
                        "see_full_page": "انظر الصفحة كاملة"
                    }
                },
                "print": {
                    "element_not_found": "العنصر المطلوب طباعته \"{id}\" غير موجود!"
                },
                "publish": {
                    "success": "تم التحقق من صحة النقطة (النقاط) المثيرة للاهتمام ونشرها (إذا كانت مؤهلة)"
                },
                "report": {
                    "choose_reason": "اختر سببًا للإبلاغ عن نقطة الاهتمام هذه \"{point_of_interest_title}\"",
                    "heading": "الإبلاغ عن نقطة الاهتمام",
                    "success": "تم الإبلاغ عن نقطة الاهتمام!"
                },
                "search": {
                    "creator_id": "إظهار نقاطي فقط؟",
                    "no_results": "لم يتم العثور على نقاط الاهتمام...",
                    "tags_mode": {
                        "all": "الجميع",
                        "all_help": "حدد \"الكل\" للعثور على النقاط المثيرة للاهتمام\n                مع كافة العلامات المحددة.",
                        "one": "مرة على الأقل",
                        "one_help": "حدد \"واحدة على الأقل\" للعثور على النقاط المثيرة للاهتمام\n                مع واحدة على الأقل من العلامات المحددة."
                    }
                },
                "share": {
                    "heading": "مشاركة هذه النقطة المثيرة للاهتمام",
                    "select_which_contacts": "حدد جهات الاتصال التي تريد مشاركة نقطة الاهتمام \"{point_of_interest_title}\" معها",
                    "select_which_users": "حدد المستخدمين الذين ترغب في مشاركة نقطة الاهتمام \"{point_of_interest_title}\" معهم أو أدخل عنوان بريد إلكتروني لدعوة مسافر جديد",
                    "select_which_users_alt": "أدخل عنوان البريد الإلكتروني للشخص الذي تريد مشاركة الملف معه\n            \"{point_of_interest_title}\" نقطة الاهتمام.",
                    "success": "تمت مشاركة نقطة الاهتمام!"
                },
                "states": {
                    "draft": "مسودة",
                    "pending": "قيد الانتظار",
                    "published": "نشرت"
                },
                "store": {
                    "heading": "إنشاء نقطة اهتمام",
                    "success": "تم إنشاء نقطة الاهتمام"
                },
                "submit": {
                    "success": "النقطة (النقاط) المثيرة للاهتمام معلقة للإشراف (إذا كانت مؤهلة)"
                },
                "update": {
                    "alert": "سيؤدي تحديث هذه النقطة إلى تعطيل التحديث التلقائي من استيراد {import_source}",
                    "confirm": "هل تريد حقًا تحديث نقطة الاهتمام هذه: {point_of_interest_name}؟",
                    "heading": "تحرير هذه النقطة المثيرة للاهتمام",
                    "success": "تم تحديث نقطة الاهتمام"
                }
            },
            "posts": {
                "archive": {
                    "success": "تمت أرشفة المشاركة (المشاركات) (إذا كانت مؤهلة)"
                },
                "draft": {
                    "success": "تمت صياغة المشاركة (المشاركات) (إذا كانت مؤهلة)"
                },
                "publish": {
                    "success": "تم نشر المشاركة (المشاركات) (إذا كانت مؤهلة)"
                },
                "search": {
                    "type_to_search": "البحث في الفئة المختارة..."
                }
            },
            "prices": {
                "excl_taxes": "غير شامل. الضرائب",
                "free": "حر",
                "from": "من",
                "incl_taxes": "بما في ذلك الضرائب",
                "per_month": "كل شهر",
                "price_excl_taxes": "السعر باستثناء. الضرائب",
                "price_incl_taxes": "السعر شامل الضرائب",
                "vat_amount": "قيمة الضريبة",
                "vat_percentage": "نسبة ضريبة القيمة المضافة"
            },
            "privacy_policies": {
                "archive": {
                    "success": "تمت أرشفة سياسة (سياسات) الخصوصية (إذا كانت مؤهلة)"
                },
                "errors": {
                    "not_found": "لم يتم العثور على سياسة الخصوصية!"
                },
                "publish": {
                    "success": "لقد تم نشر سياسة الخصوصية"
                },
                "update": {
                    "acceptation": "يجب عليك قبول سياسة الخصوصية للمتابعة",
                    "new_version": "تم نشر نسخة جديدة من سياسة الخصوصية",
                    "success": "تم قبول سياسة الخصوصية!"
                }
            },
            "reports": {
                "process": {
                    "success": "تمت معالجة التقرير (التقارير) (إذا كان مؤهلاً)"
                },
                "reject": {
                    "success": "تم رفض التقرير (التقارير) (إذا كان مؤهلاً)"
                }
            },
            "reservations": {
                "archive": {
                    "confirm": "هل تريد حقًا أرشفة هذا الحجز؟",
                    "success": "تمت أرشفة الحجز"
                },
                "cancel": {
                    "confirm": "هل تريد حقًا إلغاء هذا الحجز؟",
                    "success": "تم إلغاء الحجز"
                },
                "confirm": {
                    "confirm": "هل تريد حقا تأكيد هذا الحجز؟",
                    "success": "لقد تم تأكيد الحجز"
                },
                "destroy": {
                    "confirm": "هل تريد حقًا حذف هذا الحجز؟",
                    "success": "تم حذف الحجز"
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "butler": "مسافر",
                                "poi": "مسافر",
                                "traveller": "مؤسسة"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "butler": "خدمات الاستقبال والإرشاد",
                            "poi": "نقطة الأهتمام",
                            "reservation": "حجز",
                            "traveller": "مسافر"
                        }
                    }
                },
                "states": {
                    "archived": "مؤرشف",
                    "canceled": "ألغيت",
                    "confirmed": "مؤكد",
                    "pending": "قيد الانتظار"
                },
                "store": {
                    "success": "تم إنشاء الحجز"
                },
                "update": {
                    "success": "تم تحديث الحجز"
                }
            },
            "settings": {
                "items": {
                    "about": "عن",
                    "geoloc_settings": "إعدادات تحديد الموقع الجغرافي",
                    "notification_settings": "إعدادات الإخطارات",
                    "privacy_setting": "إعدادات الخصوصية",
                    "profile": "حساب تعريفي",
                    "signOut": "خروج"
                }
            },
            "shares": {
                "emails": {
                    "select_hint": "يمكنك تحديد البريد الإلكتروني المستخدم مسبقًا من خلال النقر أدناه:",
                    "space_hint": "منفصلة بمساحة لمشاركة رسائل البريد الإلكتروني المتعددة"
                },
                "index": {
                    "no_results": "لم يتم العثور على أسهم!"
                },
                "link": {
                    "instructions": "انسخ هذا الرابط لدعوة أحد المسافرين ومشاركة خط سير الرحلة معه"
                },
                "show": {
                    "shared_by": "نشر بواسطة"
                },
                "type": {
                    "excursions": "مسار الرحلة",
                    "pointsofinterest": "نقطة الأهتمام"
                }
            },
            "states": {
                "changelog_state": {
                    "archived": "مؤرشف",
                    "draft": "مسودة",
                    "published": "نشرت"
                },
                "claim_dispute_state": {
                    "pending": "قيد الانتظار",
                    "rejected": "مرفوض",
                    "validated": "تم التحقق من صحتها"
                },
                "claim_state": {
                    "missing-info": "معلومات مفقودة",
                    "pending": "قيد الانتظار",
                    "rejected": "مرفوض",
                    "revoked": "إلغاء - فسخ",
                    "validated": "تم التحقق من صحتها"
                },
                "payment_state": {
                    "canceled": "ألغيت",
                    "completed": "مكتمل",
                    "failed": "فشل",
                    "pending": "قيد الانتظار",
                    "transferred": "نقل"
                },
                "plan_state": {
                    "archived": "مؤرشف",
                    "coming_soon": "قريباً",
                    "draft": "مسودة",
                    "published": "نشرت"
                },
                "point_of_interest_state": {
                    "deleted": "تم الحذف",
                    "denied": "رفض",
                    "draft": "مسودة",
                    "pending": "في انتظار الاعتدال",
                    "published": "نشرت"
                },
                "post_state": {
                    "archived": "مؤرشف",
                    "draft": "مسودة",
                    "published": "نشرت"
                },
                "privacy_policy_state": {
                    "archived": "مؤرشف",
                    "draft": "مسودة",
                    "published": "نشرت"
                },
                "report_state": {
                    "pending": "قيد الانتظار",
                    "processed": "تمت معالجتها",
                    "rejected": "مرفوض"
                },
                "reservation_state": {
                    "archived": "مؤرشف",
                    "canceled": "ألغيت",
                    "confirmed": "مؤكد",
                    "pending": "قيد الانتظار"
                },
                "subscription_state": {
                    "active": "نشيط",
                    "canceled": "ألغيت",
                    "pending": "في انتظار الدفع",
                    "suspended": "معلق"
                },
                "support_request_state": {
                    "archived": "مؤرشف",
                    "canceled": "ألغيت",
                    "pending-support": "في انتظار الدعم",
                    "pending-user": "في انتظار المستخدم",
                    "resolved": "تم الحل"
                },
                "terms_condition_state": {
                    "archived": "مؤرشف",
                    "draft": "مسودة",
                    "published": "نشرت"
                },
                "traveller_folder_state": {
                    "approved": "موافقة",
                    "archived": "مؤرشف",
                    "declined": "انخفض",
                    "pending": "قيد الانتظار"
                },
                "user_credential_verification_state": {
                    "canceled": "ألغيت",
                    "completed": "مكتمل",
                    "pending": "قيد الانتظار"
                },
                "user_tutorial_state": {
                    "claimed": "ادعى",
                    "done": "منتهي",
                    "employee-added": "وأضاف الموظف",
                    "pending": "قيد الانتظار"
                },
                "user_utm_campaign_state": {
                    "always_on": "دائما متاح"
                },
                "user_utm_medium_state": {
                    "owned_social": "شبكة اجتماعية"
                },
                "user_utm_source_state": {
                    "facebook": "فيسبوك",
                    "instagram": "انستغرام",
                    "linkedin": "ينكدين"
                },
                "users_relationship_state": {
                    "approved": "موافقة",
                    "blocked": "محظور",
                    "declined": "انخفض",
                    "pending": "قيد الانتظار"
                }
            },
            "stripe": {
                "connect": {
                    "account": {
                        "create": {
                            "completed": "اكتمل تكوين حساب Stripe",
                            "link_invalid": "فشل الاتصال بـ Stripe، يرجى المحاولة مرة أخرى"
                        },
                        "must_subscribe": "يجب أن تكون مشتركًا لإنشاء حساب Stripe الخاص بك.",
                        "show": {
                            "account_usage": "سيسمح حساب Stripe الخاص بك لمؤسستك بتلقي المدفوعات على UgoZER.",
                            "can_receive_payments": "أنت قادر على تلقي المدفوعات.",
                            "cannot_receive_payments": "لا يمكنك تلقي المدفوعات",
                            "fee_percentage": "سيتم تطبيق رسوم بنسبة {percentage}% على كل دفعة تتلقاها.",
                            "go_to_stripe": "انتقل إلى لوحة القيادة الشريطية",
                            "has_stripe_account_being_reviewed": "تتم مراجعة حسابك بواسطة Stripe.",
                            "no_account_yet": "ليس لديك حساب Stripe حتى الآن.",
                            "should_complete_stripe_onboarding": "يرجى إكمال تسجيلك مع Stripe وتزويدهم بالمعلومات المطلوبة."
                        }
                    }
                },
                "stripe": "شريط"
            },
            "subscriptions": {
                "activated_at": "التنشيط",
                "cancel": "يلغي",
                "cancelation_success": "يؤخذ الإلغاء بعين الاعتبار",
                "canceled_at": "الإلغاء",
                "confirm_cancel": "هل تريد حقًا إلغاء اشتراكك؟",
                "confirm_cancel_date": "ستستفيد من مزاياها حتى تاريخ الإلغاء الفعلي في {date}.",
                "confirm_cancel_now": "سيتم تفعيل الإلغاء على الفور.",
                "expiry": "انقضاء",
                "invoices": "الفواتير",
                "my_current_subscription": "اشتراكي الحالي",
                "price_excl_taxes": "السعر باستثناء. الضرائب",
                "price_incl_taxes": "السعر شامل الضرائب",
                "upcoming_subscription": "الاشتراك القادم"
            },
            "support_messages": {
                "show": {
                    "you": "أنت",
                    "your_question": "سؤالك"
                },
                "store": {
                    "heading": "اضف رسالة",
                    "success": {
                        "title": "تم إنشاء الرسالة. سيتم الرد على دعم الاتصال خلال 48 ساعة."
                    }
                }
            },
            "support_requests": {
                "actions": {
                    "archived": {
                        "success": "تم أرشفة الطلب"
                    },
                    "canceled": {
                        "success": "تم إلغاء الطلب"
                    },
                    "pending_support": {
                        "success": "في انتظار الدعم للإجابة"
                    },
                    "pending_user": {
                        "success": "في انتظار المستخدم للإجابة"
                    },
                    "resolved": {
                        "success": "تم حل الطلب"
                    }
                },
                "cancel": {
                    "confirm": "هل تريد حقًا إلغاء طلب الدعم هذا: {support_request_title}؟",
                    "error": "لا يمكن إلغاء طلب الدعم",
                    "success": "تم إلغاء طلب الدعم"
                },
                "index": {
                    "no_results": "ليس لديك أي طلب دعم",
                    "search": "البحث عن طلب دعم",
                    "your_requests": "طلبات الدعم الخاصة بك"
                },
                "pages": {
                    "create": {
                        "notice": "بيانات شخصية"
                    },
                    "index": {
                        "search": "البحث عن طلب دعم"
                    },
                    "show": {
                        "sections": {
                            "messages": "رسائل",
                            "request": "طلب"
                        }
                    }
                },
                "show": {
                    "add_request": "إضافة طلب دعم جديد"
                },
                "states": {
                    "archived": "مؤرشف",
                    "canceled": "ألغيت",
                    "pending-support": "في انتظار الدعم",
                    "pending-user": "في انتظار المستخدم",
                    "resolved": "تم الحل"
                },
                "store": {
                    "heading": "إنشاء طلب دعم جديد",
                    "success": {
                        "title": "تم إنشاء الطلب. سيتم الرد على دعم الاتصال خلال 48 ساعة."
                    }
                }
            },
            "terms_conditions": {
                "archive": {
                    "success": "تمت أرشفة الشروط والأحكام (إذا كانت مؤهلة)"
                },
                "errors": {
                    "not_found": "لم يتم العثور على الشروط والأحكام!"
                },
                "publish": {
                    "success": "تم نشر الشروط والأحكام"
                },
                "update": {
                    "acceptation": "يجب عليك قبول الشروط والأحكام للمتابعة",
                    "new_version": "تم نشر نسخة جديدة من الشروط والأحكام",
                    "success": "لقد تم قبول الشروط والأحكام!"
                }
            },
            "traveller_folders": {
                "messages": {
                    "approve": {
                        "confirm": "هل تريد حقًا تأكيد هذه الإقامة؟",
                        "success": "البقاء مؤكدة!"
                    },
                    "archive": {
                        "confirm": "هل تريد حقًا أرشفة هذه الإقامة؟",
                        "success": "تم أرشفة هذه الإقامة!"
                    },
                    "decline": {
                        "confirm": "هل تريد حقًا رفض هذه الإقامة؟",
                        "success": "رفض البقاء!"
                    }
                },
                "no_results": {
                    "approved": "لا توجد إقامات معتمدة",
                    "archived": "لا توجد إقامات مؤرشفة",
                    "declined": "لم يتم رفض الإقامات",
                    "pending": "لا طلبات الإقامة"
                },
                "pages": {
                    "form": {
                        "cannot_attach": {
                            "description": "هذا المستخدم موجود ولكن لا يمكن دعمه...",
                            "title": "الدعم غير ممكن"
                        },
                        "poi_empty_option": "إزالة نقطة الاهتمام",
                        "poi_label": "مؤسسة الكونسيرج",
                        "verification": {
                            "description": "أدخل عنوان البريد الإلكتروني للمسافر. إذا كان حساب المسافر موجودًا بالفعل، فسيكون موجودًا\n                    تكون مرتبطة مباشرة، وإلا فسيتعين عليك إدخال بعض المعلومات الإضافية لإنشاء ملف\n                    جديد.",
                            "title": "تَحَقّق"
                        }
                    },
                    "index": {
                        "pending_stays": "إقاماتك المعلقة",
                        "search": "البحث بين المسافرين بلدي",
                        "travellers_folders": "مجلدات المسافرين",
                        "your_stays": "إقامتك"
                    },
                    "qr_code_1": "يمكن للمسافر مسح رمز الاستجابة السريعة هذا للحصول على مجلد المسافر تلقائيًا\n            مع مؤسستك.",
                    "qr_code_2": "إذا لم يكن المسافر قد قام بالتسجيل بعد في UgoZER، فسيتم إعادة توجيهه إلى التسجيل\n            الصفحة وسيتم ربطها بمؤسستك في نهاية تسجيلها.",
                    "show": {
                        "fields": {
                            "state": "ولاية:"
                        },
                        "sections": {
                            "butler": "الكونسيرج ذات الصلة",
                            "folder": "مجلد",
                            "hotel": "الفندق",
                            "institution": "المؤسسة ذات الصلة",
                            "interactions": "التفاعلات",
                            "invitation": "دعوة مسافر",
                            "payments": "المدفوعات",
                            "reservations": "التحفظات",
                            "stay": "يقضي",
                            "thanks": "شكرًا لك",
                            "traveller": "مسافر"
                        }
                    },
                    "wizard": {
                        "choose_an_institution": "اختر مؤسسة",
                        "no_linked_institution": "يجب أن تكون مرتبطًا بمؤسسة قبل أن تتمكن من اصطحاب مسافر."
                    }
                },
                "placeholders": {
                    "butler": "فريق الاستقبال"
                },
                "states": {
                    "approved": "قبلت",
                    "archived": "مؤرشف",
                    "declined": "انخفض",
                    "pending": "قيد الانتظار",
                    "undefined": "غير معرف"
                },
                "store": {
                    "success": "تم إنشاء المجلد"
                },
                "tabs": {
                    "approved": "موافقة",
                    "archived": "مؤرشف",
                    "declined": "انخفض",
                    "pending": "قيد الانتظار"
                },
                "thank": {
                    "success": "تم إرسال الشكر"
                },
                "update": {
                    "success": "تم تحديث المجلد"
                }
            },
            "tutorials": {
                "extranet": {
                    "begin": {
                        "butler": {
                            "description": "من أجل السماح لك باستخدام تطبيق UgoZER في أفضل الظروف،\n                    ننصحك باتباع الخطوات التالية التي سترشدك في الخطوات الأولى\n                    التعامل وإعداد المعلمات لمساحة الكونسيرج الخاصة بك.",
                            "steps": {
                                "institution": {
                                    "content": "ابحث عن فندقك في قائمة المنشآت باستخدام شريط البحث و\n                            المرشحات المتاحة في القسم الأيسر من الصفحة. بمجرد العثور على الخاص بك\n                            المؤسسة، أنشئ طلبًا بالنقر فوق <strong>تقديم طلب للانضمام</strong>\n                            زر.",
                                    "description": "رابط إلى مؤسستك",
                                    "step_next": "ادعى",
                                    "step_state": "قيد الانتظار",
                                    "subtitle": {
                                        "link": "مؤسستي",
                                        "part_1": "اربطني بالفندق",
                                        "part_2": "يمكنك الارتباط بفندقك مباشرة بالصفحة"
                                    },
                                    "title": "مؤسسة"
                                }
                            }
                        },
                        "done": {
                            "description": "لقد أكملت الآن الخطوات المختلفة لإعداد تطبيق UgoZER.",
                            "faq": {
                                "link": "التعليمات",
                                "part_1": "إذا كنت بحاجة إلى مزيد من المساعدة، يمكنك العثور على مقالات في",
                                "part_2": "قسم من التطبيق"
                            },
                            "help": "يمكنك أيضًا العثور على مساعدة سياقية من خلال <strong>المساعدة</strong> الموجودة في بعض الصفحات.",
                            "title": "تهانينا!"
                        },
                        "hotelier": {
                            "description": "من أجل السماح لك باستخدام تطبيق UgoZER في أفضل الظروف،\n                    ننصحك باتباع الخطوات التالية التي سترشدك في الخطوات الأولى\n                    التعامل وإعداد المعلمات لمساحة مدير الفندق الخاص بك.",
                            "steps": {
                                "employees": {
                                    "description": "إدارة الكونسيرج الخاص بك",
                                    "step_next": "تمت إضافة الموظف",
                                    "step_state": "ادعى",
                                    "title": "البوابون"
                                }
                            }
                        },
                        "manager": {
                            "description": "من أجل السماح لك باستخدام تطبيق UgoZER في أفضل الظروف،\n                    ننصحك باتباع الخطوات التالية التي سترشدك في الخطوات الأولى\n                    التعامل وإعداد المعلمات لمساحة مدير الأعمال الأخرى الخاصة بك."
                        },
                        "messages": {
                            "claim": "لقد تم التحقق من صحة المطالبة الخاصة بك.",
                            "contest": "لقد تم إرسال نزاعك إلى فريقنا.",
                            "payment": "لقد تم تسجيل معلوماتك المصرفية بنجاح.",
                            "point_of_interest": "تم إنشاء نقطة الاهتمام، ويمكنك الآن المطالبة بها."
                        },
                        "no_tutorial": {
                            "content": "لم يتم إعداد أي برنامج تعليمي لهذا الدور أو أنك متصل كمسؤول.",
                            "title": "لا يوجد برنامج تعليمي متاح"
                        },
                        "notices": {
                            "add_butler": {
                                "content": "لاستخدام التطبيق، يجب عليك إنشاء حساب كونسيرج لمؤسستك\n                        والتي سيتم من خلالها إرسال المعلومات للمسافرين.",
                                "content_2": "لتفعيل حساب الكونسيرج، سيحتاج الكونسيرج الخاص بك إلى تأكيد بريده الإلكتروني\n                        عنوان وإنشاء كلمة المرور الخاصة بهم.",
                                "title": "إضافة بواب"
                            },
                            "add_employee": {
                                "content": "لاستخدام التطبيق، يجب عليك إنشاء حساب موظف لمؤسستك\n                        والتي سيتم من خلالها إرسال المعلومات للمسافرين.",
                                "content_2": "لتنشيط حساب الموظف، سيحتاج موظفك إلى تأكيد بريده الإلكتروني\n                        عنوان وإنشاء كلمة المرور الخاصة بهم.",
                                "title": "إضافة موظف"
                            }
                        },
                        "shared": {
                            "actions": {
                                "skip_step": "تخطي الخطوة",
                                "skip_tutorial": "تخطي البرنامج التعليمي"
                            },
                            "claims": {
                                "description": "المطالبة بمؤسساتك",
                                "documents": {
                                    "association": {
                                        "identity_piece": "إثبات هوية الممثل القانوني أثناء سير العمل\n                                    صالح",
                                        "statutes": "النظام الأساسي للجمعيات",
                                        "title": "إذا كنت جمعية"
                                    },
                                    "company": {
                                        "identity_piece": "إثبات هوية الممثل القانوني أثناء سير العمل\n                                    صالح",
                                        "kbis": "استخراج KBIS",
                                        "title": "إذا كنت شركة"
                                    },
                                    "description": "للمطالبة بمؤسستك، ستحتاج إلى إعداد ما يلي\n                                وثائق:",
                                    "individual": {
                                        "identity_piece": "قطعة إثبات هوية صالحة",
                                        "registration_document": "شهادة تسجيل العمل الذاتي",
                                        "title": "إذا كنت من أصحاب الأعمال التجارية الذاتية"
                                    },
                                    "label": "وثائق",
                                    "title": "وثائق المطالبة"
                                },
                                "notice": {
                                    "description": "يمكنك العثور على هذا الشرح بالنقر على <strong>تعليمات</strong>\n                                الزر الموجود في أعلى يمين قائمة النقاط المثيرة للاهتمام.",
                                    "title": "يرجى الملاحظة"
                                },
                                "step_next": "ادعى",
                                "step_state": "قيد الانتظار",
                                "title": "مطالبة"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "هل أنت متأكد أنك تريد تخطي هذه الخطوة؟",
                                    "success": "لقد تم تجاهل الخطوة جيدًا!"
                                },
                                "skip_tutorial": {
                                    "confirm": "هل أنت متأكد أنك تريد تخطي هذا البرنامج التعليمي؟",
                                    "success": "تم تجاهل البرنامج التعليمي بشكل جيد!"
                                }
                            },
                            "payments": {
                                "description": "تلقي النصائح",
                                "step_next": "منتهي",
                                "step_state": "تمت إضافة الموظف",
                                "steps": {
                                    "bank": {
                                        "create": "انقر فوق الزر <strong>إضافة حساب</strong> في\n                                    قسم <strong>الحساب البنكي</strong>"
                                    },
                                    "easytransac": {
                                        "connection": "اضغط على الرابط\n                                    <strong>انتقل إلى لوحة تحكم EasyTransac</strong> وقم بتسجيل الدخول باستخدام بيانات الاعتماد الخاصة بك\n                                        تلقى عن طريق البريد الإلكتروني",
                                        "create": "انقر فوق الزر <strong>إنشاء</strong> في\n                                    قسم <strong>EasyTransac</strong>",
                                        "created": "يتم إنشاء الحساب تلقائيًا بناءً على البيانات المدخلة\n                                    على ملفك الشخصي",
                                        "form": "املأ النموذج على EasyTransac لتفعيل حساب الدفع الخاص بك",
                                        "warning": {
                                            "description": "اعتمادًا على مستوى \"اعرف عميلك\" (KYC) الخاص بحساب EasyTransac الخاص بك، يمكنك القيام بذلك\n                                        أن تكون قادرًا على تلقي المدفوعات ولكن لا يمكنك تحويل الأموال إلى بنك عادي\n                                        حساب. الأموال إلى حساب مصرفي عادي. لهذا سوف تحتاج إلى مستوى KYC\n                                        2 وتقديم مستندات معينة في واجهة EasyTransac. إيزي ترانساك\n                                        واجهه المستخدم.",
                                            "title": "مستوى \"اعرف عميلك\"."
                                        }
                                    },
                                    "my_profile": {
                                        "part_1": "اذهب إلى الصفحة",
                                        "part_2": "ملفي"
                                    }
                                },
                                "subtitle_1": "لكي يتلقى الكونسيرج الإكراميات، يجب عليك إنشاء حساب\n                            على الموقع الإلكتروني لشريكنا EasyTransac.",
                                "subtitle_2": "لفعل هذا:",
                                "title": "قسط"
                            }
                        },
                        "tourist_office": {
                            "description": "من أجل السماح لك باستخدام تطبيق UgoZER في أفضل الظروف،\n                    ننصحك باتباع الخطوات التالية التي سترشدك في الخطوات الأولى\n                    التعامل وإعداد المعلمات لمساحة مكتبك السياحي.",
                            "steps": {
                                "employees": {
                                    "description": "إدارة موظفيك",
                                    "step_next": "تمت إضافة الموظف",
                                    "step_state": "ادعى",
                                    "title": "موظفين"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "من أجل السماح لك باستخدام تطبيق UgoZER في أفضل الظروف،\n                    ننصحك باتباع الخطوات التالية التي سترشدك في الخطوات الأولى\n                    التعامل وإعداد المعلمات لمساحة موظف مكتبك السياحي.",
                            "steps": {
                                "institution": {
                                    "content": "ابحث عن مكتبك السياحي في قائمة المنشآت باستخدام شريط البحث\n                            والمرشحات المتاحة في القسم الأيسر من الصفحة. بمجرد العثور على الخاص بك\n                            المؤسسة، أنشئ طلبًا بالنقر فوق <strong>تقديم طلب للانضمام</strong>\n                            زر.",
                                    "description": "رابط إلى مؤسستك",
                                    "step_next": "ادعى",
                                    "step_state": "قيد الانتظار",
                                    "subtitle": {
                                        "link": "مؤسستي",
                                        "part_1": "اربطني بمكتبي السياحي",
                                        "part_2": "يمكنك الارتباط بمكتبك السياحي مباشرة بالصفحة"
                                    },
                                    "title": "مؤسسة"
                                }
                            }
                        }
                    },
                    "dashboard": {
                        "claim": {
                            "steps": {
                                "claim_form": "ثم اتبع التعليمات الموجودة في نموذج المطالبة",
                                "claim_validation": "سيتم بعد ذلك التحقق من المطالبة والتحقق من صحتها من قبل فريقنا",
                                "dont_exist": "إذا لم تكن موجودة، فانقر على الزر <strong>إنشاء نقطة اهتمام\n                        </strong> ثم املأ النموذج. بمجرد إنشاء نقطة الاهتمام، انتقل إلى\n                        البطاقة وانقر على الزر <strong>مطالبة</strong>",
                                "exist": "إذا كانت مؤسستك موجودة، فانتقل إلى الملف الخاص بها وانقر على الزر <strong>\n                        مطالبة</strong>",
                                "filters": "استخدم عوامل التصفية والبحث للعثور على مؤسستك",
                                "poi_index": {
                                    "part_1": "اذهب الى",
                                    "part_2": "قائمة النقاط المثيرة للاهتمام"
                                }
                            },
                            "subtitle_1": "يمكنك المطالبة بمؤسسة (مؤسسات) واحدة (أو أكثر) لإدارتها.",
                            "subtitle_2": "لنفعل ذلك:",
                            "title": "المطالبة بملكية مؤسستي وإدارتها"
                        },
                        "employees": {
                            "link": "لإنشاء طلب، يمكنهم الانتقال إلى الصفحة <strong>مؤسستي</strong>\n                    من المساحة الخاصة بهم وملء النموذج.",
                            "manage": "في هذه الصفحة، يمكنك قبول أو رفض الموظفين الذين يرغبون في الارتباط بشركتك\n                    مؤسسة.",
                            "subtitle": {
                                "part_1": "يمكنك إدارة موظفيك مباشرة عبر الصفحة",
                                "part_2": "موظفيني"
                            },
                            "title": "إدارة الموظفين لدي"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "distance": "<strong>المكان:</strong> يسمح لك بتصفية النقاط من موقع محدد",
                            "main_title": "المرشحات",
                            "subtitle": "يمكنك تصفية قائمة مسارات الرحلات وفقًا للمعايير التالية:",
                            "title": "تصفية مسارات الرحلة",
                            "type": {
                                "customized": "<strong>مخصصة:</strong> مسارات رحلات تم إنشاؤها خصيصًا للمسافر",
                                "explanation": "<strong>النوع:</strong> يسمح لك بالتصفية حسب نوع خط سير الرحلة",
                                "pro": "<strong>UgoZER Pro:</strong> مسارات الرحلات المقدمة من UgoZER",
                                "root": "<strong>النماذج الخاصة بي:</strong> نموذج لخطوط السير"
                            }
                        }
                    },
                    "institution_employee": {
                        "approve": {
                            "content": "يتيح لك هذا الإجراء قبول طلب من أحد موظفيك للانضمام إلى أحدهم\n                    مؤسساتك.",
                            "main_title": "يعتمد",
                            "title": "الموافقة على الموظف"
                        },
                        "cancel": {
                            "content": "يتيح لك هذا الإجراء إلغاء طلب دعوة الموظف في حالة حضوره\n                    حالة وجود خطأ، على سبيل المثال.",
                            "main_title": "يلغي",
                            "title": "إلغاء طلب الموظف"
                        },
                        "decline": {
                            "content": "يتيح لك هذا الإجراء رفض طلب أحد موظفيك للانضمام إلى أحد موظفيك\n                    المؤسسات.",
                            "main_title": "رفض",
                            "title": "رفض الموظف"
                        },
                        "functioning": {
                            "content": {
                                "invite": "في هذه الصفحة، يمكنك إما دعوة الموظفين أو قبول/رفض الطلبات المقدمة منك\n                        موظفين.",
                                "section": "يتيح لك هذا القسم إدارة موظفي مؤسساتك."
                            },
                            "main_title": "تسيير",
                            "title": "عمل قسم الموظفين"
                        },
                        "invite": {
                            "content": {
                                "button": "من خلال النقر على زر <strong>دعوة الموظف</strong>، يمكنك دعوة أحد موظفيك\n                        الموظفين سواء كان لديهم بالفعل حساب UgoZER أم لا.",
                                "form": "للقيام بذلك، ما عليك سوى اتباع الإرشادات الموجودة في النموذج خطوة بخطوة الذي يظهر.\n                        الشكل الذي يظهر."
                            },
                            "main_title": "يدعو",
                            "title": "دعوة موظف"
                        },
                        "remove": {
                            "content": "يتيح لك هذا الإجراء إزالة مستخدم من موظفيك في حالة حدوث ذلك\n                    الموظف يترك شركتك. في حالة مغادرة الموظف لشركتك.",
                            "main_title": "يزيل",
                            "title": "إزالة موظف"
                        }
                    },
                    "points_of_interest": {
                        "claim": "مطالبة",
                        "filters": {
                            "category": "<strong>الفئة :</strong> تسمح لك باختيار فئة معينة\n                    (مطعم الفندق...)",
                            "city": "<strong>المدينة :</strong> تسمح لك بالتصفية حسب مدينة معينة",
                            "country": "<strong>البلدان :</strong> تسمح لك بالتصفية في بلد محدد واحد أو أكثر",
                            "distance": "<strong>المكان :</strong> يسمح لك بتصفية النقاط من موقع محدد",
                            "main_title": "المرشحات",
                            "measure_unit": "<strong>وحدة القياس :</strong> تسمح لك بتغيير وحدة القياس المستخدمة\n                    مرشح المسافة والعرض",
                            "my_points": "<strong>إظهار نقاطي فقط :</strong> يعرض فقط النقاط التي قمت بإنشائها أو\n                    ادعى من قبلك",
                            "postal_code": "<strong>Zip :</strong> يسمح لك بالتصفية حسب الرمز البريدي",
                            "radius": "<strong>الحد من نصف القطر :</strong> يسمح بتصفية النقاط في دائرة محددة\n                    نصف القطر",
                            "subtitle": "يمكنك تصفية قائمة النقاط المثيرة للاهتمام وفقًا للمعايير التالية:",
                            "tags": "<strong>التحسين :</strong> يتيح لك تحسين البحث حسب العلامات لتحديد الفئة.\n                    من الممكن أيضًا تحديد ما إذا كنت تريد البحث عن النقاط بواحدة أو جميعها أو جميعها\n                    العلامات المحددة",
                            "title": "تصفية النقاط المثيرة للاهتمام"
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "validate": {
                    "success": "تم التحقق من صحة بيانات الاعتماد بنجاح (إذا كانت مؤهلة)"
                },
                "verify": {
                    "already_verified": "تم التحقق من بيانات الاعتماد الخاصة بك بالفعل",
                    "expired": "انتهت صلاحية الرابط، اطلب رابطًا جديدًا",
                    "impossible": "لم يعد التحقق من بيانات الاعتماد ممكنًا بعد الآن",
                    "not_received": "لم تحصل على رابط التأكيد؟",
                    "sent": {
                        "again": "أعد الإرسال",
                        "correct": "قم بتصحيح بيانات الاعتماد المختارة",
                        "email_1": "سيتم إرسال بريد إلكتروني إلى {email}، إذا لم يكن هذا العنوان قيد الاستخدام بالفعل.",
                        "email_2": "تحتوي هذه الرسالة الإلكترونية على رابط يسمح لك بتأكيد عنوانك.",
                        "email_3": "سيتم تحديث بريدك الإلكتروني بمجرد دخولك إلى الرابط.",
                        "refresh": "هل قمت بالنقر على الرابط؟ قم بتحديث التطبيق",
                        "title": "تم إرسال الرابط",
                        "wait_before": "يرجى الانتظار ×1 ثانية قبل الإرسال مرة أخرى"
                    },
                    "success": "لقد تم التحقق من بيانات الاعتماد الخاصة بك بنجاح"
                }
            },
            "user_geoloc_settings": {
                "geolocation": {
                    "error": {
                        "allow_browser": "اسمح لمتصفحك بتحديد موقعك الجغرافي في إعدادات هاتفك",
                        "allow_site": "اسمح لـ UgoZER بتحديد موقعك الجغرافي في إعدادات المتصفح الخاص بك",
                        "intro": "لعرض النقاط المثيرة للاهتمام من حولك:",
                        "title": "فشل تحديد الموقع الجغرافي"
                    },
                    "intro": "يتيح لك تحديد الموقع الجغرافي العثور على أقرب النقاط المثيرة للاهتمام."
                },
                "not_configured": {
                    "body1": "حدد موقعًا من اختيارك لعرض مجموعة مختارة من النقاط المثيرة للاهتمام بالقرب منه.",
                    "body2": "من الممكن أيضًا عرض النقاط المثيرة للاهتمام القريبة منك فقط.",
                    "do_not_ask_again": "لا تسألني مرة أخرى حتى يتم إعادة تشغيل التطبيق",
                    "subject": "لم تقم بعد بتحديد إعدادات الموقع الجغرافي الخاصة بك."
                },
                "update": {
                    "success": "تم تحديث إعدادات تحديد الموقع الجغرافي"
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "تم تحديث إعدادات الإشعارات"
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "تم تحديث إعدادات الخصوصية"
                }
            },
            "users": {
                "butlers": "البوابون",
                "finish": {
                    "success": "تم تغيير البرنامج التعليمي لإنهاء الحالة"
                },
                "index": {
                    "hint": "يمكن العثور على المستخدم من خلال لقبه أو هويته العامة.",
                    "no_results": "لم يتم العثور على المستخدم",
                    "search": "ابحث عن مستخدم"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "delete": "احذف حسابي؟",
                            "profile": "ملفي"
                        }
                    }
                },
                "profile": {
                    "alerts": {
                        "blocked": {
                            "message": "لم يعد بإمكانك التفاعل مع هذا المستخدم.",
                            "title": "هذا المستخدم قام بحظرك..."
                        },
                        "missing_infos": {
                            "message": "من أجل الاستفادة من كافة مميزات التطبيق، يرجى إكمال المعلومات التالية:",
                            "title": "أكمل ملفك الشخصي"
                        },
                        "pending_validation": {
                            "message": "هذا المستخدم لم يقبل طلبك بعد.",
                            "title": "في انتظار التحقق من صحة"
                        }
                    },
                    "avatar": {
                        "defaults": {
                            "female": "أنثى",
                            "male": "ذكر"
                        },
                        "section": {
                            "cta": "استخدم هذه الصورة الرمزية",
                            "description": "يمكنك تحديد صورة رمزية محددة مسبقًا من القائمة أدناه",
                            "title": "اختيار الصورة الرمزية المحددة مسبقا"
                        },
                        "success": "لقد تم تحديث الصورة الرمزية الخاصة بك بنجاح!"
                    },
                    "bank_account_number_missing": "يرجى تقديم المعلومات المصرفية الخاصة بك لتلقي المدفوعات الخاصة بك.",
                    "delete_account": {
                        "easy_transac": "لن يتم حذف حساب EasyTransac الخاص بك.",
                        "instructions": "لتأكيد الحذف النهائي لحسابك، يرجى إدخال كلمة \"حذف\" في الحقل أدناه والنقر على زر \"تأكيد\".",
                        "success": "لقد تم حذف حسابك.",
                        "title": "حذف الحساب",
                        "warning": "سيتم حذف حسابك، بالإضافة إلى جميع المعلومات المتعلقة بحسابك، على الفور دون إمكانية استردادها."
                    },
                    "edit_email": "تحرير البريد الإلكتروني الخاص بك",
                    "edit_password": "قم بتحرير كلمة المرور الخاصة بك",
                    "edit_phone": "قم بتحرير رقم هاتفك",
                    "edit_your_profile": "عدل ملفك الشخصي",
                    "email_missing": "لم تقم بملء البريد الإلكتروني الذي تم التحقق منه.",
                    "fill_country": "يرجى تحديد بلدك",
                    "form": {
                        "poi_label": "مؤسسة مرتبطة"
                    },
                    "manage_my_subscription": "إدارة اشتراكي",
                    "password_missing": "لم تقم بملء كلمة المرور.",
                    "phone_missing": "لم تقم بملء رقم هاتف تم التحقق منه",
                    "preferences": "التفضيلات",
                    "public_id": "أو استخدم هذا المعرف:",
                    "qr_code": "مسح لي لرؤية ملفي الشخصي",
                    "security": "حماية",
                    "share_profile": "مشاركة ملفك الشخصي",
                    "subscribe_to_receive_payments": "يجب أن تكون مشتركا لتلقي المدفوعات.",
                    "unnamed": "لا اسم",
                    "your_personal_information": "معلوماتك الشخصية"
                },
                "report": {
                    "choose_reason": "اختر سببًا للإبلاغ عن هذا المستخدم \"{user_name}\"",
                    "heading": "الإبلاغ عن مستخدم",
                    "success": "تم الإبلاغ عن المستخدم!"
                },
                "search": {
                    "no_results": "لم يتم العثور على مستخدمين"
                },
                "send_link": {
                    "error": "لا يمكن إرسال الرابط، هل لدى المستخدم بريد إلكتروني؟",
                    "success": "تم إرسال رابط التطبيق"
                },
                "update": {
                    "success": "تم تحديث الملف الشخصي"
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "لم يتم العثور على جهات اتصال",
                    "pending_requests": "جهات الاتصال المعلقة الخاصة بك"
                },
                "messages": {
                    "add": {
                        "confirm": "هل تريد حقًا إضافة جهة الاتصال هذه؟",
                        "error": "حدث خطأ أثناء طلب الاتصال...",
                        "success": "تم طلب الاتصال بنجاح!"
                    },
                    "approve": {
                        "confirm": "هل تريد حقًا قبول طلب الاتصال هذا؟",
                        "success": "تم قبول طلب الاتصال!"
                    },
                    "block": {
                        "confirm": "هل تريد حقًا حظر هذا المستخدم؟",
                        "success": "تم حظر جهة الاتصال هذه!"
                    },
                    "decline": {
                        "confirm": "هل تريد حقًا رفض طلب الاتصال هذا؟",
                        "success": "تم رفض طلب الاتصال!"
                    },
                    "unblock": {
                        "confirm": "هل تريد حقًا إلغاء حظر هذا المستخدم؟",
                        "success": "تم إلغاء حظر جهة الاتصال هذه!"
                    }
                },
                "no_results": {
                    "approved": "لا توجد جهات اتصال معتمدة",
                    "blocked": "لا توجد جهات اتصال محظورة",
                    "pending": "لا توجد طلبات اتصالات معلقة",
                    "sent": "لم يتم إرسال طلبات جهات الاتصال"
                },
                "search": {
                    "no_results": "لم يتم العثور على جهات اتصال"
                },
                "tabs": {
                    "approved": "موافقة",
                    "blocked": "محظور",
                    "pending": "قيد الانتظار",
                    "sent": "مرسل"
                }
            }
        },
        "validation": {
            "accepted": "يجب قبول {attribute}.",
            "active_url": " {attribute} ليس عنوان URL صالحًا.",
            "after": "يجب أن يكون {attribute} تاريخًا بعد {date}.",
            "after_or_equal": "يجب أن يكون {attribute} تاريخًا بعد أو يساوي {date}.",
            "alpha": "قد يحتوي {attribute} على أحرف فقط.",
            "alpha_dash": "قد يحتوي {attribute} فقط على أحرف وأرقام وشرطات وشرطات سفلية.",
            "alpha_num": "قد يحتوي {attribute} على أحرف وأرقام فقط.",
            "array": "يجب أن يكون {attribute} مصفوفة.",
            "attributes": {
                "accounting_file": "ملف المحاسبة",
                "actions": "أجراءات",
                "activated_at": "تفعيلها على",
                "active": "نشيط",
                "additional_information": "معلومات إضافية",
                "address": "عنوان",
                "addresses": "عناوين",
                "admin_message": "رسالة",
                "administrative_area": "منطقة إدارية",
                "amount": "كمية",
                "app": "طلب",
                "app_fees": "مصاريف",
                "approved_at": "تمت الموافقة عليه عند",
                "archived_at": "مؤرشفة في",
                "association_legal_representative_identity_piece": "إثبات هوية الممثل القانوني",
                "association_statutes": "القوانين",
                "attached_files": "الملفات المرفقة",
                "audio": "صوتي",
                "audio_refreshing": "تحديث الصوت",
                "audios": "صوتيات",
                "authenticated_once": "مصدق مرة واحدة",
                "auto_renewal_enabled": "تم تمكين التجديد التلقائي",
                "avatar": "الصورة الرمزية",
                "bank_account": "حساب البنك",
                "bank_account_number": "رقم الحساب المصرفي",
                "bank_bic": "بيك",
                "bank_city": "مدينة البنوك",
                "bank_country": "بنك الدولة",
                "bank_file": "ملف البنك",
                "bank_iban": "رقم الحساب المصرفي الدولي",
                "bank_name": "اسم البنك",
                "bank_payee": "المستفيد",
                "bank_transfer": "التحويل المصرفي",
                "bank_transfer_institutions": "مؤسسة التحويلات المصرفية",
                "bank_transfer_reason": "سبب النقل",
                "butler": "خدمات الاستقبال والإرشاد",
                "butler_point_of_interest": "مؤسسة الكونسيرج",
                "canceled_at": "تم الإلغاء عند",
                "categories": "فئات",
                "category": "فئة",
                "category_id": "معرف الفئة",
                "causer": "المسبب",
                "causer_id": "معرف المسبب",
                "causer_type": "نوع المسبب",
                "certified": "معتمد",
                "city": "مدينة",
                "claim_confirmation": "تأكيد المطالبة",
                "code": "شفرة",
                "collection_name": "اسم المجموعة",
                "communication_consent": "الاتصالات الشخصية",
                "communication_consent_alt": "مجال الاتصالات؟",
                "company_legal_representative_identity_piece": "إثبات هوية الممثل القانوني",
                "company_registration_document": "وثيقة تسجيل",
                "company_statutes": "القوانين",
                "company_type": "نوع الشركة",
                "completed_at": "اكتمل في",
                "contact": "اتصال",
                "contact_email": "تواصل بالبريد الاكتروني",
                "contact_phone": "هاتف الاتصال",
                "content": "محتوى",
                "contestant": "المتسابق",
                "contestant_company_type": "نوع الشركة",
                "contestant_email": "بريد إلكتروني",
                "contestant_first_name": "الاسم الأول",
                "contestant_last_name": "اسم العائلة",
                "contestant_message": "رسالة",
                "contestant_phone": "هاتف",
                "conversions_disk": "قرص التحويل",
                "coords": "الإحداثيات",
                "countries": "بلدان",
                "countries_codes": "بلدان",
                "country": "دولة",
                "country_code": "الرقم الدولي",
                "created": "مخلوق",
                "created_at": "أنشئت في",
                "creator": "المنشئ",
                "credential": "الاعتماد",
                "currency": "عملة",
                "current_password": "كلمة السر الحالية",
                "custom_amount": "المبلغ المخصص",
                "custom_properties": "خصائص مخصصة",
                "customers_qty": "الكمية للعملاء",
                "date": "تاريخ",
                "dates": "بلح",
                "days": "أيام",
                "declined_at": "رفض عند",
                "deleted_at": "تم حذفه عند",
                "deletion_confirmation": "تأكيد الحذف",
                "denial_reason": "سبب الرفض",
                "dependent_locality": "محلية تابعة",
                "description": "وصف",
                "details": "تفاصيل",
                "disk": "القرص",
                "display": "عرض",
                "displayed_name": "اسم عرض",
                "distance": "مسافة",
                "documents": "وثائق",
                "dt_created_by": "انشأ من قبل",
                "dt_last_update": "آخر تحديث DataTourisme",
                "dynamic_translations_enabled": "تمكين الترجمات الديناميكية",
                "email": "بريد إلكتروني",
                "emails_must_different": "لا يمكن أن يكون لكلا الحسابين نفس عنوان البريد الإلكتروني.",
                "employee": "موظف",
                "end_date": "تاريخ الانتهاء",
                "engaged_until": "مخطوب حتى",
                "engagement_period": "فترة الخطوبة",
                "event": "حدث",
                "excursion": "مسار الرحلة",
                "existing_user": "مستخدم موجود",
                "expirable": "قابل للانتهاء",
                "expired_at": "انتهت عند",
                "expired_on": "تاريخ انتهاء الإقامة",
                "expiry": "انتهاء الصلاحية",
                "expiry_date": "تاريخ الانتهاء",
                "extranet": "إكسترانت",
                "fallback_locale": "اللغة الاحتياطية",
                "favorite": "خط سير الرحلة المفضل",
                "featured": "متميز",
                "file": "ملف",
                "file_name": "اسم الملف",
                "filter": "منقي",
                "financial": "مالي",
                "first_name": "الاسم الأول",
                "formatted_address": "عنوان منسق",
                "free_period": "فترة حرة",
                "free_period_ended_at": "انتهت الفترة المجانية في",
                "front": "أمام",
                "gallery": "صالة عرض",
                "gdpr": "اللائحة العامة لحماية البيانات",
                "general": "عام",
                "generated_conversions": "ولدت التحويلات",
                "gross_amount": "المبلغ الإجمالي",
                "has_employees": "لديها موظفين",
                "hour": "ساعة",
                "hours": "ساعات",
                "id": "بطاقة تعريف",
                "image": "صورة",
                "images": "الصور",
                "import_source": "مصدر الاستيراد",
                "import_synchronizing": "تنشيط التحديث التلقائي",
                "individual_first_identity_piece": "قطعة الهوية",
                "individual_registration_document": "وثيقة تسجيل",
                "individual_second_identity_piece": "قطعة الهوية الثانية",
                "information": "معلومة",
                "institution": "مؤسسة",
                "institution_address": "عنوان المؤسسة",
                "institution_id": "مؤسسة",
                "institution_title": "عنوان المؤسسة",
                "interaction_type": "نوع التفاعل",
                "invitation": "دعوة",
                "invitation_accepted": "قبلت الدعوة",
                "invitation_status": "حالة الدعوة",
                "invited_at": "مدعو في",
                "invited_by": "بدعوة من",
                "invitees": "المدعوين",
                "is_geocoding": "تحديد الموقع الجغرافي قيد التقدم",
                "iso_code": "كود ISO",
                "items": "أغراض",
                "key": "مفتاح",
                "kind": "عطوف",
                "label": "ملصق",
                "last_geoloc": "آخر تحديد الموقع الجغرافي",
                "last_logged_at": "آخر تسجيل في",
                "last_login_ip": "آخر عنوان IP لتسجيل الدخول",
                "last_name": "اسم العائلة",
                "last_validated_at": "التحقق الأخير",
                "lat": "خط العرض",
                "latitude": "خط العرض",
                "line1": "العنوان سطر 1",
                "line2": "سطر العنوان 2",
                "link": "وصلة",
                "lng": "خط الطول",
                "locale": "لغة",
                "login": "اتصال",
                "logo": "شعار",
                "longitude": "خط الطول",
                "manipulations": "التلاعب",
                "max_amount_per_checkout": "الحد الأقصى للمبلغ لكل الخروج",
                "measurement_system": "نظام القياس",
                "media": "وسائط",
                "message": "رسالة",
                "metadata": "البيانات الوصفية",
                "mime_type": "نوع التمثيل الصامت",
                "min_amount_per_checkout": "الحد الأدنى للمبلغ لكل الخروج",
                "misc": "متنوع",
                "model": "سِجِلّ",
                "model_type": "نوع السجل",
                "name": "اسم",
                "net_amount": "كمية الشبكة",
                "new_password": "كلمة المرور الجديدة",
                "new_password_confirmation": "تأكيد كلمة السر الجديدة",
                "next_payment_at": "الدفعة التالية",
                "nickname": "كنية",
                "not_specified": "غير محدد",
                "not_validated_activities": "لم يتم التحقق من صحتها الأنشطة",
                "notes": "مزيد من المعلومات",
                "opening_hours": "ساعات العمل",
                "order_column": "طلب",
                "origin": "أصل",
                "owner": "مالك",
                "owner_email": "بريد إلكتروني",
                "owner_first_name": "الاسم الأول",
                "owner_last_name": "اسم العائلة",
                "owner_phone": "هاتف",
                "password": "كلمة المرور",
                "password_confirmation": "تأكيد كلمة المرور",
                "passwords_must_different": "لا يمكن أن يكون لكلا الحسابين نفس كلمة المرور.",
                "payable": "مستحق الدفع",
                "payee": "المستفيد",
                "payee_country": "دولة",
                "payee_email": "بريد إلكتروني",
                "payee_id": "المستفيد",
                "payee_name": "اسم المستفيد",
                "payee_phone": "هاتف",
                "payer": "دافع",
                "payer_country": "دولة",
                "payer_email": "بريد إلكتروني",
                "payer_id": "دافع",
                "payer_name": "اسم الدافع",
                "payer_phone": "هاتف",
                "payment_received": "تم استلام الدفع",
                "payment_service": "خدمة الدفع",
                "payment_service_account_id": "حساب دفع الهوية",
                "payment_service_transaction_id": "معاملة دفع الهوية",
                "payment_thanks": "شكرا الدفع",
                "payment_type": "نوع الدفع",
                "period": "فترة",
                "phone": "هاتف",
                "plan": "يخطط",
                "point_of_interest": "نقطة الأهتمام",
                "point_of_interest_categories": "فئات",
                "point_of_interest_category": "فئة",
                "point_of_interest_event_expired": "انتهت صلاحية الحدث",
                "points_of_interest": "النقاط المثيرة للاهتمام",
                "points_of_interest_count": "نقاط الاهتمام العد",
                "post_category": "فئة آخر",
                "price": "سعر",
                "price_excluding_taxes": "السعر باستثناء الضرائب",
                "prices": "الأسعار",
                "priority": "أولوية",
                "privacy_policy": "سياسة الخصوصية",
                "privacy_policy_accepted": "سياسة الخصوصية مقبولة",
                "privacy_policy_version": "إصدار سياسة الخصوصية",
                "private": "خاص",
                "private_title": "عنوان خاص",
                "pro_file": "حساب تعريفي",
                "profile_picture": "الصوره الشخصيه",
                "properties": "ملكيات",
                "properties_comparison": "الخصائص المحدثة",
                "provider": "مزود",
                "public": "عام",
                "public_id": "المعرف العام",
                "published_at": "نشرت في",
                "quantity": "كمية",
                "quitted_at": "تم الاستغناء عنه في",
                "radius": "نصف القطر",
                "rates": "معدلات",
                "reason_details": "تفاصيل",
                "reason_type": "سبب",
                "recipient": "متلقي",
                "recipients": "المتلقين",
                "recipients_alt": "مسافرين مسجلين",
                "remember": "تذكرنى",
                "removed_at": "تمت إزالته في",
                "reports": "التقارير",
                "requested_at": "طلبت في",
                "reservation_created": "تم إنشاء الحجز",
                "reservation_date": "تاريخ",
                "reservation_time": "وقت",
                "resolved_at": "حل في",
                "responsive_images": "الصور المستجيبة",
                "roles": "الأدوار",
                "room_number": "رقم الغرفة",
                "scout_searchable": "قابل للفهرسة",
                "searchable": "قابل للبحث",
                "sender": "مرسل",
                "senders": "المرسلين",
                "service": "خدمة",
                "settings": "إعدادات",
                "share_created": "تم إنشاء المشاركة",
                "shareable": "قابل للمشاركة",
                "show_contact_email": "رؤية البريد الإلكتروني للاتصال",
                "show_contact_phone": "الاتصال برؤية الهاتف",
                "show_profile_picture": "رؤية الصورة الرمزية",
                "show_real_name": "رؤية الاسم الأول والأخير",
                "siret": "سيريت",
                "size": "مقاس",
                "source": "مصدر",
                "source_id": "معرف المصدر",
                "specified": "محدد",
                "starable": "لاحظ بالنجوم",
                "stars": "النجوم",
                "start_date": "تاريخ البدء",
                "state": "ولاية",
                "states": "تنص على",
                "static_translations_enabled": "تمكين الترجمات الثابتة",
                "status": "حالة",
                "subject": "موضوع",
                "subject_id": "معرف الموضوع",
                "subject_type": "نوع الموضوع",
                "summary": "ملخص",
                "support_category": "فئة",
                "support_messages_count": "عدد رسائل الدعم",
                "support_request": "طلب دعم",
                "support_request_archived": "تمت أرشفة طلب الدعم",
                "support_request_message_received": "تم استلام الرد في طلب الدعم",
                "suspended_at": "معلقة عند",
                "tag": "بطاقة شعار",
                "tags": "العلامات",
                "target": "هدف",
                "terms_condition_accepted": "الشروط والأحكام مقبولة",
                "terms_condition_version": "نسخة الأحكام والشروط",
                "terms_conditions": "الأحكام والشروط",
                "thanked": "شكر",
                "thanked_at": "تاريخ الشكر",
                "thanks": "شكرًا",
                "thanks_message": "رسالة شكر",
                "thanks_sent": "تم إرسال الشكر",
                "tipping_fees": "رسوم البقشيش",
                "tips_enabled": "تم تمكين النصائح",
                "title": "عنوان",
                "total_amount": "المبلغ الإجمالي",
                "traveller": "مسافر",
                "traveller_folder": "مجلد المسافر",
                "traveller_folder_approved": "تمت الموافقة على مجلد المسافر",
                "traveller_folder_archived": "تم وضع مجلد المسافر في الأرشيف",
                "traveller_folder_pending": "مجلد المسافر معلق",
                "traveller_folder_thanks": "شكرا على الإقامة",
                "tutorial_state": "حالة البرنامج التعليمي",
                "type": "يكتب",
                "updated_at": "تم التحديث في",
                "user": "مستخدم",
                "user_id": "مستخدم",
                "user_report": "المستخدم (الذي يبلغ)",
                "users": "المستخدمين",
                "utm_campaign": "حملة",
                "utm_medium": "واسطة",
                "utm_settings": "معلمات UTM",
                "utm_source": "مصدر",
                "uuid": "uuid",
                "validated_activities": "الأنشطة التي تم التحقق من صحتها",
                "validated_at": "تم التحقق من صحتها في",
                "value": "قيمة",
                "vat": "ضريبة القيمة المضافة",
                "vat_rate": "قيمة الضريبة",
                "version": "إصدار",
                "video": "فيديو",
                "videos": "أشرطة فيديو",
                "website": "موقع إلكتروني",
                "zip": "أَزِيز"
            },
            "before": "يجب أن يكون تاريخ {attribute} قبل {date}.",
            "before_or_equal": "يجب أن يكون تاريخ {attribute} قبل أو يساوي {date}.",
            "between": {
                "array": "يجب أن يحتوي {attribute} بين {min} و {max} من العناصر.",
                "file": "يجب أن تكون قيمة {attribute} بين {min} و {max} كيلوبايت.",
                "numeric": "يجب أن تكون قيمة {attribute} بين {min} و {max}.",
                "string": "يجب أن يكون {attribute} بين {min} و {max} حرفًا."
            },
            "boolean": "يجب أن يكون الحقل {attribute} صحيحًا أم خطأ.",
            "confirmed": "لا يتطابق {attribute}.",
            "currency_scale": "يجب ألا يتجاوز عدد خانات {attribute} بعد الفاصلة العشرية {scale}.",
            "current_password": "لا يتطابق {attribute} مع كلمة المرور الخاصة بك.",
            "custom": {
                "attribute-name": {
                    "rule-name": "رسالة مخصصة"
                },
                "h-captcha-response": {
                    "HCaptcha": "خطأ في كلمة التحقق! حاول مرة أخرى لاحقًا أو اتصل بمسؤول الموقع.",
                    "required": "يرجى التحقق من أنك لست روبوتًا."
                }
            },
            "date": " {attribute} ليس تاريخًا صالحًا.",
            "date_equals": "يجب أن يكون تاريخ {attribute} مساويًا لـ {date}.",
            "date_format": "لا يتطابق {attribute} مع التنسيق {format}.",
            "different": "يجب أن يكون {attribute} و {other} مختلفين.",
            "digits": "يجب أن يكون {attribute} رقمًا {digits}.",
            "digits_between": "يجب أن تكون قيمة {attribute} بين رقمين {min} و {max}.",
            "dimensions": " {attribute} له أبعاد صورة غير صالحة.",
            "distinct": "يحتوي الحقل {attribute} على قيمة مكررة.",
            "email": "يجب أن يكون {attribute} عنوان بريد إلكتروني صالحًا.",
            "ends_with": "يجب أن ينتهي {attribute} بواحد مما يلي: {values}.",
            "exists": " {attribute} المحدد غير صالح.",
            "file": "يجب أن يكون {attribute} ملفًا.",
            "filled": "يجب أن يكون للحقل {attribute} قيمة.",
            "gt": {
                "array": "يجب أن يحتوي {attribute} على أكثر من {value} عنصرًا.",
                "file": "يجب أن يكون {attribute} أكبر من {value} كيلوبايت.",
                "numeric": "يجب أن يكون {attribute} أكبر من {value}.",
                "string": "يجب أن يكون {attribute} أكبر من {value} حرفًا."
            },
            "gte": {
                "array": "يجب أن يحتوي {attribute} على {value} من العناصر أو أكثر.",
                "file": "يجب أن يكون {attribute} أكبر من أو يساوي {value} كيلوبايت.",
                "numeric": "يجب أن يكون {attribute} أكبر من أو يساوي {value}.",
                "string": "يجب أن يكون {attribute} أكبر من أو يساوي {value} حرفًا."
            },
            "image": "يجب أن تكون {attribute} صورة.",
            "in": " {attribute} المحدد غير صالح.",
            "in_array": "الحقل {attribute} غير موجود في {other}.",
            "integer": "يجب أن يكون {attribute} عددًا صحيحًا.",
            "ip": "يجب أن يكون {attribute} عنوان IP صالحًا.",
            "ipv4": "يجب أن يكون {attribute} عنوان IPv4 صالحًا.",
            "ipv6": "يجب أن يكون {attribute} عنوان IPv6 صالحًا.",
            "json": "يجب أن يكون {attribute} سلسلة JSON صالحة.",
            "lt": {
                "array": "يجب أن يحتوي {attribute} على أقل من {value} عنصر.",
                "file": "يجب أن تكون قيمة {attribute} أقل من {value} كيلوبايت.",
                "numeric": "يجب أن يكون {attribute} أقل من {value}.",
                "string": "يجب أن يكون عدد الأحرف {attribute} أقل من {value} حرفًا."
            },
            "lte": {
                "array": "يجب ألا يحتوي {attribute} على أكثر من {value} عنصرًا.",
                "file": "يجب أن يكون {attribute} أقل من أو يساوي {value} كيلوبايت.",
                "numeric": "يجب أن يكون {attribute} أقل من أو يساوي {value}.",
                "string": "يجب أن يكون {attribute} أقل من أو يساوي {value} حرفًا."
            },
            "luhn": "{attribute} هو رقم غير صالح.",
            "max": {
                "array": "لا يجوز أن يحتوي {attribute} على أكثر من {max} عنصرًا.",
                "file": "لا يجوز أن يكون {attribute} أكبر من {max} كيلوبايت.",
                "height": "يجب ألا يتجاوز ارتفاع {attribute} {max} بكسل.",
                "numeric": "قد لا يكون {attribute} أكبر من {max}.",
                "string": "لا يجوز أن يكون {attribute} أكبر من {max} حرفًا.",
                "width": "يجب ألا يتجاوز عرض {attribute} {max} بكسل."
            },
            "max_digits": "يجب ألا يتجاوز {attribute} {max} رقمًا.",
            "mimes": "يجب أن يكون لـ {attribute} نوع صالح.",
            "mimetypes": "يجب أن يكون لـ {attribute} نوع صالح.",
            "min": {
                "array": "يجب أن يحتوي {attribute} على {min} من العناصر على الأقل.",
                "file": "يجب ألا يقل حجم {attribute} عن {min} كيلوبايت.",
                "numeric": "يجب أن يكون {attribute} على الأقل {min}.",
                "string": "يجب ألا يقل عدد الأحرف في {attribute} عن 2 حرفًا."
            },
            "min_digits": "يجب أن يكون {attribute} على الأقل {min} رقمًا.",
            "not_in": " {attribute} المحدد غير صالح.",
            "not_regex": "تنسيق {attribute} غير صالح.",
            "notification_setting": "تنسيق {attribute} غير صالح (التنسيق المتوقع هو صفيف [bool]).",
            "numeric": "يجب أن يكون {attribute} رقمًا.",
            "password": "كلمة المرور غير صحيحة.",
            "phone": "يجب أن يكون {attribute} رقم هاتف صالحًا.",
            "present": "يجب أن يكون الحقل {attribute} موجودًا.",
            "prohibited_if": "الحقل {attribute} محظور عندما تكون {other} هي {value}.",
            "prohibited_unless": "الحقل {attribute} محظور ما لم يكن {other} في {values}.",
            "real_name_characters": "يمكن أن تحتوي :{attribute} فقط على أحرف ومسافات ونقاط و\n        الواصلات أو الفاصلات.",
            "regex": "تنسيق {attribute} غير صالح.",
            "required": "حقل {attribute} مطلوب.",
            "required_if": "يكون الحقل {attribute} مطلوبًا عندما تكون {other} هي {value}.",
            "required_unless": "الحقل {attribute} مطلوب ما لم يكن {other} في {values}.",
            "required_with": "مطلوب حقل {attribute} عندما يكون {values} موجودًا.",
            "required_with_all": "الحقل {attribute} مطلوب عند وجود {values}.",
            "required_without": "يكون الحقل {attribute} مطلوبًا عندما لا يكون {values} موجودًا.",
            "required_without_all": "يكون الحقل {attribute} مطلوبًا في حالة عدم وجود أي من {values}.",
            "same": "يجب أن يتطابق {attribute} و {other}.",
            "same_currency": "يجب أن تستخدم جميع العناصر نفس العملة.",
            "size": {
                "array": "يجب أن يحتوي {attribute} على {size} عنصرًا.",
                "file": "يجب أن يكون {attribute} {size} كيلو بايت.",
                "numeric": "يجب أن يكون {attribute} {size}.",
                "string": "يجب أن يكون {attribute} من الأحرف {size}."
            },
            "starts_with": "يجب أن يبدأ {attribute} بواحد مما يلي: {values}.",
            "string": "يجب أن يكون {attribute} عبارة عن سلسلة.",
            "tag_same_poi_category": "يجب أن تنتمي جميع {attribute} إلى نفس الفئة!",
            "timezone": "يجب أن تكون {attribute} منطقة صالحة.",
            "unique": "تم أخذ {attribute} بالفعل.",
            "uploaded": "فشل تحميل {attribute}.",
            "url": "تنسيق {attribute} غير صالح.",
            "uuid": "يجب أن يكون {attribute} UUID صالحًا."
        }
    },
    "de": {
        "The {attribute} must be at least {length} characters and contain at least one number.": "Das {attribute} muss mindestens {length} Zeichen lang sein und mindestens eine Zahl enthalten.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "Das {attribute} muss mindestens {length} Zeichen lang sein und mindestens ein Sonderzeichen und eine Zahl enthalten.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "Das {attribute} muss mindestens {length} Zeichen lang sein und mindestens ein Sonderzeichen enthalten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "Das {attribute} muss mindestens {length} Zeichen lang sein und mindestens einen Großbuchstaben und eine Zahl enthalten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "Das {attribute} muss mindestens {length} Zeichen lang sein und mindestens ein Großbuchstabe und ein Sonderzeichen enthalten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "Das {attribute} muss mindestens {length} Zeichen lang sein und mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "Das {attribute} muss mindestens {length} Zeichen lang sein und mindestens einen Großbuchstaben enthalten.",
        "The {attribute} must be at least {length} characters.": "Das {attribute} muss mindestens {length} Zeichen lang sein.",
        "The {attribute} must contain at least one letter.": "Das {attribute} muss mindestens einen Buchstaben enthalten.",
        "The {attribute} must contain at least one number.": "Das {attribute} muss mindestens eine Zahl enthalten.",
        "The {attribute} must contain at least one symbol.": "Das {attribute} muss mindestens ein Symbol enthalten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Das {attribute} muss mindestens einen Groß- und einen Kleinbuchstaben enthalten.",
        "auth": {
            "failed": "Diese Anmeldeinformationen stimmen nicht mit unseren Unterlagen überein. Sind Sie sicher, dass Sie diese E-Mail-Adresse nicht für ein {type}-Konto verwendet haben?",
            "throttle": "Zu viele Anmeldeversuche. Bitte versuchen Sie es in {seconds} Sekunden erneut.",
            "throttle_cascade": "Zu viele Anmeldeversuche. Bitte versuchen Sie es erneut in {remaining}."
        },
        "documents": {
            "display": {
                "description": "Anzeige im A6-Format mit verlinkendem QRCode und dem Logo Ihrer Einrichtung.",
                "title": "Display – A6-Karte"
            },
            "qrcode": {
                "description": "Allein der QRCode ermöglicht es dem Reisenden, direkt mit Ihrer Institution verbunden zu werden.",
                "filename": "QR-Code",
                "title": "QRCode verknüpfen"
            }
        },
        "enums": {
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "Erstellt",
                "deleted": "Gelöscht",
                "updated": "Aktualisiert",
                "validated": "Bestätigt"
            },
            "App\\Enums\\BinaryChoiceEnum": [
                "Bestritten",
                "Akzeptiert"
            ],
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "association": "Verein (oder Stiftung)",
                "company": "Unternehmen (öffentlich/privat)",
                "individual": "Individuell",
                "undefined": "Nicht definiert"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "DE": "Deutschland",
                "ES": "Spanien",
                "FR": "Frankreich",
                "IT": "Italien",
                "TH": "Thailand",
                "undefined": "Nicht definiert"
            },
            "App\\Enums\\ContextsEnum": {
                "city_hall": "Rathaus",
                "hotel": "Hotel",
                "manager": "Manager",
                "tourist_office": "Touristeninformation",
                "traveller": "Reisender"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "Euro",
                "usd": "Dollar"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Friday": "Freitag",
                "Monday": "Montag",
                "Saturday": "Samstag",
                "Sunday": "Sonntag",
                "Thursday": "Donnerstag",
                "Tuesday": "Dienstag",
                "Wednesday": "Mittwoch"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "Maßgeschneidert",
                "root": "Meine Vorlagen",
                "ugozer_app": "UgoZER-App",
                "ugozer_pro": "UgoZER Pro"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "Kaiserliche",
                "metric": "Metrisch"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "In App",
                "email": "Email"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "customer": "Kunde",
                "partner": "Partner"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "easytransac": "EasyTransac",
                "paypal": "PayPal",
                "stripe": "Streifen"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "subscription": "Abonnement",
                "tip": "Tipp"
            },
            "App\\Enums\\PrivacySettingsEnum": {
                "contacts": "Meine Kontakte",
                "everyone": "Alle",
                "nobody": "Niemand"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "illegal": "Illegal",
                "incorrect_data": "Falsche Angaben",
                "other": "Andere",
                "shocking": "Schockierend",
                "spam": "Spam"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "Frühstück",
                "dinner": "Abendessen",
                "lunch": "Mittagessen",
                "null": "N / A"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "Facebook",
                "google": "Google",
                "instagram": "Instagram",
                "linkedin": "LinkedIn",
                "tiktok": "Tick ​​Tack",
                "twitter": "X (Twitter)",
                "wechat": "WeChat"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "1 Monat",
                "one year": "1 Jahr"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "1 Monat",
                "one year": "1 Jahr",
                "six months": "6 Monate",
                "three months": "3 Monate"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "expired": "Das Abonnement ist beendet",
                "free period ended": "Der kostenlose Zeitraum ist beendet",
                "payment default": "Zahlungsverzug"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "App",
                "extranet": "Extranet",
                "front": "Vorderseite"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "Archiviert",
                "canceled": "Abgesagt",
                "pending_support": "Ausstehender Support",
                "pending_user": "Ausstehender Benutzer",
                "resolved": "Gelöst"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "Um mich herum",
                "customized": "Maßgeschneidert",
                "staying_hotel": "In der Nähe eines Hotels"
            },
            "App\\Enums\\UserRoleEnum": {
                "butler": "Diener",
                "contributor": "Mitwirkender",
                "hotelier": "Hotelier",
                "manager": "Manager",
                "moderator": "Moderator",
                "superadmin": "Höchster Vorgesetzter",
                "touristOffice": "Touristeninformation",
                "touristOfficeEmployee": "AN Mitarbeiter",
                "traveller": "Reisender"
            }
        },
        "nova": {
            "lens": {
                "user": {
                    "user_origin": "Herkunft des Benutzers"
                }
            },
            "menu": {
                "administration": "Verwaltung",
                "content": "Inhalt",
                "excursions": "Reiserouten",
                "import_poi": "POI importieren",
                "miscellaneous": "Verschiedenes",
                "parameters": "Parameter",
                "pois": "Sehenswürdigkeiten",
                "subscriptions": "Abonnements",
                "support": "Unterstützung",
                "users": "Benutzer"
            },
            "s3_private_download": {
                "link": {
                    "expiration": "Der Link läuft nach 30 Minuten ab. Aktualisieren Sie in diesem Fall einfach die Seite.",
                    "open": "Sehen Sie sich das Dokument an"
                },
                "no_document": "Keine zugehörigen Dokumente",
                "not_found": "Das Dokument konnte auf dem S3-Laufwerk nicht gefunden werden..."
            },
            "user_menu": {
                "profile": "Mein Profil"
            }
        },
        "pagination": {
            "go_page": "Gehen Sie zu Seite {page}",
            "next": "Nächste \"",
            "next_alt": "Nächste",
            "of": "von",
            "pagination_navigation": "Paginierungsnavigation",
            "previous": "\" Vorherige",
            "previous_alt": "Vorherige",
            "results": "Ergebnisse",
            "showing": "Zeigt",
            "to": "Zu"
        },
        "passwords": {
            "reset": "Dein Passwort wurde zurück gesetzt!",
            "sent": "Wir haben Ihren Link zum Zurücksetzen Ihres Passworts per E-Mail verschickt!",
            "throttled": "Bitte warten Sie, bevor Sie es erneut versuchen.",
            "token": "Dieses Passwort-Reset-Token ist ungültig.",
            "user": "Kein Benutzer korrespondiert."
        },
        "ttmt": {
            "account_deletion": {
                "confirmation": "Bestätigen Sie auf der sich öffnenden Seite den Löschvorgang, indem Sie das Wort <strong>LÖSCHEN</strong> eingeben\n        und klicken Sie auf <strong>Bestätigen</strong>.",
                "content": {
                    "asking": "Im Falle einer Anfrage zur Kontolöschung löscht UgoZER das Konto und die Daten des Benutzers.\n            es sei denn, sie müssen aufgrund gesetzlicher oder behördlicher Anforderungen, aus Sicherheitsgründen oder wegen Betrugs aufbewahrt werden\n            Verhinderung oder aufgrund eines Problems im Zusammenhang mit dem Benutzerkonto, beispielsweise einer unbezahlten, ungelösten Forderung oder\n            Disput.",
                    "conservation": {
                        "link": "Datenschutzrichtlinie.",
                        "part_1": "UgoZER speichert Benutzerdaten so lange, wie es für die in der genannten Zwecke erforderlich ist",
                        "part_2": "Gemäß den Empfehlungen der CNIL wird ein Konto, das 3 Jahre lang nicht genutzt wurde, gelöscht.",
                        "part_3": "Benutzer können jederzeit die Löschung ihrer Konten beantragen. UgoZER kann Benutzerdaten nach einer bestimmten Zeit aufbewahren\n                Löschungsverlangen aufgrund gesetzlicher oder behördlicher Vorgaben oder aus den dort genannten Gründen\n                Stellungnahme."
                    },
                    "instructions": "Wenn Sie Ihr UgoZER-Konto löschen möchten, befolgen Sie bitte die nachstehenden Anweisungen."
                },
                "logout": "Sie werden abgemeldet und das Konto sowie die damit verbundenen Daten werden umgehend gelöscht.",
                "pro": {
                    "instructions": {
                        "delete": "Klicken Sie dann auf die Schaltfläche <strong>Löschen</strong> vor <strong>Konto löschen\n                </strong>,",
                        "profile": {
                            "link": "Profil",
                            "part_1": "Gehen Sie zu Ihrem",
                            "path": "(durch Klicken auf Ihren Avatar > Mein Profil),"
                        }
                    },
                    "subtitle": "So löschen Sie Ihr professionelles Konto:",
                    "title": "Professionelles Konto"
                },
                "title": "Kontolöschung",
                "traveller": {
                    "instructions": {
                        "delete": "Klicken Sie dann auf die Schaltfläche <strong>Löschen</strong> vor <strong>Mein Konto löschen?\n                </strong>",
                        "profile": {
                            "link": "Profil",
                            "part_1": "Gehen Sie zu Ihrem",
                            "path": "(Hamburger-Symbolmenü > Einstellungen > Profil),"
                        }
                    },
                    "subtitle": "So löschen Sie Ihr Reisekonto:",
                    "title": "Reisekonto"
                }
            },
            "activity_logs": {
                "summary": {
                    "created": {
                        "intro": "Der Betreff wurde mit diesen Eigenschaften erstellt:"
                    },
                    "deleted": {
                        "intro": "Der Betreff wurde mit diesen Eigenschaften gelöscht:"
                    },
                    "updated": {
                        "attribute_from_to": "{attribute} wurde von „{old_value}“ auf „{new_value}“ aktualisiert",
                        "intro": "Betreff wurde aktualisiert:"
                    },
                    "validated": {
                        "intro": "Betreff wurde validiert:"
                    }
                }
            },
            "auth": {
                "credential_verification": {
                    "intro": "Geben Sie Ihre Zugangsdaten ein, ein Bestätigungslink wird Ihnen zugesandt.",
                    "intro2": "Ihre Zugangsdaten werden aktualisiert, sobald Sie auf den Link zugreifen."
                },
                "expiration": {
                    "session_expired": "Ihre Sitzung ist abgelaufen. Bitte authentifizieren Sie sich erneut"
                },
                "extranet_register": {
                    "form": {
                        "choose_establishment": "Wählen Sie Ihre Art von Einrichtung",
                        "form_title": "Erstelle deinen Account",
                        "validate_registration": "Schließe meine Registrierung ab"
                    },
                    "success": "Erfolgreich registriert, ein Bestätigungslink wurde an Ihre E-Mail-Adresse gesendet"
                },
                "login": {
                    "already_logged": "Du bist bereits eingeloggt",
                    "logging_in": "Einloggen...",
                    "success": "Eingeloggt",
                    "use_email": "Melden Sie sich mit einer E-Mail an",
                    "use_social_networks": "Nutzen Sie soziale Netzwerke"
                },
                "logout": {
                    "logging_out": "Ausloggen...",
                    "switch": "Wechseln zu"
                },
                "messages": {
                    "continue": "Weitermachen mit",
                    "extranet_here": "klicken Sie hier",
                    "have_account": "Sie haben bereits ein Konto?",
                    "login": "Anmeldung",
                    "login_subtitle": "(Registrierung oder Login)",
                    "login_title": "Mit sozialen Netzwerken geht es besser",
                    "no_account": "Sie haben noch kein Konto?",
                    "or": "ODER",
                    "professional": "Sie sind ein Profi?",
                    "register": "Registrieren",
                    "register_mail": "Registrieren Sie sich per E-Mail",
                    "register_title": "Registrieren Sie sich, um alle Funktionen freizuschalten",
                    "reset_password": "Passwort zurücksetzen",
                    "send_magic_link": "Passwortlosen Link senden"
                },
                "password_forgotten": {
                    "intro": "Geben Sie Ihre Zugangsdaten ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.",
                    "submit": "Erhalten Sie einen Link zum Zurücksetzen",
                    "success": {
                        "correct_credential": "Richtiger Ausweis",
                        "email_1": "Eine E-Mail an {email} wird Ihnen zugesandt, wenn diese Adresse korrekt registriert ist.",
                        "email_2": "Diese E-Mail enthält einen Link, über den Sie Ihr Passwort zurücksetzen können.",
                        "send_again": "Nochmals senden",
                        "title": "Link zum Zurücksetzen des Passworts gesendet",
                        "wait_before": "Bitte warten Sie {seconds} Sekunden, bevor Sie erneut senden"
                    }
                },
                "password_reset": {
                    "submit": "Setze dein Passwort zurück",
                    "success": "Das Passwort wurde zurückgesetzt"
                },
                "register": {
                    "form": {
                        "hide_privacy_policy": "Datenschutzrichtlinie ausblenden",
                        "hide_terms_conditions": "Allgemeine Geschäftsbedingungen ausblenden",
                        "privacy_policy": "Ich akzeptiere die Datenschutzregelung",
                        "show_privacy_policy": "Datenschutzbestimmungen anzeigen",
                        "show_terms_conditions": "Allgemeine Geschäftsbedingungen anzeigen",
                        "terms_conditions": "Ich stimme den AGB zu"
                    },
                    "staying_hotel": "Sobald Sie sich registriert haben, werden Sie von dem Hotel betreut, dessen\n            Sie haben gerade den QR-Code gescannt.",
                    "success": {
                        "email_1": "Eine E-Mail wird an {email} gesendet.",
                        "email_2": "Diese E-Mail enthält einen Link, über den Sie Ihre Adresse bestätigen und eine Verbindung herstellen können.",
                        "paired": {
                            "email_1": "Zwei E-Mails wurden an die folgenden Adressen gesendet",
                            "email_2": "Jede E-Mail enthält einen Link zur Bestätigung der Adresse und zur Verbindung des zugehörigen Kontos."
                        },
                        "title": "Erfolgreich registriert"
                    }
                },
                "social_networks": {
                    "authenticate": "Einloggen mit",
                    "facebook": "Facebook",
                    "google": "Google",
                    "no_email": "Mit Ihrem sozialen Netzwerk ist keine E-Mail verknüpft, Sie können sich damit nicht authentifizieren",
                    "twitter": "Twitter"
                },
                "travel_assistant": "Kostenloser Reiseassistent"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "Bankkonto nicht angegeben"
                    },
                    "form": {
                        "placeholders": {
                            "bank_city": "Stadt Ihrer Bankfiliale.",
                            "bank_name": "Bank Name",
                            "bank_transfer_reason": "Beispiel: „UgoZER-Tipps“",
                            "bic": "Geben Sie Ihren BIC ein",
                            "iban": "Geben Sie Ihre IBAN/BBAN ein",
                            "payee_name": "Name des Zahlungsempfängers oder Name der Institution",
                            "types": "IBAN/BBAN"
                        }
                    },
                    "lines": {
                        "no_account_yet": "Ihr Bankkonto ermöglicht Ihrer Institution den Empfang von Zahlungen auf UgoZER."
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "amount": "Menge",
                    "amount_to_pay": "Zu zahlender Betrag",
                    "concierge": "Portier",
                    "currency": "Währung",
                    "customer": "Kunde",
                    "date": "Datum",
                    "repartition_key": "Verteilungsschlüssel",
                    "tip_sharing_form": "Formular zum Teilen von Trinkgeldern",
                    "tips_received": "erhaltene Trinkgelder",
                    "total_received": "insgesamt erhalten"
                }
            },
            "breadcrumbs": {
                "changelogs": {
                    "changelogs": "Änderungsprotokolle"
                },
                "claims": {
                    "claims": "Ansprüche"
                },
                "common": {
                    "add": "Hinzufügen",
                    "claim": "Beanspruchen",
                    "create": "Neu",
                    "create_f": "Neu",
                    "delete": "Löschen",
                    "download_pdf": "PDF Herunterladen",
                    "edit": "Bearbeiten",
                    "report": "Bericht",
                    "share": "Aktie",
                    "show": "Zeigen"
                },
                "contacts": {
                    "contacts": "Kontakte"
                },
                "cookie": {
                    "cookie": "Cookie-Verwaltung"
                },
                "credit": {
                    "credit": "Credits"
                },
                "dashboard": {
                    "dashboard": "Armaturenbrett"
                },
                "errors": {
                    "404": {
                        "label": "Fehler 404",
                        "title": "Fehler 404 nicht gefunden"
                    }
                },
                "excursions": {
                    "create": "Neue Reiseroute",
                    "excursions": "Reiserouten"
                },
                "faq": {
                    "faq": "Häufig gestellte Fragen"
                },
                "finances": {
                    "finances": "Finanzen"
                },
                "institutions": {
                    "institution": "Institution",
                    "institutions": "Institutionen"
                },
                "institutions_employees": {
                    "institutions_employees": "Meine Mitarbeiter",
                    "invite": "Laden Sie einen Mitarbeiter ein"
                },
                "interactions": {
                    "interactions": "Interaktionen"
                },
                "invitations": {
                    "invitations": "Einladungen"
                },
                "notification_settings": {
                    "notification_settings": "Benachrichtigungseinstellungen"
                },
                "notifications": {
                    "notifications": "Benachrichtigungen"
                },
                "partners": {
                    "customers": "Kunden",
                    "partners": "Partner"
                },
                "points_of_interest": {
                    "add_excursion": "Zu einer Reiseroute hinzufügen",
                    "points_of_interest": "Sehenswürdigkeiten"
                },
                "privacy_policy": {
                    "privacy_policy": "Datenschutzrichtlinie"
                },
                "privacy_settings": {
                    "privacy_settings": "Datenschutzeinstellungen"
                },
                "reservations": {
                    "reservations": "Reservierungen"
                },
                "search": {
                    "search": "Suchen"
                },
                "subscriptions": {
                    "subscription": "Abonnement"
                },
                "support_messages": {
                    "add_message": "Nachricht hinzufügen",
                    "support_messages": "Mitteilungen"
                },
                "support_requests": {
                    "add_request": "Fügen Sie eine neue Supportanfrage hinzu",
                    "support_requests": "Supportanfragen"
                },
                "terms_condition": {
                    "terms_condition": "Geschäftsbedingungen"
                },
                "travellers": {
                    "folders": "Reiseordner",
                    "travellers": "Reisende"
                },
                "tutorials": {
                    "begin": "Erste Schritte"
                },
                "users": {
                    "edit_credential": "Anmeldeinformationen bearbeiten",
                    "profile": "Mein Profil",
                    "users": "Benutzer"
                }
            },
            "changelogs": {
                "archive": {
                    "success": "Änderungsprotokoll(e) wurden archiviert (sofern berechtigt)"
                },
                "draft": {
                    "success": "Änderungsprotokoll(e) wurden erstellt (sofern berechtigt)"
                },
                "index": {
                    "sections": {
                        "all": "Alle Updates",
                        "last": "Letztes Update"
                    }
                },
                "publish": {
                    "success": "Changelog(s) wurden veröffentlicht (sofern berechtigt)"
                },
                "show": {
                    "version": "Ausführung"
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "Anspruchsstreit wurde erstellt"
                },
                "rejected": {
                    "success": "Der Anspruchsstreit wurde abgelehnt"
                },
                "validated": {
                    "success": "Der Anspruchsstreit wurde validiert"
                }
            },
            "claims": {
                "form_wizard": {
                    "documents": {
                        "DE": {
                            "association_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neue\n                    Version mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "association_statutes": "Handelsregisterauszug",
                            "company_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neue\n                    Version mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "company_registration_document": "Handelsregisterauszug",
                            "company_statutes": "Aktuelle und unterschriebene (von der Geschäftsführung) Gesellschaftsverträge",
                            "individual_first_identity_piece": "Reisepass, Personalausweis, Führerschein (neue Version mit\n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_registration_document": "Gewerbeschein, Handlungsbevollmächtigung, Handelsregisterauszug,\n                    Prokura, Steuernummerzuordnungsschreiben",
                            "individual_second_identity_piece": "Reisepass, Personalausweis, Führerschein (neue Version mit\n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)"
                        },
                        "ES": {
                            "association_legal_representative_identity_piece": "Pasaporte, Documento Nacional de identidad,\n                    Permiso de ductir (version reciente con lineas MRZ) or Permiso de residentcia (solo si es de un\n                    país europeo)",
                            "association_statutes": "Escrituras de constitución o Estatutos sociales",
                            "company_legal_representative_identity_piece": "Pasaporte, Nationales Identitätsdokument, Permiso\n                    Leitung (version reciente con lineas MRZ) o Permiso de residentcia (solo si es de un país).\n                    europao)",
                            "company_registration_document": "Extracto del Registro Mercantil Central, Nota Simple del Registro\n                    lokaler Händler oder Numeriker, Auszug aus den Registratoren von Spanien (Informationen).\n                    General Mercantil) mit Gültigkeitsdauer von weniger als 3 Monaten oder Modell 600 mit Gültigkeitsdauer\n                    de menos de 3 meses (solo para sociedad Civil)",
                            "company_statutes": "Escrituras de constitución o Estatutos sociales",
                            "individual_first_identity_piece": "Pasaporte, Nationales Identitätsdokument, Führungserlaubnis\n                    (aktuelle Version mit MRZ-Linien) oder Aufenthaltserlaubnis (nur in einem europäischen Land)",
                            "individual_registration_document": "Agencia Tributaria – Certificado de situacion Censal\n                    (Alta Censal)",
                            "individual_second_identity_piece": "Pasaporte, Nationales Identitätsdokument, Führungserlaubnis\n                    (aktuelle Version mit MRZ-Linien) oder Aufenthaltserlaubnis (nur in einem europäischen Land)"
                        },
                        "FR": {
                            "association_legal_representative_identity_piece": "Ein Stück Identität auf dem Weg zur Gültigkeit\n                    Vertreter des Vereins: Identitätskarte (Vorder- und Rückseite), Reisepass, Titel\n                    Aufenthalt (recto et verso), permis de conduire biométrique (recto et verso)",
                            "association_statutes": "Die Statuten der Vereinigung",
                            "company_legal_representative_identity_piece": "Ein Stück Identität auf dem Weg zur Gültigkeit\n                    Vertreter der Gesellschaft: Carte d'identité (Vorder- und Rückseite), Reisepass, Titre de séjour\n                    (recto et verso), permis de conduire biométrique (recto et verso)",
                            "company_registration_document": "Ein KBIS-Extrait",
                            "company_statutes": "Die Statuten",
                            "individual_first_identity_piece": "Ein erstes Identitätsstück mit Gültigkeit: Karte\n                    d'identité (recto et verso), passeport, titre de séjour (recto et verso), permis de conduire\n                    Biometrie (Vorder- und Rückseite)",
                            "individual_registration_document": "Eine Bescheinigung über die Eintragung in die INSEE oder ein Dokument\n                    Attestant de Son Inscription auprès de la Chambre de Commerce (pour un exerçant auto-entrepreneur).\n                    eine kommerzielle Tätigkeit) oder vor der Handwerkskammer (für einen aktiven Selbstunternehmer).\n                    eine handwerkliche Tätigkeit)",
                            "individual_second_identity_piece": "Ein zweites Identitätsstück mit Gültigkeit: Karte\n                    d'identité (recto et verso), passeport, titre de séjour (recto et verso), permis de conduire\n                    (recto et verso), das letzte Avis d'imposition date de moins d'an, livret de famille ou\n                    Empfangsbestätigung der Eintragung in den Zivilpakt für Solidarität"
                        },
                        "IT": {
                            "association_legal_representative_identity_piece": "Passaporto, Carta d'identità nationale, La\n                    Patente di Guide (aktuelle Version mit MRZ-Linie) oder Permesso di Residence (solo se di un paese).\n                    europao)",
                            "association_statutes": "tatuto firmato dal Legale Rappresentante (se non incluso all'interno della\n                    Visura Camerale",
                            "company_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, La patente\n                    von der Anleitung (aktuelle Version mit MRZ-Linie) oder von der Aufenthaltsgenehmigung (nur bei einem europäischen Besuch)",
                            "company_registration_document": "Visura Camerale",
                            "company_statutes": "Statuto firmato dal Legale Rappresentante (se non incluso all'interno della\n                    Visura Camerale)",
                            "individual_first_identity_piece": "Passaporto, Carta d'identità nazionale, La patente di guida\n                    (aktuelle Version mit MRZ-Linie) oder Permesso di Residence (nur in einem europäischen Land)",
                            "individual_registration_document": "Estratto dal registro delle imprese („Visura Camerale“) vor Kurzem\n                    vor 3 Monaten",
                            "individual_second_identity_piece": "Passaporto, Carta d'identità nazionale, La patente di guida\n                    (aktuelle Version mit MRZ-Linie) oder Permesso di Residence (nur in einem europäischen Land)"
                        },
                        "TH": {
                            "association_legal_representative_identity_piece": "เอกสารประจำตัวที่ถูกต้องจากตัวแทนทางกฎหมา ยของสมาคม\n                    (ทั้งด้านหน้าและด้านหล ัง),\n                    หนังสือเดินทาง, ใบอนุญาตมีถิ่นที่อยู่ (ทั้งด ้านหน้าและด้านหลัง),\n                    (ทั้งด้านหน้าและด้านห ลัง)",
                            "association_statutes": "กฎเกณฑ์ของสมาคม",
                            "company_legal_representative_identity_piece": "เอกสารประจำตัวที่ถูกต้องจากตัวแทนทางกฎหมา Inhalt:\n                    บัตรประจำตัวประชาชน, หน ังสือเดินทาง,\n                    ด้านหน้าและด้านหลัง,\n                    ด้านหน้าและด้านหลัง",
                            "company_registration_document": "KBIS",
                            "company_statutes": "สถานะต่างๆ",
                            "individual_first_identity_piece": "เอกสารแสดงตนที่ถูกต้องฉบับแรก: วประชาชน\n                    (ทั้งด้านหน้าและด้านหลัง), หนังสือเดินทาง, ใบ อนุญาตมีถิ่นที่อยู่ (ทั้งด้านหน้าและด้านหลั ),\n                    (ทั้งด้านหน้าและด้านห ลัง)",
                            "individual_registration_document": "หนังสือรับรองการจดทะเบียนกับอินทรีหรือเอก สารรับรองการจดทะเบียนกับหอการค้า\n                    () ชิงพาณิชย์)\n                    หรือกับหอการค้า (สำหรับผู้ประกอบอาชีพอิสระ (nicht verfügbar)\n                    กิจกรรมงานฝีมือ)",
                            "individual_second_identity_piece": "เอกสารประจำตัวที่สองที่ถูกต้อง: ัวประชาชน\n                    (ด้านหน้าและด้านหลัง) หนานหลัง ตมีถิ่นที่อยู่\n                    (ด้านหน้าและด้านหลัง) ใบขับขี่ (ด้านหน้าและด้ านหลัง)\n                    ประกาศภาษีครั้งสุดท้ายลงวันที่น้อยกว่าหนึ ่งปี สมุดบันทึกครอบครัวหรือใบเสร็จรับเงินส ำหรับการจดทะเบียน\n                    สนธิสัญญาความสามัคคีของพลเมือง"
                        }
                    },
                    "steps": {
                        "claim": "Beanspruchen",
                        "context": "Kontext",
                        "create": "Erstellen",
                        "documents": "Unterlagen",
                        "info": "Information",
                        "search": "Suchen"
                    }
                },
                "missing_info": {
                    "success": "Der Anspruch wurde wegen fehlender Informationen abgelehnt"
                },
                "notice": {
                    "actions": {
                        "edit": "Ändern Sie meine Anfrage"
                    },
                    "already_claimed": {
                        "description": "Dieser Punkt von Interesse wurde bereits beansprucht. Wenn Sie glauben, dass dies ein Fehler ist, können Sie dies tun\n                Füllen Sie das untenstehende Streitbeilegungsformular aus.",
                        "title": "Interessanter Punkt bereits beansprucht!"
                    },
                    "find_or_new": {
                        "description": "Suchen Sie in der Liste unten nach einem Sonderziel oder erstellen Sie ein neues.",
                        "title": "Suchen oder erstellen Sie einen POI"
                    },
                    "missing-info": {
                        "infos": "Bitte schließen Sie Ihre Anfrage ab, indem Sie auf die Schaltfläche unten klicken.",
                        "state": "Ihr Anspruch wartet derzeit auf zusätzliche Informationen oder Unterlagen:",
                        "title": "Warten auf zusätzliche Informationen"
                    },
                    "pending": {
                        "state": "Ihre Schadensanfrage wird derzeit bearbeitet",
                        "title": "Ausstehender Anspruchsantrag"
                    },
                    "rejected": {
                        "state": "Ihr Anspruchsantrag wurde aus folgendem Grund abgelehnt:",
                        "title": "Anspruchsantrag abgelehnt"
                    }
                },
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "Beanspruchen",
                            "poi": "Sehenswürdigkeit",
                            "user": "Benutzer"
                        }
                    }
                },
                "rejected": {
                    "success": "Der Anspruch wurde abgelehnt"
                },
                "revoked": {
                    "success": "Anspruch wurde widerrufen"
                },
                "states": {
                    "missing-info": "Fehlende Information",
                    "pending": "Ausstehend",
                    "rejected": "Abgelehnt",
                    "revoked": "Widerrufen",
                    "validated": "Bestätigt"
                },
                "update": {
                    "success": "Der Anspruch wurde aktualisiert"
                },
                "valid_identity_pieces": "gültiger Personalausweis, Reisepass oder Führerschein",
                "validated": {
                    "success": "Der Anspruch wurde bestätigt"
                }
            },
            "common": {
                "actions": {
                    "accept": "Akzeptieren",
                    "accept_contact": "Kontaktanfrage annehmen",
                    "add": "Hinzufügen",
                    "add_contact": "Zu den Kontakten hinzufügen",
                    "add_excursion": "Zur Reiseroute hinzufügen",
                    "add_message": "Nachricht hinzufügen",
                    "apply": "Anwenden",
                    "approve": "Genehmigen",
                    "archive": "Archiv",
                    "attach": "Anfügen",
                    "block_contact": "Kontakt blocken",
                    "cancel": "Stornieren",
                    "check": "Überprüfen",
                    "claim": "Beanspruchen",
                    "close": "Schließen",
                    "configure": "Konfigurieren",
                    "confirm": "Bestätigen",
                    "contest": "Wettbewerb",
                    "create": "Erstellen",
                    "decline": "Leugnen",
                    "decline_contact": "Kontaktanfrage ablehnen",
                    "delete": "Löschen",
                    "download": "Herunterladen",
                    "edit": "Bearbeiten",
                    "file_preview": "Vorschaudatei",
                    "filters": "Filter",
                    "give_a_tip": "Trinkgeld geben",
                    "google_maps": "Auf Google Maps öffnen",
                    "help": "Helfen",
                    "hide": "Verstecken",
                    "ignore": "Ignorieren",
                    "interactions": "Interaktionen anzeigen",
                    "invite": "Einladen",
                    "load_more": "Mehr laden",
                    "login": "Anmeldung",
                    "logout": "Ausloggen",
                    "manage": "Verwalten",
                    "next": "Nächste",
                    "pause": "Pause",
                    "pay": "Zahlen",
                    "play": "Spielen",
                    "print": "Drucken",
                    "quit": "Aufhören",
                    "record": "Aufzeichnen",
                    "register": "Registrieren",
                    "report": "Bericht",
                    "request_to_join": "Beitrittsanfrage",
                    "reset": "Zurücksetzen",
                    "resume": "Wieder aufnehmen",
                    "retry": "Wiederholen",
                    "return": "Zurück",
                    "save": "Speichern",
                    "save_alt": "Speichern",
                    "search": "Suchen",
                    "see_plans": "Siehe Pläne",
                    "see_website": "Siehe Website",
                    "send": "Schicken",
                    "send_link": "App-Link senden",
                    "share": "Aktie",
                    "show": "Zeigen",
                    "start": "Start",
                    "stop": "Stoppen",
                    "subscribe": "Abonnieren",
                    "thank": "Dank",
                    "thank_for_stay": "Danke für den Aufenthalt",
                    "traveller_show": "Konsultieren Sie den Reisenden",
                    "unblock_contact": "Kontakt entsperren",
                    "unblock_this_user": "Entsperren Sie diesen Benutzer",
                    "update": "Aktualisieren",
                    "validate": "Bestätigen"
                },
                "all": "alle",
                "and": "Und",
                "app_name": "UgoZER",
                "back_to_dashboard": "Zurück zum Dashboard",
                "back_to_home": "Zurück nach Hause",
                "characters_limit": "{length} / {limit} Zeichen",
                "counter": "{dividend} auf {divisor}",
                "country": "Land",
                "dashboard": "Armaturenbrett",
                "errors": {
                    "deleted_user": "Gelöschter Benutzer",
                    "internal": "Etwas ist schief gelaufen!",
                    "not_found": "Nicht gefunden"
                },
                "false": "NEIN",
                "fast": "schnell",
                "home": "Heim",
                "later": "später",
                "loading": "Wird geladen",
                "messages": {
                    "application_update": {
                        "if_ignore": "Wenn Sie das Update ignorieren, wird es beim nächsten Start der Anwendung durchgeführt.",
                        "new_version_available": "Eine neue Version ist verfügbar.",
                        "updating": "Aktualisierung...",
                        "want_to_refresh": "Möchten Sie die Anwendung neu starten, um sie zu aktualisieren?"
                    },
                    "clipboard": {
                        "error": "Fehler beim Kopieren in die Zwischenablage...",
                        "success": "In die Zwischenablage kopiert!"
                    },
                    "no_records": "Keine Aufzeichnungen gefunden"
                },
                "missing_title": "Titel fehlt",
                "more_options": "Mehr Optionen",
                "no": "NEIN",
                "none": "keiner",
                "normal": "normal",
                "not_specified": "Nicht angegeben",
                "online": {
                    "connected": "Sie sind wieder mit dem Internet verbunden",
                    "disconnected": "Sie sind nicht mehr mit dem Internet verbunden"
                },
                "oops": "Hoppla",
                "or": "oder",
                "qr_code": "QR-Code",
                "search": "Suchen",
                "slow": "langsam",
                "someone": "jemand",
                "sort": "Sortieren",
                "tip": "Tipp",
                "tip_received": "Trinkgeld erhalten (falls aktiviert)",
                "true": "Ja",
                "units": {
                    "kilometer": "Kilometer | Kilometer",
                    "km": "km",
                    "mi": "mi",
                    "mile": "Meile | Meilen"
                },
                "welcome": "Willkommen",
                "yes": "Ja"
            },
            "cookies": {
                "details": {
                    "content": "Hier ist die Liste der Cookies, die in diesem Abschnitt der UgoZER-Anwendung basierend auf dem platziert werden\n            oben definierte Kategorien:",
                    "table": {
                        "headers": {
                            "category": "Nutzungskategorie",
                            "description": "Beschreibung",
                            "lifespan": "Lebensdauer",
                            "mandatory": "Obligatorisch",
                            "name": "Cookie-Name (oder Muster)"
                        },
                        "lines": {
                            "alphanumeric": {
                                "content": "Enthält Informationen zur Sitzung.",
                                "lifespan": "2 Stunden",
                                "title": "[[{alnum}:]] (Folge alphanumerischer Zeichen)"
                            },
                            "gmaps": {
                                "content": "Wird zum Anzeigen des Google Maps-Iframes verwendet.",
                                "lifespan": "180-400 Tage",
                                "title": "CONSENT, AEC, SOCS... (oder andere google.com-Domain-Cookies)"
                            },
                            "remember": {
                                "content": "Wird zur Aufbewahrung der Benutzerauthentifizierung verwendet.",
                                "lifespan": "400 Tage",
                                "title": "Remember_web_*"
                            },
                            "session": {
                                "content": "Enthält Informationen zur Sitzung.",
                                "lifespan": "2 Stunden",
                                "title": "*_ugozer_session"
                            },
                            "tarteaucitron": {
                                "content": "Ermöglicht Ihnen, die Cookie-Einstellungen des Benutzers beizubehalten.",
                                "lifespan": "365 Tage",
                                "title": "Tarteaucitron"
                            },
                            "xsrf": {
                                "content": "Ermöglicht die Sicherung der Anwendung (Anfragen, Formulare...).",
                                "lifespan": "2 Stunden",
                                "title": "XSRF-TOKEN-*"
                            }
                        }
                    },
                    "title": "Details zu hinterlegten Cookies"
                },
                "management": {
                    "content": {
                        "cta": "Ändere meine Präferenzen",
                        "line_1": "Einige Funktionen dieser Website basieren auf der Verwendung von Cookies.",
                        "line_2": "Das Cookie-Banner wird während Ihrer Navigation nicht auf der Startseite angezeigt, wenn dies nur erforderlich ist\n                zur Funktionsfähigkeit der Website erhoben werden.",
                        "line_3": "Sie können Ihre Einstellungen ändern, indem Sie auf die Schaltfläche unten klicken:"
                    },
                    "title": "Cookie-Verwaltung"
                },
                "notice": {
                    "needed": "Um diesen Abschnitt anzuzeigen, müssen Sie Cookies {name} akzeptieren.",
                    "refresh": "Wenn Sie es nach dem Akzeptieren von Cookies nicht sehen, aktualisieren Sie bitte die Seite.",
                    "title": "Kekse"
                },
                "services": {
                    "gmaps": "Google Maps",
                    "youtube": "Youtube"
                },
                "usage": {
                    "content": "Cookies können verwendet werden, um Sie zu erkennen, wenn Sie UgoZER besuchen, sich an Ihre Präferenzen zu erinnern und diese zu übermitteln\n            Ihnen ein personalisiertes Erlebnis, das Ihren Einstellungen entspricht. Cookies machen auch Ihre Interaktionen mit\n            UgoZER schneller und sicherer. Darüber hinaus ermöglichen uns Cookies, Ihnen sowohl innerhalb als auch außerhalb der Website Werbung anzuzeigen\n            UgoZER-Sites.",
                    "table": {
                        "headers": {
                            "category": "Nutzungskategorie",
                            "description": "Beschreibung"
                        },
                        "lines": {
                            "ads": {
                                "content": "Wir können Cookies verwenden, um Ihnen relevante Werbung sowohl auf der UgoZER-Website als auch außerhalb davon anzuzeigen.\n                        Wir können ein Cookie auch verwenden, um zu erfahren, ob jemand, der eine Anzeige später gesehen hat, sie besucht und angenommen hat\n                        Aktion (z. B. ein Whitepaper heruntergeladen oder einen Kauf getätigt) auf der Website des Werbetreibenden.\n                        Ebenso können unsere Partner ein Cookie verwenden, um festzustellen, ob und wie wir eine Anzeige geschaltet haben\n                        durchgeführt werden oder uns Informationen darüber übermitteln, wie Sie mit Anzeigen interagieren. Wir dürfen auch arbeiten\n                        mit einem Partner, um Ihnen eine Anzeige auf oder außerhalb von UgoZER zu zeigen, beispielsweise nachdem Sie einen Partner besucht haben\n                        Website oder Anwendung.",
                                "title": "Werbung"
                            },
                            "authentication": {
                                "content": "Wenn Sie bei UgoZER angemeldet sind, helfen uns Cookies dabei, Ihnen die richtigen Informationen anzuzeigen und\n                        Personalisieren Sie Ihr Erlebnis.",
                                "title": "Authentifizierung"
                            },
                            "security": {
                                "content": "Wir verwenden Cookies, um unsere Sicherheitsfunktionen zu aktivieren und zu unterstützen und uns bei der Erkennung zu helfen\n                        böswillige Aktivitäten und Verstöße gegen unsere",
                                "cta": "Geschäftsbedingungen.",
                                "title": "Sicherheit"
                            },
                            "services": {
                                "content": "Cookies bieten Ihnen Funktionen, Einblicke und personalisierte Inhalte.",
                                "title": "Präferenzen, Funktionen und Dienste"
                            }
                        }
                    },
                    "title": "Wofür werden Cookies verwendet?"
                }
            },
            "countries": {
                "AD": "Andorra",
                "AE": "Vereinigte Arabische Emirate",
                "AF": "Afghanistan",
                "AG": "Antigua und Barbuda",
                "AI": "Anguilla",
                "AL": "Albanien",
                "AM": "Armenien",
                "AO": "Angola",
                "AQ": "Antarktis",
                "AR": "Argentinien",
                "AS": "Amerikanischen Samoa-Inseln",
                "AT": "Österreich",
                "AU": "Australien",
                "AW": "Aruba",
                "AX": "Åland-Inseln",
                "AZ": "Aserbaidschan",
                "BA": "Bosnien und Herzegowina",
                "BB": "Barbados",
                "BD": "Bangladesch",
                "BE": "Belgien",
                "BF": "Burkina Faso",
                "BG": "Bulgarien",
                "BH": "Bahrain",
                "BI": "Burundi",
                "BJ": "Benin",
                "BL": "St. Barthélemy",
                "BM": "Bermuda",
                "BN": "Brunei",
                "BO": "Bolivien",
                "BQ": "Karibische Niederlande",
                "BR": "Brasilien",
                "BS": "Bahamas",
                "BT": "Bhutan",
                "BV": "Bouvetinsel",
                "BW": "Botswana",
                "BY": "Weißrussland",
                "BZ": "Belize",
                "CA": "Kanada",
                "CC": "Kokosinseln (Keelinginseln).",
                "CD": "Kongo – Kinshasa",
                "CF": "Zentralafrikanische Republik",
                "CG": "Kongo – Brazzaville",
                "CH": "Schweiz",
                "CI": "Elfenbeinküste",
                "CK": "Cookinseln",
                "CL": "Chile",
                "CM": "Kamerun",
                "CN": "China",
                "CO": "Kolumbien",
                "CR": "Costa Rica",
                "CU": "Kuba",
                "CV": "Kap Verde",
                "CW": "Curacao",
                "CX": "Weihnachtsinsel",
                "CY": "Zypern",
                "CZ": "Tschechien",
                "DE": "Deutschland",
                "DJ": "Dschibuti",
                "DK": "Dänemark",
                "DM": "Dominica",
                "DO": "Dominikanische Republik",
                "DZ": "Algerien",
                "EC": "Ecuador",
                "EE": "Estland",
                "EG": "Ägypten",
                "EH": "Westsahara",
                "ER": "Eritrea",
                "ES": "Spanien",
                "ET": "Äthiopien",
                "FI": "Finnland",
                "FJ": "Fidschi",
                "FK": "Falkland Inseln",
                "FM": "Mikronesien",
                "FO": "Färöer Inseln",
                "FR": "Frankreich",
                "GA": "Gabun",
                "GB": "Großbritannien",
                "GD": "Grenada",
                "GE": "Georgia",
                "GF": "Französisch-Guayana",
                "GG": "Guernsey",
                "GH": "Ghana",
                "GI": "Gibraltar",
                "GL": "Grönland",
                "GM": "Gambia",
                "GN": "Guinea",
                "GP": "Guadeloupe",
                "GQ": "Äquatorialguinea",
                "GR": "Griechenland",
                "GS": "Südgeorgien und Südliche Sandwichinseln",
                "GT": "Guatemala",
                "GU": "Guam",
                "GW": "Guinea-Bissau",
                "GY": "Guyana",
                "HK": "Sonderverwaltungszone Hongkong",
                "HM": "Heard- und McDonald-Inseln",
                "HN": "Honduras",
                "HR": "Kroatien",
                "HT": "Haiti",
                "HU": "Ungarn",
                "ID": "Indonesien",
                "IE": "Irland",
                "IL": "Israel",
                "IM": "Isle of Man",
                "IN": "Indien",
                "IO": "Britisches Territorium des Indischen Ozeans",
                "IQ": "Irak",
                "IR": "Iran",
                "IS": "Island",
                "IT": "Italien",
                "JE": "Jersey",
                "JM": "Jamaika",
                "JO": "Jordanien",
                "JP": "Japan",
                "KE": "Kenia",
                "KG": "Kirgisistan",
                "KH": "Kambodscha",
                "KI": "Kiribati",
                "KM": "Komoren",
                "KN": "St. Kitts und Nevis",
                "KP": "Nord Korea",
                "KR": "Südkorea",
                "KW": "Kuwait",
                "KY": "Cayman Inseln",
                "KZ": "Kasachstan",
                "LA": "Laos",
                "LB": "Libanon",
                "LC": "St. Lucia",
                "LI": "Liechtenstein",
                "LK": "Sri Lanka",
                "LR": "Liberia",
                "LS": "Lesotho",
                "LT": "Litauen",
                "LU": "Luxemburg",
                "LV": "Lettland",
                "LY": "Libyen",
                "MA": "Marokko",
                "MC": "Monaco",
                "MD": "Moldawien",
                "ME": "Montenegro",
                "MF": "St. Martin",
                "MG": "Madagaskar",
                "MH": "Marshallinseln",
                "MK": "Nordmazedonien",
                "ML": "Mali",
                "MM": "Myanmar (Burma)",
                "MN": "Mongolei",
                "MO": "Sonderverwaltungszone Macau, China",
                "MP": "Nördliche Marianneninseln",
                "MQ": "Martinique",
                "MR": "Mauretanien",
                "MS": "Montserrat",
                "MT": "Malta",
                "MU": "Mauritius",
                "MV": "Malediven",
                "MW": "Malawi",
                "MX": "Mexiko",
                "MY": "Malaysia",
                "MZ": "Mosambik",
                "NA": "Namibia",
                "NC": "Neu-Kaledonien",
                "NE": "Niger",
                "NF": "Norfolkinsel",
                "NG": "Nigeria",
                "NI": "Nicaragua",
                "NL": "Niederlande",
                "NO": "Norwegen",
                "NP": "Nepal",
                "NR": "Nauru",
                "NU": "Niue",
                "NZ": "Neuseeland",
                "OM": "Oman",
                "PA": "Panama",
                "PE": "Peru",
                "PF": "Französisch Polynesien",
                "PG": "Papua Neu-Guinea",
                "PH": "Philippinen",
                "PK": "Pakistan",
                "PL": "Polen",
                "PM": "St. Pierre und Miquelon",
                "PN": "Pitcairninseln",
                "PR": "Puerto Rico",
                "PS": "Palästinensische Gebiete",
                "PT": "Portugal",
                "PW": "Palau",
                "PY": "Paraguay",
                "QA": "Katar",
                "RE": "Wiedervereinigung",
                "RO": "Rumänien",
                "RS": "Serbien",
                "RU": "Russland",
                "RW": "Ruanda",
                "SA": "Saudi-Arabien",
                "SB": "Salomon-Inseln",
                "SC": "Seychellen",
                "SD": "Sudan",
                "SE": "Schweden",
                "SG": "Singapur",
                "SH": "St. Helena",
                "SI": "Slowenien",
                "SJ": "Spitzbergen und Jan Mayen",
                "SK": "Slowakei",
                "SL": "Sierra Leone",
                "SM": "San Marino",
                "SN": "Senegal",
                "SO": "Somalia",
                "SR": "Surinam",
                "SS": "Südsudan",
                "ST": "São Tomé und Príncipe",
                "SV": "El Salvador",
                "SX": "Sint Maarten",
                "SY": "Syrien",
                "SZ": "Eswatini",
                "TC": "Turks- und Caicosinseln",
                "TD": "Tschad",
                "TF": "Südfranzösische Territorien",
                "TG": "Gehen",
                "TH": "Thailand",
                "TJ": "Tadschikistan",
                "TK": "Tokelau",
                "TL": "Timor-Leste",
                "TM": "Turkmenistan",
                "TN": "Tunesien",
                "TO": "Tonga",
                "TR": "Truthahn",
                "TT": "Trinidad & Tobago",
                "TV": "Tuvalu",
                "TW": "Taiwan",
                "TZ": "Tansania",
                "UA": "Ukraine",
                "UG": "Uganda",
                "UM": "Vorgelagerte Inseln der USA",
                "US": "Vereinigte Staaten",
                "UY": "Uruguay",
                "UZ": "Usbekistan",
                "VA": "Vatikanstadt",
                "VC": "St. Vincent und die Grenadinen",
                "VE": "Venezuela",
                "VG": "Britische Jungferninseln",
                "VI": "US Jungferninseln",
                "VN": "Vietnam",
                "VU": "Vanuatu",
                "WF": "Wallis und Futuna",
                "WS": "Samoa",
                "XK": "Kosovo",
                "YE": "Jemen",
                "YT": "Mayotte",
                "ZA": "Südafrika",
                "ZM": "Sambia",
                "ZW": "Zimbabwe"
            },
            "credits": {
                "images": "Bildnachweis"
            },
            "csv": {
                "common": {
                    "generating": "CSV-Datei wird generiert...",
                    "waiting": "Warten Sie mal..."
                },
                "download": "CSV-Export"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "Favoriten von Ihrem Experten vor Ort"
                },
                "links": {
                    "closest_pois": "Nächstgelegene Punkte",
                    "excursions": "Reiserouten und Spaziergänge",
                    "shares": "Anteile"
                },
                "no_dashboard": {
                    "contact": {
                        "link": "kontaktiere uns.",
                        "text": "Wenn Sie glauben, dass dies ein Fehler ist, zögern Sie nicht"
                    },
                    "content": "Für Ihre Rolle wurde kein Dashboard eingerichtet oder Sie sind als Administrator angemeldet.",
                    "title": "Kein Dashboard verfügbar"
                },
                "points_of_interest": {
                    "categories": "Kategorien von Sonderzielen"
                }
            },
            "documents": {
                "display": {
                    "description": "Anzeige im A6-Format mit verlinkendem QRCode und dem Logo Ihrer Einrichtung.",
                    "title": "Display – A6-Karte"
                },
                "qrcode": {
                    "description": "Allein der QRCode ermöglicht es dem Reisenden, direkt mit Ihrer Institution verbunden zu werden.",
                    "filename": "QR-Code",
                    "title": "QRCode verknüpfen"
                }
            },
            "easy_transac": {
                "account": {
                    "create": {
                        "button": "Erstellen Sie ein EasyTransac-Konto",
                        "fail": "Die Verbindung zu EasyTransac ist fehlgeschlagen. Bitte versuchen Sie es später erneut",
                        "success": "Ihr EasyTransac-Konto wurde erfolgreich erstellt"
                    },
                    "show": {
                        "account_usage": "Mit Ihrem EasyTransac-Konto kann Ihre Institution Zahlungen über UgoZER erhalten.",
                        "can_receive_payments": "Ihre Kunden können Ihrem Betrieb nun Trinkgeld geben\n                aus dem UgoZER-Profil Ihrer Concierges.",
                        "can_retrieve_payments": {
                            "description": "Sie müssen Ihr EasyTransac-Konto mit den angeforderten Informationen ausfüllen\n                    um das verfügbare Guthaben auf Ihrem Konto zu verwenden.",
                            "title": "Ihr EasyTransac-Konto ist unvollständig"
                        },
                        "cannot_receive_payments": "Sie können keine Zahlungen erhalten",
                        "credentials_sent_mail": "Ihre EasyTransac-Zugangsdaten wurden Ihnen per E-Mail zugesandt.",
                        "fee_percentage": "Für jede Zahlung, die Sie erhalten, wird eine Gebühr erhoben.",
                        "go_to_easy_transac": "Greifen Sie auf das EasyTransac-Dashboard zu",
                        "must_subscribe": "Um Ihr EasyTransac-Konto erstellen zu können, müssen Sie angemeldet sein.",
                        "no_account_yet": "Sie haben noch kein EasyTransac-Konto.",
                        "should_complete_kyc": "Bitte senden Sie die von EasyTransac angeforderten Unterlagen,\n                um Zahlungen zu erhalten."
                    }
                },
                "easy_transac": "EasyTransac"
            },
            "exceptions": {
                "default": "Ein Fehler ist aufgetreten",
                "error_texts": {
                    "403": {
                        "link": {
                            "title": "Möglicherweise möchten Sie darauf zugreifen",
                            "to_app_label": "Abschnitt für Reisende?",
                            "to_extranet_label": "Profibereich?"
                        },
                        "message": "Sie haben keinen Zugriff auf diese Seite.",
                        "title": "Hoppla! Zutritt verboten."
                    },
                    "credential_not_verified": {
                        "title": "Ihre Anmeldedaten sind nicht verifiziert",
                        "verify_mail": "Bestätigungslink für meine E-Mail erhalten",
                        "verify_phone": "Erhalten Sie einen Bestätigungslink für meine Telefonnummer"
                    },
                    "opening_hours": {
                        "invalid": "Der angegebene Wert ist keine OpeningHours-Instanz."
                    },
                    "password_missing": {
                        "app": "Bitte legen Sie ein Passwort fest.",
                        "extranet": "Bitte legen Sie ein Passwort fest, um auf die Anwendung zuzugreifen."
                    }
                },
                "spam_detected": "Spam erkannt"
            },
            "excursions": {
                "destroy": {
                    "confirm": "Möchten Sie diese Reiseroute wirklich löschen: {excursion_name}?",
                    "success": "Die Reiseroute wurde gelöscht"
                },
                "direction": {
                    "bicycling": "Radfahren",
                    "display_mode": "Anzeigemodus",
                    "driving": "Fahren",
                    "walking": "gehen"
                },
                "form": {
                    "all_points_of_interest": "Alle Sehenswürdigkeiten",
                    "create_new_point_of_interest": "Erstellen Sie einen neuen Point of Interest",
                    "existing_excursions": "Bestehende Reiserouten",
                    "from_existing_excursions": "Aus einer bestehenden Reiseroute",
                    "order_hint": "Die Reihenfolge der Sehenswürdigkeiten entspricht der Reihenfolge des Besuchs.",
                    "points_of_interest": "Sehenswürdigkeiten",
                    "select_from_points_of_interest": "Wählen Sie aus Sehenswürdigkeiten aus",
                    "your_excursion": "Deine Reiseroute"
                },
                "index": {
                    "alert": {
                        "content": "Sie können Routen nur anzeigen. Wenn Sie eine Reiseroute erstellen oder teilen möchten, müssen Sie sich mit anmelden\n                ein <strong>Concierge-/Rezeptionistenkonto</strong>",
                        "title": "Reiserouten nur nach Absprache"
                    },
                    "no_results": "Sie haben noch keine Reiserouten",
                    "no_results_here": "In Ihrem Suchgebiet sind keine Reiserouten verfügbar.",
                    "read_only": "Nur Beratung",
                    "search": "Suchen Sie nach einer Reiseroute",
                    "try_more_filters": "Sie können Ihre Suche erweitern oder eine Reiseroute erstellen.",
                    "your_excursions": "Ihre Reiserouten"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "Route",
                            "pois": "Sehenswürdigkeiten"
                        }
                    }
                },
                "search": {
                    "no_results": "Keine Reiseroute gefunden"
                },
                "share": {
                    "contains_private": "Diese Reiseroute enthält private Sehenswürdigkeiten. Sie dürfen nicht in die Aktie einbezogen werden.",
                    "heading": "Teilen Sie diese Reiseroute",
                    "select_which_contacts": "Wählen Sie die Kontakte aus, mit denen Sie die Reiseroute „{excursion_title}“ teilen möchten",
                    "select_which_users": "Wählen Sie die Benutzer aus, mit denen Sie die Reiseroute „{excursion_title}“ teilen möchten, oder geben Sie sie ein\n            eine E-Mail-Adresse, um einen neuen Reisenden einzuladen",
                    "select_which_users_alt": "Geben Sie die E-Mail-Adresse der Person ein, mit der Sie das teilen möchten\n            „{excursion_title}“ Reiseroute.",
                    "success": "Die Reiseroute wurde geteilt!"
                },
                "store": {
                    "create": "Erstellen Sie eine Reiseroute",
                    "heading": "Erstellen Sie eine Reiseroute",
                    "success": "Die Reiseroute wurde erstellt"
                },
                "update": {
                    "heading": "Aktualisieren Sie diese Reiseroute",
                    "success": "Die Reiseroute wurde aktualisiert"
                }
            },
            "extranet": {
                "dashboard": {
                    "last_claims": {
                        "none": "Sie haben noch keine Ansprüche.",
                        "see_all": "Sehen Sie sich alle Ihre Ansprüche an",
                        "see_index": "Gehen Sie zur Schadensseite"
                    },
                    "last_payments": {
                        "none": "Sie haben noch keine Zahlungen.",
                        "see_all": "Sehen Sie sich alle Ihre Zahlungen an",
                        "see_index": "Gehen Sie zur Zahlungsseite"
                    },
                    "last_reservations": {
                        "none": "Sie haben noch keine Reservierungen."
                    },
                    "last_traveller_folders": {
                        "none": "Sie haben noch keinen Reisenden in Ihrer Obhut.",
                        "see_all": "Sehen Sie alle Ihre Reisenden",
                        "take_care": "Kümmere dich um einen Reisenden"
                    },
                    "manager": {
                        "sections": {
                            "butlers_institutions": "Concierges meiner Institutionen",
                            "butlers_interactions": "Concierges mit Interaktionen"
                        }
                    },
                    "sections": {
                        "last_claims": "Ihre letzten Ansprüche",
                        "last_payments": "Ihre letzten Zahlungen",
                        "last_reservations": "Ihre letzten Reservierungen",
                        "last_travellers": "Ihre letzten Reisenden",
                        "search_butler": "Suchen Sie einen Concierge",
                        "stats": "Statistiken"
                    },
                    "stats": {
                        "butlers": "Concierge|Concierges",
                        "employees": "Mitarbeiter|Mitarbeiter",
                        "excursions": "Reiseroute|Reiserouten",
                        "folders": "Ordner|Ordner",
                        "institutions": "Verknüpfte Institution|Verknüpfte Institutionen",
                        "processing_claims": "Reklamation bearbeiten|Reklamationen bearbeiten",
                        "reservations": "Reservierung|Reservierungen"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "Laden Sie einen Mitarbeiter ein",
                        "new-hotelier": "Erstellen Sie ein Empfangskonto",
                        "new-tourist-office": "Erstellen Sie ein Schreibtischkonto"
                    }
                },
                "excursions": {
                    "actions": {
                        "new": "Erstellen Sie eine Reiseroute"
                    },
                    "sections": {
                        "excursions": "Reiserouten"
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "Kategorien"
                    }
                },
                "filters": {
                    "closed": "Filter schließen",
                    "open": "Filter öffnen"
                },
                "finances": {
                    "alerts": {
                        "easytransac": {
                            "cta": "Greifen Sie auf mein Profil zu, um ein EasyTransac-Konto zu erstellen",
                            "description": "Sie haben noch kein EasyTransac-Konto erstellt! Sie können also keine Zahlungen erhalten.",
                            "title": "EasyTransac-Konto nicht vorhanden!"
                        }
                    },
                    "sections": {
                        "issued_payments": "Ausgestellte Zahlungen",
                        "received_by_institution_payments": "Bei Ihren Institutionen eingegangene Zahlungen",
                        "received_payments": "Erhaltene Zahlungen"
                    }
                },
                "institution": {
                    "messages": {
                        "no_records": "Im Moment ist keine Institution verlinkt"
                    },
                    "sections": {
                        "butler": {
                            "institution": "Meine Institution"
                        },
                        "manager": {
                            "institution": "Meine Institutionen"
                        }
                    }
                },
                "navbar": {
                    "contact": "Kontaktiere uns",
                    "contacts": "Kontakte",
                    "dashboard": "Armaturenbrett",
                    "excursions": "Reiserouten",
                    "faq": "FAQ",
                    "finances": "Finanzen",
                    "invitations": "Einladungen",
                    "my_employees": "Meine Mitarbeiter",
                    "my_institution": "Meine Institution",
                    "my_institutions": "Meine Institutionen",
                    "points_of_interest": "Sehenswürdigkeiten",
                    "travellers": "Reisende",
                    "tutorials": "Beginnen",
                    "users": "Benutzer"
                },
                "pages": {
                    "excursions": {
                        "edit": "Reiseroute bearbeiten",
                        "new": "Neue Reiseroute"
                    },
                    "folders": {
                        "edit": "Bearbeiten Sie den Reiseordner",
                        "new": "Neuer Reiseordner"
                    },
                    "institutions": {
                        "new": "Verlinken Sie eine Institution"
                    },
                    "pois": {
                        "edit": "Sonderziel bearbeiten",
                        "new": "Neuer interessanter Punkt"
                    },
                    "profile": {
                        "edit": "Profil bearbeiten"
                    },
                    "reservations": {
                        "edit": "Reservierung bearbeiten",
                        "new": "Neue Reservierung"
                    }
                },
                "points_of_interest": {
                    "actions": {
                        "new": "Erstellen Sie einen Point of Interest"
                    },
                    "sections": {
                        "points_of_interest": "Sehenswürdigkeiten"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "Im Moment keine FAQ"
                    }
                },
                "professional_section": "Professioneller Bereich",
                "profile": {
                    "sections": {
                        "currency": "Währung",
                        "delete_account": "Kontolöschung",
                        "infos": "allgemeine Informationen",
                        "locale": "Sprache",
                        "notification_settings": "Benachrichtigungseinstellungen",
                        "payment": "Zahlung",
                        "privacy_settings": "Datenschutzeinstellungen",
                        "shares": "Anteile"
                    }
                },
                "support_messages": {
                    "actions": {
                        "back": "Zurück zur Supportanfrage",
                        "new": "Fügen Sie eine neue Nachricht hinzu"
                    },
                    "sections": {
                        "support_messages": "Mitteilungen"
                    }
                },
                "support_requests": {
                    "actions": {
                        "back": "Zurück",
                        "new": "Erstellen Sie eine Supportanfrage"
                    },
                    "sections": {
                        "support_requests": "Supportanfragen"
                    }
                },
                "travellers": {
                    "buttons": {
                        "new_folder": "Neuen Reiseordner hinzufügen"
                    },
                    "sections": {
                        "folders": "Meine Reiseordner"
                    }
                },
                "user_dropdown": {
                    "changelogs": "Änderungsprotokolle",
                    "cookies": "Cookie-Verwaltung",
                    "credits": "Credits",
                    "customers": "Kunden",
                    "partners": "Partner",
                    "privacy_policy": "Datenschutzrichtlinie",
                    "profile": "Mein Profil",
                    "sign_out": "Abmelden",
                    "subscription": "Abonnement",
                    "terms_condition": "Geschäftsbedingungen"
                }
            },
            "form": {
                "file": {
                    "current_file": "Tatsächliche Datei: {name}"
                },
                "hints": {
                    "auth": {
                        "register": {
                            "email": "Diese E-Mail-Adresse darf nicht bereits mit einem UgoZER-Konto verknüpft sein",
                            "employee": {
                                "not_duplicate_email": "Die E-Mail-Adresse muss sich von der des unterscheiden",
                                "not_duplicate_password": "Das Passwort muss sich von dem des unterscheiden"
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "Individuell: Vom Concierge erstellter Reiseplan\n                        für einen bestimmten Reisenden",
                                "root": "Meine Vorlagen: „ready to share“-Reiseplan, bestehend aus Sehenswürdigkeiten, die man gesehen haben muss,\n                        oder Sehenswürdigkeiten nach Thema\n                        (Beispiel: Liste der häufig in Reiseplänen enthaltenen Restaurants)\n                        ausgewählt werden, um eine neue Reiseroute zu erstellen.",
                                "ugozer_app": "UgoZER-App: von UgoZER erstellte und vorgeschlagene Reiseroute\n                        und den Reisenden zur Verfügung gestellt.",
                                "ugozer_pro": "UgoZER Pro: Von UgoZER erstellte und vorgeschlagene Reiseroute\n                        und Fachleuten zur Verfügung gestellt werden, um ihnen die Arbeit zu erleichtern."
                            },
                            "pro": {
                                "customized": "Für einen bestimmten Reisenden erstellter Reiseplan",
                                "root": "„Ready to share“-Reiseroute bestehend aus Sehenswürdigkeiten, die man gesehen haben muss,\n                        oder Sehenswürdigkeiten nach Thema\n                        (Beispiel: Liste der häufig in Reiseplänen enthaltenen Restaurants)\n                        ausgewählt werden, um eine neue Reiseroute zu erstellen.",
                                "ugozer": "Reiseroute erstellt und vorgeschlagen von UgoZER."
                            }
                        },
                        "points_of_interest_search": "Suchen Sie einen Punkt von Interesse und wählen Sie ihn aus, um ihn zur Liste hinzuzufügen"
                    },
                    "excursions_filters": {
                        "radius": "Es werden Ihnen nur Reiserouten angezeigt, bei denen sich mindestens ein Sonderziel in diesem Umkreis befindet."
                    },
                    "geoloc_setting": {
                        "filter_header": "Suchen Sie nach einem Land, einer Region, einer Stadt oder einem Viertel",
                        "kind": {
                            "admin": {
                                "around_me": "Um mich herum: Die Geolokalisierung wird ermittelt\n                        durch den Browser des Benutzers.",
                                "customized": "Angepasst: Der Benutzer kann manuell nach einem Ort suchen.",
                                "staying_hotel": "Aufenthalt im Hotel: Die Geolokalisierung wird durchgeführt\n                        vom Hotel, in dem der Reisende übernachtet."
                            },
                            "app": {
                                "around_me": "Die Geolokalisierung erfolgt von Ihrer Position aus.",
                                "customized": "Die Geolokalisierung erfolgt von einem Ort Ihrer Wahl aus.",
                                "staying_hotel": "Die Geolokalisierung erfolgt vom Hotel aus, in dem Sie übernachten: {title}."
                            }
                        }
                    },
                    "locale": {
                        "dynamic_translations_enabled": "Ermöglicht Benutzern das Anzeigen oder Übersetzen von Ressourcen\n                (Sehenswürdigkeiten, Reiserouten...) in dieser Sprache",
                        "fallback_locale": "Zeigt den Titel automatisch in einer zweiten Sprache (vor der Beschreibung) an\n                die POI-Datei.",
                        "static_translations_enabled": "Ermöglicht Benutzern die Auswahl dieser Sprache zur Anzeige der Anwendung\n                (erfordert, dass dem Quellcode Übersetzungen hinzugefügt wurden)"
                    },
                    "permission": {
                        "name": "Im Quellcode verwendeter Name"
                    },
                    "plan": {
                        "countries": "Lassen Sie das Feld leer, um den Plan in allen Ländern verfügbar zu machen",
                        "information": "Zusätzliche Informationen zum Plan (Voraussetzungen, Bedingungen...)",
                        "name": "Dieser Name ist für potenzielle Abonnenten sichtbar\n                wenn sie ihr Abonnement wählen",
                        "private_title": "Dieser Titel ist nur in der Administration sichtbar"
                    },
                    "plan_price": {
                        "description": "Diese Beschreibung ist für potenzielle Abonnenten sichtbar\n                wenn sie ihr Abonnement wählen",
                        "price": "Bitte in Euro eingeben",
                        "private_title": "Dieser Titel ist nur in der Administration sichtbar"
                    },
                    "point_of_interest": {
                        "audio_refreshing_1": "Wenn Sie die Aktualisierung der Audiodatei aktivieren, wird automatisch eine Audiodatei generiert\n                in der Sprache des Sonderziels ({Sprache}).",
                        "audio_refreshing_2": "Darin werden der Titel und die Adresse der Sehenswürdigkeit angegeben.",
                        "audio_refreshing_3": "Es wird aktualisiert, sobald die Sprache, der Titel\n                oder die Adresse des Sonderziels werden aktualisiert.",
                        "audio_refreshing_4": "Die Aktualisierung wird deaktiviert, wenn Sie selbst eine Audiodatei hinzufügen oder aufnehmen.\n                oder wenn Sie die generierte Audiodatei löschen.",
                        "cant_have_employees": "Hier sehen Sie nur Betriebe, die möglicherweise Kategorien zugeordnet sind\n                Mitarbeiter",
                        "expiry_date": "Ihr Point of Interest läuft am {date} ab",
                        "expiry_date_details_1": "Ihr Sonderziel ist Teil der Kategorie „{category}“.",
                        "expiry_date_details_2": "Es läuft daher am {date} ab, da dies das letzte von Ihnen eingegebene Datum ist.",
                        "expiry_date_details_3": "Ab diesem Datum bleiben Ihrem Sonderziel noch 21 Tage\n                bevor es automatisch gelöscht wird.",
                        "expiry_date_details_4": "Sie können jederzeit ein neues Datum hinzufügen\n                um den Ablauf zu verschieben oder kein Datum anzugeben, damit Ihr Sonderziel nicht abläuft.",
                        "images": "Die Bilder werden in der Galerie des Sonderziels in der gewählten Reihenfolge angezeigt.\n                Sie können sie durch Ziehen neu organisieren.",
                        "is_geocoding": "Deaktivieren Sie diese Option, wenn die Geolokalisierung korrekt eingegeben wurde, die Karte jedoch immer noch nicht angezeigt wird.",
                        "line1": "Sie können entweder die Adresse oder den Namen des gesuchten Ortes eingeben. Wählen Sie aus\n                Liste, um die Adresse automatisch auszufüllen",
                        "locale": "Die Hauptsprache, die an diesem interessanten Punkt gesprochen wird",
                        "opening_dates": "Lassen Sie das Feld leer, wenn Zeitpläne für ein beliebiges Datum gelten",
                        "opening_days": "Wählen Sie nichts aus, wenn die Zeitpläne an einem beliebigen Tag gelten",
                        "opening_hours": "Fügen Sie nichts hinzu, wenn die Sehenswürdigkeit jederzeit geöffnet ist",
                        "sync_1": "Durch Aktivieren der automatischen Aktualisierung können Sie Daten vom {import_source} abrufen\n                importieren",
                        "sync_2": "Manuelle Eingaben werden überschrieben"
                    },
                    "points_of_interest_filters": {
                        "order_distance": "Bitte wählen Sie einen Entfernungsfilter, um diese Option auszuwählen",
                        "order_relevance": "Bitte geben Sie Suchbegriffe ein, um diese Option auszuwählen",
                        "radius": "Sie sehen nur Sonderziele, die sich in diesem Umkreis befinden."
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "butler": "Egal für welche Option Sie sich entscheiden, Ihr Nachname und Ihr Vorname\n                    bleibt für den Manager Ihrer Institution sichtbar",
                            "traveller": "Egal für welche Option Sie sich entscheiden, Ihr Nachname und Ihr Vorname\n                  bleiben für den Concierge des Hotels, in dem Sie übernachten, sichtbar."
                        }
                    },
                    "profile": {
                        "contact_email": "Diese E-Mail kann in Ihrem öffentlichen Profil angezeigt werden",
                        "contact_phone": "Dieses Telefon kann in Ihrem öffentlichen Profil angezeigt werden",
                        "name": "Der Spitzname ist öffentlich und ermöglicht es anderen Benutzern der Anwendung, Sie leichter zu identifizieren.",
                        "password_template": {
                            "digit": "1 Ziffer",
                            "length": "{length} Zeichen",
                            "lowercase": "1 Kleinbuchstabe",
                            "uppercase": "1 Großbuchstabe"
                        },
                        "password_unchanged": "Lassen Sie das Feld leer, um es unverändert zu lassen"
                    },
                    "role": {
                        "description": "Die Beschreibung der Rolle wird den Benutzern angezeigt, wenn sie die Rolle auswählen\n                Melden Sie sich im professionellen Bereich an.",
                        "name": "Codename der Rolle, er wird Benutzern nicht angezeigt."
                    },
                    "support_request": {
                        "support_categories_search": "Suchen Sie eine Kategorie und wählen Sie sie aus"
                    },
                    "translatable": {
                        "pro_global": "Ein oder mehrere Felder dieses Formulars können in verschiedenen Sprachen ausgefüllt werden.",
                        "pro_global_2": "Felder, die in bestimmten Sprachen nicht ausgefüllt sind, werden automatisch übersetzt.",
                        "pro_global_title": "Mehrsprachige Eingabe"
                    },
                    "traveller_folder": {
                        "expired_on": "Standardmäßig ist das Enddatum auf eine Woche eingestellt"
                    },
                    "user": {
                        "bank_account_number": "Geben Sie die Kontonummer ein, die für die Überweisung verwendet wird"
                    }
                },
                "labels": {
                    "geoloc_setting": {
                        "distance_filter": "Distanzfilter",
                        "limit_to_a_radius": "auf einen Radius beschränken",
                        "limit_to_a_radius_from_center": "auf einen Radius vom Mittelpunkt beschränken",
                        "measure_unit": "Maßeinheit",
                        "other_filters": "andere Filter",
                        "where_does_the_traveller_go": "Wohin geht der Reisende?",
                        "where_would_you_like_to_go": "Wo würdest du gerne hingehen?",
                        "within_a_radius": "in einem Umkreis"
                    },
                    "payment": {
                        "payable_type": "Zahlung verknüpft mit"
                    },
                    "plan": {
                        "authorized_roles": "autorisierte Rollen",
                        "tipping_fees": "Trinkgeldgebühren (Prozent)"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "Preis ohne Steuern"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "Aktualisieren Sie die Audiodatei automatisch",
                        "opening": "Öffnung",
                        "opening_date": "An",
                        "opening_date_from": "aus",
                        "opening_date_range": "Geben Sie ein Enddatum an",
                        "opening_date_to": "Zu",
                        "opening_hours": "bei",
                        "opening_hours_from": "aus",
                        "opening_hours_range": "Geben Sie eine Endstunde an",
                        "opening_hours_to": "Zu",
                        "opening_schedule_add": "Geben Sie Tage oder Stunden an",
                        "opening_schedule_add_alt": "Stunden angeben",
                        "opening_specification_add": "einen Punkt hinzufügen"
                    },
                    "points_of_interest_filters": {
                        "geoloc": "Ort",
                        "order_distance": "nach Entfernung",
                        "order_relevance": "nach Relevanz (Suchbegriffe)",
                        "sort": "Sortieren",
                        "tags": "verfeinern"
                    },
                    "register": {
                        "communication_consent": "Ich möchte personalisierte Mitteilungen erhalten, einschließlich Angeboten,\n                Werbeinformationen und reisebezogene Produkte von UgoZER per E-Mail.",
                        "privacy_policy": {
                            "part1": "Ich stimme dem zu",
                            "part2": "Datenschutzrichtlinie"
                        },
                        "terms_conditions": {
                            "part1": "Ich stimme dem zu",
                            "part2": "Geschäftsbedingungen"
                        }
                    },
                    "support_category": {
                        "title": "Wähle eine Kategorie"
                    },
                    "support_message": {
                        "content": "Nachricht",
                        "email": "E-Mail-Adresse"
                    },
                    "support_request": {
                        "title": "Betreff der Anfrage"
                    },
                    "traveller_invitation": {
                        "locale": "Sprache, die dieser Reisende spricht"
                    },
                    "user": {
                        "credential": "Email",
                        "email": "Email",
                        "image": {
                            "_destroy": "Profilbild löschen"
                        },
                        "new_password": "Neues Kennwort",
                        "notes": "weitere Informationen"
                    },
                    "vat_rate": {
                        "vat_rate": "Mehrwertsteuersatz (Prozentsatz)"
                    }
                },
                "placeholders": {
                    "audio_file_name": "Name der Audiodatei",
                    "name": "Spitzname",
                    "support_message": {
                        "create_message": "Ihre Nachricht zur Supportanfrage"
                    }
                },
                "select": {
                    "no_options": "Keine Optionen",
                    "no_results": "Leider keine passenden Optionen.",
                    "type_to_search": "Suchen..."
                },
                "titles": {
                    "verifications": "Überprüfung Ihrer Angaben"
                }
            },
            "front": {
                "access_pro": "Gehen Sie zum Profi-Zugang",
                "app": "Reise-App",
                "brand_name": "UgoZER",
                "captcha": {
                    "and": "Und",
                    "apply": "anwenden.",
                    "privacy_policy": "Datenschutzrichtlinie",
                    "protected": "Diese Seite ist durch hCaptcha und seine geschützt",
                    "terms_service": "Nutzungsbedingungen"
                },
                "contact": {
                    "cancel": "Stornieren Sie die Supportanfrage",
                    "contact": "Kontakt",
                    "send": "Schicken"
                },
                "cookies": {
                    "title": "Cookie-Verwaltung"
                },
                "customers": {
                    "see_customers": "Sehen Sie unsere Kunden",
                    "title": "Sie vertrauen uns"
                },
                "footer": {
                    "account_deletion": "Kontolöschung",
                    "app": "Reise-App",
                    "contact": "Kontakt",
                    "cookies": "Cookie-Verwaltung",
                    "copyright": "© {year} UgoZER – Alle Rechte vorbehalten.",
                    "facebook": "Facebook",
                    "faq": "FAQ",
                    "home": "Heim",
                    "instagram": "Instagram",
                    "legal_notice": "Impressum",
                    "linkedin": "Linkedin",
                    "privacy_policy": "Datenschutzrichtlinie",
                    "pro": "Professioneller Zugang",
                    "sitemap": "Seitenverzeichnis",
                    "terms_conditions": "Geschäftsbedingungen",
                    "twitter": "Twitter"
                },
                "header": {
                    "app": "Reise-App",
                    "contact": "Kontakt",
                    "faq": "FAQ",
                    "home": "Heim",
                    "pro": "Professioneller Zugang",
                    "you_are": "Du bist"
                },
                "intro": {
                    "intro_1": "Mit UgoZER haben Sie immer Ihre Lieblingsadressen in mehrsprachigen Dateien auf Ihrem Smartphone,\n            mit einem Klick hörbar oder lesbar.",
                    "intro_2": "Wenn Sie im Ausland sind, machen Sie es den Taxifahrern und anderen Menschen leicht, Ihr Ziel zu verstehen.",
                    "intro_3": "Rezeptionistin, Hotelmanagerin, entwirft und bietet Tourprogramme an. Teilen Sie sie mit Ihren Kunden\n            auf UgoZER.",
                    "intro_title_pro": "PROFIS",
                    "intro_title_traveller": "REISENDER",
                    "title_homepage": "Der mehrsprachige Reiseführer, kostenlos."
                },
                "navigation": {
                    "next": "Nächste",
                    "previous": "Vorherige"
                },
                "outro": {
                    "description": "Reisen Sie im 2.0"
                },
                "partners": {
                    "no_records": "Zur Zeit keine Partner",
                    "read_more": "Mehr lesen",
                    "see_partners": "Sehen Sie sich unsere Partner an",
                    "title": "Partner"
                },
                "posts": {
                    "no_records": "Zur Zeit keine Beiträge",
                    "title": "FAQ."
                },
                "pro": "Professioneller Zugang",
                "professionals": {
                    "argument_1": {
                        "part_1": "UgoZER bietet eine digitale Lösung für Ihre Gäste.",
                        "part_2": "Verbessern Sie ihren Aufenthalt und das Gästeerlebnis.",
                        "part_3": "Es ermöglicht Ihrem Hotel, Resort oder Informationszentrum die Erstellung personalisierter, benutzerfreundlicher,\n                mehrsprachige mobile Reiserouten.",
                        "part_4": "Teilen Sie individuelle Reisen aus einer Echtzeit-POI-Datenbank über die professionelle Plattform UgoZER\n                und seine mobile App."
                    },
                    "argument_2": {
                        "part_1": "Sie können für Ihre Gäste vorgefertigte Reiserouten auswählen.",
                        "part_2": "Erstellen Sie Ihr eigenes mit den besten Orten oder Restaurants der Stadt, verborgenen Schätzen, die es zu entdecken gilt, perfekt\n                Wanderungen, Wanderwege zum Genießen...",
                        "part_3": "Oder wählen Sie jeden Schritt ihres Besuchs aus einer umfassenden Liste von POIs aus, um sie zu kombinieren\n                werden und dann eine personalisierte Reiseroute teilen."
                    },
                    "argument_3": {
                        "part_1": "Jeder POI verfügt über eine detaillierte Beschreibung, seine Position auf einer interaktiven Karte und seine\n                Adresse automatisch in die Landessprache übersetzt.",
                        "part_2": "Ihr Gast wird nie verloren gehen. Er wird immer seinen Weg finden und leicht zu Ihnen zurückkehren\n                Hotel."
                    },
                    "argument_4": {
                        "part_1": "Am Ende seiner Reise wird Ihr Gast, nachdem er eine maßgeschneiderte Reiseroute genossen hat, dazu in der Lage sein\n                Bedanken Sie sich bei Ihrem Concierge oder Rezeptionisten für seine einzigartige Empfehlung, indem Sie ihm ein Trinkgeld geben.",
                        "part_2": "Durch den Einsatz von UgoZER verbessern Sie die Kundenbindung durch höhere Einnahmen und verringern den Personalbestand\n                Umsatz.",
                        "part_3": "Sie steigern die Motivation, sich am Alltag Ihres Hotels zu beteiligen\n                und ihnen ein Zugehörigkeitsgefühl geben."
                    },
                    "intro": {
                        "main_title": "UgoZER, der mehrsprachige digitale und persönliche Reiseassistent. Nehmen Sie Ihren Gast mit\n                Bringen Sie Ihr Erlebnis auf die nächste Stufe und steigern Sie Ihren Umsatz."
                    },
                    "outro": {
                        "catchphrase": "UgoZER hilft Ihnen dabei, die Zufriedenheit zu steigern und das Wachstum voranzutreiben."
                    }
                },
                "startup": {
                    "description_1": "UgoZER ist ein französisches Unternehmen mit Sitz in Straßburg, der Hauptstadt Europas. Erstellt durch Reisen\n            Liebhabern, es erleichtert Ihre Reisen in Länder, deren Sprache Sie nicht sprechen.",
                    "description_2": "Es wird von den Tourismusbüros empfohlen.",
                    "title": "Das Startup UgoZER"
                },
                "support_request": "Unterstützungsanfrage",
                "travellers": {
                    "argument_1": {
                        "arguments": {
                            "audio": "<strong>Audio-Ansprache</strong> in der Landessprache, um einem Taxi oder einem Passanten zuzuhören",
                            "desc": "<strong>detaillierte Beschreibung</strong>",
                            "languages": "Alle Informationen sind ins Französische, Englische, Deutsche, Niederländische, Portugiesische übersetzt.\n                    Spanisch, Italienisch, Koreanisch, Chinesisch, Japanisch und Arabisch.</strong>",
                            "map": "<strong>Standort auf einer interaktiven Karte, Ausgabe auf Google Maps™ möglich</strong>"
                        },
                        "part_1": "Greifen Sie auf alle in Echtzeit aktualisierten Points of Interest (POI) auf Ihrem Smartphone zu.",
                        "part_2": "POIs klassifiziert nach <strong>Kategorien: Denkmäler, Museen, Ausstellungen, Geschäfte, Restaurants,\n                Bars, temporäre Veranstaltungen...</strong>",
                        "part_3": "POIs bereitgestellt mit:"
                    },
                    "argument_2": {
                        "part_1": "Genießen Sie Ihre Reise mit permanentem Zugriff auf umfassende Informationen. Alles auf Ihrem Smartphone,\n                direkt in deiner Tasche."
                    },
                    "argument_3": {
                        "part_1": "Mit UgoZER erhalten Sie automatisch kostenlose Reiserouten. Sie können die Reisen auch erstellen und teilen\n                sie mit deinen Freunden. Machen Sie das Beste aus Ihrer Reise, indem Sie personalisierte Touren von Ihrem Einheimischen buchen\n                Experte: Hotel-Concierge oder Rezeptionist, Touristeninformation.",
                        "part_2": "Wenn Ihnen Ihre Reise gefallen hat, können Sie sich sogar mit einer digitalisierten und gesicherten Nachricht bei Ihrem Experten vor Ort bedanken\n                Tipp-Option."
                    },
                    "intro": {
                        "main_title": "Ihr kostenloser mehrsprachiger Reiseführer"
                    },
                    "outro": {
                        "catchphrase": "Lassen Sie sich mit UgoZER sicher führen und reisen Sie beruhigt."
                    }
                },
                "you_are": {
                    "professional": "Fachmann",
                    "traveller": "Reisender"
                },
                "youtube": {
                    "directed_by": "Geleitet von",
                    "producer": "Suprahead-Studio"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "Wählen Sie Ihre Institution"
                    },
                    "sections": {
                        "pending_claims": "Bis zur Validierung",
                        "requests": "Offene Anfragen",
                        "validated_claims": "Behauptet"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "Die Anfrage wurde gesendet"
                },
                "approve": {
                    "confirm": {
                        "employee": "Möchten Sie die Anfrage wirklich genehmigen?",
                        "employer": "Möchten Sie {user_name} wirklich genehmigen?"
                    },
                    "success": "Der Antrag wurde genehmigt"
                },
                "cancel": {
                    "confirm": {
                        "employee": "Möchten Sie diese Anfrage wirklich stornieren?",
                        "employer": "Möchten Sie die {user_name}-Anfrage wirklich stornieren?"
                    },
                    "success": "Die Anfrage wurde storniert"
                },
                "decline": {
                    "confirm": {
                        "change_institution": "Ihre verknüpfte Institution wird geändert!",
                        "employee": "Möchten Sie die Anfrage wirklich ablehnen?",
                        "employer": "Wollen Sie {user_name} wirklich ablehnen?"
                    },
                    "success": "Die Anfrage wurde abgelehnt"
                },
                "feature": {
                    "confirm": "Möchten Sie {user_name} wirklich vorstellen?",
                    "folder": "Für einen Reisenden, der noch nie mit einem Ihrer Mitarbeiter interagiert hat, ist es das hier vorgestellte\n           wer im Ordner des Reisenden angezeigt wird.",
                    "only_one": "Es kann nur ein Mitarbeiter vorgestellt werden.",
                    "success": "Der Mitarbeiter wurde vorgestellt"
                },
                "index": {
                    "my_employees": "Meine Mitarbeiter"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "Konto",
                        "invitation": "Einladung"
                    }
                },
                "invite": {
                    "invite": "Laden Sie einen Mitarbeiter ein"
                },
                "quit": {
                    "confirm": "Willst du diese Institution wirklich verlassen {poi_name}?",
                    "success": "Sie haben die Einrichtung verlassen"
                },
                "remove": {
                    "confirm": "Möchten Sie {user_name} wirklich entfernen?",
                    "success": "Der Mitarbeiter wurde entfernt"
                },
                "show": {
                    "pending": "Ihr Antrag auf Beitritt zu dieser Institution ist derzeit ausstehend."
                },
                "states": {
                    "approved": "Genehmigt",
                    "canceled": "Abgesagt",
                    "declined": "Abgelehnt",
                    "invited": "Eingeladen",
                    "pending": "Ausstehend",
                    "removed": "ENTFERNT"
                }
            },
            "interactions": {
                "description": {
                    "contact approved": "Die Kontaktanfrage wurde von {sender_name} genehmigt",
                    "contact blocked": "Kontaktanfrage wurde von {sender_name} blockiert",
                    "contact created": "Kontaktanfrage wurde von {sender_name} erstellt",
                    "contact declined": "Kontaktanfrage wurde von {sender_name} abgelehnt",
                    "invitation accepted": "Die Einladung wurde von {sender_name} angenommen",
                    "invitation created": "Die Einladung wurde von {sender_name} gesendet",
                    "payment succeeded": "{sender_name} hat eine Zahlung an {recipient_name} gesendet",
                    "share created": "{sender_name} hat {share} geteilt",
                    "traveller_folder created": "Der Traveler-Ordner wurde von {sender_name} erstellt",
                    "traveller_folder thanked": "{sender_name} hat dem Reisenden für seinen Aufenthalt gedankt"
                },
                "share_type": {
                    "excursions": "die Reiseroute",
                    "pointsofinterest": "der Punkt von Interesse"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "Einladen"
                },
                "states": {
                    "authenticated_once": {
                        "false": "Ausstehend",
                        "true": "Bestätigt"
                    }
                },
                "success": {
                    "professional": {
                        "sent_1": "Eine E-Mail wird an {email} gesendet, sofern diese Adresse nicht bereits verwendet wird.",
                        "sent_2": "Diese E-Mail enthält einen Link zur Anmeldung für den Profi-Bereich.",
                        "title": "Einladung versendet"
                    },
                    "traveller": {
                        "email_1": "Eine E-Mail wird an {email} gesendet, sofern diese Adresse nicht bereits verwendet wird.",
                        "email_2": "Diese E-Mail enthält einen Link, der die Verbindung zur Anwendung ermöglicht.",
                        "title": "Einladung versendet"
                    }
                },
                "texts": {
                    "already_have_account": "Verfügt der Benutzer bereits über ein Konto in der Anwendung?",
                    "description": "Sie können jemanden mit einer E-Mail-Adresse oder Telefonnummer zur Teilnahme an unserer Bewerbung einladen.",
                    "employee_already_have_account": "Verfügt der Mitarbeiter bereits über ein Konto auf der Bewerbung?",
                    "geoloc_settings": {
                        "description": "Die Geolokalisierungseinstellungen werden automatisch auf Ihre Hotelkoordinaten eingestellt\n                (wenn Sie mit einer Einrichtung verbunden sind).",
                        "title": "Geolocation-Einstellungen"
                    },
                    "invite_link": {
                        "instructions": "Kopieren Sie diesen Link, um einen Reisenden einzuladen",
                        "title": "Per Link einladen"
                    },
                    "invite_professional": "Laden Sie einen Fachmann ein",
                    "invite_traveller": "Laden Sie einen Reisenden ein",
                    "new_traveller": "Neuer Reisender",
                    "professional_description": "Sie können einen Fachmann einladen, sich unserem professionellen Bereich anzuschließen\n            Bewerbung per E-Mail",
                    "title": "Lade jemanden ein"
                }
            },
            "locales": {
                "aa": "In der Ferne",
                "ab": "Abchasisch",
                "af": "Afrikaans",
                "af_NA": "Afrikaans (Namibia)",
                "af_ZA": "Afrikaans (Südafrika)",
                "ak": "Akan",
                "ak_GH": "Akan (Ghana)",
                "am": "Amharisch",
                "am_ET": "Amharisch (Äthiopien)",
                "an": "Aragonesisch",
                "ar": "Arabisch",
                "ar_AE": "Arabisch (Vereinigte Arabische Emirate)",
                "ar_BH": "Arabisch (Bahrain)",
                "ar_DJ": "Arabisch (Dschibuti)",
                "ar_DZ": "Arabisch (Algerien)",
                "ar_EG": "Arabisch (Ägypten)",
                "ar_EH": "Arabisch (Westsahara)",
                "ar_ER": "Arabisch (Eritrea)",
                "ar_IL": "Arabisch (Israel)",
                "ar_IQ": "Arabisch (Irak)",
                "ar_JO": "Arabisch (Jordanien)",
                "ar_KM": "Arabisch (Komoren)",
                "ar_KW": "Arabisch (Kuwait)",
                "ar_LB": "Arabisch (Libanon)",
                "ar_LY": "Arabisch (Libyen)",
                "ar_MA": "Arabisch (Marokko)",
                "ar_MR": "Arabisch (Mauretanien)",
                "ar_OM": "Arabisch (Oman)",
                "ar_PS": "Arabisch (Palästinensische Gebiete)",
                "ar_QA": "Arabisch (Katar)",
                "ar_SA": "Arabisch (Saudi-Arabien)",
                "ar_SD": "Arabisch (Sudan)",
                "ar_SO": "Arabisch (Somalia)",
                "ar_SS": "Arabisch (Südsudan)",
                "ar_SY": "Arabisch (Syrien)",
                "ar_TD": "Arabisch (Tschad)",
                "ar_TN": "Arabisch (Tunesien)",
                "ar_YE": "Arabisch (Jemen)",
                "as": "Assamesisch",
                "as_IN": "Assamesisch (Indien)",
                "av": "Avarisch",
                "ay": "Aymara",
                "az": "Aserbaidschanisch",
                "az_AZ": "Aserbaidschanisch (Aserbaidschan)",
                "az_Cyrl": "Aserbaidschanisch (Kyrillisch)",
                "az_Cyrl_AZ": "Aserbaidschanisch (Kyrillisch, Aserbaidschan)",
                "az_Latn": "Aserbaidschanisch (Latein)",
                "az_Latn_AZ": "Aserbaidschanisch (Latein, Aserbaidschan)",
                "ba": "Baschkirisch",
                "be": "Belarussisch",
                "be_BY": "Weißrussisch (Weißrussland)",
                "bg": "bulgarisch",
                "bg_BG": "Bulgarisch (Bulgarien)",
                "bh": "Bihari (Sprachen)",
                "bi": "Bislama",
                "bm": "Bambara",
                "bm_Latn": "Bambara (Latein)",
                "bm_Latn_ML": "Bambara (Latein, Mali)",
                "bn": "Bengali",
                "bn_BD": "Bengali (Bangladesch)",
                "bn_IN": "Bengali (Indien)",
                "bo": "Tibetisch",
                "bo_CN": "Tibetisch (China)",
                "bo_IN": "Tibetisch (Indien)",
                "br": "Bretonisch",
                "br_FR": "Bretonisch (Frankreich)",
                "bs": "bosnisch",
                "bs_BA": "Bosnisch (Bosnien und Herzegowina)",
                "bs_Cyrl": "Bosnisch (Kyrillisch)",
                "bs_Cyrl_BA": "Bosnisch (Kyrillisch, Bosnien und Herzegowina)",
                "bs_Latn": "Bosnisch (Latein)",
                "bs_Latn_BA": "Bosnisch (Latein, Bosnien und Herzegowina)",
                "ca": "katalanisch",
                "ca_AD": "Katalanisch (Andorra)",
                "ca_ES": "Katalanisch (Spanien)",
                "ca_FR": "Katalanisch (Frankreich)",
                "ca_IT": "Katalanisch (Italien)",
                "ce": "Tschetschenisch",
                "ch": "Chamorro",
                "co": "Korsisch",
                "cr": "Cree",
                "cs": "Tschechisch",
                "cs_CZ": "Tschechisch (Tschechische Republik)",
                "cu": "Altbulgarisch",
                "cv": "Tschuwaschisch",
                "cy": "Walisisch",
                "cy_GB": "Walisisch (Vereinigtes Königreich)",
                "da": "dänisch",
                "da_DK": "Dänisch (Dänemark)",
                "da_GL": "Dänisch (Grönland)",
                "de": "Deutsch",
                "de_AT": "Deutsch (Österreich)",
                "de_BE": "Deutsch (Belgien)",
                "de_CH": "Deutsch (Schweiz)",
                "de_DE": "Deutsches Deutschland)",
                "de_LI": "Deutsch (Liechtenstein)",
                "de_LU": "Deutsch (Luxemburg)",
                "dv": "Maledivisch",
                "dz": "Dzongkha",
                "dz_BT": "Dzongkha (Bhutan)",
                "ee": "Mutterschaf",
                "ee_GH": "Mutterschaf (Ghana)",
                "ee_TG": "Mutterschaf (Togo)",
                "el": "griechisch",
                "el_CY": "Griechisch (Zypern)",
                "el_GR": "Griechisch (Griechenland)",
                "en": "Englisch",
                "en_AG": "Englisch (Antigua und Barbuda)",
                "en_AI": "Englisch (Anguilla)",
                "en_AS": "Englisch (Amerikanisch-Samoa)",
                "en_AU": "Englisch (Australien)",
                "en_BB": "Englisch (Barbados)",
                "en_BE": "Englisch (Belgien)",
                "en_BM": "Englisch (Bermuda)",
                "en_BS": "Englisch (Bahamas)",
                "en_BW": "Englisch (Botswana)",
                "en_BZ": "Englisch (Belize)",
                "en_CA": "Englisch (Kanada)",
                "en_CC": "Englisch (Kokosinseln (Keelinginseln))",
                "en_CK": "Englisch (Cookinseln)",
                "en_CM": "Englisch (Kamerun)",
                "en_CX": "Englisch (Weihnachtsinsel)",
                "en_DG": "Englisch (Diego Garcia)",
                "en_DM": "Englisch (Dominica)",
                "en_ER": "Englisch (Eritrea)",
                "en_FJ": "Englisch (Fidschi)",
                "en_FK": "Englisch (Falklandinseln)",
                "en_FM": "Englisch (Mikronesien)",
                "en_GB": "Englisches Vereinigtes Königreich)",
                "en_GD": "Englisch (Grenada)",
                "en_GG": "Englisch (Guernsey)",
                "en_GH": "Englisch (Ghana)",
                "en_GI": "Englisch (Gibraltar)",
                "en_GM": "Englisch (Gambia)",
                "en_GU": "Englisch (Guam)",
                "en_GY": "Englisch (Guyana)",
                "en_HK": "Englisch (SVR China Hongkong)",
                "en_IE": "Englisch (Irland)",
                "en_IM": "Englisch (Isle of Man)",
                "en_IN": "Englisch (Indien)",
                "en_IO": "Englisch (Britisches Territorium im Indischen Ozean)",
                "en_JE": "Englisch (Jersey)",
                "en_JM": "Englisch (Jamaika)",
                "en_KE": "Englisch (Kenia)",
                "en_KI": "Englisch (Kiribati)",
                "en_KN": "Englisch (St. Kitts und Nevis)",
                "en_KY": "Englisch (Kaimaninseln)",
                "en_LC": "Englisch (St. Lucia)",
                "en_LR": "Englisch (Liberia)",
                "en_LS": "Englisch (Lesotho)",
                "en_MG": "Englisch (Madagaskar)",
                "en_MH": "Englisch (Marshallinseln)",
                "en_MO": "Englisch (Macau SAR China)",
                "en_MP": "Englisch (Nördliche Marianen)",
                "en_MS": "Englisch (Montserrat)",
                "en_MT": "Englisch (Malta)",
                "en_MU": "Englisch (Mauritius)",
                "en_MW": "Englisch (Malawi)",
                "en_MY": "Englisch (Malaysia)",
                "en_NA": "Englisch (Namibia)",
                "en_NF": "Englisch (Norfolkinsel)",
                "en_NG": "Englisch (Nigeria)",
                "en_NR": "Englisch (Nauru)",
                "en_NU": "Englisch (Niue)",
                "en_NZ": "Englisch (Neuseeland)",
                "en_PG": "Englisch (Papua-Neuguinea)",
                "en_PH": "Englisch (Philippinen)",
                "en_PK": "Englisch (Pakistan)",
                "en_PN": "Englisch (Pitcairninseln)",
                "en_PR": "Englisch (Puerto Rico)",
                "en_PW": "Englisch (Palau)",
                "en_RW": "Englisch (Ruanda)",
                "en_SB": "Englisch (Salomonen)",
                "en_SC": "Englisch (Seychellen)",
                "en_SD": "Englisch (Sudan)",
                "en_SG": "Englisch (Singapur)",
                "en_SH": "Englisch (St. Helena)",
                "en_SL": "Englisch (Sierra Leone)",
                "en_SS": "Englisch (Südsudan)",
                "en_SX": "Englisch (Sint Maarten)",
                "en_SZ": "Englisch (Swasiland)",
                "en_TC": "Englisch (Turks- und Caicosinseln)",
                "en_TK": "Englisch (Tokelau)",
                "en_TO": "Englisch (Tonga)",
                "en_TT": "Englisch (Trinidad und Tobago)",
                "en_TV": "Englisch (Tuvalu)",
                "en_TZ": "Englisch (Tansania)",
                "en_UG": "Englisch (Uganda)",
                "en_UM": "Englisch (Vorgelagerte Inseln der USA)",
                "en_US": "Englisch (USA)",
                "en_VC": "Englisch (St. Vincent und Grenadinen)",
                "en_VG": "Englisch (Britische Jungferninseln)",
                "en_VI": "Englisch (US-amerikanische Jungferninseln)",
                "en_VU": "Englisch (Vanuatu)",
                "en_WS": "Englisch (Samoa)",
                "en_ZA": "Englisch (Südafrika)",
                "en_ZM": "Englisch (Sambia)",
                "en_ZW": "Englisch (Simbabwe)",
                "eo": "Esperanto",
                "es": "Spanisch",
                "es_AR": "Spanisch (Argentinien)",
                "es_BO": "Spanisch (Bolivien)",
                "es_CL": "Spanisch (Chile)",
                "es_CO": "Spanisch (Kolumbien)",
                "es_CR": "Spanisch (Costa Rica)",
                "es_CU": "Spanisch (Kuba)",
                "es_DO": "Spanisch (Dominikanische Republik)",
                "es_EA": "Spanisch (Ceuta und Melilla)",
                "es_EC": "Spanisch (Ecuador)",
                "es_ES": "Spanisch (Spanien)",
                "es_GQ": "Spanisch (Äquatorialguinea)",
                "es_GT": "Spanisch (Guatemala)",
                "es_HN": "Spanisch (Honduras)",
                "es_IC": "Spanisch (Kanarische Inseln)",
                "es_MX": "Spanisch (Mexiko)",
                "es_NI": "Spanisch (Nicaragua)",
                "es_PA": "Spanisch (Panama)",
                "es_PE": "Spanisch (Peru)",
                "es_PH": "Spanisch (Philippinen)",
                "es_PR": "Spanisch (Puerto Rico)",
                "es_PY": "Spanisch (Paraguay)",
                "es_SV": "Spanisch (El Salvador)",
                "es_US": "Spanisch (USA)",
                "es_UY": "Spanisch (Uruguay)",
                "es_VE": "Spanisch (Venezuela)",
                "et": "estnisch",
                "et_EE": "Estnisch (Estland)",
                "eu": "baskisch",
                "eu_ES": "Baskisch (Spanien)",
                "fa": "persisch",
                "fa_AF": "Persisch (Afghanistan)",
                "fa_IR": "Persisch (Iran)",
                "ff": "Fulah",
                "ff_CM": "Fulah (Kamerun)",
                "ff_GN": "Fulah (Guinea)",
                "ff_MR": "Fulah (Mauretanien)",
                "ff_SN": "Fulah (Senegal)",
                "fi": "finnisch",
                "fi_FI": "Finnisch (Finnland)",
                "fj": "Fidschianisch",
                "fo": "Färöisch",
                "fo_FO": "Färöisch (Färöer-Inseln)",
                "fr": "Französisch",
                "fr_BE": "Französisch (Belgien)",
                "fr_BF": "Französisch (Burkina Faso)",
                "fr_BI": "Französisch (Burundi)",
                "fr_BJ": "Französisch (Benin)",
                "fr_BL": "Französisch (St. Barthélemy)",
                "fr_CA": "Französisch (Kanada)",
                "fr_CD": "Französisch (Kongo – Kinshasa)",
                "fr_CF": "Französisch (Zentralafrikanische Republik)",
                "fr_CG": "Französisch (Kongo – Brazzaville)",
                "fr_CH": "Französisch (Schweiz)",
                "fr_CI": "Französisch (Côte d’Ivoire)",
                "fr_CM": "Französisch (Kamerun)",
                "fr_DJ": "Französisch (Dschibuti)",
                "fr_DZ": "Französisch (Algerien)",
                "fr_FR": "Französisch Frankreich)",
                "fr_GA": "Französisch (Gabun)",
                "fr_GF": "Französisch (Französisch-Guayana)",
                "fr_GN": "Französisch (Guinea)",
                "fr_GP": "Französisch (Guadeloupe)",
                "fr_GQ": "Französisch (Äquatorialguinea)",
                "fr_HT": "Französisch (Haiti)",
                "fr_KM": "Französisch (Komoren)",
                "fr_LU": "Französisch (Luxemburg)",
                "fr_MA": "Französisch (Marokko)",
                "fr_MC": "Französisch (Monaco)",
                "fr_MF": "Französisch (St. Martin)",
                "fr_MG": "Französisch (Madagaskar)",
                "fr_ML": "Französisch (Mali)",
                "fr_MQ": "Französisch (Martinique)",
                "fr_MR": "Französisch (Mauretanien)",
                "fr_MU": "Französisch (Mauritius)",
                "fr_NC": "Französisch (Neukaledonien)",
                "fr_NE": "Französisch (Niger)",
                "fr_PF": "Französisch (Französisch-Polynesien)",
                "fr_PM": "Französisch (St. Pierre und Miquelon)",
                "fr_RE": "Französisch (Réunion)",
                "fr_RW": "Französisch (Ruanda)",
                "fr_SC": "Französisch (Seychellen)",
                "fr_SN": "Französisch (Senegal)",
                "fr_SY": "Französisch (Syrien)",
                "fr_TD": "Französisch (Tschad)",
                "fr_TG": "Französisch (Togo)",
                "fr_TN": "Französisch (Tunesien)",
                "fr_VU": "Französisch (Vanuatu)",
                "fr_WF": "Französisch (Wallis und Futuna)",
                "fr_YT": "Französisch (Mayotte)",
                "fy": "Westfriesisch",
                "fy_NL": "Westfriesisch (Niederlande)",
                "ga": "irisch",
                "ga_IE": "Irisch (Irland)",
                "gd": "schottisch Gälisch",
                "gd_GB": "Schottisch-Gälisch (Vereinigtes Königreich)",
                "gl": "galizisch",
                "gl_ES": "Galizisch (Spanien)",
                "gn": "Guarani",
                "gu": "Gujarati",
                "gu_IN": "Gujarati (Indien)",
                "gv": "Manx",
                "gv_IM": "Manx (Insel Man)",
                "ha": "Hausa",
                "ha_GH": "Hausa (Ghana)",
                "ha_Latn": "Hausa (lateinisch)",
                "ha_Latn_GH": "Hausa (Latein, Ghana)",
                "ha_Latn_NE": "Hausa (Latein, Niger)",
                "ha_Latn_NG": "Hausa (Latein, Nigeria)",
                "ha_NE": "Hausa (Niger)",
                "ha_NG": "Hausa (Nigeria)",
                "he": "hebräisch",
                "he_IL": "Hebräisch (Israel)",
                "hi": "Hindi",
                "hi_IN": "Hindi (Indien)",
                "ho": "Hiri Motu",
                "hr": "kroatisch",
                "hr_BA": "Kroatisch (Bosnien und Herzegowina)",
                "hr_HR": "Kroatisch (Kroatien)",
                "ht": "haitianisch",
                "hu": "ungarisch",
                "hu_HU": "Ungarisch (Ungarn)",
                "hy": "Armenisch",
                "hy_AM": "Armenisch (Armenien)",
                "hz": "Herero",
                "ia": "Interlingua",
                "id": "Indonesisch",
                "id_ID": "Indonesisch (Indonesien)",
                "ie": "Zwischensprache",
                "ig": "Igbo",
                "ig_NG": "Igbo (Nigeria)",
                "ii": "Sichuan Yi",
                "ii_CN": "Sichuan Yi (China)",
                "ik": "Inupiaq",
                "io": "Ich tue",
                "is": "isländisch",
                "is_IS": "Isländisch (Island)",
                "it": "Italienisch",
                "it_CH": "Italienisch (Schweiz)",
                "it_IT": "Italienisch (Italien)",
                "it_SM": "Italienisch (San Marino)",
                "iu": "Inuktitut",
                "ja": "japanisch",
                "ja_JP": "Japanisch (Japan)",
                "jv": "Javanisch",
                "ka": "georgisch",
                "ka_GE": "Georgisch (Georgien)",
                "kg": "Kongo",
                "ki": "Kikuyu",
                "ki_KE": "Kikuyu (Kenia)",
                "kj": "Kwanyama",
                "kk": "Kasachisch",
                "kk_Cyrl": "Kasachisch (Kyrillisch)",
                "kk_Cyrl_KZ": "Kasachisch (Kyrillisch, Kasachstan)",
                "kk_KZ": "Kasachisch (Kasachstan)",
                "kl": "Kalaallisut",
                "kl_GL": "Kalaallisut (Grönland)",
                "km": "Khmer",
                "km_KH": "Khmer (Kambodscha)",
                "kn": "Kannada",
                "kn_IN": "Kannada (Indien)",
                "ko": "Koreanisch",
                "ko_KP": "Koreanisch (Nordkorea)",
                "ko_KR": "Koreanisch (Südkorea)",
                "kr": "Kanuri",
                "ks": "Kaschmir",
                "ks_Arab": "Kaschmiri (Arabisch)",
                "ks_Arab_IN": "Kaschmir (Arabisch, Indien)",
                "ks_IN": "Kaschmir (Indien)",
                "ku": "kurdisch",
                "kv": "Komi",
                "kw": "kornisch",
                "kw_GB": "Cornish (Vereinigtes Königreich)",
                "ky": "Kirgisisch",
                "ky_Cyrl": "Kirgisisch (Kyrillisch)",
                "ky_Cyrl_KG": "Kirgisisch (Kyrillisch, Kirgisistan)",
                "ky_KG": "Kirgisisch (Kirgisistan)",
                "la": "Latein",
                "lb": "Luxemburgisch",
                "lb_LU": "Luxemburgisch (Luxemburgisch)",
                "lg": "Ganda",
                "lg_UG": "Ganda (Uganda)",
                "li": "Limburgisch",
                "ln": "Lingala",
                "ln_AO": "Lingala (Angola)",
                "ln_CD": "Lingala (Kongo – Kinshasa)",
                "ln_CF": "Lingala (Zentralafrikanische Republik)",
                "ln_CG": "Lingala (Kongo - Brazzaville)",
                "lo": "Laotisch",
                "lo_LA": "Lao (Laos)",
                "lt": "litauisch",
                "lt_LT": "Litauisch (Litauen)",
                "lu": "Luba-Katanga",
                "lu_CD": "Luba-Katanga (Kongo – Kinshasa)",
                "lv": "lettisch",
                "lv_LV": "Lettisch (Lettland)",
                "mg": "Madagassisch",
                "mg_MG": "Madagassisch (Madagaskar)",
                "mh": "Marshallesisch",
                "mi": "Maori",
                "mk": "mazedonisch",
                "mk_MK": "Mazedonisch (Mazedonien)",
                "ml": "Malayalam",
                "ml_IN": "Malayalam (Indien)",
                "mn": "mongolisch",
                "mn_Cyrl": "Mongolisch (Kyrillisch)",
                "mn_Cyrl_MN": "Mongolisch (Kyrillisch, Mongolei)",
                "mn_MN": "Mongolisch (Mongolei)",
                "mo": "Macau",
                "mr": "Marathi",
                "mr_IN": "Marathi (Indien)",
                "ms": "malaiisch",
                "ms_BN": "Malaiisch (Brunei)",
                "ms_Latn": "Malaiisch (Latein)",
                "ms_Latn_BN": "Malaiisch (Latein, Brunei)",
                "ms_Latn_MY": "Malaiisch (Latein, Malaysia)",
                "ms_Latn_SG": "Malaiisch (Latein, Singapur)",
                "ms_MY": "Malaiisch (Malaysia)",
                "ms_SG": "Malaiisch (Singapur)",
                "mt": "maltesisch",
                "mt_MT": "Maltesisch (Malta)",
                "my": "birmanisch",
                "my_MM": "Burmesisch (Myanmar (Burma))",
                "na": "Nauru",
                "nb": "Norwegisches Bokmål",
                "nb_NO": "Norwegisches Bokmål (Norwegen)",
                "nb_SJ": "Norwegisches Bokmål (Spitzbergen und Jan Mayen)",
                "nd": "Nord-Ndebele",
                "nd_ZW": "Nord-Ndebele (Simbabwe)",
                "ne": "Nepali",
                "ne_IN": "Nepali (Indien)",
                "ne_NP": "Nepali (Nepal)",
                "ng": "Ndonga",
                "nl": "Niederländisch",
                "nl_AW": "Niederländisch (Aruba)",
                "nl_BE": "Niederländisch (Belgien)",
                "nl_BQ": "Niederländisch (Karibische Niederlande)",
                "nl_CW": "Niederländisch (Curaçao)",
                "nl_NL": "Niederländisch (Niederlande)",
                "nl_SR": "Niederländisch (Suriname)",
                "nl_SX": "Niederländisch (Sint Maarten)",
                "nn": "Norwegisches Nynorsk",
                "nn_NO": "Norwegisch Nynorsk (Norwegen)",
                "no": "norwegisch",
                "no_NO": "Norwegisch (Norwegen)",
                "nr": "Süd-Ndebele",
                "nv": "Navaho",
                "ny": "Chichewa",
                "oc": "Okzitanisch",
                "oj": "Ojibwa",
                "om": "Oromo",
                "om_ET": "Oromo (Äthiopien)",
                "om_KE": "Oromo (Kenia)",
                "or": "Oriya",
                "or_IN": "Oriya (Indien)",
                "os": "Ossetisch",
                "os_GE": "Ossetisch (Georgien)",
                "os_RU": "Ossetisch (Russland)",
                "pa": "Punjabi",
                "pa_Arab": "Punjabi (Arabisch)",
                "pa_Arab_PK": "Punjabi (Arabisch, Pakistan)",
                "pa_Guru": "Punjabi (Gurmukhi)",
                "pa_Guru_IN": "Punjabi (Gurmukhi, Indien)",
                "pa_IN": "Punjabi (Indien)",
                "pa_PK": "Punjabi (Pakistan)",
                "pi": "Pali",
                "pl": "Polieren",
                "pl_PL": "Polnisch (Polen)",
                "ps": "Paschtu",
                "ps_AF": "Paschtu (Afghanistan)",
                "pt": "Portugiesisch",
                "pt_AO": "Portugiesisch (Angola)",
                "pt_BR": "Portugiesisch (Brasilien)",
                "pt_CV": "Portugiesisch (Kap Verde)",
                "pt_GW": "Portugiesisch (Guinea-Bissau)",
                "pt_MO": "Portugiesisch (SVR China Macau)",
                "pt_MZ": "Portugiesisch (Mosambik)",
                "pt_PT": "Portugiesisch (Portugal)",
                "pt_ST": "Portugiesisch (São Tomé und Príncipe)",
                "pt_TL": "Portugiesisch (Timor-Leste)",
                "qu": "Quechua",
                "qu_BO": "Quechua (Bolivien)",
                "qu_EC": "Quechua (Ecuador)",
                "qu_PE": "Quechua (Peru)",
                "rm": "Rätoromanisch",
                "rm_CH": "Rätoromanisch (Schweiz)",
                "rn": "Rundi",
                "rn_BI": "Rundi (Burundi)",
                "ro": "rumänisch",
                "ro_MD": "Rumänisch (Moldawien)",
                "ro_RO": "Rumänisch (Rumänien)",
                "ru": "Russisch",
                "ru_BY": "Russisch (Weißrussland)",
                "ru_KG": "Russisch (Kirgisistan)",
                "ru_KZ": "Russisch (Kasachstan)",
                "ru_MD": "Russisch (Moldawien)",
                "ru_RU": "Russisch (Russland)",
                "ru_UA": "Russisch (Ukraine)",
                "rw": "Kinyarwanda",
                "rw_RW": "Kinyarwanda (Ruanda)",
                "sa": "Sanskrit",
                "sc": "Sardisch",
                "sd": "Sindhi",
                "se": "Nordsamisch",
                "se_FI": "Nordsamisch (Finnland)",
                "se_NO": "Nordsamisch (Norwegen)",
                "se_SE": "Nordsamisch (Schweden)",
                "sg": "Sango",
                "sg_CF": "Sango (Zentralafrikanische Republik)",
                "sh": "Serbokroatisch",
                "sh_BA": "Serbokroatisch (Bosnien und Herzegowina)",
                "si": "Singhalesisch",
                "si_LK": "Singhalesisch (Sri Lanka)",
                "sk": "slowakisch",
                "sk_SK": "Slowakisch (Slowakei)",
                "sl": "Slowenisch",
                "sl_SI": "Slowenisch (Slowenien)",
                "sm": "Samoaner",
                "sn": "Shona",
                "sn_ZW": "Shona (Simbabwe)",
                "so": "somali",
                "so_DJ": "Somali (Dschibuti)",
                "so_ET": "Somali (Äthiopien)",
                "so_KE": "Somali (Kenia)",
                "so_SO": "Somali (Somalia)",
                "sq": "albanisch",
                "sq_AL": "Albanisch (Albanien)",
                "sq_MK": "Albanisch (Mazedonien)",
                "sq_XK": "Albanisch (Kosovo)",
                "sr": "serbisch",
                "sr_BA": "Serbisch (Bosnien und Herzegowina)",
                "sr_Cyrl": "Serbisch (Kyrillisch)",
                "sr_Cyrl_BA": "Serbisch (Kyrillisch, Bosnien und Herzegowina)",
                "sr_Cyrl_ME": "Serbisch (Kyrillisch, Montenegro)",
                "sr_Cyrl_RS": "Serbisch (Kyrillisch, Serbien)",
                "sr_Cyrl_XK": "Serbisch (Kyrillisch, Kosovo)",
                "sr_Latn": "Serbisch (Latein)",
                "sr_Latn_BA": "Serbisch (Latein, Bosnien und Herzegowina)",
                "sr_Latn_ME": "Serbisch (Latein, Montenegro)",
                "sr_Latn_RS": "Serbisch (Latein, Serbien)",
                "sr_Latn_XK": "Serbisch (Latein, Kosovo)",
                "sr_ME": "Serbisch (Montenegro)",
                "sr_RS": "Serbisch (Serbien)",
                "sr_XK": "Serbisch (Kosovo)",
                "ss": "Swati",
                "st": "Sotho, Südstaaten",
                "su": "Sundanesisch",
                "sv": "Schwedisch",
                "sv_AX": "Schwedisch (Åland-Inseln)",
                "sv_FI": "Schwedisch (Finnland)",
                "sv_SE": "Schwedisch (Schweden)",
                "sw": "Suaheli",
                "sw_KE": "Suaheli (Kenia)",
                "sw_TZ": "Suaheli (Tansania)",
                "sw_UG": "Suaheli (Uganda)",
                "ta": "Tamilisch",
                "ta_IN": "Tamil (Indien)",
                "ta_LK": "Tamil (Sri Lanka)",
                "ta_MY": "Tamil (Malaysia)",
                "ta_SG": "Tamil (Singapur)",
                "te": "Telugu",
                "te_IN": "Telugu (Indien)",
                "tg": "Tadschikisch",
                "th": "Thailändisch",
                "th_TH": "Thailändisch (Thailand)",
                "ti": "Tigrinya",
                "ti_ER": "Tigrinya (Eritrea)",
                "ti_ET": "Tigrinya (Äthiopien)",
                "tk": "Turkmenisch",
                "tl": "Tagalog",
                "tl_PH": "Tagalog (Philippinen)",
                "tn": "Tswana",
                "to": "Tonganisch",
                "to_TO": "Tongaisch (Tonga)",
                "tr": "Türkisch",
                "tr_CY": "Türkisch (Zypern)",
                "tr_TR": "Türkisch (Türkei)",
                "ts": "Tsonga",
                "tt": "Tatarisch",
                "tw": "Twi",
                "ty": "Tahitianisch",
                "ug": "Uigur",
                "ug_Arab": "Uigurisch (Arabisch)",
                "ug_Arab_CN": "Uigurisch (Arabisch, China)",
                "ug_CN": "Uiguren (China)",
                "uk": "ukrainisch",
                "uk_UA": "Ukrainisch (Ukraine)",
                "ur": "Urdu",
                "ur_IN": "Urdu (Indien)",
                "ur_PK": "Urdu (Pakistan)",
                "uz": "Usbekisch",
                "uz_AF": "Usbekisch (Afghanistan)",
                "uz_Arab": "Usbekisch (Arabisch)",
                "uz_Arab_AF": "Usbekisch (Arabisch, Afghanistan)",
                "uz_Cyrl": "Usbekisch (Kyrillisch)",
                "uz_Cyrl_UZ": "Usbekisch (Kyrillisch, Usbekistan)",
                "uz_Latn": "Usbekisch (Latein)",
                "uz_Latn_UZ": "Usbekisch (Latein, Usbekistan)",
                "uz_UZ": "Usbekisch (Usbekistan)",
                "ve": "Venda",
                "vi": "Vietnamesisch",
                "vi_VN": "Vietnamesisch (Vietnam)",
                "vo": "Volapük",
                "wa": "wallonisch",
                "wo": "Wolof",
                "xh": "Xhosa",
                "yi": "Jiddisch",
                "yo": "Yoruba",
                "yo_BJ": "Yoruba (Benin)",
                "yo_NG": "Yoruba (Nigeria)",
                "za": "Zhuang",
                "zh": "Chinesisch",
                "zh_CN": "Chinesisch (China)",
                "zh_HK": "Chinesisch (SVR China Hongkong)",
                "zh_Hans": "Vereinfachtes Chinesisch)",
                "zh_Hans_CN": "Chinesisch (vereinfacht, China)",
                "zh_Hans_HK": "Chinesisch (vereinfacht, Sonderverwaltungszone Hongkong)",
                "zh_Hans_MO": "Chinesisch (vereinfacht, Sonderverwaltungszone Macau)",
                "zh_Hans_SG": "Chinesisch (vereinfacht, Singapur)",
                "zh_Hant": "Chinesische Tradition)",
                "zh_Hant_HK": "Chinesisch (traditionell, Sonderverwaltungszone Hongkong)",
                "zh_Hant_MO": "Chinesisch (traditionell, Sonderverwaltungszone Macau)",
                "zh_Hant_TW": "Chinesisch (traditionell, Taiwan)",
                "zh_MO": "Chinesisch (Sonderverwaltungsregion Macau)",
                "zh_SG": "Chinesisch (Singapur)",
                "zh_TW": "Chinesisch (Taiwan)",
                "zu": "Zulu",
                "zu_ZA": "Zulu (Südafrika)"
            },
            "mail": {
                "action": "Wenn Sie Probleme beim Klicken auf die Schaltfläche „{actionText}“ haben, kopieren Sie die folgende URL und fügen Sie sie in Ihren Webbrowser ein:",
                "auth": {
                    "deleted": {
                        "line1": "Wir informieren Sie, dass Ihr UgoZER-Konto gelöscht wurde.",
                        "reason": {
                            "intro": "Dieser wurde aus folgendem Grund gelöscht:",
                            "never_authenticated_for_too_long": "Ihr Konto wurde vor mehr als einer Woche erstellt\n                    und Sie haben sich nie angemeldet:\n                    Daher gilt dieses Konto als inaktiv.",
                            "not_logged_for_too_long": "Sie haben sich seit mehr als 2 Jahren nicht angemeldet:\n                    Ihr Konto gilt als inaktiv."
                        },
                        "subject": "Löschung Ihres UgoZER-Kontos"
                    },
                    "login_attempt_unverified_credential": {
                        "action": "Email Adresse bestätigen",
                        "line1": "Es wurde versucht, sich mit der E-Mail-Adresse {credential} bei Ihrem UgoZER-Konto anzumelden.",
                        "line2": "Wenn Sie diesen Anmeldeversuch gestartet haben,\n                Bitte klicken Sie auf die Schaltfläche unten, um Ihre E-Mail-Adresse zu bestätigen\n                und in der Lage sein, eine Verbindung herzustellen.",
                        "line3": "Dieser Link läuft in einer Woche ab.",
                        "line4": "Wenn Sie diesen Anmeldeversuch nicht initiiert haben, sind keine weiteren Maßnahmen erforderlich.",
                        "subject": "Versuchen Sie, sich bei Ihrem UgoZER-Konto anzumelden"
                    },
                    "login_magic_link": {
                        "action": "Anmeldung",
                        "line1": "Klicken Sie auf diesen Link, um sich anzumelden",
                        "line2": "Vielen Dank, dass Sie unsere Anwendung nutzen!",
                        "subject": "Passwortloser Login-Link"
                    },
                    "not_logged": {
                        "intro": "Es ist fast zwei Jahre her, seit Sie sich das letzte Mal bei Ihrem UgoZER-Konto angemeldet haben.",
                        "outro": "Melden Sie sich einfach bei Ihrem Konto an, damit es nicht gelöscht wird.",
                        "remaining": {
                            "two_days": "Sie haben noch 2 Tage Zeit, bevor Ihr Konto gelöscht wird.",
                            "two_months": "Sie haben noch 2 Monate Zeit, bevor Ihr Konto gelöscht wird.",
                            "two_weeks": "Sie haben noch zwei Wochen Zeit, bevor Ihr Konto gelöscht wird."
                        },
                        "subject": "Ihr UgoZER-Konto wird bald gelöscht"
                    },
                    "registration": {
                        "action": "Email Adresse bestätigen",
                        "line1": "Bitte klicken Sie auf die Schaltfläche unten, um Ihre E-Mail-Adresse zu bestätigen und sich anmelden zu können.",
                        "line2": "Dieser Link läuft in einer Woche ab.",
                        "line3": "Wenn Sie kein Konto erstellt haben, sind keine weiteren Maßnahmen erforderlich.",
                        "subject": "Bestätige deine Email-Adresse"
                    },
                    "registration_attempt": {
                        "line1": "Die <strong>{credential}</strong>-E-Mail wird in unserer Anwendung bereits für ein <strong>{role_type}</strong>-Konto verwendet.",
                        "line2": "Um ein Konto zu erstellen, starten Sie die Registrierung bitte erneut mit einer anderen E-Mail.",
                        "subject": "Versuchen Sie, sich mit Ihren UgoZER-Zugangsdaten zu registrieren"
                    },
                    "registration_attempt_unverified_credential": {
                        "line1": "Die <strong>{credential}</strong>-E-Mail wird in unserer Anwendung bereits für ein <strong>{role_type}</strong>-Konto verwendet.",
                        "line2": "Um ein Konto zu erstellen, starten Sie die Registrierung bitte erneut mit einer anderen E-Mail.",
                        "subject": "Versuchen Sie, sich mit Ihren UgoZER-Zugangsdaten zu registrieren"
                    },
                    "reset_password": {
                        "action": "Passwort zurücksetzen",
                        "line1": "Sie erhalten diese E-Mail, weil wir eine Anfrage zum Zurücksetzen des Passworts für Ihr Konto erhalten haben.",
                        "line2": "Dieser Link zum Zurücksetzen des Passworts läuft in {count} Minuten ab.",
                        "line3": "Wenn Sie keine Passwortzurücksetzung angefordert haben, sind keine weiteren Maßnahmen erforderlich.",
                        "subject": "Passwortbenachrichtigung zurücksetzen"
                    }
                },
                "copyright": "© {year} UgoZER – Alle Rechte vorbehalten.",
                "credential_verification": {
                    "verify": {
                        "action": "Email Adresse bestätigen",
                        "line1": "Bitte klicken Sie auf die Schaltfläche unten, um Ihre E-Mail-Adresse zu bestätigen.",
                        "line2": "Dieser Link läuft in einer Woche ab.",
                        "line3": "Wenn Sie keine Verifizierung angefordert haben, sind keine weiteren Maßnahmen erforderlich.",
                        "subject": "Bestätigung Ihrer E-Mail"
                    }
                },
                "hello": "Hallo!",
                "invitation": {
                    "attempt": {
                        "action": "Zugriff auf die Anwendung",
                        "line1": "Sie wurden eingeladen, an unserer Bewerbung teilzunehmen",
                        "line1_by": "Sie wurden von {name} eingeladen, an unserer Bewerbung teilzunehmen",
                        "line1_by_from": "Sie wurden von {name} von der {poi_title}-Institution eingeladen, an unserer Bewerbung teilzunehmen",
                        "line2": "Sie haben bereits ein bestehendes Konto in unserer Anwendung",
                        "line3": "Klicken Sie bitte auf die Schaltfläche unten, um zur Anwendung zu gelangen.",
                        "subject": "Einladung, UgoZER beizutreten"
                    },
                    "verification": {
                        "action": "Email Adresse bestätigen",
                        "excursion_shared": "Eine Reiseroute wurde mit Ihnen geteilt. Sie finden es im PDF-Format im Anhang.\n                    sowie in der Anmeldung in Ihren Anteilen.",
                        "line1": "Sie wurden eingeladen, an unserer Bewerbung teilzunehmen",
                        "line1_by": "Sie wurden von {name} eingeladen, an unserer Bewerbung teilzunehmen",
                        "line1_by_from": "Sie wurden von {name} von der {poi_title}-Institution eingeladen, an unserer Bewerbung teilzunehmen",
                        "line2": "Bitte klicken Sie auf die Schaltfläche unten, um Ihre E-Mail-Adresse zu bestätigen.",
                        "line3": "Dieser Link läuft in einer Woche ab.",
                        "line4": "Vielen Dank, dass Sie unsere Anwendung nutzen!",
                        "point_of_interest_shared": "Ein interessanter Punkt wurde mit Ihnen geteilt. Du kannst es finden\n                im Antrag auf Ihre Anteile.",
                        "subject": "Einladung, UgoZER beizutreten"
                    }
                },
                "invitation_professional": {
                    "content": "Sie wurden eingeladen, dem professionellen Bereich unserer Anwendung UgoZER beizutreten",
                    "cta": "Registrieren Sie sich im professionellen Bereich",
                    "subject": "UgoZER - Einladung zum professionellen Zugang",
                    "thanks": "Danke,",
                    "title": "Einladung zum professionellen Bereich der UgoZER-Anwendung"
                },
                "preview": {
                    "default": {
                        "subject": "E-Mail Betreff",
                        "user": {
                            "name": "UgoZER"
                        }
                    },
                    "from": "Aus:",
                    "mails": {
                        "auth/account-deleted-notification": "Kontolöschung",
                        "auth/not-logged-notification": "Löschen, wenn keine Verbindung besteht",
                        "auth/registration-attempt-notification": "Versuchte Registrierung",
                        "auth/registration-notification": "Anmeldung",
                        "auth/reset-password-notification": "Ändern Sie Ihr Passwort",
                        "invitation/invited-notification": "Einladung",
                        "share/created-notification": "Neu",
                        "support-message/message-notification": "Neue Antwort",
                        "support-request/archived-notification": "Archivierte Anfrage",
                        "support-request/created-notification": "Anfrage erstellt",
                        "user-credential-verification/login-attempt-notification": "Versuchen Sie, eine Verbindung herzustellen",
                        "user-credential-verification/registration-attempt-notification": "Versuchte Registrierung",
                        "user-credential-verification/verify-notification": "Account Bestätigung",
                        "user/welcome-notification": "Willkommen"
                    },
                    "main_title": "E-Mail-Vorschau",
                    "sections": {
                        "auth": "Authentifizierung",
                        "invitation": "Einladungen",
                        "share": "Anteile",
                        "support-message": "Support-Nachrichten",
                        "support-request": "Supportanfragen",
                        "user": "Benutzer",
                        "user-credential-verification": "Benutzeranmeldeinformationen"
                    }
                },
                "salutations": "Grüße",
                "share": {
                    "created": {
                        "action": "Zeigen",
                        "excursion": {
                            "gotoapp": "Gehen Sie zur App, um es anzuzeigen.",
                            "line": "{username} von {poi_title} Institution hat eine Reiseroute mit Ihnen geteilt.",
                            "line_alt": "{username} hat eine Reiseroute mit Ihnen geteilt.",
                            "line_alt_2": "Eine Reiseroute wurde mit Ihnen geteilt.",
                            "subject": "Eine Reiseroute wurde mit Ihnen geteilt."
                        },
                        "line": "{username} von {poi_title} Institution hat einen Artikel mit Ihnen geteilt.",
                        "line_alt": "{username} hat einen Artikel mit Ihnen geteilt.",
                        "line_alt_2": "Ein Artikel wurde mit Ihnen geteilt.",
                        "point_of_interest": {
                            "line": "{username} von der {poi_title}-Institution hat einen interessanten Punkt mit Ihnen geteilt.",
                            "line_alt": "{username} hat einen interessanten Punkt mit Ihnen geteilt.",
                            "line_alt_2": "Ein interessanter Punkt wurde mit Ihnen geteilt.",
                            "subject": "Ein interessanter Punkt wurde mit Ihnen geteilt."
                        },
                        "subject": "Ein Artikel wurde mit Ihnen geteilt."
                    }
                },
                "subscription": {
                    "not_continue_after_free_period": {
                        "cta": "Siehe Detail",
                        "line1": "{name} wurde nach Ende des kostenlosen Zeitraums nicht fortgesetzt",
                        "line2": "Bitte klicken Sie auf die Schaltfläche unten, um auf die Details zuzugreifen",
                        "subject": "Der Abonnent hat nach Ablauf des kostenlosen Zeitraums nicht weitergemacht"
                    },
                    "suspended": {
                        "line": "Ihr Abonnement wurde aus folgendem Grund ausgesetzt: {suspension_reason}.",
                        "line_alt": "Ihr Abonnement wurde ausgesetzt.",
                        "subject": "Abonnement ausgesetzt"
                    },
                    "switched_from_paid_to_free": {
                        "cta": "Siehe Detail",
                        "line1": "{name} ist auf den kostenlosen Tarif umgestiegen",
                        "line2": "Bitte klicken Sie auf die Schaltfläche unten, um auf die Details zuzugreifen",
                        "subject": "Der Abonnent hat zum kostenlosen Tarif gewechselt"
                    }
                },
                "support_request": {
                    "archived": {
                        "subject": "UgoZER – Supportanfrage archiviert",
                        "title": "Ihre Supportanfrage wurde archiviert"
                    },
                    "message": {
                        "action": "Zugang zur Supportanfrage",
                        "line1": "Bitte klicken Sie auf die Schaltfläche unten, um auf Ihre Anfrage zuzugreifen.",
                        "subject": "UgoZER – Sie haben eine Antwort",
                        "title": "Auf Ihre Supportanfrage wurde eine Antwort gepostet"
                    },
                    "request": {
                        "action": "Zugang zur Supportanfrage",
                        "line1": "Der Kontaktsupport wird innerhalb von 48 Stunden antworten.",
                        "line2": "Bitte klicken Sie auf die Schaltfläche unten, um auf Ihre Anfrage zuzugreifen.",
                        "subject": "UgoZER – Ihre Supportanfrage wurde erstellt"
                    }
                },
                "user_send_link": {
                    "content": "Verbinden Sie sich erneut mit unserer Anwendung UgoZER! Wenn Sie Ihr Passwort vergessen haben, können Sie es zurücksetzen.",
                    "cta": "Melden Sie sich bei der Anwendung an",
                    "subject": "UgoZER - Anwendung",
                    "thanks": "Danke,",
                    "title": "Sie haben ein Konto in unserer Anwendung, melden Sie sich an!"
                },
                "welcome": {
                    "action": "Zugriff auf die Anwendung",
                    "line1": "Wir freuen uns, Sie zu unseren Nutzern zählen zu dürfen.",
                    "line2": "Klicken Sie auf die Schaltfläche unten, um auf die Anwendung zuzugreifen.",
                    "subject": "Willkommen bei UgoZER",
                    "title": "Willkommen bei UgoZER, Ihrer kostenlosen mehrsprachigen Reiseassistenz-App."
                }
            },
            "media": {
                "audio": {
                    "generated_audio": "Generiertes Audio",
                    "recording": {
                        "errors": {
                            "default": "Die Aufnahme ist fehlgeschlagen",
                            "not_allowed": "Die Aufnahme ist fehlgeschlagen. Erlauben Sie Ihrem Browser und UgoZER, Ihr Mikrofon zu verwenden, und versuchen Sie es erneut."
                        }
                    }
                },
                "default": {
                    "alt": "Statische Karte von Google Maps",
                    "caption": "Klicken Sie auf die Karte, um diese auf Google Maps anzuzeigen"
                },
                "image": {
                    "credits": "Credits: {credits}"
                }
            },
            "models": {
                "activity_log": "Aktivitätsprotokoll|Aktivitätsprotokolle",
                "address": "Adresse|Adressen",
                "bank_transfer": "Banküberweisung|Banküberweisungen",
                "bank_transfer_institution": "Banküberweisungsinstitut|Banküberweisungsinstitute",
                "category": "Kategorie|Kategorien",
                "changelog": "Änderungsprotokoll|Änderungsprotokolle",
                "claim": "Anspruch|Ansprüche",
                "claim_dispute": "Anspruchsstreit|Anspruchsstreitigkeiten",
                "currency": "Währung|Währungen",
                "data_tourisme_category": "DataTourisme-Kategorie|DataTourisme-Kategorien",
                "data_tourisme_tag": "DataTourisme-Tag|DataTourisme-Tags",
                "excursion": "Reiseroute|Reiserouten",
                "institution_employee": "Mitarbeiter|Mitarbeiter",
                "interaction": "Interaktion|Interaktionen",
                "locale": "Sprache|Sprachen",
                "medium": "Medium|Medien",
                "notification": "Benachrichtigung|Benachrichtigungen",
                "partner": "Partner|Partner",
                "payment": "Zahlung|Zahlungen",
                "permission": "Erlaubnis|Berechtigungen",
                "plan": "Abonnementplan|Abonnementpläne",
                "plan_price": "Planpreis|Planpreise",
                "point_of_interest": "Sehenswürdigkeit|Sehenswürdigkeiten",
                "point_of_interest_category": "Kategorie|Kategorien",
                "post": "Beitrag|Beiträge",
                "post_category": "Beitragskategorie|Beitragskategorien",
                "privacy_policy": "Datenschutzrichtlinie|Datenschutzrichtlinien",
                "report": "Bericht|Berichte",
                "reservation": "Reservierung|Reservierungen",
                "role": "Rolle|Rollen",
                "share": "Teilen|Aktien",
                "subscription": "Abonnement|Abonnements",
                "support_category": "Support-Kategorie|Support-Kategorien",
                "support_message": "Nachricht|Nachrichten",
                "support_request": "Anfrage|Anfragen",
                "tag": "Tag|Tags",
                "terms_condition": "Allgemeine Geschäftsbedingungen|Allgemeine Geschäftsbedingungen",
                "traveller_email": "E-Mail von Reisenden|E-Mails von Reisenden",
                "traveller_folder": "Reiseordner|Reiseordner",
                "user": "Benutzer|Benutzer",
                "user_credential_verification": "Berechtigungsüberprüfung|Berechtigungsüberprüfungen",
                "user_geoloc_setting": "Geoloc-Einstellung|Geoloc-Einstellungen",
                "user_notification_setting": "Benachrichtigungseinstellungen|Benachrichtigungseinstellungen",
                "user_password_reset": "Benutzerpasswort zurücksetzen|Benutzerpasswörter werden zurückgesetzt",
                "user_privacy_setting": "Datenschutzeinstellungen|Privatsphäreeinstellungen",
                "users_relationship": "Kontakt|Kontakte",
                "vat_rate": "Mehrwertsteuersatz|Mehrwertsteuersätze"
            },
            "navigations": {
                "items": {
                    "changelogs": "Änderungsprotokolle",
                    "contact_us": "Kontaktiere uns",
                    "contacts": "Kontakte",
                    "cookies": "Cookie-Verwaltung",
                    "credits": "Credits",
                    "customers": "Sie vertrauen uns",
                    "faqs": "FAQ",
                    "folders": "Reiseordner",
                    "invite_professional": "Laden Sie einen Fachmann ein",
                    "invite_traveller": "Laden Sie einen Reisenden ein",
                    "partners": "Partner",
                    "payments": "Zahlungen",
                    "privacy_policy": "Datenschutzrichtlinie",
                    "settings": "Parameter",
                    "stays": "Bleibt",
                    "terms_conditions": "Geschäftsbedingungen",
                    "users": "Benutzer"
                }
            },
            "notifications": {
                "changelog": {
                    "created": {
                        "text": "Die Version {version} wurde bereitgestellt. Sie können die Neuigkeiten überprüfen, indem Sie auf die Benachrichtigung klicken.",
                        "title": "Neues Änderungsprotokoll"
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "text": "Der Anspruchsstreit bezüglich des Point of Interest {poi} muss noch von unserem Team überprüft werden.",
                        "title": "Anspruchsstreit bis zur Überprüfung"
                    },
                    "rejected": {
                        "no_reason": "Ohne Grund",
                        "text": "Der Anspruchsstreit zum {poi}-Punkt von Interesse wurde aus folgendem Grund abgelehnt: {reason}.",
                        "title": "Anspruchsstreit abgelehnt"
                    },
                    "validated": {
                        "text": "Der Anspruchsstreit bezüglich des {poi} Point of Interest wurde angenommen!",
                        "title": "Anspruchsstreit bestätigt!"
                    }
                },
                "index": {
                    "no_results": "Keine Benachrichtigungen!"
                },
                "institution_employee": {
                    "approved": {
                        "text": "Ihre Anfrage, {poi} beizutreten, wurde genehmigt",
                        "title": "Mitarbeiterantrag genehmigt"
                    },
                    "declined": {
                        "text": "Ihre Anfrage, {poi} beizutreten, wurde abgelehnt",
                        "title": "Mitarbeiteranfrage abgelehnt"
                    },
                    "pending": {
                        "text": "{user} beantragt die Registrierung als Mitarbeiter Ihrer Einrichtung {poi}",
                        "title": "Mitarbeiteranfrage eingegangen"
                    },
                    "removed": {
                        "text": "Ihr Mitarbeiterstatus von {poi} wurde entfernt",
                        "title": "Mitarbeiterstatus entfernt"
                    }
                },
                "invitation": {
                    "accepted": {
                        "email": {
                            "text": "Ihre Einladung zur Teilnahme an der an {credential} gesendeten Bewerbung wurde angenommen"
                        },
                        "phone": {
                            "text": "Ihre Einladung zur Teilnahme an der an {credential} gesendeten Bewerbung wurde angenommen"
                        },
                        "title": "Einladung angenommen"
                    },
                    "pro": {
                        "text": "Ihre berufliche Einladung wurde an E-Mail {email} gesendet",
                        "title": "Professionelle Einladung verschickt"
                    }
                },
                "office": {
                    "claim": {
                        "content": {
                            "line_1": "Ein Punkt von Interesse wurde gerade beansprucht.",
                            "line_2": "Klicken Sie auf die Schaltfläche unten, um die Details in der Admin-Oberfläche anzuzeigen."
                        },
                        "cta": "Siehe Anspruch",
                        "subject": "Neuer Anspruch!"
                    },
                    "claim_dispute": {
                        "content": {
                            "line_1": "Ein Punkt von Interesse wurde gerade angefochten.",
                            "line_2": "Klicken Sie auf die Schaltfläche unten, um die Details in der Admin-Oberfläche anzuzeigen."
                        },
                        "cta": "Siehe Streit",
                        "subject": "Neuer Streit!"
                    },
                    "report": {
                        "content": {
                            "line_1": "{type} wurde gerade gemeldet."
                        },
                        "cta": "Siehe Bericht",
                        "subject": "Neuer Bericht!",
                        "type": {
                            "App\\Models\\PointOfInterest": "Ein Punkt von Interesse",
                            "App\\Models\\User": "Ein Benutzer"
                        }
                    },
                    "support_request": {
                        "content": {
                            "line_1": "Eine neue Supportanfrage wurde gerade hinzugefügt."
                        },
                        "cta": "Siehe Supportanfrage",
                        "subject": "Neue Supportanfrage!"
                    }
                },
                "payment": {
                    "proposal": {
                        "text": "Bedanken Sie sich bei {username} von {poi_title} Institution, indem Sie ein Trinkgeld hinterlassen",
                        "text_alt": "Bedanken Sie sich beim Concierge der {poi_title} Institution, indem Sie ein Trinkgeld hinterlassen",
                        "text_alt_2": "Bedanken Sie sich bei {username}, indem Sie ein Trinkgeld hinterlassen",
                        "text_alt_3": "Bedanken Sie sich beim Concierge, indem Sie ein Trinkgeld hinterlassen",
                        "title": "Trinkgeld geben"
                    },
                    "received": {
                        "text": "Sie haben von der {payer}-Institution ein {amount}-Trinkgeld von der {poi_title}-Institution erhalten",
                        "text_alt": "Du hast einen {amount}-Tipp von {payer} erhalten",
                        "text_alt_2": "Du hast ein {amount} Trinkgeld erhalten",
                        "title": "Trinkgeld erhalten"
                    },
                    "thanks": {
                        "text": "{payee} von {poi_title} Institution danke für den Tipp!",
                        "text_alt": "{payee} Danke für den Tipp!",
                        "text_alt_2": "Für den Tipp wurde Ihnen gedankt",
                        "title": "Danke für den Tipp!"
                    }
                },
                "point_of_interest": {
                    "event": {
                        "expired": {
                            "text": "Die in Ihren Reiseplänen enthaltene Veranstaltung {title} ist abgelaufen",
                            "title": "Veranstaltung abgelaufen"
                        }
                    },
                    "moderation": {
                        "denied": {
                            "text": "Ihr Point of Interest {title} wurde aufgrund seiner Moderation abgelehnt",
                            "title": "Interessenpunkt abgelehnt"
                        },
                        "published": {
                            "text": "Ihr Point of Interest {title} wurde nach seiner Moderation veröffentlicht",
                            "title": "Sehenswürdigkeit veröffentlicht"
                        }
                    }
                },
                "reservation": {
                    "created": {
                        "text": "{butler_name} der Institution {butler_poi_title} hat eine Reservierung vorgenommen\n                in Ihrer Einrichtung {poi_title}",
                        "text_alt": "Ein Concierge der Institution {butler_poi_title} hat eine Reservierung vorgenommen\n                in Ihrer Einrichtung {poi_title}",
                        "text_alt_2": "{butler_name} hat eine Reservierung vorgenommen\n                in Ihrer Einrichtung {poi_title}",
                        "text_alt_3": "Eine Reservierung wurde vorgenommen\n                in Ihrer Einrichtung {poi_title}",
                        "title": "Reservierung in Ihrer Institution"
                    }
                },
                "share": {
                    "created": {
                        "excursion": {
                            "text": "{username} von {poi_title} Institution hat eine Reiseroute mit Ihnen geteilt",
                            "text_alt": "{username} hat eine Reiseroute mit Ihnen geteilt",
                            "text_alt_2": "Eine Reiseroute wurde mit Ihnen geteilt",
                            "title": "Teilen Sie eine Reiseroute"
                        },
                        "point_of_interest": {
                            "text": "{username} von der {poi_title}-Institution hat einen interessanten Punkt mit Ihnen geteilt",
                            "text_alt": "{username} hat einen interessanten Punkt mit Ihnen geteilt",
                            "text_alt_2": "Ein interessanter Punkt wurde mit Ihnen geteilt",
                            "title": "Teilen Sie einen Punkt von Interesse"
                        },
                        "text": "{username} von {poi_title} Institution hat einen Artikel mit Ihnen geteilt",
                        "text_alt": "{username} hat einen Artikel mit Ihnen geteilt",
                        "text_alt_2": "Ein Artikel wurde mit Ihnen geteilt"
                    }
                },
                "stripe": {
                    "account_requirements": {
                        "text": "Nach der Verifizierung Ihres Stripe-Kontos werden neue Informationen abgefragt",
                        "title": "Anforderungen an das Stripe-Konto"
                    },
                    "payments_enabled": {
                        "text": "Sie können jetzt Zahlungen über Stripe empfangen",
                        "title": "Stripe-Zahlungen aktiviert"
                    }
                },
                "support_request": {
                    "archived": {
                        "text": "Ihre Supportanfrage wurde archiviert",
                        "title": "Supportanfrage archiviert"
                    },
                    "message": {
                        "text": "Auf Ihre Supportanfrage wurde eine Antwort gepostet",
                        "title": "Nachricht erhalten"
                    }
                },
                "traveller_folder": {
                    "approved": {
                        "text": "{username} hat seinen Reiseordner genehmigt",
                        "text_alt": "Ein Reisender hat seinen Ordner genehmigt",
                        "title": "Reiseordner genehmigt"
                    },
                    "archived": {
                        "text": "{username} hat seinen Traveler-Ordner archiviert",
                        "text_alt": "Ein Reisender hat seinen Ordner archiviert",
                        "title": "Traveler-Ordner archiviert"
                    },
                    "created": {
                        "text": "Für Ihr Konto wurde ein Aufenthalt im Hotel {poi_title} registriert.",
                        "title": "Bleiben Sie erschaffen"
                    },
                    "pending": {
                        "text": "{username} hat für Ihr Konto einen Aufenthalt im Hotel {poi_title} registriert.\n                Bitte bestätige es.",
                        "text_alt": "Für Ihr Konto wurde ein Aufenthalt im Hotel {poi_title} registriert.\n                Bitte bestätige es.",
                        "title": "Bleiben Sie bis zur Bestätigung"
                    },
                    "thanks": {
                        "text": "{username} von {poi_title} Institution Vielen Dank für Ihren Aufenthalt",
                        "text_alt": "Ein Concierge der {poi_title} Institution dankt Ihnen für Ihren Aufenthalt",
                        "title": "Vielen Dank für Ihren Aufenthalt"
                    }
                }
            },
            "nova": {
                "actions": {
                    "bank_transfer": {
                        "transfer": "Machen Sie eine Banküberweisung"
                    },
                    "changelog": {
                        "archive": "Archiv",
                        "draft": "Entwurf",
                        "publish": "Veröffentlichen"
                    },
                    "claim": {
                        "missing_info": "Fehlende Informationen",
                        "reject": "Ablehnen",
                        "revoke": "Widerrufen",
                        "validate": "Bestätigen"
                    },
                    "claim_dispute": {
                        "reject": "Ablehnen",
                        "validate": "Bestätigen"
                    },
                    "payment": {
                        "download_invoice": "Download Rechnung"
                    },
                    "plan": {
                        "archive": "Archiv",
                        "draft": "Entwurf",
                        "publish": "Veröffentlichen"
                    },
                    "point_of_interest": {
                        "deny": "Leugnen",
                        "draft": "Entwurf",
                        "publish": "Bestätigen",
                        "submit": "Bitten Sie um Moderation"
                    },
                    "post": {
                        "archive": "Archiv",
                        "draft": "Entwurf",
                        "publish": "Veröffentlichen",
                        "set_coming_soon": "Auf „bald verfügbar“ eingestellt"
                    },
                    "privacy_policy": {
                        "archive": "Archiv",
                        "confirmation": {
                            "confirm_button_text": "Veröffentlichen",
                            "confirm_text": "Sind Sie sicher, dass Sie diese Datenschutzrichtlinie veröffentlichen möchten? ALLE Benutzer werden gebeten, sie erneut zu validieren!"
                        },
                        "errors": {
                            "resources_count": "Sie können nicht mehr als EINE private Police veröffentlichen!"
                        },
                        "publish": "Veröffentlichen"
                    },
                    "report": {
                        "process": "Verfahren",
                        "reject": "Ablehnen"
                    },
                    "support_request": {
                        "answer": "Antwort",
                        "archive": "Archiv",
                        "cancel": "Stornieren",
                        "pending_support": "Warten auf Unterstützung",
                        "pending_user": "Warten auf Benutzer",
                        "resolve": "Lösen"
                    },
                    "terms_condition": {
                        "archive": "Archiv",
                        "confirmation": {
                            "confirm_button_text": "Veröffentlichen",
                            "confirm_text": "Sind Sie sicher, dass Sie diese Allgemeinen Geschäftsbedingungen veröffentlichen möchten? ALLE Benutzer werden gebeten, sie erneut zu validieren!"
                        },
                        "errors": {
                            "resources_count": "Sie können nicht mehr als EINE AGB veröffentlichen!"
                        },
                        "publish": "Veröffentlichen"
                    },
                    "user": {
                        "finish": "Beende das Tutorial"
                    },
                    "user_credential_verification": {
                        "validate": "Bestätigen"
                    }
                },
                "cards": {
                    "new_excursions": "Neue Reiserouten",
                    "new_points_of_interest": "Neue Sehenswürdigkeiten",
                    "new_users": "Neue Nutzer"
                },
                "filters": {
                    "app": "Anwendung",
                    "archived_at_gte": "Archiviert nach",
                    "archived_at_lte": "Vorher archiviert",
                    "canceled_at_gte": "Abgesagt nach",
                    "canceled_at_lte": "Vorher abgesagt",
                    "created_at_gte": "Erstellt nach",
                    "created_at_lte": "Vorher erstellt",
                    "dynamic_translations_enabled": "Dynamische Übersetzungen aktiviert",
                    "extranet": "Extranet",
                    "front": "Vorderseite",
                    "group": "Gruppe",
                    "published_at_gte": "Veröffentlicht nach",
                    "published_at_lte": "Vorher veröffentlicht",
                    "reservation_date_gte": "Reservierung nach",
                    "reservation_date_lte": "Reservierung vorher",
                    "resolved_at_gte": "Danach gelöst",
                    "resolved_at_lte": "Vorher gelöst",
                    "static_translations_enabled": "Statische Übersetzungen aktiviert",
                    "to_validate": "Bestätigen"
                },
                "labels": {
                    "institutions": "Institutionen",
                    "point_of_interest_categories": "Kategorien"
                }
            },
            "opening_hours": {
                "additional_info": "Weitere Informationen",
                "content": {
                    "date": "Das {date}",
                    "date_range_from": "Ab {from}",
                    "date_range_from_to": "Von {from} bis {to}",
                    "date_range_to": "Zu {to}",
                    "hours_range_from": "Ab {from}",
                    "hours_range_from_to": "Von {from} bis {to}",
                    "hours_range_to": "Zu {to}"
                }
            },
            "partners": {
                "customers": {
                    "title": "Unsere Kunden"
                },
                "index": {
                    "no_results": "Keine Partner gefunden",
                    "our_last_partners": "Unsere letzten Partner",
                    "title": "Unsere Partner"
                },
                "show": {
                    "link": "Besuche die Website"
                }
            },
            "payments": {
                "checkout": {
                    "cancel": "Die Zahlung wurde storniert",
                    "custom_amount": "Geben Sie einen benutzerdefinierten Betrag ein",
                    "success": "Zahlung erfolgreich durchgeführt",
                    "tip_amount": "Trinkgeldbetrag"
                },
                "index": {
                    "no_results": "Keine Zahlungen gefunden"
                },
                "labels": {
                    "payee": "Zahlungsempfänger:",
                    "payment_service": "Bezahlt von"
                },
                "payable": {
                    "reservation": "Reservierungsnummer{id}"
                },
                "thanks": {
                    "success": "Danke gesendet!"
                }
            },
            "pdf": {
                "common": {
                    "generating": "PDF-Datei wird erstellt...",
                    "waiting": "Bitte warten..."
                },
                "payments": {
                    "period": "Zeitraum von {from} bis {to}",
                    "title": "Erhaltene Zahlungen"
                },
                "receipt": {
                    "debited_amount": "Abgebuchter Betrag",
                    "paid_amount": "Bezahlte Menge",
                    "payment_date": "Zahlungsdatum",
                    "payment_method": "Bezahlverfahren",
                    "receipt_for": "Quittung für die Zahlung an",
                    "receipt_number": "Zahlungsbeleg #{id}",
                    "summary": "Zusammenfassung"
                },
                "share": {
                    "cta": "Konsultieren Sie die Reiseroute",
                    "details": {
                        "line1": "Gerne teilen wir Ihnen Ihren individuellen Reiseplan mit.",
                        "line2": "Nachfolgend finden Sie den Inhalt Ihrer Reiseroute.",
                        "line3": "Um auf die detaillierten Informationen zuzugreifen (Audio mit Adresse in Landessprache, interaktive Karte,\n                Bilder, vollständige Beschreibung...), klicken Sie auf die Schaltfläche unten."
                    }
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "ADMIN": "Greifen Sie auf die Verwaltung zu",
                        "APP": "Greifen Sie auf die App zu",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Zugriffsberechtigungen",
                        "EXTRANET": "Greifen Sie auf den professionellen Bereich zu",
                        "HORIZON": "Zugangshorizont",
                        "TELESCOPE": "Zugriff auf das Teleskop",
                        "VIEW_BUTLER_DASHBOARD": "Butler-Dashboard anzeigen",
                        "VIEW_HOTELIER_DASHBOARD": "Sehen Sie sich das Hotelier-Dashboard an",
                        "VIEW_MANAGER_DASHBOARD": "Manager-Dashboard anzeigen",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "Sehen Sie sich das Dashboard des Tourismusbüros an",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "Sehen Sie sich das Mitarbeiter-Dashboard des Tourismusbüros an",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "Melden Sie sich an, ohne Ihre Zugangsdaten überprüfen zu müssen"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Aktivitätsprotokollen",
                        "CREATE": "Erstellen Sie ein Aktivitätsprotokoll",
                        "DELETE": "Löschen Sie ein Aktivitätsprotokoll",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Aktivitätsprotokolls",
                        "RESTORE": "Stellen Sie ein Aktivitätsprotokoll wieder her",
                        "UPDATE": "Aktualisieren Sie ein Aktivitätsprotokoll",
                        "VIEW": "Konsultieren Sie ein Aktivitätsprotokoll",
                        "VIEW_ANY": "Konsultieren Sie den Index der Aktivitätsprotokolle"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Adressen",
                        "CREATE": "Erstellen Sie eine Adresse",
                        "DELETE": "Eine Adresse löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Adresse",
                        "RESTORE": "Eine Adresse wiederherstellen",
                        "UPDATE": "Aktualisieren Sie eine Adresse",
                        "VIEW": "Konsultieren Sie eine Adresse",
                        "VIEW_ANY": "Adressverzeichnis konsultieren"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Banküberweisungsinstituten",
                        "VIEW_ANY": "Konsultieren Sie den Index der Banküberweisungsinstitute"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "ARCHIVE": "Archivieren Sie ein Änderungsprotokoll",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Änderungsprotokollen",
                        "CREATE": "Erstellen Sie ein Änderungsprotokoll",
                        "DELETE": "Löschen Sie ein Änderungsprotokoll",
                        "DRAFT": "Entwerfen Sie ein Änderungsprotokoll",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Änderungsprotokolls",
                        "PUBLISH": "Veröffentlichen Sie ein Änderungsprotokoll",
                        "RESTORE": "Stellen Sie ein Änderungsprotokoll wieder her",
                        "UPDATE": "Aktualisieren Sie ein Änderungsprotokoll",
                        "VIEW": "Konsultieren Sie ein Änderungsprotokoll",
                        "VIEW_ANY": "Konsultieren Sie den Index der Änderungsprotokolle"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Anspruchsstreitigkeiten",
                        "CREATE": "Erstellen Sie einen Anspruchsstreit",
                        "DELETE": "Löschen Sie einen Anspruchsstreit",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Anspruchsstreits",
                        "REJECT": "Lehnen Sie einen Anspruchsstreit ab",
                        "RESTORE": "Stellen Sie einen Anspruchsstreit wieder her",
                        "UPDATE": "Aktualisieren Sie einen Anspruchsstreit",
                        "VALIDATE": "Bestätigen Sie einen Anspruchsstreit",
                        "VIEW": "Wenden Sie sich an einen Anspruchsstreit",
                        "VIEW_ANY": "Konsultieren Sie das Verzeichnis der Anspruchsstreitigkeiten"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Ansprüchen",
                        "CREATE": "Erstellen Sie einen Anspruch",
                        "DELETE": "Anspruch löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Anspruchs",
                        "MISSING_INFO": "Lehnen Sie einen Anspruch als fehlende Informationen ab",
                        "REJECT": "Einen Anspruch ablehnen",
                        "RESTORE": "Einen Anspruch wiederherstellen",
                        "REVOKE": "Einen Anspruch widerrufen",
                        "SHOW_FILE": "Zeigen Sie eine Schadensakte an",
                        "UPDATE": "Aktualisieren Sie einen Anspruch",
                        "VALIDATE": "Bestätigen Sie einen Anspruch",
                        "VIEW": "Konsultieren Sie einen Anspruch",
                        "VIEW_ANY": "Siehe Schadensverzeichnis"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie länderbezogene Berechtigungen",
                        "USE_ANY": "Verwenden Sie ein beliebiges Land"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Währungen",
                        "CREATE": "Erstellen Sie eine Währung",
                        "DELETE": "Eine Währung löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Währung",
                        "RESTORE": "Eine Währung wiederherstellen",
                        "UPDATE": "Aktualisieren Sie eine Währung",
                        "VIEW": "Konsultieren Sie eine Währung",
                        "VIEW_ANY": "Konsultieren Sie den Währungsindex"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit EasyTransac-Konten",
                        "HAVE": "Besitzen Sie ein EasyTransac-Konto"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Reiseplänen",
                        "CREATE": "Erstellen Sie eine Reiseroute",
                        "DELETE": "Löschen Sie eine Reiseroute",
                        "FORCE_DELETE": "Löschen einer Reiseroute erzwingen",
                        "RESTORE": "Eine Reiseroute wiederherstellen",
                        "SHARE": "Teilen Sie eine Reiseroute",
                        "UPDATE": "Aktualisieren Sie eine Reiseroute",
                        "VIEW": "Konsultieren Sie eine Reiseroute",
                        "VIEW_ANY": "Konsultieren Sie den Reiseplan",
                        "VIEW_KIND_UGOZER_APP": "Sehen Sie sich Reiserouten mit der UgoZER-App an",
                        "VIEW_KIND_UGOZER_PRO": "Sehen Sie sich Reiserouten mit UgoZER Pro an"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Verknüpfungen zwischen Reiserouten und Points of Interest",
                        "CREATE": "Erstellen Sie eine Verknüpfung zwischen einer Reiseroute und einem Sehenswürdigkeit",
                        "DELETE": "Löschen Sie eine Verknüpfung zwischen einer Reiseroute und einem Sonderziel",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Verknüpfung zwischen einer Reiseroute und einem Point of Interest",
                        "RESTORE": "Stellen Sie eine Verknüpfung zwischen einer Reiseroute und einem Point of Interest wieder her",
                        "UPDATE": "Aktualisieren Sie eine Verknüpfung zwischen einer Reiseroute und einem Point of Interest",
                        "VIEW": "Konsultieren Sie einen Link zwischen einer Reiseroute und einem Sehenswürdigkeit",
                        "VIEW_ANY": "Konsultieren Sie den Index der Links zwischen Reiserouten und Sehenswürdigkeiten"
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "APPROVE": "Genehmigen Sie eine Mitarbeiteranfrage einer Einrichtung",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen für Mitarbeiter der Einrichtung",
                        "CANCEL": "Stornieren Sie eine Mitarbeiteranfrage einer Institution",
                        "CREATE": "Erstellen Sie einen Mitarbeiter der Institution",
                        "DECLINE": "Lehnen Sie die Anfrage eines Mitarbeiters einer Einrichtung ab",
                        "DELETE": "Löschen Sie einen Mitarbeiter einer Institution",
                        "FEATURE": "Stellen Sie einen Mitarbeiter einer Institution vor",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Institutionsmitarbeiters",
                        "REMOVE": "Entfernen Sie einen Mitarbeiter aus einer Institution",
                        "RESTORE": "Stellen Sie einen Mitarbeiter der Institution wieder her",
                        "UPDATE": "Aktualisieren Sie einen Mitarbeiter einer Institution",
                        "VIEW": "Wenden Sie sich an einen Mitarbeiter der Einrichtung",
                        "VIEW_ANY": "Konsultieren Sie das Verzeichnis der Mitarbeiter der Institutionen"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "ARCHIVE": "Archivieren Sie eine Interaktion",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Interaktionen",
                        "CREATE": "Erstellen Sie eine Interaktion",
                        "DELETE": "Eine Interaktion löschen",
                        "DRAFT": "Entwerfen Sie eine Interaktion",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Interaktion",
                        "PUBLISH": "Veröffentlichen Sie eine Interaktion",
                        "RESTORE": "Eine Interaktion wiederherstellen",
                        "UPDATE": "Aktualisieren Sie eine Interaktion",
                        "VIEW": "Konsultieren Sie eine Interaktion",
                        "VIEW_ANY": "Konsultieren Sie den Interaktionsindex"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Sprachen",
                        "CREATE": "Erstelle eine Sprache",
                        "DELETE": "Eine Sprache löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Sprache",
                        "RESTORE": "Eine Sprache wiederherstellen",
                        "UPDATE": "Aktualisieren Sie eine Sprache",
                        "VIEW": "Konsultieren Sie eine Sprache",
                        "VIEW_ANY": "Konsultieren Sie den Sprachenindex"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Medien",
                        "CREATE": "Erstellen Sie ein Medium",
                        "DELETE": "Löschen Sie ein Medium",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Mediums",
                        "RESTORE": "Ein Medium wiederherstellen",
                        "UPDATE": "Aktualisieren Sie ein Medium",
                        "VIEW": "Konsultieren Sie einen Medienvertreter",
                        "VIEW_ANY": "Konsultieren Sie den Medienindex"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "Erhalten Sie eine Benachrichtigung, wenn eine Einladung angenommen wird",
                        "PAYMENT_RECEIVED": "Erhalten Sie eine Benachrichtigung, wenn eine Zahlung eingegangen ist",
                        "PAYMENT_THANKS": "Erhalten Sie eine Benachrichtigung, wenn eine Zahlung bestätigt wird",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "Erhalten Sie eine Benachrichtigung, wenn ein Point-of-Interest-Ereignis abläuft",
                        "RESERVATION_CREATED": "Erhalten Sie eine Benachrichtigung, wenn eine Reservierung erstellt wird",
                        "SHARE_CREATED": "Erhalten Sie eine Benachrichtigung, wenn eine Freigabe erstellt wird",
                        "SUPPORT_REQUEST_ARCHIVED": "Erhalten Sie eine Benachrichtigung, wenn eine Supportanfrage archiviert wird",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "Erhalten Sie eine Benachrichtigung, wenn eine Nachricht von einem Support eingeht\n                Anfrage",
                        "TRAVELLER_FOLDER_APPROVED": "Erhalten Sie eine Benachrichtigung, wenn eine Reisedatei genehmigt wird",
                        "TRAVELLER_FOLDER_ARCHIVED": "Erhalten Sie eine Benachrichtigung, wenn eine Reisedatei archiviert wird",
                        "TRAVELLER_FOLDER_PENDING": "Erhalten Sie eine Benachrichtigung, während Sie auf die Validierung einer Datei warten",
                        "TRAVELLER_FOLDER_THANKS": "Erhalten Sie eine Benachrichtigung, um dem Concierge zu danken, der für einen Aktenreisenden zuständig ist"
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen für Partner",
                        "CREATE": "Erstellen Sie einen Partner",
                        "DELETE": "Einen Partner löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Partners",
                        "RESTORE": "Einen Partner wiederherstellen",
                        "UPDATE": "Aktualisieren Sie einen Partner",
                        "VIEW": "Konsultieren Sie einen Partner",
                        "VIEW_ANY": "Konsultieren Sie das Partnerverzeichnis"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Zahlungen",
                        "CREATE": "Erstellen Sie eine Zahlung",
                        "DELETE": "Eine Zahlung löschen",
                        "FORCE_DELETE": "Löschen einer Zahlung erzwingen",
                        "GET_RECEIPT": "Erhalten Sie eine Quittung für eine Zahlung",
                        "RECEIVE": "Erhalten Sie Zahlungen",
                        "RESTORE": "Eine Zahlung wiederherstellen",
                        "THANK": "Erhalten Sie ein Dankeschön für eine Zahlung",
                        "UPDATE": "Aktualisieren Sie eine Zahlung",
                        "VIEW": "Konsultieren Sie eine Zahlung",
                        "VIEW_ANY": "Konsultieren Sie den Zahlungsindex"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Berechtigungen",
                        "CREATE": "Erstellen Sie eine Berechtigung",
                        "DELETE": "Löschen Sie eine Berechtigung",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Berechtigung",
                        "RESTORE": "Stellen Sie eine Berechtigung wieder her",
                        "UPDATE": "Aktualisieren Sie eine Berechtigung",
                        "VIEW": "Konsultieren Sie eine Genehmigung",
                        "VIEW_ANY": "Konsultieren Sie den Berechtigungsindex"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "ARCHIVE": "Archivieren Sie ein Abonnement",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Abonnementplänen",
                        "CREATE": "Erstellen Sie ein Abonnement",
                        "DELETE": "Löschen Sie ein Abonnement",
                        "DRAFT": "Entwerfen Sie einen Abonnementplan",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Abonnementplans",
                        "PUBLISH": "Veröffentlichen Sie ein Abonnement",
                        "RESTORE": "Stellen Sie ein Abonnement wieder her",
                        "SUBSCRIBE": "Abonnieren Sie einen Plan",
                        "UPDATE": "Aktualisieren Sie ein Abonnement",
                        "VIEW": "Konsultieren Sie ein Abonnement",
                        "VIEW_ANY": "Konsultieren Sie den Index der Abonnementpläne"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Sonderzielkategorien",
                        "CREATE": "Erstellen Sie eine Sonderzielkategorie",
                        "DELETE": "Löschen Sie eine Sonderzielkategorie",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Point-of-Interest-Kategorie",
                        "RESTORE": "Stellen Sie eine Point-of-Interest-Kategorie wieder her",
                        "UPDATE": "Aktualisieren Sie eine Point-of-Interest-Kategorie",
                        "VIEW": "Konsultieren Sie eine Sonderzielkategorie",
                        "VIEW_ANY": "Konsultieren Sie den Index der Sonderzielkategorien"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "ADMIN_ANY": "Verwalten Sie alle interessanten Punkte",
                        "APPLY": "Bewerben Sie sich als Mitarbeiter",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Points of Interest",
                        "CLAIM": "Beanspruchen Sie einen Punkt von Interesse",
                        "CONTEST": "Bewerben Sie sich um einen Punkt von Interesse",
                        "CONTRIBUTE": "Tragen Sie zu einem Punkt von Interesse bei",
                        "CREATE": "Erstellen Sie einen Point of Interest",
                        "DELETE": "Einen Punkt von Interesse löschen",
                        "DENY": "Einen Punkt von Interesse ablehnen",
                        "DRAFT": "Entwerfen Sie einen Punkt von Interesse",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Sonderziels",
                        "IMPORT": "Points of Interest importieren",
                        "MODERATE": "Moderieren Sie einen Punkt von Interesse",
                        "PUBLISH": "Veröffentlichen Sie einen Point of Interest",
                        "REPORT": "Melden Sie einen Punkt von Interesse",
                        "RESTORE": "Stellen Sie einen Punkt von Interesse wieder her",
                        "SHARE": "Teilen Sie einen Punkt von Interesse",
                        "SUBMIT": "Senden Sie einen Punkt von Interesse",
                        "UPDATE": "Aktualisieren Sie einen Point of Interest",
                        "UPDATE_CERTIFIED": "Aktualisieren Sie das zertifizierte Attribut eines Points of Interest",
                        "VIEW": "Konsultieren Sie einen Punkt von Interesse",
                        "VIEW_ANY": "Konsultieren Sie den Index der Sehenswürdigkeiten"
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Beitragskategorien",
                        "CREATE": "Erstellen Sie eine Beitragskategorie",
                        "DELETE": "Löschen Sie eine Beitragskategorie",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Beitragskategorie",
                        "RESTORE": "Stellen Sie eine Beitragskategorie wieder her",
                        "UPDATE": "Aktualisieren Sie eine Beitragskategorie",
                        "VIEW": "Konsultieren Sie eine Beitragskategorie",
                        "VIEW_ANY": "Konsultieren Sie den Index der Beitragskategorien"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "ARCHIVE": "Archivieren Sie einen Beitrag",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Beiträgen",
                        "CREATE": "Erstellen Sie einen Beitrag",
                        "DELETE": "Einen Beitrag löschen",
                        "DRAFT": "Verfassen Sie einen Beitrag",
                        "FORCE_DELETE": "Erzwinge das Löschen eines Beitrags",
                        "PUBLISH": "Veröffentlichen Sie einen Beitrag",
                        "RESTORE": "Einen Beitrag wiederherstellen",
                        "UPDATE": "Aktualisieren Sie einen Beitrag",
                        "VIEW": "Konsultieren Sie einen Beitrag",
                        "VIEW_ANY": "Konsultieren Sie den Beitragsindex"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "ARCHIVE": "Archivieren Sie eine Datenschutzrichtlinie",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Datenschutzrichtlinien",
                        "CREATE": "Erstellen Sie eine Datenschutzrichtlinie",
                        "DELETE": "Löschen Sie eine Datenschutzrichtlinie",
                        "DRAFT": "Entwerfen Sie eine Datenschutzrichtlinie",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Datenschutzrichtlinie",
                        "PUBLISH": "Veröffentlichen Sie eine Datenschutzrichtlinie",
                        "RESTORE": "Stellen Sie eine Datenschutzrichtlinie wieder her",
                        "UPDATE": "Aktualisieren Sie eine Datenschutzrichtlinie",
                        "VIEW": "Konsultieren Sie eine Datenschutzrichtlinie",
                        "VIEW_ANY": "Konsultieren Sie die Datenschutzrichtlinien"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "Registrieren Sie sich im professionellen Bereich mit jeder Rolle, die über diese Berechtigung verfügt"
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "ARCHIVE": "Archivieren Sie einen Bericht",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Berichten",
                        "CREATE": "Erstellen Sie einen Bericht",
                        "DELETE": "Einen Bericht löschen",
                        "DRAFT": "Erstellen Sie einen Bericht",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Berichts",
                        "PROCESS_ANY": "Verarbeiten Sie jeden Bericht",
                        "PUBLISH": "Veröffentlichen Sie einen Bericht",
                        "REJECT_ANY": "Jede Meldung ablehnen",
                        "RESTORE": "Stellen Sie einen Bericht wieder her",
                        "UPDATE": "Aktualisieren Sie einen Bericht",
                        "VIEW": "Konsultieren Sie einen Bericht",
                        "VIEW_ANY": "Konsultieren Sie den Berichtsindex"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "ARCHIVE": "Archivieren Sie eine Reservierung",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Reservierungen",
                        "CANCEL": "Stornieren Sie eine Reservierung",
                        "CONFIRM": "Bestätigen Sie eine Reservierung",
                        "CREATE": "Erstellen Sie eine Reservierung",
                        "DELETE": "Eine Reservierung löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Reservierung",
                        "RESTORE": "Eine Reservierung wiederherstellen",
                        "UPDATE": "Aktualisieren Sie eine Reservierung",
                        "VIEW": "Konsultieren Sie eine Reservierung",
                        "VIEW_ANY": "Konsultieren Sie das Reservierungsverzeichnis"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie rollenbezogene Berechtigungen",
                        "CREATE": "Erstellen Sie eine Rolle",
                        "DELETE": "Eine Rolle löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Rolle",
                        "RESTORE": "Stellen Sie eine Rolle wieder her",
                        "UPDATE": "Aktualisieren Sie eine Rolle",
                        "VIEW": "Konsultieren Sie eine Rolle",
                        "VIEW_ANY": "Konsultieren Sie das Rollenverzeichnis"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Freigaben",
                        "CREATE": "Erstellen Sie eine Freigabe",
                        "DELETE": "Eine Freigabe löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Freigabe",
                        "RESTORE": "Eine Freigabe wiederherstellen",
                        "UPDATE": "Aktualisieren Sie eine Freigabe",
                        "VIEW": "Konsultieren Sie eine Aktie",
                        "VIEW_ANY": "Konsultieren Sie den Aktienindex"
                    },
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Stripe-Konten",
                        "HAVE": "Besitzen Sie ein Stripe-Konto"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Abonnements",
                        "CANCEL": "Kündigen Sie ein Abonnement",
                        "CREATE": "Erstellen Sie ein Abonnement",
                        "DELETE": "Ein Abonnement löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Abonnements",
                        "PAY": "Zahlen Sie ein Abonnement",
                        "RESTORE": "Stellen Sie ein Abonnement wieder her",
                        "UPDATE": "Aktualisieren Sie ein Abonnement",
                        "VIEW": "Konsultieren Sie ein Abonnement",
                        "VIEW_ANY": "Konsultieren Sie den Abonnementindex"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit der Anfrage nach Supportkategorien",
                        "CREATE": "Erstellen Sie eine Supportkategorie",
                        "DELETE": "Löschen Sie eine Supportkategorie",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Supportkategorie",
                        "RESTORE": "Stellen Sie eine Supportkategorie wieder her",
                        "UPDATE": "Aktualisieren Sie eine Supportkategorie",
                        "VIEW": "Konsultieren Sie eine Support-Kategorie",
                        "VIEW_ANY": "Konsultieren Sie den Index der Supportkategorien"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Nachrichten",
                        "CREATE": "Erstellen Sie eine Nachricht",
                        "DELETE": "Eine Nachricht löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Nachricht",
                        "RESTORE": "Eine Nachricht wiederherstellen",
                        "UPDATE": "Aktualisieren Sie eine Nachricht",
                        "VIEW": "Konsultieren Sie eine Nachricht",
                        "VIEW_ANY": "Konsultieren Sie den Nachrichtenindex"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "ADD_MESSAGE": "Nachricht hinzufügen",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Supportanfragen",
                        "CANCEL": "Stornieren Sie eine Supportanfrage",
                        "CREATE": "Erstellen Sie eine Supportanfrage",
                        "DELETE": "Löschen Sie eine Supportanfrage",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Supportanfrage",
                        "RESTORE": "Stellen Sie eine Supportanfrage wieder her",
                        "UPDATE": "Aktualisieren Sie eine Supportanfrage",
                        "VIEW": "Konsultieren Sie eine Supportanfrage",
                        "VIEW_ANY": "Konsultieren Sie den Index der Supportanfragen"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Tags",
                        "CREATE": "Erstellen Sie ein Tag",
                        "DELETE": "Löschen Sie ein Tag",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Tags",
                        "RESTORE": "Stellen Sie ein Tag wieder her",
                        "UPDATE": "Aktualisieren Sie ein Tag",
                        "VIEW": "Konsultieren Sie einen Tag",
                        "VIEW_ANY": "Konsultieren Sie den Tag-Index"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "ARCHIVE": "Archivieren Sie die Allgemeinen Geschäftsbedingungen",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit den Allgemeinen Geschäftsbedingungen",
                        "CREATE": "Erstellen Sie AGB",
                        "DELETE": "Löschen Sie die Allgemeinen Geschäftsbedingungen",
                        "DRAFT": "Entwerfen Sie die Allgemeinen Geschäftsbedingungen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen der Allgemeinen Geschäftsbedingungen",
                        "PUBLISH": "Veröffentlichen Sie die Allgemeinen Geschäftsbedingungen",
                        "RESTORE": "Allgemeine Geschäftsbedingungen wiederherstellen",
                        "UPDATE": "Aktualisieren Sie die Allgemeinen Geschäftsbedingungen",
                        "VIEW": "Konsultieren Sie die Allgemeinen Geschäftsbedingungen",
                        "VIEW_ANY": "Lesen Sie die Allgemeinen Geschäftsbedingungen"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit übersetzbaren Datensätzen",
                        "REQUIRE_ANY": "Ermöglicht die Übersetzung eines Pflichtfelds in eine Sprache, die nicht unbedingt Englisch ist"
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit E-Mails von Reisenden",
                        "CREATE": "Erstellen Sie eine E-Mail für Reisende",
                        "DELETE": "Löschen Sie die E-Mail eines Reisenden",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer E-Mail eines Reisenden",
                        "RESTORE": "Stellen Sie die E-Mail eines Reisenden wieder her",
                        "UPDATE": "Aktualisieren Sie die E-Mail eines Reisenden",
                        "VIEW": "Konsultieren Sie eine E-Mail eines Reisenden",
                        "VIEW_ANY": "Konsultieren Sie den Index der E-Mails von Reisenden"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "APPROVE": "Genehmigen Sie einen Reiseordner",
                        "ARCHIVE": "Archivieren Sie einen Reiseordner",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Reiseordnern",
                        "CREATE": "Erstellen Sie einen Reiseordner",
                        "DECLINE": "Einen Reiseordner ablehnen",
                        "DELETE": "Löschen Sie einen Reiseordner",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Traveler-Ordners",
                        "RESTORE": "Stellen Sie einen Traveler-Ordner wieder her",
                        "THANK": "Bedanken Sie sich bei einem Reisenden für den Aufenthalt",
                        "UPDATE": "Aktualisieren Sie einen Reiseordner",
                        "VIEW": "Konsultieren Sie einen Reiseordner",
                        "VIEW_ANY": "Konsultieren Sie den Index der Reisemappen"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit der Überprüfung von Benutzeranmeldeinformationen",
                        "CREATE": "Erstellen Sie eine Überprüfung der Benutzeranmeldeinformationen",
                        "DELETE": "Löschen Sie die Überprüfung der Benutzeranmeldeinformationen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Benutzeranmeldeinformationsüberprüfung",
                        "RESTORE": "Stellen Sie die Überprüfung der Benutzeranmeldeinformationen wieder her",
                        "UPDATE": "Aktualisieren Sie die Überprüfung der Benutzeranmeldeinformationen",
                        "VIEW": "Konsultieren Sie eine Überprüfung der Benutzeranmeldeinformationen",
                        "VIEW_ANY": "Konsultieren Sie den Index der Überprüfungen der Benutzeranmeldeinformationen"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "ADD_CONTACT": "Fügen Sie einen Benutzer als Kontakt hinzu",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie benutzerbezogene Berechtigungen",
                        "CREATE": "Erstellen Sie einen Benutzer",
                        "DELETE": "Einen Benutzer löschen",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen eines Benutzers",
                        "INVITE": "Laden Sie einen Benutzer ein",
                        "REPORT": "Melden Sie einen Benutzer",
                        "RESTORE": "Einen Benutzer wiederherstellen",
                        "TIP": "Geben Sie einem Benutzer ein Trinkgeld",
                        "UPDATE": "Aktualisieren Sie einen Benutzer",
                        "UPDATE_BANK_ACCOUNT": "Bankkonto aktualisieren",
                        "VIEW": "Konsultieren Sie einen Benutzer",
                        "VIEW_ANY": "Konsultieren Sie den Benutzerindex",
                        "VIEW_AVATAR": "Zeigen Sie den Avatar eines Benutzers an",
                        "VIEW_CONTACT_EMAIL": "Sehen Sie sich die Kontakt-E-Mail-Adresse eines Benutzers an",
                        "VIEW_CONTACT_PHONE": "Sehen Sie sich die Kontakttelefonnummer eines Benutzers an",
                        "VIEW_REAL_NAME": "Zeigen Sie den echten Namen eines Benutzers an"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit den Geoloc-Einstellungen des Benutzers",
                        "CREATE": "Erstellen Sie eine Benutzer-Geoloc-Einstellung",
                        "DELETE": "Löschen Sie eine Benutzer-Geoloc-Einstellung",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Benutzer-Geoloc-Einstellung",
                        "RESTORE": "Stellen Sie eine Benutzer-Geoloc-Einstellung wieder her",
                        "UPDATE": "Aktualisieren Sie eine Benutzer-Geoloc-Einstellung",
                        "VIEW": "Konsultieren Sie eine Benutzer-Geoloc-Einstellung",
                        "VIEW_ANY": "Konsultieren Sie den Index der Benutzer-Geoloc-Einstellungen"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Benutzerbenachrichtigungseinstellungen",
                        "CREATE": "Erstellen Sie eine Benutzerbenachrichtigungseinstellung",
                        "DELETE": "Löschen Sie eine Benutzerbenachrichtigungseinstellung",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Benutzerbenachrichtigungseinstellung",
                        "RESTORE": "Stellen Sie eine Benutzerbenachrichtigungseinstellung wieder her",
                        "UPDATE": "Aktualisieren Sie eine Benutzerbenachrichtigungseinstellung",
                        "VIEW": "Konsultieren Sie eine Benutzerbenachrichtigungseinstellung",
                        "VIEW_ANY": "Konsultieren Sie den Index der Benutzerbenachrichtigungseinstellungen"
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit den Datenschutzeinstellungen des Benutzers",
                        "CREATE": "Erstellen Sie eine Benutzer-Datenschutzeinstellung",
                        "DELETE": "Löschen Sie eine Benutzer-Datenschutzeinstellung",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Benutzer-Datenschutzeinstellung",
                        "RESTORE": "Stellen Sie eine Benutzer-Datenschutzeinstellung wieder her",
                        "UPDATE": "Aktualisieren Sie eine Benutzer-Datenschutzeinstellung",
                        "VIEW": "Konsultieren Sie die Datenschutzeinstellungen eines Benutzers",
                        "VIEW_ANY": "Konsultieren Sie den Index der Datenschutzeinstellungen der Benutzer"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "APPROVE_CONTACT": "Genehmigen Sie eine Benutzerbeziehung",
                        "BLOCK_CONTACT": "Blockieren Sie eine Benutzerbeziehung",
                        "BYPASS_PERMISSIONS": "Ignorieren Sie Berechtigungen im Zusammenhang mit Benutzerbeziehungen",
                        "CREATE": "Erstellen Sie eine Benutzerbeziehung",
                        "DECLINE_CONTACT": "Eine Benutzerbeziehung ablehnen",
                        "DELETE": "Löschen Sie eine Benutzerbeziehung",
                        "FORCE_DELETE": "Erzwingen Sie das Löschen einer Benutzerbeziehung",
                        "RESTORE": "Stellen Sie eine Benutzerbeziehung wieder her",
                        "UNBLOCK_CONTACT": "Entsperren Sie eine Benutzerbeziehung",
                        "UPDATE": "Aktualisieren Sie eine Benutzerbeziehung",
                        "VIEW": "Konsultieren Sie eine Benutzerbeziehung",
                        "VIEW_ANY": "Konsultieren Sie den Index der Benutzerbeziehungen"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\AccessEnum": "Zugang",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "Aktivitätsprotokolle",
                    "App\\Enums\\Permissions\\AddressEnum": "Adressen",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "Banküberweisungsinstitute",
                    "App\\Enums\\Permissions\\ChangelogEnum": "Änderungsprotokolle",
                    "App\\Enums\\Permissions\\ClaimEnum": "Ansprüche",
                    "App\\Enums\\Permissions\\CountryEnum": "Länder",
                    "App\\Enums\\Permissions\\CurrencyEnum": "Währungen",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "EasyTransac-Konto",
                    "App\\Enums\\Permissions\\ExcursionEnum": "Reiserouten",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "Verknüpfung zwischen Reiserouten und Sehenswürdigkeiten",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "Mitarbeiter der Institutionen",
                    "App\\Enums\\Permissions\\InteractionEnum": "Interaktionen",
                    "App\\Enums\\Permissions\\LocaleEnum": "Sprachen",
                    "App\\Enums\\Permissions\\MediumEnum": "Medien",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "Benachrichtigungen erhalten",
                    "App\\Enums\\Permissions\\PartnerEnum": "Partner",
                    "App\\Enums\\Permissions\\PaymentEnum": "Zahlungen",
                    "App\\Enums\\Permissions\\PermissionEnum": "Berechtigungen",
                    "App\\Enums\\Permissions\\PlanEnum": "Abonnementpläne",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "Kategorien von Sonderzielen",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "Sehenswürdigkeiten",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "Beitragskategorien",
                    "App\\Enums\\Permissions\\PostEnum": "Beiträge",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "Datenschutzrichtlinien",
                    "App\\Enums\\Permissions\\RegistrationEnum": "Anmeldung",
                    "App\\Enums\\Permissions\\ReportEnum": "Berichte",
                    "App\\Enums\\Permissions\\ReservationEnum": "Reservierungen",
                    "App\\Enums\\Permissions\\RoleEnum": "Rollen",
                    "App\\Enums\\Permissions\\ShareEnum": "Anteile",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "Stripe-Konto",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "Abonnements",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "Kategorien von Supportanfragen",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "Support-Nachrichten",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "Supportanfragen",
                    "App\\Enums\\Permissions\\TagEnum": "Stichworte",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "Geschäftsbedingungen",
                    "App\\Enums\\Permissions\\TranslatableEnum": "Übersetzbare Datensätze",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "E-Mails von Reisenden",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "Reiseordner",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "Überprüfung der Benutzeranmeldeinformationen",
                    "App\\Enums\\Permissions\\UserEnum": "Benutzer",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "Benutzer-Geoloc-Einstellungen",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "Einstellungen für Benutzerbenachrichtigungen",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "Datenschutzeinstellungen des Benutzers",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "Benutzerbeziehungen"
                }
            },
            "plans": {
                "archive": {
                    "success": "Abonnementpläne wurden archiviert (sofern berechtigt)"
                },
                "confirmation": {
                    "benefit_current_subscription": "Sie profitieren weiterhin von Ihrem aktuellen Abonnement\n            bis zum Monatsende bereits bezahlt.",
                    "engagement_period": "Dieses Angebot beinhaltet einen Verlobungszeitraum von\n            {engagement_period} nach der 1. Zahlung.",
                    "fee": "Dieses Angebot ist mit einer Gebühr von {fee_percentage} % verbunden und berechtigt Sie dazu\n            Erhalten Sie {tip_percentage} % Ihres Trinkgeldes.",
                    "free": "Dieses Abonnement ist kostenlos, vorbehaltlich der Einrichtung eines Trinkgeldkontos.",
                    "free_period": "Sie profitieren von einem {free_period} kostenlosen Zeitraum.",
                    "free_period_used": "Sie haben Ihren Freizeitraum bereits aufgebraucht und werden ihn nicht aufgebraucht haben\n            in der Lage, von dem in diesem Angebot vorgeschlagenen {free_period} zu profitieren.",
                    "name": "Sie sind dabei, das „{name}“-Angebot zu abonnieren.",
                    "name_launch": "Sie sind dabei, das „{name}“-Startangebot zu abonnieren.",
                    "no_fee": "Für dieses Angebot wird eine Gebühr von 0 % erhoben und Sie können Ihr Trinkgeld vollständig erhalten.",
                    "please_confirm": "Bitte bestätigen Sie Ihr Abonnement, indem Sie auf die Schaltfläche unten klicken.",
                    "price_summary": "Dieses Abonnement kostet Sie\n            {price} exkl. Steuern / {price_incl_taxes} inkl. Steuern pro Monat.",
                    "replace_current_subscription": "Dieses Abonnement ersetzt automatisch Ihr aktuelles Abonnement."
                },
                "draft": {
                    "success": "Abonnementpläne wurden erstellt (sofern berechtigt)"
                },
                "free_period_ended_in": "Kostenlose Zeiträume enden {in}",
                "publish": {
                    "success": "Abonnementpläne wurden veröffentlicht (sofern berechtigt)"
                },
                "set_coming_soon": {
                    "success": "Der/die Abonnementplan(e) wurde/wurden auf „bald verfügbar“ eingestellt (sofern berechtigt)"
                },
                "your_current_plan": "Ihr aktueller Plan"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "Durch Ankreuzen dieses Kästchens bestätige ich, dass ich ein autorisierter Vertreter der bin\n            Einrichtung behauptet. Für jede falsche Aussage oder jeden Identitätsbetrugs werde ich zivil- und strafrechtlich verfolgt\n            Strafen gemäß den geltenden Gesetzen.",
                    "success": "Ihr Anspruch wurde zur Überprüfung eingereicht."
                },
                "denied": {
                    "for_the_reason": "Aus folgendem Grund abgelehnt",
                    "moderation_again": "Sie können den Point of Interest bearbeiten, wodurch er erneut zur Moderation eingereicht wird, damit er veröffentlicht werden kann."
                },
                "deny": {
                    "success": "Punkt(e) von Interesse wurden abgelehnt (sofern berechtigt)"
                },
                "destroy": {
                    "confirm": "Möchten Sie diesen Point of Interest wirklich löschen: {point_of_interest_name}?",
                    "success": "Das Sonderziel wurde gelöscht"
                },
                "draft": {
                    "success": "Der/die Punkt(e) von Interesse wurden ausgearbeitet (sofern förderfähig)"
                },
                "duplicates": {
                    "check_explanation1": "Um Ihren Point of Interest zu erstellen,\n            Bitte füllen Sie die folgenden Hauptfelder aus.",
                    "check_explanation2": "Zur Vermeidung wird eine Prüfung durchgeführt\n            Eingabe eines bereits vorhandenen Punktes.",
                    "check_explanation3": "Nach der Überprüfung können Sie den Vorgang abschließen\n            Geben Sie die Informationen zu Ihrem Punkt ein und speichern Sie sie.",
                    "check_explanation_title": "Überprüfung",
                    "continue_creation": "Setzen Sie die Erstellung fort",
                    "found_explanation1": "Ein oder mehrere Sehenswürdigkeiten entsprechend\n            Ihre Eingabekriterien sind bereits vorhanden.",
                    "found_explanation2": "Wenn Sie der Meinung sind, dass es sich hierbei nicht um ein Duplikat handelt,\n            Anschließend können Sie mit der Eingabe Ihres neuen Sonderziels fortfahren.",
                    "match_percentage": "An {percentage} % anpassen",
                    "select_duplicate": "Klicken Sie auf einen der vorgeschlagenen Sehenswürdigkeiten, um die Details anzuzeigen",
                    "use_poi": "Nutzen Sie diesen Point of Interest",
                    "view_poi": "Sehen Sie sich diesen interessanten Punkt an"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "Fügen Sie diese Sehenswürdigkeit zu einer Reiseroute hinzu",
                    "already_in_excursions": "Diese Sehenswürdigkeit ist bereits Teil dieser Reiserouten",
                    "create_excursion": "Erstellen Sie eine Reiseroute",
                    "or_create_from": "Oder erstellen Sie eine neue Reiseroute von diesem Punkt aus",
                    "select_which": "Wählen Sie aus, zu welcher vorhandenen Reiseroute der Sonderzielpunkt „{point_of_interest_name}“ hinzugefügt werden soll",
                    "success": "Die Sehenswürdigkeit wurde zur Reiseroute hinzugefügt"
                },
                "form": {
                    "help": {
                        "stars": "Nur wenn die Kategorie mit Sternen gekennzeichnet werden kann (z. B. Hotel, Restaurant...)"
                    }
                },
                "import_source": {
                    "DataTourisme": "DataTourisme"
                },
                "index": {
                    "no_results": "Keine Sehenswürdigkeiten gefunden",
                    "no_results_here": "In Ihrem Suchgebiet sind keine Sonderziele verfügbar.",
                    "no_tags": "Kein Tag definiert",
                    "refresh_delayed": "Bitte warten Sie {time_remaining}, bevor Sie die Aktualisierung durchführen",
                    "search": "Suchen Sie nach einem Punkt von Interesse",
                    "try_more_filters": "Sie können Ihre aktiven Filter ändern und Ihre Suche erweitern."
                },
                "pages": {
                    "show": {
                        "automatic_update": "Automatisches Update",
                        "belongs_to_you": "Gehört dir",
                        "claim_dispute_pending": {
                            "details": "Details der Anfrage: {message}",
                            "notice": "Dauerhafter Anspruchsstreit",
                            "title": "Sie haben einen laufenden Anspruchsstreit zu diesem Punkt von Interesse."
                        },
                        "deletion": {
                            "causes": {
                                "event": "Ursache: abgelaufenes Ereignis"
                            },
                            "title": "Ausstehende Löschung"
                        },
                        "dt_required_datatourisme_fields": "Aktualisiert von {dt_created_by} von {import_source} auf {dt_last_update}",
                        "excursions_counter": "Wurde {counter} Mal zu Reiseplänen hinzugefügt",
                        "is_geocoding_1": "Der Punkt von Interesse wird geolokalisiert.",
                        "is_geocoding_2": "Bitte überprüfen Sie diese Seite später, um den Punkt von Interesse zu sehen\n                auf der Karte.",
                        "is_refreshing_audio_1": "Eine Audiodatei wird generiert.",
                        "is_refreshing_audio_2": "Bitte schauen Sie später auf dieser Seite nach, um sich dieses Audio anzuhören.",
                        "not_current_locale": {
                            "change_locale": "Sie können die angezeigte Sprache mit der obigen Auswahl ändern",
                            "incomplete_translation": "Einige Informationen zu dieser Sehenswürdigkeit (Titel, Beschreibung und/oder Adresse) sind in der ausgewählten Sprache nicht verfügbar.",
                            "missing_translations": "Fehlende Übersetzungen"
                        },
                        "opening_hours": {
                            "expired": "Öffnungszeiten abgelaufen",
                            "is_deleting": "Der Sonderziel wird in {days} Tagen gelöscht",
                            "show": "Siehe Öffnungszeiten"
                        },
                        "sections": {
                            "audios": "Audios",
                            "gallery": "Galerie",
                            "logo": "Logo",
                            "main_image": "Hauptbild",
                            "map": "Karte",
                            "media": "Medien",
                            "poi": "Sehenswürdigkeit",
                            "videos": "Videos"
                        },
                        "see_full_page": "Siehe ganze Seite"
                    }
                },
                "print": {
                    "element_not_found": "Das zu druckende Element „{id}“ wurde nicht gefunden!"
                },
                "publish": {
                    "success": "Der/die Punkt(e) von Interesse wurden validiert und veröffentlicht (sofern berechtigt)"
                },
                "report": {
                    "choose_reason": "Wählen Sie einen Grund für die Meldung dieses Sonderziels „{point_of_interest_title}“",
                    "heading": "Melden Sie einen Punkt von Interesse",
                    "success": "Die Sehenswürdigkeit wurde gemeldet!"
                },
                "search": {
                    "creator_id": "Nur meine Punkte anzeigen?",
                    "no_results": "Keine Sehenswürdigkeiten gefunden...",
                    "tags_mode": {
                        "all": "Alle",
                        "all_help": "Wählen Sie „Alle“, um Sonderziele zu finden\n                mit allen ausgewählten Tags.",
                        "one": "Mindestens ein",
                        "one_help": "Wählen Sie „mindestens einen“, um Sehenswürdigkeiten zu finden\n                mit mindestens einem der ausgewählten Tags."
                    }
                },
                "share": {
                    "heading": "Teilen Sie diesen interessanten Punkt",
                    "select_which_contacts": "Wählen Sie die Kontakte aus, mit denen Sie den Point of Interest „{point_of_interest_title}“ teilen möchten",
                    "select_which_users": "Wählen Sie die Benutzer aus, mit denen Sie das Sonderziel „{point_of_interest_title}“ teilen möchten, oder geben Sie eine E-Mail-Adresse ein, um einen neuen Reisenden einzuladen",
                    "select_which_users_alt": "Geben Sie die E-Mail-Adresse der Person ein, mit der Sie das teilen möchten\n            „{point_of_interest_title}“ Sonderziel.",
                    "success": "Der Punkt von Interesse wurde geteilt!"
                },
                "states": {
                    "draft": "Entwurf",
                    "pending": "Ausstehend",
                    "published": "Veröffentlicht"
                },
                "store": {
                    "heading": "Erstellen Sie einen Point of Interest",
                    "success": "Der Sonderzielpunkt wurde erstellt"
                },
                "submit": {
                    "success": "Der/die interessante(n) Punkt(e) steht/sind noch zur Moderation bereit (sofern berechtigt)"
                },
                "update": {
                    "alert": "Durch die Aktualisierung dieses Punktes wird die automatische Aktualisierung durch den {import_source}-Import deaktiviert",
                    "confirm": "Möchten Sie diesen Point of Interest wirklich aktualisieren: {point_of_interest_name}?",
                    "heading": "Bearbeiten Sie diesen Point of Interest",
                    "success": "Die Sehenswürdigkeit wurde aktualisiert"
                }
            },
            "posts": {
                "archive": {
                    "success": "Beiträge wurden archiviert (sofern berechtigt)"
                },
                "draft": {
                    "success": "Der/die Beitrag(e) wurde/wurden verfasst (falls zulässig)"
                },
                "publish": {
                    "success": "Beiträge wurden veröffentlicht (sofern berechtigt)"
                },
                "search": {
                    "type_to_search": "In der ausgewählten Kategorie suchen..."
                }
            },
            "prices": {
                "excl_taxes": "exkl. Steuern",
                "free": "Frei",
                "from": "Aus",
                "incl_taxes": "inkl. Steuern",
                "per_month": "Pro Monat",
                "price_excl_taxes": "Preis exkl. MwSt. Steuern",
                "price_incl_taxes": "Preis inkl. Steuern",
                "vat_amount": "Mehrwertsteuerbetrag",
                "vat_percentage": "Mehrwertsteuer-Prozentsatz"
            },
            "privacy_policies": {
                "archive": {
                    "success": "Datenschutzrichtlinie(n) wurde(n) archiviert (sofern berechtigt)"
                },
                "errors": {
                    "not_found": "Keine Datenschutzerklärung gefunden!"
                },
                "publish": {
                    "success": "Datenschutzerklärung wurde veröffentlicht"
                },
                "update": {
                    "acceptation": "Sie sollten die Datenschutzrichtlinie akzeptieren, um fortzufahren",
                    "new_version": "Eine neue Version der Datenschutzerklärung wurde veröffentlicht",
                    "success": "Datenschutzerklärung wurde akzeptiert!"
                }
            },
            "reports": {
                "process": {
                    "success": "Die Meldung(en) wurde(n) bearbeitet (sofern berechtigt)"
                },
                "reject": {
                    "success": "Bericht(e) wurde(n) abgelehnt (sofern zulässig)"
                }
            },
            "reservations": {
                "archive": {
                    "confirm": "Möchten Sie diese Reservierung wirklich archivieren?",
                    "success": "Die Reservierung wurde archiviert"
                },
                "cancel": {
                    "confirm": "Möchten Sie diese Reservierung wirklich stornieren?",
                    "success": "Die Reservierung wurde storniert"
                },
                "confirm": {
                    "confirm": "Möchten Sie diese Reservierung wirklich bestätigen?",
                    "success": "Die Reservierung wurde bestätigt"
                },
                "destroy": {
                    "confirm": "Möchten Sie diese Reservierung wirklich löschen?",
                    "success": "Die Reservierung wurde gelöscht"
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "butler": "Reisender",
                                "poi": "Reisender",
                                "traveller": "Institution"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "butler": "Portier",
                            "poi": "Sehenswürdigkeit",
                            "reservation": "Reservierung",
                            "traveller": "Reisender"
                        }
                    }
                },
                "states": {
                    "archived": "Archiviert",
                    "canceled": "Abgesagt",
                    "confirmed": "Bestätigt",
                    "pending": "Ausstehend"
                },
                "store": {
                    "success": "Die Reservierung wurde erstellt"
                },
                "update": {
                    "success": "Die Reservierung wurde aktualisiert"
                }
            },
            "settings": {
                "items": {
                    "about": "Um",
                    "geoloc_settings": "Geolocation-Einstellungen",
                    "notification_settings": "Benachrichtigungseinstellungen",
                    "privacy_setting": "Datenschutzeinstellungen",
                    "profile": "Profil",
                    "signOut": "Abmelden"
                }
            },
            "shares": {
                "emails": {
                    "select_hint": "Sie können eine zuvor verwendete E-Mail auswählen, indem Sie unten klicken:",
                    "space_hint": "Durch ein Leerzeichen trennen, um mehrere E-Mails zu teilen"
                },
                "index": {
                    "no_results": "Keine Aktien gefunden!"
                },
                "link": {
                    "instructions": "Kopieren Sie diesen Link, um einen Reisenden einzuladen und diese Reiseroute mit ihm zu teilen"
                },
                "show": {
                    "shared_by": "Geteilt von"
                },
                "type": {
                    "excursions": "Route",
                    "pointsofinterest": "Sehenswürdigkeit"
                }
            },
            "states": {
                "changelog_state": {
                    "archived": "Archiviert",
                    "draft": "Entwurf",
                    "published": "Veröffentlicht"
                },
                "claim_dispute_state": {
                    "pending": "Ausstehend",
                    "rejected": "Abgelehnt",
                    "validated": "Bestätigt"
                },
                "claim_state": {
                    "missing-info": "Fehlende Information",
                    "pending": "Ausstehend",
                    "rejected": "Abgelehnt",
                    "revoked": "Widerrufen",
                    "validated": "Bestätigt"
                },
                "payment_state": {
                    "canceled": "Abgesagt",
                    "completed": "Vollendet",
                    "failed": "Fehlgeschlagen",
                    "pending": "Ausstehend",
                    "transferred": "Übertragen"
                },
                "plan_state": {
                    "archived": "Archiviert",
                    "coming_soon": "Demnächst",
                    "draft": "Entwurf",
                    "published": "Veröffentlicht"
                },
                "point_of_interest_state": {
                    "deleted": "Gelöscht",
                    "denied": "Bestritten",
                    "draft": "Entwurf",
                    "pending": "Ausstehende Moderation",
                    "published": "Veröffentlicht"
                },
                "post_state": {
                    "archived": "Archiviert",
                    "draft": "Entwurf",
                    "published": "Veröffentlicht"
                },
                "privacy_policy_state": {
                    "archived": "Archiviert",
                    "draft": "Entwurf",
                    "published": "Veröffentlicht"
                },
                "report_state": {
                    "pending": "Ausstehend",
                    "processed": "Verarbeitet",
                    "rejected": "Abgelehnt"
                },
                "reservation_state": {
                    "archived": "Archiviert",
                    "canceled": "Abgesagt",
                    "confirmed": "Bestätigt",
                    "pending": "Ausstehend"
                },
                "subscription_state": {
                    "active": "Aktiv",
                    "canceled": "Abgesagt",
                    "pending": "Ausstehende Zahlung",
                    "suspended": "Ausgesetzt"
                },
                "support_request_state": {
                    "archived": "Archiviert",
                    "canceled": "Abgesagt",
                    "pending-support": "Ausstehender Support",
                    "pending-user": "Ausstehender Benutzer",
                    "resolved": "Gelöst"
                },
                "terms_condition_state": {
                    "archived": "Archiviert",
                    "draft": "Entwurf",
                    "published": "Veröffentlicht"
                },
                "traveller_folder_state": {
                    "approved": "Genehmigt",
                    "archived": "Archiviert",
                    "declined": "Abgelehnt",
                    "pending": "Ausstehend"
                },
                "user_credential_verification_state": {
                    "canceled": "Abgesagt",
                    "completed": "Vollendet",
                    "pending": "Ausstehend"
                },
                "user_tutorial_state": {
                    "claimed": "Behauptet",
                    "done": "Erledigt",
                    "employee-added": "Mitarbeiter hinzugefügt",
                    "pending": "Ausstehend"
                },
                "user_utm_campaign_state": {
                    "always_on": "Immer auf"
                },
                "user_utm_medium_state": {
                    "owned_social": "Soziales Netzwerk"
                },
                "user_utm_source_state": {
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "linkedin": "LinkedIn"
                },
                "users_relationship_state": {
                    "approved": "Genehmigt",
                    "blocked": "verstopft",
                    "declined": "Abgelehnt",
                    "pending": "Ausstehend"
                }
            },
            "stripe": {
                "connect": {
                    "account": {
                        "create": {
                            "completed": "Die Konfiguration des Stripe-Kontos ist abgeschlossen",
                            "link_invalid": "Die Verbindung zu Stripe ist fehlgeschlagen. Bitte versuchen Sie es erneut"
                        },
                        "must_subscribe": "Sie müssen ein Abonnement haben, um Ihr Stripe-Konto erstellen zu können.",
                        "show": {
                            "account_usage": "Ihr Stripe-Konto ermöglicht Ihrer Institution den Empfang von Zahlungen auf UgoZER.",
                            "can_receive_payments": "Sie können Zahlungen empfangen.",
                            "cannot_receive_payments": "Sie können keine Zahlungen empfangen",
                            "fee_percentage": "Für jede Zahlung, die Sie erhalten, wird eine Gebühr von {percentage} % erhoben.",
                            "go_to_stripe": "Gehen Sie zum Stripe-Dashboard",
                            "has_stripe_account_being_reviewed": "Ihr Konto wird von Stripe überprüft.",
                            "no_account_yet": "Sie haben noch kein Stripe-Konto.",
                            "should_complete_stripe_onboarding": "Bitte schließen Sie Ihre Registrierung bei Stripe ab und übermitteln Sie dort die angeforderten Informationen."
                        }
                    }
                },
                "stripe": "Streifen"
            },
            "subscriptions": {
                "activated_at": "Aktivierung",
                "cancel": "Stornieren",
                "cancelation_success": "Stornierung berücksichtigt",
                "canceled_at": "Stornierung",
                "confirm_cancel": "Möchten Sie Ihr Abonnement wirklich kündigen?",
                "confirm_cancel_date": "Sie profitieren von den Vorteilen bis zum Wirksamwerden der Kündigung am {date}.",
                "confirm_cancel_now": "Die Kündigung wird sofort wirksam.",
                "expiry": "Ablauf",
                "invoices": "Rechnungen",
                "my_current_subscription": "Mein aktuelles Abonnement",
                "price_excl_taxes": "Preis exkl. MwSt. Steuern",
                "price_incl_taxes": "Preis inkl. Steuern",
                "upcoming_subscription": "Kommendes Abonnement"
            },
            "support_messages": {
                "show": {
                    "you": "Du",
                    "your_question": "Ihre Frage"
                },
                "store": {
                    "heading": "Nachricht hinzufügen",
                    "success": {
                        "title": "Die Nachricht wurde erstellt. Der Kontaktsupport wird innerhalb von 48 Stunden antworten."
                    }
                }
            },
            "support_requests": {
                "actions": {
                    "archived": {
                        "success": "Die Anfrage wird archiviert"
                    },
                    "canceled": {
                        "success": "Die Anfrage wird abgebrochen"
                    },
                    "pending_support": {
                        "success": "Warte auf Antwort des Supports"
                    },
                    "pending_user": {
                        "success": "Wartender Benutzer auf Antwort"
                    },
                    "resolved": {
                        "success": "Die Anfrage wurde gelöst"
                    }
                },
                "cancel": {
                    "confirm": "Möchten Sie diese Supportanfrage wirklich abbrechen: {support_request_title} ?",
                    "error": "Die Supportanfrage konnte nicht abgebrochen werden",
                    "success": "Die Supportanfrage wurde abgebrochen"
                },
                "index": {
                    "no_results": "Sie haben keine Supportanfrage",
                    "search": "Suchen Sie nach einer Supportanfrage",
                    "your_requests": "Ihre Supportanfragen"
                },
                "pages": {
                    "create": {
                        "notice": "persönliche Daten"
                    },
                    "index": {
                        "search": "Suchen Sie nach einer Supportanfrage"
                    },
                    "show": {
                        "sections": {
                            "messages": "Mitteilungen",
                            "request": "Anfrage"
                        }
                    }
                },
                "show": {
                    "add_request": "Fügen Sie eine neue Supportanfrage hinzu"
                },
                "states": {
                    "archived": "Archiviert",
                    "canceled": "Abgesagt",
                    "pending-support": "Ausstehender Support",
                    "pending-user": "Ausstehender Benutzer",
                    "resolved": "Gelöst"
                },
                "store": {
                    "heading": "Erstellen Sie eine neue Supportanfrage",
                    "success": {
                        "title": "Die Anfrage wurde erstellt. Der Kontaktsupport wird innerhalb von 48 Stunden antworten."
                    }
                }
            },
            "terms_conditions": {
                "archive": {
                    "success": "Die Allgemeinen Geschäftsbedingungen wurden archiviert (sofern berechtigt)."
                },
                "errors": {
                    "not_found": "Keine AGB gefunden!"
                },
                "publish": {
                    "success": "Die Allgemeinen Geschäftsbedingungen wurden veröffentlicht"
                },
                "update": {
                    "acceptation": "Sie sollten die Allgemeinen Geschäftsbedingungen akzeptieren, um fortzufahren",
                    "new_version": "Eine neue Version der Allgemeinen Geschäftsbedingungen wurde veröffentlicht",
                    "success": "Die AGB wurden akzeptiert!"
                }
            },
            "traveller_folders": {
                "messages": {
                    "approve": {
                        "confirm": "Möchten Sie diesen Aufenthalt wirklich bestätigen?",
                        "success": "Bleiben Sie bestätigt!"
                    },
                    "archive": {
                        "confirm": "Möchten Sie diesen Aufenthalt wirklich archivieren?",
                        "success": "Dieser Aufenthalt wurde archiviert!"
                    },
                    "decline": {
                        "confirm": "Möchten Sie diesen Aufenthalt wirklich ablehnen?",
                        "success": "Bleiben Sie abgelehnt!"
                    }
                },
                "no_results": {
                    "approved": "Keine genehmigten Aufenthalte",
                    "archived": "Keine archivierten Aufenthalte",
                    "declined": "Keine abgelehnten Aufenthalte",
                    "pending": "Keine Aufenthaltsanfragen"
                },
                "pages": {
                    "form": {
                        "cannot_attach": {
                            "description": "Dieser Benutzer existiert, kann aber nicht unterstützt werden...",
                            "title": "Support nicht möglich"
                        },
                        "poi_empty_option": "Interessenpunkt entfernen",
                        "poi_label": "Concierge-Institution",
                        "verification": {
                            "description": "Geben Sie die E-Mail-Adresse des Reisenden ein. Wenn bereits ein Reisekonto vorhanden ist, ist dies der Fall\n                    direkt verlinkt sein, andernfalls müssen Sie einige zusätzliche Informationen eingeben, um eine zu erstellen\n                    ein neues.",
                            "title": "Überprüfung"
                        }
                    },
                    "index": {
                        "pending_stays": "Ihre ausstehenden Aufenthalte",
                        "search": "Suchen Sie unter meinen Reisenden",
                        "travellers_folders": "Reiseordner",
                        "your_stays": "Ihre Aufenthalte"
                    },
                    "qr_code_1": "Ein Reisender kann diesen QR-Code scannen, um automatisch eine Reisemappe zu erhalten\n            mit Ihrer Institution.",
                    "qr_code_2": "Wenn sich der Reisende noch nicht bei UgoZER registriert hat, wird er zur Registrierung weitergeleitet\n            Seite und wird am Ende der Registrierung mit Ihrer Institution verlinkt.",
                    "show": {
                        "fields": {
                            "state": "Zustand:"
                        },
                        "sections": {
                            "butler": "Zugehöriger Concierge",
                            "folder": "Ordner",
                            "hotel": "Hotel",
                            "institution": "Verwandte Institution",
                            "interactions": "Interaktionen",
                            "invitation": "Laden Sie einen Reisenden ein",
                            "payments": "Zahlungen",
                            "reservations": "Reservierungen",
                            "stay": "Bleiben",
                            "thanks": "Danke",
                            "traveller": "Reisender"
                        }
                    },
                    "wizard": {
                        "choose_an_institution": "Wählen Sie eine Institution",
                        "no_linked_institution": "Sie müssen mit einer Institution verknüpft sein, bevor Sie einen Reisenden abholen können."
                    }
                },
                "placeholders": {
                    "butler": "Empfangsteam"
                },
                "states": {
                    "approved": "Akzeptiert",
                    "archived": "Archiviert",
                    "declined": "Abgelehnt",
                    "pending": "Ausstehend",
                    "undefined": "Nicht definiert"
                },
                "store": {
                    "success": "Der Ordner wurde erstellt"
                },
                "tabs": {
                    "approved": "Genehmigt",
                    "archived": "Archiviert",
                    "declined": "Abgelehnt",
                    "pending": "Ausstehend"
                },
                "thank": {
                    "success": "Der Dank wurde verschickt"
                },
                "update": {
                    "success": "Der Ordner wurde aktualisiert"
                }
            },
            "tutorials": {
                "extranet": {
                    "begin": {
                        "butler": {
                            "description": "Damit Sie die UgoZER-Anwendung unter den besten Bedingungen nutzen können,\n                    Wir empfehlen Ihnen, die folgenden Schritte zu befolgen, die Sie bei den ersten Schritten unterstützen\n                    Handhabung und Parametrierung Ihres Concierge-Bereichs.",
                            "steps": {
                                "institution": {
                                    "content": "Suchen Sie mithilfe der Suchleiste und in der Liste der Betriebe nach Ihrem Hotel\n                            die im linken Bereich der Seite verfügbaren Filter. Sobald Sie Ihr gefunden haben\n                            Erstellen Sie eine Bewerbung, indem Sie auf <strong>Beitritt beantragen</strong> klicken\n                            Taste.",
                                    "description": "Link zu Ihrer Institution",
                                    "step_next": "behauptet",
                                    "step_state": "ausstehend",
                                    "subtitle": {
                                        "link": "Meine Institution",
                                        "part_1": "Verlinken Sie mich mit meinem Hotel",
                                        "part_2": "Sie können direkt auf der Seite auf Ihr Hotel verlinken"
                                    },
                                    "title": "Institution"
                                }
                            }
                        },
                        "done": {
                            "description": "Sie haben nun die verschiedenen Schritte zur Einrichtung der UgoZER-Anwendung abgeschlossen.",
                            "faq": {
                                "link": "FAQ",
                                "part_1": "Wenn Sie weitere Hilfe benötigen, finden Sie Artikel im",
                                "part_2": "Abschnitt der Bewerbung"
                            },
                            "help": "Auf einigen Seiten finden Sie auch kontextbezogene Hilfe über die <strong>Hilfe</strong>.",
                            "title": "Glückwunsch!"
                        },
                        "hotelier": {
                            "description": "Damit Sie die UgoZER-Anwendung unter den besten Bedingungen nutzen können,\n                    Wir empfehlen Ihnen, die folgenden Schritte zu befolgen, die Sie bei den ersten Schritten unterstützen\n                    Handhabung und Parametrierung Ihres Hotelmanagerbereichs.",
                            "steps": {
                                "employees": {
                                    "description": "Verwalten Sie Ihre Concierges",
                                    "step_next": "Mitarbeiter hinzugefügt",
                                    "step_state": "behauptet",
                                    "title": "Concierges"
                                }
                            }
                        },
                        "manager": {
                            "description": "Damit Sie die UgoZER-Anwendung unter den besten Bedingungen nutzen können,\n                    Wir empfehlen Ihnen, die folgenden Schritte zu befolgen, die Sie bei den ersten Schritten unterstützen\n                    Handhabung und Parametrierung Ihres Bereichs „Andere Business Manager“."
                        },
                        "messages": {
                            "claim": "Ihr Anspruch wurde bestätigt.",
                            "contest": "Ihr Streit wurde an unser Team weitergeleitet.",
                            "payment": "Ihre Bankdaten wurden erfolgreich erfasst.",
                            "point_of_interest": "Der Point of Interest wurde erstellt, Sie können ihn nun beanspruchen."
                        },
                        "no_tutorial": {
                            "content": "Für diese Rolle wurde kein Tutorial eingerichtet oder Sie sind als Administrator angemeldet.",
                            "title": "Kein verfügbares Tutorial"
                        },
                        "notices": {
                            "add_butler": {
                                "content": "Um die Anwendung nutzen zu können, müssen Sie ein Concierge-Konto für Ihre Einrichtung erstellen\n                        von wo aus Informationen an Reisende gesendet werden.",
                                "content_2": "Um das Concierge-Konto zu aktivieren, muss Ihr Concierge seine E-Mail-Adresse bestätigen\n                        Adresse eingeben und ihr Passwort erstellen.",
                                "title": "Fügen Sie einen Concierge hinzu"
                            },
                            "add_employee": {
                                "content": "Um die Anwendung nutzen zu können, müssen Sie ein Mitarbeiterkonto für Ihren Betrieb erstellen\n                        von wo aus Informationen an Reisende gesendet werden.",
                                "content_2": "Um das Mitarbeiterkonto zu aktivieren, muss Ihr Mitarbeiter seine E-Mail-Adresse bestätigen\n                        Adresse eingeben und ihr Passwort erstellen.",
                                "title": "Fügen Sie einen Mitarbeiter hinzu"
                            }
                        },
                        "shared": {
                            "actions": {
                                "skip_step": "Schritt überspringen",
                                "skip_tutorial": "Lernprogramm überspringen"
                            },
                            "claims": {
                                "description": "Beanspruchen Sie Ihre Institutionen",
                                "documents": {
                                    "association": {
                                        "identity_piece": "Ein Identitätsnachweis des gesetzlichen Vertreters im Zuge dessen\n                                    gültig",
                                        "statutes": "Vereinsstatuten",
                                        "title": "Wenn Sie ein Verein sind"
                                    },
                                    "company": {
                                        "identity_piece": "Ein Identitätsnachweis des gesetzlichen Vertreters im Zuge dessen\n                                    gültig",
                                        "kbis": "Ein KBIS-Auszug",
                                        "title": "Wenn Sie ein Unternehmen sind"
                                    },
                                    "description": "Um Ihre Institution zu beanspruchen, müssen Sie Folgendes vorbereiten\n                                Unterlagen:",
                                    "individual": {
                                        "identity_piece": "Ein gültiger Ausweis",
                                        "registration_document": "Eine Bescheinigung über die Registrierung des Selbstständigenunternehmens",
                                        "title": "Wenn Sie ein Selbstständiger sind"
                                    },
                                    "label": "Unterlagen",
                                    "title": "Anspruchsunterlagen"
                                },
                                "notice": {
                                    "description": "Sie finden diese Erklärung, indem Sie auf <strong>Hilfe</strong> klicken\n                                Klicken Sie auf die Schaltfläche oben rechts in der Liste der Sonderziele.",
                                    "title": "bitte beachten Sie"
                                },
                                "step_next": "behauptet",
                                "step_state": "ausstehend",
                                "title": "Beanspruchen"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "Möchten Sie diesen Schritt wirklich überspringen?",
                                    "success": "Der Schritt wurde wohl ignoriert!"
                                },
                                "skip_tutorial": {
                                    "confirm": "Möchten Sie dieses Tutorial wirklich überspringen?",
                                    "success": "Das Tutorial wurde wohl ignoriert!"
                                }
                            },
                            "payments": {
                                "description": "Erhalten Sie Tipps",
                                "step_next": "Erledigt",
                                "step_state": "Mitarbeiter hinzugefügt",
                                "steps": {
                                    "bank": {
                                        "create": "Klicken Sie auf die Schaltfläche <strong>Konto hinzufügen</strong>\n                                    Abschnitt <strong>Bankkonto</strong>"
                                    },
                                    "easytransac": {
                                        "connection": "Klick auf den Link\n                                    <strong>Gehen Sie zum EasyTransac-Dashboard</strong> und melden Sie sich mit Ihren Anmeldeinformationen an\n                                        per E-Mail erhalten",
                                        "create": "Klicken Sie auf die Schaltfläche <strong>Erstellen</strong> im\n                                    Abschnitt <strong>EasyTransac</strong>",
                                        "created": "Das Konto wird automatisch auf Basis der eingegebenen Daten erstellt\n                                    auf Ihrem Profil",
                                        "form": "Füllen Sie das Formular auf EasyTransac aus, um Ihr Zahlungskonto zu aktivieren",
                                        "warning": {
                                            "description": "Abhängig vom KYC-Level Ihres EasyTransac-Kontos können Sie dies tun\n                                        in der Lage sein, Zahlungen zu empfangen, aber kein Geld an eine reguläre Bank zu überweisen\n                                        Konto. Geld auf ein reguläres Bankkonto überweisen. Hierfür benötigen Sie einen KYC-Level\n                                        2 und stellen Sie bestimmte Dokumente in der EasyTransac-Schnittstelle bereit. der EasyTransac\n                                        Schnittstelle.",
                                            "title": "KYC-Ebene"
                                        }
                                    },
                                    "my_profile": {
                                        "part_1": "Gehen Sie zur Seite",
                                        "part_2": "Mein Profil"
                                    }
                                },
                                "subtitle_1": "Damit Ihre Concierges Trinkgelder erhalten, müssen Sie Bankdaten hinzufügen.",
                                "subtitle_2": "Um dies zu tun:",
                                "title": "Zahlung"
                            }
                        },
                        "tourist_office": {
                            "description": "Damit Sie die UgoZER-Anwendung unter den besten Bedingungen nutzen können,\n                    Wir empfehlen Ihnen, die folgenden Schritte zu befolgen, die Sie bei den ersten Schritten unterstützen\n                    Abwicklung und Parametrierung Ihrer Tourismusbüro-Räumlichkeiten.",
                            "steps": {
                                "employees": {
                                    "description": "Verwalten Sie Ihre Mitarbeiter",
                                    "step_next": "Mitarbeiter hinzugefügt",
                                    "step_state": "behauptet",
                                    "title": "Mitarbeiter"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "Damit Sie die UgoZER-Anwendung unter den besten Bedingungen nutzen können,\n                    Wir empfehlen Ihnen, die folgenden Schritte zu befolgen, die Sie bei den ersten Schritten unterstützen\n                    Abwicklung und Parametrierung Ihrer Mitarbeiterräume im Tourismusbüro.",
                            "steps": {
                                "institution": {
                                    "content": "Suchen Sie in der Liste der Betriebe über die Suchleiste nach Ihrem Tourismusbüro\n                            und die im linken Bereich der Seite verfügbaren Filter. Sobald Sie Ihr gefunden haben\n                            Erstellen Sie eine Bewerbung, indem Sie auf <strong>Beitritt beantragen</strong> klicken\n                            Taste.",
                                    "description": "Link zu Ihrer Institution",
                                    "step_next": "behauptet",
                                    "step_state": "ausstehend",
                                    "subtitle": {
                                        "link": "Meine Institution",
                                        "part_1": "Verlinken Sie mich mit meinem Tourismusbüro",
                                        "part_2": "Sie können direkt auf der Seite auf Ihr Tourismusbüro verlinken"
                                    },
                                    "title": "Institution"
                                }
                            }
                        }
                    },
                    "dashboard": {
                        "claim": {
                            "steps": {
                                "claim_form": "Folgen Sie dann den Anweisungen auf dem Antragsformular",
                                "claim_validation": "Der Anspruch wird dann von unserem Team überprüft und validiert",
                                "dont_exist": "Wenn es noch nicht vorhanden ist, klicken Sie auf die Schaltfläche <strong>Ein Sonderziel erstellen\n                        </strong> Füllen Sie dann das Formular aus. Sobald der Punkt von Interesse erstellt ist, gehen Sie zu ihm\n                        Karte und klicken Sie auf die Schaltfläche <strong>Anfordern</strong>",
                                "exist": "Wenn Ihre Institution existiert, gehen Sie zu deren Datei und klicken Sie auf die Schaltfläche <strong>\n                        Anspruch</strong>",
                                "filters": "Nutzen Sie die Filter und die Suche, um Ihre Institution zu finden",
                                "poi_index": {
                                    "part_1": "Gehe zum",
                                    "part_2": "Liste der Sehenswürdigkeiten"
                                }
                            },
                            "subtitle_1": "Sie können eine (oder mehrere) Einrichtung(en) beanspruchen, um diese zu verwalten.",
                            "subtitle_2": "Gehen Sie dazu wie folgt vor:",
                            "title": "Ich beanspruche das Eigentum und die Leitung meiner Einrichtung"
                        },
                        "employees": {
                            "link": "Um eine Anfrage zu erstellen, können sie auf die Seite <strong>Meine Institution</strong> gehen\n                    ihres Platzes und füllen Sie das Formular aus.",
                            "manage": "Auf dieser Seite können Sie Mitarbeiter akzeptieren oder ablehnen, die sich mit Ihnen vernetzen möchten\n                    Institution.",
                            "subtitle": {
                                "part_1": "Sie können Ihre Mitarbeiter direkt über die Seite verwalten",
                                "part_2": "Meine Mitarbeiter"
                            },
                            "title": "Verwalten Sie meine Mitarbeiter"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "distance": "<strong>Ort:</strong> ermöglicht Ihnen, die Punkte von einem bestimmten Ort aus zu filtern",
                            "main_title": "Filter",
                            "subtitle": "Sie können die Liste der Reiserouten nach folgenden Kriterien filtern:",
                            "title": "Reiserouten filtern",
                            "type": {
                                "customized": "<strong>Individuell:</strong> Reiserouten, die speziell für einen Reisenden erstellt wurden",
                                "explanation": "Mit <strong>Typ:</strong> können Sie nach Art der Reiseroute filtern",
                                "pro": "<strong>UgoZER Pro:</strong> Reiserouten bereitgestellt von UgoZER",
                                "root": "<strong>Meine Vorlagen:</strong> Modellreisepläne"
                            }
                        }
                    },
                    "institution_employee": {
                        "approve": {
                            "content": "Mit dieser Aktion können Sie die Anfrage eines Ihrer Mitarbeiter annehmen, einem Ihrer Mitarbeiter beizutreten\n                    Ihre Institutionen.",
                            "main_title": "Genehmigen",
                            "title": "Genehmigen Sie einen Mitarbeiter"
                        },
                        "cancel": {
                            "content": "Mit dieser Aktion können Sie die Einladungsanfrage eines Mitarbeiters im Falle einer Stornierung stornieren\n                    B. im Fehlerfall.",
                            "main_title": "Stornieren",
                            "title": "Stornieren Sie die Bewerbung eines Mitarbeiters"
                        },
                        "decline": {
                            "content": "Mit dieser Aktion können Sie die Anfrage eines Ihrer Mitarbeiter, einem Ihrer Mitarbeiter beizutreten, ablehnen\n                    Institutionen.",
                            "main_title": "Verweigern",
                            "title": "Einen Mitarbeiter ablehnen"
                        },
                        "functioning": {
                            "content": {
                                "invite": "Auf dieser Seite können Sie entweder Mitarbeiter einladen oder Anfragen von Ihnen annehmen bzw. ablehnen\n                        Mitarbeiter.",
                                "section": "In diesem Abschnitt können Sie die Mitarbeiter Ihrer Institutionen verwalten."
                            },
                            "main_title": "Funktion",
                            "title": "Funktionsweise des Mitarbeiterbereichs"
                        },
                        "invite": {
                            "content": {
                                "button": "Durch Klicken auf die Schaltfläche <strong>Mitarbeiter einladen</strong> können Sie einen Ihrer Mitarbeiter einladen\n                        Mitarbeiter unabhängig davon, ob sie bereits ein UgoZER-Konto haben oder nicht.",
                                "form": "Befolgen Sie dazu einfach die Anweisungen im angezeigten Schritt-für-Schritt-Formular.\n                        Formular, das erscheint."
                            },
                            "main_title": "Einladen",
                            "title": "Laden Sie einen Mitarbeiter ein"
                        },
                        "remove": {
                            "content": "Mit dieser Aktion können Sie einen Benutzer aus Ihren Mitarbeitern entfernen, falls ein\n                    Mitarbeiter verlässt Ihr Unternehmen. für den Fall, dass ein Mitarbeiter Ihr Unternehmen verlässt.",
                            "main_title": "Entfernen",
                            "title": "Entfernen Sie einen Mitarbeiter"
                        }
                    },
                    "points_of_interest": {
                        "claim": "Beanspruchen",
                        "filters": {
                            "category": "<strong>Kategorie:</strong> ermöglicht Ihnen die Auswahl einer bestimmten Kategorie\n                    (Hotelrestaurant...)",
                            "city": "<strong>Stadt:</strong> ermöglicht Ihnen, nach einer bestimmten Stadt zu filtern",
                            "country": "<strong>Länder:</strong> ermöglicht Ihnen, nach einem oder mehreren bestimmten Ländern zu filtern",
                            "distance": "<strong>Ort:</strong> ermöglicht es Ihnen, die Punkte von einem bestimmten Ort aus zu filtern",
                            "main_title": "Filter",
                            "measure_unit": "<strong>Maßeinheit:</strong> ermöglicht Ihnen, die verwendete Maßeinheit zu ändern\n                    der Distanzfilter und die Anzeige",
                            "my_points": "<strong>Nur meine Punkte anzeigen:</strong> Zeigt nur Punkte an, die Sie erstellt haben oder\n                    von Ihnen beansprucht",
                            "postal_code": "<strong>Postleitzahl:</strong> ermöglicht Ihnen das Filtern nach Postleitzahl",
                            "radius": "<strong>Auf einen Radius beschränken:</strong> ermöglicht das Filtern der Punkte in einem bestimmten Kreis\n                    Radius",
                            "subtitle": "Sie können die Liste der Sonderziele nach folgenden Kriterien filtern:",
                            "tags": "<strong>Verfeinern:</strong> ermöglicht Ihnen, die Suche nach Tags zu verfeinern, um die Kategorie anzugeben.\n                    Es besteht auch die Möglichkeit zu definieren, ob nach Punkten mit einem, allen oder allen gesucht werden soll\n                    die ausgewählten Tags",
                            "title": "Interessante Orte filtern"
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "validate": {
                    "success": "Der Berechtigungsnachweis wurde erfolgreich validiert (falls berechtigt)"
                },
                "verify": {
                    "already_verified": "Ihre Anmeldedaten sind bereits verifiziert",
                    "expired": "Der Link ist abgelaufen. Fordern Sie einen neuen an",
                    "impossible": "Die Überprüfung dieser Anmeldeinformationen ist nicht mehr möglich",
                    "not_received": "Sie haben den Bestätigungslink nicht erhalten?",
                    "sent": {
                        "again": "Nochmals senden",
                        "correct": "Korrigieren Sie den gewählten Berechtigungsnachweis",
                        "email_1": "Eine E-Mail wird an {email} gesendet, sofern diese Adresse nicht bereits verwendet wird.",
                        "email_2": "Diese E-Mail enthält einen Link, über den Sie Ihre Adresse bestätigen können.",
                        "email_3": "Ihre E-Mail-Adresse wird aktualisiert, sobald Sie auf den Link zugreifen.",
                        "refresh": "Hast du auf den Link geklickt? Aktualisieren Sie die Anwendung",
                        "title": "Link gesendet",
                        "wait_before": "Bitte warten Sie {seconds} Sekunden, bevor Sie erneut senden"
                    },
                    "success": "Ihre Anmeldedaten wurden erfolgreich verifiziert"
                }
            },
            "user_geoloc_settings": {
                "geolocation": {
                    "error": {
                        "allow_browser": "Erlauben Sie Ihrem Browser, Sie in den Einstellungen Ihres Telefons zu lokalisieren",
                        "allow_site": "Erlauben Sie UgoZER, Sie in Ihren Browsereinstellungen zu geolokalisieren",
                        "intro": "So zeigen Sie interessante Orte in Ihrer Umgebung an:",
                        "title": "Die Geolokalisierung ist fehlgeschlagen"
                    },
                    "intro": "Mit der Geolokalisierung können Sie die nächstgelegenen Sehenswürdigkeiten finden."
                },
                "not_configured": {
                    "body1": "Wählen Sie einen Ort Ihrer Wahl aus, um eine Auswahl an Sehenswürdigkeiten in der Nähe anzuzeigen.",
                    "body2": "Es besteht auch die Möglichkeit, sich nur die Sonderziele in Ihrer Nähe anzeigen zu lassen.",
                    "do_not_ask_again": "Fragen Sie mich nicht erneut, bis die Anwendung neu gestartet wird",
                    "subject": "Sie haben Ihre Geolokalisierungseinstellungen noch nicht definiert."
                },
                "update": {
                    "success": "Die Geolocation-Einstellungen wurden aktualisiert"
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "Die Benachrichtigungseinstellungen wurden aktualisiert"
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "Die Datenschutzeinstellungen wurden aktualisiert"
                }
            },
            "users": {
                "butlers": "Concierges",
                "finish": {
                    "success": "Das Tutorial wurde in den Status „Abgeschlossen“ geändert"
                },
                "index": {
                    "hint": "Ein Benutzer kann über seinen Spitznamen oder seine öffentliche ID gefunden werden.",
                    "no_results": "Kein Benutzer gefunden",
                    "search": "Suchen Sie einen Benutzer"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "delete": "Mein Konto löschen?",
                            "profile": "Mein Profil"
                        }
                    }
                },
                "profile": {
                    "alerts": {
                        "blocked": {
                            "message": "Sie können mit diesem Benutzer nicht mehr interagieren.",
                            "title": "Dieser Benutzer hat Sie blockiert..."
                        },
                        "missing_infos": {
                            "message": "Um alle Funktionen der Anwendung nutzen zu können, füllen Sie bitte die folgenden Informationen aus:",
                            "title": "vervollständige dein Profil"
                        },
                        "pending_validation": {
                            "message": "Dieser Benutzer hat Ihre Anfrage noch nicht angenommen.",
                            "title": "Bis zur Validierung"
                        }
                    },
                    "avatar": {
                        "defaults": {
                            "female": "Weiblich",
                            "male": "Männlich"
                        },
                        "section": {
                            "cta": "Benutze diesen Avatar",
                            "description": "Sie können einen vordefinierten Avatar aus der Liste unten auswählen",
                            "title": "Auswahl eines vordefinierten Avatars"
                        },
                        "success": "Ihr Avatar wurde erfolgreich aktualisiert!"
                    },
                    "bank_account_number_missing": "Bitte geben Sie Ihre Bankdaten an, um Ihre Zahlungen zu erhalten.",
                    "delete_account": {
                        "easy_transac": "Ihr EasyTransac-Konto wird nicht gelöscht.",
                        "instructions": "Um die endgültige Löschung Ihres Kontos zu bestätigen, geben Sie bitte das Wort „LÖSCHEN“ in das Feld unten ein und klicken Sie auf die Schaltfläche „Bestätigen“.",
                        "success": "Dein Account wurde gelöscht.",
                        "title": "Kontolöschung",
                        "warning": "Ihr Konto sowie alle mit Ihrem Konto verbundenen Informationen werden unverzüglich gelöscht, ohne dass eine Wiederherstellung möglich ist."
                    },
                    "edit_email": "Bearbeiten Sie Ihre E-Mail",
                    "edit_password": "Bearbeiten Sie Ihr Passwort",
                    "edit_phone": "Bearbeiten Sie Ihre Telefonnummer",
                    "edit_your_profile": "bearbeite dein Profil",
                    "email_missing": "Sie haben keine bestätigte E-Mail-Adresse eingegeben.",
                    "fill_country": "Bitte geben Sie Ihr Land an",
                    "form": {
                        "poi_label": "Verknüpfte Institution"
                    },
                    "manage_my_subscription": "Mein Abonnement verwalten",
                    "password_missing": "Sie haben kein Passwort eingegeben.",
                    "phone_missing": "Sie haben keine bestätigte Telefonnummer eingegeben",
                    "preferences": "Präferenzen",
                    "public_id": "Oder verwenden Sie diese Kennung:",
                    "qr_code": "Scannen Sie mich, um mein Profil zu sehen",
                    "security": "Sicherheit",
                    "share_profile": "Teile dein Profil",
                    "subscribe_to_receive_payments": "Um Zahlungen zu erhalten, müssen Sie angemeldet sein.",
                    "unnamed": "Kein Name",
                    "your_personal_information": "Ihre persönliche Information"
                },
                "report": {
                    "choose_reason": "Wählen Sie einen Grund für die Meldung dieses Benutzers „{user_name}“",
                    "heading": "Melden Sie einen Benutzer",
                    "success": "Der Benutzer wurde gemeldet!"
                },
                "search": {
                    "no_results": "Keine Benutzer gefunden"
                },
                "send_link": {
                    "error": "Link kann nicht gesendet werden. Hat der Benutzer eine E-Mail?",
                    "success": "Der Link zur Bewerbung wurde gesendet"
                },
                "update": {
                    "success": "Profil wurde aktualisiert"
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "Keine Kontakte gefunden",
                    "pending_requests": "Ihre ausstehenden Kontakte"
                },
                "messages": {
                    "add": {
                        "confirm": "Möchten Sie diesen Kontakt wirklich hinzufügen?",
                        "error": "Bei der Kontaktanfrage ist ein Fehler aufgetreten...",
                        "success": "Kontaktanfrage erfolgreich erledigt!"
                    },
                    "approve": {
                        "confirm": "Möchten Sie diese Kontaktanfrage wirklich annehmen?",
                        "success": "Kontaktanfrage angenommen!"
                    },
                    "block": {
                        "confirm": "Möchten Sie diesen Benutzer wirklich blockieren?",
                        "success": "Dieser Kontakt wurde blockiert!"
                    },
                    "decline": {
                        "confirm": "Möchten Sie diese Kontaktanfrage wirklich ablehnen?",
                        "success": "Kontaktanfrage abgelehnt!"
                    },
                    "unblock": {
                        "confirm": "Möchten Sie diesen Benutzer wirklich entsperren?",
                        "success": "Dieser Kontakt wurde entsperrt!"
                    }
                },
                "no_results": {
                    "approved": "Keine genehmigten Kontakte",
                    "blocked": "Keine blockierten Kontakte",
                    "pending": "Keine ausstehenden Kontaktanfragen",
                    "sent": "Keine gesendeten Kontaktanfragen"
                },
                "search": {
                    "no_results": "Keine Kontakte gefunden"
                },
                "tabs": {
                    "approved": "Genehmigt",
                    "blocked": "verstopft",
                    "pending": "Ausstehend",
                    "sent": "Gesendet"
                }
            }
        },
        "validation": {
            "accepted": "Das {attribute} muss akzeptiert werden.",
            "active_url": " {attribute} ist keine gültige URL.",
            "after": "Das {attribute} muss ein Datum nach {date} sein.",
            "after_or_equal": " {attribute} muss ein Datum nach oder gleich {date} sein.",
            "alpha": "Das {attribute} darf nur Buchstaben enthalten.",
            "alpha_dash": "Das {attribute} darf nur Buchstaben, Zahlen, Bindestriche und Unterstriche enthalten.",
            "alpha_num": "Das {attribute} darf nur Buchstaben und Zahlen enthalten.",
            "array": "Das {attribute} muss ein Array sein.",
            "attributes": {
                "accounting_file": "Buchhaltungsdatei",
                "actions": "Aktionen",
                "activated_at": "aktiviert auf",
                "active": "aktiv",
                "additional_information": "Weitere Informationen",
                "address": "Adresse",
                "addresses": "Adressen",
                "admin_message": "Nachricht",
                "administrative_area": "Verwaltungsbereich",
                "amount": "Menge",
                "app": "Anwendung",
                "app_fees": "Gebühren",
                "approved_at": "genehmigt bei",
                "archived_at": "Archiviert unter",
                "association_legal_representative_identity_piece": "Identitätsnachweis des gesetzlichen Vertreters",
                "association_statutes": "Statuten",
                "attached_files": "Angehängte Dokumente",
                "audio": "Audio-",
                "audio_refreshing": "Audio erfrischend",
                "audios": "Audios",
                "authenticated_once": "einmal authentifiziert",
                "auto_renewal_enabled": "Automatische Verlängerung aktiviert",
                "avatar": "Benutzerbild",
                "bank_account": "Bankkonto",
                "bank_account_number": "Kontonummer",
                "bank_bic": "BIC",
                "bank_city": "Bankstadt",
                "bank_country": "Bankland",
                "bank_file": "Bankdatei",
                "bank_iban": "IBAN",
                "bank_name": "Bank Name",
                "bank_payee": "Zahlungsempfänger",
                "bank_transfer": "Banküberweisung",
                "bank_transfer_institutions": "Banküberweisungsinstitut",
                "bank_transfer_reason": "Grund der Übertragung",
                "butler": "Portier",
                "butler_point_of_interest": "Concierge-Institution",
                "canceled_at": "Abgesagt um",
                "categories": "Kategorien",
                "category": "Kategorie",
                "category_id": "Kategorie ID",
                "causer": "Verursacher",
                "causer_id": "Verursacher-ID",
                "causer_type": "Verursachertyp",
                "certified": "zertifiziert",
                "city": "Stadt",
                "claim_confirmation": "Anspruchsbestätigung",
                "code": "Code",
                "collection_name": "Sammlungsname",
                "communication_consent": "Personalisierte Kommunikation",
                "communication_consent_alt": "Kommunikation?",
                "company_legal_representative_identity_piece": "Identitätsnachweis des gesetzlichen Vertreters",
                "company_registration_document": "Anmeldebogen",
                "company_statutes": "Statuten",
                "company_type": "Unternehmenstyp",
                "completed_at": "abgeschlossen um",
                "contact": "Kontakt",
                "contact_email": "Kontakt E-mail",
                "contact_phone": "Kontakt-Telefon",
                "content": "Inhalt",
                "contestant": "Kandidat",
                "contestant_company_type": "Unternehmenstyp",
                "contestant_email": "Email",
                "contestant_first_name": "Vorname",
                "contestant_last_name": "Familienname, Nachname",
                "contestant_message": "Nachricht",
                "contestant_phone": "Telefon",
                "conversions_disk": "Konvertierungsdiskette",
                "coords": "Koordinaten",
                "countries": "Länder",
                "countries_codes": "Länder",
                "country": "Land",
                "country_code": "Landesvorwahl",
                "created": "erstellt",
                "created_at": "hergestellt in",
                "creator": "Schöpfer",
                "credential": "Berechtigung",
                "currency": "Währung",
                "current_password": "Aktuelles Passwort",
                "custom_amount": "benutzerdefinierter Betrag",
                "custom_properties": "benutzerdefinierte Eigenschaften",
                "customers_qty": "Kundenmenge",
                "date": "Datum",
                "dates": "Termine",
                "days": "Tage",
                "declined_at": "lehnte ab",
                "deleted_at": "gelöscht am",
                "deletion_confirmation": "Löschbestätigung",
                "denial_reason": "Ablehnungsgrund",
                "dependent_locality": "abhängiger Ort",
                "description": "Beschreibung",
                "details": "Einzelheiten",
                "disk": "Scheibe",
                "display": "Anzeige",
                "displayed_name": "Angezeigter Name",
                "distance": "Distanz",
                "documents": "Unterlagen",
                "dt_created_by": "Erstellt von",
                "dt_last_update": "Letztes DataTourisme-Update",
                "dynamic_translations_enabled": "Dynamische Übersetzungen aktiviert",
                "email": "Email",
                "emails_must_different": "Beide Konten dürfen nicht dieselbe E-Mail-Adresse haben.",
                "employee": "Mitarbeiter",
                "end_date": "Endtermin",
                "engaged_until": "verlobt bis",
                "engagement_period": "Verlobungszeitraum",
                "event": "Ereignis",
                "excursion": "Route",
                "existing_user": "existierender Benutzer",
                "expirable": "abgelaufen",
                "expired_at": "abgelaufen um",
                "expired_on": "Enddatum des Aufenthalts",
                "expiry": "Ablauf",
                "expiry_date": "Verfallsdatum",
                "extranet": "Extranet",
                "fallback_locale": "Fallback-Gebietsschema",
                "favorite": "Lieblingsroute",
                "featured": "vorgestellt",
                "file": "Datei",
                "file_name": "Dateiname",
                "filter": "Filter",
                "financial": "finanziell",
                "first_name": "Vorname",
                "formatted_address": "formatierte Adresse",
                "free_period": "freie Zeit",
                "free_period_ended_at": "Die freie Zeit endete um",
                "front": "Vorderseite",
                "gallery": "Galerie",
                "gdpr": "DSGVO",
                "general": "Allgemein",
                "generated_conversions": "generierte Conversions",
                "gross_amount": "Bruttobetrag",
                "has_employees": "hat Mitarbeiter",
                "hour": "Stunde",
                "hours": "Std.",
                "id": "Ausweis",
                "image": "Bild",
                "images": "Bilder",
                "import_source": "Importquelle",
                "import_synchronizing": "Automatisches Update aktivieren",
                "individual_first_identity_piece": "Identitätsstück",
                "individual_registration_document": "Anmeldebogen",
                "individual_second_identity_piece": "zweites Identitätsstück",
                "information": "Information",
                "institution": "Institution",
                "institution_address": "Adresse der Einrichtung",
                "institution_id": "Institution",
                "institution_title": "Titel der Institution",
                "interaction_type": "Interaktionstyp",
                "invitation": "Einladung",
                "invitation_accepted": "Einladung angenommen",
                "invitation_status": "Einladungsstatus",
                "invited_at": "eingeladen bei",
                "invited_by": "eingeladen von",
                "invitees": "Eingeladene",
                "is_geocoding": "Geolokalisierung läuft",
                "iso_code": "ISO-Code",
                "items": "Artikel",
                "key": "Schlüssel",
                "kind": "Art",
                "label": "Etikett",
                "last_geoloc": "letzte Geolokalisierung",
                "last_logged_at": "zuletzt eingeloggt bei",
                "last_login_ip": "letzte Login-IP",
                "last_name": "Familienname, Nachname",
                "last_validated_at": "letzte Validierung",
                "lat": "Breite",
                "latitude": "Breite",
                "line1": "Anschrift Zeile 1",
                "line2": "Adresszeile 2",
                "link": "Verknüpfung",
                "lng": "Längengrad",
                "locale": "Sprache",
                "login": "Verbindung",
                "logo": "Logo",
                "longitude": "Längengrad",
                "manipulations": "Manipulationen",
                "max_amount_per_checkout": "Höchstbetrag pro Kasse",
                "measurement_system": "Mess-System",
                "media": "Medien",
                "message": "Nachricht",
                "metadata": "Metadaten",
                "mime_type": "Mime Typ",
                "min_amount_per_checkout": "Mindestbetrag pro Kasse",
                "misc": "Verschiedenes",
                "model": "aufzeichnen",
                "model_type": "Aufnahmetyp",
                "name": "Name",
                "net_amount": "Netto-Betrag",
                "new_password": "Neues Kennwort",
                "new_password_confirmation": "Neues Passwort bestätigen",
                "next_payment_at": "nächste Zahlung",
                "nickname": "Spitzname",
                "not_specified": "Nicht angegeben",
                "not_validated_activities": "nicht validierte Aktivitäten",
                "notes": "weitere Informationen",
                "opening_hours": "Öffnungszeiten",
                "order_column": "Befehl",
                "origin": "Herkunft",
                "owner": "Eigentümer",
                "owner_email": "Email",
                "owner_first_name": "Vorname",
                "owner_last_name": "Familienname, Nachname",
                "owner_phone": "Telefon",
                "password": "Passwort",
                "password_confirmation": "Passwort Bestätigung",
                "passwords_must_different": "Beide Konten dürfen nicht dasselbe Passwort haben.",
                "payable": "zahlbar",
                "payee": "Zahlungsempfänger",
                "payee_country": "Land",
                "payee_email": "Email",
                "payee_id": "Zahlungsempfänger",
                "payee_name": "Name des Zahlungsempfängers",
                "payee_phone": "Telefon",
                "payer": "Zahler",
                "payer_country": "Land",
                "payer_email": "Email",
                "payer_id": "Zahler",
                "payer_name": "Name des Zahlers",
                "payer_phone": "Telefon",
                "payment_received": "Zahlung erhalten",
                "payment_service": "Zahlungsdienst",
                "payment_service_account_id": "ID-Zahlungskonto",
                "payment_service_transaction_id": "ID-Zahlungstransaktion",
                "payment_thanks": "Zahlung danke",
                "payment_type": "Zahlungsart",
                "period": "Zeitraum",
                "phone": "Telefon",
                "plan": "planen",
                "point_of_interest": "Sehenswürdigkeit",
                "point_of_interest_categories": "Kategorien",
                "point_of_interest_category": "Kategorie",
                "point_of_interest_event_expired": "Veranstaltung abgelaufen",
                "points_of_interest": "Sehenswürdigkeiten",
                "points_of_interest_count": "Sehenswürdigkeiten zählen",
                "post_category": "Beitragskategorie",
                "price": "Preis",
                "price_excluding_taxes": "Preis ohne Steuern",
                "prices": "Preise",
                "priority": "Priorität",
                "privacy_policy": "Datenschutzrichtlinie",
                "privacy_policy_accepted": "Datenschutzerklärung akzeptiert",
                "privacy_policy_version": "Version der Datenschutzrichtlinie",
                "private": "Privat",
                "private_title": "privater Titel",
                "pro_file": "Profil",
                "profile_picture": "Profilbild",
                "properties": "Eigenschaften",
                "properties_comparison": "aktualisierte Eigenschaften",
                "provider": "Anbieter",
                "public": "öffentlich",
                "public_id": "öffentlicher Ausweis",
                "published_at": "veröffentlicht unter",
                "quantity": "Menge",
                "quitted_at": "aufgegeben um",
                "radius": "Radius",
                "rates": "Tarife",
                "reason_details": "Einzelheiten",
                "reason_type": "Grund",
                "recipient": "Empfänger",
                "recipients": "Empfänger",
                "recipients_alt": "registrierte Reisende",
                "remember": "erinnere dich an mich",
                "removed_at": "entfernt bei",
                "reports": "Berichte",
                "requested_at": "angefordert unter",
                "reservation_created": "Reservierung erstellt",
                "reservation_date": "Datum",
                "reservation_time": "Zeit",
                "resolved_at": "Gelöst bei",
                "responsive_images": "reaktionsfähige Bilder",
                "roles": "Rollen",
                "room_number": "Zimmernummer",
                "scout_searchable": "indexierbar",
                "searchable": "durchsuchbar",
                "sender": "Absender",
                "senders": "Absender",
                "service": "Service",
                "settings": "Einstellungen",
                "share_created": "Freigabe erstellt",
                "shareable": "teilbar",
                "show_contact_email": "Sichtbarkeit der Kontakt-E-Mail",
                "show_contact_phone": "Sichtbarkeit des Kontakttelefons",
                "show_profile_picture": "Sichtbarkeit des Avatars",
                "show_real_name": "Sichtbarkeit des Vor- und Nachnamens",
                "siret": "SIRET",
                "size": "Größe",
                "source": "Quelle",
                "source_id": "Quell-ID",
                "specified": "Angegeben",
                "starable": "mit Sternen gekennzeichnet",
                "stars": "Sterne",
                "start_date": "Startdatum",
                "state": "Zustand",
                "states": "Zustände",
                "static_translations_enabled": "Statische Übersetzungen aktiviert",
                "status": "Status",
                "subject": "Thema",
                "subject_id": "Betreff-ID",
                "subject_type": "Betrefftyp",
                "summary": "Zusammenfassung",
                "support_category": "Kategorie",
                "support_messages_count": "Support-Nachrichten zählen",
                "support_request": "Unterstützungsanfrage",
                "support_request_archived": "Supportanfrage archiviert",
                "support_request_message_received": "Antwort in einer Supportanfrage erhalten",
                "suspended_at": "suspendiert um",
                "tag": "Etikett",
                "tags": "Stichworte",
                "target": "Ziel",
                "terms_condition_accepted": "Allgemeine Geschäftsbedingungen akzeptiert",
                "terms_condition_version": "Version der Allgemeinen Geschäftsbedingungen",
                "terms_conditions": "Geschäftsbedingungen",
                "thanked": "dankte",
                "thanked_at": "Danke Datum",
                "thanks": "Danke",
                "thanks_message": "Danke Nachricht",
                "thanks_sent": "Danke geschickt",
                "tipping_fees": "Trinkgeldgebühren",
                "tips_enabled": "Tipps aktiviert",
                "title": "Titel",
                "total_amount": "Gesamtmenge",
                "traveller": "Reisender",
                "traveller_folder": "Reiseordner",
                "traveller_folder_approved": "Reiseordner genehmigt",
                "traveller_folder_archived": "Reiseordner archiviert",
                "traveller_folder_pending": "Reiseordner ausstehend",
                "traveller_folder_thanks": "Danke für den Aufenthalt",
                "tutorial_state": "Tutorial-Status",
                "type": "Typ",
                "updated_at": "aktualisiert am",
                "user": "Benutzer",
                "user_id": "Benutzer",
                "user_report": "Benutzer (der meldet)",
                "users": "Benutzer",
                "utm_campaign": "Kampagne",
                "utm_medium": "Mittel",
                "utm_settings": "UTM-Parameter",
                "utm_source": "Quelle",
                "uuid": "uuid",
                "validated_activities": "validierte Aktivitäten",
                "validated_at": "validiert bei",
                "value": "Wert",
                "vat": "MwSt",
                "vat_rate": "Mehrwertsteuersatz",
                "version": "Ausführung",
                "video": "Video",
                "videos": "Videos",
                "website": "Webseite",
                "zip": "Reißverschluss"
            },
            "before": "Das {attribute} muss ein Datum vor {date} sein.",
            "before_or_equal": " {attribute} muss ein Datum vor oder gleich {date} sein.",
            "between": {
                "array": "Das {attribute} muss zwischen {min} und {max} Elemente enthalten.",
                "file": "Das {attribute} muss zwischen {min} und {max} Kilobyte liegen.",
                "numeric": " {attribute} muss zwischen {min} und {max} liegen.",
                "string": "Das {attribute} muss zwischen {min} und {max} Zeichen sein."
            },
            "boolean": "Das {attribute}-Feld muss wahr oder falsch sein.",
            "confirmed": "Das {attribute} stimmt nicht überein.",
            "currency_scale": "Die Anzahl der Nachkommastellen von {attribute} darf {scale} nicht überschreiten.",
            "current_password": "Das {attribute} stimmt nicht mit Ihrem Passwort überein.",
            "custom": {
                "attribute-name": {
                    "rule-name": "benutzerdefinierte Nachricht"
                },
                "h-captcha-response": {
                    "HCaptcha": "CAPTCHA Fehler! Versuchen Sie es später noch einmal oder wenden Sie sich an den Site-Administrator.",
                    "required": "Bitte stellen Sie sicher, dass Sie kein Roboter sind."
                }
            },
            "date": "Das {attribute} ist kein gültiges Datum.",
            "date_equals": " {attribute} muss ein Datum sein, das {date} entspricht.",
            "date_format": "Das {attribute} stimmt nicht mit dem Format {format} überein.",
            "different": " {attribute} und {other} müssen unterschiedlich sein.",
            "digits": "Das {attribute} muss aus {digits} Ziffern bestehen.",
            "digits_between": " {attribute} muss zwischen {min} und {max} Ziffern liegen.",
            "dimensions": "Das {attribute} hat ungültige Bildabmessungen.",
            "distinct": "Das {attribute}-Feld hat einen doppelten Wert.",
            "email": "Das {attribute} muss eine gültige E-Mail-Adresse sein.",
            "ends_with": "Das {attribute} muss mit einem der folgenden Elemente enden: {values}.",
            "exists": "Das ausgewählte {attribute} ist ungültig.",
            "file": "Das {attribute} muss eine Datei sein.",
            "filled": "Das {attribute}-Feld muss einen Wert haben.",
            "gt": {
                "array": "Das {attribute} muss mehr als {value} Elemente enthalten.",
                "file": "Das {attribute} muss größer als {value} Kilobyte sein.",
                "numeric": "Das {attribute} muss größer als {value} sein.",
                "string": "Das {attribute} muss größer als {value} Zeichen sein."
            },
            "gte": {
                "array": "Das {attribute} muss {value} Elemente oder mehr enthalten.",
                "file": "Das {attribute} muss größer oder gleich {value} Kilobyte sein.",
                "numeric": " {attribute} muss größer oder gleich {value} sein.",
                "string": "Das {attribute} muss größer oder gleich {value} Zeichen sein."
            },
            "image": "Das {attribute} muss ein Bild sein.",
            "in": "Das ausgewählte {attribute} ist ungültig.",
            "in_array": "Das Feld {attribute} existiert in {other} nicht.",
            "integer": "Das {attribute} muss eine ganze Zahl sein.",
            "ip": "Das {attribute} muss eine gültige IP-Adresse sein.",
            "ipv4": " {attribute} muss eine gültige IPv4-Adresse sein.",
            "ipv6": " {attribute} muss eine gültige IPv6-Adresse sein.",
            "json": " {attribute} muss eine gültige JSON-Zeichenfolge sein.",
            "lt": {
                "array": "Das {attribute} muss weniger als {value} Elemente enthalten.",
                "file": "Das {attribute} muss kleiner als {value} Kilobyte sein.",
                "numeric": " {attribute} muss kleiner als {value} sein.",
                "string": "Das {attribute} muss weniger als {value} Zeichen umfassen."
            },
            "lte": {
                "array": "Das {attribute} darf nicht mehr als {value} Elemente enthalten.",
                "file": "Das {attribute} muss kleiner oder gleich {value} Kilobyte sein.",
                "numeric": " {attribute} muss kleiner oder gleich {value} sein.",
                "string": "Das {attribute} muss kleiner oder gleich {value} Zeichen sein."
            },
            "luhn": "{attribute} ist eine ungültige Zahl.",
            "max": {
                "array": "Das {attribute} darf nicht mehr als {max} Elemente enthalten.",
                "file": "Das {attribute} darf nicht größer als {max} Kilobyte sein.",
                "height": "{attribute} darf in der Höhe {max} Pixel nicht überschreiten.",
                "numeric": "Das {attribute} darf nicht größer als {max} sein.",
                "string": "Das {attribute} darf nicht größer als {max} Zeichen sein.",
                "width": "{attribute} darf in der Breite {max} Pixel nicht überschreiten."
            },
            "max_digits": "{attribute} darf {max} Ziffern nicht überschreiten.",
            "mimes": "Der {attribute} muss einen gültigen Typ haben.",
            "mimetypes": "Der {attribute} muss einen gültigen Typ haben.",
            "min": {
                "array": "Das {attribute} muss mindestens {min} Elemente enthalten.",
                "file": "Das {attribute} muss mindestens {min} Kilobyte groß sein.",
                "numeric": "Das {attribute} muss mindestens {min} sein.",
                "string": "Das {attribute} muss mindestens {min} Zeichen lang sein."
            },
            "min_digits": "{attribute} muss mindestens {min} Stellen haben.",
            "not_in": "Das ausgewählte {attribute} ist ungültig.",
            "not_regex": "Das {attribute}-Format ist ungültig.",
            "notification_setting": "Das {attribute}-Format ist ungültig (erwartetes Format ist array[bool]).",
            "numeric": "Das {attribute} muss eine Zahl sein.",
            "password": "Das Passwort ist inkorrekt.",
            "phone": "Das {attribute} muss eine gültige Telefonnummer sein.",
            "present": "Das {attribute}-Feld muss vorhanden sein.",
            "prohibited_if": "Das Feld {attribute} ist verboten, wenn {other} gleich {value} ist.",
            "prohibited_unless": "Das {attribute}-Feld ist verboten, es sei denn, {other} ist in {values}.",
            "real_name_characters": ":{attribute} darf nur Buchstaben, Leerzeichen, Punkte usw. enthalten.\n        Bindestriche oder Apostrophe.",
            "regex": "Das {attribute}-Format ist ungültig.",
            "required": "Das {attribute}-Feld ist erforderlich.",
            "required_if": "Das {attribute}-Feld ist erforderlich, wenn {other} {value} ist.",
            "required_unless": "Das Feld {attribute} ist erforderlich, es sei denn, {other} befindet sich in {values}.",
            "required_with": "Das {attribute}-Feld ist erforderlich, wenn {values} vorhanden ist.",
            "required_with_all": "Das {attribute}-Feld ist erforderlich, wenn {values} vorhanden ist.",
            "required_without": "Das {attribute}-Feld ist erforderlich, wenn {values} nicht vorhanden ist.",
            "required_without_all": "Das {attribute}-Feld ist erforderlich, wenn keines von {values} vorhanden ist.",
            "same": " {attribute} und {other} müssen übereinstimmen.",
            "same_currency": "Für alle Artikel muss dieselbe Währung verwendet werden.",
            "size": {
                "array": "Das {attribute} muss {size} Elemente enthalten.",
                "file": "Das {attribute} muss {size} Kilobyte sein.",
                "numeric": "Das {attribute} muss {size} sein.",
                "string": "Das {attribute} muss aus {size} Zeichen bestehen."
            },
            "starts_with": "Das {attribute} muss mit einem der folgenden Elemente beginnen: {values}.",
            "string": "Das {attribute} muss eine Zeichenfolge sein.",
            "tag_same_poi_category": "{attribute} sollte alle zur gleichen Kategorie gehören!",
            "timezone": " {attribute} muss eine gültige Zone sein.",
            "unique": "Das {attribute} ist bereits vergeben.",
            "uploaded": "Das {attribute} konnte nicht hochgeladen werden.",
            "url": "Das {attribute}-Format ist ungültig.",
            "uuid": " {attribute} muss eine gültige UUID sein."
        }
    },
    "en": {
        "The {attribute} must be at least {length} characters and contain at least one number.": "The {attribute} must be at least {length} characters and contain at least one number.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "The {attribute} must be at least {length} characters and contain at least one special character and one number.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "The {attribute} must be at least {length} characters and contain at least one special character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character.",
        "The {attribute} must be at least {length} characters.": "The {attribute} must be at least {length} characters.",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "auth": {
            "failed": "These credentials do not match our records. Are you sure you didn't use this email for a {type} account?",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "throttle_cascade": "Too many login attempts. Please try again in {remaining}."
        },
        "enums": {
            "App\\Enums\\PrivacySettingsEnum": {
                "everyone": "Everyone",
                "contacts": "My contacts",
                "nobody": "Nobody"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "Imperial",
                "metric": "Metric"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "Breakfast",
                "lunch": "Lunch",
                "dinner": "Dinner",
                "null": "N/A"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "Euro",
                "usd": "Dollar"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Monday": "Monday",
                "Tuesday": "Tuesday",
                "Wednesday": "Wednesday",
                "Thursday": "Thursday",
                "Friday": "Friday",
                "Saturday": "Saturday",
                "Sunday": "Sunday"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "In app",
                "email": "Email"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "stripe": "Stripe",
                "easytransac": "EasyTransac",
                "paypal": "PayPal"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "tip": "Tip",
                "subscription": "Subscription"
            },
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "Created",
                "updated": "Updated",
                "validated": "Validated",
                "deleted": "Deleted"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "incorrect_data": "Incorrect information",
                "shocking": "Shocking",
                "illegal": "Illegal",
                "spam": "Spam",
                "other": "Other"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "Customized",
                "root": "My templates",
                "ugozer_pro": "UgoZER Pro",
                "ugozer_app": "UgoZER App"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "App",
                "extranet": "Extranet",
                "front": "Front"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "Around me",
                "staying_hotel": "Near staying hotel",
                "customized": "Customized"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "FR": "France",
                "DE": "Germany",
                "ES": "Spain",
                "IT": "Italy",
                "TH": "Thailand",
                "undefined": "Undefined"
            },
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "individual": "Individual",
                "company": "Company (public/private)",
                "association": "Association (or foundation)",
                "undefined": "Undefined"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "Archived",
                "canceled": "Canceled",
                "pending_support": "Pending Support",
                "pending_user": "Pending User",
                "resolved": "Resolved"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "partner": "Partner",
                "customer": "Customer"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "1 month",
                "one year": "1 year"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "1 month",
                "three months": "3 months",
                "six months": "6 months",
                "one year": "1 year"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "payment default": "Payment default",
                "free period ended": "Free period ended",
                "expired": "Subscription ended"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "Facebook",
                "twitter": "X (Twitter)",
                "instagram": "Instagram",
                "linkedin": "LinkedIn",
                "google": "Google",
                "tiktok": "TikTok",
                "wechat": "WeChat"
            },
            "App\\Enums\\BinaryChoiceEnum": {
                "1": "Accepted",
                "0": "Denied"
            },
            "App\\Enums\\ContextsEnum": {
                "traveller": "Traveller",
                "tourist_office": "Tourist office",
                "city_hall": "City Hall",
                "manager": "Manager",
                "hotel": "Hotel"
            },
            "App\\Enums\\UserRoleEnum": {
                "traveller": "Traveller",
                "manager": "Manager",
                "butler": "Butler",
                "superadmin": "SuperAdmin",
                "contributor": "Contributor",
                "moderator": "Moderator",
                "hotelier": "Hotelier",
                "touristOffice": "Tourist office",
                "touristOfficeEmployee": "TO Employee"
            }
        },
        "nova": {
            "menu": {
                "pois": "Points of interest",
                "excursions": "Itineraries",
                "users": "Users",
                "content": "Content",
                "miscellaneous": "Miscellaneous",
                "parameters": "Parameters",
                "administration": "Administration",
                "import_poi": "Import POI",
                "support": "Support",
                "subscriptions": "Subscriptions"
            },
            "user_menu": {
                "profile": "My profile"
            },
            "s3_private_download": {
                "link": {
                    "open": "View the document",
                    "expiration": "The link will expire after 30 min, if this is the case, simply refresh the page."
                },
                "no_document": "No related documents",
                "not_found": "The document could not be found on the S3 drive..."
            },
            "lens": {
                "user": {
                    "user_origin": "Users origin"
                }
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "previous_alt": "Previous",
            "next": "Next &raquo;",
            "next_alt": "Next",
            "showing": "Showing",
            "to": "to",
            "of": "of",
            "results": "results",
            "pagination_navigation": "Pagination navigation",
            "go_page": "Go to page {page}"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "No user corresponding."
        },
        "ttmt": {
            "account_deletion": {
                "title": "Account deletion",
                "content": {
                    "conservation": {
                        "part_1": "UgoZER retains user data for as long as is necessary for the purposes described in the",
                        "link": "Privacy policy.",
                        "part_2": "In accordance with CNIL recommendations, an account not used for 3 years will be deleted.",
                        "part_3": "Users may request deletion of their accounts at any time. UgoZER may retain user data after a\n                deletion request in accordance with legal or regulatory requirements, or for reasons stated in this\n                statement."
                    },
                    "asking": "In the event of an account deletion request, UgoZER will delete the user's account and data,\n            unless they must be retained due to legal or regulatory requirements, for security purposes, fraud\n            prevention or due to a problem related to the user's account such as an unpaid, unresolved claim or\n            dispute.",
                    "instructions": "If you wish to delete your UgoZER account, please follow the instructions below."
                },
                "traveller": {
                    "title": "Traveller account",
                    "subtitle": "To delete your traveler account :",
                    "instructions": {
                        "profile": {
                            "part_1": "Go to your",
                            "link": "profile",
                            "path": "(hamburger icon menu > Settings > Profile),"
                        },
                        "delete": "Then click on the <strong>Delete</strong> button in front of <strong>Delete my account?\n                </strong>"
                    }
                },
                "pro": {
                    "title": "Professional account",
                    "subtitle": "To delete your professional account :",
                    "instructions": {
                        "profile": {
                            "part_1": "Go to your",
                            "link": "profile",
                            "path": "(by clicking on your avatar > My profile),"
                        },
                        "delete": "Then click on the <strong>Delete</strong> button in front of <strong>Account deletion\n                </strong>,"
                    }
                },
                "confirmation": "On the page that opens, confirm deletion by entering the word <strong>DELETE</strong>\n        and click on <strong>Confirme</strong>.",
                "logout": "You will be logged out and the account and associated data will be deleted immediately."
            },
            "activity_logs": {
                "summary": {
                    "updated": {
                        "intro": "Subject has been updated:",
                        "attribute_from_to": "{attribute} has been updated from \"{old_value}\" to \"{new_value}\""
                    },
                    "validated": {
                        "intro": "Subject has been validated:"
                    },
                    "created": {
                        "intro": "Subject has been created with these properties:"
                    },
                    "deleted": {
                        "intro": "Subject has been deleted with these properties:"
                    }
                }
            },
            "auth": {
                "login": {
                    "success": "Logged in",
                    "already_logged": "You are already logged in",
                    "logging_in": "Logging in...",
                    "use_email": "Login with an e-mail",
                    "use_social_networks": "Use social networks"
                },
                "password_forgotten": {
                    "intro": "Enter your credential and we will send you a link to reset your password.",
                    "success": {
                        "title": "Password reset link sent",
                        "email_1": "An e-mail will be sent to you at {email}, if this address is well registered.",
                        "email_2": "This email contains a link that will allow you to reset your password.",
                        "correct_credential": "Correct credential",
                        "send_again": "Send again",
                        "wait_before": "Please wait {seconds} seconds before sending again"
                    },
                    "submit": "Receive a reset link"
                },
                "password_reset": {
                    "success": "The password has been reset",
                    "submit": "Reset your password"
                },
                "messages": {
                    "login": "Login",
                    "login_title": "It's better with social networks",
                    "login_subtitle": "(registration or login)",
                    "send_magic_link": "Send passwordless link",
                    "no_account": "Don't have an account yet?",
                    "have_account": "Already have an account?",
                    "register": "Register",
                    "register_title": "Register to unlock all features",
                    "register_mail": "Register with an e-mail",
                    "or": "OR",
                    "professional": "You are a professional?",
                    "extranet_here": "Click here",
                    "reset_password": "Reset password",
                    "continue": "Continue with"
                },
                "register": {
                    "success": {
                        "paired": {
                            "email_1": "Two emails were sent to the following addresses",
                            "email_2": "Each email contains a link to confirm the address and connect the associated account."
                        },
                        "title": "Successfully registered",
                        "email_1": "An e-mail will be sent to {email}.",
                        "email_2": "This e-mail contains a link that will allow you to confirm your address and to connect."
                    },
                    "form": {
                        "privacy_policy": "I agree to the privacy policy",
                        "terms_conditions": "I agree to the terms and conditions",
                        "show_privacy_policy": "Show privacy policy",
                        "hide_privacy_policy": "Hide privacy policy",
                        "show_terms_conditions": "Show terms and conditions",
                        "hide_terms_conditions": "Hide terms and conditions"
                    },
                    "staying_hotel": "Once you've registered, you'll be taken care of by the hotel whose\n            you've just scanned the QR code."
                },
                "credential_verification": {
                    "intro": "Enter your credential, a confirmation link will be sent to you.",
                    "intro2": "Your credential will be updated as soon as you access the link."
                },
                "extranet_register": {
                    "success": "Successfully registered, a verification link has been sent to your email",
                    "form": {
                        "form_title": "Create your account",
                        "choose_establishment": "Choose your type of establishment",
                        "validate_registration": "Finalize my registration"
                    }
                },
                "social_networks": {
                    "authenticate": "Login with",
                    "no_email": "No email is associated with your social network, you can't authenticate with it",
                    "facebook": "Facebook",
                    "twitter": "Twitter",
                    "google": "Google"
                },
                "expiration": {
                    "session_expired": "Your session has expired, please re-authenticate"
                },
                "logout": {
                    "logging_out": "Logging out...",
                    "switch": "Switch to"
                },
                "travel_assistant": "Free travel assistant"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "Bank account not provided"
                    },
                    "lines": {
                        "no_account_yet": "Your bank account will enable your institution to receive payments on UgoZER."
                    },
                    "form": {
                        "placeholders": {
                            "bank_transfer_reason": "Example: \"UgoZER Tips\"",
                            "payee_name": "Payee name or institution name",
                            "bank_name": "Bank name",
                            "bank_city": "City of your bank branch.",
                            "bic": "Enter your BIC",
                            "types": "IBAN/BBAN",
                            "iban": "Enter your IBAN/BBAN"
                        }
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "customer": "customer",
                    "date": "date",
                    "tip_sharing_form": "tip sharing form",
                    "tips_received": "tips received",
                    "concierge": "concierge",
                    "amount": "amount",
                    "currency": "currency",
                    "total_received": "total received",
                    "repartition_key": "distribution key",
                    "amount_to_pay": "amount to pay"
                }
            },
            "breadcrumbs": {
                "points_of_interest": {
                    "points_of_interest": "Points of interest",
                    "add_excursion": "Add to an itinerary"
                },
                "excursions": {
                    "excursions": "Itineraries",
                    "create": "New itinerary"
                },
                "institutions": {
                    "institutions": "Institutions",
                    "institution": "Institution"
                },
                "institutions_employees": {
                    "institutions_employees": "My employees",
                    "invite": "Invite an employee"
                },
                "travellers": {
                    "travellers": "Travellers",
                    "folders": "Traveller folders"
                },
                "reservations": {
                    "reservations": "Reservations"
                },
                "dashboard": {
                    "dashboard": "Dashboard"
                },
                "notification_settings": {
                    "notification_settings": "Notifications settings"
                },
                "users": {
                    "users": "Users",
                    "profile": "My profile",
                    "edit_credential": "Edit credential"
                },
                "notifications": {
                    "notifications": "Notifications"
                },
                "finances": {
                    "finances": "Finances"
                },
                "invitations": {
                    "invitations": "Invitations"
                },
                "faq": {
                    "faq": "Frequently asked questions"
                },
                "changelogs": {
                    "changelogs": "Changelogs"
                },
                "privacy_policy": {
                    "privacy_policy": "Privacy policy"
                },
                "search": {
                    "search": "Search"
                },
                "terms_condition": {
                    "terms_condition": "Terms and conditions"
                },
                "contacts": {
                    "contacts": "Contacts"
                },
                "partners": {
                    "partners": "Partners",
                    "customers": "Customers"
                },
                "interactions": {
                    "interactions": "Interactions"
                },
                "privacy_settings": {
                    "privacy_settings": "Privacy settings"
                },
                "support_requests": {
                    "support_requests": "Support Requests",
                    "add_request": "Add a new Support Request"
                },
                "support_messages": {
                    "support_messages": "Messages",
                    "add_message": "Add a message"
                },
                "claims": {
                    "claims": "Claims"
                },
                "tutorials": {
                    "begin": "Getting started"
                },
                "cookie": {
                    "cookie": "Cookies management"
                },
                "credit": {
                    "credit": "Credits"
                },
                "subscriptions": {
                    "subscription": "Subscription"
                },
                "common": {
                    "create": "New",
                    "create_f": "New",
                    "edit": "Edit",
                    "share": "Share",
                    "show": "Show",
                    "add": "Add",
                    "report": "Report",
                    "delete": "Delete",
                    "download_pdf": "Download PDF",
                    "claim": "Claim"
                },
                "errors": {
                    "404": {
                        "label": "Error 404",
                        "title": "Error 404 - Not found"
                    }
                }
            },
            "changelogs": {
                "publish": {
                    "success": "Changelog(s) has(ve) been published (if eligible)"
                },
                "draft": {
                    "success": "Changelog(s) has(ve) been drafted (if eligible)"
                },
                "archive": {
                    "success": "Changelog(s) has(ve) been archived (if eligible)"
                },
                "show": {
                    "version": "Version "
                },
                "index": {
                    "sections": {
                        "last": "Last update",
                        "all": "All updates"
                    }
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "Claim dispute has been created"
                },
                "validated": {
                    "success": "Claim dispute has been validated"
                },
                "rejected": {
                    "success": "Claim dispute has been rejected"
                }
            },
            "claims": {
                "notice": {
                    "pending": {
                        "title": "Pending claim request",
                        "state": "Your claim request is currently being processed"
                    },
                    "rejected": {
                        "title": "Rejected claim request",
                        "state": "Your claim request was rejected for the following reason:"
                    },
                    "missing-info": {
                        "title": "Awaiting additional information",
                        "state": "Your claim is currently awaiting additional information or documentation:",
                        "infos": "Please complete your request by clicking on the button below."
                    },
                    "find_or_new": {
                        "title": "Search or create a POI",
                        "description": "Search for a point of interest in the list below or create a new one."
                    },
                    "already_claimed": {
                        "title": "Point of interest already claimed!",
                        "description": "This point of interest has already been claimed. If you think this is an error, you can\n                fill out the dispute form below."
                    },
                    "actions": {
                        "edit": "Modify my request"
                    }
                },
                "valid_identity_pieces": "valid identity card, passport or driver's license",
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "Claim",
                            "poi": "Point of interest",
                            "user": "User"
                        }
                    }
                },
                "states": {
                    "pending": "Pending",
                    "missing-info": "Missing information",
                    "rejected": "Rejected",
                    "validated": "Validated",
                    "revoked": "Revoked"
                },
                "update": {
                    "success": "The claim has been updated"
                },
                "form_wizard": {
                    "steps": {
                        "context": "Context",
                        "documents": "Documents",
                        "info": "Information",
                        "search": "Search",
                        "create": "Create",
                        "claim": "Claim"
                    },
                    "documents": {
                        "FR": {
                            "company_registration_document": "Un extrait KBIS",
                            "company_statutes": "Les statuts",
                            "company_legal_representative_identity_piece": "Une pièce d'identité en cours de validité du \n                    représentant légal de la société : carte d'identité (recto et verso), passeport, titre de séjour \n                    (recto et verso), permis de conduire biométrique (recto et verso)",
                            "individual_first_identity_piece": "Une première pièce d'identité en cours de validité : carte \n                    d'identité (recto et verso), passeport, titre de séjour (recto et verso), permis de conduire \n                    biométrique (recto et verso)",
                            "individual_second_identity_piece": "Une seconde pièce d'identité en cours de validité : carte \n                    d'identité (recto et verso), passeport, titre de séjour (recto et verso), permis de conduire \n                    (recto et verso), dernier avis d'imposition daté de moins d'un an, livret de famille ou \n                    récépissé d'enregistrement du pacte civil de solidarité",
                            "individual_registration_document": "Un certificat d'inscription à l'INSEE ou un document \n                    attestant de son inscription auprès de la Chambre de Commerce (pour un auto-entrepreneur exerçant \n                    une activité commerciale) ou auprès de la Chambre des métiers (pour un auto-entrepreneur exerçant \n                    une activité artisanale)",
                            "association_statutes": "Les statuts de l'association",
                            "association_legal_representative_identity_piece": "Une pièce d'identité en cours de validité du \n                    représentant légal de l'association : carte d'identité (recto et verso), passeport, titre de \n                    séjour (recto et verso), permis de conduire biométrique (recto et verso)"
                        },
                        "DE": {
                            "company_registration_document": "Handelsregisterauszug",
                            "company_statutes": "Aktuelle und unterschriebene (von der Geschäftsführung) Gesellschaftverträge",
                            "company_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere \n                    Version mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_first_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere Version mit \n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_second_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere Version mit \n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_registration_document": "Gewerbeschein, Handlungsbevollmächtigung, Handelsregisterauszug, \n                    Prokura, Steuernummer attribution letter",
                            "association_statutes": "Handelsregisterauszug",
                            "association_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere \n                    Version mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)"
                        },
                        "ES": {
                            "company_registration_document": "Extracto del Registro Mercantil Central, Nota Simple del Registro \n                    mercantil local sellado o numerico, Extracto desde los Registradores de España (informacion \n                    General Mercantil) con fecha de validez de menos de 3 meses o Modelo 600 con fecha de validez \n                    de menos de 3 meses (solo para sociedad civil)",
                            "company_statutes": "Escrituras de constitución o Estatutos sociales",
                            "company_legal_representative_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso \n                    de conducir (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un país \n                    europeo)",
                            "individual_first_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso de conducir \n                    (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un país europeo)",
                            "individual_second_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso de conducir \n                    (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un país europeo)",
                            "individual_registration_document": "Agencia Tributaria - Certificado de situacion Censal \n                    (Alta Censal)",
                            "association_statutes": "Escrituras de constitución o Estatutos sociales",
                            "association_legal_representative_identity_piece": "Pasaporte, Documento nacional de identidad, \n                    Permiso de conducir (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un \n                    país europeo)"
                        },
                        "IT": {
                            "company_registration_document": "Visura Camerale",
                            "company_statutes": "Statuto firmato dal Legale Rappresentante (se non incluso all'interno della \n                    Visura Camerale)",
                            "company_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, La patente \n                    di guida (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "individual_first_identity_piece": "Passaporto, Carta d'identità nazionale, La patente di guida \n                    (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "individual_second_identity_piece": "Passaporto, Carta d'identità nazionale, La patente di guida \n                    (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "individual_registration_document": "Estratto dal registro delle imprese (\"Visura Camerale\") di meno \n                    di 3 mesi",
                            "association_statutes": "tatuto firmato dal Legale Rappresentante (se non incluso all'interno della \n                    Visura Camerale",
                            "association_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, La \n                    patente di guida (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese \n                    europeo)"
                        },
                        "TH": {
                            "company_registration_document": "สารสกัดจาก KBIS",
                            "company_statutes": "สถานะต่างๆ",
                            "company_legal_representative_identity_piece": "เอกสารประจำตัวที่ถูกต้องจากตัวแทนทางกฎหมายของบริษัท: \n                    บัตรประจำตัวประชาชน (ด้านหน้าและด้านหลัง), หนังสือเดินทาง,\n                    ใบอนุญาตมีถิ่นที่อยู่ (ด้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ด้านหน้าและด้านหลัง)",
                            "individual_first_identity_piece": "เอกสารแสดงตนที่ถูกต้องฉบับแรก: บัตรประจำตัวประชาชน\n                    (ทั้งด้านหน้าและด้านหลัง), หนังสือเดินทาง, ใบอนุญาตมีถิ่นที่อยู่ (ทั้งด้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านหลัง)",
                            "individual_second_identity_piece": "เอกสารประจำตัวที่สองที่ถูกต้อง: บัตรประจำตัวประชาชน\n                    (ด้านหน้าและด้านหลัง) หนังสือเดินทาง ใบอนุญาตมีถิ่นที่อยู่\n                    (ด้านหน้าและด้านหลัง) ใบขับขี่ (ด้านหน้าและด้านหลัง)\n                    ประกาศภาษีครั้งสุดท้ายลงวันที่น้อยกว่าหนึ่งปี สมุดบันทึกครอบครัวหรือใบเสร็จรับเงินสำหรับการจดทะเบียน\n                    สนธิสัญญาความสามัคคีของพลเมือง",
                            "individual_registration_document": "หนังสือรับรองการจดทะเบียนกับอินทรีหรือเอกสารรับรองการจดทะเบียนกับหอการค้า\n                    (สำหรับผู้ประกอบอาชีพอิสระที่ดำเนินกิจการเชิงพาณิชย์)\n                    หรือกับหอการค้า (สำหรับผู้ประกอบอาชีพอิสระที่ดำเนินกิจการเชิงพาณิชย์)\n                    กิจกรรมงานฝีมือ)",
                            "association_statutes": "กฎเกณฑ์ของสมาคม",
                            "association_legal_representative_identity_piece": "เอกสารประจำตัวที่ถูกต้องจากตัวแทนทางกฎหมายของสมาคม:\n                    บัตรประจำตัวประชาชน (ทั้งด้านหน้าและด้านหลัง),\n                    หนังสือเดินทาง, ใบอนุญาตมีถิ่นที่อยู่ (ทั้งด้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านหลัง)"
                        }
                    }
                },
                "validated": {
                    "success": "Claim has been validated"
                },
                "rejected": {
                    "success": "Claim has been rejected"
                },
                "missing_info": {
                    "success": "Claim has been rejected for missing information"
                },
                "revoked": {
                    "success": "Claim has been revoked"
                }
            },
            "common": {
                "app_name": "UgoZER",
                "actions": {
                    "add": "Add",
                    "add_message": "Add a message",
                    "attach": "Attach",
                    "archive": "Archive",
                    "cancel": "Cancel",
                    "check": "Check",
                    "confirm": "Confirm",
                    "contest": "Contest",
                    "create": "Create",
                    "delete": "Delete",
                    "edit": "Edit",
                    "login": "Login",
                    "register": "Register",
                    "load_more": "Load more",
                    "add_contact": "Add to contacts",
                    "accept_contact": "Accept contact request",
                    "decline_contact": "Decline contact request",
                    "download": "Download",
                    "block_contact": "Block contact",
                    "unblock_contact": "Unblock contact",
                    "unblock_this_user": "Unblock this user",
                    "add_excursion": "Add to itinerary",
                    "share": "Share",
                    "show": "Show",
                    "approve": "Approve",
                    "decline": "Deny",
                    "configure": "Configure",
                    "update": "Update",
                    "ignore": "Ignore",
                    "quit": "Quit",
                    "google_maps": "Open on Google Maps",
                    "play": "Play",
                    "pause": "Pause",
                    "stop": "Stop",
                    "start": "Start",
                    "record": "Record",
                    "report": "Report",
                    "return": "Back",
                    "resume": "Resume",
                    "save": "Save",
                    "save_alt": "Save",
                    "filters": "Filters",
                    "apply": "Apply",
                    "reset": "Reset",
                    "give_a_tip": "Give a tip",
                    "pay": "Pay",
                    "validate": "Validate",
                    "thank": "Thank",
                    "hide": "Hide",
                    "see_website": "See website",
                    "close": "Close",
                    "send_link": "Send app link",
                    "accept": "Accept",
                    "search": "Search",
                    "logout": "Logout",
                    "thank_for_stay": "Thanks for the stay",
                    "interactions": "Show interactions",
                    "claim": "Claim",
                    "print": "Print",
                    "invite": "Invite",
                    "manage": "Manage",
                    "request_to_join": "Request to join",
                    "send": "Send",
                    "file_preview": "Preview file",
                    "next": "Next",
                    "help": "Help",
                    "subscribe": "Subscribe",
                    "traveller_show": "Consult traveller",
                    "see_plans": "See plans",
                    "retry": "Retry"
                },
                "errors": {
                    "internal": "Something went wrong!",
                    "not_found": "Not found",
                    "deleted_user": "Deleted user"
                },
                "online": {
                    "disconnected": "You are no longer connected to the Internet",
                    "connected": "You are connected to the Internet again"
                },
                "units": {
                    "km": "km",
                    "kilometer": "kilometer | kilometers",
                    "mi": "mi",
                    "mile": "mile | miles"
                },
                "messages": {
                    "clipboard": {
                        "success": "Copied to clipboard!",
                        "error": "Error during copy to clipboard..."
                    },
                    "no_records": "No records found",
                    "application_update": {
                        "new_version_available": "A new version is available.",
                        "want_to_refresh": "Do you want to restart the application to update it?",
                        "if_ignore": "If you ignore the update, it will be done the next time you start the application.",
                        "updating": "Updating..."
                    }
                },
                "dashboard": "Dashboard",
                "home": "Home",
                "back_to_home": "Back to home",
                "back_to_dashboard": "Back to dashboard",
                "welcome": "Welcome",
                "and": "and",
                "no": "no",
                "yes": "yes",
                "true": "yes",
                "false": "no",
                "search": "Search",
                "someone": "someone",
                "not_specified": "Not specified",
                "loading": "loading",
                "oops": "oops",
                "none": "none",
                "all": "all",
                "or": "or",
                "tip": "Tip",
                "tip_received": "Tip received (if enabled)",
                "counter": "{dividend} on {divisor}",
                "slow": "slow",
                "normal": "normal",
                "fast": "fast",
                "characters_limit": "{length} / {limit} characters",
                "later": "later",
                "more_options": "more options",
                "sort": "sort",
                "qr_code": "QR code",
                "missing_title": "Missing title",
                "country": "country"
            },
            "cookies": {
                "management": {
                    "title": "Cookies management",
                    "content": {
                        "line_1": "Some features of this site rely on the use of cookies.",
                        "line_2": "The cookies banner does not appear on the home page during your navigation if only necessary\n                to the functioning of the site are deposited.",
                        "line_3": "You can change your preferences by clicking on the button below:",
                        "cta": "Change my preferences"
                    }
                },
                "usage": {
                    "title": "What are cookies used for?",
                    "content": "Cookies can be used to recognize you when you visit UgoZER, remember your preferences, and give\n            you a personalized experience that's in line with your settings. Cookies also make your interactions with\n            UgoZER faster and more secure. Additionally, cookies allow us to bring you advertising both on and off the\n            UgoZER sites.",
                    "table": {
                        "headers": {
                            "category": "Category of use",
                            "description": "Description"
                        },
                        "lines": {
                            "authentication": {
                                "title": "Authentication",
                                "content": "If you're signed in to UgoZER, cookies help us show you the right information and\n                        personalize your experience."
                            },
                            "security": {
                                "title": "Security",
                                "content": "We use cookies to enable and support our security features, and to help us detect\n                        malicious activity and violations of our",
                                "cta": "Terms and conditions."
                            },
                            "services": {
                                "title": "Preferences, features and services",
                                "content": "Cookies provide you with features, insights, and customized content."
                            },
                            "ads": {
                                "title": "Advertising",
                                "content": "We may use cookies to show you relevant advertising both on and off the UgoZER site.\n                        We may also use a cookie to learn whether someone who saw an ad later visited and took an\n                        action (e.g. downloaded a white paper or made a purchase) on the advertiser's site.\n                        Similarly, our partners may use a cookie to determine whether we've shown an ad and how it\n                        performed, or provide us with information about how you interact with ads. We may also work\n                        with a partner to show you an ad on or off UgoZER, such as after you've visited a partner's\n                        site or application."
                            }
                        }
                    }
                },
                "details": {
                    "title": "Details of deposited cookies",
                    "content": "Here is the list of cookies placed on this section of the UgoZER application based on the\n            categories defined above:",
                    "table": {
                        "headers": {
                            "name": "Cookie name (or pattern)",
                            "category": "Category of use",
                            "description": "Description",
                            "mandatory": "Mandatory",
                            "lifespan": "Lifetime"
                        },
                        "lines": {
                            "session": {
                                "title": "*_ugozer_session",
                                "content": "Contains information about the session.",
                                "lifespan": "2 hours"
                            },
                            "xsrf": {
                                "title": "XSRF-TOKEN-*",
                                "content": "Allows to secure the application (requests, forms...).",
                                "lifespan": "2 hours"
                            },
                            "alphanumeric": {
                                "title": "[[{alnum}:]] (sequence of alphanumeric characters)",
                                "content": "Contains information about the session.",
                                "lifespan": "2 hours"
                            },
                            "tarteaucitron": {
                                "title": "tarteaucitron",
                                "content": "Allows you to keep the user's cookie settings.",
                                "lifespan": "365 days"
                            },
                            "gmaps": {
                                "title": "CONSENT, AEC, SOCS... (or other google.com domain cookies)",
                                "content": "Used for showing Google Maps iframe.",
                                "lifespan": "180-400 days"
                            },
                            "remember": {
                                "title": "remember_web_*",
                                "content": "Used for keeping user's authentication.",
                                "lifespan": "400 days"
                            }
                        }
                    }
                },
                "notice": {
                    "title": "Cookies",
                    "needed": "To see this section, you must accept cookies {name}.",
                    "refresh": "If you do not see it after accepting cookies, please refresh the page."
                },
                "services": {
                    "gmaps": "Google Maps",
                    "youtube": "YouTube"
                }
            },
            "countries": {
                "AF": "Afghanistan",
                "AX": "Åland Islands",
                "AL": "Albania",
                "DZ": "Algeria",
                "AS": "American Samoa",
                "AD": "Andorra",
                "AO": "Angola",
                "AI": "Anguilla",
                "AQ": "Antarctica",
                "AG": "Antigua & Barbuda",
                "AR": "Argentina",
                "AM": "Armenia",
                "AW": "Aruba",
                "AU": "Australia",
                "AT": "Austria",
                "AZ": "Azerbaijan",
                "BS": "Bahamas",
                "BH": "Bahrain",
                "BD": "Bangladesh",
                "BB": "Barbados",
                "BY": "Belarus",
                "BE": "Belgium",
                "BZ": "Belize",
                "BJ": "Benin",
                "BM": "Bermuda",
                "BT": "Bhutan",
                "BO": "Bolivia",
                "BA": "Bosnia & Herzegovina",
                "BW": "Botswana",
                "BV": "Bouvet Island",
                "BR": "Brazil",
                "IO": "British Indian Ocean Territory",
                "VG": "British Virgin Islands",
                "BN": "Brunei",
                "BG": "Bulgaria",
                "BF": "Burkina Faso",
                "BI": "Burundi",
                "KH": "Cambodia",
                "CM": "Cameroon",
                "CA": "Canada",
                "CV": "Cape Verde",
                "BQ": "Caribbean Netherlands",
                "KY": "Cayman Islands",
                "CF": "Central African Republic",
                "TD": "Chad",
                "CL": "Chile",
                "CN": "China",
                "CX": "Christmas Island",
                "CC": "Cocos (Keeling) Islands",
                "CO": "Colombia",
                "KM": "Comoros",
                "CG": "Congo - Brazzaville",
                "CD": "Congo - Kinshasa",
                "CK": "Cook Islands",
                "CR": "Costa Rica",
                "CI": "Côte d’Ivoire",
                "HR": "Croatia",
                "CU": "Cuba",
                "CW": "Curaçao",
                "CY": "Cyprus",
                "CZ": "Czechia",
                "DK": "Denmark",
                "DJ": "Djibouti",
                "DM": "Dominica",
                "DO": "Dominican Republic",
                "EC": "Ecuador",
                "EG": "Egypt",
                "SV": "El Salvador",
                "GQ": "Equatorial Guinea",
                "ER": "Eritrea",
                "EE": "Estonia",
                "SZ": "Eswatini",
                "ET": "Ethiopia",
                "FK": "Falkland Islands",
                "FO": "Faroe Islands",
                "FJ": "Fiji",
                "FI": "Finland",
                "FR": "France",
                "GF": "French Guiana",
                "PF": "French Polynesia",
                "TF": "French Southern Territories",
                "GA": "Gabon",
                "GM": "Gambia",
                "GE": "Georgia",
                "DE": "Germany",
                "GH": "Ghana",
                "GI": "Gibraltar",
                "GR": "Greece",
                "GL": "Greenland",
                "GD": "Grenada",
                "GP": "Guadeloupe",
                "GU": "Guam",
                "GT": "Guatemala",
                "GG": "Guernsey",
                "GN": "Guinea",
                "GW": "Guinea-Bissau",
                "GY": "Guyana",
                "HT": "Haiti",
                "HM": "Heard & McDonald Islands",
                "HN": "Honduras",
                "HK": "Hong Kong SAR China",
                "HU": "Hungary",
                "IS": "Iceland",
                "IN": "India",
                "ID": "Indonesia",
                "IR": "Iran",
                "IQ": "Iraq",
                "IE": "Ireland",
                "IM": "Isle of Man",
                "IL": "Israel",
                "IT": "Italy",
                "JM": "Jamaica",
                "JP": "Japan",
                "JE": "Jersey",
                "JO": "Jordan",
                "KZ": "Kazakhstan",
                "KE": "Kenya",
                "KI": "Kiribati",
                "KW": "Kuwait",
                "KG": "Kyrgyzstan",
                "LA": "Laos",
                "LV": "Latvia",
                "LB": "Lebanon",
                "LS": "Lesotho",
                "LR": "Liberia",
                "LY": "Libya",
                "LI": "Liechtenstein",
                "LT": "Lithuania",
                "LU": "Luxembourg",
                "MO": "Macao SAR China",
                "MG": "Madagascar",
                "MW": "Malawi",
                "MY": "Malaysia",
                "MV": "Maldives",
                "ML": "Mali",
                "MT": "Malta",
                "MH": "Marshall Islands",
                "MQ": "Martinique",
                "MR": "Mauritania",
                "MU": "Mauritius",
                "YT": "Mayotte",
                "MX": "Mexico",
                "FM": "Micronesia",
                "MD": "Moldova",
                "MC": "Monaco",
                "MN": "Mongolia",
                "ME": "Montenegro",
                "MS": "Montserrat",
                "MA": "Morocco",
                "MZ": "Mozambique",
                "MM": "Myanmar (Burma)",
                "NA": "Namibia",
                "NR": "Nauru",
                "NP": "Nepal",
                "NL": "Netherlands",
                "NC": "New Caledonia",
                "NZ": "New Zealand",
                "NI": "Nicaragua",
                "NE": "Niger",
                "NG": "Nigeria",
                "NU": "Niue",
                "NF": "Norfolk Island",
                "KP": "North Korea",
                "MK": "North Macedonia",
                "MP": "Northern Mariana Islands",
                "NO": "Norway",
                "OM": "Oman",
                "PK": "Pakistan",
                "PW": "Palau",
                "PS": "Palestinian Territories",
                "PA": "Panama",
                "PG": "Papua New Guinea",
                "PY": "Paraguay",
                "PE": "Peru",
                "PH": "Philippines",
                "PN": "Pitcairn Islands",
                "PL": "Poland",
                "PT": "Portugal",
                "PR": "Puerto Rico",
                "QA": "Qatar",
                "RE": "Réunion",
                "RO": "Romania",
                "RU": "Russia",
                "RW": "Rwanda",
                "WS": "Samoa",
                "SM": "San Marino",
                "ST": "São Tomé & Príncipe",
                "SA": "Saudi Arabia",
                "SN": "Senegal",
                "RS": "Serbia",
                "SC": "Seychelles",
                "SL": "Sierra Leone",
                "SG": "Singapore",
                "SX": "Sint Maarten",
                "SK": "Slovakia",
                "SI": "Slovenia",
                "SB": "Solomon Islands",
                "SO": "Somalia",
                "ZA": "South Africa",
                "GS": "South Georgia & South Sandwich Islands",
                "KR": "South Korea",
                "SS": "South Sudan",
                "ES": "Spain",
                "LK": "Sri Lanka",
                "BL": "St. Barthélemy",
                "SH": "St. Helena",
                "KN": "St. Kitts & Nevis",
                "LC": "St. Lucia",
                "MF": "St. Martin",
                "PM": "St. Pierre & Miquelon",
                "VC": "St. Vincent & Grenadines",
                "SD": "Sudan",
                "SR": "Suriname",
                "SJ": "Svalbard & Jan Mayen",
                "SE": "Sweden",
                "CH": "Switzerland",
                "SY": "Syria",
                "TW": "Taiwan",
                "TJ": "Tajikistan",
                "TZ": "Tanzania",
                "TH": "Thailand",
                "TL": "Timor-Leste",
                "TG": "Togo",
                "TK": "Tokelau",
                "TO": "Tonga",
                "TT": "Trinidad & Tobago",
                "TN": "Tunisia",
                "TR": "Turkey",
                "TM": "Turkmenistan",
                "TC": "Turks & Caicos Islands",
                "TV": "Tuvalu",
                "UM": "U.S. Outlying Islands",
                "VI": "U.S. Virgin Islands",
                "UG": "Uganda",
                "UA": "Ukraine",
                "AE": "United Arab Emirates",
                "GB": "United Kingdom",
                "US": "United States",
                "UY": "Uruguay",
                "UZ": "Uzbekistan",
                "VU": "Vanuatu",
                "VA": "Vatican City",
                "VE": "Venezuela",
                "VN": "Vietnam",
                "WF": "Wallis & Futuna",
                "EH": "Western Sahara",
                "XK": "Kosovo",
                "YE": "Yemen",
                "ZM": "Zambia",
                "ZW": "Zimbabwe"
            },
            "credits": {
                "images": "Pictures crédits"
            },
            "csv": {
                "common": {
                    "generating": "Generating CSV file...",
                    "waiting": "Please wait..."
                },
                "download": "CSV export"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "Favorites from your local expert"
                },
                "points_of_interest": {
                    "categories": "Points of interest categories"
                },
                "no_dashboard": {
                    "title": "No available dashboard",
                    "content": "No dashboard has been setup for your role or you're connected as an administrator.",
                    "contact": {
                        "text": "If you think this is a mistake, don't hesitate to",
                        "link": "contact us."
                    }
                },
                "links": {
                    "closest_pois": "Closest points",
                    "excursions": "Itineraries and walks",
                    "shares": "Shares"
                }
            },
            "documents": {
                "display": {
                    "title": "Display - A6 card",
                    "description": "Display in A6 format with linking QRCode and the logo of your institution."
                },
                "qrcode": {
                    "title": "Link QRCode",
                    "description": "QRCode alone allowing the traveler to be directly linked to your institution.",
                    "filename": "qrcode"
                }
            },
            "easy_transac": {
                "easy_transac": "EasyTransac",
                "account": {
                    "create": {
                        "button": "Create an EasyTransac account",
                        "success": "Your EasyTransac account has been successfully created",
                        "fail": "Connection to EasyTransac failed, please try again later"
                    },
                    "show": {
                        "no_account_yet": "You do not have an EasyTransac account yet.",
                        "account_usage": "Your EasyTransac account will allow your institution to receive payments on UgoZER.",
                        "can_receive_payments": "Your customers can now tip your establishment\n                from the UgoZER profile of your concierges.",
                        "fee_percentage": "A fee will be applied to each payment you receive.",
                        "cannot_receive_payments": "You cannot receive payments",
                        "go_to_easy_transac": "Access EasyTransac dashboard",
                        "credentials_sent_mail": "Your EasyTransac credentials have been sent to you by email.",
                        "should_complete_kyc": "Please send the documents requested by EasyTransac,\n                in order to receive payments.",
                        "can_retrieve_payments": {
                            "title": "Your EasyTransac account is incomplete",
                            "description": "You must complete your EasyTransac account with the requested information in order \n                    to use available funds in your account."
                        },
                        "must_subscribe": "You must be subscribed to create your EasyTransac account."
                    }
                }
            },
            "exceptions": {
                "401": "Not authenticated",
                "403": "Unauthorized",
                "404": "Resource not found",
                "408": "Timeout",
                "422": "Unprocessable request",
                "429": "Too many requests",
                "500": "Internal server error",
                "default": "An error occurred",
                "error_texts": {
                    "403": {
                        "title": "Oops! Access forbidden.",
                        "message": "You don't have access to this page.",
                        "link": {
                            "title": "You may want to access to",
                            "to_app_label": "traveller section?",
                            "to_extranet_label": "professional section?"
                        }
                    },
                    "credential_not_verified": {
                        "title": "Your credential is not verified",
                        "verify_mail": "Receive verification link for my email",
                        "verify_phone": "Receive verification link for my phone number"
                    },
                    "password_missing": {
                        "extranet": "Please set a password to access the application.",
                        "app": "Please set a password."
                    },
                    "opening_hours": {
                        "invalid": "The given value is not an OpeningHours instance."
                    }
                },
                "spam_detected": "Spam detected"
            },
            "excursions": {
                "destroy": {
                    "confirm": "Do you really want to delete this itinerary : {excursion_name}?",
                    "success": "The itinerary has been deleted"
                },
                "store": {
                    "create": "Create an itinerary",
                    "success": "The itinerary has been created",
                    "heading": "Create an itinerary"
                },
                "update": {
                    "success": "The itinerary has been updated",
                    "heading": "Update this itinerary"
                },
                "index": {
                    "your_excursions": "Your itineraries",
                    "no_results_here": "No itineraries are available in your search area.",
                    "try_more_filters": "You can widen your search or create an itinerary.",
                    "no_results": "You do not have any itineraries yet",
                    "search": "Search an itinerary",
                    "read_only": "Consultation only",
                    "alert": {
                        "title": "Itineraries in consultation only",
                        "content": "You can only view routes. If you want to create or share an itinerary, you must sign in with\n                a <strong>concierge/receptionist account</strong>"
                    }
                },
                "search": {
                    "no_results": "No itinerary found"
                },
                "share": {
                    "heading": "Share this itinerary",
                    "select_which_users": "Select the users you want to share the itinerary \"{excursion_title}\" with or enter\n            an email address to invite a new traveller",
                    "select_which_users_alt": "Enter the e-mail address of the person with whom you want to share the\n            \"{excursion_title}\" itinerary.",
                    "select_which_contacts": "Select the contacts you want to share the itinerary \"{excursion_title}\" with",
                    "success": "The itinerary has been shared!",
                    "contains_private": "This itinerary contains private points of interest. They may not be included in the share."
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "Itinerary",
                            "pois": "Points of interest"
                        }
                    }
                },
                "form": {
                    "all_points_of_interest": "All points of interest",
                    "select_from_points_of_interest": "Select from points of interest",
                    "from_existing_excursions": "From an existing itinerary",
                    "create_new_point_of_interest": "Create a new point of interest",
                    "your_excursion": "Your itinerary",
                    "order_hint": "The order of the points of interest corresponds to the order of the visit.",
                    "points_of_interest": "Points of interest",
                    "existing_excursions": "Existing itineraries"
                },
                "direction": {
                    "display_mode": "Display mode",
                    "driving": "driving",
                    "bicycling": "bicycling",
                    "walking": "walking"
                }
            },
            "extranet": {
                "professional_section": "Professional section",
                "navbar": {
                    "dashboard": "Dashboard",
                    "my_institutions": "My institutions",
                    "my_institution": "My institution",
                    "my_employees": "My employees",
                    "travellers": "Travellers",
                    "points_of_interest": "Points of interest",
                    "excursions": "Itineraries",
                    "finances": "Finances",
                    "invitations": "Invitations",
                    "contacts": "Contacts",
                    "faq": "FAQ",
                    "contact": "Contact us",
                    "tutorials": "Begin",
                    "users": "Users"
                },
                "institution": {
                    "sections": {
                        "butler": {
                            "institution": "My institution"
                        },
                        "manager": {
                            "institution": "My institutions"
                        }
                    },
                    "messages": {
                        "no_records": "No institution linked at the moment"
                    }
                },
                "pages": {
                    "folders": {
                        "new": "New traveller folder",
                        "edit": "Edit traveller folder"
                    },
                    "institutions": {
                        "new": "Link an institution"
                    },
                    "profile": {
                        "edit": "Edit profile"
                    },
                    "pois": {
                        "new": "New point of interest",
                        "edit": "Edit point of interest"
                    },
                    "excursions": {
                        "new": "New itinerary",
                        "edit": "Edit itinerary"
                    },
                    "reservations": {
                        "new": "New reservation",
                        "edit": "Edit reservation"
                    }
                },
                "travellers": {
                    "sections": {
                        "folders": "My travellers folders"
                    },
                    "buttons": {
                        "new_folder": "Add new traveller folder"
                    }
                },
                "user_dropdown": {
                    "profile": "My profile",
                    "subscription": "Subscription",
                    "sign_out": "Sign out",
                    "privacy_policy": "Privacy policy",
                    "terms_condition": "Terms and conditions",
                    "cookies": "Cookies management",
                    "credits": "Credits",
                    "changelogs": "Changelogs",
                    "partners": "Partners",
                    "customers": "Customers"
                },
                "dashboard": {
                    "sections": {
                        "stats": "Stats",
                        "last_travellers": "Your last travellers",
                        "last_payments": "Your last payments",
                        "search_butler": "Search a concierge",
                        "last_claims": "Your last claims",
                        "last_reservations": "Your last reservations"
                    },
                    "stats": {
                        "institutions": "Linked institution|Linked institutions",
                        "folders": "Folder|Folders",
                        "excursions": "Itinerary|Itineraries",
                        "processing_claims": "Processing claim|Processing claims",
                        "reservations": "Reservation|Reservations",
                        "butlers": "Concierge|Concierges",
                        "employees": "Employee|Employees"
                    },
                    "manager": {
                        "sections": {
                            "butlers_interactions": "Concierges with interactions",
                            "butlers_institutions": "Concierges of my institutions"
                        }
                    },
                    "last_traveller_folders": {
                        "none": "You do not have any traveller in your care yet.",
                        "take_care": "Take care of a traveller",
                        "see_all": "See all your travellers"
                    },
                    "last_payments": {
                        "none": "You do not have any payments yet.",
                        "see_index": "Go to the payment page",
                        "see_all": "See all your payments"
                    },
                    "last_claims": {
                        "none": "You do not have any claims yet.",
                        "see_index": "Go to the claims page",
                        "see_all": "See all your claims"
                    },
                    "last_reservations": {
                        "none": "You do not have any reservations yet."
                    }
                },
                "points_of_interest": {
                    "sections": {
                        "points_of_interest": "Points of interest"
                    },
                    "actions": {
                        "new": "Create a point of interest"
                    }
                },
                "excursions": {
                    "sections": {
                        "excursions": "Itineraries"
                    },
                    "actions": {
                        "new": "Create an itinerary"
                    }
                },
                "profile": {
                    "sections": {
                        "infos": "General information",
                        "notification_settings": "Notifications settings",
                        "delete_account": "Account deletion",
                        "privacy_settings": "Privacy settings",
                        "currency": "Currency",
                        "locale": "Language",
                        "shares": "Shares",
                        "payment": "Payment"
                    }
                },
                "filters": {
                    "open": "Open filters",
                    "closed": "Close filters"
                },
                "finances": {
                    "sections": {
                        "received_payments": "Received payments",
                        "received_by_institution_payments": "Payments received by your institutions",
                        "issued_payments": "Issued payments"
                    },
                    "alerts": {
                        "easytransac": {
                            "title": "EasyTransac account non-existent!",
                            "description": "You have not yet created an EasyTransac account! So you cannot receive payments.",
                            "cta": "Access my profile to create an EasyTransac account"
                        }
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "Categories"
                    }
                },
                "support_requests": {
                    "sections": {
                        "support_requests": "Support Requests"
                    },
                    "actions": {
                        "new": "Create a support request",
                        "back": "Back"
                    }
                },
                "support_messages": {
                    "sections": {
                        "support_messages": "Messages"
                    },
                    "actions": {
                        "new": "Add a new message",
                        "back": "Back to the support request"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "No FAQ at the moment"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "Invite an employee",
                        "new-hotelier": "Create a reception account",
                        "new-tourist-office": "Create a desk account"
                    }
                }
            },
            "form": {
                "titles": {
                    "verifications": "Checking your informations"
                },
                "labels": {
                    "user": {
                        "credential": "email",
                        "new_password": "new password",
                        "notes": "further information",
                        "image": {
                            "_destroy": "Delete profile picture"
                        },
                        "email": "email"
                    },
                    "geoloc_setting": {
                        "measure_unit": "measure unit",
                        "within_a_radius": "within a radius",
                        "limit_to_a_radius": "limit to a radius",
                        "limit_to_a_radius_from_center": "limit to a radius from center",
                        "where_would_you_like_to_go": "where would you like to go?",
                        "where_does_the_traveller_go": "where does the traveller go?",
                        "distance_filter": "distance filter",
                        "other_filters": "other filters"
                    },
                    "payment": {
                        "payable_type": "Payment linked to"
                    },
                    "points_of_interest_filters": {
                        "sort": "sort",
                        "geoloc": "place",
                        "order_distance": "by distance",
                        "tags": "refine",
                        "order_relevance": "by relevance (search keywords)"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "Automatically refresh the audio file",
                        "opening": "opening",
                        "opening_specification_add": "add a period",
                        "opening_schedule_add": "specify days or hours",
                        "opening_schedule_add_alt": "specify hours",
                        "opening_date": "on",
                        "opening_date_range": "specify an end date",
                        "opening_date_from": "from",
                        "opening_date_to": "to",
                        "opening_hours": "at",
                        "opening_hours_range": "specify an end hour",
                        "opening_hours_from": "from",
                        "opening_hours_to": "to"
                    },
                    "plan": {
                        "authorized_roles": "authorized roles",
                        "tipping_fees": "Tipping fees (percent)"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "price excluding taxes"
                    },
                    "register": {
                        "privacy_policy": {
                            "part1": "I agree to the",
                            "part2": "privacy policy"
                        },
                        "terms_conditions": {
                            "part1": "I agree to the",
                            "part2": "terms and conditions"
                        },
                        "communication_consent": "I would like to receive personalized communications, including offers,\n                promotional information and travel-related products from UgoZER via email."
                    },
                    "support_request": {
                        "title": "Subject of the request"
                    },
                    "support_message": {
                        "email": "Email address",
                        "content": "Message"
                    },
                    "support_category": {
                        "title": "Choose a category"
                    },
                    "traveller_invitation": {
                        "locale": "language spoken by this traveller"
                    },
                    "vat_rate": {
                        "vat_rate": "VAT rate (percentage)"
                    }
                },
                "placeholders": {
                    "name": "nickname",
                    "audio_file_name": "audio file name",
                    "support_message": {
                        "create_message": "Your message for the support request"
                    }
                },
                "select": {
                    "no_results": "Sorry, no matching options.",
                    "no_options": "No options",
                    "type_to_search": "Search..."
                },
                "hints": {
                    "translatable": {
                        "pro_global_title": "Multi-language input",
                        "pro_global": "One or more fields of this form can be filled in different languages.",
                        "pro_global_2": "Fields not filled in in certain languages will be automatically translated."
                    },
                    "plan": {
                        "countries": "Leave empty to make the plan available in all countries",
                        "private_title": "This title is only visible in administration",
                        "name": "This name will be visible to potential subscribers\n                when they choose their subscription",
                        "information": "Additional information on plan (prerequisites, conditions...)"
                    },
                    "plan_price": {
                        "private_title": "This title is only visible in administration",
                        "description": "This description will be visible to potential subscribers\n                when they choose their subscription",
                        "price": "Please enter in euros"
                    },
                    "profile": {
                        "password_unchanged": "Leave blank to remain unchanged",
                        "name": "The nickname is public and allows other users of the application to identify you more easily.",
                        "contact_phone": "This phone can be displayed on your public profile",
                        "contact_email": "This email can be displayed on your public profile",
                        "password_template": {
                            "length": "{length} characters",
                            "uppercase": "1 capital letter",
                            "lowercase": "1 lower case letter",
                            "digit": "1 digit"
                        }
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "butler": "No matter which option you choose, your last name and your first name\n                    will remain visible by your institution's manager",
                            "traveller": "No matter which option you choose, your last name and your first name\n                  will remain visible by the concierges of the hotel where you are staying."
                        }
                    },
                    "geoloc_setting": {
                        "filter_header": "Search for a country, region, city or neighborhood",
                        "kind": {
                            "admin": {
                                "around_me": "Around me: the geolocation will be determined\n                        by the user's browser.",
                                "customized": "Customized: the user will be able to manually search for a place.",
                                "staying_hotel": "Staying hotel: the geolocation will be done\n                        from the hotel where the traveller is staying."
                            },
                            "app": {
                                "around_me": "The geolocation will be done from your position.",
                                "customized": "The geolocation will be done from a location of your choice.",
                                "staying_hotel": "The geolocation will be done from the hotel where you stay: {title}."
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "Customized: itinerary created by the concierge\n                        for a specific traveller",
                                "root": "My templates: \"ready to share\" itinerary consisting of must-see points of interest,\n                        or points of interest by theme\n                        (example: list of restaurants frequently included in itineraries)\n                        to be selected to create a new itinerary.",
                                "ugozer_pro": "UgoZER Pro: itinerary created and proposed by UgoZER\n                        and made available to professionals to facilitate their work.",
                                "ugozer_app": "UgoZER App: itinerary created and proposed by UgoZER\n                        and made available to travellers."
                            },
                            "pro": {
                                "customized": "Itinerary created for a specific traveller",
                                "ugozer": "Itinerary created and proposed by UgoZER.",
                                "root": "\"ready to share\" itinerary consisting of must-see points of interest,\n                        or points of interest by theme\n                        (example: list of restaurants frequently included in itineraries)\n                        to be selected to create a new itinerary."
                            }
                        },
                        "points_of_interest_search": "Search and select a point of interest to add it to the list"
                    },
                    "locale": {
                        "static_translations_enabled": "Allows users to choose this language to display the application\n                (requires that translations have been added to the source code)",
                        "dynamic_translations_enabled": "Allows users to display or translate resources\n                (points of interest, itineraries...) in that language",
                        "fallback_locale": "Automatically displays the title in a second language (before the description) on\n                the POI file."
                    },
                    "permission": {
                        "name": "Name used in the source code"
                    },
                    "point_of_interest": {
                        "images": "The images will be displayed in the gallery of the point of interest in the chosen order,\n                you can reorganize them by dragging them.",
                        "locale": "The main language spoken at this point of interest",
                        "line1": "You can enter either the address or the name of the place you are looking for. Choose from the\n                list to fill in the address automatically",
                        "audio_refreshing_1": "Enabling the refresh of the audio file will automatically generate an audio file\n                in the language of the point of interest ({language}).",
                        "audio_refreshing_2": "It will state the title and address of the point of interest.",
                        "audio_refreshing_3": "It will be refreshed as soon as the language, the title\n                or the address of the point of interest are updated.",
                        "audio_refreshing_4": "The refresh will be disabled if you add or record an audio file yourself,\n                or if you delete the generated audio file.",
                        "sync_1": "Activate Automatic update allows you to retrieve data from the {import_source}\n                import",
                        "sync_2": "Manually entries will be overwritten",
                        "opening_days": "Do not select anything if schedules apply on any day",
                        "opening_hours": "Do not add anything if the point of interest is open at any time",
                        "opening_dates": "Leave blank if schedules apply to any date",
                        "expiry_date": "Your point of interest will expire on {date}",
                        "expiry_date_details_1": "Your point of interest is part of the \"{category}\" category.",
                        "expiry_date_details_2": "It will therefore expire on {date}, as this is the last date you entered.",
                        "expiry_date_details_3": "From this date, your point of interest will have 21 days left\n                before it is automatically deleted..",
                        "expiry_date_details_4": "You can always add a new date\n                to postpone expiration, or specify no date so that your point of interest doesn't expire.",
                        "cant_have_employees": "Here you only see establishments associated with categories that may have\n                employees",
                        "is_geocoding": "Deactivate if the geolocation is correctly entered but the map still does not appear."
                    },
                    "points_of_interest_filters": {
                        "radius": "You will only see points of interest located within this radius.",
                        "order_distance": "Please choose a distance filter to select this option",
                        "order_relevance": "Please enter search keywords to select this option"
                    },
                    "excursions_filters": {
                        "radius": "You will only see itineraries with at least one point of interest located within this radius."
                    },
                    "role": {
                        "name": "Code name of the role, it will not be displayed to users.",
                        "description": "Role's description is displayed to the users when they choose the role when they\n                register to the professional section."
                    },
                    "support_request": {
                        "support_categories_search": "Search and select a category"
                    },
                    "traveller_folder": {
                        "expired_on": "By default, the end date is set in a week"
                    },
                    "auth": {
                        "register": {
                            "email": "This email address must not already be linked to a UgoZER account",
                            "employee": {
                                "not_duplicate_email": "The e-mail address must be different from that of the ",
                                "not_duplicate_password": "The password must be different from that of the "
                            }
                        }
                    },
                    "user": {
                        "bank_account_number": "Enter the account number that will be used to make the transfer"
                    }
                },
                "file": {
                    "current_file": "Actual file: {name}"
                }
            },
            "front": {
                "app": "Travellers app",
                "pro": "Professional access",
                "access_pro": "Go to the professional access",
                "brand_name": "UgoZER",
                "partners": {
                    "title": "Partners",
                    "no_records": "No partners at the moment",
                    "read_more": "Read more",
                    "see_partners": "See our partners"
                },
                "customers": {
                    "title": "They trust us",
                    "see_customers": "See our customers"
                },
                "header": {
                    "app": "Travellers App",
                    "pro": "Professional access",
                    "home": "Home",
                    "contact": "Contact",
                    "you_are": "You are",
                    "faq": "FAQ"
                },
                "footer": {
                    "contact": "Contact",
                    "home": "Home",
                    "app": "Travellers app",
                    "pro": "Professional access",
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "linkedin": "Linkedin",
                    "twitter": "Twitter",
                    "legal_notice": "Legal notice",
                    "sitemap": "Sitemap",
                    "copyright": "© {year} UgoZER - All rights reserved.",
                    "privacy_policy": "Privacy policy",
                    "terms_conditions": "Terms and conditions",
                    "cookies": "Cookies management",
                    "faq": "FAQ",
                    "account_deletion": "Account deletion"
                },
                "intro": {
                    "title_homepage": "The multilingual tourist guide, free.",
                    "intro_title_traveller": "TRAVELLER",
                    "intro_title_pro": "PROFESSIONALS",
                    "intro_1": "With UgoZER, always have in your smartphone your favorite addresses in multi-language files,\n            audible or readable, with a click.",
                    "intro_2": "When abroad, make it easy for cab drivers and other people understand your destination.",
                    "intro_3": "Receptionist, hotel manager, design and offer tour programs. Share them with your customers\n            on UgoZER."
                },
                "travellers": {
                    "intro": {
                        "main_title": "Your free multilingual tourist guide"
                    },
                    "argument_1": {
                        "part_1": "Access all points of interest (POI) updated in real time on your smartphone.",
                        "part_2": "POIs classified by <strong>categories: monuments, museums, exhibitions, shops, restaurants,\n                bars, temporary events...</strong>",
                        "part_3": "POIs supplied with:",
                        "arguments": {
                            "desc": "<strong>detailed description</strong>",
                            "map": "<strong>location on an interactive map, output on Google Maps™ possible</strong>",
                            "audio": "<strong>audio address</strong> in the local language to listen to a taxi or a passerby",
                            "languages": "All information is translated into <strong>French, English, German, Dutch, Portuguese,\n                    Spanish, Italian, Korean, Chinese, Japanese and Arabic.</strong>"
                        }
                    },
                    "argument_2": {
                        "part_1": "Enjoy your trip with permanent access to complete information. All on your smartphone,\n                right in your pocket."
                    },
                    "argument_3": {
                        "part_1": "With UgoZER, get automatically, free itineraries. You can also create the trips and share\n                them with your friends. Make the most of your journey by getting personalized tours from your local\n                expert : hotel concierge or receptionist, tourist information office.",
                        "part_2": "If you liked your trip, you can even thank your local expert with a digitalized and secured\n                tip option."
                    },
                    "outro": {
                        "catchphrase": "With UgoZER let yourself be guided safely and travel with peace of mind."
                    }
                },
                "startup": {
                    "title": "The startup UgoZER",
                    "description_1": "UgoZER is a French company, based in Strasbourg, capital of Europe. Created by travel\n            lovers, it facilitate your travels in countries where you do not speak the language.",
                    "description_2": "It is recommended by the tourist offices."
                },
                "outro": {
                    "description": "Travel in 2.0"
                },
                "contact": {
                    "contact": "Contact",
                    "send": "Send",
                    "cancel": "Cancel the support request"
                },
                "captcha": {
                    "protected": "This site is protected by hCaptcha and its",
                    "privacy_policy": "Privacy Policy",
                    "and": "and",
                    "terms_service": "Terms of Service",
                    "apply": "apply."
                },
                "you_are": {
                    "traveller": "Traveller",
                    "professional": "Professional"
                },
                "navigation": {
                    "previous": "Previous",
                    "next": "Next"
                },
                "support_request": "Support request",
                "cookies": {
                    "title": "Cookies management"
                },
                "professionals": {
                    "intro": {
                        "main_title": "UgoZER, the multilingual digital and personal travel assistant. Take your guest\n                experience to the next level and increase your revenue."
                    },
                    "argument_1": {
                        "part_1": "UgoZER provides a digital solution for your guests.",
                        "part_2": "Enhance their stay and their guest experience.",
                        "part_3": "It empowers your hotel, resort or information center to create personalized, easy to use,\n                multilingual mobile itineraries.",
                        "part_4": "Share customized trips from one real-time POIs database trough UgoZER professional platform\n                and its mobile app."
                    },
                    "argument_2": {
                        "part_1": "You can select for you guests prepackaged itineraries.",
                        "part_2": "Create yours with the best places or restaurants in town, hidden gems to discover, perfect\n                hikes trails to enjoy...",
                        "part_3": "Or choose each step of their visit within an exhaustive list of POIs to combine them at\n                will and then share a personalized itinerary."
                    },
                    "argument_3": {
                        "part_1": "Each POI possesses a detailed description, its position on an interactive map and its\n                address automatically translated in the local language.",
                        "part_2": "Your guest will never be lost. He will always find its way and easily come back to your\n                hotel."
                    },
                    "argument_4": {
                        "part_1": "At the end of his trip, after enjoying a tailored-made itinerary, your guest will be able to\n                thank your concierge or receptionist for his unique recommendation by giving him a tip.",
                        "part_2": "By using UgoZER, you will improve retention through increased revenue and decrease the staff\n                turnover.",
                        "part_3": "You will give them a greater motivation to get involved in the every day life of your hotel\n                and give them a sense of belonging."
                    },
                    "outro": {
                        "catchphrase": "UgoZER will help you to boost satisfaction and to drive growth."
                    }
                },
                "posts": {
                    "title": "F.A.Q.",
                    "no_records": "No posts at the moment"
                },
                "youtube": {
                    "directed_by": "Directed by",
                    "producer": "Suprahead studio"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "Choose your institution"
                    },
                    "sections": {
                        "validated_claims": "Claimed",
                        "pending_claims": "Pending validation",
                        "requests": "Pending requests"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "The request has been sent"
                },
                "cancel": {
                    "success": "The request has been canceled",
                    "confirm": {
                        "employer": "Do you really want to cancel {user_name} request?",
                        "employee": "Do you really want to cancel this request?"
                    }
                },
                "quit": {
                    "success": "You have quit the institution",
                    "confirm": "Do you really want to quit this institution {poi_name}?"
                },
                "decline": {
                    "success": "The request has been declined",
                    "confirm": {
                        "employer": "Do you really want to decline {user_name}?",
                        "employee": "Do you really want to decline the request?",
                        "change_institution": "Your linked institution will be changed!"
                    }
                },
                "approve": {
                    "success": "The request has been approved",
                    "confirm": {
                        "employer": "Do you really want to approve {user_name}?",
                        "employee": "Do you really want to approve the request?"
                    }
                },
                "remove": {
                    "success": "The employee has been removed",
                    "confirm": "Do you really want to remove {user_name}?"
                },
                "feature": {
                    "success": "The employee has been featured",
                    "confirm": "Do you really want to feature {user_name} ?",
                    "only_one": "Only one employee can be featured.",
                    "folder": "For a traveller who has had no interaction with one of your employees, it is the one featured\n           who will be displayed in the traveller's folder."
                },
                "states": {
                    "pending": "Pending",
                    "approved": "Approved",
                    "declined": "Declined",
                    "canceled": "Canceled",
                    "removed": "Removed",
                    "invited": "Invited"
                },
                "show": {
                    "pending": "Your request to join this institution is currently pending."
                },
                "index": {
                    "my_employees": "My employees"
                },
                "invite": {
                    "invite": "Invite an employee"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "Account",
                        "invitation": "Invitation"
                    }
                }
            },
            "interactions": {
                "description": {
                    "traveller_folder created": "Traveller folder has been created by {sender_name}",
                    "traveller_folder thanked": "{sender_name} has thanked traveller for his stay",
                    "contact created": "Contact request has been created by {sender_name}",
                    "contact approved": "Contact request has been approved by {sender_name}",
                    "contact declined": "Contact request has been declined by {sender_name}",
                    "contact blocked": "Contact request has been blocked by {sender_name}",
                    "share created": "{sender_name} has shared {share}",
                    "payment succeeded": "{sender_name} has sent a payment to {recipient_name}",
                    "invitation created": "Invitation has been sent by {sender_name}",
                    "invitation accepted": "Invitation has been accepted by {sender_name}"
                },
                "share_type": {
                    "pointsofinterest": "the point of interest",
                    "excursions": "the itinerary"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "Invite"
                },
                "texts": {
                    "title": "Invite someone",
                    "description": "You can invite someone to join our application with an email address or a phone number.",
                    "geoloc_settings": {
                        "title": "Geolocation settings",
                        "description": "Geolocation settings will be automatically set to your hotel coordinates \n                (if you are linked to an establishment)."
                    },
                    "new_traveller": "New traveller",
                    "already_have_account": "Does the user already have an account on the application?",
                    "employee_already_have_account": "Does the employee already have an account on the application?",
                    "invite_traveller": "Invite a traveler",
                    "invite_professional": "Invite a professional",
                    "invite_link": {
                        "title": "Invite by link",
                        "instructions": "Copy this link to invite a traveler"
                    },
                    "professional_description": "You can invite a professional to join the professional section of our \n            application with an email"
                },
                "states": {
                    "authenticated_once": {
                        "true": "Validated",
                        "false": "Pending"
                    }
                },
                "success": {
                    "professional": {
                        "title": "Invitation sent",
                        "sent_1": "An e-mail will be sent to {email}, if this address is not already in used.",
                        "sent_2": "This e-mail contains a link to register for the professional section."
                    },
                    "traveller": {
                        "title": "Invitation sent",
                        "email_1": "An e-mail will be sent to {email}, if this address is not already in use.",
                        "email_2": "This e-mail contains a link that will allow to connect to the application."
                    }
                }
            },
            "locales": {
                "ab": "Abkhazian",
                "aa": "Afar",
                "af": "Afrikaans",
                "af_NA": "Afrikaans (Namibia)",
                "af_ZA": "Afrikaans (South Africa)",
                "ak": "Akan",
                "ak_GH": "Akan (Ghana)",
                "sq": "Albanian",
                "sq_AL": "Albanian (Albania)",
                "sq_XK": "Albanian (Kosovo)",
                "sq_MK": "Albanian (Macedonia)",
                "am": "Amharic",
                "am_ET": "Amharic (Ethiopia)",
                "ar": "Arabic",
                "ar_DZ": "Arabic (Algeria)",
                "ar_BH": "Arabic (Bahrain)",
                "ar_TD": "Arabic (Chad)",
                "ar_KM": "Arabic (Comoros)",
                "ar_DJ": "Arabic (Djibouti)",
                "ar_EG": "Arabic (Egypt)",
                "ar_ER": "Arabic (Eritrea)",
                "ar_IQ": "Arabic (Iraq)",
                "ar_IL": "Arabic (Israel)",
                "ar_JO": "Arabic (Jordan)",
                "ar_KW": "Arabic (Kuwait)",
                "ar_LB": "Arabic (Lebanon)",
                "ar_LY": "Arabic (Libya)",
                "ar_MR": "Arabic (Mauritania)",
                "ar_MA": "Arabic (Morocco)",
                "ar_OM": "Arabic (Oman)",
                "ar_PS": "Arabic (Palestinian Territories)",
                "ar_QA": "Arabic (Qatar)",
                "ar_SA": "Arabic (Saudi Arabia)",
                "ar_SO": "Arabic (Somalia)",
                "ar_SS": "Arabic (South Sudan)",
                "ar_SD": "Arabic (Sudan)",
                "ar_SY": "Arabic (Syria)",
                "ar_TN": "Arabic (Tunisia)",
                "ar_AE": "Arabic (United Arab Emirates)",
                "ar_EH": "Arabic (Western Sahara)",
                "ar_YE": "Arabic (Yemen)",
                "an": "Aragonese",
                "hy": "Armenian",
                "hy_AM": "Armenian (Armenia)",
                "as": "Assamese",
                "as_IN": "Assamese (India)",
                "av": "Avaric",
                "ay": "Aymara",
                "az": "Azerbaijani",
                "az_AZ": "Azerbaijani (Azerbaijan)",
                "az_Cyrl_AZ": "Azerbaijani (Cyrillic, Azerbaijan)",
                "az_Cyrl": "Azerbaijani (Cyrillic)",
                "az_Latn_AZ": "Azerbaijani (Latin, Azerbaijan)",
                "az_Latn": "Azerbaijani (Latin)",
                "bm": "Bambara",
                "bm_Latn_ML": "Bambara (Latin, Mali)",
                "bm_Latn": "Bambara (Latin)",
                "ba": "Bashkir",
                "eu": "Basque",
                "eu_ES": "Basque (Spain)",
                "be": "Belarusian",
                "be_BY": "Belarusian (Belarus)",
                "bn": "Bengali",
                "bn_BD": "Bengali (Bangladesh)",
                "bn_IN": "Bengali (India)",
                "bh": "Bihari (languages)",
                "bi": "Bislama",
                "bs": "Bosnian",
                "bs_BA": "Bosnian (Bosnia & Herzegovina)",
                "bs_Cyrl_BA": "Bosnian (Cyrillic, Bosnia & Herzegovina)",
                "bs_Cyrl": "Bosnian (Cyrillic)",
                "bs_Latn_BA": "Bosnian (Latin, Bosnia & Herzegovina)",
                "bs_Latn": "Bosnian (Latin)",
                "br": "Breton",
                "br_FR": "Breton (France)",
                "bg": "Bulgarian",
                "bg_BG": "Bulgarian (Bulgaria)",
                "my": "Burmese",
                "my_MM": "Burmese (Myanmar (Burma))",
                "ca": "Catalan",
                "ca_AD": "Catalan (Andorra)",
                "ca_FR": "Catalan (France)",
                "ca_IT": "Catalan (Italy)",
                "ca_ES": "Catalan (Spain)",
                "ch": "Chamorro",
                "ce": "Chechen",
                "ny": "Chichewa",
                "zh": "Chinese",
                "zh_CN": "Chinese (China)",
                "zh_HK": "Chinese (Hong Kong SAR China)",
                "zh_MO": "Chinese (Macau SAR China)",
                "zh_Hans_CN": "Chinese (Simplified, China)",
                "zh_Hans_HK": "Chinese (Simplified, Hong Kong SAR China)",
                "zh_Hans_MO": "Chinese (Simplified, Macau SAR China)",
                "zh_Hans_SG": "Chinese (Simplified, Singapore)",
                "zh_Hans": "Chinese (Simplified)",
                "zh_SG": "Chinese (Singapore)",
                "zh_TW": "Chinese (Taiwan)",
                "zh_Hant_HK": "Chinese (Traditional, Hong Kong SAR China)",
                "zh_Hant_MO": "Chinese (Traditional, Macau SAR China)",
                "zh_Hant_TW": "Chinese (Traditional, Taiwan)",
                "zh_Hant": "Chinese (Traditional)",
                "cv": "Chuvash",
                "kw": "Cornish",
                "kw_GB": "Cornish (United Kingdom)",
                "co": "Corsican",
                "cr": "Cree",
                "hr": "Croatian",
                "hr_BA": "Croatian (Bosnia & Herzegovina)",
                "hr_HR": "Croatian (Croatia)",
                "cs": "Czech",
                "cs_CZ": "Czech (Czech Republic)",
                "da": "Danish",
                "da_DK": "Danish (Denmark)",
                "da_GL": "Danish (Greenland)",
                "nl": "Dutch",
                "nl_AW": "Dutch (Aruba)",
                "nl_BE": "Dutch (Belgium)",
                "nl_BQ": "Dutch (Caribbean Netherlands)",
                "nl_CW": "Dutch (Curaçao)",
                "nl_NL": "Dutch (Netherlands)",
                "nl_SX": "Dutch (Sint Maarten)",
                "nl_SR": "Dutch (Suriname)",
                "dz": "Dzongkha",
                "dz_BT": "Dzongkha (Bhutan)",
                "en": "English",
                "en_AS": "English (American Samoa)",
                "en_AI": "English (Anguilla)",
                "en_AG": "English (Antigua & Barbuda)",
                "en_AU": "English (Australia)",
                "en_BS": "English (Bahamas)",
                "en_BB": "English (Barbados)",
                "en_BE": "English (Belgium)",
                "en_BZ": "English (Belize)",
                "en_BM": "English (Bermuda)",
                "en_BW": "English (Botswana)",
                "en_IO": "English (British Indian Ocean Territory)",
                "en_VG": "English (British Virgin Islands)",
                "en_CM": "English (Cameroon)",
                "en_CA": "English (Canada)",
                "en_KY": "English (Cayman Islands)",
                "en_CX": "English (Christmas Island)",
                "en_CC": "English (Cocos (Keeling) Islands)",
                "en_CK": "English (Cook Islands)",
                "en_DG": "English (Diego Garcia)",
                "en_DM": "English (Dominica)",
                "en_ER": "English (Eritrea)",
                "en_FK": "English (Falkland Islands)",
                "en_FJ": "English (Fiji)",
                "en_GM": "English (Gambia)",
                "en_GH": "English (Ghana)",
                "en_GI": "English (Gibraltar)",
                "en_GD": "English (Grenada)",
                "en_GU": "English (Guam)",
                "en_GG": "English (Guernsey)",
                "en_GY": "English (Guyana)",
                "en_HK": "English (Hong Kong SAR China)",
                "en_IN": "English (India)",
                "en_IE": "English (Ireland)",
                "en_IM": "English (Isle of Man)",
                "en_JM": "English (Jamaica)",
                "en_JE": "English (Jersey)",
                "en_KE": "English (Kenya)",
                "en_KI": "English (Kiribati)",
                "en_LS": "English (Lesotho)",
                "en_LR": "English (Liberia)",
                "en_MO": "English (Macau SAR China)",
                "en_MG": "English (Madagascar)",
                "en_MW": "English (Malawi)",
                "en_MY": "English (Malaysia)",
                "en_MT": "English (Malta)",
                "en_MH": "English (Marshall Islands)",
                "en_MU": "English (Mauritius)",
                "en_FM": "English (Micronesia)",
                "en_MS": "English (Montserrat)",
                "en_NA": "English (Namibia)",
                "en_NR": "English (Nauru)",
                "en_NZ": "English (New Zealand)",
                "en_NG": "English (Nigeria)",
                "en_NU": "English (Niue)",
                "en_NF": "English (Norfolk Island)",
                "en_MP": "English (Northern Mariana Islands)",
                "en_PK": "English (Pakistan)",
                "en_PW": "English (Palau)",
                "en_PG": "English (Papua New Guinea)",
                "en_PH": "English (Philippines)",
                "en_PN": "English (Pitcairn Islands)",
                "en_PR": "English (Puerto Rico)",
                "en_RW": "English (Rwanda)",
                "en_WS": "English (Samoa)",
                "en_SC": "English (Seychelles)",
                "en_SL": "English (Sierra Leone)",
                "en_SG": "English (Singapore)",
                "en_SX": "English (Sint Maarten)",
                "en_SB": "English (Solomon Islands)",
                "en_ZA": "English (South Africa)",
                "en_SS": "English (South Sudan)",
                "en_SH": "English (St. Helena)",
                "en_KN": "English (St. Kitts & Nevis)",
                "en_LC": "English (St. Lucia)",
                "en_VC": "English (St. Vincent & Grenadines)",
                "en_SD": "English (Sudan)",
                "en_SZ": "English (Swaziland)",
                "en_TZ": "English (Tanzania)",
                "en_TK": "English (Tokelau)",
                "en_TO": "English (Tonga)",
                "en_TT": "English (Trinidad & Tobago)",
                "en_TC": "English (Turks & Caicos Islands)",
                "en_TV": "English (Tuvalu)",
                "en_UM": "English (U.S. Outlying Islands)",
                "en_VI": "English (U.S. Virgin Islands)",
                "en_UG": "English (Uganda)",
                "en_GB": "English (United Kingdom)",
                "en_US": "English (United States)",
                "en_VU": "English (Vanuatu)",
                "en_ZM": "English (Zambia)",
                "en_ZW": "English (Zimbabwe)",
                "eo": "Esperanto",
                "et": "Estonian",
                "et_EE": "Estonian (Estonia)",
                "ee": "Ewe",
                "ee_GH": "Ewe (Ghana)",
                "ee_TG": "Ewe (Togo)",
                "fo": "Faroese",
                "fo_FO": "Faroese (Faroe Islands)",
                "fj": "Fijian",
                "fi": "Finnish",
                "fi_FI": "Finnish (Finland)",
                "fr": "French",
                "fr_DZ": "French (Algeria)",
                "fr_BE": "French (Belgium)",
                "fr_BJ": "French (Benin)",
                "fr_BF": "French (Burkina Faso)",
                "fr_BI": "French (Burundi)",
                "fr_CM": "French (Cameroon)",
                "fr_CA": "French (Canada)",
                "fr_CF": "French (Central African Republic)",
                "fr_TD": "French (Chad)",
                "fr_KM": "French (Comoros)",
                "fr_CG": "French (Congo - Brazzaville)",
                "fr_CD": "French (Congo - Kinshasa)",
                "fr_CI": "French (Côte d’Ivoire)",
                "fr_DJ": "French (Djibouti)",
                "fr_GQ": "French (Equatorial Guinea)",
                "fr_FR": "French (France)",
                "fr_GF": "French (French Guiana)",
                "fr_PF": "French (French Polynesia)",
                "fr_GA": "French (Gabon)",
                "fr_GP": "French (Guadeloupe)",
                "fr_GN": "French (Guinea)",
                "fr_HT": "French (Haiti)",
                "fr_LU": "French (Luxembourg)",
                "fr_MG": "French (Madagascar)",
                "fr_ML": "French (Mali)",
                "fr_MQ": "French (Martinique)",
                "fr_MR": "French (Mauritania)",
                "fr_MU": "French (Mauritius)",
                "fr_YT": "French (Mayotte)",
                "fr_MC": "French (Monaco)",
                "fr_MA": "French (Morocco)",
                "fr_NC": "French (New Caledonia)",
                "fr_NE": "French (Niger)",
                "fr_RE": "French (Réunion)",
                "fr_RW": "French (Rwanda)",
                "fr_SN": "French (Senegal)",
                "fr_SC": "French (Seychelles)",
                "fr_BL": "French (St. Barthélemy)",
                "fr_MF": "French (St. Martin)",
                "fr_PM": "French (St. Pierre & Miquelon)",
                "fr_CH": "French (Switzerland)",
                "fr_SY": "French (Syria)",
                "fr_TG": "French (Togo)",
                "fr_TN": "French (Tunisia)",
                "fr_VU": "French (Vanuatu)",
                "fr_WF": "French (Wallis & Futuna)",
                "ff": "Fulah",
                "ff_CM": "Fulah (Cameroon)",
                "ff_GN": "Fulah (Guinea)",
                "ff_MR": "Fulah (Mauritania)",
                "ff_SN": "Fulah (Senegal)",
                "gl": "Galician",
                "gl_ES": "Galician (Spain)",
                "lg": "Ganda",
                "lg_UG": "Ganda (Uganda)",
                "ka": "Georgian",
                "ka_GE": "Georgian (Georgia)",
                "de": "German",
                "de_AT": "German (Austria)",
                "de_BE": "German (Belgium)",
                "de_DE": "German (Germany)",
                "de_LI": "German (Liechtenstein)",
                "de_LU": "German (Luxembourg)",
                "de_CH": "German (Switzerland)",
                "el": "Greek",
                "el_CY": "Greek (Cyprus)",
                "el_GR": "Greek (Greece)",
                "gn": "Guarani",
                "gu": "Gujarati",
                "gu_IN": "Gujarati (India)",
                "ht": "Haitian",
                "ha": "Hausa",
                "ha_GH": "Hausa (Ghana)",
                "ha_Latn_GH": "Hausa (Latin, Ghana)",
                "ha_Latn_NE": "Hausa (Latin, Niger)",
                "ha_Latn_NG": "Hausa (Latin, Nigeria)",
                "ha_Latn": "Hausa (Latin)",
                "ha_NE": "Hausa (Niger)",
                "ha_NG": "Hausa (Nigeria)",
                "he": "Hebrew",
                "he_IL": "Hebrew (Israel)",
                "hz": "Herero",
                "hi": "Hindi",
                "hi_IN": "Hindi (India)",
                "ho": "Hiri Motu",
                "hu": "Hungarian",
                "hu_HU": "Hungarian (Hungary)",
                "is": "Icelandic",
                "is_IS": "Icelandic (Iceland)",
                "io": "Ido",
                "ig": "Igbo",
                "ig_NG": "Igbo (Nigeria)",
                "id": "Indonesian",
                "id_ID": "Indonesian (Indonesia)",
                "ia": "Interlingua",
                "ie": "Interlingue",
                "iu": "Inuktitut",
                "ik": "Inupiaq",
                "ga": "Irish",
                "ga_IE": "Irish (Ireland)",
                "it": "Italian",
                "it_IT": "Italian (Italy)",
                "it_SM": "Italian (San Marino)",
                "it_CH": "Italian (Switzerland)",
                "ja": "Japanese",
                "ja_JP": "Japanese (Japan)",
                "jv": "Javanese",
                "kl": "Kalaallisut",
                "kl_GL": "Kalaallisut (Greenland)",
                "kn": "Kannada",
                "kn_IN": "Kannada (India)",
                "kr": "Kanuri",
                "ks": "Kashmiri",
                "ks_Arab_IN": "Kashmiri (Arabic, India)",
                "ks_Arab": "Kashmiri (Arabic)",
                "ks_IN": "Kashmiri (India)",
                "kk": "Kazakh",
                "kk_Cyrl_KZ": "Kazakh (Cyrillic, Kazakhstan)",
                "kk_Cyrl": "Kazakh (Cyrillic)",
                "kk_KZ": "Kazakh (Kazakhstan)",
                "km": "Khmer",
                "km_KH": "Khmer (Cambodia)",
                "ki": "Kikuyu",
                "ki_KE": "Kikuyu (Kenya)",
                "rw": "Kinyarwanda",
                "rw_RW": "Kinyarwanda (Rwanda)",
                "kv": "Komi",
                "kg": "Kongo",
                "ko": "Korean",
                "ko_KP": "Korean (North Korea)",
                "ko_KR": "Korean (South Korea)",
                "ku": "Kurdish",
                "kj": "Kwanyama",
                "ky": "Kyrgyz",
                "ky_Cyrl_KG": "Kyrgyz (Cyrillic, Kyrgyzstan)",
                "ky_Cyrl": "Kyrgyz (Cyrillic)",
                "ky_KG": "Kyrgyz (Kyrgyzstan)",
                "lo": "Lao",
                "lo_LA": "Lao (Laos)",
                "la": "Latin",
                "lv": "Latvian",
                "lv_LV": "Latvian (Latvia)",
                "li": "Limburgan",
                "ln": "Lingala",
                "ln_AO": "Lingala (Angola)",
                "ln_CF": "Lingala (Central African Republic)",
                "ln_CG": "Lingala (Congo - Brazzaville)",
                "ln_CD": "Lingala (Congo - Kinshasa)",
                "lt": "Lithuanian",
                "lt_LT": "Lithuanian (Lithuania)",
                "lu": "Luba-Katanga",
                "lu_CD": "Luba-Katanga (Congo - Kinshasa)",
                "lb": "Luxembourgish",
                "lb_LU": "Luxembourgish (Luxembourg)",
                "mo": "Macao",
                "mk": "Macedonian",
                "mk_MK": "Macedonian (Macedonia)",
                "mg": "Malagasy",
                "mg_MG": "Malagasy (Madagascar)",
                "ms": "Malay",
                "ms_BN": "Malay (Brunei)",
                "ms_Latn_BN": "Malay (Latin, Brunei)",
                "ms_Latn_MY": "Malay (Latin, Malaysia)",
                "ms_Latn_SG": "Malay (Latin, Singapore)",
                "ms_Latn": "Malay (Latin)",
                "ms_MY": "Malay (Malaysia)",
                "ms_SG": "Malay (Singapore)",
                "ml": "Malayalam",
                "ml_IN": "Malayalam (India)",
                "dv": "Maldivian",
                "mt": "Maltese",
                "mt_MT": "Maltese (Malta)",
                "gv": "Manx",
                "gv_IM": "Manx (Isle of Man)",
                "mi": "Maori",
                "mr": "Marathi",
                "mr_IN": "Marathi (India)",
                "mh": "Marshallese",
                "mn": "Mongolian",
                "mn_Cyrl_MN": "Mongolian (Cyrillic, Mongolia)",
                "mn_Cyrl": "Mongolian (Cyrillic)",
                "mn_MN": "Mongolian (Mongolia)",
                "na": "Nauru",
                "nv": "Navaho",
                "ng": "Ndonga",
                "ne": "Nepali",
                "ne_IN": "Nepali (India)",
                "ne_NP": "Nepali (Nepal)",
                "nd": "North Ndebele",
                "nd_ZW": "North Ndebele (Zimbabwe)",
                "se": "Northern Sami",
                "se_FI": "Northern Sami (Finland)",
                "se_NO": "Northern Sami (Norway)",
                "se_SE": "Northern Sami (Sweden)",
                "no": "Norwegian",
                "no_NO": "Norwegian (Norway)",
                "nb": "Norwegian Bokmål",
                "nb_NO": "Norwegian Bokmål (Norway)",
                "nb_SJ": "Norwegian Bokmål (Svalbard & Jan Mayen)",
                "nn": "Norwegian Nynorsk",
                "nn_NO": "Norwegian Nynorsk (Norway)",
                "oc": "Occitan",
                "oj": "Ojibwa",
                "cu": "Old Bulgarian",
                "or": "Oriya",
                "or_IN": "Oriya (India)",
                "om": "Oromo",
                "om_ET": "Oromo (Ethiopia)",
                "om_KE": "Oromo (Kenya)",
                "os": "Ossetic",
                "os_GE": "Ossetic (Georgia)",
                "os_RU": "Ossetic (Russia)",
                "pi": "Pali",
                "ps": "Pashto",
                "ps_AF": "Pashto (Afghanistan)",
                "fa": "Persian",
                "fa_AF": "Persian (Afghanistan)",
                "fa_IR": "Persian (Iran)",
                "pl": "Polish",
                "pl_PL": "Polish (Poland)",
                "pt": "Portuguese",
                "pt_AO": "Portuguese (Angola)",
                "pt_BR": "Portuguese (Brazil)",
                "pt_CV": "Portuguese (Cape Verde)",
                "pt_GW": "Portuguese (Guinea-Bissau)",
                "pt_MO": "Portuguese (Macau SAR China)",
                "pt_MZ": "Portuguese (Mozambique)",
                "pt_PT": "Portuguese (Portugal)",
                "pt_ST": "Portuguese (São Tomé & Príncipe)",
                "pt_TL": "Portuguese (Timor-Leste)",
                "pa": "Punjabi",
                "pa_Arab_PK": "Punjabi (Arabic, Pakistan)",
                "pa_Arab": "Punjabi (Arabic)",
                "pa_Guru_IN": "Punjabi (Gurmukhi, India)",
                "pa_Guru": "Punjabi (Gurmukhi)",
                "pa_IN": "Punjabi (India)",
                "pa_PK": "Punjabi (Pakistan)",
                "qu": "Quechua",
                "qu_BO": "Quechua (Bolivia)",
                "qu_EC": "Quechua (Ecuador)",
                "qu_PE": "Quechua (Peru)",
                "ro": "Romanian",
                "ro_MD": "Romanian (Moldova)",
                "ro_RO": "Romanian (Romania)",
                "rm": "Romansh",
                "rm_CH": "Romansh (Switzerland)",
                "rn": "Rundi",
                "rn_BI": "Rundi (Burundi)",
                "ru": "Russian",
                "ru_BY": "Russian (Belarus)",
                "ru_KZ": "Russian (Kazakhstan)",
                "ru_KG": "Russian (Kyrgyzstan)",
                "ru_MD": "Russian (Moldova)",
                "ru_RU": "Russian (Russia)",
                "ru_UA": "Russian (Ukraine)",
                "sm": "Samoan",
                "sg": "Sango",
                "sg_CF": "Sango (Central African Republic)",
                "sa": "Sanskrit",
                "sc": "Sardinian",
                "gd": "Scottish Gaelic",
                "gd_GB": "Scottish Gaelic (United Kingdom)",
                "sr": "Serbian",
                "sr_BA": "Serbian (Bosnia & Herzegovina)",
                "sr_Cyrl_BA": "Serbian (Cyrillic, Bosnia & Herzegovina)",
                "sr_Cyrl_XK": "Serbian (Cyrillic, Kosovo)",
                "sr_Cyrl_ME": "Serbian (Cyrillic, Montenegro)",
                "sr_Cyrl_RS": "Serbian (Cyrillic, Serbia)",
                "sr_Cyrl": "Serbian (Cyrillic)",
                "sr_XK": "Serbian (Kosovo)",
                "sr_Latn_BA": "Serbian (Latin, Bosnia & Herzegovina)",
                "sr_Latn_XK": "Serbian (Latin, Kosovo)",
                "sr_Latn_ME": "Serbian (Latin, Montenegro)",
                "sr_Latn_RS": "Serbian (Latin, Serbia)",
                "sr_Latn": "Serbian (Latin)",
                "sr_ME": "Serbian (Montenegro)",
                "sr_RS": "Serbian (Serbia)",
                "sh": "Serbo-Croatian",
                "sh_BA": "Serbo-Croatian (Bosnia & Herzegovina)",
                "sn": "Shona",
                "sn_ZW": "Shona (Zimbabwe)",
                "ii": "Sichuan Yi",
                "ii_CN": "Sichuan Yi (China)",
                "sd": "Sindhi",
                "si": "Sinhala",
                "si_LK": "Sinhala (Sri Lanka)",
                "sk": "Slovak",
                "sk_SK": "Slovak (Slovakia)",
                "sl": "Slovenian",
                "sl_SI": "Slovenian (Slovenia)",
                "so": "Somali",
                "so_DJ": "Somali (Djibouti)",
                "so_ET": "Somali (Ethiopia)",
                "so_KE": "Somali (Kenya)",
                "so_SO": "Somali (Somalia)",
                "st": "Sotho, Southern",
                "nr": "South Ndebele",
                "es": "Spanish",
                "es_AR": "Spanish (Argentina)",
                "es_BO": "Spanish (Bolivia)",
                "es_IC": "Spanish (Canary Islands)",
                "es_EA": "Spanish (Ceuta & Melilla)",
                "es_CL": "Spanish (Chile)",
                "es_CO": "Spanish (Colombia)",
                "es_CR": "Spanish (Costa Rica)",
                "es_CU": "Spanish (Cuba)",
                "es_DO": "Spanish (Dominican Republic)",
                "es_EC": "Spanish (Ecuador)",
                "es_SV": "Spanish (El Salvador)",
                "es_GQ": "Spanish (Equatorial Guinea)",
                "es_GT": "Spanish (Guatemala)",
                "es_HN": "Spanish (Honduras)",
                "es_MX": "Spanish (Mexico)",
                "es_NI": "Spanish (Nicaragua)",
                "es_PA": "Spanish (Panama)",
                "es_PY": "Spanish (Paraguay)",
                "es_PE": "Spanish (Peru)",
                "es_PH": "Spanish (Philippines)",
                "es_PR": "Spanish (Puerto Rico)",
                "es_ES": "Spanish (Spain)",
                "es_US": "Spanish (United States)",
                "es_UY": "Spanish (Uruguay)",
                "es_VE": "Spanish (Venezuela)",
                "su": "Sundanese",
                "sw": "Swahili",
                "sw_KE": "Swahili (Kenya)",
                "sw_TZ": "Swahili (Tanzania)",
                "sw_UG": "Swahili (Uganda)",
                "ss": "Swati",
                "sv": "Swedish",
                "sv_AX": "Swedish (Åland Islands)",
                "sv_FI": "Swedish (Finland)",
                "sv_SE": "Swedish (Sweden)",
                "tl": "Tagalog",
                "tl_PH": "Tagalog (Philippines)",
                "ty": "Tahitian",
                "tg": "Tajik",
                "ta": "Tamil",
                "ta_IN": "Tamil (India)",
                "ta_MY": "Tamil (Malaysia)",
                "ta_SG": "Tamil (Singapore)",
                "ta_LK": "Tamil (Sri Lanka)",
                "tt": "Tatar",
                "te": "Telugu",
                "te_IN": "Telugu (India)",
                "th": "Thai",
                "th_TH": "Thai (Thailand)",
                "bo": "Tibetan",
                "bo_CN": "Tibetan (China)",
                "bo_IN": "Tibetan (India)",
                "ti": "Tigrinya",
                "ti_ER": "Tigrinya (Eritrea)",
                "ti_ET": "Tigrinya (Ethiopia)",
                "to": "Tongan",
                "to_TO": "Tongan (Tonga)",
                "tn": "Tswana",
                "ts": "Tsonga",
                "tr": "Turkish",
                "tr_CY": "Turkish (Cyprus)",
                "tr_TR": "Turkish (Turkey)",
                "tk": "Turkmen",
                "tw": "Twi",
                "uk": "Ukrainian",
                "uk_UA": "Ukrainian (Ukraine)",
                "ur": "Urdu",
                "ur_IN": "Urdu (India)",
                "ur_PK": "Urdu (Pakistan)",
                "ug": "Uyghur",
                "ug_Arab_CN": "Uyghur (Arabic, China)",
                "ug_Arab": "Uyghur (Arabic)",
                "ug_CN": "Uyghur (China)",
                "uz": "Uzbek",
                "uz_AF": "Uzbek (Afghanistan)",
                "uz_Arab_AF": "Uzbek (Arabic, Afghanistan)",
                "uz_Arab": "Uzbek (Arabic)",
                "uz_Cyrl_UZ": "Uzbek (Cyrillic, Uzbekistan)",
                "uz_Cyrl": "Uzbek (Cyrillic)",
                "uz_Latn_UZ": "Uzbek (Latin, Uzbekistan)",
                "uz_Latn": "Uzbek (Latin)",
                "uz_UZ": "Uzbek (Uzbekistan)",
                "ve": "Venda",
                "vi": "Vietnamese",
                "vi_VN": "Vietnamese (Vietnam)",
                "vo": "Volapük",
                "wa": "Walloon",
                "cy": "Welsh",
                "cy_GB": "Welsh (United Kingdom)",
                "fy": "Western Frisian",
                "fy_NL": "Western Frisian (Netherlands)",
                "wo": "Wolof",
                "xh": "Xhosa",
                "yi": "Yiddish",
                "yo": "Yoruba",
                "yo_BJ": "Yoruba (Benin)",
                "yo_NG": "Yoruba (Nigeria)",
                "za": "Zhuang",
                "zu": "Zulu",
                "zu_ZA": "Zulu (South Africa)"
            },
            "mail": {
                "copyright": "© {year} UgoZER - All rights reserved.",
                "hello": "Hello!",
                "action": "If you’re having trouble clicking the \"{actionText}\" button, copy and paste the URL below into your web browser:",
                "salutations": "Regards",
                "auth": {
                    "deleted": {
                        "subject": "Deletion of your UgoZER account",
                        "line1": "We inform you that your UgoZER account has been deleted.",
                        "reason": {
                            "intro": "This one has been deleted for the following reason:",
                            "never_authenticated_for_too_long": "Your account was created more than a week ago\n                    and you never logged in:\n                    so this account is considered inactive.",
                            "not_logged_for_too_long": "You have not logged in for more than 2 years:\n                    your account is considered inactive."
                        }
                    },
                    "not_logged": {
                        "subject": "Your UgoZER account will be deleted soon",
                        "intro": "It's been almost 2 years since you last logged into your UgoZER account.",
                        "remaining": {
                            "two_days": "You have 2 days left before your account is deleted.",
                            "two_weeks": "You have 2 weeks left before your account is deleted.",
                            "two_months": "You have 2 months left before your account is deleted."
                        },
                        "outro": "Simply log in to your account so that it is not deleted."
                    },
                    "login_magic_link": {
                        "action": "Log in",
                        "line1": "Click this link to log in",
                        "line2": "Thank you for using our application!",
                        "subject": "Passwordless login Link"
                    },
                    "reset_password": {
                        "subject": "Reset Password Notification",
                        "action": "Reset password",
                        "line1": "You are receiving this email because we received a password reset request for your account.",
                        "line2": "This password reset link will expire in {count} minutes.",
                        "line3": "If you did not request a password reset, no further action is required."
                    },
                    "registration": {
                        "subject": "Verify your email address",
                        "line1": "Please click the button below to verify your email address and be able to log in.",
                        "line2": "This link will expire in 1 week.",
                        "line3": "If you did not create an account, no further action is required.",
                        "action": "Verify Email Address"
                    },
                    "login_attempt_unverified_credential": {
                        "subject": "Attempt to log into your UgoZER account",
                        "line1": "There was an attempt to log in to your UgoZER account with the email address {credential}.",
                        "line2": "If you initiated this login attempt,\n                please click on the button below to verify your email address\n                and be able to connect.",
                        "line3": "This link will expire in 1 week.",
                        "line4": "If you did not initiate this login attempt, no further action is required.",
                        "action": "Verify Email Address"
                    },
                    "registration_attempt_unverified_credential": {
                        "subject": "Attempt to register with your UgoZER credential",
                        "line1": "The <strong>{credential}</strong> email is already used for a <strong>{role_type}</strong> account in our application.",
                        "line2": "To create an account, please start registration again with another email."
                    },
                    "registration_attempt": {
                        "subject": "Attempt to register with your UgoZER credential",
                        "line1": "The <strong>{credential}</strong> email is already used for a <strong>{role_type}</strong> account in our application.",
                        "line2": "To create an account, please start registration again with another email."
                    }
                },
                "credential_verification": {
                    "verify": {
                        "subject": "Confirmation of your e-mail",
                        "line1": "Please click on the button below to verify your email address.",
                        "line2": "This link will expire in 1 week.",
                        "line3": "If you did not request verification, no further action is required.",
                        "action": "Verify Email Address"
                    }
                },
                "invitation": {
                    "verification": {
                        "action": "Verify Email Address",
                        "line1_by": "You've been invited by {name} to join our application",
                        "line1_by_from": "You've been invited by {name} from {poi_title} institution to join our application",
                        "line1": "You've been invited to join our application",
                        "line2": "Please click on the button below to verify your email address.",
                        "line3": "This link will expire in 1 week.",
                        "line4": "Thank you for using our application!",
                        "excursion_shared": "An itinerary has been shared with you. You can find it attached in PDF format,\n                    as well as in the application in your shares.",
                        "point_of_interest_shared": "A point of interest has been shared with you. You can find it\n                in the application in your shares.",
                        "subject": "Invitation to join UgoZER"
                    },
                    "attempt": {
                        "action": "Access to the application",
                        "line1_by": "You've been invited by {name} to join our application",
                        "line1_by_from": "You've been invited by {name} from {poi_title} institution to join our application",
                        "line1": "You've been invited to join our application",
                        "line2": "You already have an existing account in our application",
                        "line3": "Please click on the button below to access the application.",
                        "subject": "Invitation to join UgoZER"
                    }
                },
                "subscription": {
                    "switched_from_paid_to_free": {
                        "subject": "Subscriber switched to the free plan",
                        "line1": "{name} switched to the free plan",
                        "line2": "Please click on the button below to access the detail",
                        "cta": "See detail"
                    },
                    "not_continue_after_free_period": {
                        "subject": "Subscriber did not continue after free period ended",
                        "line1": "{name} did not continue after free period ended",
                        "line2": "Please click on the button below to access the detail",
                        "cta": "See detail"
                    },
                    "suspended": {
                        "subject": "Subscription suspended",
                        "line": "Your subscription has been suspended for the following reason: {suspension_reason}.",
                        "line_alt": "Your subscription has been suspended."
                    }
                },
                "share": {
                    "created": {
                        "action": "Show",
                        "subject": "An item has been shared with you.",
                        "line": "{username} from {poi_title} institution shared an item with you.",
                        "line_alt": "{username} shared an item with you.",
                        "line_alt_2": "An item has been shared with you.",
                        "excursion": {
                            "subject": "An itinerary has been shared with you.",
                            "line": "{username} from {poi_title} institution shared an itinerary with you.",
                            "gotoapp": "Go to the app to view it.",
                            "line_alt": "{username} shared an itinerary with you.",
                            "line_alt_2": "An itinerary has been shared with you."
                        },
                        "point_of_interest": {
                            "subject": "A point of interest has been shared with you.",
                            "line": "{username} from {poi_title} institution shared a point of interest with you.",
                            "line_alt": "{username} shared a point of interest with you.",
                            "line_alt_2": "A point of interest has been shared with you."
                        }
                    }
                },
                "invitation_professional": {
                    "subject": "UgoZER - Invitation professional access",
                    "title": "Invitation to UgoZER application's professional section",
                    "content": "You've been invited to join professional section of our application UgoZER",
                    "cta": "Register to professional section",
                    "thanks": "Thanks,"
                },
                "user_send_link": {
                    "subject": "UgoZER - Application",
                    "title": "You have an account on our application, log in!",
                    "content": "Reconnect you to our application UgoZER! If you don't remember your password, you can reset it.",
                    "cta": "Log in application",
                    "thanks": "Thanks,"
                },
                "support_request": {
                    "request": {
                        "action": "Access to the support request",
                        "subject": "UgoZER - Your support request has been created",
                        "line1": "Contact support will answer within 48 hours.",
                        "line2": "Please click on the button below to access your request."
                    },
                    "message": {
                        "action": "Access to the support request",
                        "subject": "UgoZER - You have an answer",
                        "title": "An answer has been post on your support request",
                        "line1": "Please click on the button below to access your request."
                    },
                    "archived": {
                        "subject": "UgoZER - Support request archived",
                        "title": " Your support request has been archived"
                    }
                },
                "preview": {
                    "main_title": "Email preview",
                    "from": "From: ",
                    "sections": {
                        "auth": "Authentication",
                        "invitation": "Invitations",
                        "share": "Shares",
                        "support-message": "Support messages",
                        "support-request": "Support requests",
                        "user-credential-verification": "User credential",
                        "user": "User"
                    },
                    "mails": {
                        "auth/account-deleted-notification": "Account deletion",
                        "auth/not-logged-notification": "Delete if not connected",
                        "auth/registration-attempt-notification": "Attempted registration",
                        "auth/registration-notification": "Registration",
                        "auth/reset-password-notification": "Change your password",
                        "invitation/invited-notification": "Invitation",
                        "share/created-notification": "New",
                        "support-message/message-notification": "New answer",
                        "support-request/archived-notification": "Archived request",
                        "support-request/created-notification": "Created request",
                        "user-credential-verification/login-attempt-notification": "Attempt to connect",
                        "user-credential-verification/registration-attempt-notification": "Attempted registration",
                        "user-credential-verification/verify-notification": "Account confirmation",
                        "user/welcome-notification": "Welcome"
                    },
                    "default": {
                        "subject": "E-mail subject",
                        "user": {
                            "name": "UgoZER"
                        }
                    }
                },
                "welcome": {
                    "subject": "Welcome to UgoZER",
                    "title": "Welcome to UgoZER, your free multi-language travel assistance app.",
                    "line1": "We are happy to count you among our users.",
                    "line2": "Click on the button below to access the application.",
                    "action": "Access to the application"
                }
            },
            "media": {
                "default": {
                    "caption": "Click on map to show this on Google Maps",
                    "alt": "Google Maps' static map"
                },
                "audio": {
                    "recording": {
                        "errors": {
                            "default": "Recording failed",
                            "not_allowed": "Recording failed. Allow your browser and UgoZER to use your microphone and try again."
                        }
                    },
                    "generated_audio": "Generated audio"
                },
                "image": {
                    "credits": "Credits: {credits}"
                }
            },
            "models": {
                "excursion": "Itinerary|Itineraries",
                "point_of_interest": "Point of interest|Points of interest",
                "category": "Category|Categories",
                "medium": "Medium|Media",
                "user": "User|Users",
                "activity_log": "Activity log|Activity logs",
                "permission": "Permission|Permissions",
                "role": "Role|Roles",
                "notification": "Notification|Notifications",
                "users_relationship": "Contact|Contacts",
                "share": "Share|Shares",
                "user_privacy_setting": "Privacy setting|Privacy settings",
                "traveller_folder": "Traveller folder|Traveller folders",
                "currency": "Currency|Currencies",
                "user_notification_setting": "Notifications setting|Notifications settings",
                "tag": "Tag|Tags",
                "payment": "Payment|Payments",
                "post": "Post|Posts",
                "post_category": "Posts category|Posts categories",
                "privacy_policy": "Privacy policy|Privacy policies",
                "terms_condition": "Terms and conditions|Terms and conditions",
                "changelog": "Changelog|Changelogs",
                "report": "Report|Reports",
                "address": "Address|Addresses",
                "partner": "Partner|Partners",
                "reservation": "Reservation|Reservations",
                "user_credential_verification": "Credential verification|Credential verifications",
                "claim": "Claim|Claims",
                "claim_dispute": "Claim dispute|Claim disputes",
                "locale": "Language|Languages",
                "support_category": "Support Category|Support Categories",
                "support_request": "Request|Requests",
                "support_message": "Message|Messages",
                "user_geoloc_setting": "Geoloc setting|Geoloc settings",
                "data_tourisme_category": "DataTourisme category|DataTourisme categories",
                "data_tourisme_tag": "DataTourisme tag|DataTourisme tags",
                "user_password_reset": "User password reset|User passwords resets",
                "interaction": "Interaction|Interactions",
                "institution_employee": "Employee|Employees",
                "plan": "Subscription plan|Subscription plans",
                "plan_price": "Plan price|Plan prices",
                "subscription": "Subscription|Subscriptions",
                "traveller_email": "Traveller email|Travellers emails",
                "vat_rate": "VAT rate|VAT rates",
                "bank_transfer": "Bank transfer|Bank transfers",
                "bank_transfer_institution": "Bank transfer institution|Bank transfers institutions",
                "point_of_interest_category": "Category|Categories"
            },
            "navigations": {
                "items": {
                    "settings": "Parameters",
                    "contacts": "Contacts",
                    "folders": "Traveller folders",
                    "stays": "Stays",
                    "payments": "Payments",
                    "faqs": "FAQ",
                    "changelogs": "Changelogs",
                    "invite_traveller": "Invite a traveller",
                    "invite_professional": "Invite a professional",
                    "partners": "Partners",
                    "contact_us": "Contact us",
                    "privacy_policy": "Privacy policy",
                    "terms_conditions": "Terms and conditions",
                    "cookies": "Cookies management",
                    "credits": "Credits",
                    "users": "Users",
                    "customers": "They trust us"
                }
            },
            "notifications": {
                "index": {
                    "no_results": "No notifications!"
                },
                "share": {
                    "created": {
                        "text": "{username} from {poi_title} institution shared an item with you",
                        "text_alt": "{username} shared an item with you",
                        "text_alt_2": "An item has been shared with you",
                        "excursion": {
                            "title": "Share an itinerary",
                            "text": "{username} from {poi_title} institution shared an itinerary with you",
                            "text_alt": "{username} shared an itinerary with you",
                            "text_alt_2": "An itinerary has been shared with you"
                        },
                        "point_of_interest": {
                            "title": "Share a point of interest",
                            "text": "{username} from {poi_title} institution shared a point of interest with you",
                            "text_alt": "{username} shared a point of interest with you",
                            "text_alt_2": "A point of interest has been shared with you"
                        }
                    }
                },
                "point_of_interest": {
                    "moderation": {
                        "published": {
                            "title": "Point of interest published",
                            "text": "Your point of interest {title} has been published following its moderation"
                        },
                        "denied": {
                            "title": "Point of interest denied",
                            "text": "Your point of interest {title} has been denied following its moderation"
                        }
                    },
                    "event": {
                        "expired": {
                            "title": "Event expired",
                            "text": "The event {title}, included in your itineraries, is expired"
                        }
                    }
                },
                "invitation": {
                    "accepted": {
                        "title": "Invitation accepted",
                        "email": {
                            "text": "Your invitation to join the application sent to {credential} has been accepted"
                        },
                        "phone": {
                            "text": "Your invitation to join the application sent to {credential} has been accepted"
                        }
                    },
                    "pro": {
                        "title": "Professional invitation sent",
                        "text": "Your professional invitation has been sent to email {email}"
                    }
                },
                "reservation": {
                    "created": {
                        "title": "Reservation in your institution",
                        "text": "{butler_name} of the institution {butler_poi_title} has made a reservation\n                in your institution {poi_title}",
                        "text_alt": "A concierge from the institution {butler_poi_title} has made a reservation\n                in your institution {poi_title}",
                        "text_alt_2": "{butler_name} has made a reservation\n                in your institution {poi_title}",
                        "text_alt_3": "A reservation has been made\n                in your institution {poi_title}"
                    }
                },
                "traveller_folder": {
                    "pending": {
                        "title": "Stay pending confirmation",
                        "text": "{username} has registered for your account a stay at the hotel {poi_title}.\n                Please confirm it.",
                        "text_alt": "A stay has been registered for your account at the hotel {poi_title}.\n                Please confirm it."
                    },
                    "created": {
                        "title": "Stay created",
                        "text": "A stay has been registered for your account at the hotel {poi_title}."
                    },
                    "thanks": {
                        "title": "Thanks for your stay",
                        "text": "{username} from {poi_title} institution thank you for your stay",
                        "text_alt": "A concierge from {poi_title} institution thank you for your stay"
                    },
                    "approved": {
                        "title": "Traveller folder approved",
                        "text": "{username} has approved his traveller folder",
                        "text_alt": "A traveller has approved his folder"
                    },
                    "archived": {
                        "title": "Traveller folder archived",
                        "text": "{username} has archived his traveller folder",
                        "text_alt": "A traveller has archived his folder"
                    }
                },
                "stripe": {
                    "payments_enabled": {
                        "title": "Stripe payments enabled",
                        "text": "You can now receive payments through Stripe"
                    },
                    "account_requirements": {
                        "title": "Stripe Account requirements",
                        "text": "Following the verification of your Stripe account, new information are requested"
                    }
                },
                "payment": {
                    "received": {
                        "title": "Tip received",
                        "text": "You received a {amount} tip from {payer} from {poi_title} institution",
                        "text_alt": "You received a {amount} tip from {payer}",
                        "text_alt_2": "You received a {amount} tip"
                    },
                    "thanks": {
                        "title": "Thanks for the tip!",
                        "text": "{payee} from {poi_title} institution thanks you for the tip!",
                        "text_alt": "{payee} thanks you for the tip!",
                        "text_alt_2": "You have been thanked for the tip"
                    },
                    "proposal": {
                        "title": "Leave a tip",
                        "text": "Thank {username} from {poi_title} institution by leaving a tip",
                        "text_alt": "Thank the concierge from {poi_title} institution by leaving a tip",
                        "text_alt_2": "Thank {username} by leaving a tip",
                        "text_alt_3": "Thank the concierge by leaving a tip"
                    }
                },
                "changelog": {
                    "created": {
                        "title": "New changelog",
                        "text": "The version {version} has been deployed. You can check the news by clicking on the notification."
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "title": "Claim dispute pending verification",
                        "text": "The claim dispute regarding the point of interest {poi} is pending verification by our team."
                    },
                    "validated": {
                        "title": "Claim dispute validated!",
                        "text": "The claim dispute regarding the {poi} point of interest has been accepted!"
                    },
                    "rejected": {
                        "title": "Claim dispute rejected",
                        "text": "The claim dispute to the {poi} point of interest was rejected for the following reason: {reason}.",
                        "no_reason": "No reason given"
                    }
                },
                "support_request": {
                    "message": {
                        "title": "Message received",
                        "text": "An answer has been posted on your support request"
                    },
                    "archived": {
                        "title": "Support request archived",
                        "text": "Your support request has been archived"
                    }
                },
                "institution_employee": {
                    "pending": {
                        "title": "Employee request received",
                        "text": "{user} requests to be registered as an employee of your institution {poi}"
                    },
                    "approved": {
                        "title": "Employee request approved",
                        "text": "Your request to join {poi} has been approved"
                    },
                    "declined": {
                        "title": "Employee request declined",
                        "text": "Your request to join {poi} has been declined"
                    },
                    "removed": {
                        "title": "Employee status removed",
                        "text": "Your employee status from {poi} has been removed"
                    }
                },
                "office": {
                    "claim": {
                        "subject": "New claim!",
                        "content": {
                            "line_1": "A point of interest has just been claimed.",
                            "line_2": "Click on the button below to see the detail in the admin interface."
                        },
                        "cta": "See claim"
                    },
                    "claim_dispute": {
                        "subject": "New dispute!",
                        "content": {
                            "line_1": "A point of interest has just been contested.",
                            "line_2": "Click on the button below to see the detail in the admin interface."
                        },
                        "cta": "See dispute"
                    },
                    "report": {
                        "subject": "New Report!",
                        "content": {
                            "line_1": "{type} has just been reported."
                        },
                        "cta": "See report",
                        "type": {
                            "App\\Models\\PointOfInterest": "A point of interest",
                            "App\\Models\\User": "a user"
                        }
                    },
                    "support_request": {
                        "subject": "New support request!",
                        "content": {
                            "line_1": "A new support request has just been added."
                        },
                        "cta": "See support request"
                    }
                }
            },
            "nova": {
                "actions": {
                    "payment": {
                        "download_invoice": "Download invoice"
                    },
                    "point_of_interest": {
                        "draft": "Draft",
                        "submit": "Ask for moderation",
                        "deny": "Deny",
                        "publish": "Validate"
                    },
                    "post": {
                        "draft": "Draft",
                        "publish": "Publish",
                        "archive": "Archive",
                        "set_coming_soon": "Set to \"coming soon\""
                    },
                    "plan": {
                        "draft": "Draft",
                        "publish": "Publish",
                        "archive": "Archive"
                    },
                    "privacy_policy": {
                        "publish": "Publish",
                        "confirmation": {
                            "confirm_button_text": "Publish",
                            "confirm_text": "Are you sure do you want to publish this privacy policy? ALL users will be asked to validate them again!"
                        },
                        "errors": {
                            "resources_count": "You cannot publish more than ONE private policy!"
                        },
                        "archive": "Archive"
                    },
                    "terms_condition": {
                        "publish": "Publish",
                        "confirmation": {
                            "confirm_button_text": "Publish",
                            "confirm_text": "Are you sure do you want to publish this terms and conditions? ALL users will be asked to validate them again!"
                        },
                        "errors": {
                            "resources_count": "You cannot publish more than ONE terms and conditions!"
                        },
                        "archive": "Archive"
                    },
                    "changelog": {
                        "draft": "Draft",
                        "publish": "Publish",
                        "archive": "Archive"
                    },
                    "report": {
                        "process": "Process",
                        "reject": "Reject"
                    },
                    "claim_dispute": {
                        "reject": "Reject",
                        "validate": "Validate"
                    },
                    "support_request": {
                        "answer": "Answer",
                        "archive": "Archive",
                        "cancel": "Cancel",
                        "pending_user": "Waiting for user",
                        "pending_support": "Waiting for support",
                        "resolve": "Resolve"
                    },
                    "claim": {
                        "reject": "Reject",
                        "validate": "Validate",
                        "missing_info": "Missing info",
                        "revoke": "Revoke"
                    },
                    "user_credential_verification": {
                        "validate": "Validate"
                    },
                    "user": {
                        "finish": "Finish tutorial"
                    },
                    "bank_transfer": {
                        "transfer": "Make bank transfer"
                    }
                },
                "cards": {
                    "new_points_of_interest": "New Points of interest",
                    "new_excursions": "New Itineraries",
                    "new_users": "New Users"
                },
                "filters": {
                    "to_validate": "To validate",
                    "group": "Group",
                    "front": "Front",
                    "app": "Application",
                    "extranet": "Extranet",
                    "static_translations_enabled": "Static translations enabled",
                    "dynamic_translations_enabled": "Dynamic translations enabled",
                    "archived_at_lte": "Archived before",
                    "archived_at_gte": "Archived after",
                    "canceled_at_lte": "Canceled before",
                    "canceled_at_gte": "Canceled after",
                    "created_at_lte": "Created before",
                    "created_at_gte": "Created after",
                    "resolved_at_lte": "Resolved before",
                    "resolved_at_gte": "Resolved after",
                    "reservation_date_lte": "Reservation before",
                    "reservation_date_gte": "Reservation after",
                    "published_at_lte": "Published before",
                    "published_at_gte": "Published after"
                },
                "labels": {
                    "point_of_interest_categories": "Categories",
                    "institutions": "Institutions"
                }
            },
            "opening_hours": {
                "content": {
                    "date": "The {date}",
                    "date_range_from": "From {from}",
                    "date_range_to": "To {to}",
                    "date_range_from_to": "From {from} to {to}",
                    "hours_range_from": "From {from}",
                    "hours_range_to": "To {to}",
                    "hours_range_from_to": "From {from} to {to}"
                },
                "additional_info": "Additional information"
            },
            "partners": {
                "index": {
                    "no_results": "No partners found",
                    "title": "Our partners",
                    "our_last_partners": "Our last partners"
                },
                "customers": {
                    "title": "Our customers"
                },
                "show": {
                    "link": "Visit website"
                }
            },
            "payments": {
                "checkout": {
                    "tip_amount": "Tip amount",
                    "cancel": "The payment has been cancelled",
                    "success": "Payment successfully done",
                    "custom_amount": "Enter custom amount"
                },
                "thanks": {
                    "success": "Thanks sent!"
                },
                "labels": {
                    "payee": "Payee:",
                    "payment_service": "Payed by "
                },
                "index": {
                    "no_results": "No payments found"
                },
                "payable": {
                    "reservation": "Reservation #{id}"
                }
            },
            "pdf": {
                "payments": {
                    "title": "Received payments",
                    "period": "Period from {from} to {to}"
                },
                "receipt": {
                    "receipt_for": "Receipt for payment made to ",
                    "receipt_number": "Receipt for payment #{id}",
                    "paid_amount": "Paid amount",
                    "payment_date": "Payment date",
                    "payment_method": "Payment method",
                    "summary": "Summary",
                    "debited_amount": "Debited amount"
                },
                "common": {
                    "generating": "Generating PDF file...",
                    "waiting": "Please wait..."
                },
                "share": {
                    "details": {
                        "line1": "We are pleased to share with you your personalized itinerary.",
                        "line2": "You'll find your itinerary's content below.",
                        "line3": "To access to the detailed information (audio with address in local language, interactive map,\n                pictures, full description...), click on the button below."
                    },
                    "cta": "Consult the itinerary"
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to Stripe accounts",
                        "HAVE": "Have a Stripe account"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to EasyTransac accounts",
                        "HAVE": "Have a EasyTransac account"
                    },
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to access",
                        "APP": "Access the app",
                        "EXTRANET": "Access the professional section",
                        "TELESCOPE": "Access Telescope",
                        "HORIZON": "Access Horizon",
                        "ADMIN": "Access the administration",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "Login without having to verify your credential",
                        "VIEW_BUTLER_DASHBOARD": "View butler dashboard",
                        "VIEW_MANAGER_DASHBOARD": "View manager dashboard",
                        "VIEW_HOTELIER_DASHBOARD": "View hotelier dashboard",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "View tourist office dashboard",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "View tourist office employee dashboard"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to activity logs",
                        "CREATE": "Create an activity log",
                        "VIEW": "Consult an activity log",
                        "VIEW_ANY": "Consult index of activity logs",
                        "UPDATE": "Update an activity log",
                        "DELETE": "Delete an activity log",
                        "FORCE_DELETE": "Force delete an activity log",
                        "RESTORE": "Restore an activity log"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to countries",
                        "USE_ANY": "Use any country"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to currencies",
                        "CREATE": "Create a currency",
                        "VIEW": "Consult a currency",
                        "VIEW_ANY": "Consult index of currencies",
                        "UPDATE": "Update a currency",
                        "DELETE": "Delete a currency",
                        "FORCE_DELETE": "Force delete a currency",
                        "RESTORE": "Restore a currency"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to itineraries",
                        "CREATE": "Create an itinerary",
                        "VIEW": "Consult an itinerary",
                        "VIEW_ANY": "Consult index of itineraries",
                        "UPDATE": "Update an itinerary",
                        "DELETE": "Delete an itinerary",
                        "FORCE_DELETE": "Force delete an itinerary",
                        "RESTORE": "Restore an itinerary",
                        "SHARE": "Share an itinerary",
                        "VIEW_KIND_UGOZER_PRO": "View itineraries with UgoZER Pro kind",
                        "VIEW_KIND_UGOZER_APP": "View itineraries with UgoZER App kind"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to links between itineraries and points of interest",
                        "CREATE": "Create a link between an itinerary and point of interest",
                        "VIEW": "Consult a link between an itinerary and point of interest",
                        "VIEW_ANY": "Consult index of links between itineraries and points of interest",
                        "UPDATE": "Update a link between an itinerary and point of interest",
                        "DELETE": "Delete a link between an itinerary and point of interest",
                        "FORCE_DELETE": "Force delete a link between an itinerary and point of interest",
                        "RESTORE": "Restore a link between an itinerary and point of interest"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to media",
                        "CREATE": "Create a media",
                        "VIEW": "Consult a media",
                        "VIEW_ANY": "Consult index of media",
                        "UPDATE": "Update a media",
                        "DELETE": "Delete a media",
                        "FORCE_DELETE": "Force delete a media",
                        "RESTORE": "Restore a media"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to payments",
                        "CREATE": "Create a payment",
                        "VIEW": "Consult a payment",
                        "VIEW_ANY": "Consult index of payments",
                        "UPDATE": "Update a payment",
                        "DELETE": "Delete a payment",
                        "FORCE_DELETE": "Force delete a payment",
                        "RESTORE": "Restore a payment",
                        "THANK": "Receive a thanks for a payment",
                        "GET_RECEIPT": "Receive a receipt for a payment",
                        "RECEIVE": "Receive payments"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to permissions",
                        "CREATE": "Create a permission",
                        "VIEW": "Consult a permission",
                        "VIEW_ANY": "Consult index of permissions",
                        "UPDATE": "Update a permission",
                        "DELETE": "Delete a permission",
                        "FORCE_DELETE": "Force delete a permission",
                        "RESTORE": "Restore a permission"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to points of interest categories",
                        "CREATE": "Create a point of interest category",
                        "VIEW": "Consult a point of interest category",
                        "VIEW_ANY": "Consult index of points of interest categories",
                        "UPDATE": "Update a point of interest category",
                        "DELETE": "Delete a point of interest category",
                        "FORCE_DELETE": "Force delete a point of interest category",
                        "RESTORE": "Restore a point of interest category"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to points of interest",
                        "CREATE": "Create a point of interest",
                        "VIEW": "Consult a point of interest",
                        "VIEW_ANY": "Consult index of points of interest",
                        "UPDATE": "Update a point of interest",
                        "DELETE": "Delete a point of interest",
                        "FORCE_DELETE": "Force delete a point of interest",
                        "RESTORE": "Restore a point of interest",
                        "SHARE": "Share a point of interest",
                        "PUBLISH": "Publish a point of interest",
                        "DENY": "Deny a point of interest",
                        "SUBMIT": "Submit a point of interest",
                        "DRAFT": "Draft a point of interest",
                        "MODERATE": "Moderate a point of interest",
                        "CONTRIBUTE": "Contribute to a point of interest",
                        "UPDATE_CERTIFIED": "Update a point of interest's certified attribute",
                        "ADMIN_ANY": "Administrate any point of interest",
                        "REPORT": "Report a point of interest",
                        "IMPORT": "Import points of interest",
                        "CLAIM": "Claim a point of interest",
                        "CONTEST": "Contest a point of interest",
                        "APPLY": "Apply to be employee"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to reservations",
                        "CREATE": "Create a reservation",
                        "VIEW": "Consult a reservation",
                        "VIEW_ANY": "Consult index of reservations",
                        "UPDATE": "Update a reservation",
                        "DELETE": "Delete a reservation",
                        "FORCE_DELETE": "Force delete a reservation",
                        "RESTORE": "Restore a reservation",
                        "CONFIRM": "Confirm a reservation",
                        "CANCEL": "Cancel a reservation",
                        "ARCHIVE": "Archive a reservation"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to roles",
                        "CREATE": "Create a role",
                        "VIEW": "Consult a role",
                        "VIEW_ANY": "Consult index of roles",
                        "UPDATE": "Update a role",
                        "DELETE": "Delete a role",
                        "FORCE_DELETE": "Force delete a role",
                        "RESTORE": "Restore a role"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to shares",
                        "CREATE": "Create a share",
                        "VIEW": "Consult a share",
                        "VIEW_ANY": "Consult index of shares",
                        "UPDATE": "Update a share",
                        "DELETE": "Delete a share",
                        "FORCE_DELETE": "Force delete a share",
                        "RESTORE": "Restore a share"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to traveller folders",
                        "CREATE": "Create a traveller folder",
                        "VIEW": "Consult a traveller folder",
                        "VIEW_ANY": "Consult index of traveller folders",
                        "UPDATE": "Update a traveller folder",
                        "DELETE": "Delete a traveller folder",
                        "FORCE_DELETE": "Force delete a traveller folder",
                        "RESTORE": "Restore a traveller folder",
                        "APPROVE": "Approve a traveller folder",
                        "DECLINE": "Decline a traveller folder",
                        "ARCHIVE": "Archive a traveller folder",
                        "THANK": "Thank a traveller for the stay"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to users",
                        "CREATE": "Create a user",
                        "VIEW": "Consult a user",
                        "VIEW_ANY": "Consult index of users",
                        "UPDATE": "Update a user",
                        "DELETE": "Delete a user",
                        "FORCE_DELETE": "Force delete a user",
                        "RESTORE": "Restore a user",
                        "VIEW_AVATAR": "View a user's avatar",
                        "VIEW_REAL_NAME": "View a user's real name",
                        "VIEW_CONTACT_PHONE": "View a user's contact phone",
                        "VIEW_CONTACT_EMAIL": "View a user's contact email",
                        "ADD_CONTACT": "Add a user as a contact",
                        "INVITE": "Invite a user",
                        "REPORT": "Report a user",
                        "TIP": "Tip a user",
                        "UPDATE_BANK_ACCOUNT": "Update bank account"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to users relationships",
                        "CREATE": "Create a user relationship",
                        "VIEW": "Consult a user relationship",
                        "VIEW_ANY": "Consult index of users relationships",
                        "UPDATE": "Update a user relationship",
                        "DELETE": "Delete a user relationship",
                        "FORCE_DELETE": "Force delete a user relationship",
                        "RESTORE": "Restore a user relationship",
                        "APPROVE_CONTACT": "Approve a user relationship",
                        "DECLINE_CONTACT": "Decline a user relationship",
                        "BLOCK_CONTACT": "Block a user relationship",
                        "UNBLOCK_CONTACT": "Unblock a user relationship"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to tags",
                        "CREATE": "Create a tag",
                        "VIEW": "Consult a tag",
                        "VIEW_ANY": "Consult index of tags",
                        "UPDATE": "Update a tag",
                        "DELETE": "Delete a tag",
                        "FORCE_DELETE": "Force delete a tag",
                        "RESTORE": "Restore a tag"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to posts",
                        "CREATE": "Create a post",
                        "VIEW": "Consult a post",
                        "VIEW_ANY": "Consult index of posts",
                        "UPDATE": "Update a post",
                        "DELETE": "Delete a post",
                        "FORCE_DELETE": "Force delete a post",
                        "RESTORE": "Restore a post",
                        "DRAFT": "Draft a post",
                        "PUBLISH": "Publish a post",
                        "ARCHIVE": "Archive a post"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to subscription plans",
                        "CREATE": "Create a subscription plan",
                        "VIEW": "Consult a subscription plan",
                        "VIEW_ANY": "Consult index of subscription plans",
                        "UPDATE": "Update a subscription plan",
                        "DELETE": "Delete a subscription plan",
                        "FORCE_DELETE": "Force delete a subscription plan",
                        "RESTORE": "Restore a subscription plan",
                        "DRAFT": "Draft a subscription plan",
                        "PUBLISH": "Publish a subscription plan",
                        "ARCHIVE": "Archive a subscription plan",
                        "SUBSCRIBE": "Subscribe to a plan"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to subscriptions",
                        "CREATE": "Create a subscription",
                        "VIEW": "Consult a subscription",
                        "VIEW_ANY": "Consult index of subscriptions",
                        "UPDATE": "Update a subscription",
                        "DELETE": "Delete a subscription",
                        "FORCE_DELETE": "Force delete a subscription",
                        "RESTORE": "Restore a subscription",
                        "PAY": "Pay a subscription",
                        "CANCEL": "Cancel a subscription"
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to posts categories",
                        "CREATE": "Create an posts category",
                        "VIEW": "Consult an posts category",
                        "VIEW_ANY": "Consult index of posts categories",
                        "UPDATE": "Update an posts category",
                        "DELETE": "Delete an posts category",
                        "FORCE_DELETE": "Force delete an posts category",
                        "RESTORE": "Restore an posts category"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to privacy policies",
                        "CREATE": "Create a privacy policy",
                        "VIEW": "Consult a privacy policy",
                        "VIEW_ANY": "Consult privacy policies",
                        "UPDATE": "Update a privacy policy",
                        "DELETE": "Delete a privacy policy",
                        "FORCE_DELETE": "Force delete a privacy policy",
                        "RESTORE": "Restore a privacy policy",
                        "DRAFT": "Draft a privacy policy",
                        "PUBLISH": "Publish a privacy policy",
                        "ARCHIVE": "Archive a privacy policy"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to terms and conditions",
                        "CREATE": "Create a terms and conditions",
                        "VIEW": "Consult a terms and conditions",
                        "VIEW_ANY": "Consult terms and conditions",
                        "UPDATE": "Update a terms and conditions",
                        "DELETE": "Delete a terms and conditions",
                        "FORCE_DELETE": "Force delete a terms and conditions",
                        "RESTORE": "Restore a terms and conditions",
                        "DRAFT": "Draft a terms and conditions",
                        "PUBLISH": "Publish a terms and conditions",
                        "ARCHIVE": "Archive a terms and conditions"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to changelogs",
                        "CREATE": "Create a changelog",
                        "VIEW": "Consult a changelog",
                        "VIEW_ANY": "Consult index of changelogs",
                        "UPDATE": "Update a changelog",
                        "DELETE": "Delete a changelog",
                        "FORCE_DELETE": "Force delete a changelog",
                        "RESTORE": "Restore a changelog",
                        "DRAFT": "Draft a changelog",
                        "PUBLISH": "Publish a changelog",
                        "ARCHIVE": "Archive a changelog"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to interactions",
                        "CREATE": "Create an interaction",
                        "VIEW": "Consult an interaction",
                        "VIEW_ANY": "Consult index of interactions",
                        "UPDATE": "Update an interaction",
                        "DELETE": "Delete an interaction",
                        "FORCE_DELETE": "Force delete an interaction",
                        "RESTORE": "Restore an interaction",
                        "DRAFT": "Draft an interaction",
                        "PUBLISH": "Publish an interaction",
                        "ARCHIVE": "Archive an interaction"
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to reports",
                        "CREATE": "Create a report",
                        "VIEW": "Consult a report",
                        "VIEW_ANY": "Consult index of reports",
                        "UPDATE": "Update a report",
                        "DELETE": "Delete a report",
                        "FORCE_DELETE": "Force delete a report",
                        "RESTORE": "Restore a report",
                        "DRAFT": "Draft a report",
                        "PUBLISH": "Publish a report",
                        "ARCHIVE": "Archive a report",
                        "PROCESS_ANY": "Process any report",
                        "REJECT_ANY": "Reject any report"
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to partners",
                        "CREATE": "Create a partner",
                        "VIEW": "Consult a partner",
                        "VIEW_ANY": "Consult index of partners",
                        "UPDATE": "Update a partner",
                        "DELETE": "Delete a partner",
                        "FORCE_DELETE": "Force delete a partner",
                        "RESTORE": "Restore a partner"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to translatable records",
                        "REQUIRE_ANY": "Allow to translate a required field in a language that is not necessarily English"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "Register to the professional section with any role that has this permission"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to claim disputes",
                        "CREATE": "Create a claim dispute",
                        "VIEW": "Consult a claim dispute",
                        "VIEW_ANY": "Consult index of claim disputes",
                        "UPDATE": "Update a claim dispute",
                        "DELETE": "Delete a claim dispute",
                        "FORCE_DELETE": "Force delete a claim dispute",
                        "RESTORE": "Restore a claim dispute",
                        "REJECT": "Reject a claim dispute",
                        "VALIDATE": "Validate a claim dispute"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to support categories request",
                        "CREATE": "Create a support category",
                        "VIEW": "Consult a support category",
                        "VIEW_ANY": "Consult index of support categories",
                        "UPDATE": "Update a support category",
                        "DELETE": "Delete a support category",
                        "FORCE_DELETE": "Force delete a support category",
                        "RESTORE": "Restore a support category"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to messages",
                        "CREATE": "Create a message",
                        "VIEW": "Consult a message",
                        "VIEW_ANY": "Consult index of messages",
                        "UPDATE": "Update a message",
                        "DELETE": "Delete a message",
                        "FORCE_DELETE": "Force delete a message",
                        "RESTORE": "Restore a message"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to support requests",
                        "CREATE": "Create a support request",
                        "VIEW": "Consult a support request",
                        "VIEW_ANY": "Consult index of support requests",
                        "UPDATE": "Update a support request",
                        "ADD_MESSAGE": "Add a message",
                        "DELETE": "Delete a support request",
                        "FORCE_DELETE": "Force delete a support request",
                        "RESTORE": "Restore a support request",
                        "CANCEL": "Cancel a support request"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to user geoloc settings",
                        "CREATE": "Create a user geoloc setting",
                        "VIEW": "Consult a user geoloc setting",
                        "VIEW_ANY": "Consult index of user geoloc settings",
                        "UPDATE": "Update a user geoloc setting",
                        "DELETE": "Delete a user geoloc setting",
                        "FORCE_DELETE": "Force delete a user geoloc setting",
                        "RESTORE": "Restore a user geoloc setting"
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to user privacy settings",
                        "CREATE": "Create a user privacy setting",
                        "VIEW": "Consult a user privacy setting",
                        "VIEW_ANY": "Consult index of user privacy settings",
                        "UPDATE": "Update a user privacy setting",
                        "DELETE": "Delete a user privacy setting",
                        "FORCE_DELETE": "Force delete a user privacy setting",
                        "RESTORE": "Restore a user privacy setting"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to user notification settings",
                        "CREATE": "Create a user notification setting",
                        "VIEW": "Consult a user notification setting",
                        "VIEW_ANY": "Consult index of user notification settings",
                        "UPDATE": "Update a user notification setting",
                        "DELETE": "Delete a user notification setting",
                        "FORCE_DELETE": "Force delete a user notification setting",
                        "RESTORE": "Restore a user notification setting"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to user credential verifications",
                        "CREATE": "Create a user credential verification",
                        "VIEW": "Consult a user credential verification",
                        "VIEW_ANY": "Consult index of user credential verifications",
                        "UPDATE": "Update a user credential verification",
                        "DELETE": "Delete a user credential verification",
                        "FORCE_DELETE": "Force delete a user credential verification",
                        "RESTORE": "Restore a user credential verification"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to addresses",
                        "CREATE": "Create an address",
                        "VIEW": "Consult an address",
                        "VIEW_ANY": "Consult index of addresses",
                        "UPDATE": "Update an address",
                        "DELETE": "Delete an address",
                        "FORCE_DELETE": "Force delete an address",
                        "RESTORE": "Restore an address"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to languages",
                        "CREATE": "Create a language",
                        "VIEW": "Consult a language",
                        "VIEW_ANY": "Consult index of languages",
                        "UPDATE": "Update a language",
                        "DELETE": "Delete a language",
                        "FORCE_DELETE": "Force delete a language",
                        "RESTORE": "Restore a language"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to claims",
                        "CREATE": "Create a claim",
                        "VIEW": "Consult a claim",
                        "VIEW_ANY": "Consult index of claims",
                        "UPDATE": "Update a claim",
                        "DELETE": "Delete a claim",
                        "FORCE_DELETE": "Force delete a claim",
                        "RESTORE": "Restore a claim",
                        "REJECT": "Reject a claim",
                        "VALIDATE": "Validate a claim",
                        "MISSING_INFO": "Reject a claim as missing info",
                        "SHOW_FILE": "Show a claim file",
                        "REVOKE": "Revoke a claim"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "Receive a notification when an invitation is accepted",
                        "PAYMENT_RECEIVED": "Receive a notification when a payment is received",
                        "PAYMENT_THANKS": "Receive a notification when a payment is acknowledged",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "Receive a notification when a point of interest event expires",
                        "RESERVATION_CREATED": "Receive a notification when a reservation is created",
                        "SHARE_CREATED": "Receive a notification when a share is created",
                        "SUPPORT_REQUEST_ARCHIVED": "Receive a notification when a support request is archived",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "Receive a notification when a message is received from a support \n                request",
                        "TRAVELLER_FOLDER_APPROVED": "Receive a notification when a traveler file is approved",
                        "TRAVELLER_FOLDER_ARCHIVED": "Receive a notification when a traveler file is archived",
                        "TRAVELLER_FOLDER_PENDING": "Receive a notification while waiting for a file to be validated traveler",
                        "TRAVELLER_FOLDER_THANKS": "Receive a notification to thank the concierge in charge of a file traveler"
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to institutions employees",
                        "CREATE": "Create an institution employee",
                        "VIEW": "Consult an institution employee",
                        "VIEW_ANY": "Consult index of institutions employees",
                        "UPDATE": "Update an institution employee",
                        "DELETE": "Delete an institution employee",
                        "FORCE_DELETE": "Force delete an institution employee",
                        "RESTORE": "Restore an institution employee",
                        "APPROVE": "Approve an institution employee request",
                        "DECLINE": "Decline an institution employee request",
                        "CANCEL": "Cancel an institution employee request",
                        "REMOVE": "Remove an employee from an institution",
                        "FEATURE": "Feature an employee from an institution"
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to travellers emails",
                        "CREATE": "Create a traveller email",
                        "VIEW": "Consult a traveller email",
                        "VIEW_ANY": "Consult index of travellers emails",
                        "UPDATE": "Update a traveller email",
                        "DELETE": "Delete a traveller email",
                        "FORCE_DELETE": "Force delete a traveller email",
                        "RESTORE": "Restore a traveller email"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "Ignore permissions related to bank transfers institutions",
                        "VIEW_ANY": "Consult index of bank transfers institutions"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\ExcursionEnum": "Itineraries",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "Points of interest",
                    "App\\Enums\\Permissions\\ReservationEnum": "Reservations",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "Point of interest categories",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "Traveller folders",
                    "App\\Enums\\Permissions\\UserEnum": "Users",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "Users relationships",
                    "App\\Enums\\Permissions\\PermissionEnum": "Permissions",
                    "App\\Enums\\Permissions\\RoleEnum": "Roles",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "Link between itineraries and points of interest",
                    "App\\Enums\\Permissions\\MediumEnum": "Media",
                    "App\\Enums\\Permissions\\ShareEnum": "Shares",
                    "App\\Enums\\Permissions\\CurrencyEnum": "Currencies",
                    "App\\Enums\\Permissions\\CountryEnum": "Countries",
                    "App\\Enums\\Permissions\\AccessEnum": "Access",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "Activity logs",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "Stripe Account",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "EasyTransac Account",
                    "App\\Enums\\Permissions\\TagEnum": "Tags",
                    "App\\Enums\\Permissions\\PaymentEnum": "Payments",
                    "App\\Enums\\Permissions\\PostEnum": "Posts",
                    "App\\Enums\\Permissions\\PlanEnum": "Subscription plans",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "Subscriptions",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "Posts categories",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "Terms and conditions",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "Privacy policies",
                    "App\\Enums\\Permissions\\ChangelogEnum": "Changelogs",
                    "App\\Enums\\Permissions\\ReportEnum": "Reports",
                    "App\\Enums\\Permissions\\InteractionEnum": "Interactions",
                    "App\\Enums\\Permissions\\PartnerEnum": "Partners",
                    "App\\Enums\\Permissions\\TranslatableEnum": "Translatable records",
                    "App\\Enums\\Permissions\\RegistrationEnum": "Registration",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "Support request categories",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "Support messages",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "Support requests",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "User geoloc settings",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "User privacy settings",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "User notification settings",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "User credential verifications",
                    "App\\Enums\\Permissions\\AddressEnum": "Addresses",
                    "App\\Enums\\Permissions\\LocaleEnum": "Languages",
                    "App\\Enums\\Permissions\\ClaimEnum": "Claims",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "Receive notifications",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "Institutions employees",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "Travellers emails",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "Bank transfers institutions"
                }
            },
            "plans": {
                "publish": {
                    "success": "Subscription plan(s) has(ve) been published (if eligible)"
                },
                "draft": {
                    "success": "Subscription plan(s) has(ve) been drafted (if eligible)"
                },
                "archive": {
                    "success": "Subscription plan(s) has(ve) been archived (if eligible)"
                },
                "set_coming_soon": {
                    "success": "Subscription plan(s) has(ve) been set to \"coming soon\" (if eligible)"
                },
                "confirmation": {
                    "name": "You are about to subscribe to the \"{name}\" offer.",
                    "name_launch": "You are about to subscribe to the \"{name}\" launch offer.",
                    "no_fee": "This offer is subject to a 0% fee, and will allow you to receive your tips in full.",
                    "fee": "This offer is subject to a fee of {fee_percentage}% and entitles you to\n            receive {tip_percentage}% of your tips.",
                    "free_period": "You benefit from a {free_period} free period.",
                    "free_period_used": "You have already used up your free period and will not be\n            able to benefit from the {free_period} proposed by this offer.",
                    "engagement_period": "This offer includes an engagement period of\n            {engagement_period} after the 1st payment.",
                    "free": "This subscription is free of charge, subject to the creation of a tip collection account.",
                    "please_confirm": "Please confirm your subscription by clicking on the button below.",
                    "price_summary": "This subscription will cost you\n            {price} excl. taxes / {price_incl_taxes} incl. taxes per month.",
                    "benefit_current_subscription": "You will continue to benefit from your current subscription\n            until the end of the month already paid.",
                    "replace_current_subscription": "This subscription will automatically replace your current subscription."
                },
                "free_period_ended_in": "Free periods ends {in}",
                "your_current_plan": "Your current plan"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "By checking this box, I certify that I am an authorized representative of the \n            establishment claimed. Any false statement or impersonation will subject me to civil and criminal \n            penalties in accordance with applicable laws.",
                    "success": "Your claim has been submitted for verification."
                },
                "destroy": {
                    "confirm": "Do you really want to delete this point of interest: {point_of_interest_name}?",
                    "success": "The point of interest has been deleted"
                },
                "store": {
                    "success": "The point of interest has been created",
                    "heading": "Create a point of interest"
                },
                "update": {
                    "success": "The point of interest has been updated",
                    "heading": "Edit this point of interest",
                    "confirm": "Do you really want to update this point of interest: {point_of_interest_name}?",
                    "alert": "Updating this point will disable the automatic update from {import_source} import"
                },
                "index": {
                    "no_results_here": "No points of interest are available in your search area.",
                    "try_more_filters": "You can change your active filters and widen your search.",
                    "no_results": "No points of interest found",
                    "no_tags": "No tag defined",
                    "search": "Search a point of interest",
                    "refresh_delayed": "Please wait {time_remaining} before refresh"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "Add this point of interest to an itinerary",
                    "select_which": "Select which existing itinerary to add the point of interest \"{point_of_interest_name}\" to",
                    "already_in_excursions": "This point of interest is already part of those itineraries",
                    "create_excursion": "Create an itinerary",
                    "success": "The point of interest has been added to the itinerary",
                    "or_create_from": "Or create a new itinerary from this point of interest"
                },
                "denied": {
                    "for_the_reason": "Denied for the following reason",
                    "moderation_again": "You can edit the point of interest, which will submit it again for moderation, so that it can be published."
                },
                "deny": {
                    "success": "Point(s) of interest has(ve) been denied (if eligible)"
                },
                "draft": {
                    "success": "Point(s) of interest has(ve) been drafted (if eligible)"
                },
                "submit": {
                    "success": "Point(s) of interest is(are) pending moderation (if eligible)"
                },
                "publish": {
                    "success": "Point(s) of interest has(ve) been validated and published (if eligible)"
                },
                "search": {
                    "no_results": "No points of interest found...",
                    "creator_id": "Show only my points?",
                    "tags_mode": {
                        "one": "At least one",
                        "one_help": "Select \"at least one\", to find points of interest\n                with at least one of the selected tags.",
                        "all": "All",
                        "all_help": "Select \"all\" to find points of interest\n                with all the selected tags."
                    }
                },
                "share": {
                    "heading": "Share this point of interest",
                    "select_which_users": "Select the users you want to share the point of interest \"{point_of_interest_title}\" with or enter an email address to invite a new traveller",
                    "select_which_users_alt": "Enter the e-mail address of the person with whom you want to share the\n            \"{point_of_interest_title}\" point of interest.",
                    "select_which_contacts": "Select the contacts you want to share the point of interest \"{point_of_interest_title}\" with",
                    "success": "The point of interest has been shared!"
                },
                "states": {
                    "draft": "Draft",
                    "pending": "Pending",
                    "published": "Published"
                },
                "form": {
                    "help": {
                        "stars": "Only if category can be noted with stars (like Hotel, Restaurant...)"
                    }
                },
                "report": {
                    "heading": "Report a point of interest",
                    "choose_reason": "Choose a reason for reporting this point of interest \"{point_of_interest_title}\"",
                    "success": "The point of interest has been reported!"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "poi": "Point of interest",
                            "map": "Map",
                            "media": "Media",
                            "main_image": "Main image",
                            "gallery": "Gallery",
                            "audios": "Audios",
                            "videos": "Videos",
                            "logo": "Logo"
                        },
                        "excursions_counter": "Has been added {counter} times to itineraries",
                        "is_geocoding_1": "The point of interest is being geolocated.",
                        "is_geocoding_2": "Please check this page later to see the point of interest\n                on the map.",
                        "is_refreshing_audio_1": "An audio file is being generated.",
                        "is_refreshing_audio_2": "Please check this page later to listen to this audio.",
                        "belongs_to_you": "Belongs to you",
                        "see_full_page": "See full page",
                        "not_current_locale": {
                            "missing_translations": "Missing translations",
                            "incomplete_translation": "Some information of this point of interest (title, description and/or address) is not available in the selected language.",
                            "change_locale": "You can change displayed language using above selector"
                        },
                        "claim_dispute_pending": {
                            "notice": "Ongoing claim dispute",
                            "title": "You have an ongoing claim dispute to this point of interest.",
                            "details": "Details of the request: {message}"
                        },
                        "automatic_update": "Automatic update",
                        "dt_required_datatourisme_fields": "Updated by {dt_created_by} from {import_source} the {dt_last_update}",
                        "opening_hours": {
                            "show": "See opening hours",
                            "is_deleting": "The point of interest will be deleted in {days} days",
                            "expired": "Opening hours expired"
                        },
                        "deletion": {
                            "title": "Pending deletion",
                            "causes": {
                                "event": "Cause: expired event"
                            }
                        }
                    }
                },
                "print": {
                    "element_not_found": "Element to print \"{id}\" not found!"
                },
                "import_source": {
                    "DataTourisme": "DataTourisme"
                },
                "duplicates": {
                    "match_percentage": "Match to {percentage}%",
                    "check_explanation_title": "Vérification",
                    "check_explanation1": "In order to create your point of interest,\n            please fill in the main fields below.",
                    "check_explanation2": "A check will be performed to avoid\n            entering an already existing point.",
                    "check_explanation3": "After verification, you will be able to complete\n            the information of your point and save it.",
                    "found_explanation1": "One or more points of interest corresponding to\n            your input criteria already exist.",
                    "found_explanation2": "If you think that this is not a duplicate,\n            then you may proceed to enter your new point of interest.",
                    "continue_creation": "Continue creation",
                    "view_poi": "View this point of interest",
                    "select_duplicate": "Click on one of the proposed points of interest to see the details",
                    "use_poi": "Use this point of interest"
                }
            },
            "posts": {
                "publish": {
                    "success": "Post(s) has(ve) been published (if eligible)"
                },
                "draft": {
                    "success": "Post(s) has(ve) been drafted (if eligible)"
                },
                "archive": {
                    "success": "Post(s) has(ve) been archived (if eligible)"
                },
                "search": {
                    "type_to_search": "Search in the selected category..."
                }
            },
            "prices": {
                "free": "Free",
                "price_incl_taxes": "Price incl. taxes",
                "price_excl_taxes": "Price excl. taxes",
                "incl_taxes": "incl. taxes",
                "excl_taxes": "excl. taxes",
                "from": "From",
                "per_month": "Per month",
                "vat_amount": "VAT amount",
                "vat_percentage": "VAT percentage"
            },
            "privacy_policies": {
                "publish": {
                    "success": "Privacy policy has been published"
                },
                "archive": {
                    "success": "Privacy policy(ies) has(ve) been archived (if eligible)"
                },
                "errors": {
                    "not_found": "No privacy policy found!"
                },
                "update": {
                    "new_version": "A new version of privacy policy has been published",
                    "acceptation": "You should accept the privacy policy to continue",
                    "success": "Privacy policy has been accepted!"
                }
            },
            "reports": {
                "process": {
                    "success": "Report(s) has(ve) been processed (if eligible)"
                },
                "reject": {
                    "success": "Report(s) has(ve) been rejected (if eligible)"
                }
            },
            "reservations": {
                "destroy": {
                    "confirm": "Do you really want to delete this reservation?",
                    "success": "The reservation has been deleted"
                },
                "store": {
                    "success": "The reservation has been created"
                },
                "update": {
                    "success": "The reservation has been updated"
                },
                "confirm": {
                    "confirm": "Do you really want to confirm this reservation?",
                    "success": "The reservation has been confirmed"
                },
                "cancel": {
                    "confirm": "Do you really want to cancel this reservation?",
                    "success": "The reservation has been cancelled"
                },
                "archive": {
                    "confirm": "Do you really want to archive this reservation?",
                    "success": "The reservation has been archived"
                },
                "states": {
                    "pending": "Pending",
                    "confirmed": "Confirmed",
                    "canceled": "Canceled",
                    "archived": "Archived"
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "poi": "traveller",
                                "traveller": "institution",
                                "butler": "traveller"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "reservation": "Reservation",
                            "poi": "Point of interest",
                            "butler": "Concierge",
                            "traveller": "Traveller"
                        }
                    }
                }
            },
            "settings": {
                "items": {
                    "profile": "Profile",
                    "privacy_setting": "Privacy settings",
                    "geoloc_settings": "Geolocation settings",
                    "notification_settings": "Notifications settings",
                    "signOut": "Sign out",
                    "about": "About"
                }
            },
            "shares": {
                "index": {
                    "no_results": "No shares found!"
                },
                "show": {
                    "shared_by": "Shared by"
                },
                "type": {
                    "pointsofinterest": "Point of interest",
                    "excursions": "Itinerary"
                },
                "link": {
                    "instructions": "Copy this link to invite a traveler and share this itinerary with them"
                },
                "emails": {
                    "space_hint": "Separate by a space to share multiple emails",
                    "select_hint": "You can select a previously used email by clicking below:"
                }
            },
            "states": {
                "point_of_interest_state": {
                    "draft": "Draft",
                    "pending": "Pending moderation",
                    "published": "Published",
                    "deleted": "Deleted",
                    "denied": "Denied"
                },
                "users_relationship_state": {
                    "pending": "Pending",
                    "approved": "Approved",
                    "declined": "Declined",
                    "blocked": "Blocked"
                },
                "traveller_folder_state": {
                    "pending": "Pending",
                    "approved": "Approved",
                    "declined": "Declined",
                    "archived": "Archived"
                },
                "payment_state": {
                    "pending": "Pending",
                    "completed": "Completed",
                    "canceled": "Canceled",
                    "failed": "Failed",
                    "transferred": "Transferred"
                },
                "post_state": {
                    "draft": "Draft",
                    "published": "Published",
                    "archived": "Archived"
                },
                "plan_state": {
                    "draft": "Draft",
                    "published": "Published",
                    "archived": "Archived",
                    "coming_soon": "Coming soon"
                },
                "subscription_state": {
                    "active": "Active",
                    "canceled": "Canceled",
                    "pending": "Pending payment",
                    "suspended": "Suspended"
                },
                "privacy_policy_state": {
                    "draft": "Draft",
                    "published": "Published",
                    "archived": "Archived"
                },
                "terms_condition_state": {
                    "draft": "Draft",
                    "published": "Published",
                    "archived": "Archived"
                },
                "changelog_state": {
                    "draft": "Draft",
                    "published": "Published",
                    "archived": "Archived"
                },
                "report_state": {
                    "pending": "Pending",
                    "processed": "Processed",
                    "rejected": "Rejected"
                },
                "user_credential_verification_state": {
                    "pending": "Pending",
                    "completed": "Completed",
                    "canceled": "Canceled"
                },
                "claim_dispute_state": {
                    "pending": "Pending",
                    "rejected": "Rejected",
                    "validated": "Validated"
                },
                "support_request_state": {
                    "pending-support": "Pending Support",
                    "pending-user": "Pending User",
                    "archived": "Archived",
                    "canceled": "Canceled",
                    "resolved": "Resolved"
                },
                "reservation_state": {
                    "pending": "Pending",
                    "confirmed": "Confirmed",
                    "canceled": "Canceled",
                    "archived": "Archived"
                },
                "claim_state": {
                    "pending": "Pending",
                    "missing-info": "Missing information",
                    "rejected": "Rejected",
                    "validated": "Validated",
                    "revoked": "Revoked"
                },
                "user_tutorial_state": {
                    "claimed": "Claimed",
                    "done": "Done",
                    "employee-added": "Employee added",
                    "pending": "Pending"
                },
                "user_utm_source_state": {
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "linkedin": "LinkedIn"
                },
                "user_utm_medium_state": {
                    "owned_social": "Social network"
                },
                "user_utm_campaign_state": {
                    "always_on": "Always on"
                }
            },
            "stripe": {
                "stripe": "Stripe",
                "connect": {
                    "account": {
                        "create": {
                            "completed": "Stripe account configuration completed",
                            "link_invalid": "Connection to Stripe failed, please try again"
                        },
                        "show": {
                            "no_account_yet": "You don't have a Stripe account yet.",
                            "account_usage": "Your Stripe account will allow your institution to receive payments on UgoZER.",
                            "can_receive_payments": "You are able to receive payments.",
                            "fee_percentage": "A {percentage}% fee will be applied to each payment you receive.",
                            "cannot_receive_payments": "You are not able to receive payments",
                            "should_complete_stripe_onboarding": "Please complete your registration with Stripe and provide them with the requested information.",
                            "has_stripe_account_being_reviewed": "Your account is being reviewed by Stripe.",
                            "go_to_stripe": "Go to Stripe dashboard"
                        },
                        "must_subscribe": "You must be subscribed to create your Stripe account."
                    }
                }
            },
            "subscriptions": {
                "my_current_subscription": "My current subscription",
                "upcoming_subscription": "Upcoming subscription",
                "price_excl_taxes": "Price excl. taxes",
                "price_incl_taxes": "Price incl. taxes",
                "expiry": "Expiry",
                "canceled_at": "Cancelation",
                "activated_at": "Activation",
                "invoices": "Invoices",
                "cancel": "Cancel",
                "confirm_cancel": "Do you really want to cancel your subscription?",
                "confirm_cancel_date": "You will benefit of its advantages until its effective cancelation date on {date}.",
                "confirm_cancel_now": "Cancelation will take effect immediately.",
                "cancelation_success": "Cancelation taken into account"
            },
            "support_messages": {
                "store": {
                    "success": {
                        "title": "The message has been created. Contact support will answer within 48 hours."
                    },
                    "heading": "Add a message"
                },
                "show": {
                    "your_question": "Your question",
                    "you": "You"
                }
            },
            "support_requests": {
                "index": {
                    "your_requests": "Your support requests",
                    "no_results": "You do not have any support request",
                    "search": "Search a support request"
                },
                "show": {
                    "add_request": "Add a new support request"
                },
                "store": {
                    "success": {
                        "title": "The request has been created. Contact support will answer within 48 hours."
                    },
                    "heading": "Create a new support request"
                },
                "states": {
                    "pending-support": "Pending Support",
                    "pending-user": "Pending User",
                    "archived": "Archived",
                    "canceled": "Canceled",
                    "resolved": "Resolved"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "request": "Request",
                            "messages": "Messages"
                        }
                    },
                    "index": {
                        "search": "Search a support request"
                    },
                    "create": {
                        "notice": "Personal data"
                    }
                },
                "actions": {
                    "archived": {
                        "success": "The request is archived"
                    },
                    "canceled": {
                        "success": "The request is cancelled"
                    },
                    "pending_user": {
                        "success": "Waiting user to answer"
                    },
                    "pending_support": {
                        "success": "Waiting support to answer"
                    },
                    "resolved": {
                        "success": "The request is resolved"
                    }
                },
                "cancel": {
                    "confirm": "Do you really want to cancel this support request: {support_request_title} ?",
                    "success": "The support request has been cancelled",
                    "error": "The support request could not be cancelled"
                }
            },
            "terms_conditions": {
                "publish": {
                    "success": "Terms and conditions has been published"
                },
                "archive": {
                    "success": "Terms and conditions has(ve) been archived (if eligible)"
                },
                "errors": {
                    "not_found": "No terms and conditions found!"
                },
                "update": {
                    "new_version": "A new version of terms and conditions has been published",
                    "acceptation": "You should accept the terms and conditions to continue",
                    "success": "Terms and conditions have been accepted!"
                }
            },
            "traveller_folders": {
                "states": {
                    "pending": "Pending",
                    "approved": "Accepted",
                    "declined": "Declined",
                    "archived": "Archived",
                    "undefined": "Undefined"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "traveller": "Traveller",
                            "folder": "Folder",
                            "butler": "Related concierge",
                            "institution": "Related institution",
                            "hotel": "Hotel",
                            "reservations": "Reservations",
                            "invitation": "Invite a traveller",
                            "interactions": "Interactions",
                            "thanks": "Thank you",
                            "payments": "Payments",
                            "stay": "Stay"
                        },
                        "fields": {
                            "state": "State:"
                        }
                    },
                    "index": {
                        "search": "Search among my travelers",
                        "your_stays": "Your stays",
                        "travellers_folders": "Travellers folders",
                        "pending_stays": "Your pending stays"
                    },
                    "form": {
                        "poi_empty_option": "Remove point of interest",
                        "poi_label": "Concierge's institution",
                        "verification": {
                            "title": "Verification",
                            "description": "Enter the traveler's email address. If a traveler account already exists, it will\n                    be directly linked, otherwise you will have to enter some additional information to create a\n                    new one."
                        },
                        "cannot_attach": {
                            "title": "Support not possible",
                            "description": "This user exists but cannot be supported..."
                        }
                    },
                    "qr_code_1": "A traveller can scan this QR code to automatically obtain a traveller folder\n            with your institution.",
                    "qr_code_2": "If the traveller has not yet registered to UgoZER, it will be redirected to the registration\n            page and will be linked to your institution at the end of its registration.",
                    "wizard": {
                        "no_linked_institution": "You must be linked to an institution before you can pick up a traveler.",
                        "choose_an_institution": "Choose an institution"
                    }
                },
                "store": {
                    "success": "The folder has been created"
                },
                "update": {
                    "success": "The folder has been updated"
                },
                "thank": {
                    "success": "The thanks have been sent"
                },
                "messages": {
                    "approve": {
                        "confirm": "Do you really want to confirm this stay?",
                        "success": "Stay confirmed!"
                    },
                    "decline": {
                        "confirm": "Do you really want to decline this stay?",
                        "success": "Stay declined!"
                    },
                    "archive": {
                        "confirm": "Do you really want to archive this stay?",
                        "success": "This stay has been archived!"
                    }
                },
                "tabs": {
                    "approved": "Approved",
                    "pending": "Pending",
                    "archived": "Archived",
                    "declined": "Declined"
                },
                "no_results": {
                    "approved": "No approved stays",
                    "pending": "No stays requests",
                    "archived": "No archived stays",
                    "declined": "No declined stays"
                },
                "placeholders": {
                    "butler": "Reception team"
                }
            },
            "tutorials": {
                "extranet": {
                    "dashboard": {
                        "claim": {
                            "title": "Claiming ownership and management of my institution",
                            "subtitle_1": "You can claim one (or more) establishment(s) in order to manage them.",
                            "subtitle_2": "To do so:",
                            "steps": {
                                "poi_index": {
                                    "part_1": "Go to the",
                                    "part_2": "list of points of interest"
                                },
                                "filters": "Use the filters and the search to find your institution",
                                "exist": "If your institution exists, go to its file and click on the button <strong>\n                        Claim</strong>",
                                "dont_exist": "If it does not exist, click on the button <strong>Create a point of interest\n                        </strong> then fill in the form. Once the point of interest is created, go to its \n                        card and click on the button <strong>Claim</strong>",
                                "claim_form": "Then follow the instructions on the claim form",
                                "claim_validation": "The claim will then be verified and validated by our team"
                            }
                        },
                        "employees": {
                            "title": "Manage my employees",
                            "subtitle": {
                                "part_1": "You can manage your employees directly via the page",
                                "part_2": "My employees"
                            },
                            "manage": "On this page you can accept or reject employees who want to link up with your\n                    institution.",
                            "link": "To create a request, they can go to the page <strong>My institution</strong>\n                    of their space and fill out the form."
                        }
                    },
                    "points_of_interest": {
                        "claim": "Claim",
                        "filters": {
                            "main_title": "Filters",
                            "title": "Filter points of interest",
                            "subtitle": "You can filter the list of points of interest according to the following criteria:",
                            "category": "<strong>Category :</strong> allows you to choose a specific category \n                    (Hotel, restaurant...)",
                            "tags": "<strong>Refine :</strong> allows you to refine the search by tags to specify the category. \n                    It is also possible to define whether you want to search for points with one or all of the or all \n                    the selected tags",
                            "my_points": "<strong>Show only my points :</strong> only displays points that you have created or \n                    claimed by you",
                            "postal_code": "<strong>Zip :</strong> allows you to filter by postal code",
                            "city": "<strong>City :</strong> allows you to filter on a specific city",
                            "country": "<strong>Countries :</strong> allows you to filter on one or more specific countries",
                            "distance": "<strong>Place :</strong> allows you to filter the points from a specific location",
                            "radius": "<strong>Limit to a radius :</strong> allows to filter the points in a circle of specific \n                    radius",
                            "measure_unit": "<strong>Measure unit :</strong> allows you to change the unit of measure used for \n                    the distance filter and the display"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "main_title": "Filters",
                            "title": "Filter itineraries",
                            "subtitle": "You can filter the list of itineraries according to the following criteria:",
                            "distance": "<strong>Place:</strong> allows you to filter the points from a specific location",
                            "type": {
                                "explanation": "<strong>Type:</strong> allows you to filter by type of itinerary",
                                "customized": "<strong>Customized:</strong> itineraries created specifically for a traveler",
                                "root": "<strong>My templates:</strong> model itineraries",
                                "pro": "<strong>UgoZER Pro:</strong> itineraries provided by UgoZER"
                            }
                        }
                    },
                    "institution_employee": {
                        "functioning": {
                            "main_title": "Functioning",
                            "title": "Functioning of the employees section",
                            "content": {
                                "section": "This section allows you to manage the employees of your institutions.",
                                "invite": "On this page you can either invite employees or accept / refuse requests from your\n                        employees."
                            }
                        },
                        "invite": {
                            "main_title": "Invite",
                            "title": "Invite an employee",
                            "content": {
                                "button": "By clicking on the <strong>Invite Employee</strong> button you can invite one of your\n                        employees whether they already have a UgoZER account or not.",
                                "form": "To do this, simply follow the instructions on the step-by-step form that appears.\n                        form that appears."
                            }
                        },
                        "approve": {
                            "main_title": "Approve",
                            "title": "Approve an employee",
                            "content": "This action allows you to accept a request from one of your employees to join one of\n                    your institutions."
                        },
                        "decline": {
                            "main_title": "Refuse",
                            "title": "Refuse an employee",
                            "content": "This action allows you to deny the request of one of your employees to join one of your\n                    institutions."
                        },
                        "remove": {
                            "main_title": "Remove",
                            "title": "Remove an employee",
                            "content": "This action allows you to remove a user from your employees in the event that an\n                    employee leaves your company. in the event that an employee leaves your company."
                        },
                        "cancel": {
                            "main_title": "Cancel",
                            "title": "Cancel an employee's application",
                            "content": "This action allows you to cancel the invitation request of an employee in case of in\n                    case of an error, for example."
                        }
                    },
                    "begin": {
                        "hotelier": {
                            "description": "In order to allow you to use the UgoZER application in the best conditions, \n                    we advise you to follow the following steps which will guide you in the first steps of the \n                    handling and the parameter setting of your Hotel manager space.",
                            "steps": {
                                "employees": {
                                    "step_state": "claimed",
                                    "step_next": "employee-added",
                                    "title": "Concierges",
                                    "description": "Manage your concierges"
                                }
                            }
                        },
                        "manager": {
                            "description": "In order to allow you to use the UgoZER application in the best conditions, \n                    we advise you to follow the following steps which will guide you in the first steps of the \n                    handling and the parameter setting of your Other business manager space."
                        },
                        "butler": {
                            "description": "In order to allow you to use the UgoZER application in the best conditions, \n                    we advise you to follow the following steps which will guide you in the first steps of the \n                    handling and the parameter setting of your Concierge space.",
                            "steps": {
                                "institution": {
                                    "step_state": "pending",
                                    "step_next": "claimed",
                                    "title": "Institution",
                                    "description": "Link to your institution",
                                    "subtitle": {
                                        "part_1": "Link me to my hotel",
                                        "part_2": "You can link to your hotel directly to the page",
                                        "link": "My institution"
                                    },
                                    "content": "Search for your hotel in the list of establishments using the search bar and \n                            the filters available in the left section of the page. Once you have found your\n                            establishment, create an application by clicking on the <strong>Apply to join</strong>\n                            button."
                                }
                            }
                        },
                        "tourist_office": {
                            "description": "In order to allow you to use the UgoZER application in the best conditions, \n                    we advise you to follow the following steps which will guide you in the first steps of the \n                    handling and the parameter setting of your Tourist office space.",
                            "steps": {
                                "employees": {
                                    "step_state": "claimed",
                                    "step_next": "employee-added",
                                    "title": "Employees",
                                    "description": "Manage your employees"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "In order to allow you to use the UgoZER application in the best conditions, \n                    we advise you to follow the following steps which will guide you in the first steps of the \n                    handling and the parameter setting of your Tourist office employee space.",
                            "steps": {
                                "institution": {
                                    "step_state": "pending",
                                    "step_next": "claimed",
                                    "title": "Institution",
                                    "description": "Link to your institution",
                                    "subtitle": {
                                        "part_1": "Link me to my tourist office",
                                        "part_2": "You can link to your tourist office directly to the page",
                                        "link": "My institution"
                                    },
                                    "content": "Search for your tourist office in the list of establishments using the search bar\n                            and the filters available in the left section of the page. Once you have found your\n                            establishment, create an application by clicking on the <strong>Apply to join</strong>\n                            button."
                                }
                            }
                        },
                        "done": {
                            "title": "Congratulations!",
                            "description": "You have now completed the various steps for setting up the UgoZER application.",
                            "faq": {
                                "part_1": "If you need more help, you can find articles in the",
                                "link": "FAQ",
                                "part_2": "section of the application"
                            },
                            "help": "You can also find contextual help through the <strong>Help</strong> present on some pages."
                        },
                        "shared": {
                            "claims": {
                                "step_state": "pending",
                                "step_next": "claimed",
                                "title": "Claim",
                                "description": "Claim your institutions",
                                "notice": {
                                    "title": "Please note",
                                    "description": "You can find this explanation by clicking on the <strong>Help</strong> \n                                button at the top right of the points of interest list."
                                },
                                "documents": {
                                    "label": "Documents",
                                    "title": "Claim documents",
                                    "description": "To claim your institution, you will need to  prepare the following\n                                documents:",
                                    "company": {
                                        "title": "If you are a company",
                                        "kbis": "A KBIS extract",
                                        "identity_piece": "A proof of identity of the legal representative in the course of\n                                    valid"
                                    },
                                    "individual": {
                                        "title": "If you are a self-business",
                                        "identity_piece": "A valid piece of identification",
                                        "registration_document": "A certificate of registration of the self-business"
                                    },
                                    "association": {
                                        "title": "If you are an association",
                                        "statutes": "Associations statutes",
                                        "identity_piece": "A proof of identity of the legal representative in the course of\n                                    valid"
                                    }
                                }
                            },
                            "payments": {
                                "step_state": "employee-added",
                                "step_next": "done",
                                "title": "Payment",
                                "description": "Receive tips",
                                "subtitle_1": "In order for your concierges to receive tips, you need to add banking information.",
                                "subtitle_2": "To do this:",
                                "steps": {
                                    "my_profile": {
                                        "part_1": "Go to the page",
                                        "part_2": "My profile"
                                    },
                                    "bank": {
                                        "create": "Click on the <strong>Add an account</strong> button in the \n                                    <strong>Bank Account</strong> section"
                                    },
                                    "easytransac": {
                                        "create": "Click on the <strong>Create</strong> button in the \n                                    <strong>EasyTransac</strong> section",
                                        "created": "The account is created automatically on the basis of the data entered \n                                    on your profile",
                                        "connection": "Click on the link \n                                    <strong>Go to EasyTransac Dashboard</strong> and log in with the credentials you \n                                        received by email",
                                        "form": "Fill out the form on EasyTransac to activate your account payment account",
                                        "warning": {
                                            "title": "KYC level",
                                            "description": "Depending on the KYC level of your EasyTransac account, you may \n                                        be able to receive payments but not transfer funds to a regular bank \n                                        account. funds to a regular bank account. For this you will need to KYC level \n                                        2 and provide certain documents in the EasyTransac interface. the EasyTransac \n                                        interface."
                                        }
                                    }
                                }
                            },
                            "actions": {
                                "skip_step": "Skip step",
                                "skip_tutorial": "Skip tutorial"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "Are you sure you want to skip this step?",
                                    "success": "The step was well ignored!"
                                },
                                "skip_tutorial": {
                                    "confirm": "Are you sure you want to skip this tutorial?",
                                    "success": "The tutorial was well ignored!"
                                }
                            }
                        },
                        "no_tutorial": {
                            "title": "No available tutorial",
                            "content": "No tutorial has been setup for this role or you're connected as an administrator."
                        },
                        "messages": {
                            "claim": "Your claim has been validated.",
                            "contest": "Your dispute has been sent to our team.",
                            "point_of_interest": "The point of interest has been created, you can now claim it.",
                            "payment": "Your banking information has been successfully recorded."
                        },
                        "notices": {
                            "add_butler": {
                                "title": "Add a concierge",
                                "content": "To use the application, you must create a concierge account for your establishment\n                        from which information will be sent to travelers.",
                                "content_2": "To activate the concierge account, your concierge will need to confirm their email\n                        address and create their password."
                            },
                            "add_employee": {
                                "title": "Add an employee",
                                "content": "To use the application, you must create an employee account for your establishment\n                        from which information will be sent to travelers.",
                                "content_2": "To activate the employee account, your employee will need to confirm their email\n                        address and create their password."
                            }
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "verify": {
                    "success": "Your credential has been successfully verified",
                    "already_verified": "Your credential is already verified",
                    "impossible": "This credential's verification is not possible anymore",
                    "not_received": "Didn't get the confirmation link?",
                    "expired": "The link has expired, ask for a new one",
                    "sent": {
                        "title": "Link sent",
                        "email_1": "An e-mail will be sent to {email}, if this address is not already in use.",
                        "email_2": "This e-mail contains a link that will allow you to confirm your address.",
                        "email_3": "Your email will be updated as soon as you access the link.",
                        "again": "Send again",
                        "refresh": "Did you click on the link ? Refresh the application",
                        "correct": "Correct the chosen credential",
                        "wait_before": "Please wait {seconds} seconds before sending again"
                    }
                },
                "validate": {
                    "success": "Credential has been successfully validated (if eligible)"
                }
            },
            "user_geoloc_settings": {
                "update": {
                    "success": "Geolocation settings have been updated"
                },
                "geolocation": {
                    "intro": "Geolocation allows you to find the nearest points of interest.",
                    "error": {
                        "title": "Geolocation failed",
                        "intro": "To view points of interest around you:",
                        "allow_browser": "Allow your browser to geolocate you in your phone's settings",
                        "allow_site": "Allow UgoZER to geolocate you in your browser settings"
                    }
                },
                "not_configured": {
                    "subject": "You have not yet defined your geolocation settings.",
                    "body1": "Select a location of your choice to view a selection of points of interest near it.",
                    "body2": "It is also possible to display only the points of interest near you.",
                    "do_not_ask_again": "Do not ask me again until the application is restarted"
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "Notifications settings have been updated"
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "Privacy settings have been updated"
                }
            },
            "users": {
                "profile": {
                    "unnamed": "No name",
                    "alerts": {
                        "pending_validation": {
                            "title": "Pending validation",
                            "message": "This user hasn't accepted your demand yet."
                        },
                        "blocked": {
                            "title": "This user blocked you...",
                            "message": "You can no longer interact with this user."
                        },
                        "missing_infos": {
                            "title": "Complete your profile",
                            "message": "In order to take advantage of all the features of the application, please complete the following information:"
                        }
                    },
                    "bank_account_number_missing": "Please provide your banking information to receive your payments.",
                    "qr_code": "Scan me to see my profile",
                    "public_id": "Or use this identifier:",
                    "edit_your_profile": "Edit your profile",
                    "share_profile": "Share your profile",
                    "your_personal_information": "Your personal information",
                    "security": "Security",
                    "phone_missing": "You have not fill in a verified phone number",
                    "email_missing": "You have not fill in a verified email.",
                    "password_missing": "You have not fill in a password.",
                    "edit_password": "Edit your password",
                    "edit_email": "Edit your email",
                    "edit_phone": "Edit your phone number",
                    "fill_country": "Please specify your country",
                    "form": {
                        "poi_label": "Linked institution"
                    },
                    "delete_account": {
                        "title": "Account deletion",
                        "warning": "Your account, as well as all information related to your account, will be immediately deleted without possibility of recovery.",
                        "instructions": "To confirm the final deletion of your account, please enter the word \"DELETE\" in the  field below and click on the \"Confirm\" button.",
                        "easy_transac": "Your EasyTransac account will not be deleted.",
                        "success": "Your account has been deleted."
                    },
                    "preferences": "Preferences",
                    "manage_my_subscription": "Manage my subscription",
                    "subscribe_to_receive_payments": "You must be subscribed to receive payments.",
                    "avatar": {
                        "section": {
                            "title": "Choosing a predefined avatar",
                            "description": "You can select a predefined avatar from the list below",
                            "cta": "Use this avatar"
                        },
                        "defaults": {
                            "male": "Male",
                            "female": "Female"
                        },
                        "success": "Your avatar has been successfully updated!"
                    }
                },
                "update": {
                    "success": "Profile has been updated"
                },
                "send_link": {
                    "success": "Link to application has been sent",
                    "error": "Cannot send link, do user have an email?"
                },
                "search": {
                    "no_results": "No users found"
                },
                "report": {
                    "heading": "Report a user",
                    "choose_reason": "Choose a reason for reporting this user \"{user_name}\"",
                    "success": "The user has been reported!"
                },
                "index": {
                    "search": "Search a user",
                    "no_results": "No user found",
                    "hint": "A user can be found by his nickname or his public ID."
                },
                "pages": {
                    "show": {
                        "sections": {
                            "profile": "My profile",
                            "delete": "Delete my account?"
                        }
                    }
                },
                "butlers": "Concierges",
                "finish": {
                    "success": "Tutorial changed to finish state"
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "No contacts found",
                    "pending_requests": "Your pending contacts"
                },
                "search": {
                    "no_results": "No contacts found"
                },
                "messages": {
                    "add": {
                        "confirm": "Do you really want to add this contact?",
                        "success": "Contact request successfully done!",
                        "error": "An error occurs during contact request..."
                    },
                    "approve": {
                        "confirm": "Do you really want to accept this contact request?",
                        "success": "Contact request accepted!"
                    },
                    "decline": {
                        "confirm": "Do you really want to decline this contact request?",
                        "success": "Contact request declined!"
                    },
                    "block": {
                        "confirm": "Do you really want to block this user?",
                        "success": "This contact has been blocked!"
                    },
                    "unblock": {
                        "confirm": "Do you really want to unblock this user?",
                        "success": "This contact has been unblocked!"
                    }
                },
                "tabs": {
                    "approved": "Approved",
                    "pending": "Pending",
                    "sent": "Sent",
                    "blocked": "Blocked"
                },
                "no_results": {
                    "approved": "No approved contacts",
                    "pending": "No pending contacts requests",
                    "sent": "No sent contacts requests",
                    "blocked": "No blocked contacts"
                }
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} does not match.",
            "currency_scale": "The {attribute}'s numbers of digits after the decimal point must not exceed {scale}.",
            "current_password": "The {attribute} does not match your password.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "luhn": "{attribute} is an invalid number.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items.",
                "width": "{attribute} must not exceed {max} pixels in width.",
                "height": "{attribute} must not exceed {max} pixels in height."
            },
            "max_digits": "{attribute} must not exceed {max} digits.",
            "mimes": "The {attribute} must have a valid type.",
            "mimetypes": "The {attribute} must have a valid type.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "min_digits": "{attribute} must be at least {min} digits.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "notification_setting": "The {attribute} format is invalid (expected format is array[bool]).",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "phone": "The {attribute} must be a valid phone number.",
            "present": "The {attribute} field must be present.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "real_name_characters": ":{attribute} can only contain letters, spaces, dots,\n        hyphens or apostrophes.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "same_currency": "All items must used the same currency.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "tag_same_poi_category": "{attribute} should all belongs to the same category!",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                },
                "h-captcha-response": {
                    "required": "Please verify that you are not a robot.",
                    "HCaptcha": "Captcha error! try again later or contact site admin."
                }
            },
            "attributes": {
                "accounting_file": "accounting file",
                "actions": "actions",
                "activated_at": "activated on",
                "active": "active",
                "additional_information": "additional information",
                "address": "address",
                "addresses": "addresses",
                "admin_message": "message",
                "administrative_area": "administrative area",
                "amount": "amount",
                "app": "application",
                "app_fees": "fees",
                "approved_at": "approved at",
                "auto_renewal_enabled": "auto renewal enabled",
                "has_employees": "has employees",
                "archived_at": "Archived at",
                "association_statutes": "statutes",
                "association_legal_representative_identity_piece": "proof of identity of the legal representative",
                "attached_files": "Attached files",
                "audio": "audio",
                "audios": "audios",
                "audio_refreshing": "audio refreshing",
                "authenticated_once": "authenticated once",
                "avatar": "avatar",
                "bank_account_number": "bank account number",
                "bank_file": "bank file",
                "bank_transfer": "bank transfer",
                "bank_transfer_institutions": "bank transfers institution",
                "butler": "concierge",
                "butler_point_of_interest": "concierge's institution",
                "canceled_at": "Canceled at",
                "category": "category",
                "categories": "categories",
                "category_id": "category id",
                "causer": "causer",
                "causer_id": "causer id",
                "causer_type": "causer type",
                "certified": "certified",
                "city": "city",
                "collection_name": "collection name",
                "claim_confirmation": "claim confirmation",
                "code": "code",
                "company_type": "company type",
                "company_registration_document": "registration document",
                "company_statutes": "statutes",
                "company_legal_representative_identity_piece": "proof of identity of the legal representative",
                "completed_at": "completed at",
                "contact": "contact",
                "contact_email": "contact email",
                "contact_phone": "contact phone",
                "content": "content",
                "contestant": "contestant",
                "contestant_email": "email",
                "contestant_first_name": "first name",
                "contestant_last_name": "last name",
                "contestant_message": "message",
                "contestant_phone": "phone",
                "contestant_company_type": "company type",
                "conversions_disk": "conversion disk",
                "coords": "coordinates",
                "countries": "countries",
                "countries_codes": "countries",
                "country": "country",
                "country_code": "country code",
                "created": "created",
                "created_at": "created at",
                "creator": "creator",
                "credential": "credential",
                "currency": "currency",
                "current_password": "current password",
                "customers_qty": "customers qty",
                "custom_amount": "custom amount",
                "custom_properties": "custom properties",
                "date": "date",
                "dates": "dates",
                "days": "days",
                "declined_at": "declined at",
                "deleted_at": "deleted at",
                "deletion_confirmation": "deletion confirmation",
                "denial_reason": "denial reason",
                "dependent_locality": "dependent locality",
                "description": "description",
                "details": "details",
                "disk": "disk",
                "display": "display",
                "displayed_name": "displayed name",
                "distance": "distance",
                "documents": "documents",
                "dt_created_by": "Created by",
                "dt_last_update": "Last DataTourisme update",
                "dynamic_translations_enabled": "dynamic translations enabled",
                "email": "email",
                "employee": "employee",
                "end_date": "end date",
                "engagement_period": "engagement period",
                "engaged_until": "engaged until",
                "event": "event",
                "excursion": "itinerary",
                "existing_user": "existing user",
                "expirable": "expirable",
                "expired_at": "expired at",
                "expired_on": "end date of stay",
                "expiry_date": "Expiry date",
                "expiry": "expiration",
                "extranet": "extranet",
                "fallback_locale": "fallback locale",
                "favorite": "favorite itinerary",
                "featured": "featured",
                "file": "file",
                "file_name": "file name",
                "filter": "filter",
                "financial": "financial",
                "first_name": "first name",
                "formatted_address": "formatted address",
                "free_period": "free period",
                "free_period_ended_at": "free period ended at",
                "front": "front",
                "gallery": "gallery",
                "gdpr": "GDPR",
                "general": "General",
                "generated_conversions": "generated conversions",
                "gross_amount": "gross amount",
                "hour": "hour",
                "hours": "hours",
                "id": "id",
                "image": "image",
                "images": "images",
                "import_source": "import source",
                "import_synchronizing": "Activate automatic update",
                "individual_first_identity_piece": "identity piece",
                "individual_second_identity_piece": "second identity piece",
                "individual_registration_document": "registration document",
                "information": "information",
                "institution": "institution",
                "institution_address": "institution address",
                "institution_id": "institution",
                "institution_title": "institution title",
                "invitation": "invitation",
                "invitation_accepted": "invitation accepted",
                "invitation_status": "invitation status",
                "invited_at": "invited at",
                "invited_by": "invited by",
                "invitees": "invitees",
                "interaction_type": "interaction type",
                "items": "items",
                "is_geocoding": "geolocation in progress",
                "iso_code": "ISO code",
                "key": "key",
                "kind": "kind",
                "label": "label",
                "last_geoloc": "last geolocation",
                "last_logged_at": "last logged at",
                "last_login_ip": "last login IP",
                "last_name": "last name",
                "last_validated_at": "last validation",
                "lat": "latitude",
                "latitude": "latitude",
                "line1": "address line 1",
                "line2": "address line 2",
                "link": "link",
                "lng": "longitude",
                "locale": "language",
                "login": "connection",
                "longitude": "longitude",
                "logo": "logo",
                "manipulations": "manipulations",
                "media": "media",
                "message": "message",
                "measurement_system": "measurement system",
                "metadata": "metadata",
                "max_amount_per_checkout": "maximum amount per checkout",
                "min_amount_per_checkout": "minimum amount per checkout",
                "mime_type": "MIME type",
                "misc": "miscellaneous",
                "model": "record",
                "model_type": "record type",
                "name": "name",
                "net_amount": "net amount",
                "new_password": "new password",
                "new_password_confirmation": "new password confirmation",
                "next_payment_at": "next payment",
                "nickname": "nickname",
                "not_validated_activities": "not validated activities",
                "notes": "further information",
                "order_column": "order",
                "origin": "origin",
                "opening_hours": "Opening hours",
                "owner": "owner",
                "owner_email": "email",
                "owner_first_name": "first name",
                "owner_last_name": "last name",
                "owner_phone": "phone",
                "password": "password",
                "password_confirmation": "password confirmation",
                "payable": "payable",
                "payee": "payee",
                "payee_id": "payee",
                "payee_name": "payee name",
                "payee_email": "email",
                "payee_phone": "phone",
                "payee_country": "country",
                "payer": "payer",
                "payer_id": "payer",
                "payer_name": "payer name",
                "payer_email": "email",
                "payer_phone": "phone",
                "payer_country": "country",
                "payment_received": "payment received",
                "payment_service": "payment service",
                "payment_thanks": "payment thanks",
                "payment_service_account_id": "ID payment account",
                "payment_service_transaction_id": "ID payment transaction",
                "payment_type": "payment type",
                "period": "period",
                "point_of_interest_category": "category",
                "point_of_interest_categories": "categories",
                "point_of_interest": "point of interest",
                "point_of_interest_event_expired": "Event expired",
                "points_of_interest": "points of interest",
                "points_of_interest_count": "points of interest count",
                "post_category": "post category",
                "phone": "phone",
                "plan": "plan",
                "pro_file": "pro file",
                "provider": "provider",
                "communication_consent": "Personalized communications",
                "communication_consent_alt": "Communications?",
                "price": "price",
                "price_excluding_taxes": "price excluding taxes",
                "prices": "prices",
                "private_title": "private title",
                "priority": "priority",
                "privacy_policy": "privacy policy",
                "privacy_policy_accepted": "privacy policy accepted",
                "privacy_policy_version": "privacy policy version",
                "private": "private",
                "profile_picture": "Profile picture",
                "properties": "properties",
                "properties_comparison": "updated properties",
                "public": "public",
                "public_id": "public ID",
                "published_at": "published at",
                "quantity": "quantity",
                "quitted_at": "quitted at",
                "radius": "radius",
                "rates": "rates",
                "reason_type": "reason",
                "reason_details": "details",
                "recipient": "recipient",
                "recipients": "recipients",
                "recipients_alt": "registered travellers",
                "remember": "remember me",
                "removed_at": "removed at",
                "reports": "reports",
                "requested_at": "requested at",
                "reservation_created": "reservation created",
                "reservation_date": "date",
                "reservation_time": "time",
                "resolved_at": "Resolved at",
                "responsive_images": "responsive images",
                "roles": "roles",
                "room_number": "room number",
                "scout_searchable": "indexable",
                "searchable": "searchable",
                "sender": "sender",
                "senders": "senders",
                "service": "service",
                "settings": "settings",
                "shareable": "shareable",
                "share_created": "share created",
                "show_contact_email": "Contact email visibility",
                "show_contact_phone": "Contact phone visibility",
                "show_profile_picture": "Avatar's visibility",
                "show_real_name": "First and last name's visibility",
                "siret": "SIRET",
                "size": "size",
                "source": "source",
                "source_id": "source id",
                "starable": "noted with stars",
                "stars": "stars",
                "start_date": "start date",
                "state": "state",
                "states": "states",
                "static_translations_enabled": "static translations enabled",
                "status": "status",
                "subject": "subject",
                "subject_id": "subject id",
                "subject_type": "subject type",
                "summary": "summary",
                "support_category": "category",
                "support_messages_count": "support messages count",
                "support_request": "support request",
                "support_request_message_received": "Answer received in a Support Request",
                "support_request_archived": "Support Request archived",
                "suspended_at": "suspended at",
                "tag": "Tag",
                "tags": "Tags",
                "target": "target",
                "terms_condition_accepted": "terms and conditions accepted",
                "terms_condition_version": "terms and conditions version",
                "terms_conditions": "terms and conditions",
                "thanks": "thanks",
                "thanked": "thanked",
                "thanked_at": "thanks date",
                "thanks_message": "thanks message",
                "thanks_sent": "thanks sent",
                "title": "title",
                "tipping_fees": "tipping fees",
                "tips_enabled": "tips enabled",
                "total_amount": "total amount",
                "traveller": "traveller",
                "traveller_folder": "traveller folder",
                "traveller_folder_approved": "traveller folder approved",
                "traveller_folder_archived": "traveller folder archived",
                "traveller_folder_pending": "traveller folder pending",
                "traveller_folder_thanks": "thanks for the stay",
                "tutorial_state": "Tutorial state",
                "type": "type",
                "updated_at": "updated at",
                "user_id": "user",
                "user": "user",
                "user_report": "user (who reports)",
                "users": "users",
                "utm_campaign": "Campaign",
                "utm_medium": "Medium",
                "utm_settings": "UTM parameters",
                "utm_source": "Source",
                "uuid": "uuid",
                "value": "value",
                "validated_activities": "validated activities",
                "validated_at": "validated at",
                "vat": "vat",
                "vat_rate": "vat rate",
                "version": "version",
                "video": "video",
                "videos": "videos",
                "website": "website",
                "zip": "zip",
                "bank_payee": "Payee",
                "bank_iban": "IBAN",
                "bank_bic": "BIC",
                "bank_name": "Bank name",
                "bank_city": "Bank city",
                "bank_country": "Bank country",
                "bank_transfer_reason": "Reason for transfer",
                "bank_account": "Bank account",
                "specified": "Specified",
                "not_specified": "Not specified",
                "passwords_must_different": "Both accounts cannot have the same password.",
                "emails_must_different": "Both accounts cannot have the same email address."
            }
        }
    },
    "es": {
        "The {attribute} must be at least {length} characters and contain at least one number.": "El {attribute} debe tener al menos {length} caracteres y contener al menos un número.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "El {attribute} debe tener al menos {length} caracteres y contener al menos un carácter especial y un número.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "El {attribute} debe tener al menos {length} caracteres y contener al menos un carácter especial.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "El {attribute} debe tener al menos {length} caracteres y contener al menos un carácter en mayúscula y un número.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "El {attribute} debe tener al menos {length} caracteres y contener al menos un carácter en mayúscula y un carácter especial.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "El {attribute} debe tener al menos {length} caracteres y contener al menos un carácter en mayúscula, un número y un carácter especial.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "El {attribute} debe tener al menos {length} caracteres y contener al menos un carácter en mayúscula.",
        "The {attribute} must be at least {length} characters.": "El {attribute} debe tener al menos {length} caracteres.",
        "The {attribute} must contain at least one letter.": "El {attribute} debe contener al menos una letra.",
        "The {attribute} must contain at least one number.": "El {attribute} debe contener al menos un número.",
        "The {attribute} must contain at least one symbol.": "El {attribute} debe contener al menos un símbolo.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "El {attribute} debe contener al menos una letra mayúscula y una minúscula.",
        "auth": {
            "failed": "Estas credenciales no coinciden con nuestros registros. ¿Estás seguro de que no usaste este correo electrónico para una cuenta {type}?",
            "throttle": "Demasiados intentos de inicio de sesión. Inténtelo de nuevo en {seconds} segundos.",
            "throttle_cascade": "Demasiados intentos de inicio de sesión. Inténtelo de nuevo en {remaining}."
        },
        "documents": {
            "display": {
                "description": "Exhibición en formato A6 con código QR de enlace y el logo de su institución.",
                "title": "Pantalla - Tarjeta A6"
            },
            "qrcode": {
                "description": "El QRCode por sí solo permite vincular al viajero directamente con su institución.",
                "filename": "Código QR",
                "title": "Enlace código QR"
            }
        },
        "enums": {
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "Creado",
                "deleted": "Eliminado",
                "updated": "Actualizado",
                "validated": "Validado"
            },
            "App\\Enums\\BinaryChoiceEnum": [
                "Denegado",
                "Aceptado"
            ],
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "association": "Asociación (o fundación)",
                "company": "Empresa (pública/privada)",
                "individual": "Individual",
                "undefined": "Indefinido"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "DE": "Alemania",
                "ES": "España",
                "FR": "Francia",
                "IT": "Italia",
                "TH": "Tailandia",
                "undefined": "Indefinido"
            },
            "App\\Enums\\ContextsEnum": {
                "city_hall": "Ayuntamiento",
                "hotel": "Hotel",
                "manager": "Gerente",
                "tourist_office": "Oficina de Turismo",
                "traveller": "Viajero"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "Euro",
                "usd": "Dólar"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Friday": "Viernes",
                "Monday": "Lunes",
                "Saturday": "Sábado",
                "Sunday": "Domingo",
                "Thursday": "Jueves",
                "Tuesday": "Martes",
                "Wednesday": "Miércoles"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "Personalizado",
                "root": "Mis plantillas",
                "ugozer_app": "Aplicación UgoZER",
                "ugozer_pro": "UgoZER Pro"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "Imperial",
                "metric": "Métrico"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "En la aplicación",
                "email": "Correo electrónico"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "customer": "Cliente",
                "partner": "Pareja"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "easytransac": "EasyTransac",
                "paypal": "PayPal",
                "stripe": "Raya"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "subscription": "Suscripción",
                "tip": "Consejo"
            },
            "App\\Enums\\PrivacySettingsEnum": {
                "contacts": "Mis contactos",
                "everyone": "Todos",
                "nobody": "Nadie"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "illegal": "Ilegal",
                "incorrect_data": "Información incorrecta",
                "other": "Otro",
                "shocking": "Impactante",
                "spam": "Correo basura"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "Desayuno",
                "dinner": "Cena",
                "lunch": "Almuerzo",
                "null": "N / A"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "Facebook",
                "google": "Google",
                "instagram": "Instagram",
                "linkedin": "LinkedIn",
                "tiktok": "Tik Tok",
                "twitter": "X (Twitter)",
                "wechat": "WeChat"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "1 mes",
                "one year": "1 año"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "1 mes",
                "one year": "1 año",
                "six months": "6 meses",
                "three months": "3 meses"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "expired": "Suscripción finalizada",
                "free period ended": "Periodo gratuito finalizado",
                "payment default": "Pago predeterminado"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "Aplicación",
                "extranet": "Extranet",
                "front": "Frente"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "Archivado",
                "canceled": "Cancelado",
                "pending_support": "Soporte pendiente",
                "pending_user": "Usuario pendiente",
                "resolved": "Resuelto"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "A mi alrededor",
                "customized": "Personalizado",
                "staying_hotel": "Cerca del hotel alojado"
            },
            "App\\Enums\\UserRoleEnum": {
                "butler": "Mayordomo",
                "contributor": "Contribuyente",
                "hotelier": "Hotelero",
                "manager": "Gerente",
                "moderator": "Moderador",
                "superadmin": "Superadministrador",
                "touristOffice": "Oficina de Turismo",
                "touristOfficeEmployee": "PARA Empleado",
                "traveller": "Viajero"
            }
        },
        "nova": {
            "lens": {
                "user": {
                    "user_origin": "Origen de los usuarios"
                }
            },
            "menu": {
                "administration": "Administración",
                "content": "Contenido",
                "excursions": "Itinerarios",
                "import_poi": "Importar puntos de interés",
                "miscellaneous": "Misceláneas",
                "parameters": "Parámetros",
                "pois": "Puntos de interés",
                "subscriptions": "Suscripciones",
                "support": "Apoyo",
                "users": "Usuarios"
            },
            "s3_private_download": {
                "link": {
                    "expiration": "El enlace caducará después de 30 minutos, si este es el caso, simplemente actualice la página.",
                    "open": "Ver el documento"
                },
                "no_document": "Sin documentos relacionados",
                "not_found": "El documento no se pudo encontrar en la unidad S3..."
            },
            "user_menu": {
                "profile": "Mi perfil"
            }
        },
        "pagination": {
            "go_page": "Ir a la página {page}",
            "next": "Próximo \"",
            "next_alt": "Próximo",
            "of": "de",
            "pagination_navigation": "Navegación de paginación",
            "previous": "\" Anterior",
            "previous_alt": "Anterior",
            "results": "resultados",
            "showing": "Demostración",
            "to": "a"
        },
        "passwords": {
            "reset": "¡Tu contraseña ha sido restablecida!",
            "sent": "¡Le hemos enviado por correo electrónico el enlace para restablecer su contraseña!",
            "throttled": "Espere antes de volver a intentarlo.",
            "token": "Este token de restablecimiento de contraseña no es válido.",
            "user": "Ningún usuario correspondiente."
        },
        "ttmt": {
            "account_deletion": {
                "confirmation": "En la página que se abre, confirme la eliminación ingresando la palabra <strong>ELIMINAR</strong>\n        y haga clic en <strong>Confirmar</strong>.",
                "content": {
                    "asking": "En caso de una solicitud de eliminación de cuenta, UgoZER eliminará la cuenta y los datos del usuario,\n            a menos que deban conservarse debido a requisitos legales o reglamentarios, por motivos de seguridad, fraude\n            prevención o debido a un problema relacionado con la cuenta del usuario, como un reclamo impago, no resuelto o\n            disputar.",
                    "conservation": {
                        "link": "Política de privacidad.",
                        "part_1": "UgoZER conserva los datos de los usuarios durante el tiempo necesario para las finalidades descritas en el",
                        "part_2": "De acuerdo con las recomendaciones de la CNIL, una cuenta que no se utilice durante 3 años será eliminada.",
                        "part_3": "Los usuarios pueden solicitar la eliminación de sus cuentas en cualquier momento. UgoZER podrá conservar los datos del usuario después de un\n                solicitud de eliminación de acuerdo con requisitos legales o reglamentarios, o por los motivos establecidos en este\n                declaración."
                    },
                    "instructions": "Si desea eliminar su cuenta UgoZER, siga las instrucciones a continuación."
                },
                "logout": "Se cerrará su sesión y la cuenta y los datos asociados se eliminarán inmediatamente.",
                "pro": {
                    "instructions": {
                        "delete": "Luego haga clic en el botón <strong>Eliminar</strong> frente a <strong>Eliminación de cuenta.\n                </strong>,",
                        "profile": {
                            "link": "perfil",
                            "part_1": "Ir a tu",
                            "path": "(haciendo clic en tu avatar > Mi perfil),"
                        }
                    },
                    "subtitle": "Para eliminar su cuenta profesional:",
                    "title": "cuenta profesional"
                },
                "title": "Eliminación de cuenta",
                "traveller": {
                    "instructions": {
                        "delete": "Luego haga clic en el botón <strong>Eliminar</strong> frente a <strong>¿Eliminar mi cuenta?\n                </strong>",
                        "profile": {
                            "link": "perfil",
                            "part_1": "Ir a tu",
                            "path": "(menú del icono de hamburguesa > Configuración > Perfil),"
                        }
                    },
                    "subtitle": "Para eliminar su cuenta de viajero:",
                    "title": "Cuenta de viajero"
                }
            },
            "activity_logs": {
                "summary": {
                    "created": {
                        "intro": "El sujeto ha sido creado con estas propiedades:"
                    },
                    "deleted": {
                        "intro": "El asunto ha sido eliminado con estas propiedades:"
                    },
                    "updated": {
                        "attribute_from_to": "{attribute} se ha actualizado de \"{valor_antiguo}\" a \"{valor_nuevo}\"",
                        "intro": "El asunto ha sido actualizado:"
                    },
                    "validated": {
                        "intro": "La materia ha sido validada:"
                    }
                }
            },
            "auth": {
                "credential_verification": {
                    "intro": "Ingresa tu credencial, se te enviará un enlace de confirmación.",
                    "intro2": "Su credencial se actualizará tan pronto como acceda al enlace."
                },
                "expiration": {
                    "session_expired": "Su sesión ha caducado, vuelva a autenticarse"
                },
                "extranet_register": {
                    "form": {
                        "choose_establishment": "Elige tu tipo de establecimiento",
                        "form_title": "Crea tu cuenta",
                        "validate_registration": "Finalizar mi registro"
                    },
                    "success": "Registrado exitosamente, se ha enviado un enlace de verificación a su correo electrónico"
                },
                "login": {
                    "already_logged": "Ya se ha autentificado",
                    "logging_in": "Iniciando sesión...",
                    "success": "Conectado",
                    "use_email": "Iniciar sesión con un correo electrónico",
                    "use_social_networks": "Usa las redes sociales"
                },
                "logout": {
                    "logging_out": "Saliendo de tu cuenta...",
                    "switch": "Cambiar a"
                },
                "messages": {
                    "continue": "Continua con",
                    "extranet_here": "haga clic aquí",
                    "have_account": "¿Ya tienes una cuenta?",
                    "login": "Acceso",
                    "login_subtitle": "(registro o inicio de sesión)",
                    "login_title": "Es mejor con las redes sociales",
                    "no_account": "¿Aún no tienes una cuenta?",
                    "or": "O",
                    "professional": "¿Eres un profesional?",
                    "register": "Registro",
                    "register_mail": "Regístrese con un correo electrónico",
                    "register_title": "Regístrese para desbloquear todas las funciones",
                    "reset_password": "Restablecer la contraseña",
                    "send_magic_link": "Enviar enlace sin contraseña"
                },
                "password_forgotten": {
                    "intro": "Ingresa tu credencial y te enviaremos un enlace para restablecer tu contraseña.",
                    "submit": "Recibir un enlace de reinicio",
                    "success": {
                        "correct_credential": "Credencial correcta",
                        "email_1": "Se le enviará un correo electrónico a {email}, si esta dirección está bien registrada.",
                        "email_2": "Este correo electrónico contiene un enlace que le permitirá restablecer su contraseña.",
                        "send_again": "Enviar de nuevo",
                        "title": "Enlace para restablecer contraseña enviado",
                        "wait_before": "Espere {seconds} segundos antes de volver a enviar"
                    }
                },
                "password_reset": {
                    "submit": "Restablecer su contraseña",
                    "success": "La contraseña ha sido restablecida"
                },
                "register": {
                    "form": {
                        "hide_privacy_policy": "Ocultar política de privacidad",
                        "hide_terms_conditions": "Ocultar términos y condiciones",
                        "privacy_policy": "Acepto la política de privacidad",
                        "show_privacy_policy": "Mostrar política de privacidad",
                        "show_terms_conditions": "Mostrar términos y condiciones",
                        "terms_conditions": "Estoy de acuerdo con los términos y condiciones"
                    },
                    "staying_hotel": "Una vez que te hayas registrado, serás atendido por el hotel cuyo\n            Acabas de escanear el código QR.",
                    "success": {
                        "email_1": "Se enviará un correo electrónico a {email}.",
                        "email_2": "Este correo electrónico contiene un enlace que le permitirá confirmar su dirección y conectarse.",
                        "paired": {
                            "email_1": "Se enviaron dos correos electrónicos a las siguientes direcciones",
                            "email_2": "Cada correo electrónico contiene un enlace para confirmar la dirección y conectar la cuenta asociada."
                        },
                        "title": "Registrado exitosamente"
                    }
                },
                "social_networks": {
                    "authenticate": "Inicia con",
                    "facebook": "Facebook",
                    "google": "Google",
                    "no_email": "No hay ningún correo electrónico asociado a tu red social, no puedes autenticarte en ella",
                    "twitter": "Gorjeo"
                },
                "travel_assistant": "Asistente de viaje gratuito"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "Cuenta bancaria no proporcionada"
                    },
                    "form": {
                        "placeholders": {
                            "bank_city": "Ciudad de su sucursal bancaria.",
                            "bank_name": "Nombre del banco",
                            "bank_transfer_reason": "Ejemplo: \"Consejos UgoZER\"",
                            "bic": "Introduce tu BIC",
                            "iban": "Introduce tu IBAN/BBAN",
                            "payee_name": "Nombre del beneficiario o nombre de la institución",
                            "types": "IBAN/BBAN"
                        }
                    },
                    "lines": {
                        "no_account_yet": "Su cuenta bancaria permitirá a su institución recibir pagos en UgoZER."
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "amount": "cantidad",
                    "amount_to_pay": "monto a pagar",
                    "concierge": "conserje",
                    "currency": "divisa",
                    "customer": "cliente",
                    "date": "fecha",
                    "repartition_key": "clave de distribución",
                    "tip_sharing_form": "formulario para compartir propinas",
                    "tips_received": "propinas recibidas",
                    "total_received": "total recibido"
                }
            },
            "breadcrumbs": {
                "changelogs": {
                    "changelogs": "Registros de cambios"
                },
                "claims": {
                    "claims": "Reclamos"
                },
                "common": {
                    "add": "Agregar",
                    "claim": "Afirmar",
                    "create": "Nuevo",
                    "create_f": "Nuevo",
                    "delete": "Borrar",
                    "download_pdf": "Descargar PDF",
                    "edit": "Editar",
                    "report": "Informe",
                    "share": "Compartir",
                    "show": "Espectáculo"
                },
                "contacts": {
                    "contacts": "Contactos"
                },
                "cookie": {
                    "cookie": "Gestión de cookies"
                },
                "credit": {
                    "credit": "Créditos"
                },
                "dashboard": {
                    "dashboard": "Panel"
                },
                "errors": {
                    "404": {
                        "label": "error 404",
                        "title": "Error 404 no encontrado"
                    }
                },
                "excursions": {
                    "create": "Nuevo itinerario",
                    "excursions": "Itinerarios"
                },
                "faq": {
                    "faq": "Preguntas frecuentes"
                },
                "finances": {
                    "finances": "Finanzas"
                },
                "institutions": {
                    "institution": "Institución",
                    "institutions": "Instituciones"
                },
                "institutions_employees": {
                    "institutions_employees": "mis empleados",
                    "invite": "invitar a un empleado"
                },
                "interactions": {
                    "interactions": "Interacciones"
                },
                "invitations": {
                    "invitations": "Invitaciones"
                },
                "notification_settings": {
                    "notification_settings": "Configuracion de notificaciones"
                },
                "notifications": {
                    "notifications": "Notificaciones"
                },
                "partners": {
                    "customers": "Clientes",
                    "partners": "Socios"
                },
                "points_of_interest": {
                    "add_excursion": "Añadir a un itinerario",
                    "points_of_interest": "Puntos de interés"
                },
                "privacy_policy": {
                    "privacy_policy": "Política de privacidad"
                },
                "privacy_settings": {
                    "privacy_settings": "La configuración de privacidad"
                },
                "reservations": {
                    "reservations": "Reservas"
                },
                "search": {
                    "search": "Buscar"
                },
                "subscriptions": {
                    "subscription": "Suscripción"
                },
                "support_messages": {
                    "add_message": "Añade un mensaje",
                    "support_messages": "Mensajes"
                },
                "support_requests": {
                    "add_request": "Agregar una nueva solicitud de soporte",
                    "support_requests": "Solicitudes de soporte"
                },
                "terms_condition": {
                    "terms_condition": "Términos y condiciones"
                },
                "travellers": {
                    "folders": "Carpetas de viajero",
                    "travellers": "Viajeros"
                },
                "tutorials": {
                    "begin": "Empezando"
                },
                "users": {
                    "edit_credential": "Editar credencial",
                    "profile": "Mi perfil",
                    "users": "Usuarios"
                }
            },
            "changelogs": {
                "archive": {
                    "success": "Los registros de cambios se han archivado (si son elegibles)"
                },
                "draft": {
                    "success": "Se han redactado registros de cambios (si son elegibles)"
                },
                "index": {
                    "sections": {
                        "all": "Todas las actualizaciones",
                        "last": "Última actualización"
                    }
                },
                "publish": {
                    "success": "Se han publicado registros de cambios (si son elegibles)"
                },
                "show": {
                    "version": "Versión"
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "Se ha creado una disputa de reclamo"
                },
                "rejected": {
                    "success": "La disputa de reclamación ha sido rechazada."
                },
                "validated": {
                    "success": "La disputa de reclamación ha sido validada."
                }
            },
            "claims": {
                "form_wizard": {
                    "documents": {
                        "DE": {
                            "association_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere\n                    Version mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "association_statutes": "Handelsregisterauszug",
                            "company_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere\n                    Version mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "company_registration_document": "Handelsregisterauszug",
                            "company_statutes": "Aktuelle und unterschriebene (von der Geschäftsführung) Gesellschaftverträge",
                            "individual_first_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere Version mit\n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_registration_document": "Gewerbeschein, Handlungsbevollmächtigung, Handelsregisterauszug,\n                    Prokura, carta de atribución Steuernummer",
                            "individual_second_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere Version mit\n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)"
                        },
                        "ES": {
                            "association_legal_representative_identity_piece": "Pasaporte, Documento nacional de identidad,\n                    Permiso de conducir (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un\n                    país europeo)",
                            "association_statutes": "Escrituras de constitución o estatutos sociales",
                            "company_legal_representative_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso\n                    de conducir (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un país\n                    europeo)",
                            "company_registration_document": "Extracto del Registro Mercantil Central, Nota Simple del Registro\n                    local mercantil sellado o numérico, Extracto desde los Registradores de España (información\n                    General Mercantil) con fecha de validez de menos de 3 meses o Modelo 600 con fecha de validez\n                    de menos de 3 meses (solo para sociedad civil)",
                            "company_statutes": "Escrituras de constitución o estatutos sociales",
                            "individual_first_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso de conducir\n                    (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un país europeo)",
                            "individual_registration_document": "Agencia Tributaria - Certificado de situación Censal\n                    (Alta Censal)",
                            "individual_second_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso de conducir\n                    (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un país europeo)"
                        },
                        "FR": {
                            "association_legal_representative_identity_piece": "Una pieza de identidad en el curso de validez del\n                    representante legal de la asociación: carta de identidad (recto y verso), pasaporte, título de\n                    séjour (recto y verso), permis de conduire biométrique (recto y verso)",
                            "association_statutes": "Los estatutos de la asociación",
                            "company_legal_representative_identity_piece": "Una pieza de identidad en el curso de validez del\n                    representante legal de la sociedad: carta de identidad (anverso y reverso), pasaporte, título de séjour\n                    (recto y verso), permiso de conducción biométrique (recto y verso)",
                            "company_registration_document": "Extraer KBIS",
                            "company_statutes": "Los estatutos",
                            "individual_first_identity_piece": "Une première pièce d'identité en curso de validez: carta\n                    d'identité (recto y verso), pasaporte, título de séjour (recto y verso), permiso de conducta\n                    biométrica (recto y verso)",
                            "individual_registration_document": "Un certificado de inscripción en el INSEE o un documento\n                    attestant de son inscripción auprès de la Chambre de Commerce (pour un auto-entrepreneur exerçant\n                    una actividad comercial) o auprès de la Chambre des métiers (para un autoempresario ejerciendo\n                    una actividad artesanal)",
                            "individual_second_identity_piece": "Una segunda pieza de identidad en curso de validez: carta\n                    d'identité (recto y verso), pasaporte, título de séjour (recto y verso), permiso de conducta\n                    (recto y verso), dernier avis d'imposition daté de moins d'un an, livret de famille ou\n                    Registro del Pacto Civil de Solidaridad"
                        },
                        "IT": {
                            "association_legal_representative_identity_piece": "Pasaporte, Carta de identidad nacional, La\n                    patente di guida (versión reciente con línea MRZ) o Permesso di residenza (solo se di un paese\n                    europeo)",
                            "association_statutes": "tatuto firmato dal Legale Rappresentante (se non incluso all'interno della\n                    Cámara Visura",
                            "company_legal_representative_identity_piece": "Pasaporte, Carta de identidad nacional, La patente\n                    di guida (versión reciente con línea MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "company_registration_document": "Cámara Visura",
                            "company_statutes": "Statuto firmato dal Legale Rappresentante (se non incluso all'interno della\n                    cámara de visión)",
                            "individual_first_identity_piece": "Pasaporte, Carta de identidad nacional, La patente de guía\n                    (versión reciente con línea MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "individual_registration_document": "Estratto dal registro delle imprese (\"Visura Camerale\") di meno\n                    de 3 meses",
                            "individual_second_identity_piece": "Pasaporte, Carta de identidad nacional, La patente de guía\n                    (versión reciente con línea MRZ) o Permesso di residenza (solo se di un paese europeo)"
                        },
                        "TH": {
                            "association_legal_representative_identity_piece": "Compras Número:\n                    บัตรประจำตัวประชาชน (ทั้งด้านหน้าและด้านหล ัง),\n                    หนังสือเดินทาง, ใบอนุญาตมีถิ่นที่อยู่ (ทั้งด ้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านห ลัง)",
                            "association_statutes": "กฎเกณฑ์ของสมาคม",
                            "company_legal_representative_identity_piece": "Compras ยของบริษัท:\n                    บัตรประจำตัวประชาชน (ด้านหน้าและด้านหลัง), หน ังสือเดินทาง,\n                    ใบอนุญาตมีถิ่นที่อยู่ (ด้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ด้านหน้าและด้านหลัง)",
                            "company_registration_document": "Más KBIS",
                            "company_statutes": "สถานะต่างๆ",
                            "individual_first_identity_piece": "เอกสารแสดงตนที่ถูกต้องฉบับแรก: แรก วประชาชน\n                    (ทั้งด้านหน้าและด้านหลัง), หนังสือเดินทาง, ใบ อนุญาตมีถิ่นที่อยู่ (ทั้งด้านหน้าและด้านลหง ),\n                    ใบขับขี่ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านห ลัง)",
                            "individual_registration_document": "หนังสือรับรองการจดทะเบียนกับอินทรีหรือเอก สารรับรองการจดทะเบียนกับหอการค้า\n                    () ชิงพาณิชย์)\n                    หรือกับหอการค้า (สำหรับผู้ประกอบอาชีพอิสระ ที่ดำเนินกิจการเชิงพาณิชย์)\n                    กิจกรรมงานฝีมือ)",
                            "individual_second_identity_piece": "เอกสารประจำตัวที่สองที่ถูกต้อง: บัตรประจำต ัวประชาชน\n                    (ด้านหน้าและด้านหลัง) หนังสือเดินทาง ใบอนุญา ตมีถิ่นที่อยู่\n                    (ด้านหน้าและด้านหลัง) ใบขับขี่ (ด้านหน้าและด้ านหลัง)\n                    Compras Información adicional ำหรับการจดทะเบียน\n                    สนธิสัญญาความสามัคคีของพลเมือง"
                        }
                    },
                    "steps": {
                        "claim": "Afirmar",
                        "context": "Contexto",
                        "create": "Crear",
                        "documents": "Documentos",
                        "info": "Información",
                        "search": "Buscar"
                    }
                },
                "missing_info": {
                    "success": "La reclamación ha sido rechazada por falta de información."
                },
                "notice": {
                    "actions": {
                        "edit": "Modificar mi solicitud"
                    },
                    "already_claimed": {
                        "description": "Este punto de interés ya ha sido reclamado. Si cree que se trata de un error, puede\n                Complete el formulario de disputa a continuación.",
                        "title": "¡Punto de interés ya reclamado!"
                    },
                    "find_or_new": {
                        "description": "Busque un punto de interés en la lista a continuación o cree uno nuevo.",
                        "title": "Buscar o crear un PDI"
                    },
                    "missing-info": {
                        "infos": "Complete su solicitud haciendo clic en el botón a continuación.",
                        "state": "Su reclamo actualmente está esperando información o documentación adicional:",
                        "title": "En espera de información adicional"
                    },
                    "pending": {
                        "state": "Su solicitud de reclamo se está procesando actualmente",
                        "title": "Solicitud de reclamación pendiente"
                    },
                    "rejected": {
                        "state": "Su solicitud de reclamo fue rechazada por el siguiente motivo:",
                        "title": "Solicitud de reclamación rechazada"
                    }
                },
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "Afirmar",
                            "poi": "Punto de interés",
                            "user": "Usuario"
                        }
                    }
                },
                "rejected": {
                    "success": "La reclamación ha sido rechazada"
                },
                "revoked": {
                    "success": "El reclamo ha sido revocado"
                },
                "states": {
                    "missing-info": "Información faltante",
                    "pending": "Pendiente",
                    "rejected": "Rechazado",
                    "revoked": "Revocado",
                    "validated": "Validado"
                },
                "update": {
                    "success": "El reclamo ha sido actualizado."
                },
                "valid_identity_pieces": "documento de identidad válido, pasaporte o licencia de conducir",
                "validated": {
                    "success": "La reclamación ha sido validada."
                }
            },
            "common": {
                "actions": {
                    "accept": "Aceptar",
                    "accept_contact": "Aceptar solicitud de contacto",
                    "add": "Agregar",
                    "add_contact": "Añadir contactos",
                    "add_excursion": "Añadir al itinerario",
                    "add_message": "Añade un mensaje",
                    "apply": "Aplicar",
                    "approve": "Aprobar",
                    "archive": "Archivo",
                    "attach": "Adjuntar",
                    "block_contact": "Bloquear contacto",
                    "cancel": "Cancelar",
                    "check": "Controlar",
                    "claim": "Afirmar",
                    "close": "Cerca",
                    "configure": "Configurar",
                    "confirm": "Confirmar",
                    "contest": "Concurso",
                    "create": "Crear",
                    "decline": "Denegar",
                    "decline_contact": "Rechazar solicitud de contacto",
                    "delete": "Borrar",
                    "download": "Descargar",
                    "edit": "Editar",
                    "file_preview": "Vista previa del archivo",
                    "filters": "Filtros",
                    "give_a_tip": "Dar una propina",
                    "google_maps": "Abrir en Google Maps",
                    "help": "Ayuda",
                    "hide": "Esconder",
                    "ignore": "Ignorar",
                    "interactions": "Mostrar interacciones",
                    "invite": "Invitar",
                    "load_more": "Carga más",
                    "login": "Acceso",
                    "logout": "Cerrar sesión",
                    "manage": "Administrar",
                    "next": "Próximo",
                    "pause": "Pausa",
                    "pay": "Pagar",
                    "play": "Jugar",
                    "print": "Imprimir",
                    "quit": "Abandonar",
                    "record": "Registro",
                    "register": "Registro",
                    "report": "Informe",
                    "request_to_join": "Solicitud de ingreso",
                    "reset": "Reiniciar",
                    "resume": "Reanudar",
                    "retry": "Rever",
                    "return": "Atrás",
                    "save": "Ahorrar",
                    "save_alt": "Ahorrar",
                    "search": "Buscar",
                    "see_plans": "Ver planos",
                    "see_website": "Ver sitio web",
                    "send": "Enviar",
                    "send_link": "Enviar enlace de la aplicación",
                    "share": "Compartir",
                    "show": "Espectáculo",
                    "start": "Comenzar",
                    "stop": "Detener",
                    "subscribe": "Suscribir",
                    "thank": "Agradecer",
                    "thank_for_stay": "Gracias por la estancia",
                    "traveller_show": "Consultar viajero",
                    "unblock_contact": "Desbloquear contacto",
                    "unblock_this_user": "Desbloquear a este usuario",
                    "update": "Actualizar",
                    "validate": "Validar"
                },
                "all": "todo",
                "and": "y",
                "app_name": "UgoZER",
                "back_to_dashboard": "Volver al tablero",
                "back_to_home": "De vuelta a casa",
                "characters_limit": "{length} / {limit} caracteres",
                "counter": "{dividend} sobre {divisor}",
                "country": "país",
                "dashboard": "Panel",
                "errors": {
                    "deleted_user": "Usuario eliminado",
                    "internal": "¡Algo salió mal!",
                    "not_found": "Extraviado"
                },
                "false": "No",
                "fast": "rápido",
                "home": "Hogar",
                "later": "más tarde",
                "loading": "cargando",
                "messages": {
                    "application_update": {
                        "if_ignore": "Si ignora la actualización, se realizará la próxima vez que inicie la aplicación.",
                        "new_version_available": "Una nueva version esta disponible.",
                        "updating": "Actualizando...",
                        "want_to_refresh": "¿Quieres reiniciar la aplicación para actualizarla?"
                    },
                    "clipboard": {
                        "error": "Error al copiar al portapapeles...",
                        "success": "¡Copiado al portapapeles!"
                    },
                    "no_records": "No se encontraron registros"
                },
                "missing_title": "Título faltante",
                "more_options": "mas opciones",
                "no": "No",
                "none": "ninguno",
                "normal": "normal",
                "not_specified": "No especificado",
                "online": {
                    "connected": "Estás conectado a Internet nuevamente.",
                    "disconnected": "Ya no estás conectado a Internet"
                },
                "oops": "ups",
                "or": "o",
                "qr_code": "Código QR",
                "search": "Buscar",
                "slow": "lento",
                "someone": "alguien",
                "sort": "clasificar",
                "tip": "Consejo",
                "tip_received": "Propina recibida (si está habilitada)",
                "true": "Sí",
                "units": {
                    "kilometer": "kilómetro | kilómetros",
                    "km": "kilómetros",
                    "mi": "mi",
                    "mile": "milla | millas"
                },
                "welcome": "Bienvenido",
                "yes": "Sí"
            },
            "cookies": {
                "details": {
                    "content": "Aquí está la lista de cookies colocadas en esta sección de la aplicación UgoZER según el\n            categorías definidas anteriormente:",
                    "table": {
                        "headers": {
                            "category": "Categoría de uso",
                            "description": "Descripción",
                            "lifespan": "Toda la vida",
                            "mandatory": "Obligatorio",
                            "name": "Nombre de la cookie (o patrón)"
                        },
                        "lines": {
                            "alphanumeric": {
                                "content": "Contiene información sobre la sesión.",
                                "lifespan": "2 horas",
                                "title": "[[{alnum}:]] (secuencia de caracteres alfanuméricos)"
                            },
                            "gmaps": {
                                "content": "Se utiliza para mostrar el iframe de Google Maps.",
                                "lifespan": "180-400 días",
                                "title": "CONSENTIMIENTO, AEC, SOCS... (u otras cookies del dominio google.com)"
                            },
                            "remember": {
                                "content": "Se utiliza para mantener la autenticación del usuario.",
                                "lifespan": "400 días",
                                "title": "recordar_web_*"
                            },
                            "session": {
                                "content": "Contiene información sobre la sesión.",
                                "lifespan": "2 horas",
                                "title": "*_ugozer_session"
                            },
                            "tarteaucitron": {
                                "content": "Le permite mantener la configuración de cookies del usuario.",
                                "lifespan": "365 dias",
                                "title": "tarteaucitrón"
                            },
                            "xsrf": {
                                "content": "Permite proteger la solicitud (solicitudes, formularios...).",
                                "lifespan": "2 horas",
                                "title": "TOKEN-XSRF-*"
                            }
                        }
                    },
                    "title": "Detalles de las cookies depositadas"
                },
                "management": {
                    "content": {
                        "cta": "Cambiar mis preferencias",
                        "line_1": "Algunas características de este sitio dependen del uso de cookies.",
                        "line_2": "El banner de cookies no aparece en la página de inicio durante su navegación si sólo es necesario\n                para el funcionamiento del sitio se depositan.",
                        "line_3": "Puede cambiar sus preferencias haciendo clic en el botón a continuación:"
                    },
                    "title": "Gestión de cookies"
                },
                "notice": {
                    "needed": "Para ver esta sección debes aceptar las cookies {name}.",
                    "refresh": "Si no lo ve después de aceptar las cookies, actualice la página.",
                    "title": "Galletas"
                },
                "services": {
                    "gmaps": "mapas de Google",
                    "youtube": "YouTube"
                },
                "usage": {
                    "content": "Las cookies se pueden utilizar para reconocerlo cuando visita UgoZER, recordar sus preferencias y brindarle\n            ofrecerle una experiencia personalizada y acorde con su configuración. Las cookies también facilitan sus interacciones con\n            UgoZER más rápido y seguro. Además, las cookies nos permiten ofrecerle publicidad tanto dentro como fuera del sitio.\n            Sitios UgoZER.",
                    "table": {
                        "headers": {
                            "category": "Categoría de uso",
                            "description": "Descripción"
                        },
                        "lines": {
                            "ads": {
                                "content": "Podemos utilizar cookies para mostrarle publicidad relevante tanto dentro como fuera del sitio de UgoZER.\n                        También podemos utilizar una cookie para saber si alguien que vio un anuncio posteriormente lo visitó y tomó una\n                        acción (por ejemplo, descargar un documento técnico o realizar una compra) en el sitio del anunciante.\n                        De manera similar, nuestros socios pueden usar una cookie para determinar si hemos mostrado un anuncio y cómo\n                        realizado, o proporcionarnos información sobre cómo interactúa con los anuncios. También podemos trabajar\n                        con un socio para mostrarle un anuncio dentro o fuera de UgoZER, como después de haber visitado la tienda de un socio\n                        sitio o aplicación.",
                                "title": "Publicidad"
                            },
                            "authentication": {
                                "content": "Si ha iniciado sesión en UgoZER, las cookies nos ayudan a mostrarle la información correcta y\n                        personaliza tu experiencia.",
                                "title": "Autenticación"
                            },
                            "security": {
                                "content": "Utilizamos cookies para habilitar y respaldar nuestras funciones de seguridad y para ayudarnos a detectar\n                        actividad maliciosa y violaciones de nuestra",
                                "cta": "Términos y condiciones.",
                                "title": "Seguridad"
                            },
                            "services": {
                                "content": "Las cookies le brindan funciones, información y contenido personalizado.",
                                "title": "Preferencias, características y servicios"
                            }
                        }
                    },
                    "title": "¿Para qué se utilizan las cookies?"
                }
            },
            "countries": {
                "AD": "Andorra",
                "AE": "Emiratos Árabes Unidos",
                "AF": "Afganistán",
                "AG": "Antigua y Barbuda",
                "AI": "Anguila",
                "AL": "Albania",
                "AM": "Armenia",
                "AO": "Angola",
                "AQ": "Antártida",
                "AR": "Argentina",
                "AS": "Samoa Americana",
                "AT": "Austria",
                "AU": "Australia",
                "AW": "Aruba",
                "AX": "Islas Aland",
                "AZ": "Azerbaiyán",
                "BA": "Bosnia y Herzegovina",
                "BB": "Barbados",
                "BD": "Bangladesh",
                "BE": "Bélgica",
                "BF": "Burkina Faso",
                "BG": "Bulgaria",
                "BH": "Bahréin",
                "BI": "Burundi",
                "BJ": "Benín",
                "BL": "San Bartolomé",
                "BM": "islas Bermudas",
                "BN": "Brunéi",
                "BO": "bolivia",
                "BQ": "Caribe Neerlandés",
                "BR": "Brasil",
                "BS": "bahamas",
                "BT": "Bután",
                "BV": "Isla Bouvet",
                "BW": "Botsuana",
                "BY": "Bielorrusia",
                "BZ": "Belice",
                "CA": "Canadá",
                "CC": "Islas Cocos (Keeling)",
                "CD": "Congo - Kinsasa",
                "CF": "República Centroafricana",
                "CG": "Congo - Brazzaville",
                "CH": "Suiza",
                "CI": "Costa de Marfil",
                "CK": "Islas Cook",
                "CL": "Chile",
                "CM": "Camerún",
                "CN": "Porcelana",
                "CO": "Colombia",
                "CR": "Costa Rica",
                "CU": "Cuba",
                "CV": "Cabo Verde",
                "CW": "Curazao",
                "CX": "Isla de Navidad",
                "CY": "Chipre",
                "CZ": "Chequia",
                "DE": "Alemania",
                "DJ": "Yibuti",
                "DK": "Dinamarca",
                "DM": "república dominicana",
                "DO": "República Dominicana",
                "DZ": "Argelia",
                "EC": "Ecuador",
                "EE": "Estonia",
                "EG": "Egipto",
                "EH": "Sahara Occidental",
                "ER": "Eritrea",
                "ES": "España",
                "ET": "Etiopía",
                "FI": "Finlandia",
                "FJ": "Fiyi",
                "FK": "Islas Malvinas",
                "FM": "Micronesia",
                "FO": "Islas Faroe",
                "FR": "Francia",
                "GA": "Gabón",
                "GB": "Reino Unido",
                "GD": "Granada",
                "GE": "Georgia",
                "GF": "Guayana Francesa",
                "GG": "Guernesey",
                "GH": "Ghana",
                "GI": "Gibraltar",
                "GL": "Groenlandia",
                "GM": "Gambia",
                "GN": "Guinea",
                "GP": "Guadalupe",
                "GQ": "Guinea Ecuatorial",
                "GR": "Grecia",
                "GS": "Islas Georgias del Sur y Sandwich del Sur",
                "GT": "Guatemala",
                "GU": "Guam",
                "GW": "Guinea-Bisáu",
                "GY": "Guayana",
                "HK": "RAE de Hong Kong (China)",
                "HM": "Islas Heard y McDonald",
                "HN": "Honduras",
                "HR": "Croacia",
                "HT": "Haití",
                "HU": "Hungría",
                "ID": "Indonesia",
                "IE": "Irlanda",
                "IL": "Israel",
                "IM": "Isla del hombre",
                "IN": "India",
                "IO": "Territorio Británico del Océano Índico",
                "IQ": "Irak",
                "IR": "Irán",
                "IS": "Islandia",
                "IT": "Italia",
                "JE": "Jersey",
                "JM": "Jamaica",
                "JO": "Jordán",
                "JP": "Japón",
                "KE": "Kenia",
                "KG": "Kirguistán",
                "KH": "Camboya",
                "KI": "Kiribati",
                "KM": "Comoras",
                "KN": "San Cristóbal y Nieves",
                "KP": "Corea del Norte",
                "KR": "Corea del Sur",
                "KW": "Kuwait",
                "KY": "Islas Caimán",
                "KZ": "Kazajstán",
                "LA": "Laos",
                "LB": "Líbano",
                "LC": "Santa Lucía",
                "LI": "Liechtenstein",
                "LK": "Sri Lanka",
                "LR": "Liberia",
                "LS": "Lesoto",
                "LT": "Lituania",
                "LU": "Luxemburgo",
                "LV": "Letonia",
                "LY": "Libia",
                "MA": "Marruecos",
                "MC": "Mónaco",
                "MD": "Moldavia",
                "ME": "Montenegro",
                "MF": "San Martín",
                "MG": "Madagascar",
                "MH": "Islas Marshall",
                "MK": "Macedonia del Norte",
                "ML": "Malí",
                "MM": "Myanmar (Birmania)",
                "MN": "Mongolia",
                "MO": "RAE de Macao (China)",
                "MP": "Islas Marianas del Norte",
                "MQ": "Martinica",
                "MR": "Mauritania",
                "MS": "Montserrat",
                "MT": "Malta",
                "MU": "Mauricio",
                "MV": "Maldivas",
                "MW": "Malaui",
                "MX": "México",
                "MY": "Malasia",
                "MZ": "Mozambique",
                "NA": "Namibia",
                "NC": "Nueva Caledonia",
                "NE": "Níger",
                "NF": "Isla Norfolk",
                "NG": "Nigeria",
                "NI": "Nicaragua",
                "NL": "Países Bajos",
                "NO": "Noruega",
                "NP": "Nepal",
                "NR": "Nauru",
                "NU": "Niue",
                "NZ": "Nueva Zelanda",
                "OM": "Omán",
                "PA": "Panamá",
                "PE": "Perú",
                "PF": "Polinesia francés",
                "PG": "Papúa Nueva Guinea",
                "PH": "Filipinas",
                "PK": "Pakistán",
                "PL": "Polonia",
                "PM": "San Pedro y Miquelón",
                "PN": "Islas Pitcairn",
                "PR": "Puerto Rico",
                "PS": "territorios palestinos",
                "PT": "Portugal",
                "PW": "Palaos",
                "PY": "Paraguay",
                "QA": "Katar",
                "RE": "Reunión",
                "RO": "Rumania",
                "RS": "Serbia",
                "RU": "Rusia",
                "RW": "Ruanda",
                "SA": "Arabia Saudita",
                "SB": "Islas Salomón",
                "SC": "Seychelles",
                "SD": "Sudán",
                "SE": "Suecia",
                "SG": "Singapur",
                "SH": "santa elena",
                "SI": "Eslovenia",
                "SJ": "Svalbard y Jan Mayen",
                "SK": "Eslovaquia",
                "SL": "Sierra Leona",
                "SM": "San Marino",
                "SN": "Senegal",
                "SO": "Somalia",
                "SR": "Surinam",
                "SS": "Sudán del Sur",
                "ST": "Santo Tomé y Príncipe",
                "SV": "El Salvador",
                "SX": "San Martín",
                "SY": "Siria",
                "SZ": "Esuatini",
                "TC": "Islas Turcas y Caicos",
                "TD": "Chad",
                "TF": "Territorios Franceses del Sur",
                "TG": "Ir",
                "TH": "Tailandia",
                "TJ": "Tayikistán",
                "TK": "Tokelau",
                "TL": "Timor Oriental",
                "TM": "Turkmenistán",
                "TN": "Túnez",
                "TO": "Tonga",
                "TR": "Pavo",
                "TT": "Trinidad y Tobago",
                "TV": "Tuvalu",
                "TW": "Taiwán",
                "TZ": "Tanzania",
                "UA": "Ucrania",
                "UG": "Uganda",
                "UM": "Islas periféricas de EE. UU.",
                "US": "Estados Unidos",
                "UY": "Uruguay",
                "UZ": "Uzbekistán",
                "VA": "Ciudad del Vaticano",
                "VC": "San Vicente y las Granadinas",
                "VE": "Venezuela",
                "VG": "Islas Vírgenes Británicas",
                "VI": "Islas Vírgenes de EE.UU",
                "VN": "Vietnam",
                "VU": "Vanuatu",
                "WF": "Wallis y Futuna",
                "WS": "Samoa",
                "XK": "Kosovo",
                "YE": "Yemen",
                "YT": "Mayotte",
                "ZA": "Sudáfrica",
                "ZM": "Zambia",
                "ZW": "Zimbabue"
            },
            "credits": {
                "images": "Creditos de imagenes"
            },
            "csv": {
                "common": {
                    "generating": "Generando archivo CSV...",
                    "waiting": "Espere por favor..."
                },
                "download": "exportación CSV"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "Favoritos de su experto local"
                },
                "links": {
                    "closest_pois": "Puntos más cercanos",
                    "excursions": "Itinerarios y paseos",
                    "shares": "Comparte"
                },
                "no_dashboard": {
                    "contact": {
                        "link": "Contáctenos.",
                        "text": "Si crees que esto es un error, no dudes en"
                    },
                    "content": "No se ha configurado ningún panel para su función o está conectado como administrador.",
                    "title": "No hay panel disponible"
                },
                "points_of_interest": {
                    "categories": "Categorías de puntos de interés"
                }
            },
            "documents": {
                "display": {
                    "description": "Exhibición en formato A6 con código QR de enlace y el logo de su institución.",
                    "title": "Pantalla - Tarjeta A6"
                },
                "qrcode": {
                    "description": "El QRCode por sí solo permite vincular al viajero directamente con su institución.",
                    "filename": "Código QR",
                    "title": "Enlace código QR"
                }
            },
            "easy_transac": {
                "account": {
                    "create": {
                        "button": "Crear una cuenta EasyTransac",
                        "fail": "La conexión con EasyTransac falló, inténtelo de nuevo más tarde",
                        "success": "Su cuenta EasyTransac ha sido creada exitosamente"
                    },
                    "show": {
                        "account_usage": "Su cuenta EasyTransac permitirá a su institución recibir pagos en UgoZER.",
                        "can_receive_payments": "Tus clientes ahora pueden dar propina a tu establecimiento\n                desde el perfil UgoZER de sus conserjes.",
                        "can_retrieve_payments": {
                            "description": "Debe completar su cuenta EasyTransac con la información solicitada para poder\n                    para utilizar los fondos disponibles en su cuenta.",
                            "title": "Su cuenta EasyTransac está incompleta"
                        },
                        "cannot_receive_payments": "No puedes recibir pagos",
                        "credentials_sent_mail": "Sus credenciales de EasyTransac le han sido enviadas por correo electrónico.",
                        "fee_percentage": "Se aplicará una tarifa a cada pago que reciba.",
                        "go_to_easy_transac": "Acceda al panel de EasyTransac",
                        "must_subscribe": "Debe estar suscrito para crear su cuenta EasyTransac.",
                        "no_account_yet": "Aún no tienes una cuenta EasyTransac.",
                        "should_complete_kyc": "Por favor envíe los documentos solicitados por EasyTransac,\n                para poder recibir pagos."
                    }
                },
                "easy_transac": "EasyTransac"
            },
            "exceptions": {
                "default": "Ocurrió un error",
                "error_texts": {
                    "403": {
                        "link": {
                            "title": "Es posible que desee acceder a",
                            "to_app_label": "sección de viajeros?",
                            "to_extranet_label": "sección profesional?"
                        },
                        "message": "No tienes acceso a esta página.",
                        "title": "¡Ups! Acceso Prohibido."
                    },
                    "credential_not_verified": {
                        "title": "Tu credencial no está verificada",
                        "verify_mail": "Recibir enlace de verificación para mi correo electrónico",
                        "verify_phone": "Recibir enlace de verificación para mi número de teléfono"
                    },
                    "opening_hours": {
                        "invalid": "El valor proporcionado no es una instancia de OpeningHours."
                    },
                    "password_missing": {
                        "app": "Por favor establezca una contraseña.",
                        "extranet": "Por favor establezca una contraseña para acceder a la aplicación."
                    }
                },
                "spam_detected": "Spam detectado"
            },
            "excursions": {
                "destroy": {
                    "confirm": "¿Realmente deseas eliminar este itinerario: {excursion_name}?",
                    "success": "El itinerario ha sido eliminado."
                },
                "direction": {
                    "bicycling": "montar en bicicleta",
                    "display_mode": "Modo de visualización",
                    "driving": "conduciendo",
                    "walking": "caminando"
                },
                "form": {
                    "all_points_of_interest": "Todos los puntos de interés",
                    "create_new_point_of_interest": "Crear un nuevo punto de interés",
                    "existing_excursions": "Itinerarios existentes",
                    "from_existing_excursions": "De un itinerario existente",
                    "order_hint": "El orden de los puntos de interés corresponde al orden de la visita.",
                    "points_of_interest": "Puntos de interés",
                    "select_from_points_of_interest": "Seleccione entre puntos de interés",
                    "your_excursion": "Tu itinerario"
                },
                "index": {
                    "alert": {
                        "content": "Sólo puedes ver rutas. Si desea crear o compartir un itinerario, debe iniciar sesión con\n                una <strong>cuenta de conserje/recepcionista</strong>",
                        "title": "Itinerarios sólo en consulta"
                    },
                    "no_results": "Aún no tienes itinerarios",
                    "no_results_here": "No hay itinerarios disponibles en su área de búsqueda.",
                    "read_only": "Solo consulta",
                    "search": "Buscar un itinerario",
                    "try_more_filters": "Puedes ampliar tu búsqueda o crear un itinerario.",
                    "your_excursions": "Tus itinerarios"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "Itinerario",
                            "pois": "Puntos de interés"
                        }
                    }
                },
                "search": {
                    "no_results": "No se encontró ningún itinerario"
                },
                "share": {
                    "contains_private": "Este itinerario contiene puntos de interés privados. No podrán incluirse en la acción.",
                    "heading": "Comparte este itinerario",
                    "select_which_contacts": "Selecciona los contactos con los que quieres compartir el itinerario \"{excursion_title}\"",
                    "select_which_users": "Selecciona los usuarios con los que quieres compartir el itinerario \"{excursion_title}\" o ingresa\n            una dirección de correo electrónico para invitar a un nuevo viajero",
                    "select_which_users_alt": "Introduzca la dirección de correo electrónico de la persona con la que desea compartir el\n            Itinerario \"{excursion_title}\".",
                    "success": "¡El itinerario ha sido compartido!"
                },
                "store": {
                    "create": "Crear un itinerario",
                    "heading": "Crear un itinerario",
                    "success": "El itinerario ha sido creado."
                },
                "update": {
                    "heading": "Actualizar este itinerario",
                    "success": "El itinerario ha sido actualizado."
                }
            },
            "extranet": {
                "dashboard": {
                    "last_claims": {
                        "none": "Aún no tienes ningún reclamo.",
                        "see_all": "Ver todos tus reclamos",
                        "see_index": "Ir a la página de reclamaciones"
                    },
                    "last_payments": {
                        "none": "Aún no tienes ningún pago.",
                        "see_all": "Ver todos tus pagos",
                        "see_index": "Ir a la página de pago"
                    },
                    "last_reservations": {
                        "none": "Aún no tienes ninguna reserva."
                    },
                    "last_traveller_folders": {
                        "none": "Aún no tienes ningún viajero a tu cargo.",
                        "see_all": "Ver todos tus viajeros",
                        "take_care": "Cuidar a un viajero"
                    },
                    "manager": {
                        "sections": {
                            "butlers_institutions": "Conserjes de mis instituciones",
                            "butlers_interactions": "Conserjes con interacciones"
                        }
                    },
                    "sections": {
                        "last_claims": "Tus últimas reclamaciones",
                        "last_payments": "Tus últimos pagos",
                        "last_reservations": "Tus últimas reservas",
                        "last_travellers": "Tus últimos viajeros",
                        "search_butler": "Buscar un conserje",
                        "stats": "Estadísticas"
                    },
                    "stats": {
                        "butlers": "Conserje|Conserjes",
                        "employees": "Empleado|Empleados",
                        "excursions": "Itinerario|Itinerarios",
                        "folders": "Carpeta|Carpetas",
                        "institutions": "Institución vinculada|Instituciones vinculadas",
                        "processing_claims": "Procesamiento de reclamo|Procesamiento de reclamos",
                        "reservations": "Reserva|Reservas"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "invitar a un empleado",
                        "new-hotelier": "Crear una cuenta de recepción",
                        "new-tourist-office": "Crear una cuenta de escritorio"
                    }
                },
                "excursions": {
                    "actions": {
                        "new": "Crear un itinerario"
                    },
                    "sections": {
                        "excursions": "Itinerarios"
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "Categorías"
                    }
                },
                "filters": {
                    "closed": "Cerrar filtros",
                    "open": "Abrir filtros"
                },
                "finances": {
                    "alerts": {
                        "easytransac": {
                            "cta": "Accede a mi perfil para crear una cuenta EasyTransac",
                            "description": "¡Aún no has creado una cuenta EasyTransac! Entonces no puedes recibir pagos.",
                            "title": "¡Cuenta EasyTransac inexistente!"
                        }
                    },
                    "sections": {
                        "issued_payments": "Pagos emitidos",
                        "received_by_institution_payments": "Pagos recibidos por sus instituciones",
                        "received_payments": "Pagos recibidos"
                    }
                },
                "institution": {
                    "messages": {
                        "no_records": "Ninguna institución vinculada por el momento"
                    },
                    "sections": {
                        "butler": {
                            "institution": "mi institución"
                        },
                        "manager": {
                            "institution": "Mis instituciones"
                        }
                    }
                },
                "navbar": {
                    "contact": "Contáctenos",
                    "contacts": "Contactos",
                    "dashboard": "Panel",
                    "excursions": "Itinerarios",
                    "faq": "Preguntas más frecuentes",
                    "finances": "Finanzas",
                    "invitations": "Invitaciones",
                    "my_employees": "mis empleados",
                    "my_institution": "mi institución",
                    "my_institutions": "Mis instituciones",
                    "points_of_interest": "Puntos de interés",
                    "travellers": "Viajeros",
                    "tutorials": "Comenzar",
                    "users": "Usuarios"
                },
                "pages": {
                    "excursions": {
                        "edit": "Editar itinerario",
                        "new": "Nuevo itinerario"
                    },
                    "folders": {
                        "edit": "Editar carpeta de viajero",
                        "new": "Nueva carpeta de viajero"
                    },
                    "institutions": {
                        "new": "Vincular una institución"
                    },
                    "pois": {
                        "edit": "Editar punto de interés",
                        "new": "Nuevo punto de interés"
                    },
                    "profile": {
                        "edit": "Editar perfil"
                    },
                    "reservations": {
                        "edit": "Editar reserva",
                        "new": "Nueva reserva"
                    }
                },
                "points_of_interest": {
                    "actions": {
                        "new": "Crear un punto de interés"
                    },
                    "sections": {
                        "points_of_interest": "Puntos de interés"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "No hay preguntas frecuentes por el momento"
                    }
                },
                "professional_section": "Sección profesional",
                "profile": {
                    "sections": {
                        "currency": "Divisa",
                        "delete_account": "Eliminación de cuenta",
                        "infos": "información general",
                        "locale": "Idioma",
                        "notification_settings": "Configuracion de notificaciones",
                        "payment": "Pago",
                        "privacy_settings": "La configuración de privacidad",
                        "shares": "Comparte"
                    }
                },
                "support_messages": {
                    "actions": {
                        "back": "Volver a la solicitud de soporte",
                        "new": "Agregar un nuevo mensaje"
                    },
                    "sections": {
                        "support_messages": "Mensajes"
                    }
                },
                "support_requests": {
                    "actions": {
                        "back": "Atrás",
                        "new": "Crear una solicitud de soporte"
                    },
                    "sections": {
                        "support_requests": "Solicitudes de soporte"
                    }
                },
                "travellers": {
                    "buttons": {
                        "new_folder": "Agregar nueva carpeta de viajero"
                    },
                    "sections": {
                        "folders": "Mis carpetas de viajeros"
                    }
                },
                "user_dropdown": {
                    "changelogs": "Registros de cambios",
                    "cookies": "Gestión de cookies",
                    "credits": "Créditos",
                    "customers": "Clientes",
                    "partners": "Socios",
                    "privacy_policy": "Política de privacidad",
                    "profile": "Mi perfil",
                    "sign_out": "desconectar",
                    "subscription": "Suscripción",
                    "terms_condition": "Términos y condiciones"
                }
            },
            "form": {
                "file": {
                    "current_file": "Archivo real: {name}"
                },
                "hints": {
                    "auth": {
                        "register": {
                            "email": "Esta dirección de correo electrónico no debe estar vinculada a una cuenta UgoZER",
                            "employee": {
                                "not_duplicate_email": "La dirección de correo electrónico debe ser diferente a la del",
                                "not_duplicate_password": "La contraseña debe ser diferente a la del"
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "Personalizado: itinerario creado por el conserje.\n                        para un viajero específico",
                                "root": "Mis plantillas: itinerario \"listo para compartir\" que consta de puntos de interés imperdibles,\n                        o puntos de interés por temática\n                        (ejemplo: lista de restaurantes incluidos frecuentemente en los itinerarios)\n                        para ser seleccionado para crear un nuevo itinerario.",
                                "ugozer_app": "App UgoZER: itinerario creado y propuesto por UgoZER\n                        y puesta a disposición de los viajeros.",
                                "ugozer_pro": "UgoZER Pro: itinerario creado y propuesto por UgoZER\n                        y puestos a disposición de los profesionales para facilitar su trabajo."
                            },
                            "pro": {
                                "customized": "Itinerario creado para un viajero específico.",
                                "root": "Itinerario \"listo para compartir\" que consta de puntos de interés imperdibles,\n                        o puntos de interés por temática\n                        (ejemplo: lista de restaurantes incluidos frecuentemente en los itinerarios)\n                        para ser seleccionado para crear un nuevo itinerario.",
                                "ugozer": "Itinerario creado y propuesto por UgoZER."
                            }
                        },
                        "points_of_interest_search": "Busca y selecciona un punto de interés para agregarlo a la lista"
                    },
                    "excursions_filters": {
                        "radius": "Sólo verás itinerarios con al menos un punto de interés ubicado dentro de este radio."
                    },
                    "geoloc_setting": {
                        "filter_header": "Buscar un país, región, ciudad o barrio",
                        "kind": {
                            "admin": {
                                "around_me": "A mi alrededor: se determinará la geolocalización\n                        por el navegador del usuario.",
                                "customized": "Personalizado: el usuario podrá buscar un lugar manualmente.",
                                "staying_hotel": "Estancia en hotel: se realizará la geolocalización\n                        del hotel donde se hospeda el viajero."
                            },
                            "app": {
                                "around_me": "La geolocalización se realizará desde tu posición.",
                                "customized": "La geolocalización se realizará desde una ubicación de su elección.",
                                "staying_hotel": "La geolocalización se realizará desde el hotel donde te alojes: {title}."
                            }
                        }
                    },
                    "locale": {
                        "dynamic_translations_enabled": "Permite a los usuarios mostrar o traducir recursos.\n                (puntos de interés, itinerarios...) en ese idioma",
                        "fallback_locale": "Muestra automáticamente el título en un segundo idioma (antes de la descripción) en\n                el archivo PDI.",
                        "static_translations_enabled": "Permite a los usuarios elegir este idioma para mostrar la aplicación.\n                (requiere que se hayan agregado traducciones al código fuente)"
                    },
                    "permission": {
                        "name": "Nombre utilizado en el código fuente."
                    },
                    "plan": {
                        "countries": "Déjelo vacío para que el plan esté disponible en todos los países.",
                        "information": "Información adicional sobre el plan (prerrequisitos, condiciones...)",
                        "name": "Este nombre será visible para los suscriptores potenciales.\n                cuando eligen su suscripción",
                        "private_title": "Este título sólo es visible en administración."
                    },
                    "plan_price": {
                        "description": "Esta descripción será visible para los suscriptores potenciales.\n                cuando eligen su suscripción",
                        "price": "Por favor introduzca en euros",
                        "private_title": "Este título sólo es visible en administración."
                    },
                    "point_of_interest": {
                        "audio_refreshing_1": "Al habilitar la actualización del archivo de audio, se generará automáticamente un archivo de audio.\n                en el idioma del punto de interés ({idioma}).",
                        "audio_refreshing_2": "Indicará el título y la dirección del punto de interés.",
                        "audio_refreshing_3": "Se actualizará tan pronto como el idioma, el título\n                o la dirección del punto de interés se actualizan.",
                        "audio_refreshing_4": "La actualización se desactivará si agrega o graba un archivo de audio usted mismo.\n                o si elimina el archivo de audio generado.",
                        "cant_have_employees": "Aquí sólo ves establecimientos asociados a categorías que puedan tener\n                empleados",
                        "expiry_date": "Tu punto de interés caducará el {date}",
                        "expiry_date_details_1": "Su punto de interés es parte de la categoría \"{categor}ía\".",
                        "expiry_date_details_2": "Por lo tanto, caducará el {date}, ya que esta es la última fecha que ingresó.",
                        "expiry_date_details_3": "A partir de esta fecha, a tu punto de interés le quedarán 21 días\n                antes de que se borre automáticamente..",
                        "expiry_date_details_4": "Siempre puedes agregar una nueva fecha.\n                para posponer el vencimiento o no especificar ninguna fecha para que su punto de interés no expire.",
                        "images": "Las imágenes se mostrarán en la galería del punto de interés en el orden elegido,\n                puedes reorganizarlos arrastrándolos.",
                        "is_geocoding": "Desactivar si se introduce correctamente la geolocalización pero el mapa sigue sin aparecer.",
                        "line1": "Puede ingresar la dirección o el nombre del lugar que está buscando. Elija entre el\n                lista para completar la dirección automáticamente",
                        "locale": "El idioma principal que se habla en este punto de interés.",
                        "opening_dates": "Deje en blanco si los horarios se aplican a cualquier fecha",
                        "opening_days": "No seleccione nada si los horarios aplican en cualquier día",
                        "opening_hours": "No agregues nada si el punto de interés está abierto en cualquier momento",
                        "sync_1": "Activar actualización automática le permite recuperar datos del {import_source}\n                importar",
                        "sync_2": "Las entradas manuales se sobrescribirán"
                    },
                    "points_of_interest_filters": {
                        "order_distance": "Por favor elija un filtro de distancia para seleccionar esta opción",
                        "order_relevance": "Por favor ingrese las palabras clave de búsqueda para seleccionar esta opción",
                        "radius": "Sólo verás puntos de interés ubicados dentro de este radio."
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "butler": "No importa la opción que elijas, tu apellido y tu nombre\n                    permanecerá visible para el administrador de su institución",
                            "traveller": "No importa la opción que elijas, tu apellido y tu nombre\n                  permanecerá visible por los conserjes del hotel donde se aloje."
                        }
                    },
                    "profile": {
                        "contact_email": "Este correo electrónico se puede mostrar en su perfil público.",
                        "contact_phone": "Este teléfono se puede mostrar en su perfil público.",
                        "name": "El apodo es público y permite que otros usuarios de la aplicación te identifiquen más fácilmente.",
                        "password_template": {
                            "digit": "1 dígito",
                            "length": "{length} caracteres",
                            "lowercase": "1 letra minúscula",
                            "uppercase": "1 letra mayúscula"
                        },
                        "password_unchanged": "Dejar en blanco para permanecer sin cambios"
                    },
                    "role": {
                        "description": "La descripción del rol se muestra a los usuarios cuando eligen el rol cuando\n                Regístrate en la sección profesional.",
                        "name": "Nombre clave del rol, no se mostrará a los usuarios."
                    },
                    "support_request": {
                        "support_categories_search": "Busca y selecciona una categoría"
                    },
                    "translatable": {
                        "pro_global": "Uno o más campos de este formulario se pueden completar en diferentes idiomas.",
                        "pro_global_2": "Los campos no completados en ciertos idiomas se traducirán automáticamente.",
                        "pro_global_title": "Entrada en varios idiomas"
                    },
                    "traveller_folder": {
                        "expired_on": "De forma predeterminada, la fecha de finalización se establece en una semana."
                    },
                    "user": {
                        "bank_account_number": "Ingrese el número de cuenta que se utilizará para realizar la transferencia"
                    }
                },
                "labels": {
                    "geoloc_setting": {
                        "distance_filter": "filtro de distancia",
                        "limit_to_a_radius": "limitar a un radio",
                        "limit_to_a_radius_from_center": "limitar a un radio desde el centro",
                        "measure_unit": "Unidad de medida",
                        "other_filters": "otros filtros",
                        "where_does_the_traveller_go": "¿Adónde va el viajero?",
                        "where_would_you_like_to_go": "¿Dónde te gustaría ir?",
                        "within_a_radius": "dentro de un radio"
                    },
                    "payment": {
                        "payable_type": "Pago vinculado a"
                    },
                    "plan": {
                        "authorized_roles": "roles autorizados",
                        "tipping_fees": "Tarifas de propinas (porcentaje)"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "precio sin impuestos"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "Actualizar automáticamente el archivo de audio",
                        "opening": "apertura",
                        "opening_date": "en",
                        "opening_date_from": "de",
                        "opening_date_range": "especificar una fecha de finalización",
                        "opening_date_to": "a",
                        "opening_hours": "en",
                        "opening_hours_from": "de",
                        "opening_hours_range": "especificar una hora de finalización",
                        "opening_hours_to": "a",
                        "opening_schedule_add": "especificar días u horas",
                        "opening_schedule_add_alt": "especificar horas",
                        "opening_specification_add": "agregar un punto"
                    },
                    "points_of_interest_filters": {
                        "geoloc": "lugar",
                        "order_distance": "a distancia",
                        "order_relevance": "por relevancia (palabras clave de búsqueda)",
                        "sort": "clasificar",
                        "tags": "refinar"
                    },
                    "register": {
                        "communication_consent": "Me gustaría recibir comunicaciones personalizadas, incluidas ofertas,\n                información promocional y productos relacionados con viajes de UgoZER por correo electrónico.",
                        "privacy_policy": {
                            "part1": "Estoy de acuerdo con la",
                            "part2": "política de privacidad"
                        },
                        "terms_conditions": {
                            "part1": "Estoy de acuerdo con la",
                            "part2": "Términos y condiciones"
                        }
                    },
                    "support_category": {
                        "title": "Elige una categoría"
                    },
                    "support_message": {
                        "content": "Mensaje",
                        "email": "Dirección de correo electrónico"
                    },
                    "support_request": {
                        "title": "Asunto de la solicitud"
                    },
                    "traveller_invitation": {
                        "locale": "Idioma hablado por este viajero."
                    },
                    "user": {
                        "credential": "correo electrónico",
                        "email": "correo electrónico",
                        "image": {
                            "_destroy": "Eliminar foto de perfil"
                        },
                        "new_password": "Nueva contraseña",
                        "notes": "más información"
                    },
                    "vat_rate": {
                        "vat_rate": "Tasa del IVA (porcentaje)"
                    }
                },
                "placeholders": {
                    "audio_file_name": "nombre del archivo de audio",
                    "name": "apodo",
                    "support_message": {
                        "create_message": "Su mensaje para la solicitud de soporte"
                    }
                },
                "select": {
                    "no_options": "Sin opciones",
                    "no_results": "Lo sentimos, no hay opciones coincidentes.",
                    "type_to_search": "Buscar..."
                },
                "titles": {
                    "verifications": "Comprobando tu información"
                }
            },
            "front": {
                "access_pro": "Ir al acceso profesional",
                "app": "Aplicación para viajeros",
                "brand_name": "UgoZER",
                "captcha": {
                    "and": "y",
                    "apply": "aplicar.",
                    "privacy_policy": "política de privacidad",
                    "protected": "Este sitio está protegido por hCaptcha y sus",
                    "terms_service": "Términos de servicio"
                },
                "contact": {
                    "cancel": "Cancelar la solicitud de soporte",
                    "contact": "Contacto",
                    "send": "Enviar"
                },
                "cookies": {
                    "title": "Gestión de cookies"
                },
                "customers": {
                    "see_customers": "Ver nuestros clientes",
                    "title": "Ellos confían en nosotros"
                },
                "footer": {
                    "account_deletion": "Eliminación de cuenta",
                    "app": "Aplicación para viajeros",
                    "contact": "Contacto",
                    "cookies": "Gestión de cookies",
                    "copyright": "© {year} UgoZER - Todos los derechos reservados.",
                    "facebook": "Facebook",
                    "faq": "Preguntas más frecuentes",
                    "home": "Hogar",
                    "instagram": "Instagram",
                    "legal_notice": "Aviso Legal",
                    "linkedin": "LinkedIn",
                    "privacy_policy": "Política de privacidad",
                    "pro": "Acceso profesional",
                    "sitemap": "Mapa del sitio",
                    "terms_conditions": "Términos y condiciones",
                    "twitter": "Gorjeo"
                },
                "header": {
                    "app": "Aplicación para viajeros",
                    "contact": "Contacto",
                    "faq": "Preguntas más frecuentes",
                    "home": "Hogar",
                    "pro": "Acceso profesional",
                    "you_are": "Eres"
                },
                "intro": {
                    "intro_1": "Con UgoZER, tenga siempre en su teléfono inteligente sus direcciones favoritas en archivos en varios idiomas,\n            audible o legible, con un clic.",
                    "intro_2": "Cuando esté en el extranjero, facilite que los taxistas y otras personas comprendan su destino.",
                    "intro_3": "Recepcionista, gerente de hotel, diseño y oferta de programas turísticos. Compártelos con tus clientes\n            en UgoZER.",
                    "intro_title_pro": "PROFESIONALES",
                    "intro_title_traveller": "VIAJERO",
                    "title_homepage": "La guía turística multilingüe, gratuita."
                },
                "navigation": {
                    "next": "Próximo",
                    "previous": "Anterior"
                },
                "outro": {
                    "description": "Viaja en 2.0"
                },
                "partners": {
                    "no_records": "No hay socios por el momento",
                    "read_more": "Leer más",
                    "see_partners": "Ver nuestros socios",
                    "title": "Socios"
                },
                "posts": {
                    "no_records": "No hay publicaciones en este momento.",
                    "title": "PREGUNTAS MÁS FRECUENTES."
                },
                "pro": "Acceso profesional",
                "professionals": {
                    "argument_1": {
                        "part_1": "UgoZER ofrece una solución digital para sus invitados.",
                        "part_2": "Mejore su estadía y la experiencia de sus huéspedes.",
                        "part_3": "Le permite a su hotel, resort o centro de información crear información personalizada, fácil de usar,\n                itinerarios móviles multilingües.",
                        "part_4": "Comparta viajes personalizados desde una base de datos de puntos de interés en tiempo real a través de la plataforma profesional UgoZER\n                y su aplicación móvil."
                    },
                    "argument_2": {
                        "part_1": "Puede seleccionar itinerarios preempaquetados para sus invitados.",
                        "part_2": "Crea el tuyo con los mejores lugares o restaurantes de la ciudad, joyas escondidas por descubrir, perfecto\n                caminatas senderos para disfrutar...",
                        "part_3": "O elija cada paso de su visita dentro de una lista exhaustiva de puntos de interés para combinarlos en\n                y luego compartiremos un itinerario personalizado."
                    },
                    "argument_3": {
                        "part_1": "Cada PDI posee una descripción detallada, su posición en un mapa interactivo y su\n                dirección traducida automáticamente al idioma local.",
                        "part_2": "Tu invitado nunca se perderá. Él siempre encontrará su camino y volverá fácilmente a tu\n                hotel."
                    },
                    "argument_4": {
                        "part_1": "Al final de su viaje, después de disfrutar de un itinerario hecho a su medida, su huésped podrá\n                agradezca a su conserje o recepcionista por su recomendación única dándole una propina.",
                        "part_2": "Al utilizar UgoZER, mejorará la retención mediante mayores ingresos y disminuirá el personal.\n                Rotación.",
                        "part_3": "Les darás una mayor motivación para involucrarse en el día a día de tu hotel.\n                y darles un sentido de pertenencia."
                    },
                    "intro": {
                        "main_title": "UgoZER, el asistente de viajes personal y digital multilingüe. Lleva a tu invitado\n                su experiencia al siguiente nivel y aumente sus ingresos."
                    },
                    "outro": {
                        "catchphrase": "UgoZER le ayudará a aumentar la satisfacción e impulsar el crecimiento."
                    }
                },
                "startup": {
                    "description_1": "UgoZER es una empresa francesa, con sede en Estrasburgo, capital de Europa. Creado por viajes\n            amantes, facilita tus viajes a países donde no hablas el idioma.",
                    "description_2": "Está recomendado por las oficinas de turismo.",
                    "title": "La startup UgoZER"
                },
                "support_request": "Solicitud de soporte",
                "travellers": {
                    "argument_1": {
                        "arguments": {
                            "audio": "<strong>dirección de audio</strong> en el idioma local para escuchar un taxi o un transeúnte",
                            "desc": "<strong>descripción detallada</strong>",
                            "languages": "Toda la información está traducida al <strong>francés, inglés, alemán, holandés, portugués,\n                    Español, italiano, coreano, chino, japonés y árabe.</strong>",
                            "map": "<strong>ubicación en un mapa interactivo, posible visualización en Google Maps™</strong>"
                        },
                        "part_1": "Accede a todos los puntos de interés (POI) actualizados en tiempo real en tu smartphone.",
                        "part_2": "PDI clasificados por <strong>categorías: monumentos, museos, exposiciones, tiendas, restaurantes,\n                bares, eventos temporales...</strong>",
                        "part_3": "PDI suministrados con:"
                    },
                    "argument_2": {
                        "part_1": "Disfrute de su viaje con acceso permanente a información completa. Todo en tu teléfono inteligente,\n                justo en tu bolsillo."
                    },
                    "argument_3": {
                        "part_1": "Con UgoZER, obtenga itinerarios automáticamente y gratuitos. También puedes crear los viajes y compartirlos.\n                ellos con tus amigos. Aproveche al máximo su viaje obteniendo recorridos personalizados de su local\n                experto: conserje o recepcionista de hotel, oficina de información turística.",
                        "part_2": "Si le gustó su viaje, incluso puede agradecerle a su experto local con una tarjeta digitalizada y segura.\n                opción de propina."
                    },
                    "intro": {
                        "main_title": "Tu guía turística multilingüe gratuita"
                    },
                    "outro": {
                        "catchphrase": "Con UgoZER déjate guiar con seguridad y viaja con tranquilidad."
                    }
                },
                "you_are": {
                    "professional": "Profesional",
                    "traveller": "Viajero"
                },
                "youtube": {
                    "directed_by": "Dirigido por",
                    "producer": "estudio suprahead"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "Elige tu institución"
                    },
                    "sections": {
                        "pending_claims": "Validación pendiente",
                        "requests": "Solicitudes pendientes",
                        "validated_claims": "Reclamado"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "La solicitud ha sido enviada."
                },
                "approve": {
                    "confirm": {
                        "employee": "¿Realmente desea aprobar la solicitud?",
                        "employer": "¿De verdad quieres aprobar {user_name}?"
                    },
                    "success": "La solicitud ha sido aprobada."
                },
                "cancel": {
                    "confirm": {
                        "employee": "¿Realmente desea cancelar esta solicitud?",
                        "employer": "¿Realmente desea cancelar la solicitud {user_name}?"
                    },
                    "success": "La solicitud ha sido cancelada."
                },
                "decline": {
                    "confirm": {
                        "change_institution": "¡Su institución vinculada cambiará!",
                        "employee": "¿Realmente desea rechazar la solicitud?",
                        "employer": "¿Realmente quieres rechazar {user_name}?"
                    },
                    "success": "La solicitud ha sido rechazada."
                },
                "feature": {
                    "confirm": "¿Realmente quieres presentar {user_name}?",
                    "folder": "Para un viajero que no ha tenido interacción con uno de sus empleados, es el que aparece\n           que se mostrará en la carpeta del viajero.",
                    "only_one": "Sólo se puede presentar un empleado.",
                    "success": "El empleado ha sido destacado."
                },
                "index": {
                    "my_employees": "mis empleados"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "Cuenta",
                        "invitation": "Invitación"
                    }
                },
                "invite": {
                    "invite": "invitar a un empleado"
                },
                "quit": {
                    "confirm": "¿De verdad quieres dejar esta institución {poi_name}?",
                    "success": "Has abandonado la institución."
                },
                "remove": {
                    "confirm": "¿Realmente quieres eliminar {user_name}?",
                    "success": "El empleado ha sido removido."
                },
                "show": {
                    "pending": "Su solicitud para unirse a esta institución está actualmente pendiente."
                },
                "states": {
                    "approved": "Aprobado",
                    "canceled": "Cancelado",
                    "declined": "Rechazado",
                    "invited": "Invitado",
                    "pending": "Pendiente",
                    "removed": "Remoto"
                }
            },
            "interactions": {
                "description": {
                    "contact approved": "La solicitud de contacto ha sido aprobada por {sender_name}",
                    "contact blocked": "La solicitud de contacto ha sido bloqueada por {sender_name}",
                    "contact created": "La solicitud de contacto ha sido creada por {sender_name}",
                    "contact declined": "La solicitud de contacto ha sido rechazada por {sender_name}",
                    "invitation accepted": "La invitación ha sido aceptada por {sender_name}",
                    "invitation created": "La invitación ha sido enviada por {sender_name}",
                    "payment succeeded": "{sender_name} ha enviado un pago a {recipient_name}",
                    "share created": "{sender_name} ha compartido {share}",
                    "traveller_folder created": "La carpeta de viajero ha sido creada por {sender_name}",
                    "traveller_folder thanked": "{sender_name} ha agradecido al viajero su estancia"
                },
                "share_type": {
                    "excursions": "el itinerario",
                    "pointsofinterest": "el punto de interés"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "Invitar"
                },
                "states": {
                    "authenticated_once": {
                        "false": "Pendiente",
                        "true": "Validado"
                    }
                },
                "success": {
                    "professional": {
                        "sent_1": "Se enviará un correo electrónico a {email}, si esta dirección aún no está en uso.",
                        "sent_2": "Este correo electrónico contiene un enlace para registrarse en la sección profesional.",
                        "title": "Invitación enviada"
                    },
                    "traveller": {
                        "email_1": "Se enviará un correo electrónico a {email}, si esta dirección aún no está en uso.",
                        "email_2": "Este correo electrónico contiene un enlace que le permitirá conectarse a la aplicación.",
                        "title": "Invitación enviada"
                    }
                },
                "texts": {
                    "already_have_account": "¿El usuario ya tiene una cuenta en la aplicación?",
                    "description": "Puede invitar a alguien a unirse a nuestra aplicación con una dirección de correo electrónico o un número de teléfono.",
                    "employee_already_have_account": "¿El empleado ya tiene una cuenta en la aplicación?",
                    "geoloc_settings": {
                        "description": "La configuración de geolocalización se establecerá automáticamente según las coordenadas de su hotel.\n                (si estás vinculado a un establecimiento).",
                        "title": "Configuración de geolocalización"
                    },
                    "invite_link": {
                        "instructions": "Copia este enlace para invitar a un viajero",
                        "title": "Invitar por enlace"
                    },
                    "invite_professional": "invita a un profesional",
                    "invite_traveller": "invitar a un viajero",
                    "new_traveller": "Nuevo viajero",
                    "professional_description": "Puedes invitar a un profesional a unirse a la sección profesional de nuestro\n            solicitud con un correo electrónico",
                    "title": "Invita a alguien"
                }
            },
            "locales": {
                "aa": "Lejos",
                "ab": "abjasio",
                "af": "africaans",
                "af_NA": "Afrikáans (Namibia)",
                "af_ZA": "Afrikáans (Sudáfrica)",
                "ak": "akan",
                "ak_GH": "Akan (Ghana)",
                "am": "amárico",
                "am_ET": "Amárico (Etiopía)",
                "an": "aragonés",
                "ar": "Arábica",
                "ar_AE": "Árabe (Emiratos Árabes Unidos)",
                "ar_BH": "Árabe (Bahrein)",
                "ar_DJ": "Árabe (Yibuti)",
                "ar_DZ": "Árabe (Argelia)",
                "ar_EG": "Árabe (Egipto)",
                "ar_EH": "Árabe (Sáhara Occidental)",
                "ar_ER": "Árabe (Eritrea)",
                "ar_IL": "Árabe (Israel)",
                "ar_IQ": "Árabe (Irak)",
                "ar_JO": "Árabe (Jordania)",
                "ar_KM": "Árabe (Comoras)",
                "ar_KW": "Árabe (Kuwait)",
                "ar_LB": "Árabe (Líbano)",
                "ar_LY": "Árabe (Libia)",
                "ar_MA": "Árabe (Marruecos)",
                "ar_MR": "Árabe (Mauritania)",
                "ar_OM": "Árabe (Omán)",
                "ar_PS": "Árabe (Territorios Palestinos)",
                "ar_QA": "Árabe (Qatar)",
                "ar_SA": "Árabe (Arabia Saudita)",
                "ar_SD": "Árabe (Sudán)",
                "ar_SO": "Árabe (Somalia)",
                "ar_SS": "Árabe (Sudán del Sur)",
                "ar_SY": "Árabe (Siria)",
                "ar_TD": "Árabe (Chad)",
                "ar_TN": "Árabe (Túnez)",
                "ar_YE": "Árabe (Yemen)",
                "as": "Assamese",
                "as_IN": "Asamés (India)",
                "av": "avarico",
                "ay": "Aymara",
                "az": "azerbaiyano",
                "az_AZ": "Azerbaiyán (Azerbaiyán)",
                "az_Cyrl": "Azerbaiyano (cirílico)",
                "az_Cyrl_AZ": "Azerbaiyano (cirílico, Azerbaiyán)",
                "az_Latn": "Azerbaiyano (latín)",
                "az_Latn_AZ": "Azerbaiyano (latín, Azerbaiyán)",
                "ba": "bashkir",
                "be": "bielorruso",
                "be_BY": "Bielorruso (Bielorrusia)",
                "bg": "búlgaro",
                "bg_BG": "Búlgaro (Bulgaria)",
                "bh": "Bihari (idiomas)",
                "bi": "Bislama",
                "bm": "Bambara",
                "bm_Latn": "Bambara (latín)",
                "bm_Latn_ML": "Bambara (latín, Malí)",
                "bn": "bengalí",
                "bn_BD": "Bengalí (Bangladesh)",
                "bn_IN": "bengalí (India)",
                "bo": "tibetano",
                "bo_CN": "Tibetano (China)",
                "bo_IN": "Tibetano (India)",
                "br": "bretón",
                "br_FR": "Bretón (Francia)",
                "bs": "bosnio",
                "bs_BA": "Bosnio (Bosnia y Herzegovina)",
                "bs_Cyrl": "bosnio (cirílico)",
                "bs_Cyrl_BA": "Bosnio (cirílico, Bosnia y Herzegovina)",
                "bs_Latn": "bosnio (latín)",
                "bs_Latn_BA": "Bosnio (latín, Bosnia y Herzegovina)",
                "ca": "catalán",
                "ca_AD": "Catalán (Andorra)",
                "ca_ES": "catalán (españa)",
                "ca_FR": "Catalán (Francia)",
                "ca_IT": "catalán (italia)",
                "ce": "checheno",
                "ch": "chamorro",
                "co": "corso",
                "cr": "cree",
                "cs": "checo",
                "cs_CZ": "Checo (República Checa)",
                "cu": "Búlgaro antiguo",
                "cv": "chuvasio",
                "cy": "galés",
                "cy_GB": "Galés (Reino Unido)",
                "da": "danés",
                "da_DK": "Danés (Dinamarca)",
                "da_GL": "Danés (Groenlandia)",
                "de": "Alemán",
                "de_AT": "Alemán (Austria)",
                "de_BE": "Alemán (Bélgica)",
                "de_CH": "Alemán (Suiza)",
                "de_DE": "Alemán Alemania)",
                "de_LI": "Alemán (Liechtenstein)",
                "de_LU": "Alemán (Luxemburgo)",
                "dv": "maldivo",
                "dz": "Dzongkha",
                "dz_BT": "Dzongkha (Bután)",
                "ee": "Ewe",
                "ee_GH": "Oveja (Ghana)",
                "ee_TG": "Oveja (Togo)",
                "el": "Griego",
                "el_CY": "Griego (Chipre)",
                "el_GR": "Griego (Grecia)",
                "en": "Inglés",
                "en_AG": "Inglés (Antigua y Barbuda)",
                "en_AI": "Inglés (Anguila)",
                "en_AS": "Inglés (Samoa Americana)",
                "en_AU": "Inglés (Australia)",
                "en_BB": "Inglés (Barbados)",
                "en_BE": "Inglés (Bélgica)",
                "en_BM": "Inglés (Bermudas)",
                "en_BS": "Inglés (Bahamas)",
                "en_BW": "Inglés (Botsuana)",
                "en_BZ": "Inglés (Belice)",
                "en_CA": "Inglés (Canadá)",
                "en_CC": "Inglés (Islas Cocos (Keeling))",
                "en_CK": "Inglés (Islas Cook)",
                "en_CM": "Inglés (Camerún)",
                "en_CX": "Inglés (Isla de Navidad)",
                "en_DG": "Inglés (Diego García)",
                "en_DM": "Inglés (Dominica)",
                "en_ER": "Inglés (Eritrea)",
                "en_FJ": "Inglés (Fiyi)",
                "en_FK": "Inglés (Islas Malvinas)",
                "en_FM": "Inglés (Micronesia)",
                "en_GB": "Inglés reino unido)",
                "en_GD": "Inglés (Granada)",
                "en_GG": "Inglés (Guernesey)",
                "en_GH": "Inglés (Ghana)",
                "en_GI": "Inglés (Gibraltar)",
                "en_GM": "Inglés (Gambia)",
                "en_GU": "Inglés (Guam)",
                "en_GY": "Inglés (Guyana)",
                "en_HK": "Inglés (RAE de Hong Kong, China)",
                "en_IE": "Inglés (Irlanda)",
                "en_IM": "Inglés (Isla de Man)",
                "en_IN": "Inglés (India)",
                "en_IO": "Inglés (Territorio Británico del Océano Índico)",
                "en_JE": "Inglés (Jersey)",
                "en_JM": "Inglés (Jamaica)",
                "en_KE": "Inglés (Kenia)",
                "en_KI": "Inglés (Kiribati)",
                "en_KN": "Inglés (San Cristóbal y Nieves)",
                "en_KY": "Inglés (Islas Caimán)",
                "en_LC": "Inglés (Santa Lucía)",
                "en_LR": "Inglés (Liberia)",
                "en_LS": "Inglés (Lesoto)",
                "en_MG": "Inglés (Madagascar)",
                "en_MH": "Inglés (Islas Marshall)",
                "en_MO": "Inglés (RAE de Macao, China)",
                "en_MP": "Inglés (Islas Marianas del Norte)",
                "en_MS": "Inglés (Montserrat)",
                "en_MT": "Inglés (Malta)",
                "en_MU": "Inglés (Mauricio)",
                "en_MW": "Inglés (Malaui)",
                "en_MY": "Inglés (Malasia)",
                "en_NA": "Inglés (Namibia)",
                "en_NF": "Inglés (Isla Norfolk)",
                "en_NG": "Inglés (Nigeria)",
                "en_NR": "Inglés (Nauru)",
                "en_NU": "Inglés (Niue)",
                "en_NZ": "Inglés (Nueva Zelanda)",
                "en_PG": "Inglés (Papúa Nueva Guinea)",
                "en_PH": "Inglés (Filipinas)",
                "en_PK": "Inglés (Pakistán)",
                "en_PN": "Inglés (Islas Pitcairn)",
                "en_PR": "Inglés (Puerto Rico)",
                "en_PW": "Inglés (Palaos)",
                "en_RW": "Inglés (Ruanda)",
                "en_SB": "Inglés (Islas Salomón)",
                "en_SC": "Inglés (Seychelles)",
                "en_SD": "Inglés (Sudán)",
                "en_SG": "Inglés (Singapur)",
                "en_SH": "Inglés (Santa Elena)",
                "en_SL": "Inglés (Sierra Leona)",
                "en_SS": "Inglés (Sudán del Sur)",
                "en_SX": "Inglés (Sint Maarten)",
                "en_SZ": "Inglés (Suazilandia)",
                "en_TC": "Inglés (Islas Turcas y Caicos)",
                "en_TK": "Inglés (Tokelau)",
                "en_TO": "Inglés (Tonga)",
                "en_TT": "Inglés (Trinidad y Tobago)",
                "en_TV": "Inglés (Tuvalu)",
                "en_TZ": "Inglés (Tanzania)",
                "en_UG": "Inglés (Uganda)",
                "en_UM": "Inglés (islas periféricas de EE. UU.)",
                "en_US": "Inglés Estados Unidos)",
                "en_VC": "Inglés (San Vicente y las Granadinas)",
                "en_VG": "Inglés (Islas Vírgenes Británicas)",
                "en_VI": "Inglés (Islas Vírgenes de EE. UU.)",
                "en_VU": "Inglés (Vanuatu)",
                "en_WS": "Inglés (Samoa)",
                "en_ZA": "Inglés (Sudáfrica)",
                "en_ZM": "Inglés (Zambia)",
                "en_ZW": "Inglés (Zimbabue)",
                "eo": "esperanto",
                "es": "Español",
                "es_AR": "Español (Argentina)",
                "es_BO": "Español (Bolivia)",
                "es_CL": "español (chile)",
                "es_CO": "Español (Colombia)",
                "es_CR": "Español (Costa Rica)",
                "es_CU": "Español (Cuba)",
                "es_DO": "Español (República Dominicana)",
                "es_EA": "Español (Ceuta y Melilla)",
                "es_EC": "Español (Ecuador)",
                "es_ES": "Español (España)",
                "es_GQ": "Español (Guinea Ecuatorial)",
                "es_GT": "Español (Guatemala)",
                "es_HN": "Español (Honduras)",
                "es_IC": "Español (Islas Canarias)",
                "es_MX": "Español (México)",
                "es_NI": "Español (Nicaragua)",
                "es_PA": "Español (Panamá)",
                "es_PE": "Español (Perú)",
                "es_PH": "Español (Filipinas)",
                "es_PR": "Español (Puerto Rico)",
                "es_PY": "Español (Paraguay)",
                "es_SV": "Español (El Salvador)",
                "es_US": "Español (Estados Unidos)",
                "es_UY": "Español (Uruguay)",
                "es_VE": "Español (Venezuela)",
                "et": "estonio",
                "et_EE": "estonio (Estonia)",
                "eu": "vasco",
                "eu_ES": "Vasco (España)",
                "fa": "persa",
                "fa_AF": "Persa (Afganistán)",
                "fa_IR": "Persa (Irán)",
                "ff": "Fulá",
                "ff_CM": "Fulah (Camerún)",
                "ff_GN": "Fulah (Guinea)",
                "ff_MR": "Fulah (Mauritania)",
                "ff_SN": "Fulah (Senegal)",
                "fi": "finlandés",
                "fi_FI": "Finlandés (Finlandia)",
                "fj": "fiyiano",
                "fo": "feroés",
                "fo_FO": "Feroés (Islas Feroe)",
                "fr": "Francés",
                "fr_BE": "Francés (Bélgica)",
                "fr_BF": "Francés (Burkina Faso)",
                "fr_BI": "Francés (Burundi)",
                "fr_BJ": "Francés (Benín)",
                "fr_BL": "Francés (San Bartolomé)",
                "fr_CA": "Francés (Canadá)",
                "fr_CD": "Francés (Congo - Kinshasa)",
                "fr_CF": "Francés (República Centroafricana)",
                "fr_CG": "Francés (Congo - Brazzaville)",
                "fr_CH": "Francés (Suiza)",
                "fr_CI": "Francés (Costa de Marfil)",
                "fr_CM": "Francés (Camerún)",
                "fr_DJ": "Francés (Yibuti)",
                "fr_DZ": "Francés (Argelia)",
                "fr_FR": "Francés Francia)",
                "fr_GA": "Francés (Gabón)",
                "fr_GF": "Francesa (Guayana Francesa)",
                "fr_GN": "Francés (Guinea)",
                "fr_GP": "Francés (Guadalupe)",
                "fr_GQ": "Francés (Guinea Ecuatorial)",
                "fr_HT": "Francés (Haití)",
                "fr_KM": "Francés (Comoras)",
                "fr_LU": "Francés (Luxemburgo)",
                "fr_MA": "Francés (Marruecos)",
                "fr_MC": "Francés (Mónaco)",
                "fr_MF": "Francés (San Martín)",
                "fr_MG": "Francés (Madagascar)",
                "fr_ML": "Francés (Malí)",
                "fr_MQ": "Francés (Martinica)",
                "fr_MR": "Francés (Mauritania)",
                "fr_MU": "Francés (Mauricio)",
                "fr_NC": "Francés (Nueva Caledonia)",
                "fr_NE": "Francés (Níger)",
                "fr_PF": "Francés (Polinesia Francesa)",
                "fr_PM": "Francés (San Pedro y Miquelón)",
                "fr_RE": "Francés (Reunión)",
                "fr_RW": "Francés (Ruanda)",
                "fr_SC": "Francés (Seychelles)",
                "fr_SN": "Francés (Senegal)",
                "fr_SY": "Francés (Siria)",
                "fr_TD": "Francés (Chad)",
                "fr_TG": "Francés (Togo)",
                "fr_TN": "Francés (Túnez)",
                "fr_VU": "Francés (Vanuatu)",
                "fr_WF": "Francés (Wallis y Futuna)",
                "fr_YT": "Francés (Mayotte)",
                "fy": "Frisia occidental",
                "fy_NL": "Frisia occidental (Países Bajos)",
                "ga": "irlandesa",
                "ga_IE": "Irlandés (Irlanda)",
                "gd": "gaélico escocés",
                "gd_GB": "Gaélico escocés (Reino Unido)",
                "gl": "gallego",
                "gl_ES": "Gallego (España)",
                "gn": "guaraní",
                "gu": "gujarati",
                "gu_IN": "Guyaratí (India)",
                "gv": "de la isla de Man",
                "gv_IM": "Isla de Man (Isla de Man)",
                "ha": "Hausa",
                "ha_GH": "Hausa (Ghana)",
                "ha_Latn": "Hausa (latín)",
                "ha_Latn_GH": "Hausa (latín, Ghana)",
                "ha_Latn_NE": "Hausa (latín, Níger)",
                "ha_Latn_NG": "Hausa (latín, Nigeria)",
                "ha_NE": "Hausa (Níger)",
                "ha_NG": "Hausa (Nigeria)",
                "he": "hebreo",
                "he_IL": "Hebreo (Israel)",
                "hi": "hindi",
                "hi_IN": "Hindú (India)",
                "ho": "Hiri Motu",
                "hr": "croata",
                "hr_BA": "Croata (Bosnia y Herzegovina)",
                "hr_HR": "croata (Croacia)",
                "ht": "haitiano",
                "hu": "húngaro",
                "hu_HU": "Húngaro (Hungría)",
                "hy": "armenio",
                "hy_AM": "Armenio (Armenia)",
                "hz": "Herero",
                "ia": "Interlingua",
                "id": "indonesio",
                "id_ID": "Indonesio (Indonesia)",
                "ie": "interlingue",
                "ig": "igbo",
                "ig_NG": "Igbo (Nigeria)",
                "ii": "Sichuan Yi",
                "ii_CN": "Sichuan Yi (China)",
                "ik": "Inupiaq",
                "io": "Sí",
                "is": "islandés",
                "is_IS": "Islandés (Islandia)",
                "it": "italiano",
                "it_CH": "Italiano (Suiza)",
                "it_IT": "Italiano (Italia)",
                "it_SM": "Italiano (San Marino)",
                "iu": "inuktitut",
                "ja": "japonés",
                "ja_JP": "Japonés (Japón)",
                "jv": "javanés",
                "ka": "georgiano",
                "ka_GE": "Georgiano (Georgia)",
                "kg": "Congo",
                "ki": "kikuyu",
                "ki_KE": "Kikuyu (Kenia)",
                "kj": "Kwanyama",
                "kk": "kazajo",
                "kk_Cyrl": "Kazajo (cirílico)",
                "kk_Cyrl_KZ": "Kazajo (cirílico, Kazajstán)",
                "kk_KZ": "Kazajo (Kazajstán)",
                "kl": "Kalaallisut",
                "kl_GL": "Kalaallisut (Groenlandia)",
                "km": "jemer",
                "km_KH": "Jemer (Camboya)",
                "kn": "canarés",
                "kn_IN": "Canarés (India)",
                "ko": "coreano",
                "ko_KP": "Coreano (Corea del Norte)",
                "ko_KR": "Coreano (Corea del Sur)",
                "kr": "kanuri",
                "ks": "Cachemira",
                "ks_Arab": "Cachemira (árabe)",
                "ks_Arab_IN": "Cachemira (árabe, India)",
                "ks_IN": "Cachemira (India)",
                "ku": "kurdo",
                "kv": "komi",
                "kw": "de Cornualles",
                "kw_GB": "Cornualles (Reino Unido)",
                "ky": "Kirguistán",
                "ky_Cyrl": "Kirguís (cirílico)",
                "ky_Cyrl_KG": "Kirguistán (cirílico, Kirguistán)",
                "ky_KG": "Kirguistán (Kirguistán)",
                "la": "latín",
                "lb": "luxemburgués",
                "lb_LU": "Luxemburgués (Luxemburgo)",
                "lg": "Ganda",
                "lg_UG": "Ganda (Uganda)",
                "li": "Limburgués",
                "ln": "Lingala",
                "ln_AO": "Lingala (Angola)",
                "ln_CD": "Lingala (Congo - Kinshasa)",
                "ln_CF": "Lingala (República Centroafricana)",
                "ln_CG": "Lingala (Congo - Brazzaville)",
                "lo": "laosiano",
                "lo_LA": "Laos (Laos)",
                "lt": "lituano",
                "lt_LT": "lituano (Lituania)",
                "lu": "Luba-Katanga",
                "lu_CD": "Luba-Katanga (Congo - Kinshasa)",
                "lv": "letón",
                "lv_LV": "Letón (Letonia)",
                "mg": "madagascarí",
                "mg_MG": "Malgache (Madagascar)",
                "mh": "marshalés",
                "mi": "maorí",
                "mk": "macedónio",
                "mk_MK": "Macedonio (Macedonia)",
                "ml": "malayalam",
                "ml_IN": "Malayalam (India)",
                "mn": "mongol",
                "mn_Cyrl": "mongol (cirílico)",
                "mn_Cyrl_MN": "Mongol (cirílico, Mongolia)",
                "mn_MN": "mongol (Mongolia)",
                "mo": "macao",
                "mr": "marathi",
                "mr_IN": "Maratí (India)",
                "ms": "malayo",
                "ms_BN": "Malayo (Brunéi)",
                "ms_Latn": "malayo (latín)",
                "ms_Latn_BN": "Malayo (latín, Brunei)",
                "ms_Latn_MY": "Malayo (latín, Malasia)",
                "ms_Latn_SG": "Malayo (latín, Singapur)",
                "ms_MY": "malayo (malasia)",
                "ms_SG": "malayo (singapur)",
                "mt": "maltés",
                "mt_MT": "maltés (Malta)",
                "my": "birmano",
                "my_MM": "Birmano (Myanmar (Birmania))",
                "na": "Nauru",
                "nb": "Bokmål noruego",
                "nb_NO": "Bokmål noruego (Noruega)",
                "nb_SJ": "Bokmål noruego (Svalbard y Jan Mayen)",
                "nd": "Ndebele del norte",
                "nd_ZW": "Ndebele del Norte (Zimbabue)",
                "ne": "nepalí",
                "ne_IN": "Nepalí (India)",
                "ne_NP": "Nepalí (Nepal)",
                "ng": "Ndonga",
                "nl": "Holandés",
                "nl_AW": "Holandés (Aruba)",
                "nl_BE": "Holandés (Bélgica)",
                "nl_BQ": "Holandés (Caribe Países Bajos)",
                "nl_CW": "Holandés (Curazao)",
                "nl_NL": "Holandés (Países Bajos)",
                "nl_SR": "Holandés (Surinam)",
                "nl_SX": "Holandés (Sint Maarten)",
                "nn": "nynorsk noruego",
                "nn_NO": "Nynorsk noruego (Noruega)",
                "no": "noruego",
                "no_NO": "Noruego (Noruega)",
                "nr": "Ndebele del sur",
                "nv": "navajo",
                "ny": "chichewa",
                "oc": "occitano",
                "oj": "Ojibwa",
                "om": "Oromo",
                "om_ET": "Oromo (Etiopía)",
                "om_KE": "Oromo (Kenia)",
                "or": "Oriya",
                "or_IN": "Oriya (India)",
                "os": "osético",
                "os_GE": "Ossético (Georgia)",
                "os_RU": "Ossético (Rusia)",
                "pa": "punjabi",
                "pa_Arab": "Punjabí (árabe)",
                "pa_Arab_PK": "Punjabi (árabe, Pakistán)",
                "pa_Guru": "Punjabí (Gurmukhi)",
                "pa_Guru_IN": "Punjabí (Gurmukhi, India)",
                "pa_IN": "Punjabí (India)",
                "pa_PK": "Punjabí (Pakistán)",
                "pi": "pali",
                "pl": "Polaco",
                "pl_PL": "Polaco (Polonia)",
                "ps": "pastún",
                "ps_AF": "Pastún (Afganistán)",
                "pt": "portugués",
                "pt_AO": "Portugués (Angola)",
                "pt_BR": "Portugués (Brasil)",
                "pt_CV": "Portugués (Cabo Verde)",
                "pt_GW": "Portugués (Guinea-Bisáu)",
                "pt_MO": "Portugués (RAE de Macao, China)",
                "pt_MZ": "Portugués (Mozambique)",
                "pt_PT": "Portugués (Portugal)",
                "pt_ST": "Portugués (Santo Tomé y Príncipe)",
                "pt_TL": "Portugués (Timor Oriental)",
                "qu": "quechua",
                "qu_BO": "Quechua (Bolivia)",
                "qu_EC": "Quechua (Ecuador)",
                "qu_PE": "Quechua (Perú)",
                "rm": "romanche",
                "rm_CH": "Romanche (Suiza)",
                "rn": "Rundi",
                "rn_BI": "Rundi (Burundi)",
                "ro": "rumano",
                "ro_MD": "rumano (Moldavia)",
                "ro_RO": "Rumano (Rumania)",
                "ru": "ruso",
                "ru_BY": "Ruso (Bielorrusia)",
                "ru_KG": "Ruso (Kirguistán)",
                "ru_KZ": "Ruso (Kazajstán)",
                "ru_MD": "Ruso (Moldavia)",
                "ru_RU": "Ruso (Rusia)",
                "ru_UA": "Ruso (Ucrania)",
                "rw": "Kinyarwanda",
                "rw_RW": "Kinyarwanda (Ruanda)",
                "sa": "Sanskrit",
                "sc": "sardo",
                "sd": "sindhi",
                "se": "Sami del norte",
                "se_FI": "Sami del Norte (Finlandia)",
                "se_NO": "Sami del Norte (Noruega)",
                "se_SE": "Sami del Norte (Suecia)",
                "sg": "sango",
                "sg_CF": "Sango (República Centroafricana)",
                "sh": "Serbocroata",
                "sh_BA": "Serbocroata (Bosnia y Herzegovina)",
                "si": "cingalés",
                "si_LK": "Cingalés (Sri Lanka)",
                "sk": "eslovaco",
                "sk_SK": "Eslovaco (Eslovaquia)",
                "sl": "esloveno",
                "sl_SI": "Esloveno (Eslovenia)",
                "sm": "samoano",
                "sn": "Shona",
                "sn_ZW": "Shona (Zimbabue)",
                "so": "somalí",
                "so_DJ": "Somalí (Yibuti)",
                "so_ET": "Somalia (Etiopía)",
                "so_KE": "Somalí (Kenia)",
                "so_SO": "somalí (Somalia)",
                "sq": "albanés",
                "sq_AL": "Albanés (Albania)",
                "sq_MK": "Albanés (Macedonia)",
                "sq_XK": "Albanés (Kosovo)",
                "sr": "serbio",
                "sr_BA": "Serbio (Bosnia y Herzegovina)",
                "sr_Cyrl": "Serbio (cirílico)",
                "sr_Cyrl_BA": "Serbio (cirílico, Bosnia y Herzegovina)",
                "sr_Cyrl_ME": "Serbio (cirílico, Montenegro)",
                "sr_Cyrl_RS": "Serbio (cirílico, Serbia)",
                "sr_Cyrl_XK": "Serbio (cirílico, Kosovo)",
                "sr_Latn": "Serbio (latín)",
                "sr_Latn_BA": "Serbio (latín, Bosnia y Herzegovina)",
                "sr_Latn_ME": "Serbio (latín, Montenegro)",
                "sr_Latn_RS": "Serbio (latín, Serbia)",
                "sr_Latn_XK": "Serbio (latín, Kosovo)",
                "sr_ME": "Serbio (Montenegro)",
                "sr_RS": "serbio (serbia)",
                "sr_XK": "Serbio (Kosovo)",
                "ss": "swati",
                "st": "Sotho, Sur",
                "su": "sundanés",
                "sv": "sueco",
                "sv_AX": "Sueco (Islas Åland)",
                "sv_FI": "Sueco (Finlandia)",
                "sv_SE": "sueco (suecia)",
                "sw": "swahili",
                "sw_KE": "Suajili (Kenia)",
                "sw_TZ": "Suajili (Tanzania)",
                "sw_UG": "Suajili (Uganda)",
                "ta": "Tamil",
                "ta_IN": "Tamil (India)",
                "ta_LK": "Tamil (Sri Lanka)",
                "ta_MY": "Tamil (Malasia)",
                "ta_SG": "Tamil (Singapur)",
                "te": "telugu",
                "te_IN": "Telugu (India)",
                "tg": "tayiko",
                "th": "tailandés",
                "th_TH": "Tailandés (Tailandia)",
                "ti": "Tigrinya",
                "ti_ER": "Tigriña (Eritrea)",
                "ti_ET": "Tigriña (Etiopía)",
                "tk": "turcomano",
                "tl": "tagalo",
                "tl_PH": "Tagalo (Filipinas)",
                "tn": "tswana",
                "to": "tongano",
                "to_TO": "Tongano (Tonga)",
                "tr": "turco",
                "tr_CY": "Turco (Chipre)",
                "tr_TR": "Turco (Turquía)",
                "ts": "Tsonga",
                "tt": "tártaro",
                "tw": "Twi",
                "ty": "tahitiano",
                "ug": "uigur",
                "ug_Arab": "Uigur (árabe)",
                "ug_Arab_CN": "Uigur (árabe, China)",
                "ug_CN": "Uigur (China)",
                "uk": "ucranio",
                "uk_UA": "Ucraniano (Ucrania)",
                "ur": "urdu",
                "ur_IN": "Urdu (India)",
                "ur_PK": "Urdu (Pakistán)",
                "uz": "uzbeko",
                "uz_AF": "Uzbeko (Afganistán)",
                "uz_Arab": "Uzbeko (árabe)",
                "uz_Arab_AF": "Uzbeko (árabe, Afganistán)",
                "uz_Cyrl": "Uzbeko (cirílico)",
                "uz_Cyrl_UZ": "Uzbeko (cirílico, Uzbekistán)",
                "uz_Latn": "Uzbeko (latín)",
                "uz_Latn_UZ": "Uzbeko (latín, Uzbekistán)",
                "uz_UZ": "Uzbeko (Uzbekistán)",
                "ve": "venta",
                "vi": "vietnamita",
                "vi_VN": "Vietnamita (Vietnam)",
                "vo": "Volapük",
                "wa": "valón",
                "wo": "wólof",
                "xh": "xhosa",
                "yi": "yídish",
                "yo": "Yoruba",
                "yo_BJ": "Yoruba (Benín)",
                "yo_NG": "Yoruba (Nigeria)",
                "za": "Zhuang",
                "zh": "Chino",
                "zh_CN": "Chino (China)",
                "zh_HK": "Chino (RAE de Hong Kong, China)",
                "zh_Hans": "Chino simplificado)",
                "zh_Hans_CN": "Chino (simplificado, China)",
                "zh_Hans_HK": "Chino (simplificado, RAE de Hong Kong, China)",
                "zh_Hans_MO": "Chino (simplificado, RAE de Macao, China)",
                "zh_Hans_SG": "Chino (simplificado, Singapur)",
                "zh_Hant": "Chino tradicional)",
                "zh_Hant_HK": "Chino (tradicional, RAE de Hong Kong, China)",
                "zh_Hant_MO": "Chino (tradicional, RAE de Macao, China)",
                "zh_Hant_TW": "Chino (tradicional, Taiwán)",
                "zh_MO": "Chino (RAE de Macao, China)",
                "zh_SG": "Chino (Singapur)",
                "zh_TW": "Chino (Taiwán)",
                "zu": "zulú",
                "zu_ZA": "Zulú (Sudáfrica)"
            },
            "mail": {
                "action": "Si tiene problemas para hacer clic en el botón \"{actionText}\", copie y pegue la siguiente URL en su navegador web:",
                "auth": {
                    "deleted": {
                        "line1": "Le informamos que su cuenta UgoZER ha sido eliminada.",
                        "reason": {
                            "intro": "Éste ha sido eliminado por el siguiente motivo:",
                            "never_authenticated_for_too_long": "Tu cuenta fue creada hace más de una semana.\n                    y nunca iniciaste sesión:\n                    por lo que esta cuenta se considera inactiva.",
                            "not_logged_for_too_long": "No has iniciado sesión durante más de 2 años:\n                    su cuenta se considera inactiva."
                        },
                        "subject": "Eliminación de su cuenta UgoZER"
                    },
                    "login_attempt_unverified_credential": {
                        "action": "Confirme su dirección de correo electrónico",
                        "line1": "Se intentó iniciar sesión en su cuenta UgoZER con la dirección de correo electrónico {credential}.",
                        "line2": "Si usted inició este intento de inicio de sesión,\n                por favor haga clic en el botón a continuación para verificar su dirección de correo electrónico\n                y poder conectarnos.",
                        "line3": "Este enlace caducará en 1 semana.",
                        "line4": "Si no inició este intento de inicio de sesión, no es necesario realizar ninguna otra acción.",
                        "subject": "Intente iniciar sesión en su cuenta UgoZER"
                    },
                    "login_magic_link": {
                        "action": "Acceso",
                        "line1": "Haga clic en este enlace para iniciar sesión",
                        "line2": "¡Gracias por utilizar nuestra aplicación!",
                        "subject": "Enlace de inicio de sesión sin contraseña"
                    },
                    "not_logged": {
                        "intro": "Han pasado casi 2 años desde la última vez que inició sesión en su cuenta UgoZER.",
                        "outro": "Simplemente inicie sesión en su cuenta para que no se elimine.",
                        "remaining": {
                            "two_days": "Te quedan 2 días antes de que se elimine tu cuenta.",
                            "two_months": "Te quedan 2 meses antes de que se elimine tu cuenta.",
                            "two_weeks": "Te quedan 2 semanas antes de que se elimine tu cuenta."
                        },
                        "subject": "Su cuenta UgoZER se eliminará pronto"
                    },
                    "registration": {
                        "action": "Confirme su dirección de correo electrónico",
                        "line1": "Haga clic en el botón a continuación para verificar su dirección de correo electrónico y poder iniciar sesión.",
                        "line2": "Este enlace caducará en 1 semana.",
                        "line3": "Si no creó una cuenta, no es necesario realizar ninguna otra acción.",
                        "subject": "Verifique su dirección de correo electrónico"
                    },
                    "registration_attempt": {
                        "line1": "El correo electrónico <strong>{credential}</strong> ya se utiliza para una cuenta <strong>{role_type}</strong> en nuestra aplicación.",
                        "line2": "Para crear una cuenta, inicie el registro nuevamente con otro correo electrónico.",
                        "subject": "Intente registrarse con su credencial UgoZER"
                    },
                    "registration_attempt_unverified_credential": {
                        "line1": "El correo electrónico <strong>{credential}</strong> ya se utiliza para una cuenta <strong>{role_type}</strong> en nuestra aplicación.",
                        "line2": "Para crear una cuenta, inicie el registro nuevamente con otro correo electrónico.",
                        "subject": "Intente registrarse con su credencial UgoZER"
                    },
                    "reset_password": {
                        "action": "Restablecer la contraseña",
                        "line1": "Está recibiendo este correo electrónico porque recibimos una solicitud de restablecimiento de contraseña para su cuenta.",
                        "line2": "Este enlace de restablecimiento de contraseña caducará en {count} minutos.",
                        "line3": "Si no solicitó un restablecimiento de contraseña, no es necesario realizar ninguna otra acción.",
                        "subject": "Notificación de restablecimiento de contraseña"
                    }
                },
                "copyright": "© {year} UgoZER - Todos los derechos reservados.",
                "credential_verification": {
                    "verify": {
                        "action": "Confirme su dirección de correo electrónico",
                        "line1": "Haga clic en el botón a continuación para verificar su dirección de correo electrónico.",
                        "line2": "Este enlace caducará en 1 semana.",
                        "line3": "Si no solicitó verificación, no se requiere ninguna otra acción.",
                        "subject": "Confirmación de su correo electrónico"
                    }
                },
                "hello": "¡Hola!",
                "invitation": {
                    "attempt": {
                        "action": "Acceso a la aplicación",
                        "line1": "Has sido invitado a unirte a nuestra aplicación.",
                        "line1_by": "Has sido invitado por {name} a unirte a nuestra aplicación.",
                        "line1_by_from": "Has sido invitado por {name} de {poi_title} institución a unirte a nuestra aplicación",
                        "line2": "Ya tienes una cuenta existente en nuestra aplicación.",
                        "line3": "Haga clic en el botón a continuación para acceder a la aplicación.",
                        "subject": "Invitación a unirse a UgoZER"
                    },
                    "verification": {
                        "action": "Confirme su dirección de correo electrónico",
                        "excursion_shared": "Se ha compartido un itinerario contigo. Lo podéis encontrar adjunto en formato PDF,\n                    así como en la aplicación en tus acciones.",
                        "line1": "Has sido invitado a unirte a nuestra aplicación.",
                        "line1_by": "Has sido invitado por {name} a unirte a nuestra aplicación.",
                        "line1_by_from": "Has sido invitado por {name} de {poi_title} institución a unirte a nuestra aplicación",
                        "line2": "Haga clic en el botón a continuación para verificar su dirección de correo electrónico.",
                        "line3": "Este enlace caducará en 1 semana.",
                        "line4": "¡Gracias por utilizar nuestra aplicación!",
                        "point_of_interest_shared": "Se ha compartido contigo un punto de interés. Puedes encontrarlo\n                en la aplicación en sus acciones.",
                        "subject": "Invitación a unirse a UgoZER"
                    }
                },
                "invitation_professional": {
                    "content": "Has sido invitado a unirte a la sección profesional de nuestra aplicación UgoZER",
                    "cta": "Regístrate en la sección profesional",
                    "subject": "UgoZER - Invitación acceso profesional",
                    "thanks": "Gracias,",
                    "title": "Invitación a la sección profesional de la aplicación UgoZER"
                },
                "preview": {
                    "default": {
                        "subject": "Asunto del email",
                        "user": {
                            "name": "UgoZER"
                        }
                    },
                    "from": "De:",
                    "mails": {
                        "auth/account-deleted-notification": "Eliminación de cuenta",
                        "auth/not-logged-notification": "Eliminar si no está conectado",
                        "auth/registration-attempt-notification": "Intento de registro",
                        "auth/registration-notification": "Registro",
                        "auth/reset-password-notification": "cambia tu contraseña",
                        "invitation/invited-notification": "Invitación",
                        "share/created-notification": "Nuevo",
                        "support-message/message-notification": "Nueva respuesta",
                        "support-request/archived-notification": "Solicitud archivada",
                        "support-request/created-notification": "Solicitud creada",
                        "user-credential-verification/login-attempt-notification": "Intenta conectar",
                        "user-credential-verification/registration-attempt-notification": "Intento de registro",
                        "user-credential-verification/verify-notification": "Confirmacion de cuenta",
                        "user/welcome-notification": "Bienvenido"
                    },
                    "main_title": "Vista previa de correo electrónico",
                    "sections": {
                        "auth": "Autenticación",
                        "invitation": "Invitaciones",
                        "share": "Comparte",
                        "support-message": "Mensajes de soporte",
                        "support-request": "Solicitudes de soporte",
                        "user": "Usuario",
                        "user-credential-verification": "Credencial de usuario"
                    }
                },
                "salutations": "Saludos",
                "share": {
                    "created": {
                        "action": "Espectáculo",
                        "excursion": {
                            "gotoapp": "Vaya a la aplicación para verlo.",
                            "line": "{username} de la institución {poi_title} compartió un itinerario contigo.",
                            "line_alt": "{username} compartió un itinerario contigo.",
                            "line_alt_2": "Se ha compartido un itinerario contigo.",
                            "subject": "Se ha compartido un itinerario contigo."
                        },
                        "line": "{username} de la institución {poi_title} compartió un artículo contigo.",
                        "line_alt": "{username} compartió un artículo contigo.",
                        "line_alt_2": "Se ha compartido un artículo contigo.",
                        "point_of_interest": {
                            "line": "{username} de la institución {poi_title} compartió un punto de interés contigo.",
                            "line_alt": "{username} compartió un punto de interés contigo.",
                            "line_alt_2": "Se ha compartido contigo un punto de interés.",
                            "subject": "Se ha compartido contigo un punto de interés."
                        },
                        "subject": "Se ha compartido un artículo contigo."
                    }
                },
                "subscription": {
                    "not_continue_after_free_period": {
                        "cta": "Ver detalle",
                        "line1": "{name} no continuó después de que finalizó el período gratuito",
                        "line2": "Por favor haga clic en el botón de abajo para acceder al detalle",
                        "subject": "El suscriptor no continuó después de que finalizó el período gratuito"
                    },
                    "suspended": {
                        "line": "Su suscripción ha sido suspendida por el siguiente motivo: {suspension_reason}.",
                        "line_alt": "Tu suscripción ha sido suspendida.",
                        "subject": "Suscripción suspendida"
                    },
                    "switched_from_paid_to_free": {
                        "cta": "Ver detalle",
                        "line1": "{name} cambió al plan gratuito",
                        "line2": "Por favor haga clic en el botón de abajo para acceder al detalle",
                        "subject": "El suscriptor cambió al plan gratuito"
                    }
                },
                "support_request": {
                    "archived": {
                        "subject": "UgoZER - Solicitud de soporte archivada",
                        "title": "Su solicitud de soporte ha sido archivada"
                    },
                    "message": {
                        "action": "Acceso a la solicitud de soporte",
                        "line1": "Haga clic en el botón a continuación para acceder a su solicitud.",
                        "subject": "UgoZER - Tienes una respuesta",
                        "title": "Se ha publicado una respuesta a su solicitud de soporte."
                    },
                    "request": {
                        "action": "Acceso a la solicitud de soporte",
                        "line1": "El soporte de contacto responderá dentro de las 48 horas.",
                        "line2": "Haga clic en el botón a continuación para acceder a su solicitud.",
                        "subject": "UgoZER - Su solicitud de soporte ha sido creada"
                    }
                },
                "user_send_link": {
                    "content": "¡Vuelve a conectarte a nuestra aplicación UgoZER! Si no recuerda su contraseña, puede restablecerla.",
                    "cta": "Iniciar sesión en la aplicación",
                    "subject": "UgoZER - Aplicación",
                    "thanks": "Gracias,",
                    "title": "Tienes una cuenta en nuestra aplicación, ¡inicie sesión!"
                },
                "welcome": {
                    "action": "Acceso a la aplicación",
                    "line1": "Estamos felices de contar con usted entre nuestros usuarios.",
                    "line2": "Haga clic en el botón de abajo para acceder a la aplicación.",
                    "subject": "Bienvenido a UgoZER",
                    "title": "Bienvenido a UgoZER, tu aplicación gratuita de asistencia en viajes en varios idiomas."
                }
            },
            "media": {
                "audio": {
                    "generated_audio": "audio generado",
                    "recording": {
                        "errors": {
                            "default": "Error de grabación",
                            "not_allowed": "La grabación falló. Permita que su navegador y UgoZER usen su micrófono y vuelva a intentarlo."
                        }
                    }
                },
                "default": {
                    "alt": "Mapa estático de Google Maps",
                    "caption": "Haga clic en el mapa para mostrarlo en Google Maps"
                },
                "image": {
                    "credits": "Créditos: {credits}"
                }
            },
            "models": {
                "activity_log": "Registro de actividad|Registros de actividad",
                "address": "Dirección|Direcciones",
                "bank_transfer": "Transferencia bancaria|Transferencias bancarias",
                "bank_transfer_institution": "Institución de transferencia bancaria|Instituciones de transferencia bancaria",
                "category": "Categoría|Categorías",
                "changelog": "Registro de cambios|Registros de cambios",
                "claim": "Reclamación|Reclamaciones",
                "claim_dispute": "Disputa de reclamación|Disputas de reclamación",
                "currency": "Moneda|Monedas",
                "data_tourisme_category": "Categoría DataTurismo|Categorías DataTurismo",
                "data_tourisme_tag": "Etiqueta de DataTurismo|Etiquetas de DataTurismo",
                "excursion": "Itinerario|Itinerarios",
                "institution_employee": "Empleado|Empleados",
                "interaction": "Interacción|Interacciones",
                "locale": "Idioma|Idiomas",
                "medium": "Medio|Medios",
                "notification": "Notificación|Notificaciones",
                "partner": "Socio|Socios",
                "payment": "Pago|Pagos",
                "permission": "Permiso|Permisos",
                "plan": "Plan de suscripción|Planes de suscripción",
                "plan_price": "Precio del plan|Precios del plan",
                "point_of_interest": "Punto de interés|Puntos de interés",
                "point_of_interest_category": "Categoría|Categorías",
                "post": "Publicar|Publicaciones",
                "post_category": "Categoría de publicaciones|Categorías de publicaciones",
                "privacy_policy": "Política de privacidad|Políticas de privacidad",
                "report": "Informe|Informes",
                "reservation": "Reserva|Reservas",
                "role": "Rol|Roles",
                "share": "Compartir|Acciones",
                "subscription": "Suscripción|Suscripciones",
                "support_category": "Categoría de soporte|Categorías de soporte",
                "support_message": "Mensaje|Mensajes",
                "support_request": "Solicitud|Solicitudes",
                "tag": "Etiqueta|Etiquetas",
                "terms_condition": "Términos y condiciones|Términos y condiciones",
                "traveller_email": "Correo electrónico de viajero|Correos electrónicos de viajero",
                "traveller_folder": "Carpeta de viajero|Carpetas de viajero",
                "user": "Usuario|Usuarios",
                "user_credential_verification": "Verificación de credenciales|Verificaciones de credenciales",
                "user_geoloc_setting": "Configuración de Geoloc|Configuración de Geoloc",
                "user_notification_setting": "Configuración de notificaciones|Configuración de notificaciones",
                "user_password_reset": "Restablecimiento de contraseña de usuario|Restablecimiento de contraseñas de usuario",
                "user_privacy_setting": "Configuración de privacidad|Configuración de privacidad",
                "users_relationship": "Contacto|Contactos",
                "vat_rate": "Tipo de IVA|Tipos de IVA"
            },
            "navigations": {
                "items": {
                    "changelogs": "Registros de cambios",
                    "contact_us": "Contáctenos",
                    "contacts": "Contactos",
                    "cookies": "Gestión de cookies",
                    "credits": "Créditos",
                    "customers": "Ellos confían en nosotros",
                    "faqs": "Preguntas más frecuentes",
                    "folders": "Carpetas de viajero",
                    "invite_professional": "invita a un profesional",
                    "invite_traveller": "invitar a un viajero",
                    "partners": "Socios",
                    "payments": "Pagos",
                    "privacy_policy": "Política de privacidad",
                    "settings": "Parámetros",
                    "stays": "Corsé",
                    "terms_conditions": "Términos y condiciones",
                    "users": "Usuarios"
                }
            },
            "notifications": {
                "changelog": {
                    "created": {
                        "text": "Se ha implementado la versión {version}. Puedes consultar las novedades pulsando en la notificación.",
                        "title": "Nuevo registro de cambios"
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "text": "La disputa del reclamo sobre el punto de interés {poi} está pendiente de verificación por parte de nuestro equipo.",
                        "title": "Disputa de reclamación pendiente de verificación"
                    },
                    "rejected": {
                        "no_reason": "Ninguna razón dada",
                        "text": "La disputa de reclamación al punto de interés {poi} fue rechazada por el siguiente motivo: {reason}.",
                        "title": "Disputa de reclamación rechazada"
                    },
                    "validated": {
                        "text": "¡Se ha aceptado la disputa de reclamo sobre el punto de interés {poi}!",
                        "title": "¡Disputa de reclamación validada!"
                    }
                },
                "index": {
                    "no_results": "¡No Notificaciones!"
                },
                "institution_employee": {
                    "approved": {
                        "text": "Su solicitud para unirse a {poi} ha sido aprobada",
                        "title": "Solicitud de empleado aprobada"
                    },
                    "declined": {
                        "text": "Su solicitud para unirse a {poi} ha sido rechazada",
                        "title": "Solicitud del empleado rechazada"
                    },
                    "pending": {
                        "text": "{user} solicita ser registrado como empleado de su institución {poi}",
                        "title": "Solicitud de empleado recibida"
                    },
                    "removed": {
                        "text": "Su estado de empleado de {poi} ha sido eliminado",
                        "title": "Estado de empleado eliminado"
                    }
                },
                "invitation": {
                    "accepted": {
                        "email": {
                            "text": "Su invitación para unirse a la solicitud enviada a {credential} ha sido aceptada"
                        },
                        "phone": {
                            "text": "Su invitación para unirse a la solicitud enviada a {credential} ha sido aceptada"
                        },
                        "title": "Invitación aceptada"
                    },
                    "pro": {
                        "text": "Tu invitación profesional ha sido enviada al correo electrónico {email}",
                        "title": "Invitación profesional enviada"
                    }
                },
                "office": {
                    "claim": {
                        "content": {
                            "line_1": "Se acaba de reclamar un punto de interés.",
                            "line_2": "Haga clic en el botón a continuación para ver los detalles en la interfaz de administración."
                        },
                        "cta": "Ver reclamo",
                        "subject": "¡Nuevo reclamo!"
                    },
                    "claim_dispute": {
                        "content": {
                            "line_1": "Se acaba de impugnar un punto de interés.",
                            "line_2": "Haga clic en el botón a continuación para ver los detalles en la interfaz de administración."
                        },
                        "cta": "Ver disputa",
                        "subject": "¡Nueva disputa!"
                    },
                    "report": {
                        "content": {
                            "line_1": "Se acaba de informar {type}."
                        },
                        "cta": "Ver informe",
                        "subject": "¡Nuevo reporte!",
                        "type": {
                            "App\\Models\\PointOfInterest": "Un punto de interés",
                            "App\\Models\\User": "un usuario"
                        }
                    },
                    "support_request": {
                        "content": {
                            "line_1": "Se acaba de agregar una nueva solicitud de soporte."
                        },
                        "cta": "Ver solicitud de soporte",
                        "subject": "¡Nueva solicitud de soporte!"
                    }
                },
                "payment": {
                    "proposal": {
                        "text": "Agradezca a {username} de la institución {poi_title} dejando un consejo.",
                        "text_alt": "Agradezca al conserje de la institución {poi_title} dejando una propina.",
                        "text_alt_2": "Gracias {username} dejando un consejo",
                        "text_alt_3": "Agradezca al conserje dejando una propina.",
                        "title": "Deja una propina"
                    },
                    "received": {
                        "text": "Recibiste una propina {amount} de {payer} de {poi_title} institución",
                        "text_alt": "Recibiste una propina {amount} de {payer}",
                        "text_alt_2": "Recibiste una propina {amount}",
                        "title": "Propina recibida"
                    },
                    "thanks": {
                        "text": "{payee} de {poi_title} institución gracias por el consejo!",
                        "text_alt": "{payee} gracias por el consejo!",
                        "text_alt_2": "Te han agradecido el consejo.",
                        "title": "¡Gracias por el consejo!"
                    }
                },
                "point_of_interest": {
                    "event": {
                        "expired": {
                            "text": "El evento {title}, incluido en tus itinerarios, está caducado",
                            "title": "Evento caducado"
                        }
                    },
                    "moderation": {
                        "denied": {
                            "text": "Tu punto de interés {title} ha sido denegado tras su moderación",
                            "title": "Punto de interés denegado"
                        },
                        "published": {
                            "text": "Tu punto de interés {title} ha sido publicado siguiendo su moderación",
                            "title": "Punto de interés publicado"
                        }
                    }
                },
                "reservation": {
                    "created": {
                        "text": "{butler_name} de la institución {butler_poi_title} ha hecho una reserva\n                en tu institución {poi_title}",
                        "text_alt": "Un conserje de la institución {butler_poi_title} ha realizado una reserva.\n                en tu institución {poi_title}",
                        "text_alt_2": "{butler_name} ha hecho una reserva\n                en tu institución {poi_title}",
                        "text_alt_3": "Se ha hecho una reserva.\n                en tu institución {poi_title}",
                        "title": "Reserva en tu institución"
                    }
                },
                "share": {
                    "created": {
                        "excursion": {
                            "text": "{username} de {poi_title} institución compartió un itinerario contigo",
                            "text_alt": "{username} compartió un itinerario contigo",
                            "text_alt_2": "Se ha compartido un itinerario contigo.",
                            "title": "Compartir un itinerario"
                        },
                        "point_of_interest": {
                            "text": "{username} de {poi_title} institución compartió un punto de interés contigo",
                            "text_alt": "{username} compartió un punto de interés contigo",
                            "text_alt_2": "Se ha compartido contigo un punto de interés.",
                            "title": "Comparte un punto de interés"
                        },
                        "text": "{username} de {poi_title} institución compartió un artículo contigo",
                        "text_alt": "{username} compartió un artículo contigo",
                        "text_alt_2": "Se ha compartido un artículo contigo."
                    }
                },
                "stripe": {
                    "account_requirements": {
                        "text": "Tras la verificación de su cuenta Stripe, se solicita nueva información",
                        "title": "Requisitos de la cuenta Stripe"
                    },
                    "payments_enabled": {
                        "text": "Ahora puedes recibir pagos a través de Stripe",
                        "title": "Pagos de franjas habilitados"
                    }
                },
                "support_request": {
                    "archived": {
                        "text": "Su solicitud de soporte ha sido archivada",
                        "title": "Solicitud de soporte archivada"
                    },
                    "message": {
                        "text": "Se ha publicado una respuesta a su solicitud de soporte.",
                        "title": "Mensaje recibido"
                    }
                },
                "traveller_folder": {
                    "approved": {
                        "text": "{username} ha aprobado su carpeta de viajero",
                        "text_alt": "Un viajero ha aprobado su carpeta",
                        "title": "Carpeta de viajero aprobada"
                    },
                    "archived": {
                        "text": "{username} ha archivado su carpeta de viajero",
                        "text_alt": "Un viajero ha archivado su carpeta",
                        "title": "Carpeta de viajero archivada"
                    },
                    "created": {
                        "text": "Se ha registrado una estancia en tu cuenta en el hotel {poi_title}.",
                        "title": "Mantente creado"
                    },
                    "pending": {
                        "text": "{username} ha registrado en tu cuenta una estancia en el hotel {poi_title}.\n                Por favor, confirme eso.",
                        "text_alt": "Se ha registrado una estancia en tu cuenta en el hotel {poi_title}.\n                Por favor, confirme eso.",
                        "title": "Queda pendiente de confirmación"
                    },
                    "thanks": {
                        "text": "{username} de {poi_title} institución gracias por su estadía",
                        "text_alt": "Un conserje de {poi_title} institución gracias por su estancia.",
                        "title": "Gracias por tu estancia"
                    }
                }
            },
            "nova": {
                "actions": {
                    "bank_transfer": {
                        "transfer": "hacer transferencia bancaria"
                    },
                    "changelog": {
                        "archive": "Archivo",
                        "draft": "Borrador",
                        "publish": "Publicar"
                    },
                    "claim": {
                        "missing_info": "información faltante",
                        "reject": "Rechazar",
                        "revoke": "Revocar",
                        "validate": "Validar"
                    },
                    "claim_dispute": {
                        "reject": "Rechazar",
                        "validate": "Validar"
                    },
                    "payment": {
                        "download_invoice": "Descargar factura"
                    },
                    "plan": {
                        "archive": "Archivo",
                        "draft": "Borrador",
                        "publish": "Publicar"
                    },
                    "point_of_interest": {
                        "deny": "Denegar",
                        "draft": "Borrador",
                        "publish": "Validar",
                        "submit": "Pide moderación"
                    },
                    "post": {
                        "archive": "Archivo",
                        "draft": "Borrador",
                        "publish": "Publicar",
                        "set_coming_soon": "Establecido en \"próximamente\""
                    },
                    "privacy_policy": {
                        "archive": "Archivo",
                        "confirmation": {
                            "confirm_button_text": "Publicar",
                            "confirm_text": "¿Estás seguro de que quieres publicar esta política de privacidad? ¡A TODOS los usuarios se les pedirá que los validen nuevamente!"
                        },
                        "errors": {
                            "resources_count": "¡No puedes publicar más de UNA política privada!"
                        },
                        "publish": "Publicar"
                    },
                    "report": {
                        "process": "Proceso",
                        "reject": "Rechazar"
                    },
                    "support_request": {
                        "answer": "Respuesta",
                        "archive": "Archivo",
                        "cancel": "Cancelar",
                        "pending_support": "esperando apoyo",
                        "pending_user": "Esperando usuario",
                        "resolve": "Resolver"
                    },
                    "terms_condition": {
                        "archive": "Archivo",
                        "confirmation": {
                            "confirm_button_text": "Publicar",
                            "confirm_text": "¿Estás seguro de que deseas publicar estos términos y condiciones? ¡A TODOS los usuarios se les pedirá que los validen nuevamente!"
                        },
                        "errors": {
                            "resources_count": "¡No puedes publicar más de UN término y condiciones!"
                        },
                        "publish": "Publicar"
                    },
                    "user": {
                        "finish": "Terminar el tutorial"
                    },
                    "user_credential_verification": {
                        "validate": "Validar"
                    }
                },
                "cards": {
                    "new_excursions": "Nuevos itinerarios",
                    "new_points_of_interest": "Nuevos puntos de interés",
                    "new_users": "Usuarios nuevos"
                },
                "filters": {
                    "app": "Solicitud",
                    "archived_at_gte": "Archivado después",
                    "archived_at_lte": "Archivado antes",
                    "canceled_at_gte": "Cancelado después",
                    "canceled_at_lte": "Cancelado antes",
                    "created_at_gte": "Creado después",
                    "created_at_lte": "Creado antes",
                    "dynamic_translations_enabled": "Traducciones dinámicas habilitadas",
                    "extranet": "Extranet",
                    "front": "Frente",
                    "group": "Grupo",
                    "published_at_gte": "Publicado después",
                    "published_at_lte": "Publicado antes",
                    "reservation_date_gte": "Reserva después",
                    "reservation_date_lte": "Reserva antes",
                    "resolved_at_gte": "Resuelto después",
                    "resolved_at_lte": "Resuelto antes",
                    "static_translations_enabled": "Traducciones estáticas habilitadas",
                    "to_validate": "Validar"
                },
                "labels": {
                    "institutions": "Instituciones",
                    "point_of_interest_categories": "Categorías"
                }
            },
            "opening_hours": {
                "additional_info": "Información adicional",
                "content": {
                    "date": "el {date}",
                    "date_range_from": "Desde {from}",
                    "date_range_from_to": "De {from} a {to}",
                    "date_range_to": "A {to}",
                    "hours_range_from": "Desde {from}",
                    "hours_range_from_to": "De {from} a {to}",
                    "hours_range_to": "A {to}"
                }
            },
            "partners": {
                "customers": {
                    "title": "Nuestros clientes"
                },
                "index": {
                    "no_results": "No se encontraron socios",
                    "our_last_partners": "Nuestros últimos socios",
                    "title": "Nuestros compañeros"
                },
                "show": {
                    "link": "Visita el sitio web"
                }
            },
            "payments": {
                "checkout": {
                    "cancel": "El pago ha sido cancelado.",
                    "custom_amount": "Ingrese la cantidad personalizada",
                    "success": "Pago realizado exitosamente",
                    "tip_amount": "Monto de propina"
                },
                "index": {
                    "no_results": "No se encontraron pagos"
                },
                "labels": {
                    "payee": "Tenedor:",
                    "payment_service": "Pagado por"
                },
                "payable": {
                    "reservation": "Número de reserva: identificación"
                },
                "thanks": {
                    "success": "Gracias enviado!"
                }
            },
            "pdf": {
                "common": {
                    "generating": "Generando archivo PDF...",
                    "waiting": "Espere por favor..."
                },
                "payments": {
                    "period": "Periodo de {from} a {to}",
                    "title": "Pagos recibidos"
                },
                "receipt": {
                    "debited_amount": "Monto debitado",
                    "paid_amount": "Monto de pago",
                    "payment_date": "Fecha de pago",
                    "payment_method": "Método de pago",
                    "receipt_for": "Recibo del pago realizado a",
                    "receipt_number": "Recibo de pago #{id}",
                    "summary": "Resumen"
                },
                "share": {
                    "cta": "Consultar el itinerario",
                    "details": {
                        "line1": "Nos complace compartir con usted su itinerario personalizado.",
                        "line2": "Encontrarás el contenido de tu itinerario a continuación.",
                        "line3": "Para acceder a la información detallada (audio con dirección en idioma local, mapa interactivo,\n                fotos, descripción completa...), haz clic en el botón de abajo."
                    }
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "ADMIN": "Accede a la administración",
                        "APP": "Accede a la aplicación",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con el acceso",
                        "EXTRANET": "Accede a la sección profesional",
                        "HORIZON": "Horizonte de acceso",
                        "TELESCOPE": "Telescopio de acceso",
                        "VIEW_BUTLER_DASHBOARD": "Ver panel de mayordomo",
                        "VIEW_HOTELIER_DASHBOARD": "Ver panel del hotelero",
                        "VIEW_MANAGER_DASHBOARD": "Ver panel del administrador",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "Ver panel de la oficina de turismo",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "Ver panel de empleados de la oficina de turismo",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "Inicie sesión sin tener que verificar su credencial"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con registros de actividad",
                        "CREATE": "Crear un registro de actividad",
                        "DELETE": "Eliminar un registro de actividad",
                        "FORCE_DELETE": "Forzar la eliminación de un registro de actividad",
                        "RESTORE": "Restaurar un registro de actividad",
                        "UPDATE": "Actualizar un registro de actividad",
                        "VIEW": "Consultar un registro de actividad",
                        "VIEW_ANY": "Consultar índice de registros de actividad"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con direcciones",
                        "CREATE": "crear una dirección",
                        "DELETE": "Eliminar una dirección",
                        "FORCE_DELETE": "Forzar la eliminación de una dirección",
                        "RESTORE": "restaurar una dirección",
                        "UPDATE": "Actualizar una dirección",
                        "VIEW": "Consultar una dirección",
                        "VIEW_ANY": "Consultar índice de direcciones"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con transferencias bancarias a instituciones",
                        "VIEW_ANY": "Consultar índice de instituciones de transferencias bancarias"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "ARCHIVE": "Archivar un registro de cambios",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con registros de cambios",
                        "CREATE": "Crear un registro de cambios",
                        "DELETE": "Eliminar un registro de cambios",
                        "DRAFT": "Redactar un registro de cambios",
                        "FORCE_DELETE": "Forzar la eliminación de un registro de cambios",
                        "PUBLISH": "Publicar un registro de cambios",
                        "RESTORE": "Restaurar un registro de cambios",
                        "UPDATE": "Actualizar un registro de cambios",
                        "VIEW": "Consultar un registro de cambios",
                        "VIEW_ANY": "Consultar índice de changelogs"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con disputas de reclamos",
                        "CREATE": "Crear una disputa de reclamación",
                        "DELETE": "Eliminar una disputa de reclamo",
                        "FORCE_DELETE": "Forzar la eliminación de una disputa de reclamo",
                        "REJECT": "Rechazar una disputa de reclamo",
                        "RESTORE": "Restaurar una disputa de reclamación",
                        "UPDATE": "Actualizar una disputa de reclamo",
                        "VALIDATE": "Validar una disputa de reclamo",
                        "VIEW": "Consultar una reclamación de disputa",
                        "VIEW_ANY": "Consultar índice de disputas de siniestros"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con reclamos",
                        "CREATE": "Crear un reclamo",
                        "DELETE": "Eliminar un reclamo",
                        "FORCE_DELETE": "Forzar la eliminación de un reclamo",
                        "MISSING_INFO": "Rechazar un reclamo por falta de información",
                        "REJECT": "Rechazar un reclamo",
                        "RESTORE": "Restaurar un reclamo",
                        "REVOKE": "revocar un reclamo",
                        "SHOW_FILE": "Mostrar un archivo de reclamación",
                        "UPDATE": "Actualizar un reclamo",
                        "VALIDATE": "Validar un reclamo",
                        "VIEW": "Consultar una reclamación",
                        "VIEW_ANY": "Consultar índice de reclamaciones"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con países",
                        "USE_ANY": "Usa cualquier país"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con monedas",
                        "CREATE": "Crear una moneda",
                        "DELETE": "Eliminar una moneda",
                        "FORCE_DELETE": "Forzar la eliminación de una moneda",
                        "RESTORE": "Restaurar una moneda",
                        "UPDATE": "Actualizar una moneda",
                        "VIEW": "Consultar una moneda",
                        "VIEW_ANY": "Consultar índice de divisas"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con cuentas EasyTransac",
                        "HAVE": "Tener una cuenta EasyTransac"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con itinerarios",
                        "CREATE": "Crear un itinerario",
                        "DELETE": "Eliminar un itinerario",
                        "FORCE_DELETE": "Forzar eliminación de un itinerario",
                        "RESTORE": "Restaurar un itinerario",
                        "SHARE": "Compartir un itinerario",
                        "UPDATE": "Actualizar un itinerario",
                        "VIEW": "Consultar un itinerario",
                        "VIEW_ANY": "Consultar índice de itinerarios",
                        "VIEW_KIND_UGOZER_APP": "Ver itinerarios con la aplicación UgoZER",
                        "VIEW_KIND_UGOZER_PRO": "Ver itinerarios con el tipo UgoZER Pro"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con enlaces entre itinerarios y puntos de interés",
                        "CREATE": "Crear un vínculo entre un itinerario y un punto de interés",
                        "DELETE": "Eliminar un enlace entre un itinerario y un punto de interés",
                        "FORCE_DELETE": "Forzar la eliminación de un vínculo entre un itinerario y un punto de interés",
                        "RESTORE": "Restaurar un vínculo entre un itinerario y un punto de interés",
                        "UPDATE": "Actualizar un vínculo entre un itinerario y un punto de interés",
                        "VIEW": "Consultar un enlace entre un itinerario y un punto de interés.",
                        "VIEW_ANY": "Consultar índice de enlaces entre itinerarios y puntos de interés."
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "APPROVE": "Aprobar una solicitud de empleado de una institución",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con empleados de instituciones",
                        "CANCEL": "Cancelar una solicitud de empleado de la institución",
                        "CREATE": "Crear un empleado de la institución",
                        "DECLINE": "Rechazar una solicitud de empleado de la institución",
                        "DELETE": "Eliminar un empleado de la institución",
                        "FEATURE": "Destacar a un empleado de una institución",
                        "FORCE_DELETE": "Forzar la eliminación de un empleado de la institución",
                        "REMOVE": "Eliminar a un empleado de una institución",
                        "RESTORE": "Restaurar a un empleado de la institución",
                        "UPDATE": "Actualizar un empleado de la institución",
                        "VIEW": "Consultar a un empleado de la institución",
                        "VIEW_ANY": "Consultar índice de empleados de instituciones"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "ARCHIVE": "Archivar una interacción",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con interacciones",
                        "CREATE": "Crear una interacción",
                        "DELETE": "Eliminar una interacción",
                        "DRAFT": "Redactar una interacción",
                        "FORCE_DELETE": "Forzar la eliminación de una interacción",
                        "PUBLISH": "Publicar una interacción",
                        "RESTORE": "Restaurar una interacción",
                        "UPDATE": "Actualizar una interacción",
                        "VIEW": "Consultar una interacción",
                        "VIEW_ANY": "Consultar índice de interacciones"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con idiomas",
                        "CREATE": "crear un idioma",
                        "DELETE": "Eliminar un idioma",
                        "FORCE_DELETE": "Forzar la eliminación de un idioma",
                        "RESTORE": "Restaurar un idioma",
                        "UPDATE": "Actualizar un idioma",
                        "VIEW": "Consultar un idioma",
                        "VIEW_ANY": "Consultar índice de idiomas"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con los medios",
                        "CREATE": "Crear un medio",
                        "DELETE": "Eliminar un medio",
                        "FORCE_DELETE": "Forzar la eliminación de un medio",
                        "RESTORE": "Restaurar un medio",
                        "UPDATE": "Actualizar un medio",
                        "VIEW": "Consultar un medio",
                        "VIEW_ANY": "Consultar índice de medios"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "Recibir una notificación cuando se acepte una invitación",
                        "PAYMENT_RECEIVED": "Recibir una notificación cuando se reciba un pago",
                        "PAYMENT_THANKS": "Reciba una notificación cuando se confirme un pago",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "Reciba una notificación cuando expire un evento de punto de interés",
                        "RESERVATION_CREATED": "Recibir una notificación cuando se cree una reserva",
                        "SHARE_CREATED": "Reciba una notificación cuando se cree un recurso compartido",
                        "SUPPORT_REQUEST_ARCHIVED": "Reciba una notificación cuando se archive una solicitud de soporte",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "Recibir una notificación cuando se reciba un mensaje de un soporte\n                pedido",
                        "TRAVELLER_FOLDER_APPROVED": "Reciba una notificación cuando se apruebe un archivo de viajero",
                        "TRAVELLER_FOLDER_ARCHIVED": "Reciba una notificación cuando se archive un archivo de viajero",
                        "TRAVELLER_FOLDER_PENDING": "Reciba una notificación mientras espera que se valide un archivo viajero",
                        "TRAVELLER_FOLDER_THANKS": "Reciba una notificación para agradecer al conserje a cargo de un archivo viajero"
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con socios",
                        "CREATE": "crear un socio",
                        "DELETE": "Eliminar un socio",
                        "FORCE_DELETE": "Forzar la eliminación de un socio",
                        "RESTORE": "Restaurar un socio",
                        "UPDATE": "Actualizar un socio",
                        "VIEW": "Consultar a un socio",
                        "VIEW_ANY": "Consultar índice de socios"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con pagos",
                        "CREATE": "Crear un pago",
                        "DELETE": "Eliminar un pago",
                        "FORCE_DELETE": "Forzar la eliminación de un pago",
                        "GET_RECEIPT": "Recibir un recibo por un pago",
                        "RECEIVE": "Recibir pagos",
                        "RESTORE": "Restaurar un pago",
                        "THANK": "Reciba un agradecimiento por un pago",
                        "UPDATE": "Actualizar un pago",
                        "VIEW": "Consultar un pago",
                        "VIEW_ANY": "Consultar índice de pagos"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con permisos",
                        "CREATE": "Crear un permiso",
                        "DELETE": "Eliminar un permiso",
                        "FORCE_DELETE": "Forzar la eliminación de un permiso",
                        "RESTORE": "Restaurar un permiso",
                        "UPDATE": "Actualizar un permiso",
                        "VIEW": "Consultar un permiso",
                        "VIEW_ANY": "Consultar índice de permisos"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "ARCHIVE": "Archivar un plan de suscripción",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con planes de suscripción",
                        "CREATE": "Crear un plan de suscripción",
                        "DELETE": "Eliminar un plan de suscripción",
                        "DRAFT": "Redactar un plan de suscripción",
                        "FORCE_DELETE": "Forzar la eliminación de un plan de suscripción",
                        "PUBLISH": "Publicar un plan de suscripción",
                        "RESTORE": "Restaurar un plan de suscripción",
                        "SUBSCRIBE": "Suscríbete a un plan",
                        "UPDATE": "Actualizar un plan de suscripción",
                        "VIEW": "Consultar un plan de suscripción",
                        "VIEW_ANY": "Consultar índice de planes de suscripción."
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con categorías de puntos de interés",
                        "CREATE": "Crear una categoría de punto de interés",
                        "DELETE": "Eliminar una categoría de punto de interés",
                        "FORCE_DELETE": "Forzar la eliminación de una categoría de punto de interés",
                        "RESTORE": "Restaurar una categoría de punto de interés",
                        "UPDATE": "Actualizar una categoría de punto de interés",
                        "VIEW": "Consultar una categoría de punto de interés",
                        "VIEW_ANY": "Consultar índice de categorías de puntos de interés."
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "ADMIN_ANY": "Administrar cualquier punto de interés",
                        "APPLY": "Aplicar para ser empleado",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con puntos de interés",
                        "CLAIM": "Reclamar un punto de interés",
                        "CONTEST": "Concurso de un punto de interés",
                        "CONTRIBUTE": "Contribuir a un punto de interés.",
                        "CREATE": "Crear un punto de interés",
                        "DELETE": "Eliminar un punto de interés",
                        "DENY": "Denegar un punto de interés",
                        "DRAFT": "Redactar un punto de interés",
                        "FORCE_DELETE": "Forzar eliminación de un punto de interés",
                        "IMPORT": "Importar puntos de interés",
                        "MODERATE": "Moderar un punto de interés",
                        "PUBLISH": "Publicar un punto de interés",
                        "REPORT": "Reportar un punto de interés",
                        "RESTORE": "Restaurar un punto de interés",
                        "SHARE": "Comparte un punto de interés",
                        "SUBMIT": "Enviar un punto de interés",
                        "UPDATE": "Actualizar un punto de interés",
                        "UPDATE_CERTIFIED": "Actualizar el atributo certificado de un punto de interés",
                        "VIEW": "Consultar un punto de interés",
                        "VIEW_ANY": "Consultar índice de puntos de interés."
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con categorías de publicaciones",
                        "CREATE": "Crear una categoría de publicaciones",
                        "DELETE": "Eliminar una categoría de publicaciones",
                        "FORCE_DELETE": "Forzar la eliminación de una categoría de publicaciones",
                        "RESTORE": "Restaurar una categoría de publicaciones",
                        "UPDATE": "Actualizar una categoría de publicaciones",
                        "VIEW": "Consultar una categoría de publicaciones",
                        "VIEW_ANY": "Consultar índice de categorías de publicaciones"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "ARCHIVE": "Archivar una publicación",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con publicaciones",
                        "CREATE": "crear una publicación",
                        "DELETE": "eliminar una publicación",
                        "DRAFT": "Redactar una publicación",
                        "FORCE_DELETE": "Forzar la eliminación de una publicación",
                        "PUBLISH": "publicar una publicación",
                        "RESTORE": "Restaurar una publicación",
                        "UPDATE": "Actualizar una publicación",
                        "VIEW": "Consultar una publicación",
                        "VIEW_ANY": "Consultar índice de publicaciones"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "ARCHIVE": "Archivar una política de privacidad",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con políticas de privacidad",
                        "CREATE": "Crear una política de privacidad",
                        "DELETE": "Eliminar una política de privacidad",
                        "DRAFT": "Redactar una política de privacidad",
                        "FORCE_DELETE": "Forzar la eliminación de una política de privacidad",
                        "PUBLISH": "Publicar una política de privacidad",
                        "RESTORE": "Restaurar una política de privacidad",
                        "UPDATE": "Actualizar una política de privacidad",
                        "VIEW": "Consultar una política de privacidad",
                        "VIEW_ANY": "Consultar políticas de privacidad"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "Regístrate en la sección profesional con cualquier rol que tenga este permiso"
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "ARCHIVE": "Archivar un informe",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con informes",
                        "CREATE": "crear un informe",
                        "DELETE": "Eliminar un informe",
                        "DRAFT": "Redactar un informe",
                        "FORCE_DELETE": "Forzar la eliminación de un informe",
                        "PROCESS_ANY": "Procesar cualquier informe",
                        "PUBLISH": "Publicar un informe",
                        "REJECT_ANY": "Rechazar cualquier informe",
                        "RESTORE": "Restaurar un informe",
                        "UPDATE": "Actualizar un informe",
                        "VIEW": "Consultar un informe",
                        "VIEW_ANY": "Consultar índice de informes"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "ARCHIVE": "Archivar una reserva",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con reservas",
                        "CANCEL": "Cancelar una reserva",
                        "CONFIRM": "Confirmar una reserva",
                        "CREATE": "Crear una reserva",
                        "DELETE": "Eliminar una reserva",
                        "FORCE_DELETE": "Forzar la eliminación de una reserva",
                        "RESTORE": "Restaurar una reserva",
                        "UPDATE": "Actualizar una reserva",
                        "VIEW": "Consultar una reserva",
                        "VIEW_ANY": "Consultar índice de reservas"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con roles",
                        "CREATE": "crear un rol",
                        "DELETE": "Eliminar un rol",
                        "FORCE_DELETE": "Forzar la eliminación de un rol",
                        "RESTORE": "Restaurar un rol",
                        "UPDATE": "Actualizar un rol",
                        "VIEW": "Consultar un rol",
                        "VIEW_ANY": "Consultar índice de roles"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con recursos compartidos",
                        "CREATE": "Crear una acción",
                        "DELETE": "Eliminar un recurso compartido",
                        "FORCE_DELETE": "Forzar la eliminación de un recurso compartido",
                        "RESTORE": "Restaurar un recurso compartido",
                        "UPDATE": "Actualizar un recurso compartido",
                        "VIEW": "Consultar una acción",
                        "VIEW_ANY": "Consultar índice de acciones"
                    },
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con cuentas de Stripe",
                        "HAVE": "Tener una cuenta de Stripe"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con suscripciones",
                        "CANCEL": "Cancelar una suscripción",
                        "CREATE": "Crear una suscripción",
                        "DELETE": "Eliminar una suscripción",
                        "FORCE_DELETE": "Forzar la eliminación de una suscripción",
                        "PAY": "Pagar una suscripción",
                        "RESTORE": "Restaurar una suscripción",
                        "UPDATE": "Actualizar una suscripción",
                        "VIEW": "Consultar una suscripción",
                        "VIEW_ANY": "Consultar índice de suscripciones"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con la solicitud de categorías de soporte",
                        "CREATE": "Crear una categoría de soporte",
                        "DELETE": "Eliminar una categoría de soporte",
                        "FORCE_DELETE": "Forzar la eliminación de una categoría de soporte",
                        "RESTORE": "Restaurar una categoría de soporte",
                        "UPDATE": "Actualizar una categoría de soporte",
                        "VIEW": "Consultar una categoría de soporte",
                        "VIEW_ANY": "Consultar índice de categorías de apoyo"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con mensajes",
                        "CREATE": "crear un mensaje",
                        "DELETE": "eliminar un mensaje",
                        "FORCE_DELETE": "Forzar la eliminación de un mensaje",
                        "RESTORE": "Restaurar un mensaje",
                        "UPDATE": "Actualizar un mensaje",
                        "VIEW": "Consultar un mensaje",
                        "VIEW_ANY": "Consultar índice de mensajes"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "ADD_MESSAGE": "Añade un mensaje",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con solicitudes de soporte",
                        "CANCEL": "Cancelar una solicitud de soporte",
                        "CREATE": "Crear una solicitud de soporte",
                        "DELETE": "Eliminar una solicitud de soporte",
                        "FORCE_DELETE": "Forzar la eliminación de una solicitud de soporte",
                        "RESTORE": "Restaurar una solicitud de soporte",
                        "UPDATE": "Actualizar una solicitud de soporte",
                        "VIEW": "Consultar una solicitud de soporte",
                        "VIEW_ANY": "Consultar índice de solicitudes de soporte"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con etiquetas",
                        "CREATE": "crear una etiqueta",
                        "DELETE": "Eliminar una etiqueta",
                        "FORCE_DELETE": "Forzar la eliminación de una etiqueta",
                        "RESTORE": "Restaurar una etiqueta",
                        "UPDATE": "Actualizar una etiqueta",
                        "VIEW": "Consultar una etiqueta",
                        "VIEW_ANY": "Consultar índice de etiquetas"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "ARCHIVE": "Archivar términos y condiciones",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con términos y condiciones",
                        "CREATE": "Crear términos y condiciones",
                        "DELETE": "Eliminar términos y condiciones",
                        "DRAFT": "Redactar términos y condiciones",
                        "FORCE_DELETE": "Forzar la eliminación de términos y condiciones",
                        "PUBLISH": "Publicar términos y condiciones.",
                        "RESTORE": "Restaurar un términos y condiciones",
                        "UPDATE": "Actualizar términos y condiciones",
                        "VIEW": "Consulta términos y condiciones",
                        "VIEW_ANY": "Consulta términos y condiciones"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con registros traducibles",
                        "REQUIRE_ANY": "Permitir traducir un campo obligatorio en un idioma que no sea necesariamente el inglés."
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar los permisos relacionados con los correos electrónicos de los viajeros",
                        "CREATE": "Crear un correo electrónico de viajero",
                        "DELETE": "Eliminar un correo electrónico de viajero",
                        "FORCE_DELETE": "Forzar la eliminación de un correo electrónico de viajero",
                        "RESTORE": "Restaurar un correo electrónico de viajero",
                        "UPDATE": "Actualizar un correo electrónico de viajero",
                        "VIEW": "Consultar el correo electrónico de un viajero",
                        "VIEW_ANY": "Consulta índice de emails de viajeros"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "APPROVE": "Aprobar una carpeta de viajero",
                        "ARCHIVE": "Archivar una carpeta de viajero",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con carpetas de viajeros",
                        "CREATE": "Crear una carpeta de viajero",
                        "DECLINE": "Rechazar una carpeta de viajero",
                        "DELETE": "Eliminar una carpeta de viajero",
                        "FORCE_DELETE": "Forzar la eliminación de una carpeta de viajero",
                        "RESTORE": "Restaurar una carpeta de viajero",
                        "THANK": "Agradecer a un viajero por la estadía",
                        "UPDATE": "Actualizar una carpeta de viajero",
                        "VIEW": "Consultar una carpeta de viajero",
                        "VIEW_ANY": "Consultar índice de carpetas de viajeros"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con verificaciones de credenciales de usuario",
                        "CREATE": "Crear una verificación de credenciales de usuario",
                        "DELETE": "Eliminar una verificación de credenciales de usuario",
                        "FORCE_DELETE": "Forzar la eliminación de una verificación de credenciales de usuario",
                        "RESTORE": "Restaurar una verificación de credenciales de usuario",
                        "UPDATE": "Actualizar una verificación de credenciales de usuario",
                        "VIEW": "Consultar una verificación de credenciales de usuario",
                        "VIEW_ANY": "Consultar índice de verificaciones de credenciales de usuarios"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "ADD_CONTACT": "Agregar un usuario como contacto",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con usuarios",
                        "CREATE": "crear un usuario",
                        "DELETE": "Eliminar un usuario",
                        "FORCE_DELETE": "Forzar la eliminación de un usuario",
                        "INVITE": "Invitar a un usuario",
                        "REPORT": "Reportar a un usuario",
                        "RESTORE": "Restaurar un usuario",
                        "TIP": "Dar propina a un usuario",
                        "UPDATE": "Actualizar un usuario",
                        "UPDATE_BANK_ACCOUNT": "Actualizar cuenta bancaria",
                        "VIEW": "Consultar a un usuario",
                        "VIEW_ANY": "Consultar índice de usuarios",
                        "VIEW_AVATAR": "Ver el avatar de un usuario",
                        "VIEW_CONTACT_EMAIL": "Ver el correo electrónico de contacto de un usuario",
                        "VIEW_CONTACT_PHONE": "Ver el teléfono de contacto de un usuario",
                        "VIEW_REAL_NAME": "Ver el nombre real de un usuario"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con la configuración de ubicación geográfica del usuario",
                        "CREATE": "Crear una configuración de geolocalización de usuario",
                        "DELETE": "Eliminar una configuración de geolocalización de usuario",
                        "FORCE_DELETE": "Forzar la eliminación de una configuración de geolocalización de usuario",
                        "RESTORE": "Restaurar la configuración de geolocalización de un usuario",
                        "UPDATE": "Actualizar la configuración de geolocalización de un usuario",
                        "VIEW": "Consultar la configuración de geolocalización de un usuario",
                        "VIEW_ANY": "Consultar índice de configuración de geolocalización del usuario"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con la configuración de notificaciones de usuario",
                        "CREATE": "Crear una configuración de notificación de usuario",
                        "DELETE": "Eliminar una configuración de notificación de usuario",
                        "FORCE_DELETE": "Forzar la eliminación de una configuración de notificación de usuario",
                        "RESTORE": "Restaurar una configuración de notificación de usuario",
                        "UPDATE": "Actualizar una configuración de notificación de usuario",
                        "VIEW": "Consultar una configuración de notificación de usuario",
                        "VIEW_ANY": "Consultar índice de configuración de notificaciones de usuario."
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con la configuración de privacidad del usuario",
                        "CREATE": "Crear una configuración de privacidad de usuario",
                        "DELETE": "Eliminar una configuración de privacidad de usuario",
                        "FORCE_DELETE": "Forzar la eliminación de una configuración de privacidad de usuario",
                        "RESTORE": "Restaurar la configuración de privacidad de un usuario",
                        "UPDATE": "Actualizar la configuración de privacidad de un usuario",
                        "VIEW": "Consultar la configuración de privacidad de un usuario",
                        "VIEW_ANY": "Consultar índice de configuración de privacidad del usuario"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "APPROVE_CONTACT": "Aprobar una relación de usuario",
                        "BLOCK_CONTACT": "Bloquear una relación de usuario",
                        "BYPASS_PERMISSIONS": "Ignorar permisos relacionados con las relaciones de los usuarios",
                        "CREATE": "Crear una relación de usuario",
                        "DECLINE_CONTACT": "Rechazar una relación de usuario",
                        "DELETE": "Eliminar una relación de usuario",
                        "FORCE_DELETE": "Forzar la eliminación de una relación de usuario",
                        "RESTORE": "Restaurar una relación de usuario",
                        "UNBLOCK_CONTACT": "Desbloquear una relación de usuario",
                        "UPDATE": "Actualizar una relación de usuario",
                        "VIEW": "Consultar una relación de usuario",
                        "VIEW_ANY": "Consultar índice de relaciones de usuarios"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\AccessEnum": "Acceso",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "Registros de actividad",
                    "App\\Enums\\Permissions\\AddressEnum": "direcciones",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "Transferencias bancarias instituciones",
                    "App\\Enums\\Permissions\\ChangelogEnum": "Registros de cambios",
                    "App\\Enums\\Permissions\\ClaimEnum": "Reclamos",
                    "App\\Enums\\Permissions\\CountryEnum": "Países",
                    "App\\Enums\\Permissions\\CurrencyEnum": "Monedas",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "Cuenta EasyTransac",
                    "App\\Enums\\Permissions\\ExcursionEnum": "Itinerarios",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "Enlace entre itinerarios y puntos de interés",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "Empleados de instituciones",
                    "App\\Enums\\Permissions\\InteractionEnum": "Interacciones",
                    "App\\Enums\\Permissions\\LocaleEnum": "Idiomas",
                    "App\\Enums\\Permissions\\MediumEnum": "Medios de comunicación",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "Recibir notificaciones",
                    "App\\Enums\\Permissions\\PartnerEnum": "Socios",
                    "App\\Enums\\Permissions\\PaymentEnum": "Pagos",
                    "App\\Enums\\Permissions\\PermissionEnum": "Permisos",
                    "App\\Enums\\Permissions\\PlanEnum": "Planes de suscripción",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "Categorías de puntos de interés",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "Puntos de interés",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "Categorías de publicaciones",
                    "App\\Enums\\Permissions\\PostEnum": "Publicaciones",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "Políticas de privacidad",
                    "App\\Enums\\Permissions\\RegistrationEnum": "Registro",
                    "App\\Enums\\Permissions\\ReportEnum": "Informes",
                    "App\\Enums\\Permissions\\ReservationEnum": "Reservas",
                    "App\\Enums\\Permissions\\RoleEnum": "Roles",
                    "App\\Enums\\Permissions\\ShareEnum": "Comparte",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "Cuenta de raya",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "Suscripciones",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "Categorías de solicitud de soporte",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "Mensajes de soporte",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "Solicitudes de soporte",
                    "App\\Enums\\Permissions\\TagEnum": "Etiquetas",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "Términos y condiciones",
                    "App\\Enums\\Permissions\\TranslatableEnum": "Registros traducibles",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "Correos electrónicos de viajeros",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "Carpetas de viajero",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "Verificaciones de credenciales de usuario",
                    "App\\Enums\\Permissions\\UserEnum": "Usuarios",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "Configuración de geolocalización del usuario",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "Configuración de notificaciones de usuario",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "Configuración de privacidad del usuario",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "Relaciones de usuarios"
                }
            },
            "plans": {
                "archive": {
                    "success": "Los planes de suscripción se han archivado (si son elegibles)"
                },
                "confirmation": {
                    "benefit_current_subscription": "Continuarás beneficiándote de tu suscripción actual\n            Hasta fin de mes ya pagado.",
                    "engagement_period": "Esta oferta incluye un período de contratación de\n            {engagement_period} después del 1er pago.",
                    "fee": "Esta oferta está sujeta a una tarifa del {fee_percentage}% y le da derecho a\n            Recibe {tip_percentage}% de tus propinas.",
                    "free": "Esta suscripción es gratuita, sujeta a la creación de una cuenta de recaudación de propinas.",
                    "free_period": "Te beneficias de un período gratuito {free_period}.",
                    "free_period_used": "Ya has agotado tu período gratuito y no podrás\n            poder beneficiarse del {free_period} que propone esta oferta.",
                    "name": "Estás a punto de suscribirte a la oferta \"{name}\".",
                    "name_launch": "Estás a punto de suscribirte a la oferta de lanzamiento \"{name}\".",
                    "no_fee": "Esta oferta está sujeta a una tarifa del 0% y le permitirá recibir sus propinas en su totalidad.",
                    "please_confirm": "Confirme su suscripción haciendo clic en el botón a continuación.",
                    "price_summary": "Esta suscripción te costará\n            {price} sin incluir impuestos / {price_incl_taxes} incl. impuestos por mes.",
                    "replace_current_subscription": "Esta suscripción reemplazará automáticamente su suscripción actual."
                },
                "draft": {
                    "success": "Se han redactado planes de suscripción (si son elegibles)"
                },
                "free_period_ended_in": "Los periodos libres terminan {in}",
                "publish": {
                    "success": "Se han publicado planes de suscripción (si son elegibles)"
                },
                "set_coming_soon": {
                    "success": "Los planes de suscripción se han configurado como \"próximamente\" (si son elegibles)"
                },
                "your_current_plan": "Tu plan actual"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "Al marcar esta casilla, certifico que soy un representante autorizado de la\n            establecimiento reclamado. Cualquier declaración falsa o suplantación me someterá a sanciones civiles y penales.\n            sanciones de conformidad con las leyes aplicables.",
                    "success": "Su reclamo ha sido enviado para verificación."
                },
                "denied": {
                    "for_the_reason": "Rechazado por el siguiente motivo",
                    "moderation_again": "Puedes editar el punto de interés, lo que lo enviará nuevamente a moderación, para que pueda publicarse."
                },
                "deny": {
                    "success": "Se han negado puntos de interés (si son elegibles)"
                },
                "destroy": {
                    "confirm": "¿Realmente quieres eliminar este punto de interés: {point_of_interest_name}?",
                    "success": "El punto de interés ha sido eliminado."
                },
                "draft": {
                    "success": "Se han redactado puntos de interés (si son elegibles)"
                },
                "duplicates": {
                    "check_explanation1": "Para crear su punto de interés,\n            Por favor complete los campos principales a continuación.",
                    "check_explanation2": "Se realizará un control para evitar\n            entrando en un punto ya existente.",
                    "check_explanation3": "Después de la verificación, podrá completar\n            la información de tu punto y guárdala.",
                    "check_explanation_title": "Verificación",
                    "continue_creation": "Continuar creación",
                    "found_explanation1": "Uno o más puntos de interés correspondientes a\n            sus criterios de entrada ya existen.",
                    "found_explanation2": "Si crees que esto no es un duplicado,\n            luego podrá proceder a ingresar su nuevo punto de interés.",
                    "match_percentage": "Coincidir con {percentage}%",
                    "select_duplicate": "Haga clic en uno de los puntos de interés propuestos para ver los detalles.",
                    "use_poi": "Utilice este punto de interés",
                    "view_poi": "Ver este punto de interés"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "Añadir este punto de interés a un itinerario",
                    "already_in_excursions": "Este punto de interés ya forma parte de esos itinerarios.",
                    "create_excursion": "Crear un itinerario",
                    "or_create_from": "O crear un nuevo itinerario desde este punto de interés.",
                    "select_which": "Seleccione a qué itinerario existente agregar el punto de interés \"{point_of_interest_name}\"",
                    "success": "El punto de interés ha sido añadido al itinerario."
                },
                "form": {
                    "help": {
                        "stars": "Sólo si la categoría se puede anotar con estrellas (como Hotel, Restaurante...)"
                    }
                },
                "import_source": {
                    "DataTourisme": "DatosTurismo"
                },
                "index": {
                    "no_results": "No se encontraron puntos de interés",
                    "no_results_here": "No hay puntos de interés disponibles en su área de búsqueda.",
                    "no_tags": "Ninguna etiqueta definida",
                    "refresh_delayed": "Espere {time_remaining} antes de actualizar",
                    "search": "Buscar un punto de interés",
                    "try_more_filters": "Puedes cambiar tus filtros activos y ampliar tu búsqueda."
                },
                "pages": {
                    "show": {
                        "automatic_update": "Actualización automática",
                        "belongs_to_you": "Te pertenece",
                        "claim_dispute_pending": {
                            "details": "Detalles de la solicitud: {message}",
                            "notice": "Disputa de reclamación en curso",
                            "title": "Tiene una disputa de reclamo en curso sobre este punto de interés."
                        },
                        "deletion": {
                            "causes": {
                                "event": "Causa: evento caducado"
                            },
                            "title": "Borrado pendiente"
                        },
                        "dt_required_datatourisme_fields": "Actualizado por {dt_created_by} de {import_source} a {dt_last_update}",
                        "excursions_counter": "Se ha agregado {counter} veces a los itinerarios.",
                        "is_geocoding_1": "El punto de interés está siendo geolocalizado.",
                        "is_geocoding_2": "Consulte esta página más tarde para ver el punto de interés.\n                en el mapa.",
                        "is_refreshing_audio_1": "Se está generando un archivo de audio.",
                        "is_refreshing_audio_2": "Consulte esta página más tarde para escuchar este audio.",
                        "not_current_locale": {
                            "change_locale": "Puede cambiar el idioma mostrado usando el selector de arriba",
                            "incomplete_translation": "Parte de la información de este punto de interés (título, descripción y/o dirección) no está disponible en el idioma seleccionado.",
                            "missing_translations": "Traducciones faltantes"
                        },
                        "opening_hours": {
                            "expired": "Horario de apertura vencido",
                            "is_deleting": "El punto de interés se eliminará en {days} días.",
                            "show": "Ver horarios de apertura"
                        },
                        "sections": {
                            "audios": "Audios",
                            "gallery": "Galería",
                            "logo": "Logo",
                            "main_image": "Imagen principal",
                            "map": "Mapa",
                            "media": "Medios de comunicación",
                            "poi": "Punto de interés",
                            "videos": "Vídeos"
                        },
                        "see_full_page": "Ver página completa"
                    }
                },
                "print": {
                    "element_not_found": "¡Elemento para imprimir \"{id}\" no encontrado!"
                },
                "publish": {
                    "success": "Los puntos de interés han sido validados y publicados (si son elegibles)"
                },
                "report": {
                    "choose_reason": "Elija un motivo para informar este punto de interés \"{point_of_interest_title}\"",
                    "heading": "Reportar un punto de interés",
                    "success": "¡El punto de interés ha sido informado!"
                },
                "search": {
                    "creator_id": "¿Mostrar solo mis puntos?",
                    "no_results": "No se encontraron puntos de interés...",
                    "tags_mode": {
                        "all": "Todo",
                        "all_help": "Seleccione \"todos\" para encontrar puntos de interés\n                con todas las etiquetas seleccionadas.",
                        "one": "Al menos uno",
                        "one_help": "Seleccione \"al menos uno\" para encontrar puntos de interés\n                con al menos una de las etiquetas seleccionadas."
                    }
                },
                "share": {
                    "heading": "Comparte este punto de interés",
                    "select_which_contacts": "Seleccione los contactos con los que desea compartir el punto de interés \"{point_of_interest_title}\"",
                    "select_which_users": "Seleccione los usuarios con los que desea compartir el punto de interés \"{point_of_interest_title}\" o ingrese una dirección de correo electrónico para invitar a un nuevo viajero",
                    "select_which_users_alt": "Introduzca la dirección de correo electrónico de la persona con la que desea compartir el\n            \"{point_of_interest_title}\" punto de interés.",
                    "success": "¡El punto de interés ha sido compartido!"
                },
                "states": {
                    "draft": "Borrador",
                    "pending": "Pendiente",
                    "published": "Publicado"
                },
                "store": {
                    "heading": "Crear un punto de interés",
                    "success": "El punto de interés ha sido creado."
                },
                "submit": {
                    "success": "Los puntos de interés están pendientes de moderación (si son elegibles)"
                },
                "update": {
                    "alert": "Actualizar este punto deshabilitará la actualización automática desde la importación {import_source}",
                    "confirm": "¿De verdad quieres actualizar este punto de interés: {point_of_interest_name}?",
                    "heading": "Editar este punto de interés",
                    "success": "El punto de interés ha sido actualizado."
                }
            },
            "posts": {
                "archive": {
                    "success": "Las publicaciones se han archivado (si son elegibles)"
                },
                "draft": {
                    "success": "Se han redactado puestos (si son elegibles)"
                },
                "publish": {
                    "success": "Se han publicado publicaciones (si son elegibles)"
                },
                "search": {
                    "type_to_search": "Buscar en la categoría seleccionada..."
                }
            },
            "prices": {
                "excl_taxes": "excluido impuestos",
                "free": "Gratis",
                "from": "De",
                "incl_taxes": "incluido impuestos",
                "per_month": "Por mes",
                "price_excl_taxes": "Precio sin IVA impuestos",
                "price_incl_taxes": "Precio incluido. impuestos",
                "vat_amount": "importe del IVA",
                "vat_percentage": "porcentaje de IVA"
            },
            "privacy_policies": {
                "archive": {
                    "success": "Las políticas de privacidad se han archivado (si son elegibles)"
                },
                "errors": {
                    "not_found": "¡No se encontró ninguna política de privacidad!"
                },
                "publish": {
                    "success": "La política de privacidad ha sido publicada."
                },
                "update": {
                    "acceptation": "Debes aceptar la política de privacidad para continuar",
                    "new_version": "Se ha publicado una nueva versión de la política de privacidad.",
                    "success": "¡La política de privacidad ha sido aceptada!"
                }
            },
            "reports": {
                "process": {
                    "success": "Los informes han sido procesados ​​(si son elegibles)"
                },
                "reject": {
                    "success": "Los informes han sido rechazados (si son elegibles)"
                }
            },
            "reservations": {
                "archive": {
                    "confirm": "¿Realmente quieres archivar esta reserva?",
                    "success": "La reserva ha sido archivada."
                },
                "cancel": {
                    "confirm": "¿Realmente quieres cancelar esta reserva?",
                    "success": "La reserva ha sido cancelada."
                },
                "confirm": {
                    "confirm": "¿De verdad quieres confirmar esta reserva?",
                    "success": "La reserva ha sido confirmada."
                },
                "destroy": {
                    "confirm": "¿Realmente quieres eliminar esta reserva?",
                    "success": "La reserva ha sido eliminada."
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "butler": "viajero",
                                "poi": "viajero",
                                "traveller": "institución"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "butler": "Conserje",
                            "poi": "Punto de interés",
                            "reservation": "Reserva",
                            "traveller": "Viajero"
                        }
                    }
                },
                "states": {
                    "archived": "Archivado",
                    "canceled": "Cancelado",
                    "confirmed": "Confirmado",
                    "pending": "Pendiente"
                },
                "store": {
                    "success": "La reserva ha sido creada."
                },
                "update": {
                    "success": "La reserva ha sido actualizada."
                }
            },
            "settings": {
                "items": {
                    "about": "Acerca de",
                    "geoloc_settings": "Configuración de geolocalización",
                    "notification_settings": "Configuracion de notificaciones",
                    "privacy_setting": "La configuración de privacidad",
                    "profile": "Perfil",
                    "signOut": "desconectar"
                }
            },
            "shares": {
                "emails": {
                    "select_hint": "Puede seleccionar un correo electrónico utilizado anteriormente haciendo clic a continuación:",
                    "space_hint": "Separar por un espacio para compartir varios correos electrónicos"
                },
                "index": {
                    "no_results": "¡No se encontraron acciones!"
                },
                "link": {
                    "instructions": "Copie este enlace para invitar a un viajero y compartir este itinerario con él."
                },
                "show": {
                    "shared_by": "Compartido por"
                },
                "type": {
                    "excursions": "Itinerario",
                    "pointsofinterest": "Punto de interés"
                }
            },
            "states": {
                "changelog_state": {
                    "archived": "Archivado",
                    "draft": "Borrador",
                    "published": "Publicado"
                },
                "claim_dispute_state": {
                    "pending": "Pendiente",
                    "rejected": "Rechazado",
                    "validated": "Validado"
                },
                "claim_state": {
                    "missing-info": "Información faltante",
                    "pending": "Pendiente",
                    "rejected": "Rechazado",
                    "revoked": "Revocado",
                    "validated": "Validado"
                },
                "payment_state": {
                    "canceled": "Cancelado",
                    "completed": "Terminado",
                    "failed": "Fallido",
                    "pending": "Pendiente",
                    "transferred": "Transferido"
                },
                "plan_state": {
                    "archived": "Archivado",
                    "coming_soon": "Muy pronto",
                    "draft": "Borrador",
                    "published": "Publicado"
                },
                "point_of_interest_state": {
                    "deleted": "Eliminado",
                    "denied": "Denegado",
                    "draft": "Borrador",
                    "pending": "Pendiente de moderación",
                    "published": "Publicado"
                },
                "post_state": {
                    "archived": "Archivado",
                    "draft": "Borrador",
                    "published": "Publicado"
                },
                "privacy_policy_state": {
                    "archived": "Archivado",
                    "draft": "Borrador",
                    "published": "Publicado"
                },
                "report_state": {
                    "pending": "Pendiente",
                    "processed": "Procesada",
                    "rejected": "Rechazado"
                },
                "reservation_state": {
                    "archived": "Archivado",
                    "canceled": "Cancelado",
                    "confirmed": "Confirmado",
                    "pending": "Pendiente"
                },
                "subscription_state": {
                    "active": "Activo",
                    "canceled": "Cancelado",
                    "pending": "Pago pendiente",
                    "suspended": "Suspendido"
                },
                "support_request_state": {
                    "archived": "Archivado",
                    "canceled": "Cancelado",
                    "pending-support": "Soporte pendiente",
                    "pending-user": "Usuario pendiente",
                    "resolved": "Resuelto"
                },
                "terms_condition_state": {
                    "archived": "Archivado",
                    "draft": "Borrador",
                    "published": "Publicado"
                },
                "traveller_folder_state": {
                    "approved": "Aprobado",
                    "archived": "Archivado",
                    "declined": "Rechazado",
                    "pending": "Pendiente"
                },
                "user_credential_verification_state": {
                    "canceled": "Cancelado",
                    "completed": "Terminado",
                    "pending": "Pendiente"
                },
                "user_tutorial_state": {
                    "claimed": "Reclamado",
                    "done": "Hecho",
                    "employee-added": "Empleado agregado",
                    "pending": "Pendiente"
                },
                "user_utm_campaign_state": {
                    "always_on": "Siempre encendido"
                },
                "user_utm_medium_state": {
                    "owned_social": "Red social"
                },
                "user_utm_source_state": {
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "linkedin": "LinkedIn"
                },
                "users_relationship_state": {
                    "approved": "Aprobado",
                    "blocked": "Obstruido",
                    "declined": "Rechazado",
                    "pending": "Pendiente"
                }
            },
            "stripe": {
                "connect": {
                    "account": {
                        "create": {
                            "completed": "Configuración de la cuenta de Stripe completada",
                            "link_invalid": "La conexión a Stripe falló, inténtelo de nuevo"
                        },
                        "must_subscribe": "Debes estar suscrito para crear tu cuenta Stripe.",
                        "show": {
                            "account_usage": "Su cuenta Stripe permitirá a su institución recibir pagos en UgoZER.",
                            "can_receive_payments": "Puedes recibir pagos.",
                            "cannot_receive_payments": "No puedes recibir pagos",
                            "fee_percentage": "Se aplicará una tarifa del {percentage}% a cada pago que reciba.",
                            "go_to_stripe": "Ir al panel de Stripe",
                            "has_stripe_account_being_reviewed": "Stripe está revisando tu cuenta.",
                            "no_account_yet": "Aún no tienes una cuenta de Stripe.",
                            "should_complete_stripe_onboarding": "Complete su registro en Stripe y proporcióneles la información solicitada."
                        }
                    }
                },
                "stripe": "Raya"
            },
            "subscriptions": {
                "activated_at": "Activación",
                "cancel": "Cancelar",
                "cancelation_success": "Cancelación tomada en cuenta.",
                "canceled_at": "Cancelación",
                "confirm_cancel": "¿Realmente quieres cancelar tu suscripción?",
                "confirm_cancel_date": "Te beneficiarás de sus ventajas hasta su fecha de cancelación efectiva el {date}.",
                "confirm_cancel_now": "La cancelación surtirá efecto inmediatamente.",
                "expiry": "Expiración",
                "invoices": "Facturas",
                "my_current_subscription": "Mi suscripción actual",
                "price_excl_taxes": "Precio sin IVA impuestos",
                "price_incl_taxes": "Precio incluido. impuestos",
                "upcoming_subscription": "Próxima suscripción"
            },
            "support_messages": {
                "show": {
                    "you": "Tú",
                    "your_question": "Tu pregunta"
                },
                "store": {
                    "heading": "Añade un mensaje",
                    "success": {
                        "title": "El mensaje ha sido creado. El soporte de contacto responderá dentro de las 48 horas."
                    }
                }
            },
            "support_requests": {
                "actions": {
                    "archived": {
                        "success": "La solicitud está archivada."
                    },
                    "canceled": {
                        "success": "La solicitud está cancelada."
                    },
                    "pending_support": {
                        "success": "Esperando soporte para responder"
                    },
                    "pending_user": {
                        "success": "Esperando usuario para responder"
                    },
                    "resolved": {
                        "success": "La solicitud está resuelta."
                    }
                },
                "cancel": {
                    "confirm": "¿Realmente desea cancelar esta solicitud de soporte: {support_request_title}?",
                    "error": "La solicitud de soporte no se pudo cancelar.",
                    "success": "La solicitud de soporte ha sido cancelada."
                },
                "index": {
                    "no_results": "No tienes ninguna solicitud de soporte",
                    "search": "Buscar una solicitud de soporte",
                    "your_requests": "Sus solicitudes de soporte"
                },
                "pages": {
                    "create": {
                        "notice": "Información personal"
                    },
                    "index": {
                        "search": "Buscar una solicitud de soporte"
                    },
                    "show": {
                        "sections": {
                            "messages": "Mensajes",
                            "request": "Pedido"
                        }
                    }
                },
                "show": {
                    "add_request": "Agregar una nueva solicitud de soporte"
                },
                "states": {
                    "archived": "Archivado",
                    "canceled": "Cancelado",
                    "pending-support": "Soporte pendiente",
                    "pending-user": "Usuario pendiente",
                    "resolved": "Resuelto"
                },
                "store": {
                    "heading": "Crear una nueva solicitud de soporte",
                    "success": {
                        "title": "La solicitud ha sido creada. El soporte de contacto responderá dentro de las 48 horas."
                    }
                }
            },
            "terms_conditions": {
                "archive": {
                    "success": "Los términos y condiciones han sido archivados (si son elegibles)"
                },
                "errors": {
                    "not_found": "¡No se encontraron términos y condiciones!"
                },
                "publish": {
                    "success": "Términos y condiciones han sido publicados."
                },
                "update": {
                    "acceptation": "Debes aceptar los términos y condiciones para continuar.",
                    "new_version": "Se ha publicado una nueva versión de términos y condiciones.",
                    "success": "¡Se han aceptado los términos y condiciones!"
                }
            },
            "traveller_folders": {
                "messages": {
                    "approve": {
                        "confirm": "¿De verdad quieres confirmar esta estancia?",
                        "success": "¡Mantente confirmado!"
                    },
                    "archive": {
                        "confirm": "¿Realmente quieres archivar esta estancia?",
                        "success": "¡Esta estancia ha sido archivada!"
                    },
                    "decline": {
                        "confirm": "¿Realmente desea rechazar esta estancia?",
                        "success": "¡Quédate rechazado!"
                    }
                },
                "no_results": {
                    "approved": "No hay estancias aprobadas",
                    "archived": "No hay estancias archivadas",
                    "declined": "No se han rechazado estancias",
                    "pending": "No hay solicitudes de estancia"
                },
                "pages": {
                    "form": {
                        "cannot_attach": {
                            "description": "Este usuario existe pero no puede ser admitido...",
                            "title": "Soporte no posible"
                        },
                        "poi_empty_option": "Quitar punto de interés",
                        "poi_label": "Institución de conserjería",
                        "verification": {
                            "description": "Introduzca la dirección de correo electrónico del viajero. Si ya existe una cuenta de viajero, se\n                    estar directamente vinculado; de lo contrario, tendrá que ingresar información adicional para crear un\n                    uno nuevo.",
                            "title": "Verificación"
                        }
                    },
                    "index": {
                        "pending_stays": "Tus estancias pendientes",
                        "search": "Buscar entre mis viajeros",
                        "travellers_folders": "Carpetas de viajeros",
                        "your_stays": "Tus estancias"
                    },
                    "qr_code_1": "Un viajero puede escanear este código QR para obtener automáticamente una carpeta de viajero\n            con su institución.",
                    "qr_code_2": "Si el viajero aún no se ha registrado en UgoZER, será redirigido al registro\n            página y será vinculado a su institución al final de su registro.",
                    "show": {
                        "fields": {
                            "state": "Estado:"
                        },
                        "sections": {
                            "butler": "Conserjería relacionada",
                            "folder": "Carpeta",
                            "hotel": "Hotel",
                            "institution": "Institución relacionada",
                            "interactions": "Interacciones",
                            "invitation": "invitar a un viajero",
                            "payments": "Pagos",
                            "reservations": "Reservas",
                            "stay": "Permanecer",
                            "thanks": "Gracias",
                            "traveller": "Viajero"
                        }
                    },
                    "wizard": {
                        "choose_an_institution": "Elige una institución",
                        "no_linked_institution": "Debes estar vinculado a una institución antes de poder recoger a un viajero."
                    }
                },
                "placeholders": {
                    "butler": "Equipo de recepción"
                },
                "states": {
                    "approved": "Aceptado",
                    "archived": "Archivado",
                    "declined": "Rechazado",
                    "pending": "Pendiente",
                    "undefined": "Indefinido"
                },
                "store": {
                    "success": "La carpeta ha sido creada."
                },
                "tabs": {
                    "approved": "Aprobado",
                    "archived": "Archivado",
                    "declined": "Rechazado",
                    "pending": "Pendiente"
                },
                "thank": {
                    "success": "Los agradecimientos han sido enviados."
                },
                "update": {
                    "success": "La carpeta ha sido actualizada."
                }
            },
            "tutorials": {
                "extranet": {
                    "begin": {
                        "butler": {
                            "description": "Para permitirle utilizar la aplicación UgoZER en las mejores condiciones,\n                    Le aconsejamos que siga los siguientes pasos que le guiarán en los primeros pasos del proceso.\n                    manejo y parametrización de su espacio Concierge.",
                            "steps": {
                                "institution": {
                                    "content": "Busca tu hotel en el listado de establecimientos usando la barra de búsqueda y\n                            los filtros disponibles en la sección izquierda de la página. Una vez que haya encontrado su\n                            establecimiento, cree una solicitud haciendo clic en <strong>Solicitar unirse</strong>\n                            botón.",
                                    "description": "Enlace a su institución",
                                    "step_next": "reclamado",
                                    "step_state": "pendiente",
                                    "subtitle": {
                                        "link": "mi institución",
                                        "part_1": "Enlázame a mi hotel",
                                        "part_2": "Puedes enlazar a tu hotel directamente a la página."
                                    },
                                    "title": "Institución"
                                }
                            }
                        },
                        "done": {
                            "description": "Ya ha completado los distintos pasos para configurar la aplicación UgoZER.",
                            "faq": {
                                "link": "Preguntas más frecuentes",
                                "part_1": "Si necesita más ayuda, puede encontrar artículos en el",
                                "part_2": "sección de la aplicación"
                            },
                            "help": "También puede encontrar ayuda contextual a través de la <strong>Ayuda</strong> presente en algunas páginas.",
                            "title": "¡Felicidades!"
                        },
                        "hotelier": {
                            "description": "Para permitirle utilizar la aplicación UgoZER en las mejores condiciones,\n                    Le aconsejamos que siga los siguientes pasos que le guiarán en los primeros pasos del proceso.\n                    manejo y parametrización del espacio de su hotel manager.",
                            "steps": {
                                "employees": {
                                    "description": "Gestiona tus conserjes",
                                    "step_next": "agregado por empleado",
                                    "step_state": "reclamado",
                                    "title": "Conserjes"
                                }
                            }
                        },
                        "manager": {
                            "description": "Para permitirle utilizar la aplicación UgoZER en las mejores condiciones,\n                    Le aconsejamos que siga los siguientes pasos que le guiarán en los primeros pasos del proceso.\n                    manejo y configuración de parámetros de su espacio de administrador de Otros negocios."
                        },
                        "messages": {
                            "claim": "Su reclamo ha sido validado.",
                            "contest": "Su disputa ha sido enviada a nuestro equipo.",
                            "payment": "Su información bancaria se ha registrado correctamente.",
                            "point_of_interest": "El punto de interés ha sido creado, ahora puedes reclamarlo."
                        },
                        "no_tutorial": {
                            "content": "No se ha configurado ningún tutorial para este rol o estás conectado como administrador.",
                            "title": "No hay tutoriales disponibles"
                        },
                        "notices": {
                            "add_butler": {
                                "content": "Para utilizar la aplicación, debe crear una cuenta de conserjería para su establecimiento.\n                        desde donde se enviará información a los viajeros.",
                                "content_2": "Para activar la cuenta de conserje, su conserje deberá confirmar su correo electrónico\n                        dirección y crear su contraseña.",
                                "title": "Añadir un conserje"
                            },
                            "add_employee": {
                                "content": "Para utilizar la aplicación, debes crear una cuenta de empleado para tu establecimiento.\n                        desde donde se enviará información a los viajeros.",
                                "content_2": "Para activar la cuenta de empleado, su empleado deberá confirmar su correo electrónico\n                        dirección y crear su contraseña.",
                                "title": "Agregar un empleado"
                            }
                        },
                        "shared": {
                            "actions": {
                                "skip_step": "Saltar paso",
                                "skip_tutorial": "Saltar tutorial"
                            },
                            "claims": {
                                "description": "Reclama tus instituciones",
                                "documents": {
                                    "association": {
                                        "identity_piece": "Una prueba de identidad del representante legal en el curso de\n                                    válido",
                                        "statutes": "Estatutos de asociaciones",
                                        "title": "Si eres una asociación"
                                    },
                                    "company": {
                                        "identity_piece": "Una prueba de identidad del representante legal en el curso de\n                                    válido",
                                        "kbis": "Un extracto de KBIS",
                                        "title": "Si eres una empresa"
                                    },
                                    "description": "Para reclamar su institución, deberá preparar lo siguiente\n                                documentos:",
                                    "individual": {
                                        "identity_piece": "Una identificación válida",
                                        "registration_document": "Un certificado de registro de la empresa propia.",
                                        "title": "Si eres un negocio propio"
                                    },
                                    "label": "Documentos",
                                    "title": "Documentos de reclamación"
                                },
                                "notice": {
                                    "description": "Puedes encontrar esta explicación haciendo clic en <strong>Ayuda</strong>\n                                botón en la parte superior derecha de la lista de puntos de interés.",
                                    "title": "tenga en cuenta"
                                },
                                "step_next": "reclamado",
                                "step_state": "pendiente",
                                "title": "Afirmar"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "¿Estás seguro de que quieres omitir este paso?",
                                    "success": "¡El paso fue bien ignorado!"
                                },
                                "skip_tutorial": {
                                    "confirm": "¿Estás seguro de que quieres saltarte este tutorial?",
                                    "success": "¡El tutorial fue bien ignorado!"
                                }
                            },
                            "payments": {
                                "description": "Recibe propinas",
                                "step_next": "hecho",
                                "step_state": "agregado por empleado",
                                "steps": {
                                    "bank": {
                                        "create": "Haga clic en el botón <strong>Agregar una cuenta</strong> en el\n                                    Sección <strong>Cuenta bancaria</strong>"
                                    },
                                    "easytransac": {
                                        "connection": "Clic en el enlace\n                                    <strong>Vaya al Panel de EasyTransac</strong> e inicie sesión con las credenciales que\n                                        recibido por correo electrónico",
                                        "create": "Haga clic en el botón <strong>Crear</strong> en el\n                                    Sección <strong>EasyTransac</strong>",
                                        "created": "La cuenta se crea automáticamente en base a los datos ingresados.\n                                    en tu perfil",
                                        "form": "Rellena el formulario en EasyTransac para activar tu cuenta de pago",
                                        "warning": {
                                            "description": "Dependiendo del nivel KYC de su cuenta EasyTransac, es posible que\n                                        poder recibir pagos pero no transferir fondos a un banco normal\n                                        cuenta. fondos a una cuenta bancaria regular. Para esto necesitarás el nivel KYC\n                                        2 y proporcionar ciertos documentos en la interfaz de EasyTransac. el EasyTransac\n                                        interfaz.",
                                            "title": "nivel KYC"
                                        }
                                    },
                                    "my_profile": {
                                        "part_1": "ir a la pagina",
                                        "part_2": "Mi perfil"
                                    }
                                },
                                "subtitle_1": "Para que sus conserjes reciban propinas, debe crear una cuenta.\n                            en el sitio web de nuestro socio EasyTransac.",
                                "subtitle_2": "Para hacer esto:",
                                "title": "Pago"
                            }
                        },
                        "tourist_office": {
                            "description": "Para permitirle utilizar la aplicación UgoZER en las mejores condiciones,\n                    Le aconsejamos que siga los siguientes pasos que le guiarán en los primeros pasos del proceso.\n                    manipulación y parametrización del espacio de su Oficina de Turismo.",
                            "steps": {
                                "employees": {
                                    "description": "Gestiona a tus empleados",
                                    "step_next": "agregado por empleado",
                                    "step_state": "reclamado",
                                    "title": "Empleados"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "Para permitirle utilizar la aplicación UgoZER en las mejores condiciones,\n                    Le aconsejamos que siga los siguientes pasos que le guiarán en los primeros pasos del proceso.\n                    Manejo y parametrización del espacio para empleados de su Oficina de Turismo.",
                            "steps": {
                                "institution": {
                                    "content": "Busca tu oficina de turismo en el listado de establecimientos mediante la barra de búsqueda\n                            y los filtros disponibles en la sección izquierda de la página. Una vez que haya encontrado su\n                            establecimiento, cree una solicitud haciendo clic en <strong>Solicitar unirse</strong>\n                            botón.",
                                    "description": "Enlace a su institución",
                                    "step_next": "reclamado",
                                    "step_state": "pendiente",
                                    "subtitle": {
                                        "link": "mi institución",
                                        "part_1": "Enlázame a mi oficina de turismo.",
                                        "part_2": "Puedes enlazar a tu oficina de turismo directamente a la página."
                                    },
                                    "title": "Institución"
                                }
                            }
                        }
                    },
                    "dashboard": {
                        "claim": {
                            "steps": {
                                "claim_form": "Luego siga las instrucciones en el formulario de reclamo.",
                                "claim_validation": "El reclamo será luego verificado y validado por nuestro equipo.",
                                "dont_exist": "Si no existe, haz clic en el botón <strong>Crear un punto de interés\n                        </strong> luego rellene el formulario. Una vez creado el punto de interés, vaya a su\n                        tarjeta y haga clic en el botón <strong>Reclamar</strong>",
                                "exist": "Si su institución existe, vaya a su ficha y haga clic en el botón <strong>\n                        Reclamar</strong>",
                                "filters": "Utiliza los filtros y el buscador para encontrar tu institución",
                                "poi_index": {
                                    "part_1": "Ve a la",
                                    "part_2": "lista de puntos de interés"
                                }
                            },
                            "subtitle_1": "Puedes reclamar uno (o más) establecimiento(s) para poder gestionarlos.",
                            "subtitle_2": "Para hacerlo:",
                            "title": "Reclamar la propiedad y gestión de mi institución"
                        },
                        "employees": {
                            "link": "Para crear una solicitud, pueden ir a la página <strong>Mi institución</strong>\n                    de su espacio y llenar el formulario.",
                            "manage": "En esta página puedes aceptar o rechazar empleados que quieran vincularse con tu\n                    institución.",
                            "subtitle": {
                                "part_1": "Puede administrar sus empleados directamente a través de la página",
                                "part_2": "mis empleados"
                            },
                            "title": "Gestionar a mis empleados"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "distance": "<strong>Lugar:</strong> te permite filtrar los puntos de una ubicación específica",
                            "main_title": "Filtros",
                            "subtitle": "Puedes filtrar la lista de itinerarios según los siguientes criterios:",
                            "title": "Filtrar itinerarios",
                            "type": {
                                "customized": "<strong>Personalizado:</strong> itinerarios creados específicamente para un viajero",
                                "explanation": "<strong>Tipo:</strong> te permite filtrar por tipo de itinerario",
                                "pro": "<strong>UgoZER Pro:</strong> itinerarios proporcionados por UgoZER",
                                "root": "<strong>Mis plantillas:</strong> itinerarios modelo"
                            }
                        }
                    },
                    "institution_employee": {
                        "approve": {
                            "content": "Esta acción le permite aceptar una solicitud de uno de sus empleados para unirse a uno de\n                    sus instituciones.",
                            "main_title": "Aprobar",
                            "title": "Aprobar un empleado"
                        },
                        "cancel": {
                            "content": "Esta acción le permite cancelar la solicitud de invitación de un empleado en caso de\n                    en caso de error, por ejemplo.",
                            "main_title": "Cancelar",
                            "title": "Cancelar la solicitud de un empleado"
                        },
                        "decline": {
                            "content": "Esta acción le permite rechazar la solicitud de uno de sus empleados para unirse a uno de sus\n                    instituciones.",
                            "main_title": "Rechazar",
                            "title": "Rechazar a un empleado"
                        },
                        "functioning": {
                            "content": {
                                "invite": "En esta página puede invitar a empleados o aceptar/rechazar solicitudes de su\n                        empleados.",
                                "section": "Esta sección le permite administrar los empleados de sus instituciones."
                            },
                            "main_title": "Marcha",
                            "title": "Funcionamiento de la sección de empleados"
                        },
                        "invite": {
                            "content": {
                                "button": "Al hacer clic en el botón <strong>Invitar empleado</strong>, puede invitar a uno de sus\n                        empleados ya tengan una cuenta UgoZER o no.",
                                "form": "Para ello, simplemente siga las instrucciones del formulario paso a paso que aparece.\n                        forma que aparece."
                            },
                            "main_title": "Invitar",
                            "title": "invitar a un empleado"
                        },
                        "remove": {
                            "content": "Esta acción le permite eliminar un usuario de sus empleados en caso de que un\n                    El empleado abandona su empresa. en el caso de que un empleado abandone su empresa.",
                            "main_title": "Eliminar",
                            "title": "Eliminar un empleado"
                        }
                    },
                    "points_of_interest": {
                        "claim": "Afirmar",
                        "filters": {
                            "category": "<strong>Categoría:</strong> le permite elegir una categoría específica\n                    (Hotel Restaurante...)",
                            "city": "<strong>Ciudad:</strong> le permite filtrar por una ciudad específica",
                            "country": "<strong>Países:</strong> le permite filtrar por uno o más países específicos.",
                            "distance": "<strong>Lugar:</strong> te permite filtrar los puntos de una ubicación específica",
                            "main_title": "Filtros",
                            "measure_unit": "<strong>Unidad de medida:</strong> le permite cambiar la unidad de medida utilizada para\n                    el filtro de distancia y la pantalla",
                            "my_points": "<strong>Mostrar solo mis puntos:</strong> solo muestra los puntos que usted ha creado o\n                    reclamado por ti",
                            "postal_code": "<strong>Zip:</strong> te permite filtrar por código postal",
                            "radius": "<strong>Limitar a un radio:</strong> permite filtrar los puntos en un círculo de áreas específicas.\n                    radio",
                            "subtitle": "Puede filtrar la lista de puntos de interés según los siguientes criterios:",
                            "tags": "<strong>Refinar:</strong> le permite refinar la búsqueda por etiquetas para especificar la categoría.\n                    También es posible definir si se desea buscar puntos con uno o todos o todos\n                    las etiquetas seleccionadas",
                            "title": "Filtrar puntos de interés"
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "validate": {
                    "success": "La credencial ha sido validada exitosamente (si es elegible)"
                },
                "verify": {
                    "already_verified": "Tu credencial ya está verificada",
                    "expired": "El enlace ha caducado, pide uno nuevo.",
                    "impossible": "La verificación de esta credencial ya no es posible",
                    "not_received": "¿No recibiste el enlace de confirmación?",
                    "sent": {
                        "again": "Enviar de nuevo",
                        "correct": "Corregir la credencial elegida",
                        "email_1": "Se enviará un correo electrónico a {email}, si esta dirección aún no está en uso.",
                        "email_2": "Este correo electrónico contiene un enlace que le permitirá confirmar su dirección.",
                        "email_3": "Su correo electrónico se actualizará tan pronto como acceda al enlace.",
                        "refresh": "¿Hiciste clic en el enlace? Actualizar la aplicación",
                        "title": "Enlace enviado",
                        "wait_before": "Espere {seconds} segundos antes de volver a enviar"
                    },
                    "success": "Su credencial ha sido verificada exitosamente"
                }
            },
            "user_geoloc_settings": {
                "geolocation": {
                    "error": {
                        "allow_browser": "Permite que tu navegador te geolocalice en la configuración de tu teléfono",
                        "allow_site": "Permita que UgoZER lo geolocalice en la configuración de su navegador",
                        "intro": "Para ver puntos de interés a tu alrededor:",
                        "title": "Error de geolocalización"
                    },
                    "intro": "La geolocalización te permite encontrar los puntos de interés más cercanos."
                },
                "not_configured": {
                    "body1": "Seleccione una ubicación de su elección para ver una selección de puntos de interés cerca de ella.",
                    "body2": "También es posible mostrar sólo los puntos de interés cercanos a usted.",
                    "do_not_ask_again": "No me vuelvas a preguntar hasta que se reinicie la aplicación.",
                    "subject": "Aún no has definido tu configuración de geolocalización."
                },
                "update": {
                    "success": "La configuración de geolocalización se ha actualizado."
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "La configuración de notificaciones se ha actualizado."
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "La configuración de privacidad se ha actualizado."
                }
            },
            "users": {
                "butlers": "conserjes",
                "finish": {
                    "success": "Tutorial cambiado al estado finalizado."
                },
                "index": {
                    "hint": "Un usuario puede ser encontrado por su apodo o su identificación pública.",
                    "no_results": "Usuario no encontrado",
                    "search": "Buscar un usuario"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "delete": "¿Borrar mi cuenta?",
                            "profile": "Mi perfil"
                        }
                    }
                },
                "profile": {
                    "alerts": {
                        "blocked": {
                            "message": "Ya no puedes interactuar con este usuario.",
                            "title": "Este usuario te bloqueó..."
                        },
                        "missing_infos": {
                            "message": "Para aprovechar todas las características de la aplicación, complete la siguiente información:",
                            "title": "completa tu perfil"
                        },
                        "pending_validation": {
                            "message": "Este usuario aún no ha aceptado tu demanda.",
                            "title": "Validación pendiente"
                        }
                    },
                    "avatar": {
                        "defaults": {
                            "female": "Femenino",
                            "male": "Masculino"
                        },
                        "section": {
                            "cta": "Usa este avatar",
                            "description": "Puede seleccionar un avatar predefinido de la lista a continuación",
                            "title": "Elegir un avatar predefinido"
                        },
                        "success": "¡Tu avatar se ha actualizado correctamente!"
                    },
                    "bank_account_number_missing": "Por favor ingrese su número de cuenta para recibir sus pagos",
                    "delete_account": {
                        "easy_transac": "Su cuenta EasyTransac no se eliminará.",
                        "instructions": "Para confirmar la eliminación final de su cuenta, ingrese la palabra \"ELIMINAR\" en el campo a continuación y haga clic en el botón \"Confirmar\".",
                        "success": "Tu cuenta ha sido eliminada.",
                        "title": "Eliminación de cuenta",
                        "warning": "Su cuenta, así como toda la información relacionada con su cuenta, se eliminará inmediatamente sin posibilidad de recuperación."
                    },
                    "edit_email": "Edita tu correo electrónico",
                    "edit_password": "Edita tu contraseña",
                    "edit_phone": "Edita tu número de teléfono",
                    "edit_your_profile": "Edite su perfil",
                    "email_missing": "No has rellenado un correo electrónico verificado.",
                    "fill_country": "Por favor especifica tu país",
                    "form": {
                        "poi_label": "Institución vinculada"
                    },
                    "manage_my_subscription": "Administrar mi suscripción",
                    "password_missing": "No has introducido una contraseña.",
                    "phone_missing": "No has rellenado un número de teléfono verificado",
                    "preferences": "preferencias",
                    "public_id": "O usa este identificador:",
                    "qr_code": "Escanéame para ver mi perfil",
                    "security": "Seguridad",
                    "share_profile": "Comparte tu perfil",
                    "subscribe_to_receive_payments": "Debes estar suscrito para recibir pagos.",
                    "unnamed": "Sin nombre",
                    "your_personal_information": "Tu información personal"
                },
                "report": {
                    "choose_reason": "Elige un motivo para denunciar a este usuario \"{user_name}\"",
                    "heading": "Reportar a un usuario",
                    "success": "¡El usuario ha sido denunciado!"
                },
                "search": {
                    "no_results": "No se encontraron usuarios"
                },
                "send_link": {
                    "error": "No se puede enviar el enlace, ¿el usuario tiene un correo electrónico?",
                    "success": "Se ha enviado el enlace a la solicitud"
                },
                "update": {
                    "success": "El perfil ha sido actualizado"
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "No se encontraron contactos",
                    "pending_requests": "Tus contactos pendientes"
                },
                "messages": {
                    "add": {
                        "confirm": "¿Realmente quieres agregar este contacto?",
                        "error": "Se produce un error durante la solicitud de contacto...",
                        "success": "Solicitud de contacto realizada con éxito!"
                    },
                    "approve": {
                        "confirm": "¿Realmente desea aceptar esta solicitud de contacto?",
                        "success": "¡Solicitud de contacto aceptada!"
                    },
                    "block": {
                        "confirm": "¿Realmente quieres bloquear a este usuario?",
                        "success": "¡Este contacto ha sido bloqueado!"
                    },
                    "decline": {
                        "confirm": "¿Realmente desea rechazar esta solicitud de contacto?",
                        "success": "¡Solicitud de contacto rechazada!"
                    },
                    "unblock": {
                        "confirm": "¿Realmente quieres desbloquear a este usuario?",
                        "success": "¡Este contacto ha sido desbloqueado!"
                    }
                },
                "no_results": {
                    "approved": "No hay contactos aprobados",
                    "blocked": "Sin contactos bloqueados",
                    "pending": "No hay solicitudes de contactos pendientes",
                    "sent": "No se han enviado solicitudes de contactos"
                },
                "search": {
                    "no_results": "No se encontraron contactos"
                },
                "tabs": {
                    "approved": "Aprobado",
                    "blocked": "Obstruido",
                    "pending": "Pendiente",
                    "sent": "Enviado"
                }
            }
        },
        "validation": {
            "accepted": "Se debe aceptar el {attribute}.",
            "active_url": "El {attribute} no es una URL válida.",
            "after": "El {attribute} debe ser una fecha posterior a {date}.",
            "after_or_equal": "El {attribute} debe ser una fecha posterior o igual a {date}.",
            "alpha": "El {attribute} sólo puede contener letras.",
            "alpha_dash": "El {attribute} sólo puede contener letras, números, guiones y guiones bajos.",
            "alpha_num": "El {attribute} sólo puede contener letras y números.",
            "array": "El {attribute} debe ser una matriz.",
            "attributes": {
                "accounting_file": "archivo contable",
                "actions": "comportamiento",
                "activated_at": "activado en",
                "active": "activo",
                "additional_information": "información adicional",
                "address": "DIRECCIÓN",
                "addresses": "direcciones",
                "admin_message": "mensaje",
                "administrative_area": "área administrativa",
                "amount": "cantidad",
                "app": "solicitud",
                "app_fees": "honorarios",
                "approved_at": "aprobado en",
                "archived_at": "Archivado en",
                "association_legal_representative_identity_piece": "prueba de identidad del representante legal",
                "association_statutes": "estatutos",
                "attached_files": "Archivos adjuntos",
                "audio": "audio",
                "audio_refreshing": "refrescante de audio",
                "audios": "audios",
                "authenticated_once": "autenticado una vez",
                "auto_renewal_enabled": "renovación automática habilitada",
                "avatar": "avatar",
                "bank_account": "cuenta bancaria",
                "bank_account_number": "número de cuenta bancaria",
                "bank_bic": "BIC",
                "bank_city": "ciudad bancaria",
                "bank_country": "País del banco",
                "bank_file": "archivo bancario",
                "bank_iban": "IBAN",
                "bank_name": "Nombre del banco",
                "bank_payee": "Tenedor",
                "bank_transfer": "transferencia bancaria",
                "bank_transfer_institutions": "institución de transferencias bancarias",
                "bank_transfer_reason": "Razón para transferir",
                "butler": "conserje",
                "butler_point_of_interest": "institución de conserjería",
                "canceled_at": "Cancelado en",
                "categories": "categorias",
                "category": "categoría",
                "category_id": "categoria ID",
                "causer": "culpable",
                "causer_id": "identificación del causante",
                "causer_type": "tipo de causante",
                "certified": "certificado",
                "city": "ciudad",
                "claim_confirmation": "confirmación de reclamo",
                "code": "código",
                "collection_name": "nombre de la colección",
                "communication_consent": "Comunicaciones personalizadas",
                "communication_consent_alt": "¿Comunicaciones?",
                "company_legal_representative_identity_piece": "prueba de identidad del representante legal",
                "company_registration_document": "documento de registro",
                "company_statutes": "estatutos",
                "company_type": "tipo de compañía",
                "completed_at": "completado en",
                "contact": "contacto",
                "contact_email": "Email de contacto",
                "contact_phone": "teléfono de contacto",
                "content": "contenido",
                "contestant": "concursante",
                "contestant_company_type": "tipo de compañía",
                "contestant_email": "correo electrónico",
                "contestant_first_name": "nombre de pila",
                "contestant_last_name": "apellido",
                "contestant_message": "mensaje",
                "contestant_phone": "teléfono",
                "conversions_disk": "disco de conversión",
                "coords": "coordenadas",
                "countries": "países",
                "countries_codes": "países",
                "country": "país",
                "country_code": "código de país",
                "created": "creado",
                "created_at": "Creado en",
                "creator": "creador",
                "credential": "credencial",
                "currency": "divisa",
                "current_password": "Contraseña actual",
                "custom_amount": "cantidad personalizada",
                "custom_properties": "propiedades personalizadas",
                "customers_qty": "cantidad de clientes",
                "date": "fecha",
                "dates": "fechas",
                "days": "días",
                "declined_at": "rechazado en",
                "deleted_at": "eliminado en",
                "deletion_confirmation": "confirmación de eliminación",
                "denial_reason": "motivo de denegación",
                "dependent_locality": "localidad dependiente",
                "description": "descripción",
                "details": "detalles",
                "disk": "disco",
                "display": "mostrar",
                "displayed_name": "Nombre mostrado",
                "distance": "distancia",
                "documents": "documentos",
                "dt_created_by": "Creado por",
                "dt_last_update": "Última actualización de DataTourisme",
                "dynamic_translations_enabled": "traducciones dinámicas habilitadas",
                "email": "correo electrónico",
                "emails_must_different": "Ambas cuentas no pueden tener la misma dirección de correo electrónico.",
                "employee": "empleado",
                "end_date": "fecha final",
                "engaged_until": "comprometido hasta",
                "engagement_period": "periodo de compromiso",
                "event": "evento",
                "excursion": "itinerario",
                "existing_user": "usuario existente",
                "expirable": "expirable",
                "expired_at": "expiró en",
                "expired_on": "fecha de finalización de la estancia",
                "expiry": "vencimiento",
                "expiry_date": "Fecha de caducidad",
                "extranet": "extranet",
                "fallback_locale": "configuración regional alternativa",
                "favorite": "itinerario favorito",
                "featured": "presentado",
                "file": "archivo",
                "file_name": "Nombre del archivo",
                "filter": "filtrar",
                "financial": "financiero",
                "first_name": "nombre de pila",
                "formatted_address": "dirección formateada",
                "free_period": "periodo libre",
                "free_period_ended_at": "período gratuito finalizó en",
                "front": "frente",
                "gallery": "galería",
                "gdpr": "RGPD",
                "general": "General",
                "generated_conversions": "conversiones generadas",
                "gross_amount": "Cantidad bruta",
                "has_employees": "tiene empleados",
                "hour": "hora",
                "hours": "horas",
                "id": "identificación",
                "image": "imagen",
                "images": "imágenes",
                "import_source": "fuente de importación",
                "import_synchronizing": "Activar actualización automática",
                "individual_first_identity_piece": "pieza de identidad",
                "individual_registration_document": "documento de registro",
                "individual_second_identity_piece": "segunda pieza de identidad",
                "information": "información",
                "institution": "institución",
                "institution_address": "dirección de la institución",
                "institution_id": "institución",
                "institution_title": "título de la institución",
                "interaction_type": "tipo de interacción",
                "invitation": "invitación",
                "invitation_accepted": "invitación aceptada",
                "invitation_status": "estado de la invitación",
                "invited_at": "invitado en",
                "invited_by": "Invitado por",
                "invitees": "invitados",
                "is_geocoding": "geolocalización en progreso",
                "iso_code": "código ISO",
                "items": "elementos",
                "key": "llave",
                "kind": "amable",
                "label": "etiqueta",
                "last_geoloc": "última geolocalización",
                "last_logged_at": "última sesión en",
                "last_login_ip": "última IP de inicio de sesión",
                "last_name": "apellido",
                "last_validated_at": "última validación",
                "lat": "latitud",
                "latitude": "latitud",
                "line1": "Dirección Línea 1",
                "line2": "línea de dirección 2",
                "link": "enlace",
                "lng": "longitud",
                "locale": "idioma",
                "login": "conexión",
                "logo": "logo",
                "longitude": "longitud",
                "manipulations": "manipulaciones",
                "max_amount_per_checkout": "cantidad máxima por pago",
                "measurement_system": "sistema de medida",
                "media": "medios de comunicación",
                "message": "mensaje",
                "metadata": "metadatos",
                "mime_type": "Tipo de Mimica",
                "min_amount_per_checkout": "importe mínimo por pago",
                "misc": "misceláneas",
                "model": "registro",
                "model_type": "tipo de registro",
                "name": "nombre",
                "net_amount": "importe neto",
                "new_password": "Nueva contraseña",
                "new_password_confirmation": "Nueva confirmación de contraseña",
                "next_payment_at": "siguiente pago",
                "nickname": "apodo",
                "not_specified": "No especificado",
                "not_validated_activities": "actividades no validadas",
                "notes": "más información",
                "opening_hours": "Horario de apertura",
                "order_column": "orden",
                "origin": "origen",
                "owner": "dueño",
                "owner_email": "correo electrónico",
                "owner_first_name": "nombre de pila",
                "owner_last_name": "apellido",
                "owner_phone": "teléfono",
                "password": "contraseña",
                "password_confirmation": "confirmación de contraseña",
                "passwords_must_different": "Ambas cuentas no pueden tener la misma contraseña.",
                "payable": "pagadero",
                "payee": "tenedor",
                "payee_country": "país",
                "payee_email": "correo electrónico",
                "payee_id": "tenedor",
                "payee_name": "nombre del beneficiario",
                "payee_phone": "teléfono",
                "payer": "pagador",
                "payer_country": "país",
                "payer_email": "correo electrónico",
                "payer_id": "pagador",
                "payer_name": "nombre del pagador",
                "payer_phone": "teléfono",
                "payment_received": "pago recibido",
                "payment_service": "servicio de pago",
                "payment_service_account_id": "cuenta de pago de identificación",
                "payment_service_transaction_id": "transacción de pago de identificación",
                "payment_thanks": "pago gracias",
                "payment_type": "tipo de pago",
                "period": "período",
                "phone": "teléfono",
                "plan": "plan",
                "point_of_interest": "punto de interés",
                "point_of_interest_categories": "categorías",
                "point_of_interest_category": "categoría",
                "point_of_interest_event_expired": "Evento caducado",
                "points_of_interest": "puntos de interés",
                "points_of_interest_count": "recuento de puntos de interés",
                "post_category": "categoría de publicación",
                "price": "precio",
                "price_excluding_taxes": "precio sin impuestos",
                "prices": "precios",
                "priority": "prioridad",
                "privacy_policy": "política de privacidad",
                "privacy_policy_accepted": "política de privacidad aceptada",
                "privacy_policy_version": "versión de política de privacidad",
                "private": "privado",
                "private_title": "título privado",
                "pro_file": "perfil",
                "profile_picture": "Foto de perfil",
                "properties": "propiedades",
                "properties_comparison": "propiedades actualizadas",
                "provider": "proveedor",
                "public": "público",
                "public_id": "identificación pública",
                "published_at": "publicado en",
                "quantity": "cantidad",
                "quitted_at": "renunció a",
                "radius": "radio",
                "rates": "tarifas",
                "reason_details": "detalles",
                "reason_type": "razón",
                "recipient": "recipiente",
                "recipients": "destinatarios",
                "recipients_alt": "viajeros registrados",
                "remember": "Acuérdate de mí",
                "removed_at": "eliminado en",
                "reports": "informes",
                "requested_at": "solicitado en",
                "reservation_created": "reserva creada",
                "reservation_date": "fecha",
                "reservation_time": "tiempo",
                "resolved_at": "Resuelto en",
                "responsive_images": "Imágenes de responsivo",
                "roles": "roles",
                "room_number": "número de habitación",
                "scout_searchable": "indexable",
                "searchable": "buscable",
                "sender": "remitente",
                "senders": "remitentes",
                "service": "servicio",
                "settings": "ajustes",
                "share_created": "compartir creado",
                "shareable": "compartible",
                "show_contact_email": "Visibilidad del correo electrónico de contacto",
                "show_contact_phone": "Visibilidad del teléfono de contacto",
                "show_profile_picture": "visibilidad de avatar",
                "show_real_name": "Visibilidad de nombre y apellido",
                "siret": "SIRET",
                "size": "tamaño",
                "source": "fuente",
                "source_id": "identificación de fuente",
                "specified": "Especificado",
                "starable": "señalado con estrellas",
                "stars": "estrellas",
                "start_date": "fecha de inicio",
                "state": "estado",
                "states": "estados",
                "static_translations_enabled": "traducciones estáticas habilitadas",
                "status": "estado",
                "subject": "sujeto",
                "subject_id": "identificación del sujeto",
                "subject_type": "tipo de tema",
                "summary": "resumen",
                "support_category": "categoría",
                "support_messages_count": "recuento de mensajes de soporte",
                "support_request": "solicitud de soporte",
                "support_request_archived": "Solicitud de soporte archivada",
                "support_request_message_received": "Respuesta recibida en una solicitud de soporte",
                "suspended_at": "suspendido en",
                "tag": "Etiqueta",
                "tags": "Etiquetas",
                "target": "objetivo",
                "terms_condition_accepted": "términos y condiciones aceptados",
                "terms_condition_version": "versión términos y condiciones",
                "terms_conditions": "Términos y condiciones",
                "thanked": "agradeció",
                "thanked_at": "fecha de agradecimiento",
                "thanks": "gracias",
                "thanks_message": "mensaje de agradecimiento",
                "thanks_sent": "gracias enviado",
                "tipping_fees": "tarifas de propina",
                "tips_enabled": "consejos habilitados",
                "title": "título",
                "total_amount": "cantidad total",
                "traveller": "viajero",
                "traveller_folder": "carpeta de viajero",
                "traveller_folder_approved": "carpeta viajero homologada",
                "traveller_folder_archived": "carpeta viajero archivada",
                "traveller_folder_pending": "carpeta viajero pendiente",
                "traveller_folder_thanks": "gracias por la estancia",
                "tutorial_state": "Estado del tutorial",
                "type": "tipo",
                "updated_at": "actualizado en",
                "user": "usuario",
                "user_id": "usuario",
                "user_report": "usuario (que informa)",
                "users": "usuarios",
                "utm_campaign": "Campaña",
                "utm_medium": "Medio",
                "utm_settings": "Parámetros UTM",
                "utm_source": "Fuente",
                "uuid": "uuid",
                "validated_activities": "actividades validadas",
                "validated_at": "validado en",
                "value": "valor",
                "vat": "IVA",
                "vat_rate": "tipo de IVA",
                "version": "versión",
                "video": "video",
                "videos": "vídeos",
                "website": "sitio web",
                "zip": "cremallera"
            },
            "before": "El {attribute} debe ser una fecha anterior a {date}.",
            "before_or_equal": "El {attribute} debe ser una fecha anterior o igual a {date}.",
            "between": {
                "array": "El {attribute} debe tener entre {min} y {max} elementos.",
                "file": "El {attribute} debe estar entre {min} y {max} kilobytes.",
                "numeric": "El {attribute} debe estar entre {min} y {max}.",
                "string": "El {attribute} debe tener entre {min} y {max} caracteres."
            },
            "boolean": "El campo {attribute} debe ser verdadero o falso.",
            "confirmed": "El {attribute} no coincide.",
            "currency_scale": "El número de dígitos de {attribute} después del punto decimal no debe exceder {scale}.",
            "current_password": "El {attribute} no coincide con su contraseña.",
            "custom": {
                "attribute-name": {
                    "rule-name": "mensaje personalizado"
                },
                "h-captcha-response": {
                    "HCaptcha": "¡Error de CAPTCHA! inténtelo de nuevo más tarde o comuníquese con el administrador del sitio.",
                    "required": "Por favor verifica que no eres un robot."
                }
            },
            "date": "El {attribute} no es una fecha válida.",
            "date_equals": "El {attribute} debe ser una fecha igual a {date}.",
            "date_format": "El {attribute} no coincide con el formato {format}.",
            "different": "El {attribute} y {other} deben ser diferentes.",
            "digits": "El {attribute} debe ser {digits} dígitos.",
            "digits_between": "El {attribute} debe tener entre {min} y {max} dígitos.",
            "dimensions": "El {attribute} tiene dimensiones de imagen no válidas.",
            "distinct": "El campo {attribute} tiene un valor duplicado.",
            "email": "El {attribute} debe ser una dirección de correo electrónico válida.",
            "ends_with": "El {attribute} debe terminar con uno de los siguientes: {values}.",
            "exists": "El {attribute} seleccionado no es válido.",
            "file": "El {attribute} debe ser un archivo.",
            "filled": "El campo {attribute} debe tener un valor.",
            "gt": {
                "array": "El {attribute} debe tener más de {value} elementos.",
                "file": "El {attribute} debe ser mayor que {value} kilobytes.",
                "numeric": "El {attribute} debe ser mayor que {value}.",
                "string": "El {attribute} debe ser mayor que {value} caracteres."
            },
            "gte": {
                "array": "El {attribute} debe tener {value} elementos o más.",
                "file": "El {attribute} debe ser mayor o igual a {value} kilobytes.",
                "numeric": "El {attribute} debe ser mayor o igual que {value}.",
                "string": "El {attribute} debe ser mayor o igual a {value} caracteres."
            },
            "image": "El {attribute} debe ser una imagen.",
            "in": "El {attribute} seleccionado no es válido.",
            "in_array": "El campo {attribute} no existe en {other}.",
            "integer": "El {attribute} debe ser un número entero.",
            "ip": "El {attribute} debe ser una dirección IP válida.",
            "ipv4": "El {attribute} debe ser una dirección IPv4 válida.",
            "ipv6": "El {attribute} debe ser una dirección IPv6 válida.",
            "json": "El {attribute} debe ser una cadena JSON válida.",
            "lt": {
                "array": "El {attribute} debe tener menos de {value} elementos.",
                "file": "El {attribute} debe tener menos de {value} kilobytes.",
                "numeric": "El {attribute} debe ser menor que {value}.",
                "string": "El {attribute} debe tener menos de {value} caracteres."
            },
            "lte": {
                "array": "El {attribute} no debe tener más de {value} elementos.",
                "file": "El {attribute} debe ser menor o igual a {value} kilobytes.",
                "numeric": "El {attribute} debe ser menor o igual que {value}.",
                "string": "El {attribute} debe ser menor o igual a {value} caracteres."
            },
            "luhn": "{attribute} es un número no válido.",
            "max": {
                "array": "El {attribute} no puede tener más de {max} artículos.",
                "file": "El {attribute} no puede ser mayor que {max} kilobytes.",
                "height": "{attribute} no debe exceder {max} píxeles de altura.",
                "numeric": "El {attribute} no puede ser mayor que {max}.",
                "string": "El {attribute} no puede ser mayor que {max} caracteres.",
                "width": "{attribute} no debe exceder {max} píxeles de ancho."
            },
            "max_digits": "{attribute} no debe exceder {max} dígitos.",
            "mimes": "El {attribute} debe tener un tipo válido.",
            "mimetypes": "El {attribute} debe tener un tipo válido.",
            "min": {
                "array": "El {attribute} debe tener al menos {min} elementos.",
                "file": "El {attribute} debe tener al menos {min} kilobytes.",
                "numeric": "El {attribute} debe ser al menos {min}.",
                "string": "El {attribute} debe tener al menos {min} caracteres."
            },
            "min_digits": "{attribute} debe tener al menos {min} dígitos.",
            "not_in": "El {attribute} seleccionado no es válido.",
            "not_regex": "El formato {attribute} no es válido.",
            "notification_setting": "El formato {attribute} no es válido (el formato esperado es array[bool]).",
            "numeric": "El {attribute} debe ser un número.",
            "password": "La contraseña es incorrecta.",
            "phone": "El {attribute} debe ser un número de teléfono válido.",
            "present": "El campo {attribute} debe estar presente.",
            "prohibited_if": "El campo {attribute} está prohibido cuando {other} es {value}.",
            "prohibited_unless": "El campo {attribute} está prohibido a menos que {other} esté en {values}.",
            "real_name_characters": ":{attribute} solo puede contener letras, espacios, puntos,\n        guiones o apóstrofes.",
            "regex": "El formato {attribute} no es válido.",
            "required": "El campo {attribute} es obligatorio.",
            "required_if": "El campo {attribute} es obligatorio cuando {other} es {value}.",
            "required_unless": "El campo {attribute} es obligatorio a menos que {other} esté en {values}.",
            "required_with": "El campo {attribute} es obligatorio cuando {values} está presente.",
            "required_with_all": "El campo {attribute} es obligatorio cuando {values} está presente.",
            "required_without": "El campo {attribute} es obligatorio cuando {values} no está presente.",
            "required_without_all": "El campo {attribute} es obligatorio cuando ninguno de los {values} está presente.",
            "same": "El {attribute} y {other} deben coincidir.",
            "same_currency": "Todos los artículos deben utilizar la misma moneda.",
            "size": {
                "array": "El {attribute} debe contener {size} elementos.",
                "file": "El {attribute} debe ser {size} kilobytes.",
                "numeric": "El {attribute} debe ser {size}.",
                "string": "El {attribute} debe ser {size} caracteres."
            },
            "starts_with": "El {attribute} debe comenzar con uno de los siguientes: {values}.",
            "string": "El {attribute} debe ser una cadena.",
            "tag_same_poi_category": "¡{attribute} debería pertenecer a la misma categoría!",
            "timezone": "El {attribute} debe ser una zona válida.",
            "unique": "El {attribute} ya ha sido tomado.",
            "uploaded": "El {attribute} no se pudo cargar.",
            "url": "El formato {attribute} no es válido.",
            "uuid": "El {attribute} debe ser un UUID válido."
        }
    },
    "fr": {
        "The {attribute} must be at least {length} characters and contain at least one number.": "Le champ {attribute} doit avoir au moins {length} caractères et contenir au moins un chiffre.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "Le champ {attribute} doit avoir au moins {length} caractères et contenir au moins un caractère spécial et un nombre.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "Le champ {attribute} doit avoir au moins {length} caractères et contenir au moins un caractère spécial.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "Le champ {attribute} doit avoir au moins {length} caractères et contenir au moins une majuscule et un chiffre.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "Le champ {attribute} doit avoir au moins {length} caractères et contenir au moins une majuscule et un caractère spécial.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "Le champ {attribute} doit avoir au moins {length} caractères, et contenir au moins une majuscule, un chiffre et un caractère spécial.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "Le champ {attribute} doit avoir au moins {length} caractères et au moins une majuscule.",
        "The {attribute} must be at least {length} characters.": "Le champ {attribute} doit avoir au moins {length} caractères.",
        "The {attribute} must contain at least one letter.": "Le champ {attribute} doit avoir au moins une lettre.",
        "The {attribute} must contain at least one number.": "Le champ {attribute} doit avoir au moins un numéro.",
        "The {attribute} must contain at least one symbol.": "Le champ {attribute} doit avoir au moins un symbole.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Le champ {attribute} doit avoir au moins une lettre majuscule et une lettre minuscule.",
        "auth": {
            "failed": "Ces identifiants ne correspondent pas à nos données. Êtes-vous sur de ne pas avoir utilisé ce mail pour un compte {type} ?",
            "throttle": "Trop de tentatives de connexion. Réessayez dans {seconds} secondes.",
            "throttle_cascade": "Trop de tentatives de connexion. Réessayez dans {remaining}."
        },
        "enums": {
            "App\\Enums\\PrivacySettingsEnum": {
                "everyone": "Tout le monde",
                "contacts": "Mes contacts",
                "nobody": "Personne"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "Impérial",
                "metric": "Métrique"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "Petit déjeuner",
                "lunch": "Déjeuner",
                "dinner": "Dîner",
                "null": "N/A"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "Euro",
                "usd": "Dollar"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Monday": "Lundi",
                "Tuesday": "Mardi",
                "Wednesday": "Mercredi",
                "Thursday": "Jeudi",
                "Friday": "Vendredi",
                "Saturday": "Samedi",
                "Sunday": "Dimanche"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "Application",
                "email": "E-mail"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "stripe": "Stripe",
                "easytransac": "EasyTransac",
                "paypal": "PayPal"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "tip": "Pourboire",
                "subscription": "Abonnement"
            },
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "Créé",
                "updated": "Mis à jour",
                "validated": "Validé",
                "deleted": "Supprimé"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "incorrect_data": "Informations erronées",
                "shocking": "Choquant",
                "illegal": "Illégal",
                "spam": "Spam",
                "other": "Autre"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "Sur mesure",
                "root": "Mes modèles",
                "ugozer_pro": "UgoZER Pro",
                "ugozer_app": "UgoZER App"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "App",
                "extranet": "Extranet",
                "front": "Front"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "Autour de moi",
                "staying_hotel": "Près de l'hôtel",
                "customized": "Personnalisée"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "FR": "France",
                "DE": "Allemagne",
                "ES": "Espagne",
                "IT": "Italie",
                "TH": "Thaïlande",
                "undefined": "Non défini"
            },
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "individual": "Auto-entrepreneur / entreprise individuelle / profession libérale",
                "company": "Société (SARL, SAS...)",
                "association": "Association",
                "undefined": "Non définie"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "Archivé",
                "canceled": "Annulé",
                "pending_support": "En attente du support",
                "pending_user": "En attente de l'utilisateur",
                "resolved": "Résolu"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "partner": "Partenaire",
                "customer": "Client"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "1 mois",
                "one year": "1 an"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "1 mois",
                "three months": "3 mois",
                "six months": "6 mois",
                "one year": "1 an"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "payment default": "Défaut de paiement",
                "free period ended": "Période de gratuité terminée",
                "expired": "Abonnement expiré"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "Facebook",
                "twitter": "X (Twitter)",
                "instagram": "Instagram",
                "linkedin": "LinkedIn",
                "google": "Google",
                "tiktok": "TikTok",
                "wechat": "WeChat"
            },
            "App\\Enums\\BinaryChoiceEnum": {
                "1": "Accepté",
                "0": "Refusé"
            },
            "App\\Enums\\ContextsEnum": {
                "traveller": "Voyageur",
                "tourist_office": "Office de tourisme",
                "city_hall": "Mairie",
                "manager": "Manager",
                "hotel": "Hôtel"
            },
            "App\\Enums\\UserRoleEnum": {
                "traveller": "Voyageur",
                "manager": "Manager",
                "butler": "Réceptionniste",
                "superadmin": "Administrateur",
                "contributor": "Contributeur",
                "moderator": "Modérateur",
                "hotelier": "Hôtelier",
                "touristOffice": "Office de tourisme",
                "touristOfficeEmployee": "Employé(e) d'OT"
            }
        },
        "nova": {
            "menu": {
                "pois": "Points d'intérêt",
                "excursions": "Itinéraires",
                "users": "Utilisateurs",
                "content": "Contenu",
                "miscellaneous": "Divers",
                "parameters": "Paramètres",
                "administration": "Administration",
                "import_poi": "Import POI",
                "support": "Support",
                "subscriptions": "Abonnements"
            },
            "user_menu": {
                "profile": "Mon profil"
            },
            "s3_private_download": {
                "link": {
                    "open": "Consulter le document",
                    "expiration": "Le lien expirera au bout de 30 min, si c'est le cas, rafraichissez simplement la page."
                },
                "no_document": "Aucun document associé",
                "not_found": "Le document n'a pas pu être trouvé sur le disque S3..."
            },
            "lens": {
                "user": {
                    "user_origin": "Provenance des utilisateurs"
                }
            }
        },
        "pagination": {
            "previous": "&laquo; Précédent",
            "previous_alt": "Précédent",
            "next": "Suivant &raquo;",
            "next_alt": "Suivant",
            "showing": "Affichage",
            "to": "de",
            "of": "sur",
            "results": "résultats",
            "pagination_navigation": "Navigation pagination",
            "go_page": "Aller en page {page}"
        },
        "passwords": {
            "reset": "Votre mot de passe a été réinitialisé !",
            "sent": "Votre lien de réinitialisation vous a été envoyé par mail !",
            "throttled": "Merci d'attendre avant de réessayer.",
            "token": "Ce jeton de réinitialisation est invalide.",
            "user": "Aucun utilisateur correspondant."
        },
        "ttmt": {
            "account_deletion": {
                "title": "Suppression de compte",
                "content": {
                    "conservation": {
                        "part_1": "UgoZER conserve les données des utilisateurs aussi longtemps que nécessaire aux fins décrites\n                dans la",
                        "link": "Charte – Politique de confidentialité.",
                        "part_2": "Conformément aux recommandations de la CNIL, un compte non utilisé pendant 3 ans est\n                supprimé.",
                        "part_3": "Les utilisateurs peuvent demander la suppression de leurs comptes à tout moment. UgoZER peut\n                conserver les données des utilisateurs après une demande de suppression conformément aux exigences\n                légales ou réglementaires, ou pour des raisons indiquées dans la présente déclaration."
                    },
                    "asking": "En cas de demande de suppression de compte, UgoZER supprime le compte et les données de\n            l'utilisateur, sauf s'ils doivent être conservés en raison d'exigences légales ou réglementaires, à\n            des fins de sécurité, de prévention de la fraude ou du fait d'un problème lié au compte de l'utilisateur\n            tel qu'un impayé, une réclamation ou un litige non résolu.",
                    "instructions": "Si vous souhaitez supprimer votre compte UgoZER, suivez les instructions ci-dessous."
                },
                "traveller": {
                    "title": "Compte voyageur",
                    "subtitle": "Pour supprimer votre compte voyageur :",
                    "instructions": {
                        "profile": {
                            "part_1": "Allez dans votre",
                            "link": "profil",
                            "path": "(menu icône hamburger > Paramètres > Profil),"
                        },
                        "delete": "Puis cliquez sur le bouton <strong>Supprimer</strong> en face de <strong>Supprimer mon\n                compte ?</strong>"
                    }
                },
                "pro": {
                    "title": "Compte professionnel",
                    "subtitle": "Pour supprimer votre compte professionnel :",
                    "instructions": {
                        "profile": {
                            "part_1": "Allez dans votre",
                            "link": "profil",
                            "path": "(en cliquant sur votre avatar > Mon profil),"
                        },
                        "delete": "Puis cliquez sur le bouton <strong>Supprimer</strong> en face de <strong>Suppression du\n                compte</strong>,"
                    }
                },
                "confirmation": "Dans la page qui s'ouvre, confirmez la suppression en saisissant le mot <strong>DELETE</strong>\n        et cliquez sur <strong>Confirmer</strong>.",
                "logout": "Vous serez déconnecté et le compte ainsi que les données associées seront immédiatement supprimées."
            },
            "activity_logs": {
                "summary": {
                    "updated": {
                        "intro": "Le sujet a été mis à jour :",
                        "attribute_from_to": "{attribute} a été mis à jour de \"{old_value}\" à \"{new_value}\""
                    },
                    "validated": {
                        "intro": "Le sujet a été validé :"
                    },
                    "created": {
                        "intro": "Le sujet a été créé avec ces propriétés :"
                    },
                    "deleted": {
                        "intro": "Le sujet a été supprimé avec ces propriétés :"
                    }
                }
            },
            "auth": {
                "login": {
                    "success": "Connecté",
                    "already_logged": "Vous êtes déjà connecté",
                    "logging_in": "Connexion...",
                    "use_email": "Se connecter avec un e-mail",
                    "use_social_networks": "Utiliser les réseaux sociaux"
                },
                "password_forgotten": {
                    "intro": "Entrez votre identifiant et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
                    "success": {
                        "title": "Lien de réinitialisation envoyé",
                        "email_1": "Un e-mail va vous être envoyé à {email}, si cette adresse est bien inscrite.",
                        "email_2": "Cet e-mail contient un lien qui vous permettra de réinitialiser votre mot de passe.",
                        "correct_credential": "Corriger l'identifiant",
                        "send_again": "Envoyer à nouveau",
                        "wait_before": "Merci de patienter {seconds} secondes avant un nouvel envoi"
                    },
                    "submit": "Recevoir un lien de réinitialisation"
                },
                "password_reset": {
                    "success": "Le mot de passe a été réinitialisé",
                    "submit": "Réinitialiser votre mot de passe"
                },
                "messages": {
                    "login": "Se connecter",
                    "login_title": "C'est mieux avec les réseaux sociaux",
                    "login_subtitle": "(inscription ou connexion)",
                    "send_magic_link": "Envoi du lien de connexion",
                    "no_account": "Vous n'avez pas encore de compte ?",
                    "have_account": "Vous avez déjà un compte ?",
                    "register": "S'inscrire",
                    "register_title": "Inscrivez-vous pour débloquer toutes les fonctionnalités",
                    "register_mail": "S'inscrire avec un e-mail",
                    "or": "OU",
                    "professional": "Vous êtes un professionnel ?",
                    "extranet_here": "Par ici",
                    "reset_password": "Réinitialiser votre mot de passe",
                    "continue": "Continuer avec"
                },
                "register": {
                    "success": {
                        "paired": {
                            "email_1": "Deux emails ont été envoyés aux adresses suivantes",
                            "email_2": "Chaque email contient un lien qui premettra de confirmer l'adresse et de connecter le compte associé."
                        },
                        "title": "Inscription réussie",
                        "email_1": "Un e-mail va vous être envoyé à {email}.",
                        "email_2": "Cet e-mail contient un lien qui vous permettra de confirmer votre adresse et de vous \n                connecter."
                    },
                    "form": {
                        "privacy_policy": "J'accepte la charte de confidentialité",
                        "terms_conditions": "J'accepte les conditions générales",
                        "show_privacy_policy": "Afficher la charte de confidentialité",
                        "hide_privacy_policy": "Masquer la charte de confidentialité",
                        "show_terms_conditions": "Afficher les conditions générales",
                        "hide_terms_conditions": "Masquer les conditions générales"
                    },
                    "staying_hotel": "Une fois inscrit, vous serez pris en charge par l'hôtel dont\n            vous venez de scanner le QR code."
                },
                "credential_verification": {
                    "intro": "Entrez votre identifiant, un lien de confirmation y sera envoyé.",
                    "intro2": "Votre identifiant sera mis à jour dès que vous aurez accéder au lien."
                },
                "extranet_register": {
                    "success": "Inscription réussie, un lien de vérification vous a été envoyé par e-mail",
                    "form": {
                        "form_title": "Créez votre compte",
                        "choose_establishment": "Choisissez votre type d'établissement",
                        "validate_registration": "Finaliser mon inscription"
                    }
                },
                "social_networks": {
                    "authenticate": "Se connecter avec",
                    "no_email": "Aucun email n'est associé à votre réseau social, vous ne pouvez pas vous authentifier avec celui-ci",
                    "facebook": "Facebook",
                    "twitter": "Twitter",
                    "google": "Google"
                },
                "expiration": {
                    "session_expired": "Votre session a expirée, veuillez vous ré-authentifier"
                },
                "logout": {
                    "logging_out": "Déconnexion...",
                    "switch": "Basculer vers"
                },
                "travel_assistant": "Assistant de voyage gratuit"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "Compte bancaire non renseigné"
                    },
                    "lines": {
                        "no_account_yet": "Votre compte bancaire permettra à votre établissement de recevoir des paiements sur\n                UgoZER."
                    },
                    "form": {
                        "placeholders": {
                            "bank_transfer_reason": "Exemple: \"Pourboire UgoZER.\"",
                            "payee_name": "Nom du bénéficiaire ou nom de l'institution",
                            "bank_name": "Nom de la banque",
                            "bank_city": "Ville de l'agence de votre banque",
                            "bic": "Entrez votre BIC",
                            "types": "IBAN/BBAN",
                            "iban": "Entrez votre IBAN/BBAN"
                        }
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "customer": "client",
                    "date": "date",
                    "tip_sharing_form": "bordereau de partage des pourboires",
                    "tips_received": "pourboires encaissés",
                    "concierge": "concierge",
                    "amount": "montant",
                    "currency": "devise",
                    "total_received": "total encaissé",
                    "repartition_key": "clé de répartition",
                    "amount_to_pay": "montant à décaisser"
                }
            },
            "breadcrumbs": {
                "points_of_interest": {
                    "points_of_interest": "Points d'intérêt",
                    "add_excursion": "Ajouter à un itinéraire"
                },
                "excursions": {
                    "excursions": "Itinéraires",
                    "create": "Nouvel itinéraire"
                },
                "institutions": {
                    "institutions": "Etablissements",
                    "institution": "Etablissement"
                },
                "institutions_employees": {
                    "institutions_employees": "Mes employés",
                    "invite": "Inviter un employé"
                },
                "travellers": {
                    "travellers": "Voyageurs",
                    "folders": "Dossiers voyageur"
                },
                "reservations": {
                    "reservations": "Réservations"
                },
                "dashboard": {
                    "dashboard": "Tableau de bord"
                },
                "notification_settings": {
                    "notification_settings": "Paramètres de notifications"
                },
                "users": {
                    "users": "Utilisateurs",
                    "profile": "Mon profil",
                    "edit_credential": "Modifier l'identifiant"
                },
                "notifications": {
                    "notifications": "Notifications"
                },
                "finances": {
                    "finances": "Finances"
                },
                "invitations": {
                    "invitations": "Invitations"
                },
                "faq": {
                    "faq": "Foire aux questions"
                },
                "changelogs": {
                    "changelogs": "Notes de mise à jour"
                },
                "privacy_policy": {
                    "privacy_policy": "Charte de confidentialité"
                },
                "search": {
                    "search": "Recherche"
                },
                "terms_condition": {
                    "terms_condition": "Conditions générales"
                },
                "contacts": {
                    "contacts": "Contacts"
                },
                "partners": {
                    "partners": "Partenaires",
                    "customers": "Clients"
                },
                "interactions": {
                    "interactions": "Interactions"
                },
                "privacy_settings": {
                    "privacy_settings": "Paramètres de confidentialité"
                },
                "support_requests": {
                    "support_requests": "Demandes de contact",
                    "add_request": "Nouvelle demande de contact"
                },
                "support_messages": {
                    "support_messages": "Messages",
                    "add_message": "Ajouter un message"
                },
                "claims": {
                    "claims": "Revendications"
                },
                "tutorials": {
                    "begin": "Pour commencer"
                },
                "cookie": {
                    "cookie": "Gestion des cookies"
                },
                "credit": {
                    "credit": "Crédits"
                },
                "subscriptions": {
                    "subscription": "Abonnement"
                },
                "common": {
                    "create": "Nouveau",
                    "create_f": "Nouvelle",
                    "edit": "Modifier",
                    "share": "Partager",
                    "show": "Voir",
                    "add": "Ajouter",
                    "report": "Signaler",
                    "delete": "Supprimer",
                    "download_pdf": "Télécharger PDF",
                    "claim": "Rejoindre"
                },
                "errors": {
                    "404": {
                        "label": "Erreur 404",
                        "title": "Erreur 404 - Non trouvé(e)"
                    }
                }
            },
            "changelogs": {
                "publish": {
                    "success": "Changelog(s) publié(s) (si éligible)"
                },
                "draft": {
                    "success": "Changelog(s) mis en brouillon (si éligible)"
                },
                "archive": {
                    "success": "Changelog(s) archivé(s) (si éligible)"
                },
                "show": {
                    "version": "Version "
                },
                "index": {
                    "sections": {
                        "last": "Dernière mise à jour",
                        "all": "Toutes les mises à jour"
                    }
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "La contestation a été enregistrée"
                },
                "validated": {
                    "success": "La contestation a été validée"
                },
                "rejected": {
                    "success": "La contestation a été rejetée"
                }
            },
            "claims": {
                "notice": {
                    "pending": {
                        "title": "Revendication en cours",
                        "state": "Votre demande de revendication est actuellement en cours de traitement"
                    },
                    "rejected": {
                        "title": "Revendication rejetée",
                        "state": "Votre demande de revendication a été rejetée pour la raison suivante :"
                    },
                    "missing-info": {
                        "title": "En attente d'informations complémentaires",
                        "state": "Votre demande de revendication est actuellement en attente d'informations complémentaires \n                ou de documents :",
                        "infos": "Merci de compléter votre demande en cliquant sur le bouton ci-dessous."
                    },
                    "find_or_new": {
                        "title": "Rechercher ou créer un POI",
                        "description": "Recherchez un point d'intérêt dans la liste ci-dessous ou créez en un nouveau."
                    },
                    "already_claimed": {
                        "title": "Point d'intérêt déjà revendiqué !",
                        "description": "Ce point d'intérêt à déjà été revendiqué. Si vous pensez que c'est une erreur, vous\n                pouvez remplir le formulaire de contestation ci-dessous."
                    },
                    "actions": {
                        "edit": "Modifier ma demande"
                    }
                },
                "valid_identity_pieces": "Carte nationale d'identité (CNI), passeport ou permis de conduire en cours de \n        validité",
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "Revendication",
                            "poi": "Point d'intérêt",
                            "user": "Utilisateur"
                        }
                    }
                },
                "states": {
                    "pending": "En attente",
                    "missing-info": "Informations manquantes",
                    "rejected": "Rejetée",
                    "validated": "Validée",
                    "revoked": "Révoquée"
                },
                "update": {
                    "success": "La revendication a été modifiée"
                },
                "form_wizard": {
                    "steps": {
                        "context": "Contexte",
                        "documents": "Documents",
                        "info": "Informations",
                        "search": "Recherche",
                        "create": "Création",
                        "claim": "Revendication"
                    },
                    "documents": {
                        "FR": {
                            "company_registration_document": "Un extrait KBIS",
                            "company_statutes": "Les statuts",
                            "company_legal_representative_identity_piece": "Une pièce d'identité en cours de validité du \n                    représentant légal de la société : carte d'identité (recto et verso), passeport, titre de séjour \n                    (recto et verso), permis de conduire biométrique (recto et verso)",
                            "individual_first_identity_piece": "Une pièce d'identité en cours de validité : carte \n                    d'identité (recto et verso), passeport, titre de séjour (recto et verso), permis de conduire \n                    biométrique (recto et verso)",
                            "individual_second_identity_piece": "Une seconde pièce d'identité en cours de validité : carte \n                    d'identité (recto et verso), passeport, titre de séjour (recto et verso), permis de conduire \n                    (recto et verso), dernier avis d'imposition daté de moins d'un an, livret de famille ou \n                    récépissé d'enregistrement du pacte civil de solidarité",
                            "individual_registration_document": "Un certificat d'inscription à l'INSEE ou un document \n                    attestant de son inscription auprès de la Chambre de Commerce (pour un auto-entrepreneur exerçant \n                    une activité commerciale) ou auprès de la Chambre des métiers (pour un auto-entrepreneur exerçant \n                    une activité artisanale)",
                            "association_statutes": "Les statuts de l'association",
                            "association_legal_representative_identity_piece": "Une pièce d'identité en cours de validité du \n                    représentant légal de l'association : carte d'identité (recto et verso), passeport, titre de \n                    séjour (recto et verso), permis de conduire biométrique (recto et verso)"
                        },
                        "DE": {
                            "company_registration_document": "Handelsregisterauszug",
                            "company_statutes": "Aktuelle und unterschriebene (von der Geschäftsführung) Gesellschaftverträge",
                            "company_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere \n                    Version mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_first_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere Version mit \n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_second_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere Version mit \n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_registration_document": "Gewerbeschein, Handlungsbevollmächtigung, Handelsregisterauszug, \n                    Prokura, Steuernummer attribution letter",
                            "association_statutes": "Handelsregisterauszug",
                            "association_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere \n                    Version mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)"
                        },
                        "ES": {
                            "company_registration_document": "Extracto del Registro Mercantil Central, Nota Simple del Registro \n                    mercantil local sellado o numerico, Extracto desde los Registradores de España (informacion \n                    General Mercantil) con fecha de validez de menos de 3 meses o Modelo 600 con fecha de validez \n                    de menos de 3 meses (solo para sociedad civil)",
                            "company_statutes": "Escrituras de constitución o Estatutos sociales",
                            "company_legal_representative_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso \n                    de conducir (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un país \n                    europeo)",
                            "individual_first_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso de conducir \n                    (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un país europeo)",
                            "individual_second_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso de conducir \n                    (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un país europeo)",
                            "individual_registration_document": "Agencia Tributaria - Certificado de situacion Censal \n                    (Alta Censal)",
                            "association_statutes": "Escrituras de constitución o Estatutos sociales",
                            "association_legal_representative_identity_piece": "Pasaporte, Documento nacional de identidad, \n                    Permiso de conducir (versión reciente con líneas MRZ) o Permiso de residencia (solo si es de un \n                    país europeo)"
                        },
                        "IT": {
                            "company_registration_document": "Visura Camerale",
                            "company_statutes": "Statuto firmato dal Legale Rappresentante (se non incluso all'interno della \n                    Visura Camerale)",
                            "company_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, La patente \n                    di guida (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "individual_first_identity_piece": "Passaporto, Carta d'identità nazionale, La patente di guida \n                    (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "individual_second_identity_piece": "Passaporto, Carta d'identità nazionale, La patente di guida \n                    (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "individual_registration_document": "Estratto dal registro delle imprese (\"Visura Camerale\") di meno \n                    di 3 mesi",
                            "association_statutes": "tatuto firmato dal Legale Rappresentante (se non incluso all'interno della \n                    Visura Camerale",
                            "association_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, La \n                    patente di guida (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese \n                    europeo)"
                        },
                        "TH": {
                            "company_registration_document": "สารสกัดจาก KBIS",
                            "company_statutes": "สถานะต่างๆ",
                            "company_legal_representative_identity_piece": "เอกสารประจำตัวที่ถูกต้องจากตัวแทนทางกฎหมายของบริษัท: \n                    บัตรประจำตัวประชาชน (ด้านหน้าและด้านหลัง), หนังสือเดินทาง,\n                    ใบอนุญาตมีถิ่นที่อยู่ (ด้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ด้านหน้าและด้านหลัง)",
                            "individual_first_identity_piece": "เอกสารแสดงตนที่ถูกต้องฉบับแรก: บัตรประจำตัวประชาชน\n                    (ทั้งด้านหน้าและด้านหลัง), หนังสือเดินทาง, ใบอนุญาตมีถิ่นที่อยู่ (ทั้งด้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านหลัง)",
                            "individual_second_identity_piece": "เอกสารประจำตัวที่สองที่ถูกต้อง: บัตรประจำตัวประชาชน\n                    (ด้านหน้าและด้านหลัง) หนังสือเดินทาง ใบอนุญาตมีถิ่นที่อยู่\n                    (ด้านหน้าและด้านหลัง) ใบขับขี่ (ด้านหน้าและด้านหลัง)\n                    ประกาศภาษีครั้งสุดท้ายลงวันที่น้อยกว่าหนึ่งปี สมุดบันทึกครอบครัวหรือใบเสร็จรับเงินสำหรับการจดทะเบียน\n                    สนธิสัญญาความสามัคคีของพลเมือง",
                            "individual_registration_document": "หนังสือรับรองการจดทะเบียนกับอินทรีหรือเอกสารรับรองการจดทะเบียนกับหอการค้า\n                    (สำหรับผู้ประกอบอาชีพอิสระที่ดำเนินกิจการเชิงพาณิชย์)\n                    หรือกับหอการค้า (สำหรับผู้ประกอบอาชีพอิสระที่ดำเนินกิจการเชิงพาณิชย์)\n                    กิจกรรมงานฝีมือ)",
                            "association_statutes": "กฎเกณฑ์ของสมาคม",
                            "association_legal_representative_identity_piece": "เอกสารประจำตัวที่ถูกต้องจากตัวแทนทางกฎหมายของสมาคม:\n                    บัตรประจำตัวประชาชน (ทั้งด้านหน้าและด้านหลัง),\n                    หนังสือเดินทาง, ใบอนุญาตมีถิ่นที่อยู่ (ทั้งด้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านหลัง)"
                        }
                    }
                },
                "validated": {
                    "success": "La revendication a été validée"
                },
                "rejected": {
                    "success": "La revendication a été rejetée"
                },
                "missing_info": {
                    "success": "La revendication a été rejetée pour cause d'informations manquantes"
                },
                "revoked": {
                    "success": "La revendication a été révoquée"
                }
            },
            "common": {
                "app_name": "UgoZER",
                "actions": {
                    "add": "Ajouter",
                    "add_message": "Ajouter un message",
                    "attach": "Joindre",
                    "archive": "Archiver",
                    "cancel": "Annuler",
                    "check": "Vérifier",
                    "confirm": "Confirmer",
                    "contest": "Contester",
                    "create": "Créer",
                    "delete": "Supprimer",
                    "edit": "Modifier",
                    "login": "Se connecter",
                    "register": "S'inscrire",
                    "load_more": "Voir plus",
                    "add_contact": "Ajouter aux contacts",
                    "accept_contact": "Accepter la demande",
                    "decline_contact": "Refuser la demande",
                    "download": "Télécharger",
                    "block_contact": "Bloquer le contact",
                    "unblock_contact": "Débloquer le contact",
                    "unblock_this_user": "Débloquer cet utilisateur",
                    "add_excursion": "Ajouter à un itinéraire",
                    "share": "Partager",
                    "show": "Consulter",
                    "approve": "Approuver",
                    "decline": "Refuser",
                    "configure": "Paramétrer",
                    "update": "Mettre à jour",
                    "ignore": "Ignorer",
                    "quit": "Quitter",
                    "google_maps": "Ouvrir dans Google Maps",
                    "play": "Jouer",
                    "pause": "Interrompre",
                    "stop": "Arrêter",
                    "start": "Démarrer",
                    "record": "Enregistrer",
                    "report": "Signaler",
                    "return": "Retour",
                    "resume": "Reprendre",
                    "save": "Enregistrer",
                    "save_alt": "Sauvegarder",
                    "filters": "Filtres",
                    "apply": "Appliquer",
                    "reset": "Réinitialiser",
                    "give_a_tip": "Pourboire",
                    "pay": "Payer",
                    "validate": "Valider",
                    "thank": "Remercier",
                    "hide": "Masquer",
                    "see_website": "Voir le site web",
                    "close": "Fermer",
                    "send_link": "Envoyer lien de l'app",
                    "accept": "Accepter",
                    "search": "Rechercher",
                    "logout": "Se déconnecter",
                    "thank_for_stay": "Remercier pour le séjour",
                    "interactions": "Voir les interactions",
                    "claim": "Rejoindre",
                    "print": "Imprimer",
                    "invite": "Inviter",
                    "manage": "Gérer",
                    "request_to_join": "Demander à rejoindre",
                    "send": "Envoyer",
                    "file_preview": "Visualiser le fichier",
                    "next": "Suivant",
                    "help": "Aide",
                    "subscribe": "S'abonner",
                    "traveller_show": "Consulter le voyageur",
                    "see_plans": "Voir les formules",
                    "retry": "Réessayer"
                },
                "errors": {
                    "internal": "Il y a eu un problème !",
                    "not_found": "Non trouvé",
                    "deleted_user": "Utilisateur supprimé"
                },
                "online": {
                    "disconnected": "Vous n'êtes plus connecté à Internet",
                    "connected": "Vous êtes de nouveau connecté à Internet"
                },
                "units": {
                    "km": "km",
                    "kilometer": "kilomètre | kilomètres",
                    "mi": "mi",
                    "mile": "mile | miles"
                },
                "messages": {
                    "clipboard": {
                        "success": "Copié dans le presse-papier !",
                        "error": "Erreur pendant la copie dans le presse papier..."
                    },
                    "no_records": "Aucun enregistrement trouvé",
                    "application_update": {
                        "new_version_available": "Une nouvelle version est disponible.",
                        "want_to_refresh": "Voulez-vous redémarrer l'application pour la mettre à jour ?",
                        "if_ignore": "Si vous ignorez la mise à jour, celle-ci s'effectuera au prochain démarrage de l'application.",
                        "updating": "Mise à jour..."
                    }
                },
                "dashboard": "Tableau de bord",
                "home": "Accueil",
                "back_to_home": "Retour à l'accueil",
                "back_to_dashboard": "Retour au tableau de bord",
                "welcome": "Bienvenue",
                "and": "et",
                "no": "non",
                "yes": "oui",
                "true": "oui",
                "false": "non",
                "search": "Recherche",
                "someone": "quelqu'un",
                "not_specified": "Non spécifié(e)",
                "loading": "chargement",
                "oops": "oups",
                "none": "aucun",
                "all": "tous",
                "or": "ou",
                "tip": "Pourboire",
                "tip_received": "Pourboire perçu (si fonctionnalité activée)",
                "counter": "{dividend} sur {divisor}",
                "slow": "lent",
                "normal": "normal",
                "fast": "rapide",
                "characters_limit": "{length} / {limit} caractères",
                "later": "plus tard",
                "more_options": "plus d'options",
                "sort": "trier",
                "qr_code": "QR code",
                "missing_title": "Titre manquant",
                "country": "pays"
            },
            "cookies": {
                "management": {
                    "title": "Gestion des cookies",
                    "content": {
                        "line_1": "Certaines fonctionnalités de ce site reposent sur l'usage de cookies.",
                        "line_2": "Le bandeau cookies ne s’affiche pas sur la page d'accueil lors de votre navigation si seuls \n                des cookies nécessaires au fonctionnement du site sont déposés.",
                        "line_3": "Vous pouvez modifier vos préférences en cliquant sur le bouton ci-dessous :",
                        "cta": "Modifier mes préférences"
                    }
                },
                "usage": {
                    "title": "À quoi servent les cookies ?",
                    "content": "Les cookies peuvent être utilisés pour vous reconnaître lorsque vous visitez UgoZER, se souvenir\n            de vos préférences et vous offrir une expérience personnalisée qui correspond à vos paramètres. Les\n            cookies rendent également vos interactions avec UgoZER plus rapides et plus sûres. En outre, les cookies\n            nous permettent de vous proposer de la publicité à la fois sur les sites UgoZER et en dehors.",
                    "table": {
                        "headers": {
                            "category": "Catégorie d'utilisation",
                            "description": "Description"
                        },
                        "lines": {
                            "authentication": {
                                "title": "Authentification",
                                "content": "Si vous êtes connecté à UgoZER, les cookies nous aident à vous montrer les bonnes\n                        informations et à personnaliser votre expérience."
                            },
                            "security": {
                                "title": "Securité",
                                "content": "Nous utilisons des cookies pour activer et prendre en charge nos fonctions de \n                        sécurité, et pour nous aider à détecter les activités malveillantes et les violations de \n                        nos",
                                "cta": "Conditions d'utilisation."
                            },
                            "services": {
                                "title": "Préférences, fonctionnalités et services",
                                "content": "Les cookies vous permettent de bénéficier de fonctionnalités, d'informations et de\n                        contenus personnalisés."
                            },
                            "ads": {
                                "title": "Publicité",
                                "content": "Nous pouvons utiliser des cookies pour vous montrer des publicités pertinentes à la\n                        fois sur le site UgoZER et en dehors. Nous pouvons également utiliser un cookie pour savoir\n                        si une personne ayant vu une publicité a ensuite visité et effectué une action (par exemple,\n                        télécharger un livre blanc ou effectuer un achat) sur le site de l'annonceur. De même,\n                        nos partenaires peuvent utiliser un cookie pour déterminer si nous avons diffusé une\n                        publicité et comment elle s'est comportée, ou nous fournir des informations sur la façon\n                        dont vous interagissez avec les publicités. Nous pouvons également travailler avec un\n                        partenaire pour vous montrer une publicité sur ou hors UgoZER, par exemple après que vous\n                        ayez visité le site ou l'application d'un partenaire."
                            }
                        }
                    }
                },
                "details": {
                    "title": "Détail des cookies déposés",
                    "content": "Voici la liste des cookies déposés sur cette section de l'application UgoZER basée sur les\n            catégories définies précédemment :",
                    "table": {
                        "headers": {
                            "name": "Nom du cookie (ou motif)",
                            "category": "Catégorie d'utilisation",
                            "description": "Description",
                            "mandatory": "Obligatoire",
                            "lifespan": "Durée de vie"
                        },
                        "lines": {
                            "session": {
                                "title": "*_ugozer_session",
                                "content": "Contient des informations relatives à la session.",
                                "lifespan": "2 heures"
                            },
                            "xsrf": {
                                "title": "XSRF-TOKEN-*",
                                "content": "Permet de sécuriser l'application (requêtes, formulaires...).",
                                "lifespan": "2 heures"
                            },
                            "alphanumeric": {
                                "title": "[[{alnum}:]] (suite de caractères alphanumériques)",
                                "content": "Contient des informations relatives à la session.",
                                "lifespan": "2 heures"
                            },
                            "tarteaucitron": {
                                "title": "tarteaucitron",
                                "content": "Permet de conserver les paramètres de l'utilisateur concernant les cookies.",
                                "lifespan": "365 jours"
                            },
                            "gmaps": {
                                "title": "CONSENT, AEC, SOCS... (ou autres cookies du domaine google.com)",
                                "content": "Utilisé pour afficher l'iframe Google Maps.",
                                "lifespan": "180-400 jours"
                            },
                            "remember": {
                                "title": "remember_web_*",
                                "content": "Utilisé pour garder l'utilisateur authentifié.",
                                "lifespan": "400 jours"
                            }
                        }
                    }
                },
                "notice": {
                    "title": "Cookies",
                    "needed": "Pour voir cette section, vous devez accepter les cookies {name}.",
                    "refresh": "Si vous ne la voyez pas après avoir accepté les cookies, veuillez rafraichir la page."
                },
                "services": {
                    "gmaps": "Google Maps",
                    "youtube": "YouTube"
                }
            },
            "countries": {
                "AF": "Afghanistan",
                "ZA": "Afrique du Sud",
                "AL": "Albanie",
                "DZ": "Algérie",
                "DE": "Allemagne",
                "AD": "Andorre",
                "AO": "Angola",
                "AI": "Anguilla",
                "AQ": "Antarctique",
                "AG": "Antigua-et-Barbuda",
                "SA": "Arabie saoudite",
                "AR": "Argentine",
                "AM": "Arménie",
                "AW": "Aruba",
                "AU": "Australie",
                "AT": "Autriche",
                "AZ": "Azerbaïdjan",
                "BS": "Bahamas",
                "BH": "Bahreïn",
                "BD": "Bangladesh",
                "BB": "Barbade",
                "BE": "Belgique",
                "BZ": "Belize",
                "BJ": "Bénin",
                "BM": "Bermudes",
                "BT": "Bhoutan",
                "BY": "Biélorussie",
                "BO": "Bolivie",
                "BA": "Bosnie-Herzégovine",
                "BW": "Botswana",
                "BR": "Brésil",
                "BN": "Brunéi Darussalam",
                "BG": "Bulgarie",
                "BF": "Burkina Faso",
                "BI": "Burundi",
                "KH": "Cambodge",
                "CM": "Cameroun",
                "CA": "Canada",
                "CV": "Cap-Vert",
                "CL": "Chili",
                "CN": "Chine",
                "CY": "Chypre",
                "CO": "Colombie",
                "KM": "Comores",
                "CG": "Congo-Brazzaville",
                "CD": "Congo-Kinshasa",
                "KP": "Corée du Nord",
                "KR": "Corée du Sud",
                "CR": "Costa Rica",
                "CI": "Côte d’Ivoire",
                "HR": "Croatie",
                "CU": "Cuba",
                "CW": "Curaçao",
                "DK": "Danemark",
                "DJ": "Djibouti",
                "DM": "Dominique",
                "EG": "Égypte",
                "AE": "Émirats arabes unis",
                "EC": "Équateur",
                "ER": "Érythrée",
                "ES": "Espagne",
                "EE": "Estonie",
                "SZ": "Eswatini",
                "VA": "État de la Cité du Vatican",
                "FM": "États fédérés de Micronésie",
                "US": "États-Unis",
                "ET": "Éthiopie",
                "FJ": "Fidji",
                "FI": "Finlande",
                "FR": "France",
                "GA": "Gabon",
                "GM": "Gambie",
                "GE": "Géorgie",
                "GS": "Géorgie du Sud et îles Sandwich du Sud",
                "GH": "Ghana",
                "GI": "Gibraltar",
                "GR": "Grèce",
                "GD": "Grenade",
                "GL": "Groenland",
                "GP": "Guadeloupe",
                "GU": "Guam",
                "GT": "Guatemala",
                "GG": "Guernesey",
                "GN": "Guinée",
                "GQ": "Guinée équatoriale",
                "GW": "Guinée-Bissau",
                "GY": "Guyana",
                "GF": "Guyane française",
                "HT": "Haïti",
                "HN": "Honduras",
                "HU": "Hongrie",
                "BV": "Île Bouvet",
                "CX": "Île Christmas",
                "IM": "Île de Man",
                "NF": "Île Norfolk",
                "AX": "Îles Åland",
                "KY": "Îles Caïmans",
                "CC": "Îles Cocos",
                "CK": "Îles Cook",
                "FO": "Îles Féroé",
                "HM": "Îles Heard et McDonald",
                "FK": "Îles Malouines",
                "MP": "Îles Mariannes du Nord",
                "MH": "Îles Marshall",
                "UM": "Îles mineures éloignées des États-Unis",
                "PN": "Îles Pitcairn",
                "SB": "Îles Salomon",
                "TC": "Îles Turques-et-Caïques",
                "VG": "Îles Vierges britanniques",
                "VI": "Îles Vierges des États-Unis",
                "IN": "Inde",
                "ID": "Indonésie",
                "IQ": "Irak",
                "IR": "Iran",
                "IE": "Irlande",
                "IS": "Islande",
                "IL": "Israël",
                "IT": "Italie",
                "JM": "Jamaïque",
                "JP": "Japon",
                "JE": "Jersey",
                "JO": "Jordanie",
                "KZ": "Kazakhstan",
                "KE": "Kenya",
                "KG": "Kirghizistan",
                "KI": "Kiribati",
                "KW": "Koweït",
                "RE": "La Réunion",
                "LA": "Laos",
                "LS": "Lesotho",
                "LV": "Lettonie",
                "LB": "Liban",
                "LR": "Libéria",
                "LY": "Libye",
                "LI": "Liechtenstein",
                "LT": "Lituanie",
                "LU": "Luxembourg",
                "MK": "Macédoine du Nord",
                "MG": "Madagascar",
                "MY": "Malaisie",
                "MW": "Malawi",
                "MV": "Maldives",
                "ML": "Mali",
                "MT": "Malte",
                "MA": "Maroc",
                "MQ": "Martinique",
                "MU": "Maurice",
                "MR": "Mauritanie",
                "YT": "Mayotte",
                "MX": "Mexique",
                "MD": "Moldavie",
                "MC": "Monaco",
                "MN": "Mongolie",
                "ME": "Monténégro",
                "MS": "Montserrat",
                "MZ": "Mozambique",
                "MM": "Myanmar (Birmanie)",
                "NA": "Namibie",
                "NR": "Nauru",
                "NP": "Népal",
                "NI": "Nicaragua",
                "NE": "Niger",
                "NG": "Nigéria",
                "NU": "Niue",
                "NO": "Norvège",
                "NC": "Nouvelle-Calédonie",
                "NZ": "Nouvelle-Zélande",
                "OM": "Oman",
                "UG": "Ouganda",
                "UZ": "Ouzbékistan",
                "PK": "Pakistan",
                "PW": "Palaos",
                "PA": "Panama",
                "PG": "Papouasie-Nouvelle-Guinée",
                "PY": "Paraguay",
                "NL": "Pays-Bas",
                "BQ": "Pays-Bas caribéens",
                "PE": "Pérou",
                "PH": "Philippines",
                "PL": "Pologne",
                "PF": "Polynésie française",
                "PR": "Porto Rico",
                "PT": "Portugal",
                "QA": "Qatar",
                "HK": "R.A.S. chinoise de Hong Kong",
                "MO": "R.A.S. chinoise de Macao",
                "CF": "République centrafricaine",
                "DO": "République dominicaine",
                "RO": "Roumanie",
                "GB": "Royaume-Uni",
                "RU": "Russie",
                "RW": "Rwanda",
                "EH": "Sahara occidental",
                "BL": "Saint-Barthélemy",
                "KN": "Saint-Christophe-et-Niévès",
                "SM": "Saint-Marin",
                "MF": "Saint-Martin",
                "SX": "Saint-Martin (partie néerlandaise)",
                "PM": "Saint-Pierre-et-Miquelon",
                "VC": "Saint-Vincent-et-les-Grenadines",
                "SH": "Sainte-Hélène",
                "LC": "Sainte-Lucie",
                "SV": "Salvador",
                "WS": "Samoa",
                "AS": "Samoa américaines",
                "ST": "Sao Tomé-et-Principe",
                "SN": "Sénégal",
                "RS": "Serbie",
                "SC": "Seychelles",
                "SL": "Sierra Leone",
                "SG": "Singapour",
                "SK": "Slovaquie",
                "SI": "Slovénie",
                "SO": "Somalie",
                "SD": "Soudan",
                "SS": "Soudan du Sud",
                "LK": "Sri Lanka",
                "SE": "Suède",
                "CH": "Suisse",
                "SR": "Suriname",
                "SJ": "Svalbard et Jan Mayen",
                "SY": "Syrie",
                "TJ": "Tadjikistan",
                "TW": "Taïwan",
                "TZ": "Tanzanie",
                "TD": "Tchad",
                "CZ": "Tchéquie",
                "TF": "Terres australes françaises",
                "IO": "Territoire britannique de l’océan Indien",
                "PS": "Territoires palestiniens",
                "TH": "Thaïlande",
                "TL": "Timor oriental",
                "TG": "Togo",
                "TK": "Tokelau",
                "TO": "Tonga",
                "TT": "Trinité-et-Tobago",
                "TN": "Tunisie",
                "TM": "Turkménistan",
                "TR": "Turquie",
                "TV": "Tuvalu",
                "UA": "Ukraine",
                "UY": "Uruguay",
                "VU": "Vanuatu",
                "VE": "Venezuela",
                "VN": "Vietnam",
                "WF": "Wallis-et-Futuna",
                "XK": "Kosovo",
                "YE": "Yémen",
                "ZM": "Zambie",
                "ZW": "Zimbabwe"
            },
            "credits": {
                "images": "Crédits images"
            },
            "csv": {
                "common": {
                    "generating": "Fichier CSV en cours de création...",
                    "waiting": "Veuillez patienter..."
                },
                "download": "Export CSV"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "Les favoris de votre expert local"
                },
                "points_of_interest": {
                    "categories": "Catégories de points d'intérêt"
                },
                "no_dashboard": {
                    "title": "Aucun tableau de bord disponible",
                    "content": "Aucun dashboard n'a été paramétré pour votre rôle ou vous êtes connecté avec un compte administrateur.",
                    "contact": {
                        "text": "Si vous pensez que c'est une erreur, n'hésitez pas à",
                        "link": "nous contacter."
                    }
                },
                "links": {
                    "closest_pois": "Points proches",
                    "excursions": "Itinéraires et promenades",
                    "shares": "Partages"
                }
            },
            "documents": {
                "display": {
                    "title": "Présentoir - fiche A6",
                    "description": "Présentoir sous format A6 avec QRCode de liaison et le logo de votre établissement."
                },
                "qrcode": {
                    "title": "QRCode de liaison",
                    "description": "QRCode seul permettant de lier directement le voyageur à votre établissement.",
                    "filename": "qrcode"
                }
            },
            "easy_transac": {
                "easy_transac": "EasyTransac",
                "account": {
                    "create": {
                        "button": "Créer un compte EasyTransac",
                        "success": "Votre compte EasyTransac a été créé avec succès",
                        "fail": "La connexion à EasyTransac a échouée, veuillez réessayer ultérieurement"
                    },
                    "show": {
                        "no_account_yet": "Vous n'avez pas encore de compte EasyTransac.",
                        "account_usage": "Votre compte EasyTransac permettra à votre établissement de recevoir\n                des paiements sur UgoZER.",
                        "can_receive_payments": "Vos clients peuvent maintenant verser des pourboires à votre établissement\n                depuis le profil UgoZER de vos concierges.",
                        "fee_percentage": "Des frais seront appliqués à chaque paiement que vous recevrez.",
                        "cannot_receive_payments": "Vous ne pouvez pas recevoir de paiements",
                        "go_to_easy_transac": "Accéder au tableau de bord EasyTransac",
                        "credentials_sent_mail": "Vos identifiants EasyTransac vous ont été envoyés par email.",
                        "should_complete_kyc": "Merci de faire parvenir les documents demandés par EasyTransac,\n                afin de pouvoir recevoir des paiements.",
                        "can_retrieve_payments": {
                            "title": "Votre compte EasyTransac est incomplet",
                            "description": "Vous devez compléter votre compte EasyTransac avec les informations demandées, afin \n                    de pouvoir utiliser les fonds disponibles sur ce dernier."
                        },
                        "must_subscribe": "Vous devez être abonné pour pouvoir créer votre compte EasyTransac."
                    }
                }
            },
            "exceptions": {
                "401": "Non authentifié",
                "403": "Accès refusé",
                "404": "Ressource non trouvée",
                "408": "Expiré",
                "422": "Requête ne peut aboutir",
                "429": "Trop de requêtes",
                "500": "Erreur serveur interne",
                "default": "Une erreur est survenue",
                "error_texts": {
                    "403": {
                        "title": "Oups ! Accès refusé.",
                        "message": "Vous n'avez pas accès à cette page.",
                        "link": {
                            "title": "Vous vouliez peut-être accéder à la",
                            "to_app_label": "section voyageur ?",
                            "to_extranet_label": "l'espace professionnel ?"
                        }
                    },
                    "credential_not_verified": {
                        "title": "Votre identifiant n'est pas vérifié",
                        "verify_mail": "Recevoir le lien de vérification pour mon e-mail",
                        "verify_phone": "Recevoir le lien de vérification pour mon numéro de téléphone"
                    },
                    "password_missing": {
                        "extranet": "Merci de définir un mot de passe pour accéder à l'application.",
                        "app": "Merci de définir un mot de passe."
                    },
                    "opening_hours": {
                        "invalid": "La valeur donnée n'est pas une instance de OpeningHours."
                    }
                },
                "spam_detected": "Spam détecté"
            },
            "excursions": {
                "destroy": {
                    "confirm": "Êtes-vous sur(e) de vouloir supprimer cet itinéraire : {excursion_name} ?",
                    "success": "L'itinéraire a été supprimé"
                },
                "store": {
                    "create": "Créer un itinéraire",
                    "success": "L'itinéraire a été créé",
                    "heading": "Créer un itinéraire"
                },
                "update": {
                    "success": "L'itinéraire a été modifié",
                    "heading": "Modifier cet itinéraire"
                },
                "index": {
                    "your_excursions": "Vos itinéraires",
                    "no_results_here": "Aucun itinéraire n'est disponible dans votre zone de recherche.",
                    "try_more_filters": "Vous pouvez élargir votre recherche ou créer un itinéraire.",
                    "no_results": "Vous n’avez pas encore d’itinéraires",
                    "search": "Rechercher un itinéraire",
                    "read_only": "Consultation uniquement",
                    "alert": {
                        "title": "Itinéraires en consultation uniquement",
                        "content": "Vous pouvez uniquement consulter les itinéraires. Si vous souhaitez créer ou partager un\n                itinéraire, vous devez vous connecter avec un <strong>compte concierge / réceptionniste</strong>"
                    }
                },
                "search": {
                    "no_results": "Aucun itinéraire trouvé"
                },
                "share": {
                    "heading": "Partager cet itinéraire",
                    "select_which_users": "Sélectionnez les utilisateurs avec qui vous voulez partager l'itinéraire \n            \"{excursion_title}\" ou saisissez une adresse e-mail pour inviter un nouveau voyageur",
                    "select_which_users_alt": "Saisissez l'adresse e-mail de la personne avec qui vous voulez partager l'\n            itinéraire \"{excursion_title}\"",
                    "select_which_contacts": "Sélectionnez les contacts avec qui vous voulez partager l'itinéraire\n            \"{excursion_title}\"",
                    "success": "L'itinéraire a été partagé",
                    "contains_private": "Cet itinéraire contient des points d'intérêts privés. Ils peuvent ne pas être inclus dans le partage."
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "Itinéraire",
                            "pois": "Points d'intérêt"
                        }
                    }
                },
                "form": {
                    "all_points_of_interest": "Tous les points d'intérêts",
                    "select_from_points_of_interest": "Sélectionner parmi des points d’intérêts",
                    "from_existing_excursions": "Depuis un itinéraire existant",
                    "create_new_point_of_interest": "Créer un nouveau point d’intérêt",
                    "your_excursion": "Votre itinéraire",
                    "order_hint": "L'ordre des points d'intérêts correspond à l'ordre de visite.",
                    "points_of_interest": "Points d'intérêts",
                    "existing_excursions": "Itinéraires existants"
                },
                "direction": {
                    "display_mode": "Mode d'affichage",
                    "driving": "en voiture",
                    "bicycling": "en vélo",
                    "walking": "à pied"
                }
            },
            "extranet": {
                "professional_section": "Espace pro",
                "navbar": {
                    "dashboard": "Tableau de bord",
                    "my_institutions": "Mes établissements",
                    "my_institution": "Mon établissement",
                    "my_employees": "Mes employés",
                    "institutions": "Établissements",
                    "travellers": "Voyageurs",
                    "points_of_interest": "Points d'intérêt",
                    "excursions": "Itinéraires",
                    "finances": "Finances",
                    "invitations": "Invitations",
                    "contacts": "Contacts",
                    "faq": "FAQ",
                    "contact": "Nous contacter",
                    "tutorials": "Commencer",
                    "users": "Utilisateurs"
                },
                "institution": {
                    "sections": {
                        "butler": {
                            "institution": "Mon établissement"
                        },
                        "manager": {
                            "institution": "Mes établissements"
                        }
                    },
                    "messages": {
                        "no_records": "Aucun établissement lié pour le moment"
                    }
                },
                "pages": {
                    "folders": {
                        "new": "Nouveau dossier voyageur",
                        "edit": "Modifier dossier voyageur"
                    },
                    "institutions": {
                        "new": "Lier un établissement"
                    },
                    "profile": {
                        "edit": "Modifier profil"
                    },
                    "pois": {
                        "new": "Nouveau point d'intérêt",
                        "edit": "Modifier point d'intérêt"
                    },
                    "excursions": {
                        "new": "Nouvel itinéraire",
                        "edit": "Modifier l'itinéraire"
                    },
                    "reservations": {
                        "new": "Nouvelle réservation",
                        "edit": "Modifier la réservation"
                    }
                },
                "travellers": {
                    "sections": {
                        "folders": "Mes dossiers voyageurs"
                    },
                    "buttons": {
                        "new_folder": "Créer un dossier voyageur"
                    }
                },
                "user_dropdown": {
                    "profile": "Mon profil",
                    "subscription": "Abonnement",
                    "sign_out": "Déconnexion",
                    "privacy_policy": "Charte de confidentialité",
                    "terms_condition": "Conditions générales",
                    "cookies": "Gestion des cookies",
                    "credits": "Crédits",
                    "changelogs": "Notes de mise à jour",
                    "partners": "Nos partenaires",
                    "customers": "Nos clients"
                },
                "dashboard": {
                    "sections": {
                        "stats": "Statistiques",
                        "last_travellers": "Vos derniers voyageurs",
                        "last_payments": "Vos derniers paiements",
                        "search_butler": "Rechercher un concierge",
                        "last_claims": "Vos dernières revendications",
                        "last_reservations": "Vos dernières réservations"
                    },
                    "stats": {
                        "institutions": "Établissement lié|Établissements liés",
                        "folders": "Dossier|Dossiers",
                        "excursions": "Itinéraire|Itinéraires",
                        "processing_claims": "Revendication en cours|Revendications en cours",
                        "reservations": "Réservation|Réservations",
                        "butlers": "Concierge|Concierges",
                        "employees": "Employé(e)|Employé(e)s"
                    },
                    "manager": {
                        "sections": {
                            "butlers_interactions": "Concierges avec interactions",
                            "butlers_institutions": "Concierges de mes établissements"
                        }
                    },
                    "last_traveller_folders": {
                        "none": "Vous n'avez pas encore de voyageurs à votre charge.",
                        "take_care": "Prendre en charge un voyageur",
                        "see_all": "Voir tous vos voyageurs"
                    },
                    "last_payments": {
                        "none": "Vous n’avez pas encore de paiements.",
                        "see_index": "Accéder à la page des paiements",
                        "see_all": "Accéder à tous vos paiements"
                    },
                    "last_claims": {
                        "none": "Vous n’avez pas encore de revendications.",
                        "see_index": "Accéder à la page des revendications",
                        "see_all": "Accéder à toutes vos revendications"
                    },
                    "last_reservations": {
                        "none": "Vous n’avez pas encore de réservations."
                    }
                },
                "points_of_interest": {
                    "sections": {
                        "points_of_interest": "Points d'intérêt"
                    },
                    "actions": {
                        "new": "Créer un point d'intérêt"
                    }
                },
                "excursions": {
                    "sections": {
                        "excursions": "Itinéraires"
                    },
                    "actions": {
                        "new": "Créer un itinéraire"
                    }
                },
                "profile": {
                    "sections": {
                        "infos": "Informations générales",
                        "notification_settings": "Paramètres de notifications",
                        "delete_account": "Suppression du compte",
                        "privacy_settings": "Paramètres de confidentialité",
                        "currency": "Devise",
                        "locale": "Langue",
                        "shares": "Partages",
                        "payment": "Paiement"
                    }
                },
                "filters": {
                    "open": "Afficher les filtres",
                    "closed": "Masquer les filtres"
                },
                "finances": {
                    "sections": {
                        "received_payments": "Paiements reçus",
                        "received_by_institution_payments": "Paiements reçus par vos établissements",
                        "issued_payments": "Paiements émis"
                    },
                    "alerts": {
                        "easytransac": {
                            "title": "Compte EasyTransac inexistant !",
                            "description": "Vous n'avez pas encore créé de compte EasyTransac ! Vous ne pouvez donc pas\n                    recevoir de paiements.",
                            "cta": "Accéder à mon profil pour créer un compte EasyTransac"
                        }
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "Catégories"
                    }
                },
                "support_requests": {
                    "sections": {
                        "support_requests": "Demande de contact"
                    },
                    "actions": {
                        "new": "Créer une demande de contact",
                        "back": "Retour"
                    }
                },
                "support_messages": {
                    "sections": {
                        "support_messages": "Messages"
                    },
                    "actions": {
                        "new": "Ajouter un nouveau message",
                        "back": "Retourner à la demande"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "Aucune foire aux questions pour le moment"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "Inviter un employé",
                        "new-hotelier": "Créer un compte réception",
                        "new-tourist-office": "Créer un compte accueil"
                    }
                }
            },
            "form": {
                "titles": {
                    "verifications": "Vérification des informations"
                },
                "labels": {
                    "user": {
                        "credential": "e-mail",
                        "new_password": "nouveau mot de passe",
                        "notes": "informations complémentaires",
                        "image": {
                            "_destroy": "Supprimer la photo de profil"
                        },
                        "email": "e-mail"
                    },
                    "geoloc_setting": {
                        "measure_unit": "unité de mesure",
                        "within_a_radius": "dans un rayon",
                        "within_a_radius_from_center": "dans un rayon depuis le centre",
                        "limit_to_a_radius": "limiter à un rayon",
                        "limit_to_a_radius_from_center": "limiter à un rayon depuis le centre",
                        "where_would_you_like_to_go": "où souhaitez-vous aller ?",
                        "where_does_the_traveller_go": "où le voyageur va-t’il ??",
                        "distance_filter": "filtrer par distance",
                        "other_filters": "autres filtres"
                    },
                    "payment": {
                        "payable_type": "Paiment lié à"
                    },
                    "points_of_interest_filters": {
                        "sort": "tri",
                        "geoloc": "lieu",
                        "tags": "affiner",
                        "order_relevance": "par pertinence (mots-clés de recherche)",
                        "order_distance": "par distance"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "Actualiser automatiquement le fichier audio",
                        "opening": "ouverture",
                        "opening_specification_add": "ajouter une période",
                        "opening_schedule_add": "préciser des jours ou horaires",
                        "opening_schedule_add_alt": "préciser des horaires",
                        "opening_date": "le",
                        "opening_date_range": "préciser une date de fin",
                        "opening_date_from": "du",
                        "opening_date_to": "au",
                        "opening_hours": "à",
                        "opening_hours_range": "préciser une heure de fin",
                        "opening_hours_from": "à partir de",
                        "opening_hours_to": "jusqu'à"
                    },
                    "plan": {
                        "tipping_fees": "Frais sur les pourboires (en pourcentage)",
                        "authorized_roles": "rôles autorisés"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "prix hors taxes"
                    },
                    "register": {
                        "privacy_policy": {
                            "part1": "J'accepte la",
                            "part2": "charte de confidentialité"
                        },
                        "terms_conditions": {
                            "part1": "J'accepte les",
                            "part2": "conditions générales"
                        },
                        "communication_consent": "Je souhaite recevoir des communications personnalisées, y compris des offres,\n                des informations sur les promotions et des produits liés aux voyages de la part de UgoZER par e-mail."
                    },
                    "support_request": {
                        "title": "Objet de la demande"
                    },
                    "support_message": {
                        "email": "Votre adresse mail",
                        "content": "Message"
                    },
                    "support_category": {
                        "title": "Sélectionnez une catégorie"
                    },
                    "traveller_invitation": {
                        "locale": "langue parlée par ce voyageur"
                    },
                    "vat_rate": {
                        "vat_rate": "Taux de TVA (pourcentage)"
                    }
                },
                "placeholders": {
                    "name": "pseudonyme",
                    "audio_file_name": "nom du fichier audio",
                    "support_message": {
                        "create_message": "Votre message à propos de la demande"
                    }
                },
                "select": {
                    "no_results": "Désolé, aucune correspondance trouvée.",
                    "no_options": "Aucune option",
                    "type_to_search": "Rechercher..."
                },
                "hints": {
                    "translatable": {
                        "pro_global_title": "Saisie multilingue",
                        "pro_global": "Un ou plusieurs champs de ce formulaire peuvent être renseignés dans différentes langues.",
                        "pro_global_2": "Les champs non renseignés dans certaines langues seront automatiquement traduits."
                    },
                    "plan": {
                        "countries": "Laisser vide pour rendre l'offre disponible dans tous les pays",
                        "private_title": "Ce titre n'est visible que dans l'administration",
                        "name": "Ce nom sera visible par les potentiels abonnés au moment de choisir leur abonnement",
                        "information": "Informations additionnelles sur l'offre (pré-requis, conditions...)"
                    },
                    "plan_price": {
                        "private_title": "Ce titre n'est visible que dans l'administration",
                        "description": "Cette description sera visible par les potentiels abonnés au moment de choisir leur\n                abonnement",
                        "price": "À exprimer en euro"
                    },
                    "profile": {
                        "password_unchanged": "Laisser vide si inchangé",
                        "name": "Le pseudonyme est public et permet aux autres utilisateurs de l'application de vous identifier\n                plus facilement.",
                        "contact_phone": "Ce téléphone peut être affiché sur votre profil public",
                        "contact_email": "Cet email peut être affiché sur votre profil public",
                        "password_template": {
                            "length": "{length} caractères",
                            "uppercase": "1 lettre majuscule",
                            "lowercase": "1 lettre minuscule",
                            "digit": "1 chiffre"
                        }
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "traveller": "Peu importe l'option choisie, votre nom et votre prénom restent visibles\n                   par les concierges de l'hôtel où vous séjournez.",
                            "butler": "Peu importe l'option choisie, votre nom et votre prénom restent visibles\n                    par le gérant de votre établissement."
                        }
                    },
                    "geoloc_setting": {
                        "filter_header": "Recherchez un pays, une région, une ville ou un quartier",
                        "kind": {
                            "admin": {
                                "around_me": "Autour de moi : la géolocalisation sera déterminée\n                        par le navigateur de l'utilisateur.",
                                "customized": "Personnalisé : l'utilisateur pourra rechercher manuellement un lieu.",
                                "staying_hotel": "Près de l'hotel : la géolocalisation se fera\n                        depuis l'hôtel ou séjourne le voyageur."
                            },
                            "app": {
                                "around_me": "La géolocalisation se fera depuis votre position.",
                                "customized": "La géolocalisation se fera depuis un lieu de votre choix.",
                                "staying_hotel": "La géolocalisation se fera depuis l'hôtel où vous séjournez : {title}."
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "Sur mesure : itinéraire sur mesure créé par le concierge\n                        pour un voyageur particulier.",
                                "root": "Mes modèles : itinéraire « prêt à partager » constitué de points d'intérêts\n                        incontournables, ou de points d'intérêts par thème\n                        (exemple: liste des restaurants fréquemment insérés dans les itinéraires)\n                        à sélectionner pour constituer un nouvel itinéraire.",
                                "ugozer_pro": "UgoZER Pro : itinéraire créé et proposé par le siège UgoZER\n                        et mis à la disposition des professionnels afin de faciliter leur travail.",
                                "ugozer_app": "UgoZER App : itinéraire créé et proposé par le siège UgoZER\n                        et mis à la disposition des voyageurs."
                            },
                            "pro": {
                                "customized": "Itinéraire sur mesure créé pour un voyageur particulier.",
                                "root": "Itinéraire « prêt à partager » constitué de points d'intérêts incontournables,\n                        ou de points d'intérêts par thème\n                        (exemple: liste des restaurants fréquemment insérés dans les itinéraires)\n                        à sélectionner pour constituer un nouvel itinéraire.",
                                "ugozer": "Itinéraire créé et proposé par UgoZER."
                            }
                        },
                        "points_of_interest_search": "Rechercher et sélectionner un point d'intérêt pour l'ajouter à la liste"
                    },
                    "locale": {
                        "static_translations_enabled": "Permet aux utilisateurs de choisir cette langue pour afficher\n                l'application (nécessite que les traductions aient été ajoutées au code source)",
                        "dynamic_translations_enabled": "Permet aux utilisateurs d'afficher ou traduire des éléments\n                (points d'intérêts, itinéraires...) dans cette langue",
                        "fallback_locale": "Permet d'afficher automatiquement sur la fiche d'un POI, le titre dans une deuxième\n                langue (avant la description)"
                    },
                    "permission": {
                        "name": "Nom utilisé dans le code source"
                    },
                    "point_of_interest": {
                        "images": "Les images seront affichées dans la galerie du point d'intérêt selon l'ordre choisi, vous\n                pouvez les réorganiser en les faisant glisser.",
                        "locale": "La langue principale parlée à ce point d'intérêt",
                        "line1": "Vous pouvez saisir soit l'adresse, soit le nom du lieu recherché. Choisissez parmi les \n                propositions de la liste pour remplir l'adresse automatiquement",
                        "audio_refreshing_1": "Activer l'actualisation du fichier audio permet de générer automatiquement\n                un fichier audio dans la langue du point d'intérêt ({language}).",
                        "audio_refreshing_2": "Celui-ci énoncera le titre et l'adresse du point d'intérêt.",
                        "audio_refreshing_3": "Il sera actualisé dès que la langue, le titre\n                ou l'adresse du point d'intérêt seront mis à jour.",
                        "audio_refreshing_4": "L'actualisation sera désactivée si vous\n                ajoutez ou enregistrez vous même un fichier audio, ou si vous supprimez le fichier audio généré.",
                        "sync_1": "Activer la mise à jour automatique permet de récupérer les données\n                depuis l'import {import_source}",
                        "sync_2": "Les données saisies manuellement seront écrasées",
                        "opening_days": "Ne rien sélectionner si les horaires s'appliquent n'importe quel jour",
                        "opening_hours": "Ne rien ajouter si le point d'intérêt est ouvert à n'importe quelle heure",
                        "opening_dates": "Laisser vide si les horaires s'appliquent à n'importe quelle date",
                        "expiry_date": "Votre point d'intérêt expirera le {date}",
                        "expiry_date_details_1": "Votre point d'intérêt fait partie de la catégorie \"{category}\".",
                        "expiry_date_details_2": "Il expirera donc le {date} car c'est la dernière date que vous avez renseigné.",
                        "expiry_date_details_3": "À compter cette date, il restera 21 jours à votre point d'intérêt\n                avant qu'il soit supprimé automatiquement.",
                        "expiry_date_details_4": "Vous pouvez toujours ajouter une nouvelle date pour repousser l'expiration,\n                ou ne préciser aucune date pour que votre point d'intérêt n'expire pas.",
                        "cant_have_employees": "Vous ne voyez ici que les établissements associés à des catégories pouvant avoir\n                des employés",
                        "is_geocoding": "Désactiver si la géolocalisation est bien renseignée mais que la carte n'apparait\n                toujours pas."
                    },
                    "points_of_interest_filters": {
                        "radius": "Vous ne verrez que les points d’intérêts situés dans ce rayon.",
                        "order_distance": "Veuillez choisir un filtre par distance afin de sélectionner cette option",
                        "order_relevance": "Veuillez saisir des mots-clés de recherche afin de sélectionner cette option"
                    },
                    "excursions_filters": {
                        "radius": "Vous ne verrez que les itinéraires avec au moins un point d’intérêt situé dans ce rayon."
                    },
                    "role": {
                        "name": "Nom de code du rôle, il ne sera pas affiché aux utilisateurs.",
                        "description": "La description du rôle est affichée aux utilisateurs lors du choix du rôle à\n                l'inscription à l'espace professionnel."
                    },
                    "support_request": {
                        "support_categories_search": "Rechercher et sélectionner une catégorie"
                    },
                    "traveller_folder": {
                        "expired_on": "Par défaut, la date de fin de séjour est définie dans une semaine"
                    },
                    "auth": {
                        "register": {
                            "email": "Cette adresse e-mail ne doit pas déjà être rattachée à un compte UgoZER",
                            "employee": {
                                "not_duplicate_email": "L'adresse e-mail doit être différente du compte ",
                                "not_duplicate_password": "Le mot de passe doit être différent du compte "
                            }
                        }
                    },
                    "user": {
                        "bank_account_number": "Renseignez le numéro de compte permettant de vous faire un virement"
                    }
                },
                "file": {
                    "current_file": "Fichier actuel : {name}"
                }
            },
            "front": {
                "app": "App voyageurs",
                "pro": "Espace pro",
                "access_pro": "Accéder à l'espace pro",
                "brand_name": "UgoZER",
                "partners": {
                    "title": "Nos partenaires",
                    "no_records": "Aucun partenaire pour le moment",
                    "read_more": "En savoir plus",
                    "see_partners": "Voir nos partenaires"
                },
                "customers": {
                    "title": "Ils nous font confiance",
                    "see_customers": "Voir nos clients"
                },
                "header": {
                    "app": "App voyageurs",
                    "pro": "Espace pro",
                    "home": "Accueil",
                    "contact": "Contact",
                    "you_are": "Vous êtes",
                    "faq": "FAQ"
                },
                "footer": {
                    "contact": "Contact",
                    "home": "Accueil",
                    "app": "App voyageurs",
                    "pro": "Espace pro",
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "linkedin": "Linkedin",
                    "twitter": "Twitter",
                    "legal_notice": "Mentions légales",
                    "sitemap": "Plan du site",
                    "copyright": "© {year} UgoZER - Tous droits réservés.",
                    "privacy_policy": "Charte de confidentialité",
                    "terms_conditions": "Conditions d'utilisation",
                    "cookies": "Gestion des cookies",
                    "faq": "FAQ",
                    "account_deletion": "Suppression du compte"
                },
                "intro": {
                    "title_homepage": "Le guide touristique multilingue, gratuit.",
                    "intro_title_traveller": "VOYAGEUR",
                    "intro_title_pro": "PROFESSIONNEL",
                    "intro_1": "Avec UgoZER, ayez toujours dans votre smartphone vos adresses préférées en fichiers multilingues, \n            audibles ou lisibles, d’un clic.",
                    "intro_2": "A l’étranger, faites comprendre facilement votre destination aux chauffeurs de taxi et à vos \n            autres interlocuteurs.",
                    "intro_3": "Réceptionniste, manager d’hôtel, concevez et proposez des programmes de visites.\n            Partagez les avec vos clients sur UgoZER."
                },
                "travellers": {
                    "intro": {
                        "main_title": "Votre guide touristique multilingue gratuit"
                    },
                    "argument_1": {
                        "part_1": "Accédez à tous les points d'intérêts (POI) mis à jour en temps réel sur votre smartphone.",
                        "part_2": "POI classés par <strong>catégories : monuments, musées, expositions, magasins, restaurants,\n                bars, événements temporaires...</strong>",
                        "part_3": "POI fournis avec :",
                        "arguments": {
                            "desc": "<strong>description détaillée</strong>",
                            "map": "<strong>emplacement sur une carte interactive, sortie sur Google Maps™ possible</strong>",
                            "audio": "<strong>adresse audio</strong> dans la langue locale à faire écouter à un taxi ou un\n                    passant",
                            "languages": "Toutes les informations sont traduites en <strong>français, anglais, allemand,\n                    néerlandais, portugais, espagnol, italien, coréen, chinois, japonais et arabe.</strong>"
                        }
                    },
                    "argument_2": {
                        "part_1": "Bénéficiez d'itinéraires déjà conçus, créez les vôtres et partagez-les avec vos amis."
                    },
                    "argument_3": {
                        "part_1": "Laissez-vous guider et profitez de votre séjour grâce à des informations précises et sûres.",
                        "part_2": "Avec UgoZER, vous n'êtes jamais perdu. Grâce au fichier audio de l'adresse en langue\n                locale, rejoignez votre prochain POI ou revenez à votre hébergement, en toute sécurité."
                    },
                    "outro": {
                        "catchphrase": "Avec UgoZER, voyagez l'esprit libre et vivez une expérience touristique unique."
                    }
                },
                "startup": {
                    "title": "La startup UgoZER",
                    "description_1": "UgoZER est une société française, basée à Strasbourg, capitale de l’Europe. Créée par des \n            amoureux des voyages, elle facilite vos déplacements dans les pays dont vous ne maitrisez \n            pas la langue.",
                    "description_2": "Elle est recommandée par les offices de tourisme."
                },
                "outro": {
                    "description": "Voyagez en mode 2.0"
                },
                "contact": {
                    "contact": "Contact",
                    "send": "Envoyer",
                    "cancel": "Annuler la demande de contact"
                },
                "captcha": {
                    "protected": "Ce site est protégé par hCaptcha, sa",
                    "privacy_policy": "Politique de confidentialité",
                    "and": "et ses",
                    "terms_service": "Conditions d'utilisation",
                    "apply": "s'appliquent."
                },
                "you_are": {
                    "traveller": "Voyageur",
                    "professional": "Professionnel"
                },
                "navigation": {
                    "previous": "Précédent",
                    "next": "Suivant"
                },
                "support_request": "Demande de contact",
                "cookies": {
                    "title": "Gestion des cookies"
                },
                "professionals": {
                    "intro": {
                        "main_title": "Ugozer, le guide touristique multilingue qui propulse l'expérience client vers\n                l'excellence et génère plus de revenus."
                    },
                    "argument_1": {
                        "part_1": "UgoZER vous apporte une solution digitale entièrement dédiée à votre clientèle.",
                        "part_2": "Elle enrichit leur séjour et améliore leur expérience client.",
                        "part_3": "UgoZER vous permet de répondre efficacement aux demandes de conseils touristiques de votre\n                clientèle par la création d'itinéraires personnalisés multilingues, prêts à l'emploi.",
                        "part_4": "Partagez-les en quelques clics via la plate-forme professionnelle UgoZER et son\n                application mobile."
                    },
                    "argument_2": {
                        "part_1": "Depuis  une base de données de points d'intérêt mis à jour en temps réel,\n                sélectionnez des itinéraires préconçus.",
                        "part_2": "Créez le vôtre avec vos conseils où diner, ce qu'il faut visiter absolument.",
                        "part_3": "Ou choisissez chaque étape de visite parmi une liste de POI selon leurs centres d'intérêts.\n                Combinez-les et partagez votre itinéraire personnalisé."
                    },
                    "argument_3": {
                        "part_1": "Chaque POI présente une description détaillée, sa position sur une carte interactive\n                et son adresse traduite dans la langue locale.",
                        "part_2": "Le voyageur ne sera jamais perdu. Il trouvera toujours son chemin et saura facilement\n                rentrer à son hôtel."
                    },
                    "argument_4": {
                        "part_1": "Après avoir profité d'un itinéraire personnalisé, le voyageur pourra remercier son\n                expert local pour ses recommandations uniques et sûres en lui versant un pourboire digitalisé.",
                        "part_2": "UgoZER, supprime le turnover du personnel en renforçant la fidélisation du personnel\n                et en impulsant une augmentation des revenus.",
                        "part_3": "La solution UgoZER stimulera l'implication et la motivation de chacun ainsi que le\n                sentiment d'appartenir à une entreprise dynamique."
                    },
                    "outro": {
                        "catchphrase": "UgoZER augmente la satisfaction client et participe activement à la croissance\n                de votre établissement."
                    }
                },
                "posts": {
                    "title": "F.A.Q.",
                    "no_records": "Aucun article pour le moment"
                },
                "youtube": {
                    "directed_by": "Réalisée par",
                    "producer": "Suprahead studio"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "Choisir votre établissement"
                    },
                    "presentation": {
                        "title": "PDF de présentation",
                        "description": "Vous pouvez envoyer un PDF de présentation de l'application par mail",
                        "success": "PDF de présentation envoyé."
                    },
                    "sections": {
                        "validated_claims": "Rattachés",
                        "pending_claims": "En attente de validation",
                        "requests": "Demandes en attente"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "La demande a bien été envoyée"
                },
                "cancel": {
                    "success": "La demande a bien été annulée",
                    "confirm": {
                        "employer": "Voulez vous vraiment annuler la demande de {user_name} ?",
                        "employee": "Voulez vous vraiment annuler cette demande ?"
                    }
                },
                "quit": {
                    "success": "Vous avez bien quitté l'établissement",
                    "confirm": "Voulez vous vraiment quitter l'établissement {poi_name} ?"
                },
                "decline": {
                    "success": "La demande a bien été refusée",
                    "confirm": {
                        "employer": "Voulez vous vraiment refuser {user_name} ?",
                        "employee": "Voulez vous vraiment refuser la demande ?"
                    }
                },
                "approve": {
                    "success": "La demande a bien été approuvée",
                    "confirm": {
                        "employer": "Voulez vous vraiment approuver {user_name} ?",
                        "employee": "Voulez vous vraiment approuver la demande ?",
                        "change_institution": "Votre établissement lié sera changé !"
                    }
                },
                "remove": {
                    "success": "L'employé a bien été retiré",
                    "confirm": "Voulez vous vraiment retirer l'employé {user_name} ?"
                },
                "feature": {
                    "success": "L'employé a bien été mis en avant",
                    "confirm": "Voulez vous vraiment mettre en avant l'employé {user_name} ?",
                    "only_one": "Un seul employé peut être mis en avant.",
                    "folder": "Pour un voyageur n'ayant eu aucune intéraction avec un de vos employés, c'est celui mis en avant\n            qui sera affiché dans le dossier voyageur."
                },
                "states": {
                    "pending": "En attente",
                    "approved": "Approuvé",
                    "declined": "Refusé",
                    "canceled": "Annulé",
                    "removed": "Parti",
                    "invited": "Invité(e)"
                },
                "show": {
                    "pending": "Votre demande pour rejoindre cet établissement est actuellement en attente."
                },
                "index": {
                    "my_employees": "Mes employés"
                },
                "invite": {
                    "invite": "Inviter un employé"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "Compte",
                        "invitation": "Invitation"
                    }
                }
            },
            "interactions": {
                "description": {
                    "traveller_folder created": "Le dossier voyageur a été créé par {sender_name}",
                    "traveller_folder thanked": "{sender_name} a remercié le voyageur pour son séjour",
                    "contact created": "La demande de contact a été créée par {sender_name}",
                    "contact approved": "La demande de contact a été approuvée par {sender_name}",
                    "contact declined": "La demande de contact a été refusée par {sender_name}",
                    "contact blocked": "La demande de contact a été bloquée par {sender_name}",
                    "share created": "{sender_name} a partagé {share}",
                    "payment succeeded": "{sender_name} a envoyé un paiement à {recipient_name}",
                    "invitation created": "L'invitation a été envoyée par {sender_name}",
                    "invitation accepted": "L'invitation a été acceptée par {sender_name}"
                },
                "share_type": {
                    "pointsofinterest": "le point d'intérêt",
                    "excursions": "l'itinéraire"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "Inviter"
                },
                "texts": {
                    "title": "Inviter quelqu'un",
                    "description": "Vous pouvez inviter quelqu'un à rejoindre notre application via un e-mail ou téléphone.",
                    "geoloc_settings": {
                        "title": "Paramètres de géolocalisation",
                        "description": "Les paramètres de géolocalisation seront automatiquement définis sur les coordonnées \n                de votre hôtel (si vous êtes lié à un établissement)."
                    },
                    "new_traveller": "Nouveau voyageur",
                    "already_have_account": "L'utilisateur a-t'il déjà un compte sur l'application ?",
                    "employee_already_have_account": "L'employé(e) a-t'il(elle) déjà un compte sur l'application ?",
                    "invite_traveller": "Inviter un voyageur",
                    "invite_professional": "Inviter un professionnel",
                    "invite_link": {
                        "title": "Inviter par lien",
                        "instructions": "Copiez ce lien pour inviter un voyageur"
                    },
                    "professional_description": "Vous pouvez inviter un professionnel à s'inscrire sur l'espace professionnel\n            de notre application par e-mail."
                },
                "states": {
                    "authenticated_once": {
                        "true": "Acceptée",
                        "false": "En attente"
                    }
                },
                "success": {
                    "professional": {
                        "title": "Invitation envoyée",
                        "sent_1": "Un e-mail va être envoyé à {email}, si cette adresse n'est pas déjà utilisée.",
                        "sent_2": "Cet e-mail contient un lien permettant de s'inscrire à l'espace professionnel."
                    },
                    "traveller": {
                        "title": "Invitation envoyée",
                        "email_1": "Un e-mail va être envoyé à {email}, si cette adresse n'est pas déjà utilisée.",
                        "email_2": "Cet e-mail contient un lien permettant de se connecter à l'application."
                    }
                }
            },
            "locales": {
                "ab": "abkhaze",
                "aa": "afar",
                "af": "afrikaans",
                "af_ZA": "afrikaans (Afrique du Sud)",
                "af_NA": "afrikaans (Namibie)",
                "ak": "akan",
                "ak_GH": "akan (Ghana)",
                "sq": "albanais",
                "sq_AL": "albanais (Albanie)",
                "sq_XK": "albanais (Kosovo)",
                "sq_MK": "albanais (Macédoine)",
                "de": "allemand",
                "de_DE": "allemand (Allemagne)",
                "de_AT": "allemand (Autriche)",
                "de_BE": "allemand (Belgique)",
                "de_LI": "allemand (Liechtenstein)",
                "de_LU": "allemand (Luxembourg)",
                "de_CH": "allemand (Suisse)",
                "am": "amharique",
                "am_ET": "amharique (Éthiopie)",
                "en": "anglais",
                "en_ZA": "anglais (Afrique du Sud)",
                "en_AI": "anglais (Anguilla)",
                "en_AG": "anglais (Antigua-et-Barbuda)",
                "en_AU": "anglais (Australie)",
                "en_BS": "anglais (Bahamas)",
                "en_BB": "anglais (Barbade)",
                "en_BE": "anglais (Belgique)",
                "en_BZ": "anglais (Belize)",
                "en_BM": "anglais (Bermudes)",
                "en_BW": "anglais (Botswana)",
                "en_CM": "anglais (Cameroun)",
                "en_CA": "anglais (Canada)",
                "en_DG": "anglais (Diego Garcia)",
                "en_DM": "anglais (Dominique)",
                "en_ER": "anglais (Érythrée)",
                "en_FM": "anglais (États fédérés de Micronésie)",
                "en_US": "anglais (États-Unis)",
                "en_FJ": "anglais (Fidji)",
                "en_GM": "anglais (Gambie)",
                "en_GH": "anglais (Ghana)",
                "en_GI": "anglais (Gibraltar)",
                "en_GD": "anglais (Grenade)",
                "en_GU": "anglais (Guam)",
                "en_GG": "anglais (Guernesey)",
                "en_GY": "anglais (Guyana)",
                "en_CX": "anglais (Île Christmas)",
                "en_IM": "anglais (Île de Man)",
                "en_NF": "anglais (Île Norfolk)",
                "en_KY": "anglais (Îles Caïmans)",
                "en_CC": "anglais (Îles Cocos)",
                "en_CK": "anglais (Îles Cook)",
                "en_FK": "anglais (Îles Malouines)",
                "en_MP": "anglais (Îles Mariannes du Nord)",
                "en_MH": "anglais (Îles Marshall)",
                "en_UM": "anglais (Îles mineures éloignées des États-Unis)",
                "en_SB": "anglais (Îles Salomon)",
                "en_TC": "anglais (Îles Turques-et-Caïques)",
                "en_VG": "anglais (Îles Vierges britanniques)",
                "en_VI": "anglais (Îles Vierges des États-Unis)",
                "en_IN": "anglais (Inde)",
                "en_IE": "anglais (Irlande)",
                "en_JM": "anglais (Jamaïque)",
                "en_JE": "anglais (Jersey)",
                "en_KE": "anglais (Kenya)",
                "en_KI": "anglais (Kiribati)",
                "en_LS": "anglais (Lesotho)",
                "en_LR": "anglais (Libéria)",
                "en_MG": "anglais (Madagascar)",
                "en_MY": "anglais (Malaisie)",
                "en_MW": "anglais (Malawi)",
                "en_MT": "anglais (Malte)",
                "en_MU": "anglais (Maurice)",
                "en_MS": "anglais (Montserrat)",
                "en_NA": "anglais (Namibie)",
                "en_NR": "anglais (Nauru)",
                "en_NG": "anglais (Nigéria)",
                "en_NU": "anglais (Niue)",
                "en_NZ": "anglais (Nouvelle-Zélande)",
                "en_UG": "anglais (Ouganda)",
                "en_PK": "anglais (Pakistan)",
                "en_PW": "anglais (Palaos)",
                "en_PG": "anglais (Papouasie-Nouvelle-Guinée)",
                "en_PH": "anglais (Philippines)",
                "en_PN": "anglais (Pitcairn)",
                "en_PR": "anglais (Porto Rico)",
                "en_HK": "anglais (R.A.S. chinoise de Hong Kong)",
                "en_MO": "anglais (R.A.S. chinoise de Macao)",
                "en_GB": "anglais (Royaume-Uni)",
                "en_RW": "anglais (Rwanda)",
                "en_KN": "anglais (Saint-Christophe-et-Niévès)",
                "en_SX": "anglais (Saint-Martin (partie néerlandaise))",
                "en_VC": "anglais (Saint-Vincent-et-les-Grenadines)",
                "en_SH": "anglais (Sainte-Hélène)",
                "en_LC": "anglais (Sainte-Lucie)",
                "en_AS": "anglais (Samoa américaines)",
                "en_WS": "anglais (Samoa)",
                "en_SC": "anglais (Seychelles)",
                "en_SL": "anglais (Sierra Leone)",
                "en_SG": "anglais (Singapour)",
                "en_SS": "anglais (Soudan du Sud)",
                "en_SD": "anglais (Soudan)",
                "en_SZ": "anglais (Swaziland)",
                "en_TZ": "anglais (Tanzanie)",
                "en_IO": "anglais (Territoire britannique de l’océan Indien)",
                "en_TK": "anglais (Tokelau)",
                "en_TO": "anglais (Tonga)",
                "en_TT": "anglais (Trinité-et-Tobago)",
                "en_TV": "anglais (Tuvalu)",
                "en_VU": "anglais (Vanuatu)",
                "en_ZM": "anglais (Zambie)",
                "en_ZW": "anglais (Zimbabwe)",
                "ar": "arabe",
                "ar_DZ": "arabe (Algérie)",
                "ar_SA": "arabe (Arabie saoudite)",
                "ar_BH": "arabe (Bahreïn)",
                "ar_KM": "arabe (Comores)",
                "ar_DJ": "arabe (Djibouti)",
                "ar_EG": "arabe (Égypte)",
                "ar_AE": "arabe (Émirats arabes unis)",
                "ar_ER": "arabe (Érythrée)",
                "ar_IQ": "arabe (Irak)",
                "ar_IL": "arabe (Israël)",
                "ar_JO": "arabe (Jordanie)",
                "ar_KW": "arabe (Koweït)",
                "ar_LB": "arabe (Liban)",
                "ar_LY": "arabe (Libye)",
                "ar_MA": "arabe (Maroc)",
                "ar_MR": "arabe (Mauritanie)",
                "ar_OM": "arabe (Oman)",
                "ar_QA": "arabe (Qatar)",
                "ar_EH": "arabe (Sahara occidental)",
                "ar_SO": "arabe (Somalie)",
                "ar_SS": "arabe (Soudan du Sud)",
                "ar_SD": "arabe (Soudan)",
                "ar_SY": "arabe (Syrie)",
                "ar_TD": "arabe (Tchad)",
                "ar_PS": "arabe (Territoires palestiniens)",
                "ar_TN": "arabe (Tunisie)",
                "ar_YE": "arabe (Yémen)",
                "an": "aragonais",
                "hy": "arménien",
                "hy_AM": "arménien (Arménie)",
                "as": "assamais",
                "as_IN": "assamais (Inde)",
                "av": "avar",
                "ay": "aymara",
                "az": "azéri",
                "az_AZ": "azéri (Azerbaïdjan)",
                "az_Cyrl_AZ": "azéri (cyrillique, Azerbaïdjan)",
                "az_Cyrl": "azéri (cyrillique)",
                "az_Latn_AZ": "azéri (latin, Azerbaïdjan)",
                "az_Latn": "azéri (latin)",
                "ba": "bachkir",
                "bm": "bambara",
                "bm_Latn_ML": "bambara (latin, Mali)",
                "bm_Latn": "bambara (latin)",
                "eu": "basque",
                "eu_ES": "basque (Espagne)",
                "bn": "bengali",
                "bn_BD": "bengali (Bangladesh)",
                "bn_IN": "bengali (Inde)",
                "bi": "bichlamar",
                "be": "biélorusse",
                "be_BY": "biélorusse (Biélorussie)",
                "bh": "biharis (langues)",
                "my": "birman",
                "my_MM": "birman (Myanmar)",
                "bs": "bosniaque",
                "bs_BA": "bosniaque (Bosnie-Herzégovine)",
                "bs_Cyrl_BA": "bosniaque (cyrillique, Bosnie-Herzégovine)",
                "bs_Cyrl": "bosniaque (cyrillique)",
                "bs_Latn_BA": "bosniaque (latin, Bosnie-Herzégovine)",
                "bs_Latn": "bosniaque (latin)",
                "br": "breton",
                "br_FR": "breton (France)",
                "bg": "bulgare",
                "bg_BG": "bulgare (Bulgarie)",
                "ca": "catalan",
                "ca_AD": "catalan (Andorre)",
                "ca_ES": "catalan (Espagne)",
                "ca_FR": "catalan (France)",
                "ca_IT": "catalan (Italie)",
                "ch": "chamorro",
                "ny": "chichewa",
                "zh": "chinois",
                "zh_CN": "chinois (Chine)",
                "zh_HK": "chinois (R.A.S. chinoise de Hong Kong)",
                "zh_MO": "chinois (R.A.S. chinoise de Macao)",
                "zh_Hans_CN": "chinois (simplifié, Chine)",
                "zh_Hans_HK": "chinois (simplifié, R.A.S. chinoise de Hong Kong)",
                "zh_Hans_MO": "chinois (simplifié, R.A.S. chinoise de Macao)",
                "zh_Hans_SG": "chinois (simplifié, Singapour)",
                "zh_Hans": "chinois (simplifié)",
                "zh_SG": "chinois (Singapour)",
                "zh_TW": "chinois (Taïwan)",
                "zh_Hant_HK": "chinois (traditionnel, R.A.S. chinoise de Hong Kong)",
                "zh_Hant_MO": "chinois (traditionnel, R.A.S. chinoise de Macao)",
                "zh_Hant_TW": "chinois (traditionnel, Taïwan)",
                "zh_Hant": "chinois (traditionnel)",
                "si": "cinghalais",
                "si_LK": "cinghalais (Sri Lanka)",
                "ko": "coréen",
                "ko_KP": "coréen (Corée du Nord)",
                "ko_KR": "coréen (Corée du Sud)",
                "kw": "cornique",
                "kw_GB": "cornique (Royaume-Uni)",
                "co": "corse",
                "cr": "cree",
                "hr": "croate",
                "hr_BA": "croate (Bosnie-Herzégovine)",
                "hr_HR": "croate (Croatie)",
                "da": "danois",
                "da_DK": "danois (Danemark)",
                "da_GL": "danois (Groenland)",
                "dz": "dzongkha",
                "dz_BT": "dzongkha (Bhoutan)",
                "es": "espagnol",
                "es_AR": "espagnol (Argentine)",
                "es_BO": "espagnol (Bolivie)",
                "es_EA": "espagnol (Ceuta et Melilla)",
                "es_CL": "espagnol (Chili)",
                "es_CO": "espagnol (Colombie)",
                "es_CR": "espagnol (Costa Rica)",
                "es_CU": "espagnol (Cuba)",
                "es_SV": "espagnol (El Salvador)",
                "es_EC": "espagnol (Équateur)",
                "es_ES": "espagnol (Espagne)",
                "es_US": "espagnol (États-Unis)",
                "es_GT": "espagnol (Guatemala)",
                "es_GQ": "espagnol (Guinée équatoriale)",
                "es_HN": "espagnol (Honduras)",
                "es_IC": "espagnol (Îles Canaries)",
                "es_MX": "espagnol (Mexique)",
                "es_NI": "espagnol (Nicaragua)",
                "es_PA": "espagnol (Panama)",
                "es_PY": "espagnol (Paraguay)",
                "es_PE": "espagnol (Pérou)",
                "es_PH": "espagnol (Philippines)",
                "es_PR": "espagnol (Porto Rico)",
                "es_DO": "espagnol (République dominicaine)",
                "es_UY": "espagnol (Uruguay)",
                "es_VE": "espagnol (Venezuela)",
                "eo": "espéranto",
                "et": "estonien",
                "et_EE": "estonien (Estonie)",
                "ee": "éwé",
                "ee_GH": "éwé (Ghana)",
                "ee_TG": "éwé (Togo)",
                "fo": "féroïen",
                "fo_FO": "féroïen (Îles Féroé)",
                "fj": "fidjien",
                "fi": "finnois",
                "fi_FI": "finnois (Finlande)",
                "fr": "français",
                "fr_DZ": "français (Algérie)",
                "fr_BE": "français (Belgique)",
                "fr_BJ": "français (Bénin)",
                "fr_BF": "français (Burkina Faso)",
                "fr_BI": "français (Burundi)",
                "fr_CM": "français (Cameroun)",
                "fr_CA": "français (Canada)",
                "fr_KM": "français (Comores)",
                "fr_CG": "français (Congo-Brazzaville)",
                "fr_CD": "français (Congo-Kinshasa)",
                "fr_CI": "français (Côte d’Ivoire)",
                "fr_DJ": "français (Djibouti)",
                "fr_FR": "français (France)",
                "fr_GA": "français (Gabon)",
                "fr_GP": "français (Guadeloupe)",
                "fr_GQ": "français (Guinée équatoriale)",
                "fr_GN": "français (Guinée)",
                "fr_GF": "français (Guyane française)",
                "fr_HT": "français (Haïti)",
                "fr_RE": "français (La Réunion)",
                "fr_LU": "français (Luxembourg)",
                "fr_MG": "français (Madagascar)",
                "fr_ML": "français (Mali)",
                "fr_MA": "français (Maroc)",
                "fr_MQ": "français (Martinique)",
                "fr_MU": "français (Maurice)",
                "fr_MR": "français (Mauritanie)",
                "fr_YT": "français (Mayotte)",
                "fr_MC": "français (Monaco)",
                "fr_NE": "français (Niger)",
                "fr_NC": "français (Nouvelle-Calédonie)",
                "fr_PF": "français (Polynésie française)",
                "fr_CF": "français (République centrafricaine)",
                "fr_RW": "français (Rwanda)",
                "fr_BL": "français (Saint-Barthélemy)",
                "fr_MF": "français (Saint-Martin (partie française))",
                "fr_PM": "français (Saint-Pierre-et-Miquelon)",
                "fr_SN": "français (Sénégal)",
                "fr_SC": "français (Seychelles)",
                "fr_CH": "français (Suisse)",
                "fr_SY": "français (Syrie)",
                "fr_TD": "français (Tchad)",
                "fr_TG": "français (Togo)",
                "fr_TN": "français (Tunisie)",
                "fr_VU": "français (Vanuatu)",
                "fr_WF": "français (Wallis-et-Futuna)",
                "fy": "frison occidental",
                "fy_NL": "frison occidental (Pays-Bas)",
                "gd": "gaélique écossais",
                "gd_GB": "gaélique écossais (Royaume-Uni)",
                "gl": "galicien",
                "gl_ES": "galicien (Espagne)",
                "cy": "gallois",
                "cy_GB": "gallois (Royaume-Uni)",
                "lg": "ganda",
                "lg_UG": "ganda (Ouganda)",
                "ka": "géorgien",
                "ka_GE": "géorgien (Géorgie)",
                "el": "grec",
                "el_CY": "grec (Chypre)",
                "el_GR": "grec (Grèce)",
                "kl": "groenlandais",
                "kl_GL": "groenlandais (Groenland)",
                "gn": "guarani",
                "gu": "gujarati",
                "gu_IN": "gujarati (Inde)",
                "ht": "haïtien",
                "ha": "haoussa",
                "ha_GH": "haoussa (Ghana)",
                "ha_Latn_GH": "haoussa (latin, Ghana)",
                "ha_Latn_NE": "haoussa (latin, Niger)",
                "ha_Latn_NG": "haoussa (latin, Nigéria)",
                "ha_Latn": "haoussa (latin)",
                "ha_NE": "haoussa (Niger)",
                "ha_NG": "haoussa (Nigéria)",
                "he": "hébreu",
                "he_IL": "hébreu (Israël)",
                "hz": "herero",
                "hi": "hindi",
                "hi_IN": "hindi (Inde)",
                "ho": "hiri motu",
                "hu": "hongrois",
                "hu_HU": "hongrois (Hongrie)",
                "ig": "igbo",
                "ig_NG": "igbo (Nigéria)",
                "id": "indonésien",
                "id_ID": "indonésien (Indonésie)",
                "ia": "interlingua",
                "ie": "interlingue",
                "iu": "inuktitut",
                "ik": "inupiaq",
                "io": "ido",
                "ga": "irlandais",
                "ga_IE": "irlandais (Irlande)",
                "is": "islandais",
                "is_IS": "islandais (Islande)",
                "it": "italien",
                "it_IT": "italien (Italie)",
                "it_SM": "italien (Saint-Marin)",
                "it_CH": "italien (Suisse)",
                "ja": "japonais",
                "ja_JP": "japonais (Japon)",
                "jv": "javanais",
                "kn": "kannada",
                "kn_IN": "kannada (Inde)",
                "kr": "kanouri",
                "ks": "kashmiri",
                "ks_Arab_IN": "kashmiri (arabe, Inde)",
                "ks_Arab": "kashmiri (arabe)",
                "ks_IN": "kashmiri (Inde)",
                "kk": "kazakh",
                "kk_Cyrl_KZ": "kazakh (cyrillique, Kazakhstan)",
                "kk_Cyrl": "kazakh (cyrillique)",
                "kk_KZ": "kazakh (Kazakhstan)",
                "km": "khmer",
                "km_KH": "khmer (Cambodge)",
                "ki": "kikuyu",
                "ki_KE": "kikuyu (Kenya)",
                "ky": "kirghize",
                "ky_Cyrl_KG": "kirghize (cyrillique, Kirghizistan)",
                "ky_Cyrl": "kirghize (cyrillique)",
                "ky_KG": "kirghize (Kirghizistan)",
                "kv": "kom",
                "kg": "kongo",
                "ku": "kurde",
                "kj": "kwanyama",
                "lo": "lao",
                "lo_LA": "lao (Laos)",
                "la": "latin",
                "lv": "letton",
                "lv_LV": "letton (Lettonie)",
                "li": "limbourgeois",
                "ln": "lingala",
                "ln_AO": "lingala (Angola)",
                "ln_CG": "lingala (Congo-Brazzaville)",
                "ln_CD": "lingala (Congo-Kinshasa)",
                "ln_CF": "lingala (République centrafricaine)",
                "lt": "lituanien",
                "lt_LT": "lituanien (Lituanie)",
                "lu": "luba-katanga",
                "lu_CD": "luba-katanga (Congo-Kinshasa)",
                "lb": "luxembourgeois",
                "lb_LU": "luxembourgeois (Luxembourg)",
                "mo": "macao",
                "mk": "macédonien",
                "mk_MK": "macédonien (Macédoine)",
                "ms": "malais",
                "ms_BN": "malais (Brunéi Darussalam)",
                "ms_Latn_BN": "malais (latin, Brunéi Darussalam)",
                "ms_Latn_MY": "malais (latin, Malaisie)",
                "ms_Latn_SG": "malais (latin, Singapour)",
                "ms_Latn": "malais (latin)",
                "ms_MY": "malais (Malaisie)",
                "ms_SG": "malais (Singapour)",
                "ml": "malayalam",
                "ml_IN": "malayalam (Inde)",
                "dv": "maldivien",
                "mg": "malgache",
                "mg_MG": "malgache (Madagascar)",
                "mt": "maltais",
                "mt_MT": "maltais (Malte)",
                "gv": "manx",
                "gv_IM": "manx (Île de Man)",
                "mi": "maori",
                "mr": "marathe",
                "mr_IN": "marathe (Inde)",
                "mh": "marshall",
                "mn": "mongol",
                "mn_Cyrl_MN": "mongol (cyrillique, Mongolie)",
                "mn_Cyrl": "mongol (cyrillique)",
                "mn_MN": "mongol (Mongolie)",
                "na": "nauruan",
                "nv": "navaho",
                "nd": "ndébélé du Nord",
                "nd_ZW": "ndébélé du Nord (Zimbabwe)",
                "nr": "ndébélé du Sud",
                "ng": "ndonga",
                "nl": "néerlandais",
                "nl_AW": "néerlandais (Aruba)",
                "nl_BE": "néerlandais (Belgique)",
                "nl_CW": "néerlandais (Curaçao)",
                "nl_BQ": "néerlandais (Pays-Bas caribéens)",
                "nl_NL": "néerlandais (Pays-Bas)",
                "nl_SX": "néerlandais (Saint-Martin (partie néerlandaise))",
                "nl_SR": "néerlandais (Suriname)",
                "ne": "népalais",
                "ne_IN": "népalais (Inde)",
                "ne_NP": "népalais (Népal)",
                "no": "norvégien",
                "no_NO": "norvégien (Norvège)",
                "nb": "norvégien bokmål",
                "nb_NO": "norvégien bokmål (Norvège)",
                "nb_SJ": "norvégien bokmål (Svalbard et Jan Mayen)",
                "nn": "norvégien nynorsk",
                "nn_NO": "norvégien nynorsk (Norvège)",
                "oc": "occitan",
                "oj": "ojibwa",
                "or": "oriya",
                "or_IN": "oriya (Inde)",
                "om": "oromo",
                "om_ET": "oromo (Éthiopie)",
                "om_KE": "oromo (Kenya)",
                "os": "ossète",
                "os_GE": "ossète (Géorgie)",
                "os_RU": "ossète (Russie)",
                "ug": "ouïghour",
                "ug_Arab_CN": "ouïghour (arabe, Chine)",
                "ug_Arab": "ouïghour (arabe)",
                "ug_CN": "ouïghour (Chine)",
                "ur": "ourdou",
                "ur_IN": "ourdou (Inde)",
                "ur_PK": "ourdou (Pakistan)",
                "uz": "ouzbek",
                "uz_AF": "ouzbek (Afghanistan)",
                "uz_Arab_AF": "ouzbek (arabe, Afghanistan)",
                "uz_Arab": "ouzbek (arabe)",
                "uz_Cyrl_UZ": "ouzbek (cyrillique, Ouzbékistan)",
                "uz_Cyrl": "ouzbek (cyrillique)",
                "uz_Latn_UZ": "ouzbek (latin, Ouzbékistan)",
                "uz_Latn": "ouzbek (latin)",
                "uz_UZ": "ouzbek (Ouzbékistan)",
                "ps": "pachto",
                "ps_AF": "pachto (Afghanistan)",
                "pi": "pali",
                "pa": "pendjabi",
                "pa_Arab_PK": "pendjabi (arabe, Pakistan)",
                "pa_Arab": "pendjabi (arabe)",
                "pa_Guru_IN": "pendjabi (gourmoukhî, Inde)",
                "pa_Guru": "pendjabi (gourmoukhî)",
                "pa_IN": "pendjabi (Inde)",
                "pa_PK": "pendjabi (Pakistan)",
                "fa": "persan",
                "fa_AF": "persan (Afghanistan)",
                "fa_IR": "persan (Iran)",
                "ff": "peul",
                "ff_CM": "peul (Cameroun)",
                "ff_GN": "peul (Guinée)",
                "ff_MR": "peul (Mauritanie)",
                "ff_SN": "peul (Sénégal)",
                "pl": "polonais",
                "pl_PL": "polonais (Pologne)",
                "pt": "portugais",
                "pt_AO": "portugais (Angola)",
                "pt_BR": "portugais (Brésil)",
                "pt_CV": "portugais (Cap-Vert)",
                "pt_GW": "portugais (Guinée-Bissau)",
                "pt_MZ": "portugais (Mozambique)",
                "pt_PT": "portugais (Portugal)",
                "pt_MO": "portugais (R.A.S. chinoise de Macao)",
                "pt_ST": "portugais (Sao Tomé-et-Principe)",
                "pt_TL": "portugais (Timor oriental)",
                "qu": "quechua",
                "qu_BO": "quechua (Bolivie)",
                "qu_EC": "quechua (Équateur)",
                "qu_PE": "quechua (Pérou)",
                "rm": "romanche",
                "rm_CH": "romanche (Suisse)",
                "ro": "roumain",
                "ro_MD": "roumain (Moldavie)",
                "ro_RO": "roumain (Roumanie)",
                "rn": "roundi",
                "rn_BI": "roundi (Burundi)",
                "ru": "russe",
                "ru_BY": "russe (Biélorussie)",
                "ru_KZ": "russe (Kazakhstan)",
                "ru_KG": "russe (Kirghizistan)",
                "ru_MD": "russe (Moldavie)",
                "ru_RU": "russe (Russie)",
                "ru_UA": "russe (Ukraine)",
                "rw": "rwanda",
                "rw_RW": "rwanda (Rwanda)",
                "se": "sami du Nord",
                "se_FI": "sami du Nord (Finlande)",
                "se_NO": "sami du Nord (Norvège)",
                "se_SE": "sami du Nord (Suède)",
                "sm": "samoan",
                "sg": "sangho",
                "sg_CF": "sangho (République centrafricaine)",
                "sa": "sanskrit",
                "sc": "sarde",
                "ss": "swati",
                "sr": "serbe",
                "sr_BA": "serbe (Bosnie-Herzégovine)",
                "sr_Cyrl_BA": "serbe (cyrillique, Bosnie-Herzégovine)",
                "sr_Cyrl_XK": "serbe (cyrillique, Kosovo)",
                "sr_Cyrl_ME": "serbe (cyrillique, Monténégro)",
                "sr_Cyrl_RS": "serbe (cyrillique, Serbie)",
                "sr_Cyrl": "serbe (cyrillique)",
                "sr_XK": "serbe (Kosovo)",
                "sr_Latn_BA": "serbe (latin, Bosnie-Herzégovine)",
                "sr_Latn_XK": "serbe (latin, Kosovo)",
                "sr_Latn_ME": "serbe (latin, Monténégro)",
                "sr_Latn_RS": "serbe (latin, Serbie)",
                "sr_Latn": "serbe (latin)",
                "sr_ME": "serbe (Monténégro)",
                "sr_RS": "serbe (Serbie)",
                "sh": "serbo-croate",
                "sh_BA": "serbo-croate (Bosnie-Herzégovine)",
                "sn": "shona",
                "sn_ZW": "shona (Zimbabwe)",
                "sd": "sindhi",
                "sk": "slovaque",
                "sk_SK": "slovaque (Slovaquie)",
                "sl": "slovène",
                "sl_SI": "slovène (Slovénie)",
                "so": "somali",
                "so_DJ": "somali (Djibouti)",
                "so_ET": "somali (Éthiopie)",
                "so_KE": "somali (Kenya)",
                "so_SO": "somali (Somalie)",
                "su": "soundanais",
                "st": "sotho du Sud",
                "sv": "suédois",
                "sv_FI": "suédois (Finlande)",
                "sv_AX": "suédois (Îles Åland)",
                "sv_SE": "suédois (Suède)",
                "sw": "swahili",
                "sw_KE": "swahili (Kenya)",
                "sw_UG": "swahili (Ouganda)",
                "sw_TZ": "swahili (Tanzanie)",
                "tg": "tadjik",
                "tl": "tagalog",
                "tl_PH": "tagalog (Philippines)",
                "ty": "tahitien",
                "ta": "tamoul",
                "ta_IN": "tamoul (Inde)",
                "ta_MY": "tamoul (Malaisie)",
                "ta_SG": "tamoul (Singapour)",
                "ta_LK": "tamoul (Sri Lanka)",
                "tt": "tatar",
                "cs": "tchèque",
                "cs_CZ": "tchèque (République tchèque)",
                "ce": "tchétchène",
                "cv": "tchouvache",
                "te": "télougou",
                "te_IN": "télougou (Inde)",
                "th": "thaï",
                "th_TH": "thaï (Thaïlande)",
                "bo": "tibétain",
                "bo_CN": "tibétain (Chine)",
                "bo_IN": "tibétain (Inde)",
                "ti": "tigrigna",
                "ti_ER": "tigrigna (Érythrée)",
                "ti_ET": "tigrigna (Éthiopie)",
                "to": "tonguien",
                "to_TO": "tonguien (Tonga)",
                "ts": "tsonga",
                "tn": "tswana",
                "tr": "turc",
                "tr_CY": "turc (Chypre)",
                "tr_TR": "turc (Turquie)",
                "tk": "turkmène",
                "tw": "twi",
                "uk": "ukrainien",
                "uk_UA": "ukrainien (Ukraine)",
                "ve": "venda",
                "vi": "vietnamien",
                "vi_VN": "vietnamien (Vietnam)",
                "cu": "vieux bulgare",
                "vo": "volapük",
                "wa": "wallon",
                "wo": "wolof",
                "xh": "xhosa",
                "ii": "yi du Sichuan",
                "ii_CN": "yi du Sichuan (Chine)",
                "yi": "yiddish",
                "yo": "yoruba",
                "yo_BJ": "yoruba (Bénin)",
                "yo_NG": "yoruba (Nigéria)",
                "za": "zhuang",
                "zu": "zoulou",
                "zu_ZA": "zoulou (Afrique du Sud)"
            },
            "mail": {
                "copyright": "© {year} UgoZER - Tous droits réservés.",
                "hello": "Bonjour !",
                "action": "Si vous avez des difficultés à cliquer sur le bouton \"{actionText}\", copiez et collez l'URL ci-dessous dans votre navigateur web :",
                "salutations": "Cordialement",
                "auth": {
                    "deleted": {
                        "subject": "Suppression de votre compte UgoZER",
                        "line1": "Nous vous informons de la suppression de votre compte UgoZER.",
                        "reason": {
                            "intro": "Celui-ci a été supprimé pour la raison suivante :",
                            "never_authenticated_for_too_long": "Votre compte a été créé il y a plus d'une semaine\n                    et vous ne vous y êtes jamais connecté ;\n                    ce compte est donc considéré comme inactif.",
                            "not_logged_for_too_long": "Vous ne vous êtes pas connecté depuis plus de 2 ans ;\n                    votre compte est donc considéré comme inactif."
                        }
                    },
                    "not_logged": {
                        "subject": "Suppression prochaine de votre compte UgoZER",
                        "intro": "Cela fait bientôt 2 ans que vous ne vous êtes pas connecté à votre compte UgoZER.",
                        "remaining": {
                            "two_days": "Il vous reste 2 jours avant la suppression de votre compte.",
                            "two_weeks": "Il vous reste 2 semaines avant la suppression de votre compte.",
                            "two_months": "Il vous reste 2 mois avant la suppression de votre compte."
                        },
                        "outro": "Il vous suffit de vous connecter à votre compte afin qu'il ne soit pas supprimé."
                    },
                    "login_magic_link": {
                        "action": "Connexion",
                        "line1": "Cliquez sur ce lien pour vous connecter",
                        "line2": "Merci d'utiliser notre application !",
                        "subject": "Lien de connexion"
                    },
                    "reset_password": {
                        "subject": "Réinitialisation du mot de passe",
                        "action": "Réinitialiser le mot de passe",
                        "line1": "Vous recevez cet e-mail car nous avons reçu une demande de réinitialisation du mot de passe de votre compte.",
                        "line2": "Ce lien de réinitialisation du mot de passe expirera dans {count} minutes.",
                        "line3": "Si vous n'avez pas demandé de réinitialisation du mot de passe, aucune action supplémentaire n'est requise."
                    },
                    "registration": {
                        "subject": "Vérification de votre adresse mail",
                        "line1": "Merci de cliquer sur le bouton ci-dessous pour vérifier votre adresse e-mail et pouvoir vous connecter.",
                        "line2": "Ce lien expirera dans 1 semaine.",
                        "line3": "Si vous n'avez pas créé de compte, aucune action supplémentaire n'est requise.",
                        "action": "Vérification de votre adresse e-mail"
                    },
                    "login_attempt_unverified_credential": {
                        "subject": "Tentative de connexion à votre compte UgoZER",
                        "line1": "Il y a eu une tentative de connexion à votre compte UgoZER avec l'adresse e-mail {credential}.",
                        "line2": "Si vous êtes à l'origine de cette tentative de connexion,\n                merci de cliquer sur le bouton ci-dessous pour vérifier votre adresse e-mail\n                et pouvoir vous connecter.",
                        "line3": "Ce lien expirera dans 1 semaine.",
                        "line4": "Si vous n'êtes pas à l'origine de cette tentative de connexion, aucune action\n                supplémentaire n'est requise.",
                        "action": "Vérification de votre adresse e-mail"
                    },
                    "registration_attempt_unverified_credential": {
                        "subject": "Tentative d'inscription avec votre identifiant UgoZER",
                        "line1": "Le mail <strong>{credential}</strong> est déjà utilisé pour un compte <strong>{role_type}</strong> dans notre application.",
                        "line2": "Pour créer un compte veuillez recommencer l’inscription avec un autre mail."
                    },
                    "registration_attempt": {
                        "subject": "Tentative d'inscription avec votre identifiant UgoZER",
                        "line1": "Le mail <strong>{credential}</strong> est déjà utilisé pour un compte <strong>{role_type}</strong> dans notre application.",
                        "line2": "Pour créer un compte veuillez recommencer l’inscription avec un autre mail."
                    }
                },
                "credential_verification": {
                    "verify": {
                        "subject": "Confirmation de votre e-mail",
                        "line1": "Merci de cliquer sur le bouton ci-dessous pour vérifier votre adresse e-mail.",
                        "line2": "Ce lien expirera dans 1 semaine.",
                        "line3": "Si vous n'avez pas demandé de vérification, aucune action supplémentaire n'est requise.",
                        "action": "Vérification de votre adresse e-mail"
                    }
                },
                "invitation": {
                    "verification": {
                        "action": "Vérification de votre adresse e-mail",
                        "line1_by": "Vous avez été invité(e) par {name} à rejoindre notre application",
                        "line1_by_from": "Vous avez été invité(e) par {name} de l'établissement {poi_title} à rejoindre notre application",
                        "line1": "Vous avez été invité(e) à rejoindre notre application",
                        "line2": "Cliquez sur le bouton ci-dessous pour vérifier votre adresse e-mail.",
                        "line3": "Ce lien expirera dans 1 semaine.",
                        "line4": "Merci d'utiliser notre application !",
                        "excursion_shared": "Un itinéraire vous a été partagé, vous pouvez le trouver ci-joint au format PDF,\n                ainsi que dans l'application dans vos partages.",
                        "point_of_interest_shared": "Un point d'intérêt vous a été partagé, vous pourrez le trouver\n                dans l'application dans vos partages.",
                        "subject": "Invitation à rejoindre UgoZER"
                    },
                    "attempt": {
                        "action": "Accéder à l'application",
                        "line1_by": "Vous avez été invité(e) par {name} à rejoindre notre application",
                        "line1_by_from": "Vous avez été invité(e) par {name} de l'établissement {poi_title} à rejoindre notre application",
                        "line1": "Vous avez été invité(e) à rejoindre notre application",
                        "line2": "Un compte existe déjà avec cette adresse mail.",
                        "line3": "Cliquez sur le bouton ci-dessous pour accéder à l'application.",
                        "subject": "Invitation à rejoindre UgoZER"
                    }
                },
                "subscription": {
                    "switched_from_paid_to_free": {
                        "subject": "Abonné passé à la formule gratuite",
                        "line1": "{name} est passé à la formule gratuite",
                        "line2": "Cliquez sur le bouton ci-dessous pour accéder au détail",
                        "cta": "Voir le détail"
                    },
                    "not_continue_after_free_period": {
                        "subject": "Abonné n'a pas poursuivi après sa période de gratuité",
                        "line1": "{name} n'a pas poursuivi après sa période de gratuité",
                        "line2": "Cliquez sur le bouton ci-dessous pour accéder au détail",
                        "cta": "Voir le détail"
                    },
                    "suspended": {
                        "subject": "Abonnement suspendu",
                        "line": "Votre abonnement a été suspendu pour la raison suivante: {suspension_reason}.",
                        "line_alt": "Votre abonnement a été suspendu."
                    }
                },
                "share": {
                    "created": {
                        "action": "Voir",
                        "subject": "Un élément a été partagé avec vous.",
                        "line": "{username} de l'établissement {poi_title} a partagé un élément avec vous.",
                        "line_alt": "{username} a partagé un élément avec vous.",
                        "line_alt_2": "Un élément a été partagé avec vous.",
                        "excursion": {
                            "subject": "Un itinéraire a été partagé avec vous.",
                            "line": "{username} de l'établissement {poi_title} vous a partagé un itinéraire.",
                            "gotoapp": "Rendez-vous sur l'application pour le consulter.",
                            "line_alt": "{username} vous a partagé un itinéraire.",
                            "line_alt_2": "Un itinéraire vous a été partagé."
                        },
                        "point_of_interest": {
                            "subject": "Un point d'intérêt a été partagé avec vous.",
                            "line": "{username} de l'établissement {poi_title} vous a partagé un point d'intérêt.",
                            "line_alt": "{username} vous a partagé un point d'intérêt.",
                            "line_alt_2": "Un point d'intérêt vous a été partagé."
                        }
                    }
                },
                "invitation_professional": {
                    "subject": "UgoZER - Invitation accès professionnel",
                    "title": "Invitation à l'espace professionnel de l'application UgoZER",
                    "content": "Vous avez été invité à rejoindre l'espace professionnel de notre application UgoZER",
                    "cta": "Inscription à l'espace professionnel",
                    "thanks": "Merci,"
                },
                "user_send_link": {
                    "subject": "UgoZER - Application",
                    "title": "Vous avez un compte sur notre application, connectez-vous !",
                    "content": "Reconnectez-vous sur notre application UgoZER ! Si vous ne vous souvenez plus de votre mot de passe, vous pouvez le changer.",
                    "cta": "Connexion à l'application",
                    "thanks": "Merci,"
                },
                "support_request": {
                    "request": {
                        "action": "Accèder à la demande de support",
                        "subject": "UgoZER - Votre demande de support a été créée",
                        "line1": "Le support apportera une réponse sous 48h.",
                        "line2": "Cliquez sur le bouton ci-dessous pour accèder à votre demande."
                    },
                    "message": {
                        "action": "Accèder à la demande de support",
                        "subject": "UgoZER - Vous avez un nouveau message",
                        "title": "Une réponse a été apportée à votre demande de support.",
                        "line1": "Cliquez sur le bouton ci-dessous pour accèder à votre demande."
                    },
                    "archived": {
                        "subject": "UgoZER - Demande de support archivée",
                        "title": "Votre demande de support a été archivée"
                    }
                },
                "preview": {
                    "main_title": "Prévisualisation des e-mails",
                    "from": "De : ",
                    "sections": {
                        "auth": "Authentification",
                        "invitation": "Invitations",
                        "share": "Partages",
                        "support-message": "Messages de support",
                        "support-request": "Demandes de support",
                        "user-credential-verification": "Identification utilisateur",
                        "user": "Utilisateur"
                    },
                    "mails": {
                        "auth/account-deleted-notification": "Suppression du compte",
                        "auth/not-logged-notification": "Suppression si non connecté",
                        "auth/registration-attempt-notification": "Tentative d'inscription",
                        "auth/registration-notification": "Inscription",
                        "auth/reset-password-notification": "Changement de mot de passe",
                        "invitation/invited-notification": "Invitation",
                        "share/created-notification": "Nouveau",
                        "support-message/message-notification": "Nouvelle réponse",
                        "support-request/archived-notification": "Demande archivée",
                        "support-request/created-notification": "Nouvelle demande",
                        "user-credential-verification/login-attempt-notification": "Tentative de connexion",
                        "user-credential-verification/registration-attempt-notification": "Tentative d'inscription",
                        "user-credential-verification/verify-notification": "Confirmation du compte",
                        "user/welcome-notification": "Bienvenue"
                    },
                    "default": {
                        "subject": "Sujet de l'e-mail",
                        "user": {
                            "name": "UgoZER"
                        }
                    }
                },
                "welcome": {
                    "subject": "Bienvenue sur UgoZER",
                    "title": "Bienvenue sur UgoZER, votre application d’assistance au voyage multi-langue gratuite.",
                    "line1": "Nous sommes heureux de vous compter parmi nos utilisateurs.",
                    "line2": "Cliquez sur le bouton ci-dessous pour accéder à l'application.",
                    "action": "Accéder à l'application"
                }
            },
            "media": {
                "default": {
                    "caption": "Cliquez sur la carte pour la voir dans Google Maps",
                    "alt": "Google Maps' carte statique"
                },
                "audio": {
                    "recording": {
                        "errors": {
                            "default": "Enregistrement échoué",
                            "not_allowed": "Enregistrement échoué. Autorisez votre navigateur et UgoZER à utiliser votre micro puis réessayez."
                        }
                    },
                    "generated_audio": "Audio généré"
                },
                "image": {
                    "credits": "Crédits : {credits}"
                }
            },
            "models": {
                "excursion": "Itinéraire|Itinéraires",
                "point_of_interest": "Point d'intérêt|Points d'intérêt",
                "category": "Catégorie|Catégories",
                "medium": "Média|Médias",
                "user": "Utilisateur|Utilisateurs",
                "activity_log": "Journal d'activité|Journaux d'activité",
                "permission": "Permission|Permissions",
                "role": "Rôle|Rôles",
                "notification": "Notification|Notifications",
                "users_relationship": "Contact|Contacts",
                "share": "Partage|Partages",
                "user_privacy_setting": "Paramètre de confidentialité|Paramètres de confidentialité",
                "traveller_folder": "Dossier voyageur|Dossiers voyageur",
                "currency": "Devise|Devises",
                "user_notification_setting": "Paramètre de notifications|Paramètres de notifications",
                "tag": "Tag|Tags",
                "payment": "Paiement|Paiements",
                "post": "Article|Articles",
                "post_category": "Catégorie d'articles|Catégories d'articles",
                "privacy_policy": "Charte de confidentialité|Chartes de confidentialité",
                "terms_condition": "Conditions générales|Conditions générales",
                "changelog": "Note de mise à jour|Notes de mise à jour",
                "report": "Signalement|Signalements",
                "address": "Adresse|Adresses",
                "partner": "Partenaire|Partenaires",
                "support_category": "Catégorie de demande de contact|Catégories de demande de contact",
                "support_message": "Message|Messages",
                "support_request": "Demande de contact|Demandes de contact",
                "reservation": "Réservation|Réservations",
                "user_credential_verification": "Confirmation d'identifiant|Confirmations d'identifiants",
                "claim": "Revendication|Revendications",
                "claim_dispute": "Contestation revendication|Contestations revendication",
                "locale": "Langue|Langues",
                "user_geoloc_setting": "Paramètre de géolocalisation|Paramètres de géolocalisation",
                "data_tourisme_category": "Catégorie DataTourisme|Catégories DataTourisme",
                "data_tourisme_tag": "Tag DataTourisme|Tags DataTourisme",
                "user_password_reset": "Réinitialisation mot de passe utilisateur|Réinitialisations mot de passe utilisateur",
                "interaction": "Interaction|Interactions",
                "institution_employee": "Employé|Employés",
                "plan": "Offre d'abonnement|Offres d'abonnement",
                "plan_price": "Prix d'abonnement",
                "subscription": "Abonnement|Abonnements",
                "traveller_email": "E-mail voyageur|E-mails voyageurs",
                "vat_rate": "Taux de TVA",
                "bank_transfer": "Virement bancaire|Virements bancaires",
                "bank_transfer_institution": "Virement bancaire par institution |Virements bancaires par institution",
                "point_of_interest_category": "Catégorie|Catégories"
            },
            "navigations": {
                "items": {
                    "settings": "Paramètres",
                    "contacts": "Contacts",
                    "folders": "Dossiers voyageurs",
                    "stays": "Séjours",
                    "payments": "Paiements",
                    "faqs": "FAQ",
                    "changelogs": "Notes de mise à jour",
                    "invite_traveller": "Inviter un voyageur",
                    "invite_professional": "Inviter un professionnel",
                    "partners": "Partenaires",
                    "contact_us": "Nous contacter",
                    "privacy_policy": "Charte de confidentialité",
                    "terms_conditions": "Conditions d'utilisation",
                    "cookies": "Gestion des cookies",
                    "credits": "Crédits",
                    "users": "Utilisateurs",
                    "customers": "Ils nous font confiance"
                }
            },
            "notifications": {
                "index": {
                    "no_results": "Aucune notification"
                },
                "share": {
                    "created": {
                        "text": "{username} de l'établissement {poi_title} a partagé un élément avec vous",
                        "text_alt": "{username} a partagé un élément avec vous",
                        "text_alt_2": "Un élément a été partagé avec vous",
                        "excursion": {
                            "title": "Partage d'un itinéraire",
                            "text": "{username} de l'établissement {poi_title} vous a partagé un itinéraire",
                            "text_alt": "{username} vous a partagé un itinéraire",
                            "text_alt_2": "Quelqu'un vous a partagé un itinéraire"
                        },
                        "point_of_interest": {
                            "title": "Partage d'un point d'intérêt",
                            "text": "{username} de l'établissement {poi_title} vous a partagé un point d'intérêt",
                            "text_alt": "{username} vous a partagé un point d'intérêt",
                            "text_alt_2": "Someone vous a partagé un point d'intérêt"
                        }
                    }
                },
                "point_of_interest": {
                    "moderation": {
                        "published": {
                            "title": "Point d'intérêt publié",
                            "text": "Votre point d'intérêt {title} a été publié suite à sa modération"
                        },
                        "denied": {
                            "title": "Point d'intérêt refusé",
                            "text": "Votre point d'intérêt {title} a été refusé suite à sa modération"
                        }
                    },
                    "event": {
                        "expired": {
                            "title": "Événement expiré",
                            "text": "L'événement {title}, inclus dans vos itinéraires, est expiré"
                        }
                    }
                },
                "invitation": {
                    "accepted": {
                        "title": "Invitation acceptée",
                        "email": {
                            "text": "Votre invitation à rejoindre l'application envoyée à {credential} a été acceptée"
                        },
                        "phone": {
                            "text": "Votre invitation à rejoindre l'application envoyée au {credential} a été acceptée"
                        }
                    },
                    "pro": {
                        "title": "Invitation professionnelle envoyée",
                        "text": "Votre invitation professionnelle a été envoyée à l'adresse e-mail {email}"
                    }
                },
                "reservation": {
                    "created": {
                        "title": "Réservation dans votre établissement",
                        "text": "{butler_name} de l'établissement {butler_poi_title} a effectué une réservation\n                dans votre établissement {poi_title}",
                        "text_alt": "Un concierge de l'établissement {butler_poi_title} a effectué une réservation\n                dans votre établissement {poi_title}",
                        "text_alt_2": "{butler_name} a effectué une réservation\n                dans votre établissement {poi_title}",
                        "text_alt_3": "Une réservation a été effectuée\n            dans votre établissement {poi_title}"
                    }
                },
                "traveller_folder": {
                    "pending": {
                        "title": "Séjour en attente de confirmation",
                        "text": "{username} a enregistré pour votre compte un séjour à l'hôtel {poi_title}.\n                Merci de le confirmer.",
                        "text_alt": "Un séjour a été enregistré pour votre compte à l'établissement {poi_title}.\n                Merci de le confirmer."
                    },
                    "created": {
                        "title": "Séjour créé",
                        "text": "Un séjour a été enregistré pour votre compte à l\\'établissement {poi_title}."
                    },
                    "thanks": {
                        "title": "Remerciement pour votre séjour",
                        "text": "{username} de l'établissement {poi_title} vous remercie pour votre séjour",
                        "text_alt": "Un concierge de l'établissement {poi_title} vous remercie pour votre séjour"
                    },
                    "approved": {
                        "title": "Dossier voyageur approuvé",
                        "text": "{username} a approuvé son dossier voyageur",
                        "text_alt": "Un voyageur a approuvé son dossier"
                    },
                    "archived": {
                        "title": "Dossier voyageur archivé",
                        "text": "{username} a archivé son dossier voyageur",
                        "text_alt": "Un voyageur a archivé son dossier"
                    }
                },
                "stripe": {
                    "payments_enabled": {
                        "title": "Paiements Stripe activés",
                        "text": "Vous pouvez maintenant recevoir des paiements par Stripe"
                    },
                    "account_requirements": {
                        "title": "Compte Stripe à completer",
                        "text": "Suite à la vérification de votre compte Stripe, de nouvelles informations sont demandées"
                    }
                },
                "payment": {
                    "received": {
                        "title": "Pourboire reçu",
                        "text": "Vous avez reçu un pourboire de {amount} de la part de {payer} de l'établissement {poi_title}",
                        "text_alt": "Vous avez reçu un pourboire de {amount} de la part de {payer}",
                        "text_alt_2": "Vous avez reçu un pourboire de {amount}"
                    },
                    "thanks": {
                        "title": "Merci pour le pourboire !",
                        "text": "{payee} de l'établissement {poi_title} vous remercie pour le pourboire !",
                        "text_alt": "{payee} vous remercie pour le pourboire !",
                        "text_alt_2": "On vous remercie pour le pouboire !"
                    },
                    "proposal": {
                        "title": "Laissez un pourboire",
                        "text": "Remerciez {username} de l'établissement {poi_title} en lui laissant un pourboire",
                        "text_alt": "Remerciez le concierge de l'établissement {poi_title} en lui laissant un pourboire",
                        "text_alt_2": "Remerciez {username} en lui laissant un pourboire",
                        "text_alt_3": "Remerciez le concierge en lui laissant un pourboire"
                    }
                },
                "changelog": {
                    "created": {
                        "title": "Nouveau changelog",
                        "text": "La version {version} a été déployée. Vous pouvez consulter les nouveautés en cliquant sur la notification."
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "title": "Contestation en attente de vérification",
                        "text": "La contestation concernant le point d'intérêt {poi} est en attente de vérification par notre équipe."
                    },
                    "validated": {
                        "title": "Contestation validée !",
                        "text": "La contestation concernant le point d'intérêt {poi} a été acceptée !"
                    },
                    "rejected": {
                        "title": "Contestation rejetée",
                        "text": "La contestation concernant le point d'intérêt {poi} a été rejetée pour la raison suivante : {reason}.",
                        "no_reason": "Aucune raison précisée"
                    }
                },
                "support_request": {
                    "message": {
                        "title": "Message reçu",
                        "text": "Une réponse a été apportée à votre demande de contact"
                    },
                    "archived": {
                        "title": "Demande de contact archivée",
                        "text": "Votre demande de contact a été archivée"
                    }
                },
                "institution_employee": {
                    "pending": {
                        "title": "Demande d'employé reçue",
                        "text": "{user} demande à être enregistré comme employé de votre établissement {poi}"
                    },
                    "approved": {
                        "title": "Demande d'employé approuvée",
                        "text": "Votre demande à rejoindre l'établissement {poi} a été approuvée"
                    },
                    "declined": {
                        "title": "Demande d'employé refusée",
                        "text": "Votre demande à rejoindre l'établissement {poi} a été refusée"
                    },
                    "removed": {
                        "title": "Statut d'employé retiré",
                        "text": "Votre statut d'employé de l'établissement {poi} a été retiré"
                    }
                },
                "office": {
                    "common": {
                        "content": {
                            "line_2": "Cliquez sur le bouton ci-dessous pour voir le détail dans l'interface admin."
                        }
                    },
                    "claim": {
                        "subject": "Nouvelle revendication !",
                        "content": {
                            "line_1": "Un point d'intérêt vient d'être revendiqué."
                        },
                        "cta": "Voir la revendication"
                    },
                    "claim_dispute": {
                        "subject": "Nouvelle contestation !",
                        "content": {
                            "line_1": "Un point d'intérêt vient d'être contesté."
                        },
                        "cta": "Voir la contestation"
                    },
                    "report": {
                        "subject": "Nouveau signalement !",
                        "content": {
                            "line_1": "{type} vient d'être signalé."
                        },
                        "cta": "Voir le signalement",
                        "type": {
                            "App\\Models\\PointOfInterest": "Un point d'intérêt",
                            "App\\Models\\User": "Un utilisateur"
                        }
                    },
                    "support_request": {
                        "subject": "Nouvelle demande de support !",
                        "content": {
                            "line_1": "Une nouvelle demande de support vient d'être ajoutée."
                        },
                        "cta": "Voir la contestation"
                    }
                }
            },
            "nova": {
                "actions": {
                    "payment": {
                        "download_invoice": "Télécharger la facture"
                    },
                    "point_of_interest": {
                        "draft": "Mettre en brouillon",
                        "submit": "Demander la modération",
                        "deny": "Refuser",
                        "publish": "Valider"
                    },
                    "post": {
                        "draft": "Mettre en brouillon",
                        "publish": "Publier",
                        "archive": "Archiver"
                    },
                    "plan": {
                        "draft": "Mettre en brouillon",
                        "publish": "Publier",
                        "archive": "Archiver",
                        "set_coming_soon": "Rendre \"bientôt disponible\""
                    },
                    "privacy_policy": {
                        "publish": "Publier",
                        "confirmation": {
                            "confirm_button_text": "Publier",
                            "confirm_text": "Êtes-vous sur(e) de vouloir publier cette charte de confidentialité ? TOUS les utilisateurs devront valider la nouvelle charte de confidentialité !"
                        },
                        "errors": {
                            "resources_count": "Vous ne pouvez publier qu'UNE charte de confidentialité à la fois !"
                        },
                        "archive": "Archiver"
                    },
                    "terms_condition": {
                        "publish": "Publier",
                        "confirmation": {
                            "confirm_button_text": "Publier",
                            "confirm_text": "Êtes-vous sur(e) de vouloir publier ces conditions générales ? TOUS les utilisateurs devront valider les nouvelles conditions générales !"
                        },
                        "errors": {
                            "resources_count": "Vous ne pouvez publier qu'UNE version des conditions générales à la fois !"
                        },
                        "archive": "Archiver"
                    },
                    "changelog": {
                        "draft": "Mettre en brouillon",
                        "publish": "Publier",
                        "archive": "Archiver"
                    },
                    "report": {
                        "process": "Traité",
                        "reject": "Rejeté"
                    },
                    "claim_dispute": {
                        "reject": "Rejeter",
                        "validate": "Valider"
                    },
                    "support_request": {
                        "answer": "Répondre",
                        "archive": "Archiver",
                        "cancel": "Annuler",
                        "pending_user": "En attente de l'utilisateur",
                        "pending_support": "En attente du support",
                        "resolve": "Résoudre"
                    },
                    "claim": {
                        "reject": "Rejeter",
                        "validate": "Valider",
                        "missing_info": "Infos manquantes",
                        "revoke": "Révoquer"
                    },
                    "user_credential_verification": {
                        "validate": "Valider"
                    },
                    "user": {
                        "finish": "Terminer le tutoriel"
                    },
                    "bank_transfer": {
                        "transfer": "Effectuer le virement bancaire"
                    }
                },
                "cards": {
                    "new_points_of_interest": "Nouveaux Points d'intérêt",
                    "new_excursions": "Nouveaux Itinéraires",
                    "new_users": "Nouveaux Utilisateurs"
                },
                "filters": {
                    "to_validate": "À valider",
                    "group": "Groupe",
                    "front": "Site vitrine",
                    "app": "Application",
                    "extranet": "Extranet",
                    "static_translations_enabled": "Traductions statiques activées",
                    "dynamic_translations_enabled": "Traductions dynamiques activées",
                    "archived_at_lte": "Archivé(e) avant le",
                    "archived_at_gte": "Archivé(e) après le",
                    "canceled_at_lte": "Annulé(e) avant le",
                    "canceled_at_gte": "Annulé(e) après le",
                    "created_at_lte": "Créé(e) avant le",
                    "created_at_gte": "Créé(e) après le",
                    "resolved_at_lte": "Résolu(e) avant le",
                    "resolved_at_gte": "Résolu(e) après le",
                    "reservation_date_lte": "Date de réservation avant le",
                    "reservation_date_gte": "Date de réservation après le",
                    "published_at_lte": "Date de publication avant le",
                    "published_at_gte": "Date de publication après le"
                },
                "labels": {
                    "point_of_interest_categories": "Catégories",
                    "institutions": "Établissements"
                }
            },
            "opening_hours": {
                "content": {
                    "date": "Le {date}",
                    "date_range_from": "À partir du {from}",
                    "date_range_to": "Jusqu'au {to}",
                    "date_range_from_to": "Du {from} au {to}",
                    "hours_range_from": "À partir de {from}",
                    "hours_range_to": "Jusqu'à {to}",
                    "hours_range_from_to": "De {from} à {to}"
                },
                "additional_info": "Informations additionnelles"
            },
            "partners": {
                "index": {
                    "no_results": "Aucun partenaire trouvé",
                    "title": "Nos partenaires",
                    "our_last_partners": "Nos derniers partenaires"
                },
                "customers": {
                    "title": "Nos clients"
                },
                "show": {
                    "link": "Voir le site"
                }
            },
            "payments": {
                "checkout": {
                    "tip_amount": "Montant du pourboire",
                    "cancel": "Le paiement a été annulé",
                    "success": "Paiement effectué avec succès",
                    "custom_amount": "Saisir un montant personnalisé"
                },
                "thanks": {
                    "success": "Remerciement envoyés !"
                },
                "labels": {
                    "payee": "Bénéficiaire :",
                    "payment_service": "Payé via "
                },
                "index": {
                    "no_results": "Aucun paiement trouvé"
                },
                "payable": {
                    "reservation": "Réservation #{id}"
                }
            },
            "pdf": {
                "payments": {
                    "title": "Paiements reçus",
                    "period": "Période du {from} au {to}"
                },
                "receipt": {
                    "receipt_for": "Recu pour paiement a ",
                    "receipt_number": "Reçu pour le paiement #{id}",
                    "paid_amount": "Montant payé",
                    "payment_date": "Date du paiement",
                    "payment_method": "Moyen de paiement",
                    "summary": "Resume",
                    "debited_amount": "Montant débité"
                },
                "common": {
                    "generating": "Fichier PDF en cours de création...",
                    "waiting": "Veuillez patienter..."
                },
                "share": {
                    "details": {
                        "line1": "Nous sommes heureux de partager avec vous votre itinéraire personnalisé.",
                        "line2": "Vous trouverez ci-dessous le contenu de votre itinéraire.",
                        "line3": "Pour accéder aux informations détaillées (audio avec adresse en langue locale, carte\n                interactive, photos, description complète...), cliquez sur le bouton ci-dessous."
                    },
                    "cta": "Consulter l'itinéraire"
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux comptes Stripe",
                        "HAVE": "Avoir un compte Stripe"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux comptes EasyTransac",
                        "HAVE": "Avoir un compte EasyTransac"
                    },
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux accès",
                        "APP": "Accéder à l'application voyageur",
                        "EXTRANET": "Accéder à l'espace professionnel",
                        "TELESCOPE": "Accéder à Telescope",
                        "HORIZON": "Accéder à Horizon",
                        "ADMIN": "Accéder à l'administration",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "Se connecter sans avoir besoin de vérifier son identifiant",
                        "VIEW_BUTLER_DASHBOARD": "Voir le dashboard concierge",
                        "VIEW_MANAGER_DASHBOARD": "Voir le dashboard manager",
                        "VIEW_HOTELIER_DASHBOARD": "Voir le dashboard hôtelier",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "Voir le dashboard office de tourisme",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "Voir le dashboard employé d'office de tourisme"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux journaux d'activité",
                        "CREATE": "Créer un journal d'activité",
                        "VIEW": "Voir un journal d'activité",
                        "VIEW_ANY": "Voir l'index des journaux d'activité",
                        "UPDATE": "Mettre à jour un journal d'activité",
                        "DELETE": "Supprimer un journal d'activité",
                        "FORCE_DELETE": "Forcer la suppression d'un journal d'activité",
                        "RESTORE": "Restaurer un journal d'activité"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux pays",
                        "USE_ANY": "Utiliser n'importe quel pays"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux devises",
                        "CREATE": "Créer une devise",
                        "VIEW": "Voir une devise",
                        "VIEW_ANY": "Voir l'index des devises",
                        "UPDATE": "Mettre à jour une devise",
                        "DELETE": "Supprimer une devise",
                        "FORCE_DELETE": "Forcer la suppression d'une devise",
                        "RESTORE": "Restaurer une devise"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux itinéraires",
                        "CREATE": "Créer un itinéraire",
                        "VIEW": "Voir un itinéraire",
                        "VIEW_ANY": "Voir l'index des itinéraires",
                        "UPDATE": "Mettre à jour un itinéraire",
                        "DELETE": "Supprimer un itinéraire",
                        "FORCE_DELETE": "Forcer la suppression d'un itinéraire",
                        "RESTORE": "Restaurer un itinéraire",
                        "SHARE": "Partager un itinéraire",
                        "VIEW_KIND_UGOZER_PRO": "Voir les itinéraires de type UgoZER Pro",
                        "VIEW_KIND_UGOZER_APP": "Voir les itinéraires de type UgoZER App"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux liaisons entre itinéraires et points d'intérêts",
                        "CREATE": "Créer une liaison entre itinéraire et point d'intérêt",
                        "VIEW": "Voir une liaison entre itinéraire et point d'intérêt",
                        "VIEW_ANY": "Voir l'index des liaisons entre itinéraires et points d'intérêts",
                        "UPDATE": "Mettre à jour une liaison entre itinéraire et point d'intérêt",
                        "DELETE": "Supprimer une liaison entre itinéraire et point d'intérêt",
                        "FORCE_DELETE": "Forcer la suppression d'une liaison entre itinéraire et point d'intérêt",
                        "RESTORE": "Restaurer une liaison entre itinéraire et point d'intérêt"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux médias",
                        "CREATE": "Créer un media",
                        "VIEW": "Voir un media",
                        "VIEW_ANY": "Voir l'index des médias",
                        "UPDATE": "Mettre à jour un media",
                        "DELETE": "Supprimer un media",
                        "FORCE_DELETE": "Forcer la suppression d'un media",
                        "RESTORE": "Restaurer un media"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux paiements",
                        "CREATE": "Créer un paiement",
                        "VIEW": "Voir un paiement",
                        "VIEW_ANY": "Voir l'index des paiements",
                        "UPDATE": "Mettre à jour un paiement",
                        "DELETE": "Supprimer un paiement",
                        "FORCE_DELETE": "Forcer la suppression d'un paiement",
                        "RESTORE": "Restaurer un paiement",
                        "THANK": "Recevoir un remerciement pour un paiement",
                        "GET_RECEIPT": "Recevoir un reçu pour un paiement",
                        "RECEIVE": "Recevoir des paiements"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux permissions",
                        "CREATE": "Créer une permission",
                        "VIEW": "Voir une permission",
                        "VIEW_ANY": "Voir l'index des permissions",
                        "UPDATE": "Mettre à jour une permission",
                        "DELETE": "Supprimer une permission",
                        "FORCE_DELETE": "Forcer la suppression d'une permission",
                        "RESTORE": "Restaurer une permission"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux catégories de points d'intérêt",
                        "CREATE": "Créer une catégorie de points d'intérêt",
                        "VIEW": "Voir une catégorie de points d'intérêt",
                        "VIEW_ANY": "Voir l'index des catégories de points d'intérêt",
                        "UPDATE": "Mettre à jour une catégorie de points d'intérêt",
                        "DELETE": "Supprimer une catégorie de points d'intérêt",
                        "FORCE_DELETE": "Forcer la suppression d'une catégorie de points d'intérêt",
                        "RESTORE": "Restaurer une catégorie de points d'intérêt"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux points d'intérêt",
                        "CREATE": "Créer un point d'intérêt",
                        "VIEW": "Voir un point d'intérêt",
                        "VIEW_ANY": "Voir l'index des points d'intérêt",
                        "UPDATE": "Mettre à jour un point d'intérêt",
                        "DELETE": "Supprimer un point d'intérêt",
                        "FORCE_DELETE": "Forcer la suppression d'un point d'intérêt",
                        "RESTORE": "Restaurer un point d'intérêt",
                        "SHARE": "Partager un point d'intérêt",
                        "PUBLISH": "Publier un point d'intérêt",
                        "DENY": "Refuser un point d'intérêt",
                        "SUBMIT": "Soumettre un point d'intérêt",
                        "DRAFT": "Passer un point d'intérêt en brouillon",
                        "MODERATE": "Modérer un point d'intérêt",
                        "CONTRIBUTE": "Contribuer à un point d'intérêt",
                        "UPDATE_CERTIFIED": "Mettre à jour la certification d'un point d'intérêt",
                        "ADMIN_ANY": "Administrer n'importe quel point d'intérêt",
                        "REPORT": "Signaler un point d'intérêt",
                        "IMPORT": "Importer des points d'intérêt",
                        "CLAIM": "Rejoindre` un point d'intérêt",
                        "CONTEST": "Contester un point d'intérêt",
                        "APPLY": "Demander à être employé"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux réservations",
                        "CREATE": "Créer une réservation",
                        "VIEW": "Voir une réservation",
                        "VIEW_ANY": "Voir l'index des réservations",
                        "UPDATE": "Mettre à jour une réservation",
                        "DELETE": "Supprimer une réservation",
                        "FORCE_DELETE": "Forcer la suppression d'une réservation",
                        "RESTORE": "Restaurer une réservation",
                        "CONFIRM": "Confirmer une réservation",
                        "CANCEL": "Annuler une réservation",
                        "ARCHIVE": "Archiver une réservation"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux rôles",
                        "CREATE": "Créer un rôle",
                        "VIEW": "Voir un rôle",
                        "VIEW_ANY": "Voir l'index des rôles",
                        "UPDATE": "Mettre à jour un rôle",
                        "DELETE": "Supprimer un rôle",
                        "FORCE_DELETE": "Forcer la suppression d'un rôle",
                        "RESTORE": "Restaurer un rôle"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux partages",
                        "CREATE": "Créer un partage",
                        "VIEW": "Voir un partage",
                        "VIEW_ANY": "Voir l'index des partages",
                        "UPDATE": "Mettre à jour un partage",
                        "DELETE": "Supprimer un partage",
                        "FORCE_DELETE": "Forcer la suppression d'un partage",
                        "RESTORE": "Restaurer un partage"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux dossiers voyageur",
                        "CREATE": "Créer un dossier voyageur",
                        "VIEW": "Voir un dossier voyageur",
                        "VIEW_ANY": "Voir l'index des dossiers voyageur",
                        "UPDATE": "Mettre à jour un dossier voyageur",
                        "DELETE": "Supprimer un dossier voyageur",
                        "FORCE_DELETE": "Forcer la suppression d'un dossier voyageur",
                        "RESTORE": "Restaurer un dossier voyageur",
                        "APPROVE": "Approuver un dossier voyageur",
                        "DECLINE": "Refuser un dossier voyageur",
                        "ARCHIVE": "Archiver un dossier voyageur",
                        "THANK": "Remercier un voyageur pour son séjour"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux utilisateurs",
                        "CREATE": "Créer un utilisateur",
                        "VIEW": "Voir un utilisateur",
                        "VIEW_ANY": "Voir l'index des utilisateurs",
                        "UPDATE": "Mettre à jour un utilisateur",
                        "DELETE": "Supprimer un utilisateur",
                        "FORCE_DELETE": "Forcer la suppression d'un utilisateur",
                        "RESTORE": "Restaurer un utilisateur",
                        "VIEW_AVATAR": "Voir l'avatar d'un utilisateur",
                        "VIEW_REAL_NAME": "Voir le nom et le prénom d'un utilisateur",
                        "VIEW_CONTACT_PHONE": "Voir le téléphone de contact d'un utilisateur",
                        "VIEW_CONTACT_EMAIL": "Voir l'e-mail de contact d'un utilisateur",
                        "ADD_CONTACT": "Ajouter un utilisateur en contact",
                        "INVITE": "Inviter un utilisateur",
                        "REPORT": "Signaler un utilisateur",
                        "TIP": "Donner un pourboire à un utilisateur",
                        "UPDATE_BANK_ACCOUNT": "Mettre à jour un compte bancaire"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux contacts",
                        "CREATE": "Créer un contact",
                        "VIEW": "Voir un contact",
                        "VIEW_ANY": "Voir l'index des contacts",
                        "UPDATE": "Mettre à jour un contact",
                        "DELETE": "Supprimer un contact",
                        "FORCE_DELETE": "Forcer la suppression d'un contact",
                        "RESTORE": "Restaurer un contact",
                        "APPROVE_CONTACT": "Approuver un contact",
                        "DECLINE_CONTACT": "Refuser un contact",
                        "BLOCK_CONTACT": "Bloquer un contact",
                        "UNBLOCK_CONTACT": "Débloquer un contact"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux tags",
                        "CREATE": "Créer un tag",
                        "VIEW": "Voir un tag",
                        "VIEW_ANY": "Voir l'index des tags",
                        "UPDATE": "Mettre à jour un tag",
                        "DELETE": "Supprimer un tag",
                        "FORCE_DELETE": "Forcer la suppression d'un tag",
                        "RESTORE": "Restaurer un tag"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux articles",
                        "CREATE": "Créer un article",
                        "VIEW": "Voir un article",
                        "VIEW_ANY": "Voir l'index des articles",
                        "UPDATE": "Mettre à jour un article",
                        "DELETE": "Supprimer un article",
                        "FORCE_DELETE": "Forcer la suppression d'un article",
                        "RESTORE": "Restaurer un article",
                        "DRAFT": "Passer un article en brouillon",
                        "PUBLISH": "Publier un article",
                        "ARCHIVE": "Archiver un article"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux offres d'abonnement",
                        "CREATE": "Créer une offre d'abonnement",
                        "VIEW": "Voir une offre d'abonnement",
                        "VIEW_ANY": "Voir l\\'index des offres d'abonnement",
                        "UPDATE": "Mettre à jour une offre d'abonnement",
                        "DELETE": "Supprimer une offre d'abonnement",
                        "FORCE_DELETE": "Forcer la suppression d\\'une offre d'abonnement",
                        "RESTORE": "Restaurer une offre d'abonnement",
                        "DRAFT": "Passer une offre d'abonnement en brouillon",
                        "PUBLISH": "Publier une offre d'abonnement",
                        "ARCHIVE": "Archiver une offre d'abonnement",
                        "SUBSCRIBE": "S'abonner à une offre d\\'abonnement"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux abonnements",
                        "CREATE": "Créer un abonnement",
                        "VIEW": "Voir un abonnement",
                        "VIEW_ANY": "Voir l'index des abonnements",
                        "UPDATE": "Mettre à jour un abonnement",
                        "DELETE": "Supprimer un abonnement",
                        "FORCE_DELETE": "Forcer la suppression d\\'une offre d'abonnement",
                        "RESTORE": "Restaurer un abonnement",
                        "PAY": "Payer un abonnement",
                        "CANCEL": "Résilier un abonnement"
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux catégories d'articles",
                        "CREATE": "Créer une catégorie d'articles",
                        "VIEW": "Voir une catégorie d'articles",
                        "VIEW_ANY": "Voir l'index des catégories d'articles",
                        "UPDATE": "Mettre à jour une catégorie d'articles",
                        "DELETE": "Supprimer une catégorie d'articles",
                        "FORCE_DELETE": "Forcer la suppression d'une catégorie d'articles",
                        "RESTORE": "Restaurer une catégorie d'articles"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux chartes de confidentialité",
                        "CREATE": "Créer une charte de confidentialité",
                        "VIEW": "Voir une charte de confidentialité",
                        "VIEW_ANY": "Voir l'index des chartes de confidentialité",
                        "UPDATE": "Mettre à jour une charte de confidentialité",
                        "DELETE": "Supprimer une charte de confidentialité",
                        "FORCE_DELETE": "Forcer la suppression d'une charte de confidentialité",
                        "RESTORE": "Restaurer une charte de confidentialité",
                        "DRAFT": "Passer une charte de confidentialité en brouillon",
                        "PUBLISH": "Publier une charte de confidentialité",
                        "ARCHIVE": "Archiver une charte de confidentialité"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux conditions générales",
                        "CREATE": "Créer des conditions générales",
                        "VIEW": "Voir les conditions générales",
                        "VIEW_ANY": "Voir l'index des conditions générales",
                        "UPDATE": "Mettre à jour les conditions générales",
                        "DELETE": "Supprimer les conditions générales",
                        "FORCE_DELETE": "Forcer la suppression des conditions générales",
                        "RESTORE": "Restaurer les conditions générales",
                        "DRAFT": "Passer les conditions générales en brouillon",
                        "PUBLISH": "Publier les conditions générales",
                        "ARCHIVE": "Archiver les conditions générales"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux changelogs",
                        "CREATE": "Créer un changelog",
                        "VIEW": "Voir un changelog",
                        "VIEW_ANY": "Voir l'index des changelogs",
                        "UPDATE": "Mettre à jour un changelog",
                        "DELETE": "Supprimer un changelog",
                        "FORCE_DELETE": "Forcer la suppression d'un changelog",
                        "RESTORE": "Restaurer un changelog",
                        "DRAFT": "Passer un changelog en brouillon",
                        "PUBLISH": "Publier un changelog",
                        "ARCHIVE": "Archiver un changelog"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux interactions",
                        "CREATE": "Créer une interaction",
                        "VIEW": "Voir une interaction",
                        "VIEW_ANY": "Voir l'index des interactions",
                        "UPDATE": "Mettre à jour une interaction",
                        "DELETE": "Supprimer une interaction",
                        "FORCE_DELETE": "Forcer la suppression d'une interaction",
                        "RESTORE": "Restaurer une interaction"
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux signalements",
                        "CREATE": "Créer un signalement",
                        "VIEW": "Voir un signalement",
                        "VIEW_ANY": "Voir l'index des signalements",
                        "UPDATE": "Mettre à jour un signalement",
                        "DELETE": "Supprimer un signalement",
                        "FORCE_DELETE": "Forcer la suppression d'un signalement",
                        "RESTORE": "Restaurer un signalement",
                        "PROCESS_ANY": "Traiter des signalements",
                        "REJECT_ANY": "Rejeter des signalements"
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux partenaires",
                        "CREATE": "Créer un partenaire",
                        "VIEW": "Voir un partenaire",
                        "VIEW_ANY": "Voir l'index des partenaires",
                        "UPDATE": "Mettre à jour un partenaire",
                        "DELETE": "Supprimer un partenaire",
                        "FORCE_DELETE": "Forcer la suppression d'un partenaire",
                        "RESTORE": "Restaurer un partenaire"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux éléments traduisibles",
                        "REQUIRE_ANY": "Autorise à traduire un champ obligatoire dans une langue qui n'est pas forcément l'anglais"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "S'inscrire à l'espace professionnel avec n'importe quel rôle ayant cette permission"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux contestations de revendication",
                        "CREATE": "Créer une contestation de revendication",
                        "VIEW": "Voir une contestation de revendication",
                        "VIEW_ANY": "Voir l'index des contestations de revendication",
                        "UPDATE": "Mettre à jour une contestation de revendication",
                        "DELETE": "Supprimer une contestation de revendication",
                        "FORCE_DELETE": "Forcer la suppression d'une contestation de revendication",
                        "RESTORE": "Restaurer une contestation de revendication",
                        "REJECT": "Rejeter une contestation de revendication",
                        "VALIDATE": "Valider une contestation de revendication"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux catégories de demandes de contact",
                        "CREATE": "Créer une catégorie de demande de contact",
                        "VIEW": "Voir une catégorie de demande de contact",
                        "VIEW_ANY": "Voir l'index des catégories de demande de contact",
                        "UPDATE": "Mettre à jour une catégorie de demande de contact",
                        "DELETE": "Supprimer une catégorie de demande de contact",
                        "FORCE_DELETE": "Forcer la suppression de demande de contact",
                        "RESTORE": "Restaurer une catégorie de demande de contact"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux messages",
                        "CREATE": "Créer un message",
                        "VIEW": "Voir un message",
                        "VIEW_ANY": "Voir l'index des messages",
                        "UPDATE": "Mettre à jour un message",
                        "DELETE": "Supprimer un message",
                        "FORCE_DELETE": "Forcer la suppression d'un message",
                        "RESTORE": "Restaurer un message"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux demandes de contact",
                        "CREATE": "Créer une demande de contact",
                        "VIEW": "Voir une demande de contact",
                        "VIEW_ANY": "Voir l'index des demandes de contact",
                        "UPDATE": "Mettre à jour une demande de contact",
                        "ADD_MESSAGE": "Ajouter un message",
                        "DELETE": "Supprimer une demande de contact",
                        "FORCE_DELETE": "Forcer la suppression d'une demande de contact",
                        "RESTORE": "Restaurer une demande de contact",
                        "CANCEL": "Annuler une demande de contact"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux paramètres de géolocalisation",
                        "CREATE": "Créer un paramètre de géolocalisation",
                        "VIEW": "Voir un paramètre de géolocalisation",
                        "VIEW_ANY": "Voir l'index des paramètres de géolocalisation",
                        "UPDATE": "Mettre à jour un paramètre de géolocalisation",
                        "DELETE": "Supprimer un paramètre de géolocalisation",
                        "FORCE_DELETE": "Forcer la suppression d'un paramètre de géolocalisation",
                        "RESTORE": "Restaurer un paramètre de géolocalisation"
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux paramètres de confidentialité",
                        "CREATE": "Créer un paramètre de confidentialité",
                        "VIEW": "Voir un paramètre de confidentialité",
                        "VIEW_ANY": "Voir l'index des paramètres de confidentialité",
                        "UPDATE": "Mettre à jour un paramètre de confidentialité",
                        "DELETE": "Supprimer un paramètre de confidentialité",
                        "FORCE_DELETE": "Forcer la suppression d'un paramètre de confidentialité",
                        "RESTORE": "Restaurer un paramètre de confidentialité"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux paramètres de notification",
                        "CREATE": "Créer un paramètre de notification",
                        "VIEW": "Voir un paramètre de notification",
                        "VIEW_ANY": "Voir l'index des paramètres de notification",
                        "UPDATE": "Mettre à jour un paramètre de notification",
                        "DELETE": "Supprimer un paramètre de notification",
                        "FORCE_DELETE": "Forcer la suppression d'un paramètre de notification",
                        "RESTORE": "Restaurer un paramètre de notification"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux confirmations d'identifiant",
                        "CREATE": "Créer une confirmation d'identifiant",
                        "VIEW": "Voir une confirmation d'identifiant",
                        "VIEW_ANY": "Voir l'index des confirmations d'identifiant",
                        "UPDATE": "Mettre à jour une confirmation d'identifiant",
                        "DELETE": "Supprimer une confirmation d'identifiant",
                        "FORCE_DELETE": "Forcer la suppression d'une confirmation d'identifiant",
                        "RESTORE": "Restaurer une confirmation d'identifiant"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux adresses",
                        "CREATE": "Créer une adresse",
                        "VIEW": "Voir une adresse",
                        "VIEW_ANY": "Voir l'index des adresses",
                        "UPDATE": "Mettre à jour une adresse",
                        "DELETE": "Supprimer une adresse",
                        "FORCE_DELETE": "Forcer la suppression d'une adresse",
                        "RESTORE": "Restaurer une adresse"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux langues",
                        "CREATE": "Créer une langue",
                        "VIEW": "Voir une langue",
                        "VIEW_ANY": "Voir l'index des langues",
                        "UPDATE": "Mettre à jour une langue",
                        "DELETE": "Supprimer une langue",
                        "FORCE_DELETE": "Forcer la suppression d'une langue",
                        "RESTORE": "Restaurer une langue"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux revendications",
                        "CREATE": "Créer une revendication",
                        "VIEW": "Voir une revendication",
                        "VIEW_ANY": "Voir l'index des revendications",
                        "UPDATE": "Mettre à jour une revendication",
                        "DELETE": "Supprimer une revendication",
                        "FORCE_DELETE": "Forcer la suppression d'une revendication",
                        "RESTORE": "Restaurer une revendication",
                        "REJECT": "Rejeter une revendication",
                        "VALIDATE": "Valider une revendication",
                        "MISSING_INFO": "Rejeter une revendication pour infos manquantes",
                        "SHOW_FILE": "Visualiser le fichier",
                        "REVOKE": "Révoquer une revendication"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "Recevoir une notification lors de l'acceptation d'une invitation",
                        "PAYMENT_RECEIVED": "Recevoir une notification lors de la reception d'un paiement",
                        "PAYMENT_THANKS": "Recevoir une notification lors du remerciement d'un paiement",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "Recevoir une notification lors de l'expiration d'un point \n                d'intérêt",
                        "RESERVATION_CREATED": "Recevoir une notification lors de création d'une réservation",
                        "SHARE_CREATED": "Recevoir une notification lors de la création d'un partage",
                        "SUPPORT_REQUEST_ARCHIVED": "Recevoir une notification lors de l'archivage d'une demande de support",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "Recevoir une notification lors de la reception d'un message d'une \n                demande de support",
                        "TRAVELLER_FOLDER_APPROVED": "Recevoir une notification lors de l'approbation d'un dossier voyageur",
                        "TRAVELLER_FOLDER_ARCHIVED": "Recevoir une notification lors de l'archivage d'un dossier voyageur",
                        "TRAVELLER_FOLDER_PENDING": "Recevoir une notification lors de l'attente de validation d'un dossier \n                voyageur",
                        "TRAVELLER_FOLDER_THANKS": "Recevoir une notification pour remercier le concierge en charge d'un dossier \n                voyageur"
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux employés d'établissements",
                        "CREATE": "Créer un employé d'établissement",
                        "VIEW": "Voir un employé d'établissement",
                        "VIEW_ANY": "Voir l'index des employés d'établissements",
                        "UPDATE": "Mettre à jour un employé d'établissement",
                        "DELETE": "Supprimer un employé d'établissement",
                        "FORCE_DELETE": "Forcer la suppression d'un employé d'établissement",
                        "RESTORE": "Restaurer un employé d'établissement",
                        "APPROVE": "Approuver une demande pour être employé d'établissement",
                        "DECLINE": "Rejeter une demande pour être employé d'établissement",
                        "CANCEL": "Annuler une demande pour être employé d'établissement",
                        "REMOVE": "Retirer un employé d'un établissement",
                        "FEATURE": "Mettre en avant un employé d'un établissement"
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux emails voyageurs",
                        "CREATE": "Créer un email voyageur",
                        "VIEW": "Voir un email voyageur",
                        "VIEW_ANY": "Voir l'index des emails voyageurs",
                        "UPDATE": "Mettre à jour un email voyageur",
                        "DELETE": "Supprimer un email voyageur",
                        "FORCE_DELETE": "Forcer la suppression d'un email voyageur",
                        "RESTORE": "Restaurer un email voyageur"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorer les permissions liées aux virements bancaires par institutions",
                        "VIEW_ANY": "Voir l'index des virements bancaires par institutions"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\ExcursionEnum": "Itinéraires",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "Points d'intérêt",
                    "App\\Enums\\Permissions\\ReservationEnum": "Réservations",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "Catégories de point d'intérêt",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "Dossiers voyageur",
                    "App\\Enums\\Permissions\\UserEnum": "Utilisateurs",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "Contacts",
                    "App\\Enums\\Permissions\\PermissionEnum": "Permissions",
                    "App\\Enums\\Permissions\\RoleEnum": "Rôles",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "Liaisons entre itinéraires et points d'intérêt",
                    "App\\Enums\\Permissions\\MediumEnum": "Médias",
                    "App\\Enums\\Permissions\\ShareEnum": "Partages",
                    "App\\Enums\\Permissions\\CurrencyEnum": "Devises",
                    "App\\Enums\\Permissions\\CountryEnum": "Pays",
                    "App\\Enums\\Permissions\\AccessEnum": "Accès",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "Journaux d'activité",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "Comptes Stripe",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "Comptes EasyTransac",
                    "App\\Enums\\Permissions\\TagEnum": "Tags",
                    "App\\Enums\\Permissions\\PaymentEnum": "Paiements",
                    "App\\Enums\\Permissions\\PostEnum": "Articles",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "Catégories d'articles",
                    "App\\Enums\\Permissions\\PlanEnum": "Offres d'abonnement",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "Abonnements",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "Conditions générales",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "Chartes de confidentialité",
                    "App\\Enums\\Permissions\\ChangelogEnum": "Changelogs",
                    "App\\Enums\\Permissions\\ReportEnum": "Signalements",
                    "App\\Enums\\Permissions\\InteractionEnum": "Interactions",
                    "App\\Enums\\Permissions\\PartnerEnum": "Partenaires",
                    "App\\Enums\\Permissions\\TranslatableEnum": "Éléments traduisibles",
                    "App\\Enums\\Permissions\\RegistrationEnum": "Inscription",
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": "Contestations de revendication",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "Catégories de demandes de contact",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "Messages de contact au support",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "Demandes de contact",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "Paramètres de géolocalisation",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "Paramètres de confidentialité",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "Paramètres de notification",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "Confirmations d'identifiant",
                    "App\\Enums\\Permissions\\AddressEnum": "Adresses",
                    "App\\Enums\\Permissions\\LocaleEnum": "Langues",
                    "App\\Enums\\Permissions\\ClaimEnum": "Revendications",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "Recevoir des notifications",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "Employés d'établissements",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "Emails voyageurs",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "Virement bancaire par établissement"
                }
            },
            "plans": {
                "publish": {
                    "success": "Offre(s) d'abonnement publiée(s) (si éligible)"
                },
                "draft": {
                    "success": "Offre(s) d'abonnement mise en brouillon (si éligible)"
                },
                "archive": {
                    "success": "Offre(s) d'abonnement archivée(s) (si éligible)"
                },
                "set_coming_soon": {
                    "success": "Offre(s) d'abonnement rendue(s) \"bientôt disponible\" (si éligible)"
                },
                "confirmation": {
                    "name": "Vous êtes sur le point de vous abonner à l'offre \"{name}\".",
                    "name_launch": "Vous êtes sur le point de vous abonner à l'offre de lancement \"{name}\".",
                    "no_fee": "Cette offre donne lieu à des frais de 0%, et vous permettra de toucher vos\n            pourboires dans leur intégralité.",
                    "fee": "Cette offre donne lieu à des frais de {fee_percentage}% et vous permettra de\n            toucher {tip_percentage}% de vos pourboires.",
                    "free_period": "Vous bénéficiez d'une période de gratuité d'une durée de {free_period}.",
                    "free_period_used": "Vous avez déjà utilisé votre période de gratuité et\n            ne pourrez pas bénéficier de la période d'une durée de {free_period} proposée par cette offre.",
                    "engagement_period": "Cette offre comprend une période d'engagement d'une durée\n            de {engagement_period} après le 1er paiement.",
                    "free": "Cet abonnement est gratuit sous réserve de création d’un compte pour la perception des pourboires.",
                    "please_confirm": "Merci de confirmer votre abonnement en cliquant sur le bouton ci-dessous.",
                    "price_summary": "Cet abonnement vous coûtera {price} HT / {price_incl_taxes} TTC par mois.",
                    "benefit_current_subscription": "Vous continuerez à bénéficier de votre abonnement actuel\n            jusqu'à la fin du mois déjà payé.",
                    "replace_current_subscription": "Cet abonnement remplacera automatiquement votre abonnement actuel."
                },
                "free_period_ended_in": "Période de gratuité se termine {in}",
                "your_current_plan": "Votre offre actuelle"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "En cochant cette case, je certifie être un représentant autorisé de l’établissement \n            revendiqué. Toute fausse déclaration ou usurpation m’expose à des sanctions civiles et pénales \n            conformément aux lois en vigueur.",
                    "success": "Votre revendication a bien été soumise à vérification."
                },
                "destroy": {
                    "confirm": "Êtes-vous sur(e) de vouloir supprimer ce point d'intérêt : {point_of_interest_name} ?",
                    "success": "Le point d'intérêt a été supprimé"
                },
                "store": {
                    "success": "Le point d'intérêt a été créé",
                    "heading": "Créer un point d'intérêt"
                },
                "update": {
                    "success": "Le point d'intérêt a été modifié",
                    "heading": "Modifier ce point d'intérêt",
                    "confirm": "Êtes-vous sur(e) de vouloir modifier ce point d'intérêt : {point_of_interest_name} ?",
                    "alert": "En modifiant ce point, la mise à jour automatique par {import_source} sera désactivée"
                },
                "index": {
                    "no_results_here": "Aucun point d'intérêt n'est disponible dans votre zone de recherche.",
                    "try_more_filters": "Vous pouvez modifier vos filtres actifs et élargir votre recherche.",
                    "no_results": "Aucun point d'intérêt trouvé",
                    "no_tags": "Aucun tag défini",
                    "search": "Rechercher un point d'intérêt",
                    "refresh_delayed": "Merci de patienter {time_remaining} avant de pouvoir actualiser"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "Ajouter ce point d'intérêt à un itinéraire",
                    "select_which": "Sélectionner l'itinéraire auquel ajouter le point d'intérêt \"{point_of_interest_name}\"",
                    "already_in_excursions": "Ce point d'intérêt fait déjà partie d'un itinéraire",
                    "create_excursion": "Créer un itinéraire",
                    "success": "Le point d'intérêt a été ajouté à l'itinéraire",
                    "or_create_from": "Ou créer un nouvel itinéraire à partir de ce point d’intérêt"
                },
                "denied": {
                    "for_the_reason": "Refusé pour la raison suivante",
                    "moderation_again": "Vous pouvez modifier le point d'intérêt, ce qui le soumettra une nouvelle fois à modération, afin que celui-ci soit publié."
                },
                "deny": {
                    "success": "Point(s) d'intérêt refusé(s) (si éligible)"
                },
                "draft": {
                    "success": "Point(s) d'intérêt mis en brouillon (si éligible)"
                },
                "submit": {
                    "success": "Point(s) d'intérêt mis en attente de modération (si éligible)"
                },
                "publish": {
                    "success": "Point(s) d'intérêt validé(s) et publié(s) (si éligible)"
                },
                "search": {
                    "no_results": "Aucun point d'intérêt trouvé",
                    "creator_id": "Afficher uniquement mes points ?",
                    "tags_mode": {
                        "one": "Au moins un",
                        "one_help": "Sélectionner \"au moins un\", pour trouver les points d'intérêts\n                ayant au moins un des tags sélectionnés.",
                        "all": "Tous",
                        "all_help": "Sélectionner \"tous\", pour trouver les points d'intérêts\n                ayant tous les tags sélectionnés."
                    }
                },
                "share": {
                    "heading": "Partager ce point d'intérêt",
                    "select_which_users": "Sélectionnez les utilisateurs avec qui vous voulez partager le point d'intérêt\n            \"{point_of_interest_title}\" ou saisissez une adresse e-mail pour inviter un nouveau voyageur",
                    "select_which_users_alt": "Saisissez l'adresse e-mail de la personne avec qui vous voulez partager le\n            point d'intérêt \"{point_of_interest_title}\"",
                    "select_which_contacts": "Sélectionnez les contacts avec qui vous voulez partager le point d'intérêt \"{point_of_interest_title}\"",
                    "success": "Le point d'intérêt a été partagé !"
                },
                "states": {
                    "draft": "Brouillon",
                    "pending": "En attente",
                    "published": "Publié"
                },
                "form": {
                    "help": {
                        "stars": "Seulement si la catégorie utilise des étoiles (comme Hôtel, Restaurant...)"
                    }
                },
                "report": {
                    "heading": "Signaler un point d'intérêt",
                    "choose_reason": "Sélectionnez une raison pour le signalement du point d'intérêt \"{point_of_interest_title}\"",
                    "success": "Le point d'intérêt a été signalé !"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "poi": "Point d'intérêt",
                            "map": "Carte",
                            "media": "Média",
                            "main_image": "Image principale",
                            "gallery": "Galerie",
                            "audios": "Audios",
                            "videos": "Vidéos",
                            "logo": "Logo"
                        },
                        "excursions_counter": "A été ajouté {counter} fois à des itinéraires",
                        "is_geocoding_1": "Le point d'intérêt est en cours de géolocalisation.",
                        "is_geocoding_2": "Veuillez consulter cette page ultérieurement pour voir le point d'intérêt\n                sur la carte.",
                        "is_refreshing_audio_1": "Un fichier audio est en cours de génération.",
                        "is_refreshing_audio_2": "Veuillez consulter cette page ultérieurement pour écouter cet audio.",
                        "belongs_to_you": "Vous appartient",
                        "see_full_page": "Accéder à la page complète",
                        "not_current_locale": {
                            "missing_translations": "Traductions manquantes",
                            "incomplete_translation": "Certaines informations de ce point d'intérêt (titre, description et/ou adresse) ne sont pas disponibles dans la langue sélectionnée.",
                            "change_locale": "Vous pouvez changer la langue d'affichage via le sélecteur ci-dessus"
                        },
                        "claim_dispute_pending": {
                            "notice": "Contestation en cours",
                            "title": "Vous avez une contestation en cours sur ce point d'intérêt.",
                            "details": "Détails de la demande : {message}"
                        },
                        "automatic_update": "Mise à jour automatique",
                        "dt_required_datatourisme_fields": "Mis à jour par {dt_created_by} via {import_source} le {dt_last_update}",
                        "opening_hours": {
                            "show": "Voir les horaires d'ouverture",
                            "is_deleting": "Le point d'intérêt sera supprimé dans {days} jours",
                            "expired": "Horaires expirés"
                        },
                        "deletion": {
                            "title": "En attente de suppression",
                            "causes": {
                                "event": "Cause : événement expiré"
                            }
                        }
                    }
                },
                "print": {
                    "element_not_found": "Elément à imprimer \"{id}\" non trouvé !"
                },
                "import_source": {
                    "DataTourisme": "DataTourisme"
                },
                "duplicates": {
                    "match_percentage": "Correspond à {percentage}%",
                    "check_explanation_title": "Vérification",
                    "check_explanation1": "Afin de créer votre point d'intérêt,\n            merci de renseigner les champs principaux ci-dessous.",
                    "check_explanation2": "Une vérification sera effectuée\n            pour éviter la saisie d'un point déjà existant.",
                    "check_explanation3": "Après vérification, vous pourrez compléter\n            les informations de votre point et l'enregistrer.",
                    "found_explanation1": "Un ou plusieurs points d'intérêt correspondant à\n            vos critères de saisies existent déjà.",
                    "found_explanation2": "Si vous estimez qu'il ne s'agit pas d'un doublon,\n            alors vous pouvez poursuivre la saisie de votre nouveau point d'intérêt.",
                    "continue_creation": "Poursuivre la création",
                    "view_poi": "Consulter ce point d'intérêt",
                    "select_duplicate": "Cliquez sur l'un des points d'intérêt proposés pour en voir le détail",
                    "use_poi": "Utiliser ce point d'intérêt"
                }
            },
            "posts": {
                "publish": {
                    "success": "Article(s) publié(s) (si éligible)"
                },
                "draft": {
                    "success": "Article(s) mis en brouillon (si éligible)"
                },
                "archive": {
                    "success": "Article(s) archivé(s) (si éligible)"
                },
                "search": {
                    "type_to_search": "Rechercher dans la catégorie selectionnée..."
                }
            },
            "prices": {
                "free": "Gratuit",
                "price_incl_taxes": "Prix TTC",
                "price_excl_taxes": "Prix HT",
                "incl_taxes": "TTC",
                "excl_taxes": "HT",
                "from": "À partir de",
                "per_month": "Par mois",
                "vat_amount": "Montant de la TVA",
                "vat_percentage": "Taux de TVA"
            },
            "privacy_policies": {
                "publish": {
                    "success": "Charte de confidentialité publiée"
                },
                "archive": {
                    "success": "Charte(s) de confidentialité archivée(s) (si éligible)"
                },
                "errors": {
                    "not_found": "Aucune charte de confidentialité trouvée !"
                },
                "update": {
                    "new_version": "Une nouvelle version de la charte de confidentialité a été publiée",
                    "acceptation": "Vous devez accepter la charte de confidentialité pour pouvoir continuer",
                    "success": "Charte de confidentialité acceptée !"
                }
            },
            "reports": {
                "process": {
                    "success": "Signalement(s) traité(s) (si éligible)"
                },
                "reject": {
                    "success": "Signalement(s) rejeté(s) (si éligible)"
                }
            },
            "reservations": {
                "destroy": {
                    "confirm": "Êtes-vous sur(e) de vouloir supprimer cette réservation ?",
                    "success": "La réservation a été supprimée"
                },
                "store": {
                    "success": "La réservation a été créée"
                },
                "update": {
                    "success": "La réservation a été modifiée"
                },
                "confirm": {
                    "confirm": "Êtes-vous sur(e) de vouloir confirmer cette réservation ?",
                    "success": "La réservation a été confirmée"
                },
                "cancel": {
                    "confirm": "Êtes-vous sur(e) de vouloir annuler cette réservation ?",
                    "success": "La réservation a été annulée"
                },
                "archive": {
                    "confirm": "Êtes-vous sur(e) de vouloir archiver cette réservation ?",
                    "success": "La réservation a été archivée"
                },
                "states": {
                    "pending": "A faire",
                    "confirmed": "Confirmée",
                    "canceled": "Annulée",
                    "archived": "Archivée"
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "poi": "voyageur",
                                "traveller": "établissement",
                                "butler": "voyageur"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "reservation": "Réservation",
                            "poi": "Point d'intérêt",
                            "butler": "Concierge",
                            "traveller": "Voyageur"
                        }
                    }
                }
            },
            "settings": {
                "items": {
                    "profile": "Profil",
                    "privacy_setting": "Paramètres de confidentialité",
                    "geoloc_settings": "Paramètres de géolocalisation",
                    "notification_settings": "Paramètres de notifications",
                    "signOut": "Se déconnecter",
                    "about": "A propos"
                }
            },
            "shares": {
                "index": {
                    "no_results": "Aucun partage trouvé !"
                },
                "show": {
                    "shared_by": "Partagé par"
                },
                "type": {
                    "pointsofinterest": "Point d'intérêt",
                    "excursions": "Itinéraire"
                },
                "link": {
                    "instructions": "Copiez ce lien pour inviter un voyageur et lui partager cet itinéraire"
                },
                "emails": {
                    "space_hint": "Séparez par un espace pour partager à plusieurs emails",
                    "select_hint": "Vous pouvez sélectionner un e-mail précédemment utilisé en cliquant ci-dessous :"
                }
            },
            "states": {
                "point_of_interest_state": {
                    "draft": "Brouillon",
                    "pending": "À modérer",
                    "published": "Publié",
                    "deleted": "Supprimé",
                    "denied": "Refusé"
                },
                "users_relationship_state": {
                    "pending": "En attente",
                    "approved": "Accepté",
                    "declined": "Refusé",
                    "blocked": "Bloqué"
                },
                "traveller_folder_state": {
                    "pending": "En attente",
                    "approved": "Accepté",
                    "declined": "Refusé",
                    "archived": "Archivé"
                },
                "payment_state": {
                    "pending": "En attente",
                    "completed": "Terminé",
                    "canceled": "Annulé",
                    "failed": "Echoué",
                    "transferred": "Transféré"
                },
                "post_state": {
                    "draft": "Brouillon",
                    "published": "Publié",
                    "archived": "Archivé"
                },
                "plan_state": {
                    "draft": "Brouillon",
                    "published": "Publié",
                    "archived": "Archivé",
                    "coming_soon": "Bientôt disponible"
                },
                "subscription_state": {
                    "active": "Actif",
                    "canceled": "Résilié",
                    "pending": "En attente de paiement",
                    "suspended": "Suspendu"
                },
                "privacy_policy_state": {
                    "draft": "Brouillon",
                    "published": "Publié",
                    "archived": "Archivé"
                },
                "terms_condition_state": {
                    "draft": "Brouillon",
                    "published": "Publié",
                    "archived": "Archivé"
                },
                "changelog_state": {
                    "draft": "Brouillon",
                    "published": "Publié",
                    "archived": "Archivé"
                },
                "report_state": {
                    "pending": "En attente",
                    "processed": "Traité",
                    "rejected": "Rejeté"
                },
                "user_credential_verification_state": {
                    "pending": "En attente",
                    "completed": "Terminé",
                    "canceled": "Annulé"
                },
                "claim_dispute_state": {
                    "pending": "En attente",
                    "rejected": "Rejetée",
                    "validated": "Validée"
                },
                "support_request_state": {
                    "pending-support": "En attente du support",
                    "pending-user": "En attente de l'utilisateur",
                    "archived": "Archivée",
                    "canceled": "Annulée",
                    "resolved": "Résolue"
                },
                "reservation_state": {
                    "pending": "En attente",
                    "confirmed": "Confirmée",
                    "canceled": "Annulée",
                    "archived": "Archivée"
                },
                "claim_state": {
                    "pending": "En attente",
                    "missing-info": "Informations manquantes",
                    "rejected": "Rejetée",
                    "validated": "Validée",
                    "revoked": "Révoquée"
                },
                "user_tutorial_state": {
                    "claimed": "Revendiqué",
                    "done": "Terminé",
                    "employee-added": "Employé ajouté",
                    "pending": "En attente"
                },
                "user_utm_source_state": {
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "linkedin": "LinkedIn"
                },
                "user_utm_medium_state": {
                    "owned_social": "Réseau social"
                },
                "user_utm_campaign_state": {
                    "always_on": "Toujours actif"
                }
            },
            "stripe": {
                "stripe": "Stripe",
                "connect": {
                    "account": {
                        "create": {
                            "completed": "La configuration du compte Stripe est terminée",
                            "link_invalid": "La connexion à Stripe a échouée, veuillez réessayer"
                        },
                        "show": {
                            "no_account_yet": "Vous n'avez pas encore de compte Stripe.",
                            "account_usage": "Votre compte Stripe permettra à votre établissement de recevoir des paiements sur UgoZER.",
                            "can_receive_payments": "Vous pouvez recevoir des paiements.",
                            "fee_percentage": "Des frais de {percentage}% seront appliqués à chaque paiement que vous recevrez.",
                            "cannot_receive_payments": "Vous ne pouvez pas recevoir de paiements",
                            "should_complete_stripe_onboarding": "Veuillez compléter votre inscription à Stripe et leur fournir les informations demandées.",
                            "has_stripe_account_being_reviewed": "Votre compte est en cours de vérification par Stripe.",
                            "go_to_stripe": "Accéder au tableau de bord Stripe",
                            "must_subscribe": "Vous devez être abonné pour pouvoir créer votre compte Stripe."
                        }
                    }
                }
            },
            "subscriptions": {
                "my_current_subscription": "Mon abonnement actuel",
                "upcoming_subscription": "Abonnement à venir",
                "price_excl_taxes": "Prix HT",
                "price_incl_taxes": "Prix TTC",
                "expiry": "Expiration",
                "canceled_at": "Résiliation",
                "activated_at": "Activation",
                "invoices": "Factures",
                "cancel": "Résilier",
                "confirm_cancel": "Voulez-vous vraiment résilier votre abonnement ?",
                "confirm_cancel_date": "Vous pourrez profiter de ses avantages jusqu'à sa\n        date de résiliation effective le {date}.",
                "confirm_cancel_now": "La résiliation prendra effet immédiatement.",
                "cancelation_success": "Résiliation prise en compte"
            },
            "support_messages": {
                "store": {
                    "success": {
                        "title": "Le message a bien été envoyé. Le support apportera une réponse sous 48h."
                    },
                    "heading": "Ajouter un message"
                },
                "show": {
                    "your_question": "Votre question",
                    "you": "Vous"
                }
            },
            "support_requests": {
                "index": {
                    "your_requests": "Vos demandes de contact",
                    "no_results": "Vous n’avez pas de demande de contact",
                    "search": "Rechercher une demande de contact"
                },
                "show": {
                    "add_request": "Nouvelle demande de contact"
                },
                "store": {
                    "success": {
                        "title": "La demande de contact a bien été créée. Le support apportera une réponse sous 48h."
                    },
                    "heading": "Créer une nouvelle demande de contact"
                },
                "states": {
                    "pending-support": "En attente du support",
                    "pending-user": "En attente de l'utilisateur",
                    "archived": "Archivée",
                    "canceled": "Annulée",
                    "resolved": "Résolue"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "request": "Demande",
                            "messages": "Messages"
                        }
                    },
                    "index": {
                        "search": "Rechercher une demande"
                    },
                    "create": {
                        "notice": "Données personnelles"
                    }
                },
                "actions": {
                    "archived": {
                        "success": "La demande est archivée"
                    },
                    "canceled": {
                        "success": "La demande est annulée"
                    },
                    "pending_user": {
                        "success": "En attente de l'utilisateur"
                    },
                    "pending_support": {
                        "success": "En attente du support"
                    },
                    "resolved": {
                        "success": "La demande est résolue"
                    },
                    "failed": "L'état n'a pas pu être modifié"
                },
                "cancel": {
                    "confirm": "Êtes-vous sur(e) de vouloir annuler cette demande de contact : {support_request_title} ?",
                    "success": "La demande de contact a été annulée",
                    "error": "La demande de contact n'a pas pu être annulée"
                }
            },
            "terms_conditions": {
                "publish": {
                    "success": "Conditions générales publiées"
                },
                "archive": {
                    "success": "Conditions générales archivée(s) (si éligible)"
                },
                "errors": {
                    "not_found": "Aucunes conditions générales trouvées !"
                },
                "update": {
                    "new_version": "Une nouvelle version des conditions générales a été publiée",
                    "acceptation": "Vous devez accepter les conditions générales pour pouvoir continuer",
                    "success": "Conditions générales acceptées !"
                }
            },
            "traveller_folders": {
                "states": {
                    "pending": "En attente",
                    "approved": "Accepté",
                    "declined": "Refusé",
                    "archived": "Archivé",
                    "undefined": "Non défini"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "traveller": "Voyageur",
                            "folder": "Dossier",
                            "butler": "Concierge lié",
                            "institution": "Établissement lié",
                            "hotel": "Hôtel",
                            "reservations": "Réservations",
                            "invitation": "Inviter un voyageur",
                            "thanks": "Remerciement",
                            "interactions": "Interactions",
                            "payments": "Paiements",
                            "stay": "Séjour"
                        },
                        "fields": {
                            "state": "État :"
                        }
                    },
                    "index": {
                        "search": "Rechercher parmi mes voyageurs",
                        "your_stays": "Vos séjours",
                        "travellers_folders": "Dossiers voyageurs",
                        "pending_stays": "Vos séjours en attente"
                    },
                    "form": {
                        "poi_empty_option": "Retirer le point d'intérêt",
                        "poi_label": "Etablissement du concierge",
                        "verification": {
                            "title": "Vérification",
                            "description": "Saisissez l'adresse e-mail du voyageur. Si un compte voyageur existe déjà, \n                    il sera directement rattaché, sinon vous devrez saisir quelques informations complémentaires\n                    pour en créer un nouveau."
                        },
                        "cannot_attach": {
                            "title": "Prise en charge impossible",
                            "description": "Cet utilisateur existe mais ne peut pas être pris en charge..."
                        }
                    },
                    "qr_code_1": "Un voyageur peut scanner ce QR code afin d'avoir automatiquement un dossier voyageur\n                avec votre établissement.",
                    "qr_code_2": "Si le voyageur n'est pas encore inscrit sur UgoZER, il sera redirigé vers \n            la page d'inscription et sera lié à votre établissement à la fin de son inscription.",
                    "wizard": {
                        "no_linked_institution": "Vous devez être lié à un établissement avant de pouvoir prendre en charge un voyageur.",
                        "choose_an_institution": "Choisir un établissement"
                    }
                },
                "store": {
                    "success": "Le dossier a été créé"
                },
                "update": {
                    "success": "Le dossier a été modifié"
                },
                "thank": {
                    "success": "Les remerciements ont été envoyés"
                },
                "messages": {
                    "approve": {
                        "confirm": "Êtes-vous sur(e) de vouloir confirmer ce séjour ?",
                        "success": "Séjour confirmé"
                    },
                    "decline": {
                        "confirm": "Êtes-vous sur(e) de vouloir refuser ce séjour ?",
                        "success": "Séjour refusé"
                    },
                    "archive": {
                        "confirm": "Êtes-vous sur(e) de vouloir archiver ce séjour ?",
                        "success": "Ce séjour a été archivé !"
                    }
                },
                "tabs": {
                    "approved": "Approuvés",
                    "pending": "En attente",
                    "archived": "Archivés",
                    "declined": "Refusés"
                },
                "no_results": {
                    "approved": "Aucun séjour approuvé",
                    "pending": "Aucune demande reçue",
                    "archived": "Aucun séjour archivé",
                    "declined": "Aucun séjour refusé"
                },
                "placeholders": {
                    "butler": "Team réception"
                }
            },
            "tutorials": {
                "extranet": {
                    "dashboard": {
                        "claim": {
                            "title": "Rejoindre la propriété et la gestion de mon établissement",
                            "subtitle_1": "Vous pouvez rejoindre un (ou plusieurs) établissement(s) afin de pouvoir les gérer.",
                            "subtitle_2": "Pour cela :",
                            "steps": {
                                "poi_index": {
                                    "part_1": "Allez dans la",
                                    "part_2": "liste des points d'intérêt"
                                },
                                "filters": "Utilisez les filtres et la recherche pour trouver votre établissement",
                                "exist": "Si votre établissement existe, allez sur sa fiche et cliquez sur le bouton <strong>\n                        Rejoindre</strong>",
                                "dont_exist": "Si il n'existe pas, cliquez sur le bouton <strong>Créer un point d'intérêt\n                        </strong> puis remplissez le formulaire. Une fois le point d'intérêt créé, allez sur sa \n                        fiche et cliquez sur le bouton <strong>Rejoindre</strong>",
                                "claim_form": "Suivez ensuite les instructions du formulaire de revendication",
                                "claim_validation": "La revendication sera ensuite vérifiée et validée par notre équipe"
                            }
                        },
                        "employees": {
                            "title": "Gérer mes employés",
                            "subtitle": {
                                "part_1": "Vous pouvez gérer vos employés directement via la page",
                                "part_2": "Mes employés"
                            },
                            "manage": "Vous pouvez sur cette page accepter ou refuser les employés qui veulent se lier à \n                    votre établissement.",
                            "link": "Pour créer une demande, ils peuvent aller sur la page <strong>Mon établissement</strong>\n                    de leur espace et remplir le formulaire."
                        }
                    },
                    "points_of_interest": {
                        "claim": "Revendication",
                        "filters": {
                            "main_title": "Filtres",
                            "title": "Filtrer les points d'intérêts",
                            "subtitle": "Vous pouvez filtrer la liste des points d'intêrets selon les critères suivants :",
                            "category": "<strong>Catégorie :</strong> permet de choisir une catégorie spécifique (Hôtel, \n                    restaurant...)",
                            "tags": "<strong>Affiner :</strong> permet d'affiner la recherche par tags pour préciser la \n                    catégorie. Il est aussi possible de définir si vous souhaitez chercher les points possédants un \n                    ou tous les tags sélectionnés",
                            "my_points": "<strong>Afficher uniquement mes points :</strong> n'affiche que les points que vous \n                    avez créés ou que vous avez rattachés",
                            "postal_code": "<strong>Code postal :</strong> permet de filtrer par code postal",
                            "city": "<strong>Ville :</strong> permet de filtrer sur une ville spécifique",
                            "country": "<strong>Pays :</strong> permet de filtrer sur un ou plusieurs pays spécifique(s)",
                            "distance": "<strong>Lieu :</strong> permet de filtrer les points depuis un lieu précis",
                            "radius": "<strong>Limiter à un rayon :</strong> permet de filtrer les points dans un cercle de \n                    rayon spécifique",
                            "measure_unit": "<strong>Unité de mesure :</strong> permet de changer l'unité de mesure \n                    utilisée pour le filtre par distance et l'affichage"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "main_title": "Filtres",
                            "title": "Filtrer les itinéraires",
                            "subtitle": "Vous pouvez filtrer la liste des itinéraires selon les critères suivants :",
                            "distance": "<strong>Lieu :</strong> permet de filtrer les itinéraires depuis un lieu précis",
                            "type": {
                                "explanation": "<strong>Type :</strong> permet de filtrer par type d'itinéraires",
                                "customized": "<strong>Sur mesure :</strong> les itinéraires créées spécifiquement pour un\n                        voyageur",
                                "root": "<strong>Mes modèles :</strong> les itinéraires modèles",
                                "pro": "<strong>UgoZER Pro :</strong> les itinéraires fournies par UgoZER"
                            }
                        }
                    },
                    "institution_employee": {
                        "functioning": {
                            "main_title": "Fonctionnement",
                            "title": "Fonctionnement de la section employés",
                            "content": {
                                "section": "Cette section permet de gérér les employé(e)s de vos établissements.",
                                "invite": "Vous pouvez sur cette page, soit inviter des employé(e)s, soit accepter / refuser\n                        les demandes de liaison de vos employé(e)s."
                            }
                        },
                        "invite": {
                            "main_title": "Inviter",
                            "title": "Inviter un(e) employé(e)",
                            "content": {
                                "button": "En cliquant sur le bouton <strong>Inviter un employé</strong> vous pouvez inviter\n                        un(e) de vos employé(e)s qu'il / elle ait déjà un compte UgoZER ou non.",
                                "form": "Pour cela, il vous suffit simplement de suivre les instructions du formulaire à\n                        étapes qui s'affiche."
                            }
                        },
                        "approve": {
                            "main_title": "Approuver",
                            "title": "Approuver un(e) employé(e)",
                            "content": "Cette action vous permet d'accepter la demande d'un(e) de vos employé(s) de rejoindre\n                    l'un de vos établissements."
                        },
                        "decline": {
                            "main_title": "Refuser",
                            "title": "Refuser un(e) employé(e)",
                            "content": "Cette action vous permet de refuser la demande d'un(e) de vos employé(s) de rejoindre\n                    l'un de vos établissements."
                        },
                        "remove": {
                            "main_title": "Retirer",
                            "title": "Retirer un(e) employé(e)",
                            "content": "Cette action vous permet de retirer un(e) utilisateur(rice) de vos employé(e)s dans le\n                    cas par exemple où un(e) employé(e) quitterait votre entreprise."
                        },
                        "cancel": {
                            "main_title": "Annuler",
                            "title": "Annuler la demande d'un(e) employé(e)",
                            "content": "Cette action vous permet d'annuler la demande d'invitation d'un(e) employé(e) en\n                    cas par exemple d'erreur."
                        }
                    },
                    "begin": {
                        "hotelier": {
                            "description": "Afin de vous permettre d'utiliser l'application UgoZER dans les meilleures \n                    conditions, nous vous conseillons de suivre les étapes suivantes qui vous guideront dans les \n                    premiers pas de la prise en main et du paramétrage de votre espace Hotelier.",
                            "steps": {
                                "employees": {
                                    "step_state": "claimed",
                                    "step_next": "employee-added",
                                    "title": "Concierges",
                                    "description": "Gérez vos concierges"
                                }
                            }
                        },
                        "manager": {
                            "description": "Afin de vous permettre d'utiliser l'application UgoZER dans les meilleures \n                    conditions, nous vous conseillons de suivre les étapes suivantes qui vous guideront dans les \n                    premiers pas de la prise en main et du paramétrage de votre espace Manager d'autres commerces."
                        },
                        "butler": {
                            "description": "Afin de vous permettre d'utiliser l'application UgoZER dans les meilleures \n                    conditions, nous vous conseillons de suivre les étapes suivantes qui vous guideront dans les \n                    premiers pas de la prise en main et du paramétrage de votre espace Concierge.",
                            "steps": {
                                "institution": {
                                    "step_state": "pending",
                                    "step_next": "claimed",
                                    "title": "Etablissement",
                                    "description": "Liez-vous à votre établissement",
                                    "subtitle": {
                                        "part_1": "Me lier à mon hôtel",
                                        "part_2": "Vous pouvez vous lier à votre hôtel directement vers la page",
                                        "link": "Mon établissement"
                                    },
                                    "content": "Recherchez votre hôtel dans la liste des établissements grace à la barre de\n                            recherche et les filtres disponibles dans la section gauche de la page. Une fois votre\n                            établissement trouvé, créez une demande en cliquant sur le bouton\n                            <strong>Demander à rejoindre</strong>."
                                }
                            }
                        },
                        "tourist_office": {
                            "description": "Afin de vous permettre d'utiliser l'application UgoZER dans les meilleures \n                    conditions, nous vous conseillons de suivre les étapes suivantes qui vous guideront dans les \n                    premiers pas de la prise en main et du paramétrage de votre espace Office du tourisme.",
                            "steps": {
                                "employees": {
                                    "step_state": "claimed",
                                    "step_next": "employee-added",
                                    "title": "Employé(e)s",
                                    "description": "Gérez vos employé(e)s"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "Afin de vous permettre d'utiliser l'application UgoZER dans les meilleures \n                    conditions, nous vous conseillons de suivre les étapes suivantes qui vous guideront dans les \n                    premiers pas de la prise en main et du paramétrage de votre espace Employé d'office du tourisme.",
                            "steps": {
                                "institution": {
                                    "step_state": "pending",
                                    "step_next": "claimed",
                                    "title": "Etablissement",
                                    "description": "Liez-vous à votre établissement",
                                    "subtitle": {
                                        "part_1": "Me lier à mon office de tourisme",
                                        "part_2": "Vous pouvez vous lier à votre office de tourisme directement vers la page",
                                        "link": "Mon établissement"
                                    },
                                    "content": "Recherchez votre office de tourisme dans la liste des établissements grace à la \n                            barre de recherche et les filtres disponibles dans la section gauche de la page. Une fois\n                            votre établissement trouvé, créez une demande en cliquant sur le bouton\n                            <strong>Demander à rejoindre</strong>."
                                }
                            }
                        },
                        "done": {
                            "title": "Bravo !",
                            "description": "Vous avez maintenant complété les différentes étapes de paramétrage de l'application\n                    UgoZER.",
                            "faq": {
                                "part_1": "Si vous avez besoin de plus d'aide, vous pouvez retrouver des articles dans la\n                        section",
                                "link": "FAQ",
                                "part_2": "de l'application"
                            },
                            "help": "Vous pouvez aussi retrouver de l'aide contextuelle grâce aux boutons <strong>Aide</strong>\n                    présents sur certaines pages."
                        },
                        "shared": {
                            "claims": {
                                "step_state": "pending",
                                "step_next": "claimed",
                                "title": "Revendication",
                                "description": "Revendiquez vos établissements",
                                "notice": {
                                    "title": "A noter",
                                    "description": "Vous pouvez retrouver cette explication en cliquant sur le bouton \n                                <strong>Aide</strong> en haut à droite de la liste des points d'intérêt."
                                },
                                "documents": {
                                    "label": "Documents",
                                    "title": "Documents pour la revendication",
                                    "description": "Pour rejoindre votre établissement, il vous faudra au préalable\n                                préparer les documents suivants :",
                                    "company": {
                                        "title": "Si vous êtes une société",
                                        "kbis": "Un extrait KBIS",
                                        "identity_piece": "Un justificatif d'identité du réprésentant légal en cours de\n                                    validité"
                                    },
                                    "individual": {
                                        "title": "Si vous êtes une auto-entreprise",
                                        "identity_piece": "Une pièce d'identité en cours de validité",
                                        "registration_document": "Un certificat d'inscription de l'auto-entreprise"
                                    },
                                    "association": {
                                        "title": "Si vous êtes une association",
                                        "statutes": "Les statuts de l'association",
                                        "identity_piece": "Un justificatif d'identité du réprésentant légal en cours de\n                                    validité"
                                    }
                                }
                            },
                            "payments": {
                                "step_state": "employee-added",
                                "step_next": "done",
                                "title": "Paiement",
                                "description": "Recevez des pourboires",
                                "subtitle_1": "Afin que vos concierges puissent recevoir des pourboires, vous devez ajouter vos informations bancaires.",
                                "subtitle_2": "Pour ce faire :",
                                "steps": {
                                    "my_profile": {
                                        "part_1": "Allez sur la page",
                                        "part_2": "Mon profil"
                                    },
                                    "bank": {
                                        "create": "Cliquez sur le bouton <strong>Ajouter un compte</strong> dans la section \n                                    <strong>Compte bancaire</strong>"
                                    },
                                    "easytransac": {
                                        "create": "Cliquez sur le bouton <strong>Créer</strong> dans la section \n                                    <strong>EasyTransac</strong>",
                                        "created": "Le compte est créé automatiquement sur la base des données saisies sur \n                                    votre profil",
                                        "connection": "Cliquez sur le lien \n                                    <strong>Accéder au tableau de bord EasyTransac</strong> et connectez-vous avec \n                                    les identifiants reçus par e-mail",
                                        "form": "Remplissez le formulaire sur EasyTransac afin d'activer votre compte \n                                    de paiement",
                                        "warning": {
                                            "title": "Niveau de KYC",
                                            "description": "En fonction du niveau de KYC de votre compte EasyTransac, vous \n                                        serez peut être en mesure de recevoir des paiements mais pas de transférer \n                                        les fonds présents vers un compte bancaire classique. Il vous faudra pour \n                                        cela être en KYC de niveau 2 et fournir certains documents dans \n                                        l'interface EasyTransac."
                                        }
                                    }
                                }
                            },
                            "actions": {
                                "skip_step": "Ignorer l'étape",
                                "skip_tutorial": "Ignorer le tutoriel"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "Êtes-vous sur(e) de vouloir ignorer cette étape ?",
                                    "success": "L'étape a bien été ignorée !"
                                },
                                "skip_tutorial": {
                                    "confirm": "Êtes-vous sur(e) de vouloir ignorer le tutoriel ?",
                                    "success": "Le tutoriel a bien été ignoré !"
                                }
                            }
                        },
                        "no_tutorial": {
                            "title": "Aucun tutoriel disponible",
                            "content": "Aucun tutoriel n'a été paramétré pour votre rôle ou vous êtes connecté avec un compte administrateur."
                        },
                        "messages": {
                            "claim": "Votre revendication a bien été validée.",
                            "contest": "Votre contestation a bien été envoyée à notre équipe.",
                            "point_of_interest": "Le point d'intérêt a bien été créé, vous pouvez maintenant le revendiquer.",
                            "payment": "Vos informations bancaire ont bien été enregistrées."
                        },
                        "notices": {
                            "add_butler": {
                                "title": "Ajouter un concierge",
                                "content": "Pour utiliser l’application, vous devez créer un compte concierge pour votre\n                        établissement à partir duquel seront envoyés les informations aux voyageurs.",
                                "content_2": "Pour activer le compte concierge, votre concierge devra confirmer son adresse email\n                        et créer son mot de passe."
                            },
                            "add_employee": {
                                "title": "Ajouter un employé",
                                "content": "Pour utiliser l’application, vous devez créer un compte employé pour votre\n                        établissement à partir duquel seront envoyés les informations aux voyageurs.",
                                "content_2": "Pour activer le compte employé, votre employé devra confirmer son adresse email\n                        et créer son mot de passe."
                            }
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "verify": {
                    "success": "Votre identifiant a bien été vérifié",
                    "already_verified": "Votre identifiant a déjà été vérifié",
                    "impossible": "La vérification de cet identifiant n'est plus possible",
                    "not_received": "Vous n'avez pas reçu le lien de confirmation ?",
                    "expired": "Le lien est expiré, demandez en un nouveau",
                    "sent": {
                        "title": "Lien envoyé",
                        "email_1": "Un e-mail va vous être envoyé à {email}, si cette adresse n'est pas déjà utilisée.",
                        "email_2": "Cet e-mail contient un lien qui vous permettra de confirmer votre adresse.",
                        "email_3": "Votre e-mail sera mis à jour dès que vous aurez accéder au lien.",
                        "again": "Envoyer à nouveau",
                        "refresh": "Vous avez cliqué sur le lien ? Rafraîchir l'application",
                        "correct": "Corriger l'identifiant choisi",
                        "wait_before": "Merci de patienter {seconds} secondes avant un nouvel envoi"
                    }
                },
                "validate": {
                    "success": "Identifiants ont bien été vérifiés (si éligible)"
                }
            },
            "user_geoloc_settings": {
                "update": {
                    "success": "Les paramètres de géolocalisation ont été modifiés"
                },
                "geolocation": {
                    "intro": "La géolocalisation permet de vous proposer les points d'intérêts les plus proches.",
                    "error": {
                        "title": "Localisation échouée",
                        "intro": "Afin de pouvoir consulter les points d'intérêts autour de vous :",
                        "allow_browser": "Autorisez votre navigateur à vous localiser dans les paramètres de votre téléphone",
                        "allow_site": "Autorisez UgoZER à vous localiser dans les paramètres de votre navigateur"
                    }
                },
                "not_configured": {
                    "subject": "Vous n'avez pas encore défini vos paramètres de géolocalisation.",
                    "body1": "Sélectionnez un lieu de votre choix pour consulter une sélection de points d'intérêt à proximité de celui-ci.",
                    "body2": "Il est aussi possible de n'afficher que les points d'intérêt près de vous.",
                    "do_not_ask_again": "Ne plus me demander jusqu'au redémarrage de l'application"
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "Les paramètres de notifications ont été modifiés"
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "Les paramètres de confidentialité ont été modifiés"
                }
            },
            "users": {
                "profile": {
                    "unnamed": "Aucun nom",
                    "alerts": {
                        "pending_validation": {
                            "title": "Attente de validation",
                            "message": "Cet utilisateur n'a pas encore accepté votre demande."
                        },
                        "blocked": {
                            "title": "Cet utilisateur vous a bloqué...",
                            "message": "Vous ne pouvez plus interagir avec cet utilisateur."
                        },
                        "missing_infos": {
                            "title": "Complétez votre profil",
                            "message": "Afin de profiter de toutes les fonctionnalités de l'application, merci de compléter les informations suivantes :"
                        }
                    },
                    "bank_account_number_missing": "Merci de renseigner vos informations bancaires pour recevoir vos paiements.",
                    "qr_code": "Scannez-moi pour voir mon profil",
                    "public_id": "Ou utilisez cet identifiant :",
                    "edit_your_profile": "Modifier votre profil",
                    "share_profile": "Partager son profil",
                    "your_personal_information": "Vos informations personnelles",
                    "security": "Sécurité",
                    "phone_missing": "Vous n’avez pas renseigné de numéro de téléphone vérifié.",
                    "email_missing": "Vous n’avez pas renseigné d'e-mail verifié.",
                    "password_missing": "Vous n’avez pas renseigné de mot de passe.",
                    "edit_password": "Modifier votre mot de passe",
                    "edit_email": "Modifier votre e-mail",
                    "edit_phone": "Modifier votre numéro de téléphone",
                    "fill_country": "Merci d'indiquer votre pays",
                    "form": {
                        "poi_label": "Etablissement lié"
                    },
                    "delete_account": {
                        "title": "Suppression du compte",
                        "warning": "Votre compte, ainsi que toutes les informations relatives à votre compte, seront immédiatement supprimées sans possibilité de récupération.",
                        "instructions": "Pour confirmer la suppression définitive de votre compte, veuillez saisir le mot \"DELETE\" dans le champ ci-dessous et cliquer sur le bouton \"Confirmer\".",
                        "easy_transac": "Votre compte EasyTransac ne sera pas supprimé.",
                        "success": "Votre compte à bien été supprimé."
                    },
                    "preferences": "Préférences",
                    "manage_my_subscription": "Gérer mon abonnement",
                    "subscribe_to_receive_payments": "Vous devez être abonné pour pouvoir recevoir des paiements.",
                    "avatar": {
                        "section": {
                            "title": "Choix d'un avatar prédéfini",
                            "description": "Vous pouvez selectionner un avatar prédéfini dans la liste ci-dessous",
                            "cta": "Utiliser cet avatar"
                        },
                        "defaults": {
                            "male": "Homme",
                            "female": "Femme"
                        },
                        "success": "Votre avatar a bien été mis à jour !"
                    }
                },
                "update": {
                    "success": "Le profil a été modifié"
                },
                "send_link": {
                    "success": "Le lien vers l'application a bien été envoyé",
                    "error": "Impossible d'envoyer le lien, l'utilisateur a t'il une adresse e-mail ?"
                },
                "search": {
                    "no_results": "Aucun utilisateur trouvé"
                },
                "report": {
                    "heading": "Signaler un utilisateur",
                    "choose_reason": "Sélectionnez une raison pour le signalement de l'utilisateur \"{user_name}\"",
                    "success": "L'utilisateur a été signalé !"
                },
                "index": {
                    "search": "Rechercher un utilisateur",
                    "no_results": "Aucun utilisateur trouvé",
                    "hint": "Un utilisateur peut être trouvé par son pseudonyme ou son ID public."
                },
                "pages": {
                    "show": {
                        "sections": {
                            "profile": "Mon profil",
                            "delete": "Supprimer mon compte ?"
                        }
                    }
                },
                "butlers": "Concierges",
                "finish": {
                    "success": "Tutoriel passé dans l'état terminé"
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "Aucun contact trouvé",
                    "pending_requests": "Vos contacts en attente"
                },
                "search": {
                    "no_results": "Aucun contact trouvé"
                },
                "messages": {
                    "add": {
                        "confirm": "Êtes-vous sur(e) de vouloir ajouter le contact ?",
                        "success": "Demande de contact envoyée !",
                        "error": "Une erreur est survenue lors de la demande de contact..."
                    },
                    "approve": {
                        "confirm": "Êtes-vous sur(e) de vouloir accepter la demande de contact ?",
                        "success": "Demande de contact acceptée !"
                    },
                    "decline": {
                        "confirm": "Êtes-vous sur(e) de vouloir refuser la demande de contact ?",
                        "success": "Demande de contact refusée !"
                    },
                    "block": {
                        "confirm": "Êtes-vous sur(e) de vouloir bloquer cet utilisateur ?",
                        "success": "Ce contact a été bloqué !"
                    },
                    "unblock": {
                        "confirm": "Êtes-vous sur(e) de vouloir débloquer cet utilisateur ?",
                        "success": "Ce contact a été débloqué !"
                    }
                },
                "tabs": {
                    "approved": "Approuvés",
                    "pending": "En attente",
                    "sent": "Envoyées",
                    "blocked": "Bloqués"
                },
                "no_results": {
                    "approved": "Aucun contact approuvé",
                    "pending": "Aucune demande en attente",
                    "sent": "Aucune demande envoyée",
                    "blocked": "Aucun contact bloqué"
                }
            }
        },
        "validation": {
            "accepted": "{attribute} doit être accepté(e).",
            "active_url": "{attribute} n'est pas une URL valide.",
            "after": "{attribute} doit être une date postérieure à {date}.",
            "after_or_equal": "{attribute} doit être une date postérieure ou égale à {date}.",
            "alpha": "{attribute} doit contenir uniquement des caractères alphabétiques.",
            "alpha_dash": "{attribute} doit contenir uniquement des caractères alphanumériques,\n        des tirets ou des underscores.",
            "alpha_num": "{attribute} doit contenir uniquement des caractères alphanumériques.",
            "array": "{attribute} doit être un tableau.",
            "before": "{attribute} doit être une date antérieure à {date}.",
            "before_or_equal": "{attribute} doit être une date antérieure ou égale à {date}.",
            "between": {
                "numeric": "{attribute} doit être compris entre {min} et {max}.",
                "file": "{attribute} doit être compris entre {min} et {max} kilo-octets.",
                "string": "{attribute} doit être compris entre {min} et {max} caractères.",
                "array": "{attribute} doit avoir entre {min} et {max} éléments."
            },
            "boolean": "{attribute} doit être vrai ou faux.",
            "confirmed": "{attribute} ne correspond pas.",
            "current_password": "{attribute} ne correspond pas à votre mot de passe.",
            "currency_scale": "{attribute} ne doit pas avoir plus de {scale} chiffres après la virgule.",
            "date": "{attribute} n'est pas une date valide.",
            "date_equals": "{attribute} doit être une date égale à {date}.",
            "date_format": "{attribute} ne correspond pas au format {format}.",
            "different": "{attribute} et {other} doivent être différent(e)s.",
            "digits": "{attribute} doit comporter {digits} chiffres.",
            "digits_between": "{attribute} doit être entre {min} et {max} chiffres.",
            "dimensions": "{attribute} à des dimensions invalides.",
            "distinct": "{attribute} à une valeur dupliquée.",
            "email": "{attribute} doit être un e-mail valide.",
            "ends_with": "{attribute} doit se terminer par l'une des valeurs suivantes : {values}.",
            "exists": "{attribute} est invalide.",
            "file": "{attribute} doit être une fichier.",
            "filled": "{attribute} doit être rempli(e).",
            "gt": {
                "numeric": "{attribute} doit être supérieur(e) à {value}.",
                "file": "{attribute} doit être supérieur(e) à {value} kilo-octets.",
                "string": "{attribute} doit comporter plus de {value} caractères.",
                "array": "{attribute} doit comporter plus de {value} éléments."
            },
            "gte": {
                "numeric": "{attribute} doit être supérieur(e) ou égal(e) à {value}.",
                "file": "{attribute} doit être supérieur(e) ou égal(e) à {value} kilo-octets.",
                "string": "{attribute} doit comporter au moins {value} caractères.",
                "array": "{attribute} doit comporter au moins {value} éléments."
            },
            "image": "{attribute} doit être une image.",
            "in": "{attribute} est invalide.",
            "in_array": "{attribute} n'existe pas dans {other}.",
            "integer": "{attribute} doit être un entier.",
            "ip": "{attribute} doit être une adresse IP valide.",
            "ipv4": "{attribute} doit être une adresse IPV4 valide.",
            "ipv6": "{attribute} doit être une adresse IPV6 valide.",
            "json": "{attribute} doit être une chaine JSON valide.",
            "lt": {
                "numeric": "{attribute} doit être inférieur(e) à {value}.",
                "file": "{attribute} doit être inférieur(e) à {value} kilo-octets.",
                "string": "{attribute} doit comporter moins de {value} caractères.",
                "array": "{attribute} doit comporter moins de {value} éléments."
            },
            "lte": {
                "numeric": "{attribute} doit être inférieur(e) ou égal(e) à {value}.",
                "file": "{attribute} doit être inférieur(e) ou égal(e) à {value} kilo-octets.",
                "string": "{attribute} doit comporter au maximum {value} caractères.",
                "array": "{attribute} doit comporter au maximum {value} éléments."
            },
            "luhn": "{attribute} est un numéro invalide.",
            "max": {
                "numeric": "{attribute} ne doit pas dépasser {max}.",
                "file": "{attribute} ne doit pas dépasser {max} kilo-octets.",
                "string": "{attribute} ne doit pas dépasser {max} caractères.",
                "array": "{attribute} ne doit pas dépasser {max} éléments.",
                "width": "{attribute} ne doit pas dépasser {max} pixels de largeur.",
                "height": "{attribute} ne doit pas dépasser {max} pixels de hauteur."
            },
            "max_digits": "{attribute} ne doit pas dépasser {max} chiffres.",
            "mimes": "{attribute} doit être un type valide.",
            "mimetypes": "{attribute} doit être un type valide.",
            "min": {
                "numeric": "{attribute} doit être au moins {min}.",
                "file": "{attribute} doit être au moins {min} kilo-octets.",
                "string": "{attribute} doit comporter au moins {min} caractères.",
                "array": "{attribute} doit comporter au moins {min} éléments."
            },
            "min_digits": "{attribute} doit comporter au moins {min} chiffres.",
            "not_in": "{attribute} est invalide.",
            "not_regex": "{attribute} format invalide.",
            "notification_setting": "{attribute} format invalide (format attendu array[bool]).",
            "numeric": "{attribute} doit être numérique.",
            "password": "Mot de passe incorrect.",
            "phone": "{attribute} doit être un numéro de téléphone valide.",
            "present": "{attribute} doit être présent(e).",
            "prohibited_if": "{attribute} interdit(e) si {other} est {value}.",
            "prohibited_unless": "{attribute} interdit(e) sauf si {other} comporte une des valeurs suivantes {values}.",
            "real_name_characters": "{attribute} ne peut contenir que des lettres, espaces, points,\n        traits d'union ou apostrophes.",
            "regex": "{attribute} format invalide.",
            "required": "{attribute} requis(e).",
            "required_if": "{attribute} requis(e) si {other} est {value}.",
            "required_unless": "{attribute} requis(e) sauf si {other} comporte une des valeurs suivantes {values}.",
            "required_with": "{attribute} requis(e) si {values} est present(e).",
            "required_with_all": "{attribute} requis(e) si l'une des valeurs suivantes {values} est présent(e).",
            "required_without": "{attribute} requis(e) si l'une des valeurs suivantes {values} n'est pas présent(e).",
            "required_without_all": "{attribute} requis(e) si aucune des valeurs suivantes {values} n'est présent(e).",
            "same": "{attribute} et {other} doivent correspondre.",
            "same_currency": "Tous les articles doivent utiliser la même devise.",
            "size": {
                "numeric": "{attribute} doit avoir une taille de {size}.",
                "file": "{attribute} ne doit pas dépasser {size} kilo-octets.",
                "string": "{attribute} ne doit pas dépasser {size} caractères.",
                "array": "{attribute} ne doit pas contenir plus de {size} éléments."
            },
            "starts_with": "{attribute} doit commencer par l'une des valeurs suivantes : {values}.",
            "string": "{attribute} doit être une chaine de caractères.",
            "tag_same_poi_category": "{attribute} doivent tous appartenir à la même catégorie !",
            "timezone": "{attribute} doit être un fuseau horaire valide.",
            "unique": "{attribute} est déjà utilisé(e).",
            "uploaded": "{attribute} échec de l'upload.",
            "url": "{attribute} format invalide.",
            "uuid": "{attribute} doit être un UUID valide.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                },
                "h-captcha-response": {
                    "required": "Merci de vérifier que vous n'êtes pas un robot.",
                    "HCaptcha": "Erreur de Captcha ! Merci d'essayer plus tard ou de contacter un admin."
                }
            },
            "attributes": {
                "accounting_file": "fichier comptabilité",
                "actions": "actions",
                "activated_at": "activé le",
                "active": "actif",
                "additional_information": "informations additionnelles",
                "address": "adresse",
                "addresses": "adresses",
                "admin_message": "message",
                "administrative_area": "zone administrative",
                "amount": "montant",
                "app": "application",
                "app_fees": "frais",
                "approved_at": "approuvé(e) le",
                "auto_renewal_enabled": "reconduction tacite activée",
                "has_employees": "a des employés",
                "archived_at": "Archivé(e) le",
                "association_statutes": "statuts",
                "association_legal_representative_identity_piece": "justificatif d’identité du représentant légal",
                "attached_files": "Pièces jointes",
                "audio": "audio",
                "audios": "audios",
                "audio_refreshing": "actualiser l'audio",
                "authenticated_once": "connecté une fois",
                "avatar": "avatar",
                "bank_account_number": "numéro de compte",
                "bank_file": "fichier banque",
                "bank_transfer": "virement bancaire",
                "bank_transfer_institutions": "virements bancaires par institution",
                "butler": "concierge",
                "butler_point_of_interest": "établissement du concierge",
                "canceled_at": "Annulé(e) le",
                "category": "catégorie",
                "categories": "catégories",
                "category_id": "ID catégorie",
                "causer": "déclencheur",
                "causer_id": "ID déclencheur",
                "causer_type": "type de déclencheur",
                "certified": "certifié",
                "city": "ville",
                "collection_name": "nom de la collection",
                "claim_confirmation": "confirmation revendication",
                "code": "code",
                "company_type": "type de société",
                "company_registration_document": "document d'enregistrement",
                "company_statutes": "statuts",
                "company_legal_representative_identity_piece": "justificatif d’identité du représentant légal",
                "completed_at": "terminé le",
                "contact": "contact",
                "contact_email": "email de contact",
                "contact_phone": "téléphone de contact",
                "content": "contenu",
                "contestant": "contestataire",
                "contestant_email": "email",
                "contestant_first_name": "prénom",
                "contestant_last_name": "nom",
                "contestant_message": "message",
                "contestant_phone": "téléphone",
                "contestant_company_type": "type de société",
                "conversions_disk": "disque de conversion",
                "coords": "coordonnées",
                "countries": "pays",
                "countries_codes": "pays",
                "country": "pays",
                "country_code": "code pays",
                "created": "créé",
                "created_at": "créé le",
                "creator": "créateur",
                "credential": "identifiant",
                "currency": "devise",
                "current_password": "mot de passe actuel",
                "customers_qty": "qté clients",
                "custom_amount": "montant personnalisé",
                "custom_properties": "propriétés personnalisées",
                "date": "date",
                "dates": "dates",
                "days": "jours",
                "declined_at": "refusé(e) le",
                "deleted_at": "supprimé le",
                "deletion_confirmation": "confirmation de suppression",
                "denial_reason": "raison du refus",
                "dependent_locality": "quartier",
                "description": "description",
                "details": "détails",
                "disk": "disque",
                "display": "affichage",
                "displayed_name": "nom affiché",
                "distance": "distance",
                "documents": "documents",
                "dt_created_by": "Créé par",
                "dt_last_update": "Dernière mise à jour DataTourisme",
                "dynamic_translations_enabled": "traductions dynamiques activées",
                "email": "e-mail",
                "employee": "employé(e)",
                "end_date": "date de fin",
                "engagement_period": "période d'engagement",
                "engaged_until": "engagé jusqu'à",
                "event": "événement",
                "excursion": "itinéraire",
                "existing_user": "utilisateur existant",
                "expirable": "expirable",
                "expiry": "expiration",
                "expired_at": "expiré le",
                "expired_on": "date de fin de séjour",
                "expiry_date": "Date d'expiration",
                "extranet": "extranet",
                "fallback_locale": "langue secondaire",
                "favorite": "itinéraire favori",
                "featured": "mis en avant",
                "file": "fichier",
                "file_name": "nom du fichier",
                "filter": "filtre",
                "financial": "finances",
                "first_name": "Prénom",
                "formatted_address": "adresse formatée",
                "free_period": "période de gratuité",
                "free_period_ended_at": "fin de la période de gratuité",
                "front": "site vitrine",
                "gallery": "galerie",
                "gdpr": "RGPD",
                "general": "Général",
                "generated_conversions": "conversions générées",
                "gross_amount": "montant brut",
                "hour": "heure",
                "hours": "heures",
                "id": "id",
                "image": "image",
                "images": "images",
                "import_source": "source d'import",
                "import_synchronizing": "Activer la mise à jour automatique",
                "individual_first_identity_piece": "pièce d'identité",
                "individual_second_identity_piece": "seconde pièce d'identité",
                "individual_registration_document": "document d’enregistrement",
                "information": "informations",
                "institution": "établissement",
                "institution_address": "adresse de l'établissement",
                "institution_id": "établissement",
                "institution_title": "nom de l'établissement",
                "invitation": "invitation",
                "invitation_accepted": "invitation acceptée",
                "invitation_status": "statut invitation",
                "invited_at": "invité le",
                "invited_by": "invité par",
                "invitees": "invités",
                "interaction_type": "type d'interaction",
                "items": "éléments",
                "is_geocoding": "en cours de géolocalisation",
                "iso_code": "code ISO",
                "key": "clé",
                "kind": "type",
                "label": "label",
                "last_geoloc": "dernière géolocalisation",
                "last_logged_at": "dernière connexion",
                "last_login_ip": "IP dernière connexion",
                "last_name": "Nom",
                "last_validated_at": "dernière validation",
                "lat": "latitude",
                "latitude": "latitude",
                "line1": "adresse ligne 1",
                "line2": "adresse ligne 2",
                "link": "lien",
                "lng": "longitude",
                "locale": "langue",
                "login": "connexion",
                "longitude": "longitude",
                "logo": "logo",
                "manipulations": "manipulations",
                "max_amount_per_checkout": "montant maximum par transaction",
                "min_amount_per_checkout": "montant minimum par transaction",
                "misc": "divers",
                "mime_type": "type MIME",
                "media": "médias",
                "message": "message",
                "measurement_system": "système de mesure",
                "metadata": "métadonnées",
                "model": "enregistrement",
                "model_type": "type d'enregistrement",
                "name": "nom",
                "net_amount": "montant net",
                "new_password": "nouveau mot de passe",
                "new_password_confirmation": "confirmation du nouveau mot de passe",
                "next_payment_at": "prochain paiement",
                "nickname": "pseudonyme",
                "not_validated_activities": "modifications non validées",
                "notes": "informations complémentaires",
                "order_column": "ordre",
                "origin": "provenance",
                "opening_hours": "Horaires d'ouverture",
                "owner": "propriétaire",
                "owner_email": "email",
                "owner_first_name": "prénom",
                "owner_last_name": "nom",
                "owner_phone": "téléphone",
                "password": "mot de passe",
                "password_confirmation": "confirmation du mot de passe",
                "payable": "élément à payer",
                "payee": "bénéficiaire",
                "payee_id": "bénéficiaire",
                "payee_name": "nom du bénéficiaire",
                "payee_email": "email",
                "payee_phone": "phone",
                "payee_country": "pays",
                "payer": "payeur",
                "payer_id": "payeur",
                "payer_name": "nom du payeur",
                "payer_email": "email",
                "payer_phone": "phone",
                "payer_country": "pays",
                "payment_received": "paiement reçu",
                "payment_service": "service de paiement",
                "payment_thanks": "remerciement de paiement",
                "payment_service_account_id": "ID compte",
                "payment_service_transaction_id": "ID transaction",
                "payment_type": "type de paiement",
                "period": "période",
                "point_of_interest_category": "catégorie",
                "point_of_interest_categories": "catégories",
                "point_of_interest": "point d'intérêt",
                "point_of_interest_event_expired": "Événement expiré",
                "points_of_interest": "points d'intérêt",
                "points_of_interest_count": "nombre de points d'intérêt",
                "post_category": "catégorie d'article",
                "phone": "téléphone",
                "pro_file": "fichier pro",
                "provider": "fournisseur",
                "communication_consent": "Communications personnalisées",
                "communication_consent_alt": "Communications ?",
                "plan": "offre",
                "price": "prix",
                "price_excluding_taxes": "prix hors taxes",
                "prices": "prix",
                "private_title": "titre privé",
                "privacy_policy": "charte de confidentialité",
                "privacy_policy_accepted": "charte de confidentialité acceptée",
                "privacy_policy_version": "version de la charte de confidentialité",
                "private": "privé",
                "profile_picture": "Photo de profil",
                "properties": "propriétés",
                "properties_comparison": "propriétés modifiées",
                "public": "public",
                "public_id": "ID public",
                "published_at": "date de publication",
                "quantity": "quantité",
                "quitted_at": "parti(e) le",
                "radius": "rayon",
                "rates": "taux",
                "reason_type": "raison",
                "reason_details": "détails",
                "recipient": "destinataire",
                "recipients": "destinataires",
                "recipients_alt": "voyageurs enregistrés",
                "remember": "se souvenir de moi",
                "removed_at": "retiré(e) le",
                "reports": "signalements",
                "requested_at": "demandé(e) le",
                "reservation_created": "réservation créée",
                "reservation_date": "date",
                "reservation_time": "heure",
                "resolved_at": "Résolue le",
                "responsive_images": "images responsives",
                "roles": "rôles",
                "room_number": "numéro de chambre",
                "scout_searchable": "indexable",
                "searchable": "Peut être recherché ?",
                "sender": "expéditeur",
                "senders": "expéditeurs",
                "service": "service",
                "settings": "paramétrage",
                "shareable": "élément partagé",
                "share_created": "nouveau partage",
                "show_contact_email": "Visibilité de l'email de contact",
                "show_contact_phone": "Visibilité du téléphone de contact",
                "show_profile_picture": "Visibilité de l'avatar",
                "show_real_name": "Visibilité du nom et prénom",
                "siret": "SIRET",
                "size": "taille",
                "source": "source",
                "source_id": "id source",
                "starable": "noté en étoiles",
                "stars": "étoiles",
                "start_date": "date de début",
                "state": "état",
                "states": "états",
                "static_translations_enabled": "traductions statiques activées",
                "status": "statut",
                "subject": "sujet",
                "subject_id": "ID sujet",
                "subject_type": "type de sujet",
                "summary": "résumé",
                "support_category": "catégorie",
                "support_messages_count": "nombre de messages",
                "support_request": "demande de contact",
                "support_request_message_received": "Réponse apportée à une demande de contact",
                "support_request_archived": "Demande de contact archivée",
                "suspended_at": "suspendu le",
                "tag": "tag",
                "tags": "tags",
                "target": "cible",
                "terms_condition_accepted": "conditions générales acceptées",
                "terms_condition_version": "version des conditions générales",
                "terms_conditions": "conditions générales",
                "thanks": "remerciements",
                "thanked": "remercié",
                "thanked_at": "date de remerciement",
                "thanks_message": "message de remerciement",
                "thanks_sent": "remerciements envoyés",
                "title": "titre",
                "tipping_fees": "frais sur les pourboires",
                "tips_enabled": "pourboires activés",
                "total_amount": "montant total",
                "traveller": "voyageur",
                "traveller_folder": "dossier voyageur",
                "traveller_folder_approved": "dossier voyageur accepté",
                "traveller_folder_archived": "dossier voyageur archivé",
                "traveller_folder_pending": "dossier voyageur en attente",
                "traveller_folder_thanks": "remerciement pour le séjour",
                "tutorial_state": "Etat tutoriel",
                "type": "type",
                "updated_at": "modifié le",
                "user": "utilisateur",
                "user_id": "utilisateur",
                "user_report": "utilisateur (ayant signalé)",
                "users": "utilisateurs",
                "utm_campaign": "Campagne",
                "utm_medium": "Média",
                "utm_settings": "Paramètres UTM",
                "utm_source": "Source",
                "uuid": "uuid",
                "value": "valeur",
                "validated_activities": "modifications validées",
                "validated_at": "validé(e) le",
                "vat": "tva",
                "vat_rate": "taux de tva",
                "version": "version",
                "video": "vidéo",
                "videos": "vidéos",
                "website": "site web",
                "zip": "code postal",
                "priority": "priorité",
                "bank_payee": "Bénéficiaire",
                "bank_iban": "IBAN",
                "bank_bic": "BIC",
                "bank_name": "Nom de la banque",
                "bank_city": "Ville de la banque",
                "bank_country": "Pays de la banque",
                "bank_transfer_reason": "Motif de virement",
                "bank_account": "Compte bancaire",
                "specified": "Renseigné",
                "not_specified": "Non renseigné",
                "passwords_must_different": "Les deux comptes ne peuvent avoir le même mot de passe.",
                "emails_must_different": "Les deux comptes ne peuvent avoir la même adresse e-mail."
            }
        }
    },
    "it": {
        "The {attribute} must be at least {length} characters and contain at least one number.": "L' {attribute} deve contenere almeno {length} caratteri e contenere almeno un numero.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "L' {attribute} deve contenere almeno {length} caratteri e contenere almeno un carattere speciale e un numero.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": " {attribute} deve contenere almeno {length} caratteri e contenere almeno un carattere speciale.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": " {attribute} deve contenere almeno {length} caratteri e contenere almeno un carattere maiuscolo e un numero.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": " {attribute} deve contenere almeno {length} caratteri e contenere almeno un carattere maiuscolo e un carattere speciale.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": " {attribute} deve contenere almeno {length} caratteri e contenere almeno un carattere maiuscolo, un numero e un carattere speciale.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": " {attribute} deve contenere almeno {length} caratteri e contenere almeno un carattere maiuscolo.",
        "The {attribute} must be at least {length} characters.": "L' {attribute} deve contenere almeno {length} caratteri.",
        "The {attribute} must contain at least one letter.": "L' {attribute} deve contenere almeno una lettera.",
        "The {attribute} must contain at least one number.": "L' {attribute} deve contenere almeno un numero.",
        "The {attribute} must contain at least one symbol.": "L' {attribute} deve contenere almeno un simbolo.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "L' {attribute} deve contenere almeno una lettera maiuscola e una minuscola.",
        "auth": {
            "failed": "Queste credenziali non corrispondono ai nostri record. Sei sicuro di non aver utilizzato questa email per un account {type}?",
            "throttle": "Troppi tentativi di accesso. Riprova tra {seconds} secondi.",
            "throttle_cascade": "Troppi tentativi di accesso. Riprova tra {remaining}."
        },
        "documents": {
            "display": {
                "description": "Espositore in formato A6 con collegamento QRCode e logo della tua istituzione.",
                "title": "Display: scheda A6"
            },
            "qrcode": {
                "description": "Solo il QRCode consente al viaggiatore di essere direttamente collegato alla tua istituzione.",
                "filename": "QR Code",
                "title": "Collega il codice QR"
            }
        },
        "enums": {
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "Creato",
                "deleted": "Eliminato",
                "updated": "Aggiornato",
                "validated": "Convalidato"
            },
            "App\\Enums\\BinaryChoiceEnum": [
                "Negato",
                "Accettato"
            ],
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "association": "Associazione (o fondazione)",
                "company": "Azienda (pubblica/privata)",
                "individual": "Individuale",
                "undefined": "Non definito"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "DE": "Germania",
                "ES": "Spagna",
                "FR": "Francia",
                "IT": "Italia",
                "TH": "Tailandia",
                "undefined": "Non definito"
            },
            "App\\Enums\\ContextsEnum": {
                "city_hall": "Municipio",
                "hotel": "Hotel",
                "manager": "Manager",
                "tourist_office": "Ufficio Turistico",
                "traveller": "Viaggiatore"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "Euro",
                "usd": "Dollaro"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Friday": "Venerdì",
                "Monday": "Lunedi",
                "Saturday": "Sabato",
                "Sunday": "Domenica",
                "Thursday": "Giovedì",
                "Tuesday": "Martedì",
                "Wednesday": "Mercoledì"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "Personalizzato",
                "root": "I miei modelli",
                "ugozer_app": "Applicazione UgoZER",
                "ugozer_pro": "UgoZER Pro"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "Imperiale",
                "metric": "Metrico"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "Nell'app",
                "email": "E-mail"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "customer": "Cliente",
                "partner": "Compagno"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "easytransac": "EasyTransac",
                "paypal": "PayPal",
                "stripe": "Banda"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "subscription": "Sottoscrizione",
                "tip": "Mancia"
            },
            "App\\Enums\\PrivacySettingsEnum": {
                "contacts": "I miei contatti",
                "everyone": "Tutti",
                "nobody": "Nessuno"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "illegal": "Illegale",
                "incorrect_data": "Informazioni errate",
                "other": "Altro",
                "shocking": "Scioccante",
                "spam": "Spam"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "Colazione",
                "dinner": "Cena",
                "lunch": "Pranzo",
                "null": "N / A"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "Facebook",
                "google": "Google",
                "instagram": "Instagram",
                "linkedin": "LinkedIn",
                "tiktok": "Tic toc",
                "twitter": "X (Twitter)",
                "wechat": "WeChat"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "1 mese",
                "one year": "1 anno"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "1 mese",
                "one year": "1 anno",
                "six months": "6 mesi",
                "three months": "3 mesi"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "expired": "L'abbonamento è terminato",
                "free period ended": "Il periodo gratuito è terminato",
                "payment default": "Mancato pagamento"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "App",
                "extranet": "Extranet",
                "front": "Davanti"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "Archiviato",
                "canceled": "Annullato",
                "pending_support": "Supporto in sospeso",
                "pending_user": "Utente in sospeso",
                "resolved": "Risolto"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "Attorno a me",
                "customized": "Personalizzato",
                "staying_hotel": "Hotel vicino al soggiorno"
            },
            "App\\Enums\\UserRoleEnum": {
                "butler": "Maggiordomo",
                "contributor": "Collaboratore",
                "hotelier": "Albergatore",
                "manager": "Manager",
                "moderator": "Moderatore",
                "superadmin": "SuperAmministratore",
                "touristOffice": "Ufficio Turistico",
                "touristOfficeEmployee": "A Dipendente",
                "traveller": "Viaggiatore"
            }
        },
        "nova": {
            "lens": {
                "user": {
                    "user_origin": "Provenienza degli utenti"
                }
            },
            "menu": {
                "administration": "Amministrazione",
                "content": "Contenuto",
                "excursions": "Itinerari",
                "import_poi": "Importa PDI",
                "miscellaneous": "Varie",
                "parameters": "Parametri",
                "pois": "Punti di interesse",
                "subscriptions": "Abbonamenti",
                "support": "Supporto",
                "users": "Utenti"
            },
            "s3_private_download": {
                "link": {
                    "expiration": "Il collegamento scadrà dopo 30 minuti, in tal caso è sufficiente aggiornare la pagina.",
                    "open": "Visualizza il documento"
                },
                "no_document": "Nessun documento correlato",
                "not_found": "Impossibile trovare il documento sull'unità S3..."
            },
            "user_menu": {
                "profile": "Il mio profilo"
            }
        },
        "pagination": {
            "go_page": "Vai alla pagina {page}",
            "next": "Prossimo \"",
            "next_alt": "Prossimo",
            "of": "Di",
            "pagination_navigation": "Navigazione nella paginazione",
            "previous": "\" Precedente",
            "previous_alt": "Precedente",
            "results": "risultati",
            "showing": "Mostrando",
            "to": "A"
        },
        "passwords": {
            "reset": "La tua password è stata resettata!",
            "sent": "Ti abbiamo inviato via email il link per reimpostare la password!",
            "throttled": "Si prega di attendere prima di riprovare.",
            "token": "Questo token di reimpostazione della password non è valido.",
            "user": "Nessun utente corrispondente."
        },
        "ttmt": {
            "account_deletion": {
                "confirmation": "Nella pagina che si apre, conferma l'eliminazione inserendo la parola <strong>ELIMINA</strong>\n        e fai clic su <strong>Conferma</strong>.",
                "content": {
                    "asking": "In caso di richiesta di cancellazione dell'account, UgoZER cancellerà l'account e i dati dell'utente,\n            a meno che non debbano essere conservati a causa di requisiti legali o normativi, per motivi di sicurezza, frode\n            prevenzione o a causa di un problema relativo all'account dell'utente come un reclamo non pagato, irrisolto o\n            controversia.",
                    "conservation": {
                        "link": "Politica sulla riservatezza.",
                        "part_1": "UgoZER conserva i dati dell'utente per il tempo necessario agli scopi descritti nell'art",
                        "part_2": "Conformemente alle raccomandazioni della CNIL, un account non utilizzato per 3 anni verrà cancellato.",
                        "part_3": "Gli utenti possono richiedere la cancellazione dei propri account in qualsiasi momento. UgoZER potrà conservare i dati dell'utente dopo a\n                richiesta di cancellazione in conformità con i requisiti legali o regolamentari o per i motivi ivi indicati\n                dichiarazione."
                    },
                    "instructions": "Se desideri eliminare il tuo account UgoZER, segui le istruzioni seguenti."
                },
                "logout": "Verrai disconnesso e l'account e i dati associati verranno immediatamente cancellati.",
                "pro": {
                    "instructions": {
                        "delete": "Quindi fai clic sul pulsante <strong>Elimina</strong> davanti a <strong>Cancellazione account\n                </strong>,",
                        "profile": {
                            "link": "profilo",
                            "part_1": "Vai al tuo",
                            "path": "(cliccando sul tuo avatar > Il mio profilo),"
                        }
                    },
                    "subtitle": "Per eliminare il tuo account professionale:",
                    "title": "Conto professionale"
                },
                "title": "Cancellazione dell'account",
                "traveller": {
                    "instructions": {
                        "delete": "Quindi fai clic sul pulsante <strong>Elimina</strong> davanti a <strong>Eliminare il mio account?\n                </strong>",
                        "profile": {
                            "link": "profilo",
                            "part_1": "Vai al tuo",
                            "path": "(menu icona hamburger > Impostazioni > Profilo),"
                        }
                    },
                    "subtitle": "Per eliminare il tuo account viaggiatore:",
                    "title": "Conto viaggiatore"
                }
            },
            "activity_logs": {
                "summary": {
                    "created": {
                        "intro": "L'oggetto è stato creato con queste proprietà:"
                    },
                    "deleted": {
                        "intro": "L'oggetto è stato eliminato con queste proprietà:"
                    },
                    "updated": {
                        "attribute_from_to": "{attribute} è stato aggiornato da \"{old_value}\" a \"{new_value}\"",
                        "intro": "L'oggetto è stato aggiornato:"
                    },
                    "validated": {
                        "intro": "L'oggetto è stato convalidato:"
                    }
                }
            },
            "auth": {
                "credential_verification": {
                    "intro": "Inserisci le tue credenziali, ti verrà inviato un link di conferma.",
                    "intro2": "Le tue credenziali verranno aggiornate non appena accedi al collegamento."
                },
                "expiration": {
                    "session_expired": "La tua sessione è scaduta, esegui nuovamente l'autenticazione"
                },
                "extranet_register": {
                    "form": {
                        "choose_establishment": "Scegli il tipo di struttura",
                        "form_title": "crea il tuo account",
                        "validate_registration": "Finalizzare la mia registrazione"
                    },
                    "success": "Registrato con successo, un link di verifica è stato inviato alla tua email"
                },
                "login": {
                    "already_logged": "Sei già loggato",
                    "logging_in": "Entrando...",
                    "success": "Connesso",
                    "use_email": "Accedi con una e-mail",
                    "use_social_networks": "Utilizza i social network"
                },
                "logout": {
                    "logging_out": "Disconnessione...",
                    "switch": "Passa a"
                },
                "messages": {
                    "continue": "Continua con",
                    "extranet_here": "clicca qui",
                    "have_account": "Hai già un account?",
                    "login": "Login",
                    "login_subtitle": "(registrazione o login)",
                    "login_title": "È meglio con i social network",
                    "no_account": "Non hai ancora un conto?",
                    "or": "O",
                    "professional": "Sei un professionista?",
                    "register": "Registrati",
                    "register_mail": "Registrati con una e-mail",
                    "register_title": "Registrati per sbloccare tutte le funzionalità",
                    "reset_password": "Resetta la password",
                    "send_magic_link": "Invia collegamento senza password"
                },
                "password_forgotten": {
                    "intro": "Inserisci le tue credenziali e ti invieremo un link per reimpostare la tua password.",
                    "submit": "Ricevi un collegamento di reimpostazione",
                    "success": {
                        "correct_credential": "Credenziale corretta",
                        "email_1": "Ti verrà inviata un'e-mail all'indirizzo {email}, se questo indirizzo è ben registrato.",
                        "email_2": "Questa email contiene un collegamento che ti consentirà di reimpostare la password.",
                        "send_again": "Invia di nuovo",
                        "title": "Link per la reimpostazione della password inviato",
                        "wait_before": "Attendi {seconds} secondi prima di inviare nuovamente"
                    }
                },
                "password_reset": {
                    "submit": "reimposta la tua password",
                    "success": "La password è stata reimpostata"
                },
                "register": {
                    "form": {
                        "hide_privacy_policy": "Nascondi l'informativa sulla privacy",
                        "hide_terms_conditions": "Nascondi termini e condizioni",
                        "privacy_policy": "Accetto l'informativa sulla privacy",
                        "show_privacy_policy": "Mostra l'informativa sulla privacy",
                        "show_terms_conditions": "Mostra termini e condizioni",
                        "terms_conditions": "Accetto Termini e Condizioni"
                    },
                    "staying_hotel": "Una volta registrato, sarai curato dall'hotel di cui\n            hai appena scansionato il codice QR.",
                    "success": {
                        "email_1": "Verrà inviata un'e-mail a {email}.",
                        "email_2": "Questa e-mail contiene un collegamento che ti permetterà di confermare il tuo indirizzo e di connetterti.",
                        "paired": {
                            "email_1": "Sono state inviate due email ai seguenti indirizzi",
                            "email_2": "Ogni email contiene un collegamento per confermare l'indirizzo e collegare l'account associato."
                        },
                        "title": "Registrato con successo"
                    }
                },
                "social_networks": {
                    "authenticate": "Entra con",
                    "facebook": "Facebook",
                    "google": "Google",
                    "no_email": "Nessuna email è associata al tuo social network, non puoi autenticarti con esso",
                    "twitter": "Twitter"
                },
                "travel_assistant": "Assistente di viaggio gratuito"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "Conto bancario non fornito"
                    },
                    "form": {
                        "placeholders": {
                            "bank_city": "Città della tua filiale bancaria.",
                            "bank_name": "nome della banca",
                            "bank_transfer_reason": "Esempio: \"Consigli UgoZER\"",
                            "bic": "Inserisci il tuo BIC",
                            "iban": "Inserisci il tuo IBAN/BBAN",
                            "payee_name": "Nome del beneficiario o nome dell'istituto",
                            "types": "IBAN/BBAN"
                        }
                    },
                    "lines": {
                        "no_account_yet": "Il tuo conto bancario consentirà al tuo istituto di ricevere pagamenti su UgoZER."
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "amount": "quantità",
                    "amount_to_pay": "somma da pagare",
                    "concierge": "concierge",
                    "currency": "valuta",
                    "customer": "cliente",
                    "date": "data",
                    "repartition_key": "chiave di distribuzione",
                    "tip_sharing_form": "modulo di condivisione dei suggerimenti",
                    "tips_received": "suggerimenti ricevuti",
                    "total_received": "totale ricevuto"
                }
            },
            "breadcrumbs": {
                "changelogs": {
                    "changelogs": "Log delle modifiche"
                },
                "claims": {
                    "claims": "Affermazioni"
                },
                "common": {
                    "add": "Aggiungere",
                    "claim": "Reclamo",
                    "create": "Nuovo",
                    "create_f": "Nuovo",
                    "delete": "Eliminare",
                    "download_pdf": "Scarica il pdf",
                    "edit": "Modificare",
                    "report": "Rapporto",
                    "share": "Condividere",
                    "show": "Spettacolo"
                },
                "contacts": {
                    "contacts": "Contatti"
                },
                "cookie": {
                    "cookie": "Gestione dei cookie"
                },
                "credit": {
                    "credit": "Crediti"
                },
                "dashboard": {
                    "dashboard": "Pannello di controllo"
                },
                "errors": {
                    "404": {
                        "label": "Errore 404",
                        "title": "Errore 404 non trovato"
                    }
                },
                "excursions": {
                    "create": "Nuovo itinerario",
                    "excursions": "Itinerari"
                },
                "faq": {
                    "faq": "Domande frequenti"
                },
                "finances": {
                    "finances": "Finanze"
                },
                "institutions": {
                    "institution": "Istituzione",
                    "institutions": "Istituzioni"
                },
                "institutions_employees": {
                    "institutions_employees": "I miei dipendenti",
                    "invite": "Invita un dipendente"
                },
                "interactions": {
                    "interactions": "Interazioni"
                },
                "invitations": {
                    "invitations": "Inviti"
                },
                "notification_settings": {
                    "notification_settings": "Impostazioni delle notifiche"
                },
                "notifications": {
                    "notifications": "Notifiche"
                },
                "partners": {
                    "customers": "Clienti",
                    "partners": "Partner"
                },
                "points_of_interest": {
                    "add_excursion": "Aggiungi a un itinerario",
                    "points_of_interest": "Punti di interesse"
                },
                "privacy_policy": {
                    "privacy_policy": "Politica sulla riservatezza"
                },
                "privacy_settings": {
                    "privacy_settings": "Impostazioni della privacy"
                },
                "reservations": {
                    "reservations": "Prenotazioni"
                },
                "search": {
                    "search": "Ricerca"
                },
                "subscriptions": {
                    "subscription": "Sottoscrizione"
                },
                "support_messages": {
                    "add_message": "Aggiungi un messaggio",
                    "support_messages": "Messaggi"
                },
                "support_requests": {
                    "add_request": "Aggiungi una nuova richiesta di supporto",
                    "support_requests": "Richieste di supporto"
                },
                "terms_condition": {
                    "terms_condition": "Termini e Condizioni"
                },
                "travellers": {
                    "folders": "Cartelle dei viaggiatori",
                    "travellers": "I viaggiatori"
                },
                "tutorials": {
                    "begin": "Iniziare"
                },
                "users": {
                    "edit_credential": "Modifica credenziale",
                    "profile": "Il mio profilo",
                    "users": "Utenti"
                }
            },
            "changelogs": {
                "archive": {
                    "success": "Il registro delle modifiche è stato archiviato (se idoneo)"
                },
                "draft": {
                    "success": "Il registro delle modifiche è stato redatto (se idoneo)"
                },
                "index": {
                    "sections": {
                        "all": "Tutti gli aggiornamenti",
                        "last": "Ultimo aggiornamento"
                    }
                },
                "publish": {
                    "success": "Il registro delle modifiche è stato pubblicato (se idoneo)"
                },
                "show": {
                    "version": "Versione"
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "La controversia sul reclamo è stata creata"
                },
                "rejected": {
                    "success": "La contestazione del reclamo è stata respinta"
                },
                "validated": {
                    "success": "La contestazione del reclamo è stata convalidata"
                }
            },
            "claims": {
                "form_wizard": {
                    "documents": {
                        "DE": {
                            "association_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere\n                    Version mit MRZ-Zeilen) o Aufenthaltstitel (solo se si trova in un paese europeo)",
                            "association_statutes": "Handelsregisterauszug",
                            "company_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere\n                    Version mit MRZ-Zeilen) o Aufenthaltstitel (solo se si trova in un paese europeo)",
                            "company_registration_document": "Handelsregisterauszug",
                            "company_statutes": "Aktuelle und unterschriebene (von der Geschäftsführung) Gesellschaftverträge",
                            "individual_first_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere Version mit\n                    MRZ-Zeilen) o Aufenthaltstitel (solo se si trova in un paese europeo)",
                            "individual_registration_document": "Gewerbeschein, Handlungsbevollmächtigung, Handelsregisterauszug,\n                    Prokura, lettera di attribuzione Steuernummer",
                            "individual_second_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere Version mit\n                    MRZ-Zeilen) o Aufenthaltstitel (solo se si trova in un paese europeo)"
                        },
                        "ES": {
                            "association_legal_representative_identity_piece": "Passaporte, Documento nazionale di identità,\n                    Permesso di condurre (versione recente con linee MRZ) o Permesso di residenza (solo se si tratta di un\n                    paese europeo)",
                            "association_statutes": "Escrituras de costitución o Estatutos sociales",
                            "company_legal_representative_identity_piece": "Passaporte, Documento nacional de identidad, Permiso\n                    de conducir (versione recente con linee MRZ) o Permiso de residencia (solo se si tratta di un paese\n                    europeo)",
                            "company_registration_document": "Estratto del Registro Mercantil Central, Nota Simple del Registro\n                    mercantil local sellado o numerico, Extracto desde los Registradores de España (informacion\n                    General Mercantil) con data di validità di meno di 3 mesi o Modello 600 con data di validità\n                    de menos de 3 meses (solo para sociedad civil)",
                            "company_statutes": "Escrituras de costitución o Estatutos sociales",
                            "individual_first_identity_piece": "Passaporto, Documento nazionale di identità, Permiso de conducir\n                    (versione recente con linee MRZ) o Permesso di residenza (solo se si tratta di un paese europeo)",
                            "individual_registration_document": "Agencia Tributaria - Certificado de situacion Censal\n                    (Alta Censiale)",
                            "individual_second_identity_piece": "Passaporto, Documento nazionale di identità, Permiso de conducir\n                    (versione recente con linee MRZ) o Permesso di residenza (solo se si tratta di un paese europeo)"
                        },
                        "FR": {
                            "association_legal_representative_identity_piece": "Un pezzo d'identità nel corso di validità del\n                    rappresentante legale dell'associazione: carta d'identità (recto e verso), passaporto, titolo di\n                    séjour (recto et verso), permis de conduire biométrique (recto et verso)",
                            "association_statutes": "Gli statuti dell'associazione",
                            "company_legal_representative_identity_piece": "Un pezzo d'identità nel corso di validità del\n                    rappresentante legale della società: carta d'identità (recto e verso), passaporto, titolo di soggiorno\n                    (recto e verso), permesso di conduzione biometrica (recto e verso)",
                            "company_registration_document": "Un estratto KBIS",
                            "company_statutes": "Gli statuti",
                            "individual_first_identity_piece": "Una prima identità in corso di validità: carta\n                    d'identità (recto e verso), passaporto, titolo di soggiorno (recto e verso), permesso di condotta\n                    biometrico (recto e verso)",
                            "individual_registration_document": "Un certificato di iscrizione all'INSEE o un documento\n                    attestant de son inscription auprès de la Chambre de Commerce (per un auto-imprenditore exerçant\n                    un'attività commerciale) o oltre la Camera dei mestieri (per un autoimprenditore che esercita\n                    un'attività artigianale)",
                            "individual_second_identity_piece": "Un secondo pezzo d'identità in corso di validità: carta\n                    d'identità (recto e verso), passaporto, titolo di soggiorno (recto e verso), permesso di condotta\n                    (recto et verso), dernier avis d'imposition daté de moins d'un an, livret de famille ou\n                    récépissé d'enregistrement du pacte civil de solidarité"
                        },
                        "IT": {
                            "association_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, La\n                    patente di guida (versione recente con linee MRZ) o permesso di residenza (solo se di un paese\n                    europeo)",
                            "association_statutes": "tatuaggio firmato dal Legale Rappresentante (se non incluso all'interno della\n                    Visione Camerale",
                            "company_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, La patente\n                    di guida (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "company_registration_document": "Visione Camerale",
                            "company_statutes": "Statuto firmato dal Legale Rappresentante (se non incluso all'interno della\n                    Vista Camerale)",
                            "individual_first_identity_piece": "Passaporto, Carta d'identità nazionale, La patente di guida\n                    (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "individual_registration_document": "Estratto dal registro delle imprese (\"Visura Camerale\") di meno\n                    di 3 mesi",
                            "individual_second_identity_piece": "Passaporto, Carta d'identità nazionale, La patente di guida\n                    (versione recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)"
                        },
                        "TH": {
                            "association_legal_representative_identity_piece": "เFP Risposta:\n                    บัตรประจำตัวประชาชน (ทั้งด้านหน้าและด้านหล sì),\n                    หนังสือเดินทาง, ใบอนุญาตมีถิ่นที่อยู่ (ทั้งด ้านหน้าและด้านหลัง),\n                    ใบขับขี่ ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านห ลัง)",
                            "association_statutes": "กฎเกณฑ์ของสมาคม",
                            "company_legal_representative_identity_piece": "เFP Risposta:\n                    บัตรประจำตัวประชาชน (ด้านหน้าและด้านหลัง), หน Grazie,\n                    ใบอนุญาตมีถิ่นที่อยู่ (ด้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ด้านหน้าและด้านหลัง)",
                            "company_registration_document": "Scarica KBIS",
                            "company_statutes": "สถานะต่างๆ",
                            "individual_first_identity_piece": "Leggi di più: Altro Bene\n                    (ทั้งด้านหน้าและด้านหลัง), หนังสือเดินทาง, ใบ อนุญาตมีถิ่นที่อยู่ (ทั้งด้านหน้าและด้านหลัััง) ),\n                    ใบขับขี่ ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านห ลัง)",
                            "individual_registration_document": "หนังสือรับรองการจดทะเบียนกับอินทรีหรือเอก สารรับรองการจดทะเบียนกับหอการค้า\n                    (Più ชิงพาณิชย์)\n                    หรือกับหอการค้า (สำหรับผู้ประกอบอาชีพอิสระ ที่ดำเนินกิจการเชิงพาณิชย์)\n                    กิจกรรมงานฝีมือ)",
                            "individual_second_identity_piece": "Vedi l'articolo: ัวประชาชน\n                    (ด้านหน้าและด้านหลัง) หนังสือเดินทาง ใบอนุญา ตมีถิ่นที่อยู่\n                    (ด้านหน้าและด้านหลัง) ใบขับขี่ (ด้านหน้าและด้ านหลัง)\n                    La maggior parte dei casi Questo è il tuo caso ำหรับการจดทะเบียน\n                    Vedi di più"
                        }
                    },
                    "steps": {
                        "claim": "Reclamo",
                        "context": "Contesto",
                        "create": "Creare",
                        "documents": "Documenti",
                        "info": "Informazione",
                        "search": "Ricerca"
                    }
                },
                "missing_info": {
                    "success": "Il reclamo è stato rifiutato per informazioni mancanti"
                },
                "notice": {
                    "actions": {
                        "edit": "Modifica la mia richiesta"
                    },
                    "already_claimed": {
                        "description": "Questo punto di interesse è già stato rivendicato. Se pensi che questo sia un errore, puoi farlo\n                compila il modulo di controversia sottostante.",
                        "title": "Punto di interesse già rivendicato!"
                    },
                    "find_or_new": {
                        "description": "Cerca un punto di interesse nell'elenco sottostante o creane uno nuovo.",
                        "title": "Cerca o crea un PDI"
                    },
                    "missing-info": {
                        "infos": "Completa la tua richiesta cliccando sul pulsante qui sotto.",
                        "state": "Il tuo reclamo è attualmente in attesa di ulteriori informazioni o documentazione:",
                        "title": "In attesa di ulteriori informazioni"
                    },
                    "pending": {
                        "state": "La tua richiesta di reclamo è attualmente in fase di elaborazione",
                        "title": "Richiesta di reclamo in sospeso"
                    },
                    "rejected": {
                        "state": "La tua richiesta di reclamo è stata respinta per il seguente motivo:",
                        "title": "Richiesta di reclamo respinta"
                    }
                },
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "Reclamo",
                            "poi": "Punto d'interesse",
                            "user": "Utente"
                        }
                    }
                },
                "rejected": {
                    "success": "Il reclamo è stato respinto"
                },
                "revoked": {
                    "success": "Il reclamo è stato revocato"
                },
                "states": {
                    "missing-info": "Informazione mancante",
                    "pending": "In attesa di",
                    "rejected": "Respinto",
                    "revoked": "Revocato",
                    "validated": "Convalidato"
                },
                "update": {
                    "success": "Il reclamo è stato aggiornato"
                },
                "valid_identity_pieces": "carta d'identità, passaporto o patente di guida validi",
                "validated": {
                    "success": "Il reclamo è stato convalidato"
                }
            },
            "common": {
                "actions": {
                    "accept": "Accettare",
                    "accept_contact": "Accetta richiesta di contatto",
                    "add": "Aggiungere",
                    "add_contact": "Aggiungi ai contatti",
                    "add_excursion": "Aggiungi all'itinerario",
                    "add_message": "Aggiungi un messaggio",
                    "apply": "Fare domanda a",
                    "approve": "Approvare",
                    "archive": "Archivio",
                    "attach": "Allegare",
                    "block_contact": "Bloccare un contatto",
                    "cancel": "Annulla",
                    "check": "Controllo",
                    "claim": "Reclamo",
                    "close": "Vicino",
                    "configure": "Configura",
                    "confirm": "Confermare",
                    "contest": "Concorso",
                    "create": "Creare",
                    "decline": "Negare",
                    "decline_contact": "Rifiuta la richiesta di contatto",
                    "delete": "Eliminare",
                    "download": "Scaricamento",
                    "edit": "Modificare",
                    "file_preview": "File di anteprima",
                    "filters": "Filtri",
                    "give_a_tip": "Dai un consiglio",
                    "google_maps": "Apri su Google Maps",
                    "help": "Aiuto",
                    "hide": "Nascondere",
                    "ignore": "Ignorare",
                    "interactions": "Mostra le interazioni",
                    "invite": "Invitare",
                    "load_more": "Carica di più",
                    "login": "Login",
                    "logout": "Disconnettersi",
                    "manage": "Maneggio",
                    "next": "Prossimo",
                    "pause": "Pausa",
                    "pay": "Paga",
                    "play": "Giocare",
                    "print": "Stampa",
                    "quit": "Esentato",
                    "record": "Documentazione",
                    "register": "Registrati",
                    "report": "Rapporto",
                    "request_to_join": "Richiedi di aderire",
                    "reset": "Ripristina",
                    "resume": "Riprendere",
                    "retry": "Riprova",
                    "return": "Indietro",
                    "save": "Salva",
                    "save_alt": "Salva",
                    "search": "Ricerca",
                    "see_plans": "Vedi i piani",
                    "see_website": "Vedi il sito web",
                    "send": "Inviare",
                    "send_link": "Invia collegamento all'app",
                    "share": "Condividere",
                    "show": "Spettacolo",
                    "start": "Inizio",
                    "stop": "Fermare",
                    "subscribe": "sottoscrivi",
                    "thank": "Grazie",
                    "thank_for_stay": "Grazie per il soggiorno",
                    "traveller_show": "Consulta il viaggiatore",
                    "unblock_contact": "Sblocca il contatto",
                    "unblock_this_user": "Sblocca questo utente",
                    "update": "Aggiornamento",
                    "validate": "Convalidare"
                },
                "all": "Tutto",
                "and": "E",
                "app_name": "UgoZER",
                "back_to_dashboard": "Torna al cruscotto",
                "back_to_home": "Tornare a casa",
                "characters_limit": "{length}/ {limit} caratteri",
                "counter": "{dividend} su {divisor}",
                "country": "Paese",
                "dashboard": "Pannello di controllo",
                "errors": {
                    "deleted_user": "Utente eliminato",
                    "internal": "Qualcosa è andato storto!",
                    "not_found": "Non trovato"
                },
                "false": "NO",
                "fast": "veloce",
                "home": "Casa",
                "later": "Dopo",
                "loading": "caricamento",
                "messages": {
                    "application_update": {
                        "if_ignore": "Se ignori l'aggiornamento, verrà eseguito al successivo avvio dell'applicazione.",
                        "new_version_available": "Una nuova versione è disponibile.",
                        "updating": "In aggiornamento...",
                        "want_to_refresh": "Vuoi riavviare l'applicazione per aggiornarla?"
                    },
                    "clipboard": {
                        "error": "Errore durante la copia negli appunti...",
                        "success": "Copiato negli appunti!"
                    },
                    "no_records": "Nessun record trovato"
                },
                "missing_title": "Titolo mancante",
                "more_options": "più opzioni",
                "no": "NO",
                "none": "nessuno",
                "normal": "normale",
                "not_specified": "Non specificato",
                "online": {
                    "connected": "Sei di nuovo connesso a Internet",
                    "disconnected": "Non sei più connesso a Internet"
                },
                "oops": "ops",
                "or": "O",
                "qr_code": "QR Code",
                "search": "Ricerca",
                "slow": "lento",
                "someone": "qualcuno",
                "sort": "ordinare",
                "tip": "Mancia",
                "tip_received": "Suggerimento ricevuto (se abilitato)",
                "true": "SÌ",
                "units": {
                    "kilometer": "chilometro | chilometri",
                    "km": "km",
                    "mi": "mi",
                    "mile": "miglio | miglia"
                },
                "welcome": "Benvenuto",
                "yes": "SÌ"
            },
            "cookies": {
                "details": {
                    "content": "Ecco l'elenco dei cookie inseriti in questa sezione dell'applicazione UgoZER basata su\n            categorie sopra definite:",
                    "table": {
                        "headers": {
                            "category": "Categoria d'uso",
                            "description": "Descrizione",
                            "lifespan": "Tutta la vita",
                            "mandatory": "Obbligatorio",
                            "name": "Nome del cookie (o modello)"
                        },
                        "lines": {
                            "alphanumeric": {
                                "content": "Contiene informazioni sulla sessione.",
                                "lifespan": "2 ore",
                                "title": "[[{alnum}:]] (sequenza di caratteri alfanumerici)"
                            },
                            "gmaps": {
                                "content": "Utilizzato per mostrare iframe di Google Maps.",
                                "lifespan": "180-400 giorni",
                                "title": "CONSENT, AEC, SOCS... (o altri cookie del dominio google.com)"
                            },
                            "remember": {
                                "content": "Utilizzato per mantenere l'autenticazione dell'utente.",
                                "lifespan": "400 giorni",
                                "title": "ricorda_web_*"
                            },
                            "session": {
                                "content": "Contiene informazioni sulla sessione.",
                                "lifespan": "2 ore",
                                "title": "*_ugozer_session"
                            },
                            "tarteaucitron": {
                                "content": "Consente di mantenere le impostazioni dei cookie dell'utente.",
                                "lifespan": "365 giorni",
                                "title": "tareaucitron"
                            },
                            "xsrf": {
                                "content": "Permette di proteggere l'applicazione (richieste, moduli...).",
                                "lifespan": "2 ore",
                                "title": "XSRF-TOKEN-*"
                            }
                        }
                    },
                    "title": "Dettagli dei cookie depositati"
                },
                "management": {
                    "content": {
                        "cta": "Cambia le mie preferenze",
                        "line_1": "Alcune funzionalità di questo sito si basano sull'uso dei cookie.",
                        "line_2": "Il banner sui cookie non compare nella home page durante la navigazione se solo necessario\n                al funzionamento del sito sono depositati.",
                        "line_3": "Puoi modificare le tue preferenze cliccando sul pulsante qui sotto:"
                    },
                    "title": "Gestione dei cookie"
                },
                "notice": {
                    "needed": "Per visualizzare questa sezione è necessario accettare i cookie {name}.",
                    "refresh": "Se non lo vedi dopo aver accettato i cookie, aggiorna la pagina.",
                    "title": "Biscotti"
                },
                "services": {
                    "gmaps": "Google Maps",
                    "youtube": "Youtube"
                },
                "usage": {
                    "content": "I cookie possono essere utilizzati per riconoscerti quando visiti UgoZER, ricordare le tue preferenze e dare\n            un'esperienza personalizzata in linea con le tue impostazioni. I cookie migliorano anche le tue interazioni con\n            UgoZER più veloce e più sicuro. Inoltre, i cookie ci consentono di offrirti pubblicità sia all'interno che all'esterno del sito\n            Siti UgoZER.",
                    "table": {
                        "headers": {
                            "category": "Categoria d'uso",
                            "description": "Descrizione"
                        },
                        "lines": {
                            "ads": {
                                "content": "Potremmo utilizzare i cookie per mostrarti pubblicità pertinente sia all'interno che all'esterno del sito UgoZER.\n                        Potremmo anche utilizzare un cookie per sapere se qualcuno che ha visto un annuncio ha successivamente visitato e preso un\n                        azione (ad esempio scaricato un white paper o effettuato un acquisto) sul sito dell'inserzionista.\n                        Allo stesso modo, i nostri partner possono utilizzare un cookie per determinare se abbiamo mostrato un annuncio e in che modo\n                        eseguito o fornirci informazioni su come interagisci con gli annunci. Potremmo anche lavorare\n                        con un partner per mostrarti un annuncio dentro o fuori UgoZER, ad esempio dopo aver visitato quello di un partner\n                        sito o applicazione.",
                                "title": "Pubblicità"
                            },
                            "authentication": {
                                "content": "Se hai effettuato l'accesso a UgoZER, i cookie ci aiutano a mostrarti le informazioni giuste e\n                        personalizza la tua esperienza",
                                "title": "Autenticazione"
                            },
                            "security": {
                                "content": "Utilizziamo i cookie per abilitare e supportare le nostre funzionalità di sicurezza e per aiutarci a rilevare\n                        attività dannose e violazioni dei ns",
                                "cta": "Termini e Condizioni.",
                                "title": "Sicurezza"
                            },
                            "services": {
                                "content": "I cookie forniscono funzionalità, approfondimenti e contenuti personalizzati.",
                                "title": "Preferenze, funzionalità e servizi"
                            }
                        }
                    },
                    "title": "A cosa servono i cookie?"
                }
            },
            "countries": {
                "AD": "Andorra",
                "AE": "Emirati Arabi Uniti",
                "AF": "Afghanistan",
                "AG": "Antigua e Barbuda",
                "AI": "Anguilla",
                "AL": "Albania",
                "AM": "Armenia",
                "AO": "L'Angola",
                "AQ": "Antartide",
                "AR": "Argentina",
                "AS": "Samoa americane",
                "AT": "Austria",
                "AU": "Australia",
                "AW": "Aruba",
                "AX": "Isole Aland",
                "AZ": "Azerbaigian",
                "BA": "Bosnia ed Erzegovina",
                "BB": "Barbados",
                "BD": "Bangladesh",
                "BE": "Belgio",
                "BF": "Burkina Faso",
                "BG": "Bulgaria",
                "BH": "Bahrein",
                "BI": "Burundi",
                "BJ": "Benin",
                "BL": "San Bartolomeo",
                "BM": "Bermude",
                "BN": "Brunei",
                "BO": "Bolivia",
                "BQ": "Paesi Bassi caraibici",
                "BR": "Brasile",
                "BS": "Bahamas",
                "BT": "Bhutan",
                "BV": "Isola Bouvet",
                "BW": "Botswana",
                "BY": "Bielorussia",
                "BZ": "Belize",
                "CA": "Canada",
                "CC": "Isole Cocos (Keeling).",
                "CD": "Congo-Kinshasa",
                "CF": "Repubblica Centrafricana",
                "CG": "Congo-Brazzaville",
                "CH": "Svizzera",
                "CI": "Costa d'Avorio",
                "CK": "Isole Cook",
                "CL": "Chile",
                "CM": "Camerun",
                "CN": "Cina",
                "CO": "Colombia",
                "CR": "Costa Rica",
                "CU": "Cuba",
                "CV": "capo Verde",
                "CW": "Curacao",
                "CX": "Isola di Natale",
                "CY": "Cipro",
                "CZ": "Cechia",
                "DE": "Germania",
                "DJ": "Gibuti",
                "DK": "Danimarca",
                "DM": "Dominica",
                "DO": "Repubblica Dominicana",
                "DZ": "Algeria",
                "EC": "Ecuador",
                "EE": "Estonia",
                "EG": "Egitto",
                "EH": "Sahara occidentale",
                "ER": "Eritrea",
                "ES": "Spagna",
                "ET": "Etiopia",
                "FI": "Finlandia",
                "FJ": "Figi",
                "FK": "Isole Falkland",
                "FM": "Micronesia",
                "FO": "Isole Faroe",
                "FR": "Francia",
                "GA": "Gabon",
                "GB": "Regno Unito",
                "GD": "Grenada",
                "GE": "Georgia",
                "GF": "Guiana francese",
                "GG": "Guernsey",
                "GH": "Ghana",
                "GI": "Gibilterra",
                "GL": "Groenlandia",
                "GM": "Gambia",
                "GN": "Guinea",
                "GP": "Guadalupa",
                "GQ": "Guinea Equatoriale",
                "GR": "Grecia",
                "GS": "Georgia del Sud e Isole Sandwich Meridionali",
                "GT": "Guatemala",
                "GU": "Guam",
                "GW": "Guinea-Bissau",
                "GY": "Guyana",
                "HK": "Regione amministrativa speciale di Hong Kong, Cina",
                "HM": "Isole Heard e McDonald",
                "HN": "Honduras",
                "HR": "Croazia",
                "HT": "Haiti",
                "HU": "Ungheria",
                "ID": "Indonesia",
                "IE": "Irlanda",
                "IL": "Israele",
                "IM": "Isola di Man",
                "IN": "India",
                "IO": "Territorio britannico dell'Oceano Indiano",
                "IQ": "Iraq",
                "IR": "Iran",
                "IS": "Islanda",
                "IT": "Italia",
                "JE": "Maglia",
                "JM": "Giamaica",
                "JO": "Giordania",
                "JP": "Giappone",
                "KE": "Kenia",
                "KG": "Kirghizistan",
                "KH": "Cambogia",
                "KI": "Kiribati",
                "KM": "Comore",
                "KN": "Saint Kitts e Nevis",
                "KP": "Corea del nord",
                "KR": "Corea del Sud",
                "KW": "Kuwait",
                "KY": "Isole Cayman",
                "KZ": "Kazakistan",
                "LA": "Laos",
                "LB": "Libano",
                "LC": "Santa Lucia",
                "LI": "Liechtenstein",
                "LK": "Sri Lanka",
                "LR": "Liberia",
                "LS": "Lesoto",
                "LT": "Lituania",
                "LU": "Lussemburgo",
                "LV": "Lettonia",
                "LY": "Libia",
                "MA": "Marocco",
                "MC": "Monaco",
                "MD": "Moldavia",
                "ME": "Montenegro",
                "MF": "San Martino",
                "MG": "Madagascar",
                "MH": "Isole Marshall",
                "MK": "Macedonia del Nord",
                "ML": "Mali",
                "MM": "Myanmar (Birmania)",
                "MN": "Mongolia",
                "MO": "RAS di Macao, Cina",
                "MP": "Isole Marianne settentrionali",
                "MQ": "Martinica",
                "MR": "La Mauritania",
                "MS": "Montserrat",
                "MT": "Malta",
                "MU": "Maurizio",
                "MV": "Maldive",
                "MW": "Malawi",
                "MX": "Messico",
                "MY": "Malaysia",
                "MZ": "Mozambico",
                "NA": "Namibia",
                "NC": "Nuova Caledonia",
                "NE": "Niger",
                "NF": "Isola Norfolk",
                "NG": "Nigeria",
                "NI": "Nicaragua",
                "NL": "Olanda",
                "NO": "Norvegia",
                "NP": "Nepal",
                "NR": "Nauru",
                "NU": "Niue",
                "NZ": "Nuova Zelanda",
                "OM": "Oman",
                "PA": "Panama",
                "PE": "Perù",
                "PF": "Polinesia francese",
                "PG": "Papua Nuova Guinea",
                "PH": "Filippine",
                "PK": "Pakistan",
                "PL": "Polonia",
                "PM": "Saint Pierre e Miquelon",
                "PN": "Isole Pitcairn",
                "PR": "Porto Rico",
                "PS": "territori palestinesi",
                "PT": "Portogallo",
                "PW": "Palau",
                "PY": "Paraguay",
                "QA": "Qatar",
                "RE": "Riunione",
                "RO": "Romania",
                "RS": "Serbia",
                "RU": "Russia",
                "RW": "Ruanda",
                "SA": "Arabia Saudita",
                "SB": "Isole Salomone",
                "SC": "Seychelles",
                "SD": "Sudan",
                "SE": "Svezia",
                "SG": "Singapore",
                "SH": "Sant'Elena",
                "SI": "Slovenia",
                "SJ": "Svalbard e Jan Mayen",
                "SK": "Slovacchia",
                "SL": "Sierra Leone",
                "SM": "San Marino",
                "SN": "Senegal",
                "SO": "Somalia",
                "SR": "Suriname",
                "SS": "Sudan del Sud",
                "ST": "San Tommaso e Principe",
                "SV": "El Salvador",
                "SX": "Sint Maarten",
                "SY": "Siria",
                "SZ": "Swaziland",
                "TC": "Isole Turks e Caicos",
                "TD": "Chad",
                "TF": "Territori della Francia del sud",
                "TG": "Andare",
                "TH": "Tailandia",
                "TJ": "Tagikistan",
                "TK": "Tokelau",
                "TL": "Timor Est",
                "TM": "Turkmenistan",
                "TN": "Tunisia",
                "TO": "Tonga",
                "TR": "Tacchino",
                "TT": "Trinidad & Tobago",
                "TV": "Tuvalu",
                "TW": "Taiwan",
                "TZ": "Tanzania",
                "UA": "Ucraina",
                "UG": "Uganda",
                "UM": "Isole periferiche degli Stati Uniti",
                "US": "stati Uniti",
                "UY": "Uruguay",
                "UZ": "Uzbekistan",
                "VA": "Città del Vaticano",
                "VC": "St. Vincent e Grenadine",
                "VE": "Venezuela",
                "VG": "Isole Vergini Britanniche",
                "VI": "Isole Vergini americane",
                "VN": "Vietnam",
                "VU": "Vanuatu",
                "WF": "Wallis e Futuna",
                "WS": "Samoa",
                "XK": "Kosovo",
                "YE": "Yemen",
                "YT": "Mayotte",
                "ZA": "Sud Africa",
                "ZM": "Zambia",
                "ZW": "Zimbabwe"
            },
            "credits": {
                "images": "Crediti fotografici"
            },
            "csv": {
                "common": {
                    "generating": "Generazione del file CSV in corso...",
                    "waiting": "Attendere prego..."
                },
                "download": "Esportazione CSV"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "Preferiti dal tuo esperto locale"
                },
                "links": {
                    "closest_pois": "Punti più vicini",
                    "excursions": "Itinerari e passeggiate",
                    "shares": "Azioni"
                },
                "no_dashboard": {
                    "contact": {
                        "link": "Contattaci.",
                        "text": "Se pensi che questo sia un errore, non esitare a farlo"
                    },
                    "content": "Non è stata configurata alcuna dashboard per il tuo ruolo oppure sei connesso come amministratore.",
                    "title": "Nessuna dashboard disponibile"
                },
                "points_of_interest": {
                    "categories": "Categorie di punti di interesse"
                }
            },
            "documents": {
                "display": {
                    "description": "Espositore in formato A6 con collegamento QRCode e logo della tua istituzione.",
                    "title": "Display: scheda A6"
                },
                "qrcode": {
                    "description": "Solo il QRCode consente al viaggiatore di essere direttamente collegato alla tua istituzione.",
                    "filename": "QR Code",
                    "title": "Collega il codice QR"
                }
            },
            "easy_transac": {
                "account": {
                    "create": {
                        "button": "Crea un conto EasyTransac",
                        "fail": "La connessione a EasyTransac non è riuscita, riprova più tardi",
                        "success": "Il tuo account EasyTransac è stato creato con successo"
                    },
                    "show": {
                        "account_usage": "Il tuo account EasyTransac consentirà al tuo istituto di ricevere pagamenti su UgoZER.",
                        "can_receive_payments": "I tuoi clienti ora possono dare la mancia al tuo locale\n                dal profilo UgoZER dei tuoi concierge.",
                        "can_retrieve_payments": {
                            "description": "È necessario completare il tuo account EasyTransac con le informazioni richieste in ordine\n                    per utilizzare i fondi disponibili nel tuo account.",
                            "title": "Il tuo account EasyTransac è incompleto"
                        },
                        "cannot_receive_payments": "Non puoi ricevere pagamenti",
                        "credentials_sent_mail": "Le tue credenziali EasyTransac ti sono state inviate via email.",
                        "fee_percentage": "Verrà applicata una commissione per ogni pagamento ricevuto.",
                        "go_to_easy_transac": "Accedi al pannello di controllo di EasyTransac",
                        "must_subscribe": "Devi essere iscritto per creare il tuo account EasyTransac.",
                        "no_account_yet": "Non hai ancora un conto EasyTransac.",
                        "should_complete_kyc": "Si prega di inviare i documenti richiesti da EasyTransac,\n                per ricevere i pagamenti."
                    }
                },
                "easy_transac": "EasyTransac"
            },
            "exceptions": {
                "default": "Si è verificato un errore",
                "error_texts": {
                    "403": {
                        "link": {
                            "title": "Potresti voler accedere a",
                            "to_app_label": "sezione viaggiatori?",
                            "to_extranet_label": "sezione professionale?"
                        },
                        "message": "Non hai accesso a questa pagina.",
                        "title": "Ops! Accesso vietato."
                    },
                    "credential_not_verified": {
                        "title": "Le tue credenziali non sono verificate",
                        "verify_mail": "Ricevi il link di verifica per la mia email",
                        "verify_phone": "Ricevi il link di verifica per il mio numero di telefono"
                    },
                    "opening_hours": {
                        "invalid": "Il valore fornito non è un'istanza di OpeningHours."
                    },
                    "password_missing": {
                        "app": "Si prega di impostare una password.",
                        "extranet": "Si prega di impostare una password per accedere all'applicazione."
                    }
                },
                "spam_detected": "Rilevato spam"
            },
            "excursions": {
                "destroy": {
                    "confirm": "Vuoi davvero eliminare questo itinerario: {excursion_name}?",
                    "success": "L'itinerario è stato cancellato"
                },
                "direction": {
                    "bicycling": "andare in bicicletta",
                    "display_mode": "Modalità display",
                    "driving": "guida",
                    "walking": "a piedi"
                },
                "form": {
                    "all_points_of_interest": "Tutti i punti di interesse",
                    "create_new_point_of_interest": "Crea un nuovo punto di interesse",
                    "existing_excursions": "Itinerari esistenti",
                    "from_existing_excursions": "Da un itinerario esistente",
                    "order_hint": "L'ordine dei punti di interesse corrisponde all'ordine della visita.",
                    "points_of_interest": "Punti di interesse",
                    "select_from_points_of_interest": "Seleziona dai punti di interesse",
                    "your_excursion": "Il tuo itinerario"
                },
                "index": {
                    "alert": {
                        "content": "Puoi solo visualizzare i percorsi. Se desideri creare o condividere un itinerario, devi accedere con\n                un <strong>account concierge/receptionist</strong>",
                        "title": "Itinerari solo in consultazione"
                    },
                    "no_results": "Non hai ancora alcun itinerario",
                    "no_results_here": "Nessun itinerario disponibile nella tua area di ricerca.",
                    "read_only": "Solo consultazione",
                    "search": "Cerca un itinerario",
                    "try_more_filters": "Puoi ampliare la ricerca o creare un itinerario.",
                    "your_excursions": "I tuoi itinerari"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "Itinerario",
                            "pois": "Punti di interesse"
                        }
                    }
                },
                "search": {
                    "no_results": "Nessun itinerario trovato"
                },
                "share": {
                    "contains_private": "Questo itinerario contiene punti di interesse privati. Potrebbero non essere inclusi nella quota.",
                    "heading": "Condividi questo itinerario",
                    "select_which_contacts": "Seleziona i contatti con cui vuoi condividere l'itinerario \"{excursion_title}\".",
                    "select_which_users": "Seleziona gli utenti con cui vuoi condividere l'itinerario \"{excursion_title}\" oppure inserisci\n            un indirizzo email per invitare un nuovo viaggiatore",
                    "select_which_users_alt": "Inserisci l'indirizzo email della persona con cui vuoi condividere il\n            \"{excursion_title}\" itinerario.",
                    "success": "L'itinerario è stato condiviso!"
                },
                "store": {
                    "create": "Crea un itinerario",
                    "heading": "Crea un itinerario",
                    "success": "L'itinerario è stato creato"
                },
                "update": {
                    "heading": "Aggiorna questo itinerario",
                    "success": "L'itinerario è stato aggiornato"
                }
            },
            "extranet": {
                "dashboard": {
                    "last_claims": {
                        "none": "Non hai ancora alcun reclamo.",
                        "see_all": "Visualizza tutte le tue affermazioni",
                        "see_index": "Vai alla pagina dei reclami"
                    },
                    "last_payments": {
                        "none": "Non hai ancora alcun pagamento.",
                        "see_all": "Visualizza tutti i tuoi pagamenti",
                        "see_index": "Vai alla pagina di pagamento"
                    },
                    "last_reservations": {
                        "none": "Non hai ancora alcuna prenotazione."
                    },
                    "last_traveller_folders": {
                        "none": "Non hai ancora nessun viaggiatore a tuo carico.",
                        "see_all": "Vedi tutti i tuoi viaggiatori",
                        "take_care": "Prenditi cura di un viaggiatore"
                    },
                    "manager": {
                        "sections": {
                            "butlers_institutions": "Portieri delle mie istituzioni",
                            "butlers_interactions": "Portieri con interazioni"
                        }
                    },
                    "sections": {
                        "last_claims": "Le tue ultime affermazioni",
                        "last_payments": "I tuoi ultimi pagamenti",
                        "last_reservations": "Le tue ultime prenotazioni",
                        "last_travellers": "I tuoi ultimi viaggiatori",
                        "search_butler": "Cerca un concierge",
                        "stats": "Statistiche"
                    },
                    "stats": {
                        "butlers": "Portiere|Portiere",
                        "employees": "Dipendente|Dipendenti",
                        "excursions": "Itinerario|Itinerari",
                        "folders": "Cartella|Cartelle",
                        "institutions": "Istituzioni collegate|Istituzioni collegate",
                        "processing_claims": "Elaborazione del reclamo|Elaborazione dei reclami",
                        "reservations": "Prenotazione|Prenotazioni"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "Invita un dipendente",
                        "new-hotelier": "Crea un conto di accoglienza",
                        "new-tourist-office": "Crea un account da scrivania"
                    }
                },
                "excursions": {
                    "actions": {
                        "new": "Crea un itinerario"
                    },
                    "sections": {
                        "excursions": "Itinerari"
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "Categorie"
                    }
                },
                "filters": {
                    "closed": "Chiudi i filtri",
                    "open": "Apri filtri"
                },
                "finances": {
                    "alerts": {
                        "easytransac": {
                            "cta": "Accedi al mio profilo per creare un account EasyTransac",
                            "description": "Non hai ancora creato un conto EasyTransac! Quindi non puoi ricevere pagamenti.",
                            "title": "Conto EasyTransac inesistente!"
                        }
                    },
                    "sections": {
                        "issued_payments": "Pagamenti emessi",
                        "received_by_institution_payments": "Pagamenti ricevuti dai vostri istituti",
                        "received_payments": "Pagamenti ricevuti"
                    }
                },
                "institution": {
                    "messages": {
                        "no_records": "Nessuna istituzione collegata al momento"
                    },
                    "sections": {
                        "butler": {
                            "institution": "La mia istituzione"
                        },
                        "manager": {
                            "institution": "Le mie istituzioni"
                        }
                    }
                },
                "navbar": {
                    "contact": "Contattaci",
                    "contacts": "Contatti",
                    "dashboard": "Pannello di controllo",
                    "excursions": "Itinerari",
                    "faq": "FAQ",
                    "finances": "Finanze",
                    "invitations": "Inviti",
                    "my_employees": "I miei dipendenti",
                    "my_institution": "La mia istituzione",
                    "my_institutions": "Le mie istituzioni",
                    "points_of_interest": "Punti di interesse",
                    "travellers": "I viaggiatori",
                    "tutorials": "Inizio",
                    "users": "Utenti"
                },
                "pages": {
                    "excursions": {
                        "edit": "Modifica itinerario",
                        "new": "Nuovo itinerario"
                    },
                    "folders": {
                        "edit": "Modifica la cartella del viaggiatore",
                        "new": "Nuova cartella da viaggio"
                    },
                    "institutions": {
                        "new": "Collegare un'istituzione"
                    },
                    "pois": {
                        "edit": "Modifica punto di interesse",
                        "new": "Nuovo punto di interesse"
                    },
                    "profile": {
                        "edit": "Modifica Profilo"
                    },
                    "reservations": {
                        "edit": "Modifica prenotazione",
                        "new": "Nuova prenotazione"
                    }
                },
                "points_of_interest": {
                    "actions": {
                        "new": "Crea un punto di interesse"
                    },
                    "sections": {
                        "points_of_interest": "Punti di interesse"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "Nessuna FAQ al momento"
                    }
                },
                "professional_section": "Sezione professionale",
                "profile": {
                    "sections": {
                        "currency": "Valuta",
                        "delete_account": "Cancellazione dell'account",
                        "infos": "informazioni generali",
                        "locale": "Lingua",
                        "notification_settings": "Impostazioni delle notifiche",
                        "payment": "Pagamento",
                        "privacy_settings": "Impostazioni della privacy",
                        "shares": "Azioni"
                    }
                },
                "support_messages": {
                    "actions": {
                        "back": "Torniamo alla richiesta di supporto",
                        "new": "Aggiungi un nuovo messaggio"
                    },
                    "sections": {
                        "support_messages": "Messaggi"
                    }
                },
                "support_requests": {
                    "actions": {
                        "back": "Indietro",
                        "new": "Crea una richiesta di supporto"
                    },
                    "sections": {
                        "support_requests": "Richieste di supporto"
                    }
                },
                "travellers": {
                    "buttons": {
                        "new_folder": "Aggiungi una nuova cartella del viaggiatore"
                    },
                    "sections": {
                        "folders": "Le cartelle dei miei viaggiatori"
                    }
                },
                "user_dropdown": {
                    "changelogs": "Log delle modifiche",
                    "cookies": "Gestione dei cookie",
                    "credits": "Crediti",
                    "customers": "Clienti",
                    "partners": "Partner",
                    "privacy_policy": "Politica sulla riservatezza",
                    "profile": "Il mio profilo",
                    "sign_out": "disconnessione",
                    "subscription": "Sottoscrizione",
                    "terms_condition": "Termini e Condizioni"
                }
            },
            "form": {
                "file": {
                    "current_file": "File effettivo: {name}"
                },
                "hints": {
                    "auth": {
                        "register": {
                            "email": "Questo indirizzo email non deve essere già collegato a un account UgoZER",
                            "employee": {
                                "not_duplicate_email": "L'indirizzo e-mail deve essere diverso da quello del",
                                "not_duplicate_password": "La password deve essere diversa da quella del"
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "Personalizzato: itinerario creato dal concierge\n                        per un viaggiatore specifico",
                                "root": "I miei modelli: itinerario \"pronto da condividere\" composto da punti di interesse imperdibili,\n                        o punti di interesse per tema\n                        (esempio: elenco dei ristoranti frequentemente inseriti negli itinerari)\n                        da selezionare per creare un nuovo itinerario.",
                                "ugozer_app": "App UgoZER: itinerario creato e proposto da UgoZER\n                        e messo a disposizione dei viaggiatori.",
                                "ugozer_pro": "UgoZER Pro: itinerario ideato e proposto da UgoZER\n                        e messi a disposizione dei professionisti per facilitarne il lavoro."
                            },
                            "pro": {
                                "customized": "Itinerario creato per un viaggiatore specifico",
                                "root": "itinerario \"pronto da condividere\" composto da punti di interesse imperdibili,\n                        o punti di interesse per tema\n                        (esempio: elenco dei ristoranti frequentemente inseriti negli itinerari)\n                        da selezionare per creare un nuovo itinerario.",
                                "ugozer": "Itinerario ideato e proposto da UgoZER."
                            }
                        },
                        "points_of_interest_search": "Cerca e seleziona un punto di interesse per aggiungerlo all'elenco"
                    },
                    "excursions_filters": {
                        "radius": "Vedrai solo gli itinerari con almeno un punto di interesse situato all'interno di questo raggio."
                    },
                    "geoloc_setting": {
                        "filter_header": "Cerca un paese, una regione, una città o un quartiere",
                        "kind": {
                            "admin": {
                                "around_me": "Intorno a me: verrà determinata la geolocalizzazione\n                        dal browser dell'utente.",
                                "customized": "Personalizzato: l'utente potrà cercare manualmente un luogo.",
                                "staying_hotel": "Pernottamento in hotel: verrà effettuata la geolocalizzazione\n                        dall'hotel dove alloggia il viaggiatore."
                            },
                            "app": {
                                "around_me": "La geolocalizzazione verrà effettuata dalla tua posizione.",
                                "customized": "La geolocalizzazione verrà effettuata da una località a tua scelta.",
                                "staying_hotel": "La geolocalizzazione verrà effettuata dall'hotel in cui soggiornerai: {title}."
                            }
                        }
                    },
                    "locale": {
                        "dynamic_translations_enabled": "Consente agli utenti di visualizzare o tradurre risorse\n                (punti di interesse, itinerari...) in quella lingua",
                        "fallback_locale": "Visualizza automaticamente il titolo in una seconda lingua (prima della descrizione) su\n                il file PDI.",
                        "static_translations_enabled": "Consente agli utenti di scegliere questa lingua per visualizzare l'applicazione\n                (richiede che le traduzioni siano state aggiunte al codice sorgente)"
                    },
                    "permission": {
                        "name": "Nome utilizzato nel codice sorgente"
                    },
                    "plan": {
                        "countries": "Lascia vuoto per rendere il piano disponibile in tutti i paesi",
                        "information": "Ulteriori informazioni sul piano (prerequisiti, condizioni...)",
                        "name": "Questo nome sarà visibile ai potenziali abbonati\n                quando scelgono l'abbonamento",
                        "private_title": "Questo titolo è visibile solo in amministrazione"
                    },
                    "plan_price": {
                        "description": "Questa descrizione sarà visibile ai potenziali abbonati\n                quando scelgono l'abbonamento",
                        "price": "Si prega di inserire in euro",
                        "private_title": "Questo titolo è visibile solo in amministrazione"
                    },
                    "point_of_interest": {
                        "audio_refreshing_1": "Abilitando l'aggiornamento del file audio verrà generato automaticamente un file audio\n                nella lingua del punto di interesse ({lingua}).",
                        "audio_refreshing_2": "Indicherà il titolo e l'indirizzo del punto di interesse.",
                        "audio_refreshing_3": "Verrà aggiornato non appena la lingua, il titolo\n                o l'indirizzo del punto di interesse vengono aggiornati.",
                        "audio_refreshing_4": "L'aggiornamento verrà disabilitato se aggiungi o registri tu stesso un file audio,\n                o se elimini il file audio generato.",
                        "cant_have_employees": "Qui vedi solo gli stabilimenti associati alle categorie che potrebbero avere\n                dipendenti",
                        "expiry_date": "Il tuo punto di interesse scadrà {date}",
                        "expiry_date_details_1": "Il tuo punto di interesse fa parte della categoria \"{category}\".",
                        "expiry_date_details_2": "Scadrà quindi il {date}, poiché questa è l'ultima data inserita.",
                        "expiry_date_details_3": "Da questa data, al tuo punto di interesse resteranno 21 giorni\n                prima che venga eliminato automaticamente..",
                        "expiry_date_details_4": "Puoi sempre aggiungere una nuova data\n                per posticipare la scadenza o non specificare alcuna data in modo che il punto di interesse non scada.",
                        "images": "Le immagini verranno visualizzate nella gallery del punto di interesse nell'ordine prescelto,\n                puoi riorganizzarli trascinandoli.",
                        "is_geocoding": "Disattivare se la geolocalizzazione è inserita correttamente ma la mappa continua a non apparire.",
                        "line1": "Puoi inserire l'indirizzo o il nome del luogo che stai cercando. Scegli tra\n                elenco per compilare automaticamente l'indirizzo",
                        "locale": "La lingua principale parlata in questo punto di interesse",
                        "opening_dates": "Lascia vuoto se le pianificazioni si applicano a qualsiasi data",
                        "opening_days": "Non selezionare nulla se gli orari si applicano a qualsiasi giorno",
                        "opening_hours": "Non aggiungere nulla se il punto di interesse è aperto in qualsiasi momento",
                        "sync_1": "L'attivazione dell'aggiornamento automatico consente di recuperare i dati dall' {import_source}\n                importare",
                        "sync_2": "Le voci manualmente verranno sovrascritte"
                    },
                    "points_of_interest_filters": {
                        "order_distance": "Scegli un filtro di distanza per selezionare questa opzione",
                        "order_relevance": "Inserisci le parole chiave di ricerca per selezionare questa opzione",
                        "radius": "Vedrai solo i punti di interesse situati all'interno di questo raggio."
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "butler": "Non importa quale opzione scegli, il tuo cognome e il tuo nome\n                    rimarrà visibile al responsabile del tuo istituto",
                            "traveller": "Non importa quale opzione scegli, il tuo cognome e il tuo nome\n                  rimarrà visibile dai concierge dell'hotel dove soggiornerai."
                        }
                    },
                    "profile": {
                        "contact_email": "Questa email può essere visualizzata sul tuo profilo pubblico",
                        "contact_phone": "Questo telefono può essere visualizzato sul tuo profilo pubblico",
                        "name": "Il nickname è pubblico e consente agli altri utenti dell'applicazione di identificarti più facilmente.",
                        "password_template": {
                            "digit": "1 cifra",
                            "length": "{length} caratteri",
                            "lowercase": "1 lettera minuscola",
                            "uppercase": "1 lettera maiuscola"
                        },
                        "password_unchanged": "Lascia vuoto per rimanere invariato"
                    },
                    "role": {
                        "description": "La descrizione del ruolo viene visualizzata agli utenti quando scelgono il ruolo\n                iscriviti alla sezione professionale.",
                        "name": "Nome in codice del ruolo, non verrà visualizzato agli utenti."
                    },
                    "support_request": {
                        "support_categories_search": "Cerca e seleziona una categoria"
                    },
                    "translatable": {
                        "pro_global": "Uno o più campi di questo modulo possono essere compilati in diverse lingue.",
                        "pro_global_2": "I campi non compilati in alcune lingue verranno tradotti automaticamente.",
                        "pro_global_title": "Ingresso multilingue"
                    },
                    "traveller_folder": {
                        "expired_on": "Per impostazione predefinita, la data di fine è impostata su una settimana"
                    },
                    "user": {
                        "bank_account_number": "Inserisci il numero di conto che verrà utilizzato per effettuare il trasferimento"
                    }
                },
                "labels": {
                    "geoloc_setting": {
                        "distance_filter": "filtro a distanza",
                        "limit_to_a_radius": "limite a un raggio",
                        "limit_to_a_radius_from_center": "limite a un raggio dal centro",
                        "measure_unit": "unità di misura",
                        "other_filters": "altri filtri",
                        "where_does_the_traveller_go": "dove va il viaggiatore?",
                        "where_would_you_like_to_go": "dove vorresti andare?",
                        "within_a_radius": "entro un raggio"
                    },
                    "payment": {
                        "payable_type": "Pagamento collegato a"
                    },
                    "plan": {
                        "authorized_roles": "ruoli autorizzati",
                        "tipping_fees": "Spese di mancia (percentuale)"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "prezzo tasse escluse"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "Aggiorna automaticamente il file audio",
                        "opening": "apertura",
                        "opening_date": "SU",
                        "opening_date_from": "da",
                        "opening_date_range": "specificare una data di fine",
                        "opening_date_to": "A",
                        "opening_hours": "A",
                        "opening_hours_from": "da",
                        "opening_hours_range": "specificare un'ora di fine",
                        "opening_hours_to": "A",
                        "opening_schedule_add": "specificare giorni o orari",
                        "opening_schedule_add_alt": "specificare gli orari",
                        "opening_specification_add": "aggiungi un punto"
                    },
                    "points_of_interest_filters": {
                        "geoloc": "posto",
                        "order_distance": "per distanza",
                        "order_relevance": "per pertinenza (parole chiave di ricerca)",
                        "sort": "ordinare",
                        "tags": "perfezionare"
                    },
                    "register": {
                        "communication_consent": "Desidero ricevere comunicazioni personalizzate, anche su offerte,\n                informazioni promozionali e prodotti relativi ai viaggi da UgoZER via e-mail.",
                        "privacy_policy": {
                            "part1": "sono d'accordo con",
                            "part2": "politica sulla riservatezza"
                        },
                        "terms_conditions": {
                            "part1": "sono d'accordo con",
                            "part2": "Termini e Condizioni"
                        }
                    },
                    "support_category": {
                        "title": "Scegli una categoria"
                    },
                    "support_message": {
                        "content": "Messaggio",
                        "email": "Indirizzo e-mail"
                    },
                    "support_request": {
                        "title": "Oggetto della richiesta"
                    },
                    "traveller_invitation": {
                        "locale": "lingua parlata da questo viaggiatore"
                    },
                    "user": {
                        "credential": "e-mail",
                        "email": "e-mail",
                        "image": {
                            "_destroy": "Elimina l'immagine del profilo"
                        },
                        "new_password": "nuova password",
                        "notes": "ulteriori informazioni"
                    },
                    "vat_rate": {
                        "vat_rate": "Aliquota IVA (percentuale)"
                    }
                },
                "placeholders": {
                    "audio_file_name": "nome del file audio",
                    "name": "soprannome",
                    "support_message": {
                        "create_message": "Il tuo messaggio per la richiesta di supporto"
                    }
                },
                "select": {
                    "no_options": "Nessuna opzione",
                    "no_results": "Spiacenti, nessuna opzione di corrispondenza.",
                    "type_to_search": "Ricerca..."
                },
                "titles": {
                    "verifications": "Controllo delle tue informazioni"
                }
            },
            "front": {
                "access_pro": "Vai all'accesso professionale",
                "app": "Applicazione per i viaggiatori",
                "brand_name": "UgoZER",
                "captcha": {
                    "and": "E",
                    "apply": "fare domanda a.",
                    "privacy_policy": "politica sulla riservatezza",
                    "protected": "Questo sito è protetto da hCaptcha e suoi",
                    "terms_service": "Termini di servizio"
                },
                "contact": {
                    "cancel": "Annulla la richiesta di supporto",
                    "contact": "Contatto",
                    "send": "Inviare"
                },
                "cookies": {
                    "title": "Gestione dei cookie"
                },
                "customers": {
                    "see_customers": "Vedi i nostri clienti",
                    "title": "Si fidano di noi"
                },
                "footer": {
                    "account_deletion": "Cancellazione dell'account",
                    "app": "Applicazione per i viaggiatori",
                    "contact": "Contatto",
                    "cookies": "Gestione dei cookie",
                    "copyright": "© {year} UgoZER - Tutti i diritti riservati.",
                    "facebook": "Facebook",
                    "faq": "FAQ",
                    "home": "Casa",
                    "instagram": "Instagram",
                    "legal_notice": "Avviso legale",
                    "linkedin": "Linkedin",
                    "privacy_policy": "Politica sulla riservatezza",
                    "pro": "Accesso professionale",
                    "sitemap": "Mappa del sito",
                    "terms_conditions": "Termini e Condizioni",
                    "twitter": "Twitter"
                },
                "header": {
                    "app": "Applicazione per viaggiatori",
                    "contact": "Contatto",
                    "faq": "FAQ",
                    "home": "Casa",
                    "pro": "Accesso professionale",
                    "you_are": "Sei"
                },
                "intro": {
                    "intro_1": "Con UgoZER hai sempre nel tuo smartphone i tuoi indirizzi preferiti in file multilingua,\n            udibile o leggibile, con un clic.",
                    "intro_2": "Quando sei all'estero, fai in modo che i tassisti e le altre persone capiscano facilmente la tua destinazione.",
                    "intro_3": "Receptionist, direttore dell'hotel, progettazione e offerta di programmi di tour. Condividili con i tuoi clienti\n            su UgoZER.",
                    "intro_title_pro": "PROFESSIONISTI",
                    "intro_title_traveller": "VIAGGIATORE",
                    "title_homepage": "La guida turistica multilingue, gratuita."
                },
                "navigation": {
                    "next": "Prossimo",
                    "previous": "Precedente"
                },
                "outro": {
                    "description": "Viaggia nel 2.0"
                },
                "partners": {
                    "no_records": "Nessun partner al momento",
                    "read_more": "Per saperne di più",
                    "see_partners": "Vedi i nostri partner",
                    "title": "Partner"
                },
                "posts": {
                    "no_records": "Nessun post al momento",
                    "title": "FAQ."
                },
                "pro": "Accesso professionale",
                "professionals": {
                    "argument_1": {
                        "part_1": "UgoZER fornisce una soluzione digitale per i tuoi ospiti.",
                        "part_2": "Migliora il loro soggiorno e l'esperienza dei loro ospiti.",
                        "part_3": "Consente al tuo hotel, resort o centro informazioni di creare soluzioni personalizzate, facili da usare e\n                itinerari mobili multilingue.",
                        "part_4": "Condividi viaggi personalizzati da un database di POI in tempo reale attraverso la piattaforma professionale UgoZER\n                e la sua app mobile."
                    },
                    "argument_2": {
                        "part_1": "Potrai selezionare per i tuoi ospiti itinerari preconfezionati.",
                        "part_2": "Crea il tuo con i migliori locali o ristoranti della città, tesori nascosti da scoprire, perfetti\n                sentieri escursionistici da godersi...",
                        "part_3": "Oppure scegli ogni fase della loro visita all'interno di un elenco esaustivo di POI in cui combinarli\n                volontà e poi condividere un itinerario personalizzato."
                    },
                    "argument_3": {
                        "part_1": "Ogni POI possiede una descrizione dettagliata, la sua posizione su una mappa interattiva e la sua\n                indirizzo tradotto automaticamente nella lingua locale.",
                        "part_2": "Il tuo ospite non si perderà mai. Troverà sempre la sua strada e tornerà facilmente da te\n                Hotel."
                    },
                    "argument_4": {
                        "part_1": "Alla fine del suo viaggio, dopo aver goduto di un itinerario fatto su misura, il tuo ospite potrà farlo\n                ringrazia il tuo concierge o receptionist per la sua raccomandazione unica dandogli una mancia.",
                        "part_2": "Utilizzando UgoZER, migliorerai la fidelizzazione attraverso l'aumento delle entrate e diminuirai il personale\n                turnover.",
                        "part_3": "Darai loro una motivazione maggiore per impegnarsi nella vita quotidiana del tuo hotel\n                e dare loro un senso di appartenenza."
                    },
                    "intro": {
                        "main_title": "UgoZER, l'assistente di viaggio digitale e personale multilingue. Porta il tuo ospite\n                esperienza al livello successivo e aumenta le tue entrate."
                    },
                    "outro": {
                        "catchphrase": "UgoZER ti aiuterà ad aumentare la soddisfazione e a stimolare la crescita."
                    }
                },
                "startup": {
                    "description_1": "UgoZER è un'azienda francese, con sede a Strasburgo, capitale d'Europa. Creato dal viaggio\n            amanti, facilita i tuoi viaggi in paesi di cui non parli la lingua.",
                    "description_2": "È consigliato dagli uffici turistici.",
                    "title": "La startup UgoZER"
                },
                "support_request": "Richiesta di supporto",
                "travellers": {
                    "argument_1": {
                        "arguments": {
                            "audio": "<strong>indirizzo audio</strong> nella lingua locale per ascoltare un taxi o un passante",
                            "desc": "<strong>descrizione dettagliata</strong>",
                            "languages": "Tutte le informazioni sono tradotte in <strong>francese, inglese, tedesco, olandese, portoghese,\n                    spagnolo, italiano, coreano, cinese, giapponese e arabo.</strong>",
                            "map": "<strong>Posizione su una mappa interattiva, possibile output su Google Maps™</strong>"
                        },
                        "part_1": "Accedi a tutti i punti di interesse (POI) aggiornati in tempo reale sul tuo smartphone.",
                        "part_2": "PDI classificati per <strong>categorie: monumenti, musei, mostre, negozi, ristoranti,\n                bar, eventi temporanei...</strong>",
                        "part_3": "PDI forniti con:"
                    },
                    "argument_2": {
                        "part_1": "Goditi il ​​viaggio con accesso permanente a informazioni complete. Tutto sul tuo smartphone,\n                proprio in tasca."
                    },
                    "argument_3": {
                        "part_1": "Con UgoZER ottieni automaticamente itinerari gratuiti. Puoi anche creare viaggi e condividerli\n                loro con i tuoi amici. Ottieni il massimo dal tuo viaggio ottenendo tour personalizzati dal tuo locale\n                esperto: concierge o receptionist dell'hotel, ufficio informazioni turistiche.",
                        "part_2": "Se il tuo viaggio ti è piaciuto, puoi anche ringraziare il tuo esperto locale con un messaggio digitalizzato e protetto\n                opzione mancia."
                    },
                    "intro": {
                        "main_title": "La tua guida turistica multilingue gratuita"
                    },
                    "outro": {
                        "catchphrase": "Con UgoZER lasciati guidare in sicurezza e viaggia in tutta tranquillità."
                    }
                },
                "you_are": {
                    "professional": "Professionale",
                    "traveller": "Viaggiatore"
                },
                "youtube": {
                    "directed_by": "Diretto da",
                    "producer": "Studio sopratesta"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "Scegli il tuo istituto"
                    },
                    "sections": {
                        "pending_claims": "In attesa di convalida",
                        "requests": "Richieste in sospeso",
                        "validated_claims": "Reclamato"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "La richiesta è stata inviata"
                },
                "approve": {
                    "confirm": {
                        "employee": "Vuoi davvero approvare la richiesta?",
                        "employer": "Vuoi davvero approvare {user_name}?"
                    },
                    "success": "La richiesta è stata approvata"
                },
                "cancel": {
                    "confirm": {
                        "employee": "Vuoi davvero annullare questa richiesta?",
                        "employer": "Vuoi davvero annullare la richiesta {user_name}?"
                    },
                    "success": "La richiesta è stata annullata"
                },
                "decline": {
                    "confirm": {
                        "change_institution": "La tua istituzione collegata verrà modificata!",
                        "employee": "Vuoi davvero rifiutare la richiesta?",
                        "employer": "Vuoi davvero rifiutare {user_name}?"
                    },
                    "success": "La richiesta è stata rifiutata"
                },
                "feature": {
                    "confirm": "Vuoi davvero presentare {user_name}?",
                    "folder": "Per un viaggiatore che non ha avuto alcuna interazione con uno dei tuoi dipendenti, è quello in evidenza\n           che verrà visualizzato nella cartella del viaggiatore.",
                    "only_one": "Può essere presentato un solo dipendente.",
                    "success": "Il dipendente è stato segnalato"
                },
                "index": {
                    "my_employees": "I miei dipendenti"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "Account",
                        "invitation": "Invito"
                    }
                },
                "invite": {
                    "invite": "Invita un dipendente"
                },
                "quit": {
                    "confirm": "Vuoi davvero lasciare questa istituzione {poi_name}?",
                    "success": "Hai lasciato l'istituto"
                },
                "remove": {
                    "confirm": "Vuoi davvero rimuovere {user_name}?",
                    "success": "Il dipendente è stato allontanato"
                },
                "show": {
                    "pending": "La tua richiesta di adesione a questa istituzione è attualmente in sospeso."
                },
                "states": {
                    "approved": "Approvato",
                    "canceled": "Annullato",
                    "declined": "Rifiutato",
                    "invited": "Invitato",
                    "pending": "In attesa di",
                    "removed": "RIMOSSO"
                }
            },
            "interactions": {
                "description": {
                    "contact approved": "La richiesta di contatto è stata approvata da {sender_name}",
                    "contact blocked": "La richiesta di contatto è stata bloccata da {sender_name}",
                    "contact created": "La richiesta di contatto è stata creata da {sender_name}",
                    "contact declined": "La richiesta di contatto è stata rifiutata da {sender_name}",
                    "invitation accepted": "L'invito è stato accettato da {sender_name}",
                    "invitation created": "L'invito è stato inviato da {sender_name}",
                    "payment succeeded": "{sender_name} ha inviato un pagamento a {recipient_name}",
                    "share created": "{sender_name} ha condiviso {share}",
                    "traveller_folder created": "La cartella Viaggiatore è stata creata da {sender_name}",
                    "traveller_folder thanked": "{sender_name} ha ringraziato il viaggiatore per il suo soggiorno"
                },
                "share_type": {
                    "excursions": "l'itinerario",
                    "pointsofinterest": "il punto di interesse"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "Invitare"
                },
                "states": {
                    "authenticated_once": {
                        "false": "In attesa di",
                        "true": "Convalidato"
                    }
                },
                "success": {
                    "professional": {
                        "sent_1": "Verrà inviata un'e-mail a {email}, se questo indirizzo non è già utilizzato.",
                        "sent_2": "Questa email contiene un link per registrarsi alla sezione professionale.",
                        "title": "Invito spedito"
                    },
                    "traveller": {
                        "email_1": "Verrà inviata un'e-mail a {email}, se questo indirizzo non è già in uso.",
                        "email_2": "Questa e-mail contiene un collegamento che consentirà di connettersi all'applicazione.",
                        "title": "Invito spedito"
                    }
                },
                "texts": {
                    "already_have_account": "L'utente ha già un account sull'applicazione?",
                    "description": "Puoi invitare qualcuno a iscriversi alla nostra applicazione con un indirizzo email o un numero di telefono.",
                    "employee_already_have_account": "Il dipendente ha già un account sulla domanda?",
                    "geoloc_settings": {
                        "description": "Le impostazioni di geolocalizzazione verranno automaticamente impostate sulle coordinate del tuo hotel\n                (se sei legato a una struttura).",
                        "title": "Impostazioni di geolocalizzazione"
                    },
                    "invite_link": {
                        "instructions": "Copia questo link per invitare un viaggiatore",
                        "title": "Invita tramite collegamento"
                    },
                    "invite_professional": "Invita un professionista",
                    "invite_traveller": "Invita un viaggiatore",
                    "new_traveller": "Nuovo viaggiatore",
                    "professional_description": "Puoi invitare un professionista ad iscriversi alla sezione professionale del ns\n            domanda con una e-mail",
                    "title": "Invita qualcuno"
                }
            },
            "locales": {
                "aa": "Lontano",
                "ab": "Abkhaziano",
                "af": "afrikaans",
                "af_NA": "Afrikaans (Namibia)",
                "af_ZA": "Afrikaans (Sudafrica)",
                "ak": "Akan",
                "ak_GH": "Akan (Ghana)",
                "am": "Amarico",
                "am_ET": "Amarico (Etiopia)",
                "an": "aragonese",
                "ar": "Arabo",
                "ar_AE": "Arabo (Emirati Arabi Uniti)",
                "ar_BH": "Arabo (Bahrein)",
                "ar_DJ": "Arabo (Gibuti)",
                "ar_DZ": "Arabo (Algeria)",
                "ar_EG": "Arabo (Egitto)",
                "ar_EH": "Arabo (Sahara occidentale)",
                "ar_ER": "Arabo (Eritrea)",
                "ar_IL": "Arabo (Israele)",
                "ar_IQ": "Arabo (Iraq)",
                "ar_JO": "Arabo (Giordania)",
                "ar_KM": "Arabo (Comore)",
                "ar_KW": "Arabo (Kuwait)",
                "ar_LB": "Arabo (Libano)",
                "ar_LY": "Arabo (Libia)",
                "ar_MA": "Arabo (Marocco)",
                "ar_MR": "Arabo (Mauritania)",
                "ar_OM": "Arabo (Oman)",
                "ar_PS": "Arabo (territori palestinesi)",
                "ar_QA": "Arabo (Qatar)",
                "ar_SA": "Arabo (Arabia Saudita)",
                "ar_SD": "Arabo (Sudan)",
                "ar_SO": "Arabo (Somalia)",
                "ar_SS": "Arabo (Sud Sudan)",
                "ar_SY": "Arabo (Siria)",
                "ar_TD": "Arabo (Ciad)",
                "ar_TN": "Arabo (Tunisia)",
                "ar_YE": "Arabo (Yemen)",
                "as": "Assamese",
                "as_IN": "Assamese (India)",
                "av": "Avarico",
                "ay": "Aymara",
                "az": "Azero",
                "az_AZ": "Azero (Azerbaigian)",
                "az_Cyrl": "Azero (cirillico)",
                "az_Cyrl_AZ": "Azero (cirillico, Azerbaigian)",
                "az_Latn": "Azero (latino)",
                "az_Latn_AZ": "Azero (latino, Azerbaigian)",
                "ba": "Baschiro",
                "be": "bielorusso",
                "be_BY": "Bielorusso (Bielorussia)",
                "bg": "bulgaro",
                "bg_BG": "Bulgaro (Bulgaria)",
                "bh": "Bihari (lingue)",
                "bi": "Bislama",
                "bm": "Bambara",
                "bm_Latn": "Bambara (latino)",
                "bm_Latn_ML": "Bambara (latino, Mali)",
                "bn": "bengalese",
                "bn_BD": "Bengalese (Bangladesh)",
                "bn_IN": "Bengalese (India)",
                "bo": "tibetano",
                "bo_CN": "Tibetano (Cina)",
                "bo_IN": "Tibetano (India)",
                "br": "bretone",
                "br_FR": "Bretone (Francia)",
                "bs": "Bosniaco",
                "bs_BA": "Bosniaco (Bosnia ed Erzegovina)",
                "bs_Cyrl": "Bosniaco (cirillico)",
                "bs_Cyrl_BA": "Bosniaco (cirillico, Bosnia ed Erzegovina)",
                "bs_Latn": "Bosniaco (latino)",
                "bs_Latn_BA": "Bosniaco (latino, Bosnia ed Erzegovina)",
                "ca": "catalano",
                "ca_AD": "Catalano (Andorra)",
                "ca_ES": "Catalano (Spagna)",
                "ca_FR": "Catalano (Francia)",
                "ca_IT": "Catalano (Italia)",
                "ce": "Ceceno",
                "ch": "Chamorro",
                "co": "Corso",
                "cr": "Cre",
                "cs": "ceco",
                "cs_CZ": "Ceco (Repubblica Ceca)",
                "cu": "Vecchio bulgaro",
                "cv": "Ciuvascio",
                "cy": "gallese",
                "cy_GB": "Gallese (Regno Unito)",
                "da": "danese",
                "da_DK": "Danese (Danimarca)",
                "da_GL": "Danese (Groenlandia)",
                "de": "Tedesco",
                "de_AT": "Tedesco (Austria)",
                "de_BE": "Tedesco (Belgio)",
                "de_CH": "Tedesco (Svizzera)",
                "de_DE": "Tedesco (Germania)",
                "de_LI": "Tedesco (Liechtenstein)",
                "de_LU": "Tedesco (Lussemburgo)",
                "dv": "Maldive",
                "dz": "Dzongkha",
                "dz_BT": "Dzongkha (Bhutan)",
                "ee": "Pecora",
                "ee_GH": "Pecora (Ghana)",
                "ee_TG": "Pecora (Togo)",
                "el": "greco",
                "el_CY": "Greco (Cipro)",
                "el_GR": "Greco (Grecia)",
                "en": "Inglese",
                "en_AG": "Inglese (Antigua e Barbuda)",
                "en_AI": "Inglese (Anguilla)",
                "en_AS": "Inglese (Samoa americane)",
                "en_AU": "Inglese (Australia)",
                "en_BB": "Inglese (Barbados)",
                "en_BE": "Inglese (Belgio)",
                "en_BM": "Inglese (Bermuda)",
                "en_BS": "Inglese (Bahamas)",
                "en_BW": "Inglese (Botswana)",
                "en_BZ": "Inglese (Belize)",
                "en_CA": "Inglese (Canada)",
                "en_CC": "Inglese (Isole Cocos (Keeling))",
                "en_CK": "Inglese (Isole Cook)",
                "en_CM": "Inglese (Camerun)",
                "en_CX": "Inglese (Isola di Natale)",
                "en_DG": "Inglese (Diego Garcia)",
                "en_DM": "Inglese (Domenica)",
                "en_ER": "Inglese (Eritrea)",
                "en_FJ": "Inglese (Figi)",
                "en_FK": "Inglese (Isole Falkland)",
                "en_FM": "Inglese (Micronesia)",
                "en_GB": "Inglese Regno Unito)",
                "en_GD": "Inglese (Grenada)",
                "en_GG": "Inglese (Guernsey)",
                "en_GH": "Inglese (Ghana)",
                "en_GI": "Inglese (Gibilterra)",
                "en_GM": "Inglese (Gambia)",
                "en_GU": "Inglese (Guam)",
                "en_GY": "Inglese (Guyana)",
                "en_HK": "Inglese (RAS di Hong Kong, Cina)",
                "en_IE": "Inglese (Irlanda)",
                "en_IM": "Inglese (Isola di Man)",
                "en_IN": "Inglese (India)",
                "en_IO": "Inglese (Territorio britannico dell'Oceano Indiano)",
                "en_JE": "Inglese (Jersey)",
                "en_JM": "Inglese (Giamaica)",
                "en_KE": "Inglese (Kenia)",
                "en_KI": "Inglese (Kiribati)",
                "en_KN": "Inglese (Saint Kitts e Nevis)",
                "en_KY": "Inglese (Isole Cayman)",
                "en_LC": "Inglese (Santa Lucia)",
                "en_LR": "Inglese (Liberia)",
                "en_LS": "Inglese (Lesoto)",
                "en_MG": "Inglese (Madagascar)",
                "en_MH": "Inglese (Isole Marshall)",
                "en_MO": "Inglese (RAS di Macao, Cina)",
                "en_MP": "Inglese (Isole Marianne Settentrionali)",
                "en_MS": "Inglese (Montserrat)",
                "en_MT": "Inglese (Malta)",
                "en_MU": "Inglese (Maurizio)",
                "en_MW": "Inglese (Malawi)",
                "en_MY": "Inglese (Malesia)",
                "en_NA": "Inglese (Namibia)",
                "en_NF": "Inglese (Isola Norfolk)",
                "en_NG": "Inglese (Nigeria)",
                "en_NR": "Inglese (Nauru)",
                "en_NU": "Inglese (Niue)",
                "en_NZ": "Inglese (Nuova Zelanda)",
                "en_PG": "Inglese (Papua Nuova Guinea)",
                "en_PH": "Inglese (Filippine)",
                "en_PK": "Inglese (Pakistan)",
                "en_PN": "Inglese (Isole Pitcairn)",
                "en_PR": "Inglese (Porto Rico)",
                "en_PW": "Inglese (Palau)",
                "en_RW": "Inglese (Ruanda)",
                "en_SB": "Inglese (Isole Salomone)",
                "en_SC": "Inglese (Seychelles)",
                "en_SD": "Inglese (Sudan)",
                "en_SG": "Inglese (Singapore)",
                "en_SH": "Inglese (Sant'Elena)",
                "en_SL": "Inglese (Sierra Leone)",
                "en_SS": "Inglese (Sud Sudan)",
                "en_SX": "Inglese (Sint Maarten)",
                "en_SZ": "Inglese (Swaziland)",
                "en_TC": "Inglese (Isole Turks e Caicos)",
                "en_TK": "Inglese (Tokelau)",
                "en_TO": "Inglese (Tonga)",
                "en_TT": "Inglese (Trinidad e Tobago)",
                "en_TV": "Inglese (Tuvalu)",
                "en_TZ": "Inglese (Tanzania)",
                "en_UG": "Inglese (Uganda)",
                "en_UM": "Inglese (isole esterne agli Stati Uniti)",
                "en_US": "Inglese (Stati Uniti)",
                "en_VC": "Inglese (St. Vincent e Grenadine)",
                "en_VG": "Inglese (Isole Vergini britanniche)",
                "en_VI": "Inglese (Isole Vergini americane)",
                "en_VU": "Inglese (Vanuatu)",
                "en_WS": "Inglese (Samoa)",
                "en_ZA": "Inglese (Sudafrica)",
                "en_ZM": "Inglese (Zambia)",
                "en_ZW": "Inglese (Zimbabwe)",
                "eo": "esperanto",
                "es": "spagnolo",
                "es_AR": "spagnolo (Argentina)",
                "es_BO": "Spagnolo (Bolivia)",
                "es_CL": "Spagnolo (Cile)",
                "es_CO": "Spagnolo (Colombia)",
                "es_CR": "Spagnolo (Costa Rica)",
                "es_CU": "Spagnolo (Cuba)",
                "es_DO": "Spagnolo (Repubblica Dominicana)",
                "es_EA": "Spagnola (Ceuta e Melilla)",
                "es_EC": "Spagnolo (Ecuador)",
                "es_ES": "Spagnolo (Spagna)",
                "es_GQ": "Spagnolo (Guinea Equatoriale)",
                "es_GT": "Spagnolo (Guatemala)",
                "es_HN": "Spagnolo (Honduras)",
                "es_IC": "Spagnolo (Isole Canarie)",
                "es_MX": "Spagnolo (Messico)",
                "es_NI": "Spagnolo (Nicaragua)",
                "es_PA": "Spagnolo (Panama)",
                "es_PE": "Spagnolo (Perù)",
                "es_PH": "Spagnolo (Filippine)",
                "es_PR": "Spagnolo (Porto Rico)",
                "es_PY": "Spagnolo (Paraguay)",
                "es_SV": "Spagnolo (El Salvador)",
                "es_US": "Spagnolo (Stati Uniti)",
                "es_UY": "spagnolo (Uruguay)",
                "es_VE": "Spagnolo (Venezuela)",
                "et": "Estone",
                "et_EE": "Estone (Estonia)",
                "eu": "Basco",
                "eu_ES": "Basco (Spagna)",
                "fa": "persiano",
                "fa_AF": "Persiano (Afghanistan)",
                "fa_IR": "Persiano (Iran)",
                "ff": "Fulah",
                "ff_CM": "Fulah (Camerun)",
                "ff_GN": "Fulah (Guinea)",
                "ff_MR": "Fulah (Mauritania)",
                "ff_SN": "Fulah (Senegal)",
                "fi": "finlandese",
                "fi_FI": "Finlandese (Finlandia)",
                "fj": "Figiano",
                "fo": "Faroese",
                "fo_FO": "Faroese (Isole Faroe)",
                "fr": "francese",
                "fr_BE": "Francese (Belgio)",
                "fr_BF": "Francese (Burkina Faso)",
                "fr_BI": "Francese (Burundi)",
                "fr_BJ": "Francese (Benin)",
                "fr_BL": "Francese (St. Barthélemy)",
                "fr_CA": "Francese (Canada)",
                "fr_CD": "Francese (Congo - Kinshasa)",
                "fr_CF": "Francese (Repubblica Centrafricana)",
                "fr_CG": "Francese (Congo - Brazzaville)",
                "fr_CH": "Francese (Svizzera)",
                "fr_CI": "Francese (Costa d'Avorio)",
                "fr_CM": "Francese (Camerun)",
                "fr_DJ": "Francese (Gibuti)",
                "fr_DZ": "Francese (Algeria)",
                "fr_FR": "Francese (Francia)",
                "fr_GA": "Francese (Gabon)",
                "fr_GF": "Francese (Guiana francese)",
                "fr_GN": "Francese (Guinea)",
                "fr_GP": "Francese (Guadalupa)",
                "fr_GQ": "Francese (Guinea Equatoriale)",
                "fr_HT": "Francese (Haiti)",
                "fr_KM": "Francese (Comore)",
                "fr_LU": "Francese (Lussemburgo)",
                "fr_MA": "Francese (Marocco)",
                "fr_MC": "Francese (Monaco)",
                "fr_MF": "Francese (San Martino)",
                "fr_MG": "Francese (Madagascar)",
                "fr_ML": "Francese (Mali)",
                "fr_MQ": "Francese (Martinica)",
                "fr_MR": "Francese (Mauritania)",
                "fr_MU": "Francese (Maurizio)",
                "fr_NC": "Francese (Nuova Caledonia)",
                "fr_NE": "Francese (Niger)",
                "fr_PF": "Francese (Polinesia francese)",
                "fr_PM": "Francese (St. Pierre e Miquelon)",
                "fr_RE": "Francese (Riunione)",
                "fr_RW": "Francese (Ruanda)",
                "fr_SC": "Francese (Seychelles)",
                "fr_SN": "Francese (Senegal)",
                "fr_SY": "Francese (Siria)",
                "fr_TD": "Francese (Ciad)",
                "fr_TG": "Francese (Togo)",
                "fr_TN": "Francese (Tunisia)",
                "fr_VU": "Francese (Vanuatu)",
                "fr_WF": "Francese (Wallis e Futuna)",
                "fr_YT": "Francese (Mayotte)",
                "fy": "Frisone occidentale",
                "fy_NL": "Frisone occidentali (Paesi Bassi)",
                "ga": "irlandesi",
                "ga_IE": "Irlandese (Irlanda)",
                "gd": "Gaelico Scozzese",
                "gd_GB": "Gaelico scozzese (Regno Unito)",
                "gl": "galiziano",
                "gl_ES": "Galiziano (Spagna)",
                "gn": "Guarani",
                "gu": "Gujarati",
                "gu_IN": "Gujarati (India)",
                "gv": "Manx",
                "gv_IM": "Manx (Isola di Man)",
                "ha": "Hausa",
                "ha_GH": "Hausa (Ghana)",
                "ha_Latn": "Hausa (latino)",
                "ha_Latn_GH": "Hausa (latino, Ghana)",
                "ha_Latn_NE": "Hausa (latino, Niger)",
                "ha_Latn_NG": "Hausa (latino, Nigeria)",
                "ha_NE": "Hausa (Niger)",
                "ha_NG": "Hausa (Nigeria)",
                "he": "ebraico",
                "he_IL": "Ebraico (Israele)",
                "hi": "hindi",
                "hi_IN": "Hindi (India)",
                "ho": "Hiri Motu",
                "hr": "croato",
                "hr_BA": "Croato (Bosnia ed Erzegovina)",
                "hr_HR": "Croato (Croazia)",
                "ht": "Haitiano",
                "hu": "ungherese",
                "hu_HU": "Ungherese (Ungheria)",
                "hy": "armeno",
                "hy_AM": "Armeno (Armenia)",
                "hz": "Herero",
                "ia": "Interlingua",
                "id": "indonesiano",
                "id_ID": "Indonesiano (Indonesia)",
                "ie": "Interlingua",
                "ig": "Igbo",
                "ig_NG": "Igbo (Nigeria)",
                "ii": "Sichuan Yi",
                "ii_CN": "Sichuan Yi (Cina)",
                "ik": "Inupiaq",
                "io": "Io faccio",
                "is": "islandese",
                "is_IS": "Islandese (Islanda)",
                "it": "Italiano",
                "it_CH": "Italiano (Svizzera)",
                "it_IT": "Italiano (Italia)",
                "it_SM": "Italiano (San Marino)",
                "iu": "Inuktitut",
                "ja": "giapponese",
                "ja_JP": "Giapponese (Giappone)",
                "jv": "giavanese",
                "ka": "georgiano",
                "ka_GE": "Georgiano (Georgia)",
                "kg": "Congo",
                "ki": "Kikuyu",
                "ki_KE": "Kikuyu (Kenia)",
                "kj": "Kwanyama",
                "kk": "Kazakistan",
                "kk_Cyrl": "Kazakistan (cirillico)",
                "kk_Cyrl_KZ": "Kazakistan (cirillico, Kazakistan)",
                "kk_KZ": "Kazakistan (Kazakistan)",
                "kl": "Kalaallisut",
                "kl_GL": "Kalaallisut (Groenlandia)",
                "km": "Khmer",
                "km_KH": "Khmer (Cambogia)",
                "kn": "Kannada",
                "kn_IN": "Kannada (India)",
                "ko": "coreano",
                "ko_KP": "Coreano (Corea del Nord)",
                "ko_KR": "Coreano (Corea del Sud)",
                "kr": "Kanuri",
                "ks": "Kashmir",
                "ks_Arab": "Kashmir (arabo)",
                "ks_Arab_IN": "Kashmir (arabo, India)",
                "ks_IN": "Kashmir (India)",
                "ku": "curdo",
                "kv": "Komi",
                "kw": "della Cornovaglia",
                "kw_GB": "Cornovaglia (Regno Unito)",
                "ky": "Kirghizistan",
                "ky_Cyrl": "Kirghiso (cirillico)",
                "ky_Cyrl_KG": "Kirghiso (cirillico, Kirghizistan)",
                "ky_KG": "Kirghizistan (Kirghizistan)",
                "la": "latino",
                "lb": "lussemburghese",
                "lb_LU": "Lussemburghese (Lussemburgo)",
                "lg": "Ganda",
                "lg_UG": "Ganda (Uganda)",
                "li": "Limburghese",
                "ln": "Lingala",
                "ln_AO": "Lingala (Angola)",
                "ln_CD": "Lingala (Congo - Kinshasa)",
                "ln_CF": "Lingala (Repubblica Centrafricana)",
                "ln_CG": "Lingala (Congo-Brazzaville)",
                "lo": "Laotiano",
                "lo_LA": "Laotiano (Laos)",
                "lt": "lituano",
                "lt_LT": "Lituano (Lituania)",
                "lu": "Luba-Katanga",
                "lu_CD": "Luba-Katanga (Congo - Kinshasa)",
                "lv": "lettone",
                "lv_LV": "Lettone (Lettonia)",
                "mg": "malgascio",
                "mg_MG": "Malgascio (Madagascar)",
                "mh": "Marshallese",
                "mi": "Maori",
                "mk": "macedone",
                "mk_MK": "Macedone (Macedonia)",
                "ml": "Malayalam",
                "ml_IN": "Malayalam (India)",
                "mn": "mongolo",
                "mn_Cyrl": "Mongolo (cirillico)",
                "mn_Cyrl_MN": "Mongolo (cirillico, Mongolia)",
                "mn_MN": "Mongolo (Mongolia)",
                "mo": "Macao",
                "mr": "Marathi",
                "mr_IN": "Marathi (India)",
                "ms": "malese",
                "ms_BN": "Malese (Brunei)",
                "ms_Latn": "Malese (latino)",
                "ms_Latn_BN": "Malese (latino, Brunei)",
                "ms_Latn_MY": "Malese (latino, Malesia)",
                "ms_Latn_SG": "Malese (latino, Singapore)",
                "ms_MY": "Malese (Malesia)",
                "ms_SG": "Malese (Singapore)",
                "mt": "maltese",
                "mt_MT": "Maltese (Malta)",
                "my": "birmano",
                "my_MM": "Birmano (Myanmar (Birmania))",
                "na": "Nauru",
                "nb": "Bokmål norvegese",
                "nb_NO": "Bokmål norvegese (Norvegia)",
                "nb_SJ": "Bokmål norvegese (Svalbard e Jan Mayen)",
                "nd": "Ndebele settentrionale",
                "nd_ZW": "Ndebele settentrionale (Zimbabwe)",
                "ne": "nepalese",
                "ne_IN": "Nepalese (India)",
                "ne_NP": "Nepalese (Nepal)",
                "ng": "Ndonga",
                "nl": "Olandese",
                "nl_AW": "Olandese (Aruba)",
                "nl_BE": "Olandese (Belgio)",
                "nl_BQ": "Olandese (Paesi Bassi dei Caraibi)",
                "nl_CW": "Olandese (Curaçao)",
                "nl_NL": "Olandese (Paesi Bassi)",
                "nl_SR": "Olandese (Suriname)",
                "nl_SX": "Olandese (Sint Maarten)",
                "nn": "Nynorsk norvegese",
                "nn_NO": "Nynorsk norvegese (Norvegia)",
                "no": "norvegese",
                "no_NO": "Norvegese (Norvegia)",
                "nr": "Ndebele meridionale",
                "nv": "Navaho",
                "ny": "Chichewa",
                "oc": "occitano",
                "oj": "Ojibwa",
                "om": "Oromo",
                "om_ET": "Oromo (Etiopia)",
                "om_KE": "Oromo (Kenia)",
                "or": "Oriya",
                "or_IN": "Oriya (India)",
                "os": "Ossetico",
                "os_GE": "Osseto (Georgia)",
                "os_RU": "Ossetico (Russia)",
                "pa": "Punjabi",
                "pa_Arab": "Punjabi (arabo)",
                "pa_Arab_PK": "Punjabi (arabo, Pakistan)",
                "pa_Guru": "Punjabi (Gurmukhi)",
                "pa_Guru_IN": "Punjabi (Gurmukhi, India)",
                "pa_IN": "Punjabi (India)",
                "pa_PK": "Punjabi (Pakistan)",
                "pi": "Pali",
                "pl": "Polacco",
                "pl_PL": "Polacco (Polonia)",
                "ps": "Pashtu",
                "ps_AF": "Pashtu (Afghanistan)",
                "pt": "portoghese",
                "pt_AO": "Portoghese (Angola)",
                "pt_BR": "Portoghese (Brasile)",
                "pt_CV": "Portoghese (Capo Verde)",
                "pt_GW": "Portoghese (Guinea-Bissau)",
                "pt_MO": "Portoghese (RAS di Macao, Cina)",
                "pt_MZ": "Portoghese (Mozambico)",
                "pt_PT": "Portoghese (Portogallo)",
                "pt_ST": "Portoghese (São Tomé e Principe)",
                "pt_TL": "Portoghese (Timor Est)",
                "qu": "Quechua",
                "qu_BO": "Quechua (Bolivia)",
                "qu_EC": "Quechua (Ecuador)",
                "qu_PE": "Quechua (Perù)",
                "rm": "Romancio",
                "rm_CH": "Romancio (Svizzera)",
                "rn": "Rundi",
                "rn_BI": "Rundi (Burundi)",
                "ro": "rumeno",
                "ro_MD": "Rumeno (Moldavia)",
                "ro_RO": "Rumeno (Romania)",
                "ru": "russo",
                "ru_BY": "Russo (Bielorussia)",
                "ru_KG": "Russo (Kirghizistan)",
                "ru_KZ": "Russo (Kazakistan)",
                "ru_MD": "Russo (Moldavia)",
                "ru_RU": "Russo (Russia)",
                "ru_UA": "Russo (Ucraina)",
                "rw": "Kinyarwanda",
                "rw_RW": "Kinyarwanda (Ruanda)",
                "sa": "sanscrito",
                "sc": "sardo",
                "sd": "Sindhi",
                "se": "Sami settentrionale",
                "se_FI": "Sami settentrionale (Finlandia)",
                "se_NO": "Sami del Nord (Norvegia)",
                "se_SE": "Sami del Nord (Svezia)",
                "sg": "Sangò",
                "sg_CF": "Sango (Repubblica Centrafricana)",
                "sh": "serbo-croato",
                "sh_BA": "Serbo-croato (Bosnia ed Erzegovina)",
                "si": "Singalese",
                "si_LK": "Singalese (Sri Lanka)",
                "sk": "slovacco",
                "sk_SK": "Slovacco (Slovacchia)",
                "sl": "sloveno",
                "sl_SI": "Sloveno (Slovenia)",
                "sm": "Samoano",
                "sn": "Shona",
                "sn_ZW": "Shona (Zimbabwe)",
                "so": "Somalo",
                "so_DJ": "Somalo (Gibuti)",
                "so_ET": "Somalo (Etiopia)",
                "so_KE": "Somalo (Kenya)",
                "so_SO": "Somalo (Somalia)",
                "sq": "albanese",
                "sq_AL": "Albanese (Albania)",
                "sq_MK": "Albanese (Macedonia)",
                "sq_XK": "Albanese (Kosovo)",
                "sr": "serbo",
                "sr_BA": "Serbo (Bosnia ed Erzegovina)",
                "sr_Cyrl": "Serbo (cirillico)",
                "sr_Cyrl_BA": "Serbo (cirillico, Bosnia ed Erzegovina)",
                "sr_Cyrl_ME": "Serbo (cirillico, Montenegro)",
                "sr_Cyrl_RS": "Serbo (cirillico, Serbia)",
                "sr_Cyrl_XK": "Serbo (cirillico, Kosovo)",
                "sr_Latn": "Serbo (latino)",
                "sr_Latn_BA": "Serbo (latino, Bosnia ed Erzegovina)",
                "sr_Latn_ME": "Serbo (latino, Montenegro)",
                "sr_Latn_RS": "Serbo (latino, Serbia)",
                "sr_Latn_XK": "Serbo (latino, Kosovo)",
                "sr_ME": "Serbo (Montenegro)",
                "sr_RS": "Serbo (Serbia)",
                "sr_XK": "Serbo (Kosovo)",
                "ss": "Swati",
                "st": "Sotho, Sud",
                "su": "Sundanese",
                "sv": "svedese",
                "sv_AX": "Svedese (Isole Åland)",
                "sv_FI": "Svedese (Finlandia)",
                "sv_SE": "Svedese (Svezia)",
                "sw": "Swahili",
                "sw_KE": "Swahili (Kenia)",
                "sw_TZ": "Swahili (Tanzania)",
                "sw_UG": "Swahili (Uganda)",
                "ta": "Tamil",
                "ta_IN": "Tamil (India)",
                "ta_LK": "Tamil (Sri Lanka)",
                "ta_MY": "Tamil (Malesia)",
                "ta_SG": "Tamil (Singapore)",
                "te": "Telugu",
                "te_IN": "Telugu (India)",
                "tg": "Tagico",
                "th": "tailandese",
                "th_TH": "Tailandese (Thailandia)",
                "ti": "Tigrino",
                "ti_ER": "Tigrino (Eritrea)",
                "ti_ET": "Tigrino (Etiopia)",
                "tk": "turkmeno",
                "tl": "Tagalog",
                "tl_PH": "Tagalog (Filippine)",
                "tn": "Tswana",
                "to": "Tongano",
                "to_TO": "Tongano (Tonga)",
                "tr": "Turco",
                "tr_CY": "Turco (Cipro)",
                "tr_TR": "Turco (Turchia)",
                "ts": "Tsonga",
                "tt": "Tartaro",
                "tw": "Due",
                "ty": "Tahitiano",
                "ug": "Uiguro",
                "ug_Arab": "Uiguro (arabo)",
                "ug_Arab_CN": "Uiguro (arabo, Cina)",
                "ug_CN": "Uiguro (Cina)",
                "uk": "ucraino",
                "uk_UA": "Ucraino (Ucraina)",
                "ur": "Urdu",
                "ur_IN": "Urdu (India)",
                "ur_PK": "Urdu (Pakistan)",
                "uz": "Uzbeco",
                "uz_AF": "Uzbeko (Afghanistan)",
                "uz_Arab": "Uzbeco (arabo)",
                "uz_Arab_AF": "Uzbeco (arabo, Afghanistan)",
                "uz_Cyrl": "Uzbeco (cirillico)",
                "uz_Cyrl_UZ": "Uzbeco (cirillico, Uzbekistan)",
                "uz_Latn": "Uzbeco (latino)",
                "uz_Latn_UZ": "Uzbeco (latino, Uzbekistan)",
                "uz_UZ": "Uzbeco (Uzbekistan)",
                "ve": "Venda",
                "vi": "vietnamita",
                "vi_VN": "Vietnamita (Vietnam)",
                "vo": "Volapük",
                "wa": "Vallone",
                "wo": "Wolof",
                "xh": "Xhosa",
                "yi": "yiddish",
                "yo": "Yoruba",
                "yo_BJ": "Yoruba (Benin)",
                "yo_NG": "Yoruba (Nigeria)",
                "za": "Zhuang",
                "zh": "Cinese",
                "zh_CN": "Cinese (Cina)",
                "zh_HK": "Cinese (RAS di Hong Kong, Cina)",
                "zh_Hans": "Cinese semplificato)",
                "zh_Hans_CN": "Cinese (semplificato, Cina)",
                "zh_Hans_HK": "Cinese (semplificato, Hong Kong SAR Cina)",
                "zh_Hans_MO": "Cinese (semplificato, RAS di Macao, Cina)",
                "zh_Hans_SG": "Cinese (semplificato, Singapore)",
                "zh_Hant": "Cinese tradizionale)",
                "zh_Hant_HK": "Cinese (tradizionale, Hong Kong SAR Cina)",
                "zh_Hant_MO": "Cinese (tradizionale, RAS di Macao, Cina)",
                "zh_Hant_TW": "Cinese (tradizionale, Taiwan)",
                "zh_MO": "Cinese (RAS di Macao, Cina)",
                "zh_SG": "Cinese (Singapore)",
                "zh_TW": "Cinese (Taiwan)",
                "zu": "Zulù",
                "zu_ZA": "Zulu (Sudafrica)"
            },
            "mail": {
                "action": "Se hai problemi a fare clic sul pulsante \"{actionText}\", copia e incolla l'URL seguente nel tuo browser web:",
                "auth": {
                    "deleted": {
                        "line1": "Ti informiamo che il tuo account UgoZER è stato cancellato.",
                        "reason": {
                            "intro": "Questo è stato cancellato per il seguente motivo:",
                            "never_authenticated_for_too_long": "Il tuo account è stato creato più di una settimana fa\n                    e non hai mai effettuato l'accesso:\n                    quindi questo account è considerato inattivo.",
                            "not_logged_for_too_long": "Non sei loggato da più di 2 anni:\n                    il tuo account è considerato inattivo."
                        },
                        "subject": "Cancellazione del tuo account UgoZER"
                    },
                    "login_attempt_unverified_credential": {
                        "action": "verifica indirizzo e-mail",
                        "line1": "C'è stato un tentativo di accedere al tuo account UgoZER con l'indirizzo email {credential}.",
                        "line2": "Se hai avviato tu questo tentativo di accesso,\n                clicca sul pulsante qui sotto per verificare il tuo indirizzo email\n                ed essere in grado di connettersi.",
                        "line3": "Questo collegamento scadrà tra 1 settimana.",
                        "line4": "Se non hai avviato tu questo tentativo di accesso, non sono necessarie ulteriori azioni.",
                        "subject": "Prova ad accedere al tuo account UgoZER"
                    },
                    "login_magic_link": {
                        "action": "Login",
                        "line1": "Fare clic su questo collegamento per accedere",
                        "line2": "Grazie per aver utilizzato la nostra applicazione!",
                        "subject": "Collegamento di accesso senza password"
                    },
                    "not_logged": {
                        "intro": "Sono passati quasi 2 anni dall'ultima volta che hai effettuato l'accesso al tuo account UgoZER.",
                        "outro": "Accedi semplicemente al tuo account in modo che non venga eliminato.",
                        "remaining": {
                            "two_days": "Ti restano 2 giorni prima che il tuo account venga eliminato.",
                            "two_months": "Ti restano 2 mesi prima che il tuo account venga eliminato.",
                            "two_weeks": "Ti restano 2 settimane prima che il tuo account venga eliminato."
                        },
                        "subject": "Il tuo account UgoZER verrà eliminato a breve"
                    },
                    "registration": {
                        "action": "verifica indirizzo e-mail",
                        "line1": "Fai clic sul pulsante qui sotto per verificare il tuo indirizzo email ed essere in grado di accedere.",
                        "line2": "Questo collegamento scadrà tra 1 settimana.",
                        "line3": "Se non hai creato un account, non sono necessarie ulteriori azioni.",
                        "subject": "Verifica il tuo indirizzo email"
                    },
                    "registration_attempt": {
                        "line1": "L'e-mail <strong>{credential}</strong> è già utilizzata per un account <strong>{role_type}</strong> nella nostra applicazione.",
                        "line2": "Per creare un account, avvia nuovamente la registrazione con un'altra email.",
                        "subject": "Prova a registrarti con le tue credenziali UgoZER"
                    },
                    "registration_attempt_unverified_credential": {
                        "line1": "L'e-mail <strong>{credential}</strong> è già utilizzata per un account <strong>{role_type}</strong> nella nostra applicazione.",
                        "line2": "Per creare un account, avvia nuovamente la registrazione con un'altra email.",
                        "subject": "Prova a registrarti con le tue credenziali UgoZER"
                    },
                    "reset_password": {
                        "action": "Resetta la password",
                        "line1": "Hai ricevuto questa email perché abbiamo ricevuto una richiesta di reimpostazione della password per il tuo account.",
                        "line2": "Questo collegamento per la reimpostazione della password scadrà tra {count} minuti.",
                        "line3": "Se non hai richiesto la reimpostazione della password, non sono necessarie ulteriori azioni.",
                        "subject": "Reimposta notifica password"
                    }
                },
                "copyright": "© {year} UgoZER - Tutti i diritti riservati.",
                "credential_verification": {
                    "verify": {
                        "action": "verifica indirizzo e-mail",
                        "line1": "Fai clic sul pulsante in basso per verificare il tuo indirizzo email.",
                        "line2": "Questo collegamento scadrà tra 1 settimana.",
                        "line3": "Se non hai richiesto la verifica, non sono necessarie ulteriori azioni.",
                        "subject": "Conferma della tua email"
                    }
                },
                "hello": "Ciao!",
                "invitation": {
                    "attempt": {
                        "action": "Accesso all'applicazione",
                        "line1": "Sei stato invitato a partecipare alla nostra applicazione",
                        "line1_by": "Sei stato invitato da {name} a partecipare alla nostra applicazione",
                        "line1_by_from": "Sei stato invitato da {name} dell'istituzione {poi_title} a partecipare alla nostra applicazione",
                        "line2": "Hai già un account esistente nella nostra applicazione",
                        "line3": "Fare clic sul pulsante in basso per accedere all'applicazione.",
                        "subject": "Invito ad aderire a UgoZER"
                    },
                    "verification": {
                        "action": "verifica indirizzo e-mail",
                        "excursion_shared": "Un itinerario è stato condiviso con te. Lo trovate allegato in formato PDF,\n                    così come nell'applicazione delle tue azioni.",
                        "line1": "Sei stato invitato a partecipare alla nostra applicazione",
                        "line1_by": "Sei stato invitato da {name} a partecipare alla nostra applicazione",
                        "line1_by_from": "Sei stato invitato da {name} dell'istituzione {poi_title} a partecipare alla nostra applicazione",
                        "line2": "Fai clic sul pulsante in basso per verificare il tuo indirizzo email.",
                        "line3": "Questo collegamento scadrà tra 1 settimana.",
                        "line4": "Grazie per aver utilizzato la nostra applicazione!",
                        "point_of_interest_shared": "Un punto di interesse è stato condiviso con te. Puoi trovarlo\n                nell'applicazione nelle tue azioni.",
                        "subject": "Invito ad aderire a UgoZER"
                    }
                },
                "invitation_professional": {
                    "content": "Sei stato invitato a iscriverti alla sezione professionale della nostra applicazione UgoZER",
                    "cta": "Registrati alla sezione professionale",
                    "subject": "UgoZER - Invito accesso professionale",
                    "thanks": "Grazie,",
                    "title": "Invito alla sezione professionale dell'applicazione UgoZER"
                },
                "preview": {
                    "default": {
                        "subject": "Oggetto dell'email",
                        "user": {
                            "name": "UgoZER"
                        }
                    },
                    "from": "Da:",
                    "mails": {
                        "auth/account-deleted-notification": "Cancellazione dell'account",
                        "auth/not-logged-notification": "Elimina se non connesso",
                        "auth/registration-attempt-notification": "Tentativa di registrazione",
                        "auth/registration-notification": "Registrazione",
                        "auth/reset-password-notification": "Cambia la tua password",
                        "invitation/invited-notification": "Invito",
                        "share/created-notification": "Nuovo",
                        "support-message/message-notification": "Nuova risposta",
                        "support-request/archived-notification": "Richiesta archiviata",
                        "support-request/created-notification": "Richiesta creata",
                        "user-credential-verification/login-attempt-notification": "Tentativo di connessione",
                        "user-credential-verification/registration-attempt-notification": "Tentativa di registrazione",
                        "user-credential-verification/verify-notification": "Conferma dell'account",
                        "user/welcome-notification": "Benvenuto"
                    },
                    "main_title": "Anteprima e-mail",
                    "sections": {
                        "auth": "Autenticazione",
                        "invitation": "Inviti",
                        "share": "Azioni",
                        "support-message": "Messaggi di supporto",
                        "support-request": "Richieste di supporto",
                        "user": "Utente",
                        "user-credential-verification": "Credenziali dell'utente"
                    }
                },
                "salutations": "Saluti",
                "share": {
                    "created": {
                        "action": "Spettacolo",
                        "excursion": {
                            "gotoapp": "Vai all'app per visualizzarlo.",
                            "line": "{username} dell'istituzione {poi_title} ha condiviso un itinerario con te.",
                            "line_alt": "{username} ha condiviso un itinerario con te.",
                            "line_alt_2": "Un itinerario è stato condiviso con te.",
                            "subject": "Un itinerario è stato condiviso con te."
                        },
                        "line": "{username} dell'istituzione {poi_title} ha condiviso un elemento con te.",
                        "line_alt": "{username} ha condiviso un elemento con te.",
                        "line_alt_2": "Un elemento è stato condiviso con te.",
                        "point_of_interest": {
                            "line": "{username} dell'istituzione {poi_title} ha condiviso un punto di interesse con te.",
                            "line_alt": "{username} ha condiviso un punto di interesse con te.",
                            "line_alt_2": "Un punto di interesse è stato condiviso con te.",
                            "subject": "Un punto di interesse è stato condiviso con te."
                        },
                        "subject": "Un elemento è stato condiviso con te."
                    }
                },
                "subscription": {
                    "not_continue_after_free_period": {
                        "cta": "Vedi dettaglio",
                        "line1": "{name} non è continuato al termine del periodo gratuito",
                        "line2": "Fare clic sul pulsante in basso per accedere ai dettagli",
                        "subject": "L'abbonato non ha continuato al termine del periodo gratuito"
                    },
                    "suspended": {
                        "line": "Il tuo abbonamento è stato sospeso per il seguente motivo: {suspension_reason}.",
                        "line_alt": "Il tuo abbonamento è stato sospeso.",
                        "subject": "Abbonamento sospeso"
                    },
                    "switched_from_paid_to_free": {
                        "cta": "Vedi dettaglio",
                        "line1": "{name} è passato al piano gratuito",
                        "line2": "Fare clic sul pulsante in basso per accedere ai dettagli",
                        "subject": "L'abbonato è passato al piano gratuito"
                    }
                },
                "support_request": {
                    "archived": {
                        "subject": "UgoZER - Richiesta di supporto archiviata",
                        "title": "La tua richiesta di supporto è stata archiviata"
                    },
                    "message": {
                        "action": "Accesso alla richiesta di supporto",
                        "line1": "Clicca sul pulsante qui sotto per accedere alla tua richiesta.",
                        "subject": "UgoZER – Hai una risposta",
                        "title": "È stata pubblicata una risposta alla tua richiesta di supporto"
                    },
                    "request": {
                        "action": "Accesso alla richiesta di supporto",
                        "line1": "L'assistenza contattata risponderà entro 48 ore.",
                        "line2": "Clicca sul pulsante qui sotto per accedere alla tua richiesta.",
                        "subject": "UgoZER - La tua richiesta di supporto è stata creata"
                    }
                },
                "user_send_link": {
                    "content": "Riconnettiti alla nostra applicazione UgoZER! Se non ricordi la password, puoi reimpostarla.",
                    "cta": "Accedi all'applicazione",
                    "subject": "UgoZER - Applicazione",
                    "thanks": "Grazie,",
                    "title": "Hai un account sulla nostra applicazione, accedi!"
                },
                "welcome": {
                    "action": "Accesso all'applicazione",
                    "line1": "Siamo felici di annoverarti tra i nostri utenti.",
                    "line2": "Clicca sul pulsante qui sotto per accedere all'applicazione.",
                    "subject": "Benvenuti in UgoZER",
                    "title": "Benvenuto in UgoZER, la tua app gratuita di assistenza di viaggio multilingue."
                }
            },
            "media": {
                "audio": {
                    "generated_audio": "Audio generato",
                    "recording": {
                        "errors": {
                            "default": "La registrazione non è riuscita",
                            "not_allowed": "La registrazione non è riuscita. Consenti al tuo browser e a UgoZER di utilizzare il microfono e riprova."
                        }
                    }
                },
                "default": {
                    "alt": "Mappa statica di Google Maps",
                    "caption": "Clicca sulla mappa per mostrarla su Google Maps"
                },
                "image": {
                    "credits": "Crediti: {credits}"
                }
            },
            "models": {
                "activity_log": "Registro attività|Registri attività",
                "address": "Indirizzo|Indirizzi",
                "bank_transfer": "Bonifico bancario|Bonifici bancari",
                "bank_transfer_institution": "Istituti di bonifico|Istituti di bonifico",
                "category": "Categoria|Categorie",
                "changelog": "Registro delle modifiche|Registro delle modifiche",
                "claim": "Reclamo|Reclami",
                "claim_dispute": "Controversia sui reclami|Controversie sui reclami",
                "currency": "Valuta|Valute",
                "data_tourisme_category": "Categoria DataTourisme|Categorie DataTourisme",
                "data_tourisme_tag": "Tag DataTourisme|Tag DataTourisme",
                "excursion": "Itinerario|Itinerari",
                "institution_employee": "Dipendente|Dipendenti",
                "interaction": "Interazione|Interazioni",
                "locale": "Lingua|Lingue",
                "medium": "Medio|Media",
                "notification": "Notifica|Notifiche",
                "partner": "Socio|Partner",
                "payment": "Pagamento|Pagamenti",
                "permission": "Autorizzazione|Autorizzazioni",
                "plan": "Piano di abbonamento|Piani di abbonamento",
                "plan_price": "Prezzo del piano|Prezzi del piano",
                "point_of_interest": "Punto di interesse|Punti di interesse",
                "point_of_interest_category": "Categoria|Categorie",
                "post": "Posta|Post",
                "post_category": "Categoria dei post|Categorie dei post",
                "privacy_policy": "Informativa sulla privacy|Informativa sulla privacy",
                "report": "Rapporto|Rapporti",
                "reservation": "Prenotazione|Prenotazioni",
                "role": "Ruolo|Ruoli",
                "share": "Condividi|Azioni",
                "subscription": "Abbonamento|Abbonamenti",
                "support_category": "Categoria di supporto|Categorie di supporto",
                "support_message": "Messaggio|Messaggi",
                "support_request": "Richiesta|Richieste",
                "tag": "Etichetta|Etichette",
                "terms_condition": "Termini e condizioni|Termini e condizioni",
                "traveller_email": "E-mail dei viaggiatori|E-mail dei viaggiatori",
                "traveller_folder": "Cartella da viaggio|Cartella da viaggio",
                "user": "Utente|Utenti",
                "user_credential_verification": "Verifica delle credenziali|Verifica delle credenziali",
                "user_geoloc_setting": "Impostazione Geolocalizzazione|Impostazioni Geolocalizzazione",
                "user_notification_setting": "Impostazione notifiche|Impostazioni notifiche",
                "user_password_reset": "Reimpostazione password utente|Reimpostazione password utente",
                "user_privacy_setting": "Impostazioni privacy|Impostazioni privacy",
                "users_relationship": "Contatto|Contatti",
                "vat_rate": "Aliquota IVA|Aliquote IVA"
            },
            "navigations": {
                "items": {
                    "changelogs": "Log delle modifiche",
                    "contact_us": "Contattaci",
                    "contacts": "Contatti",
                    "cookies": "Gestione dei cookie",
                    "credits": "Crediti",
                    "customers": "Si fidano di noi",
                    "faqs": "FAQ",
                    "folders": "Cartelle dei viaggiatori",
                    "invite_professional": "Invita un professionista",
                    "invite_traveller": "Invita un viaggiatore",
                    "partners": "Partner",
                    "payments": "Pagamenti",
                    "privacy_policy": "Politica sulla riservatezza",
                    "settings": "Parametri",
                    "stays": "Soggiorni",
                    "terms_conditions": "Termini e Condizioni",
                    "users": "Utenti"
                }
            },
            "notifications": {
                "changelog": {
                    "created": {
                        "text": "La versione {version} è stata distribuita. Puoi controllare le novità cliccando sulla notifica.",
                        "title": "Nuovo registro delle modifiche"
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "text": "La controversia relativa al punto di interesse {poi} è in attesa di verifica da parte del nostro team.",
                        "title": "Reclama la controversia in attesa di verifica"
                    },
                    "rejected": {
                        "no_reason": "Nessun motivo fornito",
                        "text": "La contestazione del reclamo al punto di interesse {poi} è stata respinta per il seguente motivo: {reason}.",
                        "title": "Contestazione del reclamo respinta"
                    },
                    "validated": {
                        "text": "La controversia relativa al punto di interesse {poi} è stata accettata!",
                        "title": "Contestazione del reclamo convalidata!"
                    }
                },
                "index": {
                    "no_results": "Nessuna notifica!"
                },
                "institution_employee": {
                    "approved": {
                        "text": "La tua richiesta di adesione a {poi} è stata approvata",
                        "title": "Richiesta del dipendente approvata"
                    },
                    "declined": {
                        "text": "La tua richiesta di adesione a {poi} è stata rifiutata",
                        "title": "La richiesta del dipendente è stata rifiutata"
                    },
                    "pending": {
                        "text": "{user} richiede di essere registrato come dipendente della tua istituzione {poi}",
                        "title": "Richiesta del dipendente ricevuta"
                    },
                    "removed": {
                        "text": "Il tuo stato di dipendente da {poi} è stato rimosso",
                        "title": "Stato di dipendente rimosso"
                    }
                },
                "invitation": {
                    "accepted": {
                        "email": {
                            "text": "Il tuo invito a partecipare alla domanda inviata a {credential} è stato accettato"
                        },
                        "phone": {
                            "text": "Il tuo invito a partecipare alla domanda inviata a {credential} è stato accettato"
                        },
                        "title": "Invito accettato"
                    },
                    "pro": {
                        "text": "Il tuo invito professionale è stato inviato all'e-mail {email}",
                        "title": "Invito professionale inviato"
                    }
                },
                "office": {
                    "claim": {
                        "content": {
                            "line_1": "È stato appena rivendicato un punto di interesse.",
                            "line_2": "Fare clic sul pulsante in basso per visualizzare i dettagli nell'interfaccia di amministrazione."
                        },
                        "cta": "Vedi reclamo",
                        "subject": "Nuovo reclamo!"
                    },
                    "claim_dispute": {
                        "content": {
                            "line_1": "È stato appena contestato un motivo di interesse.",
                            "line_2": "Fare clic sul pulsante in basso per visualizzare i dettagli nell'interfaccia di amministrazione."
                        },
                        "cta": "Vedi controversia",
                        "subject": "Nuova disputa!"
                    },
                    "report": {
                        "content": {
                            "line_1": "{type} è appena stato segnalato."
                        },
                        "cta": "Vedi rapporto",
                        "subject": "Nuovo rapporto!",
                        "type": {
                            "App\\Models\\PointOfInterest": "Un punto di interesse",
                            "App\\Models\\User": "un utente"
                        }
                    },
                    "support_request": {
                        "content": {
                            "line_1": "È stata appena aggiunta una nuova richiesta di supporto."
                        },
                        "cta": "Vedi richiesta di supporto",
                        "subject": "Nuova richiesta di supporto!"
                    }
                },
                "payment": {
                    "proposal": {
                        "text": "Ringrazia {username} dall'istituzione {poi_title} lasciando un suggerimento",
                        "text_alt": "Ringrazia il concierge dell'istituzione {poi_title} lasciando una mancia",
                        "text_alt_2": "Ringrazia {username} lasciando un suggerimento",
                        "text_alt_3": "Ringrazia il concierge lasciando una mancia",
                        "title": "Lasciare una mancia"
                    },
                    "received": {
                        "text": "Hai ricevuto un suggerimento {amount} da {payer} da un'istituzione {poi_title}",
                        "text_alt": "Hai ricevuto un suggerimento {amount} da {payer}",
                        "text_alt_2": "Hai ricevuto un suggerimento {amount}",
                        "title": "Suggerimento ricevuto"
                    },
                    "thanks": {
                        "text": "{payee} dall'istituzione {poi_title} grazie per il suggerimento!",
                        "text_alt": "{payee} grazie per il suggerimento!",
                        "text_alt_2": "Sei stato ringraziato per il suggerimento",
                        "title": "Grazie per il consiglio!"
                    }
                },
                "point_of_interest": {
                    "event": {
                        "expired": {
                            "text": "L'evento {title}, incluso nei tuoi itinerari, è scaduto",
                            "title": "Evento scaduto"
                        }
                    },
                    "moderation": {
                        "denied": {
                            "text": "Il tuo punto di interesse {title} è stato negato in seguito alla sua moderazione",
                            "title": "Punto di interesse negato"
                        },
                        "published": {
                            "text": "Il tuo punto di interesse {title} è stato pubblicato dopo la sua moderazione",
                            "title": "Punto di interesse pubblicato"
                        }
                    }
                },
                "reservation": {
                    "created": {
                        "text": "{butler_name} dell'istituzione {butler_poi_title} ha effettuato una prenotazione\n                nel tuo istituto {poi_title}",
                        "text_alt": "Un concierge dell'istituzione {butler_poi_title} ha effettuato una prenotazione\n                nel tuo istituto {poi_title}",
                        "text_alt_2": "{butler_name} ha effettuato una prenotazione\n                nel tuo istituto {poi_title}",
                        "text_alt_3": "È stata effettuata una prenotazione\n                nel tuo istituto {poi_title}",
                        "title": "Prenotazione nel tuo istituto"
                    }
                },
                "share": {
                    "created": {
                        "excursion": {
                            "text": "{username} dell'istituzione {poi_title} ha condiviso un itinerario con te",
                            "text_alt": "{username} ha condiviso un itinerario con te",
                            "text_alt_2": "Un itinerario è stato condiviso con te",
                            "title": "Condividi un itinerario"
                        },
                        "point_of_interest": {
                            "text": "{username} dell'istituzione {poi_title} ha condiviso un punto di interesse con te",
                            "text_alt": "{username} ha condiviso un punto di interesse con te",
                            "text_alt_2": "Un punto di interesse è stato condiviso con te",
                            "title": "Condividi un punto di interesse"
                        },
                        "text": "{username} dell'istituzione {poi_title} ha condiviso un elemento con te",
                        "text_alt": "{username} ha condiviso un elemento con te",
                        "text_alt_2": "Un elemento è stato condiviso con te"
                    }
                },
                "stripe": {
                    "account_requirements": {
                        "text": "A seguito della verifica del tuo account Stripe, vengono richieste nuove informazioni",
                        "title": "Requisiti dell'account Stripe"
                    },
                    "payments_enabled": {
                        "text": "Ora puoi ricevere pagamenti tramite Stripe",
                        "title": "Pagamenti con stripe abilitati"
                    }
                },
                "support_request": {
                    "archived": {
                        "text": "La tua richiesta di supporto è stata archiviata",
                        "title": "Richiesta di supporto archiviata"
                    },
                    "message": {
                        "text": "È stata pubblicata una risposta alla tua richiesta di supporto",
                        "title": "Messaggio ricevuto"
                    }
                },
                "traveller_folder": {
                    "approved": {
                        "text": "{username} ha approvato la sua cartella del viaggiatore",
                        "text_alt": "Un viaggiatore ha approvato la sua cartella",
                        "title": "Cartella del viaggiatore approvata"
                    },
                    "archived": {
                        "text": "{username} ha archiviato la sua cartella del viaggiatore",
                        "text_alt": "Un viaggiatore ha archiviato la sua cartella",
                        "title": "Cartella del viaggiatore archiviata"
                    },
                    "created": {
                        "text": "È stato registrato un soggiorno per il tuo account presso l'hotel {poi_title}.",
                        "title": "Rimani creato"
                    },
                    "pending": {
                        "text": "{username} ha registrato per il tuo account un soggiorno presso l'hotel {poi_title}.\n                Conferma per favore.",
                        "text_alt": "È stato registrato un soggiorno per il tuo account presso l'hotel {poi_title}.\n                Conferma per favore.",
                        "title": "Rimani in attesa di conferma"
                    },
                    "thanks": {
                        "text": "{username} dall'istituzione {poi_title} grazie per il tuo soggiorno",
                        "text_alt": "Un concierge dell'istituzione {poi_title} grazie per il tuo soggiorno",
                        "title": "Grazie per il tuo soggiorno"
                    }
                }
            },
            "nova": {
                "actions": {
                    "bank_transfer": {
                        "transfer": "Effettua bonifico bancario"
                    },
                    "changelog": {
                        "archive": "Archivio",
                        "draft": "Bozza",
                        "publish": "Pubblicare"
                    },
                    "claim": {
                        "missing_info": "Informazioni mancanti",
                        "reject": "Rifiutare",
                        "revoke": "Revocare",
                        "validate": "Convalidare"
                    },
                    "claim_dispute": {
                        "reject": "Rifiutare",
                        "validate": "Convalidare"
                    },
                    "payment": {
                        "download_invoice": "Scarica fattura"
                    },
                    "plan": {
                        "archive": "Archivio",
                        "draft": "Bozza",
                        "publish": "Pubblicare"
                    },
                    "point_of_interest": {
                        "deny": "Negare",
                        "draft": "Bozza",
                        "publish": "Convalidare",
                        "submit": "Chiedi moderazione"
                    },
                    "post": {
                        "archive": "Archivio",
                        "draft": "Bozza",
                        "publish": "Pubblicare",
                        "set_coming_soon": "Impostato su \"prossimamente\""
                    },
                    "privacy_policy": {
                        "archive": "Archivio",
                        "confirmation": {
                            "confirm_button_text": "Pubblicare",
                            "confirm_text": "Sei sicuro di voler pubblicare questa informativa sulla privacy? A TUTTI gli utenti verrà chiesto di convalidarli nuovamente!"
                        },
                        "errors": {
                            "resources_count": "Non puoi pubblicare più di UNA polizza privata!"
                        },
                        "publish": "Pubblicare"
                    },
                    "report": {
                        "process": "Processi",
                        "reject": "Rifiutare"
                    },
                    "support_request": {
                        "answer": "Risposta",
                        "archive": "Archivio",
                        "cancel": "Annulla",
                        "pending_support": "In attesa di supporto",
                        "pending_user": "In attesa dell'utente",
                        "resolve": "Risolvere"
                    },
                    "terms_condition": {
                        "archive": "Archivio",
                        "confirmation": {
                            "confirm_button_text": "Pubblicare",
                            "confirm_text": "Sei sicuro di voler pubblicare questi termini e condizioni? A TUTTI gli utenti verrà chiesto di convalidarli nuovamente!"
                        },
                        "errors": {
                            "resources_count": "Non puoi pubblicare più di UN termini e condizioni!"
                        },
                        "publish": "Pubblicare"
                    },
                    "user": {
                        "finish": "Termina il tutorial"
                    },
                    "user_credential_verification": {
                        "validate": "Convalidare"
                    }
                },
                "cards": {
                    "new_excursions": "Nuovi Itinerari",
                    "new_points_of_interest": "Nuovi punti di interesse",
                    "new_users": "Nuovi utenti"
                },
                "filters": {
                    "app": "Applicazione",
                    "archived_at_gte": "Archiviato dopo",
                    "archived_at_lte": "Archiviato prima",
                    "canceled_at_gte": "Annullato dopo",
                    "canceled_at_lte": "Annullato prima",
                    "created_at_gte": "Creato dopo",
                    "created_at_lte": "Creato prima",
                    "dynamic_translations_enabled": "Traduzioni dinamiche abilitate",
                    "extranet": "Extranet",
                    "front": "Davanti",
                    "group": "Gruppo",
                    "published_at_gte": "Pubblicato dopo",
                    "published_at_lte": "Pubblicato prima",
                    "reservation_date_gte": "Prenotazione dopo",
                    "reservation_date_lte": "Prenotazione prima",
                    "resolved_at_gte": "Risolto dopo",
                    "resolved_at_lte": "Risolto prima",
                    "static_translations_enabled": "Traduzioni statiche abilitate",
                    "to_validate": "Per convalidare"
                },
                "labels": {
                    "institutions": "Istituzioni",
                    "point_of_interest_categories": "Categorie"
                }
            },
            "opening_hours": {
                "additional_info": "Informazioni aggiuntive",
                "content": {
                    "date": "L' {date}",
                    "date_range_from": "Da {from}",
                    "date_range_from_to": "Da {from} a {to}",
                    "date_range_to": "A {to}",
                    "hours_range_from": "Da {from}",
                    "hours_range_from_to": "Da {from} a {to}",
                    "hours_range_to": "A {to}"
                }
            },
            "partners": {
                "customers": {
                    "title": "I nostri clienti"
                },
                "index": {
                    "no_results": "Nessun partner trovato",
                    "our_last_partners": "I nostri ultimi soci",
                    "title": "I nostri partner"
                },
                "show": {
                    "link": "Visita il sito web"
                }
            },
            "payments": {
                "checkout": {
                    "cancel": "Il pagamento è stato annullato",
                    "custom_amount": "Inserisci un importo personalizzato",
                    "success": "Pagamento effettuato con successo",
                    "tip_amount": "Importo della mancia"
                },
                "index": {
                    "no_results": "Nessun pagamento trovato"
                },
                "labels": {
                    "payee": "Beneficiario:",
                    "payment_service": "Pagato da"
                },
                "payable": {
                    "reservation": "Prenotazione n.{id}"
                },
                "thanks": {
                    "success": "Grazie inviato!"
                }
            },
            "pdf": {
                "common": {
                    "generating": "Generazione del file PDF...",
                    "waiting": "Attendere prego..."
                },
                "payments": {
                    "period": "Periodo da {from} a {to}",
                    "title": "Pagamenti ricevuti"
                },
                "receipt": {
                    "debited_amount": "Importo addebitato",
                    "paid_amount": "Importo pagato",
                    "payment_date": "Data di pagamento",
                    "payment_method": "Metodo di pagamento",
                    "receipt_for": "Ricevuta del pagamento effettuato a",
                    "receipt_number": "Ricevuta del pagamento n.{id}",
                    "summary": "Riepilogo"
                },
                "share": {
                    "cta": "Consulta l'itinerario",
                    "details": {
                        "line1": "Siamo lieti di condividere con voi il vostro itinerario personalizzato.",
                        "line2": "Di seguito troverai il contenuto del tuo itinerario.",
                        "line3": "Per accedere alle informazioni dettagliate (audio con indirizzo in lingua locale, mappa interattiva,\n                immagini, descrizione completa...), clicca sul pulsante qui sotto."
                    }
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "ADMIN": "Accedi all'amministrazione",
                        "APP": "Accedi all'app",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative all'accesso",
                        "EXTRANET": "Accedi alla sezione professionale",
                        "HORIZON": "Accedi a Orizzonte",
                        "TELESCOPE": "Telescopio di accesso",
                        "VIEW_BUTLER_DASHBOARD": "Visualizza la dashboard del maggiordomo",
                        "VIEW_HOTELIER_DASHBOARD": "Visualizza la dashboard dell'albergatore",
                        "VIEW_MANAGER_DASHBOARD": "Visualizza la dashboard del gestore",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "Visualizza la dashboard dell'ufficio turistico",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "Visualizza la dashboard dei dipendenti dell'ufficio turistico",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "Accedi senza dover verificare le tue credenziali"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai registri delle attività",
                        "CREATE": "Crea un registro delle attività",
                        "DELETE": "Elimina un registro attività",
                        "FORCE_DELETE": "Forza l'eliminazione di un registro attività",
                        "RESTORE": "Ripristina un registro attività",
                        "UPDATE": "Aggiorna un registro delle attività",
                        "VIEW": "Consultare un registro delle attività",
                        "VIEW_ANY": "Consulta l'indice dei registri delle attività"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative agli indirizzi",
                        "CREATE": "Crea un indirizzo",
                        "DELETE": "Elimina un indirizzo",
                        "FORCE_DELETE": "Forza l'eliminazione di un indirizzo",
                        "RESTORE": "Ripristina un indirizzo",
                        "UPDATE": "Aggiorna un indirizzo",
                        "VIEW": "Consulta un indirizzo",
                        "VIEW_ANY": "Consulta l'indice degli indirizzi"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative agli istituti di bonifico bancario",
                        "VIEW_ANY": "Consulta l'indice degli istituti di bonifico bancario"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "ARCHIVE": "Archivia un registro delle modifiche",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai log delle modifiche",
                        "CREATE": "Crea un registro delle modifiche",
                        "DELETE": "Elimina un registro delle modifiche",
                        "DRAFT": "Redigere un registro delle modifiche",
                        "FORCE_DELETE": "Forza l'eliminazione di un registro delle modifiche",
                        "PUBLISH": "Pubblica un registro delle modifiche",
                        "RESTORE": "Ripristina un log delle modifiche",
                        "UPDATE": "Aggiorna un registro delle modifiche",
                        "VIEW": "Consulta un registro delle modifiche",
                        "VIEW_ANY": "Consulta l'indice dei log delle modifiche"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle contestazioni dei reclami",
                        "CREATE": "Crea una contestazione di reclamo",
                        "DELETE": "Eliminare una contestazione di reclamo",
                        "FORCE_DELETE": "Forza l'eliminazione di una controversia relativa a un reclamo",
                        "REJECT": "Rifiutare una contestazione di reclamo",
                        "RESTORE": "Ripristinare una controversia relativa a un reclamo",
                        "UPDATE": "Aggiorna una controversia relativa a un reclamo",
                        "VALIDATE": "Convalidare una contestazione di reclamo",
                        "VIEW": "Consultare una disputa reclamo",
                        "VIEW_ANY": "Consulta l'indice delle contestazioni"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle rivendicazioni",
                        "CREATE": "Crea un reclamo",
                        "DELETE": "Elimina un reclamo",
                        "FORCE_DELETE": "Forza l'eliminazione di un reclamo",
                        "MISSING_INFO": "Rifiutare un reclamo come informazioni mancanti",
                        "REJECT": "Rifiutare un reclamo",
                        "RESTORE": "Ripristinare un reclamo",
                        "REVOKE": "Revocare un reclamo",
                        "SHOW_FILE": "Mostra un file di reclamo",
                        "UPDATE": "Aggiorna un reclamo",
                        "VALIDATE": "Convalida un reclamo",
                        "VIEW": "Consultare un reclamo",
                        "VIEW_ANY": "Consultare l'indice dei reclami"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai paesi",
                        "USE_ANY": "Usa qualsiasi paese"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle valute",
                        "CREATE": "Crea una valuta",
                        "DELETE": "Elimina una valuta",
                        "FORCE_DELETE": "Forza l'eliminazione di una valuta",
                        "RESTORE": "Ripristina una valuta",
                        "UPDATE": "Aggiorna una valuta",
                        "VIEW": "Consulta una valuta",
                        "VIEW_ANY": "Consulta l'indice delle valute"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative agli account EasyTransac",
                        "HAVE": "Avere un conto EasyTransac"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative agli itinerari",
                        "CREATE": "Crea un itinerario",
                        "DELETE": "Elimina un itinerario",
                        "FORCE_DELETE": "Forza l'eliminazione di un itinerario",
                        "RESTORE": "Ripristina un itinerario",
                        "SHARE": "Condividi un itinerario",
                        "UPDATE": "Aggiorna un itinerario",
                        "VIEW": "Consulta un itinerario",
                        "VIEW_ANY": "Consulta l'indice degli itinerari",
                        "VIEW_KIND_UGOZER_APP": "Visualizza gli itinerari con l'App UgoZER kind",
                        "VIEW_KIND_UGOZER_PRO": "Visualizza gli itinerari con UgoZER Pro kind"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "Ignora i permessi relativi ai collegamenti tra itinerari e punti di interesse",
                        "CREATE": "Crea un collegamento tra un itinerario e un punto di interesse",
                        "DELETE": "Elimina un collegamento tra un itinerario e un punto di interesse",
                        "FORCE_DELETE": "Forza l'eliminazione di un collegamento tra un itinerario e un punto di interesse",
                        "RESTORE": "Ripristinare un collegamento tra un itinerario e un punto di interesse",
                        "UPDATE": "Aggiorna un collegamento tra un itinerario e un punto di interesse",
                        "VIEW": "Consultare un collegamento tra un itinerario e un punto di interesse",
                        "VIEW_ANY": "Consulta l'indice dei link tra itinerari e punti di interesse"
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "APPROVE": "Approvare una richiesta di un dipendente dell'istituto",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai dipendenti delle istituzioni",
                        "CANCEL": "Annullare una richiesta di un dipendente dell'istituto",
                        "CREATE": "Crea un dipendente dell'istituto",
                        "DECLINE": "Rifiutare una richiesta di un dipendente dell'istituto",
                        "DELETE": "Elimina un dipendente dell'istituto",
                        "FEATURE": "Presenta un dipendente di un'istituzione",
                        "FORCE_DELETE": "Forza l'eliminazione di un dipendente dell'istituto",
                        "REMOVE": "Rimuovere un dipendente da un'istituzione",
                        "RESTORE": "Ripristina un dipendente dell'istituzione",
                        "UPDATE": "Aggiorna un dipendente dell'istituto",
                        "VIEW": "Consultare un dipendente dell'istituto",
                        "VIEW_ANY": "Consulta l'indice dei dipendenti delle istituzioni"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "ARCHIVE": "Archiviare un'interazione",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle interazioni",
                        "CREATE": "Crea un'interazione",
                        "DELETE": "Elimina un'interazione",
                        "DRAFT": "Disegna un'interazione",
                        "FORCE_DELETE": "Forza l'eliminazione di un'interazione",
                        "PUBLISH": "Pubblica un'interazione",
                        "RESTORE": "Ripristina un'interazione",
                        "UPDATE": "Aggiorna un'interazione",
                        "VIEW": "Consulta un'interazione",
                        "VIEW_ANY": "Consulta l'indice delle interazioni"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "Ignora i permessi relativi alle lingue",
                        "CREATE": "Crea una lingua",
                        "DELETE": "Elimina una lingua",
                        "FORCE_DELETE": "Forza l'eliminazione di una lingua",
                        "RESTORE": "Ripristina una lingua",
                        "UPDATE": "Aggiorna una lingua",
                        "VIEW": "Consulta una lingua",
                        "VIEW_ANY": "Consulta l'indice delle lingue"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai media",
                        "CREATE": "Crea un supporto",
                        "DELETE": "Elimina un file multimediale",
                        "FORCE_DELETE": "Forza l'eliminazione di un supporto",
                        "RESTORE": "Ripristina un supporto",
                        "UPDATE": "Aggiorna un supporto",
                        "VIEW": "Consulta un media",
                        "VIEW_ANY": "Consulta l'indice dei media"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "Ricevi una notifica quando un invito viene accettato",
                        "PAYMENT_RECEIVED": "Ricevi una notifica quando viene ricevuto un pagamento",
                        "PAYMENT_THANKS": "Ricevi una notifica quando viene riconosciuto un pagamento",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "Ricevi una notifica quando un evento punto di interesse scade",
                        "RESERVATION_CREATED": "Ricevi una notifica quando viene creata una prenotazione",
                        "SHARE_CREATED": "Ricevi una notifica quando viene creata una condivisione",
                        "SUPPORT_REQUEST_ARCHIVED": "Ricevi una notifica quando una richiesta di supporto viene archiviata",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "Ricevi una notifica quando viene ricevuto un messaggio da un supporto\n                richiesta",
                        "TRAVELLER_FOLDER_APPROVED": "Ricevi una notifica quando la pratica di un viaggiatore viene approvata",
                        "TRAVELLER_FOLDER_ARCHIVED": "Ricevi una notifica quando un file del viaggiatore viene archiviato",
                        "TRAVELLER_FOLDER_PENDING": "Ricevi una notifica in attesa che un file venga convalidato viaggiatore",
                        "TRAVELLER_FOLDER_THANKS": "Ricevi una notifica per ringraziare il portiere incaricato di un file viaggiatore"
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai partner",
                        "CREATE": "Crea un partner",
                        "DELETE": "Elimina un partner",
                        "FORCE_DELETE": "Forza l'eliminazione di un partner",
                        "RESTORE": "Ripristina un partner",
                        "UPDATE": "Aggiorna un partner",
                        "VIEW": "Consulta un partner",
                        "VIEW_ANY": "Consulta l'indice dei partner"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai pagamenti",
                        "CREATE": "Crea un pagamento",
                        "DELETE": "Elimina un pagamento",
                        "FORCE_DELETE": "Forza l'eliminazione di un pagamento",
                        "GET_RECEIPT": "Ricevere una ricevuta per un pagamento",
                        "RECEIVE": "Ricevi pagamenti",
                        "RESTORE": "Ripristinare un pagamento",
                        "THANK": "Ricevi un ringraziamento per un pagamento",
                        "UPDATE": "Aggiorna un pagamento",
                        "VIEW": "Consultare un pagamento",
                        "VIEW_ANY": "Consulta l'indice dei pagamenti"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "Ignora i permessi relativi ai permessi",
                        "CREATE": "Crea un'autorizzazione",
                        "DELETE": "Elimina un'autorizzazione",
                        "FORCE_DELETE": "Forza l'eliminazione di un'autorizzazione",
                        "RESTORE": "Ripristina un'autorizzazione",
                        "UPDATE": "Aggiorna un'autorizzazione",
                        "VIEW": "Consultare un permesso",
                        "VIEW_ANY": "Consulta l'indice dei permessi"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "ARCHIVE": "Archivia un piano di abbonamento",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai piani di abbonamento",
                        "CREATE": "Crea un piano di abbonamento",
                        "DELETE": "Elimina un piano di abbonamento",
                        "DRAFT": "Redigere un piano di abbonamento",
                        "FORCE_DELETE": "Forza l'eliminazione di un piano di abbonamento",
                        "PUBLISH": "Pubblica un piano di abbonamento",
                        "RESTORE": "Ripristina un piano di abbonamento",
                        "SUBSCRIBE": "Iscriviti a un piano",
                        "UPDATE": "Aggiorna un piano di abbonamento",
                        "VIEW": "Consulta un piano di abbonamento",
                        "VIEW_ANY": "Consulta l'indice dei piani di abbonamento"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignora i permessi relativi alle categorie dei punti di interesse",
                        "CREATE": "Crea una categoria di punti di interesse",
                        "DELETE": "Elimina una categoria di punti di interesse",
                        "FORCE_DELETE": "Forza l'eliminazione di una categoria di punti di interesse",
                        "RESTORE": "Ripristina una categoria di punto di interesse",
                        "UPDATE": "Aggiorna una categoria di punti di interesse",
                        "VIEW": "Consulta una categoria di punti di interesse",
                        "VIEW_ANY": "Consulta l'indice delle categorie dei punti di interesse"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "ADMIN_ANY": "Amministra qualsiasi punto di interesse",
                        "APPLY": "Candidati per diventare dipendente",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai punti di interesse",
                        "CLAIM": "Richiedi un punto di interesse",
                        "CONTEST": "Contestare un punto di interesse",
                        "CONTRIBUTE": "Contribuire a un punto di interesse",
                        "CREATE": "Crea un punto di interesse",
                        "DELETE": "Elimina un punto di interesse",
                        "DENY": "Negare un punto di interesse",
                        "DRAFT": "Disegna un punto di interesse",
                        "FORCE_DELETE": "Forza l'eliminazione di un punto di interesse",
                        "IMPORT": "Importa punti di interesse",
                        "MODERATE": "Modera un punto di interesse",
                        "PUBLISH": "Pubblica un punto di interesse",
                        "REPORT": "Segnala un punto di interesse",
                        "RESTORE": "Ripristina un punto di interesse",
                        "SHARE": "Condividi un punto di interesse",
                        "SUBMIT": "Invia un punto di interesse",
                        "UPDATE": "Aggiorna un punto di interesse",
                        "UPDATE_CERTIFIED": "Aggiorna l'attributo certificato di un punto di interesse",
                        "VIEW": "Consulta un punto di interesse",
                        "VIEW_ANY": "Consulta l'indice dei punti di interesse"
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignora i permessi relativi alle categorie di post",
                        "CREATE": "Crea una categoria di post",
                        "DELETE": "Elimina una categoria di post",
                        "FORCE_DELETE": "Forza l'eliminazione di una categoria di post",
                        "RESTORE": "Ripristina una categoria di post",
                        "UPDATE": "Aggiorna una categoria di post",
                        "VIEW": "Consulta una categoria di post",
                        "VIEW_ANY": "Consulta l'indice delle categorie dei post"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "ARCHIVE": "Archivia un post",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai post",
                        "CREATE": "Crea un post",
                        "DELETE": "Elimina un post",
                        "DRAFT": "Disegna un post",
                        "FORCE_DELETE": "Forza l'eliminazione di un post",
                        "PUBLISH": "Pubblica un post",
                        "RESTORE": "Ripristina un post",
                        "UPDATE": "Aggiorna un post",
                        "VIEW": "Consulta un post",
                        "VIEW_ANY": "Consulta l'indice dei post"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "ARCHIVE": "Archiviare una politica sulla privacy",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle politiche sulla privacy",
                        "CREATE": "Crea un'informativa sulla privacy",
                        "DELETE": "Eliminare un'informativa sulla privacy",
                        "DRAFT": "Redigere una politica sulla privacy",
                        "FORCE_DELETE": "Forza l'eliminazione di un'informativa sulla privacy",
                        "PUBLISH": "Pubblicare una politica sulla privacy",
                        "RESTORE": "Ripristinare un'informativa sulla privacy",
                        "UPDATE": "Aggiorna un'informativa sulla privacy",
                        "VIEW": "Consulta un'informativa sulla privacy",
                        "VIEW_ANY": "Consulta le politiche sulla privacy"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "Registrati alla sezione professionale con qualsiasi ruolo che disponga di questo permesso"
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "ARCHIVE": "Archiviare un rapporto",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai rapporti",
                        "CREATE": "Crea un rapporto",
                        "DELETE": "Elimina un rapporto",
                        "DRAFT": "Redigere un rapporto",
                        "FORCE_DELETE": "Forza l'eliminazione di un rapporto",
                        "PROCESS_ANY": "Elabora qualsiasi rapporto",
                        "PUBLISH": "Pubblica un rapporto",
                        "REJECT_ANY": "Rifiuta qualsiasi segnalazione",
                        "RESTORE": "Ripristina un rapporto",
                        "UPDATE": "Aggiorna un rapporto",
                        "VIEW": "Consulta un rapporto",
                        "VIEW_ANY": "Consulta l'indice dei rapporti"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "ARCHIVE": "Archivia una prenotazione",
                        "BYPASS_PERMISSIONS": "Ignora i permessi relativi alle prenotazioni",
                        "CANCEL": "Annulla una prenotazione",
                        "CONFIRM": "Conferma una prenotazione",
                        "CREATE": "Crea una prenotazione",
                        "DELETE": "Elimina una prenotazione",
                        "FORCE_DELETE": "Forza l'eliminazione di una prenotazione",
                        "RESTORE": "Ripristina una prenotazione",
                        "UPDATE": "Aggiorna una prenotazione",
                        "VIEW": "Consulta una prenotazione",
                        "VIEW_ANY": "Consulta l'indice delle prenotazioni"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai ruoli",
                        "CREATE": "Crea un ruolo",
                        "DELETE": "Elimina un ruolo",
                        "FORCE_DELETE": "Forza l'eliminazione di un ruolo",
                        "RESTORE": "Ripristina un ruolo",
                        "UPDATE": "Aggiorna un ruolo",
                        "VIEW": "Consulta un ruolo",
                        "VIEW_ANY": "Consulta l'indice dei ruoli"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "Ignora i permessi relativi alle condivisioni",
                        "CREATE": "Crea una condivisione",
                        "DELETE": "Elimina una condivisione",
                        "FORCE_DELETE": "Forza l'eliminazione di una condivisione",
                        "RESTORE": "Ripristina una condivisione",
                        "UPDATE": "Aggiorna una condivisione",
                        "VIEW": "Consulta una quota",
                        "VIEW_ANY": "Consulta l'indice delle azioni"
                    },
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative agli account Stripe",
                        "HAVE": "Avere un account Stripe"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative agli abbonamenti",
                        "CANCEL": "Annulla un abbonamento",
                        "CREATE": "Crea un abbonamento",
                        "DELETE": "Elimina un abbonamento",
                        "FORCE_DELETE": "Forza l'eliminazione di un abbonamento",
                        "PAY": "Paga un abbonamento",
                        "RESTORE": "Ripristina un abbonamento",
                        "UPDATE": "Aggiorna un abbonamento",
                        "VIEW": "Consulta un abbonamento",
                        "VIEW_ANY": "Consulta l'indice degli abbonamenti"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alla richiesta di categorie di supporto",
                        "CREATE": "Crea una categoria di supporto",
                        "DELETE": "Elimina una categoria di supporto",
                        "FORCE_DELETE": "Forza l'eliminazione di una categoria di supporto",
                        "RESTORE": "Ripristina una categoria di supporto",
                        "UPDATE": "Aggiorna una categoria di supporto",
                        "VIEW": "Consulta una categoria di supporto",
                        "VIEW_ANY": "Consulta l'indice delle categorie di sostegno"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai messaggi",
                        "CREATE": "Crea un messaggio",
                        "DELETE": "Elimina un messaggio",
                        "FORCE_DELETE": "Forza l'eliminazione di un messaggio",
                        "RESTORE": "Ripristina un messaggio",
                        "UPDATE": "Aggiorna un messaggio",
                        "VIEW": "Consulta un messaggio",
                        "VIEW_ANY": "Consulta l'indice dei messaggi"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "ADD_MESSAGE": "Aggiungi un messaggio",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle richieste di supporto",
                        "CANCEL": "Annulla una richiesta di supporto",
                        "CREATE": "Crea una richiesta di assistenza",
                        "DELETE": "Elimina una richiesta di supporto",
                        "FORCE_DELETE": "Forza l'eliminazione di una richiesta di supporto",
                        "RESTORE": "Ripristina una richiesta di supporto",
                        "UPDATE": "Aggiorna una richiesta di supporto",
                        "VIEW": "Consulta una richiesta di supporto",
                        "VIEW_ANY": "Consulta l'indice delle richieste di supporto"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai tag",
                        "CREATE": "Crea un'etichetta",
                        "DELETE": "Elimina un'etichetta",
                        "FORCE_DELETE": "Forza l'eliminazione di un tag",
                        "RESTORE": "Ripristina un tag",
                        "UPDATE": "Aggiorna un tag",
                        "VIEW": "Consulta un tag",
                        "VIEW_ANY": "Consulta l'indice dei tag"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "ARCHIVE": "Archivia i termini e le condizioni",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative a termini e condizioni",
                        "CREATE": "Crea termini e condizioni",
                        "DELETE": "Elimina un termini e condizioni",
                        "DRAFT": "Redigere termini e condizioni",
                        "FORCE_DELETE": "Forza l'eliminazione di termini e condizioni",
                        "PUBLISH": "Pubblicare termini e condizioni",
                        "RESTORE": "Ripristinare termini e condizioni",
                        "UPDATE": "Aggiorna i termini e le condizioni",
                        "VIEW": "Consulta i termini e le condizioni",
                        "VIEW_ANY": "Consulta termini e condizioni"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative ai record traducibili",
                        "REQUIRE_ANY": "Permetti di tradurre un campo obbligatorio in una lingua che non è necessariamente l'inglese"
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle e-mail dei viaggiatori",
                        "CREATE": "Crea un'e-mail del viaggiatore",
                        "DELETE": "Elimina l'e-mail di un viaggiatore",
                        "FORCE_DELETE": "Forza l'eliminazione dell'email di un viaggiatore",
                        "RESTORE": "Ripristina l'e-mail di un viaggiatore",
                        "UPDATE": "Aggiorna l'email di un viaggiatore",
                        "VIEW": "Consulta l'e-mail di un viaggiatore",
                        "VIEW_ANY": "Consulta l'indice delle email dei viaggiatori"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "APPROVE": "Approva una cartella del viaggiatore",
                        "ARCHIVE": "Archivia una cartella del viaggiatore",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle cartelle dei viaggiatori",
                        "CREATE": "Crea una cartella del viaggiatore",
                        "DECLINE": "Rifiuta una cartella del viaggiatore",
                        "DELETE": "Elimina una cartella del viaggiatore",
                        "FORCE_DELETE": "Forza l'eliminazione di una cartella viaggiatore",
                        "RESTORE": "Ripristina una cartella del viaggiatore",
                        "THANK": "Grazie a un viaggiatore per il soggiorno",
                        "UPDATE": "Aggiorna una cartella del viaggiatore",
                        "VIEW": "Consultare una cartella del viaggiatore",
                        "VIEW_ANY": "Consulta l'indice delle cartelle dei viaggiatori"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle verifiche delle credenziali dell'utente",
                        "CREATE": "Crea una verifica delle credenziali dell'utente",
                        "DELETE": "Elimina una verifica delle credenziali dell'utente",
                        "FORCE_DELETE": "Forza l'eliminazione di una verifica delle credenziali utente",
                        "RESTORE": "Ripristina una verifica delle credenziali utente",
                        "UPDATE": "Aggiorna una verifica delle credenziali dell'utente",
                        "VIEW": "Consultare una verifica delle credenziali dell'utente",
                        "VIEW_ANY": "Consulta l'indice delle verifiche delle credenziali utente"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "ADD_CONTACT": "Aggiungi un utente come contatto",
                        "BYPASS_PERMISSIONS": "Ignora i permessi relativi agli utenti",
                        "CREATE": "Crea un utente",
                        "DELETE": "Elimina un utente",
                        "FORCE_DELETE": "Forza l'eliminazione di un utente",
                        "INVITE": "Invita un utente",
                        "REPORT": "Segnala un utente",
                        "RESTORE": "Ripristina un utente",
                        "TIP": "Suggerimento a un utente",
                        "UPDATE": "Aggiorna un utente",
                        "UPDATE_BANK_ACCOUNT": "Aggiorna conto bancario",
                        "VIEW": "Consulta un utente",
                        "VIEW_ANY": "Consulta l'indice degli utenti",
                        "VIEW_AVATAR": "Visualizza l'avatar di un utente",
                        "VIEW_CONTACT_EMAIL": "Visualizza l'e-mail di contatto di un utente",
                        "VIEW_CONTACT_PHONE": "Visualizza il telefono di contatto di un utente",
                        "VIEW_REAL_NAME": "Visualizza il vero nome di un utente"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle impostazioni geolocalizzate dell'utente",
                        "CREATE": "Crea un'impostazione di geolocalizzazione dell'utente",
                        "DELETE": "Elimina un'impostazione di geolocalizzazione dell'utente",
                        "FORCE_DELETE": "Forza l'eliminazione di un'impostazione geolocalizzata dell'utente",
                        "RESTORE": "Ripristina un'impostazione geolocalizzata dell'utente",
                        "UPDATE": "Aggiorna un'impostazione di geolocalizzazione dell'utente",
                        "VIEW": "Consultare un'impostazione geolocalizzata dell'utente",
                        "VIEW_ANY": "Consulta l'indice delle impostazioni geolocalizzate dell'utente"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle impostazioni di notifica dell'utente",
                        "CREATE": "Crea un'impostazione di notifica utente",
                        "DELETE": "Elimina un'impostazione di notifica utente",
                        "FORCE_DELETE": "Forza l'eliminazione di un'impostazione di notifica utente",
                        "RESTORE": "Ripristina un'impostazione di notifica utente",
                        "UPDATE": "Aggiorna un'impostazione di notifica utente",
                        "VIEW": "Consultare un'impostazione di notifica utente",
                        "VIEW_ANY": "Consulta l'indice delle impostazioni di notifica utente"
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle impostazioni sulla privacy dell'utente",
                        "CREATE": "Crea un'impostazione per la privacy dell'utente",
                        "DELETE": "Elimina un'impostazione di privacy dell'utente",
                        "FORCE_DELETE": "Forza l'eliminazione di un'impostazione di privacy dell'utente",
                        "RESTORE": "Ripristina un'impostazione di privacy dell'utente",
                        "UPDATE": "Aggiorna un'impostazione di privacy dell'utente",
                        "VIEW": "Consultare un'impostazione della privacy dell'utente",
                        "VIEW_ANY": "Consulta l'indice delle impostazioni sulla privacy degli utenti"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "APPROVE_CONTACT": "Approva una relazione utente",
                        "BLOCK_CONTACT": "Blocca una relazione utente",
                        "BYPASS_PERMISSIONS": "Ignora le autorizzazioni relative alle relazioni degli utenti",
                        "CREATE": "Crea una relazione utente",
                        "DECLINE_CONTACT": "Rifiuta una relazione utente",
                        "DELETE": "Elimina una relazione utente",
                        "FORCE_DELETE": "Forza l'eliminazione di una relazione utente",
                        "RESTORE": "Ripristina una relazione utente",
                        "UNBLOCK_CONTACT": "Sblocca una relazione utente",
                        "UPDATE": "Aggiorna una relazione utente",
                        "VIEW": "Consultare una relazione utente",
                        "VIEW_ANY": "Consulta l'indice delle relazioni degli utenti"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\AccessEnum": "Accesso",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "Registri attività",
                    "App\\Enums\\Permissions\\AddressEnum": "Indirizzi",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "Istituti di bonifici bancari",
                    "App\\Enums\\Permissions\\ChangelogEnum": "Log delle modifiche",
                    "App\\Enums\\Permissions\\ClaimEnum": "Affermazioni",
                    "App\\Enums\\Permissions\\CountryEnum": "Paesi",
                    "App\\Enums\\Permissions\\CurrencyEnum": "Valute",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "Conto EasyTransac",
                    "App\\Enums\\Permissions\\ExcursionEnum": "Itinerari",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "Collegamento tra itinerari e punti di interesse",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "I dipendenti delle istituzioni",
                    "App\\Enums\\Permissions\\InteractionEnum": "Interazioni",
                    "App\\Enums\\Permissions\\LocaleEnum": "Le lingue",
                    "App\\Enums\\Permissions\\MediumEnum": "Media",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "Ricevi notifiche",
                    "App\\Enums\\Permissions\\PartnerEnum": "Partner",
                    "App\\Enums\\Permissions\\PaymentEnum": "Pagamenti",
                    "App\\Enums\\Permissions\\PermissionEnum": "Autorizzazioni",
                    "App\\Enums\\Permissions\\PlanEnum": "Piani di abbonamento",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "Categorie di punti di interesse",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "Punti di interesse",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "Categorie di post",
                    "App\\Enums\\Permissions\\PostEnum": "Messaggi",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "Politiche sulla privacy",
                    "App\\Enums\\Permissions\\RegistrationEnum": "Registrazione",
                    "App\\Enums\\Permissions\\ReportEnum": "Rapporti",
                    "App\\Enums\\Permissions\\ReservationEnum": "Prenotazioni",
                    "App\\Enums\\Permissions\\RoleEnum": "Ruoli",
                    "App\\Enums\\Permissions\\ShareEnum": "Azioni",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "Conto Striscia",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "Abbonamenti",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "Categorie di richieste di assistenza",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "Messaggi di supporto",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "Richieste di supporto",
                    "App\\Enums\\Permissions\\TagEnum": "Tag",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "Termini e Condizioni",
                    "App\\Enums\\Permissions\\TranslatableEnum": "Record traducibili",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "Email dei viaggiatori",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "Cartelle dei viaggiatori",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "Verifiche delle credenziali dell'utente",
                    "App\\Enums\\Permissions\\UserEnum": "Utenti",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "Impostazioni di geolocalizzazione dell'utente",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "Impostazioni di notifica utente",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "Impostazioni sulla privacy dell'utente",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "Relazioni con gli utenti"
                }
            },
            "plans": {
                "archive": {
                    "success": "I piani di abbonamento sono stati archiviati (se idonei)"
                },
                "confirmation": {
                    "benefit_current_subscription": "Continuerai a beneficiare del tuo abbonamento attuale\n            fino alla fine del mese già pagato.",
                    "engagement_period": "Questa offerta include un periodo di fidanzamento di\n            {engagement_period} dopo il primo pagamento.",
                    "fee": "Questa offerta è soggetta a una commissione del {fee_percentage}% e ti dà diritto a\n            ricevi il 2% dei tuoi suggerimenti.",
                    "free": "L'abbonamento è gratuito, previa creazione di un account per la raccolta mance.",
                    "free_period": "Benefici di un periodo gratuito {free_period}.",
                    "free_period_used": "Hai già esaurito il tuo periodo gratuito e non lo sarai\n            poter beneficiare dell' {free_period} proposto da questa offerta.",
                    "name": "Stai per iscriverti all'offerta \"{name}\".",
                    "name_launch": "Stai per iscriverti all'offerta di lancio \"{name}\".",
                    "no_fee": "Questa offerta è soggetta ad una commissione dello 0% e ti consentirà di ricevere i tuoi suggerimenti per intero.",
                    "please_confirm": "Conferma la tua iscrizione cliccando sul pulsante qui sotto.",
                    "price_summary": "Questo abbonamento ti costerà\n            {price} escl. tasse / {price_incl_tasse} incl. tasse al mese.",
                    "replace_current_subscription": "Questo abbonamento sostituirà automaticamente il tuo abbonamento attuale."
                },
                "draft": {
                    "success": "I piani di abbonamento sono stati redatti (se idonei)"
                },
                "free_period_ended_in": "I periodi gratuiti terminano {in}",
                "publish": {
                    "success": "I piani di abbonamento sono stati pubblicati (se idonei)"
                },
                "set_coming_soon": {
                    "success": "I piani di abbonamento sono stati impostati su \"disponibile a breve\" (se idonei)"
                },
                "your_current_plan": "Il tuo piano attuale"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "Selezionando questa casella, dichiaro di essere un rappresentante autorizzato di\n            stabilimento ha affermato. Qualsiasi falsa dichiarazione o impersonificazione mi sottoporrà a sanzioni civili e penali\n            sanzioni secondo le leggi vigenti.",
                    "success": "Il tuo reclamo è stato inviato per la verifica."
                },
                "denied": {
                    "for_the_reason": "Negato per il seguente motivo",
                    "moderation_again": "Puoi modificare il punto di interesse, che lo sottoporrà nuovamente alla moderazione, in modo che possa essere pubblicato."
                },
                "deny": {
                    "success": "I punti di interesse sono stati negati (se idonei)"
                },
                "destroy": {
                    "confirm": "Vuoi davvero eliminare questo punto di interesse: {point_of_interest_name}?",
                    "success": "Il punto di interesse è stato eliminato"
                },
                "draft": {
                    "success": "I punti di interesse sono stati redatti (se idonei)"
                },
                "duplicates": {
                    "check_explanation1": "Per creare il tuo punto di interesse,\n            si prega di compilare i campi principali sottostanti.",
                    "check_explanation2": "Verrà effettuato un controllo per evitare\n            inserendo un punto già esistente.",
                    "check_explanation3": "Dopo la verifica, sarai in grado di completare\n            le informazioni del tuo punto e salvalo.",
                    "check_explanation_title": "Verifica",
                    "continue_creation": "Continua la creazione",
                    "found_explanation1": "Uno o più punti di interesse corrispondenti a\n            i tuoi criteri di input esistono già.",
                    "found_explanation2": "Se ritieni che questo non sia un duplicato,\n            quindi potrai procedere all'inserimento del tuo nuovo punto di interesse.",
                    "match_percentage": "Corrispondenza a {percentage}%",
                    "select_duplicate": "Clicca su uno dei punti di interesse proposti per vederne i dettagli",
                    "use_poi": "Utilizza questo punto di interesse",
                    "view_poi": "Visualizza questo punto di interesse"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "Aggiungi questo punto di interesse a un itinerario",
                    "already_in_excursions": "Questo punto di interesse fa già parte di quegli itinerari",
                    "create_excursion": "Crea un itinerario",
                    "or_create_from": "Oppure crea un nuovo itinerario da questo punto di interesse",
                    "select_which": "Seleziona a quale itinerario esistente aggiungere il punto di interesse \"{point_of_interest_name}\".",
                    "success": "Il punto di interesse è stato aggiunto all'itinerario"
                },
                "form": {
                    "help": {
                        "stars": "Solo se la categoria può essere contrassegnata con stelle (come Hotel, Ristorante...)"
                    }
                },
                "import_source": {
                    "DataTourisme": "DataTourisme"
                },
                "index": {
                    "no_results": "Nessun punto di interesse trovato",
                    "no_results_here": "Nessun punto di interesse disponibile nella tua area di ricerca.",
                    "no_tags": "Nessun tag definito",
                    "refresh_delayed": "Attendere {time_remaining} prima dell'aggiornamento",
                    "search": "Cerca un punto di interesse",
                    "try_more_filters": "Puoi modificare i filtri attivi e ampliare la ricerca."
                },
                "pages": {
                    "show": {
                        "automatic_update": "Aggiornamento automatico",
                        "belongs_to_you": "Ti appartiene",
                        "claim_dispute_pending": {
                            "details": "Dettagli della richiesta: {message}",
                            "notice": "Contenzioso in corso per le rivendicazioni",
                            "title": "Hai una controversia in corso su un reclamo relativo a questo punto di interesse."
                        },
                        "deletion": {
                            "causes": {
                                "event": "Causa: evento scaduto"
                            },
                            "title": "In attesa di cancellazione"
                        },
                        "dt_required_datatourisme_fields": "Aggiornato da {dt_created_by} da {import_source} a {dt_last_update}",
                        "excursions_counter": "È stato aggiunto {counter} volte agli itinerari",
                        "is_geocoding_1": "Il punto di interesse è in fase di geolocalizzazione.",
                        "is_geocoding_2": "Si prega di controllare questa pagina più tardi per vedere il punto di interesse\n                sulla mappa.",
                        "is_refreshing_audio_1": "Viene generato un file audio.",
                        "is_refreshing_audio_2": "Per favore controlla questa pagina più tardi per ascoltare questo audio.",
                        "not_current_locale": {
                            "change_locale": "È possibile modificare la lingua visualizzata utilizzando il selettore sopra",
                            "incomplete_translation": "Alcune informazioni di questo punto di interesse (titolo, descrizione e/o indirizzo) non sono disponibili nella lingua selezionata.",
                            "missing_translations": "Traduzioni mancanti"
                        },
                        "opening_hours": {
                            "expired": "Orari di apertura scaduti",
                            "is_deleting": "Il punto di interesse verrà eliminato entro {days} giorni",
                            "show": "Vedi gli orari di apertura"
                        },
                        "sections": {
                            "audios": "Audio",
                            "gallery": "Galleria",
                            "logo": "Logo",
                            "main_image": "Immagine principale",
                            "map": "Carta geografica",
                            "media": "Media",
                            "poi": "Punto d'interesse",
                            "videos": "Video"
                        },
                        "see_full_page": "Vedi la pagina intera"
                    }
                },
                "print": {
                    "element_not_found": "Elemento da stampare \"{id}\" non trovato!"
                },
                "publish": {
                    "success": "I punti di interesse sono stati convalidati e pubblicati (se idonei)"
                },
                "report": {
                    "choose_reason": "Scegli un motivo per segnalare questo punto di interesse \"{point_of_interest_title}\"",
                    "heading": "Segnala un punto di interesse",
                    "success": "Il punto di interesse è stato segnalato!"
                },
                "search": {
                    "creator_id": "Mostrare solo i miei punti?",
                    "no_results": "Nessun punto di interesse trovato...",
                    "tags_mode": {
                        "all": "Tutto",
                        "all_help": "Seleziona \"tutti\" per trovare punti di interesse\n                con tutti i tag selezionati.",
                        "one": "Almeno una",
                        "one_help": "Seleziona \"almeno uno\", per trovare punti di interesse\n                con almeno uno dei tag selezionati."
                    }
                },
                "share": {
                    "heading": "Condividi questo punto di interesse",
                    "select_which_contacts": "Seleziona i contatti con cui desideri condividere il punto di interesse \"{point_of_interest_title}\".",
                    "select_which_users": "Seleziona gli utenti con cui desideri condividere il punto di interesse \"{point_of_interest_title}\" o inserisci un indirizzo email per invitare un nuovo viaggiatore",
                    "select_which_users_alt": "Inserisci l'indirizzo email della persona con cui vuoi condividere il\n            \"{point_of_interest_title}\" punto di interesse.",
                    "success": "Il punto di interesse è stato condiviso!"
                },
                "states": {
                    "draft": "Bozza",
                    "pending": "In attesa di",
                    "published": "Pubblicato"
                },
                "store": {
                    "heading": "Crea un punto di interesse",
                    "success": "Il punto di interesse è stato creato"
                },
                "submit": {
                    "success": "I punti di interesse sono in attesa di moderazione (se idonei)"
                },
                "update": {
                    "alert": "L'aggiornamento di questo punto disabiliterà l'aggiornamento automatico dall'importazione {import_source}",
                    "confirm": "Vuoi davvero aggiornare questo punto di interesse: {point_of_interest_name}?",
                    "heading": "Modifica questo punto di interesse",
                    "success": "Il punto di interesse è stato aggiornato"
                }
            },
            "posts": {
                "archive": {
                    "success": "I post sono stati archiviati (se idonei)"
                },
                "draft": {
                    "success": "I post sono stati redatti (se idonei)"
                },
                "publish": {
                    "success": "I post sono stati pubblicati (se idonei)"
                },
                "search": {
                    "type_to_search": "Cerca nella categoria selezionata..."
                }
            },
            "prices": {
                "excl_taxes": "escl. le tasse",
                "free": "Gratuito",
                "from": "Da",
                "incl_taxes": "incl. le tasse",
                "per_month": "Al mese",
                "price_excl_taxes": "Prezzo escl. le tasse",
                "price_incl_taxes": "Prezzo incl. le tasse",
                "vat_amount": "importo IVA",
                "vat_percentage": "percentuale IVA"
            },
            "privacy_policies": {
                "archive": {
                    "success": "L'informativa sulla privacy è stata archiviata (se idonea)"
                },
                "errors": {
                    "not_found": "Nessuna informativa sulla privacy trovata!"
                },
                "publish": {
                    "success": "L'informativa sulla privacy è stata pubblicata"
                },
                "update": {
                    "acceptation": "Dovresti accettare l'informativa sulla privacy per continuare",
                    "new_version": "È stata pubblicata una nuova versione della politica sulla privacy",
                    "success": "L'informativa sulla privacy è stata accettata!"
                }
            },
            "reports": {
                "process": {
                    "success": "I rapporti sono stati elaborati (se idonei)"
                },
                "reject": {
                    "success": "I rapporti sono stati rifiutati (se idonei)"
                }
            },
            "reservations": {
                "archive": {
                    "confirm": "Vuoi davvero archiviare questa prenotazione?",
                    "success": "La prenotazione è stata archiviata"
                },
                "cancel": {
                    "confirm": "Vuoi davvero cancellare questa prenotazione?",
                    "success": "La prenotazione è stata cancellata"
                },
                "confirm": {
                    "confirm": "Vuoi davvero confermare questa prenotazione?",
                    "success": "La prenotazione è stata confermata"
                },
                "destroy": {
                    "confirm": "Vuoi davvero eliminare questa prenotazione?",
                    "success": "La prenotazione è stata cancellata"
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "butler": "viaggiatore",
                                "poi": "viaggiatore",
                                "traveller": "istituzione"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "butler": "Concierge",
                            "poi": "Punto d'interesse",
                            "reservation": "Prenotazione",
                            "traveller": "Viaggiatore"
                        }
                    }
                },
                "states": {
                    "archived": "Archiviato",
                    "canceled": "Annullato",
                    "confirmed": "Confermato",
                    "pending": "In attesa di"
                },
                "store": {
                    "success": "La prenotazione è stata creata"
                },
                "update": {
                    "success": "La prenotazione è stata aggiornata"
                }
            },
            "settings": {
                "items": {
                    "about": "Di",
                    "geoloc_settings": "Impostazioni di geolocalizzazione",
                    "notification_settings": "Impostazioni delle notifiche",
                    "privacy_setting": "Impostazioni della privacy",
                    "profile": "Profilo",
                    "signOut": "disconnessione"
                }
            },
            "shares": {
                "emails": {
                    "select_hint": "Puoi selezionare un'e-mail utilizzata in precedenza facendo clic qui sotto:",
                    "space_hint": "Separati da uno spazio per condividere più email"
                },
                "index": {
                    "no_results": "Nessuna condivisione trovata!"
                },
                "link": {
                    "instructions": "Copia questo link per invitare un viaggiatore e condividere questo itinerario con lui"
                },
                "show": {
                    "shared_by": "Condiviso da"
                },
                "type": {
                    "excursions": "Itinerario",
                    "pointsofinterest": "Punto d'interesse"
                }
            },
            "states": {
                "changelog_state": {
                    "archived": "Archiviato",
                    "draft": "Bozza",
                    "published": "Pubblicato"
                },
                "claim_dispute_state": {
                    "pending": "In attesa di",
                    "rejected": "Respinto",
                    "validated": "Convalidato"
                },
                "claim_state": {
                    "missing-info": "Informazione mancante",
                    "pending": "In attesa di",
                    "rejected": "Respinto",
                    "revoked": "Revocato",
                    "validated": "Convalidato"
                },
                "payment_state": {
                    "canceled": "Annullato",
                    "completed": "Completato",
                    "failed": "Fallito",
                    "pending": "In attesa di",
                    "transferred": "Trasferito"
                },
                "plan_state": {
                    "archived": "Archiviato",
                    "coming_soon": "Prossimamente",
                    "draft": "Bozza",
                    "published": "Pubblicato"
                },
                "point_of_interest_state": {
                    "deleted": "Eliminato",
                    "denied": "Negato",
                    "draft": "Bozza",
                    "pending": "In attesa di moderazione",
                    "published": "Pubblicato"
                },
                "post_state": {
                    "archived": "Archiviato",
                    "draft": "Bozza",
                    "published": "Pubblicato"
                },
                "privacy_policy_state": {
                    "archived": "Archiviato",
                    "draft": "Bozza",
                    "published": "Pubblicato"
                },
                "report_state": {
                    "pending": "In attesa di",
                    "processed": "Elaborato",
                    "rejected": "Respinto"
                },
                "reservation_state": {
                    "archived": "Archiviato",
                    "canceled": "Annullato",
                    "confirmed": "Confermato",
                    "pending": "In attesa di"
                },
                "subscription_state": {
                    "active": "Attivo",
                    "canceled": "Annullato",
                    "pending": "In attesa di Pagamento",
                    "suspended": "Sospeso"
                },
                "support_request_state": {
                    "archived": "Archiviato",
                    "canceled": "Annullato",
                    "pending-support": "Supporto in sospeso",
                    "pending-user": "Utente in sospeso",
                    "resolved": "Risolto"
                },
                "terms_condition_state": {
                    "archived": "Archiviato",
                    "draft": "Bozza",
                    "published": "Pubblicato"
                },
                "traveller_folder_state": {
                    "approved": "Approvato",
                    "archived": "Archiviato",
                    "declined": "Rifiutato",
                    "pending": "In attesa di"
                },
                "user_credential_verification_state": {
                    "canceled": "Annullato",
                    "completed": "Completato",
                    "pending": "In attesa di"
                },
                "user_tutorial_state": {
                    "claimed": "Reclamato",
                    "done": "Fatto",
                    "employee-added": "Ha aggiunto il dipendente",
                    "pending": "In attesa di"
                },
                "user_utm_campaign_state": {
                    "always_on": "Sempre acceso"
                },
                "user_utm_medium_state": {
                    "owned_social": "Rete sociale"
                },
                "user_utm_source_state": {
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "linkedin": "LinkedIn"
                },
                "users_relationship_state": {
                    "approved": "Approvato",
                    "blocked": "Bloccato",
                    "declined": "Rifiutato",
                    "pending": "In attesa di"
                }
            },
            "stripe": {
                "connect": {
                    "account": {
                        "create": {
                            "completed": "Configurazione dell'account Stripe completata",
                            "link_invalid": "La connessione a Stripe non è riuscita, riprova"
                        },
                        "must_subscribe": "Devi essere iscritto per creare il tuo account Stripe.",
                        "show": {
                            "account_usage": "Il tuo account Stripe consentirà al tuo istituto di ricevere pagamenti su UgoZER.",
                            "can_receive_payments": "Puoi ricevere pagamenti.",
                            "cannot_receive_payments": "Non sei in grado di ricevere pagamenti",
                            "fee_percentage": "Verrà applicata una commissione pari all'1% su ciascun pagamento ricevuto.",
                            "go_to_stripe": "Vai alla dashboard di Stripe",
                            "has_stripe_account_being_reviewed": "Il tuo account è in fase di revisione da parte di Stripe.",
                            "no_account_yet": "Non hai ancora un account Stripe.",
                            "should_complete_stripe_onboarding": "Completa la registrazione con Stripe e fornisci loro le informazioni richieste."
                        }
                    }
                },
                "stripe": "Banda"
            },
            "subscriptions": {
                "activated_at": "Attivazione",
                "cancel": "Annulla",
                "cancelation_success": "Cancellazione presa in considerazione",
                "canceled_at": "Cancellazione",
                "confirm_cancel": "Vuoi davvero cancellare il tuo abbonamento?",
                "confirm_cancel_date": "Beneficerai dei suoi vantaggi fino alla data di cancellazione effettiva in {date}.",
                "confirm_cancel_now": "La cancellazione avrà effetto immediato.",
                "expiry": "Scadenza",
                "invoices": "Fatture",
                "my_current_subscription": "Il mio abbonamento attuale",
                "price_excl_taxes": "Prezzo escl. le tasse",
                "price_incl_taxes": "Prezzo incl. le tasse",
                "upcoming_subscription": "Prossimo abbonamento"
            },
            "support_messages": {
                "show": {
                    "you": "Voi",
                    "your_question": "La tua domanda"
                },
                "store": {
                    "heading": "Aggiungi un messaggio",
                    "success": {
                        "title": "Il messaggio è stato creato. L'assistenza contattata risponderà entro 48 ore."
                    }
                }
            },
            "support_requests": {
                "actions": {
                    "archived": {
                        "success": "La richiesta è archiviata"
                    },
                    "canceled": {
                        "success": "La richiesta viene annullata"
                    },
                    "pending_support": {
                        "success": "In attesa che il supporto risponda"
                    },
                    "pending_user": {
                        "success": "In attesa che l'utente risponda"
                    },
                    "resolved": {
                        "success": "La richiesta è risolta"
                    }
                },
                "cancel": {
                    "confirm": "Vuoi davvero annullare questa richiesta di supporto: {support_request_title} ?",
                    "error": "Impossibile annullare la richiesta di supporto",
                    "success": "La richiesta di supporto è stata annullata"
                },
                "index": {
                    "no_results": "Non hai alcuna richiesta di supporto",
                    "search": "Cerca una richiesta di supporto",
                    "your_requests": "Le tue richieste di supporto"
                },
                "pages": {
                    "create": {
                        "notice": "Dati personali"
                    },
                    "index": {
                        "search": "Cerca una richiesta di supporto"
                    },
                    "show": {
                        "sections": {
                            "messages": "Messaggi",
                            "request": "Richiesta"
                        }
                    }
                },
                "show": {
                    "add_request": "Aggiungi una nuova richiesta di supporto"
                },
                "states": {
                    "archived": "Archiviato",
                    "canceled": "Annullato",
                    "pending-support": "Supporto in sospeso",
                    "pending-user": "Utente in sospeso",
                    "resolved": "Risolto"
                },
                "store": {
                    "heading": "Crea una nuova richiesta di supporto",
                    "success": {
                        "title": "La richiesta è stata creata. L'assistenza contattata risponderà entro 48 ore."
                    }
                }
            },
            "terms_conditions": {
                "archive": {
                    "success": "I termini e le condizioni sono stati archiviati (se idonei)"
                },
                "errors": {
                    "not_found": "Nessun termine e condizione trovato!"
                },
                "publish": {
                    "success": "Termini e condizioni sono stati pubblicati"
                },
                "update": {
                    "acceptation": "Dovresti accettare i termini e le condizioni per continuare",
                    "new_version": "È stata pubblicata una nuova versione dei termini e delle condizioni",
                    "success": "Termini e condizioni sono stati accettati!"
                }
            },
            "traveller_folders": {
                "messages": {
                    "approve": {
                        "confirm": "Vuoi davvero confermare questo soggiorno?",
                        "success": "Resta confermato!"
                    },
                    "archive": {
                        "confirm": "Vuoi davvero archiviare questo soggiorno?",
                        "success": "Questo soggiorno è stato archiviato!"
                    },
                    "decline": {
                        "confirm": "Vuoi davvero rifiutare questo soggiorno?",
                        "success": "Rimani rifiutato!"
                    }
                },
                "no_results": {
                    "approved": "Nessun soggiorno approvato",
                    "archived": "Nessun soggiorno archiviato",
                    "declined": "Nessun soggiorno rifiutato",
                    "pending": "Nessuna richiesta di soggiorno"
                },
                "pages": {
                    "form": {
                        "cannot_attach": {
                            "description": "Questo utente esiste ma non può essere supportato...",
                            "title": "Supporto non possibile"
                        },
                        "poi_empty_option": "Rimuovi punto di interesse",
                        "poi_label": "L'istituzione della portineria",
                        "verification": {
                            "description": "Inserisci l'indirizzo email del viaggiatore. Se esiste già un account viaggiatore, lo sarà\n                    essere direttamente collegato, altrimenti dovrai inserire alcune informazioni aggiuntive per creare un file\n                    nuovo.",
                            "title": "Verifica"
                        }
                    },
                    "index": {
                        "pending_stays": "I tuoi soggiorni in sospeso",
                        "search": "Cerca tra i miei viaggiatori",
                        "travellers_folders": "Cartelle dei viaggiatori",
                        "your_stays": "I tuoi soggiorni"
                    },
                    "qr_code_1": "Un viaggiatore può scansionare questo codice QR per ottenere automaticamente una cartella del viaggiatore\n            con il tuo istituto",
                    "qr_code_2": "Se il viaggiatore non si è ancora registrato a UgoZER, verrà reindirizzato alla registrazione\n            e sarà collegato al tuo istituto al termine della sua registrazione.",
                    "show": {
                        "fields": {
                            "state": "Stato:"
                        },
                        "sections": {
                            "butler": "Portineria relativa",
                            "folder": "Cartella",
                            "hotel": "Hotel",
                            "institution": "Istituzione correlata",
                            "interactions": "Interazioni",
                            "invitation": "Invita un viaggiatore",
                            "payments": "Pagamenti",
                            "reservations": "Prenotazioni",
                            "stay": "Rimanere",
                            "thanks": "Grazie",
                            "traveller": "Viaggiatore"
                        }
                    },
                    "wizard": {
                        "choose_an_institution": "Scegli un'istituzione",
                        "no_linked_institution": "Devi essere collegato a un'istituzione prima di poter prendere un viaggiatore."
                    }
                },
                "placeholders": {
                    "butler": "Squadra di accoglienza"
                },
                "states": {
                    "approved": "Accettato",
                    "archived": "Archiviato",
                    "declined": "Rifiutato",
                    "pending": "In attesa di",
                    "undefined": "Non definito"
                },
                "store": {
                    "success": "La cartella è stata creata"
                },
                "tabs": {
                    "approved": "Approvato",
                    "archived": "Archiviato",
                    "declined": "Rifiutato",
                    "pending": "In attesa di"
                },
                "thank": {
                    "success": "I ringraziamenti sono stati inviati"
                },
                "update": {
                    "success": "La cartella è stata aggiornata"
                }
            },
            "tutorials": {
                "extranet": {
                    "begin": {
                        "butler": {
                            "description": "Per consentirti di utilizzare l'applicazione UgoZER nelle migliori condizioni,\n                    ti consigliamo di seguire i seguenti passi che ti guideranno nei primi passi del\n                    gestione e la parametrizzazione del tuo spazio Concierge.",
                            "steps": {
                                "institution": {
                                    "content": "Cerca il tuo hotel nell'elenco delle strutture utilizzando la barra di ricerca e\n                            i filtri disponibili nella sezione sinistra della pagina. Una volta trovato il tuo\n                            stabilimento, crea un'applicazione facendo clic su <strong>Iscriviti per partecipare</strong>\n                            pulsante.",
                                    "description": "Collegati alla tua istituzione",
                                    "step_next": "reclamato",
                                    "step_state": "in attesa di",
                                    "subtitle": {
                                        "link": "La mia istituzione",
                                        "part_1": "Collegami al mio hotel",
                                        "part_2": "Puoi collegarti al tuo hotel direttamente alla pagina"
                                    },
                                    "title": "Istituzione"
                                }
                            }
                        },
                        "done": {
                            "description": "Hai ora completato i vari passaggi per la configurazione dell'applicazione UgoZER.",
                            "faq": {
                                "link": "FAQ",
                                "part_1": "Se hai bisogno di ulteriore aiuto, puoi trovare articoli nel",
                                "part_2": "sezione della domanda"
                            },
                            "help": "Puoi anche trovare aiuto contestuale tramite la sezione <strong>Aiuto</strong> presente in alcune pagine.",
                            "title": "Congratulazioni!"
                        },
                        "hotelier": {
                            "description": "Per consentirti di utilizzare l'applicazione UgoZER nelle migliori condizioni,\n                    ti consigliamo di seguire i seguenti passi che ti guideranno nei primi passi del\n                    la gestione e la parametrizzazione del tuo spazio Hotel manager.",
                            "steps": {
                                "employees": {
                                    "description": "Gestisci i tuoi concierge",
                                    "step_next": "aggiunto dal dipendente",
                                    "step_state": "reclamato",
                                    "title": "Portieri"
                                }
                            }
                        },
                        "manager": {
                            "description": "Per consentirti di utilizzare l'applicazione UgoZER nelle migliori condizioni,\n                    ti consigliamo di seguire i seguenti passi che ti guideranno nei primi passi del\n                    gestione e la parametrizzazione del tuo spazio Altro business manager."
                        },
                        "messages": {
                            "claim": "Il tuo reclamo è stato convalidato.",
                            "contest": "La tua controversia è stata inviata al nostro team.",
                            "payment": "Le tue informazioni bancarie sono state registrate con successo.",
                            "point_of_interest": "Il punto di interesse è stato creato, ora puoi rivendicarlo."
                        },
                        "no_tutorial": {
                            "content": "Non è stato configurato alcun tutorial per questo ruolo oppure sei connesso come amministratore.",
                            "title": "Nessun tutorial disponibile"
                        },
                        "notices": {
                            "add_butler": {
                                "content": "Per utilizzare l'applicazione, devi creare un account concierge per la tua struttura\n                        da cui verranno inviate le informazioni ai viaggiatori.",
                                "content_2": "Per attivare l'account concierge, il concierge dovrà confermare la propria email\n                        indirizzo e creare la propria password.",
                                "title": "Aggiungi un portiere"
                            },
                            "add_employee": {
                                "content": "Per utilizzare l'applicazione, è necessario creare un account dipendente per la propria struttura\n                        da cui verranno inviate le informazioni ai viaggiatori.",
                                "content_2": "Per attivare l'account dipendente, il dipendente dovrà confermare la propria e-mail\n                        indirizzo e creare la propria password.",
                                "title": "Aggiungi un dipendente"
                            }
                        },
                        "shared": {
                            "actions": {
                                "skip_step": "Salta il passaggio",
                                "skip_tutorial": "Salta il tutorial"
                            },
                            "claims": {
                                "description": "Rivendica le tue istituzioni",
                                "documents": {
                                    "association": {
                                        "identity_piece": "Una prova dell'identità del legale rappresentante nel corso dell'\n                                    valido",
                                        "statutes": "Statuti delle associazioni",
                                        "title": "Se sei un'associazione"
                                    },
                                    "company": {
                                        "identity_piece": "Una prova dell'identità del legale rappresentante nel corso dell'\n                                    valido",
                                        "kbis": "Un estratto del KBIS",
                                        "title": "Se sei un'azienda"
                                    },
                                    "description": "Per richiedere il tuo istituto, dovrai preparare quanto segue\n                                documenti:",
                                    "individual": {
                                        "identity_piece": "Un documento d'identità valido",
                                        "registration_document": "Un certificato di registrazione dell'attività autonoma",
                                        "title": "Se sei un'impresa autonoma"
                                    },
                                    "label": "Documenti",
                                    "title": "Richiedi documenti"
                                },
                                "notice": {
                                    "description": "Puoi trovare questa spiegazione facendo clic su <strong>Aiuto</strong>\n                                pulsante in alto a destra nell'elenco dei punti di interesse.",
                                    "title": "notare che"
                                },
                                "step_next": "reclamato",
                                "step_state": "in attesa di",
                                "title": "Reclamo"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "Sei sicuro di voler saltare questo passaggio?",
                                    "success": "Il passaggio è stato ben ignorato!"
                                },
                                "skip_tutorial": {
                                    "confirm": "Sei sicuro di voler saltare questo tutorial?",
                                    "success": "Il tutorial è stato ben ignorato!"
                                }
                            },
                            "payments": {
                                "description": "Ricevi suggerimenti",
                                "step_next": "Fatto",
                                "step_state": "aggiunto dal dipendente",
                                "steps": {
                                    "bank": {
                                        "create": "Fai clic sul pulsante <strong>Aggiungi un account</strong> nella\n                                    Sezione <strong>Conto bancario</strong>"
                                    },
                                    "easytransac": {
                                        "connection": "Clicca sul link\n                                    <strong>Vai alla dashboard di EasyTransac</strong> e accedi con le tue credenziali\n                                        ricevuto via e-mail",
                                        "create": "Fai clic sul pulsante <strong>Crea</strong> nel\n                                    Sezione <strong>EasyTransac</strong>",
                                        "created": "L'account viene creato automaticamente sulla base dei dati inseriti\n                                    sul tuo profilo",
                                        "form": "Compila il modulo su EasyTransac per attivare il tuo conto di pagamento",
                                        "warning": {
                                            "description": "A seconda del livello KYC del tuo account EasyTransac, potresti\n                                        essere in grado di ricevere pagamenti ma non di trasferire fondi a una banca normale\n                                        account. fondi su un conto bancario regolare. Per questo avrai bisogno del livello KYC\n                                        2 e fornire determinati documenti nell'interfaccia di EasyTransac. l'EasyTransac\n                                        interfaccia.",
                                            "title": "Livello KYC"
                                        }
                                    },
                                    "my_profile": {
                                        "part_1": "Vai alla pagina",
                                        "part_2": "Il mio profilo"
                                    }
                                },
                                "subtitle_1": "Affinché i tuoi concierge possano ricevere mance, devi creare un account\n                            sul sito web del nostro partner EasyTransac.",
                                "subtitle_2": "Per fare questo:",
                                "title": "Pagamento"
                            }
                        },
                        "tourist_office": {
                            "description": "Per consentirti di utilizzare l'applicazione UgoZER nelle migliori condizioni,\n                    ti consigliamo di seguire i seguenti passi che ti guideranno nei primi passi del\n                    gestione e la parametrizzazione del vostro spazio ufficio turistico.",
                            "steps": {
                                "employees": {
                                    "description": "Gestisci i tuoi dipendenti",
                                    "step_next": "aggiunto dal dipendente",
                                    "step_state": "reclamato",
                                    "title": "Dipendenti"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "Per consentirti di utilizzare l'applicazione UgoZER nelle migliori condizioni,\n                    ti consigliamo di seguire i seguenti passi che ti guideranno nei primi passi del\n                    gestione e la parametrizzazione del vostro spazio per i dipendenti dell'Ufficio turistico.",
                            "steps": {
                                "institution": {
                                    "content": "Cerca il tuo ufficio turistico nell'elenco degli stabilimenti utilizzando la barra di ricerca\n                            e i filtri disponibili nella sezione sinistra della pagina. Una volta trovato il tuo\n                            stabilimento, crea un'applicazione facendo clic su <strong>Iscriviti per partecipare</strong>\n                            pulsante.",
                                    "description": "Collegati alla tua istituzione",
                                    "step_next": "reclamato",
                                    "step_state": "in attesa di",
                                    "subtitle": {
                                        "link": "La mia istituzione",
                                        "part_1": "Collegami al mio ufficio turistico",
                                        "part_2": "Puoi collegarti al tuo ufficio turistico direttamente alla pagina"
                                    },
                                    "title": "Istituzione"
                                }
                            }
                        }
                    },
                    "dashboard": {
                        "claim": {
                            "steps": {
                                "claim_form": "Quindi seguire le istruzioni sul modulo di richiesta",
                                "claim_validation": "Il reclamo verrà quindi verificato e convalidato dal nostro team",
                                "dont_exist": "Se non esiste, clicca sul pulsante <strong>Crea un punto di interesse\n                        </strong> quindi compila il modulo. Una volta creato il punto di interesse, vai al suo\n                        carta e fai clic sul pulsante <strong>Richiedi</strong>",
                                "exist": "Se il tuo istituto esiste, vai alla sua scheda e clicca sul pulsante <strong>\n                        Reclamo</strong>",
                                "filters": "Utilizza i filtri e la ricerca per trovare il tuo istituto",
                                "poi_index": {
                                    "part_1": "Vai a",
                                    "part_2": "elenco dei punti di interesse"
                                }
                            },
                            "subtitle_1": "Puoi rivendicare uno (o più) stabilimenti per gestirli.",
                            "subtitle_2": "Fare così:",
                            "title": "Rivendicare la proprietà e la gestione della mia istituzione"
                        },
                        "employees": {
                            "link": "Per creare una richiesta possono andare alla pagina <strong>La mia istituzione</strong>\n                    del proprio spazio e compilare il modulo.",
                            "manage": "In questa pagina puoi accettare o rifiutare i dipendenti che desiderano collegarsi al tuo\n                    istituzione.",
                            "subtitle": {
                                "part_1": "Puoi gestire i tuoi dipendenti direttamente tramite la pagina",
                                "part_2": "I miei dipendenti"
                            },
                            "title": "Gestisci i miei dipendenti"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "distance": "<strong>Luogo:</strong> ti consente di filtrare i punti da una posizione specifica",
                            "main_title": "Filtri",
                            "subtitle": "Puoi filtrare l'elenco degli itinerari secondo i seguenti criteri:",
                            "title": "Filtra itinerari",
                            "type": {
                                "customized": "<strong>Personalizzato:</strong> itinerari creati appositamente per un viaggiatore",
                                "explanation": "<strong>Tipo:</strong> ti permette di filtrare per tipologia di itinerario",
                                "pro": "<strong>UgoZER Pro:</strong> itinerari forniti da UgoZER",
                                "root": "<strong>I miei modelli:</strong> itinerari modello"
                            }
                        }
                    },
                    "institution_employee": {
                        "approve": {
                            "content": "Questa azione ti consente di accettare una richiesta da parte di uno dei tuoi dipendenti di unirsi a uno di\n                    le tue istituzioni.",
                            "main_title": "Approvare",
                            "title": "Approvare un dipendente"
                        },
                        "cancel": {
                            "content": "Questa azione consente di annullare la richiesta di invito di un dipendente in caso di in\n                    caso di errore, ad es.",
                            "main_title": "Annulla",
                            "title": "Annullare la domanda di un dipendente"
                        },
                        "decline": {
                            "content": "Questa azione ti consente di negare la richiesta di uno dei tuoi dipendenti di unirsi a uno dei tuoi\n                    istituzioni.",
                            "main_title": "Rifiutare",
                            "title": "Rifiutare un dipendente"
                        },
                        "functioning": {
                            "content": {
                                "invite": "In questa pagina puoi invitare dipendenti o accettare/rifiutare richieste da parte tua\n                        dipendenti.",
                                "section": "Questa sezione ti consente di gestire i dipendenti delle tue istituzioni."
                            },
                            "main_title": "Funzionamento",
                            "title": "Funzionamento della sezione dipendenti"
                        },
                        "invite": {
                            "content": {
                                "button": "Facendo clic sul pulsante <strong>Invita dipendente</strong> puoi invitare uno dei tuoi\n                        dipendenti indipendentemente dal fatto che abbiano già un account UgoZER o meno.",
                                "form": "Per fare ciò, segui semplicemente le istruzioni nel modulo passo passo che appare.\n                        forma che appare."
                            },
                            "main_title": "Invitare",
                            "title": "Invita un dipendente"
                        },
                        "remove": {
                            "content": "Questa azione ti consente di rimuovere un utente dai tuoi dipendenti nel caso in cui un\n                    il dipendente lascia la tua azienda. nel caso in cui un dipendente lasci la tua azienda.",
                            "main_title": "Rimuovere",
                            "title": "Rimuovere un dipendente"
                        }
                    },
                    "points_of_interest": {
                        "claim": "Reclamo",
                        "filters": {
                            "category": "<strong>Categoria:</strong> ti consente di scegliere una categoria specifica\n                    (Albergo, ristorante...)",
                            "city": "<strong>Città:</strong> ti consente di filtrare in base a una città specifica",
                            "country": "<strong>Paesi:</strong> ti consente di filtrare in base a uno o più paesi specifici",
                            "distance": "<strong>Luogo:</strong> consente di filtrare i punti da una posizione specifica",
                            "main_title": "Filtri",
                            "measure_unit": "<strong>Unità di misura:</strong> consente di modificare l'unità di misura utilizzata\n                    il filtro della distanza e il display",
                            "my_points": "<strong>Mostra solo i miei punti:</strong> mostra solo i punti che hai creato o\n                    rivendicato da te",
                            "postal_code": "<strong>CAP :</strong> ti consente di filtrare per codice postale",
                            "radius": "<strong>Limita a un raggio:</strong> consente di filtrare i punti in un cerchio specifico\n                    raggio",
                            "subtitle": "Puoi filtrare l'elenco dei punti di interesse secondo i seguenti criteri:",
                            "tags": "<strong>Perfeziona:</strong> consente di perfezionare la ricerca per tag per specificare la categoria.\n                    È anche possibile definire se si desidera cercare punti con uno o tutti o tutti\n                    i tag selezionati",
                            "title": "Filtra punti di interesse"
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "validate": {
                    "success": "La credenziale è stata convalidata con successo (se idonea)"
                },
                "verify": {
                    "already_verified": "Le tue credenziali sono già verificate",
                    "expired": "Il link è scaduto, richiedine uno nuovo",
                    "impossible": "La verifica di questa credenziale non è più possibile",
                    "not_received": "Non hai ricevuto il link di conferma?",
                    "sent": {
                        "again": "Invia di nuovo",
                        "correct": "Correggere la credenziale scelta",
                        "email_1": "Verrà inviata un'e-mail a {email}, se questo indirizzo non è già in uso.",
                        "email_2": "Questa e-mail contiene un collegamento che ti consentirà di confermare il tuo indirizzo.",
                        "email_3": "La tua email verrà aggiornata non appena accederai al collegamento.",
                        "refresh": "Hai cliccato sul link? Aggiorna l'applicazione",
                        "title": "Collegamento inviato",
                        "wait_before": "Attendi {seconds} secondi prima di inviare nuovamente"
                    },
                    "success": "Le tue credenziali sono state verificate con successo"
                }
            },
            "user_geoloc_settings": {
                "geolocation": {
                    "error": {
                        "allow_browser": "Consenti al tuo browser di geolocalizzarti nelle impostazioni del tuo telefono",
                        "allow_site": "Consenti a UgoZER di geolocalizzarti nelle impostazioni del tuo browser",
                        "intro": "Per visualizzare i punti di interesse intorno a te:",
                        "title": "Geolocalizzazione fallita"
                    },
                    "intro": "La geolocalizzazione ti permette di trovare i punti di interesse più vicini."
                },
                "not_configured": {
                    "body1": "Seleziona una posizione a tua scelta per visualizzare una selezione di punti di interesse nelle vicinanze.",
                    "body2": "E' possibile visualizzare anche solo i punti di interesse più vicini a te.",
                    "do_not_ask_again": "Non chiedermelo più fino al riavvio dell'applicazione",
                    "subject": "Non hai ancora definito le impostazioni di geolocalizzazione."
                },
                "update": {
                    "success": "Le impostazioni di geolocalizzazione sono state aggiornate"
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "Le impostazioni delle notifiche sono state aggiornate"
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "Le impostazioni sulla privacy sono state aggiornate"
                }
            },
            "users": {
                "butlers": "Portieri",
                "finish": {
                    "success": "Il tutorial è stato modificato allo stato finale"
                },
                "index": {
                    "hint": "Un utente può essere trovato tramite il suo nickname o il suo ID pubblico.",
                    "no_results": "Nessun utente trovato",
                    "search": "Cerca un utente"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "delete": "Cancella il mio account?",
                            "profile": "Il mio profilo"
                        }
                    }
                },
                "profile": {
                    "alerts": {
                        "blocked": {
                            "message": "Non puoi più interagire con questo utente.",
                            "title": "Questo utente ti ha bloccato..."
                        },
                        "missing_infos": {
                            "message": "Per sfruttare tutte le funzionalità dell'applicazione, completare le seguenti informazioni:",
                            "title": "Completa il tuo profilo"
                        },
                        "pending_validation": {
                            "message": "Questo utente non ha ancora accettato la tua richiesta.",
                            "title": "In attesa di convalida"
                        }
                    },
                    "avatar": {
                        "defaults": {
                            "female": "Femmina",
                            "male": "Maschio"
                        },
                        "section": {
                            "cta": "Usa questo avatar",
                            "description": "Puoi selezionare un avatar predefinito dall'elenco sottostante",
                            "title": "Scelta di un avatar predefinito"
                        },
                        "success": "Il tuo avatar è stato aggiornato con successo!"
                    },
                    "bank_account_number_missing": "Si prega di fornire le informazioni bancarie per ricevere i pagamenti.",
                    "delete_account": {
                        "easy_transac": "Il tuo account EasyTransac non verrà eliminato.",
                        "instructions": "Per confermare la cancellazione definitiva del tuo account, inserisci la parola \"CANCELLA\" nel campo sottostante e clicca sul pulsante \"Conferma\".",
                        "success": "Il tuo account è stato eliminato.",
                        "title": "Cancellazione dell'account",
                        "warning": "Il tuo account, così come tutte le informazioni ad esso relative, verranno immediatamente cancellati senza possibilità di recupero."
                    },
                    "edit_email": "Modifica la tua email",
                    "edit_password": "Modifica la tua password",
                    "edit_phone": "Modifica il tuo numero di telefono",
                    "edit_your_profile": "modifica il tuo profilo",
                    "email_missing": "Non hai inserito un'e-mail verificata.",
                    "fill_country": "Per favore specifica il tuo paese",
                    "form": {
                        "poi_label": "Istituzione collegata"
                    },
                    "manage_my_subscription": "Gestisci il mio abbonamento",
                    "password_missing": "Non hai inserito una password.",
                    "phone_missing": "Non hai inserito un numero di telefono verificato",
                    "preferences": "Preferenze",
                    "public_id": "Oppure usa questo identificatore:",
                    "qr_code": "Scansionami per vedere il mio profilo",
                    "security": "Sicurezza",
                    "share_profile": "Condividi il tuo profilo",
                    "subscribe_to_receive_payments": "Devi essere iscritto per ricevere i pagamenti.",
                    "unnamed": "Senza nome",
                    "your_personal_information": "Le tue informazioni personali"
                },
                "report": {
                    "choose_reason": "Scegli un motivo per segnalare questo utente \"{user_name}\"",
                    "heading": "Segnala un utente",
                    "success": "L'utente è stato segnalato!"
                },
                "search": {
                    "no_results": "Nessun utente trovato"
                },
                "send_link": {
                    "error": "Impossibile inviare il collegamento, l'utente ha un'e-mail?",
                    "success": "Il collegamento all'applicazione è stato inviato"
                },
                "update": {
                    "success": "Il profilo è stato aggiornato"
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "Nessun contatto trovato",
                    "pending_requests": "I tuoi contatti in sospeso"
                },
                "messages": {
                    "add": {
                        "confirm": "Vuoi davvero aggiungere questo contatto?",
                        "error": "Si verifica un errore durante la richiesta di contatto...",
                        "success": "Richiesta di contatto completata con successo!"
                    },
                    "approve": {
                        "confirm": "Vuoi davvero accettare questa richiesta di contatto?",
                        "success": "Richiesta di contatto accettata!"
                    },
                    "block": {
                        "confirm": "Vuoi davvero bloccare questo utente?",
                        "success": "Questo contatto è stato bloccato!"
                    },
                    "decline": {
                        "confirm": "Vuoi davvero rifiutare questa richiesta di contatto?",
                        "success": "Richiesta di contatto rifiutata!"
                    },
                    "unblock": {
                        "confirm": "Vuoi davvero sbloccare questo utente?",
                        "success": "Questo contatto è stato sbloccato!"
                    }
                },
                "no_results": {
                    "approved": "Nessun contatto approvato",
                    "blocked": "Nessun contatto bloccato",
                    "pending": "Nessuna richiesta di contatto in sospeso",
                    "sent": "Nessuna richiesta di contatto inviata"
                },
                "search": {
                    "no_results": "Nessun contatto trovato"
                },
                "tabs": {
                    "approved": "Approvato",
                    "blocked": "Bloccato",
                    "pending": "In attesa di",
                    "sent": "Inviato"
                }
            }
        },
        "validation": {
            "accepted": "L' {attribute} deve essere accettato.",
            "active_url": "L' {attribute} non è un URL valido.",
            "after": " {attribute} deve essere una data successiva a {date}.",
            "after_or_equal": " {attribute} deve essere una data successiva o uguale a {date}.",
            "alpha": "L' {attribute} può contenere solo lettere.",
            "alpha_dash": "L' {attribute} può contenere solo lettere, numeri, trattini e trattini bassi.",
            "alpha_num": "L' {attribute} può contenere solo lettere e numeri.",
            "array": " {attribute} deve essere un array.",
            "attributes": {
                "accounting_file": "fascicolo contabile",
                "actions": "Azioni",
                "activated_at": "attivato il",
                "active": "attivo",
                "additional_information": "Informazioni aggiuntive",
                "address": "indirizzo",
                "addresses": "indirizzi",
                "admin_message": "Messaggio",
                "administrative_area": "area amministrativa",
                "amount": "quantità",
                "app": "applicazione",
                "app_fees": "commissioni",
                "approved_at": "approvato a",
                "archived_at": "Archiviato in",
                "association_legal_representative_identity_piece": "prova dell'identità del legale rappresentante",
                "association_statutes": "statuti",
                "attached_files": "Files allegati",
                "audio": "Audio",
                "audio_refreshing": "aggiornamento dell'audio",
                "audios": "audio",
                "authenticated_once": "autenticato una volta",
                "auto_renewal_enabled": "rinnovo automatico abilitato",
                "avatar": "avatar",
                "bank_account": "conto bancario",
                "bank_account_number": "numero di conto bancario",
                "bank_bic": "BIC",
                "bank_city": "Città bancaria",
                "bank_country": "Paese della banca",
                "bank_file": "cartella bancaria",
                "bank_iban": "IBAN",
                "bank_name": "nome della banca",
                "bank_payee": "Beneficiario",
                "bank_transfer": "trasferimento bancario",
                "bank_transfer_institutions": "istituto di bonifici bancari",
                "bank_transfer_reason": "Motivo del trasferimento",
                "butler": "concierge",
                "butler_point_of_interest": "istituto di portineria",
                "canceled_at": "Annullato alle",
                "categories": "categorie",
                "category": "categoria",
                "category_id": "identificativo della categoria",
                "causer": "causante",
                "causer_id": "ID causante",
                "causer_type": "tipo di causa",
                "certified": "certificato",
                "city": "città",
                "claim_confirmation": "conferma del reclamo",
                "code": "codice",
                "collection_name": "nome della raccolta",
                "communication_consent": "Comunicazioni personalizzate",
                "communication_consent_alt": "Comunicazioni?",
                "company_legal_representative_identity_piece": "prova dell'identità del legale rappresentante",
                "company_registration_document": "documento di registrazione",
                "company_statutes": "statuti",
                "company_type": "Tipo di azienda",
                "completed_at": "completato a",
                "contact": "contatto",
                "contact_email": "contatto email",
                "contact_phone": "Contatto telefonico",
                "content": "contenuto",
                "contestant": "concorrente",
                "contestant_company_type": "Tipo di azienda",
                "contestant_email": "e-mail",
                "contestant_first_name": "nome di battesimo",
                "contestant_last_name": "cognome",
                "contestant_message": "Messaggio",
                "contestant_phone": "telefono",
                "conversions_disk": "disco di conversione",
                "coords": "coordinate",
                "countries": "Paesi",
                "countries_codes": "Paesi",
                "country": "Paese",
                "country_code": "prefisso internazionale",
                "created": "creato",
                "created_at": "creato a",
                "creator": "Creatore",
                "credential": "credenziali",
                "currency": "valuta",
                "current_password": "password attuale",
                "custom_amount": "importo personalizzato",
                "custom_properties": "proprietà personalizzate",
                "customers_qty": "quantità clienti",
                "date": "data",
                "dates": "date",
                "days": "giorni",
                "declined_at": "rifiutato a",
                "deleted_at": "cancellato a",
                "deletion_confirmation": "conferma della cancellazione",
                "denial_reason": "motivo di rifiuto",
                "dependent_locality": "località dipendente",
                "description": "descrizione",
                "details": "dettagli",
                "disk": "disco",
                "display": "Schermo",
                "displayed_name": "nome visualizzato",
                "distance": "distanza",
                "documents": "documenti",
                "dt_created_by": "Creato da",
                "dt_last_update": "Ultimo aggiornamento di DataTourisme",
                "dynamic_translations_enabled": "traduzioni dinamiche abilitate",
                "email": "e-mail",
                "emails_must_different": "Entrambi gli account non possono avere lo stesso indirizzo email.",
                "employee": "dipendente",
                "end_date": "data di fine",
                "engaged_until": "fidanzato fino al",
                "engagement_period": "periodo di fidanzamento",
                "event": "evento",
                "excursion": "itinerario",
                "existing_user": "utente esistente",
                "expirable": "scadente",
                "expired_at": "Scaduto il",
                "expired_on": "data di fine soggiorno",
                "expiry": "scadenza",
                "expiry_date": "Data di scadenza",
                "extranet": "extranet",
                "fallback_locale": "impostazioni locali di riserva",
                "favorite": "itinerario preferito",
                "featured": "in primo piano",
                "file": "file",
                "file_name": "nome del file",
                "filter": "filtro",
                "financial": "finanziario",
                "first_name": "nome di battesimo",
                "formatted_address": "indirizzo formattato",
                "free_period": "periodo libero",
                "free_period_ended_at": "il periodo gratuito è terminato alle",
                "front": "davanti",
                "gallery": "galleria",
                "gdpr": "GDPR",
                "general": "Generale",
                "generated_conversions": "conversioni generate",
                "gross_amount": "importo lordo",
                "has_employees": "ha dipendenti",
                "hour": "ora",
                "hours": "ore",
                "id": "id",
                "image": "Immagine",
                "images": "immagini",
                "import_source": "fonte di importazione",
                "import_synchronizing": "Attiva l'aggiornamento automatico",
                "individual_first_identity_piece": "pezzo di identità",
                "individual_registration_document": "documento di registrazione",
                "individual_second_identity_piece": "secondo pezzo di identità",
                "information": "informazione",
                "institution": "istituzione",
                "institution_address": "indirizzo dell'istituto",
                "institution_id": "istituzione",
                "institution_title": "titolo di istituto",
                "interaction_type": "tipo di interazione",
                "invitation": "invito",
                "invitation_accepted": "invito accettato",
                "invitation_status": "stato dell'invito",
                "invited_at": "invitato a",
                "invited_by": "invitato da",
                "invitees": "invitati",
                "is_geocoding": "geolocalizzazione in corso",
                "iso_code": "Codice ISO",
                "items": "elementi",
                "key": "chiave",
                "kind": "Tipo",
                "label": "etichetta",
                "last_geoloc": "ultima geolocalizzazione",
                "last_logged_at": "ultimo accesso alle",
                "last_login_ip": "ultimo IP di accesso",
                "last_name": "cognome",
                "last_validated_at": "ultima convalida",
                "lat": "latitudine",
                "latitude": "latitudine",
                "line1": "Indirizzo Linea 1",
                "line2": "indirizzo 2",
                "link": "collegamento",
                "lng": "longitudine",
                "locale": "lingua",
                "login": "connessione",
                "logo": "logo",
                "longitude": "longitudine",
                "manipulations": "manipolazioni",
                "max_amount_per_checkout": "importo massimo per cassa",
                "measurement_system": "sistema di misura",
                "media": "media",
                "message": "Messaggio",
                "metadata": "metadati",
                "mime_type": "tipo MIME",
                "min_amount_per_checkout": "importo minimo per cassa",
                "misc": "varie",
                "model": "documentazione",
                "model_type": "tipo di registrazione",
                "name": "nome",
                "net_amount": "importo netto",
                "new_password": "nuova password",
                "new_password_confirmation": "nuova conferma della password",
                "next_payment_at": "prossimo pagamento",
                "nickname": "soprannome",
                "not_specified": "Non specificato",
                "not_validated_activities": "attività non convalidate",
                "notes": "ulteriori informazioni",
                "opening_hours": "Orari di apertura",
                "order_column": "ordine",
                "origin": "origine",
                "owner": "proprietario",
                "owner_email": "e-mail",
                "owner_first_name": "nome di battesimo",
                "owner_last_name": "cognome",
                "owner_phone": "telefono",
                "password": "parola d'ordine",
                "password_confirmation": "conferma password",
                "passwords_must_different": "Entrambi gli account non possono avere la stessa password.",
                "payable": "pagabile",
                "payee": "beneficiario",
                "payee_country": "Paese",
                "payee_email": "e-mail",
                "payee_id": "beneficiario",
                "payee_name": "nome del beneficiario",
                "payee_phone": "telefono",
                "payer": "pagatore",
                "payer_country": "Paese",
                "payer_email": "e-mail",
                "payer_id": "pagatore",
                "payer_name": "nome del pagatore",
                "payer_phone": "telefono",
                "payment_received": "pagamento ricevuto",
                "payment_service": "servizio di pagamento",
                "payment_service_account_id": "Conto di pagamento ID",
                "payment_service_transaction_id": "Transazione di pagamento ID",
                "payment_thanks": "pagamento grazie",
                "payment_type": "modalità di pagamento",
                "period": "periodo",
                "phone": "telefono",
                "plan": "piano",
                "point_of_interest": "punto d'interesse",
                "point_of_interest_categories": "categorie",
                "point_of_interest_category": "categoria",
                "point_of_interest_event_expired": "Evento scaduto",
                "points_of_interest": "punti di interesse",
                "points_of_interest_count": "contano i punti di interesse",
                "post_category": "categoria posta",
                "price": "prezzo",
                "price_excluding_taxes": "prezzo tasse escluse",
                "prices": "prezzi",
                "priority": "priorità",
                "privacy_policy": "politica sulla riservatezza",
                "privacy_policy_accepted": "informativa sulla privacy accettata",
                "privacy_policy_version": "versione dell'informativa sulla privacy",
                "private": "privato",
                "private_title": "titolo privato",
                "pro_file": "file professionale",
                "profile_picture": "Immagine del profilo",
                "properties": "proprietà",
                "properties_comparison": "proprietà aggiornate",
                "provider": "fornitore",
                "public": "pubblico",
                "public_id": "ID pubblico",
                "published_at": "pubblicato a",
                "quantity": "quantità",
                "quitted_at": "licenziato a",
                "radius": "raggio",
                "rates": "aliquote",
                "reason_details": "dettagli",
                "reason_type": "motivo",
                "recipient": "destinatario",
                "recipients": "destinatari",
                "recipients_alt": "viaggiatori registrati",
                "remember": "Ricordati di me",
                "removed_at": "rimosso a",
                "reports": "rapporti",
                "requested_at": "richiesto a",
                "reservation_created": "prenotazione creata",
                "reservation_date": "data",
                "reservation_time": "tempo",
                "resolved_at": "Risolto a",
                "responsive_images": "immagini reattive",
                "roles": "ruoli",
                "room_number": "numero di Camera",
                "scout_searchable": "indicizzabile",
                "searchable": "ricercabile",
                "sender": "mittente",
                "senders": "mittenti",
                "service": "servizio",
                "settings": "impostazioni",
                "share_created": "condivisione creata",
                "shareable": "condivisibile",
                "show_contact_email": "Contatto visibilità e-mail",
                "show_contact_phone": "Contattare la visibilità del telefono",
                "show_profile_picture": "Visibilità dell'avatar",
                "show_real_name": "Visibilità del nome e del cognome",
                "siret": "SIRET",
                "size": "misurare",
                "source": "fonte",
                "source_id": "ID della fonte",
                "specified": "Specificato",
                "starable": "annotato con le stelle",
                "stars": "stelle",
                "start_date": "data d'inizio",
                "state": "stato",
                "states": "stati",
                "static_translations_enabled": "traduzioni statiche abilitate",
                "status": "stato",
                "subject": "soggetto",
                "subject_id": "id soggetto",
                "subject_type": "tipo di soggetto",
                "summary": "riepilogo",
                "support_category": "categoria",
                "support_messages_count": "i messaggi di supporto contano",
                "support_request": "richiesta di supporto",
                "support_request_archived": "Richiesta di assistenza archiviata",
                "support_request_message_received": "Risposta ricevuta in una richiesta di supporto",
                "suspended_at": "sospeso a",
                "tag": "Etichetta",
                "tags": "Tag",
                "target": "bersaglio",
                "terms_condition_accepted": "termini e condizioni accettati",
                "terms_condition_version": "versione termini e condizioni",
                "terms_conditions": "Termini e Condizioni",
                "thanked": "ringraziato",
                "thanked_at": "grazie data",
                "thanks": "Grazie",
                "thanks_message": "messaggio di ringraziamento",
                "thanks_sent": "grazie inviato",
                "tipping_fees": "spese di mancia",
                "tips_enabled": "suggerimenti abilitati",
                "title": "titolo",
                "total_amount": "importo totale",
                "traveller": "viaggiatore",
                "traveller_folder": "cartella del viaggiatore",
                "traveller_folder_approved": "cartella del viaggiatore approvata",
                "traveller_folder_archived": "cartella del viaggiatore archiviata",
                "traveller_folder_pending": "cartella del viaggiatore in sospeso",
                "traveller_folder_thanks": "grazie per il soggiorno",
                "tutorial_state": "Stato dell'esercitazione",
                "type": "tipo",
                "updated_at": "aggiornato a",
                "user": "utente",
                "user_id": "utente",
                "user_report": "utente (che segnala)",
                "users": "utenti",
                "utm_campaign": "Campagna",
                "utm_medium": "medio",
                "utm_settings": "Parametri UTM",
                "utm_source": "Fonte",
                "uuid": "uuid",
                "validated_activities": "attività convalidate",
                "validated_at": "convalidato a",
                "value": "valore",
                "vat": "I.V.A",
                "vat_rate": "aliquota IVA",
                "version": "versione",
                "video": "video",
                "videos": "video",
                "website": "sito web",
                "zip": "cerniera lampo"
            },
            "before": " {attribute} deve essere una data precedente a {date}.",
            "before_or_equal": " {attribute} deve essere una data precedente o uguale a {date}.",
            "between": {
                "array": "L' {attribute} deve avere tra {min} e {max} elementi.",
                "file": "L' {attribute} deve essere compreso tra {min} e {max} kilobyte.",
                "numeric": "Il {attribute} deve essere compreso tra {min} e {max}.",
                "string": " {attribute} deve essere compreso tra {min} e {max} caratteri."
            },
            "boolean": "Il campo {attribute} deve essere vero o falso.",
            "confirmed": "La {attribute} non corrisponde.",
            "currency_scale": "Il numero di cifre di {attribute} dopo la virgola non deve superare {scale}.",
            "current_password": "L' {attribute} non corrisponde alla tua password.",
            "custom": {
                "attribute-name": {
                    "rule-name": "messaggio personalizzato"
                },
                "h-captcha-response": {
                    "HCaptcha": "Errore captcha! riprova più tardi o contatta l'amministratore del sito.",
                    "required": "Verifica di non essere un robot."
                }
            },
            "date": "La {attribute} non è una data valida.",
            "date_equals": "La {attribute} deve essere una data uguale a {date}.",
            "date_format": "L' {attribute} non corrisponde al formato {format}.",
            "different": " {attribute} e {other} devono essere diversi.",
            "digits": "Il {attribute} deve essere {digits} cifre.",
            "digits_between": " {attribute} deve essere compreso tra {min} e {max} cifre.",
            "dimensions": "L' {attribute} ha dimensioni dell'immagine non valide.",
            "distinct": "Il campo {attribute} ha un valore duplicato.",
            "email": "L' {attribute} deve essere un indirizzo email valido.",
            "ends_with": "Il {attribute} deve terminare con uno dei seguenti: {values}.",
            "exists": "L' {attribute} selezionato non è valido.",
            "file": "L' {attribute} deve essere un file.",
            "filled": "Il campo {attribute} deve avere un valore.",
            "gt": {
                "array": "L' {attribute} deve contenere più di {value} elementi.",
                "file": " {attribute} deve essere maggiore di {value} kilobyte.",
                "numeric": " {attribute} deve essere maggiore di {value}.",
                "string": " {attribute} deve essere maggiore di {value} caratteri."
            },
            "gte": {
                "array": "L' {attribute} deve avere {value} elementi o più.",
                "file": " {attribute} deve essere maggiore o uguale a {value} kilobyte.",
                "numeric": "La {attribute} deve essere maggiore o uguale a {value}.",
                "string": " {attribute} deve essere maggiore o uguale a {value} caratteri."
            },
            "image": "La {attribute} deve essere un'immagine.",
            "in": "L' {attribute} selezionato non è valido.",
            "in_array": "Il campo {attribute} non esiste in {other}.",
            "integer": " {attribute} deve essere un numero intero.",
            "ip": " {attribute} deve essere un indirizzo IP valido.",
            "ipv4": " {attribute} deve essere un indirizzo IPv4 valido.",
            "ipv6": " {attribute} deve essere un indirizzo IPv6 valido.",
            "json": " {attribute} deve essere una stringa JSON valida.",
            "lt": {
                "array": "L' {attribute} deve contenere meno di {value} elementi.",
                "file": " {attribute} deve essere inferiore a {value} kilobyte.",
                "numeric": " {attribute} deve essere minore di {value}.",
                "string": " {attribute} deve contenere meno di {value} caratteri."
            },
            "lte": {
                "array": "L' {attribute} non deve contenere più di {value} elementi.",
                "file": " {attribute} deve essere inferiore o uguale a {value} kilobyte.",
                "numeric": " {attribute} deve essere minore o uguale a {value}.",
                "string": " {attribute} deve essere minore o uguale a {value} caratteri."
            },
            "luhn": "{attribute} è un numero non valido.",
            "max": {
                "array": "L' {attribute} non può contenere più di {max} elementi.",
                "file": "L' {attribute} non può essere maggiore di {max} kilobyte.",
                "height": "{attribute} non deve superare {max} pixel di altezza.",
                "numeric": "La {attribute} non può essere maggiore di {max}.",
                "string": " {attribute} non può essere maggiore di {max} caratteri.",
                "width": "{attribute} non deve superare {max} pixel di larghezza."
            },
            "max_digits": "{attribute} non deve superare {max} cifre.",
            "mimes": " {attribute} deve avere un tipo valido.",
            "mimetypes": " {attribute} deve avere un tipo valido.",
            "min": {
                "array": "L' {attribute} deve avere almeno {min} elementi.",
                "file": "Il {attribute} deve essere almeno {min} kilobyte.",
                "numeric": "Il {attribute} deve essere almeno {min}.",
                "string": "Il {attribute} deve essere almeno {min} caratteri."
            },
            "min_digits": "{attribute} deve essere di almeno {min} cifre.",
            "not_in": "L' {attribute} selezionato non è valido.",
            "not_regex": "Il formato {attribute} non è valido.",
            "notification_setting": "Il formato {attribute} non è valido (il formato previsto è array[bool]).",
            "numeric": "La {attribute} deve essere un numero.",
            "password": "La password non è corretta.",
            "phone": " {attribute} deve essere un numero di telefono valido.",
            "present": "Il campo {attribute} deve essere presente.",
            "prohibited_if": "Il campo {attribute} è proibito quando {other} è {value}.",
            "prohibited_unless": "Il campo {attribute} è proibito a meno che {other} non sia in {values}.",
            "real_name_characters": ":{attribute} può contenere solo lettere, spazi, punti,\n        trattini o apostrofi.",
            "regex": "Il formato {attribute} non è valido.",
            "required": "Il campo {attribute} è obbligatorio.",
            "required_if": "Il campo {attribute} è obbligatorio quando {other} è {value}.",
            "required_unless": "Il campo {attribute} è obbligatorio a meno che {other} non sia in {values}.",
            "required_with": "Il campo {attribute} è obbligatorio quando è presente {values}.",
            "required_with_all": "Il campo {attribute} è obbligatorio quando sono presenti {values}.",
            "required_without": "Il campo {attribute} è obbligatorio quando {values} non è presente.",
            "required_without_all": "Il campo {attribute} è obbligatorio quando nessuno di {values} è presente.",
            "same": " {attribute} e {other} devono corrispondere.",
            "same_currency": "Tutti gli articoli devono utilizzare la stessa valuta.",
            "size": {
                "array": "L' {attribute} deve contenere {size} elementi.",
                "file": "L' {attribute} deve essere {size} kilobyte.",
                "numeric": "Il {attribute} deve essere {size}.",
                "string": "Il {attribute} deve essere {size} caratteri."
            },
            "starts_with": "Il {attribute} deve iniziare con uno dei seguenti: {values}.",
            "string": " {attribute} deve essere una stringa.",
            "tag_same_poi_category": "{attribute} dovrebbe appartenere tutti alla stessa categoria!",
            "timezone": "La {attribute} deve essere una zona valida.",
            "unique": "L' {attribute} è già stato preso.",
            "uploaded": "L' {attribute} non è riuscito a caricare.",
            "url": "Il formato {attribute} non è valido.",
            "uuid": " {attribute} deve essere un UUID valido."
        }
    },
    "ja": {
        "The {attribute} must be at least {length} characters and contain at least one number.": " {attribute} は少なくとも {length} 文字であり、少なくとも 1 つの数字が含まれている必要があります。",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": " {attribute} は少なくとも {length} 文字であり、少なくとも 1 つの特殊文字と 1 つの数字を含む必要があります。",
        "The {attribute} must be at least {length} characters and contain at least one special character.": " {attribute} は少なくとも {length} 文字であり、少なくとも 1 つの特殊文字が含まれている必要があります。",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": " {attribute} は少なくとも {length} 文字であり、少なくとも 1 つの大文字と 1 つの数字を含む必要があります。",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": " {attribute} は少なくとも {length} 文字であり、少なくとも 1 つの大文字と 1 つの特殊文字が含まれている必要があります。",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": " {attribute} は少なくとも {length} 文字であり、少なくとも 1 つの大文字、1 つの数字、および 1 つの特殊文字を含む必要があります。",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": " {attribute} は少なくとも {length} 文字であり、少なくとも 1 つの大文字が含まれている必要があります。",
        "The {attribute} must be at least {length} characters.": " {attribute} は少なくとも {length} 文字でなければなりません。",
        "The {attribute} must contain at least one letter.": " {attribute} には少なくとも 1 つの文字が含まれている必要があります。",
        "The {attribute} must contain at least one number.": " {attribute} には少なくとも 1 つの数値が含まれている必要があります。",
        "The {attribute} must contain at least one symbol.": " {attribute} には少なくとも 1 つのシンボルが含まれている必要があります。",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": " {attribute} には、少なくとも 1 つの大文字と 1 つの小文字が含まれている必要があります。",
        "auth": {
            "failed": "これらの認証情報は当社の記録と一致しません。このメールを {type} アカウントに使用していませんか?",
            "throttle": "ログイン試行が多すぎます。 {seconds} 秒後にもう一度お試しください。",
            "throttle_cascade": "ログイン試行が多すぎます。 {remaining} でもう一度お試しください。"
        },
        "documents": {
            "display": {
                "description": "QRコードと貴機関のロゴをリンクさせたA6フォーマットで表示します。",
                "title": "ディスプレイ - A6カード"
            },
            "qrcode": {
                "description": "QRコードだけで旅行者を貴施設に直接リンクできます。",
                "filename": "QRコード",
                "title": "QRコードをリンクする"
            }
        },
        "enums": {
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "作成した",
                "deleted": "削除されました",
                "updated": "更新しました",
                "validated": "検証済み"
            },
            "App\\Enums\\BinaryChoiceEnum": [
                "拒否されました",
                "承認されました"
            ],
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "association": "協会（または財団）",
                "company": "会社（公立・私立）",
                "individual": "個人",
                "undefined": "未定義"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "DE": "ドイツ",
                "ES": "スペイン",
                "FR": "フランス",
                "IT": "イタリア",
                "TH": "タイ",
                "undefined": "未定義"
            },
            "App\\Enums\\ContextsEnum": {
                "city_hall": "市役所",
                "hotel": "ホテル",
                "manager": "マネージャー",
                "tourist_office": "観光局",
                "traveller": "旅行者"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "ユーロ",
                "usd": "ドル"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Friday": "金曜日",
                "Monday": "月曜日",
                "Saturday": "土曜日",
                "Sunday": "日曜日",
                "Thursday": "木曜日",
                "Tuesday": "火曜日",
                "Wednesday": "水曜日"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "カスタマイズされた",
                "root": "私のテンプレート",
                "ugozer_app": "うごZERアプリ",
                "ugozer_pro": "ウーゴザープロ"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "インペリアル",
                "metric": "メトリック"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "アプリ内",
                "email": "Eメール"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "customer": "お客様",
                "partner": "相棒"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "easytransac": "EasyTransac",
                "paypal": "ペイパル",
                "stripe": "ストライプ"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "subscription": "サブスクリプション",
                "tip": "ヒント"
            },
            "App\\Enums\\PrivacySettingsEnum": {
                "contacts": "私の連絡先",
                "everyone": "みんな",
                "nobody": "誰でもない"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "illegal": "違法",
                "incorrect_data": "不正確な情報",
                "other": "他の",
                "shocking": "ショッキング",
                "spam": "スパム"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "朝食",
                "dinner": "夕食",
                "lunch": "ランチ",
                "null": "該当なし"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "フェイスブック",
                "google": "グーグル",
                "instagram": "インスタグラム",
                "linkedin": "リンクトイン",
                "tiktok": "チクタク",
                "twitter": "X（ツイッター）",
                "wechat": "微信"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "1ヶ月",
                "one year": "1年"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "1ヶ月",
                "one year": "1年",
                "six months": "6ヵ月",
                "three months": "3ヶ月"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "expired": "購読は終了しました",
                "free period ended": "無料期間は終了しました",
                "payment default": "支払いのデフォルト"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "アプリ",
                "extranet": "エクストラネット",
                "front": "フロント"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "アーカイブ済み",
                "canceled": "キャンセル",
                "pending_support": "保留中のサポート",
                "pending_user": "保留中のユーザー",
                "resolved": "解決済み"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "私の周り",
                "customized": "カスタマイズされた",
                "staying_hotel": "宿泊ホテル近く"
            },
            "App\\Enums\\UserRoleEnum": {
                "butler": "執事",
                "contributor": "投稿者",
                "hotelier": "ホテル経営者",
                "manager": "マネージャー",
                "moderator": "モデレータ",
                "superadmin": "スーパー管理者",
                "touristOffice": "観光局",
                "touristOfficeEmployee": "従業員へ",
                "traveller": "旅行者"
            }
        },
        "nova": {
            "lens": {
                "user": {
                    "user_origin": "ユーザーの起源"
                }
            },
            "menu": {
                "administration": "管理",
                "content": "コンテンツ",
                "excursions": "旅程",
                "import_poi": "POIのインポート",
                "miscellaneous": "その他",
                "parameters": "パラメーター",
                "pois": "興味がある点",
                "subscriptions": "定期購入",
                "support": "サポート",
                "users": "ユーザー"
            },
            "s3_private_download": {
                "link": {
                    "expiration": "リンクは 30 分後に期限切れになります。その場合は、ページを更新してください。",
                    "open": "ドキュメントを見る"
                },
                "no_document": "関連文書はありません",
                "not_found": "ドキュメントが S3 ドライブで見つかりませんでした..."
            },
            "user_menu": {
                "profile": "私のプロフィール"
            }
        },
        "pagination": {
            "go_page": "ページ {page} に移動",
            "next": "次 \"",
            "next_alt": "次",
            "of": "の",
            "pagination_navigation": "ページネーションのナビゲーション",
            "previous": "\" 前の",
            "previous_alt": "前の",
            "results": "結果",
            "showing": "表示中",
            "to": "に"
        },
        "passwords": {
            "reset": "あなたのパスワードはリセットされました！",
            "sent": "パスワードリセットリンクを電子メールで送信しました。",
            "throttled": "再試行する前にお待ちください。",
            "token": "このパスワード リセット トークンは無効です。",
            "user": "該当するユーザーはいません。"
        },
        "ttmt": {
            "account_deletion": {
                "confirmation": "開いたページで「<strong>削除</strong>」という単語を入力して削除を確認します。\n        [<strong>確認</strong>] をクリックします。",
                "content": {
                    "asking": "アカウント削除リクエストがあった場合、UgoZER はユーザーのアカウントとデータを削除します。\n            法的または規制上の要件、セキュリティ目的、詐欺行為のために保持する必要がある場合を除きます。\n            予防のため、またはユーザーのアカウントに関連する問題（未払い、未解決の請求や請求など）によるもの\n            紛争。",
                    "conservation": {
                        "link": "プライバシーポリシー。",
                        "part_1": "UgoZER は、に記載されている目的に必要な期間、ユーザー データを保持します。",
                        "part_2": "CNIL の推奨に従って、3 年間使用されなかったアカウントは削除されます。",
                        "part_3": "ユーザーはいつでも自分のアカウントの削除をリクエストできます。 UgoZER は、次のユーザー データを保持する場合があります。\n                法的または規制上の要件に従って、または本文書に記載されている理由による削除リクエスト\n                声明。"
                    },
                    "instructions": "UgoZER アカウントを削除したい場合は、以下の手順に従ってください。"
                },
                "logout": "ログアウトされ、アカウントと関連データはすぐに削除されます。",
                "pro": {
                    "instructions": {
                        "delete": "次に、[<strong>アカウントの削除] の前にある [<strong>削除</strong>] ボタンをクリックします。\n                </strong>、",
                        "profile": {
                            "link": "プロフィール",
                            "part_1": "あなたのところに行ってください",
                            "path": "(アバター > 私のプロフィールをクリック)、"
                        }
                    },
                    "subtitle": "プロフェッショナルアカウントを削除するには:",
                    "title": "プロフェッショナルアカウント"
                },
                "title": "アカウントの削除",
                "traveller": {
                    "instructions": {
                        "delete": "次に、[<strong>アカウントを削除しますか?] の前にある [<strong>削除</strong>] ボタンをクリックします。\n                </strong>",
                        "profile": {
                            "link": "プロフィール",
                            "part_1": "あなたのところに行ってください",
                            "path": "(ハンバーガーアイコンメニュー > 設定 > プロフィール)、"
                        }
                    },
                    "subtitle": "旅行者アカウントを削除するには:",
                    "title": "旅行者アカウント"
                }
            },
            "activity_logs": {
                "summary": {
                    "created": {
                        "intro": "件名は次のプロパティで作成されました。"
                    },
                    "deleted": {
                        "intro": "件名は次のプロパティで削除されました:"
                    },
                    "updated": {
                        "attribute_from_to": "{attribute} は「{old_value}」から「{new_value}」に更新されました",
                        "intro": "件名が更新されました:"
                    },
                    "validated": {
                        "intro": "件名は検証されました:"
                    }
                }
            },
            "auth": {
                "credential_verification": {
                    "intro": "資格情報を入力すると、確認リンクが送信されます。",
                    "intro2": "リンクにアクセスするとすぐに資格情報が更新されます。"
                },
                "expiration": {
                    "session_expired": "セッションの有効期限が切れました。再認証してください"
                },
                "extranet_register": {
                    "form": {
                        "choose_establishment": "施設のタイプを選択してください",
                        "form_title": "アカウントを作成",
                        "validate_registration": "登録を完了する"
                    },
                    "success": "正常に登録されました。確認リンクがメールに送信されました。"
                },
                "login": {
                    "already_logged": "あなたは既にログインしています",
                    "logging_in": "ログイン中...",
                    "success": "ログイン済み",
                    "use_email": "メールでログイン",
                    "use_social_networks": "ソーシャルネットワークを使う"
                },
                "logout": {
                    "logging_out": "ログアウト...",
                    "switch": "切り替える"
                },
                "messages": {
                    "continue": "を続行",
                    "extranet_here": "ここをクリック",
                    "have_account": "すでにアカウントをお持ちですか？",
                    "login": "ログイン",
                    "login_subtitle": "(登録またはログイン)",
                    "login_title": "ソーシャルネットワークの方が便利です",
                    "no_account": "まだアカウントをお持ちでない場合は、",
                    "or": "または",
                    "professional": "あなたは専門家ですか？",
                    "register": "登録する",
                    "register_mail": "電子メールで登録する",
                    "register_title": "登録してすべての機能のロックを解除する",
                    "reset_password": "パスワードを再設定する",
                    "send_magic_link": "パスワードなしのリンクを送信する"
                },
                "password_forgotten": {
                    "intro": "資格情報を入力すると、パスワードをリセットするためのリンクが送信されます。",
                    "submit": "リセットリンクを受け取る",
                    "success": {
                        "correct_credential": "正しい資格情報",
                        "email_1": "このアドレスが正しく登録されていれば、 {email} に電子メールが送信されます。",
                        "email_2": "このメールには、パスワードをリセットできるリンクが含まれています。",
                        "send_again": "もう一度送信",
                        "title": "パスワードリセットリンクが送信されました",
                        "wait_before": "再送信する前に {seconds} 秒待ってください"
                    }
                },
                "password_reset": {
                    "submit": "あなたのパスワードをリセット",
                    "success": "パスワードがリセットされました"
                },
                "register": {
                    "form": {
                        "hide_privacy_policy": "プライバシーポリシーを隠す",
                        "hide_terms_conditions": "利用規約を非表示にする",
                        "privacy_policy": "プライバシーポリシーに同意します",
                        "show_privacy_policy": "プライバシーポリシーを表示",
                        "show_terms_conditions": "利用規約を表示する",
                        "terms_conditions": "私は利用規約に同意します"
                    },
                    "staying_hotel": "登録すると、ホテルが手配します。\n            QR コードをスキャンしたところです。",
                    "success": {
                        "email_1": " {email} にメールが送信されます。",
                        "email_2": "この電子メールには、アドレスを確認して接続できるリンクが含まれています。",
                        "paired": {
                            "email_1": "2 通のメールが次のアドレスに送信されました",
                            "email_2": "各メールには、アドレスを確認し、関連付けられたアカウントに接続するためのリンクが含まれています。"
                        },
                        "title": "正常に登録されました"
                    }
                },
                "social_networks": {
                    "authenticate": "でログイン",
                    "facebook": "フェイスブック",
                    "google": "グーグル",
                    "no_email": "ソーシャル ネットワークに電子メールが関連付けられていないため、認証できません",
                    "twitter": "ツイッター"
                },
                "travel_assistant": "無料のトラベルアシスタント"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "銀行口座が提供されていません"
                    },
                    "form": {
                        "placeholders": {
                            "bank_city": "銀行支店の市区町村。",
                            "bank_name": "銀行名",
                            "bank_transfer_reason": "例: 「UgoZER のヒント」",
                            "bic": "BIC を入力してください",
                            "iban": "IBAN / BBAN を入力してください",
                            "payee_name": "受取人の名前または機関名",
                            "types": "IBAN/BBAN"
                        }
                    },
                    "lines": {
                        "no_account_yet": "あなたの銀行口座により、あなたの教育機関は UgoZER で支払いを受け取ることができます。"
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "amount": "額",
                    "amount_to_pay": "支払う金額",
                    "concierge": "コンシェルジュ",
                    "currency": "通貨",
                    "customer": "お客様",
                    "date": "日付",
                    "repartition_key": "配布キー",
                    "tip_sharing_form": "ヒント共有フォーム",
                    "tips_received": "受け取ったヒント",
                    "total_received": "受け取った合計"
                }
            },
            "breadcrumbs": {
                "changelogs": {
                    "changelogs": "変更履歴"
                },
                "claims": {
                    "claims": "請求"
                },
                "common": {
                    "add": "追加",
                    "claim": "請求",
                    "create": "新しい",
                    "create_f": "新しい",
                    "delete": "消去",
                    "download_pdf": "PDFをダウンロード",
                    "edit": "編集",
                    "report": "報告",
                    "share": "共有",
                    "show": "見せる"
                },
                "contacts": {
                    "contacts": "連絡先"
                },
                "cookie": {
                    "cookie": "Cookieの管理"
                },
                "credit": {
                    "credit": "クレジット"
                },
                "dashboard": {
                    "dashboard": "ダッシュボード"
                },
                "errors": {
                    "404": {
                        "label": "エラー404",
                        "title": "エラー 404 - 見つかりません"
                    }
                },
                "excursions": {
                    "create": "新しい旅程",
                    "excursions": "旅程"
                },
                "faq": {
                    "faq": "よくある質問"
                },
                "finances": {
                    "finances": "財政"
                },
                "institutions": {
                    "institution": "機関",
                    "institutions": "機関"
                },
                "institutions_employees": {
                    "institutions_employees": "私の従業員",
                    "invite": "従業員を招待する"
                },
                "interactions": {
                    "interactions": "インタラクション"
                },
                "invitations": {
                    "invitations": "招待状"
                },
                "notification_settings": {
                    "notification_settings": "通知設定"
                },
                "notifications": {
                    "notifications": "通知"
                },
                "partners": {
                    "customers": "お客様",
                    "partners": "パートナー"
                },
                "points_of_interest": {
                    "add_excursion": "旅程に追加する",
                    "points_of_interest": "興味がある点"
                },
                "privacy_policy": {
                    "privacy_policy": "プライバシーポリシー"
                },
                "privacy_settings": {
                    "privacy_settings": "プライバシー設定"
                },
                "reservations": {
                    "reservations": "予約"
                },
                "search": {
                    "search": "検索"
                },
                "subscriptions": {
                    "subscription": "サブスクリプション"
                },
                "support_messages": {
                    "add_message": "メッセージを追加",
                    "support_messages": "メッセージ"
                },
                "support_requests": {
                    "add_request": "新しいサポート リクエストを追加する",
                    "support_requests": "サポートリクエスト"
                },
                "terms_condition": {
                    "terms_condition": "利用規約"
                },
                "travellers": {
                    "folders": "旅行者フォルダー",
                    "travellers": "旅行者"
                },
                "tutorials": {
                    "begin": "はじめる"
                },
                "users": {
                    "edit_credential": "資格情報の編集",
                    "profile": "私のプロフィール",
                    "users": "ユーザー"
                }
            },
            "changelogs": {
                "archive": {
                    "success": "変更ログはアーカイブされています (対象となる場合)"
                },
                "draft": {
                    "success": "変更ログは草稿されています (適格な場合)"
                },
                "index": {
                    "sections": {
                        "all": "すべてのアップデート",
                        "last": "最後の更新"
                    }
                },
                "publish": {
                    "success": "変更ログは公開されています (対象となる場合)"
                },
                "show": {
                    "version": "バージョン"
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "申し立てに関する異議申し立てが作成されました"
                },
                "rejected": {
                    "success": "請求に関する異議申し立ては拒否されました"
                },
                "validated": {
                    "success": "請求の異議申し立てが検証されました"
                }
            },
            "claims": {
                "form_wizard": {
                    "documents": {
                        "DE": {
                            "association_legal_representative_identity_piece": "ライゼパス、パーソナルアウスヴァイス、総統シャイン (ノイエレ)\n                    MRZ-Zeilen によるバージョン) Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "association_statutes": "ヘンデル登録局",
                            "company_legal_representative_identity_piece": "ライゼパス、パーソナルアウスヴァイス、総統シャイン (ノイエレ)\n                    MRZ-Zeilen によるバージョン) Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "company_registration_document": "ヘンデル登録局",
                            "company_statutes": "Aktuelle und unterschriebene (von der Geschäftsführung) Gesellschaftverträge",
                            "individual_first_identity_piece": "ライゼパス、パーソナルアウスヴァイス、総統 (neuere Version mit)\n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_registration_document": "Gewerbeschein、Handlungsbevollmächtigung、Handelsregisterauszug、\n                    プロクラ、Steuernummer の帰属レター",
                            "individual_second_identity_piece": "ライゼパス、パーソナルアウスヴァイス、総統 (neuere Version mit)\n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)"
                        },
                        "ES": {
                            "association_legal_representative_identity_piece": "パサポルテ、国家識別文書、\n                    Permiso de conducir (バージョン reciente con líneas MRZ) o Permiso de residencia (solo si es de un)\n                    ヨーロッパ）",
                            "association_statutes": "社会制度の制定法",
                            "company_legal_representative_identity_piece": "パサポルテ、国家身分証明書、ペルミソ\n                    de conducir (バージョン reciente con líneas MRZ) o Permiso de residencia (solo si es de un país)\n                    ヨーロッパ)",
                            "company_registration_document": "Extracto del Registro Mercantil Central、Nota Simple del Registro\n                    mercantil local sellado o numerico、Extracto desde los Registraradores de España (情報\n                    General Mercantil) con fecha de validez de menos de 3 meses o Modelo 600 con fecha de validez\n                    de menos de 3 meses (ソロ・パラ・ソシエダ・シビル)",
                            "company_statutes": "社会制度の制定法",
                            "individual_first_identity_piece": "パサポルテ、国家身分証明書、管理者権限\n                    (MRZ のバージョン) o Permiso de residencia (ヨーロッパのソロ活動)",
                            "individual_registration_document": "Agencia Tributaria - 検閲証明書\n                    (アルタ・センサル)",
                            "individual_second_identity_piece": "パサポルテ、国家身分証明書、管理者権限\n                    (MRZ のバージョン) o Permiso de residencia (ヨーロッパのソロ活動)"
                        },
                        "FR": {
                            "association_legal_representative_identity_piece": "有効な有効性を保証する同一性のないもの\n                    協会の代表者 : 身分証明書 (正と裏)、パスポート、権利書\n                    セジュール (前後)、生体認証許可 (前後)",
                            "association_statutes": "協会法",
                            "company_legal_representative_identity_piece": "有効な有効性を保証する同一性のないもの\n                    社会法務代表者 : 身分証明書 (前後関係)、パスポート、身分証明書\n                    (前後)、生体認証許可 (前後)",
                            "company_registration_document": "KBIS の離脱を解除する",
                            "company_statutes": "法規",
                            "individual_first_identity_piece": "有効なアイデンティティの最高傑作 : カルテ\n                    d'identité (前後)、パスポート、保持権 (前後)、許可証\n                    バイオメトリック (前後)",
                            "individual_registration_document": "INSEE OU UN 文書の証明書を発行していません\n                    商務庁長官の息子の証人 (自動車起業家の実行者を支援する)\n                    une activitéCommerciale) ou auprès de la Chambre des métiers (自動起業家としての活動を続ける)\n                    職人的な活動)",
                            "individual_second_identity_piece": "有効な同一性を示す第 2 部分: カルテ\n                    d'identité (前後)、パスポート、保持権 (前後)、許可証\n                    (前後左右)、アンアンの強制日付、家族の情報の詳細\n                    市民連帯協定の登録申請"
                        },
                        "IT": {
                            "association_legal_representative_identity_piece": "パッサポルト、ナツィオナーレ身分証明書、ラ\n                    Patente di guida (versione Recente con linee MRZ) o Permesso di residenza (solo se di un paese)\n                    ヨーロッパ)",
                            "association_statutes": "タトゥー フィルマート ダル Legale Rappresentante (すべてのインテルノ デッラを含むものではありません)\n                    ヴィスラ・カメラレ",
                            "company_legal_representative_identity_piece": "パッサポルト、国家身分証明書、特許庁\n                    di guida (versione Recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "company_registration_document": "ヴィスラ・カメラレ",
                            "company_statutes": "法律上の法的規定 (すべてのインテルノ デッラを含むものではない)\n                    ヴィスーラ・カメラレ)",
                            "individual_first_identity_piece": "パッサポルト、国家身分証明書、特許情報\n                    (versione Recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)",
                            "individual_registration_document": "Estratto dal registro delle imprese (「Visura Camerale」) ディ メノ\n                    ディ 3 メシ",
                            "individual_second_identity_piece": "パッサポルト、国家身分証明書、特許情報\n                    (versione Recente con linee MRZ) o Permesso di residenza (solo se di un paese europeo)"
                        },
                        "TH": {
                            "association_legal_representative_identity_piece": "返信ยของสมาคม:\n                    บัตรประจำตัวประชาชน ัง)、\n                    หนังสือเดินทาง, ใบอนุญาตมีถิ่นที่อยู่ (ทั้งด) ้านหน้าและด้านหลัง)、\n                    ใบขับขี่ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านห (ลัง)",
                            "association_statutes": "กฎเกณฑ์ของสมาคม",
                            "company_legal_representative_identity_piece": "返信意味:\n                    บัตรประจำตัวประชาชน (ด้านหน้และด้านหลัง)、 นังสือเดินทาง、\n                    ใบอนุญาตอนุญาตมีถิ่น（ด้านด้านหน้าหลังหลังหลัง）、\n                    ใบขับขี่ไบโอเมตริกซ์ (ด้านหน้าและด้านหลัง)",
                            "company_registration_document": "KBIS KBIS",
                            "company_statutes": "สถานะต่างๆ",
                            "individual_first_identity_piece": "意味: บัตรประจำตั วประชาชน\n                    (ทั้งด้านหน้าและด้านหลัง)、หนังสือเดินทาง、ใบ意味ง)、\n                    ใบขับขี่ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านห (ลัง)",
                            "individual_registration_document": "意味: สารรับรองการจดทะเบียนกับหอการค้า\n                    (意味ชิงพาณิชย์)\n                    หรือกับหอการค้า (สำหรับผู้ประกอบอาชีพอิสระ (ที่ดำเนินกิจการเชิง์)\n                    กิจกรรมงานฝีมือ)",
                            "individual_second_identity_piece": "意味: บัตรประจำต ัวประชาชน\n                    (ด้านหน้าและด้านหลัง) ตมีถิ่นที่อยู่\n                    (ด้านหน้าและด้านหลัง) านหลัง)\n                    ประกาศภาษีครั้งสุดท้ายลงวันที่น้อยกว่าหม ่งปี สมุดบันทึกครอบครัวหรือใบเสร็จรับเงินส ำหรับการจดทะเบียน\n                    สนธิสัญญาความสามัคคีของพลเมือง"
                        }
                    },
                    "steps": {
                        "claim": "請求",
                        "context": "コンテクスト",
                        "create": "作成する",
                        "documents": "書類",
                        "info": "情報",
                        "search": "検索"
                    }
                },
                "missing_info": {
                    "success": "情報が不足しているため、申し立ては拒否されました"
                },
                "notice": {
                    "actions": {
                        "edit": "リクエストを変更してください"
                    },
                    "already_claimed": {
                        "description": "この興味深い地点はすでに主張されています。これがエラーだと思われる場合は、\n                以下の異議申し立てフォームに記入してください。",
                        "title": "興味のあるポイントはすでに主張されています。"
                    },
                    "find_or_new": {
                        "description": "以下のリストで興味のあるスポットを検索するか、新しいリストを作成します。",
                        "title": "POIを検索または作成する"
                    },
                    "missing-info": {
                        "infos": "下のボタンをクリックしてリクエストを完了してください。",
                        "state": "あなたの申し立ては現在追加情報または文書を待っています:",
                        "title": "追加情報を待っています"
                    },
                    "pending": {
                        "state": "あなたの請求リクエストは現在処理中です",
                        "title": "保留中の請求リクエスト"
                    },
                    "rejected": {
                        "state": "あなたの請求リクエストは次の理由により拒否されました:",
                        "title": "拒否された請求リクエスト"
                    }
                },
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "請求",
                            "poi": "興味のあるスポット",
                            "user": "ユーザー"
                        }
                    }
                },
                "rejected": {
                    "success": "申し立ては拒否されました"
                },
                "revoked": {
                    "success": "申し立ては取り消されました"
                },
                "states": {
                    "missing-info": "不足している情報",
                    "pending": "保留中",
                    "rejected": "拒否されました",
                    "revoked": "取り消されました",
                    "validated": "検証済み"
                },
                "update": {
                    "success": "主張が更新されました"
                },
                "valid_identity_pieces": "有効な身分証明書、パスポート、または運転免許証",
                "validated": {
                    "success": "主張は検証されました"
                }
            },
            "common": {
                "actions": {
                    "accept": "受け入れる",
                    "accept_contact": "連絡リクエストを受け入れる",
                    "add": "追加",
                    "add_contact": "連絡先に登録する",
                    "add_excursion": "旅程に追加",
                    "add_message": "メッセージを追加",
                    "apply": "適用する",
                    "approve": "承認する",
                    "archive": "アーカイブ",
                    "attach": "添付する",
                    "block_contact": "連絡先をブロックする",
                    "cancel": "キャンセル",
                    "check": "チェック",
                    "claim": "請求",
                    "close": "近い",
                    "configure": "構成、設定",
                    "confirm": "確認する",
                    "contest": "コンテスト",
                    "create": "作成する",
                    "decline": "拒否",
                    "decline_contact": "連絡リクエストを拒否する",
                    "delete": "消去",
                    "download": "ダウンロード",
                    "edit": "編集",
                    "file_preview": "プレビューファイル",
                    "filters": "フィルター",
                    "give_a_tip": "ヒントを与える",
                    "google_maps": "Googleマップで開く",
                    "help": "ヘルプ",
                    "hide": "隠れる",
                    "ignore": "無視する",
                    "interactions": "インタラクションを表示する",
                    "invite": "招待する",
                    "load_more": "もっと読み込む",
                    "login": "ログイン",
                    "logout": "ログアウト",
                    "manage": "管理",
                    "next": "次",
                    "pause": "一時停止",
                    "pay": "支払う",
                    "play": "遊ぶ",
                    "print": "印刷する",
                    "quit": "やめる",
                    "record": "記録",
                    "register": "登録する",
                    "report": "報告",
                    "request_to_join": "参加リクエスト",
                    "reset": "リセット",
                    "resume": "再開する",
                    "retry": "リトライ",
                    "return": "戻る",
                    "save": "保存",
                    "save_alt": "保存",
                    "search": "検索",
                    "see_plans": "プランを見る",
                    "see_website": "ウェブサイトを見る",
                    "send": "送信",
                    "send_link": "アプリのリンクを送信する",
                    "share": "共有",
                    "show": "見せる",
                    "start": "始める",
                    "stop": "停止",
                    "subscribe": "購読する",
                    "thank": "感謝",
                    "thank_for_stay": "ご宿泊いただきありがとうございます",
                    "traveller_show": "旅行者に相談する",
                    "unblock_contact": "連絡先のブロックを解除する",
                    "unblock_this_user": "このユーザーのブロックを解除する",
                    "update": "アップデート",
                    "validate": "検証"
                },
                "all": "全て",
                "and": "そして",
                "app_name": "ウーゴZER",
                "back_to_dashboard": "ダッシュボードに戻る",
                "back_to_home": "家に帰る",
                "characters_limit": "{length} / {limit} 文字",
                "counter": "{dividend} 対 {divisor}",
                "country": "国",
                "dashboard": "ダッシュボード",
                "errors": {
                    "deleted_user": "削除されたユーザー",
                    "internal": "何か問題が発生しました!",
                    "not_found": "見つかりません"
                },
                "false": "いいえ",
                "fast": "速い",
                "home": "家",
                "later": "後で",
                "loading": "読み込み中",
                "messages": {
                    "application_update": {
                        "if_ignore": "アップデートを無視した場合、次回アプリケーションを起動したときにアップデートが行われます。",
                        "new_version_available": "新しいバージョンが利用可能です。",
                        "updating": "更新中...",
                        "want_to_refresh": "アプリケーションを再起動して更新しますか?"
                    },
                    "clipboard": {
                        "error": "クリップボードへのコピー中にエラーが発生しました...",
                        "success": "クリップボードにコピーされました!"
                    },
                    "no_records": "記録が見つかりませんでした"
                },
                "missing_title": "タイトルがありません",
                "more_options": "より多くのオプション",
                "no": "いいえ",
                "none": "なし",
                "normal": "普通",
                "not_specified": "指定されていない",
                "online": {
                    "connected": "インターネットに再び接続しました",
                    "disconnected": "インターネットに接続されていません"
                },
                "oops": "おっと",
                "or": "または",
                "qr_code": "QRコード",
                "search": "検索",
                "slow": "遅い",
                "someone": "誰か",
                "sort": "選別",
                "tip": "ヒント",
                "tip_received": "チップを受け取りました (有効な場合)",
                "true": "はい",
                "units": {
                    "kilometer": "キロ |キロメートル",
                    "km": "km",
                    "mi": "私",
                    "mile": "マイル |マイル"
                },
                "welcome": "いらっしゃいませ",
                "yes": "はい"
            },
            "cookies": {
                "details": {
                    "content": "これは、UgoZER アプリケーションのこのセクションに配置された Cookie のリストです。\n            上記で定義されたカテゴリ:",
                    "table": {
                        "headers": {
                            "category": "利用区分",
                            "description": "説明",
                            "lifespan": "一生",
                            "mandatory": "必須",
                            "name": "Cookie 名 (またはパターン)"
                        },
                        "lines": {
                            "alphanumeric": {
                                "content": "セッションに関する情報が含まれます。",
                                "lifespan": "2時間",
                                "title": "[[{alnum}:]] (英数字の並び)"
                            },
                            "gmaps": {
                                "content": "Google マップの iframe を表示するために使用されます。",
                                "lifespan": "180～400日",
                                "title": "CONSENT、AEC、SOCS... (または他の google.com ドメイン Cookie)"
                            },
                            "remember": {
                                "content": "ユーザーの認証を維持するために使用されます。",
                                "lifespan": "400日",
                                "title": "remember_web_*"
                            },
                            "session": {
                                "content": "セッションに関する情報が含まれます。",
                                "lifespan": "2時間",
                                "title": "*_ugozer_session"
                            },
                            "tarteaucitron": {
                                "content": "ユーザーの Cookie 設定を保持できます。",
                                "lifespan": "365日",
                                "title": "タルトーシトロン"
                            },
                            "xsrf": {
                                "content": "アプリケーション (リクエスト、フォームなど) を保護できます。",
                                "lifespan": "2時間",
                                "title": "XSRF-トークン-*"
                            }
                        }
                    },
                    "title": "預けられたCookieの詳細"
                },
                "management": {
                    "content": {
                        "cta": "設定を変更する",
                        "line_1": "このサイトの一部の機能は Cookie の使用に依存しています。",
                        "line_2": "Cookie バナーは、ナビゲーション中に必要な場合にのみホームページに表示されません。\n                サイトの機能に寄託されます。",
                        "line_3": "以下のボタンをクリックして設定を変更できます。"
                    },
                    "title": "Cookieの管理"
                },
                "notice": {
                    "needed": "このセクションを表示するには、Cookie {name} を受け入れる必要があります。",
                    "refresh": "Cookieを受け入れても表示されない場合は、ページを更新してください。",
                    "title": "クッキー"
                },
                "services": {
                    "gmaps": "グーグルマップ",
                    "youtube": "YouTube"
                },
                "usage": {
                    "content": "Cookie は、UgoZER にアクセスしたときにお客様を認識し、好みを記憶し、\n            設定に合わせてパーソナライズされたエクスペリエンスを提供します。 Cookie は、次のユーザーとのやり取りにも使用されます。\n            UgoZER をより速く、より安全に。さらに、Cookie を使用すると、オンとオフの両方で広告を表示できるようになります。\n            UgoZER サイト。",
                    "table": {
                        "headers": {
                            "category": "利用区分",
                            "description": "説明"
                        },
                        "lines": {
                            "ads": {
                                "content": "当社は、UgoZER サイトの内外で関連する広告を表示するために Cookie を使用する場合があります。\n                        また、広告を見た人が後で訪問して広告を閲覧したかどうかを知るために Cookie を使用する場合もあります。\n                        広告主のサイトでのアクション (ホワイト ペーパーのダウンロードや購入など)。\n                        同様に、当社のパートナーは Cookie を使用して、当社が広告を表示したかどうか、またその表示方法を判断する場合があります。\n                        または、広告とのやり取りに関する情報を当社に提供してください。私たちも働くかもしれない\n                        パートナーと連携して、パートナーのサイトを訪問した後などに、UgoZER 内外で広告を表示します。\n                        サイトまたはアプリケーション。",
                                "title": "広告"
                            },
                            "authentication": {
                                "content": "UgoZER にサインインしている場合、Cookie は正しい情報を表示するのに役立ちます。\n                        あなたの体験をパーソナライズします。",
                                "title": "認証"
                            },
                            "security": {
                                "content": "当社はセキュリティ機能を有効にしてサポートし、検出を支援するために Cookie を使用します。\n                        悪意のある活動および当社の規約違反",
                                "cta": "利用規約。",
                                "title": "安全"
                            },
                            "services": {
                                "content": "Cookie は、機能、洞察、カスタマイズされたコンテンツを提供します。",
                                "title": "設定、機能、サービス"
                            }
                        }
                    },
                    "title": "Cookieは何に使用されますか?"
                }
            },
            "countries": {
                "AD": "アンドラ",
                "AE": "アラブ首長国連邦",
                "AF": "アフガニスタン",
                "AG": "アンティグア・バーブーダ",
                "AI": "アンギラ",
                "AL": "アルバニア",
                "AM": "アルメニア",
                "AO": "アンゴラ",
                "AQ": "南極大陸",
                "AR": "アルゼンチン",
                "AS": "アメリカ領サモア",
                "AT": "オーストリア",
                "AU": "オーストラリア",
                "AW": "アルバ",
                "AX": "オーランド諸島",
                "AZ": "アゼルバイジャン",
                "BA": "ボスニア・ヘルツェゴビナ",
                "BB": "バルバドス",
                "BD": "バングラデシュ",
                "BE": "ベルギー",
                "BF": "ブルキナファソ",
                "BG": "ブルガリア",
                "BH": "バーレーン",
                "BI": "ブルンジ",
                "BJ": "ベナン",
                "BL": "サン・バルテルミー島",
                "BM": "バミューダ",
                "BN": "ブルネイ",
                "BO": "ボリビア",
                "BQ": "カリブ海オランダ",
                "BR": "ブラジル",
                "BS": "バハマ",
                "BT": "ブータン",
                "BV": "ブーベ島",
                "BW": "ボツワナ",
                "BY": "ベラルーシ",
                "BZ": "ベリーズ",
                "CA": "カナダ",
                "CC": "ココス (キーリング) 諸島",
                "CD": "コンゴ - キンシャサ",
                "CF": "中央アフリカ共和国",
                "CG": "コンゴ - ブラザビル",
                "CH": "スイス",
                "CI": "コートジボワール",
                "CK": "クック諸島",
                "CL": "チリ",
                "CM": "カメルーン",
                "CN": "中国",
                "CO": "コロンビア",
                "CR": "コスタリカ",
                "CU": "キューバ",
                "CV": "カーボベルデ",
                "CW": "キュラソー",
                "CX": "クリスマス島",
                "CY": "キプロス",
                "CZ": "チェコ",
                "DE": "ドイツ",
                "DJ": "ジブチ",
                "DK": "デンマーク",
                "DM": "ドミニカ",
                "DO": "ドミニカ共和国",
                "DZ": "アルジェリア",
                "EC": "エクアドル",
                "EE": "エストニア",
                "EG": "エジプト",
                "EH": "西サハラ",
                "ER": "エリトリア",
                "ES": "スペイン",
                "ET": "エチオピア",
                "FI": "フィンランド",
                "FJ": "フィジー",
                "FK": "フォークランド諸島",
                "FM": "ミクロネシア",
                "FO": "フェロー諸島",
                "FR": "フランス",
                "GA": "ガボン",
                "GB": "イギリス",
                "GD": "グレナダ",
                "GE": "ジョージア",
                "GF": "フランス領ギアナ",
                "GG": "ガーンジー島",
                "GH": "ガーナ",
                "GI": "ジブラルタル",
                "GL": "グリーンランド",
                "GM": "ガンビア",
                "GN": "ギニア",
                "GP": "グアドループ",
                "GQ": "赤道ギニア",
                "GR": "ギリシャ",
                "GS": "サウスジョージア島とサウスサンドイッチ諸島",
                "GT": "グアテマラ",
                "GU": "グアム",
                "GW": "ギニアビサウ",
                "GY": "ガイアナ",
                "HK": "中国香港特別行政区",
                "HM": "ハード島とマクドナルド諸島",
                "HN": "ホンジュラス",
                "HR": "クロアチア",
                "HT": "ハイチ",
                "HU": "ハンガリー",
                "ID": "インドネシア",
                "IE": "アイルランド",
                "IL": "イスラエル",
                "IM": "マン島",
                "IN": "インド",
                "IO": "英領インド洋領土",
                "IQ": "イラク",
                "IR": "イラン",
                "IS": "アイスランド",
                "IT": "イタリア",
                "JE": "ジャージー",
                "JM": "ジャマイカ",
                "JO": "ヨルダン",
                "JP": "日本",
                "KE": "ケニア",
                "KG": "キルギス",
                "KH": "カンボジア",
                "KI": "キリバス",
                "KM": "コモロ",
                "KN": "セントクリストファー・ネイビス",
                "KP": "北朝鮮",
                "KR": "韓国",
                "KW": "クウェート",
                "KY": "ケイマン諸島",
                "KZ": "カザフスタン",
                "LA": "ラオス",
                "LB": "レバノン",
                "LC": "セントルシア",
                "LI": "リヒテンシュタイン",
                "LK": "スリランカ",
                "LR": "リベリア",
                "LS": "レソト",
                "LT": "リトアニア",
                "LU": "ルクセンブルク",
                "LV": "ラトビア",
                "LY": "リビア",
                "MA": "モロッコ",
                "MC": "モナコ",
                "MD": "モルドバ",
                "ME": "モンテネグロ",
                "MF": "セント・マーチン",
                "MG": "マダガスカル",
                "MH": "マーシャル諸島",
                "MK": "北マケドニア",
                "ML": "マリ",
                "MM": "ミャンマー (ビルマ)",
                "MN": "モンゴル",
                "MO": "中国マカオ特別行政区",
                "MP": "北マリアナ諸島",
                "MQ": "マルティニーク",
                "MR": "モーリタニア",
                "MS": "モントセラト",
                "MT": "マルタ",
                "MU": "モーリシャス",
                "MV": "モルディブ",
                "MW": "マラウイ",
                "MX": "メキシコ",
                "MY": "マレーシア",
                "MZ": "モザンビーク",
                "NA": "ナミビア",
                "NC": "ニューカレドニア",
                "NE": "ニジェール",
                "NF": "ノーフォーク島",
                "NG": "ナイジェリア",
                "NI": "ニカラグア",
                "NL": "オランダ",
                "NO": "ノルウェー",
                "NP": "ネパール",
                "NR": "ナウル",
                "NU": "ニウエ",
                "NZ": "ニュージーランド",
                "OM": "オマーン",
                "PA": "パナマ",
                "PE": "ペルー",
                "PF": "フランス領ポリネシア",
                "PG": "パプアニューギニア",
                "PH": "フィリピン",
                "PK": "パキスタン",
                "PL": "ポーランド",
                "PM": "サンピエール島・ミクロン島",
                "PN": "ピトケアン諸島",
                "PR": "プエルトリコ",
                "PS": "パレスチナ領土",
                "PT": "ポルトガル",
                "PW": "パラオ",
                "PY": "パラグアイ",
                "QA": "カタール",
                "RE": "再会",
                "RO": "ルーマニア",
                "RS": "セルビア",
                "RU": "ロシア",
                "RW": "ルワンダ",
                "SA": "サウジアラビア",
                "SB": "ソロモン諸島",
                "SC": "セーシェル",
                "SD": "スーダン",
                "SE": "スウェーデン",
                "SG": "シンガポール",
                "SH": "セントヘレナ",
                "SI": "スロベニア",
                "SJ": "スバールバル諸島とヤンマイエン島",
                "SK": "スロバキア",
                "SL": "シエラレオネ",
                "SM": "サンマリノ",
                "SN": "セネガル",
                "SO": "ソマリア",
                "SR": "スリナム",
                "SS": "南スーダン",
                "ST": "サントメ・プリンシペ",
                "SV": "エルサルバドル",
                "SX": "シント・マールテン島",
                "SY": "シリア",
                "SZ": "エスワティニ",
                "TC": "タークス・カイコス諸島",
                "TD": "チャド",
                "TF": "フランス領南方領土",
                "TG": "持ち帰り",
                "TH": "タイ",
                "TJ": "タジキスタン",
                "TK": "トケラウ",
                "TL": "東ティモール",
                "TM": "トルクメニスタン",
                "TN": "チュニジア",
                "TO": "トンガ",
                "TR": "七面鳥",
                "TT": "トリニダード・トバゴ",
                "TV": "ツバル",
                "TW": "台湾",
                "TZ": "タンザニア",
                "UA": "ウクライナ",
                "UG": "ウガンダ",
                "UM": "米国の離島",
                "US": "アメリカ",
                "UY": "ウルグアイ",
                "UZ": "ウズベキスタン",
                "VA": "バチカン市",
                "VC": "セントビンセントおよびグレナディーン諸島",
                "VE": "ベネズエラ",
                "VG": "イギリス領バージン諸島",
                "VI": "アメリカ領ヴァージン諸島",
                "VN": "ベトナム",
                "VU": "バヌアツ",
                "WF": "ウォリス＆フツナ",
                "WS": "サモア",
                "XK": "コソボ",
                "YE": "イエメン",
                "YT": "マヨット",
                "ZA": "南アフリカ",
                "ZM": "ザンビア",
                "ZW": "ジンバブエ"
            },
            "credits": {
                "images": "写真のクレジット"
            },
            "csv": {
                "common": {
                    "generating": "CSV ファイルを生成しています...",
                    "waiting": "お待ちください..."
                },
                "download": "CSVエクスポート"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "地元の専門家によるお気に入り"
                },
                "links": {
                    "closest_pois": "最近接点",
                    "excursions": "旅程と散歩",
                    "shares": "株式"
                },
                "no_dashboard": {
                    "contact": {
                        "link": "お問い合わせ。",
                        "text": "これが間違いだと思われる場合は、遠慮せずに"
                    },
                    "content": "あなたのロールにダッシュボードが設定されていないか、管理者として接続されています。",
                    "title": "利用可能なダッシュボードがありません"
                },
                "points_of_interest": {
                    "categories": "興味のあるスポットのカテゴリ"
                }
            },
            "documents": {
                "display": {
                    "description": "QRコードと貴機関のロゴをリンクさせたA6フォーマットで表示します。",
                    "title": "ディスプレイ - A6カード"
                },
                "qrcode": {
                    "description": "QRコードだけで旅行者を貴施設に直接リンクできます。",
                    "filename": "QRコード",
                    "title": "QRコードをリンクする"
                }
            },
            "easy_transac": {
                "account": {
                    "create": {
                        "button": "EasyTransac アカウントを作成する",
                        "fail": "EasyTransac への接続に失敗しました。後でもう一度試してください。",
                        "success": "EasyTransac アカウントが正常に作成されました"
                    },
                    "show": {
                        "account_usage": "EasyTransac アカウントを使用すると、教育機関は UgoZER で支払いを受け取ることができます。",
                        "can_receive_payments": "顧客はあなたの施設にチップを渡すことができるようになりました\n                コンシェルジュの UgoZER プロフィールから。",
                        "can_retrieve_payments": {
                            "description": "順番に、要求された情報を EasyTransac アカウントに入力する必要があります\n                    アカウント内の利用可能な資金を使用するため。",
                            "title": "EasyTransac アカウントが不完全です"
                        },
                        "cannot_receive_payments": "支払いを受け取ることができません",
                        "credentials_sent_mail": "EasyTransac 資格情報は電子メールで送信されました。",
                        "fee_percentage": "受け取る支払いごとに手数料が適用されます。",
                        "go_to_easy_transac": "EasyTransac ダッシュボードにアクセスする",
                        "must_subscribe": "EasyTransac アカウントを作成するには、購読する必要があります。",
                        "no_account_yet": "EasyTransac アカウントをまだ持っていません。",
                        "should_complete_kyc": "EasyTransac から要求された書類をお送りください。\n                支払いを受け取るため。"
                    }
                },
                "easy_transac": "EasyTransac"
            },
            "exceptions": {
                "default": "エラーが発生しました",
                "error_texts": {
                    "403": {
                        "link": {
                            "title": "にアクセスするとよいでしょう",
                            "to_app_label": "旅行者のセクション？",
                            "to_extranet_label": "専門部門？"
                        },
                        "message": "このページへのアクセス権がありません。",
                        "title": "おっとっと！アクセス禁止。"
                    },
                    "credential_not_verified": {
                        "title": "資格情報が検証されていません",
                        "verify_mail": "メールの確認リンクを受け取る",
                        "verify_phone": "電話番号の確認リンクを受け取る"
                    },
                    "opening_hours": {
                        "invalid": "指定された値は、OpenHours インスタンスではありません。"
                    },
                    "password_missing": {
                        "app": "パスワードを設定してください。",
                        "extranet": "アプリケーションにアクセスするにはパスワードを設定してください。"
                    }
                },
                "spam_detected": "スパムが検出されました"
            },
            "excursions": {
                "destroy": {
                    "confirm": "この旅程 {excursion_name} を削除してもよろしいですか?",
                    "success": "旅程が削除されました"
                },
                "direction": {
                    "bicycling": "自転車に乗る",
                    "display_mode": "ディスプレイモード",
                    "driving": "運転",
                    "walking": "歩く"
                },
                "form": {
                    "all_points_of_interest": "すべての観光スポット",
                    "create_new_point_of_interest": "新しい観光スポットを作成する",
                    "existing_excursions": "既存の旅程",
                    "from_existing_excursions": "既存の旅程から",
                    "order_hint": "興味のあるポイントの順序は、訪問の順序に対応しています。",
                    "points_of_interest": "興味がある点",
                    "select_from_points_of_interest": "興味のあるスポットから選ぶ",
                    "your_excursion": "あなたの旅程"
                },
                "index": {
                    "alert": {
                        "content": "ルートの表示のみが可能です。旅程を作成または共有したい場合は、次のアカウントでサインインする必要があります。\n                <strong>コンシェルジュ/受付係のアカウント</strong>",
                        "title": "旅程は要相談"
                    },
                    "no_results": "まだ旅程がありません",
                    "no_results_here": "検索エリアに利用できる旅程はありません。",
                    "read_only": "相談のみ",
                    "search": "旅程を検索する",
                    "try_more_filters": "検索範囲を広げたり、旅程を作成したりできます。",
                    "your_excursions": "あなたの旅程"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "旅程",
                            "pois": "興味がある点"
                        }
                    }
                },
                "search": {
                    "no_results": "旅程が見つかりません"
                },
                "share": {
                    "contains_private": "この旅程にはプライベートの興味深いスポットが含まれています。シェアに含まれない場合があります。",
                    "heading": "この旅程を共有する",
                    "select_which_contacts": "旅程「{excursion_title}」を共有したい連絡先を選択してください",
                    "select_which_users": "旅程「{excursion_title}」を共有するユーザーを選択するか、次のように入力します\n            新しい旅行者を招待するための電子メール アドレス",
                    "select_which_users_alt": "共有したい人の電子メール アドレスを入力します。\n            「{excursion_title}」の旅程。",
                    "success": "旅程が共有されました！"
                },
                "store": {
                    "create": "旅程を作成する",
                    "heading": "旅程を作成する",
                    "success": "旅程が作成されました"
                },
                "update": {
                    "heading": "この旅程を更新する",
                    "success": "旅程が更新されました"
                }
            },
            "extranet": {
                "dashboard": {
                    "last_claims": {
                        "none": "まだ申し立てがありません。",
                        "see_all": "すべての主張を確認する",
                        "see_index": "クレームページに移動します"
                    },
                    "last_payments": {
                        "none": "まだ支払いがありません。",
                        "see_all": "すべての支払いを確認する",
                        "see_index": "支払いページに移動します"
                    },
                    "last_reservations": {
                        "none": "まだ予約がありません。"
                    },
                    "last_traveller_folders": {
                        "none": "あなたにはまだ旅行者がいません。",
                        "see_all": "すべての旅行者を表示",
                        "take_care": "旅行者の世話をする"
                    },
                    "manager": {
                        "sections": {
                            "butlers_institutions": "私の教育機関のコンシェルジュ",
                            "butlers_interactions": "インタラクションを備えたコンシェルジュ"
                        }
                    },
                    "sections": {
                        "last_claims": "あなたの最後の主張",
                        "last_payments": "最後の支払い",
                        "last_reservations": "最後の予約",
                        "last_travellers": "あなたの最後の旅行者",
                        "search_butler": "コンシェルジュを検索する",
                        "stats": "統計"
                    },
                    "stats": {
                        "butlers": "コンシェルジュ|コンシェルジュ",
                        "employees": "従業員|従業員",
                        "excursions": "旅程|旅程",
                        "folders": "フォルダ|フォルダ",
                        "institutions": "連携機関|連携機関",
                        "processing_claims": "クレームの処理|クレームの処理",
                        "reservations": "予約|予約"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "従業員を招待する",
                        "new-hotelier": "受付アカウントを作成する",
                        "new-tourist-office": "デスクアカウントを作成する"
                    }
                },
                "excursions": {
                    "actions": {
                        "new": "旅程を作成する"
                    },
                    "sections": {
                        "excursions": "旅程"
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "カテゴリー"
                    }
                },
                "filters": {
                    "closed": "フィルターを閉じる",
                    "open": "フィルターを開く"
                },
                "finances": {
                    "alerts": {
                        "easytransac": {
                            "cta": "私のプロフィールにアクセスして EasyTransac アカウントを作成してください",
                            "description": "EasyTransac アカウントをまだ作成していません。したがって、支払いを受け取ることはできません。",
                            "title": "EasyTransac アカウントが存在しません。"
                        }
                    },
                    "sections": {
                        "issued_payments": "発行された支払い",
                        "received_by_institution_payments": "所属機関が受け取った支払い",
                        "received_payments": "支払いの受領"
                    }
                },
                "institution": {
                    "messages": {
                        "no_records": "現在リンクされている機関はありません"
                    },
                    "sections": {
                        "butler": {
                            "institution": "私の教育機関"
                        },
                        "manager": {
                            "institution": "私の教育機関"
                        }
                    }
                },
                "navbar": {
                    "contact": "お問い合わせ",
                    "contacts": "連絡先",
                    "dashboard": "ダッシュボード",
                    "excursions": "旅程",
                    "faq": "よくある質問",
                    "finances": "財政",
                    "invitations": "招待状",
                    "my_employees": "私の従業員",
                    "my_institution": "私の教育機関",
                    "my_institutions": "私の教育機関",
                    "points_of_interest": "興味がある点",
                    "travellers": "旅行者",
                    "tutorials": "始める",
                    "users": "ユーザー"
                },
                "pages": {
                    "excursions": {
                        "edit": "旅程を編集する",
                        "new": "新しい旅程"
                    },
                    "folders": {
                        "edit": "旅行者フォルダーを編集する",
                        "new": "新しい旅行者フォルダー"
                    },
                    "institutions": {
                        "new": "機関をリンクする"
                    },
                    "pois": {
                        "edit": "興味のあるスポットを編集する",
                        "new": "新しい名所"
                    },
                    "profile": {
                        "edit": "プロファイル編集"
                    },
                    "reservations": {
                        "edit": "予約の編集",
                        "new": "新規予約"
                    }
                },
                "points_of_interest": {
                    "actions": {
                        "new": "興味のある場所を作成する"
                    },
                    "sections": {
                        "points_of_interest": "興味がある点"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "現在FAQはありません"
                    }
                },
                "professional_section": "専門部門",
                "profile": {
                    "sections": {
                        "currency": "通貨",
                        "delete_account": "アカウントの削除",
                        "infos": "一般情報",
                        "locale": "言語",
                        "notification_settings": "通知設定",
                        "payment": "支払い",
                        "privacy_settings": "プライバシー設定",
                        "shares": "株式"
                    }
                },
                "support_messages": {
                    "actions": {
                        "back": "サポートリクエストに戻る",
                        "new": "新しいメッセージを追加"
                    },
                    "sections": {
                        "support_messages": "メッセージ"
                    }
                },
                "support_requests": {
                    "actions": {
                        "back": "戻る",
                        "new": "サポートリクエストを作成する"
                    },
                    "sections": {
                        "support_requests": "サポートリクエスト"
                    }
                },
                "travellers": {
                    "buttons": {
                        "new_folder": "新しい旅行者フォルダーを追加"
                    },
                    "sections": {
                        "folders": "私の旅行者フォルダー"
                    }
                },
                "user_dropdown": {
                    "changelogs": "変更履歴",
                    "cookies": "Cookieの管理",
                    "credits": "クレジット",
                    "customers": "お客様",
                    "partners": "パートナー",
                    "privacy_policy": "プライバシーポリシー",
                    "profile": "私のプロフィール",
                    "sign_out": "サインアウト",
                    "subscription": "サブスクリプション",
                    "terms_condition": "利用規約"
                }
            },
            "form": {
                "file": {
                    "current_file": "実際のファイル: {name}"
                },
                "hints": {
                    "auth": {
                        "register": {
                            "email": "このメール アドレスは、UgoZER アカウントにリンクされていてはなりません",
                            "employee": {
                                "not_duplicate_email": "電子メール アドレスは、",
                                "not_duplicate_password": "パスワードは、"
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "カスタマイズ: コンシェルジュが作成した旅程\n                        特定の旅行者に対して",
                                "root": "私のテンプレート: 必見の見どころで構成される「すぐに共有できる」旅程、\n                        またはテーマごとの興味のあるポイント\n                        （例：旅程によく含まれるレストランのリスト）\n                        新しい旅程を作成するために選択する必要があります。",
                                "ugozer_app": "UgoZERアプリ：UgoZERが作成・提案する旅程\n                        そして旅行者が利用できるようになりました。",
                                "ugozer_pro": "UgoZER Pro: UgoZER が作成および提案する旅程\n                        専門家が作業を容易にするために利用できるようになります。"
                            },
                            "pro": {
                                "customized": "特定の旅行者向けに作成された旅程",
                                "root": "必見の見どころで構成される「すぐに共有できる」旅程、\n                        またはテーマごとの興味のあるポイント\n                        （例：旅程によく含まれるレストランのリスト）\n                        新しい旅程を作成するために選択する必要があります。",
                                "ugozer": "UgoZERが作成および提案した旅程。"
                            }
                        },
                        "points_of_interest_search": "興味のあるスポットを検索して選択し、リストに追加します"
                    },
                    "excursions_filters": {
                        "radius": "この半径内に少なくとも 1 つの観光スポットがある旅程のみが表示されます。"
                    },
                    "geoloc_setting": {
                        "filter_header": "国、地域、都市、または近隣地域を検索します",
                        "kind": {
                            "admin": {
                                "around_me": "私の周囲: 地理位置情報が決定されます\n                        ユーザーのブラウザによって。",
                                "customized": "カスタマイズ: ユーザーは手動で場所を検索できます。",
                                "staying_hotel": "宿泊ホテル: 地理位置情報が取得されます\n                        旅行者が滞在しているホテルから。"
                            },
                            "app": {
                                "around_me": "地理位置情報はあなたの位置から行われます。",
                                "customized": "地理位置情報は、選択した場所から実行されます。",
                                "staying_hotel": "地理位置情報は、滞在しているホテルから取得されます: {title}。"
                            }
                        }
                    },
                    "locale": {
                        "dynamic_translations_enabled": "ユーザーがリソースを表示または翻訳できるようにします\n                (興味のある場所、旅程など) その言語で",
                        "fallback_locale": "タイトルを第二言語で（説明の前に）自動的に表示します。\n                POI ファイル。",
                        "static_translations_enabled": "ユーザーがアプリケーションを表示するためにこの言語を選択できるようにします\n                (ソースコードに翻訳が追加されている必要があります)"
                    },
                    "permission": {
                        "name": "ソースコード内で使用される名前"
                    },
                    "plan": {
                        "countries": "すべての国でプランを利用できるようにするには、空のままにします",
                        "information": "計画に関する追加情報 (前提条件、条件など)",
                        "name": "この名前は潜在的な購読者に表示されます\n                サブスクリプションを選択するとき",
                        "private_title": "このタイトルは管理者のみに表示されます"
                    },
                    "plan_price": {
                        "description": "この説明は潜在的な購読者に表示されます\n                サブスクリプションを選択するとき",
                        "price": "ユーロで入力してください",
                        "private_title": "このタイトルは管理者のみに表示されます"
                    },
                    "point_of_interest": {
                        "audio_refreshing_1": "オーディオ ファイルの更新を有効にすると、オーディオ ファイルが自動的に生成されます。\n                興味のある場所の言語 (: language)。",
                        "audio_refreshing_2": "興味のある場所のタイトルと住所が記載されます。",
                        "audio_refreshing_3": "言語、タイトルが変更されるとすぐに更新されます\n                または興味のある地点の住所が更新されます。",
                        "audio_refreshing_4": "自分で音声ファイルを追加または録音した場合、更新は無効になります。\n                または、生成された音声ファイルを削除した場合。",
                        "cant_have_employees": "ここには、次のようなカテゴリに関連付けられた施設のみが表示されます。\n                従業員",
                        "expiry_date": "興味のあるポイントは {date} で期限切れになります",
                        "expiry_date_details_1": "あなたの興味のあるポイントは「{category}」カテゴリの一部です。",
                        "expiry_date_details_2": "したがって、 {date} が最後に入力した日付であるため、有効期限は {date} になります。",
                        "expiry_date_details_3": "この日から、あなたの興味のあるポイントは 21 日間残ります\n                自動的に削除される前に。。",
                        "expiry_date_details_4": "いつでも新しい日付を追加できます\n                有効期限を延期するか、日付を指定しないで関心のあるポイントが期限切れにならないようにします。",
                        "images": "画像は選択した順序で興味のある場所のギャラリーに表示されます。\n                ドラッグして並べ替えることができます。",
                        "is_geocoding": "地理位置情報が正しく入力されても地図が表示されない場合は、無効にします。",
                        "line1": "探している場所の住所または名前を入力できます。から選択してください\n                住所を自動入力するリスト",
                        "locale": "このスポットで話されている主な言語",
                        "opening_dates": "スケジュールが任意の日付に適用される場合は空白のままにしてください",
                        "opening_days": "スケジュールがいずれかの日に適用される場合は、何も選択しないでください",
                        "opening_hours": "興味のあるポイントがいつでも開いている場合は、何も追加しないでください",
                        "sync_1": "自動更新を有効にすると、 {import_source} からデータを取得できるようになります。\n                輸入",
                        "sync_2": "手動で入力した内容は上書きされます"
                    },
                    "points_of_interest_filters": {
                        "order_distance": "このオプションを選択するには、距離フィルターを選択してください",
                        "order_relevance": "このオプションを選択するには、検索キーワードを入力してください",
                        "radius": "この半径内にある興味のある地点のみが表示されます。"
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "butler": "どのオプションを選択しても、姓と名は同じです。\n                    教育機関のマネージャーに表示されたままになります",
                            "traveller": "どのオプションを選択しても、姓と名は同じです。\n                  滞在しているホテルのコンシェルジュに表示されたままになります。"
                        }
                    },
                    "profile": {
                        "contact_email": "このメールはあなたの公開プロフィールに表示できます",
                        "contact_phone": "この電話はあなたの公開プロフィールに表示できます",
                        "name": "ニックネームは公開されるため、アプリケーションの他のユーザーがあなたを簡単に識別できるようになります。",
                        "password_template": {
                            "digit": "1桁",
                            "length": "×1文字",
                            "lowercase": "小文字1文字",
                            "uppercase": "大文字1文字"
                        },
                        "password_unchanged": "変更しない場合は空白のままにします"
                    },
                    "role": {
                        "description": "ロールの説明は、ユーザーがロールを選択するときに表示されます。\n                専門セクションに登録してください。",
                        "name": "ロールのコード名。ユーザーには表示されません。"
                    },
                    "support_request": {
                        "support_categories_search": "カテゴリを検索して選択します"
                    },
                    "translatable": {
                        "pro_global": "このフォームの 1 つ以上のフィールドは、さまざまな言語で入力できます。",
                        "pro_global_2": "特定の言語で入力されていないフィールドは自動的に翻訳されます。",
                        "pro_global_title": "多言語入力"
                    },
                    "traveller_folder": {
                        "expired_on": "デフォルトでは、終了日は 1 週間に設定されています"
                    },
                    "user": {
                        "bank_account_number": "送金に使用する口座番号を入力してください"
                    }
                },
                "labels": {
                    "geoloc_setting": {
                        "distance_filter": "距離フィルター",
                        "limit_to_a_radius": "半径に制限する",
                        "limit_to_a_radius_from_center": "中心からの半径に制限",
                        "measure_unit": "測定単位",
                        "other_filters": "他のフィルター",
                        "where_does_the_traveller_go": "旅行者はどこへ行きますか？",
                        "where_would_you_like_to_go": "どこに行きたい？",
                        "within_a_radius": "半径内で"
                    },
                    "payment": {
                        "payable_type": "支払いにリンクされている"
                    },
                    "plan": {
                        "authorized_roles": "許可された役割",
                        "tipping_fees": "チップ手数料（パーセント）"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "税抜価格"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "音声ファイルを自動的に更新します",
                        "opening": "オープニング",
                        "opening_date": "の上",
                        "opening_date_from": "から",
                        "opening_date_range": "終了日を指定する",
                        "opening_date_to": "に",
                        "opening_hours": "で",
                        "opening_hours_from": "から",
                        "opening_hours_range": "終了時間を指定する",
                        "opening_hours_to": "に",
                        "opening_schedule_add": "日または時間を指定する",
                        "opening_schedule_add_alt": "時間を指定する",
                        "opening_specification_add": "ピリオドを追加する"
                    },
                    "points_of_interest_filters": {
                        "geoloc": "場所",
                        "order_distance": "距離によって",
                        "order_relevance": "関連性別（検索キーワード）",
                        "sort": "選別",
                        "tags": "リファイン"
                    },
                    "register": {
                        "communication_consent": "オファーなどのパーソナライズされたコミュニケーションを受け取りたいのですが、\n                UgoZERからのプロモーション情報や旅行関連商品を電子メールで配信します。",
                        "privacy_policy": {
                            "part1": "に同意します",
                            "part2": "プライバシーポリシー"
                        },
                        "terms_conditions": {
                            "part1": "に同意します",
                            "part2": "利用規約"
                        }
                    },
                    "support_category": {
                        "title": "カテゴリを選択"
                    },
                    "support_message": {
                        "content": "メッセージ",
                        "email": "電子メールアドレス"
                    },
                    "support_request": {
                        "title": "リクエストの件名"
                    },
                    "traveller_invitation": {
                        "locale": "この旅行者が話す言語"
                    },
                    "user": {
                        "credential": "Eメール",
                        "email": "Eメール",
                        "image": {
                            "_destroy": "プロフィール写真を削除する"
                        },
                        "new_password": "新しいパスワード",
                        "notes": "さらに詳しい情報"
                    },
                    "vat_rate": {
                        "vat_rate": "VAT 税率 (パーセンテージ)"
                    }
                },
                "placeholders": {
                    "audio_file_name": "音声ファイル名",
                    "name": "ニックネーム",
                    "support_message": {
                        "create_message": "サポートリクエストのメッセージ"
                    }
                },
                "select": {
                    "no_options": "オプションなし",
                    "no_results": "申し訳ありませんが、一致するオプションはありません。",
                    "type_to_search": "検索..."
                },
                "titles": {
                    "verifications": "自分の情報を確認する"
                }
            },
            "front": {
                "access_pro": "プロフェッショナルアクセスに移動します",
                "app": "トラベラーズアプリ",
                "brand_name": "ウーゴZER",
                "captcha": {
                    "and": "そして",
                    "apply": "適用する。",
                    "privacy_policy": "プライバシーポリシー",
                    "protected": "このサイトは hCaptcha とその",
                    "terms_service": "利用規約"
                },
                "contact": {
                    "cancel": "サポートリクエストをキャンセルする",
                    "contact": "接触",
                    "send": "送信"
                },
                "cookies": {
                    "title": "Cookieの管理"
                },
                "customers": {
                    "see_customers": "当社の顧客を見る",
                    "title": "彼らは私たちを信頼しています"
                },
                "footer": {
                    "account_deletion": "アカウントの削除",
                    "app": "トラベラーズアプリ",
                    "contact": "接触",
                    "cookies": "Cookieの管理",
                    "copyright": "© {year} UgoZER - 無断複写・転載を禁じます。",
                    "facebook": "フェイスブック",
                    "faq": "よくある質問",
                    "home": "家",
                    "instagram": "インスタグラム",
                    "legal_notice": "法的通知",
                    "linkedin": "リンクトイン",
                    "privacy_policy": "プライバシーポリシー",
                    "pro": "専門的なアクセス",
                    "sitemap": "サイトマップ",
                    "terms_conditions": "利用規約",
                    "twitter": "ツイッター"
                },
                "header": {
                    "app": "トラベラーズアプリ",
                    "contact": "接触",
                    "faq": "よくある質問",
                    "home": "家",
                    "pro": "専門的なアクセス",
                    "you_are": "あなたは"
                },
                "intro": {
                    "intro_1": "UgoZERを使えば、お気に入りのアドレスを多言語ファイルでいつでもスマートフォンに保存でき、\n            クリックするだけで音声や読み取りが可能になります。",
                    "intro_2": "海外にいる場合は、タクシーの運転手や他の人が目的地を簡単に理解できるようにしてください。",
                    "intro_3": "受付係、ホテルマネージャー、ツアープログラムの企画・提供。顧客と共有する\n            ウーゴZERで。",
                    "intro_title_pro": "プロフェッショナル",
                    "intro_title_traveller": "旅行者",
                    "title_homepage": "多言語観光ガイド、無料。"
                },
                "navigation": {
                    "next": "次",
                    "previous": "前の"
                },
                "outro": {
                    "description": "2.0 で旅行する"
                },
                "partners": {
                    "no_records": "現在パートナーはいません",
                    "read_more": "続きを読む",
                    "see_partners": "パートナーを見る",
                    "title": "パートナー"
                },
                "posts": {
                    "no_records": "現在投稿はありません",
                    "title": "よくある質問。"
                },
                "pro": "専門的なアクセス",
                "professionals": {
                    "argument_1": {
                        "part_1": "UgoZER はゲストにデジタル ソリューションを提供します。",
                        "part_2": "滞在とゲストエクスペリエンスを向上させます。",
                        "part_3": "ホテル、リゾート、インフォメーション センターがパーソナライズされた使いやすい、\n                多言語モバイル旅程。",
                        "part_4": "UgoZER プロフェッショナル プラットフォームを通じて、1 つのリアルタイム POI データベースからカスタマイズされた旅行を共有します\n                とそのモバイルアプリ。"
                    },
                    "argument_2": {
                        "part_1": "ゲストのために、事前にパッケージ化された旅程を選択できます。",
                        "part_2": "街で最高の場所やレストラン、隠れた名所、完璧なものを見つけて自分だけのものを作成しましょう\n                ハイキングコースを楽しむ...",
                        "part_3": "または、POI の網羅的なリストから訪問の各ステップを選択して、それらを組み合わせることができます。\n                パーソナライズされた旅程を共有します。"
                    },
                    "argument_3": {
                        "part_1": "各 POI には詳細な説明、インタラクティブな地図上の位置、およびその場所が含まれています。\n                住所は現地の言語に自動的に翻訳されます。",
                        "part_2": "ゲストが道に迷うことはありません。彼はいつも自分の道を見つけて、簡単にあなたのところに戻ってきます\n                ホテル。"
                    },
                    "argument_4": {
                        "part_1": "オーダーメイドの旅程を楽しんだ後、ゲストは旅の終わりに次のことを体験できます。\n                コンシェルジュや受付係に、ユニークなおすすめを教えてくれたことにチップを渡して感謝の気持ちを伝えましょう。",
                        "part_2": "UgoZERを使用することで、収益の増加とスタッフの削減により定着率が向上します。\n                ひっくり返す。",
                        "part_3": "ホテルでの日常生活に参加する意欲をさらに高めることができます。\n                そして彼らに帰属意識を与えます。"
                    },
                    "intro": {
                        "main_title": "UgoZER は、多言語対応のデジタルパーソナルトラベルアシスタントです。ゲストを連れて\n                経験を次のレベルに引き上げ、収益を増やします。"
                    },
                    "outro": {
                        "catchphrase": "UgoZER は、満足度を高め、成長を促進するのに役立ちます。"
                    }
                },
                "startup": {
                    "description_1": "UgoZER は、ヨーロッパの首都ストラスブールに拠点を置くフランスの会社です。旅行によって作成されました\n            恋人の皆さん、言語が話せない国への旅行も楽になります。",
                    "description_2": "観光案内所からも勧められています。",
                    "title": "スタートアップUgoZER"
                },
                "support_request": "サポートリクエスト",
                "travellers": {
                    "argument_1": {
                        "arguments": {
                            "audio": "タクシーや通行人の話を聞くための現地言語の<strong>音声アドレス</strong>",
                            "desc": "<strong>詳しい説明</strong>",
                            "languages": "すべての情報は<strong>フランス語、英語、ドイツ語、オランダ語、ポルトガル語、\n                    スペイン語、イタリア語、韓国語、中国語、日本語、 アラビア語</strong>です。",
                            "map": "<strong>インタラクティブな地図上の位置情報、Google Maps™ への出力が可能</strong>"
                        },
                        "part_1": "スマートフォンでリアルタイムに更新されるすべての興味のあるポイント (POI) にアクセスします。",
                        "part_2": "POI は<strong>カテゴリ別に分類されています: 記念碑、博物館、展示会、ショップ、レストラン、\n                バー、一時的なイベント...</strong>",
                        "part_3": "POI には以下が付属します:"
                    },
                    "argument_2": {
                        "part_1": "完全な情報に永久にアクセスして旅行をお楽しみください。すべてスマートフォンで、\n                ポケットの中にあります。"
                    },
                    "argument_3": {
                        "part_1": "UgoZER を使用すると、無料の旅程が自動的に取得されます。旅行を作成して共有することもできます\n                友達と一緒に。地元からのカスタマイズされたツアーを利用して、旅を最大限に活用しましょう\n                専門家 : ホテルのコンシェルジュまたは受付係、観光案内所。",
                        "part_2": "旅行が気に入ったら、デジタル化され安全な情報を提供して地元の専門家に感謝することもできます。\n                チップオプション。"
                    },
                    "intro": {
                        "main_title": "無料の多言語観光ガイド"
                    },
                    "outro": {
                        "catchphrase": "UgoZER を使用すると、安全にガイドされ、安心して旅行できます。"
                    }
                },
                "you_are": {
                    "professional": "プロ",
                    "traveller": "旅行者"
                },
                "youtube": {
                    "directed_by": "監督",
                    "producer": "スーパーヘッドスタジオ"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "教育機関を選択してください"
                    },
                    "sections": {
                        "pending_claims": "検証待ち",
                        "requests": "保留中の要求",
                        "validated_claims": "主張した"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "リクエストが送信されました"
                },
                "approve": {
                    "confirm": {
                        "employee": "本当にリクエストを承認してもよろしいですか?",
                        "employer": "本当に {user_name} を承認しますか?"
                    },
                    "success": "リクエストは承認されました"
                },
                "cancel": {
                    "confirm": {
                        "employee": "本当にこのリクエストをキャンセルしてもよろしいですか?",
                        "employer": "本当に {user_name} リクエストをキャンセルしますか?"
                    },
                    "success": "リクエストはキャンセルされました"
                },
                "decline": {
                    "confirm": {
                        "change_institution": "リンク先の機関が変更されます。",
                        "employee": "本当にリクエストを拒否しますか?",
                        "employer": "本当に {user_name} を拒否しますか?"
                    },
                    "success": "リクエストは拒否されました"
                },
                "feature": {
                    "confirm": "本当に {user_name} を特集したいですか?",
                    "folder": "貴社の従業員と接触したことのない旅行者にとって、それが注目される人物です\n           旅行者のフォルダーに表示される人。",
                    "only_one": "紹介できる従業員は 1 人だけです。",
                    "success": "社員が紹介されました"
                },
                "index": {
                    "my_employees": "私の従業員"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "アカウント",
                        "invitation": "招待"
                    }
                },
                "invite": {
                    "invite": "従業員を招待する"
                },
                "quit": {
                    "confirm": "本当にこの施設を辞めたいのですか {poi_name}？",
                    "success": "あなたは施設を辞めました"
                },
                "remove": {
                    "confirm": "本当に {user_name} を削除しますか?",
                    "success": "従業員が削除されました"
                },
                "show": {
                    "pending": "この機関への参加リクエストは現在保留中です。"
                },
                "states": {
                    "approved": "承認された",
                    "canceled": "キャンセル",
                    "declined": "拒否されました",
                    "invited": "招待されました",
                    "pending": "保留中",
                    "removed": "削除されました"
                }
            },
            "interactions": {
                "description": {
                    "contact approved": "連絡リクエストは {sender_name} によって承認されました",
                    "contact blocked": "連絡先リクエストは {sender_name} によってブロックされました",
                    "contact created": "連絡先リクエストは {sender_name} によって作成されました",
                    "contact declined": "連絡リクエストは {sender_name} によって拒否されました",
                    "invitation accepted": "招待は {sender_name} 人が受諾しました",
                    "invitation created": "招待状は {sender_name} によって送信されました",
                    "payment succeeded": "{sender_name} が {recipient_name} に支払いを送金しました",
                    "share created": "{sender_name} が {share} を共有しました",
                    "traveller_folder created": "Traveler フォルダーは {sender_name} によって作成されました",
                    "traveller_folder thanked": "{sender_name} が旅行者に滞在に感謝しました"
                },
                "share_type": {
                    "excursions": "旅程",
                    "pointsofinterest": "興味のある点"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "招待する"
                },
                "states": {
                    "authenticated_once": {
                        "false": "保留中",
                        "true": "検証済み"
                    }
                },
                "success": {
                    "professional": {
                        "sent_1": "このアドレスがまだ使用されていない場合、電子メールが {email} に送信されます。",
                        "sent_2": "この電子メールには、プロフェッショナル セクションに登録するためのリンクが含まれています。",
                        "title": "招待状が送られました"
                    },
                    "traveller": {
                        "email_1": "このアドレスがまだ使用されていない場合は、電子メールが {email} に送信されます。",
                        "email_2": "この電子メールには、アプリケーションに接続できるリンクが含まれています。",
                        "title": "招待状が送られました"
                    }
                },
                "texts": {
                    "already_have_account": "ユーザーはすでにアプリケーションのアカウントを持っていますか?",
                    "description": "電子メール アドレスまたは電話番号を使用して、誰かをアプリケーションに招待できます。",
                    "employee_already_have_account": "従業員はすでにアプリケーションのアカウントを持っていますか?",
                    "geoloc_settings": {
                        "description": "地理位置情報の設定はホテルの座標に自動的に設定されます\n                (施設にリンクされている場合)。",
                        "title": "地理位置情報の設定"
                    },
                    "invite_link": {
                        "instructions": "このリンクをコピーして旅行者を招待します",
                        "title": "リンクで招待"
                    },
                    "invite_professional": "専門家を招待する",
                    "invite_traveller": "旅行者を招待する",
                    "new_traveller": "新しい旅行者",
                    "professional_description": "専門家を招待して、専門家セクションに参加させることができます。\n            メールでのお申し込み",
                    "title": "誰かを招待してください"
                }
            },
            "locales": {
                "aa": "遠くまで",
                "ab": "アブハジア人",
                "af": "アフリカーンス語",
                "af_NA": "アフリカーンス語 (ナミビア)",
                "af_ZA": "アフリカーンス語 (南アフリカ)",
                "ak": "阿寒",
                "ak_GH": "アカン（ガーナ）",
                "am": "アムハラ語",
                "am_ET": "アムハラ語 (エチオピア)",
                "an": "アラゴン語",
                "ar": "アラビア語",
                "ar_AE": "アラビア語 (アラブ首長国連邦)",
                "ar_BH": "アラビア語 (バーレーン)",
                "ar_DJ": "アラビア語 (ジブチ)",
                "ar_DZ": "アラビア語 (アルジェリア)",
                "ar_EG": "アラビア語 (エジプト)",
                "ar_EH": "アラビア語 (西サハラ)",
                "ar_ER": "アラビア語 (エリトリア)",
                "ar_IL": "アラビア語 (イスラエル)",
                "ar_IQ": "アラビア語 (イラク)",
                "ar_JO": "アラビア語 (ヨルダン)",
                "ar_KM": "アラビア語 (コモロ)",
                "ar_KW": "アラビア語 (クウェート)",
                "ar_LB": "アラビア語 (レバノン)",
                "ar_LY": "アラビア語 (リビア)",
                "ar_MA": "アラビア語 (モロッコ)",
                "ar_MR": "アラビア語 (モーリタニア)",
                "ar_OM": "アラビア語 (オマーン)",
                "ar_PS": "アラビア語 (パレスチナ自治区)",
                "ar_QA": "アラビア語 (カタール)",
                "ar_SA": "アラビア語 (サウジアラビア)",
                "ar_SD": "アラビア語 (スーダン)",
                "ar_SO": "アラビア語 (ソマリア)",
                "ar_SS": "アラビア語 (南スーダン)",
                "ar_SY": "アラビア語 (シリア)",
                "ar_TD": "アラビア語 (チャド)",
                "ar_TN": "アラビア語 (チュニジア)",
                "ar_YE": "アラビア語 (イエメン)",
                "as": "アッサム語",
                "as_IN": "アッサム語 (インド)",
                "av": "アヴァリック",
                "ay": "アイマラ",
                "az": "アゼルバイジャン語",
                "az_AZ": "アゼルバイジャン語 (アゼルバイジャン)",
                "az_Cyrl": "アゼルバイジャン語 (キリル文字)",
                "az_Cyrl_AZ": "アゼルバイジャン語 (キリル文字、アゼルバイジャン)",
                "az_Latn": "アゼルバイジャン語 (ラテン語)",
                "az_Latn_AZ": "アゼルバイジャン語 (ラテン語、アゼルバイジャン)",
                "ba": "バシキール語",
                "be": "ベラルーシ語",
                "be_BY": "ベラルーシ語 (ベラルーシ)",
                "bg": "ブルガリア語",
                "bg_BG": "ブルガリア語 (ブルガリア)",
                "bh": "ビハール語（言語）",
                "bi": "ビスラマ",
                "bm": "バンバラ",
                "bm_Latn": "バンバラ (ラテン語)",
                "bm_Latn_ML": "バンバラ (ラテン語、マリ)",
                "bn": "ベンガル語",
                "bn_BD": "ベンガル語 (バングラデシュ)",
                "bn_IN": "ベンガル語 (インド)",
                "bo": "チベット語",
                "bo_CN": "チベット語 (中国)",
                "bo_IN": "チベット人 (インド)",
                "br": "ブルトン語",
                "br_FR": "ブルトン (フランス)",
                "bs": "ボスニア語",
                "bs_BA": "ボスニア語 (ボスニア・ヘルツェゴビナ)",
                "bs_Cyrl": "ボスニア語 (キリル文字)",
                "bs_Cyrl_BA": "ボスニア語 (キリル文字、ボスニア・ヘルツェゴビナ)",
                "bs_Latn": "ボスニア語 (ラテン語)",
                "bs_Latn_BA": "ボスニア語 (ラテン語、ボスニア・ヘルツェゴビナ)",
                "ca": "カタルーニャ語",
                "ca_AD": "カタロニア語 (アンドラ)",
                "ca_ES": "カタルーニャ語 (スペイン)",
                "ca_FR": "カタルーニャ語 (フランス)",
                "ca_IT": "カタルーニャ語 (イタリア)",
                "ce": "チェチェン",
                "ch": "チャモロ",
                "co": "コルシカ島",
                "cr": "クリー語",
                "cs": "チェコ語",
                "cs_CZ": "チェコ語 (チェコ共和国)",
                "cu": "古いブルガリア語",
                "cv": "チュヴァシ語",
                "cy": "ウェールズ語",
                "cy_GB": "ウェールズ語 (イギリス)",
                "da": "デンマーク語",
                "da_DK": "デンマーク語 (デンマーク)",
                "da_GL": "デンマーク語 (グリーンランド)",
                "de": "ドイツ人",
                "de_AT": "ドイツ語 (オーストリア)",
                "de_BE": "ドイツ語 (ベルギー)",
                "de_CH": "ドイツ語 (スイス)",
                "de_DE": "ドイツ語 (ドイツ)",
                "de_LI": "ドイツ語 (リヒテンシュタイン)",
                "de_LU": "ドイツ語 (ルクセンブルク)",
                "dv": "モルディブ語",
                "dz": "ゾンカ語",
                "dz_BT": "ゾンカ語 (ブータン)",
                "ee": "エウェ",
                "ee_GH": "羊 (ガーナ)",
                "ee_TG": "エウェ (トーゴ)",
                "el": "ギリシャ語",
                "el_CY": "ギリシャ語 (キプロス)",
                "el_GR": "ギリシャ語 (ギリシャ)",
                "en": "英語",
                "en_AG": "英語 (アンティグアバーブーダ)",
                "en_AI": "英語 (アンギラ)",
                "en_AS": "英語 (アメリカ領サモア)",
                "en_AU": "英語 (オーストラリア)",
                "en_BB": "英語 (バルバドス)",
                "en_BE": "英語 (ベルギー)",
                "en_BM": "英語 (バミューダ)",
                "en_BS": "英語 (バハマ)",
                "en_BW": "英語 (ボツワナ)",
                "en_BZ": "英語 (ベリーズ)",
                "en_CA": "英語 (カナダ)",
                "en_CC": "英語 (ココス (キーリング) 諸島)",
                "en_CK": "英語 (クック諸島)",
                "en_CM": "英語 (カメルーン)",
                "en_CX": "英語 (クリスマス島)",
                "en_DG": "英語 (ディエゴ・ガルシア)",
                "en_DM": "英語 (ドミニカ)",
                "en_ER": "英語 (エリトリア)",
                "en_FJ": "英語 (フィジー)",
                "en_FK": "英語 (フォークランド諸島)",
                "en_FM": "英語 (ミクロネシア)",
                "en_GB": "英語 (イギリス)",
                "en_GD": "英語 (グレナダ)",
                "en_GG": "英語 (ガーンジー島)",
                "en_GH": "英語 (ガーナ)",
                "en_GI": "英語 (ジブラルタル)",
                "en_GM": "英語 (ガンビア)",
                "en_GU": "英語 (グアム)",
                "en_GY": "英語 (ガイアナ)",
                "en_HK": "英語 (中国香港特別行政区)",
                "en_IE": "英語 (アイルランド)",
                "en_IM": "英語 (マン島)",
                "en_IN": "英語 (インド)",
                "en_IO": "英語 (英領インド洋地域)",
                "en_JE": "英語（ジャージ）",
                "en_JM": "英語 (ジャマイカ)",
                "en_KE": "英語 (ケニア)",
                "en_KI": "英語 (キリバス)",
                "en_KN": "英語 (セントクリストファー・ネイビス)",
                "en_KY": "英語 (ケイマン諸島)",
                "en_LC": "英語 (セントルシア)",
                "en_LR": "英語 (リベリア)",
                "en_LS": "英語 (レソト)",
                "en_MG": "英語 (マダガスカル)",
                "en_MH": "英語 (マーシャル諸島)",
                "en_MO": "英語 (中国マカオ特別行政区)",
                "en_MP": "英語 (北マリアナ諸島)",
                "en_MS": "英語 (モンセラート)",
                "en_MT": "英語 (マルタ)",
                "en_MU": "英語 (モーリシャス)",
                "en_MW": "英語 (マラウイ)",
                "en_MY": "英語 (マレーシア)",
                "en_NA": "英語 (ナミビア)",
                "en_NF": "英語 (ノーフォーク島)",
                "en_NG": "英語 (ナイジェリア)",
                "en_NR": "英語 (ナウル)",
                "en_NU": "英語 (ニウエ)",
                "en_NZ": "英語 (ニュージーランド)",
                "en_PG": "英語 (パプアニューギニア)",
                "en_PH": "英語（フィリピン）",
                "en_PK": "英語 (パキスタン)",
                "en_PN": "英語 (ピトケアン諸島)",
                "en_PR": "英語 (プエルトリコ)",
                "en_PW": "英語 (パラオ)",
                "en_RW": "英語 (ルワンダ)",
                "en_SB": "英語 (ソロモン諸島)",
                "en_SC": "英語 (セーシェル)",
                "en_SD": "英語 (スーダン)",
                "en_SG": "英語 (シンガポール)",
                "en_SH": "英語 (セントヘレナ)",
                "en_SL": "英語 (シエラレオネ)",
                "en_SS": "英語 (南スーダン)",
                "en_SX": "英語 (シント・マールテン島)",
                "en_SZ": "英語 (スワジランド)",
                "en_TC": "英語 (タークス・カイコス諸島)",
                "en_TK": "英語 (トケラウ語)",
                "en_TO": "英語 (トンガ)",
                "en_TT": "英語 (トリニダード・トバゴ)",
                "en_TV": "英語 (ツバル)",
                "en_TZ": "英語 (タンザニア)",
                "en_UG": "英語 (ウガンダ)",
                "en_UM": "英語 (米国離島)",
                "en_US": "英語 (米国)",
                "en_VC": "英語 (セントビンセントおよびグレナディーン諸島)",
                "en_VG": "英語 (イギリス領ヴァージン諸島)",
                "en_VI": "英語 (米領ヴァージン諸島)",
                "en_VU": "英語 (バヌアツ)",
                "en_WS": "英語 (サモア)",
                "en_ZA": "英語 (南アフリカ)",
                "en_ZM": "英語 (ザンビア)",
                "en_ZW": "英語 (ジンバブエ)",
                "eo": "エスペラント",
                "es": "スペイン語",
                "es_AR": "スペイン語 (アルゼンチン)",
                "es_BO": "スペイン語 (ボリビア)",
                "es_CL": "スペイン語 (チリ)",
                "es_CO": "スペイン語 (コロンビア)",
                "es_CR": "スペイン語 (コスタリカ)",
                "es_CU": "スペイン語 (キューバ)",
                "es_DO": "スペイン語 (ドミニカ共和国)",
                "es_EA": "スペイン語 (セウタとメリリャ)",
                "es_EC": "スペイン語 (エクアドル)",
                "es_ES": "スペイン語 (スペイン)",
                "es_GQ": "スペイン語 (赤道ギニア)",
                "es_GT": "スペイン語 (グアテマラ)",
                "es_HN": "スペイン語 (ホンジュラス)",
                "es_IC": "スペイン語 (カナリア諸島)",
                "es_MX": "スペイン語 (メキシコ)",
                "es_NI": "スペイン語 (ニカラグア)",
                "es_PA": "スペイン語 (パナマ)",
                "es_PE": "スペイン語 (ペルー)",
                "es_PH": "スペイン語 (フィリピン)",
                "es_PR": "スペイン語 (プエルトリコ)",
                "es_PY": "スペイン語 (パラグアイ)",
                "es_SV": "スペイン語 (エルサルバドル)",
                "es_US": "スペイン語 (米国)",
                "es_UY": "スペイン語 (ウルグアイ)",
                "es_VE": "スペイン語 (ベネズエラ)",
                "et": "エストニア語",
                "et_EE": "エストニア語 (エストニア)",
                "eu": "バスク語",
                "eu_ES": "バスク (スペイン)",
                "fa": "ペルシア語",
                "fa_AF": "ペルシア語 (アフガニスタン)",
                "fa_IR": "ペルシア語 (イラン)",
                "ff": "フラ",
                "ff_CM": "フラ (カメルーン)",
                "ff_GN": "フラ (ギニア)",
                "ff_MR": "フラ (モーリタニア)",
                "ff_SN": "フラ (セネガル)",
                "fi": "フィンランド語",
                "fi_FI": "フィンランド語 (フィンランド)",
                "fj": "フィジー語",
                "fo": "フェロー語",
                "fo_FO": "フェロー語 (フェロー諸島)",
                "fr": "フランス語",
                "fr_BE": "フランス語 (ベルギー)",
                "fr_BF": "フランス語 (ブルキナファソ)",
                "fr_BI": "フランス語 (ブルンジ)",
                "fr_BJ": "フランス語 (ベナン)",
                "fr_BL": "フランス語 (サン・バルテルミー島)",
                "fr_CA": "フランス語 (カナダ)",
                "fr_CD": "フランス語 (コンゴ - キンシャサ)",
                "fr_CF": "フランス語 (中央アフリカ共和国)",
                "fr_CG": "フランス語 (コンゴ - ブラザヴィル)",
                "fr_CH": "フランス語 (スイス)",
                "fr_CI": "フランス語 (コートジボワール)",
                "fr_CM": "フランス語 (カメルーン)",
                "fr_DJ": "フランス語 (ジブチ)",
                "fr_DZ": "フランス語 (アルジェリア)",
                "fr_FR": "フランス語 (フランス)",
                "fr_GA": "フランス語 (ガボン)",
                "fr_GF": "フランス語 (フランス領ギアナ)",
                "fr_GN": "フランス語 (ギニア)",
                "fr_GP": "フランス語 (グアドループ)",
                "fr_GQ": "フランス語 (赤道ギニア)",
                "fr_HT": "フランス語 (ハイチ)",
                "fr_KM": "フランス語 (コモロ)",
                "fr_LU": "フランス語 (ルクセンブルク)",
                "fr_MA": "フランス語 (モロッコ)",
                "fr_MC": "フランス語 (モナコ)",
                "fr_MF": "フランス語 (セント マーチン)",
                "fr_MG": "フランス語 (マダガスカル)",
                "fr_ML": "フランス語 (マリ)",
                "fr_MQ": "フランス語 (マルティニーク)",
                "fr_MR": "フランス語 (モーリタニア)",
                "fr_MU": "フランス語 (モーリシャス)",
                "fr_NC": "フランス語 (ニューカレドニア)",
                "fr_NE": "フランス語 (ニジェール)",
                "fr_PF": "フランス語 (フランス領ポリネシア)",
                "fr_PM": "フランス語 (サンピエール島およびミクロン島)",
                "fr_RE": "フランス語 (レユニオン)",
                "fr_RW": "フランス語 (ルワンダ)",
                "fr_SC": "フランス語 (セーシェル)",
                "fr_SN": "フランス語 (セネガル)",
                "fr_SY": "フランス語 (シリア)",
                "fr_TD": "フランス語 (チャド)",
                "fr_TG": "フランス語 (トーゴ)",
                "fr_TN": "フランス語 (チュニジア)",
                "fr_VU": "フランス語 (バヌアツ)",
                "fr_WF": "フランス語 (ウォリス＆フツナ島)",
                "fr_YT": "フランス語 (マヨット語)",
                "fy": "西フリジア語",
                "fy_NL": "西フリジア語 (オランダ)",
                "ga": "アイルランド人",
                "ga_IE": "アイルランド語 (アイルランド)",
                "gd": "スコットランド・ゲール語",
                "gd_GB": "スコットランドゲール語 (イギリス)",
                "gl": "ガリシア語",
                "gl_ES": "ガリシア語 (スペイン)",
                "gn": "グアラニー語",
                "gu": "グジャラート語",
                "gu_IN": "グジャラート語 (インド)",
                "gv": "マンクス",
                "gv_IM": "マンクス (マン島)",
                "ha": "ハウサ語",
                "ha_GH": "ハウサ語 (ガーナ)",
                "ha_Latn": "ハウサ語 (ラテン語)",
                "ha_Latn_GH": "ハウサ語 (ラテン語、ガーナ)",
                "ha_Latn_NE": "ハウサ語 (ラテン語、ニジェール)",
                "ha_Latn_NG": "ハウサ語 (ラテン語、ナイジェリア)",
                "ha_NE": "ハウサ語 (ニジェール)",
                "ha_NG": "ハウサ語 (ナイジェリア)",
                "he": "ヘブライ語",
                "he_IL": "ヘブライ語 (イスラエル)",
                "hi": "ヒンディー語",
                "hi_IN": "ヒンディー語 (インド)",
                "ho": "ヒリ・モツ",
                "hr": "クロアチア語",
                "hr_BA": "クロアチア語 (ボスニア・ヘルツェゴビナ)",
                "hr_HR": "クロアチア語 (クロアチア)",
                "ht": "ハイチ人",
                "hu": "ハンガリー語",
                "hu_HU": "ハンガリー語 (ハンガリー)",
                "hy": "アルメニア語",
                "hy_AM": "アルメニア語 (アルメニア)",
                "hz": "ヘレロ",
                "ia": "インターリングア",
                "id": "インドネシア語",
                "id_ID": "インドネシア語 (インドネシア)",
                "ie": "間言語",
                "ig": "イボ語",
                "ig_NG": "イボ (ナイジェリア)",
                "ii": "四川イー",
                "ii_CN": "四川イー（中国）",
                "ik": "イヌピアク",
                "io": "私はします",
                "is": "アイスランド語",
                "is_IS": "アイスランド語 (アイスランド)",
                "it": "イタリアの",
                "it_CH": "イタリア語 (スイス)",
                "it_IT": "イタリア語 (イタリア)",
                "it_SM": "イタリア語 (サンマリノ)",
                "iu": "イヌクティトゥット語",
                "ja": "日本語",
                "ja_JP": "日本語 (日本)",
                "jv": "ジャワ語",
                "ka": "ジョージア語",
                "ka_GE": "グルジア語 (ジョージア)",
                "kg": "金剛",
                "ki": "キクユ",
                "ki_KE": "キクユ (ケニア)",
                "kj": "関山",
                "kk": "カザフ語",
                "kk_Cyrl": "カザフ語 (キリル文字)",
                "kk_Cyrl_KZ": "カザフ語 (キリル文字、カザフスタン)",
                "kk_KZ": "カザフ語 (カザフスタン)",
                "kl": "カラリスト",
                "kl_GL": "カラアリスト (グリーンランド)",
                "km": "クメール語",
                "km_KH": "クメール語 (カンボジア)",
                "kn": "カンナダ語",
                "kn_IN": "カンナダ語 (インド)",
                "ko": "韓国語",
                "ko_KP": "韓国人（北朝鮮）",
                "ko_KR": "韓国語 (韓国)",
                "kr": "カヌリ",
                "ks": "カシミール語",
                "ks_Arab": "カシミール語 (アラビア語)",
                "ks_Arab_IN": "カシミール語 (アラビア語、インド)",
                "ks_IN": "カシミール語 (インド)",
                "ku": "クルド",
                "kv": "コミ",
                "kw": "コーニッシュ",
                "kw_GB": "コーニッシュ (イギリス)",
                "ky": "キルギス",
                "ky_Cyrl": "キルギス語 (キリル文字)",
                "ky_Cyrl_KG": "キルギス語 (キリル文字、キルギス)",
                "ky_KG": "キルギス (キルギス)",
                "la": "ラテン",
                "lb": "ルクセンブルク語",
                "lb_LU": "ルクセンブルク語 (ルクセンブルク)",
                "lg": "ガンダ",
                "lg_UG": "ガンダ (ウガンダ)",
                "li": "リンブルガン",
                "ln": "リンガラ",
                "ln_AO": "リンガラ (アンゴラ)",
                "ln_CD": "リンガラ (コンゴ - キンシャサ)",
                "ln_CF": "リンガラ (中央アフリカ共和国)",
                "ln_CG": "リンガラ (コンゴ - ブラザヴィル)",
                "lo": "ラオ語",
                "lo_LA": "ラオ語 (ラオス)",
                "lt": "リトアニア語",
                "lt_LT": "リトアニア語 (リトアニア)",
                "lu": "ルバ・カタンガ",
                "lu_CD": "ルバ・カタンガ (コンゴ - キンシャサ)",
                "lv": "ラトビア語",
                "lv_LV": "ラトビア語 (ラトビア)",
                "mg": "マダガスカル",
                "mg_MG": "マダガスカル (マダガスカル)",
                "mh": "マーシャル人",
                "mi": "マオリ語",
                "mk": "マケドニアの",
                "mk_MK": "マケドニア語 (マケドニア)",
                "ml": "マラヤーラム語",
                "ml_IN": "マラヤーラム語 (インド)",
                "mn": "モンゴル語",
                "mn_Cyrl": "モンゴル語 (キリル文字)",
                "mn_Cyrl_MN": "モンゴル語 (キリル文字、モンゴル語)",
                "mn_MN": "モンゴル語 (モンゴル)",
                "mo": "マカオ",
                "mr": "マラーティー語",
                "mr_IN": "マラーティー語 (インド)",
                "ms": "マレー語",
                "ms_BN": "マレー語 (ブルネイ)",
                "ms_Latn": "マレー語 (ラテン語)",
                "ms_Latn_BN": "マレー語 (ラテン語、ブルネイ)",
                "ms_Latn_MY": "マレー語 (ラテン語、マレーシア)",
                "ms_Latn_SG": "マレー語 (ラテン語、シンガポール)",
                "ms_MY": "マレー語 (マレーシア)",
                "ms_SG": "マレー語 (シンガポール)",
                "mt": "マルタ語",
                "mt_MT": "マルタ語 (マルタ)",
                "my": "ビルマ語",
                "my_MM": "ビルマ語 (ミャンマー (ビルマ))",
                "na": "ナウル",
                "nb": "ノルウェー語ブークモール",
                "nb_NO": "ノルウェー語ブークモール (ノルウェー)",
                "nb_SJ": "ノルウェー語ブークモール (スヴァールバル諸島およびヤンマイエン島)",
                "nd": "北ンデベレ",
                "nd_ZW": "北ンデベレ (ジンバブエ)",
                "ne": "ネパール語",
                "ne_IN": "ネパール語 (インド)",
                "ne_NP": "ネパール語 (ネパール)",
                "ng": "ンドンガ",
                "nl": "オランダの",
                "nl_AW": "オランダ語 (アルバ)",
                "nl_BE": "オランダ語 (ベルギー)",
                "nl_BQ": "オランダ語 (カリブ海オランダ)",
                "nl_CW": "オランダ語 (キュラソー島)",
                "nl_NL": "オランダ語 (オランダ)",
                "nl_SR": "オランダ語 (スリナム)",
                "nl_SX": "オランダ語 (シント・マールテン島)",
                "nn": "ノルウェー語ニーノシュク",
                "nn_NO": "ノルウェー語ニーノシュク (ノルウェー)",
                "no": "ノルウェー語",
                "no_NO": "ノルウェー語 (ノルウェー)",
                "nr": "南ンデベレ",
                "nv": "ナバホ州",
                "ny": "チチェワ",
                "oc": "オック語",
                "oj": "オジブワ",
                "om": "オロモ",
                "om_ET": "オロモ島 (エチオピア)",
                "om_KE": "オロモ島（ケニア）",
                "or": "おりや",
                "or_IN": "オリヤ (インド)",
                "os": "骨質",
                "os_GE": "オセティック (ジョージア州)",
                "os_RU": "オセティック (ロシア)",
                "pa": "パンジャブ語",
                "pa_Arab": "パンジャブ語 (アラビア語)",
                "pa_Arab_PK": "パンジャブ語 (アラビア語、パキスタン)",
                "pa_Guru": "パンジャブ語 (グルムキ語)",
                "pa_Guru_IN": "パンジャブ語 (グルムキ、インド)",
                "pa_IN": "パンジャブ語 (インド)",
                "pa_PK": "パンジャブ語 (パキスタン)",
                "pi": "パーリ語",
                "pl": "研磨",
                "pl_PL": "ポーランド語 (ポーランド)",
                "ps": "パシュトゥー語",
                "ps_AF": "パシュトゥー語 (アフガニスタン)",
                "pt": "ポルトガル語",
                "pt_AO": "ポルトガル語 (アンゴラ)",
                "pt_BR": "ポルトガル語 (ブラジル)",
                "pt_CV": "ポルトガル語 (カーボベルデ)",
                "pt_GW": "ポルトガル語 (ギニアビサウ)",
                "pt_MO": "ポルトガル語 (中国マカオ特別行政区)",
                "pt_MZ": "ポルトガル語 (モザンビーク)",
                "pt_PT": "ポルトガル語 (ポルトガル)",
                "pt_ST": "ポルトガル語 (サントメ・プリンシペ)",
                "pt_TL": "ポルトガル語 (東ティモール)",
                "qu": "ケチュア語",
                "qu_BO": "ケチュア語 (ボリビア)",
                "qu_EC": "ケチュア語 (エクアドル)",
                "qu_PE": "ケチュア語 (ペルー)",
                "rm": "ロマンシュ語",
                "rm_CH": "ロマンシュ語 (スイス)",
                "rn": "ルンディ",
                "rn_BI": "ルンディ (ブルンジ)",
                "ro": "ルーマニア語",
                "ro_MD": "ルーマニア語 (モルドバ)",
                "ro_RO": "ルーマニア語 (ルーマニア)",
                "ru": "ロシア",
                "ru_BY": "ロシア語 (ベラルーシ)",
                "ru_KG": "ロシア語 (キルギス)",
                "ru_KZ": "ロシア語 (カザフスタン)",
                "ru_MD": "ロシア語 (モルドバ)",
                "ru_RU": "ロシア語 (ロシア)",
                "ru_UA": "ロシア語 (ウクライナ)",
                "rw": "ルワンダ",
                "rw_RW": "キニャルワンダ (ルワンダ)",
                "sa": "サンスクリット",
                "sc": "サルデーニャ語",
                "sd": "シンド語",
                "se": "北サーミ人",
                "se_FI": "北サーミ人 (フィンランド)",
                "se_NO": "北サーミ語 (ノルウェー)",
                "se_SE": "北サーミ人 (スウェーデン)",
                "sg": "サンゴ",
                "sg_CF": "サンゴ (中央アフリカ共和国)",
                "sh": "セルボ・クロアチア語",
                "sh_BA": "セルボクロアチア語 (ボスニア・ヘルツェゴビナ)",
                "si": "シンハラ語",
                "si_LK": "シンハラ語 (スリランカ)",
                "sk": "スロバキア語",
                "sk_SK": "スロバキア語 (スロバキア)",
                "sl": "スロベニア語",
                "sl_SI": "スロベニア語 (スロベニア)",
                "sm": "サモア語",
                "sn": "ショナ",
                "sn_ZW": "ショナ (ジンバブエ)",
                "so": "ソマリ語",
                "so_DJ": "ソマリ語 (ジブチ)",
                "so_ET": "ソマリ語 (エチオピア)",
                "so_KE": "ソマリ語 (ケニア)",
                "so_SO": "ソマリ (ソマリア)",
                "sq": "アルバニア人",
                "sq_AL": "アルバニア語 (アルバニア)",
                "sq_MK": "アルバニア語 (マケドニア)",
                "sq_XK": "アルバニア語 (コソボ)",
                "sr": "セルビア語",
                "sr_BA": "セルビア語 (ボスニア・ヘルツェゴビナ)",
                "sr_Cyrl": "セルビア語 (キリル文字)",
                "sr_Cyrl_BA": "セルビア語 (キリル文字、ボスニア・ヘルツェゴビナ)",
                "sr_Cyrl_ME": "セルビア語 (キリル文字、モンテネグロ)",
                "sr_Cyrl_RS": "セルビア語 (キリル文字、セルビア)",
                "sr_Cyrl_XK": "セルビア語 (キリル文字、コソボ)",
                "sr_Latn": "セルビア語 (ラテン語)",
                "sr_Latn_BA": "セルビア語 (ラテン語、ボスニア・ヘルツェゴビナ)",
                "sr_Latn_ME": "セルビア語 (ラテン語、モンテネグロ)",
                "sr_Latn_RS": "セルビア語 (ラテン語、セルビア)",
                "sr_Latn_XK": "セルビア語 (ラテン語、コソボ)",
                "sr_ME": "セルビア語 (モンテネグロ)",
                "sr_RS": "セルビア語 (セルビア)",
                "sr_XK": "セルビア語 (コソボ)",
                "ss": "スワティ",
                "st": "ソト、南部",
                "su": "スンダ人",
                "sv": "スウェーデンの",
                "sv_AX": "スウェーデン語 (オーランド諸島)",
                "sv_FI": "スウェーデン語 (フィンランド)",
                "sv_SE": "スウェーデン語 (スウェーデン)",
                "sw": "スワヒリ語",
                "sw_KE": "スワヒリ語 (ケニア)",
                "sw_TZ": "スワヒリ語 (タンザニア)",
                "sw_UG": "スワヒリ語 (ウガンダ)",
                "ta": "タミル語",
                "ta_IN": "タミル語 (インド)",
                "ta_LK": "タミル語 (スリランカ)",
                "ta_MY": "タミル語 (マレーシア)",
                "ta_SG": "タミル語 (シンガポール)",
                "te": "テルグ語",
                "te_IN": "テルグ語 (インド)",
                "tg": "タジク語",
                "th": "タイ語",
                "th_TH": "タイ語 (タイ)",
                "ti": "ティグリニャ",
                "ti_ER": "ティグリニャ (エリトリア)",
                "ti_ET": "ティグリニャ (エチオピア)",
                "tk": "トルクメン語",
                "tl": "タガログ語",
                "tl_PH": "タガログ語 (フィリピン)",
                "tn": "ツワナ",
                "to": "トンガ語",
                "to_TO": "トンガ (トンガ)",
                "tr": "トルコ語",
                "tr_CY": "トルコ語 (キプロス)",
                "tr_TR": "トルコ語 (トルコ)",
                "ts": "ツォンガ",
                "tt": "タタール語",
                "tw": "ツイ",
                "ty": "タヒチ語",
                "ug": "ウイグル語",
                "ug_Arab": "ウイグル語 (アラビア語)",
                "ug_Arab_CN": "ウイグル語 (アラビア語、中国)",
                "ug_CN": "ウイグル族（中国）",
                "uk": "ウクライナ語",
                "uk_UA": "ウクライナ語 (ウクライナ)",
                "ur": "ウルドゥー語",
                "ur_IN": "ウルドゥー語 (インド)",
                "ur_PK": "ウルドゥー語 (パキスタン)",
                "uz": "ウズベク語",
                "uz_AF": "ウズベク語 (アフガニスタン)",
                "uz_Arab": "ウズベク語 (アラビア語)",
                "uz_Arab_AF": "ウズベク語 (アラビア語、アフガニスタン)",
                "uz_Cyrl": "ウズベク語 (キリル文字)",
                "uz_Cyrl_UZ": "ウズベク語 (キリル文字、ウズベキスタン)",
                "uz_Latn": "ウズベク語 (ラテン語)",
                "uz_Latn_UZ": "ウズベク語 (ラテン語、ウズベキスタン)",
                "uz_UZ": "ウズベク語 (ウズベキスタン)",
                "ve": "ヴェンダ",
                "vi": "ベトナム語",
                "vi_VN": "ベトナム語 (ベトナム)",
                "vo": "ヴォラピュク",
                "wa": "ワロン",
                "wo": "ウォロフ",
                "xh": "コサ語",
                "yi": "イディッシュ語",
                "yo": "ヨルバ語",
                "yo_BJ": "ヨルバ語 (ベナン)",
                "yo_NG": "ヨルバ (ナイジェリア)",
                "za": "チワン",
                "zh": "中国語",
                "zh_CN": "中国語（中国）",
                "zh_HK": "中国語 (中国香港特別行政区)",
                "zh_Hans": "中国語（簡体字）",
                "zh_Hans_CN": "中国語 (簡体字、中国)",
                "zh_Hans_HK": "中国語 (簡体字、中国香港特別行政区)",
                "zh_Hans_MO": "中国語 (簡体字、中国マカオ特別行政区)",
                "zh_Hans_SG": "中国語 (簡体字、シンガポール)",
                "zh_Hant": "中国の伝統的な）",
                "zh_Hant_HK": "中国語 (繁体字、中国香港特別行政区)",
                "zh_Hant_MO": "中国語 (繁体字、中国マカオ特別行政区)",
                "zh_Hant_TW": "中国語（繁体字、台湾）",
                "zh_MO": "中国語 (中国マカオ特別行政区)",
                "zh_SG": "中国語 (シンガポール)",
                "zh_TW": "中国語（台湾）",
                "zu": "ズールー語",
                "zu_ZA": "ズールー語 (南アフリカ)"
            },
            "mail": {
                "action": "「{actionText}」ボタンをクリックできない場合は、以下の URL をコピーして Web ブラウザに貼り付けてください。",
                "auth": {
                    "deleted": {
                        "line1": "UgoZER アカウントが削除されたことをお知らせします。",
                        "reason": {
                            "intro": "こちらは以下の理由により削除されました。",
                            "never_authenticated_for_too_long": "アカウントは 1 週間以上前に作成されました\n                    そしてあなたはログインしたことがありません:\n                    そのため、このアカウントは非アクティブとみなされます。",
                            "not_logged_for_too_long": "2 年以上ログインしていません:\n                    あなたのアカウントは非アクティブとみなされます。"
                        },
                        "subject": "UgoZER アカウントの削除"
                    },
                    "login_attempt_unverified_credential": {
                        "action": "メールアドレスの確認",
                        "line1": "メールアドレス {credential} で UgoZER アカウントにログインしようとしました。",
                        "line2": "このログイン試行を開始した場合、\n                下のボタンをクリックしてメールアドレスを確認してください\n                そして接続できるようになります。",
                        "line3": "このリンクは 1 週間で期限切れになります。",
                        "line4": "このログイン試行を開始していない場合は、それ以上のアクションは必要ありません。",
                        "subject": "UgoZER アカウントにログインしてみます"
                    },
                    "login_magic_link": {
                        "action": "ログイン",
                        "line1": "このリンクをクリックしてログインしてください",
                        "line2": "弊社のアプリケーションをご利用いただきありがとうございます。",
                        "subject": "パスワードレスログインリンク"
                    },
                    "not_logged": {
                        "intro": "最後に UgoZER アカウントにログインしてから約 2 年が経過しました。",
                        "outro": "アカウントが削除されないように、アカウントにログインするだけです。",
                        "remaining": {
                            "two_days": "アカウントが削除されるまであと 2 日あります。",
                            "two_months": "アカウントが削除されるまであと 2 か月あります。",
                            "two_weeks": "アカウントが削除されるまであと 2 週間あります。"
                        },
                        "subject": "あなたのUgoZERアカウントはまもなく削除されます"
                    },
                    "registration": {
                        "action": "メールアドレスの確認",
                        "line1": "下のボタンをクリックしてメールアドレスを認証し、ログインしてください。",
                        "line2": "このリンクは 1 週間で期限切れになります。",
                        "line3": "アカウントを作成していない場合は、それ以上のアクションは必要ありません。",
                        "subject": "あなたのメールアドレスを確認してください"
                    },
                    "registration_attempt": {
                        "line1": "<strong>{credential}</strong> メールは、アプリケーションの <strong>{role_type}</strong> アカウントにすでに使用されています。",
                        "line2": "アカウントを作成するには、別のメールアドレスで登録をやり直してください。",
                        "subject": "UgoZER 資格情報を使用して登録を試みます"
                    },
                    "registration_attempt_unverified_credential": {
                        "line1": "<strong>{credential}</strong> メールは、アプリケーションの <strong>{role_type}</strong> アカウントにすでに使用されています。",
                        "line2": "アカウントを作成するには、別のメールアドレスで登録をやり直してください。",
                        "subject": "UgoZER 資格情報を使用して登録を試みます"
                    },
                    "reset_password": {
                        "action": "パスワードを再設定する",
                        "line1": "このメールは、アカウントのパスワード リセット リクエストを受け取ったためにお送りしています。",
                        "line2": "このパスワード リセット リンクは {count} 分で期限切れになります。",
                        "line3": "パスワードのリセットを要求していない場合は、それ以上のアクションは必要ありません。",
                        "subject": "パスワードリセット通知"
                    }
                },
                "copyright": "© {year} UgoZER - 無断複写・転載を禁じます。",
                "credential_verification": {
                    "verify": {
                        "action": "メールアドレスの確認",
                        "line1": "下のボタンをクリックしてメールアドレスを確認してください。",
                        "line2": "このリンクは 1 週間で期限切れになります。",
                        "line3": "検証をリクエストしなかった場合は、それ以上のアクションは必要ありません。",
                        "subject": "メールの確認"
                    }
                },
                "hello": "こんにちは！",
                "invitation": {
                    "attempt": {
                        "action": "アプリケーションへのアクセス",
                        "line1": "私たちのアプリケーションに参加するよう招待されました",
                        "line1_by": " {name} からアプリケーションに参加するよう招待されました",
                        "line1_by_from": "あなたは {poi_title} 機関の {name} から私たちのアプリケーションに参加するよう招待されました",
                        "line2": "アプリケーションに既存のアカウントがすでにあります",
                        "line3": "下のボタンをクリックしてアプリケーションにアクセスしてください。",
                        "subject": "UgoZERへの招待"
                    },
                    "verification": {
                        "action": "メールアドレスの確認",
                        "excursion_shared": "旅程が共有されました。 PDF形式で添付されておりますので、\n                    共有内のアプリケーションでも同様です。",
                        "line1": "私たちのアプリケーションに参加するよう招待されました",
                        "line1_by": " {name} からアプリケーションに参加するよう招待されました",
                        "line1_by_from": "あなたは {poi_title} 機関の {name} から私たちのアプリケーションに参加するよう招待されました",
                        "line2": "下のボタンをクリックしてメールアドレスを確認してください。",
                        "line3": "このリンクは 1 週間で期限切れになります。",
                        "line4": "弊社のアプリケーションをご利用いただきありがとうございます。",
                        "point_of_interest_shared": "興味深いスポットがあなたと共有されました。見つけられますよ\n                あなたの共有のアプリケーションに。",
                        "subject": "UgoZERへの招待"
                    }
                },
                "invitation_professional": {
                    "content": "私たちのアプリケーション UgoZER のプロフェッショナル セクションに招待されました",
                    "cta": "専門セクションに登録する",
                    "subject": "UgoZER - プロフェッショナル アクセスへの招待",
                    "thanks": "ありがとう、",
                    "title": "UgoZER アプリケーションのプロフェッショナル セクションへの招待"
                },
                "preview": {
                    "default": {
                        "subject": "メールの件名",
                        "user": {
                            "name": "ウーゴZER"
                        }
                    },
                    "from": "から：",
                    "mails": {
                        "auth/account-deleted-notification": "アカウントの削除",
                        "auth/not-logged-notification": "接続されていない場合は削除",
                        "auth/registration-attempt-notification": "登録を試みました",
                        "auth/registration-notification": "登録",
                        "auth/reset-password-notification": "パスワードを変更してください",
                        "invitation/invited-notification": "招待",
                        "share/created-notification": "新しい",
                        "support-message/message-notification": "新しい答え",
                        "support-request/archived-notification": "アーカイブされたリクエスト",
                        "support-request/created-notification": "作成されたリクエスト",
                        "user-credential-verification/login-attempt-notification": "接続を試みます",
                        "user-credential-verification/registration-attempt-notification": "登録を試みました",
                        "user-credential-verification/verify-notification": "アカウントの確認",
                        "user/welcome-notification": "いらっしゃいませ"
                    },
                    "main_title": "電子メールのプレビュー",
                    "sections": {
                        "auth": "認証",
                        "invitation": "招待状",
                        "share": "株式",
                        "support-message": "応援メッセージ",
                        "support-request": "サポートリクエスト",
                        "user": "ユーザー",
                        "user-credential-verification": "ユーザー資格情報"
                    }
                },
                "salutations": "よろしく",
                "share": {
                    "created": {
                        "action": "見せる",
                        "excursion": {
                            "gotoapp": "アプリにアクセスして表示してください。",
                            "line": " {poi_title} 機関の {username} があなたと旅程を共有しました。",
                            "line_alt": "{username} さんがあなたと旅程を共有しました。",
                            "line_alt_2": "旅程が共有されました。",
                            "subject": "旅程が共有されました。"
                        },
                        "line": " {poi_title}機関の{username}があなたとアイテムを共有しました。",
                        "line_alt": "{username} 人があなたとアイテムを共有しました。",
                        "line_alt_2": "アイテムがあなたと共有されました。",
                        "point_of_interest": {
                            "line": " {poi_title} 機関の {username} があなたと興味のある場所を共有しました。",
                            "line_alt": "{username} さんがあなたと興味のあるスポットを共有しました。",
                            "line_alt_2": "興味深いスポットがあなたと共有されました。",
                            "subject": "興味深いスポットがあなたと共有されました。"
                        },
                        "subject": "アイテムがあなたと共有されました。"
                    }
                },
                "subscription": {
                    "not_continue_after_free_period": {
                        "cta": "詳細を見る",
                        "line1": "無料期間終了後、{name} が続行されませんでした",
                        "line2": "下のボタンをクリックして詳細にアクセスしてください",
                        "subject": "無料期間終了後に購読を継続しなかった"
                    },
                    "suspended": {
                        "line": "あなたのサブスクリプションは次の理由により一時停止されました: {suspension_reason}。",
                        "line_alt": "あなたのサブスクリプションは一時停止されました。",
                        "subject": "購読停止中"
                    },
                    "switched_from_paid_to_free": {
                        "cta": "詳細を見る",
                        "line1": "{name} が無料プランに切り替えました",
                        "line2": "下のボタンをクリックして詳細にアクセスしてください",
                        "subject": "加入者が無料プランに切り替えた"
                    }
                },
                "support_request": {
                    "archived": {
                        "subject": "UgoZER - サポート リクエストがアーカイブされました",
                        "title": "サポートリクエストはアーカイブされました"
                    },
                    "message": {
                        "action": "サポートリクエストへのアクセス",
                        "line1": "リクエストにアクセスするには、下のボタンをクリックしてください。",
                        "subject": "UgoZER - あなたには答えがあります",
                        "title": "サポートリクエストに対する回答が投稿されました"
                    },
                    "request": {
                        "action": "サポートリクエストへのアクセス",
                        "line1": "サポートにお問い合わせください。48 時間以内に回答いたします。",
                        "line2": "リクエストにアクセスするには、下のボタンをクリックしてください。",
                        "subject": "UgoZER - サポート リクエストが作成されました"
                    }
                },
                "user_send_link": {
                    "content": "アプリケーション UgoZER に再接続してください。パスワードを忘れた場合は、リセットできます。",
                    "cta": "ログインアプリケーション",
                    "subject": "UgoZER - アプリケーション",
                    "thanks": "ありがとう、",
                    "title": "あなたは私たちのアプリケーションのアカウントを持っています、ログインしてください！"
                },
                "welcome": {
                    "action": "アプリケーションへのアクセス",
                    "line1": "あなたを当社のユーザーに加えていただけることを嬉しく思います。",
                    "line2": "下のボタンをクリックしてアプリケーションにアクセスしてください。",
                    "subject": "UgoZERへようこそ",
                    "title": "無料の多言語旅行支援アプリ、UgoZER へようこそ。"
                }
            },
            "media": {
                "audio": {
                    "generated_audio": "生成された音声",
                    "recording": {
                        "errors": {
                            "default": "録音に失敗しました",
                            "not_allowed": "録音に失敗しました。ブラウザと UgoZER がマイクを使用できるようにして、もう一度お試しください。"
                        }
                    }
                },
                "default": {
                    "alt": "Google マップの静的地図",
                    "caption": "地図をクリックするとGoogleマップで表示されます"
                },
                "image": {
                    "credits": "クレジット: {credits}"
                }
            },
            "models": {
                "activity_log": "アクティビティ ログ|アクティビティ ログ",
                "address": "住所|住所",
                "bank_transfer": "銀行振込|銀行振込",
                "bank_transfer_institution": "銀行振込機関|銀行振込機関",
                "category": "カテゴリ|カテゴリ",
                "changelog": "変更ログ|変更ログ",
                "claim": "クレーム|クレーム",
                "claim_dispute": "請求に関する紛争|請求に関する紛争",
                "currency": "通貨|通貨",
                "data_tourisme_category": "データツーリズム カテゴリ|データツーリズム カテゴリ",
                "data_tourisme_tag": "DataTourisme タグ|DataTourisme タグ",
                "excursion": "旅程|旅程",
                "institution_employee": "従業員|従業員",
                "interaction": "インタラクション|インタラクション",
                "locale": "言語|言語",
                "medium": "メディア|メディア",
                "notification": "通知|通知",
                "partner": "パートナー|パートナー",
                "payment": "支払い|支払い",
                "permission": "許可|許可",
                "plan": "サブスクリプション プラン|サブスクリプション プラン",
                "plan_price": "プラン料金|プラン料金",
                "point_of_interest": "名所|名所",
                "point_of_interest_category": "カテゴリ|カテゴリ",
                "post": "ポスト|ポスト",
                "post_category": "投稿カテゴリ|投稿カテゴリ",
                "privacy_policy": "プライバシー ポリシー|プライバシー ポリシー",
                "report": "レポート|レポート",
                "reservation": "予約|予約",
                "role": "役割|役割",
                "share": "共有|共有",
                "subscription": "サブスクリプション|サブスクリプション",
                "support_category": "サポート カテゴリ|サポート カテゴリ",
                "support_message": "メッセージ|メッセージ",
                "support_request": "リクエスト|リクエスト",
                "tag": "タグ|タグ",
                "terms_condition": "利用規約|利用規約",
                "traveller_email": "旅行者のメール|旅行者のメール",
                "traveller_folder": "トラベラー フォルダー|トラベラー フォルダー",
                "user": "ユーザー|ユーザー",
                "user_credential_verification": "資格情報の検証|資格情報の検証",
                "user_geoloc_setting": "地理位置情報設定|地理位置情報設定",
                "user_notification_setting": "通知設定|通知設定",
                "user_password_reset": "ユーザー パスワードのリセット|ユーザー パスワードのリセット",
                "user_privacy_setting": "プライバシー設定|プライバシー設定",
                "users_relationship": "連絡先|連絡先",
                "vat_rate": "VAT 率|VAT 率"
            },
            "navigations": {
                "items": {
                    "changelogs": "変更履歴",
                    "contact_us": "お問い合わせ",
                    "contacts": "連絡先",
                    "cookies": "Cookieの管理",
                    "credits": "クレジット",
                    "customers": "彼らは私たちを信頼しています",
                    "faqs": "よくある質問",
                    "folders": "旅行者フォルダー",
                    "invite_professional": "専門家を招待する",
                    "invite_traveller": "旅行者を招待する",
                    "partners": "パートナー",
                    "payments": "支払い",
                    "privacy_policy": "プライバシーポリシー",
                    "settings": "パラメーター",
                    "stays": "宿泊",
                    "terms_conditions": "利用規約",
                    "users": "ユーザー"
                }
            },
            "notifications": {
                "changelog": {
                    "created": {
                        "text": "バージョン {version} がデプロイされました。通知をクリックするとニュースを確認できます。",
                        "title": "新しい変更ログ"
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "text": "関心のある地点 {poi} に関する請求の紛争は、私たちのチームによる検証待ちです。",
                        "title": "クレームの異議申し立ては検証保留中"
                    },
                    "rejected": {
                        "no_reason": "理由は示されていない",
                        "text": " {poi} の対象地点に対する請求の異議申し立ては、次の理由で拒否されました: {reason}。",
                        "title": "請求に関する異議申し立ては拒否されました"
                    },
                    "validated": {
                        "text": " {poi} ポイントに関するクレーム紛争は受理されました。",
                        "title": "申し立ての異議が認められました!"
                    }
                },
                "index": {
                    "no_results": "通知はありません!"
                },
                "institution_employee": {
                    "approved": {
                        "text": " {poi} への参加リクエストが承認されました",
                        "title": "従業員のリクエストが承認されました"
                    },
                    "declined": {
                        "text": " {poi} への参加リクエストは拒否されました",
                        "title": "従業員のリクエストは拒否されました"
                    },
                    "pending": {
                        "text": "{user} あなたの機関の従業員として登録することを要求します {poi}",
                        "title": "従業員からのリクエストを受け取りました"
                    },
                    "removed": {
                        "text": " {poi} からの従業員ステータスが削除されました",
                        "title": "従業員ステータスが削除されました"
                    }
                },
                "invitation": {
                    "accepted": {
                        "email": {
                            "text": " {credential} に送信されたアプリケーションへの参加への招待が受理されました"
                        },
                        "phone": {
                            "text": " {credential} に送信されたアプリケーションへの参加への招待が受理されました"
                        },
                        "title": "招待を受諾しました"
                    },
                    "pro": {
                        "text": "プロフェッショナルへの招待状がメール {email} に送信されました",
                        "title": "プロフェッショナルな招待状が送信されました"
                    }
                },
                "office": {
                    "claim": {
                        "content": {
                            "line_1": "興味深い地点が主張されたところです。",
                            "line_2": "下のボタンをクリックすると、管理インターフェイスで詳細が表示されます。"
                        },
                        "cta": "クレームを参照",
                        "subject": "新たな主張！"
                    },
                    "claim_dispute": {
                        "content": {
                            "line_1": "注目の地点で争われたところです。",
                            "line_2": "下のボタンをクリックすると、管理インターフェイスで詳細が表示されます。"
                        },
                        "cta": "紛争を参照",
                        "subject": "新たな争い！"
                    },
                    "report": {
                        "content": {
                            "line_1": "{type} が報告されました。"
                        },
                        "cta": "レポートを参照",
                        "subject": "新しいレポートです！",
                        "type": {
                            "App\\Models\\PointOfInterest": "興味のある点",
                            "App\\Models\\User": "ユーザー"
                        }
                    },
                    "support_request": {
                        "content": {
                            "line_1": "新しいサポート リクエストが追加されました。"
                        },
                        "cta": "サポートリクエストを参照",
                        "subject": "新たなサポートリクエスト！"
                    }
                },
                "payment": {
                    "proposal": {
                        "text": "チップを残して {poi_title} 機関から {username} に感謝します",
                        "text_alt": " {poi_title} 機関のコンシェルジュにチップを残して感謝の気持ちを伝える",
                        "text_alt_2": "チップを残して {username} に感謝します",
                        "text_alt_3": "コンシェルジュにチップを残して感謝の気持ちを伝える",
                        "title": "チップを残す"
                    },
                    "received": {
                        "text": " {poi_title} の機関から {payer} から {amount} のチップを受け取りました",
                        "text_alt": " {payer} から {amount} チップを受け取りました",
                        "text_alt_2": "チップ {amount} を受け取りました",
                        "title": "チップを受け取りました"
                    },
                    "thanks": {
                        "text": " {poi_title} 機関からの {payee} ヒントありがとうございます!",
                        "text_alt": "{payee} ヒントありがとうございます!",
                        "text_alt_2": "チップに感謝されました",
                        "title": "ヒントをありがとう！"
                    }
                },
                "point_of_interest": {
                    "event": {
                        "expired": {
                            "text": "旅程に含まれているイベント {title} の有効期限が切れています",
                            "title": "イベントの有効期限が切れました"
                        }
                    },
                    "moderation": {
                        "denied": {
                            "text": "あなたの興味のあるスポット {title} は、モデレーションの後、拒否されました",
                            "title": "興味のあるスポットが拒否されました"
                        },
                        "published": {
                            "text": "あなたの興味のあるスポット {title} は、モデレーション後に公開されました",
                            "title": "注目スポットが公開されました"
                        }
                    }
                },
                "reservation": {
                    "created": {
                        "text": " {butler_poi_title} 機関の {butler_name} が予約しました\n                あなたの機関で {poi_title}",
                        "text_alt": "施設のコンシェルジュ {butler_poi_title} 名が予約しました\n                あなたの機関で {poi_title}",
                        "text_alt_2": "{butler_name} さんが予約しました\n                あなたの機関で {poi_title}",
                        "text_alt_3": "予約が行われました\n                あなたの機関で {poi_title}",
                        "title": "所属機関での予約"
                    }
                },
                "share": {
                    "created": {
                        "excursion": {
                            "text": " {poi_title} 機関の {username} があなたと旅程を共有しました",
                            "text_alt": "{username} さんがあなたと旅程を共有しました",
                            "text_alt_2": "旅程があなたと共有されました",
                            "title": "旅程を共有する"
                        },
                        "point_of_interest": {
                            "text": " {poi_title} 機関の {username} があなたと興味のあるスポットを共有しました",
                            "text_alt": "{username} さんがあなたと興味のあるスポットを共有しました",
                            "text_alt_2": "興味のあるスポットがあなたと共有されました",
                            "title": "興味のあるスポットを共有する"
                        },
                        "text": " {poi_title}機関の{username}があなたとアイテムを共有しました",
                        "text_alt": "{username} 人があなたとアイテムを共有しました",
                        "text_alt_2": "アイテムがあなたと共有されました"
                    }
                },
                "stripe": {
                    "account_requirements": {
                        "text": "Stripe アカウントの確認後、新しい情報が要求されます",
                        "title": "Stripe アカウントの要件"
                    },
                    "payments_enabled": {
                        "text": "Stripe を通じて支払いを受け取ることができるようになりました",
                        "title": "Stripe 支払いが有効になっています"
                    }
                },
                "support_request": {
                    "archived": {
                        "text": "サポートリクエストはアーカイブされました",
                        "title": "サポートリクエストがアーカイブされました"
                    },
                    "message": {
                        "text": "サポートリクエストに対する回答が投稿されました",
                        "title": "メッセージを受信しました"
                    }
                },
                "traveller_folder": {
                    "approved": {
                        "text": "{username} は自分の旅行者フォルダーを承認しました",
                        "text_alt": "旅行者が自分のフォルダーを承認しました",
                        "title": "トラベラーフォルダーが承認されました"
                    },
                    "archived": {
                        "text": "{username} は旅行者フォルダーをアーカイブしました",
                        "text_alt": "旅行者が自分のフォルダーをアーカイブしました",
                        "title": "Traveler フォルダーがアーカイブされました"
                    },
                    "created": {
                        "text": "ホテル {poi_title} での宿泊がアカウントに登録されました。",
                        "title": "創造性を維持する"
                    },
                    "pending": {
                        "text": "{username} があなたのアカウントにホテル {poi_title} への滞在を登録しました。\n                それを確認してください。",
                        "text_alt": "ホテル {poi_title} での宿泊がアカウントに登録されました。\n                それを確認してください。",
                        "title": "確認待ちのままにする"
                    },
                    "thanks": {
                        "text": " {poi_title}機関から{username} ご宿泊いただきありがとうございます",
                        "text_alt": " {poi_title} 機関のコンシェルジュ、ご宿泊ありがとうございます",
                        "title": "ご宿泊いただきありがとうございます"
                    }
                }
            },
            "nova": {
                "actions": {
                    "bank_transfer": {
                        "transfer": "銀行振込をする"
                    },
                    "changelog": {
                        "archive": "アーカイブ",
                        "draft": "下書き",
                        "publish": "公開"
                    },
                    "claim": {
                        "missing_info": "不足している情報",
                        "reject": "拒否する",
                        "revoke": "取り消す",
                        "validate": "検証"
                    },
                    "claim_dispute": {
                        "reject": "拒否する",
                        "validate": "検証"
                    },
                    "payment": {
                        "download_invoice": "請求書のダウンロード"
                    },
                    "plan": {
                        "archive": "アーカイブ",
                        "draft": "下書き",
                        "publish": "公開"
                    },
                    "point_of_interest": {
                        "deny": "拒否",
                        "draft": "下書き",
                        "publish": "検証",
                        "submit": "節度を求める"
                    },
                    "post": {
                        "archive": "アーカイブ",
                        "draft": "下書き",
                        "publish": "公開",
                        "set_coming_soon": "「近日公開」に設定します"
                    },
                    "privacy_policy": {
                        "archive": "アーカイブ",
                        "confirmation": {
                            "confirm_button_text": "公開",
                            "confirm_text": "このプライバシー ポリシーを公開してもよろしいですか?すべてのユーザーは再度検証するように求められます。"
                        },
                        "errors": {
                            "resources_count": "複数のプライベート ポリシーを公開することはできません。"
                        },
                        "publish": "公開"
                    },
                    "report": {
                        "process": "プロセス",
                        "reject": "拒否する"
                    },
                    "support_request": {
                        "answer": "答え",
                        "archive": "アーカイブ",
                        "cancel": "キャンセル",
                        "pending_support": "サポートを待っています",
                        "pending_user": "ユーザーを待っています",
                        "resolve": "解決する"
                    },
                    "terms_condition": {
                        "archive": "アーカイブ",
                        "confirmation": {
                            "confirm_button_text": "公開",
                            "confirm_text": "この利用規約を公開してもよろしいですか?すべてのユーザーは再度検証するように求められます。"
                        },
                        "errors": {
                            "resources_count": "複数の利用規約を公開することはできません。"
                        },
                        "publish": "公開"
                    },
                    "user": {
                        "finish": "チュートリアルを終了する"
                    },
                    "user_credential_verification": {
                        "validate": "検証"
                    }
                },
                "cards": {
                    "new_excursions": "新しい旅程",
                    "new_points_of_interest": "新しい名所",
                    "new_users": "新しい利用者"
                },
                "filters": {
                    "app": "応用",
                    "archived_at_gte": "後にアーカイブされました",
                    "archived_at_lte": "以前にアーカイブされました",
                    "canceled_at_gte": "以降キャンセル",
                    "canceled_at_lte": "以前にキャンセルされました",
                    "created_at_gte": "後に作成されました",
                    "created_at_lte": "以前に作成したもの",
                    "dynamic_translations_enabled": "動的翻訳が有効になっています",
                    "extranet": "エクストラネット",
                    "front": "フロント",
                    "group": "グループ",
                    "published_at_gte": "後に公開",
                    "published_at_lte": "以前に公開されました",
                    "reservation_date_gte": "以降のご予約",
                    "reservation_date_lte": "事前予約",
                    "resolved_at_gte": "解決後",
                    "resolved_at_lte": "以前に解決済み",
                    "static_translations_enabled": "静的翻訳が有効になっています",
                    "to_validate": "検証します"
                },
                "labels": {
                    "institutions": "機関",
                    "point_of_interest_categories": "カテゴリー"
                }
            },
            "opening_hours": {
                "additional_info": "追加情報",
                "content": {
                    "date": "×1",
                    "date_range_from": " {from}から",
                    "date_range_from_to": " {from}から {to}へ",
                    "date_range_to": "×1へ",
                    "hours_range_from": " {from}から",
                    "hours_range_from_to": " {from}から {to}へ",
                    "hours_range_to": "×1へ"
                }
            },
            "partners": {
                "customers": {
                    "title": "当社のお客様"
                },
                "index": {
                    "no_results": "パートナーが見つかりませんでした",
                    "our_last_partners": "私たちの最後のパートナー",
                    "title": "当社のパートナー"
                },
                "show": {
                    "link": "ウェブサイトを訪問"
                }
            },
            "payments": {
                "checkout": {
                    "cancel": "支払いがキャンセルされました",
                    "custom_amount": "カスタム金額を入力してください",
                    "success": "支払いが正常に完了しました",
                    "tip_amount": "チップの金額"
                },
                "index": {
                    "no_results": "支払いが見つかりませんでした"
                },
                "labels": {
                    "payee": "受取人:",
                    "payment_service": "支払者"
                },
                "payable": {
                    "reservation": "予約番号{id}"
                },
                "thanks": {
                    "success": "ありがとうを送りました！"
                }
            },
            "pdf": {
                "common": {
                    "generating": "PDF ファイルを生成しています...",
                    "waiting": "お待ちください..."
                },
                "payments": {
                    "period": " {from}から {to}までの期間",
                    "title": "支払いの受領"
                },
                "receipt": {
                    "debited_amount": "借方金額",
                    "paid_amount": "支払金額",
                    "payment_date": "支払期日",
                    "payment_method": "支払方法",
                    "receipt_for": "への支払いの領収書",
                    "receipt_number": "支払いの領収書 #{id}",
                    "summary": "まとめ"
                },
                "share": {
                    "cta": "旅程を確認する",
                    "details": {
                        "line1": "あなたの個人的な旅程を喜んで共有させていただきます。",
                        "line2": "旅程の内容は以下にあります。",
                        "line3": "詳細情報にアクセスするには（現地の言語での住所を含む音声、インタラクティブな地図、\n                写真、詳細な説明...)、下のボタンをクリックしてください。"
                    }
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "ADMIN": "管理にアクセスする",
                        "APP": "アプリにアクセスする",
                        "BYPASS_PERMISSIONS": "アクセスに関連する権限を無視する",
                        "EXTRANET": "専門セクションにアクセスする",
                        "HORIZON": "アクセスホライズン",
                        "TELESCOPE": "アクセス望遠鏡",
                        "VIEW_BUTLER_DASHBOARD": "バトラーダッシュボードを表示する",
                        "VIEW_HOTELIER_DASHBOARD": "ホテル経営者のダッシュボードを表示する",
                        "VIEW_MANAGER_DASHBOARD": "マネージャーのダッシュボードを表示する",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "観光案内所のダッシュボードを表示する",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "観光局の従業員ダッシュボードを表示する",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "資格情報を確認せずにログインする"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "アクティビティ ログに関連する権限を無視する",
                        "CREATE": "アクティビティログを作成する",
                        "DELETE": "アクティビティログを削除する",
                        "FORCE_DELETE": "アクティビティ ログを強制的に削除する",
                        "RESTORE": "アクティビティ ログを復元する",
                        "UPDATE": "アクティビティログを更新する",
                        "VIEW": "アクティビティ ログを参照する",
                        "VIEW_ANY": "アクティビティ ログのインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "アドレスに関連する権限を無視する",
                        "CREATE": "アドレスを作成する",
                        "DELETE": "アドレスを削除する",
                        "FORCE_DELETE": "アドレスを強制的に削除する",
                        "RESTORE": "アドレスを復元する",
                        "UPDATE": "住所を更新する",
                        "VIEW": "住所を調べる",
                        "VIEW_ANY": "住所のインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "銀行振込機関に関連する許可を無視する",
                        "VIEW_ANY": "銀行振込機関のインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "ARCHIVE": "変更ログをアーカイブする",
                        "BYPASS_PERMISSIONS": "変更ログに関連する権限を無視する",
                        "CREATE": "変更履歴を作成する",
                        "DELETE": "変更履歴を削除する",
                        "DRAFT": "変更履歴の草稿を作成する",
                        "FORCE_DELETE": "変更ログを強制的に削除する",
                        "PUBLISH": "変更履歴を公開する",
                        "RESTORE": "変更ログを復元する",
                        "UPDATE": "変更履歴を更新する",
                        "VIEW": "変更ログを参照してください",
                        "VIEW_ANY": "変更ログのインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "請求に関する紛争に関連する許可を無視する",
                        "CREATE": "請求に関する異議申し立てを作成する",
                        "DELETE": "請求に関する異議申し立てを削除する",
                        "FORCE_DELETE": "申し立てに関する異議申し立てを強制的に削除する",
                        "REJECT": "請求に関する異議申し立てを拒否する",
                        "RESTORE": "請求に関する紛争を回復する",
                        "UPDATE": "請求に関する異議申し立てを更新する",
                        "VALIDATE": "請求の異議を検証する",
                        "VIEW": "請求に関する紛争を相談する",
                        "VIEW_ANY": "請求紛争のインデックスを参照"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "クレームに関連する権限を無視する",
                        "CREATE": "請求を作成する",
                        "DELETE": "申し立てを削除する",
                        "FORCE_DELETE": "申し立てを強制的に削除する",
                        "MISSING_INFO": "情報が不足しているとして申し立てを拒否する",
                        "REJECT": "クレームを拒否する",
                        "RESTORE": "申し立てを復元する",
                        "REVOKE": "請求を取り消す",
                        "SHOW_FILE": "請求ファイルを表示する",
                        "UPDATE": "申し立てを更新する",
                        "VALIDATE": "主張を検証する",
                        "VIEW": "クレームを相談する",
                        "VIEW_ANY": "クレームのインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "国に関連する許可を無視する",
                        "USE_ANY": "どの国でも使用可能"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "通貨に関連する権限を無視する",
                        "CREATE": "通貨を作成する",
                        "DELETE": "通貨を削除する",
                        "FORCE_DELETE": "通貨を強制的に削除する",
                        "RESTORE": "通貨を復元する",
                        "UPDATE": "通貨を更新する",
                        "VIEW": "通貨を相談する",
                        "VIEW_ANY": "通貨のインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "EasyTransac アカウントに関連する権限を無視する",
                        "HAVE": "EasyTransac アカウントを持っている"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "旅程に関連する権限を無視する",
                        "CREATE": "旅程を作成する",
                        "DELETE": "旅程を削除する",
                        "FORCE_DELETE": "旅程を強制的に削除する",
                        "RESTORE": "旅程を復元する",
                        "SHARE": "旅程を共有する",
                        "UPDATE": "旅程を更新する",
                        "VIEW": "旅程を相談する",
                        "VIEW_ANY": "旅程のインデックスを参照",
                        "VIEW_KIND_UGOZER_APP": "UgoZERアプリの種類で旅程を表示",
                        "VIEW_KIND_UGOZER_PRO": "UgoZER Pro タイプの旅程を表示する"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "旅程と観光スポットの間のリンクに関連する許可を無視する",
                        "CREATE": "旅程と観光スポットの間にリンクを作成する",
                        "DELETE": "旅程と観光スポットの間のリンクを削除する",
                        "FORCE_DELETE": "旅程と観光スポットの間のリンクを強制的に削除します",
                        "RESTORE": "旅程と観光スポットの間のリンクを復元する",
                        "UPDATE": "旅程と観光スポットの間のリンクを更新する",
                        "VIEW": "旅程と観光スポットの間のリンクを参照する",
                        "VIEW_ANY": "旅程と観光スポットの間のリンクのインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "APPROVE": "教育機関の従業員のリクエストを承認する",
                        "BYPASS_PERMISSIONS": "施設の従業員に関連する許可を無視する",
                        "CANCEL": "教育機関の従業員のリクエストをキャンセルする",
                        "CREATE": "施設の従業員を作成する",
                        "DECLINE": "施設職員のリクエストを拒否する",
                        "DELETE": "教育機関の従業員を削除する",
                        "FEATURE": "教育機関の従業員を特集する",
                        "FORCE_DELETE": "教育機関の従業員を強制的に削除する",
                        "REMOVE": "従業員を教育機関から削除する",
                        "RESTORE": "施設の従業員を回復する",
                        "UPDATE": "施設の従業員を更新する",
                        "VIEW": "施設職員に相談する",
                        "VIEW_ANY": "機関の従業員のインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "ARCHIVE": "インタラクションをアーカイブする",
                        "BYPASS_PERMISSIONS": "インタラクションに関連する権限を無視する",
                        "CREATE": "インタラクションを作成する",
                        "DELETE": "インタラクションを削除する",
                        "DRAFT": "インタラクションの草案を作成する",
                        "FORCE_DELETE": "インタラクションを強制的に削除する",
                        "PUBLISH": "インタラクションを公開する",
                        "RESTORE": "インタラクションを復元する",
                        "UPDATE": "インタラクションを更新する",
                        "VIEW": "インタラクションを相談する",
                        "VIEW_ANY": "インタラクションのインデックスを参照"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "言語に関連する権限を無視する",
                        "CREATE": "言語を作成する",
                        "DELETE": "言語を削除する",
                        "FORCE_DELETE": "言語を強制的に削除する",
                        "RESTORE": "言語を復元する",
                        "UPDATE": "言語を更新する",
                        "VIEW": "言語を相談する",
                        "VIEW_ANY": "言語の索引を参照してください"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "メディアに関連する権限を無視する",
                        "CREATE": "メディアを作成する",
                        "DELETE": "メディアを削除する",
                        "FORCE_DELETE": "メディアを強制的に削除する",
                        "RESTORE": "メディアを復元する",
                        "UPDATE": "メディアを更新する",
                        "VIEW": "メディアに相談する",
                        "VIEW_ANY": "メディアのインデックスを参照"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "招待が受け入れられたときに通知を受け取る",
                        "PAYMENT_RECEIVED": "支払いを受け取ったときに通知を受け取る",
                        "PAYMENT_THANKS": "支払いが確認されたときに通知を受け取る",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "注目ポイントのイベントの有効期限が切れたときに通知を受け取る",
                        "RESERVATION_CREATED": "予約が作成されたときに通知を受け取る",
                        "SHARE_CREATED": "共有が作成されたときに通知を受け取る",
                        "SUPPORT_REQUEST_ARCHIVED": "サポート リクエストがアーカイブされたときに通知を受け取る",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "サポートからメッセージを受信したときに通知を受け取る\n                リクエスト",
                        "TRAVELLER_FOLDER_APPROVED": "旅行者ファイルが承認されたときに通知を受け取る",
                        "TRAVELLER_FOLDER_ARCHIVED": "旅行者のファイルがアーカイブされたときに通知を受け取る",
                        "TRAVELLER_FOLDER_PENDING": "ファイルがトラベラーとして検証されるのを待っている間に通知を受け取る",
                        "TRAVELLER_FOLDER_THANKS": "ファイル旅行者を担当するコンシェルジュに感謝の通知を受け取る"
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "パートナーに関連する権限を無視する",
                        "CREATE": "パートナーを作成する",
                        "DELETE": "パートナーを削除する",
                        "FORCE_DELETE": "パートナーを強制的に削除する",
                        "RESTORE": "パートナーを回復する",
                        "UPDATE": "パートナーを更新する",
                        "VIEW": "パートナーに相談する",
                        "VIEW_ANY": "パートナーのインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "支払いに関連する権限を無視する",
                        "CREATE": "支払いを作成する",
                        "DELETE": "支払いを削除する",
                        "FORCE_DELETE": "支払いを強制的に削除する",
                        "GET_RECEIPT": "支払いの領収書を受け取る",
                        "RECEIVE": "支払いを受け取る",
                        "RESTORE": "支払いを復元する",
                        "THANK": "支払いのお礼を受け取る",
                        "UPDATE": "支払いを更新する",
                        "VIEW": "支払いについて相談する",
                        "VIEW_ANY": "支払いインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "権限に関連する権限を無視する",
                        "CREATE": "権限を作成する",
                        "DELETE": "権限を削除する",
                        "FORCE_DELETE": "権限を強制的に削除する",
                        "RESTORE": "権限を復元する",
                        "UPDATE": "権限を更新する",
                        "VIEW": "許可を確認する",
                        "VIEW_ANY": "権限のインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "ARCHIVE": "サブスクリプションプランをアーカイブする",
                        "BYPASS_PERMISSIONS": "サブスクリプションプランに関連する権限を無視する",
                        "CREATE": "サブスクリプションプランを作成する",
                        "DELETE": "サブスクリプションプランを削除する",
                        "DRAFT": "サブスクリプションプランの草案を作成する",
                        "FORCE_DELETE": "サブスクリプションプランを強制的に削除する",
                        "PUBLISH": "サブスクリプションプランを公開する",
                        "RESTORE": "サブスクリプションプランを復元する",
                        "SUBSCRIBE": "プランに加入する",
                        "UPDATE": "サブスクリプションプランを更新する",
                        "VIEW": "サブスクリプションプランを相談する",
                        "VIEW_ANY": "サブスクリプションプランのインデックスを参照"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "興味のあるスポットのカテゴリに関連する権限を無視する",
                        "CREATE": "興味のある場所のカテゴリを作成する",
                        "DELETE": "興味のあるスポットのカテゴリを削除する",
                        "FORCE_DELETE": "興味のある場所のカテゴリを強制的に削除する",
                        "RESTORE": "POI カテゴリを復元する",
                        "UPDATE": "観光スポットのカテゴリを更新する",
                        "VIEW": "興味のあるスポットのカテゴリを参照してください",
                        "VIEW_ANY": "観光スポットのカテゴリのインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "ADMIN_ANY": "あらゆる興味のあるポイントを管理",
                        "APPLY": "社員に応募する",
                        "BYPASS_PERMISSIONS": "観光スポットに関連する許可を無視する",
                        "CLAIM": "興味のある場所を主張する",
                        "CONTEST": "興味のある場所にコンテストをする",
                        "CONTRIBUTE": "注目のスポットに貢献する",
                        "CREATE": "興味のある場所を作成する",
                        "DELETE": "興味のあるスポットを削除する",
                        "DENY": "興味のある場所を拒否する",
                        "DRAFT": "関心のある地点の下書きを作成する",
                        "FORCE_DELETE": "興味のある地点を強制的に削除する",
                        "IMPORT": "興味のある場所をインポートする",
                        "MODERATE": "興味のあるスポットをモデレートする",
                        "PUBLISH": "興味のあるスポットを公開する",
                        "REPORT": "興味のあるスポットを報告する",
                        "RESTORE": "興味のある場所を復元する",
                        "SHARE": "興味のあるスポットを共有する",
                        "SUBMIT": "興味のあるスポットを送信する",
                        "UPDATE": "興味のあるスポットを更新する",
                        "UPDATE_CERTIFIED": "興味のある地点の認定された属性を更新する",
                        "VIEW": "興味のある場所に相談する",
                        "VIEW_ANY": "興味のあるポイントのインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "投稿カテゴリに関連する権限を無視する",
                        "CREATE": "投稿カテゴリーを作成する",
                        "DELETE": "投稿カテゴリーを削除する",
                        "FORCE_DELETE": "投稿カテゴリを強制的に削除する",
                        "RESTORE": "投稿カテゴリーを復元する",
                        "UPDATE": "投稿カテゴリーを更新する",
                        "VIEW": "投稿カテゴリを参照してください",
                        "VIEW_ANY": "投稿カテゴリのインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "ARCHIVE": "投稿をアーカイブする",
                        "BYPASS_PERMISSIONS": "投稿に関連する権限を無視する",
                        "CREATE": "投稿を作成する",
                        "DELETE": "投稿を削除する",
                        "DRAFT": "投稿の下書きを作成する",
                        "FORCE_DELETE": "投稿を強制的に削除する",
                        "PUBLISH": "投稿を公開する",
                        "RESTORE": "投稿を復元する",
                        "UPDATE": "投稿を更新する",
                        "VIEW": "投稿を参照する",
                        "VIEW_ANY": "投稿のインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "ARCHIVE": "プライバシー ポリシーをアーカイブする",
                        "BYPASS_PERMISSIONS": "プライバシー ポリシーに関連する権限を無視する",
                        "CREATE": "プライバシーポリシーを作成する",
                        "DELETE": "プライバシー ポリシーを削除する",
                        "DRAFT": "プライバシーポリシーの草案を作成する",
                        "FORCE_DELETE": "プライバシー ポリシーを強制的に削除する",
                        "PUBLISH": "プライバシーポリシーを発行する",
                        "RESTORE": "プライバシー ポリシーを復元する",
                        "UPDATE": "プライバシーポリシーを更新する",
                        "VIEW": "プライバシー ポリシーを参照する",
                        "VIEW_ANY": "プライバシー ポリシーを参照する"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "この権限を持つ任意の役割でプロフェッショナル セクションに登録します。"
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "ARCHIVE": "レポートをアーカイブする",
                        "BYPASS_PERMISSIONS": "レポートに関連する権限を無視する",
                        "CREATE": "レポートを作成する",
                        "DELETE": "レポートを削除する",
                        "DRAFT": "レポートの草案を作成する",
                        "FORCE_DELETE": "レポートを強制的に削除する",
                        "PROCESS_ANY": "あらゆるレポートを処理する",
                        "PUBLISH": "レポートを発行する",
                        "REJECT_ANY": "いかなる報告も拒否する",
                        "RESTORE": "レポートを復元する",
                        "UPDATE": "レポートを更新する",
                        "VIEW": "レポートを参照する",
                        "VIEW_ANY": "レポートのインデックスを参照"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "ARCHIVE": "予約をアーカイブする",
                        "BYPASS_PERMISSIONS": "予約に関連する権限を無視する",
                        "CANCEL": "予約をキャンセルする",
                        "CONFIRM": "予約を確認する",
                        "CREATE": "予約を作成する",
                        "DELETE": "予約を削除する",
                        "FORCE_DELETE": "予約を強制的に削除する",
                        "RESTORE": "予約を復元する",
                        "UPDATE": "予約を更新する",
                        "VIEW": "予約を相談する",
                        "VIEW_ANY": "予約のインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "ロールに関連する権限を無視する",
                        "CREATE": "ロールを作成する",
                        "DELETE": "役割を削除する",
                        "FORCE_DELETE": "ロールを強制的に削除する",
                        "RESTORE": "役割を復元する",
                        "UPDATE": "役割を更新する",
                        "VIEW": "役割について相談する",
                        "VIEW_ANY": "役割のインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "共有に関連する権限を無視する",
                        "CREATE": "共有を作成する",
                        "DELETE": "共有を削除する",
                        "FORCE_DELETE": "共有を強制的に削除する",
                        "RESTORE": "共有を復元する",
                        "UPDATE": "共有を更新する",
                        "VIEW": "シェアを相談する",
                        "VIEW_ANY": "株価指数を参照する"
                    },
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Stripe アカウントに関連する権限を無視する",
                        "HAVE": "Stripe アカウントをお持ちです"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "サブスクリプションに関連する権限を無視する",
                        "CANCEL": "定期購入をキャンセルする",
                        "CREATE": "サブスクリプションを作成する",
                        "DELETE": "サブスクリプションを削除する",
                        "FORCE_DELETE": "サブスクリプションを強制的に削除する",
                        "PAY": "購読料を支払う",
                        "RESTORE": "サブスクリプションを復元する",
                        "UPDATE": "サブスクリプションを更新する",
                        "VIEW": "サブスクリプションを相談する",
                        "VIEW_ANY": "サブスクリプションのインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "サポート カテゴリ リクエストに関連する権限を無視する",
                        "CREATE": "サポート カテゴリを作成する",
                        "DELETE": "サポート カテゴリを削除する",
                        "FORCE_DELETE": "サポート カテゴリを強制的に削除する",
                        "RESTORE": "サポート カテゴリを復元する",
                        "UPDATE": "サポート カテゴリを更新する",
                        "VIEW": "サポート カテゴリに問い合わせる",
                        "VIEW_ANY": "サポート カテゴリのインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "メッセージに関連する権限を無視する",
                        "CREATE": "メッセージを作成する",
                        "DELETE": "メッセージを削除する",
                        "FORCE_DELETE": "メッセージを強制的に削除する",
                        "RESTORE": "メッセージを復元する",
                        "UPDATE": "メッセージを更新する",
                        "VIEW": "メッセージを参照してください",
                        "VIEW_ANY": "メッセージのインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "ADD_MESSAGE": "メッセージを追加",
                        "BYPASS_PERMISSIONS": "サポートリクエストに関連する権限を無視する",
                        "CANCEL": "サポートリクエストをキャンセルする",
                        "CREATE": "サポートリクエストを作成する",
                        "DELETE": "サポートリクエストを削除する",
                        "FORCE_DELETE": "サポートリクエストを強制的に削除する",
                        "RESTORE": "サポートリクエストを復元する",
                        "UPDATE": "サポートリクエストを更新する",
                        "VIEW": "サポートリクエストを問い合わせる",
                        "VIEW_ANY": "サポートリクエストのインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "タグに関連する権限を無視する",
                        "CREATE": "タグを作成する",
                        "DELETE": "タグを削除する",
                        "FORCE_DELETE": "タグを強制的に削除する",
                        "RESTORE": "タグを復元する",
                        "UPDATE": "タグを更新する",
                        "VIEW": "タグを参照する",
                        "VIEW_ANY": "タグのインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "ARCHIVE": "利用規約をアーカイブする",
                        "BYPASS_PERMISSIONS": "利用規約に関連する許可を無視する",
                        "CREATE": "利用規約を作成する",
                        "DELETE": "利用規約を削除する",
                        "DRAFT": "利用規約の草案を作成する",
                        "FORCE_DELETE": "利用規約を強制的に削除する",
                        "PUBLISH": "利用規約を公開する",
                        "RESTORE": "利用規約を復元する",
                        "UPDATE": "利用規約を更新する",
                        "VIEW": "利用規約を確認する",
                        "VIEW_ANY": "利用規約を参照する"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "翻訳可能なレコードに関連する権限を無視する",
                        "REQUIRE_ANY": "必須フィールドを英語以外の言語でも翻訳できるようにする"
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "旅行者の電子メールに関連する権限を無視する",
                        "CREATE": "旅行者用メールを作成する",
                        "DELETE": "旅行者のメールを削除する",
                        "FORCE_DELETE": "旅行者のメールを強制的に削除する",
                        "RESTORE": "旅行者のメールを復元する",
                        "UPDATE": "旅行者のメールを更新する",
                        "VIEW": "旅行者のメールに問い合わせる",
                        "VIEW_ANY": "旅行者の電子メールのインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "APPROVE": "旅行者フォルダーを承認する",
                        "ARCHIVE": "旅行者フォルダーをアーカイブする",
                        "BYPASS_PERMISSIONS": "旅行者フォルダーに関連する権限を無視する",
                        "CREATE": "旅行者フォルダーを作成する",
                        "DECLINE": "旅行者フォルダーを拒否する",
                        "DELETE": "旅行者フォルダーを削除する",
                        "FORCE_DELETE": "旅行者フォルダーを強制的に削除する",
                        "RESTORE": "旅行者フォルダーを復元する",
                        "THANK": "旅行者に宿泊していただきありがとうございます",
                        "UPDATE": "旅行者フォルダーを更新する",
                        "VIEW": "旅行者フォルダーを参照してください",
                        "VIEW_ANY": "旅行者フォルダーのインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "ユーザー資格情報の検証に関連する権限を無視する",
                        "CREATE": "ユーザー資格情報検証を作成する",
                        "DELETE": "ユーザー資格情報検証を削除する",
                        "FORCE_DELETE": "ユーザー資格情報検証を強制的に削除する",
                        "RESTORE": "ユーザー資格情報の検証を復元する",
                        "UPDATE": "ユーザー資格情報検証を更新する",
                        "VIEW": "ユーザー資格情報の検証を参照してください",
                        "VIEW_ANY": "ユーザー資格情報検証のインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "ADD_CONTACT": "ユーザーを連絡先として追加する",
                        "BYPASS_PERMISSIONS": "ユーザーに関連する権限を無視する",
                        "CREATE": "ユーザーを作成する",
                        "DELETE": "ユーザーを削除する",
                        "FORCE_DELETE": "ユーザーを強制的に削除する",
                        "INVITE": "ユーザーを招待する",
                        "REPORT": "ユーザーを報告する",
                        "RESTORE": "ユーザーを復元する",
                        "TIP": "ユーザーにチップを渡す",
                        "UPDATE": "ユーザーを更新する",
                        "UPDATE_BANK_ACCOUNT": "銀行口座を更新する",
                        "VIEW": "ユーザーに相談する",
                        "VIEW_ANY": "ユーザーのインデックスを参照",
                        "VIEW_AVATAR": "ユーザーのアバターを表示する",
                        "VIEW_CONTACT_EMAIL": "ユーザーの連絡先メールアドレスを表示する",
                        "VIEW_CONTACT_PHONE": "ユーザーの連絡先電話番号を表示する",
                        "VIEW_REAL_NAME": "ユーザーの実名を表示する"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "ユーザーの geoloc 設定に関連する権限を無視する",
                        "CREATE": "ユーザー地理位置情報設定を作成する",
                        "DELETE": "ユーザーの地理位置情報設定を削除する",
                        "FORCE_DELETE": "ユーザーの地理位置情報設定を強制的に削除する",
                        "RESTORE": "ユーザーの地理位置情報設定を復元する",
                        "UPDATE": "ユーザーの地理位置情報設定を更新する",
                        "VIEW": "ユーザーの地理位置情報設定を参照してください",
                        "VIEW_ANY": "ユーザーの地理位置情報設定のインデックスを参照する"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "ユーザー通知設定に関連する権限を無視する",
                        "CREATE": "ユーザー通知設定を作成する",
                        "DELETE": "ユーザー通知設定を削除する",
                        "FORCE_DELETE": "ユーザー通知設定を強制的に削除する",
                        "RESTORE": "ユーザー通知設定を復元する",
                        "UPDATE": "ユーザー通知設定を更新する",
                        "VIEW": "ユーザー通知設定を参照してください",
                        "VIEW_ANY": "ユーザー通知設定のインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "ユーザーのプライバシー設定に関連する権限を無視する",
                        "CREATE": "ユーザーのプライバシー設定を作成する",
                        "DELETE": "ユーザーのプライバシー設定を削除する",
                        "FORCE_DELETE": "ユーザーのプライバシー設定を強制的に削除する",
                        "RESTORE": "ユーザーのプライバシー設定を復元する",
                        "UPDATE": "ユーザーのプライバシー設定を更新する",
                        "VIEW": "ユーザーのプライバシー設定を参照する",
                        "VIEW_ANY": "ユーザーのプライバシー設定のインデックスを参照してください"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "APPROVE_CONTACT": "ユーザー関係を承認する",
                        "BLOCK_CONTACT": "ユーザー関係をブロックする",
                        "BYPASS_PERMISSIONS": "ユーザー関係に関連する権限を無視する",
                        "CREATE": "ユーザー関係を作成する",
                        "DECLINE_CONTACT": "ユーザー関係を断る",
                        "DELETE": "ユーザー関係を削除する",
                        "FORCE_DELETE": "ユーザー関係を強制的に削除する",
                        "RESTORE": "ユーザー関係を復元する",
                        "UNBLOCK_CONTACT": "ユーザー関係のブロックを解除する",
                        "UPDATE": "ユーザー関係を更新する",
                        "VIEW": "ユーザー関係を相談する",
                        "VIEW_ANY": "ユーザー関係のインデックスを参照してください"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\AccessEnum": "アクセス",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "アクティビティログ",
                    "App\\Enums\\Permissions\\AddressEnum": "住所",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "銀行振込機関",
                    "App\\Enums\\Permissions\\ChangelogEnum": "変更履歴",
                    "App\\Enums\\Permissions\\ClaimEnum": "請求",
                    "App\\Enums\\Permissions\\CountryEnum": "国",
                    "App\\Enums\\Permissions\\CurrencyEnum": "通貨",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "EasyTransac アカウント",
                    "App\\Enums\\Permissions\\ExcursionEnum": "旅程",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "旅程と観光スポット間のリンク",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "施設職員",
                    "App\\Enums\\Permissions\\InteractionEnum": "インタラクション",
                    "App\\Enums\\Permissions\\LocaleEnum": "言語",
                    "App\\Enums\\Permissions\\MediumEnum": "メディア",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "通知を受け取る",
                    "App\\Enums\\Permissions\\PartnerEnum": "パートナー",
                    "App\\Enums\\Permissions\\PaymentEnum": "支払い",
                    "App\\Enums\\Permissions\\PermissionEnum": "権限",
                    "App\\Enums\\Permissions\\PlanEnum": "サブスクリプションプラン",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "観光スポットのカテゴリ",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "興味がある点",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "投稿カテゴリー",
                    "App\\Enums\\Permissions\\PostEnum": "投稿",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "プライバシーポリシー",
                    "App\\Enums\\Permissions\\RegistrationEnum": "登録",
                    "App\\Enums\\Permissions\\ReportEnum": "レポート",
                    "App\\Enums\\Permissions\\ReservationEnum": "予約",
                    "App\\Enums\\Permissions\\RoleEnum": "役割",
                    "App\\Enums\\Permissions\\ShareEnum": "株式",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "ストライプアカウント",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "定期購入",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "サポートリクエストのカテゴリ",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "応援メッセージ",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "サポートリクエスト",
                    "App\\Enums\\Permissions\\TagEnum": "タグ",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "利用規約",
                    "App\\Enums\\Permissions\\TranslatableEnum": "翻訳可能なレコード",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "旅行者の電子メール",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "旅行者フォルダー",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "ユーザー資格情報の検証",
                    "App\\Enums\\Permissions\\UserEnum": "ユーザー",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "ユーザーの地理位置情報設定",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "ユーザー通知設定",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "ユーザーのプライバシー設定",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "ユーザー関係"
                }
            },
            "plans": {
                "archive": {
                    "success": "サブスクリプション プランはアーカイブされています (対象となる場合)"
                },
                "confirmation": {
                    "benefit_current_subscription": "現在のサブスクリプションを引き続きご利用いただけます\n            支払い済みの月末まで。",
                    "engagement_period": "このオファーには、次のエンゲージメント期間が含まれます\n            1回目の支払い後に {engagement_period}。",
                    "fee": "このオファーには {fee_percentage}% の手数料がかかり、以下の特典が受けられます。\n            チップの 2% を受け取ります。",
                    "free": "この購読は無料ですが、チップ収集アカウントの作成が条件となります。",
                    "free_period": "無料期間が 1 倍になります。",
                    "free_period_used": "無料期間をすでに使い切ってしまったため、無料期間は利用できなくなります\n            このオファーによって提案される {free_period} の恩恵を受けることができます。",
                    "name": "「{name}」オファーに登録しようとしています。",
                    "name_launch": "「{name}」のローンチオファーに登録しようとしています。",
                    "no_fee": "このオファーの手数料は 0% で、チップを全額受け取ることができます。",
                    "please_confirm": "下のボタンをクリックして購読を確認してください。",
                    "price_summary": "このサブスクリプションには費用がかかります\n            {price}を除く税金 / {price_incl_taxes} 税込毎月の税金。",
                    "replace_current_subscription": "このサブスクリプションは、現在のサブスクリプションを自動的に置き換えます。"
                },
                "draft": {
                    "success": "サブスクリプション プランが作成されている (適格な場合)"
                },
                "free_period_ended_in": "無料期間終了 {in}",
                "publish": {
                    "success": "サブスクリプション プランが公開されている (対象となる場合)"
                },
                "set_coming_soon": {
                    "success": "サブスクリプション プランは「近日公開」に設定されています (対象となる場合)"
                },
                "your_current_plan": "現在のプラン"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "このボックスにチェックを入れることにより、私は\n            設立者が主張した。虚偽の陳述またはなりすましは民事および刑事の対象となります\n            適用される法律に従って罰則が課せられます。",
                    "success": "あなたの申し立ては検証のために提出されました。"
                },
                "denied": {
                    "for_the_reason": "以下の理由により拒否されました",
                    "moderation_again": "関心のあるポイントを編集すると、モデレートのために再度送信され、公開できるようになります。"
                },
                "deny": {
                    "success": "対象のポイントが拒否されました (対象となる場合)"
                },
                "destroy": {
                    "confirm": "この注目ポイント {point_of_interest_name} を削除してもよろしいですか?",
                    "success": "興味のあるスポットは削除されました"
                },
                "draft": {
                    "success": "関心のあるポイントがドラフトされています (適格な場合)"
                },
                "duplicates": {
                    "check_explanation1": "興味のあるポイントを作成するには、\n            以下の主要なフィールドに入力してください。",
                    "check_explanation2": "回避するためにチェックが実行されます\n            既存のポイントを入力します。",
                    "check_explanation3": "確認後、完了できるようになります\n            ポイントの情報を入力して保存します。",
                    "check_explanation_title": "検証",
                    "continue_creation": "作成を続ける",
                    "found_explanation1": "に対応する 1 つ以上の注目スポット\n            入力基準はすでに存在します。",
                    "found_explanation2": "これは重複していないと思われる場合は、\n            その後、新しい興味のある場所の入力に進むことができます。",
                    "match_percentage": " {percentage}% に一致",
                    "select_duplicate": "提案された興味のあるスポットの 1 つをクリックすると、詳細が表示されます",
                    "use_poi": "この観光スポットを利用する",
                    "view_poi": "この注目スポットを見る"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "この観光スポットを旅程に追加する",
                    "already_in_excursions": "この名所はすでに旅程の一部になっています",
                    "create_excursion": "旅程を作成する",
                    "or_create_from": "または、この興味のある地点から新しい旅程を作成します",
                    "select_which": "興味のある地点「{point_of_interest_name}」を追加する既存の旅程を選択してください",
                    "success": "観光スポットが旅程に追加されました"
                },
                "form": {
                    "help": {
                        "stars": "カテゴリに星を付けることができる場合のみ (ホテル、レストランなど)"
                    }
                },
                "import_source": {
                    "DataTourisme": "データツーリズム"
                },
                "index": {
                    "no_results": "興味のあるスポットが見つかりませんでした",
                    "no_results_here": "検索エリアに興味のあるスポットはありません。",
                    "no_tags": "タグが定義されていません",
                    "refresh_delayed": "更新する前に {time_remaining} 待ってください",
                    "search": "興味のあるスポットを検索する",
                    "try_more_filters": "アクティブなフィルターを変更して、検索範囲を広げることができます。"
                },
                "pages": {
                    "show": {
                        "automatic_update": "自動更新",
                        "belongs_to_you": "あなたのものです",
                        "claim_dispute_pending": {
                            "details": "リクエスト内容：×1",
                            "notice": "進行中の請求紛争",
                            "title": "この点に関して、請求に関する係争が進行中です。"
                        },
                        "deletion": {
                            "causes": {
                                "event": "原因: 期限切れイベント"
                            },
                            "title": "削除保留中"
                        },
                        "dt_required_datatourisme_fields": " {import_source} から {dt_last_update} まで {dt_created_by} によって更新されました",
                        "excursions_counter": "旅程に {counter} 回追加されました",
                        "is_geocoding_1": "関心のある地点は地理的に位置特定されています。",
                        "is_geocoding_2": "後でこのページをチェックして、興味のある点を確認してください\n                地図にある。",
                        "is_refreshing_audio_1": "音声ファイルを生成中です。",
                        "is_refreshing_audio_2": "この音声を聞くには、後でこのページを確認してください。",
                        "not_current_locale": {
                            "change_locale": "上のセレクターを使用して表示言語を変更できます",
                            "incomplete_translation": "この興味深いスポットの一部の情報 (タイトル、説明、住所) は、選択した言語では利用できません。",
                            "missing_translations": "不足している翻訳"
                        },
                        "opening_hours": {
                            "expired": "営業時間は終了しました",
                            "is_deleting": "注目のスポットは {days} 日後に削除されます",
                            "show": "営業時間を見る"
                        },
                        "sections": {
                            "audios": "オーディオ",
                            "gallery": "ギャラリー",
                            "logo": "ロゴ",
                            "main_image": "メイン画像",
                            "map": "地図",
                            "media": "メディア",
                            "poi": "興味のあるスポット",
                            "videos": "動画"
                        },
                        "see_full_page": "全ページを見る"
                    }
                },
                "print": {
                    "element_not_found": "印刷する要素「{id}」が見つかりません!"
                },
                "publish": {
                    "success": "関心のあるポイントは検証され、公開されています (適格な場合)"
                },
                "report": {
                    "choose_reason": "この興味のある地点「{point_of_interest_title}」を報告する理由を選択してください",
                    "heading": "興味のあるスポットを報告する",
                    "success": "注目ポイントをレポートしました！"
                },
                "search": {
                    "creator_id": "私のポイントだけを表示しますか?",
                    "no_results": "興味のあるスポットが見つかりませんでした...",
                    "tags_mode": {
                        "all": "全て",
                        "all_help": "興味のあるスポットを見つけるには「すべて」を選択してください\n                選択したすべてのタグを含めます。",
                        "one": "少なくとも一つの",
                        "one_help": "興味のあるポイントを見つけるには、「少なくとも 1 つ」を選択してください\n                選択したタグの少なくとも 1 つを含む。"
                    }
                },
                "share": {
                    "heading": "この興味のあるポイントを共有する",
                    "select_which_contacts": "興味のある地点「{point_of_interest_title}」を共有したい連絡先を選択してください",
                    "select_which_users": "興味のある地点「{point_of_interest_title}」を共有したいユーザーを選択するか、電子メール アドレスを入力して新しい旅行者を招待します",
                    "select_which_users_alt": "共有したい相手の電子メールアドレスを入力してください\n            「{point_of_interest_title}」の興味のあるポイント。",
                    "success": "注目ポイントがシェアされました！"
                },
                "states": {
                    "draft": "下書き",
                    "pending": "保留中",
                    "published": "発行済み"
                },
                "store": {
                    "heading": "興味のある場所を作成する",
                    "success": "興味のあるポイントが作成されました"
                },
                "submit": {
                    "success": "関心のあるポイントはモデレーション保留中です (適格な場合)"
                },
                "update": {
                    "alert": "このポイントを更新すると、 {import_source} インポートからの自動更新が無効になります。",
                    "confirm": "本当にこの注目ポイント {point_of_interest_name} を更新しますか?",
                    "heading": "この注目スポットを編集する",
                    "success": "注目スポットが更新されました"
                }
            },
            "posts": {
                "archive": {
                    "success": "投稿はアーカイブされています (対象となる場合)"
                },
                "draft": {
                    "success": "投稿は下書きされています (適格な場合)"
                },
                "publish": {
                    "success": "投稿は公開されました (対象となる場合)"
                },
                "search": {
                    "type_to_search": "選択したカテゴリで検索..."
                }
            },
            "prices": {
                "excl_taxes": "除く税金",
                "free": "無料",
                "from": "から",
                "incl_taxes": "含む税金",
                "per_month": "月あたり",
                "price_excl_taxes": "税抜価格税金",
                "price_incl_taxes": "税込価格税金",
                "vat_amount": "VAT額",
                "vat_percentage": "VAT の割合"
            },
            "privacy_policies": {
                "archive": {
                    "success": "プライバシー ポリシーはアーカイブされています (対象となる場合)"
                },
                "errors": {
                    "not_found": "プライバシー ポリシーが見つかりません!"
                },
                "publish": {
                    "success": "プライバシーポリシーを公開しました"
                },
                "update": {
                    "acceptation": "続行するにはプライバシー ポリシーに同意する必要があります",
                    "new_version": "新しいバージョンのプライバシー ポリシーが公開されました",
                    "success": "プライバシーポリシーが承認されました!"
                }
            },
            "reports": {
                "process": {
                    "success": "レポートは処理されました (対象となる場合)"
                },
                "reject": {
                    "success": "レポートは拒否されました (該当する場合)"
                }
            },
            "reservations": {
                "archive": {
                    "confirm": "この予約をアーカイブしてもよろしいですか?",
                    "success": "予約はアーカイブされました"
                },
                "cancel": {
                    "confirm": "本当にこの予約をキャンセルしてもよろしいですか?",
                    "success": "予約がキャンセルされました"
                },
                "confirm": {
                    "confirm": "本当にこの予約を確認してもよろしいですか?",
                    "success": "予約が確認されました"
                },
                "destroy": {
                    "confirm": "本当にこの予約を削除してもよろしいですか?",
                    "success": "予約は削除されました"
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "butler": "旅行者",
                                "poi": "旅行者",
                                "traveller": "機関"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "butler": "コンシェルジュ",
                            "poi": "興味のあるスポット",
                            "reservation": "予約",
                            "traveller": "旅行者"
                        }
                    }
                },
                "states": {
                    "archived": "アーカイブ済み",
                    "canceled": "キャンセル",
                    "confirmed": "確認済み",
                    "pending": "保留中"
                },
                "store": {
                    "success": "予約が作成されました"
                },
                "update": {
                    "success": "予約が更新されました"
                }
            },
            "settings": {
                "items": {
                    "about": "について",
                    "geoloc_settings": "地理位置情報の設定",
                    "notification_settings": "通知設定",
                    "privacy_setting": "プライバシー設定",
                    "profile": "プロフィール",
                    "signOut": "サインアウト"
                }
            },
            "shares": {
                "emails": {
                    "select_hint": "以下をクリックすると、以前に使用したメールを選択できます。",
                    "space_hint": "複数のメールを共有するにはスペースで区切ります"
                },
                "index": {
                    "no_results": "共有が見つかりません!"
                },
                "link": {
                    "instructions": "このリンクをコピーして旅行者を招待し、この旅程を共有します"
                },
                "show": {
                    "shared_by": "共有者"
                },
                "type": {
                    "excursions": "旅程",
                    "pointsofinterest": "興味のあるスポット"
                }
            },
            "states": {
                "changelog_state": {
                    "archived": "アーカイブ済み",
                    "draft": "下書き",
                    "published": "発行済み"
                },
                "claim_dispute_state": {
                    "pending": "保留中",
                    "rejected": "拒否されました",
                    "validated": "検証済み"
                },
                "claim_state": {
                    "missing-info": "不足している情報",
                    "pending": "保留中",
                    "rejected": "拒否されました",
                    "revoked": "取り消されました",
                    "validated": "検証済み"
                },
                "payment_state": {
                    "canceled": "キャンセル",
                    "completed": "完了",
                    "failed": "失敗した",
                    "pending": "保留中",
                    "transferred": "転送されました"
                },
                "plan_state": {
                    "archived": "アーカイブ済み",
                    "coming_soon": "近日公開",
                    "draft": "下書き",
                    "published": "発行済み"
                },
                "point_of_interest_state": {
                    "deleted": "削除されました",
                    "denied": "拒否されました",
                    "draft": "下書き",
                    "pending": "保留中のモデレーション",
                    "published": "発行済み"
                },
                "post_state": {
                    "archived": "アーカイブ済み",
                    "draft": "下書き",
                    "published": "発行済み"
                },
                "privacy_policy_state": {
                    "archived": "アーカイブ済み",
                    "draft": "下書き",
                    "published": "発行済み"
                },
                "report_state": {
                    "pending": "保留中",
                    "processed": "加工済み",
                    "rejected": "拒否されました"
                },
                "reservation_state": {
                    "archived": "アーカイブ済み",
                    "canceled": "キャンセル",
                    "confirmed": "確認済み",
                    "pending": "保留中"
                },
                "subscription_state": {
                    "active": "アクティブ",
                    "canceled": "キャンセル",
                    "pending": "保留中のお支払い",
                    "suspended": "一時停止中"
                },
                "support_request_state": {
                    "archived": "アーカイブ済み",
                    "canceled": "キャンセル",
                    "pending-support": "保留中のサポート",
                    "pending-user": "保留中のユーザー",
                    "resolved": "解決済み"
                },
                "terms_condition_state": {
                    "archived": "アーカイブ済み",
                    "draft": "下書き",
                    "published": "発行済み"
                },
                "traveller_folder_state": {
                    "approved": "承認された",
                    "archived": "アーカイブ済み",
                    "declined": "拒否されました",
                    "pending": "保留中"
                },
                "user_credential_verification_state": {
                    "canceled": "キャンセル",
                    "completed": "完了",
                    "pending": "保留中"
                },
                "user_tutorial_state": {
                    "claimed": "主張した",
                    "done": "終わり",
                    "employee-added": "従業員が追加されました",
                    "pending": "保留中"
                },
                "user_utm_campaign_state": {
                    "always_on": "常にオン"
                },
                "user_utm_medium_state": {
                    "owned_social": "ソーシャルネットワーク"
                },
                "user_utm_source_state": {
                    "facebook": "フェイスブック",
                    "instagram": "インスタグラム",
                    "linkedin": "リンクトイン"
                },
                "users_relationship_state": {
                    "approved": "承認された",
                    "blocked": "ブロックされました",
                    "declined": "拒否されました",
                    "pending": "保留中"
                }
            },
            "stripe": {
                "connect": {
                    "account": {
                        "create": {
                            "completed": "Stripe アカウントの設定が完了しました",
                            "link_invalid": "Stripe への接続に失敗しました。もう一度お試しください。"
                        },
                        "must_subscribe": "Stripe アカウントを作成するには、購読する必要があります。",
                        "show": {
                            "account_usage": "Stripe アカウントにより、教育機関は UgoZER で支払いを受け取ることができます。",
                            "can_receive_payments": "支払いを受け取ることができます。",
                            "cannot_receive_payments": "支払いを受け取ることができません",
                            "fee_percentage": "受け取るお支払いごとに {percentage}% の手数料が適用されます。",
                            "go_to_stripe": "Stripe ダッシュボードに移動します",
                            "has_stripe_account_being_reviewed": "あなたのアカウントは Stripe によって審査されています。",
                            "no_account_yet": "まだ Stripe アカウントをお持ちではありません。",
                            "should_complete_stripe_onboarding": "Stripe への登録を完了し、必要な情報を提供してください。"
                        }
                    }
                },
                "stripe": "ストライプ"
            },
            "subscriptions": {
                "activated_at": "アクティベーション",
                "cancel": "キャンセル",
                "cancelation_success": "キャンセルも考慮",
                "canceled_at": "キャンセル",
                "confirm_cancel": "本当にサブスクリプションをキャンセルしてもよろしいですか?",
                "confirm_cancel_date": " {date} の有効なキャンセル日まで、その利点を享受できます。",
                "confirm_cancel_now": "キャンセルはすぐに有効になります。",
                "expiry": "有効期限",
                "invoices": "請求書",
                "my_current_subscription": "現在のサブスクリプション",
                "price_excl_taxes": "税抜価格税金",
                "price_incl_taxes": "税込価格税金",
                "upcoming_subscription": "今後のサブスクリプション"
            },
            "support_messages": {
                "show": {
                    "you": "あなた",
                    "your_question": "あなたの質問"
                },
                "store": {
                    "heading": "メッセージを追加",
                    "success": {
                        "title": "メッセージが作成されました。サポートにお問い合わせください。48 時間以内に回答いたします。"
                    }
                }
            },
            "support_requests": {
                "actions": {
                    "archived": {
                        "success": "リクエストはアーカイブされています"
                    },
                    "canceled": {
                        "success": "リクエストはキャンセルされました"
                    },
                    "pending_support": {
                        "success": "サポートが回答を待っています"
                    },
                    "pending_user": {
                        "success": "ユーザーの回答を待っています"
                    },
                    "resolved": {
                        "success": "リクエストは解決されました"
                    }
                },
                "cancel": {
                    "confirm": "このサポート リクエスト {support_request_title} をキャンセルしてもよろしいですか?",
                    "error": "サポートリクエストをキャンセルできませんでした",
                    "success": "サポートリクエストがキャンセルされました"
                },
                "index": {
                    "no_results": "サポートリクエストはありません",
                    "search": "サポートリクエストを検索する",
                    "your_requests": "サポートリクエスト"
                },
                "pages": {
                    "create": {
                        "notice": "個人データ"
                    },
                    "index": {
                        "search": "サポートリクエストを検索する"
                    },
                    "show": {
                        "sections": {
                            "messages": "メッセージ",
                            "request": "リクエスト"
                        }
                    }
                },
                "show": {
                    "add_request": "新しいサポート リクエストを追加する"
                },
                "states": {
                    "archived": "アーカイブ済み",
                    "canceled": "キャンセル",
                    "pending-support": "保留中のサポート",
                    "pending-user": "保留中のユーザー",
                    "resolved": "解決済み"
                },
                "store": {
                    "heading": "新しいサポート リクエストを作成する",
                    "success": {
                        "title": "リクエストが作成されました。サポートにお問い合わせください。48 時間以内に回答いたします。"
                    }
                }
            },
            "terms_conditions": {
                "archive": {
                    "success": "利用規約がアーカイブされています（対象となる場合）"
                },
                "errors": {
                    "not_found": "利用規約が見つかりません!"
                },
                "publish": {
                    "success": "利用規約が公開されました"
                },
                "update": {
                    "acceptation": "続行するには利用規約に同意する必要があります",
                    "new_version": "新しいバージョンの利用規約が公開されました",
                    "success": "利用規約に同意しました!"
                }
            },
            "traveller_folders": {
                "messages": {
                    "approve": {
                        "confirm": "本当にこの滞在を確認しますか?",
                        "success": "確認してください！"
                    },
                    "archive": {
                        "confirm": "本当にこの滞在をアーカイブしますか?",
                        "success": "この滞在はアーカイブされました！"
                    },
                    "decline": {
                        "confirm": "本当に今回の宿泊をお断りしてもよろしいでしょうか？",
                        "success": "拒否されたままにしてください！"
                    }
                },
                "no_results": {
                    "approved": "承認された滞在はありません",
                    "archived": "アーカイブされた滞在はありません",
                    "declined": "宿泊拒否なし",
                    "pending": "宿泊リクエストはありません"
                },
                "pages": {
                    "form": {
                        "cannot_attach": {
                            "description": "このユーザーは存在しますが、サポートできません...",
                            "title": "サポート不可"
                        },
                        "poi_empty_option": "興味のあるスポットを削除する",
                        "poi_label": "コンシェルジュの機関",
                        "verification": {
                            "description": "旅行者の電子メール アドレスを入力します。旅行者アカウントがすでに存在する場合は、\n                    直接リンクされている場合、リンクを作成するには追加情報を入力する必要があります。\n                    新しいもの。",
                            "title": "検証"
                        }
                    },
                    "index": {
                        "pending_stays": "保留中の滞在",
                        "search": "私の旅行者の中から検索",
                        "travellers_folders": "トラベラーズフォルダー",
                        "your_stays": "あなたの滞在"
                    },
                    "qr_code_1": "旅行者はこの QR コードをスキャンして、旅行者フォルダーを自動的に取得できます。\n            あなたの機関と一緒に。",
                    "qr_code_2": "旅行者がまだUgoZERに登録していない場合は、登録画面にリダイレクトされます。\n            ページにアクセスし、登録の最後にあなたの教育機関にリンクされます。",
                    "show": {
                        "fields": {
                            "state": "州："
                        },
                        "sections": {
                            "butler": "関連するコンシェルジュ",
                            "folder": "フォルダ",
                            "hotel": "ホテル",
                            "institution": "関係機関",
                            "interactions": "インタラクション",
                            "invitation": "旅行者を招待する",
                            "payments": "支払い",
                            "reservations": "予約",
                            "stay": "滞在する",
                            "thanks": "ありがとう",
                            "traveller": "旅行者"
                        }
                    },
                    "wizard": {
                        "choose_an_institution": "教育機関を選択してください",
                        "no_linked_institution": "旅行者を迎えに行く前に、機関にリンクされている必要があります。"
                    }
                },
                "placeholders": {
                    "butler": "受付チーム"
                },
                "states": {
                    "approved": "承認されました",
                    "archived": "アーカイブ済み",
                    "declined": "拒否されました",
                    "pending": "保留中",
                    "undefined": "未定義"
                },
                "store": {
                    "success": "フォルダーが作成されました"
                },
                "tabs": {
                    "approved": "承認された",
                    "archived": "アーカイブ済み",
                    "declined": "拒否されました",
                    "pending": "保留中"
                },
                "thank": {
                    "success": "感謝の気持ちが送られてきました"
                },
                "update": {
                    "success": "フォルダが更新されました"
                }
            },
            "tutorials": {
                "extranet": {
                    "begin": {
                        "butler": {
                            "description": "UgoZERアプリケーションを最良の状態でご利用いただくために、\n                    の最初のステップをガイドする次の手順に従うことをお勧めします。\n                    コンシェルジュスペースの取り扱いとパラメータ設定。",
                            "steps": {
                                "institution": {
                                    "content": "検索バーを使用して施設リストからホテルを検索し、\n                            ページの左側のセクションで使用できるフィルター。あなたのものを見つけたら、\n                            設立するには、[<strong>参加申請</strong>] をクリックしてアプリケーションを作成します。\n                            ボタン。",
                                    "description": "所属機関へのリンク",
                                    "step_next": "主張した",
                                    "step_state": "保留中",
                                    "subtitle": {
                                        "link": "私の教育機関",
                                        "part_1": "私のホテルにリンクしてください",
                                        "part_2": "ページに直接ホテルにリンクできます"
                                    },
                                    "title": "機関"
                                }
                            }
                        },
                        "done": {
                            "description": "これで、UgoZER アプリケーションをセットアップするためのさまざまな手順が完了しました。",
                            "faq": {
                                "link": "よくある質問",
                                "part_1": "さらにヘルプが必要な場合は、次の記事を参照してください。",
                                "part_2": "アプリケーションのセクション"
                            },
                            "help": "一部のページにある<strong>ヘルプ</strong>からも状況に応じたヘルプを見つけることができます。",
                            "title": "おめでとう！"
                        },
                        "hotelier": {
                            "description": "UgoZERアプリケーションを最良の状態でご利用いただくために、\n                    の最初のステップをガイドする次の手順に従うことをお勧めします。\n                    ホテルマネージャースペースの処理とパラメータ設定。",
                            "steps": {
                                "employees": {
                                    "description": "コンシェルジュを管理する",
                                    "step_next": "従業員追加",
                                    "step_state": "主張した",
                                    "title": "コンシェルジュ"
                                }
                            }
                        },
                        "manager": {
                            "description": "UgoZERアプリケーションを最良の状態でご利用いただくために、\n                    の最初のステップをガイドする次の手順に従うことをお勧めします。\n                    その他業務管理者スペースの取り扱いとパラメータ設定。"
                        },
                        "messages": {
                            "claim": "あなたの主張は検証されました。",
                            "contest": "あなたの異議申し立ては私たちのチームに送信されました。",
                            "payment": "銀行情報が正常に記録されました。",
                            "point_of_interest": "関心のある地点が作成されたので、それを要求できるようになりました。"
                        },
                        "no_tutorial": {
                            "content": "このロール用のチュートリアルが設定されていないか、管理者として接続されています。",
                            "title": "利用可能なチュートリアルはありません"
                        },
                        "notices": {
                            "add_butler": {
                                "content": "アプリケーションを使用するには、施設のコンシェルジュ アカウントを作成する必要があります\n                        そこから旅行者に情報が送信されます。",
                                "content_2": "コンシェルジュ アカウントを有効にするには、コンシェルジュが電子メールを確認する必要があります。\n                        アドレスを指定してパスワードを作成します。",
                                "title": "コンシェルジュを追加する"
                            },
                            "add_employee": {
                                "content": "アプリケーションを使用するには、施設の従業員アカウントを作成する必要があります\n                        そこから旅行者に情報が送信されます。",
                                "content_2": "従業員アカウントを有効にするには、従業員は電子メールを確認する必要があります\n                        アドレスを指定してパスワードを作成します。",
                                "title": "従業員を追加する"
                            }
                        },
                        "shared": {
                            "actions": {
                                "skip_step": "ステップをスキップ",
                                "skip_tutorial": "チュートリアルをスキップする"
                            },
                            "claims": {
                                "description": "あなたの機関を主張してください",
                                "documents": {
                                    "association": {
                                        "identity_piece": "法定代理人の本人確認書類\n                                    有効",
                                        "statutes": "協会規約",
                                        "title": "協会なら"
                                    },
                                    "company": {
                                        "identity_piece": "法定代理人の本人確認書類\n                                    有効",
                                        "kbis": "KBIS の抜粋",
                                        "title": "あなたが会社であれば"
                                    },
                                    "description": "教育機関を申請するには、次のものを準備する必要があります。\n                                書類:",
                                    "individual": {
                                        "identity_piece": "有効な身分証明書",
                                        "registration_document": "自営業の登記証明書",
                                        "title": "個人事業主の場合"
                                    },
                                    "label": "書類",
                                    "title": "請求書類"
                                },
                                "notice": {
                                    "description": "この説明は<strong>ヘルプ</strong>をクリックすると表示されます。\n                                興味のあるスポットのリストの右上にあるボタンをクリックします。",
                                    "title": "ご注意ください"
                                },
                                "step_next": "主張した",
                                "step_state": "保留中",
                                "title": "請求"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "このステップをスキップしてもよろしいですか?",
                                    "success": "そのステップは見事に無視されました！"
                                },
                                "skip_tutorial": {
                                    "confirm": "このチュートリアルをスキップしてもよろしいですか?",
                                    "success": "チュートリアルはよく無視されました！"
                                }
                            },
                            "payments": {
                                "description": "ヒントを受け取る",
                                "step_next": "終わり",
                                "step_state": "従業員追加",
                                "steps": {
                                    "bank": {
                                        "create": "画面内の [<strong>アカウントを追加</strong>] ボタンをクリックします。\n                                    <strong>銀行口座</strong>セクション"
                                    },
                                    "easytransac": {
                                        "connection": "リンクをクリックしてください\n                                    <strong>EasyTransac ダッシュボードに移動</strong>し、認証情報を使用してログインします。\n                                        電子メールで受け取った",
                                        "create": "の<strong>作成</strong>ボタンをクリックします。\n                                    <strong>EasyTransac</strong> セクション",
                                        "created": "入力されたデータに基づいてアカウントが自動的に作成されます\n                                    あなたのプロフィールに",
                                        "form": "EasyTransac のフォームに記入してアカウント支払いアカウントを有効にします",
                                        "warning": {
                                            "description": "EasyTransac アカウントの KYC レベルに応じて、次のことができます。\n                                        支払いを受け取ることはできるが、通常の銀行に送金することはできない\n                                        アカウント。通常の銀行口座への資金。このためにはKYCレベルが必要です\n                                        2、EasyTransac インターフェイスで特定のドキュメントを提供します。 EasyTransac\n                                        インターフェース。",
                                            "title": "KYCレベル"
                                        }
                                    },
                                    "my_profile": {
                                        "part_1": "ページに移動",
                                        "part_2": "私のプロフィール"
                                    }
                                },
                                "subtitle_1": "コンシェルジュがチップを受け取るには、銀行情報を追加する必要があります。",
                                "subtitle_2": "これをする：",
                                "title": "支払い"
                            }
                        },
                        "tourist_office": {
                            "description": "UgoZERアプリケーションを最良の状態でご利用いただくために、\n                    の最初のステップをガイドする次の手順に従うことをお勧めします。\n                    観光案内所スペースの取り扱いとパラメータ設定。",
                            "steps": {
                                "employees": {
                                    "description": "従業員を管理する",
                                    "step_next": "従業員追加",
                                    "step_state": "主張した",
                                    "title": "従業員"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "UgoZERアプリケーションを最良の状態でご利用いただくために、\n                    の最初のステップをガイドする次の手順に従うことをお勧めします。\n                    観光局の従業員スペースの処理とパラメータ設定。",
                            "steps": {
                                "institution": {
                                    "content": "検索バーを使用して施設リストから観光案内所を検索します。\n                            フィルターはページの左側のセクションで使用できます。あなたのものを見つけたら、\n                            設立するには、[<strong>参加申請</strong>] をクリックしてアプリケーションを作成します。\n                            ボタン。",
                                    "description": "所属機関へのリンク",
                                    "step_next": "主張した",
                                    "step_state": "保留中",
                                    "subtitle": {
                                        "link": "私の教育機関",
                                        "part_1": "私の観光案内所にリンクしてください",
                                        "part_2": "ページに直接観光案内所にリンクできます"
                                    },
                                    "title": "機関"
                                }
                            }
                        }
                    },
                    "dashboard": {
                        "claim": {
                            "steps": {
                                "claim_form": "その後、請求フォームの指示に従ってください",
                                "claim_validation": "その後、申し立ては当社のチームによって検証および検証されます",
                                "dont_exist": "存在しない場合は、[<strong>興味のある地点を作成する] ボタンをクリックします。\n                        </strong>その後、フォームに記入します。関心のある地点が作成されたら、その地点に移動します\n                        カードを選択し、[<strong>請求</strong>] ボタンをクリックします。",
                                "exist": "あなたの教育機関が存在する場合は、そのファイルに移動し、 ボタン<strong>をクリックします。\n                        主張</strong>",
                                "filters": "フィルターと検索を使用して教育機関を見つけます",
                                "poi_index": {
                                    "part_1": "に行きます",
                                    "part_2": "興味のあるポイントのリスト"
                                }
                            },
                            "subtitle_1": "管理するために 1 つ (または複数) の施設を要求できます。",
                            "subtitle_2": "そうするために：",
                            "title": "自分の教育機関の所有権と管理を主張する"
                        },
                        "employees": {
                            "link": "リクエストを作成するには、[<strong>私の教育機関</strong>] ページに移動します。\n                    スペースを指定してフォームに記入してください。",
                            "manage": "このページでは、あなたの会社とのリンクを希望する従業員を承認または拒否できます。\n                    機関。",
                            "subtitle": {
                                "part_1": "このページから従業員を直接管理できます",
                                "part_2": "私の従業員"
                            },
                            "title": "従業員を管理する"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "distance": "<strong>場所:</strong> 特定の場所からのポイントをフィルタリングできます。",
                            "main_title": "フィルター",
                            "subtitle": "次の基準に従って旅程のリストをフィルタリングできます。",
                            "title": "旅程をフィルタリングする",
                            "type": {
                                "customized": "<strong>カスタマイズ:</strong> 旅行者向けに特別に作成された旅程",
                                "explanation": "<strong>タイプ:</strong> では、旅程のタイプでフィルタリングできます。",
                                "pro": "<strong>UgoZER Pro:</strong> UgoZER が提供する旅程",
                                "root": "<strong>私のテンプレート:</strong> モデルの旅程"
                            }
                        }
                    },
                    "institution_employee": {
                        "approve": {
                            "content": "このアクションにより、従業員の 1 人からのいずれかに参加するリクエストを受け入れることができます。\n                    あなたの機関。",
                            "main_title": "承認する",
                            "title": "従業員を承認する"
                        },
                        "cancel": {
                            "content": "このアクションにより、次のような場合に従業員の招待リクエストをキャンセルできます。\n                    たとえばエラーの場合。",
                            "main_title": "キャンセル",
                            "title": "従業員の応募をキャンセルする"
                        },
                        "decline": {
                            "content": "このアクションを使用すると、従業員の 1 人が組織に参加するリクエストを拒否できます。\n                    機関。",
                            "main_title": "拒否する",
                            "title": "従業員を拒否する"
                        },
                        "functioning": {
                            "content": {
                                "invite": "このページでは、従業員を招待したり、従業員からのリクエストを承認または拒否したりできます。\n                        従業員。",
                                "section": "このセクションでは、組織の従業員を管理できます。"
                            },
                            "main_title": "機能している",
                            "title": "従業員セクションの機能"
                        },
                        "invite": {
                            "content": {
                                "button": "[<strong>従業員を招待</strong>] ボタンをクリックすると、従業員の 1 人を招待できます。\n                        従業員は、すでに UgoZER アカウントを持っているかどうかに関係なく、",
                                "form": "これを行うには、表示されるステップバイステップのフォームの指示に従ってください。\n                        現れるフォーム。"
                            },
                            "main_title": "招待する",
                            "title": "従業員を招待する"
                        },
                        "remove": {
                            "content": "このアクションにより、次のような場合に従業員からユーザーを削除できます。\n                    従業員があなたの会社を辞めます。従業員が会社を退職した場合。",
                            "main_title": "取り除く",
                            "title": "従業員を削除する"
                        }
                    },
                    "points_of_interest": {
                        "claim": "請求",
                        "filters": {
                            "category": "<strong>カテゴリ :</strong> では、特定のカテゴリを選択できます。\n                    （ホテル、レストランなど）",
                            "city": "<strong>都市:</strong> では、特定の都市でフィルタリングできます",
                            "country": "<strong>国:</strong> 1 つ以上の特定の国でフィルタリングできます。",
                            "distance": "<strong>場所:</strong> 特定の場所からのポイントをフィルタリングできます。",
                            "main_title": "フィルター",
                            "measure_unit": "<strong>測定単位 :</strong> で使用される測定単位を変更できます。\n                    距離フィルターとディスプレイ",
                            "my_points": "<strong>自分のポイントのみを表示:</strong> 作成したポイントのみを表示します。\n                    あなたが主張した",
                            "postal_code": "<strong>郵便番号 :</strong> では、郵便番号でフィルタリングできます",
                            "radius": "<strong>半径に制限:</strong> は、特定の円内のポイントをフィルタリングできます。\n                    半径",
                            "subtitle": "次の基準に従って、興味のある地点のリストをフィルタリングできます。",
                            "tags": "<strong>絞り込み :</strong> では、タグで検索を絞り込み、カテゴリを指定できます。\n                    1 つを使用してポイントを検索するか、すべてを使用してポイントを検索するか、またはすべてを定義することもできます。\n                    選択したタグ",
                            "title": "興味のあるスポットをフィルタリングする"
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "validate": {
                    "success": "資格情報が正常に検証されました (資格がある場合)"
                },
                "verify": {
                    "already_verified": "あなたの資格情報はすでに検証されています",
                    "expired": "リンクの有効期限が切れています。新しいリンクを要求してください",
                    "impossible": "この資格情報の検証はもう不可能です",
                    "not_received": "確認リンクを取得できませんでしたか?",
                    "sent": {
                        "again": "もう一度送信",
                        "correct": "選択した資格情報を修正します",
                        "email_1": "このアドレスがまだ使用されていない場合は、電子メールが {email} に送信されます。",
                        "email_2": "この電子メールには、アドレスを確認できるリンクが含まれています。",
                        "email_3": "リンクにアクセスするとすぐにメールが更新されます。",
                        "refresh": "リンクをクリックしましたか?アプリケーションを更新する",
                        "title": "リンクが送信されました",
                        "wait_before": "再送信する前に {seconds} 秒待ってください"
                    },
                    "success": "資格情報は正常に検証されました"
                }
            },
            "user_geoloc_settings": {
                "geolocation": {
                    "error": {
                        "allow_browser": "携帯電話の設定でブラウザに位置情報を許可します",
                        "allow_site": "ブラウザ設定で UgoZER による位置情報の取得を許可します",
                        "intro": "周囲の興味深いスポットを表示するには:",
                        "title": "地理位置情報が失敗しました"
                    },
                    "intro": "地理位置情報を使用すると、最も近い観光スポットを見つけることができます。"
                },
                "not_configured": {
                    "body1": "選択した場所を選択すると、その近くの興味深いスポットが表示されます。",
                    "body2": "近くの名所のみを表示することも可能です。",
                    "do_not_ask_again": "アプリケーションが再起動されるまで、再度質問しないでください",
                    "subject": "地理位置情報の設定をまだ定義していません。"
                },
                "update": {
                    "success": "地理位置情報の設定が更新されました"
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "通知設定が更新されました"
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "プライバシー設定が更新されました"
                }
            },
            "users": {
                "butlers": "コンシェルジュ",
                "finish": {
                    "success": "チュートリアルが終了状態に変更されました"
                },
                "index": {
                    "hint": "ユーザーはニックネームまたは公開 ID で見つけることができます。",
                    "no_results": "ユーザーが見つかりませんでした",
                    "search": "ユーザーを検索する"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "delete": "アカウントを削除します？",
                            "profile": "私のプロフィール"
                        }
                    }
                },
                "profile": {
                    "alerts": {
                        "blocked": {
                            "message": "このユーザーと対話することはできなくなります。",
                            "title": "このユーザーはあなたをブロックしました..."
                        },
                        "missing_infos": {
                            "message": "アプリケーションのすべての機能を活用するには、次の情報を入力してください。",
                            "title": "プロフィールを完成させましょう"
                        },
                        "pending_validation": {
                            "message": "このユーザーはまだあなたの要求を受け入れていません。",
                            "title": "検証待ち"
                        }
                    },
                    "avatar": {
                        "defaults": {
                            "female": "女性",
                            "male": "男"
                        },
                        "section": {
                            "cta": "このアバターを使用してください",
                            "description": "以下のリストから事前定義されたアバターを選択できます",
                            "title": "事前定義されたアバターの選択"
                        },
                        "success": "アバターが正常に更新されました。"
                    },
                    "bank_account_number_missing": "支払いを受け取るには銀行情報を入力してください。",
                    "delete_account": {
                        "easy_transac": "EasyTransac アカウントは削除されません。",
                        "instructions": "アカウントの最終的な削除を確認するには、下のフィールドに「DELETE」という文字を入力し、「確認」ボタンをクリックしてください。",
                        "success": "あなたのアカウントは削除されました。",
                        "title": "アカウントの削除",
                        "warning": "あなたのアカウントおよびあなたのアカウントに関連するすべての情報は、回復の可能性なしに直ちに削除されます。"
                    },
                    "edit_email": "メールを編集する",
                    "edit_password": "パスワードを編集する",
                    "edit_phone": "電話番号を編集する",
                    "edit_your_profile": "プロフィールを編集する",
                    "email_missing": "確認済みメールアドレスが入力されていません。",
                    "fill_country": "あなたの国を指定してください",
                    "form": {
                        "poi_label": "連携機関"
                    },
                    "manage_my_subscription": "サブスクリプションを管理する",
                    "password_missing": "パスワードを入力していません。",
                    "phone_missing": "認証済みの電話番号が入力されていません",
                    "preferences": "環境設定",
                    "public_id": "または、次の識別子を使用します。",
                    "qr_code": "スキャンしてプロフィールを確認してください",
                    "security": "安全",
                    "share_profile": "プロフィールを共有する",
                    "subscribe_to_receive_payments": "支払いを受け取るには購読する必要があります。",
                    "unnamed": "ノーネーム",
                    "your_personal_information": "あなたの個人情報"
                },
                "report": {
                    "choose_reason": "このユーザー「{user_name}」を報告する理由を選択してください",
                    "heading": "ユーザーを報告する",
                    "success": "ユーザーは通報されました！"
                },
                "search": {
                    "no_results": "ユーザが見つかりませんでした"
                },
                "send_link": {
                    "error": "リンクを送信できません。ユーザーは電子メールを持っていますか?",
                    "success": "アプリケーションへのリンクが送信されました"
                },
                "update": {
                    "success": "プロフィールが更新されました"
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "連絡先が見つかりませんでした",
                    "pending_requests": "保留中の連絡先"
                },
                "messages": {
                    "add": {
                        "confirm": "本当にこの連絡先を追加してもよろしいですか?",
                        "error": "コンタクトリクエスト中にエラーが発生しました...",
                        "success": "連絡先リクエストが正常に完了しました。"
                    },
                    "approve": {
                        "confirm": "本当にこの連絡リクエストを受け入れますか?",
                        "success": "コンタクトリクエスト受付中！"
                    },
                    "block": {
                        "confirm": "本当にこのユーザーをブロックしますか?",
                        "success": "この連絡先はブロックされました!"
                    },
                    "decline": {
                        "confirm": "本当にこの連絡リクエストを拒否しますか?",
                        "success": "連絡リクエストが拒否されました。"
                    },
                    "unblock": {
                        "confirm": "本当にこのユーザーのブロックを解除しますか?",
                        "success": "この連絡先のブロックは解除されました。"
                    }
                },
                "no_results": {
                    "approved": "承認された連絡先がありません",
                    "blocked": "ブロックされた連絡先はありません",
                    "pending": "保留中の連絡先リクエストはありません",
                    "sent": "送信された連絡先リクエストはありません"
                },
                "search": {
                    "no_results": "連絡先が見つかりませんでした"
                },
                "tabs": {
                    "approved": "承認された",
                    "blocked": "ブロックされました",
                    "pending": "保留中",
                    "sent": "送信済み"
                }
            }
        },
        "validation": {
            "accepted": " {attribute} は受け入れられる必要があります。",
            "active_url": " {attribute} は有効な URL ではありません。",
            "after": " {attribute} は {date} より後の日付である必要があります。",
            "after_or_equal": " {attribute} は {date} 以降の日付である必要があります。",
            "alpha": " {attribute} には文字のみを含めることができます。",
            "alpha_dash": " {attribute} には、文字、数字、ダッシュ、アンダースコアのみを含めることができます。",
            "alpha_num": " {attribute} には文字と数字のみを含めることができます。",
            "array": " {attribute} は配列でなければなりません。",
            "attributes": {
                "accounting_file": "会計ファイル",
                "actions": "行動",
                "activated_at": "にアクティブ化されました",
                "active": "アクティブ",
                "additional_information": "追加情報",
                "address": "住所",
                "addresses": "住所",
                "admin_message": "メッセージ",
                "administrative_area": "行政区域",
                "amount": "額",
                "app": "応用",
                "app_fees": "料金",
                "approved_at": "で承認されました",
                "archived_at": "アーカイブ場所",
                "association_legal_representative_identity_piece": "法定代理人の身分証明書",
                "association_statutes": "法令",
                "attached_files": "添付ファイル",
                "audio": "オーディオ",
                "audio_refreshing": "オーディオのリフレッシュ",
                "audios": "オーディオ",
                "authenticated_once": "一度認証されました",
                "auto_renewal_enabled": "自動更新が有効になっている",
                "avatar": "アバター",
                "bank_account": "銀行口座",
                "bank_account_number": "銀行の口座番号",
                "bank_bic": "ビック",
                "bank_city": "銀行都市",
                "bank_country": "銀行の国",
                "bank_file": "バンクファイル",
                "bank_iban": "IBAN",
                "bank_name": "銀行名",
                "bank_payee": "受取人",
                "bank_transfer": "銀行振込",
                "bank_transfer_institutions": "銀行振込機関",
                "bank_transfer_reason": "異動の理由",
                "butler": "コンシェルジュ",
                "butler_point_of_interest": "コンシェルジュの機関",
                "canceled_at": "でキャンセルされました",
                "categories": "カテゴリ",
                "category": "カテゴリー",
                "category_id": "カテゴリID",
                "causer": "原因者",
                "causer_id": "原因ID",
                "causer_type": "原因のタイプ",
                "certified": "認証済み",
                "city": "市",
                "claim_confirmation": "請求の確認",
                "code": "コード",
                "collection_name": "コレクション名",
                "communication_consent": "パーソナライズされたコミュニケーション",
                "communication_consent_alt": "コミュニケーション？",
                "company_legal_representative_identity_piece": "法定代理人の身分証明書",
                "company_registration_document": "登録書類",
                "company_statutes": "法令",
                "company_type": "会社の種類",
                "completed_at": "に完了",
                "contact": "接触",
                "contact_email": "連絡先メールアドレス",
                "contact_phone": "連絡先電話",
                "content": "コンテンツ",
                "contestant": "出場者",
                "contestant_company_type": "会社の種類",
                "contestant_email": "Eメール",
                "contestant_first_name": "ファーストネーム",
                "contestant_last_name": "苗字",
                "contestant_message": "メッセージ",
                "contestant_phone": "電話",
                "conversions_disk": "変換ディスク",
                "coords": "座標",
                "countries": "国",
                "countries_codes": "国",
                "country": "国",
                "country_code": "国番号",
                "created": "作成した",
                "created_at": "で作成されました",
                "creator": "クリエイター",
                "credential": "資格情報",
                "currency": "通貨",
                "current_password": "現在のパスワード",
                "custom_amount": "カスタム金額",
                "custom_properties": "カスタムプロパティ",
                "customers_qty": "顧客数",
                "date": "日付",
                "dates": "日付",
                "days": "日々",
                "declined_at": "で断った",
                "deleted_at": "に削除されました",
                "deletion_confirmation": "削除確認",
                "denial_reason": "拒否理由",
                "dependent_locality": "従属地域",
                "description": "説明",
                "details": "詳細",
                "disk": "ディスク",
                "display": "画面",
                "displayed_name": "表示名",
                "distance": "距離",
                "documents": "書類",
                "dt_created_by": "によって作成された",
                "dt_last_update": "データツーリズムの最終更新",
                "dynamic_translations_enabled": "動的翻訳が有効になっています",
                "email": "Eメール",
                "emails_must_different": "両方のアカウントに同じメール アドレスを設定することはできません。",
                "employee": "従業員",
                "end_date": "終了日",
                "engaged_until": "まで従事",
                "engagement_period": "婚約期間",
                "event": "イベント",
                "excursion": "旅程",
                "existing_user": "既存のユーザー",
                "expirable": "期限切れの",
                "expired_at": "に期限切れになりました",
                "expired_on": "滞在終了日",
                "expiry": "有効期限",
                "expiry_date": "有効期限",
                "extranet": "エクストラネット",
                "fallback_locale": "フォールバックロケール",
                "favorite": "お気に入りの旅程",
                "featured": "特徴",
                "file": "ファイル",
                "file_name": "ファイル名",
                "filter": "フィルター",
                "financial": "金融",
                "first_name": "ファーストネーム",
                "formatted_address": "フォーマットされたアドレス",
                "free_period": "自由時間",
                "free_period_ended_at": "無料期間は に終了しました",
                "front": "フロント",
                "gallery": "ギャラリー",
                "gdpr": "GDPR",
                "general": "一般的な",
                "generated_conversions": "生成されたコンバージョン",
                "gross_amount": "総額",
                "has_employees": "従業員がいる",
                "hour": "時間",
                "hours": "時間",
                "id": "ID",
                "image": "画像",
                "images": "画像",
                "import_source": "輸入元",
                "import_synchronizing": "自動アップデートを有効にする",
                "individual_first_identity_piece": "アイデンティティの部分",
                "individual_registration_document": "登録書類",
                "individual_second_identity_piece": "2番目のアイデンティティ部分",
                "information": "情報",
                "institution": "機関",
                "institution_address": "機関の住所",
                "institution_id": "機関",
                "institution_title": "機関名",
                "interaction_type": "インタラクションタイプ",
                "invitation": "招待",
                "invitation_accepted": "招待が受け入れられました",
                "invitation_status": "招待ステータス",
                "invited_at": "に招待されました",
                "invited_by": "に招待されました",
                "invitees": "招待者",
                "is_geocoding": "地理位置情報が進行中です",
                "iso_code": "ISOコード",
                "items": "アイテム",
                "key": "鍵",
                "kind": "親切",
                "label": "ラベル",
                "last_geoloc": "最後の地理位置情報",
                "last_logged_at": "最終ログイン日",
                "last_login_ip": "最終ログインIP",
                "last_name": "苗字",
                "last_validated_at": "最後の検証",
                "lat": "緯度",
                "latitude": "緯度",
                "line1": "住所1",
                "line2": "住所2",
                "link": "リンク",
                "lng": "経度",
                "locale": "言語",
                "login": "繋がり",
                "logo": "ロゴ",
                "longitude": "経度",
                "manipulations": "操作",
                "max_amount_per_checkout": "チェックアウトごとの最大金額",
                "measurement_system": "測定システム",
                "media": "メディア",
                "message": "メッセージ",
                "metadata": "メタデータ",
                "mime_type": "MIMEタイプ",
                "min_amount_per_checkout": "チェックアウトごとの最低金額",
                "misc": "その他",
                "model": "記録",
                "model_type": "レコードタイプ",
                "name": "名前",
                "net_amount": "正味金額",
                "new_password": "新しいパスワード",
                "new_password_confirmation": "新しいパスワードの確認",
                "next_payment_at": "次回の支払い",
                "nickname": "ニックネーム",
                "not_specified": "指定されていない",
                "not_validated_activities": "検証されていないアクティビティ",
                "notes": "さらに詳しい情報",
                "opening_hours": "営業時間",
                "order_column": "注文",
                "origin": "起源",
                "owner": "所有者",
                "owner_email": "Eメール",
                "owner_first_name": "ファーストネーム",
                "owner_last_name": "苗字",
                "owner_phone": "電話",
                "password": "パスワード",
                "password_confirmation": "パスワードの確認",
                "passwords_must_different": "両方のアカウントに同じパスワードを設定することはできません。",
                "payable": "支払われる",
                "payee": "受取人",
                "payee_country": "国",
                "payee_email": "Eメール",
                "payee_id": "受取人",
                "payee_name": "支払者名",
                "payee_phone": "電話",
                "payer": "支払者",
                "payer_country": "国",
                "payer_email": "Eメール",
                "payer_id": "支払者",
                "payer_name": "支払者名",
                "payer_phone": "電話",
                "payment_received": "お支払い頂いた",
                "payment_service": "決済サービス",
                "payment_service_account_id": "ID決済アカウント",
                "payment_service_transaction_id": "ID決済取引",
                "payment_thanks": "支払いありがとう",
                "payment_type": "払いの種類",
                "period": "期間",
                "phone": "電話",
                "plan": "プラン",
                "point_of_interest": "興味のある場所",
                "point_of_interest_categories": "カテゴリ",
                "point_of_interest_category": "カテゴリー",
                "point_of_interest_event_expired": "イベントの有効期限が切れました",
                "points_of_interest": "興味がある点",
                "points_of_interest_count": "興味のあるポイントの数",
                "post_category": "投稿カテゴリー",
                "price": "価格",
                "price_excluding_taxes": "税抜価格",
                "prices": "価格",
                "priority": "優先度",
                "privacy_policy": "プライバシーポリシー",
                "privacy_policy_accepted": "プライバシーポリシーが受け入れられました",
                "privacy_policy_version": "プライバシーポリシーのバージョン",
                "private": "プライベート",
                "private_title": "私的称号",
                "pro_file": "プロファイル",
                "profile_picture": "プロフィールの写真",
                "properties": "プロパティ",
                "properties_comparison": "更新されたプロパティ",
                "provider": "プロバイダー",
                "public": "公共",
                "public_id": "公開ID",
                "published_at": "で公開されました",
                "quantity": "量",
                "quitted_at": "で辞めた",
                "radius": "半径",
                "rates": "料金",
                "reason_details": "詳細",
                "reason_type": "理由",
                "recipient": "受信者",
                "recipients": "受信者",
                "recipients_alt": "登録旅行者",
                "remember": "私を覚えてますか",
                "removed_at": "に削除されました",
                "reports": "レポート",
                "requested_at": "でリクエストされました",
                "reservation_created": "予約が作成されました",
                "reservation_date": "日付",
                "reservation_time": "時間",
                "resolved_at": "で解決されました",
                "responsive_images": "レスポンシブ画像",
                "roles": "役割",
                "room_number": "部屋番号",
                "scout_searchable": "インデックス可能",
                "searchable": "検索可能",
                "sender": "差出人",
                "senders": "送信者",
                "service": "サービス",
                "settings": "設定",
                "share_created": "共有が作成されました",
                "shareable": "共有可能",
                "show_contact_email": "連絡先メールの公開範囲",
                "show_contact_phone": "連絡先電話の可視性",
                "show_profile_picture": "アバターの可視性",
                "show_real_name": "姓と名の公開範囲",
                "siret": "シレ",
                "size": "サイズ",
                "source": "ソース",
                "source_id": "ソースID",
                "specified": "指定された",
                "starable": "星印で記されている",
                "stars": "出演者",
                "start_date": "開始日",
                "state": "州",
                "states": "州",
                "static_translations_enabled": "静的翻訳が有効になっています",
                "status": "状態",
                "subject": "主題",
                "subject_id": "件名ID",
                "subject_type": "主題の種類",
                "summary": "まとめ",
                "support_category": "カテゴリー",
                "support_messages_count": "サポートメッセージ数",
                "support_request": "サポートリクエスト",
                "support_request_archived": "サポートリクエストがアーカイブされました",
                "support_request_message_received": "サポートリクエストで受け取った回答",
                "suspended_at": "で一時停止されました",
                "tag": "鬼ごっこ",
                "tags": "タグ",
                "target": "目標",
                "terms_condition_accepted": "利用規約に同意しました",
                "terms_condition_version": "利用規約バージョン",
                "terms_conditions": "利用規約",
                "thanked": "感謝した",
                "thanked_at": "感謝の日",
                "thanks": "ありがとう",
                "thanks_message": "感謝のメッセージ",
                "thanks_sent": "感謝を送りました",
                "tipping_fees": "チップ料金",
                "tips_enabled": "ヒントが有効になっています",
                "title": "タイトル",
                "total_amount": "合計金額",
                "traveller": "旅行者",
                "traveller_folder": "旅行者フォルダー",
                "traveller_folder_approved": "旅行者フォルダーが承認されました",
                "traveller_folder_archived": "旅行者フォルダーがアーカイブされました",
                "traveller_folder_pending": "旅行者フォルダーは保留中です",
                "traveller_folder_thanks": "ご滞在いただきありがとうございます",
                "tutorial_state": "チュートリアルの状態",
                "type": "タイプ",
                "updated_at": "に更新されました",
                "user": "ユーザー",
                "user_id": "ユーザー",
                "user_report": "ユーザー（報告者）",
                "users": "ユーザー",
                "utm_campaign": "運動",
                "utm_medium": "中くらい",
                "utm_settings": "UTMパラメータ",
                "utm_source": "ソース",
                "uuid": "uuid",
                "validated_activities": "検証されたアクティビティ",
                "validated_at": "で検証されました",
                "value": "価値",
                "vat": "バット",
                "vat_rate": "付加価値税",
                "version": "バージョン",
                "video": "ビデオ",
                "videos": "ビデオ",
                "website": "Webサイト",
                "zip": "ジップ"
            },
            "before": " {attribute} は {date} より前の日付である必要があります。",
            "before_or_equal": " {attribute} は {date} 以前の日付である必要があります。",
            "between": {
                "array": " {attribute} には {min} から {max} までのアイテムが含まれている必要があります。",
                "file": " {attribute} は {min} ～ {max} キロバイトでなければなりません。",
                "numeric": " {attribute} は {min} と {max} の間にある必要があります。",
                "string": " {attribute} は {min} から {max} までの文字でなければなりません。"
            },
            "boolean": " {attribute} フィールドは true または false である必要があります。",
            "confirmed": " {attribute}が一致しません。",
            "currency_scale": " {attribute} の小数点以下の桁数は {scale} を超えてはなりません。",
            "current_password": " {attribute} はパスワードと一致しません。",
            "custom": {
                "attribute-name": {
                    "rule-name": "カスタムメッセージ"
                },
                "h-captcha-response": {
                    "HCaptcha": "キャプチャエラーです！後でもう一度試すか、サイト管理者に連絡してください。",
                    "required": "あなたがロボットではないことを確認してください。"
                }
            },
            "date": " {attribute} は有効な日付ではありません。",
            "date_equals": " {attribute} は {date} と等しい日付でなければなりません。",
            "date_format": " {attribute} は形式 {format} と一致しません。",
            "different": " {attribute} と {other} は異なっている必要があります。",
            "digits": " {attribute} は {digits} 桁でなければなりません。",
            "digits_between": " {attribute} は {min} から {max} までの数字でなければなりません。",
            "dimensions": " {attribute} の画像サイズが無効です。",
            "distinct": " {attribute} フィールドに重複した値があります。",
            "email": " {attribute} は有効な電子メール アドレスである必要があります。",
            "ends_with": " {attribute} は次のいずれかで終わる必要があります: {values}。",
            "exists": "選択された {attribute} は無効です。",
            "file": " {attribute} はファイルである必要があります。",
            "filled": " {attribute} フィールドには値が必要です。",
            "gt": {
                "array": " {attribute}には {value}以上のアイテムが含まれている必要があります。",
                "file": " {attribute} は {value} キロバイトより大きくなければなりません。",
                "numeric": " {attribute} は {value} より大きくなければなりません。",
                "string": " {attribute} は {value} 文字より大きくなければなりません。"
            },
            "gte": {
                "array": " {attribute}には {value}以上のアイテムが必要です。",
                "file": " {attribute} は {value} キロバイト以上である必要があります。",
                "numeric": " {attribute} は {value} 以上である必要があります。",
                "string": " {attribute} は {value} 文字以上である必要があります。"
            },
            "image": " {attribute} は画像である必要があります。",
            "in": "選択された {attribute} は無効です。",
            "in_array": " {attribute} フィールドは {other} には存在しません。",
            "integer": " {attribute} は整数でなければなりません。",
            "ip": " {attribute} は有効な IP アドレスである必要があります。",
            "ipv4": " {attribute} は有効な IPv4 アドレスである必要があります。",
            "ipv6": " {attribute} は有効な IPv6 アドレスである必要があります。",
            "json": " {attribute} は有効な JSON 文字列である必要があります。",
            "lt": {
                "array": " {attribute} には {value} 未満のアイテムが含まれている必要があります。",
                "file": " {attribute} は {value} キロバイト未満である必要があります。",
                "numeric": " {attribute} は {value} より小さくなければなりません。",
                "string": " {attribute} は {value} 文字未満である必要があります。"
            },
            "lte": {
                "array": " {attribute} には {value} を超えるアイテムを含めることはできません。",
                "file": " {attribute} は {value} キロバイト以下である必要があります。",
                "numeric": " {attribute} は {value} 以下でなければなりません。",
                "string": " {attribute} は {value} 文字以下である必要があります。"
            },
            "luhn": "{attribute} は無効な数値です。",
            "max": {
                "array": " {attribute} には {max} を超えるアイテムを含めることはできません。",
                "file": " {attribute} は {max} キロバイトを超えることはできません。",
                "height": "{attribute} の高さは {max} ピクセルを超えてはなりません。",
                "numeric": " {attribute} は {max} を超えることはできません。",
                "string": " {attribute} は {max} 文字を超えることはできません。",
                "width": "{attribute} の幅は {max} ピクセルを超えてはなりません。"
            },
            "max_digits": "{attribute} は {max} 桁を超えてはなりません。",
            "mimes": " {attribute} には有効なタイプが必要です。",
            "mimetypes": " {attribute} には有効なタイプが必要です。",
            "min": {
                "array": " {attribute} には少なくとも {min} のアイテムが必要です。",
                "file": " {attribute} は少なくとも {min} キロバイトでなければなりません。",
                "numeric": " {attribute} は少なくとも {min} でなければなりません。",
                "string": " {attribute} は少なくとも {min} 文字でなければなりません。"
            },
            "min_digits": "{attribute} は少なくとも {min} 桁でなければなりません。",
            "not_in": "選択された {attribute} は無効です。",
            "not_regex": " {attribute} 形式が無効です。",
            "notification_setting": " {attribute} 形式が無効です (予期される形式は array[bool] です)。",
            "numeric": " {attribute} は数値でなければなりません。",
            "password": "パスワードが間違っています。",
            "phone": " {attribute} は有効な電話番号である必要があります。",
            "present": " {attribute} フィールドが存在する必要があります。",
            "prohibited_if": " {other} が {value} の場合、 {attribute} フィールドは禁止されます。",
            "prohibited_unless": " {other} が {values} に含まれていない限り、 {attribute} フィールドは禁止されます。",
            "real_name_characters": ":{attribute} には文字、スペース、ドットのみを含めることができます。\n        ハイフンまたはアポストロフィ。",
            "regex": " {attribute} 形式が無効です。",
            "required": " {attribute} フィールドは必須です。",
            "required_if": " {other} が {value} の場合、 {attribute} フィールドは必須です。",
            "required_unless": " {other} が {values} に含まれていない限り、 {attribute} フィールドは必須です。",
            "required_with": " {values} が存在する場合、 {attribute} フィールドは必須です。",
            "required_with_all": " {values} が存在する場合、 {attribute} フィールドは必須です。",
            "required_without": " {values} が存在しない場合、 {attribute} フィールドは必須です。",
            "required_without_all": " {values} が存在しない場合は、 {attribute} フィールドが必要です。",
            "same": " {attribute} と {other} は一致する必要があります。",
            "same_currency": "すべてのアイテムは同じ通貨を使用する必要があります。",
            "size": {
                "array": " {attribute} には {size} アイテムが含まれている必要があります。",
                "file": " {attribute} は {size} キロバイトである必要があります。",
                "numeric": " {attribute} は {size} である必要があります。",
                "string": " {attribute} は {size} 文字である必要があります。"
            },
            "starts_with": " {attribute} は次のいずれかで始まる必要があります: {values}。",
            "string": " {attribute} は文字列である必要があります。",
            "tag_same_poi_category": "{attribute} はすべて同じカテゴリに属する​​必要があります。",
            "timezone": " {attribute} は有効なゾーンである必要があります。",
            "unique": " {attribute} はすでに取られています。",
            "uploaded": " {attribute} のアップロードに失敗しました。",
            "url": " {attribute} 形式が無効です。",
            "uuid": " {attribute} は有効な UUID である必要があります。"
        }
    },
    "ko": {
        "The {attribute} must be at least {length} characters and contain at least one number.": " {attribute}은 {length}자 이상이어야 하며 숫자를 하나 이상 포함해야 합니다.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": " {attribute}은 {length}자 이상이어야 하며 하나 이상의 특수 문자와 하나의 숫자를 포함해야 합니다.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": " {attribute}은 {length}자 이상이어야 하며 특수 문자를 하나 이상 포함해야 합니다.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": " {attribute}은 {length}자 이상이어야 하며 대문자와 숫자를 하나 이상 포함해야 합니다.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": " {attribute}은 {length}자 이상이어야 하며 대문자와 특수 문자를 각각 하나 이상 포함해야 합니다.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": " {attribute}은 {length}자 이상이어야 하며 대문자, 숫자, 특수 문자를 각각 하나씩 포함해야 합니다.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": " {attribute}은 {length}자 이상이어야 하며 대문자를 하나 이상 포함해야 합니다.",
        "The {attribute} must be at least {length} characters.": " {attribute}은 {length}자 이상이어야 합니다.",
        "The {attribute} must contain at least one letter.": " {attribute}에는 문자가 하나 이상 포함되어야 합니다.",
        "The {attribute} must contain at least one number.": " {attribute}에는 숫자가 하나 이상 포함되어야 합니다.",
        "The {attribute} must contain at least one symbol.": " {attribute}에는 하나 이상의 기호가 포함되어야 합니다.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": " {attribute}에는 대문자와 소문자를 각각 하나 이상 포함해야 합니다.",
        "auth": {
            "failed": "이 자격 증명은 우리 기록과 일치하지 않습니다. {type} 계정에 이 이메일을 사용하지 않으셨나요?",
            "throttle": "로그인 시도 횟수가 너무 많습니다. 1초 후에 다시 시도해 주세요.",
            "throttle_cascade": "로그인 시도 횟수가 너무 많습니다. {remaining}에서 다시 시도해 주세요."
        },
        "documents": {
            "display": {
                "description": "QRCode와 소속기관 로고를 연결하여 A6형식으로 표시합니다.",
                "title": "디스플레이 - A6 카드"
            },
            "qrcode": {
                "description": "QRCode만으로 여행자가 기관에 직접 연결될 수 있습니다.",
                "filename": "QR 코드",
                "title": "QR코드 연결"
            }
        },
        "enums": {
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "만들어진",
                "deleted": "삭제됨",
                "updated": "업데이트됨",
                "validated": "검증됨"
            },
            "App\\Enums\\BinaryChoiceEnum": [
                "거부됨",
                "수락됨"
            ],
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "association": "협회(또는 재단)",
                "company": "회사(공공/민간)",
                "individual": "개인",
                "undefined": "한정되지 않은"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "DE": "독일",
                "ES": "스페인",
                "FR": "프랑스",
                "IT": "이탈리아",
                "TH": "태국",
                "undefined": "한정되지 않은"
            },
            "App\\Enums\\ContextsEnum": {
                "city_hall": "시청",
                "hotel": "호텔",
                "manager": "관리자",
                "tourist_office": "관광 안내소",
                "traveller": "여행자"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "유로",
                "usd": "달러"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Friday": "금요일",
                "Monday": "월요일",
                "Saturday": "토요일",
                "Sunday": "일요일",
                "Thursday": "목요일",
                "Tuesday": "화요일",
                "Wednesday": "수요일"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "맞춤형",
                "root": "내 템플릿",
                "ugozer_app": "UgoZER 앱",
                "ugozer_pro": "UgoZER 프로"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "장엄한",
                "metric": "미터법"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "인앱",
                "email": "이메일"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "customer": "고객",
                "partner": "파트너"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "easytransac": "이지트랜잭",
                "paypal": "페이팔",
                "stripe": "줄무늬"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "subscription": "신청",
                "tip": "팁"
            },
            "App\\Enums\\PrivacySettingsEnum": {
                "contacts": "내 연락처",
                "everyone": "모든 사람",
                "nobody": "아무도"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "illegal": "불법적인",
                "incorrect_data": "잘못된 정보",
                "other": "다른",
                "shocking": "충격적인",
                "spam": "스팸"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "아침",
                "dinner": "저녁",
                "lunch": "점심",
                "null": "해당 없음"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "페이스북",
                "google": "Google",
                "instagram": "인스 타 그램",
                "linkedin": "링크드인",
                "tiktok": "Tik의 톡",
                "twitter": "엑스(트위터)",
                "wechat": "위챗"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "1 개월",
                "one year": "일년"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "1 개월",
                "one year": "일년",
                "six months": "6 개월",
                "three months": "3 개월"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "expired": "구독이 종료되었습니다",
                "free period ended": "무료 기간이 종료되었습니다",
                "payment default": "지불 불이행"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "앱",
                "extranet": "엑스트라넷",
                "front": "앞쪽"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "보관됨",
                "canceled": "취소 된",
                "pending_support": "지원 보류 중",
                "pending_user": "대기 중인 사용자",
                "resolved": "해결됨"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "내 주변",
                "customized": "맞춤형",
                "staying_hotel": "숙박호텔 근처"
            },
            "App\\Enums\\UserRoleEnum": {
                "butler": "집사",
                "contributor": "기부자",
                "hotelier": "호텔리어",
                "manager": "관리자",
                "moderator": "중재자",
                "superadmin": "최고관리자",
                "touristOffice": "관광 안내소",
                "touristOfficeEmployee": "직원에게",
                "traveller": "여행자"
            }
        },
        "nova": {
            "lens": {
                "user": {
                    "user_origin": "사용자 출처"
                }
            },
            "menu": {
                "administration": "관리",
                "content": "콘텐츠",
                "excursions": "여행일정",
                "import_poi": "POI 가져오기",
                "miscellaneous": "여러 가지 잡다한",
                "parameters": "매개변수",
                "pois": "가볼만한 곳",
                "subscriptions": "구독",
                "support": "지원하다",
                "users": "사용자"
            },
            "s3_private_download": {
                "link": {
                    "expiration": "링크는 30분 후에 만료됩니다. 이 경우 페이지를 새로 고치세요.",
                    "open": "문서 보기"
                },
                "no_document": "관련 서류가 없습니다",
                "not_found": "S3 드라이브에서 문서를 찾을 수 없습니다..."
            },
            "user_menu": {
                "profile": "내 프로필"
            }
        },
        "pagination": {
            "go_page": "페이지로 이동 {page}",
            "next": "다음 \"",
            "next_alt": "다음",
            "of": "~의",
            "pagination_navigation": "페이지 매김 탐색",
            "previous": "\" 이전의",
            "previous_alt": "이전의",
            "results": "결과",
            "showing": "전시",
            "to": "에게"
        },
        "passwords": {
            "reset": "당신의 비밀번호가 초기화되었습니다!",
            "sent": "비밀번호 재설정 링크를 이메일로 보내드렸습니다!",
            "throttled": "다시 시도하기 전에 잠시 기다려 주십시오.",
            "token": "이 비밀번호 재설정 토큰은 유효하지 않습니다.",
            "user": "해당 사용자가 없습니다."
        },
        "ttmt": {
            "account_deletion": {
                "confirmation": "열리는 페이지에서 <strong>DELETE</strong>라는 단어를 입력하여 삭제를 확인하세요.\n        <strong>확인</strong>을 클릭하세요.",
                "content": {
                    "asking": "계정 삭제 요청이 있는 경우 UgoZER는 사용자의 계정과 데이터를 삭제합니다.\n            법적 또는 규제 요구 사항으로 인해 보안 목적, 사기 행위로 인해 보관해야 하는 경우를 제외하고\n            예방 또는 미지급, 미해결 청구 등 사용자 계정과 관련된 문제로 인해\n            논쟁.",
                    "conservation": {
                        "link": "개인 정보 정책.",
                        "part_1": "UgoZER는 다음에 설명된 목적에 필요한 기간 동안 사용자 데이터를 보관합니다.",
                        "part_2": "CNIL 권장 사항에 따라 3년 동안 사용하지 않은 계정은 삭제됩니다.",
                        "part_3": "사용자는 언제든지 자신의 계정 삭제를 요청할 수 있습니다. UgoZER는 다음 후에도 사용자 데이터를 보유할 수 있습니다.\n                법적 또는 규제 요구 사항에 따라 또는 본 문서에 명시된 이유로 삭제 요청\n                성명."
                    },
                    "instructions": "UgoZER 계정을 삭제하려면 아래 지침을 따르십시오."
                },
                "logout": "귀하는 로그아웃되며, 계정 및 관련 데이터가 즉시 삭제됩니다.",
                "pro": {
                    "instructions": {
                        "delete": "그런 다음 <strong>계정 삭제 앞에 있는 <strong>삭제</strong> 버튼을 클릭하세요.\n                </strong>,",
                        "profile": {
                            "link": "프로필",
                            "part_1": "당신의",
                            "path": "(아바타 > 내 프로필을 클릭하여)"
                        }
                    },
                    "subtitle": "전문가 계정을 삭제하려면:",
                    "title": "전문가 계정"
                },
                "title": "계정 삭제",
                "traveller": {
                    "instructions": {
                        "delete": "그런 다음 <strong>내 계정을 삭제하시겠습니까? 앞에 있는 <strong>삭제</strong> 버튼을 클릭하세요.\n                </strong>",
                        "profile": {
                            "link": "프로필",
                            "part_1": "당신의",
                            "path": "(햄버거 아이콘 메뉴 > 설정 > 프로필),"
                        }
                    },
                    "subtitle": "여행자 계정을 삭제하려면:",
                    "title": "여행자 계정"
                }
            },
            "activity_logs": {
                "summary": {
                    "created": {
                        "intro": "주제는 다음 속성으로 생성되었습니다."
                    },
                    "deleted": {
                        "intro": "제목이 다음 속성으로 삭제되었습니다."
                    },
                    "updated": {
                        "attribute_from_to": "{attribute}이 \"{old_value}\"에서 \"{new_value}\"로 업데이트되었습니다.",
                        "intro": "제목이 업데이트되었습니다:"
                    },
                    "validated": {
                        "intro": "제목이 확인되었습니다:"
                    }
                }
            },
            "auth": {
                "credential_verification": {
                    "intro": "자격 증명을 입력하면 확인 링크가 전송됩니다.",
                    "intro2": "링크에 액세스하는 즉시 자격 증명이 업데이트됩니다."
                },
                "expiration": {
                    "session_expired": "세션이 만료되었습니다. 다시 인증하세요."
                },
                "extranet_register": {
                    "form": {
                        "choose_establishment": "귀하의 시설 유형을 선택하십시오",
                        "form_title": "계정 만들기",
                        "validate_registration": "내 등록을 완료하세요"
                    },
                    "success": "성공적으로 등록되었습니다. 확인 링크가 이메일로 전송되었습니다."
                },
                "login": {
                    "already_logged": "귀하는 이미 로그인하셨습니다",
                    "logging_in": "로그인 중...",
                    "success": "로그인",
                    "use_email": "이메일로 로그인",
                    "use_social_networks": "소셜 네트워크 사용"
                },
                "logout": {
                    "logging_out": "로그 아웃하다...",
                    "switch": "로 전환"
                },
                "messages": {
                    "continue": "계속",
                    "extranet_here": "여기를 클릭하세요",
                    "have_account": "이미 계정이 있나요?",
                    "login": "로그인",
                    "login_subtitle": "(등록 또는 로그인)",
                    "login_title": "소셜 네트워크를 사용하면 더 좋습니다.",
                    "no_account": "아직 계정이 없나요?",
                    "or": "또는",
                    "professional": "당신은 전문가입니까?",
                    "register": "등록하다",
                    "register_mail": "이메일로 등록",
                    "register_title": "모든 기능을 잠금 해제하려면 등록하세요",
                    "reset_password": "암호를 재설정",
                    "send_magic_link": "비밀번호 없는 링크 보내기"
                },
                "password_forgotten": {
                    "intro": "자격 증명을 입력하면 비밀번호를 재설정할 수 있는 링크를 보내드립니다.",
                    "submit": "재설정 링크 받기",
                    "success": {
                        "correct_credential": "올바른 자격 증명",
                        "email_1": "이 주소가 잘 등록되어 있으면 {email}으로 이메일이 전송됩니다.",
                        "email_2": "이 이메일에는 비밀번호를 재설정할 수 있는 링크가 포함되어 있습니다.",
                        "send_again": "다시 보내기",
                        "title": "비밀번호 재설정 링크가 전송되었습니다.",
                        "wait_before": "다시 보내기 전에 1초 정도 기다려 주십시오."
                    }
                },
                "password_reset": {
                    "submit": "비밀번호를 재설정",
                    "success": "비밀번호가 재설정되었습니다."
                },
                "register": {
                    "form": {
                        "hide_privacy_policy": "개인 정보 보호 정책 숨기기",
                        "hide_terms_conditions": "이용약관 숨기기",
                        "privacy_policy": "개인정보취급방침에 동의합니다.",
                        "show_privacy_policy": "개인 정보 보호 정책 표시",
                        "show_terms_conditions": "이용약관 보기",
                        "terms_conditions": "이용약관에 동의합니다."
                    },
                    "staying_hotel": "일단 등록하시면, 호텔의 관리를 받게 됩니다.\n            방금 QR 코드를 스캔했습니다.",
                    "success": {
                        "email_1": " {email}로 이메일이 전송됩니다.",
                        "email_2": "이 이메일에는 주소를 확인하고 연결할 수 있는 링크가 포함되어 있습니다.",
                        "paired": {
                            "email_1": "두 개의 이메일이 다음 주소로 전송되었습니다",
                            "email_2": "각 이메일에는 주소를 확인하고 연결된 계정을 연결할 수 있는 링크가 포함되어 있습니다."
                        },
                        "title": "성공적으로 등록되었습니다"
                    }
                },
                "social_networks": {
                    "authenticate": "다음으로 로그인",
                    "facebook": "페이스북",
                    "google": "Google",
                    "no_email": "소셜 네트워크에 연결된 이메일이 없으므로 인증할 수 없습니다.",
                    "twitter": "트위터"
                },
                "travel_assistant": "무료 여행 도우미"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "은행 계좌가 제공되지 않았습니다."
                    },
                    "form": {
                        "placeholders": {
                            "bank_city": "은행 지점이 위치한 도시입니다.",
                            "bank_name": "은행 이름",
                            "bank_transfer_reason": "예: \"UgoZER 팁\"",
                            "bic": "BIC를 입력하세요",
                            "iban": "IBAN / BBAN을 입력하세요",
                            "payee_name": "수취인 이름 또는 기관 이름",
                            "types": "이반/바반"
                        }
                    },
                    "lines": {
                        "no_account_yet": "귀하의 은행 계좌를 통해 귀하의 기관은 UgoZER에서 대금을 받을 수 있습니다."
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "amount": "양",
                    "amount_to_pay": "지불할 금액",
                    "concierge": "관리인",
                    "currency": "통화",
                    "customer": "고객",
                    "date": "날짜",
                    "repartition_key": "배포 키",
                    "tip_sharing_form": "팁 공유 양식",
                    "tips_received": "팁을 받았습니다",
                    "total_received": "받은 총액"
                }
            },
            "breadcrumbs": {
                "changelogs": {
                    "changelogs": "변경 로그"
                },
                "claims": {
                    "claims": "청구"
                },
                "common": {
                    "add": "추가하다",
                    "claim": "주장하다",
                    "create": "새로운",
                    "create_f": "새로운",
                    "delete": "삭제",
                    "download_pdf": "PDF 다운로드",
                    "edit": "편집하다",
                    "report": "보고서",
                    "share": "공유하다",
                    "show": "보여주다"
                },
                "contacts": {
                    "contacts": "콘택트 렌즈"
                },
                "cookie": {
                    "cookie": "쿠키 관리"
                },
                "credit": {
                    "credit": "크레딧"
                },
                "dashboard": {
                    "dashboard": "계기반"
                },
                "errors": {
                    "404": {
                        "label": "오류 404",
                        "title": "오류 404 - 찾을 수 없음"
                    }
                },
                "excursions": {
                    "create": "새로운 여행 일정",
                    "excursions": "여행일정"
                },
                "faq": {
                    "faq": "자주 묻는 질문"
                },
                "finances": {
                    "finances": "재정"
                },
                "institutions": {
                    "institution": "기관",
                    "institutions": "기관"
                },
                "institutions_employees": {
                    "institutions_employees": "내 직원",
                    "invite": "직원 초대"
                },
                "interactions": {
                    "interactions": "상호작용"
                },
                "invitations": {
                    "invitations": "초대장"
                },
                "notification_settings": {
                    "notification_settings": "알림 설정"
                },
                "notifications": {
                    "notifications": "알림"
                },
                "partners": {
                    "customers": "고객",
                    "partners": "파트너"
                },
                "points_of_interest": {
                    "add_excursion": "여행 일정에 추가",
                    "points_of_interest": "가볼만한 곳"
                },
                "privacy_policy": {
                    "privacy_policy": "개인 정보 정책"
                },
                "privacy_settings": {
                    "privacy_settings": "개인 정보 설정"
                },
                "reservations": {
                    "reservations": "전세"
                },
                "search": {
                    "search": "찾다"
                },
                "subscriptions": {
                    "subscription": "신청"
                },
                "support_messages": {
                    "add_message": "메시지 추가",
                    "support_messages": "메시지"
                },
                "support_requests": {
                    "add_request": "새로운 지원 요청 추가",
                    "support_requests": "지원 요청"
                },
                "terms_condition": {
                    "terms_condition": "이용 약관"
                },
                "travellers": {
                    "folders": "여행자 폴더",
                    "travellers": "여행자"
                },
                "tutorials": {
                    "begin": "시작하기"
                },
                "users": {
                    "edit_credential": "자격증명 편집",
                    "profile": "내 프로필",
                    "users": "사용자"
                }
            },
            "changelogs": {
                "archive": {
                    "success": "변경 로그가 보관되었습니다(적격한 경우)."
                },
                "draft": {
                    "success": "변경 로그 초안이 작성되었습니다(적격한 경우)."
                },
                "index": {
                    "sections": {
                        "all": "모든 업데이트",
                        "last": "마지막 업데이트"
                    }
                },
                "publish": {
                    "success": "변경 로그가 게시되었습니다(적격한 경우)."
                },
                "show": {
                    "version": "버전"
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "소유권 주장 이의 제기가 생성되었습니다."
                },
                "rejected": {
                    "success": "소유권 주장 이의 제기가 거부되었습니다."
                },
                "validated": {
                    "success": "소유권 주장 이의 제기가 확인되었습니다."
                }
            },
            "claims": {
                "form_wizard": {
                    "documents": {
                        "DE": {
                            "association_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein(neuere)\n                    버전 mit MRZ-Zeilen) oder Aufenthaltstitel(nur wenn Sie aus einem europäischen Land kommen)",
                            "association_statutes": "Handelsregisterauszug",
                            "company_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein(neuere)\n                    버전 mit MRZ-Zeilen) oder Aufenthaltstitel(nur wenn Sie aus einem europäischen Land kommen)",
                            "company_registration_document": "Handelsregisterauszug",
                            "company_statutes": "Aktuelle und unterschriebene (von der Geschäftsführung) Gesellschaftverträge",
                            "individual_first_identity_piece": "Reisepass, Personalausweis, Führerschein(neuere 버전 mit\n                    MRZ-Zeilen) oder Aufenthaltstitel(nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_registration_document": "Gewerbeschein, Handlungsbevollmächtigung, Handelsregisterauszug,\n                    Prokura, Steuernummer 귀속서",
                            "individual_second_identity_piece": "Reisepass, Personalausweis, Führerschein(neuere 버전 mit\n                    MRZ-Zeilen) oder Aufenthaltstitel(nur wenn Sie aus einem europäischen Land kommen)"
                        },
                        "ES": {
                            "association_legal_representative_identity_piece": "Pasaporte, Documento nacional de identidad,\n                    Permiso de conducir (versión reciente con líneas MRZ) 또는 Permiso de Residencencia (solo si es de un\n                    파이 유럽)",
                            "association_statutes": "Escrituras de constitución o Estatutos sociales",
                            "company_legal_representative_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso\n                    de conducir (version reciente con líneas MRZ) 또는 Permiso de Residencencia (solo si es de un país)\n                    유럽)",
                            "company_registration_document": "Extracto del Registro Mercantil Central, Nota Simple del Registro\n                    mercantil local Sellado o Numerico, Extracto desde los Registradores de España(정보)\n                    General Mercantil) con fecha de validez de menos de 3 meses o Modelo 600 con fecha de validez\n                    de menos de 3 meses (솔로 파라 소시에다드 시민)",
                            "company_statutes": "Escrituras de constitución o Estatutos sociales",
                            "individual_first_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso de conducir\n                    (versión reciente con líneas MRZ) o Permiso de Residencencia (solo si es de un país Europeo)",
                            "individual_registration_document": "Agencia Tributaria - Certificado de situacion Censal\n                    (알타 센살)",
                            "individual_second_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso de conducir\n                    (versión reciente con líneas MRZ) o Permiso de Residencencia (solo si es de un país Europeo)"
                        },
                        "FR": {
                            "association_legal_representative_identity_piece": "Une pièce d'identité en cours de validité du\n                    대표 légal de l'association: carte d'identité(직근 등), 여권, titre de\n                    séjour (reto et verso), permis de conduire biométrique (reto et verso)",
                            "association_statutes": "협회 법령",
                            "company_legal_representative_identity_piece": "Une pièce d'identité en cours de validité du\n                    대표자 légal de la société: carte d'identité(직근 등), 여권, titre de séjour\n                    (reto et verso), permis de conduire biométrique (직전 et verso)",
                            "company_registration_document": "유엔 KBIS",
                            "company_statutes": "법령",
                            "individual_first_identity_piece": "Une première pièce d'identité en cours de validité: 카르트\n                    d'identité(reto et verso), 여권, titre de séjour(reto et verso), permis de conduire\n                    biométrique (직근 등)",
                            "individual_registration_document": "Un certificat d'inscription à l'INSEE ou un 문서\n                    attestant de son 비문 auprès de la Chambre de Commerce(pour un auto-entrepreneur exerçant)\n                    une activité commerciale) ou auprès de la Chambre des métiers(pour un auto-entrepreneur exerçant)\n                    une activité artisanale)",
                            "individual_second_identity_piece": "Une seconde pièce d'identité en cours de validité: 카르트\n                    d'identité(reto et verso), 여권, titre de séjour(reto et verso), permis de conduire\n                    (righto et verso), dernier avis d'imposition daté de moins d'un an, livret de famille ou\n                    récépissé d'enregistrement du pacte Civil de Solidarité"
                        },
                        "IT": {
                            "association_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, 라\n                    Patente di guida (versione Recente con linee MRZ) o Permesso di Residentenza (solo se di un paese)\n                    유럽)",
                            "association_statutes": "tatuto Firmato dal Legale Rappresentante(se non incluso all'interno della\n                    비수라 카메라레",
                            "company_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, La Patente\n                    di guida(versione Recente con linee MRZ) o Permesso di Residentenza(solo se di un paese Europeo)",
                            "company_registration_document": "비수라 카메라레",
                            "company_statutes": "Statuto Firmato dal Legale Rappresentante(se non incluso all'interno della\n                    비수라 카메라레)",
                            "individual_first_identity_piece": "Passaporto, Carta d'identità nazionale, La Patente di Guida\n                    (최근 버전은 MRZ에 속함) o Permesso di Residentenza(solo se di un paese Europeo)",
                            "individual_registration_document": "Estratto dal registro delle imprese (\"Visura Camerale\") di meno\n                    디 3 메시",
                            "individual_second_identity_piece": "Passaporto, Carta d'identità nazionale, La Patente di Guida\n                    (최근 버전은 MRZ에 속함) o Permesso di Residentenza(solo se di un paese Europeo)"
                        },
                        "TH": {
                            "association_legal_representative_identity_piece": "다음 내용은 다음과 같습니다.\n                    감사합니다.\n                    나는 에디딘, ใบมุญม้ม่ ถ्त्त्ท่มู่ (ท्้้้้ 하고 มห้하고 ะ้하고있는 หม하고),\n                    미국의 미국인",
                            "association_statutes": "쿵쿵게임",
                            "company_legal_representative_identity_piece": "다음 내용은 다음과 같습니다.\n                    나는 당신이 좋아하는 것입니다.\n                    ใบมุญม้ม่ ถ่มท่มู่ (د้ان หن้้ا 및 ะ digit้้ان หม่),\n                    미국의 미국인",
                            "company_registration_document": "KBIS의 스포츠",
                            "company_statutes": "스포츠",
                            "individual_first_identity_piece": "이 페이지의 내용은 다음과 같습니다.\n                    (그럼에도 불구하고), 당신은 당신의 의견에 동의합니다. 예),\n                    미국의 미국인",
                            "individual_registration_document": "자동차와 함께하는 자동차와 함께하는 자동차 여행\n                    (스페인어는 챠티에스티의 에인틴카테고리)\n                    หप्र्तบหมหมค้้ม (스포츠)\n                    경찰서)",
                            "individual_second_identity_piece": "다음 내용은 다음과 같습니다.\n                    (د้ان หن้้ا 및 ะ د้้้ان ات) ห्त्त्ืא ุญ้มตบ्บมุญมตตถ้่มท่ม่ ท่ม्ู่\n                    (د้ان หن้้ا 과 ะ د้ان หม้) ใบข้บข้่ (د्้त्त्หม้้้ 하고 ะ د้ان หม้)\n                    ปप्रะมศภมษ้ ค्้้넷 มุ้ ท้้้ 몸 ้ ม้ม ท 않고 ้ ม้ tail tails 자동차 경주\n                    미국의 스포츠"
                        }
                    },
                    "steps": {
                        "claim": "주장하다",
                        "context": "문맥",
                        "create": "만들다",
                        "documents": "서류",
                        "info": "정보",
                        "search": "찾다"
                    }
                },
                "missing_info": {
                    "success": "정보 누락으로 인해 청구가 거부되었습니다."
                },
                "notice": {
                    "actions": {
                        "edit": "내 요청 수정"
                    },
                    "already_claimed": {
                        "description": "이 관심 지점은 이미 주장되었습니다. 이것이 오류라고 생각되면 다음을 수행하십시오.\n                아래 분쟁 양식을 작성하세요.",
                        "title": "관심 장소가 이미 등록되었습니다!"
                    },
                    "find_or_new": {
                        "description": "아래 목록에서 관심 지점을 검색하거나 새로 생성하세요.",
                        "title": "POI 검색 또는 생성"
                    },
                    "missing-info": {
                        "infos": "아래 버튼을 클릭하여 요청을 완료해 주세요.",
                        "state": "귀하의 청구는 현재 추가 정보나 문서를 기다리고 있습니다.",
                        "title": "추가 정보를 기다리는 중"
                    },
                    "pending": {
                        "state": "귀하의 청구 요청이 현재 처리 중입니다.",
                        "title": "대기 중인 청구 요청"
                    },
                    "rejected": {
                        "state": "다음과 같은 이유로 귀하의 청구 요청이 거부되었습니다.",
                        "title": "거부된 청구 요청"
                    }
                },
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "주장하다",
                            "poi": "관심 장소",
                            "user": "사용자"
                        }
                    }
                },
                "rejected": {
                    "success": "청구가 거부되었습니다."
                },
                "revoked": {
                    "success": "소유권 주장이 취소되었습니다."
                },
                "states": {
                    "missing-info": "빠진 정보",
                    "pending": "보류 중",
                    "rejected": "거부됨",
                    "revoked": "취소됨",
                    "validated": "검증됨"
                },
                "update": {
                    "success": "소유권 주장이 업데이트되었습니다."
                },
                "valid_identity_pieces": "유효한 신분증, 여권 또는 운전면허증",
                "validated": {
                    "success": "소유권 주장이 확인되었습니다."
                }
            },
            "common": {
                "actions": {
                    "accept": "수용하다",
                    "accept_contact": "연락요청 수락",
                    "add": "추가하다",
                    "add_contact": "연락처에 추가",
                    "add_excursion": "여행 일정에 추가",
                    "add_message": "메시지 추가",
                    "apply": "적용하다",
                    "approve": "승인하다",
                    "archive": "보관소",
                    "attach": "붙이다",
                    "block_contact": "연락 차단",
                    "cancel": "취소",
                    "check": "확인하다",
                    "claim": "주장하다",
                    "close": "닫다",
                    "configure": "구성",
                    "confirm": "확인하다",
                    "contest": "대회",
                    "create": "만들다",
                    "decline": "부인하다",
                    "decline_contact": "연락 요청 거절",
                    "delete": "삭제",
                    "download": "다운로드",
                    "edit": "편집하다",
                    "file_preview": "미리보기 파일",
                    "filters": "필터",
                    "give_a_tip": "팁을 주세요",
                    "google_maps": "Google 지도에서 열기",
                    "help": "돕다",
                    "hide": "숨다",
                    "ignore": "무시하다",
                    "interactions": "상호작용 표시",
                    "invite": "초대하다",
                    "load_more": "더 로드하기",
                    "login": "로그인",
                    "logout": "로그 아웃",
                    "manage": "관리하다",
                    "next": "다음",
                    "pause": "정지시키다",
                    "pay": "지불하다",
                    "play": "놀다",
                    "print": "인쇄",
                    "quit": "그만두다",
                    "record": "기록",
                    "register": "등록하다",
                    "report": "보고서",
                    "request_to_join": "가입 요청",
                    "reset": "초기화",
                    "resume": "재개하다",
                    "retry": "다시 해 보다",
                    "return": "뒤쪽에",
                    "save": "구하다",
                    "save_alt": "구하다",
                    "search": "찾다",
                    "see_plans": "요금제 보기",
                    "see_website": "웹사이트 보기",
                    "send": "보내다",
                    "send_link": "앱 링크 보내기",
                    "share": "공유하다",
                    "show": "보여주다",
                    "start": "시작",
                    "stop": "멈추다",
                    "subscribe": "구독하다",
                    "thank": "감사하다",
                    "thank_for_stay": "숙박해주셔서 감사합니다",
                    "traveller_show": "여행자에게 문의하세요",
                    "unblock_contact": "연락처 차단 해제",
                    "unblock_this_user": "이 사용자 차단을 해제하세요",
                    "update": "업데이트",
                    "validate": "확인"
                },
                "all": "모두",
                "and": "그리고",
                "app_name": "우고ZER",
                "back_to_dashboard": "대시보드로 돌아가기",
                "back_to_home": "홈으로",
                "characters_limit": "{length} / {limit} 문자",
                "counter": " {divisor}에 {dividend}",
                "country": "국가",
                "dashboard": "계기반",
                "errors": {
                    "deleted_user": "삭제 된 사용자",
                    "internal": "문제가 발생했습니다.",
                    "not_found": "찾을 수 없음"
                },
                "false": "아니요",
                "fast": "빠른",
                "home": "집",
                "later": "나중에",
                "loading": "로드 중",
                "messages": {
                    "application_update": {
                        "if_ignore": "업데이트를 무시하면 다음에 애플리케이션을 시작할 때 업데이트가 완료됩니다.",
                        "new_version_available": "새 버전을 사용할 수 있습니다.",
                        "updating": "업데이트 중...",
                        "want_to_refresh": "업데이트하기 위해 애플리케이션을 다시 시작하시겠습니까?"
                    },
                    "clipboard": {
                        "error": "클립보드에 복사하는 중 오류가 발생했습니다...",
                        "success": "클립보드에 복사되었습니다!"
                    },
                    "no_records": "기록 없음"
                },
                "missing_title": "제목 누락",
                "more_options": "더 많은 옵션",
                "no": "아니요",
                "none": "없음",
                "normal": "정상",
                "not_specified": "명시되지 않은",
                "online": {
                    "connected": "인터넷에 다시 연결되었습니다",
                    "disconnected": "더 이상 인터넷에 연결되어 있지 않습니다."
                },
                "oops": "이런",
                "or": "또는",
                "qr_code": "QR 코드",
                "search": "찾다",
                "slow": "느린",
                "someone": "누구",
                "sort": "종류",
                "tip": "팁",
                "tip_received": "팁 수신됨(활성화된 경우)",
                "true": "예",
                "units": {
                    "kilometer": "킬로미터 | 킬로미터",
                    "km": "km",
                    "mi": "미",
                    "mile": "마일 | 마일"
                },
                "welcome": "환영",
                "yes": "예"
            },
            "cookies": {
                "details": {
                    "content": "다음은 UgoZER 애플리케이션의 이 섹션에 배치된 쿠키 목록입니다.\n            위에 정의된 카테고리:",
                    "table": {
                        "headers": {
                            "category": "사용 카테고리",
                            "description": "설명",
                            "lifespan": "일생",
                            "mandatory": "필수적인",
                            "name": "쿠키 이름(또는 패턴)"
                        },
                        "lines": {
                            "alphanumeric": {
                                "content": "세션에 대한 정보를 포함합니다.",
                                "lifespan": "2시간",
                                "title": "[[{alnum}:]] (영숫자의 시퀀스)"
                            },
                            "gmaps": {
                                "content": "Google 지도 iframe을 표시하는 데 사용됩니다.",
                                "lifespan": "180-400일",
                                "title": "CONSENT, AEC, SOCS...(또는 기타 google.com 도메인 쿠키)"
                            },
                            "remember": {
                                "content": "사용자의 인증을 유지하기 위해 사용됩니다.",
                                "lifespan": "400일",
                                "title": "Remember_web_*"
                            },
                            "session": {
                                "content": "세션에 대한 정보를 포함합니다.",
                                "lifespan": "2시간",
                                "title": "*_ugozer_session"
                            },
                            "tarteaucitron": {
                                "content": "사용자의 쿠키 설정을 유지할 수 있습니다.",
                                "lifespan": "365일",
                                "title": "타르토시트론"
                            },
                            "xsrf": {
                                "content": "애플리케이션(요청, 양식...)을 보호할 수 있습니다.",
                                "lifespan": "2시간",
                                "title": "XSRF-토큰-*"
                            }
                        }
                    },
                    "title": "저장된 쿠키의 세부정보"
                },
                "management": {
                    "content": {
                        "cta": "내 환경설정 변경",
                        "line_1": "이 사이트의 일부 기능은 쿠키 사용에 의존합니다.",
                        "line_2": "필요한 경우에만 탐색 중에 쿠키 배너가 홈 페이지에 표시되지 않습니다.\n                사이트의 기능을 위해 예치됩니다.",
                        "line_3": "아래 버튼을 클릭하여 기본 설정을 변경할 수 있습니다."
                    },
                    "title": "쿠키 관리"
                },
                "notice": {
                    "needed": "이 섹션을 보려면 쿠키 {name}을 허용해야 합니다.",
                    "refresh": "쿠키를 허용한 후에도 표시되지 않으면 페이지를 새로 고치십시오.",
                    "title": "쿠키"
                },
                "services": {
                    "gmaps": "구글지도",
                    "youtube": "유튜브"
                },
                "usage": {
                    "content": "쿠키는 귀하가 UgoZER를 방문할 때 귀하를 인식하고, 귀하의 기본 설정을 기억하고,\n            귀하의 설정에 따라 개인화된 경험을 제공합니다. 쿠키는 또한 귀하와 상호작용을 하게 합니다.\n            UgoZER는 더욱 빠르고 안전합니다. 또한 쿠키를 통해 당사는 귀하에게 광고를 제공할 수 있습니다.\n            UgoZER 사이트.",
                    "table": {
                        "headers": {
                            "category": "사용 카테고리",
                            "description": "설명"
                        },
                        "lines": {
                            "ads": {
                                "content": "우리는 UgoZER 사이트 안팎에서 관련 광고를 표시하기 위해 쿠키를 사용할 수 있습니다.\n                        우리는 또한 쿠키를 사용하여 나중에 광고를 본 사람이 방문하여 광고를 읽었는지 알아볼 수도 있습니다.\n                        광고주 사이트에서의 행동(예: 백서 다운로드 또는 구매)\n                        마찬가지로 당사의 파트너는 쿠키를 사용하여 당사가 광고를 표시했는지 여부와 표시 방법을 확인할 수 있습니다.\n                        수행하거나 귀하가 광고와 상호 작용하는 방식에 대한 정보를 당사에 제공하십시오. 우리도 일할 수도 있다\n                        파트너와 함께 UgoZER 안팎에서 광고를 표시합니다(예: 파트너의 웹사이트를 방문한 후).\n                        사이트 또는 응용 프로그램.",
                                "title": "광고하는"
                            },
                            "authentication": {
                                "content": "UgoZER에 로그인한 경우 쿠키는 귀하에게 올바른 정보를 표시하고\n                        귀하의 경험을 개인화하십시오.",
                                "title": "입증"
                            },
                            "security": {
                                "content": "당사는 보안 기능을 활성화 및 지원하고 탐지를 돕기 위해 쿠키를 사용합니다.\n                        악의적인 활동 및 당사의 위반",
                                "cta": "이용 약관.",
                                "title": "보안"
                            },
                            "services": {
                                "content": "쿠키는 귀하에게 기능, 통찰력 및 맞춤형 콘텐츠를 제공합니다.",
                                "title": "기본 설정, 기능 및 서비스"
                            }
                        }
                    },
                    "title": "쿠키는 무엇을 위해 사용됩니까?"
                }
            },
            "countries": {
                "AD": "안도라",
                "AE": "아랍 에미리트",
                "AF": "아프가니스탄",
                "AG": "앤티가 바부다",
                "AI": "앵귈라",
                "AL": "알바니아",
                "AM": "아르메니아",
                "AO": "앙골라",
                "AQ": "남극 대륙",
                "AR": "아르헨티나",
                "AS": "아메리칸사모아",
                "AT": "오스트리아",
                "AU": "호주",
                "AW": "아루바",
                "AX": "올란드 제도",
                "AZ": "아제르바이잔",
                "BA": "보스니아 헤르체고비나",
                "BB": "바베이도스",
                "BD": "방글라데시",
                "BE": "벨기에",
                "BF": "부키 나 파소",
                "BG": "불가리아",
                "BH": "바레인",
                "BI": "부룬디",
                "BJ": "베냉",
                "BL": "생바르텔레미",
                "BM": "버뮤다",
                "BN": "브루나이",
                "BO": "볼리비아",
                "BQ": "카리브해 네덜란드",
                "BR": "브라질",
                "BS": "바하마",
                "BT": "부탄",
                "BV": "부베섬",
                "BW": "보츠와나",
                "BY": "벨라루스",
                "BZ": "벨리즈",
                "CA": "캐나다",
                "CC": "코코스(킬링) 제도",
                "CD": "콩고 - 킨샤사",
                "CF": "중앙 아프리카 공화국",
                "CG": "콩고 - 브라자빌",
                "CH": "스위스",
                "CI": "코트디부아르",
                "CK": "쿡 제도",
                "CL": "칠레",
                "CM": "카메룬",
                "CN": "중국",
                "CO": "콜롬비아",
                "CR": "코스타리카",
                "CU": "쿠바",
                "CV": "카보베르데",
                "CW": "퀴라소",
                "CX": "크리스마스 섬",
                "CY": "키프로스",
                "CZ": "체코",
                "DE": "독일",
                "DJ": "지부티",
                "DK": "덴마크",
                "DM": "도미니카",
                "DO": "도미니카 공화국",
                "DZ": "알제리",
                "EC": "에콰도르",
                "EE": "에스토니아",
                "EG": "이집트",
                "EH": "서부 사하라",
                "ER": "에리트레아",
                "ES": "스페인",
                "ET": "에티오피아",
                "FI": "핀란드",
                "FJ": "피지",
                "FK": "포클랜드 제도",
                "FM": "미크로네시아",
                "FO": "페로 제도",
                "FR": "프랑스",
                "GA": "가봉",
                "GB": "영국",
                "GD": "그레나다",
                "GE": "그루지야",
                "GF": "프랑스령 기아나",
                "GG": "건지",
                "GH": "가나",
                "GI": "지브롤터",
                "GL": "그린란드",
                "GM": "감비아",
                "GN": "기니",
                "GP": "과들루프",
                "GQ": "적도 기니",
                "GR": "그리스",
                "GS": "사우스조지아 사우스샌드위치 제도",
                "GT": "과테말라",
                "GU": "괌",
                "GW": "기니비사우",
                "GY": "가이아나",
                "HK": "홍콩특별행정구",
                "HM": "허드 & 맥도날드 제도",
                "HN": "온두라스",
                "HR": "크로아티아",
                "HT": "아이티",
                "HU": "헝가리",
                "ID": "인도네시아",
                "IE": "아일랜드",
                "IL": "이스라엘",
                "IM": "맨 섬",
                "IN": "인도",
                "IO": "영국령 인도양 지역",
                "IQ": "이라크",
                "IR": "이란",
                "IS": "아이슬란드",
                "IT": "이탈리아",
                "JE": "저지",
                "JM": "자메이카",
                "JO": "요르단",
                "JP": "일본",
                "KE": "케냐",
                "KG": "키르기스스탄",
                "KH": "캄보디아",
                "KI": "키리바시",
                "KM": "코모로",
                "KN": "세인트 키츠 네비스",
                "KP": "북한",
                "KR": "대한민국",
                "KW": "쿠웨이트",
                "KY": "케이맨 제도",
                "KZ": "카자흐스탄",
                "LA": "라오스",
                "LB": "레바논",
                "LC": "세인트루시아",
                "LI": "리히텐슈타인",
                "LK": "스리랑카",
                "LR": "라이베리아",
                "LS": "레소토",
                "LT": "리투아니아",
                "LU": "룩셈부르크",
                "LV": "라트비아",
                "LY": "리비아",
                "MA": "모로코",
                "MC": "모나코",
                "MD": "몰도바",
                "ME": "몬테네그로",
                "MF": "세인트 마틴",
                "MG": "마다가스카르",
                "MH": "마샬 군도",
                "MK": "북 마케도니아",
                "ML": "말리",
                "MM": "미얀마(버마)",
                "MN": "몽골리아",
                "MO": "마카오 특별행정구 중국",
                "MP": "북마리아나 제도",
                "MQ": "마르티니크",
                "MR": "모리타니",
                "MS": "몬세라트",
                "MT": "몰타",
                "MU": "모리셔스",
                "MV": "몰디브",
                "MW": "말라위",
                "MX": "멕시코",
                "MY": "말레이시아",
                "MZ": "모잠비크",
                "NA": "나미비아",
                "NC": "뉴 칼레도니아",
                "NE": "니제르",
                "NF": "노퍽 섬",
                "NG": "나이지리아",
                "NI": "니카라과",
                "NL": "네덜란드",
                "NO": "노르웨이",
                "NP": "네팔",
                "NR": "나우루",
                "NU": "니우에",
                "NZ": "뉴질랜드",
                "OM": "오만",
                "PA": "파나마",
                "PE": "페루",
                "PF": "프랑스 령 폴리네시아의",
                "PG": "파푸아 뉴기니",
                "PH": "필리핀 제도",
                "PK": "파키스탄",
                "PL": "폴란드",
                "PM": "생 피에르 앤 미클롱",
                "PN": "핏케언 제도",
                "PR": "푸에르토 리코",
                "PS": "팔레스타인 영토",
                "PT": "포르투갈",
                "PW": "팔라우",
                "PY": "파라과이",
                "QA": "카타르",
                "RE": "재결합",
                "RO": "루마니아",
                "RS": "세르비아",
                "RU": "러시아",
                "RW": "르완다",
                "SA": "사우디 아라비아",
                "SB": "솔로몬 제도",
                "SC": "세이셸",
                "SD": "수단",
                "SE": "스웨덴",
                "SG": "싱가포르",
                "SH": "세인트 헬레나",
                "SI": "슬로베니아",
                "SJ": "스발바르 & 얀 마옌",
                "SK": "슬로바키아",
                "SL": "시에라리온",
                "SM": "산 마리노",
                "SN": "세네갈",
                "SO": "소말리아",
                "SR": "수리남",
                "SS": "남 수단",
                "ST": "상투메 프린시페",
                "SV": "엘살바도르",
                "SX": "신트마르턴",
                "SY": "시리아",
                "SZ": "에스와티니",
                "TC": "터크스 케이커스 제도",
                "TD": "차드",
                "TF": "프랑스 남부 지역",
                "TG": "토고",
                "TH": "태국",
                "TJ": "타지키스탄",
                "TK": "토켈라우",
                "TL": "동티모르",
                "TM": "투르크메니스탄",
                "TN": "튀니지",
                "TO": "통가",
                "TR": "칠면조",
                "TT": "트리니다드 토바고",
                "TV": "투발루",
                "TW": "대만",
                "TZ": "탄자니아",
                "UA": "우크라이나",
                "UG": "우간다",
                "UM": "미국 외곽 섬",
                "US": "미국",
                "UY": "우루과이",
                "UZ": "우즈베키스탄",
                "VA": "바티칸 시국",
                "VC": "세인트 빈센트 그레나딘",
                "VE": "베네수엘라",
                "VG": "영국령 버진아일랜드",
                "VI": "미국령 버진아일랜드",
                "VN": "베트남",
                "VU": "바누아투",
                "WF": "월리스푸투나",
                "WS": "사모아",
                "XK": "코소보",
                "YE": "예멘",
                "YT": "마요트",
                "ZA": "남아프리카",
                "ZM": "잠비아",
                "ZW": "짐바브웨"
            },
            "credits": {
                "images": "사진 크레딧"
            },
            "csv": {
                "common": {
                    "generating": "CSV 파일 생성 중...",
                    "waiting": "기다리세요..."
                },
                "download": "CSV 내보내기"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "지역 전문가가 추천하는 즐겨찾기"
                },
                "links": {
                    "closest_pois": "가장 가까운 지점",
                    "excursions": "일정 및 산책",
                    "shares": "주식"
                },
                "no_dashboard": {
                    "contact": {
                        "link": "문의하기.",
                        "text": "이것이 실수라고 생각한다면 주저하지 말고"
                    },
                    "content": "귀하의 역할에 대해 대시보드가 ​​설정되지 않았거나 관리자로 연결되어 있습니다.",
                    "title": "사용 가능한 대시보드가 ​​없습니다."
                },
                "points_of_interest": {
                    "categories": "관심장소 카테고리"
                }
            },
            "documents": {
                "display": {
                    "description": "QRCode와 소속기관 로고를 연결하여 A6형식으로 표시합니다.",
                    "title": "디스플레이 - A6 카드"
                },
                "qrcode": {
                    "description": "QRCode만으로 여행자가 기관에 직접 연결될 수 있습니다.",
                    "filename": "QR 코드",
                    "title": "QR코드 연결"
                }
            },
            "easy_transac": {
                "account": {
                    "create": {
                        "button": "EasyTransac 계정 만들기",
                        "fail": "EasyTransac 연결에 실패했습니다. 나중에 다시 시도해 주세요.",
                        "success": "EasyTransac 계정이 성공적으로 생성되었습니다"
                    },
                    "show": {
                        "account_usage": "귀하의 EasyTransac 계정을 사용하면 귀하의 기관이 UgoZER에서 지불금을 받을 수 있습니다.",
                        "can_receive_payments": "이제 귀하의 고객이 시설에 팁을 줄 수 있습니다\n                컨시어지의 UgoZER 프로필에서.",
                        "can_retrieve_payments": {
                            "description": "요청된 정보로 EasyTransac 계정을 완성해야 합니다.\n                    귀하의 계좌에서 사용 가능한 자금을 사용합니다.",
                            "title": "귀하의 EasyTransac 계정이 불완전합니다"
                        },
                        "cannot_receive_payments": "대금을 받을 수 없습니다.",
                        "credentials_sent_mail": "EasyTransac 자격 증명이 이메일로 전송되었습니다.",
                        "fee_percentage": "귀하가 받는 각 결제에는 수수료가 적용됩니다.",
                        "go_to_easy_transac": "EasyTransac 대시보드에 액세스",
                        "must_subscribe": "EasyTransac 계정을 만들려면 구독해야 합니다.",
                        "no_account_yet": "아직 EasyTransac 계정이 없습니다.",
                        "should_complete_kyc": "EasyTransac에서 요청한 서류를 보내주세요.\n                지불을 받기 위해."
                    }
                },
                "easy_transac": "이지트랜잭"
            },
            "exceptions": {
                "default": "에러 발생됨",
                "error_texts": {
                    "403": {
                        "link": {
                            "title": "당신은 다음에 액세스하고 싶을 수도 있습니다",
                            "to_app_label": "여행자 섹션?",
                            "to_extranet_label": "전문 부문?"
                        },
                        "message": "이 페이지에 액세스할 수 없습니다.",
                        "title": "이런! 액세스 금지."
                    },
                    "credential_not_verified": {
                        "title": "귀하의 자격 증명이 확인되지 않았습니다",
                        "verify_mail": "내 이메일에 대한 확인 링크 받기",
                        "verify_phone": "내 전화번호에 대한 인증 링크 받기"
                    },
                    "opening_hours": {
                        "invalid": "지정된 값은 openingHours 인스턴스가 아닙니다."
                    },
                    "password_missing": {
                        "app": "비밀번호를 설정해주세요.",
                        "extranet": "애플리케이션에 액세스하려면 비밀번호를 설정하세요."
                    }
                },
                "spam_detected": "스팸이 감지되었습니다."
            },
            "excursions": {
                "destroy": {
                    "confirm": "이 여행 일정을 정말로 삭제하시겠습니까? {excursion_name}?",
                    "success": "여행 일정이 삭제되었습니다."
                },
                "direction": {
                    "bicycling": "자전거 타는 것",
                    "display_mode": "디스플레이 모드",
                    "driving": "운전",
                    "walking": "걷는"
                },
                "form": {
                    "all_points_of_interest": "모든 관심 장소",
                    "create_new_point_of_interest": "새로운 관심 장소 만들기",
                    "existing_excursions": "기존 여정",
                    "from_existing_excursions": "기존 여행 일정에서",
                    "order_hint": "관심 장소의 순서는 방문 순서와 일치합니다.",
                    "points_of_interest": "가볼만한 곳",
                    "select_from_points_of_interest": "관심 장소에서 선택",
                    "your_excursion": "여행 일정"
                },
                "index": {
                    "alert": {
                        "content": "경로만 볼 수 있습니다. 여행 일정을 생성하거나 공유하려면 다음 계정으로 로그인해야 합니다.\n                <strong>컨시어지/접수원 계정</strong>",
                        "title": "일정은 협의로만 진행"
                    },
                    "no_results": "아직 여행 일정이 없습니다.",
                    "no_results_here": "검색하신 지역에 여행일정이 없습니다.",
                    "read_only": "상담만 가능",
                    "search": "여행 일정 검색",
                    "try_more_filters": "검색 범위를 넓히거나 여행 일정을 만들 수 있습니다.",
                    "your_excursions": "귀하의 여행 일정"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "여행일정",
                            "pois": "가볼만한 곳"
                        }
                    }
                },
                "search": {
                    "no_results": "여행 일정을 찾을 수 없습니다."
                },
                "share": {
                    "contains_private": "이 여행 일정에는 개인적인 관심 장소가 포함되어 있습니다. 지분에 포함되지 않을 수도 있습니다.",
                    "heading": "이 여행 일정을 공유하세요",
                    "select_which_contacts": "여행 일정 '{excursion_title}'을 공유하고 싶은 연락처를 선택하세요.",
                    "select_which_users": "여행 일정 \"{excursion_title}\"을 공유할 사용자를 선택하거나 입력하세요.\n            새로운 여행자를 초대할 이메일 주소",
                    "select_which_users_alt": "공유하고 싶은 사람의 이메일 주소를 입력하세요.\n            \"{excursion_title}\" 일정입니다.",
                    "success": "여행 일정이 공유되었습니다!"
                },
                "store": {
                    "create": "여행 일정 만들기",
                    "heading": "여행 일정 만들기",
                    "success": "여행 일정이 생성되었습니다."
                },
                "update": {
                    "heading": "이 여행 일정 업데이트",
                    "success": "여행 일정이 업데이트되었습니다."
                }
            },
            "extranet": {
                "dashboard": {
                    "last_claims": {
                        "none": "아직 소유권 주장이 없습니다.",
                        "see_all": "모든 주장 보기",
                        "see_index": "청구 페이지로 이동"
                    },
                    "last_payments": {
                        "none": "아직 결제 내역이 없습니다.",
                        "see_all": "모든 결제 내역 보기",
                        "see_index": "결제 페이지로 이동"
                    },
                    "last_reservations": {
                        "none": "아직 예약이 없습니다."
                    },
                    "last_traveller_folders": {
                        "none": "아직 귀하의 보살핌을 받는 여행자가 없습니다.",
                        "see_all": "모든 여행자 보기",
                        "take_care": "여행자를 돌봐주세요"
                    },
                    "manager": {
                        "sections": {
                            "butlers_institutions": "내 기관의 컨시어지",
                            "butlers_interactions": "상호작용이 가능한 컨시어지"
                        }
                    },
                    "sections": {
                        "last_claims": "귀하의 마지막 주장",
                        "last_payments": "마지막 결제",
                        "last_reservations": "마지막 예약",
                        "last_travellers": "마지막 여행자",
                        "search_butler": "컨시어지 검색",
                        "stats": "통계"
                    },
                    "stats": {
                        "butlers": "컨시어지|컨시어지",
                        "employees": "직원|직원",
                        "excursions": "여행일정|여행일정",
                        "folders": "폴더|폴더",
                        "institutions": "연계기관|연계기관",
                        "processing_claims": "청구 처리 중|청구 처리 중",
                        "reservations": "예약|예약"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "직원 초대",
                        "new-hotelier": "수신 계정 만들기",
                        "new-tourist-office": "데스크 계정 만들기"
                    }
                },
                "excursions": {
                    "actions": {
                        "new": "여행 일정 만들기"
                    },
                    "sections": {
                        "excursions": "여행일정"
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "카테고리"
                    }
                },
                "filters": {
                    "closed": "필터 닫기",
                    "open": "필터 열기"
                },
                "finances": {
                    "alerts": {
                        "easytransac": {
                            "cta": "EasyTransac 계정을 만들려면 내 프로필에 액세스하세요.",
                            "description": "아직 EasyTransac 계정을 만들지 않으셨습니다! 따라서 대금을 받을 수 없습니다.",
                            "title": "EasyTransac 계정이 존재하지 않습니다!"
                        }
                    },
                    "sections": {
                        "issued_payments": "지급 지급",
                        "received_by_institution_payments": "기관에서 받은 지급금",
                        "received_payments": "지불금 수령"
                    }
                },
                "institution": {
                    "messages": {
                        "no_records": "현재 연결된 기관이 없습니다."
                    },
                    "sections": {
                        "butler": {
                            "institution": "내 기관"
                        },
                        "manager": {
                            "institution": "내 기관"
                        }
                    }
                },
                "navbar": {
                    "contact": "문의하기",
                    "contacts": "콘택트 렌즈",
                    "dashboard": "계기반",
                    "excursions": "여행일정",
                    "faq": "자주하는 질문",
                    "finances": "재정",
                    "invitations": "초대장",
                    "my_employees": "내 직원",
                    "my_institution": "내 기관",
                    "my_institutions": "내 기관",
                    "points_of_interest": "가볼만한 곳",
                    "travellers": "여행자",
                    "tutorials": "시작하다",
                    "users": "사용자"
                },
                "pages": {
                    "excursions": {
                        "edit": "여행 일정 수정",
                        "new": "새로운 여행 일정"
                    },
                    "folders": {
                        "edit": "여행자 폴더 수정",
                        "new": "새로운 여행자 폴더"
                    },
                    "institutions": {
                        "new": "기관 연결"
                    },
                    "pois": {
                        "edit": "관심 장소 수정",
                        "new": "새로운 관심 장소"
                    },
                    "profile": {
                        "edit": "프로필 수정"
                    },
                    "reservations": {
                        "edit": "예약 수정",
                        "new": "신규 예약"
                    }
                },
                "points_of_interest": {
                    "actions": {
                        "new": "관심 장소 만들기"
                    },
                    "sections": {
                        "points_of_interest": "가볼만한 곳"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "현재 FAQ가 없습니다"
                    }
                },
                "professional_section": "전문 부문",
                "profile": {
                    "sections": {
                        "currency": "통화",
                        "delete_account": "계정 삭제",
                        "infos": "일반 정보",
                        "locale": "언어",
                        "notification_settings": "알림 설정",
                        "payment": "지불",
                        "privacy_settings": "개인 정보 설정",
                        "shares": "주식"
                    }
                },
                "support_messages": {
                    "actions": {
                        "back": "지원 요청으로 돌아가기",
                        "new": "새 메시지 추가"
                    },
                    "sections": {
                        "support_messages": "메시지"
                    }
                },
                "support_requests": {
                    "actions": {
                        "back": "뒤쪽에",
                        "new": "지원 요청 만들기"
                    },
                    "sections": {
                        "support_requests": "지원 요청"
                    }
                },
                "travellers": {
                    "buttons": {
                        "new_folder": "새 여행자 폴더 추가"
                    },
                    "sections": {
                        "folders": "내 여행자 폴더"
                    }
                },
                "user_dropdown": {
                    "changelogs": "변경 로그",
                    "cookies": "쿠키 관리",
                    "credits": "크레딧",
                    "customers": "고객",
                    "partners": "파트너",
                    "privacy_policy": "개인 정보 정책",
                    "profile": "내 프로필",
                    "sign_out": "로그아웃",
                    "subscription": "신청",
                    "terms_condition": "이용 약관"
                }
            },
            "form": {
                "file": {
                    "current_file": "실제 파일: {name}"
                },
                "hints": {
                    "auth": {
                        "register": {
                            "email": "이 이메일 주소는 이미 UgoZER 계정에 연결되어 있지 않아야 합니다.",
                            "employee": {
                                "not_duplicate_email": "이메일 주소는 반드시 본인의 이메일 주소와 달라야 합니다.",
                                "not_duplicate_password": "비밀번호는 기존 비밀번호와 달라야 합니다."
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "맞춤형: 컨시어지가 만든 여행 일정\n                        특정 여행자를 위한",
                                "root": "내 템플릿: 꼭 봐야 할 명소로 구성된 '공유 준비 완료' 여행 일정,\n                        또는 테마별 관심 장소\n                        (예: 여행 일정에 자주 포함되는 레스토랑 목록)\n                        새로운 여행 일정을 생성하기 위해 선택됩니다.",
                                "ugozer_app": "UgoZER 앱: UgoZER가 생성하고 제안한 여행 일정\n                        여행자에게 제공됩니다.",
                                "ugozer_pro": "UgoZER Pro: UgoZER가 생성하고 제안한 여행 일정\n                        전문가들이 작업을 용이하게 할 수 있도록 제공됩니다."
                            },
                            "pro": {
                                "customized": "특정 여행자를 위해 생성된 여행 일정",
                                "root": "꼭 봐야 할 명소로 구성된 \"공유 준비 완료\" 여행 일정,\n                        또는 테마별 관심 장소\n                        (예: 여행 일정에 자주 포함되는 레스토랑 목록)\n                        새로운 여행 일정을 생성하기 위해 선택됩니다.",
                                "ugozer": "UgoZER가 만들고 제안한 여행 일정입니다."
                            }
                        },
                        "points_of_interest_search": "관심 지점을 검색하고 선택하여 목록에 추가하세요."
                    },
                    "excursions_filters": {
                        "radius": "이 반경 내에 관심 지점이 하나 이상 위치한 여행 일정만 표시됩니다."
                    },
                    "geoloc_setting": {
                        "filter_header": "국가, 지역, 도시 또는 인근 지역을 검색하세요.",
                        "kind": {
                            "admin": {
                                "around_me": "내 주변: 지리적 위치가 결정됩니다.\n                        사용자의 브라우저에 의해.",
                                "customized": "맞춤설정: 사용자가 수동으로 장소를 검색할 수 있습니다.",
                                "staying_hotel": "호텔 숙박: 지리적 위치 확인이 완료됩니다.\n                        여행자가 묵고 있는 호텔에서"
                            },
                            "app": {
                                "around_me": "지리적 위치는 귀하의 위치에서 수행됩니다.",
                                "customized": "지리적 위치는 선택한 위치에서 수행됩니다.",
                                "staying_hotel": "지리적 위치는 귀하가 머무르는 호텔에서 수행됩니다: {title}."
                            }
                        }
                    },
                    "locale": {
                        "dynamic_translations_enabled": "사용자가 리소스를 표시하거나 번역할 수 있습니다.\n                (관심 장소, 여행 일정...)을 해당 언어로",
                        "fallback_locale": "자동으로 제2언어로 제목을 표시합니다(설명 앞에).\n                POI 파일.",
                        "static_translations_enabled": "사용자가 애플리케이션을 표시하기 위해 이 언어를 선택할 수 있습니다.\n                (소스 코드에 번역이 추가되어야 함)"
                    },
                    "permission": {
                        "name": "소스코드에 사용된 이름"
                    },
                    "plan": {
                        "countries": "모든 국가에서 요금제를 사용할 수 있도록 하려면 비워 두세요.",
                        "information": "계획에 대한 추가 정보(전제 조건, 조건...)",
                        "name": "이 이름은 잠재적인 구독자에게 표시됩니다.\n                구독을 선택할 때",
                        "private_title": "이 제목은 관리에서만 볼 수 있습니다."
                    },
                    "plan_price": {
                        "description": "이 설명은 잠재적인 구독자에게 표시됩니다.\n                구독을 선택할 때",
                        "price": "유로로 입력해주세요",
                        "private_title": "이 제목은 관리에서만 볼 수 있습니다."
                    },
                    "point_of_interest": {
                        "audio_refreshing_1": "오디오 파일 새로 고침을 활성화하면 오디오 파일이 자동으로 생성됩니다.\n                관심 지점의 언어로(:언어)",
                        "audio_refreshing_2": "관심 지점의 제목과 주소가 표시됩니다.",
                        "audio_refreshing_3": "언어, 제목이 바뀌면 바로 새로고침됩니다\n                또는 관심지점의 주소가 업데이트됩니다.",
                        "audio_refreshing_4": "오디오 파일을 직접 추가하거나 녹음하면 새로 고침이 비활성화됩니다.\n                또는 생성된 오디오 파일을 삭제하는 경우.",
                        "cant_have_employees": "여기에서는 다음과 같은 카테고리와 관련된 시설만 볼 수 있습니다.\n                직원",
                        "expiry_date": "귀하의 관심 지점은 {date}에 만료됩니다",
                        "expiry_date_details_1": "귀하의 관심 장소는 \"{category}\" 카테고리에 속합니다.",
                        "expiry_date_details_2": "따라서 이 날짜가 귀하가 입력한 마지막 날짜이므로 {date}에 만료됩니다.",
                        "expiry_date_details_3": "이 날짜로부터 귀하의 관심 지점은 21일 남았습니다.\n                자동으로 삭제되기 전에..",
                        "expiry_date_details_4": "언제든지 새 날짜를 추가할 수 있습니다.\n                만료를 연기하거나 관심 지점이 만료되지 않도록 날짜를 지정하지 마세요.",
                        "images": "이미지는 선택한 순서대로 관심 지점의 갤러리에 표시됩니다.\n                드래그하여 재구성할 수 있습니다.",
                        "is_geocoding": "지리적 위치를 올바르게 입력했지만 여전히 지도가 표시되지 않으면 비활성화합니다.",
                        "line1": "찾고자 하는 장소의 주소나 이름을 입력하시면 됩니다. 다음 중에서 선택하세요.\n                주소를 자동으로 입력하는 목록",
                        "locale": "이 관심 지점에서 사용되는 주요 언어",
                        "opening_dates": "특정 날짜에 일정이 적용되는 경우 비워 두세요.",
                        "opening_days": "특정 날짜에 일정이 적용되는 경우 아무것도 선택하지 마세요.",
                        "opening_hours": "관심 지점이 언제든지 열려 있으면 아무것도 추가하지 마세요",
                        "sync_1": "자동 업데이트를 활성화하면 {import_source}에서 데이터를 검색할 수 있습니다.\n                수입",
                        "sync_2": "수동으로 입력한 내용을 덮어쓰게 됩니다."
                    },
                    "points_of_interest_filters": {
                        "order_distance": "이 옵션을 선택하려면 거리 필터를 선택하세요.",
                        "order_relevance": "이 옵션을 선택하려면 검색 키워드를 입력하세요.",
                        "radius": "이 반경 내에 있는 관심 지점만 볼 수 있습니다."
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "butler": "어떤 옵션을 선택하더라도 귀하의 성과 이름은\n                    기관의 관리자는 계속 볼 수 있습니다.",
                            "traveller": "어떤 옵션을 선택하더라도 귀하의 성과 이름은\n                  귀하가 머무르는 호텔의 컨시어지가 계속 볼 수 있습니다."
                        }
                    },
                    "profile": {
                        "contact_email": "이 이메일은 공개 프로필에 표시될 수 있습니다.",
                        "contact_phone": "이 전화는 공개 프로필에 표시될 수 있습니다.",
                        "name": "닉네임은 공개되며 이를 통해 애플리케이션의 다른 사용자가 귀하를 더 쉽게 식별할 수 있습니다.",
                        "password_template": {
                            "digit": "1자리",
                            "length": "{length}자",
                            "lowercase": "소문자 1개",
                            "uppercase": "대문자 1개"
                        },
                        "password_unchanged": "변경되지 않고 그대로 유지하려면 비워두세요."
                    },
                    "role": {
                        "description": "역할에 대한 설명은 사용자가 역할을 선택할 때 표시됩니다.\n                전문 섹션에 등록하십시오.",
                        "name": "역할의 코드 이름은 사용자에게 표시되지 않습니다."
                    },
                    "support_request": {
                        "support_categories_search": "카테고리 검색 및 선택"
                    },
                    "translatable": {
                        "pro_global": "이 양식의 하나 이상의 필드를 다른 언어로 채울 수 있습니다.",
                        "pro_global_2": "특정 언어로 작성되지 않은 필드는 자동으로 번역됩니다.",
                        "pro_global_title": "다국어 입력"
                    },
                    "traveller_folder": {
                        "expired_on": "기본적으로 종료일은 일주일 단위로 설정됩니다."
                    },
                    "user": {
                        "bank_account_number": "이체에 사용할 계좌번호를 입력하세요."
                    }
                },
                "labels": {
                    "geoloc_setting": {
                        "distance_filter": "거리 필터",
                        "limit_to_a_radius": "반경으로 제한",
                        "limit_to_a_radius_from_center": "중심으로부터 반경으로 제한",
                        "measure_unit": "측정 단위",
                        "other_filters": "다른 필터",
                        "where_does_the_traveller_go": "여행자는 어디로 가나요?",
                        "where_would_you_like_to_go": "어디 가고 싶어?",
                        "within_a_radius": "반경 내"
                    },
                    "payment": {
                        "payable_type": "결제 연결됨"
                    },
                    "plan": {
                        "authorized_roles": "승인된 역할",
                        "tipping_fees": "팁 수수료(%)"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "세금을 제외한 가격"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "오디오 파일 자동 새로 고침",
                        "opening": "열리는",
                        "opening_date": "~에",
                        "opening_date_from": "~에서",
                        "opening_date_range": "종료일을 지정하다",
                        "opening_date_to": "에게",
                        "opening_hours": "~에",
                        "opening_hours_from": "~에서",
                        "opening_hours_range": "종료 시간을 지정하세요",
                        "opening_hours_to": "에게",
                        "opening_schedule_add": "요일 또는 시간 지정",
                        "opening_schedule_add_alt": "시간을 지정하다",
                        "opening_specification_add": "마침표를 추가하다"
                    },
                    "points_of_interest_filters": {
                        "geoloc": "장소",
                        "order_distance": "거리별",
                        "order_relevance": "관련성별(검색 키워드)",
                        "sort": "종류",
                        "tags": "다듬다"
                    },
                    "register": {
                        "communication_consent": "제안을 포함한 맞춤형 커뮤니케이션을 받고 싶습니다.\n                이메일을 통해 UgoZER의 홍보 정보 및 여행 관련 상품을 확인하실 수 있습니다.",
                        "privacy_policy": {
                            "part1": "나는 동의 함",
                            "part2": "개인 정보 정책"
                        },
                        "terms_conditions": {
                            "part1": "나는 동의 함",
                            "part2": "이용 약관"
                        }
                    },
                    "support_category": {
                        "title": "카테고리를 선택하세요"
                    },
                    "support_message": {
                        "content": "메시지",
                        "email": "이메일 주소"
                    },
                    "support_request": {
                        "title": "요청 대상"
                    },
                    "traveller_invitation": {
                        "locale": "이 여행자가 사용하는 언어"
                    },
                    "user": {
                        "credential": "이메일",
                        "email": "이메일",
                        "image": {
                            "_destroy": "프로필 사진 삭제"
                        },
                        "new_password": "새 비밀번호",
                        "notes": "추가 정보"
                    },
                    "vat_rate": {
                        "vat_rate": "부가가치세율(%)"
                    }
                },
                "placeholders": {
                    "audio_file_name": "오디오 파일 이름",
                    "name": "별명",
                    "support_message": {
                        "create_message": "지원 요청에 대한 메시지"
                    }
                },
                "select": {
                    "no_options": "옵션 없음",
                    "no_results": "죄송합니다. 일치하는 옵션이 없습니다.",
                    "type_to_search": "찾다..."
                },
                "titles": {
                    "verifications": "정보 확인 중"
                }
            },
            "front": {
                "access_pro": "전문 액세스로 이동",
                "app": "여행자 앱",
                "brand_name": "우고ZER",
                "captcha": {
                    "and": "그리고",
                    "apply": "적용하다.",
                    "privacy_policy": "개인 정보 정책",
                    "protected": "이 사이트는 hCaptcha와 해당 사이트의 보호를 받습니다.",
                    "terms_service": "서비스 약관"
                },
                "contact": {
                    "cancel": "지원 요청 취소",
                    "contact": "연락하다",
                    "send": "보내다"
                },
                "cookies": {
                    "title": "쿠키 관리"
                },
                "customers": {
                    "see_customers": "고객 보기",
                    "title": "그들은 우리를 신뢰합니다"
                },
                "footer": {
                    "account_deletion": "계정 삭제",
                    "app": "여행자 앱",
                    "contact": "연락하다",
                    "cookies": "쿠키 관리",
                    "copyright": "© {year} UgoZER - 모든 권리 보유.",
                    "facebook": "페이스북",
                    "faq": "자주하는 질문",
                    "home": "집",
                    "instagram": "인스 타 그램",
                    "legal_notice": "법적 고지",
                    "linkedin": "링크드인",
                    "privacy_policy": "개인 정보 정책",
                    "pro": "전문적인 액세스",
                    "sitemap": "사이트맵",
                    "terms_conditions": "이용 약관",
                    "twitter": "트위터"
                },
                "header": {
                    "app": "여행자 앱",
                    "contact": "연락하다",
                    "faq": "자주하는 질문",
                    "home": "집",
                    "pro": "전문적인 액세스",
                    "you_are": "당신은"
                },
                "intro": {
                    "intro_1": "UgoZER를 사용하면 항상 다국어 파일로 즐겨찾는 주소를 스마트폰에 보관할 수 있습니다.\n            클릭 한 번으로 듣거나 읽을 수 있습니다.",
                    "intro_2": "해외에 있을 때는 택시 운전사와 다른 사람들이 목적지를 쉽게 알 수 있도록 하세요.",
                    "intro_3": "접수원, 호텔 매니저, 투어 프로그램을 설계하고 제공합니다. 고객과 공유하세요\n            UgoZER에서.",
                    "intro_title_pro": "전문가",
                    "intro_title_traveller": "여행자",
                    "title_homepage": "다국어 관광 가이드는 무료입니다."
                },
                "navigation": {
                    "next": "다음",
                    "previous": "이전의"
                },
                "outro": {
                    "description": "2.0으로 여행하기"
                },
                "partners": {
                    "no_records": "현재 파트너가 없습니다.",
                    "read_more": "더 읽어보세요",
                    "see_partners": "파트너 보기",
                    "title": "파트너"
                },
                "posts": {
                    "no_records": "현재 게시물이 없습니다",
                    "title": "자주하는 질문."
                },
                "pro": "전문적인 액세스",
                "professionals": {
                    "argument_1": {
                        "part_1": "UgoZER는 손님을 위한 디지털 솔루션을 제공합니다.",
                        "part_2": "숙박과 고객 경험을 향상시키세요.",
                        "part_3": "호텔, 리조트 또는 정보 센터를 통해 개인화되고 사용하기 쉬운 서비스를 제공할 수 있습니다.\n                다국어 모바일 여행 일정.",
                        "part_4": "UgoZER 전문 플랫폼을 통해 하나의 실시간 POI 데이터베이스에서 맞춤형 여행을 공유하세요.\n                그리고 그 모바일 앱."
                    },
                    "argument_2": {
                        "part_1": "고객을 위해 미리 패키지된 여행 일정을 선택할 수 있습니다.",
                        "part_2": "마을 최고의 장소나 레스토랑, 발견할 수 있는 숨겨진 보석으로 나만의 것을 만들어보세요.\n                즐길 수 있는 하이킹 코스...",
                        "part_3": "또는 전체 POI 목록 내에서 방문의 각 단계를 선택하여 결합할 수 있습니다.\n                개인화된 여행 일정을 공유할 것입니다."
                    },
                    "argument_3": {
                        "part_1": "각 POI에는 자세한 설명, 대화형 지도에서의 위치 및 위치가 포함되어 있습니다.\n                주소는 현지 언어로 자동 번역됩니다.",
                        "part_2": "당신의 손님은 결코 길을 잃지 않을 것입니다. 그는 항상 길을 찾고 쉽게 당신의 집으로 돌아올 것입니다\n                호텔."
                    },
                    "argument_4": {
                        "part_1": "여행이 끝나면 고객은 맞춤형 일정을 즐긴 후 다음을 수행할 수 있습니다.\n                독특한 추천을 해 준 컨시어지나 접수원에게 팁을 주어 감사를 표하세요.",
                        "part_2": "UgoZER를 사용하면 수익 증가와 직원 감소를 통해 유지율이 향상됩니다.\n                회전율.",
                        "part_3": "당신은 그들이 호텔의 일상생활에 참여할 수 있도록 더 큰 동기를 부여할 것입니다.\n                그리고 그들에게 소속감을 주세요."
                    },
                    "intro": {
                        "main_title": "다국어 디지털 및 개인 여행 도우미 UgoZER. 손님을 모셔라\n                다음 단계로 경험하고 수익을 늘리십시오."
                    },
                    "outro": {
                        "catchphrase": "UgoZER는 귀하의 만족도를 높이고 성장을 촉진하도록 도와드립니다."
                    }
                },
                "startup": {
                    "description_1": "UgoZER는 유럽의 수도인 스트라스부르에 본사를 둔 프랑스 회사입니다. 여행으로 만든\n            연인 여러분, 언어를 사용하지 않는 국가로의 여행을 용이하게 해줍니다.",
                    "description_2": "관광안내소에서 추천하는 곳입니다.",
                    "title": "스타트업 UgoZER"
                },
                "support_request": "지원 요청",
                "travellers": {
                    "argument_1": {
                        "arguments": {
                            "audio": "택시나 행인의 말을 들을 수 있도록 현지 언어로 된 <strong>오디오 주소</strong>",
                            "desc": "<strong>자세한 설명</strong>",
                            "languages": "모든 정보는 <strong>프랑스어, 영어, 독일어, 네덜란드어, 포르투갈어로 번역됩니다.\n                    스페인어, 이탈리아어, 한국어, 중국어, 일본어, 아랍어</strong>",
                            "map": "<strong>대화형 지도의 위치, Google Maps™ 출력 가능</strong>"
                        },
                        "part_1": "스마트폰에서 실시간으로 업데이트되는 모든 관심지점(POI)에 접속하세요.",
                        "part_2": "<strong> 카테고리별로 분류된 POI: 기념물, 박물관, 전시회, 상점, 레스토랑,\n                바, 임시 이벤트...</strong>",
                        "part_3": "다음과 함께 제공되는 POI:"
                    },
                    "argument_2": {
                        "part_1": "완전한 정보에 영구적으로 액세스하여 여행을 즐기십시오. 스마트폰의 모든 것,\n                주머니에 바로."
                    },
                    "argument_3": {
                        "part_1": "UgoZER를 사용하면 자동으로 무료 여행 일정을 얻을 수 있습니다. 여행을 만들고 공유할 수도 있습니다.\n                친구들과 함께. 현지에서 맞춤형 투어를 받아 여행을 최대한 활용하세요.\n                전문가 : 호텔 컨시어지 또는 접수원, 관광 안내소.",
                        "part_2": "여행이 마음에 들었다면 디지털화되고 보안이 유지되는 메시지를 통해 현지 전문가에게 감사 인사를 전할 수도 있습니다.\n                팁 옵션."
                    },
                    "intro": {
                        "main_title": "무료 다국어 관광 가이드"
                    },
                    "outro": {
                        "catchphrase": "UgoZER를 사용하면 안전하게 안내받고 안심하고 여행할 수 있습니다."
                    }
                },
                "you_are": {
                    "professional": "전문적인",
                    "traveller": "여행자"
                },
                "youtube": {
                    "directed_by": "감독",
                    "producer": "수프라헤드 스튜디오"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "기관을 선택하세요"
                    },
                    "sections": {
                        "pending_claims": "검증 보류 중",
                        "requests": "대기 중인 요청",
                        "validated_claims": "청구됨"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "요청이 전송되었습니다"
                },
                "approve": {
                    "confirm": {
                        "employee": "정말로 요청을 승인하시겠습니까?",
                        "employer": "정말로 {user_name}을 승인하시겠습니까?"
                    },
                    "success": "요청이 승인되었습니다."
                },
                "cancel": {
                    "confirm": {
                        "employee": "정말로 이 요청을 취소하시겠습니까?",
                        "employer": "정말로 {user_name} 요청을 취소하시겠습니까?"
                    },
                    "success": "요청이 취소되었습니다."
                },
                "decline": {
                    "confirm": {
                        "change_institution": "연결된 기관이 변경됩니다!",
                        "employee": "정말로 요청을 거절하시겠습니까?",
                        "employer": "정말로 거절하시겠습니까? {user_name}?"
                    },
                    "success": "요청이 거부되었습니다"
                },
                "feature": {
                    "confirm": "정말로 {user_name} 기능을 원하시나요?",
                    "folder": "직원 중 한 명과 교류한 적이 없는 여행자를 위해 추천된 내용은 다음과 같습니다.\n           여행자 폴더에 표시되는 사람입니다.",
                    "only_one": "직원은 한 명만 추천할 수 있습니다.",
                    "success": "직원이 등장했습니다"
                },
                "index": {
                    "my_employees": "내 직원"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "계정",
                        "invitation": "초대"
                    }
                },
                "invite": {
                    "invite": "직원 초대"
                },
                "quit": {
                    "confirm": "정말로 이 기관을 그만두고 싶나요 {poi_name}?",
                    "success": "당신은 기관을 그만두었습니다"
                },
                "remove": {
                    "confirm": "정말로 {user_name}을 제거하시겠습니까?",
                    "success": "직원이 제거되었습니다"
                },
                "show": {
                    "pending": "이 기관에 가입하려는 귀하의 요청이 현재 보류 중입니다."
                },
                "states": {
                    "approved": "승인됨",
                    "canceled": "취소 된",
                    "declined": "거절됨",
                    "invited": "초대됨",
                    "pending": "보류 중",
                    "removed": "제거됨"
                }
            },
            "interactions": {
                "description": {
                    "contact approved": " {sender_name}이 연락처 요청을 승인했습니다.",
                    "contact blocked": " {sender_name}에 의해 연락처 요청이 차단되었습니다.",
                    "contact created": " {sender_name}에 의해 연락처 요청이 생성되었습니다.",
                    "contact declined": " {sender_name}에 의해 연락처 요청이 거부되었습니다.",
                    "invitation accepted": " {sender_name}님이 초대를 수락했습니다.",
                    "invitation created": " {sender_name}님이 초대장을 보냈습니다.",
                    "payment succeeded": "{sender_name}이 {recipient_name}에게 지불금을 보냈습니다.",
                    "share created": "{sender_name}이 {share}를 공유했습니다.",
                    "traveller_folder created": "Traveler 폴더가 {sender_name}에 의해 생성되었습니다.",
                    "traveller_folder thanked": "{sender_name}이 숙박에 대해 여행자에게 감사를 표했습니다."
                },
                "share_type": {
                    "excursions": "여정",
                    "pointsofinterest": "관심 지점"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "초대하다"
                },
                "states": {
                    "authenticated_once": {
                        "false": "보류 중",
                        "true": "검증됨"
                    }
                },
                "success": {
                    "professional": {
                        "sent_1": "이 주소가 아직 사용되지 않은 경우 {email}로 이메일이 전송됩니다.",
                        "sent_2": "이 이메일에는 전문 섹션에 등록할 수 있는 링크가 포함되어 있습니다.",
                        "title": "초대장이 전송되었습니다"
                    },
                    "traveller": {
                        "email_1": "이 주소가 아직 사용되지 않은 경우 {email}로 이메일이 전송됩니다.",
                        "email_2": "이 이메일에는 애플리케이션에 연결할 수 있는 링크가 포함되어 있습니다.",
                        "title": "초대장이 전송되었습니다"
                    }
                },
                "texts": {
                    "already_have_account": "사용자가 이미 애플리케이션에 계정을 가지고 있습니까?",
                    "description": "이메일 주소나 전화번호를 이용해 다른 사람을 우리 애플리케이션에 초대할 수 있습니다.",
                    "employee_already_have_account": "직원이 이미 신청서에 계정을 가지고 있습니까?",
                    "geoloc_settings": {
                        "description": "지리적 위치 설정은 호텔 좌표로 자동 설정됩니다.\n                (귀하가 시설에 연결된 경우).",
                        "title": "지리적 위치 설정"
                    },
                    "invite_link": {
                        "instructions": "여행자를 초대하려면 이 링크를 복사하세요.",
                        "title": "링크로 초대"
                    },
                    "invite_professional": "전문가를 초대하세요",
                    "invite_traveller": "여행자 초대",
                    "new_traveller": "새로운 여행자",
                    "professional_description": "전문가를 초대하여 당사의 전문 섹션에 참여할 수 있습니다.\n            이메일로 신청",
                    "title": "누군가를 초대하세요"
                }
            },
            "locales": {
                "aa": "멀리",
                "ab": "압하지야어",
                "af": "아프리카 어",
                "af_NA": "아프리카어(나미비아)",
                "af_ZA": "아프리카어(남아프리카)",
                "ak": "아칸",
                "ak_GH": "아칸어(가나)",
                "am": "암하라어",
                "am_ET": "암하라어(에티오피아)",
                "an": "아라곤어",
                "ar": "아라비아 말",
                "ar_AE": "아랍어(아랍에미리트)",
                "ar_BH": "아랍어(바레인)",
                "ar_DJ": "아랍어(지부티)",
                "ar_DZ": "아랍어(알제리)",
                "ar_EG": "아랍어(이집트)",
                "ar_EH": "아랍어(서사하라)",
                "ar_ER": "아랍어(에리트레아)",
                "ar_IL": "아랍어(이스라엘)",
                "ar_IQ": "아랍어(이라크)",
                "ar_JO": "아랍어(요르단)",
                "ar_KM": "아랍어(코모로)",
                "ar_KW": "아랍어(쿠웨이트)",
                "ar_LB": "아랍어(레바논)",
                "ar_LY": "아랍어(리비아)",
                "ar_MA": "아랍어(모로코)",
                "ar_MR": "아랍어(모리타니아)",
                "ar_OM": "아랍어(오만)",
                "ar_PS": "아랍어(팔레스타인 영토)",
                "ar_QA": "아랍어(카타르)",
                "ar_SA": "아랍어(사우디아라비아)",
                "ar_SD": "아랍어(수단)",
                "ar_SO": "아랍어(소말리아)",
                "ar_SS": "아랍어(남수단)",
                "ar_SY": "아랍어(시리아)",
                "ar_TD": "아랍어(차드)",
                "ar_TN": "아랍어(튀니지)",
                "ar_YE": "아랍어(예멘)",
                "as": "아삼어",
                "as_IN": "아삼어(인도)",
                "av": "아바릭",
                "ay": "아이마라어",
                "az": "아제르바이잔",
                "az_AZ": "아제르바이잔어(아제르바이잔)",
                "az_Cyrl": "아제르바이잔어(키릴 문자)",
                "az_Cyrl_AZ": "아제르바이잔어(키릴 자모, 아제르바이잔)",
                "az_Latn": "아제르바이잔어(라틴어)",
                "az_Latn_AZ": "아제르바이잔어(라틴어, 아제르바이잔)",
                "ba": "바쉬르어",
                "be": "벨로루시어",
                "be_BY": "벨로루시어(벨로루시)",
                "bg": "불가리아 사람",
                "bg_BG": "불가리아어(불가리아)",
                "bh": "비하리어(언어)",
                "bi": "비슬라마",
                "bm": "밤바라",
                "bm_Latn": "밤바라어(라틴어)",
                "bm_Latn_ML": "밤바라어(라틴어, 말리)",
                "bn": "벵골 사람",
                "bn_BD": "벵골어(방글라데시)",
                "bn_IN": "벵골어(인도)",
                "bo": "티베트어",
                "bo_CN": "티베트어(중국)",
                "bo_IN": "티베트어(인도)",
                "br": "브르타뉴어",
                "br_FR": "브르타뉴어(프랑스)",
                "bs": "보스니아어",
                "bs_BA": "보스니아어(보스니아 & 헤르체고비나)",
                "bs_Cyrl": "보스니아어(키릴 문자)",
                "bs_Cyrl_BA": "보스니아어(키릴 자모, 보스니아 헤르체고비나)",
                "bs_Latn": "보스니아어(라틴어)",
                "bs_Latn_BA": "보스니아어(라틴 문자, 보스니아 헤르체고비나)",
                "ca": "카탈로니아 사람",
                "ca_AD": "카탈로니아어(안도라)",
                "ca_ES": "카탈로니아어(스페인)",
                "ca_FR": "카탈로니아어(프랑스)",
                "ca_IT": "카탈로니아어(이탈리아)",
                "ce": "체첸",
                "ch": "차모로어",
                "co": "코르시카어",
                "cr": "크리어",
                "cs": "체코 사람",
                "cs_CZ": "체코어(체코 공화국)",
                "cu": "고대 불가리아어",
                "cv": "추바시어",
                "cy": "웨일스 말",
                "cy_GB": "웨일스어(영국)",
                "da": "덴마크 말",
                "da_DK": "덴마크어(덴마크)",
                "da_GL": "덴마크어(그린란드)",
                "de": "독일 사람",
                "de_AT": "독일어(오스트리아)",
                "de_BE": "독일어(벨기에)",
                "de_CH": "독일어(스위스)",
                "de_DE": "독일어(독일)",
                "de_LI": "독일어(리히텐슈타인)",
                "de_LU": "독일어(룩셈부르크)",
                "dv": "몰디브어",
                "dz": "종카어",
                "dz_BT": "종카어(부탄)",
                "ee": "암양",
                "ee_GH": "에웨어(가나)",
                "ee_TG": "에웨(토고)",
                "el": "그리스 어",
                "el_CY": "그리스어(키프로스)",
                "el_GR": "그리스어(그리스)",
                "en": "영어",
                "en_AG": "영어(앤티가 바부다)",
                "en_AI": "영어(앵귈라)",
                "en_AS": "영어(아메리칸 사모아)",
                "en_AU": "영어(호주)",
                "en_BB": "영어(바베이도스)",
                "en_BE": "영어(벨기에)",
                "en_BM": "영어(버뮤다)",
                "en_BS": "영어(바하마)",
                "en_BW": "영어(보츠와나)",
                "en_BZ": "영어(벨리즈)",
                "en_CA": "영어(캐나다)",
                "en_CC": "영어(코코스(킬링) 제도)",
                "en_CK": "영어(쿡 제도)",
                "en_CM": "영어(카메룬)",
                "en_CX": "영어(크리스마스 섬)",
                "en_DG": "영어(디에고 가르시아)",
                "en_DM": "영어(도미니카)",
                "en_ER": "영어(에리트레아)",
                "en_FJ": "영어(피지)",
                "en_FK": "영어(포클랜드 제도)",
                "en_FM": "영어(미크로네시아)",
                "en_GB": "영어(영국)",
                "en_GD": "영어(그레나다)",
                "en_GG": "영어(건지)",
                "en_GH": "영어(가나)",
                "en_GI": "영어(지브롤터)",
                "en_GM": "영어(감비아)",
                "en_GU": "영어(괌)",
                "en_GY": "영어(가이아나)",
                "en_HK": "영어(중국 홍콩 특별행정구)",
                "en_IE": "영어(아일랜드)",
                "en_IM": "영어(맨 섬)",
                "en_IN": "영어(인도)",
                "en_IO": "영어(영국령 인도양 지역)",
                "en_JE": "영어(저지)",
                "en_JM": "영어(자메이카)",
                "en_KE": "영어(케냐)",
                "en_KI": "영어(키리바시)",
                "en_KN": "영어(세인트 키츠 네비스)",
                "en_KY": "영어(케이맨 제도)",
                "en_LC": "영어(세인트루시아)",
                "en_LR": "영어(라이베리아)",
                "en_LS": "영어(레소토)",
                "en_MG": "영어(마다가스카르)",
                "en_MH": "영어(마셜 제도)",
                "en_MO": "영어(중국 마카오 특별행정구)",
                "en_MP": "영어(북마리아나 제도)",
                "en_MS": "영어(몬세라트)",
                "en_MT": "영어(몰타)",
                "en_MU": "영어(모리셔스)",
                "en_MW": "영어(말라위)",
                "en_MY": "영어(말레이시아)",
                "en_NA": "영어(나미비아)",
                "en_NF": "영어(노퍽 섬)",
                "en_NG": "영어(나이지리아)",
                "en_NR": "영어(나우루)",
                "en_NU": "영어(니우에)",
                "en_NZ": "영어(뉴질랜드)",
                "en_PG": "영어(파푸아뉴기니)",
                "en_PH": "영어(필리핀)",
                "en_PK": "영어(파키스탄)",
                "en_PN": "영어(핏케언 제도)",
                "en_PR": "영어(푸에르토리코)",
                "en_PW": "영어(팔라우)",
                "en_RW": "영어(르완다)",
                "en_SB": "영어(솔로몬 제도)",
                "en_SC": "영어(세이셸)",
                "en_SD": "영어(수단)",
                "en_SG": "영어(싱가포르)",
                "en_SH": "영어(세인트 헬레나)",
                "en_SL": "영어(시에라리온)",
                "en_SS": "영어(남수단)",
                "en_SX": "영어(신트마르턴)",
                "en_SZ": "영어(스와질란드)",
                "en_TC": "영어(터크스 케이커스 제도)",
                "en_TK": "영어(토켈라우)",
                "en_TO": "영어(통가)",
                "en_TT": "영어(트리니다드 토바고)",
                "en_TV": "영어(투발루)",
                "en_TZ": "영어(탄자니아)",
                "en_UG": "영어(우간다)",
                "en_UM": "영어(미국 외곽 섬)",
                "en_US": "영어(미국)",
                "en_VC": "영어(세인트빈센트그레나딘)",
                "en_VG": "영어(영국령 버진아일랜드)",
                "en_VI": "영어(미국령 버진아일랜드)",
                "en_VU": "영어(바누아투)",
                "en_WS": "영어(사모아)",
                "en_ZA": "영어(남아프리카 공화국)",
                "en_ZM": "영어(잠비아)",
                "en_ZW": "영어(짐바브웨)",
                "eo": "에스페란토 말",
                "es": "스페인의",
                "es_AR": "스페인어(아르헨티나)",
                "es_BO": "스페인어(볼리비아)",
                "es_CL": "스페인어(칠레)",
                "es_CO": "스페인어(콜롬비아)",
                "es_CR": "스페인어(코스타리카)",
                "es_CU": "스페인어(쿠바)",
                "es_DO": "스페인어(도미니카 공화국)",
                "es_EA": "스페인어(세우타 및 멜리야)",
                "es_EC": "스페인어(에콰도르)",
                "es_ES": "스페인어(스페인)",
                "es_GQ": "스페인어(적도 기니)",
                "es_GT": "스페인어(과테말라)",
                "es_HN": "스페인어(온두라스)",
                "es_IC": "스페인어(카나리아 제도)",
                "es_MX": "스페인어(멕시코)",
                "es_NI": "스페인어(니카라과)",
                "es_PA": "스페인어(파나마)",
                "es_PE": "스페인어(페루)",
                "es_PH": "스페인어(필리핀)",
                "es_PR": "스페인어(푸에르토리코)",
                "es_PY": "스페인어(파라과이)",
                "es_SV": "스페인어(엘살바도르)",
                "es_US": "스페인어(미국)",
                "es_UY": "스페인어(우루과이)",
                "es_VE": "스페인어(베네수엘라)",
                "et": "에스토니아 사람",
                "et_EE": "에스토니아어(에스토니아)",
                "eu": "바스크 사람",
                "eu_ES": "바스크어(스페인)",
                "fa": "페르시아 인",
                "fa_AF": "페르시아어(아프가니스탄)",
                "fa_IR": "페르시아어(이란)",
                "ff": "풀라",
                "ff_CM": "풀라어(카메룬)",
                "ff_GN": "풀라어(기니)",
                "ff_MR": "풀라어(모리타니아)",
                "ff_SN": "풀라어(세네갈)",
                "fi": "핀란드어",
                "fi_FI": "핀란드어(핀란드)",
                "fj": "피지어",
                "fo": "페로어",
                "fo_FO": "페로어(페로 제도)",
                "fr": "프랑스 국민",
                "fr_BE": "프랑스어(벨기에)",
                "fr_BF": "프랑스어(부르키나파소)",
                "fr_BI": "프랑스어(부룬디)",
                "fr_BJ": "프랑스어(베냉)",
                "fr_BL": "프랑스어(생 바르텔레미)",
                "fr_CA": "프랑스어(캐나다)",
                "fr_CD": "프랑스어(콩고-킨샤사)",
                "fr_CF": "프랑스어(중앙아프리카공화국)",
                "fr_CG": "프랑스어(콩고 - 브라자빌)",
                "fr_CH": "프랑스어(스위스)",
                "fr_CI": "프랑스어(코트디부아르)",
                "fr_CM": "프랑스어(카메룬)",
                "fr_DJ": "프랑스어(지부티)",
                "fr_DZ": "프랑스어(알제리)",
                "fr_FR": "프랑스어(프랑스)",
                "fr_GA": "프랑스어(가봉)",
                "fr_GF": "프랑스어(프랑스령 기아나)",
                "fr_GN": "프랑스어(기니)",
                "fr_GP": "프랑스어(과들루프)",
                "fr_GQ": "프랑스어(적도 기니)",
                "fr_HT": "프랑스어(아이티)",
                "fr_KM": "프랑스어(코모로)",
                "fr_LU": "프랑스어(룩셈부르크)",
                "fr_MA": "프랑스어(모로코)",
                "fr_MC": "프랑스어(모나코)",
                "fr_MF": "프랑스어(세인트 마틴)",
                "fr_MG": "프랑스어(마다가스카르)",
                "fr_ML": "프랑스어(말리)",
                "fr_MQ": "프랑스어(마르티니크)",
                "fr_MR": "프랑스어(모리타니아)",
                "fr_MU": "프랑스어(모리셔스)",
                "fr_NC": "프랑스어(뉴칼레도니아)",
                "fr_NE": "프랑스어(니제르)",
                "fr_PF": "프랑스어(프랑스령 폴리네시아)",
                "fr_PM": "프랑스어(생 피에르 미클롱)",
                "fr_RE": "프랑스어(레위니옹)",
                "fr_RW": "프랑스어(르완다)",
                "fr_SC": "프랑스어(세이셸)",
                "fr_SN": "프랑스어(세네갈)",
                "fr_SY": "프랑스어(시리아)",
                "fr_TD": "프랑스어(차드)",
                "fr_TG": "프랑스어(토고)",
                "fr_TN": "프랑스어(튀니지)",
                "fr_VU": "프랑스어(바누아투)",
                "fr_WF": "프랑스어(월리스푸투나어)",
                "fr_YT": "프랑스어(마요트)",
                "fy": "서부 프리지아어",
                "fy_NL": "서부 프리지아어(네덜란드)",
                "ga": "아일랜드의",
                "ga_IE": "아일랜드어(아일랜드)",
                "gd": "스코틀랜드 게일어",
                "gd_GB": "스코틀랜드 게일어(영국)",
                "gl": "갈리시아어",
                "gl_ES": "갈리시아어(스페인)",
                "gn": "과라니어",
                "gu": "구자라트어",
                "gu_IN": "구자라트어(인도)",
                "gv": "맨 섬 사람",
                "gv_IM": "맨스(맨 섬)",
                "ha": "하우사어",
                "ha_GH": "하우사어(가나)",
                "ha_Latn": "하우사어(라틴어)",
                "ha_Latn_GH": "하우사어(라틴어, 가나)",
                "ha_Latn_NE": "하우사어(라틴어, 니제르)",
                "ha_Latn_NG": "하우사어(라틴어, 나이지리아)",
                "ha_NE": "하우사어(니제르)",
                "ha_NG": "하우사어(나이지리아)",
                "he": "헤브라이 사람",
                "he_IL": "히브리어(이스라엘)",
                "hi": "힌디 어",
                "hi_IN": "힌디어(인도)",
                "ho": "히리 모투",
                "hr": "크로아티아어",
                "hr_BA": "크로아티아어(보스니아 & 헤르체고비나)",
                "hr_HR": "크로아티아어(크로아티아)",
                "ht": "아이티 섬 사람",
                "hu": "헝가리 인",
                "hu_HU": "헝가리어(헝가리)",
                "hy": "아르메니아 사람",
                "hy_AM": "아르메니아어(아르메니아)",
                "hz": "헤레로",
                "ia": "인테르링구아",
                "id": "인도네시아 인",
                "id_ID": "인도네시아어(인도네시아)",
                "ie": "중간 언어",
                "ig": "이보어",
                "ig_NG": "이그보어(나이지리아)",
                "ii": "쓰촨 이",
                "ii_CN": "쓰촨 이(중국)",
                "ik": "이누피아크어",
                "io": "그래요",
                "is": "아이슬란드어",
                "is_IS": "아이슬란드어(아이슬란드)",
                "it": "이탈리아 사람",
                "it_CH": "이탈리아어(스위스)",
                "it_IT": "이탈리아어(이탈리아)",
                "it_SM": "이탈리아어(산마리노)",
                "iu": "이누이트어",
                "ja": "일본어",
                "ja_JP": "일본어(일본)",
                "jv": "자바어",
                "ka": "그루지야 사람",
                "ka_GE": "조지아어(조지아)",
                "kg": "콩고",
                "ki": "키쿠유어",
                "ki_KE": "키쿠유어(케냐)",
                "kj": "관야마",
                "kk": "카자흐어",
                "kk_Cyrl": "카자흐어(키릴 문자)",
                "kk_Cyrl_KZ": "카자흐어(키릴 자모, 카자흐스탄)",
                "kk_KZ": "카자흐어(카자흐스탄)",
                "kl": "칼라알리수트",
                "kl_GL": "칼라알리수트(그린란드)",
                "km": "크메르어",
                "km_KH": "크메르어(캄보디아)",
                "kn": "칸나다어",
                "kn_IN": "칸나다어(인도)",
                "ko": "한국인",
                "ko_KP": "한국어(북한)",
                "ko_KR": "한국어(한국)",
                "kr": "카누리",
                "ks": "카시미르 말",
                "ks_Arab": "카슈미르어(아랍어)",
                "ks_Arab_IN": "카슈미르어(아랍어, 인도)",
                "ks_IN": "카슈미르어(인도)",
                "ku": "쿠르드어",
                "kv": "코미",
                "kw": "콘월 말",
                "kw_GB": "코니쉬어(영국)",
                "ky": "키르기스어",
                "ky_Cyrl": "키르기스어(키릴 자모)",
                "ky_Cyrl_KG": "키르기스어(키릴 자모, 키르기스스탄)",
                "ky_KG": "키르기스어(키르기스스탄)",
                "la": "라틴어",
                "lb": "룩셈부르크어",
                "lb_LU": "룩셈부르크어(룩셈부르크)",
                "lg": "간다",
                "lg_UG": "간다어(우간다)",
                "li": "림부르간",
                "ln": "링갈라",
                "ln_AO": "링갈라어(앙골라)",
                "ln_CD": "링갈라어(콩고-킨샤사)",
                "ln_CF": "링갈라어(중앙아프리카공화국)",
                "ln_CG": "링갈라(콩고 - 브라자빌)",
                "lo": "라오스",
                "lo_LA": "라오어(라오스)",
                "lt": "리투아니아 사람",
                "lt_LT": "리투아니아어(리투아니아)",
                "lu": "루바-카탕가",
                "lu_CD": "루바카탕가(콩고-킨샤사)",
                "lv": "라트비아 사람",
                "lv_LV": "라트비아어(라트비아)",
                "mg": "마다가스카르 사람",
                "mg_MG": "마다가스카르어(마다가스카르)",
                "mh": "마샬레스",
                "mi": "마오리족",
                "mk": "마케도니아 어",
                "mk_MK": "마케도니아어(마케도니아)",
                "ml": "말라얄람어",
                "ml_IN": "말라얄람어(인도)",
                "mn": "몽고 어",
                "mn_Cyrl": "몽골어(키릴 문자)",
                "mn_Cyrl_MN": "몽골어(키릴 문자, 몽골어)",
                "mn_MN": "몽골어(몽골)",
                "mo": "마카오",
                "mr": "마라티어",
                "mr_IN": "마라티어(인도)",
                "ms": "말레이 사람",
                "ms_BN": "말레이어(브루나이)",
                "ms_Latn": "말레이어(라틴어)",
                "ms_Latn_BN": "말레이어(라틴어, 브루나이)",
                "ms_Latn_MY": "말레이어(라틴어, 말레이시아)",
                "ms_Latn_SG": "말레이어(라틴어, 싱가포르)",
                "ms_MY": "말레이어(말레이시아)",
                "ms_SG": "말레이어(싱가포르)",
                "mt": "몰티즈",
                "mt_MT": "몰타어(몰타)",
                "my": "버마 사람",
                "my_MM": "버마어(미얀마(버마))",
                "na": "나우루",
                "nb": "노르웨이어 복말어",
                "nb_NO": "노르웨이어 보크몰어(노르웨이)",
                "nb_SJ": "노르웨이어 보크몰(스발바르 및 얀 마옌)",
                "nd": "노스 은데벨레",
                "nd_ZW": "북은데벨레어(짐바브웨)",
                "ne": "네팔어",
                "ne_IN": "네팔어(인도)",
                "ne_NP": "네팔어(네팔)",
                "ng": "은동가",
                "nl": "네덜란드 사람",
                "nl_AW": "네덜란드어(아루바)",
                "nl_BE": "네덜란드어(벨기에)",
                "nl_BQ": "네덜란드어(카리브해 네덜란드)",
                "nl_CW": "네덜란드어(퀴라소)",
                "nl_NL": "네덜란드어(네덜란드)",
                "nl_SR": "네덜란드어(수리남)",
                "nl_SX": "네덜란드어(신트마르턴)",
                "nn": "노르웨이 뉘노르스크",
                "nn_NO": "노르웨이 뉘노르스크(노르웨이)",
                "no": "노르웨이 인",
                "no_NO": "노르웨이어(노르웨이)",
                "nr": "사우스 은데벨레",
                "nv": "나바호어",
                "ny": "치체와어",
                "oc": "옥시탄",
                "oj": "오지브와",
                "om": "오로모어",
                "om_ET": "오로모어(에티오피아)",
                "om_KE": "오로모어(케냐)",
                "or": "오리야어",
                "or_IN": "오리야어(인도)",
                "os": "뼈의",
                "os_GE": "오세트어(조지아)",
                "os_RU": "오세트어(러시아)",
                "pa": "펀자브어",
                "pa_Arab": "펀자브어(아랍어)",
                "pa_Arab_PK": "펀자브어(아랍어, 파키스탄)",
                "pa_Guru": "펀자브어(구르무키어)",
                "pa_Guru_IN": "펀자브어(인도 구르무키)",
                "pa_IN": "펀자브어(인도)",
                "pa_PK": "펀자브어(파키스탄)",
                "pi": "팔리 어",
                "pl": "광택",
                "pl_PL": "폴란드어(폴란드)",
                "ps": "파슈토어",
                "ps_AF": "파슈토어(아프가니스탄)",
                "pt": "포르투갈 인",
                "pt_AO": "포르투갈어(앙골라)",
                "pt_BR": "포르투갈어(브라질)",
                "pt_CV": "포르투갈어(카보베르데)",
                "pt_GW": "포르투갈어(기니비사우)",
                "pt_MO": "포르투갈어(중국 마카오 특별행정구)",
                "pt_MZ": "포르투갈어(모잠비크)",
                "pt_PT": "포르투갈어(포르투갈)",
                "pt_ST": "포르투갈어(상투메 프린시페)",
                "pt_TL": "포르투갈어(동티모르)",
                "qu": "케추아어",
                "qu_BO": "케추아어(볼리비아)",
                "qu_EC": "케추아어(에콰도르)",
                "qu_PE": "케추아어(페루)",
                "rm": "로만슈어",
                "rm_CH": "로만쉬어(스위스)",
                "rn": "룬디어",
                "rn_BI": "룬디어(부룬디)",
                "ro": "루마니아 사람",
                "ro_MD": "루마니아어(몰도바)",
                "ro_RO": "루마니아어(루마니아)",
                "ru": "러시아인",
                "ru_BY": "러시아어(벨로루시)",
                "ru_KG": "러시아어(키르기스스탄)",
                "ru_KZ": "러시아어(카자흐스탄)",
                "ru_MD": "러시아어(몰도바)",
                "ru_RU": "러시아어(러시아)",
                "ru_UA": "러시아어(우크라이나)",
                "rw": "키냐르완다어",
                "rw_RW": "키냐르완다어(르완다)",
                "sa": "산스크리트",
                "sc": "사르디니아 사람",
                "sd": "신디어",
                "se": "북부 사미어",
                "se_FI": "북부 사미어(핀란드)",
                "se_NO": "북부 사미어(노르웨이)",
                "se_SE": "북부 사미어(스웨덴)",
                "sg": "산고",
                "sg_CF": "산고어(중앙아프리카 공화국)",
                "sh": "세르보-크로아티아어",
                "sh_BA": "세르보크로아티아어(보스니아 헤르체고비나)",
                "si": "신할라어",
                "si_LK": "싱할라어(스리랑카)",
                "sk": "슬로바키아 사람",
                "sk_SK": "슬로바키아어(슬로바키아)",
                "sl": "슬로베니아",
                "sl_SI": "슬로베니아어(슬로베니아)",
                "sm": "사모아어",
                "sn": "쇼나",
                "sn_ZW": "쇼나어(짐바브웨)",
                "so": "소말리아어",
                "so_DJ": "소말리아어(지부티)",
                "so_ET": "소말리아어(에티오피아)",
                "so_KE": "소말리아어(케냐)",
                "so_SO": "소말리아어(소말리아)",
                "sq": "알바니아",
                "sq_AL": "알바니아어(알바니아)",
                "sq_MK": "알바니아어(마케도니아)",
                "sq_XK": "알바니아어(코소보)",
                "sr": "세르비아 사람",
                "sr_BA": "세르비아어(보스니아 & 헤르체고비나)",
                "sr_Cyrl": "세르비아어(키릴 문자)",
                "sr_Cyrl_BA": "세르비아어(키릴 자모, 보스니아 헤르체고비나)",
                "sr_Cyrl_ME": "세르비아어(키릴 자모, 몬테네그로)",
                "sr_Cyrl_RS": "세르비아어(키릴 자모, 세르비아)",
                "sr_Cyrl_XK": "세르비아어(키릴 자모, 코소보)",
                "sr_Latn": "세르비아어(라틴어)",
                "sr_Latn_BA": "세르비아어(라틴 문자, 보스니아 헤르체고비나)",
                "sr_Latn_ME": "세르비아어(라틴어, 몬테네그로)",
                "sr_Latn_RS": "세르비아어(라틴어, 세르비아)",
                "sr_Latn_XK": "세르비아어(라틴어, 코소보)",
                "sr_ME": "세르비아어(몬테네그로)",
                "sr_RS": "세르비아어(세르비아)",
                "sr_XK": "세르비아어(코소보)",
                "ss": "스와티어",
                "st": "소토, 남부",
                "su": "순다어",
                "sv": "스웨덴어",
                "sv_AX": "스웨덴어(올란드 제도)",
                "sv_FI": "스웨덴어(핀란드)",
                "sv_SE": "스웨덴어(스웨덴)",
                "sw": "스와힐리어",
                "sw_KE": "스와힐리어(케냐)",
                "sw_TZ": "스와힐리어(탄자니아)",
                "sw_UG": "스와힐리어(우간다)",
                "ta": "타밀 사람",
                "ta_IN": "타밀어(인도)",
                "ta_LK": "타밀어(스리랑카)",
                "ta_MY": "타밀어(말레이시아)",
                "ta_SG": "타밀어(싱가포르)",
                "te": "텔루구어",
                "te_IN": "텔루구어(인도)",
                "tg": "타지크어",
                "th": "태국어",
                "th_TH": "태국어(태국)",
                "ti": "티그리냐",
                "ti_ER": "티그리냐어(에리트레아)",
                "ti_ET": "티그리냐어(에티오피아)",
                "tk": "투르크멘 말",
                "tl": "타갈로그어",
                "tl_PH": "타갈로그어(필리핀)",
                "tn": "츠와나어",
                "to": "통가어",
                "to_TO": "통가어(통가)",
                "tr": "터키어",
                "tr_CY": "터키어(키프로스)",
                "tr_TR": "터키어(터키)",
                "ts": "총가어",
                "tt": "타타르어",
                "tw": "트위어",
                "ty": "타히티어",
                "ug": "위구르어",
                "ug_Arab": "위구르어(아랍어)",
                "ug_Arab_CN": "위구르어(아랍어, 중국)",
                "ug_CN": "위구르어(중국)",
                "uk": "우크라이나 인",
                "uk_UA": "우크라이나어(우크라이나)",
                "ur": "우르두어",
                "ur_IN": "우르두어(인도)",
                "ur_PK": "우르두어(파키스탄)",
                "uz": "우즈벡어",
                "uz_AF": "우즈벡어(아프가니스탄)",
                "uz_Arab": "우즈베크어(아랍어)",
                "uz_Arab_AF": "우즈벡어(아랍어, 아프가니스탄)",
                "uz_Cyrl": "우즈벡어(키릴 문자)",
                "uz_Cyrl_UZ": "우즈베크어(키릴 자모, 우즈베키스탄)",
                "uz_Latn": "우즈벡어(라틴어)",
                "uz_Latn_UZ": "우즈베크어(라틴어, 우즈베키스탄)",
                "uz_UZ": "우즈베크어(우즈베키스탄)",
                "ve": "벤다",
                "vi": "베트남어",
                "vi_VN": "베트남어(베트남)",
                "vo": "볼라퓌크",
                "wa": "왈론 사람",
                "wo": "월로프",
                "xh": "코사어",
                "yi": "이디시어",
                "yo": "요루바어",
                "yo_BJ": "요루바어(베냉)",
                "yo_NG": "요루바어(나이지리아)",
                "za": "좡어",
                "zh": "중국인",
                "zh_CN": "중국어(중국)",
                "zh_HK": "중국어(중국 홍콩 특별행정구)",
                "zh_Hans": "중국어 (간체)",
                "zh_Hans_CN": "중국어(간체, 중국)",
                "zh_Hans_HK": "중국어(간체, 중국 홍콩 특별행정구)",
                "zh_Hans_MO": "중국어(간체, 중국 마카오 특별행정구)",
                "zh_Hans_SG": "중국어(간체, 싱가포르)",
                "zh_Hant": "중국 전통)",
                "zh_Hant_HK": "중국어(번체, 중국 홍콩특별행정구)",
                "zh_Hant_MO": "중국어(번체, 중국 마카오 특별행정구)",
                "zh_Hant_TW": "중국어(번체, 대만)",
                "zh_MO": "중국어(중국 마카오 특별행정구)",
                "zh_SG": "중국어(싱가포르)",
                "zh_TW": "중국어(대만)",
                "zu": "줄루 족",
                "zu_ZA": "줄루어(남아프리카공화국)"
            },
            "mail": {
                "action": "\"{actionText}\" 버튼을 클릭하는 데 문제가 있는 경우 아래 URL을 복사하여 웹 브라우저에 붙여넣으세요.",
                "auth": {
                    "deleted": {
                        "line1": "귀하의 UgoZER 계정이 삭제되었음을 알려드립니다.",
                        "reason": {
                            "intro": "이 내용은 다음과 같은 이유로 삭제되었습니다.",
                            "never_authenticated_for_too_long": "귀하의 계정이 생성된 지 일주일이 넘었습니다.\n                    그리고 당신은 로그인한 적이 없습니다:\n                    따라서 이 계정은 비활성 계정으로 간주됩니다.",
                            "not_logged_for_too_long": "2년 이상 로그인하지 않으셨습니다:\n                    귀하의 계정은 비활성 상태로 간주됩니다."
                        },
                        "subject": "UgoZER 계정 삭제"
                    },
                    "login_attempt_unverified_credential": {
                        "action": "이메일 주소 확인",
                        "line1": "이메일 주소 {credential}을 사용하여 UgoZER 계정에 로그인하려는 시도가 있었습니다.",
                        "line2": "이 로그인 시도를 시작한 경우,\n                이메일 주소를 확인하려면 아래 버튼을 클릭하세요.\n                그리고 연결할 수 있습니다.",
                        "line3": "이 링크는 1주일 후에 만료됩니다.",
                        "line4": "이 로그인 시도를 시작하지 않은 경우 추가 조치가 필요하지 않습니다.",
                        "subject": "UgoZER 계정에 로그인을 시도하세요"
                    },
                    "login_magic_link": {
                        "action": "로그인",
                        "line1": "로그인하려면 이 링크를 클릭하세요.",
                        "line2": "우리의 응용 프로그램을 사용해 주셔서 감사합니다!",
                        "subject": "비밀번호 없는 로그인 링크"
                    },
                    "not_logged": {
                        "intro": "UgoZER 계정에 마지막으로 로그인한 지 거의 2년이 지났습니다.",
                        "outro": "삭제되지 않도록 계정에 로그인하기만 하면 됩니다.",
                        "remaining": {
                            "two_days": "계정이 삭제되기까지 2일 남았습니다.",
                            "two_months": "계정이 삭제되기까지 2개월 남았습니다.",
                            "two_weeks": "계정이 삭제되기까지 2주 남았습니다."
                        },
                        "subject": "귀하의 UgoZER 계정이 곧 삭제됩니다"
                    },
                    "registration": {
                        "action": "이메일 주소 확인",
                        "line1": "이메일 주소를 확인하고 로그인하려면 아래 버튼을 클릭하세요.",
                        "line2": "이 링크는 1주일 후에 만료됩니다.",
                        "line3": "계정을 만들지 않은 경우 추가 조치가 필요하지 않습니다.",
                        "subject": "이메일 주소를 확인"
                    },
                    "registration_attempt": {
                        "line1": "<strong>{credential}</strong> 이메일은 이미 애플리케이션의 <strong>{role_type}</strong> 계정에 사용되었습니다.",
                        "line2": "계정을 만들려면 다른 이메일로 다시 등록을 시작하세요.",
                        "subject": "UgoZER 자격증명으로 등록을 시도하세요"
                    },
                    "registration_attempt_unverified_credential": {
                        "line1": "<strong>{credential}</strong> 이메일은 이미 애플리케이션의 <strong>{role_type}</strong> 계정에 사용되었습니다.",
                        "line2": "계정을 만들려면 다른 이메일로 다시 등록을 시작하세요.",
                        "subject": "UgoZER 자격증명으로 등록을 시도하세요"
                    },
                    "reset_password": {
                        "action": "암호를 재설정",
                        "line1": "귀하의 계정에 대한 비밀번호 재설정 요청이 접수되었기 때문에 이 이메일을 보내드립니다.",
                        "line2": "이 비밀번호 재설정 링크는 1분 후에 만료됩니다.",
                        "line3": "비밀번호 재설정을 요청하지 않은 경우 추가 조치가 필요하지 않습니다.",
                        "subject": "비밀번호 알림 재설정"
                    }
                },
                "copyright": "© {year} UgoZER - 모든 권리 보유.",
                "credential_verification": {
                    "verify": {
                        "action": "이메일 주소 확인",
                        "line1": "이메일 주소를 확인하려면 아래 버튼을 클릭하세요.",
                        "line2": "이 링크는 1주일 후에 만료됩니다.",
                        "line3": "확인을 요청하지 않은 경우 추가 조치가 필요하지 않습니다.",
                        "subject": "이메일 확인"
                    }
                },
                "hello": "안녕하세요!",
                "invitation": {
                    "attempt": {
                        "action": "애플리케이션에 대한 액세스",
                        "line1": "당신은 우리의 응용 프로그램에 참여하도록 초대되었습니다",
                        "line1_by": "귀하는 {name}의 초대를 받아 우리 신청서에 참여했습니다.",
                        "line1_by_from": "귀하는 {poi_title} 기관의 {name}으로부터 우리 신청서에 참여하도록 초대받았습니다.",
                        "line2": "귀하의 신청서에는 이미 기존 계정이 있습니다.",
                        "line3": "아래 버튼을 클릭하시면 해당 신청서에 접속하실 수 있습니다.",
                        "subject": "UgoZER 가입 초대"
                    },
                    "verification": {
                        "action": "이메일 주소 확인",
                        "excursion_shared": "여행 일정이 귀하와 공유되었습니다. PDF 형식으로 첨부해 보실 수 있으며,\n                    귀하의 주식에 대한 응용 프로그램에서도 마찬가지입니다.",
                        "line1": "당신은 우리의 응용 프로그램에 참여하도록 초대되었습니다",
                        "line1_by": "귀하는 {name}의 초대를 받아 우리 신청서에 참여했습니다.",
                        "line1_by_from": "귀하는 {poi_title} 기관의 {name}으로부터 우리 신청서에 참여하도록 초대받았습니다.",
                        "line2": "이메일 주소를 확인하려면 아래 버튼을 클릭하세요.",
                        "line3": "이 링크는 1주일 후에 만료됩니다.",
                        "line4": "우리의 응용 프로그램을 사용해 주셔서 감사합니다!",
                        "point_of_interest_shared": "관심 장소가 귀하와 공유되었습니다. 당신은 그것을 찾을 수 있습니다\n                귀하의 주식에 있는 신청서에.",
                        "subject": "UgoZER 가입 초대"
                    }
                },
                "invitation_professional": {
                    "content": "귀하는 UgoZER 애플리케이션의 전문 섹션에 초대되었습니다.",
                    "cta": "전문 섹션에 등록",
                    "subject": "UgoZER - 초대 전문가 액세스",
                    "thanks": "감사해요,",
                    "title": "UgoZER 애플리케이션의 전문 섹션에 초대"
                },
                "preview": {
                    "default": {
                        "subject": "메일 제목",
                        "user": {
                            "name": "우고ZER"
                        }
                    },
                    "from": "에서:",
                    "mails": {
                        "auth/account-deleted-notification": "계정 삭제",
                        "auth/not-logged-notification": "연결되지 않은 경우 삭제",
                        "auth/registration-attempt-notification": "등록을 시도했습니다.",
                        "auth/registration-notification": "등록",
                        "auth/reset-password-notification": "비밀번호 변경",
                        "invitation/invited-notification": "초대",
                        "share/created-notification": "새로운",
                        "support-message/message-notification": "새로운 답변",
                        "support-request/archived-notification": "보관된 요청",
                        "support-request/created-notification": "생성된 요청",
                        "user-credential-verification/login-attempt-notification": "연결 시도",
                        "user-credential-verification/registration-attempt-notification": "등록을 시도했습니다.",
                        "user-credential-verification/verify-notification": "계정 확인",
                        "user/welcome-notification": "환영"
                    },
                    "main_title": "이메일 미리보기",
                    "sections": {
                        "auth": "입증",
                        "invitation": "초대장",
                        "share": "주식",
                        "support-message": "지원 메시지",
                        "support-request": "지원 요청",
                        "user": "사용자",
                        "user-credential-verification": "사용자 자격 증명"
                    }
                },
                "salutations": "문안 인사",
                "share": {
                    "created": {
                        "action": "보여주다",
                        "excursion": {
                            "gotoapp": "앱으로 이동하여 확인하세요.",
                            "line": " {poi_title} 기관의 {username}이 귀하와 여행 일정을 공유했습니다.",
                            "line_alt": "{username}이 당신과 여행 일정을 공유했습니다.",
                            "line_alt_2": "여행 일정이 귀하와 공유되었습니다.",
                            "subject": "여행 일정이 귀하와 공유되었습니다."
                        },
                        "line": " {poi_title} 기관의 {username}이 귀하와 항목을 공유했습니다.",
                        "line_alt": "{username}님이 나와 ​​항목을 공유했습니다.",
                        "line_alt_2": "항목이 나와 공유되었습니다.",
                        "point_of_interest": {
                            "line": " {poi_title} 기관의 {username}이 귀하와 관심 지점을 공유했습니다.",
                            "line_alt": "{username}이 당신과 관심 장소를 공유했습니다.",
                            "line_alt_2": "관심 장소가 귀하와 공유되었습니다.",
                            "subject": "관심 장소가 귀하와 공유되었습니다."
                        },
                        "subject": "항목이 나와 공유되었습니다."
                    }
                },
                "subscription": {
                    "not_continue_after_free_period": {
                        "cta": "세부정보 보기",
                        "line1": "무료 기간이 종료된 후 {name}이 계속되지 않았습니다.",
                        "line2": "아래 버튼을 클릭하시면 상세정보를 확인하실 수 있습니다",
                        "subject": "무료 기간이 종료된 후 구독자가 계속되지 않았습니다."
                    },
                    "suspended": {
                        "line": "다음과 같은 이유로 구독이 일시 중지되었습니다: {suspension_reason}.",
                        "line_alt": "구독이 일시 중지되었습니다.",
                        "subject": "구독이 정지됨"
                    },
                    "switched_from_paid_to_free": {
                        "cta": "세부정보 보기",
                        "line1": "{name}이 무료 플랜으로 전환되었습니다",
                        "line2": "아래 버튼을 클릭하시면 상세정보를 확인하실 수 있습니다",
                        "subject": "가입자가 무료 요금제로 전환했습니다."
                    }
                },
                "support_request": {
                    "archived": {
                        "subject": "UgoZER - 지원 요청이 보관되었습니다.",
                        "title": "귀하의 지원 요청이 보관되었습니다"
                    },
                    "message": {
                        "action": "지원 요청에 액세스",
                        "line1": "귀하의 요청에 액세스하려면 아래 버튼을 클릭하십시오.",
                        "subject": "UgoZER - 답이 있습니다",
                        "title": "지원 요청에 답변이 게시되었습니다."
                    },
                    "request": {
                        "action": "지원 요청에 액세스",
                        "line1": "지원팀에 문의하면 48시간 이내에 답변을 드립니다.",
                        "line2": "귀하의 요청에 액세스하려면 아래 버튼을 클릭하십시오.",
                        "subject": "UgoZER - 귀하의 지원 요청이 생성되었습니다"
                    }
                },
                "user_send_link": {
                    "content": "UgoZER 애플리케이션에 다시 연결해 보세요! 비밀번호가 기억나지 않는 경우 재설정할 수 있습니다.",
                    "cta": "로그인 신청",
                    "subject": "UgoZER-응용 프로그램",
                    "thanks": "감사해요,",
                    "title": "우리 애플리케이션에 계정이 있습니다. 로그인하세요!"
                },
                "welcome": {
                    "action": "애플리케이션에 대한 액세스",
                    "line1": "귀하를 우리 사용자로 포함하게 되어 기쁘게 생각합니다.",
                    "line2": "아래 버튼을 클릭하시면 해당 애플리케이션에 접속하실 수 있습니다.",
                    "subject": "UgoZER에 오신 것을 환영합니다",
                    "title": "무료 다국어 여행 지원 앱인 UgoZER에 오신 것을 환영합니다."
                }
            },
            "media": {
                "audio": {
                    "generated_audio": "생성된 오디오",
                    "recording": {
                        "errors": {
                            "default": "녹음 실패",
                            "not_allowed": "녹음에 실패했습니다. 브라우저와 UgoZER가 마이크를 사용하도록 허용하고 다시 시도하세요."
                        }
                    }
                },
                "default": {
                    "alt": "Google 지도의 정적 지도",
                    "caption": "Google 지도에 표시하려면 지도를 클릭하세요."
                },
                "image": {
                    "credits": "크레딧: {credits}"
                }
            },
            "models": {
                "activity_log": "활동 로그|활동 로그",
                "address": "주소|주소",
                "bank_transfer": "은행송금|은행송금",
                "bank_transfer_institution": "은행이체기관|은행이체기관",
                "category": "카테고리|카테고리",
                "changelog": "변경 로그|변경 로그",
                "claim": "청구|청구",
                "claim_dispute": "소유권 분쟁|청구 분쟁",
                "currency": "통화|통화",
                "data_tourisme_category": "DataTourisme 카테고리|DataTourisme 카테고리",
                "data_tourisme_tag": "DataTourisme 태그|DataTourisme 태그",
                "excursion": "여행일정|여행일정",
                "institution_employee": "직원|직원",
                "interaction": "상호작용|상호작용",
                "locale": "언어|언어",
                "medium": "매체|미디어",
                "notification": "알림|알림",
                "partner": "파트너|파트너",
                "payment": "결제|결제",
                "permission": "권한|권한",
                "plan": "구독 계획|구독 계획",
                "plan_price": "요금제|요금제",
                "point_of_interest": "관심가는 곳|가볼만한 곳",
                "point_of_interest_category": "카테고리|카테고리",
                "post": "게시물|게시물",
                "post_category": "게시물 카테고리|게시물 카테고리",
                "privacy_policy": "개인정보 보호정책|개인정보 보호정책",
                "report": "보고서|보고서",
                "reservation": "예약|예약",
                "role": "역할|역할",
                "share": "공유|공유",
                "subscription": "구독|구독",
                "support_category": "지원 카테고리|지원 카테고리",
                "support_message": "메시지|메시지",
                "support_request": "요청|요청",
                "tag": "태그|태그",
                "terms_condition": "이용약관|이용약관",
                "traveller_email": "여행자 이메일|여행자 이메일",
                "traveller_folder": "여행자 폴더|여행자 폴더",
                "user": "사용자|사용자",
                "user_credential_verification": "자격 증명|자격 증명 확인",
                "user_geoloc_setting": "Geoloc 설정|Geoloc 설정",
                "user_notification_setting": "알림 설정|알림 설정",
                "user_password_reset": "사용자 비밀번호 재설정|사용자 비밀번호 재설정",
                "user_privacy_setting": "개인정보 보호 설정|개인정보 보호 설정",
                "users_relationship": "연락처|연락처",
                "vat_rate": "부가가치세율|부가가치세율"
            },
            "navigations": {
                "items": {
                    "changelogs": "변경 로그",
                    "contact_us": "문의하기",
                    "contacts": "콘택트 렌즈",
                    "cookies": "쿠키 관리",
                    "credits": "크레딧",
                    "customers": "그들은 우리를 신뢰합니다",
                    "faqs": "자주하는 질문",
                    "folders": "여행자 폴더",
                    "invite_professional": "전문가를 초대하세요",
                    "invite_traveller": "여행자 초대",
                    "partners": "파트너",
                    "payments": "결제",
                    "privacy_policy": "개인 정보 정책",
                    "settings": "매개변수",
                    "stays": "숙박",
                    "terms_conditions": "이용 약관",
                    "users": "사용자"
                }
            },
            "notifications": {
                "changelog": {
                    "created": {
                        "text": "버전 {version}이 배포되었습니다. 알림을 클릭하시면 해당 소식을 확인하실 수 있습니다.",
                        "title": "새로운 변경 내역"
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "text": "관심지점 {poi}에 대한 소유권 분쟁은 당사 팀의 확인을 기다리고 있습니다.",
                        "title": "소유권 주장 분쟁 확인 대기 중"
                    },
                    "rejected": {
                        "no_reason": "이유가 제공되지 않음",
                        "text": " {poi} 관심 지점에 대한 소유권 분쟁은 {reason}라는 이유로 거부되었습니다.",
                        "title": "소유권 주장 거부됨"
                    },
                    "validated": {
                        "text": " {poi} 관심지점에 대한 클레임 분쟁이 접수되었습니다!",
                        "title": "소유권 주장 이의 제기가 확인되었습니다."
                    }
                },
                "index": {
                    "no_results": "알림이 없습니다!"
                },
                "institution_employee": {
                    "approved": {
                        "text": " {poi} 가입 요청이 승인되었습니다",
                        "title": "직원 요청이 승인됨"
                    },
                    "declined": {
                        "text": "귀하의 {poi} 가입 요청이 거부되었습니다",
                        "title": "직원 요청이 거부됨"
                    },
                    "pending": {
                        "text": "{user} 귀하 기관의 직원으로 등록 요청 {poi}",
                        "title": "직원 요청 접수됨"
                    },
                    "removed": {
                        "text": " {poi}의 직원 상태가 제거되었습니다.",
                        "title": "직원 상태가 제거됨"
                    }
                },
                "invitation": {
                    "accepted": {
                        "email": {
                            "text": " {credential}으로 전송된 신청서 가입 초대가 수락되었습니다."
                        },
                        "phone": {
                            "text": " {credential}으로 전송된 신청서 가입 초대가 수락되었습니다."
                        },
                        "title": "초대가 수락되었습니다."
                    },
                    "pro": {
                        "text": "귀하의 전문 초대장이 이메일로 전송되었습니다 {email}",
                        "title": "전문가 초대장이 전송되었습니다."
                    }
                },
                "office": {
                    "claim": {
                        "content": {
                            "line_1": "관심 지점이 방금 요청되었습니다.",
                            "line_2": "관리 인터페이스에서 세부 정보를 보려면 아래 버튼을 클릭하세요."
                        },
                        "cta": "주장 보기",
                        "subject": "새로운 주장!"
                    },
                    "claim_dispute": {
                        "content": {
                            "line_1": "방금 관심 지점에 대한 이의가 제기되었습니다.",
                            "line_2": "관리 인터페이스에서 세부 정보를 보려면 아래 버튼을 클릭하세요."
                        },
                        "cta": "분쟁 보기",
                        "subject": "새로운 분쟁!"
                    },
                    "report": {
                        "content": {
                            "line_1": "{type}이 방금 보고되었습니다."
                        },
                        "cta": "보고서 보기",
                        "subject": "새로운 보고서!",
                        "type": {
                            "App\\Models\\PointOfInterest": "관심 장소",
                            "App\\Models\\User": "사용자"
                        }
                    },
                    "support_request": {
                        "content": {
                            "line_1": "새로운 지원 요청이 추가되었습니다."
                        },
                        "cta": "지원 요청 보기",
                        "subject": "새로운 지원 요청!"
                    }
                },
                "payment": {
                    "proposal": {
                        "text": "팁을 남겨서 {poi_title} 기관으로부터 {username}에게 감사를 표하세요",
                        "text_alt": " {poi_title} 기관의 컨시어지에게 팁을 남겨 감사 인사를 전하세요",
                        "text_alt_2": "팁을 남겨서 1배 감사드립니다",
                        "text_alt_3": "팁을 남겨 컨시어지에게 감사 인사를 전하세요.",
                        "title": "팁을 남기다"
                    },
                    "received": {
                        "text": " {poi_title} 기관으로부터 {payer}로부터 {amount} 팁을 받았습니다.",
                        "text_alt": " {payer}로부터 {amount} 팁을 받았습니다.",
                        "text_alt_2": " {amount} 팁을 받았습니다.",
                        "title": "팁을 받았습니다"
                    },
                    "thanks": {
                        "text": " {poi_title} 기관의 {payee} 팁을 주셔서 감사합니다!",
                        "text_alt": "{payee} 팁을 주셔서 감사합니다!",
                        "text_alt_2": "팁을 주셔서 감사합니다.",
                        "title": "팁 고마워!"
                    }
                },
                "point_of_interest": {
                    "event": {
                        "expired": {
                            "text": "여행 일정에 포함된 이벤트 {title}이 만료되었습니다.",
                            "title": "이벤트가 만료되었습니다."
                        }
                    },
                    "moderation": {
                        "denied": {
                            "text": "귀하의 관심 장소 {title}이 조정 후 거부되었습니다.",
                            "title": "관심 장소가 거부됨"
                        },
                        "published": {
                            "text": "귀하의 관심 장소 {title}이 조정 후 게시되었습니다.",
                            "title": "관심 장소가 게시되었습니다."
                        }
                    }
                },
                "reservation": {
                    "created": {
                        "text": "기관 {butler_name} {butler_poi_title}가 예약했습니다\n                귀하의 기관에서 {poi_title}",
                        "text_alt": "해당 기관의 컨시어지 {butler_poi_title}이 예약을 완료했습니다\n                귀하의 기관에서 {poi_title}",
                        "text_alt_2": "{butler_name}님이 예약을 하셨습니다\n                귀하의 기관에서 {poi_title}",
                        "text_alt_3": "예약이 완료되었습니다\n                귀하의 기관에서 {poi_title}",
                        "title": "귀하의 기관에서 예약"
                    }
                },
                "share": {
                    "created": {
                        "excursion": {
                            "text": " {poi_title} 기관의 {username}이 귀하와 일정을 공유했습니다.",
                            "text_alt": "{username}님이 당신과 여행 일정을 공유했습니다",
                            "text_alt_2": "여행 일정이 나와 공유되었습니다.",
                            "title": "여행 일정 공유"
                        },
                        "point_of_interest": {
                            "text": " {poi_title} 기관의 {username}이 귀하와 관심 장소를 공유했습니다.",
                            "text_alt": "{username}이 당신과 관심 장소를 공유했습니다",
                            "text_alt_2": "관심 장소가 나와 공유되었습니다.",
                            "title": "관심 장소 공유"
                        },
                        "text": " {poi_title} 기관의 {username}이 귀하와 항목을 공유했습니다.",
                        "text_alt": "{username}님이 나와 ​​항목을 공유했습니다.",
                        "text_alt_2": "항목이 나와 공유되었습니다."
                    }
                },
                "stripe": {
                    "account_requirements": {
                        "text": "Stripe 계정 확인 후 새로운 정보가 요청됩니다",
                        "title": "스트라이프 계정 요구 사항"
                    },
                    "payments_enabled": {
                        "text": "이제 Stripe을 통해 결제를 받을 수 있습니다",
                        "title": "스트라이프 결제 활성화"
                    }
                },
                "support_request": {
                    "archived": {
                        "text": "귀하의 지원 요청이 보관되었습니다",
                        "title": "지원 요청이 보관되었습니다."
                    },
                    "message": {
                        "text": "지원 요청에 답변이 게시되었습니다.",
                        "title": "메시지 받음"
                    }
                },
                "traveller_folder": {
                    "approved": {
                        "text": "{username}이 여행자 폴더를 승인했습니다.",
                        "text_alt": "여행자가 자신의 폴더를 승인했습니다.",
                        "title": "여행자 폴더가 승인됨"
                    },
                    "archived": {
                        "text": "{username}이 여행자 폴더를 보관했습니다.",
                        "text_alt": "여행자가 자신의 폴더를 보관했습니다.",
                        "title": "여행자 폴더가 보관되었습니다."
                    },
                    "created": {
                        "text": "귀하의 계정에 호텔 {poi_title} 숙박이 등록되었습니다.",
                        "title": "생성된 상태 유지"
                    },
                    "pending": {
                        "text": "{username}이 귀하의 계정에 호텔 투숙을 등록했습니다 {poi_title}.\n                확인 해주세요.",
                        "text_alt": "귀하의 계정에 호텔 {poi_title} 숙박이 등록되었습니다.\n                확인 해주세요.",
                        "title": "확인 대기 중인 숙박"
                    },
                    "thanks": {
                        "text": " {poi_title} 기관에서 {username} 숙박해주셔서 감사합니다",
                        "text_alt": " {poi_title} 기관의 컨시어지가 숙박해 주셔서 감사합니다",
                        "title": "숙박해주셔서 감사합니다"
                    }
                }
            },
            "nova": {
                "actions": {
                    "bank_transfer": {
                        "transfer": "은행 송금하기"
                    },
                    "changelog": {
                        "archive": "보관소",
                        "draft": "초안",
                        "publish": "게시"
                    },
                    "claim": {
                        "missing_info": "누락된 정보",
                        "reject": "거부하다",
                        "revoke": "취소",
                        "validate": "확인"
                    },
                    "claim_dispute": {
                        "reject": "거부하다",
                        "validate": "확인"
                    },
                    "payment": {
                        "download_invoice": "송장 다운로드"
                    },
                    "plan": {
                        "archive": "보관소",
                        "draft": "초안",
                        "publish": "게시"
                    },
                    "point_of_interest": {
                        "deny": "부인하다",
                        "draft": "초안",
                        "publish": "확인",
                        "submit": "중재를 요청하세요"
                    },
                    "post": {
                        "archive": "보관소",
                        "draft": "초안",
                        "publish": "게시",
                        "set_coming_soon": "'곧'으로 설정"
                    },
                    "privacy_policy": {
                        "archive": "보관소",
                        "confirmation": {
                            "confirm_button_text": "게시",
                            "confirm_text": "이 개인정보 보호정책을 게시하시겠습니까? 모든 사용자에게 다시 확인하라는 메시지가 표시됩니다!"
                        },
                        "errors": {
                            "resources_count": "하나 이상의 개인 정책을 게시할 수 없습니다!"
                        },
                        "publish": "게시"
                    },
                    "report": {
                        "process": "프로세스",
                        "reject": "거부하다"
                    },
                    "support_request": {
                        "answer": "답변",
                        "archive": "보관소",
                        "cancel": "취소",
                        "pending_support": "지원을 기다리고 있습니다",
                        "pending_user": "사용자를 기다리는 중",
                        "resolve": "해결하다"
                    },
                    "terms_condition": {
                        "archive": "보관소",
                        "confirmation": {
                            "confirm_button_text": "게시",
                            "confirm_text": "이 이용약관을 게시하시겠습니까? 모든 사용자에게 다시 확인하라는 메시지가 표시됩니다!"
                        },
                        "errors": {
                            "resources_count": "하나 이상의 이용약관을 게시할 수 없습니다!"
                        },
                        "publish": "게시"
                    },
                    "user": {
                        "finish": "튜토리얼 완료"
                    },
                    "user_credential_verification": {
                        "validate": "확인"
                    }
                },
                "cards": {
                    "new_excursions": "새로운 여행 일정",
                    "new_points_of_interest": "새로운 관심 장소",
                    "new_users": "신규 사용자"
                },
                "filters": {
                    "app": "애플리케이션",
                    "archived_at_gte": "다음 날짜 이후에 보관됨",
                    "archived_at_lte": "이전에 보관됨",
                    "canceled_at_gte": "다음 날짜 이후에 취소됨",
                    "canceled_at_lte": "이전에 취소됨",
                    "created_at_gte": "다음 날짜 이후에 생성됨",
                    "created_at_lte": "이전에 생성됨",
                    "dynamic_translations_enabled": "동적 번역이 활성화되었습니다.",
                    "extranet": "엑스트라넷",
                    "front": "앞쪽",
                    "group": "그룹",
                    "published_at_gte": "다음 날짜 이후에 게시됨",
                    "published_at_lte": "이전에 게시됨",
                    "reservation_date_gte": "이후 예약",
                    "reservation_date_lte": "예약 전",
                    "resolved_at_gte": "다음 날짜 이후에 해결됨",
                    "resolved_at_lte": "이전에 해결됨",
                    "static_translations_enabled": "정적 번역이 활성화되었습니다.",
                    "to_validate": "검증하려면"
                },
                "labels": {
                    "institutions": "기관",
                    "point_of_interest_categories": "카테고리"
                }
            },
            "opening_hours": {
                "additional_info": "추가 정보",
                "content": {
                    "date": " {date}",
                    "date_range_from": " {from}에서",
                    "date_range_from_to": " {from}에서 {to}로",
                    "date_range_to": " {to}까지",
                    "hours_range_from": " {from}에서",
                    "hours_range_from_to": " {from}에서 {to}로",
                    "hours_range_to": " {to}까지"
                }
            },
            "partners": {
                "customers": {
                    "title": "우리의 고객들"
                },
                "index": {
                    "no_results": "파트너를 찾을 수 없습니다.",
                    "our_last_partners": "우리의 마지막 파트너",
                    "title": "우리의 파트너들"
                },
                "show": {
                    "link": "웹사이트 방문"
                }
            },
            "payments": {
                "checkout": {
                    "cancel": "결제가 취소되었습니다",
                    "custom_amount": "맞춤 금액을 입력하세요.",
                    "success": "결제가 성공적으로 완료되었습니다",
                    "tip_amount": "팁 금액"
                },
                "index": {
                    "no_results": "결제를 찾을 수 없습니다."
                },
                "labels": {
                    "payee": "수취인:",
                    "payment_service": "지불자"
                },
                "payable": {
                    "reservation": "예약번호{ID}"
                },
                "thanks": {
                    "success": "감사합니다!"
                }
            },
            "pdf": {
                "common": {
                    "generating": "PDF 파일 생성 중...",
                    "waiting": "기다리세요..."
                },
                "payments": {
                    "period": " {from}에서 {to}까지의 기간",
                    "title": "지불금 수령"
                },
                "receipt": {
                    "debited_amount": "인출금액",
                    "paid_amount": "지급금액",
                    "payment_date": "결제일",
                    "payment_method": "결제수단",
                    "receipt_for": "결제 영수증",
                    "receipt_number": "결제 영수증 #{id}",
                    "summary": "요약"
                },
                "share": {
                    "cta": "여행일정을 상담하세요",
                    "details": {
                        "line1": "귀하의 맞춤형 여행 일정을 귀하와 공유하게 되어 기쁘게 생각합니다.",
                        "line2": "아래에서 여정의 내용을 찾을 수 있습니다.",
                        "line3": "자세한 정보(현지 언어로 된 주소가 있는 오디오, 대화형 지도,\n                사진, 전체 설명...), 아래 버튼을 클릭하십시오."
                    }
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "ADMIN": "관리에 액세스",
                        "APP": "앱에 액세스",
                        "BYPASS_PERMISSIONS": "액세스와 관련된 권한 무시",
                        "EXTRANET": "전문 섹션에 액세스",
                        "HORIZON": "액세스 호라이즌",
                        "TELESCOPE": "액세스 망원경",
                        "VIEW_BUTLER_DASHBOARD": "집사 대시보드 보기",
                        "VIEW_HOTELIER_DASHBOARD": "호텔리어 대시보드 보기",
                        "VIEW_MANAGER_DASHBOARD": "관리자 대시보드 보기",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "관광 안내소 대시보드 보기",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "관광청 직원 대시보드 보기",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "공인인증서 없이 로그인"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "활동 로그와 관련된 권한 무시",
                        "CREATE": "활동 로그 만들기",
                        "DELETE": "활동 로그 삭제",
                        "FORCE_DELETE": "활동 로그 강제 삭제",
                        "RESTORE": "활동 로그 복원",
                        "UPDATE": "활동 로그 업데이트",
                        "VIEW": "활동 로그 참조",
                        "VIEW_ANY": "활동 로그 색인 참조"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "주소와 관련된 권한 무시",
                        "CREATE": "주소 만들기",
                        "DELETE": "주소 삭제",
                        "FORCE_DELETE": "주소 강제 삭제",
                        "RESTORE": "주소 복원",
                        "UPDATE": "주소 업데이트",
                        "VIEW": "주소를 참조하십시오",
                        "VIEW_ANY": "주소 색인 참조"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "은행 송금 기관과 관련된 권한을 무시합니다.",
                        "VIEW_ANY": "은행 송금 기관의 색인을 참조하십시오"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "ARCHIVE": "변경 로그 보관",
                        "BYPASS_PERMISSIONS": "변경 로그와 관련된 권한 무시",
                        "CREATE": "변경 로그 만들기",
                        "DELETE": "변경 로그 삭제",
                        "DRAFT": "변경 로그 초안 작성",
                        "FORCE_DELETE": "변경 로그 강제 삭제",
                        "PUBLISH": "변경 로그 게시",
                        "RESTORE": "변경 로그 복원",
                        "UPDATE": "변경 로그 업데이트",
                        "VIEW": "변경 로그 참조",
                        "VIEW_ANY": "변경 로그 색인 참조"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "소유권 분쟁과 관련된 권한 무시",
                        "CREATE": "클레임 분쟁 생성",
                        "DELETE": "소유권 분쟁 삭제",
                        "FORCE_DELETE": "소유권 분쟁 강제 삭제",
                        "REJECT": "클레임 분쟁 거부",
                        "RESTORE": "클레임 분쟁 복원",
                        "UPDATE": "소유권 분쟁 업데이트",
                        "VALIDATE": "소유권 분쟁 확인",
                        "VIEW": "클레임 분쟁 문의",
                        "VIEW_ANY": "클레임 분쟁 색인 참조"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "소유권 주장과 관련된 권한 무시",
                        "CREATE": "클레임 만들기",
                        "DELETE": "클레임 삭제",
                        "FORCE_DELETE": "소유권 주장 강제 삭제",
                        "MISSING_INFO": "누락된 정보로 인한 청구 거부",
                        "REJECT": "청구 거부",
                        "RESTORE": "청구 복원",
                        "REVOKE": "청구 취소",
                        "SHOW_FILE": "청구 파일 표시",
                        "UPDATE": "클레임 업데이트",
                        "VALIDATE": "클레임 확인",
                        "VIEW": "클레임 문의",
                        "VIEW_ANY": "청구 색인 참조"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "국가와 관련된 권한 무시",
                        "USE_ANY": "모든 국가 사용"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "통화와 관련된 권한 무시",
                        "CREATE": "통화 만들기",
                        "DELETE": "통화 삭제",
                        "FORCE_DELETE": "통화 강제 삭제",
                        "RESTORE": "통화 복원",
                        "UPDATE": "통화 업데이트",
                        "VIEW": "통화 상담",
                        "VIEW_ANY": "통화 지수 참조"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "EasyTransac 계정과 관련된 권한 무시",
                        "HAVE": "EasyTransac 계정 보유"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "여행 일정 관련 권한을 무시합니다.",
                        "CREATE": "여행 일정 만들기",
                        "DELETE": "여행 일정 삭제",
                        "FORCE_DELETE": "여행일정 강제삭제",
                        "RESTORE": "여행 일정 복원",
                        "SHARE": "여행 일정 공유",
                        "UPDATE": "여행 일정 업데이트",
                        "VIEW": "여행일정을 상담하세요",
                        "VIEW_ANY": "여행 일정 색인을 참조하세요.",
                        "VIEW_KIND_UGOZER_APP": "UgoZER 앱 종류로 여행 일정 보기",
                        "VIEW_KIND_UGOZER_PRO": "UgoZER Pro 종류로 여행 일정 보기"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "여행 일정과 관심 장소 간의 링크와 관련된 권한을 무시합니다.",
                        "CREATE": "여행 일정과 관심 장소 사이의 링크 만들기",
                        "DELETE": "여행 일정과 관심 장소 사이의 링크 삭제",
                        "FORCE_DELETE": "여행 일정과 관심 장소 사이의 링크 강제 삭제",
                        "RESTORE": "여행 일정과 관심 장소 간의 링크 복원",
                        "UPDATE": "여행 일정과 관심 장소 간의 링크 업데이트",
                        "VIEW": "여행 일정과 관심 장소 사이의 링크를 참조하세요",
                        "VIEW_ANY": "여행 일정과 관심 장소 간의 링크 색인을 참조하세요."
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "APPROVE": "기관 직원 요청 승인",
                        "BYPASS_PERMISSIONS": "기관 직원과 관련된 권한 무시",
                        "CANCEL": "기관 직원 요청 취소",
                        "CREATE": "기관 직원 만들기",
                        "DECLINE": "기관 직원 요청 거절",
                        "DELETE": "기관 직원 삭제",
                        "FEATURE": "기관의 직원 등장",
                        "FORCE_DELETE": "기관 직원 강제 삭제",
                        "REMOVE": "기관에서 직원 제거",
                        "RESTORE": "기관 직원 복원",
                        "UPDATE": "기관 직원 업데이트",
                        "VIEW": "기관 직원에게 문의",
                        "VIEW_ANY": "기관 직원 색인 참조"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "ARCHIVE": "상호 작용 보관",
                        "BYPASS_PERMISSIONS": "상호 작용과 관련된 권한 무시",
                        "CREATE": "상호 작용 만들기",
                        "DELETE": "상호 작용 삭제",
                        "DRAFT": "인터랙션 초안 작성",
                        "FORCE_DELETE": "상호작용 강제 삭제",
                        "PUBLISH": "상호 작용 게시",
                        "RESTORE": "상호 작용 복원",
                        "UPDATE": "상호 작용 업데이트",
                        "VIEW": "상담 문의",
                        "VIEW_ANY": "상호 작용 색인 참조"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "언어와 관련된 권한 무시",
                        "CREATE": "언어 만들기",
                        "DELETE": "언어 삭제",
                        "FORCE_DELETE": "언어 강제 삭제",
                        "RESTORE": "언어 복원",
                        "UPDATE": "언어 업데이트",
                        "VIEW": "언어 상담",
                        "VIEW_ANY": "언어 색인 참조"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "미디어 관련 권한 무시",
                        "CREATE": "미디어 만들기",
                        "DELETE": "미디어 삭제",
                        "FORCE_DELETE": "미디어 강제 삭제",
                        "RESTORE": "미디어 복원",
                        "UPDATE": "미디어 업데이트",
                        "VIEW": "미디어에 문의",
                        "VIEW_ANY": "미디어 색인 참조"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "초대가 수락되면 알림 받기",
                        "PAYMENT_RECEIVED": "결제가 접수되면 알림을 받습니다.",
                        "PAYMENT_THANKS": "결제가 확인되면 알림 받기",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "관심 지점 이벤트가 만료되면 알림 받기",
                        "RESERVATION_CREATED": "예약이 생성되면 알림 받기",
                        "SHARE_CREATED": "공유가 생성되면 알림 받기",
                        "SUPPORT_REQUEST_ARCHIVED": "지원 요청이 보관되면 알림을 받습니다.",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "지원팀에서 메시지를 받으면 알림을 받습니다.\n                요구",
                        "TRAVELLER_FOLDER_APPROVED": "여행자 파일이 승인되면 알림을 받습니다.",
                        "TRAVELLER_FOLDER_ARCHIVED": "여행자 파일이 보관될 때 알림 받기",
                        "TRAVELLER_FOLDER_PENDING": "파일이 유효한 여행자를 기다리는 동안 알림을 받습니다.",
                        "TRAVELLER_FOLDER_THANKS": "파일 트래블러 담당 컨시어지에게 감사의 알림을 받습니다."
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "파트너와 관련된 권한 무시",
                        "CREATE": "파트너 만들기",
                        "DELETE": "파트너 삭제",
                        "FORCE_DELETE": "파트너 강제 삭제",
                        "RESTORE": "파트너 복원",
                        "UPDATE": "파트너 업데이트",
                        "VIEW": "파트너에게 문의",
                        "VIEW_ANY": "파트너 색인 참조"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "결제 관련 권한 무시",
                        "CREATE": "지불 만들기",
                        "DELETE": "결제 삭제",
                        "FORCE_DELETE": "결제 강제 삭제",
                        "GET_RECEIPT": "결제 영수증 받기",
                        "RECEIVE": "지불금 받기",
                        "RESTORE": "결제 복원",
                        "THANK": "결제에 대한 감사 메시지 받기",
                        "UPDATE": "결제 업데이트",
                        "VIEW": "결제 상담",
                        "VIEW_ANY": "결제 지수 참조"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "권한과 관련된 권한 무시",
                        "CREATE": "권한 만들기",
                        "DELETE": "권한 삭제",
                        "FORCE_DELETE": "권한 강제 삭제",
                        "RESTORE": "권한 복원",
                        "UPDATE": "권한 업데이트",
                        "VIEW": "권한 문의",
                        "VIEW_ANY": "권한 색인 참조"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "ARCHIVE": "구독 계획 보관",
                        "BYPASS_PERMISSIONS": "구독 요금제와 관련된 권한을 무시합니다.",
                        "CREATE": "구독 계획 만들기",
                        "DELETE": "구독 요금제 삭제",
                        "DRAFT": "구독 계획 초안 작성",
                        "FORCE_DELETE": "구독 요금제 강제 삭제",
                        "PUBLISH": "구독 계획 게시",
                        "RESTORE": "구독 요금제 복원",
                        "SUBSCRIBE": "요금제 구독",
                        "UPDATE": "구독 요금제 업데이트",
                        "VIEW": "구독 계획을 상담하세요",
                        "VIEW_ANY": "구독 계획 색인을 참조하세요."
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "관심 지점 카테고리와 관련된 권한 무시",
                        "CREATE": "관심 지점 범주 만들기",
                        "DELETE": "관심 지점 범주 삭제",
                        "FORCE_DELETE": "관심 장소 카테고리 강제 삭제",
                        "RESTORE": "관심 지점 범주 복원",
                        "UPDATE": "관심 장소 카테고리 업데이트",
                        "VIEW": "관심 지점 카테고리 참조",
                        "VIEW_ANY": "관심 지점 카테고리 색인 참조"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "ADMIN_ANY": "관심 지점 관리",
                        "APPLY": "직원으로 신청",
                        "BYPASS_PERMISSIONS": "관심 장소와 관련된 권한 무시",
                        "CLAIM": "관심 장소 요청",
                        "CONTEST": "관심 지점 경연",
                        "CONTRIBUTE": "관심 지점에 기여",
                        "CREATE": "관심 장소 만들기",
                        "DELETE": "관심 장소 삭제",
                        "DENY": "관심 장소 거부",
                        "DRAFT": "관심 지점 초안 작성",
                        "FORCE_DELETE": "관심 장소 강제 삭제",
                        "IMPORT": "관심 지점 가져오기",
                        "MODERATE": "관심 지점 조정",
                        "PUBLISH": "관심 장소 게시",
                        "REPORT": "관심 장소 신고",
                        "RESTORE": "관심 지점 복원",
                        "SHARE": "관심 장소 공유",
                        "SUBMIT": "관심 장소 제출",
                        "UPDATE": "관심 장소 업데이트",
                        "UPDATE_CERTIFIED": "관심 지점의 인증된 속성 업데이트",
                        "VIEW": "관심 지점을 참조하십시오",
                        "VIEW_ANY": "관심 지점 색인 참조"
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "게시물 카테고리와 관련된 권한 무시",
                        "CREATE": "게시물 카테고리 만들기",
                        "DELETE": "게시물 카테고리 삭제",
                        "FORCE_DELETE": "게시물 카테고리 강제 삭제",
                        "RESTORE": "게시물 카테고리 복원",
                        "UPDATE": "게시물 카테고리 업데이트",
                        "VIEW": "게시물 카테고리를 참조하십시오",
                        "VIEW_ANY": "게시물 카테고리 색인 참조"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "ARCHIVE": "게시물 보관",
                        "BYPASS_PERMISSIONS": "게시물 관련 권한 무시",
                        "CREATE": "게시물 만들기",
                        "DELETE": "게시물 삭제",
                        "DRAFT": "게시물 초안 작성",
                        "FORCE_DELETE": "게시물 강제 삭제",
                        "PUBLISH": "게시물 게시",
                        "RESTORE": "게시물 복원",
                        "UPDATE": "게시물 업데이트",
                        "VIEW": "게시물 참조",
                        "VIEW_ANY": "게시물 색인 참조"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "ARCHIVE": "개인정보 보호정책 보관",
                        "BYPASS_PERMISSIONS": "개인 정보 보호 정책과 관련된 권한 무시",
                        "CREATE": "개인 정보 보호 정책 만들기",
                        "DELETE": "개인 정보 보호 정책 삭제",
                        "DRAFT": "개인 정보 보호 정책 초안 작성",
                        "FORCE_DELETE": "개인 정보 보호 정책 강제 삭제",
                        "PUBLISH": "개인 정보 보호 정책 게시",
                        "RESTORE": "개인 정보 보호 정책 복원",
                        "UPDATE": "개인 정보 보호 정책 업데이트",
                        "VIEW": "개인 정보 보호 정책을 참조하십시오",
                        "VIEW_ANY": "개인 정보 보호 정책을 참조하십시오"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "이 권한이 있는 역할로 전문 섹션에 등록하세요."
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "ARCHIVE": "보고서 보관",
                        "BYPASS_PERMISSIONS": "보고서와 관련된 권한 무시",
                        "CREATE": "보고서 만들기",
                        "DELETE": "보고서 삭제",
                        "DRAFT": "보고서 초안 작성",
                        "FORCE_DELETE": "보고서 강제 삭제",
                        "PROCESS_ANY": "모든 보고서 처리",
                        "PUBLISH": "보고서 게시",
                        "REJECT_ANY": "신고 거부",
                        "RESTORE": "보고서 복원",
                        "UPDATE": "보고서 업데이트",
                        "VIEW": "보고서 참조",
                        "VIEW_ANY": "보고서 색인 참조"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "ARCHIVE": "예약 보관",
                        "BYPASS_PERMISSIONS": "예약 관련 권한 무시",
                        "CANCEL": "예약 취소",
                        "CONFIRM": "예약 확인",
                        "CREATE": "예약 생성",
                        "DELETE": "예약 삭제",
                        "FORCE_DELETE": "예약 강제 삭제",
                        "RESTORE": "예약 복원",
                        "UPDATE": "예약 업데이트",
                        "VIEW": "예약 상담",
                        "VIEW_ANY": "예약 색인 참조"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "역할과 관련된 권한 무시",
                        "CREATE": "역할 만들기",
                        "DELETE": "역할 삭제",
                        "FORCE_DELETE": "역할 강제 삭제",
                        "RESTORE": "역할 복원",
                        "UPDATE": "역할 업데이트",
                        "VIEW": "역할 상담",
                        "VIEW_ANY": "역할 색인 참조"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "공유와 관련된 권한 무시",
                        "CREATE": "공유 만들기",
                        "DELETE": "공유 삭제",
                        "FORCE_DELETE": "공유 강제 삭제",
                        "RESTORE": "공유 복원",
                        "UPDATE": "공유 업데이트",
                        "VIEW": "공유를 참조하십시오",
                        "VIEW_ANY": "주식 지수 참조"
                    },
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Stripe 계정과 관련된 권한 무시",
                        "HAVE": "스트라이프 계정이 있으세요"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "구독 관련 권한 무시",
                        "CANCEL": "구독 취소",
                        "CREATE": "구독 만들기",
                        "DELETE": "구독 삭제",
                        "FORCE_DELETE": "구독 강제 삭제",
                        "PAY": "구독료 지불",
                        "RESTORE": "구독 복원",
                        "UPDATE": "구독 업데이트",
                        "VIEW": "구독 상담하기",
                        "VIEW_ANY": "구독 색인 참조"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "지원 범주 요청과 관련된 권한 무시",
                        "CREATE": "지원 범주 만들기",
                        "DELETE": "지원 범주 삭제",
                        "FORCE_DELETE": "지원 카테고리 강제 삭제",
                        "RESTORE": "지원 범주 복원",
                        "UPDATE": "지원 범주 업데이트",
                        "VIEW": "지원 범주에 문의",
                        "VIEW_ANY": "지원 카테고리 색인 참조"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "메시지와 관련된 권한 무시",
                        "CREATE": "메시지 만들기",
                        "DELETE": "메시지 삭제",
                        "FORCE_DELETE": "메시지 강제 삭제",
                        "RESTORE": "메시지 복원",
                        "UPDATE": "메시지 업데이트",
                        "VIEW": "메시지를 참조하십시오",
                        "VIEW_ANY": "메시지 색인 참조"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "ADD_MESSAGE": "메시지 추가",
                        "BYPASS_PERMISSIONS": "지원 요청과 관련된 권한 무시",
                        "CANCEL": "지원 요청 취소",
                        "CREATE": "지원 요청 만들기",
                        "DELETE": "지원 요청 삭제",
                        "FORCE_DELETE": "지원 요청 강제 삭제",
                        "RESTORE": "지원 요청 복원",
                        "UPDATE": "지원 요청 업데이트",
                        "VIEW": "지원 요청 상담",
                        "VIEW_ANY": "지원 요청 색인 참조"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "태그와 관련된 권한 무시",
                        "CREATE": "태그 만들기",
                        "DELETE": "태그 삭제",
                        "FORCE_DELETE": "태그 강제 삭제",
                        "RESTORE": "태그 복원",
                        "UPDATE": "태그 업데이트",
                        "VIEW": "태그 문의",
                        "VIEW_ANY": "태그 색인 참조"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "ARCHIVE": "이용약관 보관",
                        "BYPASS_PERMISSIONS": "이용약관 관련 권한 무시",
                        "CREATE": "이용약관 만들기",
                        "DELETE": "이용약관 삭제",
                        "DRAFT": "이용약관 초안 작성",
                        "FORCE_DELETE": "이용약관 강제 삭제",
                        "PUBLISH": "이용약관 게시",
                        "RESTORE": "이용 약관 복원",
                        "UPDATE": "이용약관 업데이트",
                        "VIEW": "이용약관을 확인하세요",
                        "VIEW_ANY": "이용약관을 참조하세요"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "번역 가능한 레코드와 관련된 권한 무시",
                        "REQUIRE_ANY": "반드시 영어가 아닌 언어로 필수 필드를 번역하도록 허용"
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "여행자 이메일과 관련된 권한 무시",
                        "CREATE": "여행자 이메일 만들기",
                        "DELETE": "여행자 이메일 삭제",
                        "FORCE_DELETE": "여행자 이메일 강제 삭제",
                        "RESTORE": "여행자 이메일 복원",
                        "UPDATE": "여행자 이메일 업데이트",
                        "VIEW": "여행자 이메일 문의",
                        "VIEW_ANY": "여행자 이메일 색인 참조"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "APPROVE": "여행자 폴더 승인",
                        "ARCHIVE": "여행자 폴더 보관",
                        "BYPASS_PERMISSIONS": "여행자 폴더와 관련된 권한 무시",
                        "CREATE": "여행자 폴더 만들기",
                        "DECLINE": "여행자 폴더 거부",
                        "DELETE": "여행자 폴더 삭제",
                        "FORCE_DELETE": "여행자 폴더 강제 삭제",
                        "RESTORE": "여행자 폴더 복원",
                        "THANK": "숙박에 대해 여행자에게 감사",
                        "UPDATE": "여행자 폴더 업데이트",
                        "VIEW": "여행자 폴더에 문의",
                        "VIEW_ANY": "여행자 폴더 색인 참조"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "사용자 자격 증명 확인과 관련된 권한을 무시합니다.",
                        "CREATE": "사용자 자격 증명 확인 만들기",
                        "DELETE": "사용자 자격 증명 확인 삭제",
                        "FORCE_DELETE": "사용자 자격 증명 확인 강제 삭제",
                        "RESTORE": "사용자 자격 증명 확인 복원",
                        "UPDATE": "사용자 자격 증명 확인 업데이트",
                        "VIEW": "사용자 자격 증명 확인에 문의",
                        "VIEW_ANY": "사용자 자격 증명 확인 색인 참조"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "ADD_CONTACT": "사용자를 연락처로 추가",
                        "BYPASS_PERMISSIONS": "사용자와 관련된 권한 무시",
                        "CREATE": "사용자 만들기",
                        "DELETE": "사용자 삭제",
                        "FORCE_DELETE": "사용자 강제 삭제",
                        "INVITE": "사용자 초대",
                        "REPORT": "사용자 신고",
                        "RESTORE": "사용자 복원",
                        "TIP": "사용자에게 팁 주기",
                        "UPDATE": "사용자 업데이트",
                        "UPDATE_BANK_ACCOUNT": "은행 계좌 업데이트",
                        "VIEW": "사용자에게 문의",
                        "VIEW_ANY": "사용자 색인 참조",
                        "VIEW_AVATAR": "사용자의 아바타 보기",
                        "VIEW_CONTACT_EMAIL": "사용자의 연락처 이메일 보기",
                        "VIEW_CONTACT_PHONE": "사용자의 연락처 전화 보기",
                        "VIEW_REAL_NAME": "사용자의 실명 보기"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "사용자 geoloc 설정과 관련된 권한 무시",
                        "CREATE": "사용자 지리적 위치 설정 만들기",
                        "DELETE": "사용자 지리적 위치 설정 삭제",
                        "FORCE_DELETE": "사용자 지리적 위치 설정 강제 삭제",
                        "RESTORE": "사용자 지리적 위치 설정 복원",
                        "UPDATE": "사용자 geoloc 설정 업데이트",
                        "VIEW": "사용자 geoloc 설정을 참조하십시오.",
                        "VIEW_ANY": "사용자 geoloc 설정 색인 참조"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "사용자 알림 설정과 관련된 권한 무시",
                        "CREATE": "사용자 알림 설정 만들기",
                        "DELETE": "사용자 알림 설정 삭제",
                        "FORCE_DELETE": "사용자 알림 설정 강제 삭제",
                        "RESTORE": "사용자 알림 설정 복원",
                        "UPDATE": "사용자 알림 설정 업데이트",
                        "VIEW": "사용자 알림 설정을 참조하세요.",
                        "VIEW_ANY": "사용자 알림 설정 색인을 참조하세요."
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "사용자 개인 정보 설정과 관련된 권한 무시",
                        "CREATE": "사용자 개인 정보 설정 만들기",
                        "DELETE": "사용자 개인 정보 설정 삭제",
                        "FORCE_DELETE": "사용자 개인 정보 설정 강제 삭제",
                        "RESTORE": "사용자 개인 정보 설정 복원",
                        "UPDATE": "사용자 개인 정보 설정 업데이트",
                        "VIEW": "사용자 개인 정보 설정 문의",
                        "VIEW_ANY": "사용자 개인정보 설정 색인 참조"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "APPROVE_CONTACT": "사용자 관계 승인",
                        "BLOCK_CONTACT": "사용자 관계 차단",
                        "BYPASS_PERMISSIONS": "사용자 관계와 관련된 권한 무시",
                        "CREATE": "사용자 관계 만들기",
                        "DECLINE_CONTACT": "사용자 관계 거부",
                        "DELETE": "사용자 관계 삭제",
                        "FORCE_DELETE": "사용자 관계 강제 삭제",
                        "RESTORE": "사용자 관계 복원",
                        "UNBLOCK_CONTACT": "사용자 관계 차단 해제",
                        "UPDATE": "사용자 관계 업데이트",
                        "VIEW": "사용자 관계 상담",
                        "VIEW_ANY": "사용자 관계 색인 참조"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\AccessEnum": "입장",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "활동 로그",
                    "App\\Enums\\Permissions\\AddressEnum": "구애",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "은행 송금 기관",
                    "App\\Enums\\Permissions\\ChangelogEnum": "변경 로그",
                    "App\\Enums\\Permissions\\ClaimEnum": "클레임",
                    "App\\Enums\\Permissions\\CountryEnum": "국가",
                    "App\\Enums\\Permissions\\CurrencyEnum": "통화",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "EasyTransac 계정",
                    "App\\Enums\\Permissions\\ExcursionEnum": "여행일정",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "여행 일정과 관심 장소 간의 연결",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "기관 직원",
                    "App\\Enums\\Permissions\\InteractionEnum": "상호 작용",
                    "App\\Enums\\Permissions\\LocaleEnum": "언어",
                    "App\\Enums\\Permissions\\MediumEnum": "미디어",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "알림 받기",
                    "App\\Enums\\Permissions\\PartnerEnum": "파트너",
                    "App\\Enums\\Permissions\\PaymentEnum": "결제",
                    "App\\Enums\\Permissions\\PermissionEnum": "권한",
                    "App\\Enums\\Permissions\\PlanEnum": "구독 계획",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "관심 카테고리",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "가볼만한 곳",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "게시물 카테고리",
                    "App\\Enums\\Permissions\\PostEnum": "게시물",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "개인 정보 보호 정책",
                    "App\\Enums\\Permissions\\RegistrationEnum": "등록",
                    "App\\Enums\\Permissions\\ReportEnum": "보고서",
                    "App\\Enums\\Permissions\\ReservationEnum": "전세",
                    "App\\Enums\\Permissions\\RoleEnum": "역할",
                    "App\\Enums\\Permissions\\ShareEnum": "주식",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "스트라이프 계정",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "구독",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "지원 요청 범주",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "지원 메시지",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "지원 요청",
                    "App\\Enums\\Permissions\\TagEnum": "태그",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "이용약관",
                    "App\\Enums\\Permissions\\TranslatableEnum": "번역 가능한 레코드",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "여행자 이메일",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "여행자 폴더",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "사용자 자격 증명 확인",
                    "App\\Enums\\Permissions\\UserEnum": "사용자",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "사용자 지오록 설정",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "사용자 알림 설정",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "사용자 개인 정보 설정",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "사용자 관계"
                }
            },
            "plans": {
                "archive": {
                    "success": "구독 계획이 보관되었습니다(적격한 경우)."
                },
                "confirmation": {
                    "benefit_current_subscription": "현재 구독의 혜택을 계속 누릴 수 있습니다.\n            이미 지불한 달 말일까지.",
                    "engagement_period": "이 제안에는 다음의 참여 기간이 포함됩니다.\n            1차 결제 후 1배.",
                    "fee": "이 제안에는 {fee_percentage}%의 수수료가 적용되며 다음과 같은 혜택을 누릴 수 있습니다.\n            팁의 2%를 받으세요.",
                    "free": "이 구독은 무료이며 팁 수집 계정을 생성해야 합니다.",
                    "free_period": "1배의 무료 기간 혜택을 누리실 수 있습니다.",
                    "free_period_used": "이미 무료 기간을 모두 사용하셨으므로 더 이상 사용하실 수 없습니다.\n            이 제안에서 제안하는 {free_period} 혜택을 누릴 수 있습니다.",
                    "name": "귀하는 \"{name}\" 제안을 구독하려고 합니다.",
                    "name_launch": "귀하는 \"{name}\" 출시 제안을 구독하려고 합니다.",
                    "no_fee": "이 제안에는 0% 수수료가 적용되며 팁을 전액 받을 수 있습니다.",
                    "please_confirm": "아래 버튼을 클릭하여 구독을 확인해 주세요.",
                    "price_summary": "이 구독료는 귀하에게 부과됩니다\n            {price} 제외 세금 / {price_incl_taxes} 포함 매달 세금.",
                    "replace_current_subscription": "이 구독은 현재 구독을 자동으로 대체합니다."
                },
                "draft": {
                    "success": "구독 계획 초안이 작성되었습니다(자격이 있는 경우)."
                },
                "free_period_ended_in": "무료 기간 종료 {in}",
                "publish": {
                    "success": "구독 계획이 게시되었습니다(적격한 경우)."
                },
                "set_coming_soon": {
                    "success": "구독 계획이 \"곧 출시 예정\"으로 설정되었습니다(해당되는 경우)."
                },
                "your_current_plan": "현재 계획"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "이 확인란을 선택함으로써 본인은 다음 회사의 공식 대리인임을 확인합니다.\n            설립을 주장했다. 허위 진술이나 명의 도용 시 민사 및 형사 처벌을 받을 수 있습니다.\n            해당 법률에 따라 처벌을 받습니다.",
                    "success": "귀하의 청구가 확인을 위해 제출되었습니다."
                },
                "denied": {
                    "for_the_reason": "다음과 같은 이유로 거부됨",
                    "moderation_again": "관심 지점을 편집하면 조정을 위해 다시 제출되어 게시될 수 있습니다."
                },
                "deny": {
                    "success": "관심 지점이 거부되었습니다(자격이 있는 경우)."
                },
                "destroy": {
                    "confirm": "이 관심 장소 {point_of_interest_name}을(를) 삭제하시겠습니까?",
                    "success": "관심 장소가 삭제되었습니다."
                },
                "draft": {
                    "success": "관심 지점이 작성되었습니다(자격이 있는 경우)."
                },
                "duplicates": {
                    "check_explanation1": "당신의 관심 지점을 만들기 위해서는,\n            아래의 주요 입력란을 작성해 주세요.",
                    "check_explanation2": "방지하기 위해 점검이 수행됩니다.\n            이미 존재하는 지점을 입력합니다.",
                    "check_explanation3": "확인 후 완료하시면 됩니다.\n            포인트 정보를 입력하고 저장하세요.",
                    "check_explanation_title": "확인",
                    "continue_creation": "계속 생성",
                    "found_explanation1": "다음에 해당하는 하나 이상의 관심 지점\n            입력 기준이 이미 존재합니다.",
                    "found_explanation2": "중복이 아니라고 생각되신다면,\n            그런 다음 새로운 관심 지점을 입력할 수 있습니다.",
                    "match_percentage": " {percentage}%에 일치",
                    "select_duplicate": "제안된 관심 지점 중 하나를 클릭하면 세부정보를 볼 수 있습니다.",
                    "use_poi": "이 관심 장소를 이용하세요",
                    "view_poi": "이 관심 장소 보기"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "여행 일정에 이 관심 장소를 추가하세요.",
                    "already_in_excursions": "이 관심 장소는 이미 해당 여행 일정에 포함되어 있습니다.",
                    "create_excursion": "여행 일정 만들기",
                    "or_create_from": "또는 이 관심 지점에서 새로운 여행 일정을 만드세요.",
                    "select_which": "관심 지점 \"{point_of_interest_name}\"을 추가할 기존 여행 일정을 선택하세요.",
                    "success": "여행 일정에 관심 장소가 추가되었습니다"
                },
                "form": {
                    "help": {
                        "stars": "카테고리를 별표로 표시할 수 있는 경우에만(예: 호텔, 레스토랑...)"
                    }
                },
                "import_source": {
                    "DataTourisme": "데이터관광"
                },
                "index": {
                    "no_results": "관심 장소를 찾을 수 없습니다.",
                    "no_results_here": "검색 영역에 관심 지점이 없습니다.",
                    "no_tags": "정의된 태그가 없습니다.",
                    "refresh_delayed": "새로고침하기 전에 1번 기다려주세요",
                    "search": "관심 지점 검색",
                    "try_more_filters": "활성 필터를 변경하고 검색 범위를 넓힐 수 있습니다."
                },
                "pages": {
                    "show": {
                        "automatic_update": "자동 업데이트",
                        "belongs_to_you": "당신에게 속합니다",
                        "claim_dispute_pending": {
                            "details": "요청 내용: {message}",
                            "notice": "진행 중인 소유권 분쟁",
                            "title": "이 관심 지점에 대한 소유권 분쟁이 진행 중입니다."
                        },
                        "deletion": {
                            "causes": {
                                "event": "원인: 만료된 이벤트"
                            },
                            "title": "삭제 대기 중"
                        },
                        "dt_required_datatourisme_fields": " {import_source}에서 {dt_last_update}으로 {dt_created_by}로 업데이트됨",
                        "excursions_counter": "여행 일정에 1회 추가되었습니다",
                        "is_geocoding_1": "관심 지점의 위치 정보가 지정되었습니다.",
                        "is_geocoding_2": "관심 지점을 보려면 나중에 이 페이지를 확인하세요.\n                지도에서.",
                        "is_refreshing_audio_1": "오디오 파일을 생성하는 중입니다.",
                        "is_refreshing_audio_2": "이 오디오를 들으려면 나중에 이 페이지를 확인하세요.",
                        "not_current_locale": {
                            "change_locale": "위 선택기를 사용하여 표시 언어를 변경할 수 있습니다.",
                            "incomplete_translation": "이 관심 장소의 일부 정보(제목, 설명 및/또는 주소)는 선택한 언어로 제공되지 않습니다.",
                            "missing_translations": "번역 누락"
                        },
                        "opening_hours": {
                            "expired": "영업시간이 만료되었습니다",
                            "is_deleting": "관심 장소는 {days}일 후에 삭제됩니다.",
                            "show": "영업시간 보기"
                        },
                        "sections": {
                            "audios": "오디오",
                            "gallery": "갤러리",
                            "logo": "심벌 마크",
                            "main_image": "메인 이미지",
                            "map": "지도",
                            "media": "미디어",
                            "poi": "관심 장소",
                            "videos": "비디오"
                        },
                        "see_full_page": "전체 페이지 보기"
                    }
                },
                "print": {
                    "element_not_found": "\"{id}\"를 인쇄할 요소를 찾을 수 없습니다!"
                },
                "publish": {
                    "success": "관심 지점이 검증되고 게시되었습니다(자격이 있는 경우)."
                },
                "report": {
                    "choose_reason": "이 관심 장소 \"{point_of_interest_title}\"을(를) 신고하는 이유를 선택하세요.",
                    "heading": "관심 장소 신고",
                    "success": "관심 지점이 신고되었습니다!"
                },
                "search": {
                    "creator_id": "내 포인트만 보여주나요?",
                    "no_results": "관심 지점을 찾을 수 없습니다...",
                    "tags_mode": {
                        "all": "모두",
                        "all_help": "관심 지점을 찾으려면 \"모두\"를 선택하세요.\n                선택한 모든 태그와 함께.",
                        "one": "적어도 하나",
                        "one_help": "관심 지점을 찾으려면 \"최소 하나\"를 선택하세요.\n                선택한 태그 중 하나 이상을 포함합니다."
                    }
                },
                "share": {
                    "heading": "이 관심 장소를 공유하세요",
                    "select_which_contacts": "관심 지점 \"{point_of_interest_title}\"을 공유하고 싶은 연락처를 선택하세요.",
                    "select_which_users": "관심 지점 \"{point_of_interest_title}\"을 공유하고 싶은 사용자를 선택하거나 이메일 주소를 입력하여 새로운 여행자를 초대하세요",
                    "select_which_users_alt": "공유하고 싶은 사람의 이메일 주소를 입력하세요.\n            \"{point_of_interest_title}\" 관심 지점입니다.",
                    "success": "관심 지점이 공유되었습니다!"
                },
                "states": {
                    "draft": "초안",
                    "pending": "보류 중",
                    "published": "게시됨"
                },
                "store": {
                    "heading": "관심 장소 만들기",
                    "success": "관심 지점이 생성되었습니다."
                },
                "submit": {
                    "success": "관심 지점이 조정 보류 중입니다(적격한 경우)."
                },
                "update": {
                    "alert": "이 지점을 업데이트하면 {import_source} 가져오기에서 자동 업데이트가 비활성화됩니다.",
                    "confirm": "이 관심 장소 {point_of_interest_name}을(를) 업데이트하시겠습니까?",
                    "heading": "관심 장소 수정",
                    "success": "관심 장소가 업데이트되었습니다."
                }
            },
            "posts": {
                "archive": {
                    "success": "게시물이 보관되었습니다(적격한 경우)."
                },
                "draft": {
                    "success": "게시물 초안이 작성되었습니다(적격한 경우)."
                },
                "publish": {
                    "success": "게시물이 게시되었습니다(적격한 경우)."
                },
                "search": {
                    "type_to_search": "선택한 카테고리에서 검색..."
                }
            },
            "prices": {
                "excl_taxes": "제외 구실",
                "free": "무료",
                "from": "에서",
                "incl_taxes": "포함. 구실",
                "per_month": "달마다",
                "price_excl_taxes": "가격 제외 구실",
                "price_incl_taxes": "가격 포함 구실",
                "vat_amount": "부가가치세 금액",
                "vat_percentage": "부가가치세 비율"
            },
            "privacy_policies": {
                "archive": {
                    "success": "개인정보 보호정책이 보관되었습니다(적격한 경우)."
                },
                "errors": {
                    "not_found": "개인정보취급방침을 찾을 수 없습니다!"
                },
                "publish": {
                    "success": "개인 정보 보호 정책이 게시되었습니다"
                },
                "update": {
                    "acceptation": "계속하려면 개인정보 보호정책에 동의해야 합니다.",
                    "new_version": "개인정보취급방침 새 버전이 공개되었습니다.",
                    "success": "개인 정보 보호 정책이 승인되었습니다!"
                }
            },
            "reports": {
                "process": {
                    "success": "보고서가 처리되었습니다(해당되는 경우)."
                },
                "reject": {
                    "success": "보고서가 거부되었습니다(해당되는 경우)."
                }
            },
            "reservations": {
                "archive": {
                    "confirm": "정말로 이 예약을 보관하시겠습니까?",
                    "success": "예약이 보관되었습니다"
                },
                "cancel": {
                    "confirm": "정말로 이 예약을 취소하시겠습니까?",
                    "success": "예약이 취소되었습니다"
                },
                "confirm": {
                    "confirm": "이 예약을 정말로 확인하시겠습니까?",
                    "success": "예약이 확인되었습니다"
                },
                "destroy": {
                    "confirm": "정말로 이 예약을 삭제하시겠습니까?",
                    "success": "예약이 삭제되었습니다"
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "butler": "여행자",
                                "poi": "여행자",
                                "traveller": "기관"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "butler": "관리인",
                            "poi": "관심 장소",
                            "reservation": "예약",
                            "traveller": "여행자"
                        }
                    }
                },
                "states": {
                    "archived": "보관됨",
                    "canceled": "취소 된",
                    "confirmed": "확인됨",
                    "pending": "보류 중"
                },
                "store": {
                    "success": "예약이 생성되었습니다"
                },
                "update": {
                    "success": "예약이 업데이트되었습니다"
                }
            },
            "settings": {
                "items": {
                    "about": "에 대한",
                    "geoloc_settings": "지리적 위치 설정",
                    "notification_settings": "알림 설정",
                    "privacy_setting": "개인 정보 설정",
                    "profile": "프로필",
                    "signOut": "로그아웃"
                }
            },
            "shares": {
                "emails": {
                    "select_hint": "아래를 클릭하여 이전에 사용한 이메일을 선택할 수 있습니다.",
                    "space_hint": "여러 이메일을 공유하려면 공백으로 구분하세요."
                },
                "index": {
                    "no_results": "공유된 내용이 없습니다."
                },
                "link": {
                    "instructions": "여행자를 초대하고 여행 일정을 공유하려면 이 링크를 복사하세요."
                },
                "show": {
                    "shared_by": "공유자:"
                },
                "type": {
                    "excursions": "여행일정",
                    "pointsofinterest": "관심 장소"
                }
            },
            "states": {
                "changelog_state": {
                    "archived": "보관됨",
                    "draft": "초안",
                    "published": "게시됨"
                },
                "claim_dispute_state": {
                    "pending": "보류 중",
                    "rejected": "거부됨",
                    "validated": "검증됨"
                },
                "claim_state": {
                    "missing-info": "빠진 정보",
                    "pending": "보류 중",
                    "rejected": "거부됨",
                    "revoked": "취소됨",
                    "validated": "검증됨"
                },
                "payment_state": {
                    "canceled": "취소 된",
                    "completed": "완전한",
                    "failed": "실패한",
                    "pending": "보류 중",
                    "transferred": "전송됨"
                },
                "plan_state": {
                    "archived": "보관됨",
                    "coming_soon": "곧 출시 예정",
                    "draft": "초안",
                    "published": "게시됨"
                },
                "point_of_interest_state": {
                    "deleted": "삭제됨",
                    "denied": "거부됨",
                    "draft": "초안",
                    "pending": "검토 대기 중",
                    "published": "게시됨"
                },
                "post_state": {
                    "archived": "보관됨",
                    "draft": "초안",
                    "published": "게시됨"
                },
                "privacy_policy_state": {
                    "archived": "보관됨",
                    "draft": "초안",
                    "published": "게시됨"
                },
                "report_state": {
                    "pending": "보류 중",
                    "processed": "처리됨",
                    "rejected": "거부됨"
                },
                "reservation_state": {
                    "archived": "보관됨",
                    "canceled": "취소 된",
                    "confirmed": "확인됨",
                    "pending": "보류 중"
                },
                "subscription_state": {
                    "active": "활동적인",
                    "canceled": "취소 된",
                    "pending": "결제 대기 중",
                    "suspended": "정지된"
                },
                "support_request_state": {
                    "archived": "보관됨",
                    "canceled": "취소 된",
                    "pending-support": "지원 보류 중",
                    "pending-user": "대기 중인 사용자",
                    "resolved": "해결됨"
                },
                "terms_condition_state": {
                    "archived": "보관됨",
                    "draft": "초안",
                    "published": "게시됨"
                },
                "traveller_folder_state": {
                    "approved": "승인됨",
                    "archived": "보관됨",
                    "declined": "거절됨",
                    "pending": "보류 중"
                },
                "user_credential_verification_state": {
                    "canceled": "취소 된",
                    "completed": "완전한",
                    "pending": "보류 중"
                },
                "user_tutorial_state": {
                    "claimed": "청구됨",
                    "done": "완료",
                    "employee-added": "직원이 추가됨",
                    "pending": "보류 중"
                },
                "user_utm_campaign_state": {
                    "always_on": "항상 켜짐"
                },
                "user_utm_medium_state": {
                    "owned_social": "소셜 네트워크"
                },
                "user_utm_source_state": {
                    "facebook": "페이스북",
                    "instagram": "인스 타 그램",
                    "linkedin": "링크드인"
                },
                "users_relationship_state": {
                    "approved": "승인됨",
                    "blocked": "막힌",
                    "declined": "거절됨",
                    "pending": "보류 중"
                }
            },
            "stripe": {
                "connect": {
                    "account": {
                        "create": {
                            "completed": "스트라이프 계정 구성이 완료되었습니다.",
                            "link_invalid": "스트라이프 연결에 실패했습니다. 다시 시도해 주세요."
                        },
                        "must_subscribe": "Stripe 계정을 만들려면 구독해야 합니다.",
                        "show": {
                            "account_usage": "귀하의 Stripe 계정을 사용하면 귀하의 기관이 UgoZER에서 지불금을 받을 수 있습니다.",
                            "can_receive_payments": "지급을 받으실 수 있습니다.",
                            "cannot_receive_payments": "결제를 받을 수 없습니다.",
                            "fee_percentage": "귀하가 받는 각 결제에 {percentage}%의 수수료가 적용됩니다.",
                            "go_to_stripe": "Stripe 대시보드로 이동",
                            "has_stripe_account_being_reviewed": "귀하의 계정은 Stripe에서 검토 중입니다.",
                            "no_account_yet": "아직 Stripe 계정이 없습니다.",
                            "should_complete_stripe_onboarding": "Stripe 등록을 완료하고 요청된 정보를 제공하십시오."
                        }
                    }
                },
                "stripe": "줄무늬"
            },
            "subscriptions": {
                "activated_at": "활성화",
                "cancel": "취소",
                "cancelation_success": "취소가 고려됨",
                "canceled_at": "취소",
                "confirm_cancel": "정말로 구독을 취소하시겠습니까?",
                "confirm_cancel_date": " {date}의 취소 유효일까지 혜택을 누릴 수 있습니다.",
                "confirm_cancel_now": "취소는 즉시 적용됩니다.",
                "expiry": "만료",
                "invoices": "송장",
                "my_current_subscription": "내 현재 구독",
                "price_excl_taxes": "가격 제외 구실",
                "price_incl_taxes": "가격 포함 구실",
                "upcoming_subscription": "다가오는 구독"
            },
            "support_messages": {
                "show": {
                    "you": "너",
                    "your_question": "귀하의 질문"
                },
                "store": {
                    "heading": "메시지 추가",
                    "success": {
                        "title": "메시지가 생성되었습니다. 지원팀에 문의하면 48시간 이내에 답변을 드립니다."
                    }
                }
            },
            "support_requests": {
                "actions": {
                    "archived": {
                        "success": "요청이 보관되었습니다."
                    },
                    "canceled": {
                        "success": "요청이 취소되었습니다."
                    },
                    "pending_support": {
                        "success": "지원팀의 답변을 기다리는 중"
                    },
                    "pending_user": {
                        "success": "사용자의 답변을 기다리는 중"
                    },
                    "resolved": {
                        "success": "요청이 해결되었습니다."
                    }
                },
                "cancel": {
                    "confirm": "이 지원 요청을 취소하시겠습니까? {support_request_title}?",
                    "error": "지원 요청을 취소할 수 없습니다.",
                    "success": "지원 요청이 취소되었습니다"
                },
                "index": {
                    "no_results": "지원 요청이 없습니다.",
                    "search": "지원 요청 검색",
                    "your_requests": "귀하의 지원 요청"
                },
                "pages": {
                    "create": {
                        "notice": "개인 정보"
                    },
                    "index": {
                        "search": "지원 요청 검색"
                    },
                    "show": {
                        "sections": {
                            "messages": "메시지",
                            "request": "요구"
                        }
                    }
                },
                "show": {
                    "add_request": "새 지원 요청 추가"
                },
                "states": {
                    "archived": "보관됨",
                    "canceled": "취소 된",
                    "pending-support": "지원 보류 중",
                    "pending-user": "대기 중인 사용자",
                    "resolved": "해결됨"
                },
                "store": {
                    "heading": "새 지원 요청 만들기",
                    "success": {
                        "title": "요청이 생성되었습니다. 지원팀에 문의하면 48시간 이내에 답변을 드립니다."
                    }
                }
            },
            "terms_conditions": {
                "archive": {
                    "success": "이용약관이 보관되었습니다(적격한 경우)."
                },
                "errors": {
                    "not_found": "이용약관을 찾을 수 없습니다!"
                },
                "publish": {
                    "success": "이용약관이 게시되었습니다."
                },
                "update": {
                    "acceptation": "계속하려면 이용약관에 동의해야 합니다.",
                    "new_version": "새 버전의 이용약관이 게시되었습니다.",
                    "success": "이용약관이 승인되었습니다!"
                }
            },
            "traveller_folders": {
                "messages": {
                    "approve": {
                        "confirm": "이 숙박을 정말로 확인하시겠습니까?",
                        "success": "확인하세요!"
                    },
                    "archive": {
                        "confirm": "정말로 이 숙박을 보관하시겠습니까?",
                        "success": "이 숙박은 보관되었습니다!"
                    },
                    "decline": {
                        "confirm": "정말로 이번 숙박을 거절하시겠습니까?",
                        "success": "숙박을 거부했습니다!"
                    }
                },
                "no_results": {
                    "approved": "승인된 숙박 없음",
                    "archived": "보관된 숙박 없음",
                    "declined": "거부된 숙박 없음",
                    "pending": "숙박 요청 없음"
                },
                "pages": {
                    "form": {
                        "cannot_attach": {
                            "description": "이 사용자는 존재하지만 지원될 수 없습니다...",
                            "title": "지원 불가"
                        },
                        "poi_empty_option": "관심 장소 삭제",
                        "poi_label": "컨시어지 기관",
                        "verification": {
                            "description": "여행자의 이메일 주소를 입력하세요. 여행자 계정이 이미 존재하는 경우\n                    직접 연결되어야 합니다. 그렇지 않으면 생성을 위해 몇 가지 추가 정보를 입력해야 합니다.\n                    새로운.",
                            "title": "확인"
                        }
                    },
                    "index": {
                        "pending_stays": "보류 중인 숙박",
                        "search": "내 여행자 중에서 검색",
                        "travellers_folders": "여행자 폴더",
                        "your_stays": "귀하의 숙박"
                    },
                    "qr_code_1": "여행자는 이 QR 코드를 스캔하여 자동으로 여행자 폴더를 얻을 수 있습니다.\n            귀하의 기관과 함께.",
                    "qr_code_2": "여행자가 아직 UgoZER에 등록하지 않은 경우 등록 페이지로 리디렉션됩니다.\n            페이지이며 등록이 끝나면 귀하의 기관에 연결됩니다.",
                    "show": {
                        "fields": {
                            "state": "상태:"
                        },
                        "sections": {
                            "butler": "관련 컨시어지",
                            "folder": "폴더",
                            "hotel": "호텔",
                            "institution": "관련 기관",
                            "interactions": "상호작용",
                            "invitation": "여행자 초대",
                            "payments": "결제",
                            "reservations": "전세",
                            "stay": "머무르다",
                            "thanks": "감사합니다",
                            "traveller": "여행자"
                        }
                    },
                    "wizard": {
                        "choose_an_institution": "기관을 선택하세요",
                        "no_linked_institution": "여행자를 픽업하기 전에 반드시 기관에 연결되어 있어야 합니다."
                    }
                },
                "placeholders": {
                    "butler": "리셉션 팀"
                },
                "states": {
                    "approved": "수락됨",
                    "archived": "보관됨",
                    "declined": "거절됨",
                    "pending": "보류 중",
                    "undefined": "한정되지 않은"
                },
                "store": {
                    "success": "폴더가 생성되었습니다"
                },
                "tabs": {
                    "approved": "승인됨",
                    "archived": "보관됨",
                    "declined": "거절됨",
                    "pending": "보류 중"
                },
                "thank": {
                    "success": "감사 인사가 전송되었습니다"
                },
                "update": {
                    "success": "폴더가 업데이트되었습니다."
                }
            },
            "tutorials": {
                "extranet": {
                    "begin": {
                        "butler": {
                            "description": "귀하가 UgoZER 애플리케이션을 최상의 조건에서 사용할 수 있도록 하기 위해,\n                    첫 번째 단계를 안내하는 다음 단계를 따르는 것이 좋습니다.\n                    컨시어지 공간의 처리 및 매개변수 설정.",
                            "steps": {
                                "institution": {
                                    "content": "검색창을 이용해 시설 목록에서 호텔을 검색하고\n                            페이지 왼쪽 섹션에서 사용할 수 있는 필터입니다. 일단 당신이 당신의\n                            <strong>가입 신청</strong>을 클릭하여 신청서를 작성하세요.\n                            단추.",
                                    "description": "귀하의 기관에 연결",
                                    "step_next": "주장하다",
                                    "step_state": "보류 중",
                                    "subtitle": {
                                        "link": "내 기관",
                                        "part_1": "내 호텔에 연결해줘",
                                        "part_2": "페이지에 직접 호텔 링크를 걸 수 있습니다"
                                    },
                                    "title": "기관"
                                }
                            }
                        },
                        "done": {
                            "description": "이제 UgoZER 애플리케이션 설정을 위한 다양한 단계를 완료했습니다.",
                            "faq": {
                                "link": "자주하는 질문",
                                "part_1": "추가 도움이 필요하면 다음에서 기사를 찾을 수 있습니다.",
                                "part_2": "신청서 섹션"
                            },
                            "help": "일부 페이지에 있는 <strong>도움말</strong>을 통해 상황별 도움말을 찾을 수도 있습니다.",
                            "title": "축하해요!"
                        },
                        "hotelier": {
                            "description": "귀하가 UgoZER 애플리케이션을 최상의 조건에서 사용할 수 있도록 하기 위해,\n                    첫 번째 단계를 안내하는 다음 단계를 따르는 것이 좋습니다.\n                    호텔 매니저 공간의 처리 및 매개변수 설정.",
                            "steps": {
                                "employees": {
                                    "description": "컨시어지 관리",
                                    "step_next": "직원 추가",
                                    "step_state": "주장하다",
                                    "title": "컨시어지"
                                }
                            }
                        },
                        "manager": {
                            "description": "귀하가 UgoZER 애플리케이션을 최상의 조건에서 사용할 수 있도록 하기 위해,\n                    첫 번째 단계를 안내하는 다음 단계를 따르는 것이 좋습니다.\n                    기타 비즈니스 관리자 공간의 처리 및 매개변수 설정."
                        },
                        "messages": {
                            "claim": "귀하의 주장이 확인되었습니다.",
                            "contest": "귀하의 분쟁이 당사 팀으로 전달되었습니다.",
                            "payment": "귀하의 은행 정보가 성공적으로 기록되었습니다.",
                            "point_of_interest": "관심 지점이 생성되었습니다. 이제 해당 지점을 요청할 수 있습니다."
                        },
                        "no_tutorial": {
                            "content": "이 역할에 대한 튜토리얼이 설정되지 않았거나 관리자로 연결되어 있습니다.",
                            "title": "사용 가능한 튜토리얼이 없습니다."
                        },
                        "notices": {
                            "add_butler": {
                                "content": "애플리케이션을 사용하려면 시설에 대한 컨시어지 계정을 만들어야 합니다.\n                        그 정보가 여행자에게 전송됩니다.",
                                "content_2": "컨시어지 계정을 활성화하려면 컨시어지가 이메일을 확인해야 합니다.\n                        주소를 지정하고 비밀번호를 만듭니다.",
                                "title": "컨시어지 추가"
                            },
                            "add_employee": {
                                "content": "애플리케이션을 사용하려면 해당 시설에 대한 직원 계정을 만들어야 합니다.\n                        그 정보가 여행자에게 전송됩니다.",
                                "content_2": "직원 계정을 활성화하려면 직원이 이메일을 확인해야 합니다.\n                        주소를 지정하고 비밀번호를 만듭니다.",
                                "title": "직원 추가"
                            }
                        },
                        "shared": {
                            "actions": {
                                "skip_step": "단계 건너뛰기",
                                "skip_tutorial": "튜토리얼 건너뛰기"
                            },
                            "claims": {
                                "description": "기관에 대한 소유권 주장",
                                "documents": {
                                    "association": {
                                        "identity_piece": "과정에서 법정대리인의 신원을 증명하는 서류\n                                    유효한",
                                        "statutes": "협회 정관",
                                        "title": "협회인 경우"
                                    },
                                    "company": {
                                        "identity_piece": "과정에서 법정대리인의 신원을 증명하는 서류\n                                    유효한",
                                        "kbis": "KBIS 추출물",
                                        "title": "당신이 회사라면"
                                    },
                                    "description": "기관을 신청하려면 다음을 준비해야 합니다.\n                                서류:",
                                    "individual": {
                                        "identity_piece": "유효한 신분증",
                                        "registration_document": "자영업 등록 증명서",
                                        "title": "자영업자인 경우"
                                    },
                                    "label": "서류",
                                    "title": "청구 서류"
                                },
                                "notice": {
                                    "description": "<strong>도움말</strong>을 클릭하면 이 설명을 확인할 수 있습니다.\n                                관심지점 목록 오른쪽 상단의 버튼을 클릭하세요.",
                                    "title": "참고하세요"
                                },
                                "step_next": "주장하다",
                                "step_state": "보류 중",
                                "title": "주장하다"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "이 단계를 건너뛰시겠습니까?",
                                    "success": "그 단계는 잘 무시되었습니다!"
                                },
                                "skip_tutorial": {
                                    "confirm": "이 튜토리얼을 건너뛰시겠습니까?",
                                    "success": "튜토리얼이 잘 무시되었습니다!"
                                }
                            },
                            "payments": {
                                "description": "팁 받기",
                                "step_next": "완료",
                                "step_state": "직원 추가",
                                "steps": {
                                    "bank": {
                                        "create": "다음에서 <strong>계정 추가</strong> 버튼을 클릭하세요.\n                                    <strong>은행 계좌</strong> 섹션"
                                    },
                                    "easytransac": {
                                        "connection": "링크를 클릭하세요\n                                    <strong>EasyTransac 대시보드로 이동</strong>하고 사용하려는 자격 증명으로 로그인하세요.\n                                        이메일로 받은",
                                        "create": "<strong>만들기</strong> 버튼을 클릭하세요.\n                                    <strong>EasyTransac</strong> 섹션",
                                        "created": "입력된 데이터를 기반으로 계정이 자동으로 생성됩니다.\n                                    프로필에",
                                        "form": "계정 결제 계정을 활성화하려면 EasyTransac에서 양식을 작성하세요.",
                                        "warning": {
                                            "description": "EasyTransac 계정의 KYC 수준에 따라 다음이 가능합니다.\n                                        대금을 받을 수는 있지만 일반 은행으로 자금을 이체할 수는 없습니다.\n                                        계정. 일반 은행계좌로 자금을 입금합니다. 이를 위해서는 KYC 레벨이 필요합니다.\n                                        2 EasyTransac 인터페이스에서 특정 문서를 제공합니다. 이지트랜잭\n                                        상호 작용.",
                                            "title": "KYC 수준"
                                        }
                                    },
                                    "my_profile": {
                                        "part_1": "페이지로 이동",
                                        "part_2": "내 프로필"
                                    }
                                },
                                "subtitle_1": "컨시어지가 팁을 받으려면 계정을 만들어야 합니다.\n                            파트너 EasyTransac 웹사이트에서.",
                                "subtitle_2": "이것을하기 위해:",
                                "title": "지불"
                            }
                        },
                        "tourist_office": {
                            "description": "귀하가 UgoZER 애플리케이션을 최상의 조건에서 사용할 수 있도록 하기 위해,\n                    첫 번째 단계를 안내하는 다음 단계를 따르는 것이 좋습니다.\n                    관광 사무실 공간의 처리 및 매개변수 설정.",
                            "steps": {
                                "employees": {
                                    "description": "직원 관리",
                                    "step_next": "직원 추가",
                                    "step_state": "주장하다",
                                    "title": "직원"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "귀하가 UgoZER 애플리케이션을 최상의 조건에서 사용할 수 있도록 하기 위해,\n                    첫 번째 단계를 안내하는 다음 단계를 따르는 것이 좋습니다.\n                    관광 사무소 직원 공간의 처리 및 매개변수 설정.",
                            "steps": {
                                "institution": {
                                    "content": "검색창을 이용해 시설 목록에서 관광 안내소를 검색하세요.\n                            페이지 왼쪽 섹션에서 사용할 수 있는 필터. 일단 당신이 당신의\n                            <strong>가입 신청</strong>을 클릭하여 신청서를 작성하세요.\n                            단추.",
                                    "description": "귀하의 기관에 연결",
                                    "step_next": "주장하다",
                                    "step_state": "보류 중",
                                    "subtitle": {
                                        "link": "내 기관",
                                        "part_1": "관광 안내소에 연결해 주세요",
                                        "part_2": "관광 안내소를 해당 페이지로 직접 연결할 수 있습니다."
                                    },
                                    "title": "기관"
                                }
                            }
                        }
                    },
                    "dashboard": {
                        "claim": {
                            "steps": {
                                "claim_form": "그런 다음 청구 양식의 지침을 따르십시오.",
                                "claim_validation": "그런 다음 해당 주장은 당사 팀에 의해 확인 및 검증됩니다.",
                                "dont_exist": "존재하지 않는 경우 <strong>관심 장소 만들기 버튼을 클릭하세요.\n                        </strong> 그런 다음 양식을 작성하세요. 관심 지점이 생성되면 해당 지점으로 이동합니다.\n                        카드를 선택하고 <strong>청구</strong> 버튼을 클릭하세요.",
                                "exist": "귀하의 기관이 존재하는 경우 해당 파일로 이동하여 <strong> 버튼을 클릭하세요.\n                        소유권 주장</strong>",
                                "filters": "필터와 검색을 사용하여 교육기관을 찾으세요.",
                                "poi_index": {
                                    "part_1": "로 이동",
                                    "part_2": "관심 장소 목록"
                                }
                            },
                            "subtitle_1": "하나 이상의 시설을 관리하기 위해 소유권을 주장할 수 있습니다.",
                            "subtitle_2": "그렇게 하려면:",
                            "title": "내 기관의 소유권 및 관리권 주장"
                        },
                        "employees": {
                            "link": "요청을 작성하려면 <strong>내 기관</strong> 페이지로 이동하세요.\n                    공간을 확보하고 양식을 작성하세요.",
                            "manage": "이 페이지에서 귀하와 연결하려는 직원을 수락하거나 거부할 수 있습니다.\n                    기관.",
                            "subtitle": {
                                "part_1": "페이지를 통해 직원을 직접 관리할 수 있습니다.",
                                "part_2": "내 직원"
                            },
                            "title": "내 직원 관리"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "distance": "<strong>장소:</strong> 특정 위치의 지점을 필터링할 수 있습니다.",
                            "main_title": "필터",
                            "subtitle": "다음 기준에 따라 여행 일정 목록을 필터링할 수 있습니다.",
                            "title": "여행 일정 필터링",
                            "type": {
                                "customized": "<strong>맞춤형:</strong> 여행자를 위해 특별히 제작된 여행 일정",
                                "explanation": "<strong>유형:</strong> 여행 일정 유형별로 필터링할 수 있습니다.",
                                "pro": "<strong>UgoZER Pro:</strong> UgoZER에서 제공하는 여행 일정",
                                "root": "<strong>내 템플릿:</strong> 모델 일정"
                            }
                        }
                    },
                    "institution_employee": {
                        "approve": {
                            "content": "이 작업을 통해 직원 중 한 명이 다음 중 하나에 가입하라는 요청을 수락할 수 있습니다.\n                    당신의 기관.",
                            "main_title": "승인하다",
                            "title": "직원 승인"
                        },
                        "cancel": {
                            "content": "이 작업을 통해 다음과 같은 경우 직원 초대 요청을 취소할 수 있습니다.\n                    예를 들어 오류가 발생한 경우입니다.",
                            "main_title": "취소",
                            "title": "직원의 지원 취소"
                        },
                        "decline": {
                            "content": "이 조치를 통해 직원 중 한 명이 회사에 가입하려는 요청을 거부할 수 있습니다.\n                    기관.",
                            "main_title": "거절하다",
                            "title": "직원을 거부하다"
                        },
                        "functioning": {
                            "content": {
                                "invite": "이 페이지에서 직원을 초대하거나 직원의 요청을 수락/거부할 수 있습니다.\n                        직원.",
                                "section": "이 섹션에서는 기관의 직원을 관리할 수 있습니다."
                            },
                            "main_title": "작동",
                            "title": "직원 섹션의 기능"
                        },
                        "invite": {
                            "content": {
                                "button": "<strong>직원 초대</strong> 버튼을 클릭하면 다음 중 한 명을 초대할 수 있습니다.\n                        직원이 이미 UgoZER 계정을 가지고 있는지 여부.",
                                "form": "이렇게 하려면 나타나는 단계별 양식의 지침을 따르십시오.\n                        나타나는 형태입니다."
                            },
                            "main_title": "초대하다",
                            "title": "직원 초대"
                        },
                        "remove": {
                            "content": "이 작업을 통해 다음과 같은 경우 직원에게서 사용자를 제거할 수 있습니다.\n                    직원이 회사를 떠난다. 직원이 회사를 떠나는 경우.",
                            "main_title": "제거하다",
                            "title": "직원 제거"
                        }
                    },
                    "points_of_interest": {
                        "claim": "주장하다",
                        "filters": {
                            "category": "<strong>카테고리:</strong> 특정 카테고리를 선택할 수 있습니다.\n                    (호텔, 레스토랑...)",
                            "city": "<strong>도시:</strong>를 사용하면 특정 도시를 필터링할 수 있습니다.",
                            "country": "<strong>국가:</strong> 하나 이상의 특정 국가를 필터링할 수 있습니다.",
                            "distance": "<strong>장소:</strong> 특정 위치의 지점을 필터링할 수 있습니다.",
                            "main_title": "필터",
                            "measure_unit": "<strong>측정 단위:</strong> 사용되는 측정 단위를 변경할 수 있습니다.\n                    거리 필터 및 디스플레이",
                            "my_points": "<strong>내 포인트만 표시:</strong> 내가 만든 포인트만 표시하거나\n                    귀하가 소유권을 주장했습니다.",
                            "postal_code": "<strong>우편번호:</strong> 우편번호로 필터링할 수 있습니다.",
                            "radius": "<strong>반경으로 제한:</strong> 특정 원 안의 점을 필터링할 수 있습니다.\n                    반지름",
                            "subtitle": "다음 기준에 따라 관심 장소 목록을 필터링할 수 있습니다.",
                            "tags": "<strong>구체화:</strong> 카테고리를 지정하기 위해 태그별로 검색을 세분화할 수 있습니다.\n                    하나 또는 전체 또는 모두를 사용하여 포인트를 검색할지 여부를 정의하는 것도 가능합니다.\n                    선택한 태그",
                            "title": "관심 장소 필터링"
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "validate": {
                    "success": "자격 증명이 성공적으로 검증되었습니다(자격이 있는 경우)."
                },
                "verify": {
                    "already_verified": "귀하의 자격 증명은 이미 확인되었습니다",
                    "expired": "링크가 만료되었습니다. 새 링크를 요청하세요.",
                    "impossible": "이 자격증명을 더 이상 확인할 수 없습니다.",
                    "not_received": "확인 링크를 받지 못하셨나요?",
                    "sent": {
                        "again": "다시 보내기",
                        "correct": "선택한 자격 증명을 수정하세요.",
                        "email_1": "이 주소가 아직 사용되지 않은 경우 {email}로 이메일이 전송됩니다.",
                        "email_2": "이 이메일에는 귀하의 주소를 확인할 수 있는 링크가 포함되어 있습니다.",
                        "email_3": "링크에 액세스하는 즉시 귀하의 이메일이 업데이트됩니다.",
                        "refresh": "링크를 클릭하셨나요? 애플리케이션 새로 고침",
                        "title": "링크가 전송되었습니다",
                        "wait_before": "다시 보내기 전에 1초 정도 기다려 주십시오."
                    },
                    "success": "귀하의 자격 증명이 성공적으로 확인되었습니다"
                }
            },
            "user_geoloc_settings": {
                "geolocation": {
                    "error": {
                        "allow_browser": "브라우저가 휴대폰 설정에서 위치 정보를 찾을 수 있도록 허용하세요.",
                        "allow_site": "브라우저 설정에서 UgoZER가 귀하의 위치 정보를 찾을 수 있도록 허용하세요",
                        "intro": "주변의 관심 지점을 보려면:",
                        "title": "위치정보 실패"
                    },
                    "intro": "지리적 위치를 사용하면 가장 가까운 관심 지점을 찾을 수 있습니다."
                },
                "not_configured": {
                    "body1": "원하는 위치를 선택하면 근처의 관심 지점을 볼 수 있습니다.",
                    "body2": "가까운 관심 지점만 표시하는 것도 가능합니다.",
                    "do_not_ask_again": "애플리케이션이 다시 시작될 때까지 다시 묻지 마세요.",
                    "subject": "아직 지리적 위치 설정을 정의하지 않았습니다."
                },
                "update": {
                    "success": "위치정보 설정이 업데이트되었습니다."
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "알림 설정이 업데이트되었습니다."
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "개인정보 보호 설정이 업데이트되었습니다."
                }
            },
            "users": {
                "butlers": "컨시어지",
                "finish": {
                    "success": "튜토리얼이 완료 상태로 변경되었습니다."
                },
                "index": {
                    "hint": "사용자는 닉네임이나 공개 ID로 찾을 수 있습니다.",
                    "no_results": "사용자를 찾을 수 없습니다.",
                    "search": "사용자 검색"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "delete": "계정을 삭제하다?",
                            "profile": "내 프로필"
                        }
                    }
                },
                "profile": {
                    "alerts": {
                        "blocked": {
                            "message": "더 이상 이 사용자와 상호작용할 수 없습니다.",
                            "title": "이 사용자가 당신을 차단했습니다..."
                        },
                        "missing_infos": {
                            "message": "애플리케이션의 모든 기능을 활용하려면 다음 정보를 작성해 주십시오.",
                            "title": "프로필을 작성하세요"
                        },
                        "pending_validation": {
                            "message": "이 사용자는 아직 귀하의 요구를 수락하지 않았습니다.",
                            "title": "검증 보류 중"
                        }
                    },
                    "avatar": {
                        "defaults": {
                            "female": "여성",
                            "male": "남성"
                        },
                        "section": {
                            "cta": "이 아바타를 사용하세요",
                            "description": "아래 목록에서 미리 정의된 아바타를 선택할 수 있습니다.",
                            "title": "미리 정의된 아바타 선택"
                        },
                        "success": "귀하의 아바타가 성공적으로 업데이트되었습니다!"
                    },
                    "bank_account_number_missing": "지급금을 받으려면 은행 정보를 제공해 주세요.",
                    "delete_account": {
                        "easy_transac": "귀하의 EasyTransac 계정은 삭제되지 않습니다.",
                        "instructions": "계정의 최종 삭제를 확인하려면 아래 필드에 \"DELETE\"라는 단어를 입력하고 \"확인\" 버튼을 클릭하십시오.",
                        "success": "귀하의 계정이 삭제되었습니다.",
                        "title": "계정 삭제",
                        "warning": "귀하의 계정 및 귀하의 계정과 관련된 모든 정보는 복구 가능성 없이 즉시 삭제됩니다."
                    },
                    "edit_email": "이메일 수정",
                    "edit_password": "비밀번호를 편집하세요",
                    "edit_phone": "전화번호 수정",
                    "edit_your_profile": "프로필 수정",
                    "email_missing": "확인된 이메일을 작성하지 않으셨습니다.",
                    "fill_country": "국가를 지정하세요.",
                    "form": {
                        "poi_label": "연계기관"
                    },
                    "manage_my_subscription": "내 구독 관리",
                    "password_missing": "비밀번호를 입력하지 않았습니다.",
                    "phone_missing": "인증된 전화번호를 입력하지 않으셨습니다.",
                    "preferences": "환경설정",
                    "public_id": "또는 다음 식별자를 사용하십시오.",
                    "qr_code": "내 프로필을 보려면 나를 스캔하세요.",
                    "security": "보안",
                    "share_profile": "프로필을 공유하세요",
                    "subscribe_to_receive_payments": "결제를 받으려면 구독해야 합니다.",
                    "unnamed": "이름 없음",
                    "your_personal_information": "귀하의 개인 정보"
                },
                "report": {
                    "choose_reason": "\"{user_name}\" 사용자를 신고하는 이유를 선택하세요.",
                    "heading": "사용자 신고",
                    "success": "사용자가 신고되었습니다!"
                },
                "search": {
                    "no_results": "사용자를 찾을 수 없습니다."
                },
                "send_link": {
                    "error": "링크를 보낼 수 없습니다. 사용자에게 이메일이 있습니까?",
                    "success": "신청 링크가 전송되었습니다"
                },
                "update": {
                    "success": "프로필이 업데이트되었습니다."
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "연락처를 찾을 수 없습니다.",
                    "pending_requests": "대기 중인 연락처"
                },
                "messages": {
                    "add": {
                        "confirm": "정말로 이 연락처를 추가하시겠습니까?",
                        "error": "연락처 요청 중 오류가 발생했습니다...",
                        "success": "연락요청이 성공적으로 완료되었습니다!"
                    },
                    "approve": {
                        "confirm": "정말로 이 연락 요청을 수락하시겠습니까?",
                        "success": "연락요청이 접수되었습니다!"
                    },
                    "block": {
                        "confirm": "정말로 이 사용자를 차단하시겠습니까?",
                        "success": "이 연락처는 차단되었습니다!"
                    },
                    "decline": {
                        "confirm": "정말로 이 연락 요청을 거절하시겠습니까?",
                        "success": "연락 요청이 거부되었습니다!"
                    },
                    "unblock": {
                        "confirm": "정말로 이 사용자의 차단을 해제하시겠습니까?",
                        "success": "이 연락처는 차단 해제되었습니다!"
                    }
                },
                "no_results": {
                    "approved": "승인된 연락처 없음",
                    "blocked": "차단된 연락처 없음",
                    "pending": "대기 중인 연락처 요청이 없습니다.",
                    "sent": "전송된 연락처 요청이 없습니다."
                },
                "search": {
                    "no_results": "연락처를 찾을 수 없습니다."
                },
                "tabs": {
                    "approved": "승인됨",
                    "blocked": "막힌",
                    "pending": "보류 중",
                    "sent": "전송된"
                }
            }
        },
        "validation": {
            "accepted": " {attribute}이 허용되어야 합니다.",
            "active_url": " {attribute}은 유효한 URL이 아닙니다.",
            "after": " {attribute}은 {date} 이후의 날짜여야 합니다.",
            "after_or_equal": " {attribute}은 {date} 이후의 날짜여야 합니다.",
            "alpha": " {attribute}에는 문자만 포함될 수 있습니다.",
            "alpha_dash": " {attribute}에는 문자, 숫자, 대시 및 밑줄만 포함될 수 있습니다.",
            "alpha_num": " {attribute}에는 문자와 숫자만 포함될 수 있습니다.",
            "array": " {attribute}은 배열이어야 합니다.",
            "attributes": {
                "accounting_file": "회계 파일",
                "actions": "행위",
                "activated_at": "활성화됨",
                "active": "활동적인",
                "additional_information": "추가 정보",
                "address": "주소",
                "addresses": "구애",
                "admin_message": "메시지",
                "administrative_area": "행정 구역",
                "amount": "양",
                "app": "애플리케이션",
                "app_fees": "수수료",
                "approved_at": "승인된 시간",
                "archived_at": "보관된 위치",
                "association_legal_representative_identity_piece": "법정대리인의 신원증명서",
                "association_statutes": "법령",
                "attached_files": "첨부 파일",
                "audio": "오디오",
                "audio_refreshing": "오디오 새로 고침",
                "audios": "오디오",
                "authenticated_once": "한 번 인증됨",
                "auto_renewal_enabled": "자동 갱신 활성화됨",
                "avatar": "화신",
                "bank_account": "은행 계좌",
                "bank_account_number": "은행 계좌 번호",
                "bank_bic": "빅",
                "bank_city": "은행 도시",
                "bank_country": "은행 국가",
                "bank_file": "은행 파일",
                "bank_iban": "이반",
                "bank_name": "은행 이름",
                "bank_payee": "수취인",
                "bank_transfer": "은행 송금",
                "bank_transfer_institutions": "은행 송금 기관",
                "bank_transfer_reason": "이전 이유",
                "butler": "관리인",
                "butler_point_of_interest": "컨시어지 기관",
                "canceled_at": "취소 시간",
                "categories": "카테고리",
                "category": "범주",
                "category_id": "카테고리 ID",
                "causer": "원인",
                "causer_id": "원인 ID",
                "causer_type": "원인 유형",
                "certified": "인증됨",
                "city": "도시",
                "claim_confirmation": "청구 확인",
                "code": "암호",
                "collection_name": "컬렉션 이름",
                "communication_consent": "개인화된 커뮤니케이션",
                "communication_consent_alt": "연락?",
                "company_legal_representative_identity_piece": "법정대리인의 신원증명서",
                "company_registration_document": "등록 서류",
                "company_statutes": "법령",
                "company_type": "회사 유형",
                "completed_at": "에 완료됨",
                "contact": "연락하다",
                "contact_email": "이메일 연락처",
                "contact_phone": "연락 전화 번호",
                "content": "콘텐츠",
                "contestant": "경기자",
                "contestant_company_type": "회사 유형",
                "contestant_email": "이메일",
                "contestant_first_name": "이름",
                "contestant_last_name": "성",
                "contestant_message": "메시지",
                "contestant_phone": "핸드폰",
                "conversions_disk": "변환 디스크",
                "coords": "좌표",
                "countries": "국가",
                "countries_codes": "국가",
                "country": "국가",
                "country_code": "국가 코드",
                "created": "만들어진",
                "created_at": "생성된 시간",
                "creator": "창조자",
                "credential": "신임장",
                "currency": "통화",
                "current_password": "현재 비밀번호",
                "custom_amount": "맞춤 금액",
                "custom_properties": "사용자 정의 속성",
                "customers_qty": "고객 수량",
                "date": "날짜",
                "dates": "날짜",
                "days": "날",
                "declined_at": "거절하다",
                "deleted_at": "삭제된 시간",
                "deletion_confirmation": "삭제 확인",
                "denial_reason": "거부 이유",
                "dependent_locality": "종속 지역",
                "description": "설명",
                "details": "세부",
                "disk": "디스크",
                "display": "표시하다",
                "displayed_name": "표시 이름",
                "distance": "거리",
                "documents": "서류",
                "dt_created_by": "작성자:",
                "dt_last_update": "마지막 DataTourisme 업데이트",
                "dynamic_translations_enabled": "동적 번역 사용",
                "email": "이메일",
                "emails_must_different": "두 계정 모두 동일한 이메일 주소를 가질 수 없습니다.",
                "employee": "직원",
                "end_date": "종료 날짜",
                "engaged_until": "까지 약혼",
                "engagement_period": "약혼 기간",
                "event": "이벤트",
                "excursion": "여정",
                "existing_user": "기존 사용자",
                "expirable": "만료 가능",
                "expired_at": "에 만료됨",
                "expired_on": "체류 종료일",
                "expiry": "만료",
                "expiry_date": "만료일",
                "extranet": "엑스트라넷",
                "fallback_locale": "대체 언어",
                "favorite": "좋아하는 여행 일정",
                "featured": "특집",
                "file": "파일",
                "file_name": "파일 이름",
                "filter": "필터",
                "financial": "재정적인",
                "first_name": "이름",
                "formatted_address": "형식화된 주소",
                "free_period": "무료 기간",
                "free_period_ended_at": "무료 기간이 종료되었습니다",
                "front": "앞쪽",
                "gallery": "갤러리",
                "gdpr": "GDPR",
                "general": "일반적인",
                "generated_conversions": "생성된 전환",
                "gross_amount": "총액",
                "has_employees": "직원이 있다",
                "hour": "시간",
                "hours": "시간",
                "id": "ID",
                "image": "영상",
                "images": "이미지",
                "import_source": "수입원",
                "import_synchronizing": "자동 업데이트 활성화",
                "individual_first_identity_piece": "정체성 조각",
                "individual_registration_document": "등록 문서",
                "individual_second_identity_piece": "두 번째 아이덴티티 조각",
                "information": "정보",
                "institution": "기관",
                "institution_address": "기관 주소",
                "institution_id": "기관",
                "institution_title": "기관명",
                "interaction_type": "상호 작용 유형",
                "invitation": "초대",
                "invitation_accepted": "초대 수락됨",
                "invitation_status": "초대 상태",
                "invited_at": "에 초대",
                "invited_by": "에 의해 초대",
                "invitees": "초대받은 사람",
                "is_geocoding": "위치정보 진행 중",
                "iso_code": "ISO 코드",
                "items": "항목",
                "key": "열쇠",
                "kind": "친절한",
                "label": "상표",
                "last_geoloc": "마지막 지리적 위치",
                "last_logged_at": "마지막으로 로그인한",
                "last_login_ip": "마지막 로그인 IP",
                "last_name": "성",
                "last_validated_at": "마지막 확인",
                "lat": "위도",
                "latitude": "위도",
                "line1": "주소 라인 1",
                "line2": "주소 2",
                "link": "링크",
                "lng": "경도",
                "locale": "언어",
                "login": "연결",
                "logo": "심벌 마크",
                "longitude": "경도",
                "manipulations": "조작",
                "max_amount_per_checkout": "결제당 최대 금액",
                "measurement_system": "측정 시스템",
                "media": "미디어",
                "message": "메시지",
                "metadata": "메타데이터",
                "mime_type": "MIME 유형",
                "min_amount_per_checkout": "체크아웃당 최소 금액",
                "misc": "여러 가지 잡다한",
                "model": "기록",
                "model_type": "레코드 유형",
                "name": "이름",
                "net_amount": "순액",
                "new_password": "새 비밀번호",
                "new_password_confirmation": "새 비밀번호 확인",
                "next_payment_at": "다음 결제",
                "nickname": "별명",
                "not_specified": "명시되지 않은",
                "not_validated_activities": "검증되지 않은 활동",
                "notes": "추가 정보",
                "opening_hours": "개관 시간",
                "order_column": "주문하다",
                "origin": "기원",
                "owner": "소유자",
                "owner_email": "이메일",
                "owner_first_name": "이름",
                "owner_last_name": "성",
                "owner_phone": "핸드폰",
                "password": "비밀번호",
                "password_confirmation": "비밀번호 확인",
                "passwords_must_different": "두 계정 모두 동일한 비밀번호를 가질 수 없습니다.",
                "payable": "지불 가능",
                "payee": "수취인",
                "payee_country": "국가",
                "payee_email": "이메일",
                "payee_id": "수취인",
                "payee_name": "수취인 이름",
                "payee_phone": "핸드폰",
                "payer": "지불인",
                "payer_country": "국가",
                "payer_email": "이메일",
                "payer_id": "지불인",
                "payer_name": "지불인 이름",
                "payer_phone": "핸드폰",
                "payment_received": "지불 수신",
                "payment_service": "결제 서비스",
                "payment_service_account_id": "신분증 결제계좌",
                "payment_service_transaction_id": "신분증 결제 거래",
                "payment_thanks": "지불 감사",
                "payment_type": "지불 유형",
                "period": "기간",
                "phone": "핸드폰",
                "plan": "계획",
                "point_of_interest": "관심 지점",
                "point_of_interest_categories": "카테고리",
                "point_of_interest_category": "범주",
                "point_of_interest_event_expired": "이벤트 만료됨",
                "points_of_interest": "가볼만한 곳",
                "points_of_interest_count": "관심 포인트 수",
                "post_category": "포스트 카테고리",
                "price": "가격",
                "price_excluding_taxes": "세금을 제외한 가격",
                "prices": "물가",
                "priority": "우선 사항",
                "privacy_policy": "개인 정보 정책",
                "privacy_policy_accepted": "개인 정보 보호 정책 수락",
                "privacy_policy_version": "개인 정보 보호 정책 버전",
                "private": "사적인",
                "private_title": "개인 타이틀",
                "pro_file": "프로필",
                "profile_picture": "프로필 사진",
                "properties": "속성",
                "properties_comparison": "업데이트된 속성",
                "provider": "공급자",
                "public": "공공의",
                "public_id": "공개 ID",
                "published_at": "에 게시됨",
                "quantity": "수량",
                "quitted_at": "에 종료",
                "radius": "반지름",
                "rates": "요금",
                "reason_details": "세부",
                "reason_type": "이유",
                "recipient": "받는 사람",
                "recipients": "수신자",
                "recipients_alt": "등록된 여행자",
                "remember": "날 기억해",
                "removed_at": "에서 제거됨",
                "reports": "보고서",
                "requested_at": "에 요청",
                "reservation_created": "예약 생성됨",
                "reservation_date": "날짜",
                "reservation_time": "시간",
                "resolved_at": "해결 시간",
                "responsive_images": "반응형 이미지",
                "roles": "역할",
                "room_number": "방 번호",
                "scout_searchable": "인덱싱 가능",
                "searchable": "검색 가능",
                "sender": "보내는 사람",
                "senders": "발신자",
                "service": "서비스",
                "settings": "설정",
                "share_created": "생성된 공유",
                "shareable": "공유 가능",
                "show_contact_email": "연락처 이메일 가시성",
                "show_contact_phone": "연락처 전화 가시성",
                "show_profile_picture": "아바타의 가시성",
                "show_real_name": "성과 이름의 가시성",
                "siret": "시렛",
                "size": "크기",
                "source": "원천",
                "source_id": "소스 ID",
                "specified": "지정됨",
                "starable": "별표로 표기",
                "stars": "별",
                "start_date": "시작일",
                "state": "상태",
                "states": "주",
                "static_translations_enabled": "정적 번역 사용",
                "status": "상태",
                "subject": "주제",
                "subject_id": "주제 ID",
                "subject_type": "과목 유형",
                "summary": "요약",
                "support_category": "범주",
                "support_messages_count": "지원 메시지 수",
                "support_request": "지원 요청",
                "support_request_archived": "지원 요청 보관됨",
                "support_request_message_received": "지원 요청에서 받은 답변",
                "suspended_at": "정지",
                "tag": "꼬리표",
                "tags": "태그",
                "target": "표적",
                "terms_condition_accepted": "이용 약관 동의",
                "terms_condition_version": "이용약관 버전",
                "terms_conditions": "이용 약관",
                "thanked": "감사",
                "thanked_at": "감사 날짜",
                "thanks": "감사해요",
                "thanks_message": "감사 메시지",
                "thanks_sent": "감사를 보냈습니다",
                "tipping_fees": "팁 수수료",
                "tips_enabled": "팁 활성화됨",
                "title": "제목",
                "total_amount": "총액",
                "traveller": "여행자",
                "traveller_folder": "여행자 폴더",
                "traveller_folder_approved": "여행자 폴더 승인됨",
                "traveller_folder_archived": "여행자 폴더 보관됨",
                "traveller_folder_pending": "여행자 폴더 대기 중",
                "traveller_folder_thanks": "숙박 주셔서 감사합니다",
                "tutorial_state": "튜토리얼 상태",
                "type": "유형",
                "updated_at": "에 업데이트됨",
                "user": "사용자",
                "user_id": "사용자",
                "user_report": "사용자(보고하는 사람)",
                "users": "사용자",
                "utm_campaign": "운동",
                "utm_medium": "중간",
                "utm_settings": "UTM 매개변수",
                "utm_source": "원천",
                "uuid": "uuid",
                "validated_activities": "검증된 활동",
                "validated_at": "에서 검증",
                "value": "값",
                "vat": "큰 통",
                "vat_rate": "부가세율",
                "version": "버전",
                "video": "동영상",
                "videos": "동영상",
                "website": "웹사이트",
                "zip": "지퍼"
            },
            "before": " {attribute}은 {date} 이전의 날짜여야 합니다.",
            "before_or_equal": " {attribute}은 {date} 이전 날짜여야 합니다.",
            "between": {
                "array": " {attribute}에는 {min}에서 {max} 사이의 항목이 있어야 합니다.",
                "file": " {attribute}은 {min}와 {max} 킬로바이트 사이여야 합니다.",
                "numeric": " {attribute}은 {min}와 {max} 사이에 있어야 합니다.",
                "string": " {attribute}은 {min}에서 {max} 사이여야 합니다."
            },
            "boolean": " {attribute} 필드는 true 또는 false여야 합니다.",
            "confirmed": " {attribute}이 일치하지 않습니다.",
            "currency_scale": " {attribute}의 소수점 이하 자릿수는 {scale}를 넘지 않아야 합니다.",
            "current_password": " {attribute}이 비밀번호와 일치하지 않습니다.",
            "custom": {
                "attribute-name": {
                    "rule-name": "맞춤 메시지"
                },
                "h-captcha-response": {
                    "HCaptcha": "보안 문자 오류! 나중에 다시 시도하거나 사이트 관리자에게 문의하십시오.",
                    "required": "로봇이 아님을 확인하십시오."
                }
            },
            "date": " {attribute}은 유효한 날짜가 아닙니다.",
            "date_equals": " {attribute}은 {date}와 같은 날짜여야 합니다.",
            "date_format": " {attribute}이 {format} 형식과 일치하지 않습니다.",
            "different": " {attribute}과 {other}는 달라야 합니다.",
            "digits": " {attribute}은 {digits} 자리여야 합니다.",
            "digits_between": " {attribute}은 {min}에서 {max} 사이여야 합니다.",
            "dimensions": " {attribute}에 잘못된 이미지 크기가 있습니다.",
            "distinct": " {attribute} 필드에 중복 값이 ​​있습니다.",
            "email": " {attribute}은 유효한 이메일 주소여야 합니다.",
            "ends_with": " {attribute}은 다음 중 하나로 끝나야 합니다. {values}.",
            "exists": "선택한 {attribute}이 유효하지 않습니다.",
            "file": " {attribute}은 파일이어야 합니다.",
            "filled": " {attribute} 필드에는 값이 있어야 합니다.",
            "gt": {
                "array": " {attribute}에는 {value}개 이상의 항목이 있어야 합니다.",
                "file": " {attribute}은 {value}킬로바이트보다 커야 합니다.",
                "numeric": " {attribute}은 {value}보다 커야 합니다.",
                "string": " {attribute}은 {value}자보다 커야 합니다."
            },
            "gte": {
                "array": " {attribute}에는 {value} 이상의 항목이 있어야 합니다.",
                "file": " {attribute}은 {value}킬로바이트보다 크거나 같아야 합니다.",
                "numeric": " {attribute}은 {value}보다 크거나 같아야 합니다.",
                "string": " {attribute}은 {value}자보다 크거나 같아야 합니다."
            },
            "image": " {attribute}은 이미지여야 합니다.",
            "in": "선택한 {attribute}이 유효하지 않습니다.",
            "in_array": " {attribute} 필드는 {other}에 존재하지 않습니다.",
            "integer": " {attribute}은 정수여야 합니다.",
            "ip": " {attribute}은 유효한 IP 주소여야 합니다.",
            "ipv4": " {attribute}은 유효한 IPv4 주소여야 합니다.",
            "ipv6": " {attribute}은 유효한 IPv6 주소여야 합니다.",
            "json": " {attribute}은 유효한 JSON 문자열이어야 합니다.",
            "lt": {
                "array": " {attribute}에는 {value}개 미만의 항목이 있어야 합니다.",
                "file": " {attribute}은 {value}킬로바이트 미만이어야 합니다.",
                "numeric": " {attribute}은 {value}보다 작아야 합니다.",
                "string": " {attribute}은 {value}자 미만이어야 합니다."
            },
            "lte": {
                "array": " {attribute}에는 {value}개 이상의 항목이 없어야 합니다.",
                "file": " {attribute}은 {value}킬로바이트보다 작거나 같아야 합니다.",
                "numeric": " {attribute}은 {value}보다 작거나 같아야 합니다.",
                "string": " {attribute}은 {value} 문자보다 작거나 같아야 합니다."
            },
            "luhn": "{attribute}은 잘못된 숫자입니다.",
            "max": {
                "array": " {attribute}에는 {max}개 이상의 항목이 있을 수 없습니다.",
                "file": " {attribute}은 {max}킬로바이트보다 클 수 없습니다.",
                "height": "{attribute}은 높이가 {max}픽셀을 초과하지 않아야 합니다.",
                "numeric": " {attribute}은 {max}보다 클 수 없습니다.",
                "string": " {attribute}은 {max}자보다 클 수 없습니다.",
                "width": "{attribute}은 너비가 {max}픽셀을 초과하면 안 됩니다."
            },
            "max_digits": "{attribute}은 {max}자리를 초과하지 않아야 합니다.",
            "mimes": " {attribute}에는 유효한 유형이 있어야 합니다.",
            "mimetypes": " {attribute}에는 유효한 유형이 있어야 합니다.",
            "min": {
                "array": " {attribute}에는 최소한 {min}개의 항목이 있어야 합니다.",
                "file": " {attribute}은 최소한 {min}킬로바이트여야 합니다.",
                "numeric": " {attribute}은 최소한 {min}여야 합니다.",
                "string": " {attribute}은 최소 {min}자여야 합니다."
            },
            "min_digits": "{attribute}은 최소 {min}자리여야 합니다.",
            "not_in": "선택한 {attribute}이 유효하지 않습니다.",
            "not_regex": " {attribute} 형식이 잘못되었습니다.",
            "notification_setting": " {attribute} 형식이 잘못되었습니다(예상 형식은 array[bool]임).",
            "numeric": " {attribute}은 숫자여야 합니다.",
            "password": "비밀번호가 올바르지 않습니다.",
            "phone": " {attribute}은 유효한 전화번호여야 합니다.",
            "present": " {attribute} 필드가 있어야 합니다.",
            "prohibited_if": " {other}가 {value}이면 {attribute} 필드는 금지됩니다.",
            "prohibited_unless": " {other}가 {values}에 있지 않으면 {attribute} 필드는 금지됩니다.",
            "real_name_characters": ":{attribute}은 문자, 공백, 점,\n        하이픈 또는 아포스트로피.",
            "regex": " {attribute} 형식이 잘못되었습니다.",
            "required": " {attribute} 필드는 필수입니다.",
            "required_if": " {other}가 {value}이면 {attribute} 필드가 필요합니다.",
            "required_unless": " {other}가 {values}에 없으면 {attribute} 필드가 필요합니다.",
            "required_with": " {values}가 있으면 {attribute} 필드가 필요합니다.",
            "required_with_all": " {values}가 있는 경우 {attribute} 필드가 필요합니다.",
            "required_without": " {values}가 없으면 {attribute} 필드가 필요합니다.",
            "required_without_all": " {values}가 없으면 {attribute} 필드가 필요합니다.",
            "same": " {attribute}과 {other}는 일치해야 합니다.",
            "same_currency": "모든 항목은 동일한 통화를 사용해야 합니다.",
            "size": {
                "array": " {attribute}에는 {size} 항목이 포함되어야 합니다.",
                "file": " {attribute}은 {size}킬로바이트여야 합니다.",
                "numeric": " {attribute}은 {size}여야 합니다.",
                "string": " {attribute}은 {size} 문자여야 합니다."
            },
            "starts_with": " {attribute}은 다음 중 하나로 시작해야 합니다. {values}.",
            "string": " {attribute}은 문자열이어야 합니다.",
            "tag_same_poi_category": "{attribute}은 모두 같은 범주에 속해야 합니다!",
            "timezone": " {attribute}은 유효한 영역이어야 합니다.",
            "unique": " {attribute}은 이미 사용되었습니다.",
            "uploaded": " {attribute} 업로드에 실패했습니다.",
            "url": " {attribute} 형식이 잘못되었습니다.",
            "uuid": " {attribute}은 유효한 UUID여야 합니다."
        }
    },
    "nl": {
        "The {attribute} must be at least {length} characters and contain at least one number.": "De {attribute} moet minimaal {length} tekens lang zijn en minimaal één cijfer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "De {attribute} moet minimaal {length} tekens bevatten en minimaal één speciaal teken en één cijfer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "De {attribute} moet minimaal {length} tekens bevatten en minimaal één speciaal teken bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "De {attribute} moet minimaal {length} tekens bevatten en minimaal één hoofdletter en één cijfer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "De {attribute} moet minimaal {length} tekens bevatten en minimaal één hoofdletter en één speciaal teken bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "De {attribute} moet minimaal {length} tekens bevatten en minimaal één hoofdletter, één cijfer en één speciaal teken bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "De {attribute} moet minimaal {length} tekens bevatten en minimaal één hoofdletter bevatten.",
        "The {attribute} must be at least {length} characters.": "De {attribute} moet minimaal {length} tekens bevatten.",
        "The {attribute} must contain at least one letter.": "De {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "De {attribute} moet minstens één getal bevatten.",
        "The {attribute} must contain at least one symbol.": "De {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "De {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "auth": {
            "failed": "Deze gegevens komen niet overeen met onze gegevens. Weet je zeker dat je dit e-mailadres niet voor een {type}-account hebt gebruikt?",
            "throttle": "Te veel inlogpogingen. Probeer het over {seconds} seconden opnieuw.",
            "throttle_cascade": "Te veel inlogpogingen. Probeer het opnieuw in {remaining}."
        },
        "documents": {
            "display": {
                "description": "Weergave in A6-formaat met koppeling van QRCode en het logo van uw instelling.",
                "title": "Display - A6-kaart"
            },
            "qrcode": {
                "description": "Alleen QRCode waarmee de reiziger rechtstreeks aan uw instelling kan worden gekoppeld.",
                "filename": "QR code",
                "title": "QR-code koppelen"
            }
        },
        "enums": {
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "Gemaakt",
                "deleted": "Verwijderd",
                "updated": "Bijgewerkt",
                "validated": "Gevalideerd"
            },
            "App\\Enums\\BinaryChoiceEnum": [
                "Geweigerd",
                "Geaccepteerd"
            ],
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "association": "Vereniging (of stichting)",
                "company": "Bedrijf (publiek/privaat)",
                "individual": "Individueel",
                "undefined": "Ongedefinieerd"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "DE": "Duitsland",
                "ES": "Spanje",
                "FR": "Frankrijk",
                "IT": "Italië",
                "TH": "Thailand",
                "undefined": "Ongedefinieerd"
            },
            "App\\Enums\\ContextsEnum": {
                "city_hall": "Gemeentehuis",
                "hotel": "Hotel",
                "manager": "Manager",
                "tourist_office": "VVV",
                "traveller": "Reiziger"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "Euro",
                "usd": "Dollar"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Friday": "Vrijdag",
                "Monday": "Maandag",
                "Saturday": "Zaterdag",
                "Sunday": "Zondag",
                "Thursday": "Donderdag",
                "Tuesday": "Dinsdag",
                "Wednesday": "Woensdag"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "Aangepast",
                "root": "Mijn sjablonen",
                "ugozer_app": "UgoZER-app",
                "ugozer_pro": "UgoZER Pro"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "Imperiaal",
                "metric": "Metriek"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "In app",
                "email": "E-mail"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "customer": "Klant",
                "partner": "Partner"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "easytransac": "EasyTransac",
                "paypal": "PayPal",
                "stripe": "Streep"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "subscription": "Abonnement",
                "tip": "Tip"
            },
            "App\\Enums\\PrivacySettingsEnum": {
                "contacts": "Mijn contacten",
                "everyone": "Iedereen",
                "nobody": "Niemand"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "illegal": "Onwettig",
                "incorrect_data": "Foutieve informatie",
                "other": "Ander",
                "shocking": "Schokkend",
                "spam": "Spam"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "Ontbijt",
                "dinner": "Diner",
                "lunch": "Lunch",
                "null": "N.v.t"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "Facebook",
                "google": "Googlen",
                "instagram": "Instagram",
                "linkedin": "LinkedIn",
                "tiktok": "TikTok",
                "twitter": "X (Twitter)",
                "wechat": "WeChat"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "1 maand",
                "one year": "1 jaar"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "1 maand",
                "one year": "1 jaar",
                "six months": "6 maanden",
                "three months": "3 maanden"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "expired": "Abonnement beëindigd",
                "free period ended": "Vrije periode afgelopen",
                "payment default": "Betalingsverzuim"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "App",
                "extranet": "Extranet",
                "front": "Voorkant"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "Gearchiveerd",
                "canceled": "Geannuleerd",
                "pending_support": "In afwachting van ondersteuning",
                "pending_user": "Gebruiker in behandeling",
                "resolved": "Opgelost"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "Om mij heen",
                "customized": "Aangepast",
                "staying_hotel": "Dichtbij verblijfhotel"
            },
            "App\\Enums\\UserRoleEnum": {
                "butler": "Butler",
                "contributor": "Bijdrager",
                "hotelier": "Hotelier",
                "manager": "Manager",
                "moderator": "Moderator",
                "superadmin": "SuperAdmin",
                "touristOffice": "VVV",
                "touristOfficeEmployee": "NAAR medewerker",
                "traveller": "Reiziger"
            }
        },
        "nova": {
            "lens": {
                "user": {
                    "user_origin": "Oorsprong van gebruikers"
                }
            },
            "menu": {
                "administration": "Administratie",
                "content": "Inhoud",
                "excursions": "Routes",
                "import_poi": "POI importeren",
                "miscellaneous": "Gemengd",
                "parameters": "Parameters",
                "pois": "Interessante punten",
                "subscriptions": "Abonnementen",
                "support": "Steun",
                "users": "Gebruikers"
            },
            "s3_private_download": {
                "link": {
                    "expiration": "De link vervalt na 30 min. Als dit het geval is, vernieuw dan eenvoudigweg de pagina.",
                    "open": "Bekijk het document"
                },
                "no_document": "Geen gerelateerde documenten",
                "not_found": "Het document kon niet worden gevonden op de S3-schijf..."
            },
            "user_menu": {
                "profile": "Mijn profiel"
            }
        },
        "pagination": {
            "go_page": "Ga naar pagina {page}",
            "next": "Volgende \"",
            "next_alt": "Volgende",
            "of": "van",
            "pagination_navigation": "Paginering navigatie",
            "previous": "\" Vorig",
            "previous_alt": "Vorig",
            "results": "resultaten",
            "showing": "Tonen",
            "to": "naar"
        },
        "passwords": {
            "reset": "Je wachtwoord is gereset!",
            "sent": "We hebben uw link voor het opnieuw instellen van uw wachtwoord per e-mail verzonden!",
            "throttled": "Wacht alstublieft voordat u het opnieuw probeert.",
            "token": "Dit token voor het opnieuw instellen van het wachtwoord is ongeldig.",
            "user": "Geen corresponderende gebruiker."
        },
        "ttmt": {
            "account_deletion": {
                "confirmation": "Op de geopende pagina bevestigt u de verwijdering door het woord <strong>DELETE</strong> in te voeren\n        en klik op <strong>Bevestigen</strong>.",
                "content": {
                    "asking": "In het geval van een verzoek tot accountverwijdering zal UgoZER het account en de gegevens van de gebruiker verwijderen,\n            tenzij ze moeten worden bewaard vanwege wettelijke of regelgevende vereisten, om veiligheidsredenen of voor fraude\n            preventie of als gevolg van een probleem met betrekking tot het account van de gebruiker, zoals een onbetaalde, onopgeloste claim of\n            geschil.",
                    "conservation": {
                        "link": "Privacybeleid.",
                        "part_1": "UgoZER bewaart gebruikersgegevens zo lang als nodig is voor de doeleinden beschreven in de",
                        "part_2": "In overeenstemming met de aanbevelingen van de CNIL wordt een account dat drie jaar niet is gebruikt, verwijderd.",
                        "part_3": "Gebruikers kunnen op elk moment verzoeken om verwijdering van hun accounts. UgoZER kan gebruikersgegevens bewaren na a\n                verwijderingsverzoek in overeenstemming met wettelijke of regelgevende vereisten, of om de redenen die hierin worden vermeld\n                stelling."
                    },
                    "instructions": "Als u uw UgoZER-account wilt verwijderen, volgt u de onderstaande instructies."
                },
                "logout": "U wordt uitgelogd en het account en bijbehorende gegevens worden onmiddellijk verwijderd.",
                "pro": {
                    "instructions": {
                        "delete": "Klik vervolgens op de knop <strong>Verwijderen</strong> vóór <strong>Account verwijderen\n                </strong>,",
                        "profile": {
                            "link": "profiel",
                            "part_1": "Ga naar je",
                            "path": "(door op je avatar > Mijn profiel te klikken),"
                        }
                    },
                    "subtitle": "Om uw professionele account te verwijderen:",
                    "title": "Professionele rekening"
                },
                "title": "Accountverwijdering",
                "traveller": {
                    "instructions": {
                        "delete": "Klik vervolgens op de knop <strong>Verwijderen</strong> voor <strong>Mijn account verwijderen?\n                </strong>",
                        "profile": {
                            "link": "profiel",
                            "part_1": "Ga naar je",
                            "path": "(hamburgerpictogrammenu > Instellingen > Profiel),"
                        }
                    },
                    "subtitle": "Om uw reizigersaccount te verwijderen:",
                    "title": "Reizigersaccount"
                }
            },
            "activity_logs": {
                "summary": {
                    "created": {
                        "intro": "Onderwerp is gemaakt met deze eigenschappen:"
                    },
                    "deleted": {
                        "intro": "Onderwerp is verwijderd met deze eigenschappen:"
                    },
                    "updated": {
                        "attribute_from_to": "{attribute} is bijgewerkt van \"{old_value}\" naar \"{new_value}\"",
                        "intro": "Onderwerp is bijgewerkt:"
                    },
                    "validated": {
                        "intro": "Onderwerp is gevalideerd:"
                    }
                }
            },
            "auth": {
                "credential_verification": {
                    "intro": "Voer uw inloggegevens in. Er wordt een bevestigingslink naar u verzonden.",
                    "intro2": "Uw inloggegevens worden bijgewerkt zodra u de link opent."
                },
                "expiration": {
                    "session_expired": "Uw sessie is verlopen. Authenticeer opnieuw"
                },
                "extranet_register": {
                    "form": {
                        "choose_establishment": "Kies uw type vestiging",
                        "form_title": "Maak een account aan",
                        "validate_registration": "Rond mijn registratie af"
                    },
                    "success": "Succesvol geregistreerd, er is een verificatielink naar uw e-mailadres verzonden"
                },
                "login": {
                    "already_logged": "je bent al ingelogd",
                    "logging_in": "Inloggen...",
                    "success": "Ingelogd",
                    "use_email": "Inloggen met een e-mail",
                    "use_social_networks": "Gebruik sociale netwerken"
                },
                "logout": {
                    "logging_out": "Uitloggen...",
                    "switch": "Overschakelen naar"
                },
                "messages": {
                    "continue": "Doorgaan met; ga door met",
                    "extranet_here": "Klik hier",
                    "have_account": "Heeft u al een account?",
                    "login": "Log in",
                    "login_subtitle": "(registratie of inloggen)",
                    "login_title": "Het is beter met sociale netwerken",
                    "no_account": "Heeft u nog geen account?",
                    "or": "OF",
                    "professional": "U bent een professional?",
                    "register": "Register",
                    "register_mail": "Meld u aan met een e-mail",
                    "register_title": "Registreer om alle functies te ontgrendelen",
                    "reset_password": "Wachtwoord opnieuw instellen",
                    "send_magic_link": "Stuur een wachtwoordloze link"
                },
                "password_forgotten": {
                    "intro": "Voer uw inloggegevens in en wij sturen u een link om uw wachtwoord opnieuw in te stellen.",
                    "submit": "Ontvang een resetlink",
                    "success": {
                        "correct_credential": "Correcte legitimatie",
                        "email_1": "Er wordt een e-mail naar u verzonden op {email}, als dit adres goed geregistreerd is.",
                        "email_2": "Deze e-mail bevat een link waarmee u uw wachtwoord opnieuw kunt instellen.",
                        "send_again": "Opnieuw versturen",
                        "title": "Link voor wachtwoordherstel verzonden",
                        "wait_before": "Wacht a.u.b. {seconds} seconden voordat u opnieuw verzendt"
                    }
                },
                "password_reset": {
                    "submit": "Stel je wachtwoord opnieuw in",
                    "success": "Het wachtwoord is opnieuw ingesteld"
                },
                "register": {
                    "form": {
                        "hide_privacy_policy": "Privacybeleid verbergen",
                        "hide_terms_conditions": "Algemene voorwaarden verbergen",
                        "privacy_policy": "Ik ga akkoord met de privacy-overeenkomst",
                        "show_privacy_policy": "Privacybeleid tonen",
                        "show_terms_conditions": "Algemene voorwaarden weergeven",
                        "terms_conditions": "Ik ga akkoord met de algemene voorwaarden"
                    },
                    "staying_hotel": "Zodra u zich heeft aangemeld, wordt er voor u gezorgd door het hotel waarvan u beschikt\n            je hebt zojuist de QR-code gescand.",
                    "success": {
                        "email_1": "Er wordt een e-mail verzonden naar {email}.",
                        "email_2": "Deze e-mail bevat een link waarmee u uw adres kunt bevestigen en verbinding kunt maken.",
                        "paired": {
                            "email_1": "Er zijn twee e-mails verzonden naar de volgende adressen",
                            "email_2": "Elke e-mail bevat een link om het adres te bevestigen en verbinding te maken met het bijbehorende account."
                        },
                        "title": "Succesvol geregistreerd"
                    }
                },
                "social_networks": {
                    "authenticate": "Login met",
                    "facebook": "Facebook",
                    "google": "Googlen",
                    "no_email": "Er is geen e-mailadres gekoppeld aan uw sociale netwerk, u kunt zich er niet mee identificeren",
                    "twitter": "Twitteren"
                },
                "travel_assistant": "Gratis reisassistent"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "Bankrekening niet opgegeven"
                    },
                    "form": {
                        "placeholders": {
                            "bank_city": "Stad van uw bankfiliaal.",
                            "bank_name": "banknaam",
                            "bank_transfer_reason": "Voorbeeld: \"UgoZER-tips\"",
                            "bic": "Vul uw BIC in",
                            "iban": "Vul uw IBAN/BBAN in",
                            "payee_name": "Naam begunstigde of naam instelling",
                            "types": "IBAN/BBAN"
                        }
                    },
                    "lines": {
                        "no_account_yet": "Met uw bankrekening kan uw instelling betalingen op UgoZER ontvangen."
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "amount": "hoeveelheid",
                    "amount_to_pay": "te betalen bedrag",
                    "concierge": "conciërge",
                    "currency": "munteenheid",
                    "customer": "klant",
                    "date": "datum",
                    "repartition_key": "distributiesleutel",
                    "tip_sharing_form": "formulier voor het delen van fooien",
                    "tips_received": "tips ontvangen",
                    "total_received": "totaal ontvangen"
                }
            },
            "breadcrumbs": {
                "changelogs": {
                    "changelogs": "Wijzigingslogboeken"
                },
                "claims": {
                    "claims": "Claims"
                },
                "common": {
                    "add": "Toevoegen",
                    "claim": "Claim",
                    "create": "Nieuw",
                    "create_f": "Nieuw",
                    "delete": "Verwijderen",
                    "download_pdf": "Download PDF",
                    "edit": "Bewerking",
                    "report": "Rapport",
                    "share": "Deel",
                    "show": "Show"
                },
                "contacts": {
                    "contacts": "Contacten"
                },
                "cookie": {
                    "cookie": "Cookiebeheer"
                },
                "credit": {
                    "credit": "Kredieten"
                },
                "dashboard": {
                    "dashboard": "Dashboard"
                },
                "errors": {
                    "404": {
                        "label": "foutmelding 404",
                        "title": "Error 404 niet gevonden"
                    }
                },
                "excursions": {
                    "create": "Nieuwe route",
                    "excursions": "Routes"
                },
                "faq": {
                    "faq": "Veel Gestelde Vragen"
                },
                "finances": {
                    "finances": "Financiën"
                },
                "institutions": {
                    "institution": "Instelling",
                    "institutions": "Instellingen"
                },
                "institutions_employees": {
                    "institutions_employees": "Mijn medewerkers",
                    "invite": "Nodig een medewerker uit"
                },
                "interactions": {
                    "interactions": "Interacties"
                },
                "invitations": {
                    "invitations": "Uitnodigingen"
                },
                "notification_settings": {
                    "notification_settings": "Instellingen voor meldingen"
                },
                "notifications": {
                    "notifications": "Meldingen"
                },
                "partners": {
                    "customers": "Klanten",
                    "partners": "Partners"
                },
                "points_of_interest": {
                    "add_excursion": "Voeg toe aan een reisplan",
                    "points_of_interest": "Interessante punten"
                },
                "privacy_policy": {
                    "privacy_policy": "Privacybeleid"
                },
                "privacy_settings": {
                    "privacy_settings": "Privacy instellingen"
                },
                "reservations": {
                    "reservations": "Reserveringen"
                },
                "search": {
                    "search": "Zoekopdracht"
                },
                "subscriptions": {
                    "subscription": "Abonnement"
                },
                "support_messages": {
                    "add_message": "Voeg een bericht toe",
                    "support_messages": "Berichten"
                },
                "support_requests": {
                    "add_request": "Voeg een nieuw ondersteuningsverzoek toe",
                    "support_requests": "Ondersteuningsverzoeken"
                },
                "terms_condition": {
                    "terms_condition": "Voorwaarden"
                },
                "travellers": {
                    "folders": "Reizigersmappen",
                    "travellers": "Reizigers"
                },
                "tutorials": {
                    "begin": "Aan de slag"
                },
                "users": {
                    "edit_credential": "Referentie bewerken",
                    "profile": "Mijn profiel",
                    "users": "Gebruikers"
                }
            },
            "changelogs": {
                "archive": {
                    "success": "Changelog(s) zijn gearchiveerd (indien in aanmerking komend)"
                },
                "draft": {
                    "success": "Changelog(s) zijn opgesteld (indien in aanmerking komend)"
                },
                "index": {
                    "sections": {
                        "all": "Alle updates",
                        "last": "Laatste update"
                    }
                },
                "publish": {
                    "success": "Changelog(s) zijn gepubliceerd (indien in aanmerking komend)"
                },
                "show": {
                    "version": "Versie"
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "Claimgeschil is aangemaakt"
                },
                "rejected": {
                    "success": "Claimgeschil is afgewezen"
                },
                "validated": {
                    "success": "Claimgeschil is gevalideerd"
                }
            },
            "claims": {
                "form_wizard": {
                    "documents": {
                        "DE": {
                            "association_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere\n                    Versie met MRZ-Zeilen) of Aufenthaltstitel (nu wenn Sie aus einem europäischen Land kommen)",
                            "association_statutes": "Handelsregisterauszug",
                            "company_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (neuere\n                    Versie met MRZ-Zeilen) of Aufenthaltstitel (nu wenn Sie aus einem europäischen Land kommen)",
                            "company_registration_document": "Handelsregisterauszug",
                            "company_statutes": "Aktuelle en unterschriebene (von der Geschäftsführung) Gesellschaftverträge",
                            "individual_first_identity_piece": "Reisepass, Personalausweis, Führerschein (nieuwe versie mit\n                    MRZ-Zeilen) of Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_registration_document": "Gewerbeschein, Handlungsbevollmächtigung, Handelsregisterauszug,\n                    Prokura, Steuernummer toeschrijvingsbrief",
                            "individual_second_identity_piece": "Reisepass, Personalausweis, Führerschein (nieuwe versie mit\n                    MRZ-Zeilen) of Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)"
                        },
                        "ES": {
                            "association_legal_representative_identity_piece": "Pasaporte, Documento nationale de identidad,\n                    Toestemming voor het leiden (recente versie met lijn MRZ) of toestemming voor wonen (alleen als een\n                    país europa)",
                            "association_statutes": "Escrituras de constitución of Estatutos sociales",
                            "company_legal_representative_identity_piece": "Pasaporte, Documento nationale de identidad, Permiso\n                    de leiding (recente versie met lijn MRZ) of verblijfsvergunning (alleen als een país\n                    Europa)",
                            "company_registration_document": "Extracto del Registro Mercantil Central, Nota Simple del Registro\n                    lokale verkoop of numerieke handel, Extracto desde los Registradores de España (informatie\n                    General Mercantil) met geldige geldigheid van 3 maanden o Modelo 600 met geldige geldigheid\n                    de menos de 3 maanden (solo para sociedad civil)",
                            "company_statutes": "Escrituras de constitución of Estatutos sociales",
                            "individual_first_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso de conducir\n                    (recente versie met lijn MRZ) of verblijfsvergunning (solo si es de un país europeo)",
                            "individual_registration_document": "Agencia Tributaria - Certificado de situacion Censal\n                    (Alta Censaal)",
                            "individual_second_identity_piece": "Pasaporte, Documento nacional de identidad, Permiso de conducir\n                    (recente versie met lijn MRZ) of verblijfsvergunning (solo si es de un país europeo)"
                        },
                        "FR": {
                            "association_legal_representative_identity_piece": "Een identiteitsbewijs tijdens de geldigheidsduur van\n                    vertegenwoordiger van de juridische vereniging: identiteitskaart (recto en verso), paspoort, titel van\n                    verblijf (recto en verso), toestemming voor biometrie (recto en verso)",
                            "association_statutes": "De statuten van de vereniging",
                            "company_legal_representative_identity_piece": "Een identiteitsbewijs tijdens de geldigheidsduur van\n                    juridisch vertegenwoordiger van de maatschappij: identiteitskaart (recto en verso), paspoort, verblijfstitel\n                    (recto en verso), toestemming voor biometrie (recto en verso)",
                            "company_registration_document": "Een uittreksel van KBIS",
                            "company_statutes": "De statistieken",
                            "individual_first_identity_piece": "Een première van een identiteitsbewijs en geldigheidsbewijs: carte\n                    d'identité (recto et verso), paspoort, titre de séjour (recto et verso), permis de conduire\n                    biometrie (recto en verso)",
                            "individual_registration_document": "Een inschrijvingscertificaat op INSEE of een document\n                    attestant de son inscription auprès de la Chambre de Commerce (pour un auto-entrepreneur exerçant\n                    een commerciële activiteit) of de kamer van de metiers (voor een auto-ondernemer\n                    een ambachtelijke activiteit)",
                            "individual_second_identity_piece": "Een tweede identiteitsbewijs en geldigheidsbewijs: kaart\n                    d'identité (recto et verso), paspoort, titre de séjour (recto et verso), permis de conduire\n                    (recto en verso), kijk naar de datum van de oplegging van een familielid of gezin\n                    het overzicht van de inschrijving voor een burgerlijk solidariteitsverdrag"
                        },
                        "IT": {
                            "association_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, La\n                    patente di guide (recente versie met linee MRZ) of Permesso di residenza (solo se di un paese\n                    Europa)",
                            "association_statutes": "tatuto firmato dal Legale Rappresentante (se non incluso all'interno della\n                    Visura Camerale",
                            "company_legal_representative_identity_piece": "Passaporto, Carta d'identità nazionale, La patente\n                    di guida (recente versie met linee MRZ) of Permesso di residenza (solo se di un paese europeo)",
                            "company_registration_document": "Visura Camerale",
                            "company_statutes": "Statuto firmato dal Legale Rappresentante (se non incluso all'interno della\n                    Visura camerale)",
                            "individual_first_identity_piece": "Passaporto, Carta d'identità nazionale, La patente di guida\n                    (recente versie met MRZ) of verblijfsvergunning (solo se di un paese europeo)",
                            "individual_registration_document": "Estratto dal registro delle imprese (\"Visura Camerale\") di meno\n                    van 3 maanden",
                            "individual_second_identity_piece": "Passaporto, Carta d'identità nazionale, La patente di guida\n                    (recente versie met MRZ) of verblijfsvergunning (solo se di un paese europeo)"
                        },
                        "TH": {
                            "association_legal_representative_identity_piece": "Veelgestelde vragen Antwoord:\n                    (meer) ัง),\n                    Ik denk dat ik het goed begrijp (bijvoorbeeld),\n                    (meer) ลัง)",
                            "association_statutes": "Veelgestelde vragen",
                            "company_legal_representative_identity_piece": "Veelgestelde vragen Antwoord:\n                    (ด้านหน้าและด้านหลัง), หน Ja,\n                    Ik denk dat ik dat wel ben\n                    (meer)",
                            "company_registration_document": "KBIS",
                            "company_statutes": "สถานะต่างๆ",
                            "individual_first_identity_piece": "Lees meer: ​​Ja\n                    (ทั้งด้านหน้าและด้านหลัง), หนังสือเดินทาง, ใบ (meer) ),\n                    (meer) ลัง)",
                            "individual_registration_document": "Veelgestelde vragen Veelgestelde vragen\n                    (meer info) (zoals)\n                    หรือกับหอการค้า (meer informatie)\n                    (meer informatie)",
                            "individual_second_identity_piece": "Antwoord: บัตรประจำต ัวประชาชน\n                    (ด้านหน้าและด้านหลัง) Veelgestelde vragen\n                    (ด้านหน้าและด้านหลัง) านหลัง)\n                    Veelgestelde vragen Ik denk dat het een goede zaak is Veelgestelde vragen\n                    Veelgestelde vragen"
                        }
                    },
                    "steps": {
                        "claim": "Claim",
                        "context": "Context",
                        "create": "Creëren",
                        "documents": "Documenten",
                        "info": "Informatie",
                        "search": "Zoekopdracht"
                    }
                },
                "missing_info": {
                    "success": "Claim is afgewezen vanwege ontbrekende informatie"
                },
                "notice": {
                    "actions": {
                        "edit": "Wijzig mijn verzoek"
                    },
                    "already_claimed": {
                        "description": "Dit aandachtspunt is al geclaimd. Als u denkt dat dit een fout is, kunt u dat doen\n                vul dan onderstaand geschilformulier in.",
                        "title": "Interessant punt al geclaimd!"
                    },
                    "find_or_new": {
                        "description": "Zoek een nuttige plaats in de onderstaande lijst of maak een nieuwe aan.",
                        "title": "Zoek of maak een POI"
                    },
                    "missing-info": {
                        "infos": "Vul uw aanvraag in door op onderstaande knop te klikken.",
                        "state": "Uw claim wacht momenteel op aanvullende informatie of documentatie:",
                        "title": "In afwachting van aanvullende informatie"
                    },
                    "pending": {
                        "state": "Uw claimaanvraag wordt momenteel verwerkt",
                        "title": "Claimverzoek in behandeling"
                    },
                    "rejected": {
                        "state": "Uw claimaanvraag is om de volgende reden afgewezen:",
                        "title": "Claimverzoek afgewezen"
                    }
                },
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "Claim",
                            "poi": "Punt van belang",
                            "user": "Gebruiker"
                        }
                    }
                },
                "rejected": {
                    "success": "Claim is afgewezen"
                },
                "revoked": {
                    "success": "Claim is ingetrokken"
                },
                "states": {
                    "missing-info": "Missende informatie",
                    "pending": "In behandeling",
                    "rejected": "Afgewezen",
                    "revoked": "Ingetrokken",
                    "validated": "Gevalideerd"
                },
                "update": {
                    "success": "De claim is bijgewerkt"
                },
                "valid_identity_pieces": "geldige identiteitskaart, paspoort of rijbewijs",
                "validated": {
                    "success": "Claim is gevalideerd"
                }
            },
            "common": {
                "actions": {
                    "accept": "Aanvaarden",
                    "accept_contact": "Contactverzoek accepteren",
                    "add": "Toevoegen",
                    "add_contact": "Toevoegen aan contacten",
                    "add_excursion": "Voeg toe aan reisschema",
                    "add_message": "Voeg een bericht toe",
                    "apply": "Toepassen",
                    "approve": "Goedkeuren",
                    "archive": "Archief",
                    "attach": "Bijvoegen",
                    "block_contact": "Blokkeer contact",
                    "cancel": "Annuleren",
                    "check": "Rekening",
                    "claim": "Claim",
                    "close": "Dichtbij",
                    "configure": "Configureer",
                    "confirm": "Bevestigen",
                    "contest": "Wedstrijd",
                    "create": "Creëren",
                    "decline": "Ontkennen",
                    "decline_contact": "Contactverzoek afwijzen",
                    "delete": "Verwijderen",
                    "download": "Downloaden",
                    "edit": "Bewerking",
                    "file_preview": "Voorbeeldbestand",
                    "filters": "Filters",
                    "give_a_tip": "Geef een fooi",
                    "google_maps": "Openen op Google Maps",
                    "help": "Hulp",
                    "hide": "Verbergen",
                    "ignore": "Negeren",
                    "interactions": "Toon interacties",
                    "invite": "Uitnodiging",
                    "load_more": "Meer laden",
                    "login": "Log in",
                    "logout": "Uitloggen",
                    "manage": "Beheren",
                    "next": "Volgende",
                    "pause": "Pauze",
                    "pay": "Betalen",
                    "play": "Toneelstuk",
                    "print": "Afdrukken",
                    "quit": "Ontslag nemen",
                    "record": "Dossier",
                    "register": "Register",
                    "report": "Rapport",
                    "request_to_join": "Verzoek om mee te doen",
                    "reset": "Opnieuw instellen",
                    "resume": "Cv",
                    "retry": "Opnieuw proberen",
                    "return": "Rug",
                    "save": "Redden",
                    "save_alt": "Redden",
                    "search": "Zoekopdracht",
                    "see_plans": "Zie plannen",
                    "see_website": "Zie website",
                    "send": "Versturen",
                    "send_link": "App-link verzenden",
                    "share": "Deel",
                    "show": "Show",
                    "start": "Begin",
                    "stop": "Stop",
                    "subscribe": "Abonneren",
                    "thank": "Dank",
                    "thank_for_stay": "Bedankt voor het verblijf",
                    "traveller_show": "Raadpleeg reiziger",
                    "unblock_contact": "Contact deblokkeren",
                    "unblock_this_user": "Deblokkeer deze gebruiker",
                    "update": "Update",
                    "validate": "Valideer"
                },
                "all": "alle",
                "and": "En",
                "app_name": "UgoZER",
                "back_to_dashboard": "Terug naar dashboard",
                "back_to_home": "Terug naar huis",
                "characters_limit": "{length} / {limit} tekens",
                "counter": "{dividend} op {divisor}",
                "country": "land",
                "dashboard": "Dashboard",
                "errors": {
                    "deleted_user": "Verwijderde gebruiker",
                    "internal": "Er is iets fout gegaan!",
                    "not_found": "Niet gevonden"
                },
                "false": "Nee",
                "fast": "snel",
                "home": "Thuis",
                "later": "later",
                "loading": "bezig met laden",
                "messages": {
                    "application_update": {
                        "if_ignore": "Als u de update negeert, wordt deze de volgende keer dat u de toepassing start, uitgevoerd.",
                        "new_version_available": "Er is een nieuwe versie beschikbaar.",
                        "updating": "Updaten...",
                        "want_to_refresh": "Wilt u de applicatie opnieuw opstarten om deze bij te werken?"
                    },
                    "clipboard": {
                        "error": "Fout tijdens kopiëren naar klembord...",
                        "success": "Gekopieerd naar het klembord!"
                    },
                    "no_records": "Geen verslagen gevonden"
                },
                "missing_title": "Ontbrekende titel",
                "more_options": "meer opties",
                "no": "Nee",
                "none": "geen",
                "normal": "normaal",
                "not_specified": "Niet gespecificeerd",
                "online": {
                    "connected": "U bent weer verbonden met internet",
                    "disconnected": "U bent niet langer verbonden met internet"
                },
                "oops": "oeps",
                "or": "of",
                "qr_code": "QR code",
                "search": "Zoekopdracht",
                "slow": "langzaam",
                "someone": "iemand",
                "sort": "soort",
                "tip": "Tip",
                "tip_received": "Ontvangen fooi (indien ingeschakeld)",
                "true": "Ja",
                "units": {
                    "kilometer": "kilometer | kilometer",
                    "km": "km",
                    "mi": "mi",
                    "mile": "mijl | mijlen"
                },
                "welcome": "Welkom",
                "yes": "Ja"
            },
            "cookies": {
                "details": {
                    "content": "Hier is de lijst met cookies die in dit gedeelte van de UgoZER-applicatie zijn geplaatst op basis van de\n            hierboven gedefinieerde categorieën:",
                    "table": {
                        "headers": {
                            "category": "Gebruikscategorie",
                            "description": "Beschrijving",
                            "lifespan": "Levenslang",
                            "mandatory": "Verplicht",
                            "name": "Cookienaam (of patroon)"
                        },
                        "lines": {
                            "alphanumeric": {
                                "content": "Bevat informatie over de sessie.",
                                "lifespan": "twee uur",
                                "title": "[[{alnum}:]] (reeks alfanumerieke tekens)"
                            },
                            "gmaps": {
                                "content": "Wordt gebruikt voor het weergeven van Google Maps iframe.",
                                "lifespan": "180-400 dagen",
                                "title": "CONSENT, AEC, SOCS... (of andere google.com-domeincookies)"
                            },
                            "remember": {
                                "content": "Wordt gebruikt voor het behouden van de authenticatie van de gebruiker.",
                                "lifespan": "400 dagen",
                                "title": "onthoud_web_*"
                            },
                            "session": {
                                "content": "Bevat informatie over de sessie.",
                                "lifespan": "twee uur",
                                "title": "*_ugozer_sessie"
                            },
                            "tarteaucitron": {
                                "content": "Hiermee kunt u de cookie-instellingen van de gebruiker behouden.",
                                "lifespan": "365 dagen",
                                "title": "tarteaucitroen"
                            },
                            "xsrf": {
                                "content": "Maakt het mogelijk om de applicatie te beveiligen (aanvragen, formulieren...).",
                                "lifespan": "twee uur",
                                "title": "XSRF-TOKEN-*"
                            }
                        }
                    },
                    "title": "Details van geplaatste cookies"
                },
                "management": {
                    "content": {
                        "cta": "Wijzig mijn voorkeuren",
                        "line_1": "Sommige functies van deze site zijn afhankelijk van het gebruik van cookies.",
                        "line_2": "De cookiesbanner verschijnt tijdens uw navigatie niet op de startpagina als dat alleen maar nodig is\n                voor het functioneren van de site worden gedeponeerd.",
                        "line_3": "U kunt uw voorkeuren wijzigen door op de onderstaande knop te klikken:"
                    },
                    "title": "Cookiebeheer"
                },
                "notice": {
                    "needed": "Om deze sectie te zien, moet u cookies {name} accepteren.",
                    "refresh": "Als u het na het accepteren van cookies niet ziet, vernieuw dan de pagina.",
                    "title": "Koekjes"
                },
                "services": {
                    "gmaps": "Google Maps",
                    "youtube": "YouTube"
                },
                "usage": {
                    "content": "Cookies kunnen worden gebruikt om u te herkennen wanneer u UgoZER bezoekt, uw voorkeuren te onthouden en te geven\n            u een persoonlijke ervaring die aansluit bij uw instellingen. Cookies maken ook uw interacties met\n            UgoZER sneller en veiliger. Bovendien zorgen cookies ervoor dat we u zowel op als buiten de website advertenties kunnen aanbieden\n            UgoZER-sites.",
                    "table": {
                        "headers": {
                            "category": "Gebruikscategorie",
                            "description": "Beschrijving"
                        },
                        "lines": {
                            "ads": {
                                "content": "We kunnen cookies gebruiken om u relevante advertenties te tonen, zowel op als buiten de UgoZER-site.\n                        We kunnen ook een cookie gebruiken om te achterhalen of iemand die een advertentie heeft gezien, deze later heeft bezocht en heeft bekeken\n                        actie (bijvoorbeeld een whitepaper downloaden of een aankoop doen) op de site van de adverteerder.\n                        Op dezelfde manier kunnen onze partners een cookie gebruiken om te bepalen of we een advertentie hebben getoond en hoe deze wordt weergegeven\n                        uitgevoerd, of geef ons informatie over hoe u met advertenties omgaat. Wij mogen ook werken\n                        met een partner om u een advertentie op of buiten UgoZER te laten zien, bijvoorbeeld nadat u die van een partner heeft bezocht\n                        website of applicatie.",
                                "title": "Reclame"
                            },
                            "authentication": {
                                "content": "Als u bent aangemeld bij UgoZER, helpen cookies ons u de juiste informatie te tonen en\n                        personaliseer uw ervaring.",
                                "title": "Authenticatie"
                            },
                            "security": {
                                "content": "We gebruiken cookies om onze beveiligingsfuncties in te schakelen en te ondersteunen, en om ons te helpen bij het detecteren\n                        kwaadaardige activiteiten en schendingen van onze",
                                "cta": "Voorwaarden.",
                                "title": "Beveiliging"
                            },
                            "services": {
                                "content": "Cookies bieden u functies, inzichten en op maat gemaakte inhoud.",
                                "title": "Voorkeuren, functies en services"
                            }
                        }
                    },
                    "title": "Waar worden cookies voor gebruikt?"
                }
            },
            "countries": {
                "AD": "Andorra",
                "AE": "Verenigde Arabische Emiraten",
                "AF": "Afghanistan",
                "AG": "Antigua en Barbuda",
                "AI": "Anguilla",
                "AL": "Albanië",
                "AM": "Armenië",
                "AO": "Angola",
                "AQ": "Antarctica",
                "AR": "Argentinië",
                "AS": "Amerikaans Samoa",
                "AT": "Oostenrijk",
                "AU": "Australië",
                "AW": "Aruba",
                "AX": "Aland-eilanden",
                "AZ": "Azerbeidzjan",
                "BA": "Bosnië & Herzegovina",
                "BB": "Barbados",
                "BD": "Bangladesh",
                "BE": "België",
                "BF": "Burkina Faso",
                "BG": "Bulgarije",
                "BH": "Bahrein",
                "BI": "Burundi",
                "BJ": "Benin",
                "BL": "St. Barthélemy",
                "BM": "Bermuda",
                "BN": "Brunei",
                "BO": "Bolivia",
                "BQ": "Caribisch Nederland",
                "BR": "Brazilië",
                "BS": "Bahamas",
                "BT": "Bhutan",
                "BV": "Bouvet-eiland",
                "BW": "Botswana",
                "BY": "Wit-Rusland",
                "BZ": "Belize",
                "CA": "Canada",
                "CC": "Cocoseilanden (Keeling).",
                "CD": "Congo-Kinshasa",
                "CF": "Centraal Afrikaanse Republiek",
                "CG": "Congo-Brazzaville",
                "CH": "Zwitserland",
                "CI": "Ivoorkust",
                "CK": "Cook Eilanden",
                "CL": "Chili",
                "CM": "Kameroen",
                "CN": "China",
                "CO": "Colombia",
                "CR": "Costa Rica",
                "CU": "Cuba",
                "CV": "Kaapverdië",
                "CW": "Curacao",
                "CX": "Kersteiland",
                "CY": "Cyprus",
                "CZ": "Tsjechië",
                "DE": "Duitsland",
                "DJ": "Djibouti",
                "DK": "Denemarken",
                "DM": "Dominica",
                "DO": "Dominicaanse Republiek",
                "DZ": "Algerije",
                "EC": "Ecuador",
                "EE": "Estland",
                "EG": "Egypte",
                "EH": "Westelijke Sahara",
                "ER": "Eritrea",
                "ES": "Spanje",
                "ET": "Ethiopië",
                "FI": "Finland",
                "FJ": "Fiji",
                "FK": "Falkland Eilanden",
                "FM": "Micronesië",
                "FO": "Faeröer eilanden",
                "FR": "Frankrijk",
                "GA": "Gabon",
                "GB": "Verenigd Koninkrijk",
                "GD": "Grenada",
                "GE": "Georgië",
                "GF": "Frans-Guyana",
                "GG": "Guernsey",
                "GH": "Ghana",
                "GI": "Gibraltar",
                "GL": "Groenland",
                "GM": "Gambia",
                "GN": "Guinea",
                "GP": "Guadeloupe",
                "GQ": "Equatoriaal-Guinea",
                "GR": "Griekenland",
                "GS": "Zuid-Georgië en Zuid-Sandwicheilanden",
                "GT": "Guatemala",
                "GU": "Guam",
                "GW": "Guinee-Bissau",
                "GY": "Guyana",
                "HK": "Hongkong SAR China",
                "HM": "Heard- en McDonaldeilanden",
                "HN": "Honduras",
                "HR": "Kroatië",
                "HT": "Haïti",
                "HU": "Hongarije",
                "ID": "Indonesië",
                "IE": "Ierland",
                "IL": "Israël",
                "IM": "Eiland Man",
                "IN": "Indië",
                "IO": "Brits-Indisch oceaan gebied",
                "IQ": "Irak",
                "IR": "Iran",
                "IS": "IJsland",
                "IT": "Italië",
                "JE": "Jersey",
                "JM": "Jamaica",
                "JO": "Jordanië",
                "JP": "Japan",
                "KE": "Kenia",
                "KG": "Kirgizië",
                "KH": "Cambodja",
                "KI": "Kiribati",
                "KM": "Comoren",
                "KN": "St. Kitts en Nevis",
                "KP": "Noord Korea",
                "KR": "Zuid-Korea",
                "KW": "Koeweit",
                "KY": "Kaaiman Eilanden",
                "KZ": "Kazachstan",
                "LA": "Laos",
                "LB": "Libanon",
                "LC": "St Lucia",
                "LI": "Liechtenstein",
                "LK": "Sri Lanka",
                "LR": "Liberia",
                "LS": "Lesotho",
                "LT": "Litouwen",
                "LU": "Luxemburg",
                "LV": "Letland",
                "LY": "Libië",
                "MA": "Marokko",
                "MC": "Monaco",
                "MD": "Moldavië",
                "ME": "Montenegro",
                "MF": "Sint Maarten",
                "MG": "Madagascar",
                "MH": "Marshall eilanden",
                "MK": "Noord-Macedonië",
                "ML": "Mali",
                "MM": "Myanmar (Birma)",
                "MN": "Mongolië",
                "MO": "Macau SAR China",
                "MP": "noordelijke Mariana eilanden",
                "MQ": "Martinique",
                "MR": "Mauritanië",
                "MS": "Montserrat",
                "MT": "Malta",
                "MU": "Mauritius",
                "MV": "Maldiven",
                "MW": "Malawi",
                "MX": "Mexico",
                "MY": "Maleisië",
                "MZ": "Mozambique",
                "NA": "Namibië",
                "NC": "Nieuw-Caledonië",
                "NE": "Niger",
                "NF": "Norfolkeiland",
                "NG": "Nigeria",
                "NI": "Nicaragua",
                "NL": "Nederland",
                "NO": "Noorwegen",
                "NP": "Nepal",
                "NR": "Nauru",
                "NU": "Niue",
                "NZ": "Nieuw-Zeeland",
                "OM": "Oman",
                "PA": "Panama",
                "PE": "Peru",
                "PF": "Frans-Polynesië",
                "PG": "Papoea-Nieuw-Guinea",
                "PH": "Filippijnen",
                "PK": "Pakistan",
                "PL": "Polen",
                "PM": "St. Pierre & Miquelon",
                "PN": "Pitcairneilanden",
                "PR": "Puerto Rico",
                "PS": "Palestijnse grondgebieden",
                "PT": "Portugal",
                "PW": "Palau",
                "PY": "Paraguay",
                "QA": "Katar",
                "RE": "Bijeenkomst",
                "RO": "Roemenië",
                "RS": "Servië",
                "RU": "Rusland",
                "RW": "Rwanda",
                "SA": "Saoedi-Arabië",
                "SB": "Solomon eilanden",
                "SC": "Seychellen",
                "SD": "Soedan",
                "SE": "Zweden",
                "SG": "Singapore",
                "SH": "Sint-Helena",
                "SI": "Slovenië",
                "SJ": "Spitsbergen en Jan Mayen",
                "SK": "Slowakije",
                "SL": "Sierra Leone",
                "SM": "San Marino",
                "SN": "Senegal",
                "SO": "Somalië",
                "SR": "Suriname",
                "SS": "Zuid Soedan",
                "ST": "Sao Tomé en Principe",
                "SV": "El Salvador",
                "SX": "Sint Maarten",
                "SY": "Syrië",
                "SZ": "Swaziland",
                "TC": "Turks- en Caicoseilanden",
                "TD": "Tsjaad",
                "TF": "Franse zuidelijke gebieden",
                "TG": "Gaan",
                "TH": "Thailand",
                "TJ": "Tadzjikistan",
                "TK": "Tokelau",
                "TL": "Oost-Timor",
                "TM": "Turkmenistan",
                "TN": "Tunesië",
                "TO": "Tonga",
                "TR": "Kalkoen",
                "TT": "Trinidad & Tobago",
                "TV": "Tuvalu",
                "TW": "Taiwan",
                "TZ": "Tanzania",
                "UA": "Oekraïne",
                "UG": "Oeganda",
                "UM": "Amerikaanse afgelegen eilanden",
                "US": "Verenigde Staten",
                "UY": "Uruguay",
                "UZ": "Oezbekistan",
                "VA": "Vaticaanstad",
                "VC": "St. Vincent en de Grenadines",
                "VE": "Venezuela",
                "VG": "Britse Maagdeneilanden",
                "VI": "Amerikaanse Maagdeneilanden",
                "VN": "Vietnam",
                "VU": "Vanuatu",
                "WF": "Wallis & Futuna",
                "WS": "Samoa",
                "XK": "Kosovo",
                "YE": "Jemen",
                "YT": "Mayotte",
                "ZA": "Zuid-Afrika",
                "ZM": "Zambia",
                "ZW": "Zimbabwe"
            },
            "credits": {
                "images": "Foto's credits"
            },
            "csv": {
                "common": {
                    "generating": "CSV-bestand genereren...",
                    "waiting": "Even geduld aub..."
                },
                "download": "CSV-export"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "Hotelfavorieten"
                },
                "links": {
                    "closest_pois": "Dichtstbijzijnde punten",
                    "excursions": "Routes en wandelingen",
                    "shares": "Aandelen"
                },
                "no_dashboard": {
                    "contact": {
                        "link": "Neem contact met ons op.",
                        "text": "Als u denkt dat dit een vergissing is, aarzel dan niet om dat te doen"
                    },
                    "content": "Er is geen dashboard ingesteld voor uw rol of u bent verbonden als beheerder.",
                    "title": "Geen beschikbaar dashboard"
                },
                "points_of_interest": {
                    "categories": "Categorieën van interessante plaatsen"
                }
            },
            "documents": {
                "display": {
                    "description": "Weergave in A6-formaat met koppeling van QRCode en het logo van uw instelling.",
                    "title": "Display - A6-kaart"
                },
                "qrcode": {
                    "description": "Alleen QRCode waarmee de reiziger rechtstreeks aan uw instelling kan worden gekoppeld.",
                    "filename": "QR code",
                    "title": "QR-code koppelen"
                }
            },
            "easy_transac": {
                "account": {
                    "create": {
                        "button": "Maak een EasyTransac-account aan",
                        "fail": "Verbinding met EasyTransac mislukt, probeer het later opnieuw",
                        "success": "Uw EasyTransac-account is succesvol aangemaakt"
                    },
                    "show": {
                        "account_usage": "Met uw EasyTransac-account kan uw instelling betalingen ontvangen op UgoZER.",
                        "can_receive_payments": "Uw klanten kunnen nu uw etablissement een fooi geven\n                uit het UgoZER-profiel van uw conciërges.",
                        "can_retrieve_payments": {
                            "description": "U moet uw EasyTransac-account compleet maken met de gevraagde gegevens\n                    om het beschikbare geld op uw rekening te gebruiken.",
                            "title": "Uw EasyTransac-account is onvolledig"
                        },
                        "cannot_receive_payments": "U kunt geen betalingen ontvangen",
                        "credentials_sent_mail": "Uw EasyTransac-inloggegevens zijn per e-mail naar u verzonden.",
                        "fee_percentage": "Voor elke betaling die u ontvangt, worden kosten in rekening gebracht.",
                        "go_to_easy_transac": "Toegang tot het EasyTransac-dashboard",
                        "must_subscribe": "U moet geabonneerd zijn om uw EasyTransac-account aan te maken.",
                        "no_account_yet": "U heeft nog geen EasyTransac-account.",
                        "should_complete_kyc": "Stuur de door EasyTransac gevraagde documenten mee,\n                om betalingen te ontvangen."
                    }
                },
                "easy_transac": "EasyTransac"
            },
            "exceptions": {
                "default": "Er is een fout opgetreden",
                "error_texts": {
                    "403": {
                        "link": {
                            "title": "Misschien wilt u toegang krijgen tot",
                            "to_app_label": "reizigersgedeelte?",
                            "to_extranet_label": "professionele sectie?"
                        },
                        "message": "U heeft geen toegang tot deze pagina.",
                        "title": "Oeps! Toegang verboden."
                    },
                    "credential_not_verified": {
                        "title": "Uw inloggegevens zijn niet geverifieerd",
                        "verify_mail": "Ontvang een verificatielink voor mijn e-mail",
                        "verify_phone": "Ontvang een verificatielink voor mijn telefoonnummer"
                    },
                    "opening_hours": {
                        "invalid": "De opgegeven waarde is geen instantie OpeningHours."
                    },
                    "password_missing": {
                        "app": "Stel een wachtwoord in.",
                        "extranet": "Stel een wachtwoord in om toegang te krijgen tot de applicatie."
                    }
                },
                "spam_detected": "Spam gedetecteerd"
            },
            "excursions": {
                "destroy": {
                    "confirm": "Wil je dit reisschema echt verwijderen: {excursion_name}?",
                    "success": "Het reisschema is verwijderd"
                },
                "direction": {
                    "bicycling": "fietsen",
                    "display_mode": "Weergavemodus",
                    "driving": "het rijden",
                    "walking": "lopen"
                },
                "form": {
                    "all_points_of_interest": "Alle aandachtspunten",
                    "create_new_point_of_interest": "Maak een nieuw interessant punt aan",
                    "existing_excursions": "Bestaande routes",
                    "from_existing_excursions": "Vanuit een bestaand reisschema",
                    "order_hint": "De volgorde van de bezienswaardigheden komt overeen met de volgorde van het bezoek.",
                    "points_of_interest": "Interessante punten",
                    "select_from_points_of_interest": "Maak een keuze uit interessante plaatsen",
                    "your_excursion": "Jouw route"
                },
                "index": {
                    "alert": {
                        "content": "Je kunt alleen routes bekijken. Als u een reisplan wilt maken of delen, moet u zich aanmelden met\n                een <strong>conciërge-/receptionistaccount</strong>",
                        "title": "Routebeschrijving uitsluitend in overleg"
                    },
                    "no_results": "Je hebt nog geen reisroutes",
                    "no_results_here": "Er zijn geen routes beschikbaar in uw zoekgebied.",
                    "read_only": "Alleen overleg",
                    "search": "Zoek een routebeschrijving",
                    "try_more_filters": "U kunt uw zoekopdracht uitbreiden of een reisplan maken.",
                    "your_excursions": "Jouw routes"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "Routebeschrijving",
                            "pois": "Interessante punten"
                        }
                    }
                },
                "search": {
                    "no_results": "Geen route gevonden"
                },
                "share": {
                    "contains_private": "Deze route bevat privé bezienswaardigheden. Zij mogen niet in het aandeel worden opgenomen.",
                    "heading": "Deel deze reisroute",
                    "select_which_contacts": "Selecteer de contacten waarmee u de reisroute \"{excursie_titel}\" wilt delen",
                    "select_which_users": "Selecteer de gebruikers met wie u de reisroute \"{excursie_titel}\" wilt delen of voer deze in\n            een e-mailadres om een ​​nieuwe reiziger uit te nodigen",
                    "select_which_users_alt": "Voer het e-mailadres in van de persoon met wie u het bericht wilt delen\n            Reisroute \"{excursie_titel}\".",
                    "success": "De route is gedeeld!"
                },
                "store": {
                    "create": "Maak een reisplan",
                    "heading": "Maak een reisplan",
                    "success": "Het reisschema is gemaakt"
                },
                "update": {
                    "heading": "Werk dit reisschema bij",
                    "success": "Het reisschema is bijgewerkt"
                }
            },
            "extranet": {
                "dashboard": {
                    "last_claims": {
                        "none": "U heeft nog geen claims.",
                        "see_all": "Bekijk al uw claims",
                        "see_index": "Ga naar de claimpagina"
                    },
                    "last_payments": {
                        "none": "U heeft nog geen betalingen.",
                        "see_all": "Bekijk al uw betalingen",
                        "see_index": "Ga naar de betaalpagina"
                    },
                    "last_reservations": {
                        "none": "U heeft nog geen reserveringen."
                    },
                    "last_traveller_folders": {
                        "none": "U heeft nog geen reiziger onder uw hoede.",
                        "see_all": "Bekijk al uw reizigers",
                        "take_care": "Zorg voor een reiziger"
                    },
                    "manager": {
                        "sections": {
                            "butlers_institutions": "Conciërges van mijn instellingen",
                            "butlers_interactions": "Conciërges met interacties"
                        }
                    },
                    "sections": {
                        "last_claims": "Je laatste beweringen",
                        "last_payments": "Uw laatste betalingen",
                        "last_reservations": "Uw laatste reserveringen",
                        "last_travellers": "Je laatste reizigers",
                        "search_butler": "Zoek een conciërge",
                        "stats": "Statistieken"
                    },
                    "stats": {
                        "butlers": "Conciërge|Conciërges",
                        "employees": "Werknemer|Werknemers",
                        "excursions": "Reisroute|Reisroutes",
                        "folders": "Map|Mappen",
                        "institutions": "Gekoppelde instelling|Gekoppelde instellingen",
                        "processing_claims": "Claim in behandeling|Clamatie in behandeling",
                        "reservations": "Reservering|Reserveringen"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "Nodig een medewerker uit",
                        "new-hotelier": "Maak een ontvangstaccount aan",
                        "new-tourist-office": "Maak een bureauaccount aan"
                    }
                },
                "excursions": {
                    "actions": {
                        "new": "Maak een reisplan"
                    },
                    "sections": {
                        "excursions": "Routes"
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "Categorieën"
                    }
                },
                "filters": {
                    "closed": "Sluit filters",
                    "open": "Filters openen"
                },
                "finances": {
                    "alerts": {
                        "easytransac": {
                            "cta": "Ga naar mijn profiel om een ​​EasyTransac-account aan te maken",
                            "description": "U heeft nog geen EasyTransac-account aangemaakt! U kunt dus geen betalingen ontvangen.",
                            "title": "EasyTransac-account bestaat niet!"
                        }
                    },
                    "sections": {
                        "issued_payments": "Uitgegeven betalingen",
                        "received_by_institution_payments": "Betalingen ontvangen door uw instellingen",
                        "received_payments": "Ontvangen betalingen"
                    }
                },
                "institution": {
                    "messages": {
                        "no_records": "Er is momenteel geen instelling gekoppeld"
                    },
                    "sections": {
                        "butler": {
                            "institution": "Mijn instelling"
                        },
                        "manager": {
                            "institution": "Mijn instellingen"
                        }
                    }
                },
                "navbar": {
                    "contact": "Neem contact met ons op",
                    "contacts": "Contacten",
                    "dashboard": "Dashboard",
                    "excursions": "Routes",
                    "faq": "FAQ",
                    "finances": "Financiën",
                    "invitations": "Uitnodigingen",
                    "my_employees": "Mijn medewerkers",
                    "my_institution": "Mijn instelling",
                    "my_institutions": "Mijn instellingen",
                    "points_of_interest": "Interessante punten",
                    "travellers": "Reizigers",
                    "tutorials": "Beginnen",
                    "users": "Gebruikers"
                },
                "pages": {
                    "excursions": {
                        "edit": "Reisplan bewerken",
                        "new": "Nieuwe route"
                    },
                    "folders": {
                        "edit": "Reizigersmap bewerken",
                        "new": "Nieuwe reizigersmap"
                    },
                    "institutions": {
                        "new": "Koppel een instelling"
                    },
                    "pois": {
                        "edit": "Bezienswaardigheid bewerken",
                        "new": "Nieuw aandachtspunt"
                    },
                    "profile": {
                        "edit": "Bewerk profiel"
                    },
                    "reservations": {
                        "edit": "Reservering bewerken",
                        "new": "Nieuwe reservering"
                    }
                },
                "points_of_interest": {
                    "actions": {
                        "new": "Creëer een aandachtspunt"
                    },
                    "sections": {
                        "points_of_interest": "Interessante punten"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "Er zijn momenteel geen veelgestelde vragen"
                    }
                },
                "professional_section": "Professionele sectie",
                "profile": {
                    "sections": {
                        "currency": "Munteenheid",
                        "delete_account": "Accountverwijdering",
                        "infos": "Algemene informatie",
                        "locale": "Taal",
                        "notification_settings": "Instellingen voor meldingen",
                        "payment": "Betaling",
                        "privacy_settings": "Privacy instellingen",
                        "shares": "Aandelen"
                    }
                },
                "support_messages": {
                    "actions": {
                        "back": "Terug naar het ondersteuningsverzoek",
                        "new": "Voeg een nieuw bericht toe"
                    },
                    "sections": {
                        "support_messages": "Berichten"
                    }
                },
                "support_requests": {
                    "actions": {
                        "back": "Rug",
                        "new": "Maak een ondersteuningsverzoek aan"
                    },
                    "sections": {
                        "support_requests": "Ondersteuningsverzoeken"
                    }
                },
                "travellers": {
                    "buttons": {
                        "new_folder": "Nieuwe reizigersmap toevoegen"
                    },
                    "sections": {
                        "folders": "Mijn reizigersmappen"
                    }
                },
                "user_dropdown": {
                    "changelogs": "Wijzigingslogboeken",
                    "cookies": "Cookiebeheer",
                    "credits": "Kredieten",
                    "customers": "Klanten",
                    "partners": "Partners",
                    "privacy_policy": "Privacybeleid",
                    "profile": "Mijn profiel",
                    "sign_out": "Afmelden",
                    "subscription": "Abonnement",
                    "terms_condition": "Voorwaarden"
                }
            },
            "form": {
                "file": {
                    "current_file": "Werkelijk bestand: {name}"
                },
                "hints": {
                    "auth": {
                        "register": {
                            "email": "Dit e-mailadres mag niet al aan een UgoZER-account zijn gekoppeld",
                            "employee": {
                                "not_duplicate_email": "Het e-mailadres moet anders zijn dan dat van de",
                                "not_duplicate_password": "Het wachtwoord moet anders zijn dan dat van de"
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "Op maat: reisschema opgesteld door de conciërge\n                        voor een specifieke reiziger",
                                "root": "Mijn sjablonen: \"klaar om te delen\" reisschema bestaande uit must-see bezienswaardigheden,\n                        of bezienswaardigheden per thema\n                        (voorbeeld: lijst met restaurants die vaak in reisroutes voorkomen)\n                        te selecteren om een ​​nieuw reisplan te maken.",
                                "ugozer_app": "UgoZER-app: route gemaakt en voorgesteld door UgoZER\n                        en beschikbaar gesteld aan reizigers.",
                                "ugozer_pro": "UgoZER Pro: route gemaakt en voorgesteld door UgoZER\n                        en beschikbaar gesteld aan professionals om hun werk te vergemakkelijken."
                            },
                            "pro": {
                                "customized": "Reisplan gemaakt voor een specifieke reiziger",
                                "root": "\"klaar om te delen\"-route bestaande uit must-see bezienswaardigheden,\n                        of bezienswaardigheden per thema\n                        (voorbeeld: lijst met restaurants die vaak in reisroutes voorkomen)\n                        te selecteren om een ​​nieuw reisplan te maken.",
                                "ugozer": "Reisplan gemaakt en voorgesteld door UgoZER."
                            }
                        },
                        "points_of_interest_search": "Zoek en selecteer een interessant punt om het aan de lijst toe te voegen"
                    },
                    "excursions_filters": {
                        "radius": "Je ziet alleen reisroutes met minstens één bijzondere bestemming binnen deze straal."
                    },
                    "geoloc_setting": {
                        "filter_header": "Zoek naar een land, regio, stad of buurt",
                        "kind": {
                            "admin": {
                                "around_me": "Om mij heen: de geolocatie wordt bepaald\n                        door de browser van de gebruiker.",
                                "customized": "Aangepast: de gebruiker kan handmatig naar een plaats zoeken.",
                                "staying_hotel": "Hotelverblijf: de geolocatie wordt uitgevoerd\n                        van het hotel waar de reiziger verblijft."
                            },
                            "app": {
                                "around_me": "De geolocatie gebeurt vanuit uw positie.",
                                "customized": "De geolocatie gebeurt vanaf een locatie naar keuze.",
                                "staying_hotel": "De geolocatie gebeurt vanuit het hotel waar u verblijft: {title}."
                            }
                        }
                    },
                    "locale": {
                        "dynamic_translations_enabled": "Hiermee kunnen gebruikers bronnen weergeven of vertalen\n                (bezienswaardigheden, reisroutes...) in die taal",
                        "fallback_locale": "Geeft automatisch de titel in een tweede taal (vóór de beschrijving) weer\n                het POI-bestand.",
                        "static_translations_enabled": "Hiermee kunnen gebruikers deze taal kiezen om de applicatie weer te geven\n                (vereist dat vertalingen aan de broncode zijn toegevoegd)"
                    },
                    "permission": {
                        "name": "Naam gebruikt in de broncode"
                    },
                    "plan": {
                        "countries": "Laat dit leeg om het plan in alle landen beschikbaar te maken",
                        "information": "Aanvullende informatie over het plan (vereisten, voorwaarden...)",
                        "name": "Deze naam is zichtbaar voor potentiële abonnees\n                wanneer ze hun abonnement kiezen",
                        "private_title": "Deze titel is alleen zichtbaar in de administratie"
                    },
                    "plan_price": {
                        "description": "Deze beschrijving is zichtbaar voor potentiële abonnees\n                wanneer ze hun abonnement kiezen",
                        "price": "Gelieve in euro's in te vullen",
                        "private_title": "Deze titel is alleen zichtbaar in de administratie"
                    },
                    "point_of_interest": {
                        "audio_refreshing_1": "Als u het vernieuwen van het audiobestand inschakelt, wordt er automatisch een audiobestand gegenereerd\n                in de taal van het interessante punt ({taal}).",
                        "audio_refreshing_2": "Hierop worden de titel en het adres van de nuttige plaats vermeld.",
                        "audio_refreshing_3": "Het wordt vernieuwd zodra de taal, de titel\n                of het adres van de nuttige plaats worden bijgewerkt.",
                        "audio_refreshing_4": "Het vernieuwen wordt uitgeschakeld als u zelf een audiobestand toevoegt of opneemt,\n                of als u het gegenereerde audiobestand verwijdert.",
                        "cant_have_employees": "Hier ziet u alleen vestigingen die gekoppeld zijn aan categorieën die mogelijk hebben\n                medewerkers",
                        "expiry_date": "Uw nuttige plaats vervalt op {date}",
                        "expiry_date_details_1": "Uw nuttige plaats maakt deel uit van de categorie \"{categorie}\".",
                        "expiry_date_details_2": "Het vervalt dus op {date}, aangezien dit de laatste datum is die u heeft ingevoerd.",
                        "expiry_date_details_3": "Vanaf deze datum heeft uw nuttige plaats nog 21 dagen\n                voordat het automatisch wordt verwijderd..",
                        "expiry_date_details_4": "Je kunt altijd een nieuwe datum toevoegen\n                om de vervaldatum uit te stellen, of geef geen datum op, zodat uw interessante plaats niet vervalt.",
                        "images": "De afbeeldingen worden in de gekozen volgorde weergegeven in de galerij van de bezienswaardigheid,\n                je kunt ze reorganiseren door ze te slepen.",
                        "is_geocoding": "Deactiveer als de geolocatie correct is ingevoerd, maar de kaart nog steeds niet verschijnt.",
                        "line1": "U kunt het adres of de naam van de plaats die u zoekt invoeren. Kies uit de\n                lijst om het adres automatisch in te vullen",
                        "locale": "De belangrijkste taal die op dit interessante punt wordt gesproken",
                        "opening_dates": "Laat dit veld leeg als de schema's op een bepaalde datum van toepassing zijn",
                        "opening_days": "Selecteer niets als er op een bepaalde dag een schema van toepassing is",
                        "opening_hours": "Voeg niets toe als de bezienswaardigheid op elk moment geopend is",
                        "sync_1": "Met Automatische update activeren kunt u gegevens ophalen van de {import_source}\n                importeren",
                        "sync_2": "Handmatige invoer wordt overschreven"
                    },
                    "points_of_interest_filters": {
                        "order_distance": "Kies een afstandsfilter om deze optie te selecteren",
                        "order_relevance": "Voer zoekwoorden in om deze optie te selecteren",
                        "radius": "U ziet alleen nuttige plaatsen binnen deze straal."
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "butler": "Welke optie u ook kiest, uw achternaam en uw voornaam\n                    blijft zichtbaar voor de manager van uw instelling",
                            "traveller": "Welke optie u ook kiest, uw achternaam en uw voornaam\n                  blijft zichtbaar voor de conciërges van het hotel waar u verblijft."
                        }
                    },
                    "profile": {
                        "contact_email": "Deze e-mail kan worden weergegeven op uw openbare profiel",
                        "contact_phone": "Deze telefoon kan worden weergegeven op uw openbare profiel",
                        "name": "De bijnaam is openbaar en zorgt ervoor dat andere gebruikers van de applicatie u gemakkelijker kunnen identificeren.",
                        "password_template": {
                            "digit": "1 cijfer",
                            "length": "{length} tekens",
                            "lowercase": "1 kleine letter",
                            "uppercase": "1 hoofdletter"
                        },
                        "password_unchanged": "Laat dit leeg om ongewijzigd te blijven"
                    },
                    "role": {
                        "description": "De beschrijving van de rol wordt aan de gebruikers getoond wanneer zij de rol kiezen wanneer zij\n                schrijf je in voor het professionele gedeelte.",
                        "name": "Codenaam van de rol. Deze wordt niet weergegeven aan gebruikers."
                    },
                    "support_request": {
                        "support_categories_search": "Zoek en selecteer een categorie"
                    },
                    "translatable": {
                        "pro_global": "Eén of meerdere velden van dit formulier kunnen in verschillende talen worden ingevuld.",
                        "pro_global_2": "Velden die in bepaalde talen niet zijn ingevuld, worden automatisch vertaald.",
                        "pro_global_title": "Meertalige invoer"
                    },
                    "traveller_folder": {
                        "expired_on": "Standaard is de einddatum ingesteld op een week"
                    },
                    "user": {
                        "bank_account_number": "Vul het rekeningnummer in dat gebruikt zal worden voor de overboeking"
                    }
                },
                "labels": {
                    "geoloc_setting": {
                        "distance_filter": "afstandsfilter",
                        "limit_to_a_radius": "beperken tot een straal",
                        "limit_to_a_radius_from_center": "beperken tot een straal vanaf het midden",
                        "measure_unit": "meeteenheid",
                        "other_filters": "andere filters",
                        "where_does_the_traveller_go": "waar gaat de reiziger heen?",
                        "where_would_you_like_to_go": "waar zou je heen willen?",
                        "within_a_radius": "binnen een straal"
                    },
                    "payment": {
                        "payable_type": "Betaling gekoppeld aan"
                    },
                    "plan": {
                        "authorized_roles": "geautoriseerde rollen",
                        "tipping_fees": "Fooikosten (procent)"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "prijs exclusief belastingen"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "Ververs het audiobestand automatisch",
                        "opening": "opening",
                        "opening_date": "op",
                        "opening_date_from": "van",
                        "opening_date_range": "een einddatum opgeven",
                        "opening_date_to": "naar",
                        "opening_hours": "bij",
                        "opening_hours_from": "van",
                        "opening_hours_range": "geef een einduur op",
                        "opening_hours_to": "naar",
                        "opening_schedule_add": "dagen of uren opgeven",
                        "opening_schedule_add_alt": "uren opgeven",
                        "opening_specification_add": "voeg een punt toe"
                    },
                    "points_of_interest_filters": {
                        "geoloc": "plaats",
                        "order_distance": "op afstand",
                        "order_relevance": "op relevantie (zoekwoorden)",
                        "sort": "soort",
                        "tags": "verfijnen"
                    },
                    "register": {
                        "communication_consent": "Ik wil graag gepersonaliseerde communicatie ontvangen, inclusief aanbiedingen,\n                promotionele informatie en reisgerelateerde producten van UgoZER via e-mail.",
                        "privacy_policy": {
                            "part1": "Ik ga akkoord met de",
                            "part2": "privacybeleid"
                        },
                        "terms_conditions": {
                            "part1": "Ik ga akkoord met de",
                            "part2": "voorwaarden"
                        }
                    },
                    "support_category": {
                        "title": "Kies een categorie"
                    },
                    "support_message": {
                        "content": "Bericht",
                        "email": "E-mailadres"
                    },
                    "support_request": {
                        "title": "Onderwerp van het verzoek"
                    },
                    "traveller_invitation": {
                        "locale": "taal gesproken door deze reiziger"
                    },
                    "user": {
                        "credential": "e-mail",
                        "email": "e-mail",
                        "image": {
                            "_destroy": "Profielfoto verwijderen"
                        },
                        "new_password": "nieuw paswoord",
                        "notes": "verdere informatie"
                    },
                    "vat_rate": {
                        "vat_rate": "Btw-tarief (percentage)"
                    }
                },
                "placeholders": {
                    "audio_file_name": "naam van het audiobestand",
                    "name": "bijnaam",
                    "support_message": {
                        "create_message": "Uw bericht voor de ondersteuningsaanvraag"
                    }
                },
                "select": {
                    "no_options": "Geen opties",
                    "no_results": "Sorry, geen overeenkomende opties.",
                    "type_to_search": "Zoekopdracht..."
                },
                "titles": {
                    "verifications": "Uw gegevens controleren"
                }
            },
            "front": {
                "access_pro": "Ga naar de professionele toegang",
                "app": "Reizigers-app",
                "brand_name": "UgoZER",
                "captcha": {
                    "and": "En",
                    "apply": "toepassen.",
                    "privacy_policy": "Privacybeleid",
                    "protected": "Deze site wordt beschermd door hCaptcha en zijn",
                    "terms_service": "Servicevoorwaarden"
                },
                "contact": {
                    "cancel": "Annuleer het ondersteuningsverzoek",
                    "contact": "Contact",
                    "send": "Versturen"
                },
                "cookies": {
                    "title": "Cookiebeheer"
                },
                "customers": {
                    "see_customers": "Bekijk onze klanten",
                    "title": "Ze vertrouwen ons"
                },
                "footer": {
                    "account_deletion": "Accountverwijdering",
                    "app": "Reizigers-app",
                    "contact": "Contact",
                    "cookies": "Cookiebeheer",
                    "copyright": "© {year} UgoZER - Alle rechten voorbehouden.",
                    "facebook": "Facebook",
                    "faq": "FAQ",
                    "home": "Thuis",
                    "instagram": "Instagram",
                    "legal_notice": "Juridische mededeling",
                    "linkedin": "Linkedin",
                    "privacy_policy": "Privacybeleid",
                    "pro": "Professionele toegang",
                    "sitemap": "Sitemap",
                    "terms_conditions": "Voorwaarden",
                    "twitter": "Twitteren"
                },
                "header": {
                    "app": "Reizigers-app",
                    "contact": "Contact",
                    "faq": "FAQ",
                    "home": "Thuis",
                    "pro": "Professionele toegang",
                    "you_are": "Jij bent"
                },
                "intro": {
                    "intro_1": "Met UgoZER heeft u altijd uw favoriete adressen op uw smartphone in meertalige bestanden,\n            hoorbaar of leesbaar, met een klik.",
                    "intro_2": "Als u in het buitenland bent, zorg er dan voor dat taxichauffeurs en andere mensen uw bestemming gemakkelijk begrijpen.",
                    "intro_3": "Receptioniste, hotelmanager, ontwerp en bied tourprogramma's aan. Deel ze met uw klanten\n            op UgoZER.",
                    "intro_title_pro": "PROFESSIONALS",
                    "intro_title_traveller": "REIZIGER",
                    "title_homepage": "De meertalige toeristische gids, gratis."
                },
                "navigation": {
                    "next": "Volgende",
                    "previous": "Vorig"
                },
                "outro": {
                    "description": "Reizen in 2.0"
                },
                "partners": {
                    "no_records": "Er zijn momenteel geen partners",
                    "read_more": "Lees verder",
                    "see_partners": "Bekijk onze partners",
                    "title": "Partners"
                },
                "posts": {
                    "no_records": "Er zijn momenteel geen berichten",
                    "title": "FAQ."
                },
                "pro": "Professionele toegang",
                "professionals": {
                    "argument_1": {
                        "part_1": "UgoZER biedt een digitale oplossing voor uw gasten.",
                        "part_2": "Verbeter hun verblijf en hun gastervaring.",
                        "part_3": "Het stelt uw hotel, resort of informatiecentrum in staat om gepersonaliseerde, gebruiksvriendelijke,\n                meertalige mobiele routes.",
                        "part_4": "Deel op maat gemaakte reizen vanuit één realtime POI-database via het professionele UgoZER-platform\n                en zijn mobiele app."
                    },
                    "argument_2": {
                        "part_1": "U kunt voor uw gasten voorverpakte routes selecteren.",
                        "part_2": "Creëer de jouwe met de beste plekken of restaurants in de stad, verborgen juweeltjes om te ontdekken, perfect\n                wandelpaden om van te genieten...",
                        "part_3": "Of kies elke stap van hun bezoek uit een uitgebreide lijst met POI's om ze te combineren\n                zal en deel vervolgens een persoonlijk reisschema."
                    },
                    "argument_3": {
                        "part_1": "Elke POI beschikt over een gedetailleerde beschrijving, zijn positie op een interactieve kaart en zijn\n                adres automatisch vertaald in de lokale taal.",
                        "part_2": "Uw gast zal nooit verloren gaan. Hij zal altijd zijn weg vinden en gemakkelijk bij je terugkomen\n                hotel."
                    },
                    "argument_4": {
                        "part_1": "Aan het einde van zijn reis, na te hebben genoten van een reisprogramma op maat, kan uw gast dat doen\n                bedank uw conciërge of receptioniste voor zijn unieke aanbeveling door hem een ​​fooi te geven.",
                        "part_2": "Door UgoZER te gebruiken, verbetert u de retentie door hogere inkomsten en vermindert u het personeelsbestand\n                afzet.",
                        "part_3": "U zult ze een grotere motivatie geven om betrokken te raken bij het dagelijkse leven van uw hotel\n                en geef ze het gevoel erbij te horen."
                    },
                    "intro": {
                        "main_title": "UgoZER, de meertalige digitale en persoonlijke reisassistent. Neem je gast\n                ervaring naar een hoger niveau tillen en uw omzet verhogen."
                    },
                    "outro": {
                        "catchphrase": "UgoZER helpt u de tevredenheid te vergroten en de groei te stimuleren."
                    }
                },
                "startup": {
                    "description_1": "UgoZER is een Frans bedrijf, gevestigd in Straatsburg, de hoofdstad van Europa. Gemaakt door reizen\n            liefhebbers, het vergemakkelijkt uw reizen in landen waar u de taal niet spreekt.",
                    "description_2": "Het wordt aanbevolen door de VVV-kantoren.",
                    "title": "De startup UgoZER"
                },
                "support_request": "Ondersteuningsverzoek",
                "travellers": {
                    "argument_1": {
                        "arguments": {
                            "audio": "<strong>audioadres</strong> in de lokale taal om naar een taxi of een voorbijganger te luisteren",
                            "desc": "<strong>gedetailleerde beschrijving</strong>",
                            "languages": "Alle informatie is vertaald in <strong>Frans, Engels, Duits, Nederlands, Portugees,\n                    Spaans, Italiaans, Koreaans, Chinees, Japans en Arabisch.</strong>",
                            "map": "<strong>locatie op een interactieve kaart, uitvoer op Google Maps™ mogelijk</strong>"
                        },
                        "part_1": "Krijg toegang tot alle nuttige punten (POI's) die in realtime zijn bijgewerkt op uw smartphone.",
                        "part_2": "POI's ingedeeld in <strong>categorieën: monumenten, musea, tentoonstellingen, winkels, restaurants,\n                bars, tijdelijke evenementen...</strong>",
                        "part_3": "POI's geleverd met:"
                    },
                    "argument_2": {
                        "part_1": "Geniet van uw reis met permanente toegang tot volledige informatie. Alles op uw smartphone,\n                gewoon in je zak."
                    },
                    "argument_3": {
                        "part_1": "Met UgoZER krijgt u automatisch gratis reisroutes. Je kunt de reizen ook maken en delen\n                ze met je vrienden. Haal het meeste uit uw reis door gepersonaliseerde rondleidingen van uw lokale partner te krijgen\n                deskundige: hotelconciërge of receptioniste, VVV-kantoor.",
                        "part_2": "Als uw reis u beviel, kunt u zelfs uw lokale expert bedanken met een gedigitaliseerd en beveiligd bericht\n                fooi optie."
                    },
                    "intro": {
                        "main_title": "Uw gratis meertalige toeristische gids"
                    },
                    "outro": {
                        "catchphrase": "Met UgoZER laat u zich veilig begeleiden en reist u met een gerust hart."
                    }
                },
                "you_are": {
                    "professional": "Professioneel",
                    "traveller": "Reiziger"
                },
                "youtube": {
                    "directed_by": "Geregisseerd door",
                    "producer": "Suprahead-studio"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "Kies uw instelling"
                    },
                    "sections": {
                        "pending_claims": "In afwachting van goedkeuring",
                        "requests": "Openstaande verzoeken",
                        "validated_claims": "Geclaimd"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "Het verzoek is verzonden"
                },
                "approve": {
                    "confirm": {
                        "employee": "Wilt u het verzoek echt goedkeuren?",
                        "employer": "Wil je echt {user_name} goedkeuren?"
                    },
                    "success": "Het verzoek is goedgekeurd"
                },
                "cancel": {
                    "confirm": {
                        "employee": "Wilt u dit verzoek echt annuleren?",
                        "employer": "Wilt u het {user_name}-verzoek echt annuleren?"
                    },
                    "success": "Het verzoek is geannuleerd"
                },
                "decline": {
                    "confirm": {
                        "change_institution": "Uw gekoppelde instelling wordt gewijzigd!",
                        "employee": "Wilt u het verzoek echt afwijzen?",
                        "employer": "Wil je echt {user_name} weigeren?"
                    },
                    "success": "Het verzoek is afgewezen"
                },
                "feature": {
                    "confirm": "Wil je echt {user_name} weergeven?",
                    "folder": "Voor een reiziger die geen interactie heeft gehad met een van uw medewerkers, is dit degene die wordt weergegeven\n           wie er in de reizigersmap wordt weergegeven.",
                    "only_one": "Er kan slechts één medewerker worden vermeld.",
                    "success": "De medewerker is in beeld gebracht"
                },
                "index": {
                    "my_employees": "Mijn medewerkers"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "Rekening",
                        "invitation": "Uitnodiging"
                    }
                },
                "invite": {
                    "invite": "Nodig een medewerker uit"
                },
                "quit": {
                    "confirm": "Wil je echt {poi_name} stoppen met deze instelling?",
                    "success": "U heeft de instelling verlaten"
                },
                "remove": {
                    "confirm": "Wilt u {user_name} echt verwijderen?",
                    "success": "De medewerker is verwijderd"
                },
                "show": {
                    "pending": "Uw verzoek om lid te worden van deze instelling is momenteel in behandeling."
                },
                "states": {
                    "approved": "Goedgekeurd",
                    "canceled": "Geannuleerd",
                    "declined": "Afgewezen",
                    "invited": "Uitgenodigd",
                    "pending": "In behandeling",
                    "removed": "VERWIJDERD"
                }
            },
            "interactions": {
                "description": {
                    "contact approved": "Contactverzoek is goedgekeurd door {sender_name}",
                    "contact blocked": "Contactverzoek is geblokkeerd door {sender_name}",
                    "contact created": "Contactverzoek is aangemaakt door {sender_name}",
                    "contact declined": "Contactverzoek is afgewezen door {sender_name}",
                    "invitation accepted": "Uitnodiging is geaccepteerd door {sender_name}",
                    "invitation created": "Uitnodiging is verzonden door {sender_name}",
                    "payment succeeded": "{sender_name} heeft een betaling verzonden naar {recipient_name}",
                    "share created": "{sender_name} heeft {share} gedeeld",
                    "traveller_folder created": "De map Traveler is gemaakt door {sender_name}",
                    "traveller_folder thanked": "{sender_name} heeft de reiziger bedankt voor zijn verblijf"
                },
                "share_type": {
                    "excursions": "de reisroute",
                    "pointsofinterest": "het aandachtspunt"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "Uitnodiging"
                },
                "states": {
                    "authenticated_once": {
                        "false": "In behandeling",
                        "true": "Gevalideerd"
                    }
                },
                "success": {
                    "professional": {
                        "sent_1": "Er wordt een e-mail verzonden naar {email}, als dit adres nog niet in gebruik is.",
                        "sent_2": "In deze e-mail vindt u een link waarmee u zich kunt aanmelden voor het professionele gedeelte.",
                        "title": "Uitnodiging verzonden"
                    },
                    "traveller": {
                        "email_1": "Er wordt een e-mail verzonden naar {email}, als dit adres nog niet in gebruik is.",
                        "email_2": "Deze e-mail bevat een link waarmee u verbinding kunt maken met de applicatie.",
                        "title": "Uitnodiging verzonden"
                    }
                },
                "texts": {
                    "already_have_account": "Heeft de gebruiker al een account op de applicatie?",
                    "description": "U kunt iemand uitnodigen om deel te nemen aan onze applicatie met een e-mailadres of telefoonnummer.",
                    "employee_already_have_account": "Heeft de medewerker al een account op de applicatie?",
                    "geoloc_settings": {
                        "description": "De geolocatie-instellingen worden automatisch ingesteld op de coördinaten van uw hotel\n                (indien u verbonden bent aan een vestiging).",
                        "title": "Geolocatie-instellingen"
                    },
                    "invite_link": {
                        "instructions": "Kopieer deze link om een ​​reiziger uit te nodigen",
                        "title": "Uitnodigen via link"
                    },
                    "invite_professional": "Nodig een professional uit",
                    "invite_traveller": "Nodig een reiziger uit",
                    "new_traveller": "Nieuwe reiziger",
                    "professional_description": "U kunt een professional uitnodigen om lid te worden van het professionele gedeelte van ons\n            aanvraag met een e-mail",
                    "title": "Iemand uitnodigen"
                }
            },
            "locales": {
                "aa": "Ver weg",
                "ab": "Abchazisch",
                "af": "Afrikaans",
                "af_NA": "Afrikaans (Namibië)",
                "af_ZA": "Afrikaans (Zuid-Afrika)",
                "ak": "Akan",
                "ak_GH": "Akan (Ghana)",
                "am": "Amhaars",
                "am_ET": "Amhaars (Ethiopië)",
                "an": "Aragonese",
                "ar": "Arabisch",
                "ar_AE": "Arabisch (Verenigde Arabische Emiraten)",
                "ar_BH": "Arabisch (Bahrein)",
                "ar_DJ": "Arabisch (Djibouti)",
                "ar_DZ": "Arabisch (Algerije)",
                "ar_EG": "Arabisch (Egypte)",
                "ar_EH": "Arabisch (Westelijke Sahara)",
                "ar_ER": "Arabisch (Eritrea)",
                "ar_IL": "Arabisch (Israël)",
                "ar_IQ": "Arabisch (Irak)",
                "ar_JO": "Arabisch (Jordanië)",
                "ar_KM": "Arabisch (Comoren)",
                "ar_KW": "Arabisch (Koeweit)",
                "ar_LB": "Arabisch (Libanon)",
                "ar_LY": "Arabisch (Libië)",
                "ar_MA": "Arabisch (Marokko)",
                "ar_MR": "Arabisch (Mauritanië)",
                "ar_OM": "Arabisch (Oman)",
                "ar_PS": "Arabisch (Palestijnse gebieden)",
                "ar_QA": "Arabisch (Qatar)",
                "ar_SA": "Arabisch (Saoedi-Arabië)",
                "ar_SD": "Arabisch (Soedan)",
                "ar_SO": "Arabisch (Somalië)",
                "ar_SS": "Arabisch (Zuid-Soedan)",
                "ar_SY": "Arabisch (Syrië)",
                "ar_TD": "Arabisch (Tsjaad)",
                "ar_TN": "Arabisch (Tunesië)",
                "ar_YE": "Arabisch (Jemen)",
                "as": "Assamees",
                "as_IN": "Assamees (India)",
                "av": "Avaarisch",
                "ay": "Aymara",
                "az": "Azerbeidzjaans",
                "az_AZ": "Azerbeidzjaans (Azerbeidzjan)",
                "az_Cyrl": "Azerbeidzjaans (Cyrillisch)",
                "az_Cyrl_AZ": "Azerbeidzjaans (Cyrillisch, Azerbeidzjan)",
                "az_Latn": "Azerbeidzjaans (Latijn)",
                "az_Latn_AZ": "Azerbeidzjaans (Latijn, Azerbeidzjan)",
                "ba": "Bashkir",
                "be": "Wit-Russisch",
                "be_BY": "Wit-Russisch (Wit-Rusland)",
                "bg": "Bulgaars",
                "bg_BG": "Bulgaars (Bulgarije)",
                "bh": "Bihari (talen)",
                "bi": "Bislama",
                "bm": "Bambara",
                "bm_Latn": "Bambara (Latijn)",
                "bm_Latn_ML": "Bambara (Latijn, Mali)",
                "bn": "Bengaals",
                "bn_BD": "Bengaals (Bangladesh)",
                "bn_IN": "Bengaals (India)",
                "bo": "Tibetaans",
                "bo_CN": "Tibetaans (China)",
                "bo_IN": "Tibetaans (India)",
                "br": "Breton",
                "br_FR": "Bretons (Frankrijk)",
                "bs": "Bosnisch",
                "bs_BA": "Bosnisch (Bosnië en Herzegovina)",
                "bs_Cyrl": "Bosnisch (Cyrillisch)",
                "bs_Cyrl_BA": "Bosnisch (Cyrillisch, Bosnië en Herzegovina)",
                "bs_Latn": "Bosnisch (Latijn)",
                "bs_Latn_BA": "Bosnisch (Latijn, Bosnië en Herzegovina)",
                "ca": "Catalaans",
                "ca_AD": "Catalaans (Andorra)",
                "ca_ES": "Catalaans (Spanje)",
                "ca_FR": "Catalaans (Frankrijk)",
                "ca_IT": "Catalaans (Italië)",
                "ce": "Tsjetsjeens",
                "ch": "Chamorro",
                "co": "Corsicaans",
                "cr": "Cree",
                "cs": "Tsjechisch",
                "cs_CZ": "Tsjechisch (Tsjechische Republiek)",
                "cu": "Oud Bulgaars",
                "cv": "Tsjoevasj",
                "cy": "Welsh",
                "cy_GB": "Welsh (Verenigd Koninkrijk)",
                "da": "Deens",
                "da_DK": "Deens (Denemarken)",
                "da_GL": "Deens (Groenland)",
                "de": "Duits",
                "de_AT": "Duits (Oostenrijk)",
                "de_BE": "Duits (België)",
                "de_CH": "Duits (Zwitserland)",
                "de_DE": "Duits duitsland)",
                "de_LI": "Duits (Liechtenstein)",
                "de_LU": "Duits (Luxemburg)",
                "dv": "Maldivisch",
                "dz": "Dzongkha",
                "dz_BT": "Dzongkha (Bhutan)",
                "ee": "Ooi",
                "ee_GH": "Ooi (Ghana)",
                "ee_TG": "Ooi (Togo)",
                "el": "Grieks",
                "el_CY": "Grieks (Cyprus)",
                "el_GR": "Grieks (Griekenland)",
                "en": "Engels",
                "en_AG": "Engels (Antigua en Barbuda)",
                "en_AI": "Engels (Anguilla)",
                "en_AS": "Engels (Amerikaans Samoa)",
                "en_AU": "Engels (Australië)",
                "en_BB": "Engels (Barbados)",
                "en_BE": "Engels (België)",
                "en_BM": "Engels (Bermuda)",
                "en_BS": "Engels (Bahama's)",
                "en_BW": "Engels (Botswana)",
                "en_BZ": "Engels (Belize)",
                "en_CA": "Engels (Canada)",
                "en_CC": "Engels (Cocos (Keeling) eilanden)",
                "en_CK": "Engels (Cookeilanden)",
                "en_CM": "Engels (Kameroen)",
                "en_CX": "Engels (Kersteiland)",
                "en_DG": "Engels (Diego Garcia)",
                "en_DM": "Engels (Dominica)",
                "en_ER": "Engels (Eritrea)",
                "en_FJ": "Engels (Fiji)",
                "en_FK": "Engels (Falklandeilanden)",
                "en_FM": "Engels (Micronesië)",
                "en_GB": "Engels Verenigd Koninkrijk)",
                "en_GD": "Engels (Grenada)",
                "en_GG": "Engels (Guernsey)",
                "en_GH": "Engels (Ghana)",
                "en_GI": "Engels (Gibraltar)",
                "en_GM": "Engels (Gambia)",
                "en_GU": "Engels (Guam)",
                "en_GY": "Engels (Guyana)",
                "en_HK": "Engels (Hong Kong SAR China)",
                "en_IE": "Engels (Ierland)",
                "en_IM": "Engels (Eiland Man)",
                "en_IN": "Engels (India)",
                "en_IO": "Engels (Brits Territorium in de Indische Oceaan)",
                "en_JE": "Engels (trui)",
                "en_JM": "Engels (Jamaica)",
                "en_KE": "Engels (Kenia)",
                "en_KI": "Engels (Kiribati)",
                "en_KN": "Engels (St. Kitts en Nevis)",
                "en_KY": "Engels (Kaaimaneilanden)",
                "en_LC": "Engels (St. Lucia)",
                "en_LR": "Engels (Liberia)",
                "en_LS": "Engels (Lesotho)",
                "en_MG": "Engels (Madagaskar)",
                "en_MH": "Engels (Marshalleilanden)",
                "en_MO": "Engels (Macau SAR China)",
                "en_MP": "Engels (Noordelijke Marianen)",
                "en_MS": "Engels (Montserrat)",
                "en_MT": "Engels (Malta)",
                "en_MU": "Engels (Mauritius)",
                "en_MW": "Engels (Malawi)",
                "en_MY": "Engels (Maleisië)",
                "en_NA": "Engels (Namibië)",
                "en_NF": "Engels (Norfolkeiland)",
                "en_NG": "Engels (Nigeria)",
                "en_NR": "Engels (Nauru)",
                "en_NU": "Engels (Niue)",
                "en_NZ": "Engels (Nieuw-Zeeland)",
                "en_PG": "Engels (Papoea-Nieuw-Guinea)",
                "en_PH": "Engels (Filippijnen)",
                "en_PK": "Engels (Pakistan)",
                "en_PN": "Engels (Pitcairneilanden)",
                "en_PR": "Engels (Puerto Rico)",
                "en_PW": "Engels (Palau)",
                "en_RW": "Engels (Rwanda)",
                "en_SB": "Engels (Salomonseilanden)",
                "en_SC": "Engels (Seychellen)",
                "en_SD": "Engels (Soedan)",
                "en_SG": "Engels (Singapore)",
                "en_SH": "Engels (Sint-Helena)",
                "en_SL": "Engels (Sierra Leone)",
                "en_SS": "Engels (Zuid-Soedan)",
                "en_SX": "Engels (Sint Maarten)",
                "en_SZ": "Engels (Swaziland)",
                "en_TC": "Engels (Turks- en Caicoseilanden)",
                "en_TK": "Engels (Tokelau)",
                "en_TO": "Engels (Tonga)",
                "en_TT": "Engels (Trinidad en Tobago)",
                "en_TV": "Engels (Tuvalu)",
                "en_TZ": "Engels (Tanzania)",
                "en_UG": "Engels (Oeganda)",
                "en_UM": "Engels (afgelegen eilanden van de VS)",
                "en_US": "Engels Verenigde Staten)",
                "en_VC": "Engels (St. Vincent en Grenadines)",
                "en_VG": "Engels (Britse Maagdeneilanden)",
                "en_VI": "Engels (Amerikaanse Maagdeneilanden)",
                "en_VU": "Engels (Vanuatu)",
                "en_WS": "Engels (Samoa)",
                "en_ZA": "Engels (Zuid-Afrika)",
                "en_ZM": "Engels (Zambia)",
                "en_ZW": "Engels (Zimbabwe)",
                "eo": "Esperanto",
                "es": "Spaans",
                "es_AR": "Spaans (Argentinië)",
                "es_BO": "Spaans (Bolivia)",
                "es_CL": "Spaans (Chili)",
                "es_CO": "Spaans (Colombia)",
                "es_CR": "Spaans (Costa Rica)",
                "es_CU": "Spaans (Cuba)",
                "es_DO": "Spaans (Dominicaanse Republiek)",
                "es_EA": "Spaans (Ceuta en Melilla)",
                "es_EC": "Spaans (Ecuador)",
                "es_ES": "Spaans (Spanje)",
                "es_GQ": "Spaans (Equatoriaal-Guinea)",
                "es_GT": "Spaans (Guatemala)",
                "es_HN": "Spaans (Honduras)",
                "es_IC": "Spaans (Canarische Eilanden)",
                "es_MX": "Spaans (Mexico)",
                "es_NI": "Spaans (Nicaragua)",
                "es_PA": "Spaans (Panama)",
                "es_PE": "Spaans (Peru)",
                "es_PH": "Spaans (Filippijnen)",
                "es_PR": "Spaans (Puerto Rico)",
                "es_PY": "Spaans (Paraguay)",
                "es_SV": "Spaans (El Salvador)",
                "es_US": "Spaans (Verenigde Staten)",
                "es_UY": "Spaans (Uruguay)",
                "es_VE": "Spaans (Venezuela)",
                "et": "Ests",
                "et_EE": "Ests (Estland)",
                "eu": "baskisch",
                "eu_ES": "Baskisch (Spanje)",
                "fa": "Perzisch",
                "fa_AF": "Perzisch (Afghanistan)",
                "fa_IR": "Perzisch (Iran)",
                "ff": "Fula",
                "ff_CM": "Fula (Kameroen)",
                "ff_GN": "Fula (Guinee)",
                "ff_MR": "Fulah (Mauritanië)",
                "ff_SN": "Fula (Senegal)",
                "fi": "Fins",
                "fi_FI": "Fins (Finland)",
                "fj": "Fijisch",
                "fo": "Faeröer",
                "fo_FO": "Faeröer (Faeröer)",
                "fr": "Frans",
                "fr_BE": "Frans (België)",
                "fr_BF": "Frans (Burkina Faso)",
                "fr_BI": "Frans (Burundi)",
                "fr_BJ": "Frans (Benin)",
                "fr_BL": "Frans (St. Barthélemy)",
                "fr_CA": "Frans (Canada)",
                "fr_CD": "Frans (Congo - Kinshasa)",
                "fr_CF": "Frans (Centraal-Afrikaanse Republiek)",
                "fr_CG": "Frans (Congo - Brazzaville)",
                "fr_CH": "Frans (Zwitserland)",
                "fr_CI": "Frans (Ivoorkust)",
                "fr_CM": "Frans (Kameroen)",
                "fr_DJ": "Frans (Djibouti)",
                "fr_DZ": "Frans (Algerije)",
                "fr_FR": "Frans Frankrijk)",
                "fr_GA": "Frans (Gabon)",
                "fr_GF": "Frans (Frans-Guyana)",
                "fr_GN": "Frans (Guinee)",
                "fr_GP": "Frans (Guadeloupe)",
                "fr_GQ": "Frans (Equatoriaal-Guinea)",
                "fr_HT": "Frans (Haïti)",
                "fr_KM": "Frans (Comoren)",
                "fr_LU": "Frans (Luxemburg)",
                "fr_MA": "Frans (Marokko)",
                "fr_MC": "Frans (Monaco)",
                "fr_MF": "Frans (Sint-Maarten)",
                "fr_MG": "Frans (Madagaskar)",
                "fr_ML": "Frans (Mali)",
                "fr_MQ": "Frans (Martinique)",
                "fr_MR": "Frans (Mauritanië)",
                "fr_MU": "Frans (Mauritius)",
                "fr_NC": "Frans (Nieuw-Caledonië)",
                "fr_NE": "Frans (Niger)",
                "fr_PF": "Frans (Frans-Polynesië)",
                "fr_PM": "Frans (St. Pierre en Miquelon)",
                "fr_RE": "Frans (Réunion)",
                "fr_RW": "Frans (Rwanda)",
                "fr_SC": "Frans (Seychellen)",
                "fr_SN": "Frans (Senegal)",
                "fr_SY": "Frans (Syrië)",
                "fr_TD": "Frans (Tsjaad)",
                "fr_TG": "Frans (Togo)",
                "fr_TN": "Frans (Tunesië)",
                "fr_VU": "Frans (Vanuatu)",
                "fr_WF": "Frans (Wallis en Futuna)",
                "fr_YT": "Frans (Mayotte)",
                "fy": "West-Fries",
                "fy_NL": "West-Fries (Nederland)",
                "ga": "Iers",
                "ga_IE": "Iers (Ierland)",
                "gd": "Schots-Gaelisch",
                "gd_GB": "Schots-Gaelisch (Verenigd Koninkrijk)",
                "gl": "Galicisch",
                "gl_ES": "Galicisch (Spanje)",
                "gn": "Guarani",
                "gu": "Gujarati",
                "gu_IN": "Gujarati (India)",
                "gv": "Manx",
                "gv_IM": "Manx (Eiland Man)",
                "ha": "Hausa",
                "ha_GH": "Hausa (Ghana)",
                "ha_Latn": "Hausa (Latijn)",
                "ha_Latn_GH": "Hausa (Latijn, Ghana)",
                "ha_Latn_NE": "Hausa (Latijn, Niger)",
                "ha_Latn_NG": "Hausa (Latijn, Nigeria)",
                "ha_NE": "Hausa (Niger)",
                "ha_NG": "Hausa (Nigeria)",
                "he": "Hebreeuws",
                "he_IL": "Hebreeuws (Israël)",
                "hi": "Hindi",
                "hi_IN": "Hindi (India)",
                "ho": "Hiri Motu",
                "hr": "Kroatisch",
                "hr_BA": "Kroatisch (Bosnië en Herzegovina)",
                "hr_HR": "Kroatisch (Kroatië)",
                "ht": "Haïtiaans",
                "hu": "Hongaars",
                "hu_HU": "Hongaars (Hongarije)",
                "hy": "Armeens",
                "hy_AM": "Armeens (Armenië)",
                "hz": "Herero",
                "ia": "Interlingua",
                "id": "Indonesisch",
                "id_ID": "Indonesisch (Indonesië)",
                "ie": "Interlinguïstisch",
                "ig": "Igbo",
                "ig_NG": "Igbo (Nigeria)",
                "ii": "Sichuan Yi",
                "ii_CN": "Sichuan Yi (China)",
                "ik": "Inupiaq",
                "io": "Ik doe",
                "is": "IJslands",
                "is_IS": "IJslands (IJsland)",
                "it": "Italiaans",
                "it_CH": "Italiaans (Zwitserland)",
                "it_IT": "Italiaans (Italië)",
                "it_SM": "Italiaans (San Marino)",
                "iu": "Inuktitut",
                "ja": "Japans",
                "ja_JP": "Japans (Japan)",
                "jv": "Javaans",
                "ka": "Georgisch",
                "ka_GE": "Georgisch (Georgië)",
                "kg": "Kongo",
                "ki": "Kikuyu",
                "ki_KE": "Kikuyu (Kenia)",
                "kj": "Kwanyama",
                "kk": "Kazachs",
                "kk_Cyrl": "Kazachs (Cyrillisch)",
                "kk_Cyrl_KZ": "Kazachs (Cyrillisch, Kazachstan)",
                "kk_KZ": "Kazachs (Kazachstan)",
                "kl": "Kalaallisut",
                "kl_GL": "Kalaallisut (Groenland)",
                "km": "Khmer",
                "km_KH": "Khmer (Cambodja)",
                "kn": "Kanada",
                "kn_IN": "Kannada (India)",
                "ko": "Koreaans",
                "ko_KP": "Koreaans (Noord-Korea)",
                "ko_KR": "Koreaans (Zuid-Korea)",
                "kr": "Kanuri",
                "ks": "Kasjmir",
                "ks_Arab": "Kasjmir (Arabisch)",
                "ks_Arab_IN": "Kashmiri (Arabisch, India)",
                "ks_IN": "Kasjmir (India)",
                "ku": "Koerdisch",
                "kv": "Komi",
                "kw": "Cornisch",
                "kw_GB": "Cornish (Verenigd Koninkrijk)",
                "ky": "Kirgizisch",
                "ky_Cyrl": "Kirgizisch (Cyrillisch)",
                "ky_Cyrl_KG": "Kirgizisch (Cyrillisch, Kirgizië)",
                "ky_KG": "Kirgizië (Kirgizië)",
                "la": "Latijns",
                "lb": "Luxemburgs",
                "lb_LU": "Luxemburgs (Luxemburg)",
                "lg": "Ganda",
                "lg_UG": "Ganda (Oeganda)",
                "li": "Limburgs",
                "ln": "Lingala",
                "ln_AO": "Lingala (Angola)",
                "ln_CD": "Lingala (Kongo - Kinshasa)",
                "ln_CF": "Lingala (Centraal-Afrikaanse Republiek)",
                "ln_CG": "Lingala (Congo - Brazzaville)",
                "lo": "Laos",
                "lo_LA": "Laos (Laos)",
                "lt": "Litouws",
                "lt_LT": "Litouws (Litouwen)",
                "lu": "Luba-Katanga",
                "lu_CD": "Luba-Katanga (Kongo - Kinshasa)",
                "lv": "Lets",
                "lv_LV": "Lets (Letland)",
                "mg": "Madagaskar",
                "mg_MG": "Malagasi (Madagaskar)",
                "mh": "Marshallees",
                "mi": "Maori",
                "mk": "Macedonisch",
                "mk_MK": "Macedonisch (Macedonië)",
                "ml": "Malayalam",
                "ml_IN": "Malayalam (India)",
                "mn": "Mongools",
                "mn_Cyrl": "Mongools (Cyrillisch)",
                "mn_Cyrl_MN": "Mongools (Cyrillisch, Mongolië)",
                "mn_MN": "Mongools (Mongolië)",
                "mo": "Macau",
                "mr": "Marathi",
                "mr_IN": "Marathi (India)",
                "ms": "Maleis",
                "ms_BN": "Maleis (Brunei)",
                "ms_Latn": "Maleis (Latijn)",
                "ms_Latn_BN": "Maleis (Latijn, Brunei)",
                "ms_Latn_MY": "Maleis (Latijn, Maleisië)",
                "ms_Latn_SG": "Maleis (Latijn, Singapore)",
                "ms_MY": "Maleis (Maleisië)",
                "ms_SG": "Maleis (Singapore)",
                "mt": "Maltees",
                "mt_MT": "Maltees (Malta)",
                "my": "Birmees",
                "my_MM": "Birmaans (Myanmar (Birma))",
                "na": "Nauru",
                "nb": "Noorse Bokmål",
                "nb_NO": "Noorse Bokmål (Noorwegen)",
                "nb_SJ": "Noorse Bokmål (Svalbard en Jan Mayen)",
                "nd": "Noord-Ndebele",
                "nd_ZW": "Noord-Ndebele (Zimbabwe)",
                "ne": "Nepalees",
                "ne_IN": "Nepalees (India)",
                "ne_NP": "Nepalees (Nepal)",
                "ng": "Ndonga",
                "nl": "Nederlands",
                "nl_AW": "Nederlands (Aruba)",
                "nl_BE": "Nederlands (België)",
                "nl_BQ": "Nederlands (Caribisch Nederland)",
                "nl_CW": "Nederlands (Curaçao)",
                "nl_NL": "Nederlands (Nederland)",
                "nl_SR": "Nederlands (Suriname)",
                "nl_SX": "Nederlands (Sint Maarten)",
                "nn": "Noorse Nynorsk",
                "nn_NO": "Noors Nynorsk (Noorwegen)",
                "no": "Noors",
                "no_NO": "Noors (Noorwegen)",
                "nr": "Zuid-Ndebele",
                "nv": "Navaho",
                "ny": "Chichewa",
                "oc": "Occitaans",
                "oj": "Ojibwa",
                "om": "Oromo",
                "om_ET": "Oromo (Ethiopië)",
                "om_KE": "Oromo (Kenia)",
                "or": "Oriya",
                "or_IN": "Oriya (India)",
                "os": "Ossetisch",
                "os_GE": "Ossetisch (Georgië)",
                "os_RU": "Ossetisch (Rusland)",
                "pa": "Punjabi",
                "pa_Arab": "Punjabi (Arabisch)",
                "pa_Arab_PK": "Punjabi (Arabisch, Pakistan)",
                "pa_Guru": "Punjabi (Gurmukhi)",
                "pa_Guru_IN": "Punjabi (Gurmukhi, India)",
                "pa_IN": "Punjabi (India)",
                "pa_PK": "Punjabi (Pakistan)",
                "pi": "Pali",
                "pl": "Pools",
                "pl_PL": "Pools (Polen)",
                "ps": "Pasjto",
                "ps_AF": "Pasjtoe (Afghanistan)",
                "pt": "Portugees",
                "pt_AO": "Portugees (Angola)",
                "pt_BR": "Portugees (Brazilië)",
                "pt_CV": "Portugees (Kaapverdië)",
                "pt_GW": "Portugees (Guinee-Bissau)",
                "pt_MO": "Portugees (Macau SAR China)",
                "pt_MZ": "Portugees (Mozambique)",
                "pt_PT": "Portugees (Portugal)",
                "pt_ST": "Portugees (São Tomé en Principe)",
                "pt_TL": "Portugees (Timor-Leste)",
                "qu": "Quechua",
                "qu_BO": "Quechua (Bolivia)",
                "qu_EC": "Quechua (Ecuador)",
                "qu_PE": "Quechua (Peru)",
                "rm": "Reto-Romaans",
                "rm_CH": "Reto-Romaans (Zwitserland)",
                "rn": "Rundi",
                "rn_BI": "Rundi (Burundi)",
                "ro": "Roemeense",
                "ro_MD": "Roemeens (Moldavië)",
                "ro_RO": "Roemeens (Roemenië)",
                "ru": "Russisch",
                "ru_BY": "Russisch (Wit-Rusland)",
                "ru_KG": "Russisch (Kirgizië)",
                "ru_KZ": "Russisch (Kazachstan)",
                "ru_MD": "Russisch (Moldavië)",
                "ru_RU": "Russisch (Rusland)",
                "ru_UA": "Russisch (Oekraïne)",
                "rw": "Kinyarwanda",
                "rw_RW": "Kinyarwanda (Rwanda)",
                "sa": "Sanskriet",
                "sc": "Sardijns",
                "sd": "Sindhi",
                "se": "Noord-Sami",
                "se_FI": "Noord-Samisch (Finland)",
                "se_NO": "Noord-Sami (Noorwegen)",
                "se_SE": "Noord-Sami (Zweden)",
                "sg": "Sango",
                "sg_CF": "Sango (Centraal-Afrikaanse Republiek)",
                "sh": "Servo-Kroatisch",
                "sh_BA": "Servo-Kroatisch (Bosnië en Herzegovina)",
                "si": "Singalees",
                "si_LK": "Singalees (Sri Lanka)",
                "sk": "Slowaaks",
                "sk_SK": "Slowaaks (Slowakije)",
                "sl": "Sloveens",
                "sl_SI": "Sloveens (Slovenië)",
                "sm": "Samoaans",
                "sn": "Shona",
                "sn_ZW": "Shona (Zimbabwe)",
                "so": "Somalisch",
                "so_DJ": "Somalisch (Djibouti)",
                "so_ET": "Somalisch (Ethiopië)",
                "so_KE": "Somalisch (Kenia)",
                "so_SO": "Somalisch (Somalië)",
                "sq": "Albanees",
                "sq_AL": "Albanees (Albanië)",
                "sq_MK": "Albanees (Macedonië)",
                "sq_XK": "Albanees (Kosovo)",
                "sr": "Servisch",
                "sr_BA": "Servisch (Bosnië en Herzegovina)",
                "sr_Cyrl": "Servisch (Cyrillisch)",
                "sr_Cyrl_BA": "Servisch (Cyrillisch, Bosnië en Herzegovina)",
                "sr_Cyrl_ME": "Servisch (Cyrillisch, Montenegro)",
                "sr_Cyrl_RS": "Servisch (Cyrillisch, Servië)",
                "sr_Cyrl_XK": "Servisch (Cyrillisch, Kosovo)",
                "sr_Latn": "Servisch (Latijn)",
                "sr_Latn_BA": "Servisch (Latijn, Bosnië en Herzegovina)",
                "sr_Latn_ME": "Servisch (Latijn, Montenegro)",
                "sr_Latn_RS": "Servisch (Latijn, Servië)",
                "sr_Latn_XK": "Servisch (Latijn, Kosovo)",
                "sr_ME": "Servisch (Montenegro)",
                "sr_RS": "Servisch (Servië)",
                "sr_XK": "Servisch (Kosovo)",
                "ss": "Swati",
                "st": "Sotho, Zuid",
                "su": "Soendanees",
                "sv": "Zweeds",
                "sv_AX": "Zweeds (Åland-eilanden)",
                "sv_FI": "Zweeds (Finland)",
                "sv_SE": "Zweeds (Zweden)",
                "sw": "Swahili",
                "sw_KE": "Swahili (Kenia)",
                "sw_TZ": "Swahili (Tanzania)",
                "sw_UG": "Swahili (Oeganda)",
                "ta": "Tamil",
                "ta_IN": "Tamil (India)",
                "ta_LK": "Tamil (Sri Lanka)",
                "ta_MY": "Tamil (Maleisië)",
                "ta_SG": "Tamil (Singapore)",
                "te": "Telugu",
                "te_IN": "Telugu (India)",
                "tg": "Tadzjiekse",
                "th": "Thais",
                "th_TH": "Thais (Thailand)",
                "ti": "Tigrinya",
                "ti_ER": "Tigrinya (Eritrea)",
                "ti_ET": "Tigrinya (Ethiopië)",
                "tk": "Turkmeens",
                "tl": "Tagalog",
                "tl_PH": "Tagalog (Filippijnen)",
                "tn": "Tswana",
                "to": "Tongaans",
                "to_TO": "Tongaans (Tonga)",
                "tr": "Turks",
                "tr_CY": "Turks (Cyprus)",
                "tr_TR": "Turks (Turkije)",
                "ts": "Tsonga",
                "tt": "Tataars",
                "tw": "Twee",
                "ty": "Tahitiaans",
                "ug": "Oeigoers",
                "ug_Arab": "Oeigoers (Arabisch)",
                "ug_Arab_CN": "Oeigoers (Arabisch, China)",
                "ug_CN": "Oeigoers (China)",
                "uk": "Oekraïens",
                "uk_UA": "Oekraïens (Oekraïne)",
                "ur": "Urdu",
                "ur_IN": "Urdu (India)",
                "ur_PK": "Urdu (Pakistan)",
                "uz": "Oezbeeks",
                "uz_AF": "Oezbeeks (Afghanistan)",
                "uz_Arab": "Oezbeeks (Arabisch)",
                "uz_Arab_AF": "Oezbeeks (Arabisch, Afghanistan)",
                "uz_Cyrl": "Oezbeeks (Cyrillisch)",
                "uz_Cyrl_UZ": "Oezbeeks (Cyrillisch, Oezbekistan)",
                "uz_Latn": "Oezbeeks (Latijn)",
                "uz_Latn_UZ": "Oezbeeks (Latijn, Oezbekistan)",
                "uz_UZ": "Oezbeeks (Oezbekistan)",
                "ve": "Venda",
                "vi": "Vietnamees",
                "vi_VN": "Vietnamees (Vietnam)",
                "vo": "Volapuk",
                "wa": "Waals",
                "wo": "Wolof",
                "xh": "Xhosa",
                "yi": "Jiddisch",
                "yo": "Joruba",
                "yo_BJ": "Joruba (Benin)",
                "yo_NG": "Yoruba (Nigeria)",
                "za": "Zhuang",
                "zh": "Chinese",
                "zh_CN": "Chinees (China)",
                "zh_HK": "Chinees (Hong Kong SAR China)",
                "zh_Hans": "Versimpeld Chinees)",
                "zh_Hans_CN": "Chinees (vereenvoudigd, China)",
                "zh_Hans_HK": "Chinees (vereenvoudigd, Hong Kong SAR China)",
                "zh_Hans_MO": "Chinees (vereenvoudigd, Macau SAR China)",
                "zh_Hans_SG": "Chinees (vereenvoudigd, Singapore)",
                "zh_Hant": "Chinese traditionele)",
                "zh_Hant_HK": "Chinees (traditioneel, Hong Kong SAR China)",
                "zh_Hant_MO": "Chinees (traditioneel, Macau SAR China)",
                "zh_Hant_TW": "Chinees (traditioneel, Taiwan)",
                "zh_MO": "Chinees (Macau SAR China)",
                "zh_SG": "Chinees (Singapore)",
                "zh_TW": "Chinees (Taiwan)",
                "zu": "Zoeloe",
                "zu_ZA": "Zoeloe (Zuid-Afrika)"
            },
            "mail": {
                "action": "Als u problemen ondervindt bij het klikken op de knop \"{actionText}\", kopieer en plak dan de onderstaande URL in uw webbrowser:",
                "auth": {
                    "deleted": {
                        "line1": "Wij informeren u dat uw UgoZER-account is verwijderd.",
                        "reason": {
                            "intro": "Deze is verwijderd om de volgende reden:",
                            "never_authenticated_for_too_long": "Uw account is meer dan een week geleden aangemaakt\n                    en je hebt nooit ingelogd:\n                    dus dit account wordt als inactief beschouwd.",
                            "not_logged_for_too_long": "U bent langer dan 2 jaar niet ingelogd:\n                    uw account wordt als inactief beschouwd."
                        },
                        "subject": "Verwijdering van uw UgoZER-account"
                    },
                    "login_attempt_unverified_credential": {
                        "action": "Bevestig e-mail adres",
                        "line1": "Er is geprobeerd in te loggen op uw UgoZER-account met het e-mailadres {credential}.",
                        "line2": "Als u deze inlogpoging heeft gestart,\n                Klik op de onderstaande knop om uw e-mailadres te verifiëren\n                en verbinding kunnen maken.",
                        "line3": "Deze link vervalt over 1 week.",
                        "line4": "Als u deze inlogpoging niet heeft gestart, hoeft u geen verdere actie te ondernemen.",
                        "subject": "Probeer in te loggen op uw UgoZER-account"
                    },
                    "login_magic_link": {
                        "action": "Log in",
                        "line1": "Klik op deze link om in te loggen",
                        "line2": "Bedankt voor het gebruik van onze applicatie!",
                        "subject": "Wachtwoordloze login-link"
                    },
                    "not_logged": {
                        "intro": "Het is bijna 2 jaar geleden dat u voor het laatst heeft ingelogd op uw UgoZER-account.",
                        "outro": "Log eenvoudig in op uw account, zodat deze niet wordt verwijderd.",
                        "remaining": {
                            "two_days": "Je hebt nog 2 dagen voordat je account wordt verwijderd.",
                            "two_months": "U heeft nog 2 maanden voordat uw account wordt verwijderd.",
                            "two_weeks": "Je hebt nog 2 weken voordat je account wordt verwijderd."
                        },
                        "subject": "Uw UgoZER-account wordt binnenkort verwijderd"
                    },
                    "registration": {
                        "action": "Bevestig e-mail adres",
                        "line1": "Klik op de onderstaande knop om uw e-mailadres te verifiëren en in te loggen.",
                        "line2": "Deze link vervalt over 1 week.",
                        "line3": "Als u geen account heeft aangemaakt, hoeft u geen verdere actie te ondernemen.",
                        "subject": "Verifieer je e-mailadres"
                    },
                    "registration_attempt": {
                        "line1": "Het <strong>{credential}</strong> e-mailadres wordt al gebruikt voor een <strong>{role_type}</strong> account in onze applicatie.",
                        "line2": "Om een ​​account aan te maken, start u de registratie opnieuw met een ander e-mailadres.",
                        "subject": "Probeer u te registreren met uw UgoZER-inloggegevens"
                    },
                    "registration_attempt_unverified_credential": {
                        "line1": "Het <strong>{credential}</strong> e-mailadres wordt al gebruikt voor een <strong>{role_type}</strong> account in onze applicatie.",
                        "line2": "Om een ​​account aan te maken, start u de registratie opnieuw met een ander e-mailadres.",
                        "subject": "Probeer u te registreren met uw UgoZER-inloggegevens"
                    },
                    "reset_password": {
                        "action": "Wachtwoord opnieuw instellen",
                        "line1": "U ontvangt deze e-mail omdat we een verzoek voor het opnieuw instellen van het wachtwoord voor uw account hebben ontvangen.",
                        "line2": "Deze link voor het opnieuw instellen van het wachtwoord vervalt over {count} minuten.",
                        "line3": "Als u geen wachtwoordreset heeft aangevraagd, is er geen verdere actie vereist.",
                        "subject": "Wachtwoordmelding opnieuw instellen"
                    }
                },
                "copyright": "© {year} UgoZER - Alle rechten voorbehouden.",
                "credential_verification": {
                    "verify": {
                        "action": "Bevestig e-mail adres",
                        "line1": "Klik op de onderstaande knop om uw e-mailadres te verifiëren.",
                        "line2": "Deze link vervalt over 1 week.",
                        "line3": "Als u geen verificatie heeft aangevraagd, hoeft u geen verdere actie te ondernemen.",
                        "subject": "Bevestiging van uw e-mail"
                    }
                },
                "hello": "Hallo!",
                "invitation": {
                    "attempt": {
                        "action": "Toegang tot de applicatie",
                        "line1": "U bent uitgenodigd om deel te nemen aan onze applicatie",
                        "line1_by": "Je bent door {name} uitgenodigd om deel te nemen aan onze applicatie",
                        "line1_by_from": "Je bent uitgenodigd door {name} van {poi_title}-instelling om deel te nemen aan onze applicatie",
                        "line2": "U heeft al een bestaand account in onze applicatie",
                        "line3": "Klik op onderstaande knop om naar de applicatie te gaan.",
                        "subject": "Uitnodiging om lid te worden van UgoZER"
                    },
                    "verification": {
                        "action": "Bevestig e-mail adres",
                        "excursion_shared": "Er is een routebeschrijving met u gedeeld. U vindt het bijgevoegd in PDF-formaat,\n                    evenals bij de toepassing in uw aandelen.",
                        "line1": "U bent uitgenodigd om deel te nemen aan onze applicatie",
                        "line1_by": "Je bent door {name} uitgenodigd om deel te nemen aan onze applicatie",
                        "line1_by_from": "Je bent uitgenodigd door {name} van {poi_title}-instelling om deel te nemen aan onze applicatie",
                        "line2": "Klik op de onderstaande knop om uw e-mailadres te verifiëren.",
                        "line3": "Deze link vervalt over 1 week.",
                        "line4": "Bedankt voor het gebruik van onze applicatie!",
                        "point_of_interest_shared": "Er is een aandachtspunt met u gedeeld. Je kan het vinden\n                in de aanvraag voor uw aandelen.",
                        "subject": "Uitnodiging om lid te worden van UgoZER"
                    }
                },
                "invitation_professional": {
                    "content": "U bent uitgenodigd om deel te nemen aan het professionele gedeelte van onze applicatie UgoZER",
                    "cta": "Schrijf u in voor het professionele gedeelte",
                    "subject": "UgoZER - Uitnodiging professionele toegang",
                    "thanks": "Bedankt,",
                    "title": "Uitnodiging voor het professionele gedeelte van de UgoZER-applicatie"
                },
                "preview": {
                    "default": {
                        "subject": "E-mail onderwerp",
                        "user": {
                            "name": "UgoZER"
                        }
                    },
                    "from": "Van:",
                    "mails": {
                        "auth/account-deleted-notification": "Accountverwijdering",
                        "auth/not-logged-notification": "Verwijder indien niet verbonden",
                        "auth/registration-attempt-notification": "Poging tot registratie",
                        "auth/registration-notification": "Registratie",
                        "auth/reset-password-notification": "Verander uw wachtwoord",
                        "invitation/invited-notification": "Uitnodiging",
                        "share/created-notification": "Nieuw",
                        "support-message/message-notification": "Nieuw antwoord",
                        "support-request/archived-notification": "Gearchiveerd verzoek",
                        "support-request/created-notification": "Aanvraag gemaakt",
                        "user-credential-verification/login-attempt-notification": "Probeer verbinding te maken",
                        "user-credential-verification/registration-attempt-notification": "Poging tot registratie",
                        "user-credential-verification/verify-notification": "Accountbevestiging",
                        "user/welcome-notification": "Welkom"
                    },
                    "main_title": "E-mailvoorbeeld",
                    "sections": {
                        "auth": "Authenticatie",
                        "invitation": "Uitnodigingen",
                        "share": "Aandelen",
                        "support-message": "Ondersteuningsberichten",
                        "support-request": "Ondersteuningsverzoeken",
                        "user": "Gebruiker",
                        "user-credential-verification": "Gebruikersreferentie"
                    }
                },
                "salutations": "Groeten",
                "share": {
                    "created": {
                        "action": "Show",
                        "excursion": {
                            "gotoapp": "Ga naar de app om deze te bekijken.",
                            "line": "{username} van {poi_title} institution heeft een reisschema met je gedeeld.",
                            "line_alt": "{username} heeft een routebeschrijving met je gedeeld.",
                            "line_alt_2": "Er is een routebeschrijving met u gedeeld.",
                            "subject": "Er is een routebeschrijving met u gedeeld."
                        },
                        "line": "{username} van {poi_title} institution heeft een item met je gedeeld.",
                        "line_alt": "{username} heeft een item met je gedeeld.",
                        "line_alt_2": "Er is een item met je gedeeld.",
                        "point_of_interest": {
                            "line": "{username} van {poi_title} institution heeft een aandachtspunt met je gedeeld.",
                            "line_alt": "{username} heeft een interessant punt met je gedeeld.",
                            "line_alt_2": "Er is een aandachtspunt met u gedeeld.",
                            "subject": "Er is een aandachtspunt met u gedeeld."
                        },
                        "subject": "Er is een item met je gedeeld."
                    }
                },
                "subscription": {
                    "not_continue_after_free_period": {
                        "cta": "Zie detail",
                        "line1": "{name} ging niet door nadat de vrije periode was afgelopen",
                        "line2": "Klik op de onderstaande knop om toegang te krijgen tot de details",
                        "subject": "De abonnee ging niet verder nadat de gratis periode was afgelopen"
                    },
                    "suspended": {
                        "line": "Uw abonnement is opgeschort om de volgende reden: {suspension_reason}.",
                        "line_alt": "Uw abonnement is opgeschort.",
                        "subject": "Abonnement opgeschort"
                    },
                    "switched_from_paid_to_free": {
                        "cta": "Zie detail",
                        "line1": "{name} is overgestapt op het gratis abonnement",
                        "line2": "Klik op de onderstaande knop om toegang te krijgen tot de details",
                        "subject": "Abonnee is overgestapt op het gratis abonnement"
                    }
                },
                "support_request": {
                    "archived": {
                        "subject": "UgoZER - Ondersteuningsverzoek gearchiveerd",
                        "title": "Uw ondersteuningsverzoek is gearchiveerd"
                    },
                    "message": {
                        "action": "Toegang tot het ondersteuningsverzoek",
                        "line1": "Klik op onderstaande knop om naar uw aanvraag te gaan.",
                        "subject": "UgoZER - Je hebt een antwoord",
                        "title": "Er is een antwoord op uw ondersteuningsverzoek geplaatst"
                    },
                    "request": {
                        "action": "Toegang tot het ondersteuningsverzoek",
                        "line1": "Contactondersteuning zal binnen 48 uur antwoorden.",
                        "line2": "Klik op onderstaande knop om naar uw aanvraag te gaan.",
                        "subject": "UgoZER - Uw ondersteuningsverzoek is aangemaakt"
                    }
                },
                "user_send_link": {
                    "content": "Maak opnieuw verbinding met onze applicatie UgoZER! Als u uw wachtwoord niet meer weet, kunt u het opnieuw instellen.",
                    "cta": "Inloggen applicatie",
                    "subject": "UgoZER - Applicatie",
                    "thanks": "Bedankt,",
                    "title": "U heeft een account op onze applicatie, log in!"
                },
                "welcome": {
                    "action": "Toegang tot de applicatie",
                    "line1": "Wij zijn blij u tot onze gebruikers te mogen rekenen.",
                    "line2": "Klik op onderstaande knop om naar de applicatie te gaan.",
                    "subject": "Welkom bij UgoZER",
                    "title": "Welkom bij UgoZER, uw gratis meertalige reisbijstandsapp."
                }
            },
            "media": {
                "audio": {
                    "generated_audio": "Gegenereerde audio",
                    "recording": {
                        "errors": {
                            "default": "Opname mislukt",
                            "not_allowed": "Opname mislukt. Laat uw browser en UgoZER uw microfoon gebruiken en probeer het opnieuw."
                        }
                    }
                },
                "default": {
                    "alt": "De statische kaart van Google Maps",
                    "caption": "Klik op de kaart om deze op Google Maps weer te geven"
                },
                "image": {
                    "credits": "Credits: {credits}"
                }
            },
            "models": {
                "activity_log": "Activiteitenlogboek|Activiteitenlogboeken",
                "address": "Adres|Adressen",
                "bank_transfer": "Bankoverschrijving|Bankoverschrijvingen",
                "bank_transfer_institution": "Bankoverboekingsinstelling|Bankoverboekingsinstellingen",
                "category": "Categorie|Categorieën",
                "changelog": "Wijzigingslog|Wijzigingslogs",
                "claim": "Claim|Clamatie",
                "claim_dispute": "Claimgeschil|Claimgeschillen",
                "currency": "Valuta|Valuta's",
                "data_tourisme_category": "DataTourisme-categorie|DataTourisme-categorieën",
                "data_tourisme_tag": "DataTourisme-tag|DataTourisme-tags",
                "excursion": "Reisroute|Reisroutes",
                "institution_employee": "Werknemer|Werknemers",
                "interaction": "Interactie|Interacties",
                "locale": "Taal|Talen",
                "medium": "Middel|Media",
                "notification": "Melding|Meldingen",
                "partner": "Partner|Partners",
                "payment": "Betaling|Betalingen",
                "permission": "Toestemming|Toestemmingen",
                "plan": "Abonnementsplan|Abonnementplannen",
                "plan_price": "Planprijs|Planprijzen",
                "point_of_interest": "Interessante plaatsen | Interessante plaatsen",
                "point_of_interest_category": "Categorie|Categorieën",
                "post": "Post|Berichten",
                "post_category": "Berichtencategorie|Berichtcategorieën",
                "privacy_policy": "Privacybeleid|Privacybeleid",
                "report": "Rapport|Rapporten",
                "reservation": "Reservering|Reserveringen",
                "role": "Rol|Rollen",
                "share": "Deel|Aandelen",
                "subscription": "Abonnement|Abonnementen",
                "support_category": "Ondersteuningscategorie|Ondersteuningscategorieën",
                "support_message": "Bericht|Berichten",
                "support_request": "Verzoek|Verzoeken",
                "tag": "Label|Tags",
                "terms_condition": "Algemene voorwaarden|Algemene voorwaarden",
                "traveller_email": "E-mail van reizigers|E-mails van reizigers",
                "traveller_folder": "Reizigersmap|Reizigersmappen",
                "user": "Gebruiker|Gebruikers",
                "user_credential_verification": "Inloggegevensverificatie|Inloggegevensverificaties",
                "user_geoloc_setting": "Geoloc-instelling|Geoloc-instellingen",
                "user_notification_setting": "Meldingsinstellingen|Meldingsinstellingen",
                "user_password_reset": "Gebruikerswachtwoord opnieuw instellen|Gebruikerswachtwoorden opnieuw instellen",
                "user_privacy_setting": "Privacy-instelling|Privacy-instellingen",
                "users_relationship": "Contact|Contacten",
                "vat_rate": "BTW-tarief|BTW-tarieven"
            },
            "navigations": {
                "items": {
                    "changelogs": "Wijzigingslogboeken",
                    "contact_us": "Neem contact met ons op",
                    "contacts": "Contacten",
                    "cookies": "Cookiebeheer",
                    "credits": "Kredieten",
                    "customers": "Ze vertrouwen ons",
                    "faqs": "FAQ",
                    "folders": "Reizigersmappen",
                    "invite_professional": "Nodig een professional uit",
                    "invite_traveller": "Nodig een reiziger uit",
                    "partners": "Partners",
                    "payments": "Betalingen",
                    "privacy_policy": "Privacybeleid",
                    "settings": "Parameters",
                    "stays": "Verblijven",
                    "terms_conditions": "Voorwaarden",
                    "users": "Gebruikers"
                }
            },
            "notifications": {
                "changelog": {
                    "created": {
                        "text": "De versie {version} is geïmplementeerd. U kunt het nieuws bekijken door op de melding te klikken.",
                        "title": "Nieuwe changelog"
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "text": "Het claimgeschil met betrekking tot het interessante punt {poi} wacht op verificatie door ons team.",
                        "title": "Claimgeschil in afwachting van verificatie"
                    },
                    "rejected": {
                        "no_reason": "Geen reden gegeven",
                        "text": "Het claimgeschil op de {poi} aandachtspunt werd afgewezen om de volgende reden: {reason}.",
                        "title": "Claimgeschil afgewezen"
                    },
                    "validated": {
                        "text": "Het claimgeschil met betrekking tot de {poi} point of interest is geaccepteerd!",
                        "title": "Claimgeschil gevalideerd!"
                    }
                },
                "index": {
                    "no_results": "Geen notificaties!"
                },
                "institution_employee": {
                    "approved": {
                        "text": "Uw verzoek om lid te worden van {poi} is goedgekeurd",
                        "title": "Aanvraag van medewerker goedgekeurd"
                    },
                    "declined": {
                        "text": "Uw verzoek om lid te worden van {poi} is afgewezen",
                        "title": "Verzoek van medewerker afgewezen"
                    },
                    "pending": {
                        "text": "{user} verzoek om inschrijving als medewerker van uw instelling {poi}",
                        "title": "Verzoek van medewerker ontvangen"
                    },
                    "removed": {
                        "text": "Uw werknemersstatus van {poi} is verwijderd",
                        "title": "Werknemersstatus verwijderd"
                    }
                },
                "invitation": {
                    "accepted": {
                        "email": {
                            "text": "Uw uitnodiging om deel te nemen aan de aanvraag die naar {credential} is verzonden, is geaccepteerd"
                        },
                        "phone": {
                            "text": "Uw uitnodiging om deel te nemen aan de aanvraag die naar {credential} is verzonden, is geaccepteerd"
                        },
                        "title": "Uitnodiging geaccepteerd"
                    },
                    "pro": {
                        "text": "Uw professionele uitnodiging is naar {email} verzonden",
                        "title": "Professionele uitnodiging verzonden"
                    }
                },
                "office": {
                    "claim": {
                        "content": {
                            "line_1": "Er is zojuist een aandachtspunt geclaimd.",
                            "line_2": "Klik op de onderstaande knop om de details in de beheerdersinterface te bekijken."
                        },
                        "cta": "Zie bewering",
                        "subject": "Nieuwe claim!"
                    },
                    "claim_dispute": {
                        "content": {
                            "line_1": "Er is zojuist een punt van belang betwist.",
                            "line_2": "Klik op de onderstaande knop om de details in de beheerdersinterface te bekijken."
                        },
                        "cta": "Zie geschil",
                        "subject": "Nieuw geschil!"
                    },
                    "report": {
                        "content": {
                            "line_1": "{type} is zojuist gerapporteerd."
                        },
                        "cta": "Zie rapport",
                        "subject": "Nieuw rapport!",
                        "type": {
                            "App\\Models\\PointOfInterest": "Een aandachtspunt",
                            "App\\Models\\User": "een gebruiker"
                        }
                    },
                    "support_request": {
                        "content": {
                            "line_1": "Er is zojuist een nieuw ondersteuningsverzoek toegevoegd."
                        },
                        "cta": "Zie ondersteuningsverzoek",
                        "subject": "Nieuw ondersteuningsverzoek!"
                    }
                },
                "payment": {
                    "proposal": {
                        "text": "Bedank {username} van {poi_title} instelling door een fooi achter te laten",
                        "text_alt": "Bedank de conciërge van {poi_title} instelling door een fooi achter te laten",
                        "text_alt_2": "Bedank {username} door een fooi achter te laten",
                        "text_alt_3": "Bedank de conciërge door een fooi achter te laten",
                        "title": "Laat een fooi achter"
                    },
                    "received": {
                        "text": "Je hebt een {amount} fooi ontvangen van {payer} van {poi_title} instelling",
                        "text_alt": "Je hebt een {amount} fooi ontvangen van {payer}",
                        "text_alt_2": "Je hebt een {amount} fooi ontvangen",
                        "title": "Tip ontvangen"
                    },
                    "thanks": {
                        "text": "{payee} van {poi_title} instelling bedankt voor de tip!",
                        "text_alt": "{payee} bedankt voor de tip!",
                        "text_alt_2": "U bent bedankt voor de tip",
                        "title": "Bedankt voor de tip!"
                    }
                },
                "point_of_interest": {
                    "event": {
                        "expired": {
                            "text": "Het evenement {title}, opgenomen in uw reisplannen, is verlopen",
                            "title": "Evenement verlopen"
                        }
                    },
                    "moderation": {
                        "denied": {
                            "text": "Uw interessante plaats {title} is geweigerd vanwege de moderatie ervan",
                            "title": "Interessant punt afgewezen"
                        },
                        "published": {
                            "text": "Uw interessante plaats {title} is na moderatie gepubliceerd",
                            "title": "Interessant punt gepubliceerd"
                        }
                    }
                },
                "reservation": {
                    "created": {
                        "text": "{butler_name} van de instelling {butler_poi_title} heeft een reservering gemaakt\n                in uw instelling {poi_title}",
                        "text_alt": "Een conciërge van instelling {butler_poi_title} heeft een reservering gemaakt\n                in uw instelling {poi_title}",
                        "text_alt_2": "{butler_name} heeft een reservering gemaakt\n                in uw instelling {poi_title}",
                        "text_alt_3": "Er is een reservering gemaakt\n                in uw instelling {poi_title}",
                        "title": "Reservering in uw instelling"
                    }
                },
                "share": {
                    "created": {
                        "excursion": {
                            "text": "{username} van {poi_title} institution heeft een reisschema met je gedeeld",
                            "text_alt": "{username} heeft een routebeschrijving met je gedeeld",
                            "text_alt_2": "Er is een routebeschrijving met u gedeeld",
                            "title": "Deel een routebeschrijving"
                        },
                        "point_of_interest": {
                            "text": "{username} van {poi_title} institution heeft een aandachtspunt met je gedeeld",
                            "text_alt": "{username} heeft een interessant punt met je gedeeld",
                            "text_alt_2": "Er is een aandachtspunt met u gedeeld",
                            "title": "Deel een interessant punt"
                        },
                        "text": "{username} van {poi_title} institution heeft een item met je gedeeld",
                        "text_alt": "{username} heeft een item met je gedeeld",
                        "text_alt_2": "Er is een item met je gedeeld"
                    }
                },
                "stripe": {
                    "account_requirements": {
                        "text": "Na de verificatie van uw Stripe-account wordt om nieuwe informatie gevraagd",
                        "title": "Vereisten voor Stripe-accounts"
                    },
                    "payments_enabled": {
                        "text": "U kunt nu betalingen ontvangen via Stripe",
                        "title": "Stripe-betalingen ingeschakeld"
                    }
                },
                "support_request": {
                    "archived": {
                        "text": "Uw ondersteuningsverzoek is gearchiveerd",
                        "title": "Ondersteuningsverzoek gearchiveerd"
                    },
                    "message": {
                        "text": "Er is een antwoord op uw ondersteuningsverzoek geplaatst",
                        "title": "Bericht ontvangen"
                    }
                },
                "traveller_folder": {
                    "approved": {
                        "text": "{username} heeft zijn reizigersmap goedgekeurd",
                        "text_alt": "Een reiziger heeft zijn map goedgekeurd",
                        "title": "Reizigersmap goedgekeurd"
                    },
                    "archived": {
                        "text": "{username} heeft zijn reizigersmap gearchiveerd",
                        "text_alt": "Een reiziger heeft zijn map gearchiveerd",
                        "title": "Reizigersmap gearchiveerd"
                    },
                    "created": {
                        "text": "Er is een verblijf geregistreerd voor uw account bij het hotel {poi_title}.",
                        "title": "Blijf gecreëerd"
                    },
                    "pending": {
                        "text": "{username} heeft voor uw account een verblijf in hotel {poi_title} geregistreerd.\n                Bevestig het, alstublieft.",
                        "text_alt": "Er is een verblijf geregistreerd voor uw account bij het hotel {poi_title}.\n                Bevestig het, alstublieft.",
                        "title": "Blijf in afwachting van bevestiging"
                    },
                    "thanks": {
                        "text": "{username} van {poi_title} instelling bedankt voor uw verblijf",
                        "text_alt": "Een conciërge van {poi_title} instelling bedankt voor uw verblijf",
                        "title": "Bedankt voor uw verblijf"
                    }
                }
            },
            "nova": {
                "actions": {
                    "bank_transfer": {
                        "transfer": "Maak een bankoverschrijving"
                    },
                    "changelog": {
                        "archive": "Archief",
                        "draft": "Voorlopige versie",
                        "publish": "Publiceren"
                    },
                    "claim": {
                        "missing_info": "Ontbrekende informatie",
                        "reject": "Afwijzen",
                        "revoke": "Herroepen",
                        "validate": "Valideer"
                    },
                    "claim_dispute": {
                        "reject": "Afwijzen",
                        "validate": "Valideer"
                    },
                    "payment": {
                        "download_invoice": "Factuur downloaden"
                    },
                    "plan": {
                        "archive": "Archief",
                        "draft": "Voorlopige versie",
                        "publish": "Publiceren"
                    },
                    "point_of_interest": {
                        "deny": "Ontkennen",
                        "draft": "Voorlopige versie",
                        "publish": "Valideer",
                        "submit": "Vraag om gematigdheid"
                    },
                    "post": {
                        "archive": "Archief",
                        "draft": "Voorlopige versie",
                        "publish": "Publiceren",
                        "set_coming_soon": "Stel in op 'binnenkort beschikbaar'"
                    },
                    "privacy_policy": {
                        "archive": "Archief",
                        "confirmation": {
                            "confirm_button_text": "Publiceren",
                            "confirm_text": "Weet u zeker dat u dit privacybeleid wilt publiceren? ALLE gebruikers zullen worden gevraagd om ze opnieuw te valideren!"
                        },
                        "errors": {
                            "resources_count": "U kunt niet meer dan ÉÉN privébeleid publiceren!"
                        },
                        "publish": "Publiceren"
                    },
                    "report": {
                        "process": "Proces",
                        "reject": "Afwijzen"
                    },
                    "support_request": {
                        "answer": "Antwoord",
                        "archive": "Archief",
                        "cancel": "Annuleren",
                        "pending_support": "Wachten op ondersteuning",
                        "pending_user": "Wachten op gebruiker",
                        "resolve": "Oplossen"
                    },
                    "terms_condition": {
                        "archive": "Archief",
                        "confirmation": {
                            "confirm_button_text": "Publiceren",
                            "confirm_text": "Weet u zeker dat u deze algemene voorwaarden wilt publiceren? ALLE gebruikers zullen worden gevraagd om ze opnieuw te valideren!"
                        },
                        "errors": {
                            "resources_count": "U kunt niet meer dan ÉÉN algemene voorwaarden publiceren!"
                        },
                        "publish": "Publiceren"
                    },
                    "user": {
                        "finish": "Maak de les af"
                    },
                    "user_credential_verification": {
                        "validate": "Valideer"
                    }
                },
                "cards": {
                    "new_excursions": "Nieuwe routes",
                    "new_points_of_interest": "Nieuwe aandachtspunten",
                    "new_users": "Nieuwe gebruikers"
                },
                "filters": {
                    "app": "Sollicitatie",
                    "archived_at_gte": "Gearchiveerd na",
                    "archived_at_lte": "Eerder gearchiveerd",
                    "canceled_at_gte": "Na geannuleerd",
                    "canceled_at_lte": "Eerder geannuleerd",
                    "created_at_gte": "Gemaakt na",
                    "created_at_lte": "Eerder gemaakt",
                    "dynamic_translations_enabled": "Dynamische vertalingen ingeschakeld",
                    "extranet": "Extranet",
                    "front": "Voorkant",
                    "group": "Groep",
                    "published_at_gte": "Na gepubliceerd",
                    "published_at_lte": "Eerder gepubliceerd",
                    "reservation_date_gte": "Reservering na",
                    "reservation_date_lte": "Reservering vooraf",
                    "resolved_at_gte": "Na opgelost",
                    "resolved_at_lte": "Eerder opgelost",
                    "static_translations_enabled": "Statische vertalingen ingeschakeld",
                    "to_validate": "Valideren"
                },
                "labels": {
                    "institutions": "Instellingen",
                    "point_of_interest_categories": "Categorieën"
                }
            },
            "opening_hours": {
                "additional_info": "Extra informatie",
                "content": {
                    "date": "De {date}",
                    "date_range_from": "Vanaf {from}",
                    "date_range_from_to": "Van {from} tot {to}",
                    "date_range_to": "Naar {to}",
                    "hours_range_from": "Vanaf {from}",
                    "hours_range_from_to": "Van {from} tot {to}",
                    "hours_range_to": "Naar {to}"
                }
            },
            "partners": {
                "customers": {
                    "title": "Onze klanten"
                },
                "index": {
                    "no_results": "Geen partners gevonden",
                    "our_last_partners": "Onze laatste partners",
                    "title": "Onze partners"
                },
                "show": {
                    "link": "Bezoek website"
                }
            },
            "payments": {
                "checkout": {
                    "cancel": "De betaling is geannuleerd",
                    "custom_amount": "Voer een aangepast bedrag in",
                    "success": "Betaling succesvol uitgevoerd",
                    "tip_amount": "Fooibedrag"
                },
                "index": {
                    "no_results": "Geen betalingen gevonden"
                },
                "labels": {
                    "payee": "Begunstigde:",
                    "payment_service": "Betaald door"
                },
                "payable": {
                    "reservation": "Reserveringsnr.{id}"
                },
                "thanks": {
                    "success": "Bedankt verzonden!"
                }
            },
            "pdf": {
                "common": {
                    "generating": "PDF-bestand genereren...",
                    "waiting": "Even geduld aub..."
                },
                "payments": {
                    "period": "Periode van {from} tot {to}",
                    "title": "Ontvangen betalingen"
                },
                "receipt": {
                    "debited_amount": "Afgeschreven bedrag",
                    "paid_amount": "Betaald bedrag",
                    "payment_date": "Betaaldatum",
                    "payment_method": "Betalingsmiddel",
                    "receipt_for": "Ontvangstbewijs voor betaling aan",
                    "receipt_number": "Ontvangstbewijs voor betaling #{id}",
                    "summary": "Samenvatting"
                },
                "share": {
                    "cta": "Raadpleeg het reisschema",
                    "details": {
                        "line1": "Wij delen graag uw persoonlijke reisroute met u.",
                        "line2": "Hieronder vindt u de inhoud van uw reisschema.",
                        "line3": "Om toegang te krijgen tot gedetailleerde informatie (audio met adres in lokale taal, interactieve kaart,\n                foto's, volledige beschrijving...), klik op de onderstaande knop."
                    }
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "ADMIN": "Toegang tot de administratie",
                        "APP": "Toegang tot de app",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot toegang",
                        "EXTRANET": "Toegang tot het professionele gedeelte",
                        "HORIZON": "Toegang tot Horizon",
                        "TELESCOPE": "Toegang tot telescoop",
                        "VIEW_BUTLER_DASHBOARD": "Bekijk het butlerdashboard",
                        "VIEW_HOTELIER_DASHBOARD": "Bekijk het hoteliersdashboard",
                        "VIEW_MANAGER_DASHBOARD": "Managerdashboard bekijken",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "Bekijk het dashboard van het VVV-kantoor",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "Bekijk het medewerkersdashboard van het VVV-kantoor",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "Log in zonder dat u uw inloggegevens hoeft te verifiëren"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan activiteitenlogboeken",
                        "CREATE": "Maak een activiteitenlogboek",
                        "DELETE": "Een activiteitenlogboek verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een activiteitenlogboek",
                        "RESTORE": "Een activiteitenlogboek herstellen",
                        "UPDATE": "Update een activiteitenlogboek",
                        "VIEW": "Raadpleeg een activiteitenlogboek",
                        "VIEW_ANY": "Raadpleeg de index van activiteitenlogboeken"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan adressen",
                        "CREATE": "Maak een adres aan",
                        "DELETE": "Verwijder een adres",
                        "FORCE_DELETE": "Forceer het verwijderen van een adres",
                        "RESTORE": "Een adres herstellen",
                        "UPDATE": "Een adres bijwerken",
                        "VIEW": "Raadpleeg een adres",
                        "VIEW_ANY": "Raadpleeg de adressenindex"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot instellingen voor bankoverboekingen",
                        "VIEW_ANY": "Raadpleeg de index van instellingen voor bankoverschrijvingen"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "ARCHIVE": "Archiveer een changelog",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan changelogs",
                        "CREATE": "Maak een changelog",
                        "DELETE": "Verwijder een changelog",
                        "DRAFT": "Stel een changelog op",
                        "FORCE_DELETE": "Forceer het verwijderen van een changelog",
                        "PUBLISH": "Publiceer een changelog",
                        "RESTORE": "Herstel een changelog",
                        "UPDATE": "Update een changelog",
                        "VIEW": "Raadpleeg een changelog",
                        "VIEW_ANY": "Raadpleeg de index van changelogs"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot claimgeschillen",
                        "CREATE": "Maak een claimgeschil aan",
                        "DELETE": "Een claimgeschil verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een claimgeschil",
                        "REJECT": "Een claimgeschil afwijzen",
                        "RESTORE": "Een claimgeschil herstellen",
                        "UPDATE": "Een claimgeschil bijwerken",
                        "VALIDATE": "Valideer een claimgeschil",
                        "VIEW": "Raadpleeg een claimgeschil",
                        "VIEW_ANY": "Raadpleeg de index van claimgeschillen"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan claims",
                        "CREATE": "Maak een claim aan",
                        "DELETE": "Een claim verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een claim",
                        "MISSING_INFO": "Een claim afwijzen omdat er ontbrekende informatie is",
                        "REJECT": "Een claim afwijzen",
                        "RESTORE": "Een claim herstellen",
                        "REVOKE": "Een claim intrekken",
                        "SHOW_FILE": "Toon een claimdossier",
                        "UPDATE": "Een claim bijwerken",
                        "VALIDATE": "Valideer een claim",
                        "VIEW": "Raadpleeg een claim",
                        "VIEW_ANY": "Raadpleeg de schade-index"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan landen",
                        "USE_ANY": "Gebruik elk land"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot valuta",
                        "CREATE": "Creëer een munt",
                        "DELETE": "Een valuta verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een valuta",
                        "RESTORE": "Herstel een valuta",
                        "UPDATE": "Een valuta bijwerken",
                        "VIEW": "Raadpleeg een valuta",
                        "VIEW_ANY": "Raadpleeg de valuta-index"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan EasyTransac-accounts",
                        "HAVE": "Zorg voor een EasyTransac-account"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot reisroutes",
                        "CREATE": "Maak een reisplan",
                        "DELETE": "Een reisplan verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een reisplan",
                        "RESTORE": "Een reisplan herstellen",
                        "SHARE": "Deel een routebeschrijving",
                        "UPDATE": "Een reisplan bijwerken",
                        "VIEW": "Raadpleeg een routebeschrijving",
                        "VIEW_ANY": "Raadpleeg de index van routes",
                        "VIEW_KIND_UGOZER_APP": "Bekijk reisroutes met de UgoZER-app",
                        "VIEW_KIND_UGOZER_PRO": "Bekijk routes met UgoZER Pro soort"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot koppelingen tussen reisroutes en interessante plaatsen",
                        "CREATE": "Maak een koppeling tussen een reisplan en een interessante plaats",
                        "DELETE": "Verwijder een link tussen een reisplan en een interessante plaats",
                        "FORCE_DELETE": "Forceer het verwijderen van een koppeling tussen een reisplan en een interessante plaats",
                        "RESTORE": "Herstel de koppeling tussen een reisplan en een interessante plaats",
                        "UPDATE": "Update een link tussen een reisplan en een interessante plaats",
                        "VIEW": "Raadpleeg een link tussen een routebeschrijving en een bezienswaardigheid",
                        "VIEW_ANY": "Raadpleeg de index van links tussen reisroutes en bezienswaardigheden"
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "APPROVE": "Een verzoek van een medewerker van een instelling goedkeuren",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan medewerkers van instellingen",
                        "CANCEL": "Een verzoek van een instellingsmedewerker annuleren",
                        "CREATE": "Maak een instellingsmedewerker aan",
                        "DECLINE": "Een verzoek van een medewerker van een instelling afwijzen",
                        "DELETE": "Een instellingsmedewerker verwijderen",
                        "FEATURE": "Laat een medewerker van een instelling zien",
                        "FORCE_DELETE": "Forceer verwijdering van een instellingsmedewerker",
                        "REMOVE": "Een medewerker uit een instelling verwijderen",
                        "RESTORE": "Herstel een medewerker van een instelling",
                        "UPDATE": "Update een instellingsmedewerker",
                        "VIEW": "Raadpleeg een medewerker van de instelling",
                        "VIEW_ANY": "Raadpleeg de index van de werknemers van de instellingen"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "ARCHIVE": "Een interactie archiveren",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan interacties",
                        "CREATE": "Creëer een interactie",
                        "DELETE": "Een interactie verwijderen",
                        "DRAFT": "Stel een interactie op",
                        "FORCE_DELETE": "Forceer het verwijderen van een interactie",
                        "PUBLISH": "Publiceer een interactie",
                        "RESTORE": "Een interactie herstellen",
                        "UPDATE": "Een interactie bijwerken",
                        "VIEW": "Raadpleeg een interactie",
                        "VIEW_ANY": "Raadpleeg de index van interacties"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan talen",
                        "CREATE": "Creëer een taal",
                        "DELETE": "Verwijder een taal",
                        "FORCE_DELETE": "Forceer het verwijderen van een taal",
                        "RESTORE": "Een taal herstellen",
                        "UPDATE": "Een taal bijwerken",
                        "VIEW": "Raadpleeg een taal",
                        "VIEW_ANY": "Raadpleeg de talenindex"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan media",
                        "CREATE": "Maak een medium",
                        "DELETE": "Een medium verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een medium",
                        "RESTORE": "Een medium herstellen",
                        "UPDATE": "Een medium bijwerken",
                        "VIEW": "Raadpleeg een medium",
                        "VIEW_ANY": "Raadpleeg de media-index"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "Ontvang een melding wanneer een uitnodiging wordt geaccepteerd",
                        "PAYMENT_RECEIVED": "Ontvang een melding wanneer een betaling is ontvangen",
                        "PAYMENT_THANKS": "Ontvang een melding wanneer een betaling is bevestigd",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "Ontvang een melding wanneer een POI-evenement afloopt",
                        "RESERVATION_CREATED": "Ontvang een melding wanneer er een reservering is gemaakt",
                        "SHARE_CREATED": "Ontvang een melding wanneer een share is aangemaakt",
                        "SUPPORT_REQUEST_ARCHIVED": "Ontvang een melding wanneer een ondersteuningsverzoek wordt gearchiveerd",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "Ontvang een melding wanneer er een bericht wordt ontvangen van een ondersteuning\n                verzoek",
                        "TRAVELLER_FOLDER_APPROVED": "Ontvang een melding wanneer een reizigersdossier is goedgekeurd",
                        "TRAVELLER_FOLDER_ARCHIVED": "Ontvang een melding wanneer een reizigersdossier wordt gearchiveerd",
                        "TRAVELLER_FOLDER_PENDING": "Ontvang een melding terwijl u wacht op een bestand dat als reiziger moet worden gevalideerd",
                        "TRAVELLER_FOLDER_THANKS": "Ontvang een melding om de conciërge die verantwoordelijk is voor een dossierreiziger te bedanken"
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan partners",
                        "CREATE": "Creëer een partner",
                        "DELETE": "Verwijder een partner",
                        "FORCE_DELETE": "Forceer het verwijderen van een partner",
                        "RESTORE": "Een partner herstellen",
                        "UPDATE": "Update een partner",
                        "VIEW": "Raadpleeg een partner",
                        "VIEW_ANY": "Raadpleeg de partnerindex"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot betalingen",
                        "CREATE": "Maak een betaling aan",
                        "DELETE": "Een betaling verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een betaling",
                        "GET_RECEIPT": "Ontvang een ontvangstbewijs voor een betaling",
                        "RECEIVE": "Ontvang betalingen",
                        "RESTORE": "Een betaling herstellen",
                        "THANK": "Ontvang een bedankje voor een betaling",
                        "UPDATE": "Een betaling bijwerken",
                        "VIEW": "Raadpleeg een betaling",
                        "VIEW_ANY": "Raadpleeg de betalingsindex"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen die verband houden met machtigingen",
                        "CREATE": "Maak een machtiging aan",
                        "DELETE": "Een machtiging verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een machtiging",
                        "RESTORE": "Een machtiging herstellen",
                        "UPDATE": "Een machtiging bijwerken",
                        "VIEW": "Raadpleeg een toestemming",
                        "VIEW_ANY": "Raadpleeg de machtigingsindex"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "ARCHIVE": "Een abonnement archiveren",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan abonnementen",
                        "CREATE": "Maak een abonnementsplan",
                        "DELETE": "Een abonnement verwijderen",
                        "DRAFT": "Stel een abonnementsplan op",
                        "FORCE_DELETE": "Forceer het verwijderen van een abonnement",
                        "PUBLISH": "Publiceer een abonnementsplan",
                        "RESTORE": "Een abonnement herstellen",
                        "SUBSCRIBE": "Abonneer u op een abonnement",
                        "UPDATE": "Update een abonnementsplan",
                        "VIEW": "Raadpleeg een abonnementsplan",
                        "VIEW_ANY": "Raadpleeg de index van abonnementen"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot categorieën van nuttige plaatsen",
                        "CREATE": "Maak een categorie voor nuttige plaatsen",
                        "DELETE": "Verwijder een POI-categorie",
                        "FORCE_DELETE": "Forceer het verwijderen van een POI-categorie",
                        "RESTORE": "Herstel een categorie nuttige plaatsen",
                        "UPDATE": "Een POI-categorie bijwerken",
                        "VIEW": "Raadpleeg een categorie nuttige plaatsen",
                        "VIEW_ANY": "Raadpleeg de index van de categorieën nuttige plaatsen"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "ADMIN_ANY": "Beheer elk interessant punt",
                        "APPLY": "Solliciteer als werknemer",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot nuttige plaatsen",
                        "CLAIM": "Claim een ​​aandachtspunt",
                        "CONTEST": "Betwist een interessant punt",
                        "CONTRIBUTE": "Draag bij aan een interessant punt",
                        "CREATE": "Creëer een aandachtspunt",
                        "DELETE": "Een nuttig punt verwijderen",
                        "DENY": "Een interessant punt weigeren",
                        "DRAFT": "Stel een aandachtspunt op",
                        "FORCE_DELETE": "Forceer het verwijderen van een interessant punt",
                        "IMPORT": "Importeer interessante plaatsen",
                        "MODERATE": "Matig een bezienswaardigheid",
                        "PUBLISH": "Publiceer een interessant punt",
                        "REPORT": "Meld een bezienswaardigheid",
                        "RESTORE": "Een interessant punt herstellen",
                        "SHARE": "Deel een interessant punt",
                        "SUBMIT": "Dien een aandachtspunt in",
                        "UPDATE": "Een nuttige plaats bijwerken",
                        "UPDATE_CERTIFIED": "Update het gecertificeerde kenmerk van een nuttige plaats",
                        "VIEW": "Raadpleeg een bezienswaardigheid",
                        "VIEW_ANY": "Raadpleeg de index van bezienswaardigheden"
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Negeer rechten gerelateerd aan berichtcategorieën",
                        "CREATE": "Maak een berichtencategorie",
                        "DELETE": "Verwijder een berichtcategorie",
                        "FORCE_DELETE": "Forceer het verwijderen van een berichtcategorie",
                        "RESTORE": "Herstel een berichtcategorie",
                        "UPDATE": "Update een berichtencategorie",
                        "VIEW": "Raadpleeg een berichtencategorie",
                        "VIEW_ANY": "Raadpleeg de index van berichtcategorieën"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "ARCHIVE": "Een bericht archiveren",
                        "BYPASS_PERMISSIONS": "Negeer rechten gerelateerd aan berichten",
                        "CREATE": "Maak een bericht",
                        "DELETE": "Verwijder een bericht",
                        "DRAFT": "Stel een bericht op",
                        "FORCE_DELETE": "Forceer het verwijderen van een bericht",
                        "PUBLISH": "Publiceer een bericht",
                        "RESTORE": "Een bericht herstellen",
                        "UPDATE": "Een bericht bijwerken",
                        "VIEW": "Een bericht raadplegen",
                        "VIEW_ANY": "Raadpleeg de index van berichten"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "ARCHIVE": "Archiveer een privacybeleid",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan privacybeleid",
                        "CREATE": "Maak een privacybeleid",
                        "DELETE": "Een privacybeleid verwijderen",
                        "DRAFT": "Stel een privacybeleid op",
                        "FORCE_DELETE": "Forceer het verwijderen van een privacybeleid",
                        "PUBLISH": "Publiceer een privacybeleid",
                        "RESTORE": "Een privacybeleid herstellen",
                        "UPDATE": "Update een privacybeleid",
                        "VIEW": "Raadpleeg een privacybeleid",
                        "VIEW_ANY": "Raadpleeg het privacybeleid"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "Registreer u bij het professionele gedeelte met elke rol die deze toestemming heeft"
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "ARCHIVE": "Een rapport archiveren",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot rapporten",
                        "CREATE": "Maak een rapport",
                        "DELETE": "Een rapport verwijderen",
                        "DRAFT": "Stel een rapport op",
                        "FORCE_DELETE": "Een rapport geforceerd verwijderen",
                        "PROCESS_ANY": "Verwerk elke melding",
                        "PUBLISH": "Publiceer een rapport",
                        "REJECT_ANY": "Wijs elk rapport af",
                        "RESTORE": "Een rapport herstellen",
                        "UPDATE": "Een rapport bijwerken",
                        "VIEW": "Raadpleeg een rapport",
                        "VIEW_ANY": "Raadpleeg de index van rapporten"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "ARCHIVE": "Een reservering archiveren",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan reserveringen",
                        "CANCEL": "Een reservering annuleren",
                        "CONFIRM": "Bevestig een reservering",
                        "CREATE": "Maak een reservering",
                        "DELETE": "Een reservering verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een reservering",
                        "RESTORE": "Een reservering herstellen",
                        "UPDATE": "Een reservering bijwerken",
                        "VIEW": "Raadpleeg een reservering",
                        "VIEW_ANY": "Raadpleeg de index van reserveringen"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan rollen",
                        "CREATE": "Creëer een rol",
                        "DELETE": "Verwijder een rol",
                        "FORCE_DELETE": "Forceer het verwijderen van een rol",
                        "RESTORE": "Een rol herstellen",
                        "UPDATE": "Een rol bijwerken",
                        "VIEW": "Raadpleeg een rol",
                        "VIEW_ANY": "Raadpleeg de index van rollen"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot shares",
                        "CREATE": "Maak een aandeel",
                        "DELETE": "Een aandeel verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een share",
                        "RESTORE": "Een aandeel herstellen",
                        "UPDATE": "Een aandeel bijwerken",
                        "VIEW": "Raadpleeg een aandeel",
                        "VIEW_ANY": "Raadpleeg de aandelenindex"
                    },
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan Stripe-accounts",
                        "HAVE": "Zorg voor een Stripe-account"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan abonnementen",
                        "CANCEL": "Een abonnement opzeggen",
                        "CREATE": "Maak een abonnement aan",
                        "DELETE": "Een abonnement verwijderen",
                        "FORCE_DELETE": "Een abonnement geforceerd verwijderen",
                        "PAY": "Betaal een abonnement",
                        "RESTORE": "Een abonnement herstellen",
                        "UPDATE": "Een abonnement bijwerken",
                        "VIEW": "Raadpleeg een abonnement",
                        "VIEW_ANY": "Raadpleeg de index van abonnementen"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan verzoeken om ondersteuningscategorieën",
                        "CREATE": "Maak een ondersteuningscategorie",
                        "DELETE": "Verwijder een ondersteuningscategorie",
                        "FORCE_DELETE": "Forceer het verwijderen van een ondersteuningscategorie",
                        "RESTORE": "Herstel een ondersteuningscategorie",
                        "UPDATE": "Update een ondersteuningscategorie",
                        "VIEW": "Raadpleeg een ondersteuningscategorie",
                        "VIEW_ANY": "Raadpleeg de index van ondersteuningscategorieën"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan berichten",
                        "CREATE": "Maak een bericht",
                        "DELETE": "Verwijder een bericht",
                        "FORCE_DELETE": "Forceer het verwijderen van een bericht",
                        "RESTORE": "Een bericht herstellen",
                        "UPDATE": "Een bericht bijwerken",
                        "VIEW": "Raadpleeg een bericht",
                        "VIEW_ANY": "Raadpleeg de berichtenindex"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "ADD_MESSAGE": "Voeg een bericht toe",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan ondersteuningsverzoeken",
                        "CANCEL": "Een ondersteuningsverzoek annuleren",
                        "CREATE": "Maak een ondersteuningsverzoek aan",
                        "DELETE": "Een ondersteuningsverzoek verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een ondersteuningsverzoek",
                        "RESTORE": "Een ondersteuningsverzoek herstellen",
                        "UPDATE": "Een ondersteuningsverzoek bijwerken",
                        "VIEW": "Raadpleeg een ondersteuningsverzoek",
                        "VIEW_ANY": "Raadpleeg de index van ondersteuningsaanvragen"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan tags",
                        "CREATE": "Maak een label",
                        "DELETE": "Verwijder een label",
                        "FORCE_DELETE": "Forceer het verwijderen van een tag",
                        "RESTORE": "Een tag herstellen",
                        "UPDATE": "Een tag bijwerken",
                        "VIEW": "Raadpleeg een tag",
                        "VIEW_ANY": "Raadpleeg de index van tags"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "ARCHIVE": "Archiveer een algemene voorwaarden",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot de algemene voorwaarden",
                        "CREATE": "Maak een algemene voorwaarden",
                        "DELETE": "Een algemene voorwaarden verwijderen",
                        "DRAFT": "Stel een algemene voorwaarden op",
                        "FORCE_DELETE": "Forceer het verwijderen van een algemene voorwaarden",
                        "PUBLISH": "Publiceer een algemene voorwaarden",
                        "RESTORE": "Een algemene voorwaarden herstellen",
                        "UPDATE": "Update een algemene voorwaarden",
                        "VIEW": "Raadpleeg een algemene voorwaarden",
                        "VIEW_ANY": "Raadpleeg de algemene voorwaarden"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot vertaalbare records",
                        "REQUIRE_ANY": "Sta toe dat een verplicht veld wordt vertaald in een taal die niet noodzakelijkerwijs Engels is"
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "Negeer rechten gerelateerd aan e-mails van reizigers",
                        "CREATE": "Maak een reizigers-e-mailadres",
                        "DELETE": "Verwijder een e-mailadres van een reiziger",
                        "FORCE_DELETE": "Forceer het verwijderen van een e-mailadres van een reiziger",
                        "RESTORE": "Herstel een e-mailadres van een reiziger",
                        "UPDATE": "Update een e-mailadres van een reiziger",
                        "VIEW": "Raadpleeg een e-mail van een reiziger",
                        "VIEW_ANY": "Raadpleeg de index van e-mails van reizigers"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "APPROVE": "Een reizigersmap goedkeuren",
                        "ARCHIVE": "Archiveer een reizigersmap",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot reizigersmappen",
                        "CREATE": "Maak een reizigersmap",
                        "DECLINE": "Een reizigersmap weigeren",
                        "DELETE": "Een reizigersmap verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een reizigersmap",
                        "RESTORE": "Herstel een reizigersmap",
                        "THANK": "Bedank een reiziger voor het verblijf",
                        "UPDATE": "Update een reizigersmap",
                        "VIEW": "Raadpleeg een reizigersmap",
                        "VIEW_ANY": "Raadpleeg de index van reizigersmappen"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot verificatie van gebruikersreferenties",
                        "CREATE": "Maak een verificatie van de gebruikersreferenties",
                        "DELETE": "Een verificatie van een gebruikersreferentie verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een verificatie van de gebruikersgegevens",
                        "RESTORE": "Herstel de verificatie van een gebruikersreferentie",
                        "UPDATE": "Een verificatie van een gebruikersreferentie bijwerken",
                        "VIEW": "Raadpleeg een verificatie van de gebruikersgegevens",
                        "VIEW_ANY": "Raadpleeg de index van verificaties van gebruikersgegevens"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "ADD_CONTACT": "Voeg een gebruiker toe als contactpersoon",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan gebruikers",
                        "CREATE": "Maak een gebruiker aan",
                        "DELETE": "Verwijder een gebruiker",
                        "FORCE_DELETE": "Forceer het verwijderen van een gebruiker",
                        "INVITE": "Nodig een gebruiker uit",
                        "REPORT": "Rapporteer een gebruiker",
                        "RESTORE": "Een gebruiker herstellen",
                        "TIP": "Tip een gebruiker",
                        "UPDATE": "Een gebruiker bijwerken",
                        "UPDATE_BANK_ACCOUNT": "Bankrekening bijwerken",
                        "VIEW": "Raadpleeg een gebruiker",
                        "VIEW_ANY": "Raadpleeg de gebruikersindex",
                        "VIEW_AVATAR": "Bekijk de avatar van een gebruiker",
                        "VIEW_CONTACT_EMAIL": "Bekijk het e-mailadres van een gebruiker",
                        "VIEW_CONTACT_PHONE": "Bekijk het contacttelefoonnummer van een gebruiker",
                        "VIEW_REAL_NAME": "Bekijk de echte naam van een gebruiker"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan gebruikersgeoloc-instellingen",
                        "CREATE": "Maak een gebruikersgeoloc-instelling",
                        "DELETE": "Verwijder een geoloc-instelling van een gebruiker",
                        "FORCE_DELETE": "Forceer het verwijderen van een geoloc-instelling van een gebruiker",
                        "RESTORE": "Herstel een geoloc-instelling van een gebruiker",
                        "UPDATE": "Update een geoloc-instelling van een gebruiker",
                        "VIEW": "Raadpleeg een geoloc-instelling van een gebruiker",
                        "VIEW_ANY": "Raadpleeg de index van gebruikersgeoloc-instellingen"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot instellingen voor gebruikersmeldingen",
                        "CREATE": "Maak een instelling voor gebruikersmeldingen",
                        "DELETE": "Verwijder een instelling voor gebruikersmeldingen",
                        "FORCE_DELETE": "Forceer het verwijderen van een instelling voor gebruikersmeldingen",
                        "RESTORE": "Een instelling voor gebruikersmeldingen herstellen",
                        "UPDATE": "Update een instelling voor gebruikersmeldingen",
                        "VIEW": "Raadpleeg een instelling voor gebruikersmeldingen",
                        "VIEW_ANY": "Raadpleeg de index van de instellingen voor gebruikersmeldingen"
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "Negeer machtigingen met betrekking tot de privacy-instellingen van gebruikers",
                        "CREATE": "Maak een privacy-instelling voor gebruikers",
                        "DELETE": "Verwijder een privacy-instelling van een gebruiker",
                        "FORCE_DELETE": "Forceer het verwijderen van een privacy-instelling van een gebruiker",
                        "RESTORE": "Een privacy-instelling van een gebruiker herstellen",
                        "UPDATE": "Update een privacy-instelling voor een gebruiker",
                        "VIEW": "Raadpleeg een privacy-instelling voor gebruikers",
                        "VIEW_ANY": "Raadpleeg de index van de privacy-instellingen van gebruikers"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "APPROVE_CONTACT": "Een gebruikersrelatie goedkeuren",
                        "BLOCK_CONTACT": "Blokkeer een gebruikersrelatie",
                        "BYPASS_PERMISSIONS": "Negeer machtigingen gerelateerd aan gebruikersrelaties",
                        "CREATE": "Creëer een gebruikersrelatie",
                        "DECLINE_CONTACT": "Een gebruikersrelatie weigeren",
                        "DELETE": "Een gebruikersrelatie verwijderen",
                        "FORCE_DELETE": "Forceer het verwijderen van een gebruikersrelatie",
                        "RESTORE": "Een gebruikersrelatie herstellen",
                        "UNBLOCK_CONTACT": "Deblokkeer een gebruikersrelatie",
                        "UPDATE": "Een gebruikersrelatie bijwerken",
                        "VIEW": "Raadpleeg een gebruikersrelatie",
                        "VIEW_ANY": "Raadpleeg de index van gebruikersrelaties"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\AccessEnum": "Toegang",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "Activiteitenlogboeken",
                    "App\\Enums\\Permissions\\AddressEnum": "Adressen",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "Instellingen voor bankoverschrijvingen",
                    "App\\Enums\\Permissions\\ChangelogEnum": "Wijzigingslogboeken",
                    "App\\Enums\\Permissions\\ClaimEnum": "Claims",
                    "App\\Enums\\Permissions\\CountryEnum": "Landen",
                    "App\\Enums\\Permissions\\CurrencyEnum": "Valuta's",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "EasyTransac-account",
                    "App\\Enums\\Permissions\\ExcursionEnum": "Routes",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "Koppeling tussen reisroutes en bezienswaardigheden",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "Medewerkers van instellingen",
                    "App\\Enums\\Permissions\\InteractionEnum": "Interacties",
                    "App\\Enums\\Permissions\\LocaleEnum": "Talen",
                    "App\\Enums\\Permissions\\MediumEnum": "Media",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "Meldingen ontvangen",
                    "App\\Enums\\Permissions\\PartnerEnum": "Partners",
                    "App\\Enums\\Permissions\\PaymentEnum": "Betalingen",
                    "App\\Enums\\Permissions\\PermissionEnum": "Rechten",
                    "App\\Enums\\Permissions\\PlanEnum": "Abonnementsplannen",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "Categorieën voor nuttige plaatsen",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "Interessante punten",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "Berichtcategorieën",
                    "App\\Enums\\Permissions\\PostEnum": "Berichten",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "Privacy beleid",
                    "App\\Enums\\Permissions\\RegistrationEnum": "Registratie",
                    "App\\Enums\\Permissions\\ReportEnum": "Rapporten",
                    "App\\Enums\\Permissions\\ReservationEnum": "Reserveringen",
                    "App\\Enums\\Permissions\\RoleEnum": "Rollen",
                    "App\\Enums\\Permissions\\ShareEnum": "Aandelen",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "Stripe-account",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "Abonnementen",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "Categorieën voor ondersteuningsverzoeken",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "Ondersteuningsberichten",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "Ondersteuningsverzoeken",
                    "App\\Enums\\Permissions\\TagEnum": "Labels",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "Voorwaarden",
                    "App\\Enums\\Permissions\\TranslatableEnum": "Vertaalbare records",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "E-mails van reizigers",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "Reizigersmappen",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "Verificatie van gebruikersreferenties",
                    "App\\Enums\\Permissions\\UserEnum": "Gebruikers",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "Gebruiker geoloc-instellingen",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "Instellingen voor gebruikersmeldingen",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "Privacy-instellingen voor gebruikers",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "Gebruikers relaties"
                }
            },
            "plans": {
                "archive": {
                    "success": "Abonnementsplan(nen) zijn gearchiveerd (indien in aanmerking komend)"
                },
                "confirmation": {
                    "benefit_current_subscription": "Je blijft profiteren van je huidige abonnement\n            tot het einde van de maand al betaald.",
                    "engagement_period": "Dit aanbod omvat een verlovingsperiode van\n            {engagement_period} na de 1e betaling.",
                    "fee": "Voor deze aanbieding geldt een vergoeding van {fee_percentage}% en u heeft er recht op\n            ontvang {tip_percentage}% van uw fooien.",
                    "free": "Dit abonnement is gratis, mits er een fooienverzamelaccount wordt aangemaakt.",
                    "free_period": "Je profiteert van een {free_period} gratis periode.",
                    "free_period_used": "Je hebt je gratis periode al opgebruikt en dat zal niet meer gebeuren\n            kunnen profiteren van de {free_period} die door dit aanbod wordt voorgesteld.",
                    "name": "U staat op het punt zich te abonneren op de \"{name}\"-aanbieding.",
                    "name_launch": "U staat op het punt zich te abonneren op de lanceringsaanbieding \"{name}\".",
                    "no_fee": "Voor deze aanbieding geldt een toeslag van 0%. Hiermee kunt u uw fooien volledig ontvangen.",
                    "please_confirm": "Bevestig uw inschrijving door op onderstaande knop te klikken.",
                    "price_summary": "Dit abonnement kost je\n            {price} excl. belastingen / {price_incl_belastingen} incl. belastingen per maand.",
                    "replace_current_subscription": "Dit abonnement vervangt automatisch uw huidige abonnement."
                },
                "draft": {
                    "success": "Abonnementsplan(nen) zijn opgesteld (indien in aanmerking komend)"
                },
                "free_period_ended_in": "Vrije periodes eindigen {in}",
                "publish": {
                    "success": "Abonnementsplan(nen) zijn gepubliceerd (indien in aanmerking komend)"
                },
                "set_coming_soon": {
                    "success": "Abonnementsplan(nen) zijn ingesteld op 'binnenkort beschikbaar' (indien in aanmerking komend)"
                },
                "your_current_plan": "Uw huidige abonnement"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "Door dit vakje aan te vinken, verklaar ik dat ik een bevoegde vertegenwoordiger ben van de\n            vestiging beweerde. Elke valse verklaring of nabootsing van identiteit zal mij onderwerpen aan civiele en strafrechtelijke vervolging\n            boetes in overeenstemming met de toepasselijke wetgeving.",
                    "success": "Uw claim is ter verificatie ingediend."
                },
                "denied": {
                    "for_the_reason": "Geweigerd om de volgende reden",
                    "moderation_again": "U kunt de nuttige plaats bewerken, waarna deze opnieuw ter controle wordt ingediend, zodat deze kan worden gepubliceerd."
                },
                "deny": {
                    "success": "Interessante punten zijn geweigerd (indien in aanmerking komend)"
                },
                "destroy": {
                    "confirm": "Wil je dit interessante punt: {point_of_interest_name} echt verwijderen?",
                    "success": "Het interessante punt is verwijderd"
                },
                "draft": {
                    "success": "Interessante punt(en) zijn opgesteld (indien in aanmerking komend)"
                },
                "duplicates": {
                    "check_explanation1": "Om uw interessepunt te creëren,\n            Vul de onderstaande hoofdvelden in.",
                    "check_explanation2": "Om dit te voorkomen wordt een controle uitgevoerd\n            een reeds bestaand punt invoeren.",
                    "check_explanation3": "Na verificatie kunt u voltooien\n            de informatie van uw punt en sla deze op.",
                    "check_explanation_title": "Verificatie",
                    "continue_creation": "Ga door met creëren",
                    "found_explanation1": "Een of meer nuttige plaatsen die overeenkomen met\n            uw invoercriteria bestaan ​​al.",
                    "found_explanation2": "Als u denkt dat dit geen duplicaat is,\n            Vervolgens kunt u doorgaan met het invoeren van uw nieuwe nuttige plaats.",
                    "match_percentage": "Komt overeen met {percentage}%",
                    "select_duplicate": "Klik op een van de voorgestelde bezienswaardigheden om de details te bekijken",
                    "use_poi": "Gebruik dit interessante punt",
                    "view_poi": "Bekijk dit aandachtspunt"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "Voeg dit interessante punt toe aan een reisplan",
                    "already_in_excursions": "Deze bezienswaardigheid maakt al deel uit van deze routes",
                    "create_excursion": "Maak een reisplan",
                    "or_create_from": "Of maak een nieuwe reisroute vanaf dit interessante punt",
                    "select_which": "Selecteer aan welk bestaand reisplan u de nuttige plaats \"{point_of_interest_name}\" wilt toevoegen",
                    "success": "De bezienswaardigheid is toegevoegd aan het reisschema"
                },
                "form": {
                    "help": {
                        "stars": "Alleen als de categorie met sterren kan worden genoteerd (zoals hotel, restaurant...)"
                    }
                },
                "import_source": {
                    "DataTourisme": "DataToerisme"
                },
                "index": {
                    "no_results": "Geen interessante punten gevonden",
                    "no_results_here": "Er zijn geen bijzondere bestemmingen beschikbaar in uw zoekgebied.",
                    "no_tags": "Geen tag gedefinieerd",
                    "refresh_delayed": "Wacht a.u.b. {time_remaining} voordat u vernieuwt",
                    "search": "Zoek een interessant punt",
                    "try_more_filters": "U kunt uw actieve filters wijzigen en uw zoekopdracht uitbreiden."
                },
                "pages": {
                    "show": {
                        "automatic_update": "Automatische update",
                        "belongs_to_you": "Is van jou",
                        "claim_dispute_pending": {
                            "details": "Details van het verzoek: {message}",
                            "notice": "Lopend claimgeschil",
                            "title": "U heeft een lopend claimgeschil met betrekking tot dit aandachtspunt."
                        },
                        "deletion": {
                            "causes": {
                                "event": "Oorzaak: verlopen evenement"
                            },
                            "title": "In afwachting van verwijdering"
                        },
                        "dt_required_datatourisme_fields": "Bijgewerkt door {dt_created_by} van {import_source} de {dt_last_update}",
                        "excursions_counter": "Is 1 x toegevoegd aan reisroutes",
                        "is_geocoding_1": "Het interessante punt wordt geolokaliseerd.",
                        "is_geocoding_2": "Controleer deze pagina later om het interessante punt te bekijken\n                op de kaart.",
                        "is_refreshing_audio_1": "Er wordt een audiobestand gegenereerd.",
                        "is_refreshing_audio_2": "Bezoek deze pagina later om naar deze audio te luisteren.",
                        "not_current_locale": {
                            "change_locale": "U kunt de weergegeven taal wijzigen met behulp van de bovenstaande selector",
                            "incomplete_translation": "Sommige informatie over deze bezienswaardigheid (titel, beschrijving en/of adres) is niet beschikbaar in de geselecteerde taal.",
                            "missing_translations": "Ontbrekende vertalingen"
                        },
                        "opening_hours": {
                            "expired": "Openingstijden verlopen",
                            "is_deleting": "Het interessante punt wordt over {days} dagen verwijderd",
                            "show": "Zie openingstijden"
                        },
                        "sections": {
                            "audios": "Audio",
                            "gallery": "Galerij",
                            "logo": "Logo",
                            "main_image": "Hoofdafbeelding",
                            "map": "Kaart",
                            "media": "Media",
                            "poi": "Punt van belang",
                            "videos": "Videos"
                        },
                        "see_full_page": "Zie volledige pagina"
                    }
                },
                "print": {
                    "element_not_found": "Af te drukken element \"{id}\" niet gevonden!"
                },
                "publish": {
                    "success": "Interessante plaats(en) zijn gevalideerd en gepubliceerd (indien in aanmerking komend)"
                },
                "report": {
                    "choose_reason": "Kies een reden om dit interessante punt \"{point_of_interest_title}\" te melden",
                    "heading": "Meld een bezienswaardigheid",
                    "success": "Het interessante punt is gemeld!"
                },
                "search": {
                    "creator_id": "Alleen mijn punten tonen?",
                    "no_results": "Geen interessante punten gevonden...",
                    "tags_mode": {
                        "all": "Alle",
                        "all_help": "Selecteer \"alles\" om interessante plaatsen te vinden\n                met alle geselecteerde tags.",
                        "one": "Minstens een",
                        "one_help": "Selecteer \"minstens één\" om interessante plaatsen te vinden\n                met ten minste één van de geselecteerde tags."
                    }
                },
                "share": {
                    "heading": "Deel dit aandachtspunt",
                    "select_which_contacts": "Selecteer de contacten waarmee u de nuttige plaats \"{point_of_interest_title}\" wilt delen",
                    "select_which_users": "Selecteer de gebruikers met wie u de nuttige plaats \"{point_of_interest_title}\" wilt delen of voer een e-mailadres in om een ​​nieuwe reiziger uit te nodigen",
                    "select_which_users_alt": "Voer het e-mailadres in van de persoon met wie u het bericht wilt delen\n            \"{point_of_interest_title}\" interessante plaats.",
                    "success": "Het aandachtspunt is gedeeld!"
                },
                "states": {
                    "draft": "Voorlopige versie",
                    "pending": "In behandeling",
                    "published": "Gepubliceerd"
                },
                "store": {
                    "heading": "Creëer een aandachtspunt",
                    "success": "Het interessante punt is gecreëerd"
                },
                "submit": {
                    "success": "Interessante punt(en) is(zijn) in afwachting van moderatie (indien in aanmerking komend)"
                },
                "update": {
                    "alert": "Als u dit punt bijwerkt, wordt de automatische update van {import_source}-import uitgeschakeld",
                    "confirm": "Wil je dit aandachtspunt: {point_of_interest_name} echt bijwerken?",
                    "heading": "Bewerk dit interessante punt",
                    "success": "Het interessante punt is bijgewerkt"
                }
            },
            "posts": {
                "archive": {
                    "success": "Bericht(en) is(zijn) gearchiveerd (indien in aanmerking komend)"
                },
                "draft": {
                    "success": "Bericht(en) is(zijn) opgesteld (indien in aanmerking komend)"
                },
                "publish": {
                    "success": "Bericht(en) is(zijn) gepubliceerd (indien in aanmerking komend)"
                },
                "search": {
                    "type_to_search": "Zoek in de geselecteerde categorie..."
                }
            },
            "prices": {
                "excl_taxes": "excl. belastingen",
                "free": "Vrij",
                "from": "Van",
                "incl_taxes": "incl. belastingen",
                "per_month": "Per maand",
                "price_excl_taxes": "Prijs excl. belastingen",
                "price_incl_taxes": "Prijs incl. belastingen",
                "vat_amount": "BTW-bedrag",
                "vat_percentage": "BTW-percentage"
            },
            "privacy_policies": {
                "archive": {
                    "success": "Privacybeleid(en) is(zijn) gearchiveerd (indien in aanmerking komend)"
                },
                "errors": {
                    "not_found": "Geen privacybeleid gevonden!"
                },
                "publish": {
                    "success": "Privacybeleid is gepubliceerd"
                },
                "update": {
                    "acceptation": "U moet het privacybeleid accepteren om door te gaan",
                    "new_version": "Er is een nieuwe versie van het privacybeleid gepubliceerd",
                    "success": "Privacybeleid is geaccepteerd!"
                }
            },
            "reports": {
                "process": {
                    "success": "Rapport(en) is(zijn) verwerkt (indien in aanmerking komend)"
                },
                "reject": {
                    "success": "Rapport(en) is(zijn) afgewezen (indien in aanmerking komend)"
                }
            },
            "reservations": {
                "archive": {
                    "confirm": "Wilt u deze reservering echt archiveren?",
                    "success": "De reservering is gearchiveerd"
                },
                "cancel": {
                    "confirm": "Wilt u deze reservering echt annuleren?",
                    "success": "De reservering is geannuleerd"
                },
                "confirm": {
                    "confirm": "Wilt u deze reservering echt bevestigen?",
                    "success": "De reservering is bevestigd"
                },
                "destroy": {
                    "confirm": "Wilt u deze reservering echt verwijderen?",
                    "success": "De reservering is verwijderd"
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "butler": "reiziger",
                                "poi": "reiziger",
                                "traveller": "instelling"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "butler": "Conciërge",
                            "poi": "Punt van belang",
                            "reservation": "Reservering",
                            "traveller": "Reiziger"
                        }
                    }
                },
                "states": {
                    "archived": "Gearchiveerd",
                    "canceled": "Geannuleerd",
                    "confirmed": "Bevestigd",
                    "pending": "In behandeling"
                },
                "store": {
                    "success": "De reservering is gemaakt"
                },
                "update": {
                    "success": "De reservering is bijgewerkt"
                }
            },
            "settings": {
                "items": {
                    "about": "Over",
                    "geoloc_settings": "Geolocatie-instellingen",
                    "notification_settings": "Instellingen voor meldingen",
                    "privacy_setting": "Privacy instellingen",
                    "profile": "Profiel",
                    "signOut": "Afmelden"
                }
            },
            "shares": {
                "emails": {
                    "select_hint": "U kunt een eerder gebruikt e-mailadres selecteren door hieronder te klikken:",
                    "space_hint": "Gescheiden door een spatie om meerdere e-mails te delen"
                },
                "index": {
                    "no_results": "Geen aandelen gevonden!"
                },
                "link": {
                    "instructions": "Kopieer deze link om een ​​reiziger uit te nodigen en deze reisroute met hem of haar te delen"
                },
                "show": {
                    "shared_by": "Gedeeld door"
                },
                "type": {
                    "excursions": "Routebeschrijving",
                    "pointsofinterest": "Punt van belang"
                }
            },
            "states": {
                "changelog_state": {
                    "archived": "Gearchiveerd",
                    "draft": "Voorlopige versie",
                    "published": "Gepubliceerd"
                },
                "claim_dispute_state": {
                    "pending": "In behandeling",
                    "rejected": "Afgewezen",
                    "validated": "Gevalideerd"
                },
                "claim_state": {
                    "missing-info": "Missende informatie",
                    "pending": "In behandeling",
                    "rejected": "Afgewezen",
                    "revoked": "Ingetrokken",
                    "validated": "Gevalideerd"
                },
                "payment_state": {
                    "canceled": "Geannuleerd",
                    "completed": "Voltooid",
                    "failed": "Mislukt",
                    "pending": "In behandeling",
                    "transferred": "Overgedragen"
                },
                "plan_state": {
                    "archived": "Gearchiveerd",
                    "coming_soon": "Binnenkort beschikbaar",
                    "draft": "Voorlopige versie",
                    "published": "Gepubliceerd"
                },
                "point_of_interest_state": {
                    "deleted": "Verwijderd",
                    "denied": "Geweigerd",
                    "draft": "Voorlopige versie",
                    "pending": "In afwachting van moderatie",
                    "published": "Gepubliceerd"
                },
                "post_state": {
                    "archived": "Gearchiveerd",
                    "draft": "Voorlopige versie",
                    "published": "Gepubliceerd"
                },
                "privacy_policy_state": {
                    "archived": "Gearchiveerd",
                    "draft": "Voorlopige versie",
                    "published": "Gepubliceerd"
                },
                "report_state": {
                    "pending": "In behandeling",
                    "processed": "Verwerkt",
                    "rejected": "Afgewezen"
                },
                "reservation_state": {
                    "archived": "Gearchiveerd",
                    "canceled": "Geannuleerd",
                    "confirmed": "Bevestigd",
                    "pending": "In behandeling"
                },
                "subscription_state": {
                    "active": "Actief",
                    "canceled": "Geannuleerd",
                    "pending": "In afwachting van betaling",
                    "suspended": "Opgeschort"
                },
                "support_request_state": {
                    "archived": "Gearchiveerd",
                    "canceled": "Geannuleerd",
                    "pending-support": "In afwachting van ondersteuning",
                    "pending-user": "Gebruiker in behandeling",
                    "resolved": "Opgelost"
                },
                "terms_condition_state": {
                    "archived": "Gearchiveerd",
                    "draft": "Voorlopige versie",
                    "published": "Gepubliceerd"
                },
                "traveller_folder_state": {
                    "approved": "Goedgekeurd",
                    "archived": "Gearchiveerd",
                    "declined": "Afgewezen",
                    "pending": "In behandeling"
                },
                "user_credential_verification_state": {
                    "canceled": "Geannuleerd",
                    "completed": "Voltooid",
                    "pending": "In behandeling"
                },
                "user_tutorial_state": {
                    "claimed": "Geclaimd",
                    "done": "Klaar",
                    "employee-added": "Medewerker toegevoegd",
                    "pending": "In behandeling"
                },
                "user_utm_campaign_state": {
                    "always_on": "Altijd aan"
                },
                "user_utm_medium_state": {
                    "owned_social": "Sociaal netwerk"
                },
                "user_utm_source_state": {
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "linkedin": "LinkedIn"
                },
                "users_relationship_state": {
                    "approved": "Goedgekeurd",
                    "blocked": "Geblokkeerd",
                    "declined": "Afgewezen",
                    "pending": "In behandeling"
                }
            },
            "stripe": {
                "connect": {
                    "account": {
                        "create": {
                            "completed": "Stripe-accountconfiguratie voltooid",
                            "link_invalid": "Verbinding met Stripe mislukt. Probeer het opnieuw"
                        },
                        "must_subscribe": "U moet geabonneerd zijn om uw Stripe-account aan te maken.",
                        "show": {
                            "account_usage": "Met uw Stripe-account kan uw instelling betalingen ontvangen op UgoZER.",
                            "can_receive_payments": "U kunt betalingen ontvangen.",
                            "cannot_receive_payments": "U kunt geen betalingen ontvangen",
                            "fee_percentage": "Er wordt een toeslag van {percentage}% in rekening gebracht voor elke betaling die u ontvangt.",
                            "go_to_stripe": "Ga naar het Stripe-dashboard",
                            "has_stripe_account_being_reviewed": "Je account wordt beoordeeld door Stripe.",
                            "no_account_yet": "Je hebt nog geen Stripe-account.",
                            "should_complete_stripe_onboarding": "Voltooi uw registratie bij Stripe en geef hen de gevraagde informatie."
                        }
                    }
                },
                "stripe": "Streep"
            },
            "subscriptions": {
                "activated_at": "Activering",
                "cancel": "Annuleren",
                "cancelation_success": "Er wordt rekening gehouden met annulering",
                "canceled_at": "Annulering",
                "confirm_cancel": "Wil je je abonnement echt opzeggen?",
                "confirm_cancel_date": "U profiteert van de voordelen tot de effectieve opzegdatum op {date}.",
                "confirm_cancel_now": "De opzegging gaat onmiddellijk in.",
                "expiry": "Vervaldatum",
                "invoices": "Facturen",
                "my_current_subscription": "Mijn huidige abonnement",
                "price_excl_taxes": "Prijs excl. belastingen",
                "price_incl_taxes": "Prijs incl. belastingen",
                "upcoming_subscription": "Aankomend abonnement"
            },
            "support_messages": {
                "show": {
                    "you": "Jij",
                    "your_question": "Jouw vraag"
                },
                "store": {
                    "heading": "Voeg een bericht toe",
                    "success": {
                        "title": "Het bericht is gemaakt. Contactondersteuning zal binnen 48 uur antwoorden."
                    }
                }
            },
            "support_requests": {
                "actions": {
                    "archived": {
                        "success": "De aanvraag wordt gearchiveerd"
                    },
                    "canceled": {
                        "success": "Het verzoek wordt geannuleerd"
                    },
                    "pending_support": {
                        "success": "Wachten op ondersteuning om te antwoorden"
                    },
                    "pending_user": {
                        "success": "Wachtende gebruiker om te antwoorden"
                    },
                    "resolved": {
                        "success": "Het verzoek is opgelost"
                    }
                },
                "cancel": {
                    "confirm": "Wilt u dit ondersteuningsverzoek echt annuleren: {support_request_title}?",
                    "error": "Het ondersteuningsverzoek kan niet worden geannuleerd",
                    "success": "Het ondersteuningsverzoek is geannuleerd"
                },
                "index": {
                    "no_results": "U heeft geen ondersteuningsverzoek",
                    "search": "Zoek een ondersteuningsverzoek",
                    "your_requests": "Uw ondersteuningsverzoeken"
                },
                "pages": {
                    "create": {
                        "notice": "Persoonlijke gegevens"
                    },
                    "index": {
                        "search": "Zoek een ondersteuningsverzoek"
                    },
                    "show": {
                        "sections": {
                            "messages": "Berichten",
                            "request": "Verzoek"
                        }
                    }
                },
                "show": {
                    "add_request": "Voeg een nieuw ondersteuningsverzoek toe"
                },
                "states": {
                    "archived": "Gearchiveerd",
                    "canceled": "Geannuleerd",
                    "pending-support": "In afwachting van ondersteuning",
                    "pending-user": "Gebruiker in behandeling",
                    "resolved": "Opgelost"
                },
                "store": {
                    "heading": "Maak een nieuw ondersteuningsverzoek aan",
                    "success": {
                        "title": "De aanvraag is aangemaakt. Contactondersteuning zal binnen 48 uur antwoorden."
                    }
                }
            },
            "terms_conditions": {
                "archive": {
                    "success": "Algemene voorwaarden zijn gearchiveerd (indien in aanmerking komend)"
                },
                "errors": {
                    "not_found": "Geen algemene voorwaarden gevonden!"
                },
                "publish": {
                    "success": "Algemene voorwaarden zijn gepubliceerd"
                },
                "update": {
                    "acceptation": "Om door te gaan, moet u de algemene voorwaarden accepteren",
                    "new_version": "Er is een nieuwe versie van de algemene voorwaarden gepubliceerd",
                    "success": "Algemene voorwaarden zijn geaccepteerd!"
                }
            },
            "traveller_folders": {
                "messages": {
                    "approve": {
                        "confirm": "Wilt u dit verblijf echt bevestigen?",
                        "success": "Blijf bevestigd!"
                    },
                    "archive": {
                        "confirm": "Wilt u dit verblijf echt archiveren?",
                        "success": "Dit verblijf is gearchiveerd!"
                    },
                    "decline": {
                        "confirm": "Wilt u dit verblijf echt weigeren?",
                        "success": "Blijf afgewezen!"
                    }
                },
                "no_results": {
                    "approved": "Geen goedgekeurde verblijven",
                    "archived": "Geen gearchiveerde verblijven",
                    "declined": "Geen geweigerde verblijven",
                    "pending": "Geen verblijfsaanvragen"
                },
                "pages": {
                    "form": {
                        "cannot_attach": {
                            "description": "Deze gebruiker bestaat, maar kan niet worden ondersteund...",
                            "title": "Ondersteuning niet mogelijk"
                        },
                        "poi_empty_option": "Verwijder het interessante punt",
                        "poi_label": "Conciërge instelling",
                        "verification": {
                            "description": "Voer het e-mailadres van de reiziger in. Als er al een reizigersaccount bestaat, zal dat ook het geval zijn\n                    direct gekoppeld zijn, anders moet u aanvullende informatie invoeren om een\n                    nieuwe.",
                            "title": "Verificatie"
                        }
                    },
                    "index": {
                        "pending_stays": "Uw openstaande verblijven",
                        "search": "Zoek tussen mijn reizigers",
                        "travellers_folders": "Reizigersmappen",
                        "your_stays": "Uw verblijven"
                    },
                    "qr_code_1": "Een reiziger kan deze QR-code scannen om automatisch een reizigersmap te verkrijgen\n            met uw instelling.",
                    "qr_code_2": "Indien de reiziger zich nog niet heeft geregistreerd bij UgoZER, wordt hij doorgestuurd naar de registratie\n            pagina en wordt aan het einde van de registratie aan uw instelling gekoppeld.",
                    "show": {
                        "fields": {
                            "state": "Staat:"
                        },
                        "sections": {
                            "butler": "Gerelateerde conciërge",
                            "folder": "Map",
                            "hotel": "Hotel",
                            "institution": "Gerelateerde instelling",
                            "interactions": "Interacties",
                            "invitation": "Nodig een reiziger uit",
                            "payments": "Betalingen",
                            "reservations": "Reserveringen",
                            "stay": "Verblijf",
                            "thanks": "Bedankt",
                            "traveller": "Reiziger"
                        }
                    },
                    "wizard": {
                        "choose_an_institution": "Kies een instelling",
                        "no_linked_institution": "Voordat u een reiziger kunt ophalen, moet u gekoppeld zijn aan een instelling."
                    }
                },
                "placeholders": {
                    "butler": "Receptieteam"
                },
                "states": {
                    "approved": "Geaccepteerd",
                    "archived": "Gearchiveerd",
                    "declined": "Afgewezen",
                    "pending": "In behandeling",
                    "undefined": "Ongedefinieerd"
                },
                "store": {
                    "success": "De map is gemaakt"
                },
                "tabs": {
                    "approved": "Goedgekeurd",
                    "archived": "Gearchiveerd",
                    "declined": "Afgewezen",
                    "pending": "In behandeling"
                },
                "thank": {
                    "success": "De bedankjes zijn verzonden"
                },
                "update": {
                    "success": "De map is bijgewerkt"
                }
            },
            "tutorials": {
                "extranet": {
                    "begin": {
                        "butler": {
                            "description": "Om u in staat te stellen de UgoZER-applicatie in de beste omstandigheden te gebruiken,\n                    Wij raden u aan de volgende stappen te volgen, die u zullen begeleiden bij de eerste stappen van de\n                    afhandeling en de parameterinstelling van uw conciërgeruimte.",
                            "steps": {
                                "institution": {
                                    "content": "Zoek uw hotel in de lijst met vestigingen met behulp van de zoekbalk en\n                            de beschikbare filters in het linkergedeelte van de pagina. Zodra u uw\n                            vestiging, maakt u een aanvraag aan door op <strong>Toepassen om lid te worden</strong> te klikken\n                            knop.",
                                    "description": "Link naar uw instelling",
                                    "step_next": "beweerde",
                                    "step_state": "in behandeling",
                                    "subtitle": {
                                        "link": "Mijn instelling",
                                        "part_1": "Verbind mij met mijn hotel",
                                        "part_2": "U kunt rechtstreeks naar uw hotel linken op de pagina"
                                    },
                                    "title": "Instelling"
                                }
                            }
                        },
                        "done": {
                            "description": "Je hebt nu de verschillende stappen doorlopen voor het instellen van de UgoZER-applicatie.",
                            "faq": {
                                "link": "FAQ",
                                "part_1": "Als u meer hulp nodig heeft, kunt u artikelen vinden in de",
                                "part_2": "gedeelte van de aanvraag"
                            },
                            "help": "U kunt ook contextuele hulp vinden via de <strong>Help</strong> op sommige pagina's.",
                            "title": "Gefeliciteerd!"
                        },
                        "hotelier": {
                            "description": "Om u in staat te stellen de UgoZER-applicatie in de beste omstandigheden te gebruiken,\n                    Wij raden u aan de volgende stappen te volgen, die u zullen begeleiden bij de eerste stappen van de\n                    afhandeling en de parameterinstelling van uw Hotelmanagerruimte.",
                            "steps": {
                                "employees": {
                                    "description": "Beheer uw conciërges",
                                    "step_next": "medewerker toegevoegd",
                                    "step_state": "beweerde",
                                    "title": "Conciërges"
                                }
                            }
                        },
                        "manager": {
                            "description": "Om u in staat te stellen de UgoZER-applicatie in de beste omstandigheden te gebruiken,\n                    Wij raden u aan de volgende stappen te volgen, die u zullen begeleiden bij de eerste stappen van de\n                    afhandeling en de parametrering van uw Andere zaakbeheerderruimte."
                        },
                        "messages": {
                            "claim": "Uw claim is gevalideerd.",
                            "contest": "Uw geschil is naar ons team verzonden.",
                            "payment": "Uw bankgegevens zijn succesvol geregistreerd.",
                            "point_of_interest": "Het interessante punt is aangemaakt, u kunt het nu claimen."
                        },
                        "no_tutorial": {
                            "content": "Er is geen tutorial ingesteld voor deze rol of u bent verbonden als beheerder.",
                            "title": "Geen beschikbare tutorial"
                        },
                        "notices": {
                            "add_butler": {
                                "content": "Om de applicatie te gebruiken, moet u een conciërgeaccount voor uw vestiging aanmaken\n                        van waaruit informatie naar reizigers wordt verzonden.",
                                "content_2": "Om het conciërgeaccount te activeren, moet uw conciërge zijn e-mailadres bevestigen\n                        adres en maak hun wachtwoord aan.",
                                "title": "Voeg een conciërge toe"
                            },
                            "add_employee": {
                                "content": "Om de applicatie te gebruiken, moet u een werknemersaccount voor uw vestiging aanmaken\n                        van waaruit informatie naar reizigers wordt verzonden.",
                                "content_2": "Om het werknemersaccount te activeren, moet uw werknemer zijn e-mailadres bevestigen\n                        adres en maak hun wachtwoord aan.",
                                "title": "Voeg een medewerker toe"
                            }
                        },
                        "shared": {
                            "actions": {
                                "skip_step": "Stap overslaan",
                                "skip_tutorial": "Handleiding overslaan"
                            },
                            "claims": {
                                "description": "Claim uw instellingen",
                                "documents": {
                                    "association": {
                                        "identity_piece": "Een identiteitsbewijs van de wettelijke vertegenwoordiger in de loop van\n                                    geldig",
                                        "statutes": "Statuten van verenigingen",
                                        "title": "Als u een vereniging bent"
                                    },
                                    "company": {
                                        "identity_piece": "Een identiteitsbewijs van de wettelijke vertegenwoordiger in de loop van\n                                    geldig",
                                        "kbis": "Een KBIS-uittreksel",
                                        "title": "Als u een bedrijf bent"
                                    },
                                    "description": "Om uw instelling te claimen, moet u het volgende voorbereiden\n                                documenten:",
                                    "individual": {
                                        "identity_piece": "Een geldig identiteitsbewijs",
                                        "registration_document": "Een certificaat van registratie van de zelfstandige onderneming",
                                        "title": "Als u een zelfstandige ondernemer bent"
                                    },
                                    "label": "Documenten",
                                    "title": "Claim documenten"
                                },
                                "notice": {
                                    "description": "U kunt deze uitleg vinden door op <strong>Help</strong> te klikken\n                                knop rechtsboven in de lijst met interessante plaatsen.",
                                    "title": "Houd er rekening mee dat"
                                },
                                "step_next": "beweerde",
                                "step_state": "in behandeling",
                                "title": "Claim"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "Weet u zeker dat u deze stap wilt overslaan?",
                                    "success": "De stap werd goed genegeerd!"
                                },
                                "skip_tutorial": {
                                    "confirm": "Weet je zeker dat je deze tutorial wilt overslaan?",
                                    "success": "De tutorial werd goed genegeerd!"
                                }
                            },
                            "payments": {
                                "description": "Ontvang tips",
                                "step_next": "klaar",
                                "step_state": "medewerker toegevoegd",
                                "steps": {
                                    "bank": {
                                        "create": "Klik op de knop <strong>Een account toevoegen</strong> in het\n                                    sectie <strong>Bankrekening</strong>"
                                    },
                                    "easytransac": {
                                        "connection": "Klik op de link\n                                    <strong>Ga naar EasyTransac Dashboard</strong> en log in met de inloggegevens die u heeft\n                                        per e-mail ontvangen",
                                        "create": "Klik op de knop <strong>Maken</strong> in het\n                                    <strong>EasyTransac</strong>-sectie",
                                        "created": "Het account wordt automatisch aangemaakt op basis van de ingevoerde gegevens\n                                    op je profiel",
                                        "form": "Vul het formulier op EasyTransac in om uw accountbetaalaccount te activeren",
                                        "warning": {
                                            "description": "Afhankelijk van het KYC-niveau van uw EasyTransac-account is dat mogelijk\n                                        betalingen kunnen ontvangen, maar geen geld kunnen overboeken naar een reguliere bank\n                                        rekening. geld op een gewone bankrekening. Hiervoor heeft u KYC-niveau nodig\n                                        2 en bepaalde documenten aanleveren in de EasyTransac-interface. de EasyTransac\n                                        koppel.",
                                            "title": "KYC-niveau"
                                        }
                                    },
                                    "my_profile": {
                                        "part_1": "Ga naar de pagina",
                                        "part_2": "Mijn profiel"
                                    }
                                },
                                "subtitle_1": "Om ervoor te zorgen dat uw conciërges fooien kunnen ontvangen, moet u bankgegevens toevoegen.",
                                "subtitle_2": "Om dit te doen:",
                                "title": "Betaling"
                            }
                        },
                        "tourist_office": {
                            "description": "Om u in staat te stellen de UgoZER-applicatie in de beste omstandigheden te gebruiken,\n                    Wij raden u aan de volgende stappen te volgen, die u zullen begeleiden bij de eerste stappen van de\n                    afhandeling en de parametrering van uw VVV-kantoorruimte.",
                            "steps": {
                                "employees": {
                                    "description": "Beheer uw medewerkers",
                                    "step_next": "medewerker toegevoegd",
                                    "step_state": "beweerde",
                                    "title": "Medewerkers"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "Om u in staat te stellen de UgoZER-applicatie in de beste omstandigheden te gebruiken,\n                    Wij raden u aan de volgende stappen te volgen, die u zullen begeleiden bij de eerste stappen van de\n                    afhandeling en de parametrering van uw medewerkersruimte van het VVV-kantoor.",
                            "steps": {
                                "institution": {
                                    "content": "Zoek uw VVV-kantoor in de lijst met vestigingen met behulp van de zoekbalk\n                            en de filters die beschikbaar zijn in het linkergedeelte van de pagina. Zodra u uw\n                            vestiging, maakt u een aanvraag aan door op <strong>Toepassen om lid te worden</strong> te klikken\n                            knop.",
                                    "description": "Link naar uw instelling",
                                    "step_next": "beweerde",
                                    "step_state": "in behandeling",
                                    "subtitle": {
                                        "link": "Mijn instelling",
                                        "part_1": "Verbind mij met mijn VVV-kantoor",
                                        "part_2": "U kunt rechtstreeks naar uw VVV-kantoor linken naar de pagina"
                                    },
                                    "title": "Instelling"
                                }
                            }
                        }
                    },
                    "dashboard": {
                        "claim": {
                            "steps": {
                                "claim_form": "Volg daarna de instructies op het declaratieformulier",
                                "claim_validation": "De claim wordt vervolgens door ons team geverifieerd en gevalideerd",
                                "dont_exist": "Als deze niet bestaat, klik dan op de knop <strong>Maak een interessante plaats\n                        </strong>vul dan het formulier in. Zodra het interessante punt is gemaakt, gaat u naar het bijbehorende punt\n                        kaart en klik op de knop <strong>Claim</strong>",
                                "exist": "Als uw instelling bestaat, ga dan naar het dossier en klik op de knop <strong>\n                        Claim</strong>",
                                "filters": "Gebruik de filters en de zoekfunctie om jouw instelling te vinden",
                                "poi_index": {
                                    "part_1": "Ga naar de",
                                    "part_2": "lijst met aandachtspunten"
                                }
                            },
                            "subtitle_1": "U kunt één (of meerdere) vestiging(en) claimen om deze te beheren.",
                            "subtitle_2": "Om dit te doen:",
                            "title": "Het eigendom en beheer van mijn instelling claimen"
                        },
                        "employees": {
                            "link": "Om een ​​verzoek aan te maken, kunnen ze naar de pagina <strong>Mijn instelling</strong> gaan\n                    van hun ruimte en vul het formulier in.",
                            "manage": "Op deze pagina kunt u medewerkers accepteren of afwijzen die zich bij u willen aansluiten\n                    instelling.",
                            "subtitle": {
                                "part_1": "Via de pagina kunt u uw medewerkers direct beheren",
                                "part_2": "Mijn medewerkers"
                            },
                            "title": "Beheer mijn medewerkers"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "distance": "<strong>Plaats:</strong> hiermee kunt u de punten van een specifieke locatie filteren",
                            "main_title": "Filters",
                            "subtitle": "U kunt de lijst met reisroutes filteren op basis van de volgende criteria:",
                            "title": "Reisroutes filteren",
                            "type": {
                                "customized": "<strong>Aangepast:</strong> reisroutes die speciaal voor een reiziger zijn gemaakt",
                                "explanation": "<strong>Type:</strong> hiermee kunt u filteren op type reisroute",
                                "pro": "<strong>UgoZER Pro:</strong> routes aangeboden door UgoZER",
                                "root": "<strong>Mijn sjablonen:</strong> modelroutes"
                            }
                        }
                    },
                    "institution_employee": {
                        "approve": {
                            "content": "Met deze actie kunt u een verzoek van een van uw medewerkers om lid te worden van een van uw medewerkers accepteren\n                    uw instellingen.",
                            "main_title": "Goedkeuren",
                            "title": "Een medewerker goedkeuren"
                        },
                        "cancel": {
                            "content": "Met deze actie kunt u het uitnodigingsverzoek van een medewerker annuleren in geval van in\n                    geval van een fout, bijvoorbeeld.",
                            "main_title": "Annuleren",
                            "title": "De aanvraag van een medewerker annuleren"
                        },
                        "decline": {
                            "content": "Met deze actie kunt u het verzoek van een van uw medewerkers om zich bij een van uw medewerkers aan te sluiten, weigeren\n                    instellingen.",
                            "main_title": "Weigeren",
                            "title": "Een medewerker weigeren"
                        },
                        "functioning": {
                            "content": {
                                "invite": "Op deze pagina kunt u medewerkers uitnodigen of verzoeken van uw medewerkers accepteren/weigeren\n                        medewerkers.",
                                "section": "In deze sectie kunt u de medewerkers van uw instellingen beheren."
                            },
                            "main_title": "Functioneren",
                            "title": "Werking van de medewerkerssectie"
                        },
                        "invite": {
                            "content": {
                                "button": "Door op de knop <strong>Medewerker uitnodigen</strong> te klikken, kunt u een van uw medewerkers uitnodigen\n                        werknemers, ongeacht of ze al een UgoZER-account hebben of niet.",
                                "form": "Om dit te doen, volgt u eenvoudigweg de instructies op het stapsgewijze formulier dat verschijnt.\n                        vorm die verschijnt."
                            },
                            "main_title": "Uitnodiging",
                            "title": "Nodig een medewerker uit"
                        },
                        "remove": {
                            "content": "Met deze actie kunt u een gebruiker verwijderen uit uw medewerkers in het geval dat een\n                    werknemer verlaat uw bedrijf. als een medewerker uw bedrijf verlaat.",
                            "main_title": "Verwijderen",
                            "title": "Verwijder een medewerker"
                        }
                    },
                    "points_of_interest": {
                        "claim": "Claim",
                        "filters": {
                            "category": "<strong>Categorie:</strong> hiermee kunt u een specifieke categorie kiezen\n                    (Hotel Restaurant...)",
                            "city": "<strong>Stad:</strong> hiermee kunt u filteren op een specifieke stad",
                            "country": "<strong>Landen:</strong> hiermee kunt u filteren op een of meer specifieke landen",
                            "distance": "<strong>Plaats:</strong> hiermee kunt u de punten van een specifieke locatie filteren",
                            "main_title": "Filters",
                            "measure_unit": "<strong>Meeteenheid:</strong> hiermee kunt u de gebruikte maateenheid wijzigen\n                    het afstandsfilter en het display",
                            "my_points": "<strong>Alleen mijn punten weergeven:</strong> geeft alleen punten weer die u heeft gemaakt of\n                    door jou geclaimd",
                            "postal_code": "<strong>Zip:</strong> hiermee kunt u filteren op postcode",
                            "radius": "<strong>Beperk tot een straal:</strong> maakt het mogelijk om de punten in een specifieke cirkel te filteren\n                    straal",
                            "subtitle": "U kunt de lijst met nuttige plaatsen filteren op basis van de volgende criteria:",
                            "tags": "<strong>Verfijnen:</strong> hiermee kunt u de zoekopdracht verfijnen op basis van tags om de categorie te specificeren.\n                    Het is ook mogelijk om te definiëren of u wilt zoeken naar punten met één of alle of alle punten\n                    de geselecteerde tags",
                            "title": "Filter interessante plaatsen"
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "validate": {
                    "success": "De inloggegevens zijn succesvol gevalideerd (indien in aanmerking komend)"
                },
                "verify": {
                    "already_verified": "Uw inloggegevens zijn al geverifieerd",
                    "expired": "De link is verlopen, vraag om een ​​nieuwe",
                    "impossible": "De verificatie van deze inloggegevens is niet meer mogelijk",
                    "not_received": "Heb je de bevestigingslink niet ontvangen?",
                    "sent": {
                        "again": "Opnieuw versturen",
                        "correct": "Corrigeer de gekozen referentie",
                        "email_1": "Er wordt een e-mail verzonden naar {email}, als dit adres nog niet in gebruik is.",
                        "email_2": "Deze e-mail bevat een link waarmee u uw adres kunt bevestigen.",
                        "email_3": "Uw e-mailadres wordt bijgewerkt zodra u de link opent.",
                        "refresh": "Heb je op de link geklikt? Vernieuw de applicatie",
                        "title": "Link verzonden",
                        "wait_before": "Wacht a.u.b. {seconds} seconden voordat u opnieuw verzendt"
                    },
                    "success": "Uw inloggegevens zijn succesvol geverifieerd"
                }
            },
            "user_geoloc_settings": {
                "geolocation": {
                    "error": {
                        "allow_browser": "Laat uw browser u geolokaliseren in de instellingen van uw telefoon",
                        "allow_site": "Laat UgoZER u geolokaliseren in uw browserinstellingen",
                        "intro": "Bezienswaardigheden om u heen bekijken:",
                        "title": "Geolocatie is mislukt"
                    },
                    "intro": "Met geolocatie kunt u de dichtstbijzijnde bezienswaardigheden vinden."
                },
                "not_configured": {
                    "body1": "Selecteer een locatie naar keuze om een ​​selectie van interessante plaatsen in de buurt te bekijken.",
                    "body2": "Het is ook mogelijk om alleen de bijzondere bestemmingen bij u in de buurt weer te geven.",
                    "do_not_ask_again": "Vraag het me niet opnieuw totdat de applicatie opnieuw is opgestart",
                    "subject": "U heeft uw geolocatie-instellingen nog niet gedefinieerd."
                },
                "update": {
                    "success": "Geolocatie-instellingen zijn bijgewerkt"
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "De instellingen voor meldingen zijn bijgewerkt"
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "Privacy-instellingen zijn bijgewerkt"
                }
            },
            "users": {
                "butlers": "Conciërges",
                "finish": {
                    "success": "Tutorial gewijzigd om de status te voltooien"
                },
                "index": {
                    "hint": "Een gebruiker kan worden gevonden op basis van zijn bijnaam of zijn publieke ID.",
                    "no_results": "Geen gebruiker gevonden",
                    "search": "Zoek een gebruiker"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "delete": "Verwijder mijn account?",
                            "profile": "Mijn profiel"
                        }
                    }
                },
                "profile": {
                    "alerts": {
                        "blocked": {
                            "message": "U kunt niet langer communiceren met deze gebruiker.",
                            "title": "Deze gebruiker heeft je geblokkeerd..."
                        },
                        "missing_infos": {
                            "message": "Vul de volgende informatie in om van alle functies van de applicatie te kunnen profiteren:",
                            "title": "Voltooi uw profiel"
                        },
                        "pending_validation": {
                            "message": "Deze gebruiker heeft uw verzoek nog niet geaccepteerd.",
                            "title": "In afwachting van goedkeuring"
                        }
                    },
                    "avatar": {
                        "defaults": {
                            "female": "Vrouwelijk",
                            "male": "Mannelijk"
                        },
                        "section": {
                            "cta": "Gebruik deze avatar",
                            "description": "U kunt een vooraf gedefinieerde avatar selecteren uit de onderstaande lijst",
                            "title": "Een vooraf gedefinieerde avatar kiezen"
                        },
                        "success": "Je avatar is succesvol bijgewerkt!"
                    },
                    "bank_account_number_missing": "Geef uw bankgegevens op om uw betalingen te ontvangen.",
                    "delete_account": {
                        "easy_transac": "Uw EasyTransac-account wordt niet verwijderd.",
                        "instructions": "Om de definitieve verwijdering van uw account te bevestigen, voert u in het onderstaande veld het woord \"VERWIJDEREN\" in en klikt u op de knop \"Bevestigen\".",
                        "success": "Uw account is verwijderd.",
                        "title": "Accountverwijdering",
                        "warning": "Uw account, evenals alle informatie met betrekking tot uw account, wordt onmiddellijk verwijderd zonder mogelijkheid tot herstel."
                    },
                    "edit_email": "Bewerk uw e-mail",
                    "edit_password": "Bewerk uw wachtwoord",
                    "edit_phone": "Bewerk uw telefoonnummer",
                    "edit_your_profile": "pas je profiel aan",
                    "email_missing": "U heeft geen geverifieerd e-mailadres ingevuld.",
                    "fill_country": "Geef uw land op",
                    "form": {
                        "poi_label": "Gekoppelde instelling"
                    },
                    "manage_my_subscription": "Beheer mijn abonnement",
                    "password_missing": "U heeft geen wachtwoord ingevuld.",
                    "phone_missing": "U heeft geen geverifieerd telefoonnummer ingevuld",
                    "preferences": "Voorkeuren",
                    "public_id": "Of gebruik deze identificatie:",
                    "qr_code": "Scan mij om mijn profiel te zien",
                    "security": "Beveiliging",
                    "share_profile": "Deel je profiel",
                    "subscribe_to_receive_payments": "Om betalingen te kunnen ontvangen, moet u geabonneerd zijn.",
                    "unnamed": "Geen naam",
                    "your_personal_information": "Jouw persoonlijke informatie"
                },
                "report": {
                    "choose_reason": "Kies een reden om deze gebruiker \"{user_name}\" te rapporteren",
                    "heading": "Rapporteer een gebruiker",
                    "success": "De gebruiker is gerapporteerd!"
                },
                "search": {
                    "no_results": "Geen gebruikers gevonden"
                },
                "send_link": {
                    "error": "Kan link niet verzenden, heeft gebruiker een e-mailadres?",
                    "success": "Link naar applicatie is verzonden"
                },
                "update": {
                    "success": "Profiel is bijgewerkt"
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "Geen contacten gevonden",
                    "pending_requests": "Uw openstaande contacten"
                },
                "messages": {
                    "add": {
                        "confirm": "Wil je dit contact echt toevoegen?",
                        "error": "Er treedt een fout op tijdens het contactverzoek...",
                        "success": "Contactverzoek succesvol uitgevoerd!"
                    },
                    "approve": {
                        "confirm": "Wilt u dit contactverzoek echt accepteren?",
                        "success": "Contactverzoek geaccepteerd!"
                    },
                    "block": {
                        "confirm": "Wilt u deze gebruiker echt blokkeren?",
                        "success": "Dit contact is geblokkeerd!"
                    },
                    "decline": {
                        "confirm": "Wilt u dit contactverzoek echt afwijzen?",
                        "success": "Contactverzoek afgewezen!"
                    },
                    "unblock": {
                        "confirm": "Wilt u deze gebruiker echt deblokkeren?",
                        "success": "Dit contact is gedeblokkeerd!"
                    }
                },
                "no_results": {
                    "approved": "Geen goedgekeurde contacten",
                    "blocked": "Geen geblokkeerde contacten",
                    "pending": "Geen openstaande contactverzoeken",
                    "sent": "Geen verzonden contactverzoeken"
                },
                "search": {
                    "no_results": "Geen contacten gevonden"
                },
                "tabs": {
                    "approved": "Goedgekeurd",
                    "blocked": "Geblokkeerd",
                    "pending": "In behandeling",
                    "sent": "Verstuurd"
                }
            }
        },
        "validation": {
            "accepted": "De {attribute} moet worden geaccepteerd.",
            "active_url": "De {attribute} is geen geldige URL.",
            "after": "De {attribute} moet een datum na {date} zijn.",
            "after_or_equal": "De {attribute} moet een datum zijn na of gelijk aan {date}.",
            "alpha": "De {attribute} mag alleen letters bevatten.",
            "alpha_dash": "De {attribute} mag alleen letters, cijfers, streepjes en onderstrepingstekens bevatten.",
            "alpha_num": "De {attribute} mag alleen letters en cijfers bevatten.",
            "array": "De {attribute} moet een array zijn.",
            "attributes": {
                "accounting_file": "boekhoudkundig bestand",
                "actions": "acties",
                "activated_at": "geactiveerd op",
                "active": "actief",
                "additional_information": "Extra informatie",
                "address": "adres",
                "addresses": "adressen",
                "admin_message": "bericht",
                "administrative_area": "administratief gebied",
                "amount": "hoeveelheid",
                "app": "sollicitatie",
                "app_fees": "vergoedingen",
                "approved_at": "goedgekeurd bij",
                "archived_at": "Gearchiveerd op",
                "association_legal_representative_identity_piece": "identiteitsbewijs van de wettelijke vertegenwoordiger",
                "association_statutes": "statuten",
                "attached_files": "Bijgevoegde bestanden",
                "audio": "geluid",
                "audio_refreshing": "audio verfrissend",
                "audios": "audio",
                "authenticated_once": "één keer geauthenticeerd",
                "auto_renewal_enabled": "automatische verlenging ingeschakeld",
                "avatar": "avatar",
                "bank_account": "bankrekening",
                "bank_account_number": "bankrekeningnummer",
                "bank_bic": "BIC",
                "bank_city": "Bankstad",
                "bank_country": "Bank Land",
                "bank_file": "bankbestand",
                "bank_iban": "IBAN",
                "bank_name": "banknaam",
                "bank_payee": "Begunstigde",
                "bank_transfer": "overschrijving",
                "bank_transfer_institutions": "instelling voor bankoverschrijvingen",
                "bank_transfer_reason": "Reden van overdracht",
                "butler": "conciërge",
                "butler_point_of_interest": "conciërge-instelling",
                "canceled_at": "Geannuleerd om",
                "categories": "categorieën",
                "category": "categorie",
                "category_id": "categorie ID",
                "causer": "veroorzaker",
                "causer_id": "veroorzaker id",
                "causer_type": "soort veroorzaker",
                "certified": "gecertificeerd",
                "city": "stad",
                "claim_confirmation": "bevestiging van claim",
                "code": "code",
                "collection_name": "collectie naam",
                "communication_consent": "Gepersonaliseerde communicatie",
                "communication_consent_alt": "Communicatie?",
                "company_legal_representative_identity_piece": "identiteitsbewijs van de wettelijke vertegenwoordiger",
                "company_registration_document": "registratie document",
                "company_statutes": "statuten",
                "company_type": "Bedrijfstype",
                "completed_at": "voltooid op",
                "contact": "contact",
                "contact_email": "contact email",
                "contact_phone": "contact telefoon",
                "content": "inhoud",
                "contestant": "deelnemer",
                "contestant_company_type": "Bedrijfstype",
                "contestant_email": "e-mail",
                "contestant_first_name": "Voornaam",
                "contestant_last_name": "achternaam",
                "contestant_message": "bericht",
                "contestant_phone": "telefoon",
                "conversions_disk": "conversie schijf",
                "coords": "coördinaten",
                "countries": "landen",
                "countries_codes": "landen",
                "country": "land",
                "country_code": "Landcode",
                "created": "gemaakt",
                "created_at": "gemaakt bij",
                "creator": "Schepper",
                "credential": "referentie",
                "currency": "munteenheid",
                "current_password": "huidig ​​wachtwoord",
                "custom_amount": "aangepast bedrag",
                "custom_properties": "aangepaste eigenschappen",
                "customers_qty": "klanten aantal",
                "date": "datum",
                "dates": "data",
                "days": "dagen",
                "declined_at": "geweigerd bij",
                "deleted_at": "verwijderd bij",
                "deletion_confirmation": "bevestiging van verwijdering",
                "denial_reason": "ontkenning reden",
                "dependent_locality": "afhankelijke plaats",
                "description": "beschrijving",
                "details": "details",
                "disk": "schijf",
                "display": "weergave",
                "displayed_name": "weergegeven naam",
                "distance": "afstand",
                "documents": "documenten",
                "dt_created_by": "Gemaakt door",
                "dt_last_update": "Laatste DataTourisme-update",
                "dynamic_translations_enabled": "dynamische vertalingen ingeschakeld",
                "email": "e-mail",
                "emails_must_different": "Beide accounts kunnen niet hetzelfde e-mailadres hebben.",
                "employee": "medewerker",
                "end_date": "einddatum",
                "engaged_until": "bezig tot",
                "engagement_period": "verlovingsperiode",
                "event": "evenement",
                "excursion": "routebeschrijving",
                "existing_user": "bestaande gebruiker",
                "expirable": "verlopen",
                "expired_at": "verlopen om",
                "expired_on": "einddatum van het verblijf",
                "expiry": "vervaldatum",
                "expiry_date": "Vervaldatum",
                "extranet": "extranet",
                "fallback_locale": "terugvallocatie",
                "favorite": "favoriete route",
                "featured": "aanbevolen",
                "file": "bestand",
                "file_name": "bestandsnaam",
                "filter": "filter",
                "financial": "financieel",
                "first_name": "Voornaam",
                "formatted_address": "geformatteerd adres",
                "free_period": "vrije periode",
                "free_period_ended_at": "de vrije periode eindigde om",
                "front": "voorkant",
                "gallery": "galerij",
                "gdpr": "AVG",
                "general": "Algemeen",
                "generated_conversions": "gegenereerde conversies",
                "gross_amount": "brutobedrag",
                "has_employees": "heeft medewerkers",
                "hour": "uur",
                "hours": "uur",
                "id": "ID kaart",
                "image": "afbeelding",
                "images": "afbeeldingen",
                "import_source": "bron importeren",
                "import_synchronizing": "Activeer automatische update",
                "individual_first_identity_piece": "identiteit stuk",
                "individual_registration_document": "registratie document",
                "individual_second_identity_piece": "tweede identiteitsstuk",
                "information": "informatie",
                "institution": "instelling",
                "institution_address": "adres van de instelling",
                "institution_id": "instelling",
                "institution_title": "instelling titel",
                "interaction_type": "soort interactie",
                "invitation": "uitnodiging",
                "invitation_accepted": "uitnodiging geaccepteerd",
                "invitation_status": "uitnodigingsstatus",
                "invited_at": "uitgenodigd bij",
                "invited_by": "uitgenodigd door",
                "invitees": "genodigden",
                "is_geocoding": "geolocatie in uitvoering",
                "iso_code": "ISO-code",
                "items": "artikelen",
                "key": "sleutel",
                "kind": "vriendelijk",
                "label": "etiket",
                "last_geoloc": "laatste geolocatie",
                "last_logged_at": "laatst ingelogd",
                "last_login_ip": "laatste inlog-IP",
                "last_name": "achternaam",
                "last_validated_at": "laatste validatie",
                "lat": "breedtegraad",
                "latitude": "breedtegraad",
                "line1": "Adres regel 1",
                "line2": "Adres lijn 2",
                "link": "koppeling",
                "lng": "Lengtegraad",
                "locale": "taal",
                "login": "verbinding",
                "logo": "logo",
                "longitude": "Lengtegraad",
                "manipulations": "manipulaties",
                "max_amount_per_checkout": "maximaal bedrag per kassa",
                "measurement_system": "meet Systeem",
                "media": "media",
                "message": "bericht",
                "metadata": "metagegevens",
                "mime_type": "Mime type",
                "min_amount_per_checkout": "minimumbedrag per kassa",
                "misc": "gemengd",
                "model": "dossier",
                "model_type": "recordtype",
                "name": "naam",
                "net_amount": "netto bedrag",
                "new_password": "nieuw paswoord",
                "new_password_confirmation": "Bevestiging van nieuwe wachtwoord",
                "next_payment_at": "volgende betaling",
                "nickname": "bijnaam",
                "not_specified": "Niet gespecificeerd",
                "not_validated_activities": "niet gevalideerde activiteiten",
                "notes": "verdere informatie",
                "opening_hours": "Openingstijden",
                "order_column": "volgorde",
                "origin": "oorsprong",
                "owner": "eigenaar",
                "owner_email": "e-mail",
                "owner_first_name": "Voornaam",
                "owner_last_name": "achternaam",
                "owner_phone": "telefoon",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "passwords_must_different": "Beide accounts kunnen niet hetzelfde wachtwoord hebben.",
                "payable": "betaalbaar",
                "payee": "begunstigde",
                "payee_country": "land",
                "payee_email": "e-mail",
                "payee_id": "begunstigde",
                "payee_name": "naam van de begunstigde",
                "payee_phone": "telefoon",
                "payer": "betaler",
                "payer_country": "land",
                "payer_email": "e-mail",
                "payer_id": "betaler",
                "payer_name": "naam betaler",
                "payer_phone": "telefoon",
                "payment_received": "betaling ontvangen",
                "payment_service": "betalingsdienst",
                "payment_service_account_id": "Identiteitsbetaalrekening",
                "payment_service_transaction_id": "ID-betalingstransactie",
                "payment_thanks": "betaling bedankt",
                "payment_type": "betalingswijze",
                "period": "periode",
                "phone": "telefoon",
                "plan": "plan",
                "point_of_interest": "aandachtspunt",
                "point_of_interest_categories": "categorieën",
                "point_of_interest_category": "categorie",
                "point_of_interest_event_expired": "Evenement verlopen",
                "points_of_interest": "aandachtspunten",
                "points_of_interest_count": "interessante punten tellen mee",
                "post_category": "postcategorie",
                "price": "prijs",
                "price_excluding_taxes": "prijs exclusief belastingen",
                "prices": "prijzen",
                "priority": "prioriteit",
                "privacy_policy": "privacybeleid",
                "privacy_policy_accepted": "privacybeleid geaccepteerd",
                "privacy_policy_version": "versie van het privacybeleid",
                "private": "privaat",
                "private_title": "privé titel",
                "pro_file": "pro-bestand",
                "profile_picture": "Profielfoto",
                "properties": "eigenschappen",
                "properties_comparison": "bijgewerkte eigenschappen",
                "provider": "aanbieder",
                "public": "openbaar",
                "public_id": "openbare ID",
                "published_at": "gepubliceerd op",
                "quantity": "hoeveelheid",
                "quitted_at": "gestopt bij",
                "radius": "straal",
                "rates": "tarieven",
                "reason_details": "details",
                "reason_type": "reden",
                "recipient": "ontvanger",
                "recipients": "ontvangers",
                "recipients_alt": "geregistreerde reizigers",
                "remember": "Onthoud me",
                "removed_at": "verwijderd bij",
                "reports": "rapporten",
                "requested_at": "aangevraagd bij",
                "reservation_created": "reservering gemaakt",
                "reservation_date": "datum",
                "reservation_time": "tijd",
                "resolved_at": "Opgelost bij",
                "responsive_images": "responsieve afbeeldingen",
                "roles": "rollen",
                "room_number": "kamernummer",
                "scout_searchable": "indexeerbaar",
                "searchable": "doorzoekbaar",
                "sender": "afzender",
                "senders": "afzenders",
                "service": "dienst",
                "settings": "instellingen",
                "share_created": "aandeel gemaakt",
                "shareable": "deelbaar",
                "show_contact_email": "Zichtbaarheid van e-mailcontacten",
                "show_contact_phone": "Zichtbaarheid van contacttelefoon",
                "show_profile_picture": "De zichtbaarheid van Avatar",
                "show_real_name": "Zichtbaarheid van voor- en achternaam",
                "siret": "SIRET",
                "size": "maat",
                "source": "bron",
                "source_id": "bron-id",
                "specified": "Gespecificeerd",
                "starable": "genoteerd met sterren",
                "stars": "sterren",
                "start_date": "begin datum",
                "state": "staat",
                "states": "staten",
                "static_translations_enabled": "statische vertalingen ingeschakeld",
                "status": "toestand",
                "subject": "onderwerp",
                "subject_id": "onderwerp-id",
                "subject_type": "onderwerp type",
                "summary": "samenvatting",
                "support_category": "categorie",
                "support_messages_count": "ondersteuningsberichten tellen mee",
                "support_request": "ondersteuningsverzoek",
                "support_request_archived": "Ondersteuningsverzoek gearchiveerd",
                "support_request_message_received": "Antwoord ontvangen in een ondersteuningsverzoek",
                "suspended_at": "geschorst bij",
                "tag": "Label",
                "tags": "Labels",
                "target": "doel",
                "terms_condition_accepted": "algemene voorwaarden geaccepteerd",
                "terms_condition_version": "versie van de algemene voorwaarden",
                "terms_conditions": "voorwaarden",
                "thanked": "bedankt",
                "thanked_at": "bedankt datum",
                "thanks": "Bedankt",
                "thanks_message": "bedankt bericht",
                "thanks_sent": "bedankt verzonden",
                "tipping_fees": "fooikosten",
                "tips_enabled": "tips ingeschakeld",
                "title": "titel",
                "total_amount": "totaalbedrag",
                "traveller": "reiziger",
                "traveller_folder": "reizigers map",
                "traveller_folder_approved": "reizigersmap goedgekeurd",
                "traveller_folder_archived": "reizigersmap gearchiveerd",
                "traveller_folder_pending": "reizigersmap in behandeling",
                "traveller_folder_thanks": "bedankt voor het verblijf",
                "tutorial_state": "Tutorial staat",
                "type": "type",
                "updated_at": "bijgewerkt op",
                "user": "gebruiker",
                "user_id": "gebruiker",
                "user_report": "gebruiker (die rapporteert)",
                "users": "gebruikers",
                "utm_campaign": "Campagne",
                "utm_medium": "Medium",
                "utm_settings": "UTM-parameters",
                "utm_source": "Bron",
                "uuid": "uuid",
                "validated_activities": "gevalideerde activiteiten",
                "validated_at": "gevalideerd bij",
                "value": "waarde",
                "vat": "vat",
                "vat_rate": "BTW-tarief",
                "version": "versie",
                "video": "video",
                "videos": "videos",
                "website": "website",
                "zip": "ritssluiting"
            },
            "before": "De {attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "De {attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "De {attribute} moet tussen {min} en {max} items bevatten.",
                "file": "De {attribute} moet tussen {min} en {max} kilobytes liggen.",
                "numeric": "De {attribute} moet tussen {min} en {max} liggen.",
                "string": "De {attribute} moet tussen {min} en {max} tekens liggen."
            },
            "boolean": "Het {attribute}-veld moet waar of onwaar zijn.",
            "confirmed": "De {attribute} komt niet overeen.",
            "currency_scale": "Het aantal cijfers achter de komma van {attribute} mag niet groter zijn dan {scale}.",
            "current_password": "De {attribute} komt niet overeen met uw wachtwoord.",
            "custom": {
                "attribute-name": {
                    "rule-name": "aangepast bericht"
                },
                "h-captcha-response": {
                    "HCaptcha": "CAPTCHA foutmelding! probeer het later opnieuw of neem contact op met de sitebeheerder.",
                    "required": "Controleer of u geen robot bent."
                }
            },
            "date": "De {attribute} is geen geldige datum.",
            "date_equals": "De {attribute} moet een datum zijn die gelijk is aan {date}.",
            "date_format": "De {attribute} komt niet overeen met het formaat {format}.",
            "different": "De {attribute} en {other} moeten verschillend zijn.",
            "digits": "De {attribute} moet uit {digits} cijfers bestaan.",
            "digits_between": "De {attribute} moet tussen {min} en {max} cijfers liggen.",
            "dimensions": "De {attribute} heeft ongeldige afbeeldingsafmetingen.",
            "distinct": "Het {attribute}-veld heeft een dubbele waarde.",
            "email": "De {attribute} moet een geldig e-mailadres zijn.",
            "ends_with": "De {attribute} moet eindigen met een van de volgende: {values}.",
            "exists": "De geselecteerde {attribute} is ongeldig.",
            "file": "De {attribute} moet een bestand zijn.",
            "filled": "Het {attribute}-veld moet een waarde hebben.",
            "gt": {
                "array": "De {attribute} moet meer dan {value} items bevatten.",
                "file": "De {attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "De {attribute} moet groter zijn dan {value}.",
                "string": "De {attribute} moet groter zijn dan {value} tekens."
            },
            "gte": {
                "array": "De {attribute} moet {value} items of meer bevatten.",
                "file": "De {attribute} moet groter zijn dan of gelijk zijn aan {value} kilobytes.",
                "numeric": "De {attribute} moet groter zijn dan of gelijk zijn aan {value}.",
                "string": "De {attribute} moet groter zijn dan of gelijk zijn aan {value} tekens."
            },
            "image": "De {attribute} moet een afbeelding zijn.",
            "in": "De geselecteerde {attribute} is ongeldig.",
            "in_array": "Het {attribute}-veld bestaat niet in {other}.",
            "integer": "De {attribute} moet een geheel getal zijn.",
            "ip": "De {attribute} moet een geldig IP-adres zijn.",
            "ipv4": "De {attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "De {attribute} moet een geldig IPv6-adres zijn.",
            "json": "De {attribute} moet een geldige JSON-tekenreeks zijn.",
            "lt": {
                "array": "De {attribute} moet minder dan {value} items bevatten.",
                "file": "De {attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "De {attribute} moet kleiner zijn dan {value}.",
                "string": "De {attribute} moet kleiner zijn dan {value} tekens."
            },
            "lte": {
                "array": "De {attribute} mag niet meer dan {value} items bevatten.",
                "file": "De {attribute} moet kleiner zijn dan of gelijk zijn aan {value} kilobytes.",
                "numeric": "De {attribute} moet kleiner zijn dan of gelijk zijn aan {value}.",
                "string": "De {attribute} moet kleiner zijn dan of gelijk zijn aan {value} tekens."
            },
            "luhn": "{attribute} is een ongeldig getal.",
            "max": {
                "array": "De {attribute} mag niet meer dan {max} items bevatten.",
                "file": "De {attribute} mag niet groter zijn dan {max} kilobytes.",
                "height": "{attribute} mag niet groter zijn dan {max} pixels hoog.",
                "numeric": "De {attribute} mag niet groter zijn dan {max}.",
                "string": "De {attribute} mag niet groter zijn dan {max} tekens.",
                "width": "{attribute} mag niet groter zijn dan {max} pixels breed."
            },
            "max_digits": "{attribute} mag niet groter zijn dan {max} cijfers.",
            "mimes": "De {attribute} moet een geldig type hebben.",
            "mimetypes": "De {attribute} moet een geldig type hebben.",
            "min": {
                "array": "De {attribute} moet minimaal {min} items bevatten.",
                "file": "De {attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "De {attribute} moet minimaal {min} zijn.",
                "string": "De {attribute} moet minimaal {min} tekens bevatten."
            },
            "min_digits": "{attribute} moet minimaal {min} cijfers bevatten.",
            "not_in": "De geselecteerde {attribute} is ongeldig.",
            "not_regex": "Het {attribute}-formaat is ongeldig.",
            "notification_setting": "Het {attribute}-formaat is ongeldig (verwachte formaat is array[bool]).",
            "numeric": "De {attribute} moet een getal zijn.",
            "password": "Het wachtwoord is incorrect.",
            "phone": "De {attribute} moet een geldig telefoonnummer zijn.",
            "present": "Het {attribute}-veld moet aanwezig zijn.",
            "prohibited_if": "Het {attribute}-veld is verboden als {other} {value} is.",
            "prohibited_unless": "Het {attribute}-veld is verboden tenzij {other} in {values} staat.",
            "real_name_characters": ":{attribute} kan alleen letters, spaties, punten,\n        koppeltekens of apostrofs.",
            "regex": "Het {attribute}-formaat is ongeldig.",
            "required": "Het {attribute}-veld is verplicht.",
            "required_if": "Het {attribute}-veld is vereist als {other} {value} is.",
            "required_unless": "Het {attribute}-veld is vereist tenzij {other} in {values} staat.",
            "required_with": "Het {attribute}-veld is vereist als {values} aanwezig is.",
            "required_with_all": "Het veld {attribute} is vereist als {values} aanwezig is.",
            "required_without": "Het {attribute}-veld is vereist als {values} niet aanwezig is.",
            "required_without_all": "Het {attribute}-veld is vereist als geen van {values} aanwezig is.",
            "same": "De {attribute} en {other} moeten overeenkomen.",
            "same_currency": "Voor alle artikelen moet dezelfde valuta worden gebruikt.",
            "size": {
                "array": "De {attribute} moet {size} items bevatten.",
                "file": "De {attribute} moet {size} kilobytes zijn.",
                "numeric": "De {attribute} moet {size} zijn.",
                "string": "De {attribute} moet {size} tekens zijn."
            },
            "starts_with": "De {attribute} moet beginnen met een van de volgende: {values}.",
            "string": "De {attribute} moet een string zijn.",
            "tag_same_poi_category": "{attribute} zou allemaal tot dezelfde categorie moeten behoren!",
            "timezone": "De {attribute} moet een geldige zone zijn.",
            "unique": "De {attribute} is al bezet.",
            "uploaded": "De {attribute} kon niet worden geüpload.",
            "url": "Het {attribute}-formaat is ongeldig.",
            "uuid": "De {attribute} moet een geldige UUID zijn."
        }
    },
    "pt": {
        "The {attribute} must be at least {length} characters and contain at least one number.": "O {attribute} deve ter pelo menos {length} caracteres e conter pelo menos um número.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "O {attribute} deve ter pelo menos {length} caracteres e conter pelo menos um caractere especial e um número.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "O {attribute} deve ter pelo menos {length} caracteres e conter pelo menos um caractere especial.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "O {attribute} deve ter pelo menos {length} caracteres e conter pelo menos um caracter maiúsculo e um número.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "O {attribute} deve ter pelo menos {length} caracteres e conter pelo menos um caractere maiúsculo e um caractere especial.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "O {attribute} deve ter pelo menos {length} caracteres e conter pelo menos um caractere maiúsculo, um número e um caractere especial.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "O {attribute} deve ter pelo menos {length} caracteres e conter pelo menos um caractere maiúsculo.",
        "The {attribute} must be at least {length} characters.": "O {attribute} deve ter pelo menos {length} caracteres.",
        "The {attribute} must contain at least one letter.": "O {attribute} deve conter pelo menos uma letra.",
        "The {attribute} must contain at least one number.": "O {attribute} deve conter pelo menos um número.",
        "The {attribute} must contain at least one symbol.": "O {attribute} deve conter pelo menos um símbolo.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "O {attribute} deve conter pelo menos uma letra maiúscula e uma letra minúscula.",
        "auth": {
            "failed": "Essas credenciais não correspondem aos nossos registros. Tem certeza de que não usou este e-mail para uma conta {type}?",
            "throttle": "Muitas tentativas de login. Tente novamente em {seconds} segundos.",
            "throttle_cascade": "Muitas tentativas de login. Por favor, tente novamente em {remaining}."
        },
        "documents": {
            "display": {
                "description": "Display em formato A6 com link QRCode e logo da sua instituição.",
                "title": "Visor - cartão A6"
            },
            "qrcode": {
                "description": "Somente QRCode permitindo que o viajante esteja diretamente vinculado à sua instituição.",
                "filename": "Código QR",
                "title": "Vincular QRCode"
            }
        },
        "enums": {
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "Criada",
                "deleted": "Excluído",
                "updated": "Atualizada",
                "validated": "Validado"
            },
            "App\\Enums\\BinaryChoiceEnum": [
                "Negado",
                "Aceitaram"
            ],
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "association": "Associação (ou fundação)",
                "company": "Empresa (pública/privada)",
                "individual": "Individual",
                "undefined": "Indefinido"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "DE": "Alemanha",
                "ES": "Espanha",
                "FR": "França",
                "IT": "Itália",
                "TH": "Tailândia",
                "undefined": "Indefinido"
            },
            "App\\Enums\\ContextsEnum": {
                "city_hall": "Prefeitura",
                "hotel": "Hotel",
                "manager": "Gerente",
                "tourist_office": "Escritório de Turismo",
                "traveller": "Viajante"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "Euro",
                "usd": "Dólar"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Friday": "Sexta-feira",
                "Monday": "Segunda-feira",
                "Saturday": "Sábado",
                "Sunday": "Domingo",
                "Thursday": "Quinta-feira",
                "Tuesday": "Terça-feira",
                "Wednesday": "Quarta-feira"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "Personalizado",
                "root": "Meus modelos",
                "ugozer_app": "Aplicativo UgoZER",
                "ugozer_pro": "UgoZER Pro"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "Imperial",
                "metric": "Métrica"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "No aplicativo",
                "email": "E-mail"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "customer": "Cliente",
                "partner": "Parceiro"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "easytransac": "EasyTransac",
                "paypal": "PayPal",
                "stripe": "Listra"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "subscription": "Inscrição",
                "tip": "Dica"
            },
            "App\\Enums\\PrivacySettingsEnum": {
                "contacts": "Meus contatos",
                "everyone": "Todos",
                "nobody": "Ninguém"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "illegal": "Ilegal",
                "incorrect_data": "Informação incorreta",
                "other": "Outro",
                "shocking": "Chocante",
                "spam": "Spam"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "Café da manhã",
                "dinner": "Jantar",
                "lunch": "Almoço",
                "null": "N / D"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "Facebook",
                "google": "Google",
                "instagram": "Instagram",
                "linkedin": "LinkedIn",
                "tiktok": "TikTok",
                "twitter": "X (Twitter)",
                "wechat": "WeChat"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "1 mês",
                "one year": "1 ano"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "1 mês",
                "one year": "1 ano",
                "six months": "6 meses",
                "three months": "3 meses"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "expired": "A assinatura terminou",
                "free period ended": "O período gratuito terminou",
                "payment default": "Padrão de pagamento"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "Aplicativo",
                "extranet": "Extranet",
                "front": "Frente"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "Arquivado",
                "canceled": "Cancelado",
                "pending_support": "Suporte pendente",
                "pending_user": "Usuário pendente",
                "resolved": "Resolvido"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "Ao meu redor",
                "customized": "Personalizado",
                "staying_hotel": "Perto do hotel"
            },
            "App\\Enums\\UserRoleEnum": {
                "butler": "Mordomo",
                "contributor": "Contribuinte",
                "hotelier": "Hoteleiro",
                "manager": "Gerente",
                "moderator": "Moderador",
                "superadmin": "Superadministrador",
                "touristOffice": "Escritório de Turismo",
                "touristOfficeEmployee": "PARA Funcionário",
                "traveller": "Viajante"
            }
        },
        "nova": {
            "lens": {
                "user": {
                    "user_origin": "Origem dos usuários"
                }
            },
            "menu": {
                "administration": "Administração",
                "content": "Contente",
                "excursions": "Itinerários",
                "import_poi": "Importar PI",
                "miscellaneous": "Diversos",
                "parameters": "Parâmetros",
                "pois": "Pontos de interesse",
                "subscriptions": "Assinaturas",
                "support": "Apoiar",
                "users": "Usuários"
            },
            "s3_private_download": {
                "link": {
                    "expiration": "O link irá expirar após 30 minutos, se for o caso, basta atualizar a página.",
                    "open": "Veja o documento"
                },
                "no_document": "Nenhum documento relacionado",
                "not_found": "O documento não foi encontrado na unidade S3..."
            },
            "user_menu": {
                "profile": "Meu perfil"
            }
        },
        "pagination": {
            "go_page": "Vá para a página {page}",
            "next": "Próximo \"",
            "next_alt": "Próximo",
            "of": "de",
            "pagination_navigation": "Navegação de paginação",
            "previous": "\" Anterior",
            "previous_alt": "Anterior",
            "results": "resultados",
            "showing": "Mostrando",
            "to": "para"
        },
        "passwords": {
            "reset": "Sua senha foi alterada!",
            "sent": "Enviamos por e-mail seu link de redefinição de senha!",
            "throttled": "Aguarde antes de tentar novamente.",
            "token": "Este token de redefinição de senha é inválido.",
            "user": "Nenhum usuário correspondente."
        },
        "ttmt": {
            "account_deletion": {
                "confirmation": "Na página que se abre, confirme a exclusão digitando a palavra <strong>DELETE</strong>\n        e clique em <strong>Confirmar</strong>.",
                "content": {
                    "asking": "Em caso de solicitação de exclusão de conta, a UgoZER excluirá a conta e os dados do usuário,\n            a menos que devam ser retidos devido a requisitos legais ou regulamentares, para fins de segurança, fraude\n            prevenção ou devido a um problema relacionado à conta do usuário, como uma reclamação não paga, não resolvida ou\n            disputa.",
                    "conservation": {
                        "link": "Política de Privacidade.",
                        "part_1": "UgoZER retém os dados do usuário pelo tempo necessário para os fins descritos no",
                        "part_2": "De acordo com as recomendações da CNIL, uma conta não utilizada durante 3 anos será eliminada.",
                        "part_3": "Os usuários podem solicitar a exclusão de suas contas a qualquer momento. UgoZER pode reter dados do usuário após um\n                solicitação de exclusão de acordo com requisitos legais ou regulamentares, ou pelos motivos indicados neste\n                declaração."
                    },
                    "instructions": "Se você deseja excluir sua conta UgoZER, siga as instruções abaixo."
                },
                "logout": "Você será desconectado e a conta e os dados associados serão excluídos imediatamente.",
                "pro": {
                    "instructions": {
                        "delete": "Em seguida, clique no botão <strong>Excluir</strong> na frente de <strong>Exclusão de conta\n                </strong>,",
                        "profile": {
                            "link": "perfil",
                            "part_1": "Vá para sua",
                            "path": "(clicando no seu avatar > Meu perfil),"
                        }
                    },
                    "subtitle": "Para excluir sua conta profissional:",
                    "title": "Conta profissional"
                },
                "title": "Exclusão de conta",
                "traveller": {
                    "instructions": {
                        "delete": "Em seguida, clique no botão <strong>Excluir</strong> antes de <strong>Excluir minha conta?\n                </strong>",
                        "profile": {
                            "link": "perfil",
                            "part_1": "Vá para sua",
                            "path": "(menu do ícone de hambúrguer > Configurações > Perfil),"
                        }
                    },
                    "subtitle": "Para excluir sua conta de viajante:",
                    "title": "Conta de viajante"
                }
            },
            "activity_logs": {
                "summary": {
                    "created": {
                        "intro": "O assunto foi criado com estas propriedades:"
                    },
                    "deleted": {
                        "intro": "O assunto foi excluído com estas propriedades:"
                    },
                    "updated": {
                        "attribute_from_to": "{attribute} foi atualizado de \"{old_value}\" para \"{new_value}\"",
                        "intro": "O assunto foi atualizado:"
                    },
                    "validated": {
                        "intro": "O assunto foi validado:"
                    }
                }
            },
            "auth": {
                "credential_verification": {
                    "intro": "Insira sua credencial, um link de confirmação será enviado para você.",
                    "intro2": "Sua credencial será atualizada assim que você acessar o link."
                },
                "expiration": {
                    "session_expired": "Sua sessão expirou. Autentique novamente"
                },
                "extranet_register": {
                    "form": {
                        "choose_establishment": "Escolha o seu tipo de estabelecimento",
                        "form_title": "crie sua conta",
                        "validate_registration": "Finalizar meu cadastro"
                    },
                    "success": "Cadastrado com sucesso, um link de verificação foi enviado para seu e-mail"
                },
                "login": {
                    "already_logged": "Você já está logado",
                    "logging_in": "Fazendo login...",
                    "success": "Logado",
                    "use_email": "Faça login com um e-mail",
                    "use_social_networks": "Use redes sociais"
                },
                "logout": {
                    "logging_out": "Sair...",
                    "switch": "Troque para"
                },
                "messages": {
                    "continue": "Continue com",
                    "extranet_here": "Clique aqui",
                    "have_account": "já tem uma conta?",
                    "login": "Conecte-se",
                    "login_subtitle": "(cadastro ou login)",
                    "login_title": "É melhor com redes sociais",
                    "no_account": "Não tem uma conta ainda?",
                    "or": "OU",
                    "professional": "Você é um profissional?",
                    "register": "Registro",
                    "register_mail": "Cadastre-se com um e-mail",
                    "register_title": "Registre-se para desbloquear todos os recursos",
                    "reset_password": "Redefinir senha",
                    "send_magic_link": "Enviar link sem senha"
                },
                "password_forgotten": {
                    "intro": "Insira sua credencial e enviaremos um link para redefinir sua senha.",
                    "submit": "Receba um link de redefinição",
                    "success": {
                        "correct_credential": "Credencial correta",
                        "email_1": "Um e-mail será enviado para você em {email}, caso este endereço esteja bem cadastrado.",
                        "email_2": "Este e-mail contém um link que permitirá que você redefina sua senha.",
                        "send_again": "Envie novamente",
                        "title": "Link de redefinição de senha enviado",
                        "wait_before": "Aguarde {seconds} segundos antes de enviar novamente"
                    }
                },
                "password_reset": {
                    "submit": "Redefinir sua senha",
                    "success": "A senha foi redefinida"
                },
                "register": {
                    "form": {
                        "hide_privacy_policy": "Ocultar política de privacidade",
                        "hide_terms_conditions": "Ocultar termos e condições",
                        "privacy_policy": "Eu concordo com a Política de Privacidade",
                        "show_privacy_policy": "Mostrar política de privacidade",
                        "show_terms_conditions": "Mostrar termos e condições",
                        "terms_conditions": "Eu concordo com os Termos e Condições"
                    },
                    "staying_hotel": "Uma vez registrado, você será atendido pelo hotel cujo\n            você acabou de digitalizar o código QR.",
                    "success": {
                        "email_1": "Um e-mail será enviado para {email}.",
                        "email_2": "Este e-mail contém um link que permitirá que você confirme seu endereço e se conecte.",
                        "paired": {
                            "email_1": "Dois e-mails foram enviados para os seguintes endereços",
                            "email_2": "Cada e-mail contém um link para confirmar o endereço e conectar a conta associada."
                        },
                        "title": "Registrado com sucesso"
                    }
                },
                "social_networks": {
                    "authenticate": "Faça login com",
                    "facebook": "Facebook",
                    "google": "Google",
                    "no_email": "Nenhum email está associado à sua rede social, você não pode autenticar com ela",
                    "twitter": "Twitter"
                },
                "travel_assistant": "Assistente de viagem gratuito"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "Conta bancária não fornecida"
                    },
                    "form": {
                        "placeholders": {
                            "bank_city": "Cidade da sua agência bancária.",
                            "bank_name": "nome do banco",
                            "bank_transfer_reason": "Exemplo: \"Dicas UgoZER\"",
                            "bic": "Insira seu BIC",
                            "iban": "Digite seu IBAN/BBAN",
                            "payee_name": "Nome do beneficiário ou nome da instituição",
                            "types": "IBAN/BBAN"
                        }
                    },
                    "lines": {
                        "no_account_yet": "Sua conta bancária permitirá que sua instituição receba pagamentos no UgoZER."
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "amount": "quantia",
                    "amount_to_pay": "valor a pagar",
                    "concierge": "porteiro",
                    "currency": "moeda",
                    "customer": "cliente",
                    "date": "data",
                    "repartition_key": "chave de distribuição",
                    "tip_sharing_form": "formulário de compartilhamento de dicas",
                    "tips_received": "dicas recebidas",
                    "total_received": "total recebido"
                }
            },
            "breadcrumbs": {
                "changelogs": {
                    "changelogs": "Registros de alterações"
                },
                "claims": {
                    "claims": "Reivindicações"
                },
                "common": {
                    "add": "Adicionar",
                    "claim": "Alegar",
                    "create": "Novo",
                    "create_f": "Novo",
                    "delete": "Excluir",
                    "download_pdf": "baixar PDF",
                    "edit": "Editar",
                    "report": "Relatório",
                    "share": "Compartilhar",
                    "show": "Mostrar"
                },
                "contacts": {
                    "contacts": "Contatos"
                },
                "cookie": {
                    "cookie": "Gestão de cookies"
                },
                "credit": {
                    "credit": "Créditos"
                },
                "dashboard": {
                    "dashboard": "Painel"
                },
                "errors": {
                    "404": {
                        "label": "Erro 404",
                        "title": "Erro 404 não encontrado"
                    }
                },
                "excursions": {
                    "create": "Novo itinerário",
                    "excursions": "Itinerários"
                },
                "faq": {
                    "faq": "Perguntas frequentes"
                },
                "finances": {
                    "finances": "Finanças"
                },
                "institutions": {
                    "institution": "Instituição",
                    "institutions": "Instituições"
                },
                "institutions_employees": {
                    "institutions_employees": "Meus funcionários",
                    "invite": "Convide um funcionário"
                },
                "interactions": {
                    "interactions": "Interações"
                },
                "invitations": {
                    "invitations": "Convites"
                },
                "notification_settings": {
                    "notification_settings": "Configurações de notificações"
                },
                "notifications": {
                    "notifications": "Notificações"
                },
                "partners": {
                    "customers": "Clientes",
                    "partners": "Parceiros"
                },
                "points_of_interest": {
                    "add_excursion": "Adicionar a um itinerário",
                    "points_of_interest": "Pontos de interesse"
                },
                "privacy_policy": {
                    "privacy_policy": "Política de Privacidade"
                },
                "privacy_settings": {
                    "privacy_settings": "Configurações de privacidade"
                },
                "reservations": {
                    "reservations": "Reservas"
                },
                "search": {
                    "search": "Procurar"
                },
                "subscriptions": {
                    "subscription": "Inscrição"
                },
                "support_messages": {
                    "add_message": "Adiciona uma mensagem",
                    "support_messages": "Mensagens"
                },
                "support_requests": {
                    "add_request": "Adicione uma nova solicitação de suporte",
                    "support_requests": "Solicitações de suporte"
                },
                "terms_condition": {
                    "terms_condition": "Termos e Condições"
                },
                "travellers": {
                    "folders": "Pastas de viajantes",
                    "travellers": "Viajantes"
                },
                "tutorials": {
                    "begin": "Começando"
                },
                "users": {
                    "edit_credential": "Editar credencial",
                    "profile": "Meu perfil",
                    "users": "Usuários"
                }
            },
            "changelogs": {
                "archive": {
                    "success": "O(s) changelog(s) foi(ão) arquivado(s) (se elegível)"
                },
                "draft": {
                    "success": "O(s) changelog(s) foi(m) elaborado(s) (se elegível)"
                },
                "index": {
                    "sections": {
                        "all": "Todas as atualizações",
                        "last": "Última atualização"
                    }
                },
                "publish": {
                    "success": "O(s) changelog(s) foi(ão) publicado(s) (se elegível)"
                },
                "show": {
                    "version": "Versão"
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "A disputa de reivindicação foi criada"
                },
                "rejected": {
                    "success": "A disputa de reivindicação foi rejeitada"
                },
                "validated": {
                    "success": "A disputa de reivindicação foi validada"
                }
            },
            "claims": {
                "form_wizard": {
                    "documents": {
                        "DE": {
                            "association_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (novo\n                    Versão com MRZ-Zeilen) ou Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "association_statutes": "Handelsregisterauszug",
                            "company_legal_representative_identity_piece": "Reisepass, Personalausweis, Führerschein (novo\n                    Versão com MRZ-Zeilen) ou Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "company_registration_document": "Handelsregisterauszug",
                            "company_statutes": "Atual e unterschriebene (von der Geschäftsführung) Gesellschaftverträge",
                            "individual_first_identity_piece": "Reisepass, Personalausweis, Führerschein (nova versão com\n                    MRZ-Zeilen) ou Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_registration_document": "Gewerbeschein, Handlungsbevollmächtigung, Handelsregisterauszug,\n                    Prokura, carta de atribuição de Steuernummer",
                            "individual_second_identity_piece": "Reisepass, Personalausweis, Führerschein (nova versão com\n                    MRZ-Zeilen) ou Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)"
                        },
                        "ES": {
                            "association_legal_representative_identity_piece": "Pasaporte, Documento nacional de identidade,\n                    Permissão de condução (versão recente com linhas MRZ) ou Permissão de residência (somente se for de um\n                    país europeu)",
                            "association_statutes": "Escrituras de Constituição ou Estatutos Sociais",
                            "company_legal_representative_identity_piece": "Pasaporte, Documento nacional de identidade, Permiso\n                    de condução (versão recente com linhas MRZ) ou permissão de residência (somente se for de um país\n                    europeu)",
                            "company_registration_document": "Extrato do Registro Mercantil Central, Nota Simples do Registro\n                    mercantil local vendido o numérico, Extracto desde los Registradores de España (informação\n                    General Mercantil) com data de validade de menos de 3 meses ou Modelo 600 com data de validade\n                    de menos de 3 meses (solo para sociedade civil)",
                            "company_statutes": "Escrituras de Constituição ou Estatutos Sociais",
                            "individual_first_identity_piece": "Pasaporte, Documento Nacional de Identidade, Permissão de Condução\n                    (versão recente com linhas MRZ) ou permissão de residência (somente se for de um país europeu)",
                            "individual_registration_document": "Agência Tributária - Certificado de situação Censal\n                    (Alta Censal)",
                            "individual_second_identity_piece": "Pasaporte, Documento Nacional de Identidade, Permissão de Condução\n                    (versão recente com linhas MRZ) ou permissão de residência (somente se for de um país europeu)"
                        },
                        "FR": {
                            "association_legal_representative_identity_piece": "Uma peça de identidade no curso de validade do\n                    representante legal da associação: cartão de identidade (frente e verso), passaporte, título de\n                    estadia (frente e verso), permis de conduire biométrique (frente e verso)",
                            "association_statutes": "Os estatutos da associação",
                            "company_legal_representative_identity_piece": "Uma peça de identidade no curso de validade do\n                    representante legal da sociedade: carta de identidade (frente e verso), passaporte, título de estadia\n                    (frente e verso), permis de conduire biométrique (frente e verso)",
                            "company_registration_document": "Uma extração do KBIS",
                            "company_statutes": "Os estatutos",
                            "individual_first_identity_piece": "Uma primeira peça de identidade no curso de validade: cartão\n                    d'identité (frente e verso), passeport, titre de séjour (frente e verso), permis de conduire\n                    biométrique (frente e verso)",
                            "individual_registration_document": "Um certificado de inscrição no INSEE ou um documento\n                    atestante de sua inscrição na Câmara de Comércio (para um autoempreendedor em exercício\n                    uma atividade comercial) ou acima da Chambre des métiers (para um autoempreendedor em exercício\n                    uma atividade artesanal)",
                            "individual_second_identity_piece": "Uma segunda peça de identidade no curso de validade: cartão\n                    d'identité (frente e verso), passeport, titre de séjour (frente e verso), permis de conduire\n                    (frente e verso), dernier avis d'imposition data de menos de um ano, livret de famille ou\n                    registro de registro do pacto civil de solidariedade"
                        },
                        "IT": {
                            "association_legal_representative_identity_piece": "Passaporto, Carta de identidade nacional, La\n                    patente de guia (versão recente com linha MRZ) ou permissão de residência (só se for um paese\n                    europeu)",
                            "association_statutes": "tatuto firmado pelo Legale Rappresentante (se não incluído no interior do\n                    Visura Camerale",
                            "company_legal_representative_identity_piece": "Passaporto, Carta de identidade nacional, La patente\n                    di guida (versão recente com linha MRZ) ou Permesso di residenza (somente se for um país europeu)",
                            "company_registration_document": "Visura Camerale",
                            "company_statutes": "Estatuto firmado pelo Legale Rappresentante (se não incluído no interior do\n                    Visura Camerale)",
                            "individual_first_identity_piece": "Passaporto, Carta de identidade nacional, Patente de guia\n                    (versão recente com linha MRZ) ou Permesso di residenza (só se for um país europeu)",
                            "individual_registration_document": "Estratto do registro delle imprese (\"Visura Camerale\") di meno\n                    em 3 meses",
                            "individual_second_identity_piece": "Passaporto, Carta de identidade nacional, Patente de guia\n                    (versão recente com linha MRZ) ou Permesso di residenza (só se for um país europeu)"
                        },
                        "TH": {
                            "association_legal_representative_identity_piece": "Mais informações O que fazer:\n                    Mais ัง),\n                    Isso é algo que você pode fazer mais),\n                    Mais informações sim)",
                            "association_statutes": "Mais",
                            "company_legal_representative_identity_piece": "Mais informações O que fazer:\n                    บัตรประจำตัวประชาชน (ด้านหน้าและด้านหลัง), หน Então,\n                    ใบอนุญาตมีถิ่นที่อยู่ (ด้านหน้าและด้านหลัง),\n                    Mais",
                            "company_registration_document": "Baixar KBIS",
                            "company_statutes": "Adeus",
                            "individual_first_identity_piece": "Mais informações: วประชาชน\n                    (ทั้งด้านหน้าและด้านหลัง), หนังสือเดินทาง, ใบ O que é isso? ),\n                    Mais informações sim)",
                            "individual_registration_document": "Mais informações Mais informações\n                    (Além disso, mais)\n                    Mais mais)\n                    mais)",
                            "individual_second_identity_piece": "Mais informações ัวประชาชน\n                    (ด้านหน้าและด้านหลัง) Mais\n                    (ด้านหน้าและด้านหลัง) mais)\n                    Mais informações Mais informações Mais\n                    Mais informações"
                        }
                    },
                    "steps": {
                        "claim": "Alegar",
                        "context": "Contexto",
                        "create": "Criar",
                        "documents": "Documentos",
                        "info": "Informação",
                        "search": "Procurar"
                    }
                },
                "missing_info": {
                    "success": "A reivindicação foi rejeitada por falta de informações"
                },
                "notice": {
                    "actions": {
                        "edit": "Modificar minha solicitação"
                    },
                    "already_claimed": {
                        "description": "Este ponto de interesse já foi reivindicado. Se você acha que isso é um erro, você pode\n                preencha o formulário de disputa abaixo.",
                        "title": "Ponto de interesse já reivindicado!"
                    },
                    "find_or_new": {
                        "description": "Procure um ponto de interesse na lista abaixo ou crie um novo.",
                        "title": "Pesquise ou crie um POI"
                    },
                    "missing-info": {
                        "infos": "Por favor, complete sua solicitação clicando no botão abaixo.",
                        "state": "Sua reivindicação está atualmente aguardando informações ou documentação adicional:",
                        "title": "Aguardando informações adicionais"
                    },
                    "pending": {
                        "state": "Sua solicitação de reivindicação está sendo processada no momento",
                        "title": "Solicitação de reivindicação pendente"
                    },
                    "rejected": {
                        "state": "Sua solicitação de reivindicação foi rejeitada pelo seguinte motivo:",
                        "title": "Solicitação de reivindicação rejeitada"
                    }
                },
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "Alegar",
                            "poi": "Ponto de interesse",
                            "user": "Do utilizador"
                        }
                    }
                },
                "rejected": {
                    "success": "A reivindicação foi rejeitada"
                },
                "revoked": {
                    "success": "A reivindicação foi revogada"
                },
                "states": {
                    "missing-info": "Faltando informação",
                    "pending": "Pendente",
                    "rejected": "Rejeitado",
                    "revoked": "Revogado",
                    "validated": "Validado"
                },
                "update": {
                    "success": "A reivindicação foi atualizada"
                },
                "valid_identity_pieces": "carteira de identidade, passaporte ou carteira de motorista válida",
                "validated": {
                    "success": "A reivindicação foi validada"
                }
            },
            "common": {
                "actions": {
                    "accept": "Aceitar",
                    "accept_contact": "Aceitar solicitação de contato",
                    "add": "Adicionar",
                    "add_contact": "Adicionar aos contatos",
                    "add_excursion": "Adicionar ao itinerário",
                    "add_message": "Adiciona uma mensagem",
                    "apply": "Aplicar",
                    "approve": "Aprovar",
                    "archive": "Arquivo",
                    "attach": "Anexar",
                    "block_contact": "Contato bloqueado",
                    "cancel": "Cancelar",
                    "check": "Verificar",
                    "claim": "Alegar",
                    "close": "Fechar",
                    "configure": "Configurar",
                    "confirm": "confirme",
                    "contest": "Concurso",
                    "create": "Criar",
                    "decline": "Negar",
                    "decline_contact": "Recusar solicitação de contato",
                    "delete": "Excluir",
                    "download": "Download",
                    "edit": "Editar",
                    "file_preview": "Visualizar arquivo",
                    "filters": "Filtros",
                    "give_a_tip": "Dê uma dica",
                    "google_maps": "Abrir no Google Maps",
                    "help": "Ajuda",
                    "hide": "Esconder",
                    "ignore": "Ignorar",
                    "interactions": "Mostrar interações",
                    "invite": "Convidar",
                    "load_more": "Carregue mais",
                    "login": "Conecte-se",
                    "logout": "Sair",
                    "manage": "Gerenciar",
                    "next": "Próximo",
                    "pause": "Pausa",
                    "pay": "Pagar",
                    "play": "Jogar",
                    "print": "Imprimir",
                    "quit": "Desistir",
                    "record": "Registro",
                    "register": "Registro",
                    "report": "Relatório",
                    "request_to_join": "Solicitar adesão",
                    "reset": "Reiniciar",
                    "resume": "Retomar",
                    "retry": "Tentar novamente",
                    "return": "Voltar",
                    "save": "Salvar",
                    "save_alt": "Salvar",
                    "search": "Procurar",
                    "see_plans": "Ver planos",
                    "see_website": "Ver site",
                    "send": "Enviar",
                    "send_link": "Enviar link do aplicativo",
                    "share": "Compartilhar",
                    "show": "Mostrar",
                    "start": "Começar",
                    "stop": "Parar",
                    "subscribe": "Se inscrever",
                    "thank": "Agradecer",
                    "thank_for_stay": "Obrigado pela estadia",
                    "traveller_show": "Consulte o viajante",
                    "unblock_contact": "Desbloquear contato",
                    "unblock_this_user": "Desbloquear este usuário",
                    "update": "Atualizar",
                    "validate": "Validar"
                },
                "all": "todos",
                "and": "e",
                "app_name": "UgoZER",
                "back_to_dashboard": "Voltar ao painel",
                "back_to_home": "De volta para casa",
                "characters_limit": "caracteres {length} / {limit}",
                "counter": "{dividend} em {divisor}",
                "country": "país",
                "dashboard": "Painel",
                "errors": {
                    "deleted_user": "Usuário deletado",
                    "internal": "Algo deu errado!",
                    "not_found": "Não encontrado"
                },
                "false": "não",
                "fast": "rápido",
                "home": "Lar",
                "later": "mais tarde",
                "loading": "carregando",
                "messages": {
                    "application_update": {
                        "if_ignore": "Se você ignorar a atualização, ela será feita na próxima vez que você iniciar o aplicativo.",
                        "new_version_available": "Uma nova versão está disponível.",
                        "updating": "Atualizando...",
                        "want_to_refresh": "Deseja reiniciar o aplicativo para atualizá-lo?"
                    },
                    "clipboard": {
                        "error": "Erro ao copiar para a área de transferência...",
                        "success": "Copiado para a área de transferência!"
                    },
                    "no_records": "Nenhum registro foi encontrado"
                },
                "missing_title": "Título ausente",
                "more_options": "mais opções",
                "no": "não",
                "none": "nenhum",
                "normal": "normal",
                "not_specified": "Não especificado",
                "online": {
                    "connected": "Você está conectado à Internet novamente",
                    "disconnected": "Você não está mais conectado à Internet"
                },
                "oops": "opa",
                "or": "ou",
                "qr_code": "Código QR",
                "search": "Procurar",
                "slow": "lento",
                "someone": "alguém",
                "sort": "organizar",
                "tip": "Dica",
                "tip_received": "Dica recebida (se habilitada)",
                "true": "sim",
                "units": {
                    "kilometer": "quilômetro | quilômetros",
                    "km": "quilômetros",
                    "mi": "mi",
                    "mile": "milha | milhas"
                },
                "welcome": "Bem-vindo",
                "yes": "sim"
            },
            "cookies": {
                "details": {
                    "content": "Aqui está a lista de cookies colocados nesta seção do aplicativo UgoZER com base no\n            categorias definidas acima:",
                    "table": {
                        "headers": {
                            "category": "Categoria de uso",
                            "description": "Descrição",
                            "lifespan": "Vida",
                            "mandatory": "Obrigatório",
                            "name": "Nome do cookie (ou padrão)"
                        },
                        "lines": {
                            "alphanumeric": {
                                "content": "Contém informações sobre a sessão.",
                                "lifespan": "2 horas",
                                "title": "[[{alnum}:]] (sequência de caracteres alfanuméricos)"
                            },
                            "gmaps": {
                                "content": "Usado para mostrar o iframe do Google Maps.",
                                "lifespan": "180-400 dias",
                                "title": "CONSENTIMENTO, AEC, SOCS... (ou outros cookies de domínio google.com)"
                            },
                            "remember": {
                                "content": "Usado para manter a autenticação do usuário.",
                                "lifespan": "400 dias",
                                "title": "lembre-se_web_*"
                            },
                            "session": {
                                "content": "Contém informações sobre a sessão.",
                                "lifespan": "2 horas",
                                "title": "*_ugozer_session"
                            },
                            "tarteaucitron": {
                                "content": "Permite manter as configurações de cookies do usuário.",
                                "lifespan": "365 dias",
                                "title": "tarteaucitron"
                            },
                            "xsrf": {
                                "content": "Permite proteger a aplicação (pedidos, formulários...).",
                                "lifespan": "2 horas",
                                "title": "XSRF-TOKEN-*"
                            }
                        }
                    },
                    "title": "Detalhes dos cookies depositados"
                },
                "management": {
                    "content": {
                        "cta": "Alterar minhas preferências",
                        "line_1": "Algumas funcionalidades deste site dependem da utilização de cookies.",
                        "line_2": "O banner de cookies não aparece na página inicial durante a sua navegação se for necessário\n                para o funcionamento do site são depositados.",
                        "line_3": "Você pode alterar suas preferências clicando no botão abaixo:"
                    },
                    "title": "Gestão de cookies"
                },
                "notice": {
                    "needed": "Para ver esta seção, você deve aceitar cookies {name}.",
                    "refresh": "Se você não o vir após aceitar os cookies, atualize a página.",
                    "title": "Biscoitos"
                },
                "services": {
                    "gmaps": "Google Mapas",
                    "youtube": "YouTube"
                },
                "usage": {
                    "content": "Os cookies podem ser usados ​​para reconhecê-lo quando você visita o UgoZER, lembrar suas preferências e fornecer\n            você uma experiência personalizada que está de acordo com suas configurações. Os cookies também tornam suas interações com\n            UgoZER mais rápido e seguro. Além disso, os cookies nos permitem trazer publicidade dentro e fora do site.\n            Sites UgoZER.",
                    "table": {
                        "headers": {
                            "category": "Categoria de uso",
                            "description": "Descrição"
                        },
                        "lines": {
                            "ads": {
                                "content": "Podemos usar cookies para mostrar publicidade relevante dentro e fora do site UgoZER.\n                        Também podemos usar um cookie para saber se alguém que viu um anúncio posteriormente visitou e fez uma\n                        ação (por exemplo, baixou um white paper ou fez uma compra) no site do anunciante.\n                        Da mesma forma, nossos parceiros podem usar um cookie para determinar se exibimos um anúncio e como ele\n                        realizadas ou nos fornecer informações sobre como você interage com os anúncios. Também podemos trabalhar\n                        com um parceiro para mostrar a você um anúncio dentro ou fora do UgoZER, como depois de visitar o site de um parceiro\n                        site ou aplicativo.",
                                "title": "Anúncio"
                            },
                            "authentication": {
                                "content": "Se você estiver conectado ao UgoZER, os cookies nos ajudam a mostrar as informações corretas e\n                        personalize sua experiência.",
                                "title": "Autenticação"
                            },
                            "security": {
                                "content": "Usamos cookies para ativar e oferecer suporte aos nossos recursos de segurança e para nos ajudar a detectar\n                        atividades maliciosas e violações de nossos",
                                "cta": "Termos e Condições.",
                                "title": "Segurança"
                            },
                            "services": {
                                "content": "Os cookies fornecem recursos, insights e conteúdo personalizado.",
                                "title": "Preferências, recursos e serviços"
                            }
                        }
                    },
                    "title": "Para que servem os cookies?"
                }
            },
            "countries": {
                "AD": "Andorra",
                "AE": "Emirados Árabes Unidos",
                "AF": "Afeganistão",
                "AG": "Antígua e Barbuda",
                "AI": "Anguila",
                "AL": "Albânia",
                "AM": "Armênia",
                "AO": "Angola",
                "AQ": "Antártica",
                "AR": "Argentina",
                "AS": "Samoa Americana",
                "AT": "Áustria",
                "AU": "Austrália",
                "AW": "Aruba",
                "AX": "Ilhas Aland",
                "AZ": "Azerbaijão",
                "BA": "Bósnia e Herzegovina",
                "BB": "Barbados",
                "BD": "Bangladesh",
                "BE": "Bélgica",
                "BF": "Burkina Faso",
                "BG": "Bulgária",
                "BH": "Bahrein",
                "BI": "Burundi",
                "BJ": "Benim",
                "BL": "São Bartolomeu",
                "BM": "Bermudas",
                "BN": "Brunei",
                "BO": "Bolívia",
                "BQ": "Holanda caribenha",
                "BR": "Brasil",
                "BS": "Bahamas",
                "BT": "Butão",
                "BV": "Ilha Bouvet",
                "BW": "Botsuana",
                "BY": "Bielorrússia",
                "BZ": "Belize",
                "CA": "Canadá",
                "CC": "Ilhas Cocos (Keeling)",
                "CD": "Congo - Kinshasa",
                "CF": "República Centro-Africana",
                "CG": "Congo - Brazavile",
                "CH": "Suíça",
                "CI": "Costa do Marfim",
                "CK": "Ilhas Cook",
                "CL": "Chile",
                "CM": "Camarões",
                "CN": "China",
                "CO": "Colômbia",
                "CR": "Costa Rica",
                "CU": "Cuba",
                "CV": "cabo Verde",
                "CW": "Curaçau",
                "CX": "Ilha do Natal",
                "CY": "Chipre",
                "CZ": "Tcheca",
                "DE": "Alemanha",
                "DJ": "Djibuti",
                "DK": "Dinamarca",
                "DM": "Domínica",
                "DO": "República Dominicana",
                "DZ": "Argélia",
                "EC": "Equador",
                "EE": "Estônia",
                "EG": "Egito",
                "EH": "Saara Ocidental",
                "ER": "Eritreia",
                "ES": "Espanha",
                "ET": "Etiópia",
                "FI": "Finlândia",
                "FJ": "Fiji",
                "FK": "Ilhas Malvinas",
                "FM": "Micronésia",
                "FO": "ilhas Faroe",
                "FR": "França",
                "GA": "Gabão",
                "GB": "Reino Unido",
                "GD": "Granada",
                "GE": "Geórgia",
                "GF": "Guiana Francesa",
                "GG": "Guernsey",
                "GH": "Gana",
                "GI": "Gibraltar",
                "GL": "Groenlândia",
                "GM": "Gâmbia",
                "GN": "Guiné",
                "GP": "Guadalupe",
                "GQ": "Guiné Equatorial",
                "GR": "Grécia",
                "GS": "Ilhas Geórgia do Sul e Sandwich do Sul",
                "GT": "Guatemala",
                "GU": "Guam",
                "GW": "Guiné-Bissau",
                "GY": "Guiana",
                "HK": "Hong Kong, RAE da China",
                "HM": "Ilhas Ouvidas e McDonald",
                "HN": "Honduras",
                "HR": "Croácia",
                "HT": "Haiti",
                "HU": "Hungria",
                "ID": "Indonésia",
                "IE": "Irlanda",
                "IL": "Israel",
                "IM": "Ilha de Man",
                "IN": "Índia",
                "IO": "Território Britânico do Oceano Índico",
                "IQ": "Iraque",
                "IR": "Irã",
                "IS": "Islândia",
                "IT": "Itália",
                "JE": "Jersey",
                "JM": "Jamaica",
                "JO": "Jordânia",
                "JP": "Japão",
                "KE": "Quênia",
                "KG": "Quirguistão",
                "KH": "Camboja",
                "KI": "Quiribáti",
                "KM": "Comores",
                "KN": "São Cristóvão e Nevis",
                "KP": "Coréia do Norte",
                "KR": "Coreia do Sul",
                "KW": "Kuwait",
                "KY": "Ilhas Cayman",
                "KZ": "Cazaquistão",
                "LA": "Laos",
                "LB": "Líbano",
                "LC": "Santa Lúcia",
                "LI": "Liechtenstein",
                "LK": "Sri Lanka",
                "LR": "Libéria",
                "LS": "Lesoto",
                "LT": "Lituânia",
                "LU": "Luxemburgo",
                "LV": "Letônia",
                "LY": "Líbia",
                "MA": "Marrocos",
                "MC": "Mônaco",
                "MD": "Moldávia",
                "ME": "Montenegro",
                "MF": "São Martinho",
                "MG": "Madagáscar",
                "MH": "Ilhas Marshall",
                "MK": "Macedônia do Norte",
                "ML": "Mali",
                "MM": "Mianmar (Birmânia)",
                "MN": "Mongólia",
                "MO": "Macau, RAE da China",
                "MP": "Ilhas Marianas do Norte",
                "MQ": "Martinica",
                "MR": "Mauritânia",
                "MS": "Montserrate",
                "MT": "Malta",
                "MU": "Maurício",
                "MV": "Maldivas",
                "MW": "Maláui",
                "MX": "México",
                "MY": "Malásia",
                "MZ": "Moçambique",
                "NA": "Namíbia",
                "NC": "Nova Caledônia",
                "NE": "Níger",
                "NF": "Ilha Norfolk",
                "NG": "Nigéria",
                "NI": "Nicarágua",
                "NL": "Holanda",
                "NO": "Noruega",
                "NP": "Nepal",
                "NR": "Nauru",
                "NU": "Niue",
                "NZ": "Nova Zelândia",
                "OM": "Omã",
                "PA": "Panamá",
                "PE": "Peru",
                "PF": "Polinésia Francesa",
                "PG": "Papua Nova Guiné",
                "PH": "Filipinas",
                "PK": "Paquistão",
                "PL": "Polônia",
                "PM": "São Pedro e Miquelon",
                "PN": "Ilhas Pitcairn",
                "PR": "Porto Rico",
                "PS": "Territórios Palestinos",
                "PT": "Portugal",
                "PW": "Palau",
                "PY": "Paraguai",
                "QA": "Catar",
                "RE": "Reunião",
                "RO": "Romênia",
                "RS": "Sérvia",
                "RU": "Rússia",
                "RW": "Ruanda",
                "SA": "Arábia Saudita",
                "SB": "Ilhas Salomão",
                "SC": "Seicheles",
                "SD": "Sudão",
                "SE": "Suécia",
                "SG": "Cingapura",
                "SH": "Santa Helena",
                "SI": "Eslovênia",
                "SJ": "Svalbard e Jan Mayen",
                "SK": "Eslováquia",
                "SL": "Serra Leoa",
                "SM": "São Marino",
                "SN": "Senegal",
                "SO": "Somália",
                "SR": "Suriname",
                "SS": "Sudão do Sul",
                "ST": "São Tomé e Príncipe",
                "SV": "El Salvador",
                "SX": "São Martinho",
                "SY": "Síria",
                "SZ": "Essuatíni",
                "TC": "Ilhas Turcas e Caicos",
                "TD": "Chade",
                "TF": "Territórios Franceses do Sul",
                "TG": "Ir",
                "TH": "Tailândia",
                "TJ": "Tadjiquistão",
                "TK": "Toquelau",
                "TL": "Timor-Leste",
                "TM": "Turcomenistão",
                "TN": "Tunísia",
                "TO": "Tonga",
                "TR": "Peru",
                "TT": "Trinidad e Tobago",
                "TV": "Tuvalu",
                "TW": "Taiwan",
                "TZ": "Tanzânia",
                "UA": "Ucrânia",
                "UG": "Uganda",
                "UM": "Ilhas Distantes dos EUA",
                "US": "Estados Unidos",
                "UY": "Uruguai",
                "UZ": "Uzbequistão",
                "VA": "Cidade do Vaticano",
                "VC": "São Vicente e Granadinas",
                "VE": "Venezuela",
                "VG": "Ilhas Virgens Britânicas",
                "VI": "Ilhas Virgens dos EUA",
                "VN": "Vietnã",
                "VU": "Vanuatu",
                "WF": "Wallis e Futuna",
                "WS": "Samoa",
                "XK": "Kosovo",
                "YE": "Iémen",
                "YT": "Maiote",
                "ZA": "África do Sul",
                "ZM": "Zâmbia",
                "ZW": "Zimbábue"
            },
            "credits": {
                "images": "Créditos das fotos"
            },
            "csv": {
                "common": {
                    "generating": "Gerando arquivo CSV...",
                    "waiting": "Por favor, aguarde..."
                },
                "download": "exportação CSV"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "Favoritos do seu especialista local"
                },
                "links": {
                    "closest_pois": "Pontos mais próximos",
                    "excursions": "Itinerários e caminhadas",
                    "shares": "Ações"
                },
                "no_dashboard": {
                    "contact": {
                        "link": "Contate-nos.",
                        "text": "Se você acha que isso é um erro, não hesite em"
                    },
                    "content": "Nenhum painel foi configurado para sua função ou você está conectado como administrador.",
                    "title": "Nenhum painel disponível"
                },
                "points_of_interest": {
                    "categories": "Categorias de pontos de interesse"
                }
            },
            "documents": {
                "display": {
                    "description": "Display em formato A6 com link QRCode e logo da sua instituição.",
                    "title": "Visor - cartão A6"
                },
                "qrcode": {
                    "description": "Somente QRCode permitindo que o viajante esteja diretamente vinculado à sua instituição.",
                    "filename": "Código QR",
                    "title": "Vincular QRCode"
                }
            },
            "easy_transac": {
                "account": {
                    "create": {
                        "button": "Crie uma conta EasyTransac",
                        "fail": "A conexão com EasyTransac falhou, tente novamente mais tarde",
                        "success": "Sua conta EasyTransac foi criada com sucesso"
                    },
                    "show": {
                        "account_usage": "Sua conta EasyTransac permitirá que sua instituição receba pagamentos no UgoZER.",
                        "can_receive_payments": "Seus clientes agora podem dar gorjeta ao seu estabelecimento\n                do perfil UgoZER de seus concierges.",
                        "can_retrieve_payments": {
                            "description": "Você deve preencher sua conta EasyTransac com as informações solicitadas para\n                    para usar os fundos disponíveis em sua conta.",
                            "title": "Sua conta EasyTransac está incompleta"
                        },
                        "cannot_receive_payments": "Você não pode receber pagamentos",
                        "credentials_sent_mail": "Suas credenciais EasyTransac foram enviadas a você por e-mail.",
                        "fee_percentage": "Uma taxa será aplicada a cada pagamento que você receber.",
                        "go_to_easy_transac": "Acesse o painel EasyTransac",
                        "must_subscribe": "Você deve estar inscrito para criar sua conta EasyTransac.",
                        "no_account_yet": "Você ainda não tem uma conta EasyTransac.",
                        "should_complete_kyc": "Por favor envie os documentos solicitados pela EasyTransac,\n                para receber pagamentos."
                    }
                },
                "easy_transac": "EasyTransac"
            },
            "exceptions": {
                "default": "Um erro ocorreu",
                "error_texts": {
                    "403": {
                        "link": {
                            "title": "Você pode querer acessar",
                            "to_app_label": "seção de viajantes?",
                            "to_extranet_label": "seção profissional?"
                        },
                        "message": "Você não tem acesso a esta página.",
                        "title": "Ops! Acesso proibido."
                    },
                    "credential_not_verified": {
                        "title": "Sua credencial não foi verificada",
                        "verify_mail": "Receber link de verificação para meu e-mail",
                        "verify_phone": "Receber link de verificação para meu número de telefone"
                    },
                    "opening_hours": {
                        "invalid": "O valor fornecido não é uma instância de OpeningHours."
                    },
                    "password_missing": {
                        "app": "Por favor, defina uma senha.",
                        "extranet": "Por favor, defina uma senha para acessar o aplicativo."
                    }
                },
                "spam_detected": "Spam detectado"
            },
            "excursions": {
                "destroy": {
                    "confirm": "Você realmente deseja excluir este itinerário: {excursion_name}?",
                    "success": "O itinerário foi excluído"
                },
                "direction": {
                    "bicycling": "ciclismo",
                    "display_mode": "Modo de exibição",
                    "driving": "dirigindo",
                    "walking": "andando"
                },
                "form": {
                    "all_points_of_interest": "Todos os pontos de interesse",
                    "create_new_point_of_interest": "Crie um novo ponto de interesse",
                    "existing_excursions": "Itinerários existentes",
                    "from_existing_excursions": "De um itinerário existente",
                    "order_hint": "A ordem dos pontos de interesse corresponde à ordem da visita.",
                    "points_of_interest": "Pontos de interesse",
                    "select_from_points_of_interest": "Selecione entre pontos de interesse",
                    "your_excursion": "Seu itinerário"
                },
                "index": {
                    "alert": {
                        "content": "Você só pode visualizar rotas. Se você deseja criar ou compartilhar um itinerário, você deve entrar com\n                uma <strong>conta de concierge/recepcionista</strong>",
                        "title": "Itinerários somente sob consulta"
                    },
                    "no_results": "Você ainda não tem nenhum itinerário",
                    "no_results_here": "Não há itinerários disponíveis na sua área de pesquisa.",
                    "read_only": "Somente consulta",
                    "search": "Pesquisar um itinerário",
                    "try_more_filters": "Você pode ampliar sua pesquisa ou criar um itinerário.",
                    "your_excursions": "Seus itinerários"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "Itinerário",
                            "pois": "Pontos de interesse"
                        }
                    }
                },
                "search": {
                    "no_results": "Nenhum itinerário encontrado"
                },
                "share": {
                    "contains_private": "Este itinerário contém pontos de interesse privados. Eles não podem ser incluídos na ação.",
                    "heading": "Compartilhe este itinerário",
                    "select_which_contacts": "Selecione os contatos com os quais deseja compartilhar o itinerário \"{excursion_title}\"",
                    "select_which_users": "Selecione os usuários com quem deseja compartilhar o itinerário \"{excursion_title}\" ou digite\n            um endereço de e-mail para convidar um novo viajante",
                    "select_which_users_alt": "Digite o endereço de e-mail da pessoa com quem você deseja compartilhar o\n            Itinerário \"{excursion_title}\".",
                    "success": "O itinerário foi compartilhado!"
                },
                "store": {
                    "create": "Crie um itinerário",
                    "heading": "Crie um itinerário",
                    "success": "O itinerário foi criado"
                },
                "update": {
                    "heading": "Atualizar este itinerário",
                    "success": "O itinerário foi atualizado"
                }
            },
            "extranet": {
                "dashboard": {
                    "last_claims": {
                        "none": "Você ainda não tem nenhuma reivindicação.",
                        "see_all": "Veja todas as suas reivindicações",
                        "see_index": "Vá para a página de reivindicações"
                    },
                    "last_payments": {
                        "none": "Você ainda não tem nenhum pagamento.",
                        "see_all": "Veja todos os seus pagamentos",
                        "see_index": "Vá para a página de pagamento"
                    },
                    "last_reservations": {
                        "none": "Você ainda não tem nenhuma reserva."
                    },
                    "last_traveller_folders": {
                        "none": "Você ainda não tem nenhum viajante sob seus cuidados.",
                        "see_all": "Veja todos os seus viajantes",
                        "take_care": "Cuide de um viajante"
                    },
                    "manager": {
                        "sections": {
                            "butlers_institutions": "Concierges das minhas instituições",
                            "butlers_interactions": "Concierges com interações"
                        }
                    },
                    "sections": {
                        "last_claims": "Suas últimas reivindicações",
                        "last_payments": "Seus últimos pagamentos",
                        "last_reservations": "Suas últimas reservas",
                        "last_travellers": "Seus últimos viajantes",
                        "search_butler": "Procure um concierge",
                        "stats": "Estatísticas"
                    },
                    "stats": {
                        "butlers": "Concierge|Concierges",
                        "employees": "Funcionário|Funcionários",
                        "excursions": "Itinerário|Itinerários",
                        "folders": "Pasta|Pastas",
                        "institutions": "Instituição vinculada|Instituições vinculadas",
                        "processing_claims": "Processando reclamação|Processando reclamações",
                        "reservations": "Reserva|Reservas"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "Convide um funcionário",
                        "new-hotelier": "Crie uma conta de recepção",
                        "new-tourist-office": "Crie uma conta bancária"
                    }
                },
                "excursions": {
                    "actions": {
                        "new": "Crie um itinerário"
                    },
                    "sections": {
                        "excursions": "Itinerários"
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "Categorias"
                    }
                },
                "filters": {
                    "closed": "Fechar filtros",
                    "open": "Abrir filtros"
                },
                "finances": {
                    "alerts": {
                        "easytransac": {
                            "cta": "Acesse meu perfil para criar uma conta EasyTransac",
                            "description": "Você ainda não criou uma conta EasyTransac! Portanto, você não pode receber pagamentos.",
                            "title": "Conta EasyTransac inexistente!"
                        }
                    },
                    "sections": {
                        "issued_payments": "Pagamentos emitidos",
                        "received_by_institution_payments": "Pagamentos recebidos por suas instituições",
                        "received_payments": "Pagamentos recebidos"
                    }
                },
                "institution": {
                    "messages": {
                        "no_records": "Nenhuma instituição vinculada no momento"
                    },
                    "sections": {
                        "butler": {
                            "institution": "Minha instituição"
                        },
                        "manager": {
                            "institution": "Minhas instituições"
                        }
                    }
                },
                "navbar": {
                    "contact": "Contate-nos",
                    "contacts": "Contatos",
                    "dashboard": "Painel",
                    "excursions": "Itinerários",
                    "faq": "Perguntas frequentes",
                    "finances": "Finanças",
                    "invitations": "Convites",
                    "my_employees": "Meus funcionários",
                    "my_institution": "Minha instituição",
                    "my_institutions": "Minhas instituições",
                    "points_of_interest": "Pontos de interesse",
                    "travellers": "Viajantes",
                    "tutorials": "Começar",
                    "users": "Usuários"
                },
                "pages": {
                    "excursions": {
                        "edit": "Editar itinerário",
                        "new": "Novo itinerário"
                    },
                    "folders": {
                        "edit": "Editar pasta do viajante",
                        "new": "Nova pasta de viajante"
                    },
                    "institutions": {
                        "new": "Vincular uma instituição"
                    },
                    "pois": {
                        "edit": "Editar ponto de interesse",
                        "new": "Novo ponto de interesse"
                    },
                    "profile": {
                        "edit": "Editar Perfil"
                    },
                    "reservations": {
                        "edit": "Editar reserva",
                        "new": "Nova reserva"
                    }
                },
                "points_of_interest": {
                    "actions": {
                        "new": "Crie um ponto de interesse"
                    },
                    "sections": {
                        "points_of_interest": "Pontos de interesse"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "Nenhuma pergunta frequente no momento"
                    }
                },
                "professional_section": "Seção profissional",
                "profile": {
                    "sections": {
                        "currency": "Moeda",
                        "delete_account": "Exclusão de conta",
                        "infos": "informações gerais",
                        "locale": "Linguagem",
                        "notification_settings": "Configurações de notificações",
                        "payment": "Pagamento",
                        "privacy_settings": "Configurações de privacidade",
                        "shares": "Ações"
                    }
                },
                "support_messages": {
                    "actions": {
                        "back": "Voltar para a solicitação de suporte",
                        "new": "Adicione uma nova mensagem"
                    },
                    "sections": {
                        "support_messages": "Mensagens"
                    }
                },
                "support_requests": {
                    "actions": {
                        "back": "Voltar",
                        "new": "Crie uma solicitação de suporte"
                    },
                    "sections": {
                        "support_requests": "Solicitações de suporte"
                    }
                },
                "travellers": {
                    "buttons": {
                        "new_folder": "Adicionar nova pasta de viajante"
                    },
                    "sections": {
                        "folders": "Minhas pastas de viajantes"
                    }
                },
                "user_dropdown": {
                    "changelogs": "Registros de alterações",
                    "cookies": "Gestão de cookies",
                    "credits": "Créditos",
                    "customers": "Clientes",
                    "partners": "Parceiros",
                    "privacy_policy": "Política de Privacidade",
                    "profile": "Meu perfil",
                    "sign_out": "sair",
                    "subscription": "Inscrição",
                    "terms_condition": "Termos e Condições"
                }
            },
            "form": {
                "file": {
                    "current_file": "Arquivo real: {name}"
                },
                "hints": {
                    "auth": {
                        "register": {
                            "email": "Este endereço de e-mail ainda não deve estar vinculado a uma conta UgoZER",
                            "employee": {
                                "not_duplicate_email": "O endereço de e-mail deve ser diferente daquele do",
                                "not_duplicate_password": "A senha deve ser diferente daquela do"
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "Personalizado: itinerário criado pelo concierge\n                        para um viajante específico",
                                "root": "Meus modelos: itinerário \"pronto para compartilhar\" composto por pontos de interesse imperdíveis,\n                        ou pontos de interesse por tema\n                        (exemplo: lista de restaurantes frequentemente incluídos nos itinerários)\n                        a ser selecionado para criar um novo itinerário.",
                                "ugozer_app": "App UgoZER: itinerário criado e proposto por UgoZER\n                        e disponibilizado aos viajantes.",
                                "ugozer_pro": "UgoZER Pro: itinerário criado e proposto por UgoZER\n                        e disponibilizados aos profissionais para facilitar seu trabalho."
                            },
                            "pro": {
                                "customized": "Itinerário criado para um viajante específico",
                                "root": "itinerário \"pronto para compartilhar\" composto por pontos de interesse imperdíveis,\n                        ou pontos de interesse por tema\n                        (exemplo: lista de restaurantes frequentemente incluídos nos itinerários)\n                        a ser selecionado para criar um novo itinerário.",
                                "ugozer": "Itinerário criado e proposto por UgoZER."
                            }
                        },
                        "points_of_interest_search": "Pesquise e selecione um ponto de interesse para adicioná-lo à lista"
                    },
                    "excursions_filters": {
                        "radius": "Você verá apenas itinerários com pelo menos um ponto de interesse localizado neste raio."
                    },
                    "geoloc_setting": {
                        "filter_header": "Pesquise um país, região, cidade ou bairro",
                        "kind": {
                            "admin": {
                                "around_me": "Ao meu redor: a geolocalização será determinada\n                        pelo navegador do usuário.",
                                "customized": "Personalizado: o usuário poderá pesquisar um local manualmente.",
                                "staying_hotel": "Hospedagem em hotel: a geolocalização será feita\n                        do hotel onde o viajante está hospedado."
                            },
                            "app": {
                                "around_me": "A geolocalização será feita a partir da sua posição.",
                                "customized": "A geolocalização será feita a partir de um local de sua escolha.",
                                "staying_hotel": "A geolocalização será feita a partir do hotel onde você estiver hospedado: {title}."
                            }
                        }
                    },
                    "locale": {
                        "dynamic_translations_enabled": "Permite que os usuários exibam ou traduzam recursos\n                (pontos de interesse, itinerários...) nesse idioma",
                        "fallback_locale": "Exibe automaticamente o título em um segundo idioma (antes da descrição) em\n                o arquivo POI.",
                        "static_translations_enabled": "Permite que os usuários escolham este idioma para exibir o aplicativo\n                (requer que as traduções tenham sido adicionadas ao código-fonte)"
                    },
                    "permission": {
                        "name": "Nome usado no código-fonte"
                    },
                    "plan": {
                        "countries": "Deixe em branco para disponibilizar o plano em todos os países",
                        "information": "Informações adicionais sobre o plano (pré-requisitos, condições...)",
                        "name": "Este nome ficará visível para assinantes em potencial\n                quando eles escolhem sua assinatura",
                        "private_title": "Este título só é visível na administração"
                    },
                    "plan_price": {
                        "description": "Esta descrição ficará visível para assinantes em potencial\n                quando eles escolhem sua assinatura",
                        "price": "Por favor insira em euros",
                        "private_title": "Este título só é visível na administração"
                    },
                    "point_of_interest": {
                        "audio_refreshing_1": "Habilitar a atualização do arquivo de áudio irá gerar automaticamente um arquivo de áudio\n                no idioma do ponto de interesse ({idioma}).",
                        "audio_refreshing_2": "Ele indicará o título e o endereço do ponto de interesse.",
                        "audio_refreshing_3": "Ele será atualizado assim que o idioma, o título\n                ou o endereço do ponto de interesse são atualizados.",
                        "audio_refreshing_4": "A atualização será desativada se você mesmo adicionar ou gravar um arquivo de áudio,\n                ou se você excluir o arquivo de áudio gerado.",
                        "cant_have_employees": "Aqui você vê apenas estabelecimentos associados a categorias que possam ter\n                funcionários",
                        "expiry_date": "Seu ponto de interesse expirará em {date}",
                        "expiry_date_details_1": "Seu ponto de interesse faz parte da categoria \"{category}\".",
                        "expiry_date_details_2": "Portanto, ele expirará em {date}, pois esta é a última data que você inseriu.",
                        "expiry_date_details_3": "A partir desta data, o seu ponto de interesse terá 21 dias restantes\n                antes de ser excluído automaticamente.",
                        "expiry_date_details_4": "Você sempre pode adicionar uma nova data\n                para adiar a expiração ou não especificar nenhuma data para que seu ponto de interesse não expire.",
                        "images": "As imagens serão exibidas na galeria do ponto de interesse na ordem escolhida,\n                você pode reorganizá-los arrastando-os.",
                        "is_geocoding": "Desative se a geolocalização for inserida corretamente, mas o mapa ainda não aparecer.",
                        "line1": "Você pode inserir o endereço ou o nome do local que procura. Escolha entre\n                lista para preencher o endereço automaticamente",
                        "locale": "O principal idioma falado neste ponto de interesse",
                        "opening_dates": "Deixe em branco se as programações se aplicarem a qualquer data",
                        "opening_days": "Não selecione nada se os horários se aplicarem a qualquer dia",
                        "opening_hours": "Não adicione nada se o ponto de interesse estiver aberto a qualquer momento",
                        "sync_1": "Ativar atualização automática permite recuperar dados do {import_source}\n                importar",
                        "sync_2": "As entradas manuais serão substituídas"
                    },
                    "points_of_interest_filters": {
                        "order_distance": "Escolha um filtro de distância para selecionar esta opção",
                        "order_relevance": "Insira palavras-chave de pesquisa para selecionar esta opção",
                        "radius": "Você verá apenas pontos de interesse localizados dentro deste raio."
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "butler": "Não importa qual opção você escolher, seu sobrenome e seu nome\n                    permanecerá visível para o gerente da sua instituição",
                            "traveller": "Não importa qual opção você escolher, seu sobrenome e seu nome\n                  permanecerá visível pelos concierges do hotel onde você está hospedado."
                        }
                    },
                    "profile": {
                        "contact_email": "Este e-mail pode ser exibido em seu perfil público",
                        "contact_phone": "Este telefone pode ser exibido em seu perfil público",
                        "name": "O apelido é público e permite que outros usuários do aplicativo identifiquem você com mais facilidade.",
                        "password_template": {
                            "digit": "1 dígito",
                            "length": "caracteres {length}",
                            "lowercase": "1 letra minúscula",
                            "uppercase": "1 letra maiúscula"
                        },
                        "password_unchanged": "Deixe em branco para permanecer inalterado"
                    },
                    "role": {
                        "description": "A descrição da função é exibida aos usuários quando eles escolhem a função quando\n                registre-se na seção profissional.",
                        "name": "Nome de código da função, ele não será exibido aos usuários."
                    },
                    "support_request": {
                        "support_categories_search": "Pesquise e selecione uma categoria"
                    },
                    "translatable": {
                        "pro_global": "Um ou mais campos deste formulário podem ser preenchidos em diferentes idiomas.",
                        "pro_global_2": "Os campos não preenchidos em determinados idiomas serão traduzidos automaticamente.",
                        "pro_global_title": "Entrada multilíngue"
                    },
                    "traveller_folder": {
                        "expired_on": "Por padrão, a data de término é definida em uma semana"
                    },
                    "user": {
                        "bank_account_number": "Digite o número da conta que será usada para fazer a transferência"
                    }
                },
                "labels": {
                    "geoloc_setting": {
                        "distance_filter": "filtro de distância",
                        "limit_to_a_radius": "limite a um raio",
                        "limit_to_a_radius_from_center": "limite a um raio do centro",
                        "measure_unit": "unidade de medida",
                        "other_filters": "outros filtros",
                        "where_does_the_traveller_go": "para onde vai o viajante?",
                        "where_would_you_like_to_go": "onde você gostaria de ir?",
                        "within_a_radius": "dentro de um raio"
                    },
                    "payment": {
                        "payable_type": "Pagamento vinculado a"
                    },
                    "plan": {
                        "authorized_roles": "funções autorizadas",
                        "tipping_fees": "Taxas de gorjeta (porcentagem)"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "preço sem impostos"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "Atualize automaticamente o arquivo de áudio",
                        "opening": "abertura",
                        "opening_date": "sobre",
                        "opening_date_from": "de",
                        "opening_date_range": "especifique uma data de término",
                        "opening_date_to": "para",
                        "opening_hours": "no",
                        "opening_hours_from": "de",
                        "opening_hours_range": "especifique uma hora de término",
                        "opening_hours_to": "para",
                        "opening_schedule_add": "especifique dias ou horas",
                        "opening_schedule_add_alt": "especificar horas",
                        "opening_specification_add": "adicionar um período"
                    },
                    "points_of_interest_filters": {
                        "geoloc": "lugar",
                        "order_distance": "por distância",
                        "order_relevance": "por relevância (palavras-chave de pesquisa)",
                        "sort": "organizar",
                        "tags": "refinar"
                    },
                    "register": {
                        "communication_consent": "Gostaria de receber comunicações personalizadas, incluindo ofertas,\n                informações promocionais e produtos relacionados a viagens da UgoZER por e-mail.",
                        "privacy_policy": {
                            "part1": "Estou de acordo com",
                            "part2": "política de Privacidade"
                        },
                        "terms_conditions": {
                            "part1": "Estou de acordo com",
                            "part2": "termos e Condições"
                        }
                    },
                    "support_category": {
                        "title": "Escolha uma categoria"
                    },
                    "support_message": {
                        "content": "Mensagem",
                        "email": "Endereço de email"
                    },
                    "support_request": {
                        "title": "Assunto do pedido"
                    },
                    "traveller_invitation": {
                        "locale": "idioma falado por este viajante"
                    },
                    "user": {
                        "credential": "e-mail",
                        "email": "e-mail",
                        "image": {
                            "_destroy": "Excluir foto do perfil"
                        },
                        "new_password": "Nova Senha",
                        "notes": "Outras informações"
                    },
                    "vat_rate": {
                        "vat_rate": "Taxa de IVA (percentagem)"
                    }
                },
                "placeholders": {
                    "audio_file_name": "nome do arquivo de áudio",
                    "name": "apelido",
                    "support_message": {
                        "create_message": "Sua mensagem para a solicitação de suporte"
                    }
                },
                "select": {
                    "no_options": "Sem opções",
                    "no_results": "Desculpe, não há opções de correspondência.",
                    "type_to_search": "Procurar..."
                },
                "titles": {
                    "verifications": "Verificando suas informações"
                }
            },
            "front": {
                "access_pro": "Acesse o acesso profissional",
                "app": "Aplicativo para viajantes",
                "brand_name": "UgoZER",
                "captcha": {
                    "and": "e",
                    "apply": "aplicar.",
                    "privacy_policy": "política de Privacidade",
                    "protected": "Este site é protegido pelo hCaptcha e seus",
                    "terms_service": "Termos de serviço"
                },
                "contact": {
                    "cancel": "Cancelar a solicitação de suporte",
                    "contact": "Contato",
                    "send": "Enviar"
                },
                "cookies": {
                    "title": "Gestão de cookies"
                },
                "customers": {
                    "see_customers": "Veja nossos clientes",
                    "title": "Eles confiam em nós"
                },
                "footer": {
                    "account_deletion": "Exclusão de conta",
                    "app": "Aplicativo para viajantes",
                    "contact": "Contato",
                    "cookies": "Gestão de cookies",
                    "copyright": "© {year} UgoZER - Todos os direitos reservados.",
                    "facebook": "Facebook",
                    "faq": "Perguntas frequentes",
                    "home": "Lar",
                    "instagram": "Instagram",
                    "legal_notice": "Notícia legal",
                    "linkedin": "Linkedin",
                    "privacy_policy": "Política de Privacidade",
                    "pro": "Acesso profissional",
                    "sitemap": "Mapa do site",
                    "terms_conditions": "Termos e Condições",
                    "twitter": "Twitter"
                },
                "header": {
                    "app": "Aplicativo para viajantes",
                    "contact": "Contato",
                    "faq": "Perguntas frequentes",
                    "home": "Lar",
                    "pro": "Acesso profissional",
                    "you_are": "Você é"
                },
                "intro": {
                    "intro_1": "Com o UgoZER, tenha sempre em seu smartphone seus endereços favoritos em arquivos multilíngues,\n            audível ou legível, com um clique.",
                    "intro_2": "Quando estiver no exterior, facilite aos motoristas de táxi e outras pessoas a compreensão do seu destino.",
                    "intro_3": "Recepcionista, gerente de hotel, elabora e oferece programas turísticos. Compartilhe-os com seus clientes\n            em UgoZER.",
                    "intro_title_pro": "PROFISSIONAIS",
                    "intro_title_traveller": "VIAJANTE",
                    "title_homepage": "O guia turístico multilíngue, gratuito."
                },
                "navigation": {
                    "next": "Próximo",
                    "previous": "Anterior"
                },
                "outro": {
                    "description": "Viaje em 2.0"
                },
                "partners": {
                    "no_records": "Nenhum parceiro no momento",
                    "read_more": "Consulte Mais informação",
                    "see_partners": "Veja nossos parceiros",
                    "title": "Parceiros"
                },
                "posts": {
                    "no_records": "Nenhuma postagem no momento",
                    "title": "PERGUNTAS FREQUENTES."
                },
                "pro": "Acesso profissional",
                "professionals": {
                    "argument_1": {
                        "part_1": "UgoZER oferece uma solução digital para seus convidados.",
                        "part_2": "Melhore a estadia e a experiência dos hóspedes.",
                        "part_3": "Ele permite que seu hotel, resort ou centro de informações crie informações personalizadas, fáceis de usar e\n                itinerários móveis multilíngues.",
                        "part_4": "Compartilhe viagens personalizadas a partir de um banco de dados de POIs em tempo real através da plataforma profissional UgoZER\n                e seu aplicativo móvel."
                    },
                    "argument_2": {
                        "part_1": "Você pode selecionar itinerários pré-definidos para seus convidados.",
                        "part_2": "Crie o seu com os melhores lugares ou restaurantes da cidade, joias escondidas para descobrir, perfeito\n                caminhadas trilhas para desfrutar...",
                        "part_3": "Ou escolha cada etapa da sua visita dentro de uma lista exaustiva de POIs para combiná-los em\n                irá e depois compartilhará um itinerário personalizado."
                    },
                    "argument_3": {
                        "part_1": "Cada POI possui uma descrição detalhada, a sua posição num mapa interactivo e a sua localização.\n                endereço traduzido automaticamente no idioma local.",
                        "part_2": "Seu convidado nunca estará perdido. Ele sempre encontrará o seu caminho e voltará facilmente para o seu\n                hotel."
                    },
                    "argument_4": {
                        "part_1": "Ao final da viagem, após desfrutar de um roteiro sob medida, seu hóspede poderá\n                agradeça ao seu concierge ou recepcionista por sua recomendação exclusiva, dando-lhe uma gorjeta.",
                        "part_2": "Ao usar o UgoZER, você melhorará a retenção por meio do aumento da receita e diminuirá o quadro de funcionários\n                rotatividade.",
                        "part_3": "Você lhes dará uma maior motivação para se envolverem no dia a dia do seu hotel\n                e dar-lhes um sentimento de pertencimento."
                    },
                    "intro": {
                        "main_title": "UgoZER, o assistente de viagens pessoal e digital multilíngue. Leve seu convidado\n                experiência para o próximo nível e aumente sua receita."
                    },
                    "outro": {
                        "catchphrase": "UgoZER irá ajudá-lo a aumentar a satisfação e impulsionar o crescimento."
                    }
                },
                "startup": {
                    "description_1": "A UgoZER é uma empresa francesa, com sede em Estrasburgo, capital da Europa. Criado por viagens\n            amantes, facilita suas viagens em países onde você não fala o idioma.",
                    "description_2": "É recomendado pelos postos de turismo.",
                    "title": "A startup UgoZER"
                },
                "support_request": "Solicitação de suporte",
                "travellers": {
                    "argument_1": {
                        "arguments": {
                            "audio": "<strong>endereço de áudio</strong> no idioma local para ouvir um táxi ou um transeunte",
                            "desc": "<strong>descrição detalhada</strong>",
                            "languages": "Todas as informações são traduzidas para <strong>francês, inglês, alemão, holandês, português,\n                    Espanhol, italiano, coreano, chinês, japonês e árabe.</strong>",
                            "map": "<strong>localização em um mapa interativo, possível saída no Google Maps™</strong>"
                        },
                        "part_1": "Aceda a todos os pontos de interesse (POI) atualizados em tempo real no seu smartphone.",
                        "part_2": "POIs classificados por <strong>categorias: monumentos, museus, exposições, lojas, restaurantes,\n                bares, eventos temporários...</strong>",
                        "part_3": "POIs fornecidos com:"
                    },
                    "argument_2": {
                        "part_1": "Aproveite a sua viagem com acesso permanente a informações completas. Tudo no seu smartphone,\n                bem no seu bolso."
                    },
                    "argument_3": {
                        "part_1": "Com o UgoZER, obtenha itinerários gratuitos e automáticos. Você também pode criar as viagens e compartilhar\n                eles com seus amigos. Aproveite ao máximo sua viagem obtendo passeios personalizados do seu local\n                especialista: concierge ou recepcionista de hotel, posto de informação turística.",
                        "part_2": "Se gostou da sua viagem, você pode até agradecer ao seu especialista local com um relatório digitalizado e seguro\n                opção de ponta."
                    },
                    "intro": {
                        "main_title": "Seu guia turístico multilíngue gratuito"
                    },
                    "outro": {
                        "catchphrase": "Com o UgoZER deixe-se guiar com segurança e viaje com tranquilidade."
                    }
                },
                "you_are": {
                    "professional": "Profissional",
                    "traveller": "Viajante"
                },
                "youtube": {
                    "directed_by": "Dirigido por",
                    "producer": "Estúdio Suprahead"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "Escolha sua instituição"
                    },
                    "sections": {
                        "pending_claims": "Validação pendente",
                        "requests": "Solicitações Pendentes",
                        "validated_claims": "Reivindicado"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "A solicitação foi enviada"
                },
                "approve": {
                    "confirm": {
                        "employee": "Você realmente deseja aprovar a solicitação?",
                        "employer": "Você realmente quer aprovar {user_name}?"
                    },
                    "success": "O pedido foi aprovado"
                },
                "cancel": {
                    "confirm": {
                        "employee": "Você realmente deseja cancelar esta solicitação?",
                        "employer": "Você realmente deseja cancelar a solicitação {user_name}?"
                    },
                    "success": "A solicitação foi cancelada"
                },
                "decline": {
                    "confirm": {
                        "change_institution": "Sua instituição vinculada será alterada!",
                        "employee": "Você realmente deseja recusar a solicitação?",
                        "employer": "Você realmente quer recusar {user_name}?"
                    },
                    "success": "A solicitação foi recusada"
                },
                "feature": {
                    "confirm": "Você realmente deseja apresentar {user_name}?",
                    "folder": "Para um viajante que não teve nenhuma interação com um de seus funcionários, é o destaque\n           que será exibido na pasta do viajante.",
                    "only_one": "Apenas um funcionário pode ser apresentado.",
                    "success": "O funcionário foi destaque"
                },
                "index": {
                    "my_employees": "Meus funcionários"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "Conta",
                        "invitation": "Convite"
                    }
                },
                "invite": {
                    "invite": "Convide um funcionário"
                },
                "quit": {
                    "confirm": "Você realmente quer sair desta instituição {poi_name}?",
                    "success": "Você saiu da instituição"
                },
                "remove": {
                    "confirm": "Você realmente deseja remover {user_name}?",
                    "success": "O funcionário foi afastado"
                },
                "show": {
                    "pending": "Seu pedido para ingressar nesta instituição está pendente."
                },
                "states": {
                    "approved": "Aprovado",
                    "canceled": "Cancelado",
                    "declined": "Recusado",
                    "invited": "Convidamos",
                    "pending": "Pendente",
                    "removed": "Removido"
                }
            },
            "interactions": {
                "description": {
                    "contact approved": "A solicitação de contato foi aprovada por {sender_name}",
                    "contact blocked": "A solicitação de contato foi bloqueada por {sender_name}",
                    "contact created": "A solicitação de contato foi criada por {sender_name}",
                    "contact declined": "A solicitação de contato foi recusada por 1x",
                    "invitation accepted": "O convite foi aceito por {sender_name}",
                    "invitation created": "O convite foi enviado por {sender_name}",
                    "payment succeeded": "{sender_name} enviou um pagamento para {recipient_name}",
                    "share created": "{sender_name} compartilhou {share}",
                    "traveller_folder created": "A pasta Traveler foi criada por {sender_name}",
                    "traveller_folder thanked": "{sender_name} agradeceu ao viajante por sua estadia"
                },
                "share_type": {
                    "excursions": "o itinerário",
                    "pointsofinterest": "o ponto de interesse"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "Convidar"
                },
                "states": {
                    "authenticated_once": {
                        "false": "Pendente",
                        "true": "Validado"
                    }
                },
                "success": {
                    "professional": {
                        "sent_1": "Um e-mail será enviado para {email}, caso este endereço ainda não esteja em uso.",
                        "sent_2": "Este e-mail contém um link para inscrição na seção profissional.",
                        "title": "Convite enviado"
                    },
                    "traveller": {
                        "email_1": "Um e-mail será enviado para {email}, caso este endereço ainda não esteja em uso.",
                        "email_2": "Este e-mail contém um link que permitirá a conexão ao aplicativo.",
                        "title": "Convite enviado"
                    }
                },
                "texts": {
                    "already_have_account": "O usuário já possui uma conta no aplicativo?",
                    "description": "Você pode convidar alguém para ingressar em nosso aplicativo com um endereço de e-mail ou número de telefone.",
                    "employee_already_have_account": "O funcionário já possui conta no aplicativo?",
                    "geoloc_settings": {
                        "description": "As configurações de geolocalização serão definidas automaticamente de acordo com as coordenadas do seu hotel\n                (se estiver vinculado a um estabelecimento).",
                        "title": "Configurações de geolocalização"
                    },
                    "invite_link": {
                        "instructions": "Copie este link para convidar um viajante",
                        "title": "Convidar por link"
                    },
                    "invite_professional": "Convide um profissional",
                    "invite_traveller": "Convide um viajante",
                    "new_traveller": "Novo viajante",
                    "professional_description": "Você pode convidar um profissional para se juntar à seção profissional do nosso\n            aplicação com um e-mail",
                    "title": "Convidar alguém"
                }
            },
            "locales": {
                "aa": "Longe",
                "ab": "Abcásio",
                "af": "afrikaans",
                "af_NA": "Africâner (Namíbia)",
                "af_ZA": "Africâner (África do Sul)",
                "ak": "Akan",
                "ak_GH": "Akan (Gana)",
                "am": "amárico",
                "am_ET": "Amárico (Etiópia)",
                "an": "aragonês",
                "ar": "árabe",
                "ar_AE": "Árabe (Emirados Árabes Unidos)",
                "ar_BH": "Árabe (Bahrein)",
                "ar_DJ": "Árabe (Djibuti)",
                "ar_DZ": "Árabe (Argélia)",
                "ar_EG": "Árabe (Egito)",
                "ar_EH": "Árabe (Saara Ocidental)",
                "ar_ER": "Árabe (Eritreia)",
                "ar_IL": "Árabe (Israel)",
                "ar_IQ": "Árabe (Iraque)",
                "ar_JO": "Árabe (Jordânia)",
                "ar_KM": "Árabe (Comores)",
                "ar_KW": "Árabe (Kuwait)",
                "ar_LB": "Árabe (Líbano)",
                "ar_LY": "Árabe (Líbia)",
                "ar_MA": "Árabe (Marrocos)",
                "ar_MR": "Árabe (Mauritânia)",
                "ar_OM": "Árabe (Omã)",
                "ar_PS": "Árabe (Territórios Palestinos)",
                "ar_QA": "Árabe (Catar)",
                "ar_SA": "Árabe (Arábia Saudita)",
                "ar_SD": "Árabe (Sudão)",
                "ar_SO": "Árabe (Somália)",
                "ar_SS": "Árabe (Sudão do Sul)",
                "ar_SY": "Árabe (Síria)",
                "ar_TD": "Árabe (Chade)",
                "ar_TN": "Árabe (Tunísia)",
                "ar_YE": "Árabe (Iêmen)",
                "as": "Assamês",
                "as_IN": "Assamês (Índia)",
                "av": "Avarico",
                "ay": "aimará",
                "az": "Azerbaijano",
                "az_AZ": "Azerbaijão (Azerbaijão)",
                "az_Cyrl": "Azerbaijano (cirílico)",
                "az_Cyrl_AZ": "Azerbaijão (cirílico, Azerbaijão)",
                "az_Latn": "Azerbaijano (latim)",
                "az_Latn_AZ": "Azerbaijão (latim, Azerbaijão)",
                "ba": "Basquir",
                "be": "Bielorrusso",
                "be_BY": "Bielorrusso (Bielorrússia)",
                "bg": "búlgaro",
                "bg_BG": "Búlgaro (Bulgária)",
                "bh": "Bihari (idiomas)",
                "bi": "Bislama",
                "bm": "Bambará",
                "bm_Latn": "Bambara (latim)",
                "bm_Latn_ML": "Bambara (latim, Mali)",
                "bn": "bengali",
                "bn_BD": "Bengali (Bangladesh)",
                "bn_IN": "Bengali (Índia)",
                "bo": "tibetano",
                "bo_CN": "Tibetano (China)",
                "bo_IN": "Tibetano (Índia)",
                "br": "bretão",
                "br_FR": "Bretão (França)",
                "bs": "Bósnio",
                "bs_BA": "Bósnio (Bósnia e Herzegovina)",
                "bs_Cyrl": "Bósnio (cirílico)",
                "bs_Cyrl_BA": "Bósnio (cirílico, Bósnia e Herzegovina)",
                "bs_Latn": "Bósnio (latim)",
                "bs_Latn_BA": "Bósnio (latim, Bósnia e Herzegovina)",
                "ca": "catalão",
                "ca_AD": "Catalão (Andorra)",
                "ca_ES": "Catalão (Espanha)",
                "ca_FR": "Catalão (França)",
                "ca_IT": "Catalão (Itália)",
                "ce": "Checheno",
                "ch": "Chamorro",
                "co": "Córsega",
                "cr": "Cree",
                "cs": "Tcheco",
                "cs_CZ": "Checa (República Checa)",
                "cu": "Antigo búlgaro",
                "cv": "chuvache",
                "cy": "galês",
                "cy_GB": "Galês (Reino Unido)",
                "da": "dinamarquês",
                "da_DK": "Dinamarquês (Dinamarca)",
                "da_GL": "Dinamarquês (Groenlândia)",
                "de": "Alemão",
                "de_AT": "Alemão (Áustria)",
                "de_BE": "Alemão (Bélgica)",
                "de_CH": "Alemão (Suíça)",
                "de_DE": "Alemanha alemã)",
                "de_LI": "Alemão (Liechtenstein)",
                "de_LU": "Alemão (Luxemburgo)",
                "dv": "Maldivas",
                "dz": "Dzongkha",
                "dz_BT": "Dzongkha (Butão)",
                "ee": "Ovelha",
                "ee_GH": "Ewe (Gana)",
                "ee_TG": "Ewe (Togo)",
                "el": "grego",
                "el_CY": "Grego (Chipre)",
                "el_GR": "Grego (Grécia)",
                "en": "Inglês",
                "en_AG": "Inglês (Antígua e Barbuda)",
                "en_AI": "Inglês (Anguila)",
                "en_AS": "Inglês (Samoa Americana)",
                "en_AU": "Inglês (Austrália)",
                "en_BB": "Inglês (Barbados)",
                "en_BE": "Inglês (Bélgica)",
                "en_BM": "Inglês (Bermudas)",
                "en_BS": "Inglês (Bahamas)",
                "en_BW": "Inglês (Botsuana)",
                "en_BZ": "Inglês (Belize)",
                "en_CA": "Inglês (Canadá)",
                "en_CC": "Inglês (Ilhas Cocos (Keeling))",
                "en_CK": "Inglês (Ilhas Cook)",
                "en_CM": "Inglês (Camarões)",
                "en_CX": "Inglês (Ilha Christmas)",
                "en_DG": "Inglês (Diego Garcia)",
                "en_DM": "Inglês (Domínica)",
                "en_ER": "Inglês (Eritreia)",
                "en_FJ": "Inglês (Fiji)",
                "en_FK": "Inglês (Ilhas Malvinas)",
                "en_FM": "Inglês (Micronésia)",
                "en_GB": "Inglês (Reino Unido)",
                "en_GD": "Inglês (Granada)",
                "en_GG": "Inglês (Guernsey)",
                "en_GH": "Inglês (Gana)",
                "en_GI": "Inglês (Gibraltar)",
                "en_GM": "Inglês (Gâmbia)",
                "en_GU": "Inglês (Guam)",
                "en_GY": "Inglês (Guiana)",
                "en_HK": "Inglês (Hong Kong, RAE da China)",
                "en_IE": "Inglês (Irlanda)",
                "en_IM": "Inglês (Ilha de Man)",
                "en_IN": "Inglês (Índia)",
                "en_IO": "Inglês (Território Britânico do Oceano Índico)",
                "en_JE": "Inglês (Jérsei)",
                "en_JM": "Inglês (Jamaica)",
                "en_KE": "Inglês (Quênia)",
                "en_KI": "Inglês (Quiribati)",
                "en_KN": "Inglês (São Cristóvão e Nevis)",
                "en_KY": "Inglês (Ilhas Cayman)",
                "en_LC": "Inglês (Santa Lúcia)",
                "en_LR": "Inglês (Libéria)",
                "en_LS": "Inglês (Lesoto)",
                "en_MG": "Inglês (Madagáscar)",
                "en_MH": "Inglês (Ilhas Marshall)",
                "en_MO": "Inglês (Macau, RAE da China)",
                "en_MP": "Inglês (Ilhas Marianas do Norte)",
                "en_MS": "Inglês (Monserrate)",
                "en_MT": "Inglês (Malta)",
                "en_MU": "Inglês (Maurício)",
                "en_MW": "Inglês (Malauí)",
                "en_MY": "Inglês (Malásia)",
                "en_NA": "Inglês (Namíbia)",
                "en_NF": "Inglês (Ilha Norfolk)",
                "en_NG": "Inglês (Nigéria)",
                "en_NR": "Inglês (Nauru)",
                "en_NU": "Inglês (Niue)",
                "en_NZ": "Inglês (Nova Zelândia)",
                "en_PG": "Inglês (Papua Nova Guiné)",
                "en_PH": "Inglês (Filipinas)",
                "en_PK": "Inglês (Paquistão)",
                "en_PN": "Inglês (Ilhas Pitcairn)",
                "en_PR": "Inglês (Porto Rico)",
                "en_PW": "Inglês (Palau)",
                "en_RW": "Inglês (Ruanda)",
                "en_SB": "Inglês (Ilhas Salomão)",
                "en_SC": "Inglês (Seicheles)",
                "en_SD": "Inglês (Sudão)",
                "en_SG": "Inglês (Singapura)",
                "en_SH": "Inglês (Santa Helena)",
                "en_SL": "Inglês (Serra Leoa)",
                "en_SS": "Inglês (Sudão do Sul)",
                "en_SX": "Inglês (São Martinho)",
                "en_SZ": "Inglês (Suazilândia)",
                "en_TC": "Inglês (Ilhas Turks e Caicos)",
                "en_TK": "Inglês (Tokelau)",
                "en_TO": "Inglês (Tonga)",
                "en_TT": "Inglês (Trindade e Tobago)",
                "en_TV": "Inglês (Tuvalu)",
                "en_TZ": "Inglês (Tanzânia)",
                "en_UG": "Inglês (Uganda)",
                "en_UM": "Inglês (Ilhas Distantes dos EUA)",
                "en_US": "Inglês dos Estados Unidos)",
                "en_VC": "Inglês (São Vicente e Granadinas)",
                "en_VG": "Inglês (Ilhas Virgens Britânicas)",
                "en_VI": "Inglês (Ilhas Virgens dos EUA)",
                "en_VU": "Inglês (Vanuatu)",
                "en_WS": "Inglês (Samoa)",
                "en_ZA": "Inglês (África do Sul)",
                "en_ZM": "Inglês (Zâmbia)",
                "en_ZW": "Inglês (Zimbábue)",
                "eo": "esperanto",
                "es": "Espanhol",
                "es_AR": "Espanhol (Argentina)",
                "es_BO": "Espanhol (Bolívia)",
                "es_CL": "Espanhol (Chile)",
                "es_CO": "Espanhol (Colômbia)",
                "es_CR": "Espanhol (Costa Rica)",
                "es_CU": "Espanhol (Cuba)",
                "es_DO": "Espanhol (República Dominicana)",
                "es_EA": "Espanhol (Ceuta e Melilha)",
                "es_EC": "Espanhol (Equador)",
                "es_ES": "Espanhol (Espanha)",
                "es_GQ": "Espanhol (Guiné Equatorial)",
                "es_GT": "Espanhol (Guatemala)",
                "es_HN": "Espanhol (Honduras)",
                "es_IC": "Espanhol (Ilhas Canárias)",
                "es_MX": "Espanhol (México)",
                "es_NI": "Espanhol (Nicarágua)",
                "es_PA": "Espanhol (Panamá)",
                "es_PE": "Espanhol (Peru)",
                "es_PH": "Espanhol (Filipinas)",
                "es_PR": "Espanhol (Porto Rico)",
                "es_PY": "Espanhol (Paraguai)",
                "es_SV": "Espanhol (El Salvador)",
                "es_US": "Espanhol (Estados Unidos)",
                "es_UY": "Espanhol (Uruguai)",
                "es_VE": "Espanhol (Venezuela)",
                "et": "estoniano",
                "et_EE": "Estoniano (Estônia)",
                "eu": "Basco",
                "eu_ES": "Basco (Espanha)",
                "fa": "persa",
                "fa_AF": "Persa (Afeganistão)",
                "fa_IR": "Persa (Irã)",
                "ff": "Fulá",
                "ff_CM": "Fulah (Camarões)",
                "ff_GN": "Fulah (Guiné)",
                "ff_MR": "Fulah (Mauritânia)",
                "ff_SN": "Fulah (Senegal)",
                "fi": "finlandês",
                "fi_FI": "Finlandês (Finlândia)",
                "fj": "Fijiano",
                "fo": "Feroês",
                "fo_FO": "Faroês (Ilhas Faroé)",
                "fr": "Francês",
                "fr_BE": "Francês (Bélgica)",
                "fr_BF": "Francês (Burkina Faso)",
                "fr_BI": "Francês (Burundi)",
                "fr_BJ": "Francês (Benin)",
                "fr_BL": "Francês (São Bartolomeu)",
                "fr_CA": "Francês (Canadá)",
                "fr_CD": "Francês (Congo - Kinshasa)",
                "fr_CF": "Francês (República Centro-Africana)",
                "fr_CG": "Francês (Congo - Brazzaville)",
                "fr_CH": "Francês (Suíça)",
                "fr_CI": "Francês (Costa do Marfim)",
                "fr_CM": "Francês (Camarões)",
                "fr_DJ": "Francês (Djibuti)",
                "fr_DZ": "Francês (Argélia)",
                "fr_FR": "Francês França)",
                "fr_GA": "Francês (Gabão)",
                "fr_GF": "Francês (Guiana Francesa)",
                "fr_GN": "Francês (Guiné)",
                "fr_GP": "Francês (Guadalupe)",
                "fr_GQ": "Francês (Guiné Equatorial)",
                "fr_HT": "Francês (Haiti)",
                "fr_KM": "Francês (Comores)",
                "fr_LU": "Francês (Luxemburgo)",
                "fr_MA": "Francês (Marrocos)",
                "fr_MC": "Francês (Mônaco)",
                "fr_MF": "Francês (São Martinho)",
                "fr_MG": "Francês (Madagascar)",
                "fr_ML": "Francês (Mali)",
                "fr_MQ": "Francês (Martinica)",
                "fr_MR": "Francês (Mauritânia)",
                "fr_MU": "Francês (Maurício)",
                "fr_NC": "Francês (Nova Caledônia)",
                "fr_NE": "Francês (Níger)",
                "fr_PF": "Francês (Polinésia Francesa)",
                "fr_PM": "Francês (São Pedro e Miquelon)",
                "fr_RE": "Francês (Reunião)",
                "fr_RW": "Francês (Ruanda)",
                "fr_SC": "Francês (Seicheles)",
                "fr_SN": "Francês (Senegal)",
                "fr_SY": "Francês (Síria)",
                "fr_TD": "Francês (Chade)",
                "fr_TG": "Francês (Togo)",
                "fr_TN": "Francês (Tunísia)",
                "fr_VU": "Francês (Vanuatu)",
                "fr_WF": "Francês (Wallis e Futuna)",
                "fr_YT": "Francês (Mayotte)",
                "fy": "Frísio Ocidental",
                "fy_NL": "Frísio Ocidental (Holanda)",
                "ga": "irlandês",
                "ga_IE": "Irlandês (Irlanda)",
                "gd": "gaélico escocês",
                "gd_GB": "Gaélico Escocês (Reino Unido)",
                "gl": "Galego",
                "gl_ES": "Galego (Espanha)",
                "gn": "guarani",
                "gu": "Guzerate",
                "gu_IN": "Guzerate (Índia)",
                "gv": "Manx",
                "gv_IM": "Manx (Ilha de Man)",
                "ha": "Hauçá",
                "ha_GH": "Hauçá (Gana)",
                "ha_Latn": "Hauçá (latim)",
                "ha_Latn_GH": "Hausa (latim, Gana)",
                "ha_Latn_NE": "Hausa (latim, Níger)",
                "ha_Latn_NG": "Hausa (latim, Nigéria)",
                "ha_NE": "Hauçá (Níger)",
                "ha_NG": "Hauçá (Nigéria)",
                "he": "hebraico",
                "he_IL": "Hebraico (Israel)",
                "hi": "hindi",
                "hi_IN": "Hindi (Índia)",
                "ho": "Hiri Motu",
                "hr": "croata",
                "hr_BA": "Croata (Bósnia e Herzegovina)",
                "hr_HR": "Croata (Croácia)",
                "ht": "haitiano",
                "hu": "húngaro",
                "hu_HU": "Húngaro (Hungria)",
                "hy": "Armênio",
                "hy_AM": "Armênio (Armênia)",
                "hz": "Hereró",
                "ia": "Interlíngua",
                "id": "indonésio",
                "id_ID": "Indonésio (Indonésia)",
                "ie": "Interlíngua",
                "ig": "Ibo",
                "ig_NG": "Ibo (Nigéria)",
                "ii": "Sichuan Yi",
                "ii_CN": "Sichuan Yi (China)",
                "ik": "Inupiaq",
                "io": "Eu faço",
                "is": "islandês",
                "is_IS": "Islandês (Islândia)",
                "it": "italiano",
                "it_CH": "Italiano (Suíça)",
                "it_IT": "Italiano (Itália)",
                "it_SM": "Italiano (San Marino)",
                "iu": "Inuktitut",
                "ja": "japonês",
                "ja_JP": "Japonês (Japão)",
                "jv": "Javanês",
                "ka": "Georgiano",
                "ka_GE": "Georgiano (Geórgia)",
                "kg": "Congo",
                "ki": "Kikuyu",
                "ki_KE": "Kikuyu (Quênia)",
                "kj": "Cuanhama",
                "kk": "Cazaque",
                "kk_Cyrl": "Cazaque (cirílico)",
                "kk_Cyrl_KZ": "Cazaque (cirílico, Cazaquistão)",
                "kk_KZ": "Cazaquistão (Cazaquistão)",
                "kl": "Kalaallisut",
                "kl_GL": "Kalaallisut (Groenlândia)",
                "km": "Khmer",
                "km_KH": "Khmer (Camboja)",
                "kn": "Kannada",
                "kn_IN": "Kannada (Índia)",
                "ko": "coreano",
                "ko_KP": "Coreano (Coreia do Norte)",
                "ko_KR": "Coreano (Coreia do Sul)",
                "kr": "Kanuri",
                "ks": "Caxemira",
                "ks_Arab": "Caxemira (árabe)",
                "ks_Arab_IN": "Caxemira (Árabe, Índia)",
                "ks_IN": "Caxemira (Índia)",
                "ku": "curdo",
                "kv": "Komi",
                "kw": "Cornualha",
                "kw_GB": "Cornualha (Reino Unido)",
                "ky": "Quirguistão",
                "ky_Cyrl": "Quirguistão (cirílico)",
                "ky_Cyrl_KG": "Quirguistão (cirílico, Quirguistão)",
                "ky_KG": "Quirguistão (Quirguistão)",
                "la": "Latim",
                "lb": "Luxemburguês",
                "lb_LU": "Luxemburguês (Luxemburgo)",
                "lg": "Ganda",
                "lg_UG": "Ganda (Uganda)",
                "li": "Limburguês",
                "ln": "Lingala",
                "ln_AO": "Lingala (Angola)",
                "ln_CD": "Lingala (Congo - Kinshasa)",
                "ln_CF": "Lingala (República Centro-Africana)",
                "ln_CG": "Lingala (Congo - Brazzaville)",
                "lo": "Laos",
                "lo_LA": "Laos (Laos)",
                "lt": "lituano",
                "lt_LT": "Lituano (Lituânia)",
                "lu": "Luba-Katanga",
                "lu_CD": "Luba-Katanga (Congo - Kinshasa)",
                "lv": "letão",
                "lv_LV": "Letão (Letônia)",
                "mg": "malgaxe",
                "mg_MG": "Malgaxe (Madagascar)",
                "mh": "marshallês",
                "mi": "maori",
                "mk": "Macedônio",
                "mk_MK": "Macedônio (Macedônia)",
                "ml": "Malaiala",
                "ml_IN": "Malaiala (Índia)",
                "mn": "mongol",
                "mn_Cyrl": "Mongol (cirílico)",
                "mn_Cyrl_MN": "Mongol (cirílico, Mongólia)",
                "mn_MN": "Mongólia (Mongólia)",
                "mo": "Macau",
                "mr": "Marathi",
                "mr_IN": "Marathi (Índia)",
                "ms": "malaio",
                "ms_BN": "Malaio (Brunei)",
                "ms_Latn": "Malaio (latim)",
                "ms_Latn_BN": "Malaio (latim, Brunei)",
                "ms_Latn_MY": "Malaio (latim, Malásia)",
                "ms_Latn_SG": "Malaio (latim, Singapura)",
                "ms_MY": "Malaio (Malásia)",
                "ms_SG": "Malaio (Cingapura)",
                "mt": "maltês",
                "mt_MT": "Maltês (Malta)",
                "my": "birmanês",
                "my_MM": "Birmanês (Myanmar (Birmânia))",
                "na": "Nauru",
                "nb": "Bokmål Norueguês",
                "nb_NO": "Bokmål norueguês (Noruega)",
                "nb_SJ": "Bokmål norueguês (Svalbard e Jan Mayen)",
                "nd": "Ndebele do Norte",
                "nd_ZW": "North Ndebele (Zimbábue)",
                "ne": "nepalês",
                "ne_IN": "Nepalês (Índia)",
                "ne_NP": "Nepalês (Nepal)",
                "ng": "Ndonga",
                "nl": "Holandês",
                "nl_AW": "Holandês (Aruba)",
                "nl_BE": "Holandês (Bélgica)",
                "nl_BQ": "Holandês (Holanda Caribenha)",
                "nl_CW": "Holandês (Curaçao)",
                "nl_NL": "Holandês (Holanda)",
                "nl_SR": "Holandês (Suriname)",
                "nl_SX": "Holandês (Sint Maarten)",
                "nn": "Norueguês Nynorsk",
                "nn_NO": "Norueguês Nynorsk (Noruega)",
                "no": "norueguês",
                "no_NO": "Norueguês (Noruega)",
                "nr": "Ndebele do Sul",
                "nv": "Navajo",
                "ny": "Chichewa",
                "oc": "Occitano",
                "oj": "Ojíbua",
                "om": "Oromo",
                "om_ET": "Oromo (Etiópia)",
                "om_KE": "Oromo (Quênia)",
                "or": "Oriá",
                "or_IN": "Oriya (Índia)",
                "os": "Ossético",
                "os_GE": "Ossético (Geórgia)",
                "os_RU": "Ossético (Rússia)",
                "pa": "punjabi",
                "pa_Arab": "Punjabi (Árabe)",
                "pa_Arab_PK": "Punjabi (árabe, Paquistão)",
                "pa_Guru": "Punjabi (Gurmukhi)",
                "pa_Guru_IN": "Punjabi (Gurmukhi, Índia)",
                "pa_IN": "Punjabi (Índia)",
                "pa_PK": "Punjabi (Paquistão)",
                "pi": "páli",
                "pl": "polonês",
                "pl_PL": "Polonês (Polônia)",
                "ps": "pashto",
                "ps_AF": "Pashto (Afeganistão)",
                "pt": "Português",
                "pt_AO": "Português (Angola)",
                "pt_BR": "Português (Brasil)",
                "pt_CV": "Português (Cabo Verde)",
                "pt_GW": "Português (Guiné-Bissau)",
                "pt_MO": "Português (Macau, RAE da China)",
                "pt_MZ": "Português (Moçambique)",
                "pt_PT": "Português (Portugal)",
                "pt_ST": "Português (São Tomé e Príncipe)",
                "pt_TL": "Português (Timor-Leste)",
                "qu": "Quechua",
                "qu_BO": "Quéchua (Bolívia)",
                "qu_EC": "Quéchua (Equador)",
                "qu_PE": "Quéchua (Peru)",
                "rm": "Romanche",
                "rm_CH": "Romanche (Suíça)",
                "rn": "Rundi",
                "rn_BI": "Rundi (Burundi)",
                "ro": "romena",
                "ro_MD": "Romeno (Moldávia)",
                "ro_RO": "Romeno (Romênia)",
                "ru": "russo",
                "ru_BY": "Russo (Bielorrússia)",
                "ru_KG": "Russo (Quirguistão)",
                "ru_KZ": "Russo (Cazaquistão)",
                "ru_MD": "Russo (Moldávia)",
                "ru_RU": "Russo (Rússia)",
                "ru_UA": "Russo (Ucrânia)",
                "rw": "Kinyarwanda",
                "rw_RW": "Kinyarwanda (Ruanda)",
                "sa": "sânscrito",
                "sc": "Sardenha",
                "sd": "Sindi",
                "se": "Sami do Norte",
                "se_FI": "Sami do Norte (Finlândia)",
                "se_NO": "Sami do Norte (Noruega)",
                "se_SE": "Sami do Norte (Suécia)",
                "sg": "Sango",
                "sg_CF": "Sango (República Centro-Africana)",
                "sh": "servo-croata",
                "sh_BA": "Servo-Croata (Bósnia e Herzegovina)",
                "si": "Cingalês",
                "si_LK": "Cingalês (Sri Lanca)",
                "sk": "Eslovaco",
                "sk_SK": "Eslovaco (Eslováquia)",
                "sl": "esloveno",
                "sl_SI": "Esloveno (Eslovênia)",
                "sm": "samoano",
                "sn": "Shona",
                "sn_ZW": "Shona (Zimbábue)",
                "so": "somali",
                "so_DJ": "Somália (Djibuti)",
                "so_ET": "Somália (Etiópia)",
                "so_KE": "Somália (Quênia)",
                "so_SO": "Somália (Somália)",
                "sq": "albanês",
                "sq_AL": "Albanês (Albânia)",
                "sq_MK": "Albanês (Macedônia)",
                "sq_XK": "Albanês (Kosovo)",
                "sr": "sérvio",
                "sr_BA": "Sérvio (Bósnia e Herzegovina)",
                "sr_Cyrl": "Sérvio (cirílico)",
                "sr_Cyrl_BA": "Sérvio (cirílico, Bósnia e Herzegovina)",
                "sr_Cyrl_ME": "Sérvio (cirílico, Montenegro)",
                "sr_Cyrl_RS": "Sérvio (cirílico, Sérvia)",
                "sr_Cyrl_XK": "Sérvio (cirílico, Kosovo)",
                "sr_Latn": "Sérvio (latim)",
                "sr_Latn_BA": "Sérvio (latim, Bósnia e Herzegovina)",
                "sr_Latn_ME": "Sérvio (latim, Montenegro)",
                "sr_Latn_RS": "Sérvio (latim, Sérvia)",
                "sr_Latn_XK": "Sérvio (latim, Kosovo)",
                "sr_ME": "Sérvio (Montenegro)",
                "sr_RS": "Sérvio (Sérvia)",
                "sr_XK": "Sérvio (Kosovo)",
                "ss": "Swati",
                "st": "Soto, Sul",
                "su": "Sudanês",
                "sv": "sueco",
                "sv_AX": "Sueco (Ilhas Åland)",
                "sv_FI": "Sueco (Finlândia)",
                "sv_SE": "Sueco (Suécia)",
                "sw": "suaíli",
                "sw_KE": "Suaíli (Quênia)",
                "sw_TZ": "Suaíli (Tanzânia)",
                "sw_UG": "Suaíli (Uganda)",
                "ta": "tâmil",
                "ta_IN": "Tâmil (Índia)",
                "ta_LK": "Tâmil (Sri Lanca)",
                "ta_MY": "Tâmil (Malásia)",
                "ta_SG": "Tâmil (Singapura)",
                "te": "Telugu",
                "te_IN": "Telugu (Índia)",
                "tg": "tadjique",
                "th": "tailandês",
                "th_TH": "Tailandês (Tailândia)",
                "ti": "Tigrínia",
                "ti_ER": "Tigrinya (Eritreia)",
                "ti_ET": "Tigrinya (Etiópia)",
                "tk": "turcomano",
                "tl": "tagalo",
                "tl_PH": "Tagalo (Filipinas)",
                "tn": "Tsuana",
                "to": "tonganês",
                "to_TO": "Tonganês (Tonga)",
                "tr": "turco",
                "tr_CY": "Turco (Chipre)",
                "tr_TR": "Turco (Turquia)",
                "ts": "Tsonga",
                "tt": "tártaro",
                "tw": "Twi",
                "ty": "taitiano",
                "ug": "Uigur",
                "ug_Arab": "Uigur (árabe)",
                "ug_Arab_CN": "Uigur (árabe, China)",
                "ug_CN": "Uigur (China)",
                "uk": "ucraniano",
                "uk_UA": "Ucraniano (Ucrânia)",
                "ur": "urdu",
                "ur_IN": "Urdu (Índia)",
                "ur_PK": "Urdu (Paquistão)",
                "uz": "Usbeque",
                "uz_AF": "Uzbeque (Afeganistão)",
                "uz_Arab": "Uzbeque (árabe)",
                "uz_Arab_AF": "Uzbeque (Árabe, Afeganistão)",
                "uz_Cyrl": "Uzbeque (cirílico)",
                "uz_Cyrl_UZ": "Uzbeque (cirílico, Uzbequistão)",
                "uz_Latn": "Uzbeque (latim)",
                "uz_Latn_UZ": "Uzbeque (latim, Uzbequistão)",
                "uz_UZ": "Uzbeque (Uzbequistão)",
                "ve": "Venda",
                "vi": "vietnamita",
                "vi_VN": "Vietnamita (Vietnã)",
                "vo": "Volapük",
                "wa": "valão",
                "wo": "Uolof",
                "xh": "Xhosa",
                "yi": "iídiche",
                "yo": "Iorubá",
                "yo_BJ": "Iorubá (Benim)",
                "yo_NG": "Iorubá (Nigéria)",
                "za": "Zhuang",
                "zh": "chinês",
                "zh_CN": "Chinês (China)",
                "zh_HK": "Chinês (Hong Kong, RAE da China)",
                "zh_Hans": "Chinês simplificado)",
                "zh_Hans_CN": "Chinês (simplificado, China)",
                "zh_Hans_HK": "Chinês (simplificado, Hong Kong, RAE da China)",
                "zh_Hans_MO": "Chinês (simplificado, Macau, RAE da China)",
                "zh_Hans_SG": "Chinês (simplificado, Singapura)",
                "zh_Hant": "Chinês tradicional)",
                "zh_Hant_HK": "Chinês (tradicional, Hong Kong, RAE da China)",
                "zh_Hant_MO": "Chinês (tradicional, Macau, RAE da China)",
                "zh_Hant_TW": "Chinês (tradicional, Taiwan)",
                "zh_MO": "Chinês (Macau, RAE da China)",
                "zh_SG": "Chinês (Cingapura)",
                "zh_TW": "Chinês (Taiwan)",
                "zu": "zulu",
                "zu_ZA": "Zulu (África do Sul)"
            },
            "mail": {
                "action": "Se você estiver tendo problemas para clicar no botão \"{actionText}\", copie e cole o URL abaixo em seu navegador:",
                "auth": {
                    "deleted": {
                        "line1": "Informamos que sua conta UgoZER foi excluída.",
                        "reason": {
                            "intro": "Este foi excluído pelo seguinte motivo:",
                            "never_authenticated_for_too_long": "Sua conta foi criada há mais de uma semana\n                    e você nunca fez login:\n                    portanto esta conta é considerada inativa.",
                            "not_logged_for_too_long": "Você não faz login há mais de 2 anos:\n                    sua conta é considerada inativa."
                        },
                        "subject": "Exclusão da sua conta UgoZER"
                    },
                    "login_attempt_unverified_credential": {
                        "action": "Verifique o endereço de e-mail",
                        "line1": "Houve uma tentativa de fazer login na sua conta UgoZER com o endereço de e-mail {credential}.",
                        "line2": "Se você iniciou esta tentativa de login,\n                por favor clique no botão abaixo para verificar seu endereço de e-mail\n                e poder se conectar.",
                        "line3": "Este link irá expirar em 1 semana.",
                        "line4": "Se você não iniciou esta tentativa de login, nenhuma ação adicional será necessária.",
                        "subject": "Tente fazer login em sua conta UgoZER"
                    },
                    "login_magic_link": {
                        "action": "Conecte-se",
                        "line1": "Clique neste link para fazer login",
                        "line2": "Obrigado por usar nosso aplicativo!",
                        "subject": "Link de login sem senha"
                    },
                    "not_logged": {
                        "intro": "Já se passaram quase 2 anos desde a última vez que você fez login em sua conta UgoZER.",
                        "outro": "Basta fazer login na sua conta para que ela não seja excluída.",
                        "remaining": {
                            "two_days": "Você tem 2 dias restantes antes que sua conta seja excluída.",
                            "two_months": "Você tem 2 meses restantes antes que sua conta seja excluída.",
                            "two_weeks": "Você tem 2 semanas restantes antes que sua conta seja excluída."
                        },
                        "subject": "Sua conta UgoZER será excluída em breve"
                    },
                    "registration": {
                        "action": "Verifique o endereço de e-mail",
                        "line1": "Clique no botão abaixo para verificar seu endereço de e-mail e poder fazer login.",
                        "line2": "Este link irá expirar em 1 semana.",
                        "line3": "Se você não criou uma conta, nenhuma ação adicional será necessária.",
                        "subject": "Verifique seu endereço de e-mail"
                    },
                    "registration_attempt": {
                        "line1": "O e-mail <strong>{credential}</strong> já é usado para uma conta <strong>{role_type}</strong> em nosso aplicativo.",
                        "line2": "Para criar uma conta, inicie o registro novamente com outro e-mail.",
                        "subject": "Tente se registrar com sua credencial UgoZER"
                    },
                    "registration_attempt_unverified_credential": {
                        "line1": "O e-mail <strong>{credential}</strong> já é usado para uma conta <strong>{role_type}</strong> em nosso aplicativo.",
                        "line2": "Para criar uma conta, inicie o registro novamente com outro e-mail.",
                        "subject": "Tente se registrar com sua credencial UgoZER"
                    },
                    "reset_password": {
                        "action": "Redefinir senha",
                        "line1": "Você está recebendo este e-mail porque recebemos uma solicitação de redefinição de senha da sua conta.",
                        "line2": "Este link de redefinição de senha expirará em {count} minutos.",
                        "line3": "Se você não solicitou uma redefinição de senha, nenhuma ação adicional será necessária.",
                        "subject": "Notificação de redefinição de senha"
                    }
                },
                "copyright": "© {year} UgoZER - Todos os direitos reservados.",
                "credential_verification": {
                    "verify": {
                        "action": "Verifique o endereço de e-mail",
                        "line1": "Clique no botão abaixo para verificar seu endereço de e-mail.",
                        "line2": "Este link irá expirar em 1 semana.",
                        "line3": "Se você não solicitou a verificação, nenhuma ação adicional será necessária.",
                        "subject": "Confirmação do seu e-mail"
                    }
                },
                "hello": "Olá!",
                "invitation": {
                    "attempt": {
                        "action": "Acesso ao aplicativo",
                        "line1": "Você foi convidado para participar do nosso aplicativo",
                        "line1_by": "Você foi convidado por {name} para participar do nosso aplicativo",
                        "line1_by_from": "Você foi convidado por {name} da instituição {poi_title} para participar do nosso aplicativo",
                        "line2": "Você já possui uma conta em nosso aplicativo",
                        "line3": "Clique no botão abaixo para acessar o aplicativo.",
                        "subject": "Convite para ingressar no UgoZER"
                    },
                    "verification": {
                        "action": "Verifique o endereço de e-mail",
                        "excursion_shared": "Um itinerário foi compartilhado com você. Você pode encontrá-lo anexado em formato PDF,\n                    bem como na aplicação em suas ações.",
                        "line1": "Você foi convidado para participar do nosso aplicativo",
                        "line1_by": "Você foi convidado por {name} para participar do nosso aplicativo",
                        "line1_by_from": "Você foi convidado por {name} da instituição {poi_title} para participar do nosso aplicativo",
                        "line2": "Clique no botão abaixo para verificar seu endereço de e-mail.",
                        "line3": "Este link irá expirar em 1 semana.",
                        "line4": "Obrigado por usar nosso aplicativo!",
                        "point_of_interest_shared": "Um ponto de interesse foi compartilhado com você. Você pode encontrá lo\n                no aplicativo em suas ações.",
                        "subject": "Convite para ingressar no UgoZER"
                    }
                },
                "invitation_professional": {
                    "content": "Você foi convidado para participar da seção profissional do nosso aplicativo UgoZER",
                    "cta": "Registre-se na seção profissional",
                    "subject": "UgoZER - Convite de acesso profissional",
                    "thanks": "Obrigado,",
                    "title": "Convite para a seção profissional do aplicativo UgoZER"
                },
                "preview": {
                    "default": {
                        "subject": "Assunto do email",
                        "user": {
                            "name": "UgoZER"
                        }
                    },
                    "from": "De:",
                    "mails": {
                        "auth/account-deleted-notification": "Exclusão de conta",
                        "auth/not-logged-notification": "Excluir se não estiver conectado",
                        "auth/registration-attempt-notification": "Tentativa de registro",
                        "auth/registration-notification": "Cadastro",
                        "auth/reset-password-notification": "Mude sua senha",
                        "invitation/invited-notification": "Convite",
                        "share/created-notification": "Novo",
                        "support-message/message-notification": "Nova resposta",
                        "support-request/archived-notification": "Solicitação arquivada",
                        "support-request/created-notification": "Solicitação criada",
                        "user-credential-verification/login-attempt-notification": "Tente se conectar",
                        "user-credential-verification/registration-attempt-notification": "Tentativa de registro",
                        "user-credential-verification/verify-notification": "Confirmação da conta",
                        "user/welcome-notification": "Bem-vindo"
                    },
                    "main_title": "Visualização de e-mail",
                    "sections": {
                        "auth": "Autenticação",
                        "invitation": "Convites",
                        "share": "Ações",
                        "support-message": "Mensagens de suporte",
                        "support-request": "Solicitações de suporte",
                        "user": "Do utilizador",
                        "user-credential-verification": "Credencial do usuário"
                    }
                },
                "salutations": "Cumprimentos",
                "share": {
                    "created": {
                        "action": "Mostrar",
                        "excursion": {
                            "gotoapp": "Acesse o aplicativo para visualizá-lo.",
                            "line": "{username} da instituição {poi_title} compartilhou um itinerário com você.",
                            "line_alt": "{username} compartilhou um itinerário com você.",
                            "line_alt_2": "Um itinerário foi compartilhado com você.",
                            "subject": "Um itinerário foi compartilhado com você."
                        },
                        "line": "{username} da instituição {poi_title} compartilhou um item com você.",
                        "line_alt": "{username} compartilhou um item com você.",
                        "line_alt_2": "Um item foi compartilhado com você.",
                        "point_of_interest": {
                            "line": "{username} da instituição {poi_title} compartilhou um ponto de interesse com você.",
                            "line_alt": "{username} compartilhou um ponto de interesse com você.",
                            "line_alt_2": "Um ponto de interesse foi compartilhado com você.",
                            "subject": "Um ponto de interesse foi compartilhado com você."
                        },
                        "subject": "Um item foi compartilhado com você."
                    }
                },
                "subscription": {
                    "not_continue_after_free_period": {
                        "cta": "Ver detalhes",
                        "line1": "{name} não continuou após o término do período gratuito",
                        "line2": "Clique no botão abaixo para acessar os detalhes",
                        "subject": "O assinante não continuou após o término do período gratuito"
                    },
                    "suspended": {
                        "line": "Sua assinatura foi suspensa pelo seguinte motivo: {suspension_reason}.",
                        "line_alt": "Sua assinatura foi suspensa.",
                        "subject": "Assinatura suspensa"
                    },
                    "switched_from_paid_to_free": {
                        "cta": "Ver detalhes",
                        "line1": "{name} mudou para o plano gratuito",
                        "line2": "Clique no botão abaixo para acessar os detalhes",
                        "subject": "Assinante mudou para o plano gratuito"
                    }
                },
                "support_request": {
                    "archived": {
                        "subject": "UgoZER - Solicitação de suporte arquivada",
                        "title": "Sua solicitação de suporte foi arquivada"
                    },
                    "message": {
                        "action": "Acesso à solicitação de suporte",
                        "line1": "Clique no botão abaixo para acessar sua solicitação.",
                        "subject": "UgoZER - Você tem uma resposta",
                        "title": "Uma resposta foi publicada em sua solicitação de suporte"
                    },
                    "request": {
                        "action": "Acesso à solicitação de suporte",
                        "line1": "Contate o suporte responderá dentro de 48 horas.",
                        "line2": "Clique no botão abaixo para acessar sua solicitação.",
                        "subject": "UgoZER - Sua solicitação de suporte foi criada"
                    }
                },
                "user_send_link": {
                    "content": "Reconecte você ao nosso aplicativo UgoZER! Se você não se lembra da sua senha, você pode redefini-la.",
                    "cta": "Faça login no aplicativo",
                    "subject": "UgoZER - Aplicativo",
                    "thanks": "Obrigado,",
                    "title": "Você tem uma conta em nosso aplicativo, faça login!"
                },
                "welcome": {
                    "action": "Acesso ao aplicativo",
                    "line1": "Estamos felizes em contar com você entre nossos usuários.",
                    "line2": "Clique no botão abaixo para acessar o aplicativo.",
                    "subject": "Bem-vindo ao UgoZER",
                    "title": "Bem-vindo ao UgoZER, seu aplicativo gratuito de assistência em viagens multilíngue."
                }
            },
            "media": {
                "audio": {
                    "generated_audio": "Áudio gerado",
                    "recording": {
                        "errors": {
                            "default": "Falha na gravação",
                            "not_allowed": "A gravação falhou. Permita que seu navegador e UgoZER usem seu microfone e tente novamente."
                        }
                    }
                },
                "default": {
                    "alt": "Mapa estático do Google Maps",
                    "caption": "Clique no mapa para mostrar isso no Google Maps"
                },
                "image": {
                    "credits": "Créditos: {credits}"
                }
            },
            "models": {
                "activity_log": "Registro de atividades|Registros de atividades",
                "address": "Endereço|Endereços",
                "bank_transfer": "Transferência bancária|Transferências bancárias",
                "bank_transfer_institution": "Instituição de transferência bancária|Instituições de transferência bancária",
                "category": "Categoria|Categorias",
                "changelog": "Registro de alterações | Registros de alterações",
                "claim": "Reivindicação|Reivindicações",
                "claim_dispute": "Disputa de reivindicação|Disputa de reivindicação",
                "currency": "Moeda|Moedas",
                "data_tourisme_category": "Categoria DataTurisme|Categorias DataTurisme",
                "data_tourisme_tag": "Tag DataTourisme|Tags DataTourisme",
                "excursion": "Itinerário|Itinerários",
                "institution_employee": "Funcionário|Funcionários",
                "interaction": "Interação|Interações",
                "locale": "Idioma|Idiomas",
                "medium": "Médio|Mídia",
                "notification": "Notificação|Notificações",
                "partner": "Parceiro|Parceiros",
                "payment": "Pagamento|Pagamentos",
                "permission": "Permissão|Permissões",
                "plan": "Plano de assinatura|Planos de assinatura",
                "plan_price": "Preço do plano|Preços do plano",
                "point_of_interest": "Ponto de interesse|Pontos de interesse",
                "point_of_interest_category": "Categoria|Categorias",
                "post": "Postagens|Postagens",
                "post_category": "Categoria de postagens|Categorias de postagens",
                "privacy_policy": "Política de privacidade|Políticas de privacidade",
                "report": "Relatório|Relatórios",
                "reservation": "Reserva|Reservas",
                "role": "Função|Funções",
                "share": "Compartilhar|Compartilhamentos",
                "subscription": "Assinatura|Assinaturas",
                "support_category": "Categoria de suporte|Categorias de suporte",
                "support_message": "Mensagem|Mensagens",
                "support_request": "Solicitação | Solicitações",
                "tag": "Etiqueta|Etiquetas",
                "terms_condition": "Termos e condições|Termos e condições",
                "traveller_email": "E-mail do viajante|E-mails do viajante",
                "traveller_folder": "Pasta Viajante|Pastas Viajante",
                "user": "Usuário|Usuários",
                "user_credential_verification": "Verificação de credenciais|Verificações de credenciais",
                "user_geoloc_setting": "Configuração Geoloc|Configurações Geoloc",
                "user_notification_setting": "Configuração de notificações|Configurações de notificações",
                "user_password_reset": "Redefinição de senha de usuário|Redefinição de senhas de usuário",
                "user_privacy_setting": "Configuração de privacidade|Configurações de privacidade",
                "users_relationship": "Contato|Contatos",
                "vat_rate": "Taxa de IVA|Taxas de IVA"
            },
            "navigations": {
                "items": {
                    "changelogs": "Registros de alterações",
                    "contact_us": "Contate-nos",
                    "contacts": "Contatos",
                    "cookies": "Gestão de cookies",
                    "credits": "Créditos",
                    "customers": "Eles confiam em nós",
                    "faqs": "Perguntas frequentes",
                    "folders": "Pastas de viajantes",
                    "invite_professional": "Convide um profissional",
                    "invite_traveller": "Convide um viajante",
                    "partners": "Parceiros",
                    "payments": "Pagamentos",
                    "privacy_policy": "Política de Privacidade",
                    "settings": "Parâmetros",
                    "stays": "Estadias",
                    "terms_conditions": "Termos e Condições",
                    "users": "Usuários"
                }
            },
            "notifications": {
                "changelog": {
                    "created": {
                        "text": "A versão {version} foi implantada. Você pode conferir as novidades clicando na notificação.",
                        "title": "Novo registro de alterações"
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "text": "A disputa de reclamação relativa ao ponto de interesse {poi} está pendente de verificação pela nossa equipe.",
                        "title": "Reivindicação de disputa pendente de verificação"
                    },
                    "rejected": {
                        "no_reason": "Nenhuma razão dada",
                        "text": "A disputa de reivindicação para o ponto de interesse {poi} foi rejeitada pelo seguinte motivo: {reason}.",
                        "title": "Disputa de reivindicação rejeitada"
                    },
                    "validated": {
                        "text": "A disputa de reivindicação relativa ao ponto de interesse {poi} foi aceita!",
                        "title": "Reivindicação de disputa validada!"
                    }
                },
                "index": {
                    "no_results": "Nenhuma notificação!"
                },
                "institution_employee": {
                    "approved": {
                        "text": "Sua solicitação para ingressar no {poi} foi aprovada",
                        "title": "Solicitação de funcionário aprovada"
                    },
                    "declined": {
                        "text": "Sua solicitação para ingressar no {poi} foi recusada",
                        "title": "Solicitação do funcionário recusada"
                    },
                    "pending": {
                        "text": "{user} solicita registro como funcionário da sua instituição {poi}",
                        "title": "Solicitação de funcionário recebida"
                    },
                    "removed": {
                        "text": "Seu status de funcionário de {poi} foi removido",
                        "title": "Status de funcionário removido"
                    }
                },
                "invitation": {
                    "accepted": {
                        "email": {
                            "text": "Seu convite para ingressar no aplicativo enviado para {credential} foi aceito"
                        },
                        "phone": {
                            "text": "Seu convite para ingressar no aplicativo enviado para {credential} foi aceito"
                        },
                        "title": "Convite aceito"
                    },
                    "pro": {
                        "text": "Seu convite profissional foi enviado para o email {email}",
                        "title": "Convite profissional enviado"
                    }
                },
                "office": {
                    "claim": {
                        "content": {
                            "line_1": "Um ponto de interesse acaba de ser reivindicado.",
                            "line_2": "Clique no botão abaixo para ver os detalhes na interface de administração."
                        },
                        "cta": "Ver reivindicação",
                        "subject": "Nova reivindicação!"
                    },
                    "claim_dispute": {
                        "content": {
                            "line_1": "Um ponto de interesse acaba de ser contestado.",
                            "line_2": "Clique no botão abaixo para ver os detalhes na interface de administração."
                        },
                        "cta": "Ver disputa",
                        "subject": "Nova disputa!"
                    },
                    "report": {
                        "content": {
                            "line_1": "{type} acaba de ser relatado."
                        },
                        "cta": "Ver relatório",
                        "subject": "Novo relatório!",
                        "type": {
                            "App\\Models\\PointOfInterest": "Um ponto de interesse",
                            "App\\Models\\User": "um usuário"
                        }
                    },
                    "support_request": {
                        "content": {
                            "line_1": "Uma nova solicitação de suporte acaba de ser adicionada."
                        },
                        "cta": "Ver solicitação de suporte",
                        "subject": "Nova solicitação de suporte!"
                    }
                },
                "payment": {
                    "proposal": {
                        "text": "Agradeça ao {username} da instituição {poi_title} deixando uma gorjeta",
                        "text_alt": "Agradeça ao concierge da instituição {poi_title} deixando uma gorjeta",
                        "text_alt_2": "Agradeça {username} deixando uma dica",
                        "text_alt_3": "Agradeça ao concierge deixando uma gorjeta",
                        "title": "Deixe uma dica"
                    },
                    "received": {
                        "text": "Você recebeu uma dica {amount} do {payer} da instituição {poi_title}",
                        "text_alt": "Você recebeu uma dica {amount} de {payer}",
                        "text_alt_2": "Você recebeu uma gorjeta {amount}",
                        "title": "Gorjeta recebida"
                    },
                    "thanks": {
                        "text": "{payee} da instituição {poi_title} obrigado pela dica!",
                        "text_alt": "{payee} obrigado pela dica!",
                        "text_alt_2": "Você foi agradecido pela dica",
                        "title": "Obrigado pela dica!"
                    }
                },
                "point_of_interest": {
                    "event": {
                        "expired": {
                            "text": "O evento {title}, incluído em seus roteiros, expirou",
                            "title": "Evento expirado"
                        }
                    },
                    "moderation": {
                        "denied": {
                            "text": "Seu ponto de interesse {title} foi negado após moderação",
                            "title": "Ponto de interesse negado"
                        },
                        "published": {
                            "text": "Seu ponto de interesse {title} foi publicado após moderação",
                            "title": "Ponto de interesse publicado"
                        }
                    }
                },
                "reservation": {
                    "created": {
                        "text": "{butler_name} da instituição {butler_poi_title} fez uma reserva\n                na sua instituição {poi_title}",
                        "text_alt": "Um concierge da instituição {butler_poi_title} fez uma reserva\n                na sua instituição {poi_title}",
                        "text_alt_2": "{butler_name} fez uma reserva\n                na sua instituição {poi_title}",
                        "text_alt_3": "Uma reserva foi feita\n                na sua instituição {poi_title}",
                        "title": "Reserva na sua instituição"
                    }
                },
                "share": {
                    "created": {
                        "excursion": {
                            "text": "{username} da instituição {poi_title} compartilhou um itinerário com você",
                            "text_alt": "{username} compartilhou um itinerário com você",
                            "text_alt_2": "Um itinerário foi compartilhado com você",
                            "title": "Compartilhe um itinerário"
                        },
                        "point_of_interest": {
                            "text": "{username} da instituição {poi_title} compartilhou um ponto de interesse com você",
                            "text_alt": "{username} compartilhou um ponto de interesse com você",
                            "text_alt_2": "Um ponto de interesse foi compartilhado com você",
                            "title": "Compartilhe um ponto de interesse"
                        },
                        "text": "{username} da instituição {poi_title} compartilhou um item com você",
                        "text_alt": "{username} compartilhou um item com você",
                        "text_alt_2": "Um item foi compartilhado com você"
                    }
                },
                "stripe": {
                    "account_requirements": {
                        "text": "Após a verificação da sua conta Stripe, novas informações são solicitadas",
                        "title": "Requisitos da conta Stripe"
                    },
                    "payments_enabled": {
                        "text": "Agora você pode receber pagamentos através do Stripe",
                        "title": "Pagamentos Stripe ativados"
                    }
                },
                "support_request": {
                    "archived": {
                        "text": "Sua solicitação de suporte foi arquivada",
                        "title": "Solicitação de suporte arquivada"
                    },
                    "message": {
                        "text": "Uma resposta foi postada em sua solicitação de suporte",
                        "title": "Mensagem recebida"
                    }
                },
                "traveller_folder": {
                    "approved": {
                        "text": "{username} aprovou sua pasta de viajante",
                        "text_alt": "Um viajante aprovou sua pasta",
                        "title": "Pasta do viajante aprovada"
                    },
                    "archived": {
                        "text": "{username} arquivou sua pasta de viajante",
                        "text_alt": "Um viajante arquivou sua pasta",
                        "title": "Pasta do viajante arquivada"
                    },
                    "created": {
                        "text": "Uma estadia foi registrada em sua conta no hotel {poi_title}.",
                        "title": "Permaneça criado"
                    },
                    "pending": {
                        "text": "{username} registrou em sua conta uma estadia no hotel {poi_title}.\n                Por favor confirme.",
                        "text_alt": "Uma estadia foi registrada em sua conta no hotel {poi_title}.\n                Por favor confirme.",
                        "title": "Fique aguardando confirmação"
                    },
                    "thanks": {
                        "text": "{username} da instituição {poi_title} obrigado pela sua estadia",
                        "text_alt": "Um concierge da instituição {poi_title} obrigado pela sua estadia",
                        "title": "Obrigado pela sua estadia"
                    }
                }
            },
            "nova": {
                "actions": {
                    "bank_transfer": {
                        "transfer": "Faça transferência bancária"
                    },
                    "changelog": {
                        "archive": "Arquivo",
                        "draft": "Rascunho",
                        "publish": "Publicar"
                    },
                    "claim": {
                        "missing_info": "Informações ausentes",
                        "reject": "Rejeitar",
                        "revoke": "Revogar",
                        "validate": "Validar"
                    },
                    "claim_dispute": {
                        "reject": "Rejeitar",
                        "validate": "Validar"
                    },
                    "payment": {
                        "download_invoice": "Baixe o Invoice"
                    },
                    "plan": {
                        "archive": "Arquivo",
                        "draft": "Rascunho",
                        "publish": "Publicar"
                    },
                    "point_of_interest": {
                        "deny": "Negar",
                        "draft": "Rascunho",
                        "publish": "Validar",
                        "submit": "Peça moderação"
                    },
                    "post": {
                        "archive": "Arquivo",
                        "draft": "Rascunho",
                        "publish": "Publicar",
                        "set_coming_soon": "Definido como \"em breve\""
                    },
                    "privacy_policy": {
                        "archive": "Arquivo",
                        "confirmation": {
                            "confirm_button_text": "Publicar",
                            "confirm_text": "Tem certeza de que deseja publicar esta política de privacidade? TODOS os usuários serão solicitados a validá-los novamente!"
                        },
                        "errors": {
                            "resources_count": "Você não pode publicar mais de UMA política privada!"
                        },
                        "publish": "Publicar"
                    },
                    "report": {
                        "process": "Processo",
                        "reject": "Rejeitar"
                    },
                    "support_request": {
                        "answer": "Responder",
                        "archive": "Arquivo",
                        "cancel": "Cancelar",
                        "pending_support": "Esperando por suporte",
                        "pending_user": "Aguardando usuário",
                        "resolve": "Resolver"
                    },
                    "terms_condition": {
                        "archive": "Arquivo",
                        "confirmation": {
                            "confirm_button_text": "Publicar",
                            "confirm_text": "Tem certeza de que deseja publicar estes termos e condições? TODOS os usuários serão solicitados a validá-los novamente!"
                        },
                        "errors": {
                            "resources_count": "Você não pode publicar mais de UM termo e condições!"
                        },
                        "publish": "Publicar"
                    },
                    "user": {
                        "finish": "Concluir tutorial"
                    },
                    "user_credential_verification": {
                        "validate": "Validar"
                    }
                },
                "cards": {
                    "new_excursions": "Novos Itinerários",
                    "new_points_of_interest": "Novos pontos de interesse",
                    "new_users": "Novos usuários"
                },
                "filters": {
                    "app": "Aplicativo",
                    "archived_at_gte": "Arquivado depois",
                    "archived_at_lte": "Arquivado antes",
                    "canceled_at_gte": "Cancelado depois",
                    "canceled_at_lte": "Cancelado antes",
                    "created_at_gte": "Criado depois",
                    "created_at_lte": "Criado antes",
                    "dynamic_translations_enabled": "Traduções dinâmicas ativadas",
                    "extranet": "Extranet",
                    "front": "Frente",
                    "group": "Grupo",
                    "published_at_gte": "Publicado depois",
                    "published_at_lte": "Publicado antes",
                    "reservation_date_gte": "Reserva depois",
                    "reservation_date_lte": "Reserva antes",
                    "resolved_at_gte": "Resolvido depois",
                    "resolved_at_lte": "Resolvido antes",
                    "static_translations_enabled": "Traduções estáticas ativadas",
                    "to_validate": "Para validar"
                },
                "labels": {
                    "institutions": "Instituições",
                    "point_of_interest_categories": "Categorias"
                }
            },
            "opening_hours": {
                "additional_info": "Informações adicionais",
                "content": {
                    "date": "O {date}",
                    "date_range_from": "De {from}",
                    "date_range_from_to": "De {from} a {to}",
                    "date_range_to": "Para {to}",
                    "hours_range_from": "De {from}",
                    "hours_range_from_to": "De {from} a {to}",
                    "hours_range_to": "Para {to}"
                }
            },
            "partners": {
                "customers": {
                    "title": "Nossos clientes"
                },
                "index": {
                    "no_results": "Nenhum parceiro encontrado",
                    "our_last_partners": "Nossos últimos parceiros",
                    "title": "Nossos parceiros"
                },
                "show": {
                    "link": "Visite o site"
                }
            },
            "payments": {
                "checkout": {
                    "cancel": "O pagamento foi cancelado",
                    "custom_amount": "Insira o valor personalizado",
                    "success": "Pagamento realizado com sucesso",
                    "tip_amount": "Valor da gorjeta"
                },
                "index": {
                    "no_results": "Nenhum pagamento encontrado"
                },
                "labels": {
                    "payee": "Beneficiário:",
                    "payment_service": "Pago por"
                },
                "payable": {
                    "reservation": "Reserva #{id}"
                },
                "thanks": {
                    "success": "Obrigado enviado!"
                }
            },
            "pdf": {
                "common": {
                    "generating": "Gerando arquivo PDF...",
                    "waiting": "Por favor, aguarde..."
                },
                "payments": {
                    "period": "Período de {from} a {to}",
                    "title": "Pagamentos recebidos"
                },
                "receipt": {
                    "debited_amount": "Valor debitado",
                    "paid_amount": "Quantidade paga",
                    "payment_date": "Data de pagamento",
                    "payment_method": "Forma de pagamento",
                    "receipt_for": "Recibo de pagamento efetuado a",
                    "receipt_number": "Recibo de pagamento #{id}",
                    "summary": "Resumo"
                },
                "share": {
                    "cta": "Consulte o itinerário",
                    "details": {
                        "line1": "Temos o prazer de compartilhar com você seu itinerário personalizado.",
                        "line2": "Você encontrará o conteúdo do seu itinerário abaixo.",
                        "line3": "Para acessar informações detalhadas (áudio com endereço no idioma local, mapa interativo,\n                fotos, descrição completa...), clique no botão abaixo."
                    }
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "ADMIN": "Acesse a administração",
                        "APP": "Acesse o aplicativo",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas ao acesso",
                        "EXTRANET": "Acesse a seção profissional",
                        "HORIZON": "Horizonte de acesso",
                        "TELESCOPE": "Telescópio de acesso",
                        "VIEW_BUTLER_DASHBOARD": "Ver painel do mordomo",
                        "VIEW_HOTELIER_DASHBOARD": "Ver painel do hoteleiro",
                        "VIEW_MANAGER_DASHBOARD": "Ver painel do gerente",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "Veja o painel do posto de turismo",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "Veja o painel do funcionário do posto de turismo",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "Faça login sem ter que verificar sua credencial"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a logs de atividades",
                        "CREATE": "Criar um registro de atividades",
                        "DELETE": "Excluir um log de atividades",
                        "FORCE_DELETE": "Forçar a exclusão de um log de atividades",
                        "RESTORE": "Restaurar um log de atividades",
                        "UPDATE": "Atualizar um log de atividades",
                        "VIEW": "Consultar um log de atividades",
                        "VIEW_ANY": "Consultar índice de logs de atividades"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a endereços",
                        "CREATE": "Criar um endereço",
                        "DELETE": "Excluir um endereço",
                        "FORCE_DELETE": "Forçar exclusão de um endereço",
                        "RESTORE": "Restaurar um endereço",
                        "UPDATE": "Atualizar um endereço",
                        "VIEW": "Consulte um endereço",
                        "VIEW_ANY": "Consultar índice de endereços"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a instituições de transferência bancária",
                        "VIEW_ANY": "Consultar índice de instituições de transferências bancárias"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "ARCHIVE": "Arquivar um registro de alterações",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a changelogs",
                        "CREATE": "Criar um registro de alterações",
                        "DELETE": "Excluir um registro de alterações",
                        "DRAFT": "Elabore um changelog",
                        "FORCE_DELETE": "Forçar a exclusão de um changelog",
                        "PUBLISH": "Publicar um registro de alterações",
                        "RESTORE": "Restaurar um registro de alterações",
                        "UPDATE": "Atualizar um registro de alterações",
                        "VIEW": "Consultar um changelog",
                        "VIEW_ANY": "Consulte o índice de changelogs"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a disputas de reivindicação",
                        "CREATE": "Criar uma disputa de reivindicação",
                        "DELETE": "Excluir uma disputa de reivindicação",
                        "FORCE_DELETE": "Forçar a exclusão de uma disputa de reivindicação",
                        "REJECT": "Rejeitar uma disputa de reivindicação",
                        "RESTORE": "Restaurar uma disputa de reivindicação",
                        "UPDATE": "Atualizar uma disputa de reivindicação",
                        "VALIDATE": "Validar uma disputa de reivindicação",
                        "VIEW": "Consultar uma disputa de reivindicação",
                        "VIEW_ANY": "Consulte o índice de disputas de reivindicações"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a reivindicações",
                        "CREATE": "Criar uma reclamação",
                        "DELETE": "Excluir uma reivindicação",
                        "FORCE_DELETE": "Forçar a exclusão de uma reivindicação",
                        "MISSING_INFO": "Rejeitar uma reivindicação como informação ausente",
                        "REJECT": "Rejeitar uma reivindicação",
                        "RESTORE": "Restaurar uma reclamação",
                        "REVOKE": "Revogar uma reivindicação",
                        "SHOW_FILE": "Mostrar um arquivo de reivindicação",
                        "UPDATE": "Atualizar uma reivindicação",
                        "VALIDATE": "Validar uma reivindicação",
                        "VIEW": "Consultar uma reivindicação",
                        "VIEW_ANY": "Consultar índice de sinistros"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a países",
                        "USE_ANY": "Use qualquer país"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a moedas",
                        "CREATE": "Criar uma moeda",
                        "DELETE": "Excluir uma moeda",
                        "FORCE_DELETE": "Forçar exclusão de uma moeda",
                        "RESTORE": "Restaurar uma moeda",
                        "UPDATE": "Atualizar uma moeda",
                        "VIEW": "Consulte uma moeda",
                        "VIEW_ANY": "Consultar índice de moedas"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a contas EasyTransac",
                        "HAVE": "Tenha uma conta EasyTransac"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a itinerários",
                        "CREATE": "Crie um itinerário",
                        "DELETE": "Excluir um itinerário",
                        "FORCE_DELETE": "Forçar a exclusão de um itinerário",
                        "RESTORE": "Restaurar um itinerário",
                        "SHARE": "Compartilhe um itinerário",
                        "UPDATE": "Atualizar um itinerário",
                        "VIEW": "Consulte um itinerário",
                        "VIEW_ANY": "Consultar índice de itinerários",
                        "VIEW_KIND_UGOZER_APP": "Veja itinerários com o tipo UgoZER App",
                        "VIEW_KIND_UGOZER_PRO": "Veja itinerários com o tipo UgoZER Pro"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a links entre itinerários e pontos de interesse",
                        "CREATE": "Crie um link entre um itinerário e um ponto de interesse",
                        "DELETE": "Excluir um link entre um itinerário e um ponto de interesse",
                        "FORCE_DELETE": "Forçar a exclusão de um link entre um itinerário e um ponto de interesse",
                        "RESTORE": "Restaurar um link entre um itinerário e um ponto de interesse",
                        "UPDATE": "Atualizar um link entre um itinerário e um ponto de interesse",
                        "VIEW": "Consultar um link entre um itinerário e um ponto de interesse",
                        "VIEW_ANY": "Consultar índice de links entre roteiros e pontos de interesse"
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "APPROVE": "Aprovar uma solicitação de funcionário da instituição",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a funcionários de instituições",
                        "CANCEL": "Cancelar uma solicitação de funcionário da instituição",
                        "CREATE": "Criar um funcionário da instituição",
                        "DECLINE": "Recusar uma solicitação de funcionário da instituição",
                        "DELETE": "Excluir um funcionário da instituição",
                        "FEATURE": "Apresente um funcionário de uma instituição",
                        "FORCE_DELETE": "Forçar a exclusão de um funcionário da instituição",
                        "REMOVE": "Remover um funcionário de uma instituição",
                        "RESTORE": "Restaurar um funcionário da instituição",
                        "UPDATE": "Atualize um funcionário da instituição",
                        "VIEW": "Consulte um funcionário da instituição",
                        "VIEW_ANY": "Consultar índice de colaboradores das instituições"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "ARCHIVE": "Arquivar uma interação",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a interações",
                        "CREATE": "Criar uma interação",
                        "DELETE": "Excluir uma interação",
                        "DRAFT": "Elaborar uma interação",
                        "FORCE_DELETE": "Forçar exclusão de uma interação",
                        "PUBLISH": "Publicar uma interação",
                        "RESTORE": "Restaurar uma interação",
                        "UPDATE": "Atualizar uma interação",
                        "VIEW": "Consultar uma interação",
                        "VIEW_ANY": "Consultar índice de interações"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a idiomas",
                        "CREATE": "Criar um idioma",
                        "DELETE": "Excluir um idioma",
                        "FORCE_DELETE": "Forçar exclusão de um idioma",
                        "RESTORE": "Restaurar um idioma",
                        "UPDATE": "Atualizar um idioma",
                        "VIEW": "Consulte um idioma",
                        "VIEW_ANY": "Consultar índice de idiomas"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a mídia",
                        "CREATE": "Criar uma mídia",
                        "DELETE": "Excluir uma mídia",
                        "FORCE_DELETE": "Forçar exclusão de uma mídia",
                        "RESTORE": "Restaurar uma mídia",
                        "UPDATE": "Atualizar uma mídia",
                        "VIEW": "Consulte uma mídia",
                        "VIEW_ANY": "Consultar índice de mídia"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "Receba uma notificação quando um convite for aceito",
                        "PAYMENT_RECEIVED": "Receba uma notificação quando um pagamento for recebido",
                        "PAYMENT_THANKS": "Receba uma notificação quando um pagamento for confirmado",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "Receba uma notificação quando um evento de ponto de interesse expirar",
                        "RESERVATION_CREATED": "Receba uma notificação quando uma reserva for criada",
                        "SHARE_CREATED": "Receba uma notificação quando um compartilhamento for criado",
                        "SUPPORT_REQUEST_ARCHIVED": "Receba uma notificação quando uma solicitação de suporte for arquivada",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "Receba uma notificação quando uma mensagem for recebida de um suporte\n                solicitar",
                        "TRAVELLER_FOLDER_APPROVED": "Receba uma notificação quando um arquivo de viajante for aprovado",
                        "TRAVELLER_FOLDER_ARCHIVED": "Receba uma notificação quando um arquivo de viajante for arquivado",
                        "TRAVELLER_FOLDER_PENDING": "Receba uma notificação enquanto aguarda a validação de um arquivo viajante",
                        "TRAVELLER_FOLDER_THANKS": "Receba uma notificação para agradecer ao concierge responsável por um viajante de arquivo"
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a parceiros",
                        "CREATE": "Criar um parceiro",
                        "DELETE": "Excluir um parceiro",
                        "FORCE_DELETE": "Forçar a exclusão de um parceiro",
                        "RESTORE": "Restaurar um parceiro",
                        "UPDATE": "Atualizar um parceiro",
                        "VIEW": "Consulte um parceiro",
                        "VIEW_ANY": "Consultar índice de parceiros"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a pagamentos",
                        "CREATE": "Criar um pagamento",
                        "DELETE": "Excluir um pagamento",
                        "FORCE_DELETE": "Forçar a exclusão de um pagamento",
                        "GET_RECEIPT": "Receber um recibo de pagamento",
                        "RECEIVE": "Receber pagamentos",
                        "RESTORE": "Restaurar um pagamento",
                        "THANK": "Receba um agradecimento por um pagamento",
                        "UPDATE": "Atualizar um pagamento",
                        "VIEW": "Consultar um pagamento",
                        "VIEW_ANY": "Consultar índice de pagamentos"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a permissões",
                        "CREATE": "Criar uma permissão",
                        "DELETE": "Excluir uma permissão",
                        "FORCE_DELETE": "Forçar exclusão de uma permissão",
                        "RESTORE": "Restaurar uma permissão",
                        "UPDATE": "Atualizar uma permissão",
                        "VIEW": "Consulte uma permissão",
                        "VIEW_ANY": "Consultar índice de permissões"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "ARCHIVE": "Arquivar um plano de assinatura",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a planos de assinatura",
                        "CREATE": "Crie um plano de assinatura",
                        "DELETE": "Excluir um plano de assinatura",
                        "DRAFT": "Elabore um plano de assinatura",
                        "FORCE_DELETE": "Forçar a exclusão de um plano de assinatura",
                        "PUBLISH": "Publicar um plano de assinatura",
                        "RESTORE": "Restaurar um plano de assinatura",
                        "SUBSCRIBE": "Assine um plano",
                        "UPDATE": "Atualizar um plano de assinatura",
                        "VIEW": "Consulte um plano de assinatura",
                        "VIEW_ANY": "Consultar índice de planos de assinatura"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a categorias de pontos de interesse",
                        "CREATE": "Criar uma categoria de ponto de interesse",
                        "DELETE": "Excluir uma categoria de ponto de interesse",
                        "FORCE_DELETE": "Forçar a exclusão de uma categoria de ponto de interesse",
                        "RESTORE": "Restaurar uma categoria de ponto de interesse",
                        "UPDATE": "Atualizar uma categoria de ponto de interesse",
                        "VIEW": "Consultar uma categoria de ponto de interesse",
                        "VIEW_ANY": "Consultar índice de categorias de pontos de interesse"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "ADMIN_ANY": "Administre qualquer ponto de interesse",
                        "APPLY": "Candidate-se a ser empregado",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a pontos de interesse",
                        "CLAIM": "Reivindique um ponto de interesse",
                        "CONTEST": "Conteste um ponto de interesse",
                        "CONTRIBUTE": "Contribuir para um ponto de interesse",
                        "CREATE": "Crie um ponto de interesse",
                        "DELETE": "Excluir um ponto de interesse",
                        "DENY": "Negar um ponto de interesse",
                        "DRAFT": "Rascunhe um ponto de interesse",
                        "FORCE_DELETE": "Forçar a exclusão de um ponto de interesse",
                        "IMPORT": "Importar pontos de interesse",
                        "MODERATE": "Moderar um ponto de interesse",
                        "PUBLISH": "Publicar um ponto de interesse",
                        "REPORT": "Informar um ponto de interesse",
                        "RESTORE": "Restaurar um ponto de interesse",
                        "SHARE": "Compartilhe um ponto de interesse",
                        "SUBMIT": "Envie um ponto de interesse",
                        "UPDATE": "Atualizar um ponto de interesse",
                        "UPDATE_CERTIFIED": "Atualize o atributo certificado de um ponto de interesse",
                        "VIEW": "Consulte um ponto de interesse",
                        "VIEW_ANY": "Consultar índice de pontos de interesse"
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a categorias de postagens",
                        "CREATE": "Criar uma categoria de postagens",
                        "DELETE": "Excluir uma categoria de postagens",
                        "FORCE_DELETE": "Forçar a exclusão de uma categoria de postagens",
                        "RESTORE": "Restaurar uma categoria de postagens",
                        "UPDATE": "Atualizar uma categoria de postagens",
                        "VIEW": "Consultar uma categoria de postagens",
                        "VIEW_ANY": "Consultar índice de categorias de postagens"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "ARCHIVE": "Arquivar uma postagem",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a postagens",
                        "CREATE": "Criar uma postagem",
                        "DELETE": "Excluir uma postagem",
                        "DRAFT": "Rascunhe uma postagem",
                        "FORCE_DELETE": "Forçar a exclusão de uma postagem",
                        "PUBLISH": "Publicar uma postagem",
                        "RESTORE": "Restaurar uma postagem",
                        "UPDATE": "Atualizar uma postagem",
                        "VIEW": "consulte uma postagem",
                        "VIEW_ANY": "Consultar índice de posts"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "ARCHIVE": "Arquivar uma política de privacidade",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a políticas de privacidade",
                        "CREATE": "Crie uma política de privacidade",
                        "DELETE": "Excluir uma política de privacidade",
                        "DRAFT": "Elabore uma política de privacidade",
                        "FORCE_DELETE": "Forçar a exclusão de uma política de privacidade",
                        "PUBLISH": "Publique uma política de privacidade",
                        "RESTORE": "Restaurar uma política de privacidade",
                        "UPDATE": "Atualizar uma política de privacidade",
                        "VIEW": "Consulte uma política de privacidade",
                        "VIEW_ANY": "Consultar políticas de privacidade"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "Registre-se na seção profissional com qualquer função que tenha essa permissão"
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "ARCHIVE": "Arquivar um relatório",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a relatórios",
                        "CREATE": "Criar um relatório",
                        "DELETE": "Excluir um relatório",
                        "DRAFT": "Elaborar um relatório",
                        "FORCE_DELETE": "Forçar a exclusão de um relatório",
                        "PROCESS_ANY": "Processe qualquer relatório",
                        "PUBLISH": "Publicar um relatório",
                        "REJECT_ANY": "Rejeitar qualquer relatório",
                        "RESTORE": "Restaurar um relatório",
                        "UPDATE": "Atualizar um relatório",
                        "VIEW": "Consultar um relatório",
                        "VIEW_ANY": "Consultar índice de relatórios"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "ARCHIVE": "Arquivar uma reserva",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a reservas",
                        "CANCEL": "Cancelar uma reserva",
                        "CONFIRM": "Confirmar uma reserva",
                        "CREATE": "Criar uma reserva",
                        "DELETE": "Excluir uma reserva",
                        "FORCE_DELETE": "Forçar exclusão de uma reserva",
                        "RESTORE": "Restaurar uma reserva",
                        "UPDATE": "Atualizar uma reserva",
                        "VIEW": "Consultar uma reserva",
                        "VIEW_ANY": "Consultar índice de reservas"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a funções",
                        "CREATE": "Criar uma função",
                        "DELETE": "Excluir uma função",
                        "FORCE_DELETE": "Forçar a exclusão de uma função",
                        "RESTORE": "Restaurar uma função",
                        "UPDATE": "Atualizar uma função",
                        "VIEW": "Consulte uma função",
                        "VIEW_ANY": "Consultar índice de funções"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a compartilhamentos",
                        "CREATE": "Criar um compartilhamento",
                        "DELETE": "Excluir um compartilhamento",
                        "FORCE_DELETE": "Forçar exclusão de um compartilhamento",
                        "RESTORE": "Restaurar um compartilhamento",
                        "UPDATE": "Atualizar um compartilhamento",
                        "VIEW": "consultar uma partilha",
                        "VIEW_ANY": "Consultar índice de ações"
                    },
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a contas Stripe",
                        "HAVE": "Tenha uma conta Stripe"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a assinaturas",
                        "CANCEL": "Cancelar uma assinatura",
                        "CREATE": "Crie uma assinatura",
                        "DELETE": "Excluir uma assinatura",
                        "FORCE_DELETE": "Forçar a exclusão de uma assinatura",
                        "PAY": "Pague uma assinatura",
                        "RESTORE": "Restaurar uma assinatura",
                        "UPDATE": "Atualizar uma assinatura",
                        "VIEW": "Consulte uma assinatura",
                        "VIEW_ANY": "Consultar índice de assinaturas"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas à solicitação de categorias de suporte",
                        "CREATE": "Crie uma categoria de suporte",
                        "DELETE": "Excluir uma categoria de suporte",
                        "FORCE_DELETE": "Forçar a exclusão de uma categoria de suporte",
                        "RESTORE": "Restaurar uma categoria de suporte",
                        "UPDATE": "Atualizar uma categoria de suporte",
                        "VIEW": "Consulte uma categoria de suporte",
                        "VIEW_ANY": "Consultar índice de categorias de suporte"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a mensagens",
                        "CREATE": "Criar uma mensagem",
                        "DELETE": "Excluir uma mensagem",
                        "FORCE_DELETE": "Forçar exclusão de uma mensagem",
                        "RESTORE": "Restaurar uma mensagem",
                        "UPDATE": "Atualizar uma mensagem",
                        "VIEW": "Consulte uma mensagem",
                        "VIEW_ANY": "Consultar índice de mensagens"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "ADD_MESSAGE": "Adiciona uma mensagem",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a solicitações de suporte",
                        "CANCEL": "Cancelar uma solicitação de suporte",
                        "CREATE": "Criar uma solicitação de suporte",
                        "DELETE": "Excluir uma solicitação de suporte",
                        "FORCE_DELETE": "Forçar a exclusão de uma solicitação de suporte",
                        "RESTORE": "Restaurar uma solicitação de suporte",
                        "UPDATE": "Atualizar uma solicitação de suporte",
                        "VIEW": "Consultar um pedido de suporte",
                        "VIEW_ANY": "Consultar índice de solicitações de suporte"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a tags",
                        "CREATE": "Criar uma etiqueta",
                        "DELETE": "Excluir uma etiqueta",
                        "FORCE_DELETE": "Forçar exclusão de uma tag",
                        "RESTORE": "Restaurar uma etiqueta",
                        "UPDATE": "Atualizar uma etiqueta",
                        "VIEW": "Consultar uma etiqueta",
                        "VIEW_ANY": "Consultar índice de tags"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "ARCHIVE": "Arquivar um termos e condições",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas aos termos e condições",
                        "CREATE": "Crie termos e condições",
                        "DELETE": "Excluir termos e condições",
                        "DRAFT": "Elaborar um termos e condições",
                        "FORCE_DELETE": "Forçar exclusão de termos e condições",
                        "PUBLISH": "Publicar termos e condições",
                        "RESTORE": "Restaurar termos e condições",
                        "UPDATE": "Atualizar os termos e condições",
                        "VIEW": "Consulte os termos e condições",
                        "VIEW_ANY": "Consulte termos e condições"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a registros traduzíveis",
                        "REQUIRE_ANY": "Permitir traduzir um campo obrigatório em um idioma que não é necessariamente o inglês"
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a e-mails de viajantes",
                        "CREATE": "Crie um e-mail de viajante",
                        "DELETE": "Excluir um e-mail de viajante",
                        "FORCE_DELETE": "Forçar a exclusão de um e-mail de viajante",
                        "RESTORE": "Restaurar um e-mail de viajante",
                        "UPDATE": "Atualizar um e-mail de viajante",
                        "VIEW": "Consultar um email de viajante",
                        "VIEW_ANY": "Consultar índice de emails de viajantes"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "APPROVE": "Aprovar uma pasta do viajante",
                        "ARCHIVE": "Arquivar uma pasta do viajante",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a pastas do viajante",
                        "CREATE": "Criar uma pasta de viajante",
                        "DECLINE": "Recusar uma pasta do viajante",
                        "DELETE": "Excluir uma pasta de viajante",
                        "FORCE_DELETE": "Forçar a exclusão de uma pasta do viajante",
                        "RESTORE": "Restaurar uma pasta do viajante",
                        "THANK": "Agradecer a um viajante pela estadia",
                        "UPDATE": "Atualizar uma pasta do viajante",
                        "VIEW": "Consulte uma pasta de viajante",
                        "VIEW_ANY": "Consultar índice de pastas de viajantes"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a verificações de credenciais de usuários",
                        "CREATE": "Crie uma verificação de credencial de usuário",
                        "DELETE": "Excluir uma verificação de credencial de usuário",
                        "FORCE_DELETE": "Forçar a exclusão de uma verificação de credencial de usuário",
                        "RESTORE": "Restaurar uma verificação de credencial de usuário",
                        "UPDATE": "Atualizar uma verificação de credencial de usuário",
                        "VIEW": "Consulte uma verificação de credencial de usuário",
                        "VIEW_ANY": "Consultar índice de verificações de credenciais de usuários"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "ADD_CONTACT": "Adicionar um usuário como contato",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a usuários",
                        "CREATE": "Criar um usuário",
                        "DELETE": "Excluir um usuário",
                        "FORCE_DELETE": "Forçar a exclusão de um usuário",
                        "INVITE": "Convide um usuário",
                        "REPORT": "Denunciar um usuário",
                        "RESTORE": "Restaurar um usuário",
                        "TIP": "Dar gorjeta a um usuário",
                        "UPDATE": "Atualizar um usuário",
                        "UPDATE_BANK_ACCOUNT": "Atualizar conta bancária",
                        "VIEW": "Consulte um usuário",
                        "VIEW_ANY": "Consultar índice de usuários",
                        "VIEW_AVATAR": "Exibir o avatar de um usuário",
                        "VIEW_CONTACT_EMAIL": "Exibir o e-mail de contato de um usuário",
                        "VIEW_CONTACT_PHONE": "Exibir o telefone de contato de um usuário",
                        "VIEW_REAL_NAME": "Exibir o nome verdadeiro de um usuário"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas às configurações geoloc do usuário",
                        "CREATE": "Criar uma configuração geoloc do usuário",
                        "DELETE": "Excluir uma configuração geoloc do usuário",
                        "FORCE_DELETE": "Forçar a exclusão de uma configuração geoloc do usuário",
                        "RESTORE": "Restaurar uma configuração geolocal do usuário",
                        "UPDATE": "Atualizar uma configuração geoloc do usuário",
                        "VIEW": "Consulte uma configuração geoloc do usuário",
                        "VIEW_ANY": "Consulte o índice de configurações geoloc do usuário"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas às configurações de notificação do usuário",
                        "CREATE": "Criar uma configuração de notificação do usuário",
                        "DELETE": "Excluir uma configuração de notificação do usuário",
                        "FORCE_DELETE": "Forçar a exclusão de uma configuração de notificação do usuário",
                        "RESTORE": "Restaurar uma configuração de notificação do usuário",
                        "UPDATE": "Atualizar uma configuração de notificação do usuário",
                        "VIEW": "Consulte uma configuração de notificação do usuário",
                        "VIEW_ANY": "Consulte o índice das configurações de notificação do usuário"
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas às configurações de privacidade do usuário",
                        "CREATE": "Crie uma configuração de privacidade do usuário",
                        "DELETE": "Excluir uma configuração de privacidade do usuário",
                        "FORCE_DELETE": "Forçar a exclusão de uma configuração de privacidade do usuário",
                        "RESTORE": "Restaurar uma configuração de privacidade do usuário",
                        "UPDATE": "Atualizar uma configuração de privacidade do usuário",
                        "VIEW": "Consultar uma configuração de privacidade do usuário",
                        "VIEW_ANY": "Consulte o índice de configurações de privacidade do usuário"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "APPROVE_CONTACT": "Aprovar um relacionamento de usuário",
                        "BLOCK_CONTACT": "Bloquear um relacionamento de usuário",
                        "BYPASS_PERMISSIONS": "Ignorar permissões relacionadas a relacionamentos de usuários",
                        "CREATE": "Criar um relacionamento de usuário",
                        "DECLINE_CONTACT": "Recusar um relacionamento de usuário",
                        "DELETE": "Excluir um relacionamento de usuário",
                        "FORCE_DELETE": "Forçar a exclusão de um relacionamento de usuário",
                        "RESTORE": "Restaurar um relacionamento de usuário",
                        "UNBLOCK_CONTACT": "Desbloquear um relacionamento de usuário",
                        "UPDATE": "Atualizar um relacionamento de usuário",
                        "VIEW": "Consulte um relacionamento com o usuário",
                        "VIEW_ANY": "Consultar índice de relacionamento dos usuários"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\AccessEnum": "Acesso",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "Registros de atividades",
                    "App\\Enums\\Permissions\\AddressEnum": "Endereços",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "Instituições de transferências bancárias",
                    "App\\Enums\\Permissions\\ChangelogEnum": "registros de alterações",
                    "App\\Enums\\Permissions\\ClaimEnum": "Reivindicações",
                    "App\\Enums\\Permissions\\CountryEnum": "Países",
                    "App\\Enums\\Permissions\\CurrencyEnum": "Moedas",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "Conta EasyTransac",
                    "App\\Enums\\Permissions\\ExcursionEnum": "Itinerários",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "Ligação entre itinerários e pontos de interesse",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "Funcionários de instituições",
                    "App\\Enums\\Permissions\\InteractionEnum": "Interações",
                    "App\\Enums\\Permissions\\LocaleEnum": "línguas",
                    "App\\Enums\\Permissions\\MediumEnum": "meios de comunicação",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "Receber notificações",
                    "App\\Enums\\Permissions\\PartnerEnum": "Parceiros",
                    "App\\Enums\\Permissions\\PaymentEnum": "Pagamentos",
                    "App\\Enums\\Permissions\\PermissionEnum": "Permissões",
                    "App\\Enums\\Permissions\\PlanEnum": "Planos de assinatura",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "Categorias de pontos de interesse",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "Pontos de interesse",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "Categorias de postagens",
                    "App\\Enums\\Permissions\\PostEnum": "Postagens",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "Políticas de privacidade",
                    "App\\Enums\\Permissions\\RegistrationEnum": "Cadastro",
                    "App\\Enums\\Permissions\\ReportEnum": "Relatórios",
                    "App\\Enums\\Permissions\\ReservationEnum": "Reservas",
                    "App\\Enums\\Permissions\\RoleEnum": "Funções",
                    "App\\Enums\\Permissions\\ShareEnum": "Ações",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "Conta Stripe",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "Assinaturas",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "Categorias de solicitação de suporte",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "Mensagens de suporte",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "Solicitações de suporte",
                    "App\\Enums\\Permissions\\TagEnum": "Tag",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "Termos e Condições",
                    "App\\Enums\\Permissions\\TranslatableEnum": "Registros traduzíveis",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "E-mails de viajantes",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "Pastas do viajante",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "Verificações de credenciais do usuário",
                    "App\\Enums\\Permissions\\UserEnum": "Usuários",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "Configurações geolocal do usuário",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "Configurações de notificação do usuário",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "Configurações de privacidade do usuário",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "relacionamentos de usuários"
                }
            },
            "plans": {
                "archive": {
                    "success": "O(s) plano(s) de assinatura foi(m) arquivado(s) (se elegível)"
                },
                "confirmation": {
                    "benefit_current_subscription": "Você continuará se beneficiando da sua assinatura atual\n            até o final do mês já pago.",
                    "engagement_period": "Esta oferta inclui um período de compromisso de\n            {engagement_period} após o 1º pagamento.",
                    "fee": "Esta oferta está sujeita a uma taxa de {fee_percentage}% e dá direito a\n            receba {tip_percentage}% de suas gorjetas.",
                    "free": "Esta assinatura é gratuita, sujeita à criação de uma conta de coleta de gorjetas.",
                    "free_period": "Você se beneficia de um período gratuito {free_period}.",
                    "free_period_used": "Você já esgotou seu período gratuito e não será\n            poderá beneficiar do {free_period} proposto por esta oferta.",
                    "name": "Você está prestes a assinar a oferta \"{name}\".",
                    "name_launch": "Você está prestes a assinar a oferta de lançamento de \"{name}\".",
                    "no_fee": "Esta oferta está sujeita a uma taxa de 0% e permitirá que você receba suas gorjetas na íntegra.",
                    "please_confirm": "Por favor, confirme sua assinatura clicando no botão abaixo.",
                    "price_summary": "Esta assinatura vai custar-lhe\n            {price} excluindo impostos / {price_incl_taxes} incl. impostos por mês.",
                    "replace_current_subscription": "Esta assinatura substituirá automaticamente sua assinatura atual."
                },
                "draft": {
                    "success": "O(s) plano(s) de assinatura foi(m) elaborado(s) (se elegível)"
                },
                "free_period_ended_in": "Os períodos gratuitos terminam {in}",
                "publish": {
                    "success": "O(s) plano(s) de assinatura foi(m) publicado(s) (se elegível)"
                },
                "set_coming_soon": {
                    "success": "O(s) plano(s) de assinatura foi(m) definido(s) como \"em breve\" (se elegível)"
                },
                "your_current_plan": "Seu plano atual"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "Ao marcar esta caixa, certifico que sou um representante autorizado da\n            estabelecimento reivindicado. Qualquer declaração falsa ou personificação me sujeitará a ações civis e criminais\n            penalidades de acordo com as leis aplicáveis.",
                    "success": "Sua reivindicação foi enviada para verificação."
                },
                "denied": {
                    "for_the_reason": "Negado pelo seguinte motivo",
                    "moderation_again": "Você pode editar o ponto de interesse, que o submeterá novamente para moderação, para que possa ser publicado."
                },
                "deny": {
                    "success": "Ponto(s) de interesse foram negados (se elegíveis)"
                },
                "destroy": {
                    "confirm": "Você realmente deseja excluir este ponto de interesse: {point_of_interest_name}?",
                    "success": "O ponto de interesse foi excluído"
                },
                "draft": {
                    "success": "Os pontos de interesse foram elaborados (se elegíveis)"
                },
                "duplicates": {
                    "check_explanation1": "Para criar o seu ponto de interesse,\n            preencha os campos principais abaixo.",
                    "check_explanation2": "Uma verificação será realizada para evitar\n            entrando em um ponto já existente.",
                    "check_explanation3": "Após a verificação, você poderá concluir\n            as informações do seu ponto e salve-as.",
                    "check_explanation_title": "Verificação",
                    "continue_creation": "Continuar a criação",
                    "found_explanation1": "Um ou mais pontos de interesse correspondentes a\n            seus critérios de entrada já existem.",
                    "found_explanation2": "Se você acha que isso não é uma duplicata,\n            então você pode prosseguir para inserir seu novo ponto de interesse.",
                    "match_percentage": "Corresponder a {percentage}%",
                    "select_duplicate": "Clique num dos pontos de interesse propostos para ver os detalhes",
                    "use_poi": "Utilize este ponto de interesse",
                    "view_poi": "Veja este ponto de interesse"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "Adicione este ponto de interesse a um itinerário",
                    "already_in_excursions": "Este ponto de interesse já faz parte desses roteiros",
                    "create_excursion": "Crie um itinerário",
                    "or_create_from": "Ou crie um novo itinerário a partir deste ponto de interesse",
                    "select_which": "Selecione qual itinerário existente ao qual adicionar o ponto de interesse \"{point_of_interest_name}\"",
                    "success": "O ponto de interesse foi adicionado ao itinerário"
                },
                "form": {
                    "help": {
                        "stars": "Somente se a categoria puder ser anotada com estrelas (como Hotel, Restaurante...)"
                    }
                },
                "import_source": {
                    "DataTourisme": "DataTurismo"
                },
                "index": {
                    "no_results": "Nenhum ponto de interesse encontrado",
                    "no_results_here": "Não há pontos de interesse disponíveis na sua área de pesquisa.",
                    "no_tags": "Nenhuma tag definida",
                    "refresh_delayed": "Aguarde {time_remaining} antes de atualizar",
                    "search": "Pesquise um ponto de interesse",
                    "try_more_filters": "Você pode alterar seus filtros ativos e ampliar sua pesquisa."
                },
                "pages": {
                    "show": {
                        "automatic_update": "Atualização automática",
                        "belongs_to_you": "Pertence a você",
                        "claim_dispute_pending": {
                            "details": "Detalhes da solicitação: {message}",
                            "notice": "Disputa de reivindicação em andamento",
                            "title": "Você tem uma disputa de reivindicação em andamento neste ponto de interesse."
                        },
                        "deletion": {
                            "causes": {
                                "event": "Causa: evento expirado"
                            },
                            "title": "Exclusão pendente"
                        },
                        "dt_required_datatourisme_fields": "Atualizado por {dt_created_by} de {import_source} a {dt_last_update}",
                        "excursions_counter": "Foi adicionado {counter} vezes aos itinerários",
                        "is_geocoding_1": "O ponto de interesse está sendo geolocalizado.",
                        "is_geocoding_2": "Por favor, verifique esta página mais tarde para ver o ponto de interesse\n                no mapa.",
                        "is_refreshing_audio_1": "Um arquivo de áudio está sendo gerado.",
                        "is_refreshing_audio_2": "Por favor, verifique esta página mais tarde para ouvir este áudio.",
                        "not_current_locale": {
                            "change_locale": "Você pode alterar o idioma exibido usando o seletor acima",
                            "incomplete_translation": "Algumas informações deste ponto de interesse (título, descrição e/ou endereço) não estão disponíveis no idioma selecionado.",
                            "missing_translations": "Traduções ausentes"
                        },
                        "opening_hours": {
                            "expired": "O horário de funcionamento expirou",
                            "is_deleting": "O ponto de interesse será excluído em {days} dias",
                            "show": "Veja horário de funcionamento"
                        },
                        "sections": {
                            "audios": "Áudios",
                            "gallery": "Galeria",
                            "logo": "Logotipo",
                            "main_image": "Imagem principal",
                            "map": "Mapa",
                            "media": "meios de comunicação",
                            "poi": "Ponto de interesse",
                            "videos": "Vídeos"
                        },
                        "see_full_page": "Ver página inteira"
                    }
                },
                "print": {
                    "element_not_found": "Elemento para imprimir \"{id}\" não encontrado!"
                },
                "publish": {
                    "success": "Os pontos de interesse foram validados e publicados (se elegíveis)"
                },
                "report": {
                    "choose_reason": "Escolha um motivo para denunciar este ponto de interesse \"{point_of_interest_title}\"",
                    "heading": "Informe um ponto de interesse",
                    "success": "O ponto de interesse foi relatado!"
                },
                "search": {
                    "creator_id": "Mostrar apenas meus pontos?",
                    "no_results": "Nenhum ponto de interesse encontrado...",
                    "tags_mode": {
                        "all": "Todos",
                        "all_help": "Selecione \"todos\" para encontrar pontos de interesse\n                com todas as tags selecionadas.",
                        "one": "Pelo menos um",
                        "one_help": "Selecione \"pelo menos um\", para encontrar pontos de interesse\n                com pelo menos uma das tags selecionadas."
                    }
                },
                "share": {
                    "heading": "Compartilhe este ponto de interesse",
                    "select_which_contacts": "Selecione os contatos com os quais deseja compartilhar o ponto de interesse \"{point_of_interest_title}\"",
                    "select_which_users": "Selecione os usuários com quem deseja compartilhar o ponto de interesse \"{point_of_interest_title}\" ou insira um endereço de e-mail para convidar um novo viajante",
                    "select_which_users_alt": "Digite o endereço de e-mail da pessoa com quem você deseja compartilhar o\n            \"{point_of_interest_title}\" ponto de interesse.",
                    "success": "O ponto de interesse foi compartilhado!"
                },
                "states": {
                    "draft": "Rascunho",
                    "pending": "Pendente",
                    "published": "Publicados"
                },
                "store": {
                    "heading": "Crie um ponto de interesse",
                    "success": "O ponto de interesse foi criado"
                },
                "submit": {
                    "success": "Os pontos de interesse estão com moderação pendente (se elegível)"
                },
                "update": {
                    "alert": "Atualizar este ponto desativará a atualização automática da importação {import_source}",
                    "confirm": "Você realmente deseja atualizar este ponto de interesse: {point_of_interest_name}?",
                    "heading": "Edite este ponto de interesse",
                    "success": "O ponto de interesse foi atualizado"
                }
            },
            "posts": {
                "archive": {
                    "success": "As postagens foram arquivadas (se qualificadas)"
                },
                "draft": {
                    "success": "As postagens foram redigidas (se elegíveis)"
                },
                "publish": {
                    "success": "A(s) postagem(ões) foi(ram) publicada(s) (se elegível)"
                },
                "search": {
                    "type_to_search": "Pesquise na categoria selecionada..."
                }
            },
            "prices": {
                "excl_taxes": "excluindo. impostos",
                "free": "Livre",
                "from": "De",
                "incl_taxes": "incluindo. impostos",
                "per_month": "Por mês",
                "price_excl_taxes": "Preço sem incluir impostos",
                "price_incl_taxes": "Preço inclui impostos",
                "vat_amount": "Valor do IVA",
                "vat_percentage": "Percentagem de IVA"
            },
            "privacy_policies": {
                "archive": {
                    "success": "A(s) política(s) de privacidade foi(m) arquivada(s) (se elegível)"
                },
                "errors": {
                    "not_found": "Nenhuma política de privacidade encontrada!"
                },
                "publish": {
                    "success": "A política de privacidade foi publicada"
                },
                "update": {
                    "acceptation": "Você deve aceitar a política de privacidade para continuar",
                    "new_version": "Uma nova versão da política de privacidade foi publicada",
                    "success": "A política de privacidade foi aceita!"
                }
            },
            "reports": {
                "process": {
                    "success": "O(s) relatório(s) foi(m) processado(s) (se elegível)"
                },
                "reject": {
                    "success": "O(s) relatório(s) foi(m) rejeitado(s) (se elegível)"
                }
            },
            "reservations": {
                "archive": {
                    "confirm": "Você realmente deseja arquivar esta reserva?",
                    "success": "A reserva foi arquivada"
                },
                "cancel": {
                    "confirm": "Você realmente deseja cancelar esta reserva?",
                    "success": "A reserva foi cancelada"
                },
                "confirm": {
                    "confirm": "Deseja realmente confirmar esta reserva?",
                    "success": "A reserva foi confirmada"
                },
                "destroy": {
                    "confirm": "Você realmente deseja excluir esta reserva?",
                    "success": "A reserva foi excluída"
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "butler": "viajante",
                                "poi": "viajante",
                                "traveller": "instituição"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "butler": "Porteiro",
                            "poi": "Ponto de interesse",
                            "reservation": "Reserva",
                            "traveller": "Viajante"
                        }
                    }
                },
                "states": {
                    "archived": "Arquivado",
                    "canceled": "Cancelado",
                    "confirmed": "Confirmado",
                    "pending": "Pendente"
                },
                "store": {
                    "success": "A reserva foi criada"
                },
                "update": {
                    "success": "A reserva foi atualizada"
                }
            },
            "settings": {
                "items": {
                    "about": "Sobre",
                    "geoloc_settings": "Configurações de geolocalização",
                    "notification_settings": "Configurações de notificações",
                    "privacy_setting": "Configurações de privacidade",
                    "profile": "Perfil",
                    "signOut": "sair"
                }
            },
            "shares": {
                "emails": {
                    "select_hint": "Você pode selecionar um e-mail usado anteriormente clicando abaixo:",
                    "space_hint": "Separe por um espaço para compartilhar vários e-mails"
                },
                "index": {
                    "no_results": "Nenhum compartilhamento encontrado!"
                },
                "link": {
                    "instructions": "Copie este link para convidar um viajante e compartilhar este itinerário com ele"
                },
                "show": {
                    "shared_by": "Compartilhado por"
                },
                "type": {
                    "excursions": "Itinerário",
                    "pointsofinterest": "Ponto de interesse"
                }
            },
            "states": {
                "changelog_state": {
                    "archived": "Arquivado",
                    "draft": "Rascunho",
                    "published": "Publicados"
                },
                "claim_dispute_state": {
                    "pending": "Pendente",
                    "rejected": "Rejeitado",
                    "validated": "Validado"
                },
                "claim_state": {
                    "missing-info": "Faltando informação",
                    "pending": "Pendente",
                    "rejected": "Rejeitado",
                    "revoked": "Revogado",
                    "validated": "Validado"
                },
                "payment_state": {
                    "canceled": "Cancelado",
                    "completed": "Concluído",
                    "failed": "Fracassado",
                    "pending": "Pendente",
                    "transferred": "Transferido"
                },
                "plan_state": {
                    "archived": "Arquivado",
                    "coming_soon": "Em breve",
                    "draft": "Rascunho",
                    "published": "Publicados"
                },
                "point_of_interest_state": {
                    "deleted": "Excluído",
                    "denied": "Negado",
                    "draft": "Rascunho",
                    "pending": "Moderação pendente",
                    "published": "Publicados"
                },
                "post_state": {
                    "archived": "Arquivado",
                    "draft": "Rascunho",
                    "published": "Publicados"
                },
                "privacy_policy_state": {
                    "archived": "Arquivado",
                    "draft": "Rascunho",
                    "published": "Publicados"
                },
                "report_state": {
                    "pending": "Pendente",
                    "processed": "Processado",
                    "rejected": "Rejeitado"
                },
                "reservation_state": {
                    "archived": "Arquivado",
                    "canceled": "Cancelado",
                    "confirmed": "Confirmado",
                    "pending": "Pendente"
                },
                "subscription_state": {
                    "active": "Ativo",
                    "canceled": "Cancelado",
                    "pending": "Pagamento Pendente",
                    "suspended": "Suspenso"
                },
                "support_request_state": {
                    "archived": "Arquivado",
                    "canceled": "Cancelado",
                    "pending-support": "Suporte pendente",
                    "pending-user": "Usuário pendente",
                    "resolved": "Resolvido"
                },
                "terms_condition_state": {
                    "archived": "Arquivado",
                    "draft": "Rascunho",
                    "published": "Publicados"
                },
                "traveller_folder_state": {
                    "approved": "Aprovado",
                    "archived": "Arquivado",
                    "declined": "Recusado",
                    "pending": "Pendente"
                },
                "user_credential_verification_state": {
                    "canceled": "Cancelado",
                    "completed": "Concluído",
                    "pending": "Pendente"
                },
                "user_tutorial_state": {
                    "claimed": "Reivindicado",
                    "done": "Feito",
                    "employee-added": "Funcionário adicionado",
                    "pending": "Pendente"
                },
                "user_utm_campaign_state": {
                    "always_on": "Sempre"
                },
                "user_utm_medium_state": {
                    "owned_social": "Rede social"
                },
                "user_utm_source_state": {
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "linkedin": "LinkedIn"
                },
                "users_relationship_state": {
                    "approved": "Aprovado",
                    "blocked": "Bloqueado",
                    "declined": "Recusado",
                    "pending": "Pendente"
                }
            },
            "stripe": {
                "connect": {
                    "account": {
                        "create": {
                            "completed": "Configuração da conta Stripe concluída",
                            "link_invalid": "A conexão com o Stripe falhou, tente novamente"
                        },
                        "must_subscribe": "Você deve estar inscrito para criar sua conta Stripe.",
                        "show": {
                            "account_usage": "Sua conta Stripe permitirá que sua instituição receba pagamentos no UgoZER.",
                            "can_receive_payments": "Você pode receber pagamentos.",
                            "cannot_receive_payments": "Você não consegue receber pagamentos",
                            "fee_percentage": "Uma taxa de {percentage}% será aplicada a cada pagamento que você receber.",
                            "go_to_stripe": "Vá para o painel do Stripe",
                            "has_stripe_account_being_reviewed": "Sua conta está sendo analisada pela Stripe.",
                            "no_account_yet": "Você ainda não tem uma conta Stripe.",
                            "should_complete_stripe_onboarding": "Preencha seu registro no Stripe e forneça as informações solicitadas."
                        }
                    }
                },
                "stripe": "Listra"
            },
            "subscriptions": {
                "activated_at": "Ativação",
                "cancel": "Cancelar",
                "cancelation_success": "Cancelamento levado em consideração",
                "canceled_at": "Cancelamento",
                "confirm_cancel": "Você realmente deseja cancelar sua assinatura?",
                "confirm_cancel_date": "Você se beneficiará de suas vantagens até a data efetiva de cancelamento em {date}.",
                "confirm_cancel_now": "O cancelamento entrará em vigor imediatamente.",
                "expiry": "Termo",
                "invoices": "Faturas",
                "my_current_subscription": "Minha assinatura atual",
                "price_excl_taxes": "Preço sem incluir impostos",
                "price_incl_taxes": "Preço inclui impostos",
                "upcoming_subscription": "Próxima assinatura"
            },
            "support_messages": {
                "show": {
                    "you": "Você",
                    "your_question": "Sua pergunta"
                },
                "store": {
                    "heading": "Adiciona uma mensagem",
                    "success": {
                        "title": "A mensagem foi criada. Contate o suporte responderá dentro de 48 horas."
                    }
                }
            },
            "support_requests": {
                "actions": {
                    "archived": {
                        "success": "A solicitação está arquivada"
                    },
                    "canceled": {
                        "success": "A solicitação foi cancelada"
                    },
                    "pending_support": {
                        "success": "Aguardando suporte para responder"
                    },
                    "pending_user": {
                        "success": "Esperando a resposta do usuário"
                    },
                    "resolved": {
                        "success": "A solicitação foi resolvida"
                    }
                },
                "cancel": {
                    "confirm": "Você realmente deseja cancelar esta solicitação de suporte: {support_request_title}?",
                    "error": "Não foi possível cancelar a solicitação de suporte",
                    "success": "A solicitação de suporte foi cancelada"
                },
                "index": {
                    "no_results": "Você não tem nenhuma solicitação de suporte",
                    "search": "Pesquisar uma solicitação de suporte",
                    "your_requests": "Suas solicitações de suporte"
                },
                "pages": {
                    "create": {
                        "notice": "Dados pessoais"
                    },
                    "index": {
                        "search": "Pesquisar uma solicitação de suporte"
                    },
                    "show": {
                        "sections": {
                            "messages": "Mensagens",
                            "request": "Solicitar"
                        }
                    }
                },
                "show": {
                    "add_request": "Adicione uma nova solicitação de suporte"
                },
                "states": {
                    "archived": "Arquivado",
                    "canceled": "Cancelado",
                    "pending-support": "Suporte pendente",
                    "pending-user": "Usuário pendente",
                    "resolved": "Resolvido"
                },
                "store": {
                    "heading": "Crie uma nova solicitação de suporte",
                    "success": {
                        "title": "A solicitação foi criada. Contate o suporte responderá dentro de 48 horas."
                    }
                }
            },
            "terms_conditions": {
                "archive": {
                    "success": "Os termos e condições foram arquivados (se elegíveis)"
                },
                "errors": {
                    "not_found": "Nenhum termo e condição encontrado!"
                },
                "publish": {
                    "success": "Os termos e condições foram publicados"
                },
                "update": {
                    "acceptation": "Você deve aceitar os termos e condições para continuar",
                    "new_version": "Uma nova versão dos termos e condições foi publicada",
                    "success": "Os termos e condições foram aceitos!"
                }
            },
            "traveller_folders": {
                "messages": {
                    "approve": {
                        "confirm": "Você realmente deseja confirmar esta estadia?",
                        "success": "Fique confirmado!"
                    },
                    "archive": {
                        "confirm": "Você realmente deseja arquivar esta estadia?",
                        "success": "Esta estadia foi arquivada!"
                    },
                    "decline": {
                        "confirm": "Você realmente deseja recusar esta estadia?",
                        "success": "Fique recusado!"
                    }
                },
                "no_results": {
                    "approved": "Nenhuma estadia aprovada",
                    "archived": "Nenhuma estadia arquivada",
                    "declined": "Nenhuma estadia recusada",
                    "pending": "Não há pedidos de estadia"
                },
                "pages": {
                    "form": {
                        "cannot_attach": {
                            "description": "Este usuário existe, mas não pode ser suportado...",
                            "title": "Suporte não é possível"
                        },
                        "poi_empty_option": "Remover ponto de interesse",
                        "poi_label": "Instituição do concierge",
                        "verification": {
                            "description": "Insira o endereço de e-mail do viajante. Se já existir uma conta de viajante, ela será\n                    estar diretamente vinculado, caso contrário você terá que inserir algumas informações adicionais para criar um\n                    novo.",
                            "title": "Verificação"
                        }
                    },
                    "index": {
                        "pending_stays": "Suas estadias pendentes",
                        "search": "Pesquise entre meus viajantes",
                        "travellers_folders": "Pastas de viajantes",
                        "your_stays": "Suas estadias"
                    },
                    "qr_code_1": "Um viajante pode escanear este código QR para obter automaticamente uma pasta do viajante\n            com sua instituição.",
                    "qr_code_2": "Caso o viajante ainda não tenha se cadastrado no UgoZER, será redirecionado para o cadastro\n            página e estará vinculado à sua instituição ao final do cadastro.",
                    "show": {
                        "fields": {
                            "state": "Estado:"
                        },
                        "sections": {
                            "butler": "Concierge relacionado",
                            "folder": "Pasta",
                            "hotel": "Hotel",
                            "institution": "Instituição relacionada",
                            "interactions": "Interações",
                            "invitation": "Convide um viajante",
                            "payments": "Pagamentos",
                            "reservations": "Reservas",
                            "stay": "Ficar",
                            "thanks": "Obrigado",
                            "traveller": "Viajante"
                        }
                    },
                    "wizard": {
                        "choose_an_institution": "Escolha uma instituição",
                        "no_linked_institution": "Você deve estar vinculado a uma instituição antes de poder buscar um viajante."
                    }
                },
                "placeholders": {
                    "butler": "Equipe de recepção"
                },
                "states": {
                    "approved": "Aceitaram",
                    "archived": "Arquivado",
                    "declined": "Recusado",
                    "pending": "Pendente",
                    "undefined": "Indefinido"
                },
                "store": {
                    "success": "A pasta foi criada"
                },
                "tabs": {
                    "approved": "Aprovado",
                    "archived": "Arquivado",
                    "declined": "Recusado",
                    "pending": "Pendente"
                },
                "thank": {
                    "success": "Os agradecimentos foram enviados"
                },
                "update": {
                    "success": "A pasta foi atualizada"
                }
            },
            "tutorials": {
                "extranet": {
                    "begin": {
                        "butler": {
                            "description": "Para permitir que você utilize o aplicativo UgoZER nas melhores condições,\n                    aconselhamos que você siga os seguintes passos que irão guiá-lo nos primeiros passos do\n                    manuseio e parametrização do seu espaço Concierge.",
                            "steps": {
                                "institution": {
                                    "content": "Procure o seu hotel na lista de estabelecimentos através da barra de pesquisa e\n                            os filtros disponíveis na seção esquerda da página. Depois de encontrar o seu\n                            estabelecimento, crie uma inscrição clicando no botão <strong>Candidatar-se</strong>\n                            botão.",
                                    "description": "Link para sua instituição",
                                    "step_next": "reivindicado",
                                    "step_state": "pendente",
                                    "subtitle": {
                                        "link": "Minha instituição",
                                        "part_1": "Link-me para o meu hotel",
                                        "part_2": "Você pode vincular seu hotel diretamente à página"
                                    },
                                    "title": "Instituição"
                                }
                            }
                        },
                        "done": {
                            "description": "Agora você concluiu as várias etapas para configurar o aplicativo UgoZER.",
                            "faq": {
                                "link": "Perguntas frequentes",
                                "part_1": "Se precisar de mais ajuda, você pode encontrar artigos no",
                                "part_2": "seção do aplicativo"
                            },
                            "help": "Você também pode encontrar ajuda contextual através da <strong>Ajuda</strong> presente em algumas páginas.",
                            "title": "Parabéns!"
                        },
                        "hotelier": {
                            "description": "Para permitir que você utilize o aplicativo UgoZER nas melhores condições,\n                    aconselhamos que você siga os seguintes passos que irão guiá-lo nos primeiros passos do\n                    manuseio e parametrização do seu espaço de gerente de hotel.",
                            "steps": {
                                "employees": {
                                    "description": "Gerencie seus concierges",
                                    "step_next": "funcionário adicionado",
                                    "step_state": "reivindicado",
                                    "title": "Concierges"
                                }
                            }
                        },
                        "manager": {
                            "description": "Para permitir que você utilize o aplicativo UgoZER nas melhores condições,\n                    aconselhamos que você siga os seguintes passos que irão guiá-lo nos primeiros passos do\n                    manuseio e configuração de parâmetros do seu espaço de gerenciamento de outros negócios."
                        },
                        "messages": {
                            "claim": "Sua reivindicação foi validada.",
                            "contest": "Sua disputa foi enviada para nossa equipe.",
                            "payment": "Suas informações bancárias foram registradas com sucesso.",
                            "point_of_interest": "O ponto de interesse foi criado, agora você pode reivindicá-lo."
                        },
                        "no_tutorial": {
                            "content": "Nenhum tutorial foi configurado para esta função ou você está conectado como administrador.",
                            "title": "Nenhum tutorial disponível"
                        },
                        "notices": {
                            "add_butler": {
                                "content": "Para utilizar o aplicativo, você deve criar uma conta de portaria para o seu estabelecimento\n                        a partir do qual as informações serão enviadas aos viajantes.",
                                "content_2": "Para ativar a conta de concierge, seu concierge precisará confirmar seu e-mail\n                        endereço e criar sua senha.",
                                "title": "Adicionar um concierge"
                            },
                            "add_employee": {
                                "content": "Para utilizar o aplicativo, você deve criar uma conta de funcionário para o seu estabelecimento\n                        a partir do qual as informações serão enviadas aos viajantes.",
                                "content_2": "Para ativar a conta do funcionário, seu funcionário precisará confirmar seu e-mail\n                        endereço e criar sua senha.",
                                "title": "Adicionar um funcionário"
                            }
                        },
                        "shared": {
                            "actions": {
                                "skip_step": "Pular etapa",
                                "skip_tutorial": "Pular tuturial"
                            },
                            "claims": {
                                "description": "Reivindique suas instituições",
                                "documents": {
                                    "association": {
                                        "identity_piece": "Comprovante de identidade do representante legal no decorrer da\n                                    válido",
                                        "statutes": "Estatutos das associações",
                                        "title": "Se você é uma associação"
                                    },
                                    "company": {
                                        "identity_piece": "Comprovante de identidade do representante legal no decorrer da\n                                    válido",
                                        "kbis": "Um extrato KBIS",
                                        "title": "Se você é uma empresa"
                                    },
                                    "description": "Para reivindicar sua instituição, você precisará preparar o seguinte\n                                documentos:",
                                    "individual": {
                                        "identity_piece": "Uma peça de identificação válida",
                                        "registration_document": "Um certificado de registro da empresa própria",
                                        "title": "Se você é um negócio autônomo"
                                    },
                                    "label": "Documentos",
                                    "title": "Documentos de reivindicação"
                                },
                                "notice": {
                                    "description": "Você pode encontrar esta explicação clicando em <strong>Ajuda</strong>\n                                botão no canto superior direito da lista de pontos de interesse.",
                                    "title": "Observe"
                                },
                                "step_next": "reivindicado",
                                "step_state": "pendente",
                                "title": "Alegar"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "Tem certeza de que deseja pular esta etapa?",
                                    "success": "O passo foi bem ignorado!"
                                },
                                "skip_tutorial": {
                                    "confirm": "Tem certeza de que deseja pular este tutorial?",
                                    "success": "O tutorial foi bem ignorado!"
                                }
                            },
                            "payments": {
                                "description": "Receba dicas",
                                "step_next": "feito",
                                "step_state": "funcionário adicionado",
                                "steps": {
                                    "bank": {
                                        "create": "Clique no botão <strong>Adicionar uma conta</strong> na\n                                    Seção <strong>Conta bancária</strong>"
                                    },
                                    "easytransac": {
                                        "connection": "Clique no link\n                                    <strong>Vá para o painel EasyTransac</strong> e faça login com as credenciais que você\n                                        recebido por e-mail",
                                        "create": "Clique no botão <strong>Criar</strong> no\n                                    Seção <strong>EasyTransac</strong>",
                                        "created": "A conta é criada automaticamente com base nos dados inseridos\n                                    no seu perfil",
                                        "form": "Preencha o formulário no EasyTransac para ativar sua conta de pagamento",
                                        "warning": {
                                            "description": "Dependendo do nível KYC da sua conta EasyTransac, você pode\n                                        ser capaz de receber pagamentos, mas não transferir fundos para um banco normal\n                                        conta. fundos para uma conta bancária normal. Para isso você precisará do nível KYC\n                                        2 e forneça determinados documentos na interface EasyTransac. o EasyTransac\n                                        interface.",
                                            "title": "Nível KYC"
                                        }
                                    },
                                    "my_profile": {
                                        "part_1": "Vá para a página",
                                        "part_2": "Meu perfil"
                                    }
                                },
                                "subtitle_1": "Para que seus concierges recebam gorjetas, você deve criar uma conta\n                            no site do nosso parceiro EasyTransac.",
                                "subtitle_2": "Para fazer isso:",
                                "title": "Pagamento"
                            }
                        },
                        "tourist_office": {
                            "description": "Para permitir que você utilize o aplicativo UgoZER nas melhores condições,\n                    aconselhamos que você siga os seguintes passos que irão guiá-lo nos primeiros passos do\n                    manuseamento e parametrização do seu espaço de Posto de Turismo.",
                            "steps": {
                                "employees": {
                                    "description": "Gerencie seus funcionários",
                                    "step_next": "funcionário adicionado",
                                    "step_state": "reivindicado",
                                    "title": "Funcionários"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "Para permitir que você utilize o aplicativo UgoZER nas melhores condições,\n                    aconselhamos que você siga os seguintes passos que irão guiá-lo nos primeiros passos do\n                    manuseio e parametrização do espaço dos funcionários do seu Posto de Turismo.",
                            "steps": {
                                "institution": {
                                    "content": "Procure o seu posto de turismo na lista de estabelecimentos através da barra de pesquisa\n                            e os filtros disponíveis na seção esquerda da página. Depois de encontrar o seu\n                            estabelecimento, crie uma inscrição clicando no botão <strong>Candidatar-se</strong>\n                            botão.",
                                    "description": "Link para sua instituição",
                                    "step_next": "reivindicado",
                                    "step_state": "pendente",
                                    "subtitle": {
                                        "link": "Minha instituição",
                                        "part_1": "Ligue-me ao meu posto de turismo",
                                        "part_2": "Você pode vincular seu posto de turismo diretamente à página"
                                    },
                                    "title": "Instituição"
                                }
                            }
                        }
                    },
                    "dashboard": {
                        "claim": {
                            "steps": {
                                "claim_form": "Em seguida, siga as instruções no formulário de reclamação",
                                "claim_validation": "A reclamação será então verificada e validada pela nossa equipe",
                                "dont_exist": "Caso não exista, clique no botão <strong>Criar um ponto de interesse\n                        </strong> e preencha o formulário. Uma vez criado o ponto de interesse, vá para o seu\n                        cartão e clique no botão <strong>Reclamar</strong>",
                                "exist": "Caso sua instituição exista, acesse seu arquivo e clique no botão <strong>\n                        Reivindicar</strong>",
                                "filters": "Use os filtros e a busca para encontrar sua instituição",
                                "poi_index": {
                                    "part_1": "Vou ao",
                                    "part_2": "lista de pontos de interesse"
                                }
                            },
                            "subtitle_1": "Você pode reivindicar um (ou mais) estabelecimento(s) para gerenciá-los.",
                            "subtitle_2": "Para fazer isso:",
                            "title": "Reivindicar propriedade e gerenciamento da minha instituição"
                        },
                        "employees": {
                            "link": "Para criar uma solicitação, eles podem acessar a página <strong>Minha instituição</strong>\n                    do seu espaço e preencha o formulário.",
                            "manage": "Nesta página você pode aceitar ou rejeitar funcionários que desejam se vincular ao seu\n                    instituição.",
                            "subtitle": {
                                "part_1": "Você pode gerenciar seus funcionários diretamente pela página",
                                "part_2": "Meus funcionários"
                            },
                            "title": "Gerenciar meus funcionários"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "distance": "<strong>Local:</strong> permite filtrar os pontos de um local específico",
                            "main_title": "Filtros",
                            "subtitle": "Você pode filtrar a lista de itinerários de acordo com os seguintes critérios:",
                            "title": "Filtrar itinerários",
                            "type": {
                                "customized": "<strong>Personalizado:</strong> itinerários criados especificamente para um viajante",
                                "explanation": "<strong>Tipo:</strong> permite filtrar por tipo de itinerário",
                                "pro": "<strong>UgoZER Pro:</strong> itinerários fornecidos por UgoZER",
                                "root": "<strong>Meus modelos:</strong> itinerários modelo"
                            }
                        }
                    },
                    "institution_employee": {
                        "approve": {
                            "content": "Esta ação permite que você aceite uma solicitação de um de seus funcionários para ingressar em um dos\n                    suas instituições.",
                            "main_title": "Aprovar",
                            "title": "Aprovar um funcionário"
                        },
                        "cancel": {
                            "content": "Esta ação permite cancelar a solicitação de convite de um funcionário em caso de\n                    caso de erro, por exemplo.",
                            "main_title": "Cancelar",
                            "title": "Cancelar a inscrição de um funcionário"
                        },
                        "decline": {
                            "content": "Esta ação permite que você negue a solicitação de um de seus funcionários para ingressar em um de seus\n                    instituições.",
                            "main_title": "Recusar",
                            "title": "Recusar um funcionário"
                        },
                        "functioning": {
                            "content": {
                                "invite": "Nesta página você pode convidar funcionários ou aceitar/recusar solicitações de seus\n                        funcionários.",
                                "section": "Esta seção permite que você gerencie os funcionários de suas instituições."
                            },
                            "main_title": "Funcionamento",
                            "title": "Funcionamento da seção de funcionários"
                        },
                        "invite": {
                            "content": {
                                "button": "Ao clicar no botão <strong>Convidar Funcionário</strong> você pode convidar um de seus\n                        funcionários, quer já tenham uma conta UgoZER ou não.",
                                "form": "Para fazer isso, basta seguir as instruções do passo a passo do formulário que aparece.\n                        forma que aparece."
                            },
                            "main_title": "Convidar",
                            "title": "Convide um funcionário"
                        },
                        "remove": {
                            "content": "Esta ação permite remover um usuário de seus funcionários caso um\n                    funcionário sai da sua empresa. caso um funcionário saia da sua empresa.",
                            "main_title": "Remover",
                            "title": "Remover um funcionário"
                        }
                    },
                    "points_of_interest": {
                        "claim": "Alegar",
                        "filters": {
                            "category": "<strong>Categoria:</strong> permite que você escolha uma categoria específica\n                    (Restaurante do hotel...)",
                            "city": "<strong>Cidade:</strong> permite filtrar por uma cidade específica",
                            "country": "<strong>Países:</strong> permite filtrar um ou mais países específicos",
                            "distance": "<strong>Local:</strong> permite filtrar os pontos de um local específico",
                            "main_title": "Filtros",
                            "measure_unit": "<strong>Unidade de medida:</strong> permite alterar a unidade de medida usada para\n                    o filtro de distância e o display",
                            "my_points": "<strong>Mostrar apenas meus pontos:</strong> exibe apenas pontos que você criou ou\n                    reivindicado por você",
                            "postal_code": "<strong>CEP:</strong> permite filtrar por código postal",
                            "radius": "<strong>Limite a um raio:</strong> permite filtrar os pontos em um círculo de\n                    raio",
                            "subtitle": "Pode filtrar a lista de pontos de interesse de acordo com os seguintes critérios:",
                            "tags": "<strong>Refinar:</strong> permite refinar a pesquisa por tags para especificar a categoria.\n                    Também é possível definir se deseja pesquisar pontos com um ou todos ou todos\n                    as tags selecionadas",
                            "title": "Filtrar pontos de interesse"
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "validate": {
                    "success": "A credencial foi validada com sucesso (se elegível)"
                },
                "verify": {
                    "already_verified": "Sua credencial já foi verificada",
                    "expired": "O link expirou, peça um novo",
                    "impossible": "A verificação desta credencial não é mais possível",
                    "not_received": "Não recebeu o link de confirmação?",
                    "sent": {
                        "again": "Envie novamente",
                        "correct": "Corrija a credencial escolhida",
                        "email_1": "Um e-mail será enviado para {email}, caso este endereço ainda não esteja em uso.",
                        "email_2": "Este e-mail contém um link que permitirá que você confirme seu endereço.",
                        "email_3": "Seu e-mail será atualizado assim que você acessar o link.",
                        "refresh": "Você clicou no link? Atualize o aplicativo",
                        "title": "Link enviado",
                        "wait_before": "Aguarde {seconds} segundos antes de enviar novamente"
                    },
                    "success": "Sua credencial foi verificada com sucesso"
                }
            },
            "user_geoloc_settings": {
                "geolocation": {
                    "error": {
                        "allow_browser": "Permita que seu navegador localize você geograficamente nas configurações do seu telefone",
                        "allow_site": "Permitir que o UgoZER localize você geograficamente nas configurações do seu navegador",
                        "intro": "Para visualizar pontos de interesse ao seu redor:",
                        "title": "Falha na geolocalização"
                    },
                    "intro": "A geolocalização permite encontrar os pontos de interesse mais próximos."
                },
                "not_configured": {
                    "body1": "Selecione um local de sua preferência para ver uma seleção de pontos de interesse próximos a ele.",
                    "body2": "Também é possível exibir apenas os pontos de interesse próximos a você.",
                    "do_not_ask_again": "Não me pergunte novamente até que o aplicativo seja reiniciado",
                    "subject": "Você ainda não definiu suas configurações de geolocalização."
                },
                "update": {
                    "success": "As configurações de geolocalização foram atualizadas"
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "As configurações de notificações foram atualizadas"
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "As configurações de privacidade foram atualizadas"
                }
            },
            "users": {
                "butlers": "Concierges",
                "finish": {
                    "success": "Tutorial alterado para estado final"
                },
                "index": {
                    "hint": "Um usuário pode ser encontrado por seu apelido ou ID público.",
                    "no_results": "Nenhum usuário encontrado",
                    "search": "Pesquisar um usuário"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "delete": "Deletar minha conta?",
                            "profile": "Meu perfil"
                        }
                    }
                },
                "profile": {
                    "alerts": {
                        "blocked": {
                            "message": "Você não pode mais interagir com este usuário.",
                            "title": "Este usuário bloqueou você..."
                        },
                        "missing_infos": {
                            "message": "Para aproveitar todas as funcionalidades do aplicativo, preencha os seguintes dados:",
                            "title": "Complete seu perfil"
                        },
                        "pending_validation": {
                            "message": "Este usuário ainda não aceitou sua demanda.",
                            "title": "Validação pendente"
                        }
                    },
                    "avatar": {
                        "defaults": {
                            "female": "Fêmea",
                            "male": "Macho"
                        },
                        "section": {
                            "cta": "Use este avatar",
                            "description": "Você pode selecionar um avatar predefinido na lista abaixo",
                            "title": "Escolhendo um avatar predefinido"
                        },
                        "success": "Seu avatar foi atualizado com sucesso!"
                    },
                    "bank_account_number_missing": "Forneça suas informações bancárias para receber seus pagamentos.",
                    "delete_account": {
                        "easy_transac": "Sua conta EasyTransac não será excluída.",
                        "instructions": "Para confirmar a exclusão definitiva de sua conta, digite a palavra “EXCLUIR” no campo abaixo e clique no botão “Confirmar”.",
                        "success": "Sua conta foi deletada.",
                        "title": "Exclusão de conta",
                        "warning": "A sua conta, bem como todas as informações relacionadas com a sua conta, serão imediatamente eliminadas sem possibilidade de recuperação."
                    },
                    "edit_email": "Edite seu e-mail",
                    "edit_password": "Edite sua senha",
                    "edit_phone": "Edite seu número de telefone",
                    "edit_your_profile": "Edite seu perfil",
                    "email_missing": "Você não preencheu um e-mail verificado.",
                    "fill_country": "Por favor especifique seu país",
                    "form": {
                        "poi_label": "Instituição vinculada"
                    },
                    "manage_my_subscription": "Gerenciar minha assinatura",
                    "password_missing": "Você não preencheu uma senha.",
                    "phone_missing": "Você não preencheu um número de telefone verificado",
                    "preferences": "Preferências",
                    "public_id": "Ou use este identificador:",
                    "qr_code": "Escaneie-me para ver meu perfil",
                    "security": "Segurança",
                    "share_profile": "Compartilhe seu perfil",
                    "subscribe_to_receive_payments": "Você deve estar inscrito para receber pagamentos.",
                    "unnamed": "Sem nome",
                    "your_personal_information": "Suas informações pessoais"
                },
                "report": {
                    "choose_reason": "Escolha um motivo para denunciar este usuário \"{user_name}\"",
                    "heading": "Denunciar um usuário",
                    "success": "O usuário foi denunciado!"
                },
                "search": {
                    "no_results": "Usuários não encontrados"
                },
                "send_link": {
                    "error": "Não é possível enviar o link. O usuário tem um email?",
                    "success": "O link para a inscrição foi enviado"
                },
                "update": {
                    "success": "O perfil foi atualizado"
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "Nenhum contato encontrado",
                    "pending_requests": "Seus contatos pendentes"
                },
                "messages": {
                    "add": {
                        "confirm": "Você realmente deseja adicionar este contato?",
                        "error": "Ocorre um erro durante a solicitação de contato...",
                        "success": "Solicitação de contato realizada com sucesso!"
                    },
                    "approve": {
                        "confirm": "Você realmente deseja aceitar esta solicitação de contato?",
                        "success": "Pedido de contato aceito!"
                    },
                    "block": {
                        "confirm": "Você realmente deseja bloquear este usuário?",
                        "success": "Este contato foi bloqueado!"
                    },
                    "decline": {
                        "confirm": "Você realmente deseja recusar esta solicitação de contato?",
                        "success": "Solicitação de contato recusada!"
                    },
                    "unblock": {
                        "confirm": "Você realmente deseja desbloquear este usuário?",
                        "success": "Este contato foi desbloqueado!"
                    }
                },
                "no_results": {
                    "approved": "Nenhum contato aprovado",
                    "blocked": "Nenhum contato bloqueado",
                    "pending": "Nenhuma solicitação de contato pendente",
                    "sent": "Nenhuma solicitação de contato enviada"
                },
                "search": {
                    "no_results": "Nenhum contato encontrado"
                },
                "tabs": {
                    "approved": "Aprovado",
                    "blocked": "Bloqueado",
                    "pending": "Pendente",
                    "sent": "Enviado"
                }
            }
        },
        "validation": {
            "accepted": "O {attribute} deve ser aceito.",
            "active_url": "O {attribute} não é um URL válido.",
            "after": "O {attribute} deve ser uma data depois de {date}.",
            "after_or_equal": "O {attribute} deve ser uma data posterior ou igual a {date}.",
            "alpha": "O {attribute} só pode conter letras.",
            "alpha_dash": "O {attribute} pode conter apenas letras, números, travessões e sublinhados.",
            "alpha_num": "O {attribute} só pode conter letras e números.",
            "array": "O {attribute} deve ser uma matriz.",
            "attributes": {
                "accounting_file": "arquivo de contabilidade",
                "actions": "ações",
                "activated_at": "ativado em",
                "active": "ativo",
                "additional_information": "Informações adicionais",
                "address": "endereço",
                "addresses": "endereços",
                "admin_message": "mensagem",
                "administrative_area": "área administrativa",
                "amount": "quantia",
                "app": "aplicativo",
                "app_fees": "tarifas",
                "approved_at": "aprovado em",
                "archived_at": "Arquivado em",
                "association_legal_representative_identity_piece": "comprovante de identidade do representante legal",
                "association_statutes": "estatutos",
                "attached_files": "Arquivos anexados",
                "audio": "áudio",
                "audio_refreshing": "atualização de áudio",
                "audios": "áudios",
                "authenticated_once": "autenticado uma vez",
                "auto_renewal_enabled": "renovação automática ativada",
                "avatar": "avatar",
                "bank_account": "conta bancária",
                "bank_account_number": "número da conta de banco",
                "bank_bic": "BIC",
                "bank_city": "Cidade bancária",
                "bank_country": "País do banco",
                "bank_file": "arquivo bancário",
                "bank_iban": "IBAN",
                "bank_name": "nome do banco",
                "bank_payee": "Beneficiário",
                "bank_transfer": "transferência bancária",
                "bank_transfer_institutions": "instituição de transferências bancárias",
                "bank_transfer_reason": "Motivo da transferência",
                "butler": "porteiro",
                "butler_point_of_interest": "instituição de concierge",
                "canceled_at": "Cancelado em",
                "categories": "categorias",
                "category": "categoria",
                "category_id": "Categoria ID",
                "causer": "causador",
                "causer_id": "ID do causador",
                "causer_type": "tipo de causador",
                "certified": "certificado",
                "city": "cidade",
                "claim_confirmation": "confirmação de reivindicação",
                "code": "código",
                "collection_name": "nome da coleção",
                "communication_consent": "Comunicações personalizadas",
                "communication_consent_alt": "Comunicações?",
                "company_legal_representative_identity_piece": "comprovante de identidade do representante legal",
                "company_registration_document": "Documento de registro",
                "company_statutes": "estatutos",
                "company_type": "Tipo de companhia",
                "completed_at": "concluído em",
                "contact": "contato",
                "contact_email": "email de contato",
                "contact_phone": "telefone de contato",
                "content": "contente",
                "contestant": "concorrente",
                "contestant_company_type": "Tipo de companhia",
                "contestant_email": "e-mail",
                "contestant_first_name": "primeiro nome",
                "contestant_last_name": "sobrenome",
                "contestant_message": "mensagem",
                "contestant_phone": "telefone",
                "conversions_disk": "disco de conversão",
                "coords": "coordenadas",
                "countries": "países",
                "countries_codes": "países",
                "country": "país",
                "country_code": "Código do país",
                "created": "criada",
                "created_at": "criado em",
                "creator": "O Criador",
                "credential": "credencial",
                "currency": "moeda",
                "current_password": "Senha atual",
                "custom_amount": "valor personalizado",
                "custom_properties": "propriedades personalizadas",
                "customers_qty": "quantidade de clientes",
                "date": "data",
                "dates": "datas",
                "days": "dias",
                "declined_at": "diminuiu em",
                "deleted_at": "excluído em",
                "deletion_confirmation": "confirmação de exclusão",
                "denial_reason": "motivo da negação",
                "dependent_locality": "localidade dependente",
                "description": "descrição",
                "details": "detalhes",
                "disk": "disco",
                "display": "mostrar",
                "displayed_name": "nome exibido",
                "distance": "distância",
                "documents": "documentos",
                "dt_created_by": "Criado por",
                "dt_last_update": "Última atualização do DataTourisme",
                "dynamic_translations_enabled": "traduções dinâmicas ativadas",
                "email": "e-mail",
                "emails_must_different": "Ambas as contas não podem ter o mesmo endereço de e-mail.",
                "employee": "funcionário",
                "end_date": "data final",
                "engaged_until": "engajado até",
                "engagement_period": "período de noivado",
                "event": "evento",
                "excursion": "itinerário",
                "existing_user": "Usuário existente",
                "expirable": "expirável",
                "expired_at": "expirou em",
                "expired_on": "data final da estadia",
                "expiry": "expiração",
                "expiry_date": "Data de validade",
                "extranet": "extranet",
                "fallback_locale": "local alternativo",
                "favorite": "itinerário favorito",
                "featured": "apresentou",
                "file": "arquivo",
                "file_name": "nome do arquivo",
                "filter": "filtro",
                "financial": "financeiro",
                "first_name": "primeiro nome",
                "formatted_address": "endereço formatado",
                "free_period": "período livre",
                "free_period_ended_at": "período gratuito terminou às",
                "front": "frente",
                "gallery": "galeria",
                "gdpr": "RGPD",
                "general": "Em geral",
                "generated_conversions": "conversões geradas",
                "gross_amount": "valor bruto",
                "has_employees": "tem funcionários",
                "hour": "hora",
                "hours": "horas",
                "id": "eu ia",
                "image": "imagem",
                "images": "imagens",
                "import_source": "fonte de importação",
                "import_synchronizing": "Ativar atualização automática",
                "individual_first_identity_piece": "peça de identidade",
                "individual_registration_document": "Documento de registro",
                "individual_second_identity_piece": "segunda peça de identidade",
                "information": "Informação",
                "institution": "instituição",
                "institution_address": "endereço da instituição",
                "institution_id": "instituição",
                "institution_title": "título da instituição",
                "interaction_type": "tipo de interação",
                "invitation": "convite",
                "invitation_accepted": "convite aceito",
                "invitation_status": "estado do convite",
                "invited_at": "convidado em",
                "invited_by": "convidado por",
                "invitees": "convidados",
                "is_geocoding": "geolocalização em andamento",
                "iso_code": "código ISO",
                "items": "Unid",
                "key": "chave",
                "kind": "tipo",
                "label": "rótulo",
                "last_geoloc": "última geolocalização",
                "last_logged_at": "último logado em",
                "last_login_ip": "IP do último login",
                "last_name": "sobrenome",
                "last_validated_at": "última validação",
                "lat": "latitude",
                "latitude": "latitude",
                "line1": "Endereço Linha 1",
                "line2": "endereço linha 2",
                "link": "link",
                "lng": "longitude",
                "locale": "linguagem",
                "login": "conexão",
                "logo": "logotipo",
                "longitude": "longitude",
                "manipulations": "manipulações",
                "max_amount_per_checkout": "valor máximo por checkout",
                "measurement_system": "sistema de medida",
                "media": "meios de comunicação",
                "message": "mensagem",
                "metadata": "metadados",
                "mime_type": "tipo MIME",
                "min_amount_per_checkout": "valor mínimo por checkout",
                "misc": "Diversos",
                "model": "registro",
                "model_type": "tipo de registro",
                "name": "nome",
                "net_amount": "valor líquido",
                "new_password": "Nova Senha",
                "new_password_confirmation": "Nova confirmação de senha",
                "next_payment_at": "Próximo pagamento",
                "nickname": "apelido",
                "not_specified": "Não especificado",
                "not_validated_activities": "atividades não validadas",
                "notes": "Outras informações",
                "opening_hours": "Horário de funcionamento",
                "order_column": "ordem",
                "origin": "origem",
                "owner": "proprietário",
                "owner_email": "e-mail",
                "owner_first_name": "primeiro nome",
                "owner_last_name": "sobrenome",
                "owner_phone": "telefone",
                "password": "senha",
                "password_confirmation": "ConfirmaÇão Da Senha",
                "passwords_must_different": "Ambas as contas não podem ter a mesma senha.",
                "payable": "a pagar",
                "payee": "beneficiário",
                "payee_country": "país",
                "payee_email": "e-mail",
                "payee_id": "beneficiário",
                "payee_name": "nome do beneficiário",
                "payee_phone": "telefone",
                "payer": "pagador",
                "payer_country": "país",
                "payer_email": "e-mail",
                "payer_id": "pagador",
                "payer_name": "nome do pagador",
                "payer_phone": "telefone",
                "payment_received": "pagamento recebido",
                "payment_service": "serviço de pagamento",
                "payment_service_account_id": "ID da conta de pagamento",
                "payment_service_transaction_id": "transação de pagamento de identidade",
                "payment_thanks": "pagamento obrigado",
                "payment_type": "tipo de pagamento",
                "period": "período",
                "phone": "telefone",
                "plan": "plano",
                "point_of_interest": "ponto de interesse",
                "point_of_interest_categories": "categorias",
                "point_of_interest_category": "categoria",
                "point_of_interest_event_expired": "Evento expirado",
                "points_of_interest": "pontos de interesse",
                "points_of_interest_count": "contagem de pontos de interesse",
                "post_category": "categoria de postagem",
                "price": "preço",
                "price_excluding_taxes": "preço sem impostos",
                "prices": "preços",
                "priority": "prioridade",
                "privacy_policy": "política de Privacidade",
                "privacy_policy_accepted": "política de privacidade aceita",
                "privacy_policy_version": "versão da política de privacidade",
                "private": "privado",
                "private_title": "título privado",
                "pro_file": "perfil",
                "profile_picture": "Foto do perfil",
                "properties": "propriedades",
                "properties_comparison": "propriedades atualizadas",
                "provider": "fornecedor",
                "public": "público",
                "public_id": "identificação pública",
                "published_at": "publicado em",
                "quantity": "quantidade",
                "quitted_at": "desistiu em",
                "radius": "raio",
                "rates": "cotações",
                "reason_details": "detalhes",
                "reason_type": "razão",
                "recipient": "destinatário",
                "recipients": "destinatários",
                "recipients_alt": "viajantes registrados",
                "remember": "lembre de mim",
                "removed_at": "removido em",
                "reports": "relatórios",
                "requested_at": "solicitado em",
                "reservation_created": "reserva criada",
                "reservation_date": "data",
                "reservation_time": "tempo",
                "resolved_at": "Resolvido em",
                "responsive_images": "imagens responsivas",
                "roles": "funções",
                "room_number": "número do quarto",
                "scout_searchable": "indexável",
                "searchable": "pesquisável",
                "sender": "remetente",
                "senders": "remetentes",
                "service": "serviço",
                "settings": "configurações",
                "share_created": "compartilhamento criado",
                "shareable": "compartilhável",
                "show_contact_email": "Visibilidade do e-mail de contato",
                "show_contact_phone": "Visibilidade do telefone de contato",
                "show_profile_picture": "visibilidade do avatar",
                "show_real_name": "Visibilidade do nome e sobrenome",
                "siret": "SIRET",
                "size": "tamanho",
                "source": "fonte",
                "source_id": "ID de origem",
                "specified": "Especificadas",
                "starable": "notado com estrelas",
                "stars": "estrelas",
                "start_date": "data de início",
                "state": "estado",
                "states": "estados",
                "static_translations_enabled": "traduções estáticas ativadas",
                "status": "status",
                "subject": "assunto",
                "subject_id": "ID do assunto",
                "subject_type": "tipo de assunto",
                "summary": "resumo",
                "support_category": "categoria",
                "support_messages_count": "contagem de mensagens de suporte",
                "support_request": "solicitação de suporte",
                "support_request_archived": "Solicitação de suporte arquivada",
                "support_request_message_received": "Resposta recebida em uma Solicitação de Suporte",
                "suspended_at": "suspenso em",
                "tag": "Marcação",
                "tags": "Tag",
                "target": "alvo",
                "terms_condition_accepted": "termos e condições aceitos",
                "terms_condition_version": "versão dos termos e condições",
                "terms_conditions": "termos e Condições",
                "thanked": "agradeceu",
                "thanked_at": "data de agradecimento",
                "thanks": "obrigado",
                "thanks_message": "mensagem de agradecimento",
                "thanks_sent": "obrigado enviado",
                "tipping_fees": "taxas de gorjeta",
                "tips_enabled": "dicas ativadas",
                "title": "título",
                "total_amount": "montante total",
                "traveller": "viajante",
                "traveller_folder": "pasta de viajante",
                "traveller_folder_approved": "pasta de viagem aprovada",
                "traveller_folder_archived": "pasta do viajante arquivada",
                "traveller_folder_pending": "pasta do viajante pendente",
                "traveller_folder_thanks": "obrigado pela estadia",
                "tutorial_state": "Estado do tutorial",
                "type": "tipo",
                "updated_at": "atualizado em",
                "user": "do utilizador",
                "user_id": "do utilizador",
                "user_report": "usuário (que reporta)",
                "users": "Usuários",
                "utm_campaign": "Campanha",
                "utm_medium": "Médio",
                "utm_settings": "Parâmetros UTM",
                "utm_source": "Fonte",
                "uuid": "uuid",
                "validated_activities": "atividades validadas",
                "validated_at": "validado em",
                "value": "valor",
                "vat": "cuba",
                "vat_rate": "taxa do IVA",
                "version": "versão",
                "video": "vídeo",
                "videos": "vídeos",
                "website": "local na rede Internet",
                "zip": "fecho eclair"
            },
            "before": "O {attribute} deve ser uma data anterior a {date}.",
            "before_or_equal": "O {attribute} deve ser uma data anterior ou igual a {date}.",
            "between": {
                "array": "O {attribute} deve ter entre os itens {min} e {max}.",
                "file": "O {attribute} deve estar entre {min} e {max} kilobytes.",
                "numeric": "O {attribute} deve estar entre {min} e {max}.",
                "string": "O {attribute} deve estar entre os caracteres {min} e {max}."
            },
            "boolean": "O campo {attribute} deve ser verdadeiro ou falso.",
            "confirmed": "O {attribute} não corresponde.",
            "currency_scale": "O número de dígitos de {attribute} após o ponto decimal não deve exceder {scale}.",
            "current_password": "O {attribute} não corresponde à sua senha.",
            "custom": {
                "attribute-name": {
                    "rule-name": "mensagem personalizada"
                },
                "h-captcha-response": {
                    "HCaptcha": "Erro CAPTCHA! tente novamente mais tarde ou entre em contato com o administrador do site.",
                    "required": "Verifique se você não é um robô."
                }
            },
            "date": "O {attribute} não é uma data válida.",
            "date_equals": "O {attribute} deve ser uma data igual a {date}.",
            "date_format": "O {attribute} não corresponde ao formato {format}.",
            "different": "O {attribute} e {other} devem ser diferentes.",
            "digits": "O {attribute} deve ter {digits} dígitos.",
            "digits_between": "O {attribute} deve estar entre {min} e {max} dígitos.",
            "dimensions": "O {attribute} tem dimensões de imagem inválidas.",
            "distinct": "O campo {attribute} tem um valor duplicado.",
            "email": "O {attribute} deve ser um endereço de e-mail válido.",
            "ends_with": "O {attribute} deve terminar com um dos seguintes: {values}.",
            "exists": "O {attribute} selecionado é inválido.",
            "file": "O {attribute} deve ser um arquivo.",
            "filled": "O campo {attribute} deve ter um valor.",
            "gt": {
                "array": "O {attribute} deve ter mais de {value} itens.",
                "file": "O {attribute} deve ser maior que {value} kilobytes.",
                "numeric": "O {attribute} deve ser maior que {value}.",
                "string": "O {attribute} deve ser maior que {value} caracteres."
            },
            "gte": {
                "array": "O {attribute} deve ter itens {value} ou mais.",
                "file": "O {attribute} deve ser maior ou igual a {value} kilobytes.",
                "numeric": "O {attribute} deve ser maior ou igual a {value}.",
                "string": "O {attribute} deve ser maior ou igual a {value} caracteres."
            },
            "image": "O {attribute} deve ser uma imagem.",
            "in": "O {attribute} selecionado é inválido.",
            "in_array": "O campo {attribute} não existe em {other}.",
            "integer": "O {attribute} deve ser um número inteiro.",
            "ip": "O {attribute} deve ser um endereço IP válido.",
            "ipv4": "O {attribute} deve ser um endereço IPv4 válido.",
            "ipv6": "O {attribute} deve ser um endereço IPv6 válido.",
            "json": "O {attribute} deve ser uma string JSON válida.",
            "lt": {
                "array": "O {attribute} deve ter menos de {value} itens.",
                "file": "O {attribute} deve ser menor que {value} kilobytes.",
                "numeric": "O {attribute} deve ser menor que {value}.",
                "string": "O {attribute} deve ter menos de {value} caracteres."
            },
            "lte": {
                "array": "O {attribute} não deve ter mais de {value} itens.",
                "file": "O {attribute} deve ser menor ou igual a {value} kilobytes.",
                "numeric": "O {attribute} deve ser menor ou igual a {value}.",
                "string": "O {attribute} deve ser menor ou igual a {value} caracteres."
            },
            "luhn": "{attribute} é um número inválido.",
            "max": {
                "array": "O {attribute} não pode ter mais de {max} itens.",
                "file": "O {attribute} não pode ser maior que {max} kilobytes.",
                "height": "{attribute} não deve exceder {max} pixels de altura.",
                "numeric": "O {attribute} não pode ser maior que {max}.",
                "string": "O {attribute} não pode ser maior que {max} caracteres.",
                "width": "{attribute} não deve exceder {max} pixels de largura."
            },
            "max_digits": "{attribute} não deve exceder {max} dígitos.",
            "mimes": "O {attribute} deve ter um tipo válido.",
            "mimetypes": "O {attribute} deve ter um tipo válido.",
            "min": {
                "array": "O {attribute} deve ter pelo menos {min} itens.",
                "file": "O {attribute} deve ter pelo menos {min} kilobytes.",
                "numeric": "O {attribute} deve ser pelo menos {min}.",
                "string": "O {attribute} deve ter pelo menos {min} caracteres."
            },
            "min_digits": "{attribute} deve ter pelo menos {min} dígitos.",
            "not_in": "O {attribute} selecionado é inválido.",
            "not_regex": "O formato {attribute} é inválido.",
            "notification_setting": "O formato {attribute} é inválido (o formato esperado é array[bool]).",
            "numeric": "O {attribute} deve ser um número.",
            "password": "A senha está incorreta.",
            "phone": "O {attribute} deve ser um número de telefone válido.",
            "present": "O campo {attribute} deve estar presente.",
            "prohibited_if": "O campo {attribute} é proibido quando {other} é {value}.",
            "prohibited_unless": "O campo {attribute} é proibido a menos que {other} esteja em {values}.",
            "real_name_characters": ":{attribute} só pode conter letras, espaços, pontos,\n        hífens ou apóstrofos.",
            "regex": "O formato {attribute} é inválido.",
            "required": "O campo {attribute} é obrigatório.",
            "required_if": "O campo {attribute} é obrigatório quando {other} é {value}.",
            "required_unless": "O campo {attribute} é obrigatório, a menos que {other} esteja em {values}.",
            "required_with": "O campo {attribute} é obrigatório quando {values} está presente.",
            "required_with_all": "O campo {attribute} é obrigatório quando {values} está presente.",
            "required_without": "O campo {attribute} é obrigatório quando {values} não está presente.",
            "required_without_all": "O campo {attribute} é obrigatório quando nenhum dos {values} está presente.",
            "same": "O {attribute} e o {other} devem corresponder.",
            "same_currency": "Todos os itens devem usar a mesma moeda.",
            "size": {
                "array": "O {attribute} deve conter itens {size}.",
                "file": "O {attribute} deve ter {size} quilobytes.",
                "numeric": "O {attribute} deve ser {size}.",
                "string": "O {attribute} deve ter {size} caracteres."
            },
            "starts_with": "O {attribute} deve começar com um dos seguintes: {values}.",
            "string": "O {attribute} deve ser uma string.",
            "tag_same_poi_category": "{attribute} devem todos pertencer à mesma categoria!",
            "timezone": "O {attribute} deve ser uma zona válida.",
            "unique": "O {attribute} já foi tomado.",
            "uploaded": "O {attribute} falhou ao carregar.",
            "url": "O formato {attribute} é inválido.",
            "uuid": "O {attribute} deve ser um UUID válido."
        }
    },
    "zh": {
        "The {attribute} must be at least {length} characters and contain at least one number.": " {attribute} 必须至少包含 {length} 个字符并且至少包含一个数字。",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": " {attribute} 必须至少包含 {length} 个字符，并且至少包含一个特殊字符和一个数字。",
        "The {attribute} must be at least {length} characters and contain at least one special character.": " {attribute} 必须至少包含 {length} 个字符，并且至少包含一个特殊字符。",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": " {attribute} 必须至少包含 {length} 个字符，并且至少包含一个大写字符和一个数字。",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": " {attribute} 必须至少包含 {length} 个字符，并且至少包含一个大写字符和一个特殊字符。",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": " {attribute} 必须至少包含 {length} 个字符，并且至少包含一个大写字符、一个数字和一个特殊字符。",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": " {attribute} 必须至少包含 {length} 个字符，并且至少包含一个大写字符。",
        "The {attribute} must be at least {length} characters.": " {attribute} 必须至少包含 {length} 个字符。",
        "The {attribute} must contain at least one letter.": " {attribute} 必须至少包含一个字母。",
        "The {attribute} must contain at least one number.": " {attribute} 必须至少包含一个数字。",
        "The {attribute} must contain at least one symbol.": " {attribute} 必须至少包含一个符号。",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": " {attribute} 必须至少包含一个大写字母和一个小写字母。",
        "auth": {
            "failed": "这些凭证与我们的记录不符。您确定您没有将此电子邮件用于 {type} 帐户吗？",
            "throttle": "登录尝试次数过多。请在 {seconds} 秒后重试。",
            "throttle_cascade": "登录尝试次数过多。请在 {remaining} 中重试。"
        },
        "documents": {
            "display": {
                "description": "以 A6 格式显示，并链接 QRCode 和您所在机构的徽标。",
                "title": "显示-A6卡"
            },
            "qrcode": {
                "description": "仅 QRCode 就可以让旅行者直接链接到您的机构。",
                "filename": "二维码",
                "title": "链接二维码"
            }
        },
        "enums": {
            "App\\Enums\\ActivityLogEventEnum": {
                "created": "已创建",
                "deleted": "已删除",
                "updated": "更新",
                "validated": "已验证"
            },
            "App\\Enums\\BinaryChoiceEnum": [
                "被拒绝",
                "公认"
            ],
            "App\\Enums\\Claim\\ClaimCompanyTypeEnum": {
                "association": "协会（或基金会）",
                "company": "公司（公共/私人）",
                "individual": "个人",
                "undefined": "不明确的"
            },
            "App\\Enums\\Claim\\ClaimCountriesEnum": {
                "DE": "德国",
                "ES": "西班牙",
                "FR": "法国",
                "IT": "意大利",
                "TH": "泰国",
                "undefined": "不明确的"
            },
            "App\\Enums\\ContextsEnum": {
                "city_hall": "市政府",
                "hotel": "酒店",
                "manager": "经理",
                "tourist_office": "旅游办事处",
                "traveller": "游客"
            },
            "App\\Enums\\CurrencyEnum": {
                "eur": "欧元",
                "usd": "美元"
            },
            "App\\Enums\\DaysOfWeekEnum": {
                "Friday": "星期五",
                "Monday": "周一",
                "Saturday": "周六",
                "Sunday": "星期日",
                "Thursday": "周四",
                "Tuesday": "周二",
                "Wednesday": "周三"
            },
            "App\\Enums\\Excursion\\KindEnum": {
                "customized": "定制",
                "root": "我的模板",
                "ugozer_app": "优果泽应用程序",
                "ugozer_pro": "UgoZER专业版"
            },
            "App\\Enums\\MeasurementSystemEnum": {
                "imperial": "帝国",
                "metric": "公制"
            },
            "App\\Enums\\NotificationSettingsEnum": {
                "app": "在应用程序中",
                "email": "电子邮件"
            },
            "App\\Enums\\Partner\\TypeEnum": {
                "customer": "顾客",
                "partner": "伙伴"
            },
            "App\\Enums\\PaymentServiceEnum": {
                "easytransac": "易传输",
                "paypal": "贝宝",
                "stripe": "条纹"
            },
            "App\\Enums\\PaymentTypeEnum": {
                "subscription": "订阅",
                "tip": "提示"
            },
            "App\\Enums\\PrivacySettingsEnum": {
                "contacts": "我的联系方式",
                "everyone": "每个人",
                "nobody": "没有人"
            },
            "App\\Enums\\Report\\ReasonTypeEnum": {
                "illegal": "非法的",
                "incorrect_data": "信息不正确",
                "other": "其他",
                "shocking": "令人震惊",
                "spam": "垃圾邮件"
            },
            "App\\Enums\\ReservationServiceEnum": {
                "breakfast": "早餐",
                "dinner": "晚餐",
                "lunch": "午餐",
                "null": "不适用"
            },
            "App\\Enums\\Social\\SourceEnum": {
                "facebook": "Facebook",
                "google": "谷歌",
                "instagram": "Instagram",
                "linkedin": "领英",
                "tiktok": "抖音",
                "twitter": "X（推特）",
                "wechat": "微信"
            },
            "App\\Enums\\Subscription\\EngagementPeriodEnum": {
                "one month": "1个月",
                "one year": "1年"
            },
            "App\\Enums\\Subscription\\FreePeriodEnum": {
                "one month": "1个月",
                "one year": "1年",
                "six months": "6个月",
                "three months": "3个月"
            },
            "App\\Enums\\Subscription\\SuspensionReasonEnum": {
                "expired": "订阅结束",
                "free period ended": "免费期结束",
                "payment default": "付款违约"
            },
            "App\\Enums\\SupportRequest\\SourceEnum": {
                "app": "应用程序",
                "extranet": "外联网",
                "front": "正面"
            },
            "App\\Enums\\SupportRequest\\StateEnum": {
                "archived": "已存档",
                "canceled": "取消",
                "pending_support": "待定支持",
                "pending_user": "待定用户",
                "resolved": "解决"
            },
            "App\\Enums\\UserGeolocSetting\\KindEnum": {
                "around_me": "我周围",
                "customized": "定制",
                "staying_hotel": "入住酒店附近"
            },
            "App\\Enums\\UserRoleEnum": {
                "butler": "管家",
                "contributor": "贡献者",
                "hotelier": "酒店经营者",
                "manager": "经理",
                "moderator": "主持人",
                "superadmin": "超级管理员",
                "touristOffice": "旅游办事处",
                "touristOfficeEmployee": "致员工",
                "traveller": "游客"
            }
        },
        "nova": {
            "lens": {
                "user": {
                    "user_origin": "用户来源"
                }
            },
            "menu": {
                "administration": "行政",
                "content": "内容",
                "excursions": "行程",
                "import_poi": "导入兴趣点",
                "miscellaneous": "各种各样的",
                "parameters": "参数",
                "pois": "兴趣点",
                "subscriptions": "订阅",
                "support": "支持",
                "users": "用户"
            },
            "s3_private_download": {
                "link": {
                    "expiration": "该链接将在 30 分钟后过期，如果是这种情况，只需刷新页面即可。",
                    "open": "查看文档"
                },
                "no_document": "无相关文件",
                "not_found": "在 S3 驱动器上找不到该文档..."
            },
            "user_menu": {
                "profile": "我的简历"
            }
        },
        "pagination": {
            "go_page": "转到第 {page} 页",
            "next": "下一个 ”",
            "next_alt": "下一个",
            "of": "的",
            "pagination_navigation": "分页导航",
            "previous": "“ 以前的",
            "previous_alt": "以前的",
            "results": "结果",
            "showing": "显示中",
            "to": "到"
        },
        "passwords": {
            "reset": "您的密码已重置！",
            "sent": "我们已通过电子邮件发送您的密码重置链接！",
            "throttled": "请等待后再重试。",
            "token": "此密码重置令牌无效。",
            "user": "没有对应的用户。"
        },
        "ttmt": {
            "account_deletion": {
                "confirmation": "在打开的页面上，输入单词<strong>DELETE</strong>确认删除\n        然后点击<strong>确认</strong>。",
                "content": {
                    "asking": "如果提出帐户删除请求，UgoZER 将删除用户的帐户和数据，\n            除非由于法律或监管要求、出于安全目的、欺诈目的而必须保留它们\n            预防或由于与用户帐户相关的问题，例如未付款、未解决的索赔或\n            争议。",
                    "conservation": {
                        "link": "隐私政策。",
                        "part_1": "UgoZER 会根据本协议中描述的目的保留用户数据",
                        "part_2": "根据CNIL的建议，3年未使用的账户将被删除。",
                        "part_3": "用户可以随时请求删除其帐户。 UgoZER 可能会在以下情况后保留用户数据：\n                根据法律或监管要求，或出于本协议中所述的原因提出删除请求\n                陈述。"
                    },
                    "instructions": "如果您想删除您的 UgoZER 帐户，请按照以下说明操作。"
                },
                "logout": "您将被注销，帐户和相关数据将立即被删除。",
                "pro": {
                    "instructions": {
                        "delete": "然后点击<strong>帐户删除前面的<strong>删除</strong>按钮\n                </strong>,",
                        "profile": {
                            "link": "轮廓",
                            "part_1": "去你的",
                            "path": "（点击您的头像 > 我的个人资料），"
                        }
                    },
                    "subtitle": "要删除您的专业帐户：",
                    "title": "专业账户"
                },
                "title": "帐户删除",
                "traveller": {
                    "instructions": {
                        "delete": "然后点击<strong>删除我的帐户？前面的<strong>删除</strong>按钮\n                </strong>",
                        "profile": {
                            "link": "轮廓",
                            "part_1": "去你的",
                            "path": "（汉堡包图标菜单 > 设置 > 个人资料），"
                        }
                    },
                    "subtitle": "要删除您的旅行者帐户：",
                    "title": "旅客账户"
                }
            },
            "activity_logs": {
                "summary": {
                    "created": {
                        "intro": "已使用以下属性创建主题："
                    },
                    "deleted": {
                        "intro": "主题已被删除，具有以下属性："
                    },
                    "updated": {
                        "attribute_from_to": "{attribute} 已从“{old_value}”更新为“{new_value}”",
                        "intro": "主题已更新："
                    },
                    "validated": {
                        "intro": "主题已被验证："
                    }
                }
            },
            "auth": {
                "credential_verification": {
                    "intro": "输入您的凭据，确认链接将发送给您。",
                    "intro2": "您访问该链接后，您的凭据将立即更新。"
                },
                "expiration": {
                    "session_expired": "您的会话已过期，请重新验证"
                },
                "extranet_register": {
                    "form": {
                        "choose_establishment": "选择您的机构类型",
                        "form_title": "创建您的帐户",
                        "validate_registration": "完成我的注册"
                    },
                    "success": "注册成功，验证链接已发送至您的邮箱"
                },
                "login": {
                    "already_logged": "您已经登录",
                    "logging_in": "在登录...",
                    "success": "登录",
                    "use_email": "使用电子邮件登录",
                    "use_social_networks": "使用社交网络"
                },
                "logout": {
                    "logging_out": "注销...",
                    "switch": "切换到"
                },
                "messages": {
                    "continue": "继续",
                    "extranet_here": "点击这里",
                    "have_account": "已经有帐户？",
                    "login": "登录",
                    "login_subtitle": "（注册或登录）",
                    "login_title": "社交网络更好",
                    "no_account": "还没有帐户？",
                    "or": "或者",
                    "professional": "你是专业人士吗？",
                    "register": "登记",
                    "register_mail": "使用电子邮件注册",
                    "register_title": "注册以解锁所有功能",
                    "reset_password": "重设密码",
                    "send_magic_link": "发送无密码链接"
                },
                "password_forgotten": {
                    "intro": "输入您的凭据，我们将向您发送用于重置密码的链接。",
                    "submit": "收到重置链接",
                    "success": {
                        "correct_credential": "正确的凭证",
                        "email_1": "如果该地址已正确注册，我们将向您发送一封电子邮件，地址为 {email}。",
                        "email_2": "此电子邮件包含一个链接，可让您重置密码。",
                        "send_again": "重新发送",
                        "title": "密码重置链接已发送",
                        "wait_before": "请等待 1 秒后再发送"
                    }
                },
                "password_reset": {
                    "submit": "重置你的密码",
                    "success": "密码已重置"
                },
                "register": {
                    "form": {
                        "hide_privacy_policy": "隐藏隐私政策",
                        "hide_terms_conditions": "隐藏条款和条件",
                        "privacy_policy": "我同意隐私政策",
                        "show_privacy_policy": "显示隐私政策",
                        "show_terms_conditions": "显示条款和条件",
                        "terms_conditions": "我同意这条款和条件"
                    },
                    "staying_hotel": "一旦您注册完成，您将受到酒店的照顾\n            您刚刚扫描了二维码。",
                    "success": {
                        "email_1": "一封电子邮件将发送至 {email}。",
                        "email_2": "此电子邮件包含一个链接，可让您确认您的地址并进行连接。",
                        "paired": {
                            "email_1": "两封电子邮件已发送至以下地址",
                            "email_2": "每封电子邮件都包含一个链接，用于确认地址并连接关联帐户。"
                        },
                        "title": "注册成功"
                    }
                },
                "social_networks": {
                    "authenticate": "登录方式",
                    "facebook": "Facebook",
                    "google": "谷歌",
                    "no_email": "没有电子邮件与您的社交网络关联，您无法使用它进行身份验证",
                    "twitter": "推特"
                },
                "travel_assistant": "免费出行助手"
            },
            "bank": {
                "account": {
                    "alerts": {
                        "no_account_yet": "未提供银行账户"
                    },
                    "form": {
                        "placeholders": {
                            "bank_city": "您的银行分行所在的城市。",
                            "bank_name": "银行名",
                            "bank_transfer_reason": "示例：“UgoZER 提示”",
                            "bic": "输入您的银行代码",
                            "iban": "输入您的 IBAN / BBAN",
                            "payee_name": "收款人姓名或机构名称",
                            "types": "IBAN/BBAN"
                        }
                    },
                    "lines": {
                        "no_account_yet": "您的银行账户将使您的机构能够在 UgoZER 上接收付款。"
                    }
                }
            },
            "bank_transfers": {
                "accounting_file": {
                    "amount": "数量",
                    "amount_to_pay": "支付数量",
                    "concierge": "门房",
                    "currency": "货币",
                    "customer": "顾客",
                    "date": "日期",
                    "repartition_key": "分配密钥",
                    "tip_sharing_form": "小费分享表格",
                    "tips_received": "收到的小费",
                    "total_received": "收到的总数"
                }
            },
            "breadcrumbs": {
                "changelogs": {
                    "changelogs": "变更日志"
                },
                "claims": {
                    "claims": "索赔"
                },
                "common": {
                    "add": "添加",
                    "claim": "宣称",
                    "create": "新的",
                    "create_f": "新的",
                    "delete": "删除",
                    "download_pdf": "下载PDF",
                    "edit": "编辑",
                    "report": "报告",
                    "share": "分享",
                    "show": "展示"
                },
                "contacts": {
                    "contacts": "联系方式"
                },
                "cookie": {
                    "cookie": "Cookie 管理"
                },
                "credit": {
                    "credit": "制作人员"
                },
                "dashboard": {
                    "dashboard": "仪表板"
                },
                "errors": {
                    "404": {
                        "label": "错误404",
                        "title": "未找到错误404"
                    }
                },
                "excursions": {
                    "create": "新行程",
                    "excursions": "行程"
                },
                "faq": {
                    "faq": "经常问的问题"
                },
                "finances": {
                    "finances": "财政"
                },
                "institutions": {
                    "institution": "机构",
                    "institutions": "机构"
                },
                "institutions_employees": {
                    "institutions_employees": "我的员工",
                    "invite": "邀请员工"
                },
                "interactions": {
                    "interactions": "互动"
                },
                "invitations": {
                    "invitations": "邀请函"
                },
                "notification_settings": {
                    "notification_settings": "通知设置"
                },
                "notifications": {
                    "notifications": "通知"
                },
                "partners": {
                    "customers": "顾客",
                    "partners": "伙伴"
                },
                "points_of_interest": {
                    "add_excursion": "添加到行程",
                    "points_of_interest": "兴趣点"
                },
                "privacy_policy": {
                    "privacy_policy": "隐私政策"
                },
                "privacy_settings": {
                    "privacy_settings": "隐私设置"
                },
                "reservations": {
                    "reservations": "预订"
                },
                "search": {
                    "search": "搜索"
                },
                "subscriptions": {
                    "subscription": "订阅"
                },
                "support_messages": {
                    "add_message": "添加消息",
                    "support_messages": "留言"
                },
                "support_requests": {
                    "add_request": "添加新的支持请求",
                    "support_requests": "支持请求"
                },
                "terms_condition": {
                    "terms_condition": "条款和条件"
                },
                "travellers": {
                    "folders": "旅行文件夹",
                    "travellers": "旅行者"
                },
                "tutorials": {
                    "begin": "入门"
                },
                "users": {
                    "edit_credential": "编辑凭证",
                    "profile": "我的简历",
                    "users": "用户"
                }
            },
            "changelogs": {
                "archive": {
                    "success": "变更日志已存档（如果符合条件）"
                },
                "draft": {
                    "success": "变更日志已起草（如果符合条件）"
                },
                "index": {
                    "sections": {
                        "all": "所有更新",
                        "last": "最后更新"
                    }
                },
                "publish": {
                    "success": "变更日志已发布（如果符合条件）"
                },
                "show": {
                    "version": "版本"
                }
            },
            "claim_disputes": {
                "create": {
                    "success": "索赔争议已产生"
                },
                "rejected": {
                    "success": "索赔争议已被驳回"
                },
                "validated": {
                    "success": "索赔争议已得到验证"
                }
            },
            "claims": {
                "form_wizard": {
                    "documents": {
                        "DE": {
                            "association_legal_representative_identity_piece": "Reisepass、Personalausweis、Führerschein（新\n                    版本 mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "association_statutes": "商业登记册",
                            "company_legal_representative_identity_piece": "Reisepass、Personalausweis、Führerschein（新\n                    版本 mit MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "company_registration_document": "商业登记册",
                            "company_statutes": "Aktuelle und unterschriebene (von der Geschäftsführung) Gesellschaftverträge",
                            "individual_first_identity_piece": "Reisepass、Personalausweis、Führerschein（新版本）\n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)",
                            "individual_registration_document": "Gewerbeschein、Handlungsbevollmächtigung、Handelsregisterauszug、\n                    Prokura、Steuernummer 归属信",
                            "individual_second_identity_piece": "Reisepass、Personalausweis、Führerschein（新版本）\n                    MRZ-Zeilen) oder Aufenthaltstitel (nur wenn Sie aus einem europäischen Land kommen)"
                        },
                        "ES": {
                            "association_legal_representative_identity_piece": "Pasaporte，国家身份证明文件，\n                    Permiso de conducir (version reciente con lineas MRZ) 或 Permiso de residencia (solo si es de un)\n                    欧洲国家）",
                            "association_statutes": "宪法或社会法令",
                            "company_legal_representative_identity_piece": "Pasaporte，国家身份文件，佩米索\n                    de conducir (version reciente con líneas MRZ) o Permiso de Residentia (solo si es de un país)\n                    欧洲）",
                            "company_registration_document": "Extracto del Registro Mercantil Central，Nota Simple del Registro\n                    Mercantil local sellado o numeric, Extracto desde los Registradores de España（信息\n                    General Mercantil) 包含 3 种型号或 Modelo 600 的验证信息\n                    de menos de 3 meses（民间社会独奏）",
                            "company_statutes": "宪法或社会法令",
                            "individual_first_identity_piece": "Pasaporte、Documento nacional de identidad、Permiso de conducir\n                    (version reciente con líneas MRZ) o Permiso deresidentcia (solo si es de un país europeo)",
                            "individual_registration_document": "Agencia Tributaria - Certificado de situacion Censal\n                    （阿尔塔普查尔）",
                            "individual_second_identity_piece": "Pasaporte、Documento nacional de identidad、Permiso de conducir\n                    (version reciente con líneas MRZ) o Permiso deresidentcia (solo si es de un país europeo)"
                        },
                        "FR": {
                            "association_legal_representative_identity_piece": "身份验证过程中的一件事情\n                    协会法律代表：身份证明（正面和反面）、护照、名称\n                    séjour（正面和反面），permis de conduire biométrique（正面和反面）",
                            "association_statutes": "协会章程",
                            "company_legal_representative_identity_piece": "身份验证过程中的一件事情\n                    社会法代表：身份证明（正面和反面）、护照、居留权\n                    （正面和反面），permis de conduire biométrique （正面和反面）",
                            "company_registration_document": "取消 KBIS",
                            "company_statutes": "法规",
                            "individual_first_identity_piece": "首次验证身份：证书\n                    d'identité（正面和反面）、passeport、titre de séjour（正面和反面）、permis de conduire\n                    生物识别（正面和反面）",
                            "individual_registration_document": "Un 证书 d'inscription à l'INSEE ou un document\n                    证明儿子铭文 auprès de la Chambre de Commerce (pour un auto-entrepreneur exerçant\n                    une activité Commerciale) ou auprès de la Chambre des métiers (pour un auto-entrepreneur exerçant)\n                    一项手工活动）",
                            "individual_second_identity_piece": "Une secondary pièce d'identité en cours de validité : carte\n                    d'identité（正面和反面）、passeport、titre de séjour（正面和反面）、permis de conduire\n                    （正面和反面），最后的日期是 d'un an，livret de famille ou\n                    民事团结协议登记记录"
                        },
                        "IT": {
                            "association_legal_representative_identity_piece": "Passaporto，《国家身份宪章》，La\n                    Patente di guida (versionecente con linee MRZ) o Permesso diresidenta (solo se di un paese)\n                    欧洲）",
                            "association_statutes": "tatuto Firmato dal Legale Rappresentante (se non incluso all'interno della)\n                    维苏拉·卡梅拉莱",
                            "company_legal_representative_identity_piece": "Passaporto、Carta d'identità nazionale、La Patente\n                    di guida (versionecente con linee MRZ) o Permesso diresidenta (solo se di un paese europeo)",
                            "company_registration_document": "维苏拉·卡梅拉莱",
                            "company_statutes": "Statuto Firmato dal Legale Rappresentante (se non incluso all'interno della)\n                    维苏拉·卡梅拉莱）",
                            "individual_first_identity_piece": "Passaporto、Carta d'identità nazionale、La Patente di guida\n                    （MRZ 最新版本） o Permesso diresidenta（solo se di un paese europeo）",
                            "individual_registration_document": "Estratto dal registro delle imprese (“Visura Camerale”) di meno\n                    迪3梅西",
                            "individual_second_identity_piece": "Passaporto、Carta d'identità nazionale、La Patente di guida\n                    （MRZ 最新版本） o Permesso diresidenta（solo se di un paese europeo）"
                        },
                        "TH": {
                            "association_legal_representative_identity_piece": "เอกสารประจำตัวที่ถูกต้องจากตัวแทนทางกฎหมายขอ说明：\n                    บัตรประจำตัวประชาชน (ทั้งด้านหน้าและด้าหลัง),\n                    หนังสือเดินทาง, ใบอนุญาตมีถิ่นที่อยู่ (ทั้งด้านหน้) าและด้านหลัง），\n                    ใบขับขี่ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านหลัง)",
                            "association_statutes": "กฎเกณฑ์ของสมาคม",
                            "company_legal_representative_identity_piece": "เอกสารประจำตัวที่ถูกต้องจากตัวแทนทางกฎหมายขอ说明：\n                    บัตรประจำตัวประชาชน (ด้านหน้าและด้านหลัง), หนังสือ เดินทาง,\n                    ใบอนุญาตมีถิ่นที่อยู่ (ด้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ด้านหน้าและด้านหลัง)",
                            "company_registration_document": "KBIS",
                            "company_statutes": "สถานะต่างๆ",
                            "individual_first_identity_piece": "名称：บัตรประจำตัวกต้องฉบับแรก： ประชาชน\n                    （ทั้งด้านหน้าและด้านหลัง），หนังสือเดินทาง，ใบอนาตม ีถิ่นที่อยู่ (ทั้งด้านหน้าและด้านหลัง),\n                    ใบขับขี่ไบโอเมตริกซ์ (ทั้งด้านหน้าและด้านหลัง)",
                            "individual_registration_document": "หนังสือรับรองการจดทะเบียนกับอินทรีหรือเอกสาร รับรองการจดทะเบียนกับหอการค้า\n                    （） งพาณิชย์)\n                    หรือกับหอการค้า (สำหรับผู้ประกอบอาชีพอิสระที ่ดำเนินกิจการเชิงพาณิชย์)\n                    กิจกรรมงานฝีมือ)",
                            "individual_second_identity_piece": "名称：บัตรประจำตั วประชาชน\n                    （ด้านหน้าและด้านหลัง）หนังสือเดินทางใบอนุญาตมีถิ่นท ี่อยู่\n                    （ด้านหน้าและด้านหลัง）ใบขับขี่（ด้านหน้าและด้านหลัง）\n                    中文名称： สมุดบันทึกครอบครัวหรือใบเสร็จรับเงินสำหรับกา รจดทะเบียน\n                    สนธิสัญญาความสามัคคีของพลเมือง"
                        }
                    },
                    "steps": {
                        "claim": "宣称",
                        "context": "语境",
                        "create": "创造",
                        "documents": "文件",
                        "info": "信息",
                        "search": "搜索"
                    }
                },
                "missing_info": {
                    "success": "索赔因缺少信息而被拒绝"
                },
                "notice": {
                    "actions": {
                        "edit": "修改我的请求"
                    },
                    "already_claimed": {
                        "description": "该兴趣点已被声明。如果您认为这是一个错误，您可以\n                填写下面的争议表。",
                        "title": "兴趣点已声明！"
                    },
                    "find_or_new": {
                        "description": "在下面的列表中搜索兴趣点或创建一个新兴趣点。",
                        "title": "搜索或创建 POI"
                    },
                    "missing-info": {
                        "infos": "请点击下面的按钮来完成您的请求。",
                        "state": "您的索赔目前正在等待更多信息或文件：",
                        "title": "等待更多信息"
                    },
                    "pending": {
                        "state": "您的索赔请求目前正在处理中",
                        "title": "待处理的索赔请求"
                    },
                    "rejected": {
                        "state": "您的索赔请求因以下原因被拒绝：",
                        "title": "索赔请求被拒绝"
                    }
                },
                "pages": {
                    "show": {
                        "sections": {
                            "claim": "宣称",
                            "poi": "兴趣点",
                            "user": "用户"
                        }
                    }
                },
                "rejected": {
                    "success": "索赔已被拒绝"
                },
                "revoked": {
                    "success": "索赔已被撤销"
                },
                "states": {
                    "missing-info": "丢失的信息",
                    "pending": "待办的",
                    "rejected": "拒绝",
                    "revoked": "已撤销",
                    "validated": "已验证"
                },
                "update": {
                    "success": "索赔已更新"
                },
                "valid_identity_pieces": "有效身份证、护照或驾驶执照",
                "validated": {
                    "success": "声明已被验证"
                }
            },
            "common": {
                "actions": {
                    "accept": "接受",
                    "accept_contact": "接受联系请求",
                    "add": "添加",
                    "add_contact": "添加到通讯录",
                    "add_excursion": "添加到行程",
                    "add_message": "添加消息",
                    "apply": "申请",
                    "approve": "批准",
                    "archive": "档案",
                    "attach": "附",
                    "block_contact": "屏蔽联系人",
                    "cancel": "取消",
                    "check": "查看",
                    "claim": "宣称",
                    "close": "关闭",
                    "configure": "配置",
                    "confirm": "确认",
                    "contest": "比赛",
                    "create": "创造",
                    "decline": "否定",
                    "decline_contact": "拒绝联系请求",
                    "delete": "删除",
                    "download": "下载",
                    "edit": "编辑",
                    "file_preview": "预览文件",
                    "filters": "过滤器",
                    "give_a_tip": "给个小费",
                    "google_maps": "在 Google 地图上打开",
                    "help": "帮助",
                    "hide": "隐藏",
                    "ignore": "忽略",
                    "interactions": "显示互动",
                    "invite": "邀请",
                    "load_more": "装载更多",
                    "login": "登录",
                    "logout": "登出",
                    "manage": "管理",
                    "next": "下一个",
                    "pause": "暂停",
                    "pay": "支付",
                    "play": "玩",
                    "print": "打印",
                    "quit": "辞职",
                    "record": "记录",
                    "register": "登记",
                    "report": "报告",
                    "request_to_join": "请求加入",
                    "reset": "重置",
                    "resume": "恢复",
                    "retry": "重试",
                    "return": "后退",
                    "save": "节省",
                    "save_alt": "节省",
                    "search": "搜索",
                    "see_plans": "查看计划",
                    "see_website": "查看网站",
                    "send": "发送",
                    "send_link": "发送应用程序链接",
                    "share": "分享",
                    "show": "展示",
                    "start": "开始",
                    "stop": "停止",
                    "subscribe": "订阅",
                    "thank": "感谢",
                    "thank_for_stay": "感谢您的入住",
                    "traveller_show": "咨询旅客",
                    "unblock_contact": "取消阻止联系人",
                    "unblock_this_user": "取消阻止该用户",
                    "update": "更新",
                    "validate": "证实"
                },
                "all": "全部",
                "and": "和",
                "app_name": "乌戈泽尔",
                "back_to_dashboard": "返回仪表板",
                "back_to_home": "回到家",
                "characters_limit": "{length} / {limit} 字符",
                "counter": "{dividend} 上 {divisor}",
                "country": "国家",
                "dashboard": "仪表板",
                "errors": {
                    "deleted_user": "已被删除的用户",
                    "internal": "出了些问题！",
                    "not_found": "未找到"
                },
                "false": "不",
                "fast": "快速地",
                "home": "家",
                "later": "之后",
                "loading": "加载中",
                "messages": {
                    "application_update": {
                        "if_ignore": "如果您忽略更新，它将在您下次启动应用程序时完成。",
                        "new_version_available": "有新版本可用。",
                        "updating": "更新中...",
                        "want_to_refresh": "您想重新启动应用程序来更新吗？"
                    },
                    "clipboard": {
                        "error": "复制到剪贴板时出错...",
                        "success": "已复制到剪贴板！"
                    },
                    "no_records": "没有找到记录"
                },
                "missing_title": "缺少标题",
                "more_options": "更多的选择",
                "no": "不",
                "none": "没有任何",
                "normal": "普通的",
                "not_specified": "未指定",
                "online": {
                    "connected": "您已再次连接到互联网",
                    "disconnected": "您不再连接到互联网"
                },
                "oops": "哎呀",
                "or": "或者",
                "qr_code": "二维码",
                "search": "搜索",
                "slow": "慢的",
                "someone": "某人",
                "sort": "种类",
                "tip": "提示",
                "tip_received": "收到提示（如果启用）",
                "true": "是的",
                "units": {
                    "kilometer": "公里 |公里",
                    "km": "公里",
                    "mi": "米",
                    "mile": "英里 |英里"
                },
                "welcome": "欢迎",
                "yes": "是的"
            },
            "cookies": {
                "details": {
                    "content": "以下是放置在 UgoZER 应用程序此部分的 cookie 列表，基于\n            上面定义的类别：",
                    "table": {
                        "headers": {
                            "category": "使用类别",
                            "description": "描述",
                            "lifespan": "寿命",
                            "mandatory": "强制的",
                            "name": "Cookie 名称（或模式）"
                        },
                        "lines": {
                            "alphanumeric": {
                                "content": "包含有关会话的信息。",
                                "lifespan": "2小时",
                                "title": "[[{alnum}:]]（字母数字字符序列）"
                            },
                            "gmaps": {
                                "content": "用于显示 Google 地图 iframe。",
                                "lifespan": "180-400天",
                                "title": "CONSENT、AEC、SOCS...（或其他 google.com 域 cookie）"
                            },
                            "remember": {
                                "content": "用于保存用户的身份验证。",
                                "lifespan": "400天",
                                "title": "记住网络_*"
                            },
                            "session": {
                                "content": "包含有关会话的信息。",
                                "lifespan": "2小时",
                                "title": "*_ugozer_会话"
                            },
                            "tarteaucitron": {
                                "content": "允许您保留用户的 cookie 设置。",
                                "lifespan": "365天",
                                "title": "柠檬酸"
                            },
                            "xsrf": {
                                "content": "允许保护应用程序（请求、表单...）。",
                                "lifespan": "2小时",
                                "title": "XSRF-令牌-*"
                            }
                        }
                    },
                    "title": "已存 cookie 的详细信息"
                },
                "management": {
                    "content": {
                        "cta": "改变我的偏好",
                        "line_1": "本网站的某些功能依赖于 cookie 的使用。",
                        "line_2": "如果只有必要的话，cookie 横幅不会在您导航期间出现在主页上\n                存入网站的功能。",
                        "line_3": "您可以通过单击下面的按钮更改您的偏好："
                    },
                    "title": "Cookie 管理"
                },
                "notice": {
                    "needed": "要查看此部分，您必须接受 cookie {name}。",
                    "refresh": "如果您在接受cookies后没有看到它，请刷新页面。",
                    "title": "饼干"
                },
                "services": {
                    "gmaps": "谷歌地图",
                    "youtube": "YouTube"
                },
                "usage": {
                    "content": "Cookie 可用于在您访问 UgoZER 时识别您、记住您的偏好并给予\n            为您提供符合您的设置的个性化体验。 Cookie 还可以让您与\n            UgoZER 更快、更安全。此外，cookie 允许我们在网站内外为您提供广告\n            UgoZER 网站。",
                    "table": {
                        "headers": {
                            "category": "使用类别",
                            "description": "描述"
                        },
                        "lines": {
                            "ads": {
                                "content": "我们可能会使用 cookie 在 UgoZER 网站内外向您展示相关广告。\n                        我们还可能使用 cookie 来了解看到广告的人后来是否访问并获取了广告\n                        在广告商网站上进行的操作（例如下载白皮书或进行购买）。\n                        同样，我们的合作伙伴可能会使用 cookie 来确定我们是否展示了广告以及展示方式\n                        执行，或向我们提供有关您如何与广告互动的信息。我们也可以工作\n                        与合作伙伴一起向您展示 UgoZER 内外的广告，例如在您访问合作伙伴的网站后\n                        网站或应用程序。",
                                "title": "广告"
                            },
                            "authentication": {
                                "content": "如果您已登录 UgoZER，Cookie 会帮助我们向您显示正确的信息并\n                        个性化您的体验。",
                                "title": "验证"
                            },
                            "security": {
                                "content": "我们使用 cookie 来启用和支持我们的安全功能，并帮助我们检测\n                        恶意活动和违反我们的",
                                "cta": "条款和条件。",
                                "title": "安全"
                            },
                            "services": {
                                "content": "Cookie 为您提供功能、见解和定制内容。",
                                "title": "偏好、功能和服务"
                            }
                        }
                    },
                    "title": "cookie 有何用途？"
                }
            },
            "countries": {
                "AD": "安道尔",
                "AE": "阿拉伯联合酋长国",
                "AF": "阿富汗",
                "AG": "安提瓜和巴布达",
                "AI": "安圭拉",
                "AL": "阿尔巴尼亚",
                "AM": "亚美尼亚",
                "AO": "安哥拉",
                "AQ": "南极洲",
                "AR": "阿根廷",
                "AS": "美属萨摩亚",
                "AT": "奥地利",
                "AU": "澳大利亚",
                "AW": "阿鲁巴岛",
                "AX": "奥兰群岛",
                "AZ": "阿塞拜疆",
                "BA": "波斯尼亚和黑塞哥维那",
                "BB": "巴巴多斯",
                "BD": "孟加拉国",
                "BE": "比利时",
                "BF": "布基纳法索",
                "BG": "保加利亚",
                "BH": "巴林",
                "BI": "布隆迪",
                "BJ": "贝宁",
                "BL": "圣巴泰勒米岛",
                "BM": "百慕大",
                "BN": "文莱",
                "BO": "玻利维亚",
                "BQ": "加勒比荷兰",
                "BR": "巴西",
                "BS": "巴哈马",
                "BT": "不丹",
                "BV": "布维岛",
                "BW": "博茨瓦纳",
                "BY": "白俄罗斯",
                "BZ": "伯利兹",
                "CA": "加拿大",
                "CC": "科科斯（基林）群岛",
                "CD": "刚果 - 金沙萨",
                "CF": "中非共和国",
                "CG": "刚果 - 布拉柴维尔",
                "CH": "瑞士",
                "CI": "科特迪瓦",
                "CK": "库克群岛",
                "CL": "智利",
                "CM": "喀麦隆",
                "CN": "中国",
                "CO": "哥伦比亚",
                "CR": "哥斯达黎加",
                "CU": "古巴",
                "CV": "佛得角",
                "CW": "库拉索",
                "CX": "圣诞岛",
                "CY": "塞浦路斯",
                "CZ": "捷克",
                "DE": "德国",
                "DJ": "吉布提",
                "DK": "丹麦",
                "DM": "多米尼加",
                "DO": "多明尼加共和国",
                "DZ": "阿尔及利亚",
                "EC": "厄瓜多尔",
                "EE": "爱沙尼亚",
                "EG": "埃及",
                "EH": "撒哈拉沙漠西部",
                "ER": "厄立特里亚",
                "ES": "西班牙",
                "ET": "埃塞俄比亚",
                "FI": "芬兰",
                "FJ": "斐济",
                "FK": "福克兰群岛",
                "FM": "密克罗尼西亚",
                "FO": "法罗群岛",
                "FR": "法国",
                "GA": "加蓬",
                "GB": "英国",
                "GD": "格林纳达",
                "GE": "乔治亚州",
                "GF": "法属圭亚那",
                "GG": "根西岛",
                "GH": "加纳",
                "GI": "直布罗陀",
                "GL": "格陵兰",
                "GM": "冈比亚",
                "GN": "几内亚",
                "GP": "瓜德罗普岛",
                "GQ": "赤道几内亚",
                "GR": "希腊",
                "GS": "南乔治亚岛和南桑威奇群岛",
                "GT": "危地马拉",
                "GU": "关岛",
                "GW": "几内亚比绍",
                "GY": "圭亚那",
                "HK": "中国香港特别行政区",
                "HM": "赫德岛和麦克唐纳群岛",
                "HN": "洪都拉斯",
                "HR": "克罗地亚",
                "HT": "海地",
                "HU": "匈牙利",
                "ID": "印度尼西亚",
                "IE": "爱尔兰",
                "IL": "以色列",
                "IM": "马恩岛",
                "IN": "印度",
                "IO": "英属印度洋领地",
                "IQ": "伊拉克",
                "IR": "伊朗",
                "IS": "冰岛",
                "IT": "意大利",
                "JE": "球衣",
                "JM": "牙买加",
                "JO": "约旦",
                "JP": "日本",
                "KE": "肯尼亚",
                "KG": "吉尔吉斯斯坦",
                "KH": "柬埔寨",
                "KI": "基里巴斯",
                "KM": "科摩罗",
                "KN": "圣基茨和尼维斯",
                "KP": "北朝鲜",
                "KR": "韩国",
                "KW": "科威特",
                "KY": "开曼群岛",
                "KZ": "哈萨克斯坦",
                "LA": "老挝",
                "LB": "黎巴嫩",
                "LC": "圣卢西亚",
                "LI": "列支敦士登",
                "LK": "斯里兰卡",
                "LR": "利比里亚",
                "LS": "莱索托",
                "LT": "立陶宛",
                "LU": "卢森堡",
                "LV": "拉脱维亚",
                "LY": "利比亚",
                "MA": "摩洛哥",
                "MC": "摩纳哥",
                "MD": "摩尔多瓦",
                "ME": "黑山",
                "MF": "圣马丁",
                "MG": "马达加斯加",
                "MH": "马绍尔群岛",
                "MK": "北马其顿",
                "ML": "马里",
                "MM": "缅甸（缅甸）",
                "MN": "蒙古",
                "MO": "中国澳门特别行政区",
                "MP": "北马里亚纳群岛",
                "MQ": "马提尼克岛",
                "MR": "毛里塔尼亚",
                "MS": "蒙特塞拉特",
                "MT": "马耳他",
                "MU": "毛里求斯",
                "MV": "马尔代夫",
                "MW": "马拉维",
                "MX": "墨西哥",
                "MY": "马来西亚",
                "MZ": "莫桑比克",
                "NA": "纳米比亚",
                "NC": "新喀里多尼亚",
                "NE": "尼日尔",
                "NF": "诺福克岛",
                "NG": "尼日利亚",
                "NI": "尼加拉瓜",
                "NL": "荷兰",
                "NO": "挪威",
                "NP": "尼泊尔",
                "NR": "瑙鲁",
                "NU": "纽埃",
                "NZ": "新西兰",
                "OM": "阿曼",
                "PA": "巴拿马",
                "PE": "秘鲁",
                "PF": "法属波利尼西亚",
                "PG": "巴布亚新几内亚",
                "PH": "菲律宾",
                "PK": "巴基斯坦",
                "PL": "波兰",
                "PM": "圣皮埃尔和密克隆群岛",
                "PN": "皮特凯恩群岛",
                "PR": "波多黎各",
                "PS": "巴勒斯坦领土",
                "PT": "葡萄牙",
                "PW": "帕劳",
                "PY": "巴拉圭",
                "QA": "卡塔尔",
                "RE": "团圆",
                "RO": "罗马尼亚",
                "RS": "塞尔维亚",
                "RU": "俄罗斯",
                "RW": "卢旺达",
                "SA": "沙特阿拉伯",
                "SB": "所罗门群岛",
                "SC": "塞舌尔",
                "SD": "苏丹",
                "SE": "瑞典",
                "SG": "新加坡",
                "SH": "圣赫勒拿岛",
                "SI": "斯洛文尼亚",
                "SJ": "斯瓦尔巴群岛和扬马延岛",
                "SK": "斯洛伐克",
                "SL": "塞拉利昂",
                "SM": "圣马力诺",
                "SN": "塞内加尔",
                "SO": "索马里",
                "SR": "苏里南",
                "SS": "南苏丹",
                "ST": "圣多美和普林西比",
                "SV": "萨尔瓦多",
                "SX": "圣马丁岛",
                "SY": "叙利亚",
                "SZ": "史瓦帝尼",
                "TC": "特克斯和凯科斯群岛",
                "TD": "乍得",
                "TF": "法属南部领土",
                "TG": "多哥",
                "TH": "泰国",
                "TJ": "塔吉克斯坦",
                "TK": "托克劳",
                "TL": "东帝汶",
                "TM": "土库曼斯坦",
                "TN": "突尼斯",
                "TO": "汤加",
                "TR": "火鸡",
                "TT": "特立尼达和多巴哥",
                "TV": "图瓦卢",
                "TW": "台湾",
                "TZ": "坦桑尼亚",
                "UA": "乌克兰",
                "UG": "乌干达",
                "UM": "美国离岛",
                "US": "美国",
                "UY": "乌拉圭",
                "UZ": "乌兹别克斯坦",
                "VA": "梵蒂冈城",
                "VC": "圣文森特和格林纳丁斯",
                "VE": "委内瑞拉",
                "VG": "英属维尔京群岛",
                "VI": "美属维尔京群岛",
                "VN": "越南",
                "VU": "瓦努阿图",
                "WF": "瓦利斯和富图纳群岛",
                "WS": "萨摩亚",
                "XK": "科索沃",
                "YE": "也门",
                "YT": "马约特岛",
                "ZA": "南非",
                "ZM": "赞比亚",
                "ZW": "津巴布韦"
            },
            "credits": {
                "images": "图片来源"
            },
            "csv": {
                "common": {
                    "generating": "正在生成 CSV 文件...",
                    "waiting": "请稍等..."
                },
                "download": "CSV 导出"
            },
            "dashboard": {
                "excursions": {
                    "favorites": "当地专家的最爱"
                },
                "links": {
                    "closest_pois": "最近点",
                    "excursions": "行程和步行",
                    "shares": "分享"
                },
                "no_dashboard": {
                    "contact": {
                        "link": "联系我们。",
                        "text": "如果您认为这是一个错误，请不要犹豫"
                    },
                    "content": "尚未为您的角色设置仪表板或您以管理员身份连接。",
                    "title": "没有可用的仪表板"
                },
                "points_of_interest": {
                    "categories": "兴趣点类别"
                }
            },
            "documents": {
                "display": {
                    "description": "以 A6 格式显示，并链接 QRCode 和您所在机构的徽标。",
                    "title": "显示-A6卡"
                },
                "qrcode": {
                    "description": "仅 QRCode 就可以让旅行者直接链接到您的机构。",
                    "filename": "二维码",
                    "title": "链接二维码"
                }
            },
            "easy_transac": {
                "account": {
                    "create": {
                        "button": "创建 EasyTransac 帐户",
                        "fail": "EasyTransac 连接失败，请稍后重试",
                        "success": "您的 EasyTransac 帐户已成功创建"
                    },
                    "show": {
                        "account_usage": "您的 EasyTransac 帐户将允许您的机构在 UgoZER 上接收付款。",
                        "can_receive_payments": "您的客户现在可以给您的餐厅小费\n                来自礼宾人员的 UgoZER 个人资料。",
                        "can_retrieve_payments": {
                            "description": "您必须使用所要求的信息填写您的 EasyTransac 帐户才能完成\n                    使用您帐户中的可用资金。",
                            "title": "您的 EasyTransac 帐户不完整"
                        },
                        "cannot_receive_payments": "您无法收到付款",
                        "credentials_sent_mail": "您的 EasyTransac 凭据已通过电子邮件发送给您。",
                        "fee_percentage": "您收到的每笔付款均需缴纳费用。",
                        "go_to_easy_transac": "访问 EasyTransac 仪表板",
                        "must_subscribe": "您必须订阅才能创建 EasyTransac 帐户。",
                        "no_account_yet": "您还没有 EasyTransac 帐户。",
                        "should_complete_kyc": "请发送 EasyTransac 要求的文件，\n                为了收到付款。"
                    }
                },
                "easy_transac": "易传输"
            },
            "exceptions": {
                "default": "发生错误",
                "error_texts": {
                    "403": {
                        "link": {
                            "title": "您可能想要访问",
                            "to_app_label": "旅行者部分？",
                            "to_extranet_label": "专业版块？"
                        },
                        "message": "您无权访问此页面。",
                        "title": "哎呀！禁止访问。"
                    },
                    "credential_not_verified": {
                        "title": "您的凭据未经过验证",
                        "verify_mail": "接收我的电子邮件的验证链接",
                        "verify_phone": "接收我的电话号码的验证链接"
                    },
                    "opening_hours": {
                        "invalid": "给定值不是 OpeningHours 实例。"
                    },
                    "password_missing": {
                        "app": "请设置密码。",
                        "extranet": "请设置密码以访问该应用程序。"
                    }
                },
                "spam_detected": "检测到垃圾邮件"
            },
            "excursions": {
                "destroy": {
                    "confirm": "您确实要删除此行程吗： {excursion_name}？",
                    "success": "行程已被删除"
                },
                "direction": {
                    "bicycling": "骑自行车",
                    "display_mode": "显示模式",
                    "driving": "驾驶",
                    "walking": "步行"
                },
                "form": {
                    "all_points_of_interest": "所有兴趣点",
                    "create_new_point_of_interest": "创建新的兴趣点",
                    "existing_excursions": "现有行程",
                    "from_existing_excursions": "从现有行程",
                    "order_hint": "兴趣点的顺序与访问的顺序相对应。",
                    "points_of_interest": "兴趣点",
                    "select_from_points_of_interest": "从兴趣点中选择",
                    "your_excursion": "您的行程"
                },
                "index": {
                    "alert": {
                        "content": "您只能查看路线。如果您想创建或共享行程，您必须使用以下身份登录\n                <strong>礼宾/接待员帐户</strong>",
                        "title": "行程仅供参考"
                    },
                    "no_results": "您还没有任何行程",
                    "no_results_here": "您的搜索区域没有可用的行程。",
                    "read_only": "仅供咨询",
                    "search": "搜索行程",
                    "try_more_filters": "您可以扩大搜索范围或创建行程。",
                    "your_excursions": "您的行程"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "excursion": "行程",
                            "pois": "兴趣点"
                        }
                    }
                },
                "search": {
                    "no_results": "没有找到行程"
                },
                "share": {
                    "contains_private": "此行程包含私人兴趣点。他们可能不包含在份额中。",
                    "heading": "分享此行程",
                    "select_which_contacts": "选择您想要与其共享行程“{excursion_title}”的联系人",
                    "select_which_users": "选择您想要与其共享行程“{excursion_title}”的用户或输入\n            用于邀请新旅行者的电子邮件地址",
                    "select_which_users_alt": "输入您想要与其共享信息的人员的电子邮件地址\n            “：excursion_title”行程。",
                    "success": "行程已分享！"
                },
                "store": {
                    "create": "创建行程",
                    "heading": "创建行程",
                    "success": "行程已创建"
                },
                "update": {
                    "heading": "更新此行程",
                    "success": "行程已更新"
                }
            },
            "extranet": {
                "dashboard": {
                    "last_claims": {
                        "none": "您还没有任何索赔。",
                        "see_all": "查看您的所有索赔",
                        "see_index": "前往理赔页面"
                    },
                    "last_payments": {
                        "none": "您还没有任何付款。",
                        "see_all": "查看您的所有付款",
                        "see_index": "进入支付页面"
                    },
                    "last_reservations": {
                        "none": "您还没有任何预订。"
                    },
                    "last_traveller_folders": {
                        "none": "您还没有需要照顾的旅行者。",
                        "see_all": "查看所有旅客",
                        "take_care": "照顾一个旅行者"
                    },
                    "manager": {
                        "sections": {
                            "butlers_institutions": "我所在机构的礼宾人员",
                            "butlers_interactions": "礼宾人员互动"
                        }
                    },
                    "sections": {
                        "last_claims": "您最后的索赔",
                        "last_payments": "您的最后一次付款",
                        "last_reservations": "您最后的预订",
                        "last_travellers": "你最后的旅行者",
                        "search_butler": "搜索礼宾部",
                        "stats": "统计数据"
                    },
                    "stats": {
                        "butlers": "礼宾部|礼宾部",
                        "employees": "员工|员工",
                        "excursions": "行程|行程",
                        "folders": "文件夹|文件夹",
                        "institutions": "关联机构|关联机构",
                        "processing_claims": "处理索赔|处理索赔",
                        "reservations": "预订|预订"
                    }
                },
                "employees": {
                    "actions": {
                        "new": "邀请员工",
                        "new-hotelier": "创建接收帐户",
                        "new-tourist-office": "创建一个桌面帐户"
                    }
                },
                "excursions": {
                    "actions": {
                        "new": "创建行程"
                    },
                    "sections": {
                        "excursions": "行程"
                    }
                },
                "faq": {
                    "sections": {
                        "categories": "类别"
                    }
                },
                "filters": {
                    "closed": "关闭过滤器",
                    "open": "打开过滤器"
                },
                "finances": {
                    "alerts": {
                        "easytransac": {
                            "cta": "访问我的个人资料以创建 EasyTransac 帐户",
                            "description": "您还没有创建 EasyTransac 帐户！因此您无法收到付款。",
                            "title": "EasyTransac 帐户不存在！"
                        }
                    },
                    "sections": {
                        "issued_payments": "已发出付款",
                        "received_by_institution_payments": "您的机构收到的付款",
                        "received_payments": "已收到付款"
                    }
                },
                "institution": {
                    "messages": {
                        "no_records": "目前没有关联机构"
                    },
                    "sections": {
                        "butler": {
                            "institution": "我的机构"
                        },
                        "manager": {
                            "institution": "我的机构"
                        }
                    }
                },
                "navbar": {
                    "contact": "联系我们",
                    "contacts": "联系方式",
                    "dashboard": "仪表板",
                    "excursions": "行程",
                    "faq": "常问问题",
                    "finances": "财政",
                    "invitations": "邀请函",
                    "my_employees": "我的员工",
                    "my_institution": "我的机构",
                    "my_institutions": "我的机构",
                    "points_of_interest": "兴趣点",
                    "travellers": "旅行者",
                    "tutorials": "开始",
                    "users": "用户"
                },
                "pages": {
                    "excursions": {
                        "edit": "编辑行程",
                        "new": "新行程"
                    },
                    "folders": {
                        "edit": "编辑旅行者文​​件夹",
                        "new": "新的旅行者文件夹"
                    },
                    "institutions": {
                        "new": "链接机构"
                    },
                    "pois": {
                        "edit": "编辑兴趣点",
                        "new": "新的兴趣点"
                    },
                    "profile": {
                        "edit": "编辑个人资料"
                    },
                    "reservations": {
                        "edit": "编辑预订",
                        "new": "新预订"
                    }
                },
                "points_of_interest": {
                    "actions": {
                        "new": "创建兴趣点"
                    },
                    "sections": {
                        "points_of_interest": "兴趣点"
                    }
                },
                "post": {
                    "messages": {
                        "no_records": "暂时没有常见问题解答"
                    }
                },
                "professional_section": "专业版块",
                "profile": {
                    "sections": {
                        "currency": "货币",
                        "delete_account": "帐户删除",
                        "infos": "一般信息",
                        "locale": "语言",
                        "notification_settings": "通知设置",
                        "payment": "支付",
                        "privacy_settings": "隐私设置",
                        "shares": "分享"
                    }
                },
                "support_messages": {
                    "actions": {
                        "back": "返回支持请求",
                        "new": "添加新消息"
                    },
                    "sections": {
                        "support_messages": "留言"
                    }
                },
                "support_requests": {
                    "actions": {
                        "back": "后退",
                        "new": "创建支持请求"
                    },
                    "sections": {
                        "support_requests": "支持请求"
                    }
                },
                "travellers": {
                    "buttons": {
                        "new_folder": "添加新的旅行者文件夹"
                    },
                    "sections": {
                        "folders": "我的旅行者文件夹"
                    }
                },
                "user_dropdown": {
                    "changelogs": "变更日志",
                    "cookies": "Cookie 管理",
                    "credits": "制作人员",
                    "customers": "顾客",
                    "partners": "伙伴",
                    "privacy_policy": "隐私政策",
                    "profile": "我的简历",
                    "sign_out": "登出",
                    "subscription": "订阅",
                    "terms_condition": "条款和条件"
                }
            },
            "form": {
                "file": {
                    "current_file": "实际文件： {name}"
                },
                "hints": {
                    "auth": {
                        "register": {
                            "email": "此电子邮件地址不得已链接到 UgoZER 帐户",
                            "employee": {
                                "not_duplicate_email": "电子邮件地址必须与您的电子邮件地址不同",
                                "not_duplicate_password": "密码必须与用户的密码不同"
                            }
                        }
                    },
                    "excursion": {
                        "kind": {
                            "admin": {
                                "customized": "定制：由礼宾部创建的行程\n                        对于特定的旅行者",
                                "root": "我的模板：“准备分享”行程，包含必看的景点，\n                        或按主题划分的兴趣点\n                        （示例：行程中经常包含的餐厅列表）\n                        被选择来创建新的行程。",
                                "ugozer_app": "UgoZER App：由UgoZER创建和提议的行程\n                        并提供给旅客。",
                                "ugozer_pro": "UgoZER Pro：由 UgoZER 创建和提议的行程\n                        并提供给专业人员以促进他们的工作。"
                            },
                            "pro": {
                                "customized": "为特定旅行者创建的行程",
                                "root": "“准备分享”包含必看景点的行程，\n                        或按主题划分的兴趣点\n                        （示例：行程中经常包含的餐厅列表）\n                        被选择来创建新的行程。",
                                "ugozer": "行程由 UgoZER 创建和提议。"
                            }
                        },
                        "points_of_interest_search": "搜索并选择兴趣点以将其添加到列表中"
                    },
                    "excursions_filters": {
                        "radius": "您只会看到至少有一个兴趣点位于该半径范围内的行程。"
                    },
                    "geoloc_setting": {
                        "filter_header": "搜索国家、地区、城市或社区",
                        "kind": {
                            "admin": {
                                "around_me": "我周围：地理位置将被确定\n                        通过用户的浏览器。",
                                "customized": "定制：用户将能够手动搜索地点。",
                                "staying_hotel": "入住酒店：地理定位完成\n                        从旅客入住的酒店出发。"
                            },
                            "app": {
                                "around_me": "地理定位将从您的位置进行。",
                                "customized": "地理定位将从您选择的位置完成。",
                                "staying_hotel": "地理定位将从您入住的酒店完成： {title}。"
                            }
                        }
                    },
                    "locale": {
                        "dynamic_translations_enabled": "允许用户显示或翻译资源\n                （兴趣点、行程...）用该语言",
                        "fallback_locale": "自动以第二种语言显示标题（在说明之前）\n                POI 文件。",
                        "static_translations_enabled": "允许用户选择该语言来显示应用程序\n                （要求翻译已添加到源代码中）"
                    },
                    "permission": {
                        "name": "源代码中使用的名称"
                    },
                    "plan": {
                        "countries": "留空以使该计划在所有国家/地区可用",
                        "information": "有关计划的附加信息（先决条件、条件...）",
                        "name": "该名称将对潜在订阅者可见\n                当他们选择订阅时",
                        "private_title": "该标题仅在管理中可见"
                    },
                    "plan_price": {
                        "description": "该描述将对潜在订阅者可见\n                当他们选择订阅时",
                        "price": "请以欧元输入",
                        "private_title": "该标题仅在管理中可见"
                    },
                    "point_of_interest": {
                        "audio_refreshing_1": "启用音频文件刷新会自动生成音频文件\n                使用兴趣点的语言（：语言）。",
                        "audio_refreshing_2": "它将注明兴趣点的标题和地址。",
                        "audio_refreshing_3": "语言、标题会立即刷新\n                或更新兴趣点的地址。",
                        "audio_refreshing_4": "如果您自己添加或录制音频文件，刷新将被禁用，\n                或者如果您删除生成的音频文件。",
                        "cant_have_employees": "在这里您只能看到与可能具有以下类别相关的机构\n                雇员",
                        "expiry_date": "您的兴趣点将于 {date} 到期",
                        "expiry_date_details_1": "您的兴趣点属于“{category}”类别的一部分。",
                        "expiry_date_details_2": "因此，它将在 {date} 到期，因为这是您输入的最后日期。",
                        "expiry_date_details_3": "从该日期起，您的兴趣点还有 21 天的时间\n                在它被自动删除之前..",
                        "expiry_date_details_4": "您随时可以添加新日期\n                推迟到期，或不指定日期，以便您的兴趣点不会到期。",
                        "images": "图像将按所选顺序显示在兴趣点的图库中，\n                您可以通过拖动它们来重新组织它们。",
                        "is_geocoding": "如果正确输入地理位置但地图仍未出现，请停用。",
                        "line1": "您可以输入您要查找的地点的地址或名称。从以下选择\n                列表自动填写地址",
                        "locale": "该兴趣点使用的主要语言",
                        "opening_dates": "如果时间表适用于任何日期，请留空",
                        "opening_days": "如果任何一天都有时间表，请勿选择任何内容",
                        "opening_hours": "如果兴趣点随时开放，请勿添加任何内容",
                        "sync_1": "激活自动更新允许您从 {import_source} 检索数据\n                进口",
                        "sync_2": "手动输入的内容将被覆盖"
                    },
                    "points_of_interest_filters": {
                        "order_distance": "请选择距离过滤器以选择此选项",
                        "order_relevance": "请输入搜索关键字以选择此选项",
                        "radius": "您只会看到位于该半径范围内的兴趣点。"
                    },
                    "privacy_setting": {
                        "show_real_name": {
                            "butler": "无论您选择哪个选项，您的姓氏和名字\n                    您机构的经理将保持可见",
                            "traveller": "无论您选择哪个选项，您的姓氏和名字\n                  您所住酒店的礼宾人员仍可以看到。"
                        }
                    },
                    "profile": {
                        "contact_email": "此电子邮件可以显示在您的公开个人资料中",
                        "contact_phone": "此电话可以显示在您的公开个人资料中",
                        "name": "该昵称是公开的，可以让应用程序的其他用户更轻松地识别您的身份。",
                        "password_template": {
                            "digit": "1 位数字",
                            "length": "{length} 个字符",
                            "lowercase": "1 个小写字母",
                            "uppercase": "1 个大写字母"
                        },
                        "password_unchanged": "留空保持不变"
                    },
                    "role": {
                        "description": "当用户选择角色时，会显示角色的描述\n                注册到专业部分。",
                        "name": "角色代号，不会向用户显示。"
                    },
                    "support_request": {
                        "support_categories_search": "搜索并选择类别"
                    },
                    "translatable": {
                        "pro_global": "此表格的一个或多个字段可以用不同的语言填写。",
                        "pro_global_2": "某些语言中未填写的字段将被自动翻译。",
                        "pro_global_title": "多语言输入"
                    },
                    "traveller_folder": {
                        "expired_on": "默认情况下，结束日期设置为一周内"
                    },
                    "user": {
                        "bank_account_number": "输入用于转账的账号"
                    }
                },
                "labels": {
                    "geoloc_setting": {
                        "distance_filter": "距离过滤器",
                        "limit_to_a_radius": "限制半径",
                        "limit_to_a_radius_from_center": "限制为距中心的半径",
                        "measure_unit": "测量单位",
                        "other_filters": "其他过滤器",
                        "where_does_the_traveller_go": "旅行者要去哪里？",
                        "where_would_you_like_to_go": "你想去哪里？",
                        "within_a_radius": "半径内"
                    },
                    "payment": {
                        "payable_type": "付款链接至"
                    },
                    "plan": {
                        "authorized_roles": "授权角色",
                        "tipping_fees": "小费（百分比）"
                    },
                    "plan_price": {
                        "price_excluding_taxes": "不含税价格"
                    },
                    "point_of_interest": {
                        "audio_refreshing": "自动刷新音频文件",
                        "opening": "开幕",
                        "opening_date": "在",
                        "opening_date_from": "从",
                        "opening_date_range": "指定结束日期",
                        "opening_date_to": "到",
                        "opening_hours": "在",
                        "opening_hours_from": "从",
                        "opening_hours_range": "指定结束时间",
                        "opening_hours_to": "到",
                        "opening_schedule_add": "指定日期或时间",
                        "opening_schedule_add_alt": "指定时间",
                        "opening_specification_add": "添加句点"
                    },
                    "points_of_interest_filters": {
                        "geoloc": "地方",
                        "order_distance": "按距离",
                        "order_relevance": "按相关性（搜索关键词）",
                        "sort": "种类",
                        "tags": "精炼"
                    },
                    "register": {
                        "communication_consent": "我希望收到个性化的通信，包括优惠、\n                通过电子邮件从 UgoZER 获取促销信息和旅行相关产品。",
                        "privacy_policy": {
                            "part1": "我同意",
                            "part2": "隐私政策"
                        },
                        "terms_conditions": {
                            "part1": "我同意",
                            "part2": "条款和条件"
                        }
                    },
                    "support_category": {
                        "title": "选择一个类别"
                    },
                    "support_message": {
                        "content": "信息",
                        "email": "电子邮件地址"
                    },
                    "support_request": {
                        "title": "请求的主题"
                    },
                    "traveller_invitation": {
                        "locale": "该旅行者所使用的语言"
                    },
                    "user": {
                        "credential": "电子邮件",
                        "email": "电子邮件",
                        "image": {
                            "_destroy": "删除个人资料图片"
                        },
                        "new_password": "新密码",
                        "notes": "更多信息"
                    },
                    "vat_rate": {
                        "vat_rate": "增值税税率（百分比）"
                    }
                },
                "placeholders": {
                    "audio_file_name": "音频文件名",
                    "name": "昵称",
                    "support_message": {
                        "create_message": "您的支持请求消息"
                    }
                },
                "select": {
                    "no_options": "无选项",
                    "no_results": "抱歉，没有匹配的选项。",
                    "type_to_search": "搜索..."
                },
                "titles": {
                    "verifications": "检查您的信息"
                }
            },
            "front": {
                "access_pro": "前往专业通道",
                "app": "旅行者应用程序",
                "brand_name": "乌戈泽尔",
                "captcha": {
                    "and": "和",
                    "apply": "申请。",
                    "privacy_policy": "隐私政策",
                    "protected": "该网站受 hCaptcha 及其保护",
                    "terms_service": "服务条款"
                },
                "contact": {
                    "cancel": "取消支持请求",
                    "contact": "接触",
                    "send": "发送"
                },
                "cookies": {
                    "title": "Cookie 管理"
                },
                "customers": {
                    "see_customers": "查看我们的客户",
                    "title": "他们信任我们"
                },
                "footer": {
                    "account_deletion": "帐户删除",
                    "app": "旅行者应用程序",
                    "contact": "接触",
                    "cookies": "Cookie 管理",
                    "copyright": "© {year} UgoZER - 保留所有权利。",
                    "facebook": "Facebook",
                    "faq": "常问问题",
                    "home": "家",
                    "instagram": "Instagram",
                    "legal_notice": "法律声明",
                    "linkedin": "领英",
                    "privacy_policy": "隐私政策",
                    "pro": "专业访问",
                    "sitemap": "网站地图",
                    "terms_conditions": "条款和条件",
                    "twitter": "推特"
                },
                "header": {
                    "app": "旅行者应用程序",
                    "contact": "接触",
                    "faq": "常问问题",
                    "home": "家",
                    "pro": "专业访问",
                    "you_are": "你是"
                },
                "intro": {
                    "intro_1": "借助 UgoZER，您的智能手机中始终可以保存多语言文件中您最喜欢的地址，\n            通过点击即可听到或读取。",
                    "intro_2": "在国外时，让出租车司机和其他人轻松了解您的目的地。",
                    "intro_3": "接待员、酒店经理，设计并提供旅游项目。与您的客户分享\n            在 UgoZER 上。",
                    "intro_title_pro": "专业人士",
                    "intro_title_traveller": "游客",
                    "title_homepage": "多语言导游，免费。"
                },
                "navigation": {
                    "next": "下一个",
                    "previous": "以前的"
                },
                "outro": {
                    "description": "旅行2.0"
                },
                "partners": {
                    "no_records": "暂时没有合作伙伴",
                    "read_more": "阅读更多",
                    "see_partners": "查看我们的合作伙伴",
                    "title": "伙伴"
                },
                "posts": {
                    "no_records": "目前没有帖子",
                    "title": "常问问题。"
                },
                "pro": "专业访问",
                "professionals": {
                    "argument_1": {
                        "part_1": "UgoZER 为您的客人提供数字解决方案。",
                        "part_2": "提升他们的住宿体验和宾客体验。",
                        "part_3": "它使您的酒店、度假村或信息中心能够创建个性化、易于使用、\n                多语言移动行程。",
                        "part_4": "通过 UgoZER 专业平台分享来自一个实时 POIs 数据库的定制行程\n                及其移动应用程序。"
                    },
                    "argument_2": {
                        "part_1": "您可以为您的客人选择预先安排的行程。",
                        "part_2": "创建您的城镇中最好的地方或餐厅，隐藏的宝石等待发现，完美\n                远足小径享受...",
                        "part_3": "或者在详尽的 POI 列表中选择访问的每个步骤，然后将它们组合在\n                将然后分享个性化行程。"
                    },
                    "argument_3": {
                        "part_1": "每个 POI 都有详细的描述、它在交互式地图上的位置以及它的位置\n                地址自动翻译成当地语言。",
                        "part_2": "您的客人永远不会丢失。他总能找到出路并轻松回到你身边\n                酒店。"
                    },
                    "argument_4": {
                        "part_1": "在旅行结束时，在享受了量身定制的行程后，您的客人将能够\n                通过给小费来感谢您的礼宾员或接待员的独特推荐。",
                        "part_2": "通过使用 UgoZER，您将通过增加收入和减少员工来提高保留率\n                周转。",
                        "part_3": "您将为他们提供更大的动力来参与您酒店的日常生活\n                并给他们一种归属感。"
                    },
                    "intro": {
                        "main_title": "UgoZER，多语言数字和个人旅行助手。带你的客人\n                体验更上一层楼并增加您的收入。"
                    },
                    "outro": {
                        "catchphrase": "UgoZER 将帮助您提高满意度并推动增长。"
                    }
                },
                "startup": {
                    "description_1": "UgoZER是一家法国公司，总部位于欧洲首都斯特拉斯堡。由旅行创造\n            爱好者们，它可以方便您在不会说该语言的国家旅行。",
                    "description_2": "这是旅游局推荐的。",
                    "title": "初创公司 UgoZER"
                },
                "support_request": "支持请求",
                "travellers": {
                    "argument_1": {
                        "arguments": {
                            "audio": "当地语言的<strong>音频地址</strong>，用于收听出租车或路人的声音",
                            "desc": "<strong>详细描述</strong>",
                            "languages": "所有信息均翻译成法语、英语、德语、荷兰语、葡萄牙语、\n                    西班牙语、意大利语、韩语、中文、日语和阿拉伯语。</strong>",
                            "map": "<strong>交互式地图上的位置，可在 Google Maps™ 上输出</strong>"
                        },
                        "part_1": "访问智能手机上实时更新的所有兴趣点 (POI)。",
                        "part_2": "按<strong>类别分类的兴趣点：纪念碑、博物馆、展览、商店、餐馆、\n                酒吧、临时活动...</strong>",
                        "part_3": "POI 提供："
                    },
                    "argument_2": {
                        "part_1": "永久访问完整信息，享受您的旅行。一切都在您的智能手机上，\n                就在你的口袋里。"
                    },
                    "argument_3": {
                        "part_1": "使用 UgoZER，自动获取免费行程。您还可以创建行程并分享\n                他们和你的朋友。通过当地的个性化旅游，充分享受您的旅程\n                专家：酒店礼宾部或接待员、旅游信息办公室。",
                        "part_2": "如果您喜欢这次旅行，您甚至可以通过数字化且安全的方式感谢当地专家\n                小费选项。"
                    },
                    "intro": {
                        "main_title": "您的免费多语言旅游指南"
                    },
                    "outro": {
                        "catchphrase": "有了 UgoZER，让自己得到安全引导，安心旅行。"
                    }
                },
                "you_are": {
                    "professional": "专业的",
                    "traveller": "游客"
                },
                "youtube": {
                    "directed_by": "导演是",
                    "producer": "超头工作室"
                }
            },
            "institutions": {
                "index": {
                    "request": {
                        "title": "选择您的机构"
                    },
                    "sections": {
                        "pending_claims": "待验证",
                        "requests": "待处理的请求",
                        "validated_claims": "声称"
                    }
                }
            },
            "institutions_employees": {
                "apply": {
                    "success": "请求已发送"
                },
                "approve": {
                    "confirm": {
                        "employee": "您真的要批准该请求吗？",
                        "employer": "您真的要批准 {user_name} 吗？"
                    },
                    "success": "请求已获批准"
                },
                "cancel": {
                    "confirm": {
                        "employee": "您确实要取消此请求吗？",
                        "employer": "您确实要取消 {user_name} 请求吗？"
                    },
                    "success": "请求已被取消"
                },
                "decline": {
                    "confirm": {
                        "change_institution": "您的关联机构将会更改！",
                        "employee": "您真的要拒绝该请求吗？",
                        "employer": "您真的要拒绝 {user_name} 吗？"
                    },
                    "success": "请求已被拒绝"
                },
                "feature": {
                    "confirm": "您真的想以 {user_name} 为特色吗？",
                    "folder": "对于与您的员工没有互动过的旅行者来说，这是一个特色\n           谁将显示在旅行者的文件夹中。",
                    "only_one": "只能推荐一名员工。",
                    "success": "该员工已被推荐"
                },
                "index": {
                    "my_employees": "我的员工"
                },
                "invitation_wizard": {
                    "steps": {
                        "account": "帐户",
                        "invitation": "邀请函"
                    }
                },
                "invite": {
                    "invite": "邀请员工"
                },
                "quit": {
                    "confirm": "你真的想退出这个机构 {poi_name}吗？",
                    "success": "您已退出该机构"
                },
                "remove": {
                    "confirm": "您真的要删除 {user_name} 吗？",
                    "success": "该员工已被除名"
                },
                "show": {
                    "pending": "您加入该机构的请求目前正在等待处理。"
                },
                "states": {
                    "approved": "得到正式认可的",
                    "canceled": "取消",
                    "declined": "拒绝",
                    "invited": "受邀",
                    "pending": "待办的",
                    "removed": "已删除"
                }
            },
            "interactions": {
                "description": {
                    "contact approved": "联系请求已获得 {sender_name} 批准",
                    "contact blocked": "联系请求已被 {sender_name} 阻止",
                    "contact created": "联系请求已由 {sender_name} 创建",
                    "contact declined": "联系请求已被拒绝 {sender_name}",
                    "invitation accepted": " {sender_name} 已接受邀请",
                    "invitation created": "邀请已由 {sender_name} 发送",
                    "payment succeeded": "{sender_name} 已向 {recipient_name} 付款",
                    "share created": "{sender_name} 已共享 {share}",
                    "traveller_folder created": "Traveler 文件夹已由 {sender_name} 创建",
                    "traveller_folder thanked": "{sender_name} 感谢旅行者的入住"
                },
                "share_type": {
                    "excursions": "行程",
                    "pointsofinterest": "兴趣点"
                }
            },
            "invitations": {
                "messages": {
                    "invite": "邀请"
                },
                "states": {
                    "authenticated_once": {
                        "false": "待办的",
                        "true": "已验证"
                    }
                },
                "success": {
                    "professional": {
                        "sent_1": "如果该地址尚未使用，则会向 {email} 发送一封电子邮件。",
                        "sent_2": "该电子邮件包含注册专业部分的链接。",
                        "title": "邀请已发送"
                    },
                    "traveller": {
                        "email_1": "如果该地址尚未使用，则会向 {email} 发送一封电子邮件。",
                        "email_2": "该电子邮件包含一个允许连接到该应用程序的链接。",
                        "title": "邀请已发送"
                    }
                },
                "texts": {
                    "already_have_account": "用户是否已经在该应用程序上拥有帐户？",
                    "description": "您可以使用电子邮件地址或电话号码邀请某人加入我们的申请。",
                    "employee_already_have_account": "该员工是否已在该应用程序上拥有帐户？",
                    "geoloc_settings": {
                        "description": "地理位置设置将自动设置为您的酒店坐标\n                （如果您与某个机构有联系）。",
                        "title": "地理位置设置"
                    },
                    "invite_link": {
                        "instructions": "复制此链接以邀请旅行者",
                        "title": "通过链接邀请"
                    },
                    "invite_professional": "邀请专业人士",
                    "invite_traveller": "邀请一位旅行者",
                    "new_traveller": "新旅行者",
                    "professional_description": "您可以邀请专业人士加入我们的专业部分\n            通过电子邮件申请",
                    "title": "邀请某人"
                }
            },
            "locales": {
                "aa": "阿法尔",
                "ab": "阿布哈兹语",
                "af": "南非荷兰语",
                "af_NA": "南非荷兰语（纳米比亚）",
                "af_ZA": "南非荷兰语（南非）",
                "ak": "阿寒",
                "ak_GH": "阿坎语（加纳）",
                "am": "阿姆哈拉语",
                "am_ET": "阿姆哈拉语（埃塞俄比亚）",
                "an": "阿拉贡语",
                "ar": "阿拉伯",
                "ar_AE": "阿拉伯语（阿拉伯联合酋长国）",
                "ar_BH": "阿拉伯语（巴林）",
                "ar_DJ": "阿拉伯语（吉布提）",
                "ar_DZ": "阿拉伯语（阿尔及利亚）",
                "ar_EG": "阿拉伯语（埃及）",
                "ar_EH": "阿拉伯语（西撒哈拉）",
                "ar_ER": "阿拉伯语（厄立特里亚）",
                "ar_IL": "阿拉伯语（以色列）",
                "ar_IQ": "阿拉伯语（伊拉克）",
                "ar_JO": "阿拉伯语（约旦）",
                "ar_KM": "阿拉伯语（科摩罗）",
                "ar_KW": "阿拉伯语（科威特）",
                "ar_LB": "阿拉伯语（黎巴嫩）",
                "ar_LY": "阿拉伯语（利比亚）",
                "ar_MA": "阿拉伯语（摩洛哥）",
                "ar_MR": "阿拉伯语（毛里塔尼亚）",
                "ar_OM": "阿拉伯语（阿曼）",
                "ar_PS": "阿拉伯语（巴勒斯坦领土）",
                "ar_QA": "阿拉伯语（卡塔尔）",
                "ar_SA": "阿拉伯语（沙特阿拉伯）",
                "ar_SD": "阿拉伯语（苏丹）",
                "ar_SO": "阿拉伯语（索马里）",
                "ar_SS": "阿拉伯语（南苏丹）",
                "ar_SY": "阿拉伯语（叙利亚）",
                "ar_TD": "阿拉伯语（乍得）",
                "ar_TN": "阿拉伯语（突尼斯）",
                "ar_YE": "阿拉伯语（也门）",
                "as": "阿萨姆语",
                "as_IN": "阿萨姆语（印度）",
                "av": "阿瓦里克",
                "ay": "艾马拉",
                "az": "阿塞拜疆语",
                "az_AZ": "阿塞拜疆语（阿塞拜疆）",
                "az_Cyrl": "阿塞拜疆语（西里尔文）",
                "az_Cyrl_AZ": "阿塞拜疆语（西里尔字母，阿塞拜疆）",
                "az_Latn": "阿塞拜疆语（拉丁语）",
                "az_Latn_AZ": "阿塞拜疆语（拉丁语，阿塞拜疆）",
                "ba": "巴什基尔语",
                "be": "白俄罗斯语",
                "be_BY": "白俄罗斯语（白俄罗斯）",
                "bg": "保加利亚语",
                "bg_BG": "保加利亚语（保加利亚）",
                "bh": "比哈里语（语言）",
                "bi": "比斯拉马",
                "bm": "班巴拉",
                "bm_Latn": "班巴拉语（拉丁语）",
                "bm_Latn_ML": "班巴拉语（拉丁语，马里）",
                "bn": "孟加拉",
                "bn_BD": "孟加拉语（孟加拉国）",
                "bn_IN": "孟加拉语（印度）",
                "bo": "藏",
                "bo_CN": "藏语（中国）",
                "bo_IN": "藏语（印度）",
                "br": "布列塔尼",
                "br_FR": "布列塔尼（法国）",
                "bs": "波斯尼亚语",
                "bs_BA": "波斯尼亚语（波斯尼亚和黑塞哥维那）",
                "bs_Cyrl": "波斯尼亚语（西里尔字母）",
                "bs_Cyrl_BA": "波斯尼亚语（西里尔字母，波斯尼亚和黑塞哥维那）",
                "bs_Latn": "波斯尼亚语（拉丁语）",
                "bs_Latn_BA": "波斯尼亚语（拉丁语，波斯尼亚和黑塞哥维那）",
                "ca": "加泰罗尼亚语",
                "ca_AD": "加泰罗尼亚语（安道尔）",
                "ca_ES": "加泰罗尼亚语（西班牙）",
                "ca_FR": "加泰罗尼亚语（法国）",
                "ca_IT": "加泰罗尼亚语（意大利）",
                "ce": "车臣",
                "ch": "查莫罗语",
                "co": "科西嘉岛",
                "cr": "克里族",
                "cs": "捷克语",
                "cs_CZ": "捷克语（捷克共和国）",
                "cu": "古保加利亚语",
                "cv": "楚瓦什语",
                "cy": "威尔士语",
                "cy_GB": "威尔士语（英国）",
                "da": "丹麦语",
                "da_DK": "丹麦语（丹麦）",
                "da_GL": "丹麦语（格陵兰）",
                "de": "德语",
                "de_AT": "德语（奥地利）",
                "de_BE": "德语（比利时）",
                "de_CH": "德语（瑞士）",
                "de_DE": "德语（德国）",
                "de_LI": "德语（列支敦士登）",
                "de_LU": "德语（卢森堡）",
                "dv": "马尔代夫",
                "dz": "宗卡",
                "dz_BT": "宗卡语（不丹）",
                "ee": "母羊",
                "ee_GH": "埃维（加纳）",
                "ee_TG": "埃维（多哥）",
                "el": "希腊语",
                "el_CY": "希腊语（塞浦路斯）",
                "el_GR": "希腊语（希腊）",
                "en": "英语",
                "en_AG": "英语（安提瓜和巴布达）",
                "en_AI": "英语（安圭拉）",
                "en_AS": "英语（美属萨摩亚）",
                "en_AU": "英语（澳大利亚）",
                "en_BB": "英语（巴巴多斯）",
                "en_BE": "英语（比利时）",
                "en_BM": "英语（百慕大）",
                "en_BS": "英语（巴哈马）",
                "en_BW": "英语（博茨瓦纳）",
                "en_BZ": "英语（伯利兹）",
                "en_CA": "英语（加拿大）",
                "en_CC": "英语（科科斯（基林）群岛）",
                "en_CK": "英语（库克群岛）",
                "en_CM": "英语（喀麦隆）",
                "en_CX": "英语（圣诞岛）",
                "en_DG": "英语（迭戈·加西亚）",
                "en_DM": "英语（多米尼克）",
                "en_ER": "英语（厄立特里亚）",
                "en_FJ": "英语（斐济）",
                "en_FK": "英语（福克兰群岛）",
                "en_FM": "英语（密克罗尼西亚）",
                "en_GB": "英语（英国）",
                "en_GD": "英语（格林纳达）",
                "en_GG": "英语（根西岛）",
                "en_GH": "英语（加纳）",
                "en_GI": "英语（直布罗陀）",
                "en_GM": "英语（冈比亚）",
                "en_GU": "英语（关岛）",
                "en_GY": "英语（圭亚那）",
                "en_HK": "英语（中国香港特别行政区）",
                "en_IE": "英语（爱尔兰）",
                "en_IM": "英语（马恩岛）",
                "en_IN": "英语（印度）",
                "en_IO": "英语（英属印度洋领地）",
                "en_JE": "英语（泽西岛）",
                "en_JM": "英语（牙买加）",
                "en_KE": "英语（肯尼亚）",
                "en_KI": "英语（基里巴斯）",
                "en_KN": "英语（圣基茨和尼维斯）",
                "en_KY": "英语（开曼群岛）",
                "en_LC": "英语（圣卢西亚）",
                "en_LR": "英语（利比里亚）",
                "en_LS": "英语（莱索托）",
                "en_MG": "英语（马达加斯加）",
                "en_MH": "英语（马绍尔群岛）",
                "en_MO": "英语（中国澳门特别行政区）",
                "en_MP": "英语（北马里亚纳群岛）",
                "en_MS": "英语（蒙特塞拉特）",
                "en_MT": "英语（马耳他）",
                "en_MU": "英语（毛里求斯）",
                "en_MW": "英语（马拉维）",
                "en_MY": "英语（马来西亚）",
                "en_NA": "英语（纳米比亚）",
                "en_NF": "英语（诺福克岛）",
                "en_NG": "英语（尼日利亚）",
                "en_NR": "英语（瑙鲁）",
                "en_NU": "英语（纽埃）",
                "en_NZ": "英语（新西兰）",
                "en_PG": "英语（巴布亚新几内亚）",
                "en_PH": "英语（菲律宾）",
                "en_PK": "英语（巴基斯坦）",
                "en_PN": "英语（皮特凯恩群岛）",
                "en_PR": "英语（波多黎各）",
                "en_PW": "英语（帕劳）",
                "en_RW": "英语（卢旺达）",
                "en_SB": "英语（所罗门群岛）",
                "en_SC": "英语（塞舌尔）",
                "en_SD": "英语（苏丹）",
                "en_SG": "英语（新加坡）",
                "en_SH": "英语（圣赫勒拿）",
                "en_SL": "英语（塞拉利昂）",
                "en_SS": "英语（南苏丹）",
                "en_SX": "英语（圣马丁）",
                "en_SZ": "英语（斯威士兰）",
                "en_TC": "英语（特克斯和凯科斯群岛）",
                "en_TK": "英语（托克劳）",
                "en_TO": "英语（汤加）",
                "en_TT": "英语（特立尼达和多巴哥）",
                "en_TV": "英语（图瓦卢）",
                "en_TZ": "英语（坦桑尼亚）",
                "en_UG": "英语（乌干达）",
                "en_UM": "英语（美国离岛）",
                "en_US": "美国英语）",
                "en_VC": "英语（圣文森特和格林纳丁斯）",
                "en_VG": "英语（英属维尔京群岛）",
                "en_VI": "英语（美属维尔京群岛）",
                "en_VU": "英语（瓦努阿图）",
                "en_WS": "英语（萨摩亚）",
                "en_ZA": "英语（南非）",
                "en_ZM": "英语（赞比亚）",
                "en_ZW": "英语（津巴布韦）",
                "eo": "世界语",
                "es": "西班牙语",
                "es_AR": "西班牙语（阿根廷）",
                "es_BO": "西班牙语（玻利维亚）",
                "es_CL": "西班牙语（智利）",
                "es_CO": "西班牙语（哥伦比亚）",
                "es_CR": "西班牙语（哥斯达黎加）",
                "es_CU": "西班牙语（古巴）",
                "es_DO": "西班牙语（多米尼加共和国）",
                "es_EA": "西班牙语（休达和梅利利亚）",
                "es_EC": "西班牙语（厄瓜多尔）",
                "es_ES": "西班牙语（西班牙）",
                "es_GQ": "西班牙语（赤道几内亚）",
                "es_GT": "西班牙语（危地马拉）",
                "es_HN": "西班牙语（洪都拉斯）",
                "es_IC": "西班牙语（加那利群岛）",
                "es_MX": "西班牙语（墨西哥）",
                "es_NI": "西班牙语（尼加拉瓜）",
                "es_PA": "西班牙语（巴拿马）",
                "es_PE": "西班牙语（秘鲁）",
                "es_PH": "西班牙语（菲律宾）",
                "es_PR": "西班牙语（波多黎各）",
                "es_PY": "西班牙语（巴拉圭）",
                "es_SV": "西班牙语（萨尔瓦多）",
                "es_US": "西班牙语（美国）",
                "es_UY": "西班牙语（乌拉圭）",
                "es_VE": "西班牙语（委内瑞拉）",
                "et": "爱沙尼亚语",
                "et_EE": "爱沙尼亚语（爱沙尼亚）",
                "eu": "巴斯克",
                "eu_ES": "巴斯克语（西班牙）",
                "fa": "波斯语",
                "fa_AF": "波斯语（阿富汗）",
                "fa_IR": "波斯语（伊朗）",
                "ff": "富拉",
                "ff_CM": "富拉（喀麦隆）",
                "ff_GN": "富拉（几内亚）",
                "ff_MR": "富拉（毛里塔尼亚）",
                "ff_SN": "富拉（塞内加尔）",
                "fi": "芬兰",
                "fi_FI": "芬兰语（芬兰）",
                "fj": "斐济语",
                "fo": "法罗语",
                "fo_FO": "法罗语（法罗群岛）",
                "fr": "法语",
                "fr_BE": "法语（比利时）",
                "fr_BF": "法语（布基纳法索）",
                "fr_BI": "法语（布隆迪）",
                "fr_BJ": "法语（贝宁）",
                "fr_BL": "法语（圣巴泰勒米）",
                "fr_CA": "法语（加拿大）",
                "fr_CD": "法语（刚果 - 金沙萨）",
                "fr_CF": "法语（中非共和国）",
                "fr_CG": "法语（刚果 - 布拉柴维尔）",
                "fr_CH": "法语（瑞士）",
                "fr_CI": "法语（科特迪瓦）",
                "fr_CM": "法语（喀麦隆）",
                "fr_DJ": "法语（吉布提）",
                "fr_DZ": "法语（阿尔及利亚）",
                "fr_FR": "法语（法国）",
                "fr_GA": "法语（加蓬）",
                "fr_GF": "法语（法属圭亚那）",
                "fr_GN": "法语（几内亚）",
                "fr_GP": "法语（瓜德罗普岛）",
                "fr_GQ": "法语（赤道几内亚）",
                "fr_HT": "法语（海地）",
                "fr_KM": "法语（科摩罗）",
                "fr_LU": "法语（卢森堡）",
                "fr_MA": "法语（摩洛哥）",
                "fr_MC": "法语（摩纳哥）",
                "fr_MF": "法语（圣马丁）",
                "fr_MG": "法语（马达加斯加）",
                "fr_ML": "法语（马里）",
                "fr_MQ": "法语（马提尼克岛）",
                "fr_MR": "法语（毛里塔尼亚）",
                "fr_MU": "法语（毛里求斯）",
                "fr_NC": "法语（新喀里多尼亚）",
                "fr_NE": "法语（尼日尔）",
                "fr_PF": "法语（法属波利尼西亚）",
                "fr_PM": "法语（圣皮埃尔和密克隆群岛）",
                "fr_RE": "法语（留尼汪）",
                "fr_RW": "法语（卢旺达）",
                "fr_SC": "法语（塞舌尔）",
                "fr_SN": "法语（塞内加尔）",
                "fr_SY": "法语（叙利亚）",
                "fr_TD": "法语（乍得）",
                "fr_TG": "法语（多哥）",
                "fr_TN": "法语（突尼斯）",
                "fr_VU": "法语（瓦努阿图）",
                "fr_WF": "法语（瓦利斯和富图纳群岛）",
                "fr_YT": "法语（马约特岛）",
                "fy": "西弗里斯兰语",
                "fy_NL": "西弗里斯兰语（荷兰）",
                "ga": "爱尔兰语",
                "ga_IE": "爱尔兰语（爱尔兰）",
                "gd": "苏格兰盖尔语",
                "gd_GB": "苏格兰盖尔语（英国）",
                "gl": "加利西亚语",
                "gl_ES": "加利西亚语（西班牙）",
                "gn": "瓜拉尼语",
                "gu": "古吉拉特语",
                "gu_IN": "古吉拉特语（印度）",
                "gv": "马恩岛",
                "gv_IM": "马恩岛（马恩岛）",
                "ha": "豪萨语",
                "ha_GH": "豪萨语（加纳）",
                "ha_Latn": "豪萨语（拉丁语）",
                "ha_Latn_GH": "豪萨语（拉丁语，加纳）",
                "ha_Latn_NE": "豪萨语（拉丁语，尼日尔）",
                "ha_Latn_NG": "豪萨语（拉丁语，尼日利亚）",
                "ha_NE": "豪萨语（尼日尔）",
                "ha_NG": "豪萨语（尼日利亚）",
                "he": "希伯来语",
                "he_IL": "希伯来语（以色列）",
                "hi": "印地语",
                "hi_IN": "印地语（印度）",
                "ho": "希里·莫图",
                "hr": "克罗地亚语",
                "hr_BA": "克罗地亚语（波斯尼亚和黑塞哥维那）",
                "hr_HR": "克罗地亚语（克罗地亚）",
                "ht": "海地",
                "hu": "匈牙利",
                "hu_HU": "匈牙利语（匈牙利）",
                "hy": "亚美尼亚语",
                "hy_AM": "亚美尼亚语（亚美尼亚）",
                "hz": "赫雷罗",
                "ia": "国际语",
                "id": "印度尼西亚",
                "id_ID": "印度尼西亚语（印度尼西亚）",
                "ie": "中间语",
                "ig": "伊博语",
                "ig_NG": "伊博语（尼日利亚）",
                "ii": "四川彝族",
                "ii_CN": "四川彝族（中国）",
                "ik": "因努皮克人",
                "io": "我愿意",
                "is": "冰岛的",
                "is_IS": "冰岛语（冰岛）",
                "it": "意大利语",
                "it_CH": "意大利语（瑞士）",
                "it_IT": "意大利语（意大利）",
                "it_SM": "意大利语（圣马力诺）",
                "iu": "因纽特人",
                "ja": "日本人",
                "ja_JP": "日语（日本）",
                "jv": "爪哇语",
                "ka": "格鲁吉亚语",
                "ka_GE": "格鲁吉亚语（格鲁吉亚）",
                "kg": "刚果",
                "ki": "基库尤语",
                "ki_KE": "基库尤语（肯尼亚）",
                "kj": "宽亚马",
                "kk": "哈萨克语",
                "kk_Cyrl": "哈萨克语（西里尔字母）",
                "kk_Cyrl_KZ": "哈萨克语（西里尔字母，哈萨克斯坦）",
                "kk_KZ": "哈萨克语（哈萨克斯坦）",
                "kl": "卡拉阿利苏特",
                "kl_GL": "卡拉利苏特（格陵兰）",
                "km": "高棉语",
                "km_KH": "高棉语（柬埔寨）",
                "kn": "卡纳达语",
                "kn_IN": "卡纳达语（印度）",
                "ko": "韩国人",
                "ko_KP": "朝鲜语（朝鲜）",
                "ko_KR": "韩语（韩国）",
                "kr": "卡努里",
                "ks": "克什米尔",
                "ks_Arab": "克什米尔语（阿拉伯语）",
                "ks_Arab_IN": "克什米尔语（阿拉伯语，印度）",
                "ks_IN": "克什米尔（印度）",
                "ku": "库尔德",
                "kv": "科米",
                "kw": "康沃尔郡",
                "kw_GB": "康沃尔郡（英国）",
                "ky": "吉尔吉斯斯坦",
                "ky_Cyrl": "吉尔吉斯语（西里尔字母）",
                "ky_Cyrl_KG": "吉尔吉斯语（西里尔字母，吉尔吉斯斯坦）",
                "ky_KG": "吉尔吉斯（吉尔吉斯斯坦）",
                "la": "拉丁",
                "lb": "卢森堡语",
                "lb_LU": "卢森堡语（卢森堡）",
                "lg": "干达",
                "lg_UG": "甘达（乌干达）",
                "li": "林堡干",
                "ln": "林加拉语",
                "ln_AO": "林加拉语（安哥拉）",
                "ln_CD": "林加拉（刚果 - 金沙萨）",
                "ln_CF": "林加拉语（中非共和国）",
                "ln_CG": "林加拉（刚果 - 布拉柴维尔）",
                "lo": "老挝",
                "lo_LA": "老挝（老挝）",
                "lt": "立陶宛语",
                "lt_LT": "立陶宛语（立陶宛）",
                "lu": "卢巴-加丹加",
                "lu_CD": "卢巴-加丹加（刚果 - 金沙萨）",
                "lv": "拉脱维亚语",
                "lv_LV": "拉脱维亚语（拉脱维亚）",
                "mg": "马尔加什",
                "mg_MG": "马达加斯加（马达加斯加）",
                "mh": "马绍尔语",
                "mi": "毛利人",
                "mk": "马其顿语",
                "mk_MK": "马其顿语（马其顿）",
                "ml": "马拉雅拉姆语",
                "ml_IN": "马拉雅拉姆语（印度）",
                "mn": "蒙",
                "mn_Cyrl": "蒙古语（西里尔字母）",
                "mn_Cyrl_MN": "蒙古语（西里尔字母，蒙古）",
                "mn_MN": "蒙古语（蒙古）",
                "mo": "澳门",
                "mr": "马拉地语",
                "mr_IN": "马拉地语（印度）",
                "ms": "马来语",
                "ms_BN": "马来语（文莱）",
                "ms_Latn": "马来语（拉丁语）",
                "ms_Latn_BN": "马来语（拉丁语、文莱）",
                "ms_Latn_MY": "马来语（拉丁语，马来西亚）",
                "ms_Latn_SG": "马来语（拉丁语、新加坡）",
                "ms_MY": "马来语（马来西亚）",
                "ms_SG": "马来语（新加坡）",
                "mt": "马耳他语",
                "mt_MT": "马耳他语（马耳他）",
                "my": "缅甸语",
                "my_MM": "缅甸语（缅甸（缅甸））",
                "na": "瑙鲁",
                "nb": "挪威博克马尔语",
                "nb_NO": "挪威语博克马尔语（挪威）",
                "nb_SJ": "挪威博克马尔语（斯瓦尔巴群岛和扬马延群岛）",
                "nd": "北恩德贝莱",
                "nd_ZW": "北恩德贝莱（津巴布韦）",
                "ne": "尼泊尔语",
                "ne_IN": "尼泊尔语（印度）",
                "ne_NP": "尼泊尔语（尼泊尔）",
                "ng": "恩东加",
                "nl": "荷兰语",
                "nl_AW": "荷兰语（阿鲁巴）",
                "nl_BE": "荷兰语（比利时）",
                "nl_BQ": "荷兰语（加勒比海荷兰）",
                "nl_CW": "荷兰语（库拉索岛）",
                "nl_NL": "荷兰语（荷兰）",
                "nl_SR": "荷兰语（苏里南）",
                "nl_SX": "荷兰语（圣马丁）",
                "nn": "挪威尼诺斯克语",
                "nn_NO": "挪威尼诺斯克语（挪威）",
                "no": "挪威",
                "no_NO": "挪威语（挪威）",
                "nr": "南恩德贝莱",
                "nv": "纳瓦霍族",
                "ny": "奇切瓦语",
                "oc": "奥克语",
                "oj": "奥吉布瓦",
                "om": "奥罗莫",
                "om_ET": "奥罗莫语（埃塞俄比亚）",
                "om_KE": "奥罗莫（肯尼亚）",
                "or": "奥里亚语",
                "or_IN": "奥里亚语（印度）",
                "os": "奥塞梯克",
                "os_GE": "奥塞蒂克（格鲁吉亚）",
                "os_RU": "奥塞梯克（俄罗斯）",
                "pa": "旁遮普语",
                "pa_Arab": "旁遮普语（阿拉伯语）",
                "pa_Arab_PK": "旁遮普语（阿拉伯语、巴基斯坦）",
                "pa_Guru": "旁遮普语（古尔穆奇语）",
                "pa_Guru_IN": "旁遮普语（印度古尔穆奇）",
                "pa_IN": "旁遮普语（印度）",
                "pa_PK": "旁遮普语（巴基斯坦）",
                "pi": "巴利语",
                "pl": "抛光",
                "pl_PL": "波兰语（波兰）",
                "ps": "普什图语",
                "ps_AF": "普什图语（阿富汗）",
                "pt": "葡萄牙语",
                "pt_AO": "葡萄牙语（安哥拉）",
                "pt_BR": "葡萄牙语（巴西）",
                "pt_CV": "葡萄牙语（佛得角）",
                "pt_GW": "葡萄牙语（几内亚比绍）",
                "pt_MO": "葡萄牙语（中国澳门特别行政区）",
                "pt_MZ": "葡萄牙语（莫桑比克）",
                "pt_PT": "葡萄牙语（葡萄牙）",
                "pt_ST": "葡萄牙语（圣多美和普林西比）",
                "pt_TL": "葡萄牙语（东帝汶）",
                "qu": "克丘亚语",
                "qu_BO": "克丘亚语（玻利维亚）",
                "qu_EC": "克丘亚语（厄瓜多尔）",
                "qu_PE": "克丘亚语（秘鲁）",
                "rm": "罗曼什语",
                "rm_CH": "罗曼什语（瑞士）",
                "rn": "伦迪",
                "rn_BI": "伦迪（布隆迪）",
                "ro": "罗马尼亚语",
                "ro_MD": "罗马尼亚语（摩尔多瓦）",
                "ro_RO": "罗马尼亚语（罗马尼亚）",
                "ru": "俄语",
                "ru_BY": "俄语（白俄罗斯）",
                "ru_KG": "俄语（吉尔吉斯斯坦）",
                "ru_KZ": "俄语（哈萨克斯坦）",
                "ru_MD": "俄语（摩尔多瓦）",
                "ru_RU": "俄语（俄罗斯）",
                "ru_UA": "俄语（乌克兰）",
                "rw": "基尼亚卢旺达语",
                "rw_RW": "基尼亚卢旺达语（卢旺达）",
                "sa": "梵文",
                "sc": "撒丁岛",
                "sd": "信德语",
                "se": "北萨米人",
                "se_FI": "北萨米语（芬兰）",
                "se_NO": "北萨米语（挪威）",
                "se_SE": "北萨米语（瑞典）",
                "sg": "三乡",
                "sg_CF": "桑戈（中非共和国）",
                "sh": "塞尔维亚-克罗地亚语",
                "sh_BA": "塞尔维亚-克罗地亚语（波斯尼亚和黑塞哥维那）",
                "si": "僧伽罗语",
                "si_LK": "僧伽罗语（斯里兰卡）",
                "sk": "斯洛伐克语",
                "sk_SK": "斯洛伐克（斯洛伐克）",
                "sl": "斯洛文尼亚语",
                "sl_SI": "斯洛文尼亚语（斯洛文尼亚）",
                "sm": "萨摩亚人",
                "sn": "绍纳语",
                "sn_ZW": "修纳语（津巴布韦）",
                "so": "索马里",
                "so_DJ": "索马里语（吉布提）",
                "so_ET": "索马里语（埃塞俄比亚）",
                "so_KE": "索马里语（肯尼亚）",
                "so_SO": "索马里语（索马里）",
                "sq": "阿尔巴尼亚语",
                "sq_AL": "阿尔巴尼亚语（阿尔巴尼亚）",
                "sq_MK": "阿尔巴尼亚语（马其顿）",
                "sq_XK": "阿尔巴尼亚语（科索沃）",
                "sr": "塞尔维亚",
                "sr_BA": "塞尔维亚语（波斯尼亚和黑塞哥维那）",
                "sr_Cyrl": "塞尔维亚语（西里尔字母）",
                "sr_Cyrl_BA": "塞尔维亚语（西里尔字母，波斯尼亚和黑塞哥维那）",
                "sr_Cyrl_ME": "塞尔维亚语（西里尔字母、黑山）",
                "sr_Cyrl_RS": "塞尔维亚语（西里尔字母，塞尔维亚）",
                "sr_Cyrl_XK": "塞尔维亚语（西里尔字母，科索沃）",
                "sr_Latn": "塞尔维亚语（拉丁语）",
                "sr_Latn_BA": "塞尔维亚语（拉丁语、波斯尼亚和黑塞哥维那）",
                "sr_Latn_ME": "塞尔维亚语（拉丁语、黑山）",
                "sr_Latn_RS": "塞尔维亚语（拉丁语，塞尔维亚）",
                "sr_Latn_XK": "塞尔维亚语（拉丁语，科索沃）",
                "sr_ME": "塞尔维亚语（黑山）",
                "sr_RS": "塞尔维亚语（塞尔维亚）",
                "sr_XK": "塞尔维亚语（科索沃）",
                "ss": "斯瓦蒂",
                "st": "索托，南部",
                "su": "巽他语",
                "sv": "瑞典",
                "sv_AX": "瑞典语（奥兰群岛）",
                "sv_FI": "瑞典语（芬兰）",
                "sv_SE": "瑞典语（瑞典）",
                "sw": "斯瓦希里语",
                "sw_KE": "斯瓦希里语（肯尼亚）",
                "sw_TZ": "斯瓦希里语（坦桑尼亚）",
                "sw_UG": "斯瓦希里语（乌干达）",
                "ta": "泰米尔语",
                "ta_IN": "泰米尔语（印度）",
                "ta_LK": "泰米尔语（斯里兰卡）",
                "ta_MY": "泰米尔语（马来西亚）",
                "ta_SG": "泰米尔语（新加坡）",
                "te": "泰卢固语",
                "te_IN": "泰卢固语（印度）",
                "tg": "塔吉克",
                "th": "泰国",
                "th_TH": "泰语（泰国）",
                "ti": "提格里尼亚",
                "ti_ER": "提格里尼亚语（厄立特里亚）",
                "ti_ET": "提格里尼亚语（埃塞俄比亚）",
                "tk": "土库曼语",
                "tl": "他加禄语",
                "tl_PH": "他加禄语（菲律宾）",
                "tn": "茨瓦纳语",
                "to": "汤加",
                "to_TO": "汤加（汤加）",
                "tr": "土耳其",
                "tr_CY": "土耳其语（塞浦路斯）",
                "tr_TR": "土耳其语（土耳其）",
                "ts": "特松加",
                "tt": "鞑靼人",
                "tw": "契维语",
                "ty": "大溪地",
                "ug": "维吾尔族",
                "ug_Arab": "维吾尔语（阿拉伯语）",
                "ug_Arab_CN": "维吾尔语（阿拉伯语，中国）",
                "ug_CN": "维吾尔族（中国）",
                "uk": "乌克兰",
                "uk_UA": "乌克兰语（乌克兰）",
                "ur": "乌尔都语",
                "ur_IN": "乌尔都语（印度）",
                "ur_PK": "乌尔都语（巴基斯坦）",
                "uz": "乌兹别克语",
                "uz_AF": "乌兹别克语（阿富汗）",
                "uz_Arab": "乌兹别克语（阿拉伯语）",
                "uz_Arab_AF": "乌兹别克语（阿拉伯语，阿富汗）",
                "uz_Cyrl": "乌兹别克语（西里尔字母）",
                "uz_Cyrl_UZ": "乌兹别克语（西里尔字母，乌兹别克斯坦）",
                "uz_Latn": "乌兹别克语（拉丁语）",
                "uz_Latn_UZ": "乌兹别克语（拉丁语，乌兹别克斯坦）",
                "uz_UZ": "乌兹别克语（乌兹别克斯坦）",
                "ve": "文达",
                "vi": "越南语",
                "vi_VN": "越南语（越南）",
                "vo": "沃拉普克",
                "wa": "瓦隆语",
                "wo": "沃洛夫语",
                "xh": "科萨语",
                "yi": "意第绪语",
                "yo": "约鲁巴语",
                "yo_BJ": "约鲁巴语（贝宁）",
                "yo_NG": "约鲁巴语（尼日利亚）",
                "za": "壮族",
                "zh": "中国人",
                "zh_CN": "中文（中国）",
                "zh_HK": "中文（中国香港特别行政区）",
                "zh_Hans": "简体中文）",
                "zh_Hans_CN": "中文（简体，中国）",
                "zh_Hans_HK": "中文（简体，中国香港特别行政区）",
                "zh_Hans_MO": "中文（简体，中国澳门特别行政区）",
                "zh_Hans_SG": "中文（简体，新加坡）",
                "zh_Hant": "中国传统的）",
                "zh_Hant_HK": "中文（繁体，中国香港特别行政区）",
                "zh_Hant_MO": "中文（繁体，中国澳门特别行政区）",
                "zh_Hant_TW": "中文（繁体，台湾）",
                "zh_MO": "中文（中国澳门特别行政区）",
                "zh_SG": "中文（新加坡）",
                "zh_TW": "中文（台湾）",
                "zu": "祖鲁语",
                "zu_ZA": "祖鲁语（南非）"
            },
            "mail": {
                "action": "如果您在单击“{actionText}”按钮时遇到问题，请将以下 URL 复制并粘贴到您的网络浏览器中：",
                "auth": {
                    "deleted": {
                        "line1": "我们通知您，您的 UgoZER 帐户已被删除。",
                        "reason": {
                            "intro": "该条已被删除，原因如下：",
                            "never_authenticated_for_too_long": "您的帐户是在一周多前创建的\n                    并且您从未登录过：\n                    因此该帐户被视为不活跃。",
                            "not_logged_for_too_long": "您已超过2年未登录：\n                    您的帐户被视为不活跃。"
                        },
                        "subject": "删除您的 UgoZER 帐户"
                    },
                    "login_attempt_unverified_credential": {
                        "action": "确认电子邮件地址",
                        "line1": "尝试使用电子邮件地址 {credential} 登录您的 UgoZER 帐户。",
                        "line2": "如果您发起此登录尝试，\n                请点击下面的按钮来验证您的电子邮件地址\n                并能够连接。",
                        "line3": "此链接将在 1 周后过期。",
                        "line4": "如果您没有发起此登录尝试，则无需采取进一步操作。",
                        "subject": "尝试登录您的 UgoZER 帐户"
                    },
                    "login_magic_link": {
                        "action": "登录",
                        "line1": "点击此链接登录",
                        "line2": "感谢您使用我们的应用程序！",
                        "subject": "无密码登录链接"
                    },
                    "not_logged": {
                        "intro": "距离您上次登录 UgoZER 帐户已经过去近 2 年了。",
                        "outro": "只需登录您的帐户即可，这样它就不会被删除。",
                        "remaining": {
                            "two_days": "您的帐户距离删除还有 2 天时间。",
                            "two_months": "您的帐户距离删除还有 2 个月的时间。",
                            "two_weeks": "您的帐户距离删除还有 2 周时间。"
                        },
                        "subject": "您的 UgoZER 帐户即将被删除"
                    },
                    "registration": {
                        "action": "确认电子邮件地址",
                        "line1": "请点击下面的按钮来验证您的电子邮件地址并能够登录。",
                        "line2": "此链接将在 1 周后过期。",
                        "line3": "如果您没有创建帐户，则无需采取进一步操作。",
                        "subject": "确认你的邮件地址"
                    },
                    "registration_attempt": {
                        "line1": "<strong>{credential}</strong> 电子邮件已用于我们应用程序中的 <strong>{role_type}</strong> 帐户。",
                        "line2": "要创建帐户，请使用另一封电子邮件重新开始注册。",
                        "subject": "尝试使用您的 UgoZER 凭证进行注册"
                    },
                    "registration_attempt_unverified_credential": {
                        "line1": "<strong>{credential}</strong> 电子邮件已用于我们应用程序中的 <strong>{role_type}</strong> 帐户。",
                        "line2": "要创建帐户，请使用另一封电子邮件重新开始注册。",
                        "subject": "尝试使用您的 UgoZER 凭证进行注册"
                    },
                    "reset_password": {
                        "action": "重设密码",
                        "line1": "您收到这封电子邮件是因为我们收到了您帐户的密码重置请求。",
                        "line2": "此密码重置链接将在 1 分钟后过期。",
                        "line3": "如果您没有请求重置密码，则无需采取进一步操作。",
                        "subject": "重置密码通知"
                    }
                },
                "copyright": "© {year} UgoZER - 保留所有权利。",
                "credential_verification": {
                    "verify": {
                        "action": "确认电子邮件地址",
                        "line1": "请点击下面的按钮来验证您的电子邮件地址。",
                        "line2": "此链接将在 1 周后过期。",
                        "line3": "如果您没有请求验证，则无需采取进一步操作。",
                        "subject": "确认您的电子邮件"
                    }
                },
                "hello": "你好！",
                "invitation": {
                    "attempt": {
                        "action": "访问应用程序",
                        "line1": "您已被邀请加入我们的申请",
                        "line1_by": " {name} 邀请您加入我们的申请",
                        "line1_by_from": "来自 {poi_title} 机构的 {name} 邀请您加入我们的申请",
                        "line2": "您已经在我们的应用程序中拥有一个现有帐户",
                        "line3": "请点击下面的按钮访问该应用程序。",
                        "subject": "邀请加入UgoZER"
                    },
                    "verification": {
                        "action": "确认电子邮件地址",
                        "excursion_shared": "行程已与您分享。您可以找到 PDF 格式的附件，\n                    以及您的股票申请中。",
                        "line1": "您已被邀请加入我们的申请",
                        "line1_by": " {name} 邀请您加入我们的申请",
                        "line1_by_from": "来自 {poi_title} 机构的 {name} 邀请您加入我们的申请",
                        "line2": "请点击下面的按钮来验证您的电子邮件地址。",
                        "line3": "此链接将在 1 周后过期。",
                        "line4": "感谢您使用我们的应用程序！",
                        "point_of_interest_shared": "已与您分享了一个兴趣点。你可以找到它\n                在您的股票申请中。",
                        "subject": "邀请加入UgoZER"
                    }
                },
                "invitation_professional": {
                    "content": "您已被邀请加入我们的应用程序 UgoZER 的专业部分",
                    "cta": "注册到专业版块",
                    "subject": "UgoZER - 邀请专业人士访问",
                    "thanks": "谢谢，",
                    "title": "邀请访问UgoZER应用程序的专业部分"
                },
                "preview": {
                    "default": {
                        "subject": "电子邮件主题",
                        "user": {
                            "name": "乌戈泽尔"
                        }
                    },
                    "from": "从：",
                    "mails": {
                        "auth/account-deleted-notification": "帐户删除",
                        "auth/not-logged-notification": "如果未连接则删除",
                        "auth/registration-attempt-notification": "尝试注册",
                        "auth/registration-notification": "登记",
                        "auth/reset-password-notification": "更改您的密码",
                        "invitation/invited-notification": "邀请函",
                        "share/created-notification": "新的",
                        "support-message/message-notification": "新答案",
                        "support-request/archived-notification": "已存档的请求",
                        "support-request/created-notification": "已创建请求",
                        "user-credential-verification/login-attempt-notification": "尝试连接",
                        "user-credential-verification/registration-attempt-notification": "尝试注册",
                        "user-credential-verification/verify-notification": "账户确认",
                        "user/welcome-notification": "欢迎"
                    },
                    "main_title": "电子邮件预览",
                    "sections": {
                        "auth": "验证",
                        "invitation": "邀请函",
                        "share": "分享",
                        "support-message": "支持消息",
                        "support-request": "支持请求",
                        "user": "用户",
                        "user-credential-verification": "用户凭证"
                    }
                },
                "salutations": "问候",
                "share": {
                    "created": {
                        "action": "展示",
                        "excursion": {
                            "gotoapp": "前往应用程序查看。",
                            "line": " {poi_title}机构的{username}与您分享了行程。",
                            "line_alt": "{username}与您分享了行程。",
                            "line_alt_2": "行程已与您分享。",
                            "subject": "行程已与您分享。"
                        },
                        "line": " {poi_title} 机构的 {username} 与您分享了一个项目。",
                        "line_alt": "{username} 与您分享了一个项目。",
                        "line_alt_2": "已与您共享一个项目。",
                        "point_of_interest": {
                            "line": " {poi_title} 机构的 {username} 与您分享了一个兴趣点。",
                            "line_alt": "{username} 与您分享了一个兴趣点。",
                            "line_alt_2": "已与您分享了一个兴趣点。",
                            "subject": "已与您分享了一个兴趣点。"
                        },
                        "subject": "已与您共享一个项目。"
                    }
                },
                "subscription": {
                    "not_continue_after_free_period": {
                        "cta": "查看详情",
                        "line1": "免费期结束后 {name} 未继续",
                        "line2": "请点击下面的按钮查看详细信息",
                        "subject": "免费期结束后订阅者未继续"
                    },
                    "suspended": {
                        "line": "您的订阅已因以下原因被暂停： {suspension_reason}。",
                        "line_alt": "您的订阅已暂停。",
                        "subject": "暂停订阅"
                    },
                    "switched_from_paid_to_free": {
                        "cta": "查看详情",
                        "line1": "{name} 切换到免费计划",
                        "line2": "请点击下面的按钮查看详细信息",
                        "subject": "订阅者切换到免费计划"
                    }
                },
                "support_request": {
                    "archived": {
                        "subject": "UgoZER - 支持请求已存档",
                        "title": "您的支持请求已存档"
                    },
                    "message": {
                        "action": "访问支持请求",
                        "line1": "请点击下面的按钮来访问您的请求。",
                        "subject": "UgoZER - 你有答案",
                        "title": "已针对您的支持请求发布答复"
                    },
                    "request": {
                        "action": "访问支持请求",
                        "line1": "联系支持人员将在 48 小时内回复。",
                        "line2": "请点击下面的按钮来访问您的请求。",
                        "subject": "UgoZER - 您的支持请求已创建"
                    }
                },
                "user_send_link": {
                    "content": "将您重新连接到我们的应用程序 UgoZER！如果您忘记了密码，可以重置密码。",
                    "cta": "登录应用程序",
                    "subject": "UgoZER - 应用",
                    "thanks": "谢谢，",
                    "title": "您在我们的应用程序上有一个帐户，请登录！"
                },
                "welcome": {
                    "action": "访问应用程序",
                    "line1": "我们很高兴您成为我们的用户之一。",
                    "line2": "单击下面的按钮访问该应用程序。",
                    "subject": "欢迎来到UgoZER",
                    "title": "欢迎使用 UgoZER，您的免费多语言旅行帮助应用程序。"
                }
            },
            "media": {
                "audio": {
                    "generated_audio": "生成的音频",
                    "recording": {
                        "errors": {
                            "default": "录音失败",
                            "not_allowed": "录音失败。允许您的浏览器和 UgoZER 使用您的麦克风，然后重试。"
                        }
                    }
                },
                "default": {
                    "alt": "Google 地图的静态地图",
                    "caption": "点击地图即可在 Google 地图上显示此内容"
                },
                "image": {
                    "credits": "学分： {credits}"
                }
            },
            "models": {
                "activity_log": "活动日志|活动日志",
                "address": "地址|地址",
                "bank_transfer": "银行转账|银行转账",
                "bank_transfer_institution": "银行转账机构|银行转账机构",
                "category": "类别|类别",
                "changelog": "变更日志|变更日志",
                "claim": "索赔|索赔",
                "claim_dispute": "索赔纠纷|索赔纠纷",
                "currency": "货币|货币",
                "data_tourisme_category": "数据旅游类别|数据旅游类别",
                "data_tourisme_tag": "DataTourisme 标签|DataTourisme 标签",
                "excursion": "行程|行程",
                "institution_employee": "员工|员工",
                "interaction": "互动|互动",
                "locale": "语言|语言",
                "medium": "媒体|媒体",
                "notification": "通知|通知",
                "partner": "合作伙伴|合作伙伴",
                "payment": "付款|付款",
                "permission": "权限|权限",
                "plan": "订阅计划|订阅计划",
                "plan_price": "计划价格|计划价格",
                "point_of_interest": "兴趣点|兴趣点",
                "point_of_interest_category": "类别|类别",
                "post": "帖子|帖子",
                "post_category": "帖子类别|帖子类别",
                "privacy_policy": "隐私政策|隐私政策",
                "report": "报告|报告",
                "reservation": "预订|预订",
                "role": "角色|角色",
                "share": "分享|分享",
                "subscription": "订阅|订阅",
                "support_category": "支持类别|支持类别",
                "support_message": "留言|留言",
                "support_request": "请求|请求",
                "tag": "标签|标签",
                "terms_condition": "条款和条件|条款和条件",
                "traveller_email": "旅客电子邮件|旅客电子邮件",
                "traveller_folder": "旅行夹|旅行夹",
                "user": "用户|用户",
                "user_credential_verification": "凭证验证|凭证验证",
                "user_geoloc_setting": "Geoloc 设置|Geoloc 设置",
                "user_notification_setting": "通知设置|通知设置",
                "user_password_reset": "用户密码重置|用户密码重置",
                "user_privacy_setting": "隐私设置|隐私设置",
                "users_relationship": "联系方式|联系方式",
                "vat_rate": "增值税税率|增值税税率"
            },
            "navigations": {
                "items": {
                    "changelogs": "变更日志",
                    "contact_us": "联系我们",
                    "contacts": "联系方式",
                    "cookies": "Cookie 管理",
                    "credits": "制作人员",
                    "customers": "他们信任我们",
                    "faqs": "常问问题",
                    "folders": "旅行文件夹",
                    "invite_professional": "邀请专业人士",
                    "invite_traveller": "邀请一位旅行者",
                    "partners": "伙伴",
                    "payments": "付款方式",
                    "privacy_policy": "隐私政策",
                    "settings": "参数",
                    "stays": "住宿",
                    "terms_conditions": "条款和条件",
                    "users": "用户"
                }
            },
            "notifications": {
                "changelog": {
                    "created": {
                        "text": "版本 {version}已部署。您可以通过点击通知来查看新闻。",
                        "title": "新的变更日志"
                    }
                },
                "claim_dispute": {
                    "pending": {
                        "text": "有关兴趣点 {poi} 的索赔争议正在等待我们团队的核实。",
                        "title": "索赔争议待核实"
                    },
                    "rejected": {
                        "no_reason": "没有给出理由",
                        "text": "针对 {poi} 兴趣点的索赔争议被拒绝，原因如下： {reason}。",
                        "title": "索赔争议被驳回"
                    },
                    "validated": {
                        "text": " {poi}兴趣点索赔纠纷已受理！",
                        "title": "索赔争议已验证！"
                    }
                },
                "index": {
                    "no_results": "没有通知！"
                },
                "institution_employee": {
                    "approved": {
                        "text": "您加入 {poi} 的请求已获得批准",
                        "title": "员工请求已获批准"
                    },
                    "declined": {
                        "text": "您加入 {poi} 的请求已被拒绝",
                        "title": "员工请求被拒绝"
                    },
                    "pending": {
                        "text": "{user} 请求注册为贵机构的雇员 {poi}",
                        "title": "收到员工请求"
                    },
                    "removed": {
                        "text": "您的 {poi} 员工状态已被删除",
                        "title": "员工身份已删除"
                    }
                },
                "invitation": {
                    "accepted": {
                        "email": {
                            "text": "您发送至 {credential} 的加入申请邀请已被接受"
                        },
                        "phone": {
                            "text": "您发送至 {credential} 的加入申请邀请已被接受"
                        },
                        "title": "已接受邀请"
                    },
                    "pro": {
                        "text": "您的专业邀请已发送至电子邮件 {email}",
                        "title": "已发送专业邀请函"
                    }
                },
                "office": {
                    "claim": {
                        "content": {
                            "line_1": "刚刚声明了一个兴趣点。",
                            "line_2": "单击下面的按钮可在管理界面中查看详细信息。"
                        },
                        "cta": "参见索赔",
                        "subject": "新主张！"
                    },
                    "claim_dispute": {
                        "content": {
                            "line_1": "刚刚对一个兴趣点进行了争论。",
                            "line_2": "单击下面的按钮可在管理界面中查看详细信息。"
                        },
                        "cta": "参见争议",
                        "subject": "新纠纷！"
                    },
                    "report": {
                        "content": {
                            "line_1": "{type} 刚刚被报道。"
                        },
                        "cta": "查看报告",
                        "subject": "新报告！",
                        "type": {
                            "App\\Models\\PointOfInterest": "一个兴趣点",
                            "App\\Models\\User": "一个用户"
                        }
                    },
                    "support_request": {
                        "content": {
                            "line_1": "刚刚添加了新的支持请求。"
                        },
                        "cta": "查看支持请求",
                        "subject": "新的支持请求！"
                    }
                },
                "payment": {
                    "proposal": {
                        "text": "通过留下小费来感谢 {poi_title} 机构的 {username}",
                        "text_alt": "通过留下小费来感谢 {poi_title} 机构的礼宾人员",
                        "text_alt_2": "感谢 {username} 留下小费",
                        "text_alt_3": "留下小费以感谢礼宾人员",
                        "title": "留下小费"
                    },
                    "received": {
                        "text": "您从 {poi_title} 机构收到 {payer} 的 {amount} 小费",
                        "text_alt": "您收到 {payer} 的 {amount} 小费",
                        "text_alt_2": "您收到 {amount} 小费",
                        "title": "收到小费"
                    },
                    "thanks": {
                        "text": "来自 {poi_title} 机构的 {payee} 感谢您的提示！",
                        "text_alt": "{payee} 谢谢你的提示！",
                        "text_alt_2": "感谢您的提示",
                        "title": "谢谢你的提示！"
                    }
                },
                "point_of_interest": {
                    "event": {
                        "expired": {
                            "text": "您行程中包含的活动 {title} 已过期",
                            "title": "活动已过期"
                        }
                    },
                    "moderation": {
                        "denied": {
                            "text": "您的兴趣点 {title} 在审核后已被拒绝",
                            "title": "兴趣点被拒绝"
                        },
                        "published": {
                            "text": "您的兴趣点 {title} 已在审核后发布",
                            "title": "已发布兴趣点"
                        }
                    }
                },
                "reservation": {
                    "created": {
                        "text": "机构{butler_name} {butler_poi_title} 已预订\n                在你的机构 {poi_title}",
                        "text_alt": "机构 {butler_poi_title} 的礼宾人员已进行预订\n                在你的机构 {poi_title}",
                        "text_alt_2": "{butler_name}已预订\n                在你的机构 {poi_title}",
                        "text_alt_3": "已预订\n                在您的机构 {poi_title}",
                        "title": "在您的机构预订"
                    }
                },
                "share": {
                    "created": {
                        "excursion": {
                            "text": " {poi_title}机构的{username}与您分享了行程",
                            "text_alt": "{username}与您分享了行程",
                            "text_alt_2": "已与您分享行程",
                            "title": "分享行程"
                        },
                        "point_of_interest": {
                            "text": "来自 {poi_title} 机构的 {username} 与您分享了一个兴趣点",
                            "text_alt": "{username} 与您分享了一个兴趣点",
                            "text_alt_2": "已与您分享一个兴趣点",
                            "title": "分享兴趣点"
                        },
                        "text": "来自 {poi_title} 机构的 {username} 与您分享了一个项目",
                        "text_alt": "{username} 与您分享了一个项目",
                        "text_alt_2": "已与您共享一个项目"
                    }
                },
                "stripe": {
                    "account_requirements": {
                        "text": "验证您的 Stripe 帐户后，需要提供新信息",
                        "title": "Stripe 账户要求"
                    },
                    "payments_enabled": {
                        "text": "您现在可以通过 Stripe 接收付款",
                        "title": "启用 Stripe 支付"
                    }
                },
                "support_request": {
                    "archived": {
                        "text": "您的支持请求已存档",
                        "title": "支持请求已存档"
                    },
                    "message": {
                        "text": "您的支持请求的答复已发布",
                        "title": "收到消息"
                    }
                },
                "traveller_folder": {
                    "approved": {
                        "text": "{username} 已批准他的旅行者文件夹",
                        "text_alt": "旅行者已批准他的文件夹",
                        "title": "旅行者文件夹已获批准"
                    },
                    "archived": {
                        "text": "{username} 已存档他的旅行者文件夹",
                        "text_alt": "旅行者已存档他的文件夹",
                        "title": "旅行者文件夹已存档"
                    },
                    "created": {
                        "text": "您的帐户已在酒店 {poi_title} 登记住宿。",
                        "title": "保持创作状态"
                    },
                    "pending": {
                        "text": "{username} 已为您的帐户注册了在酒店 {poi_title} 的住宿。\n                请证实。",
                        "text_alt": "您的帐户已在酒店 {poi_title} 登记住宿。\n                请证实。",
                        "title": "保持等待确认"
                    },
                    "thanks": {
                        "text": "{username} 来自 {poi_title} 机构感谢您的入住",
                        "text_alt": " {poi_title}机构的礼宾人员感谢您的入住",
                        "title": "感谢您的入住"
                    }
                }
            },
            "nova": {
                "actions": {
                    "bank_transfer": {
                        "transfer": "进行银行转账"
                    },
                    "changelog": {
                        "archive": "档案",
                        "draft": "草稿",
                        "publish": "发布"
                    },
                    "claim": {
                        "missing_info": "缺少信息",
                        "reject": "拒绝",
                        "revoke": "撤销",
                        "validate": "证实"
                    },
                    "claim_dispute": {
                        "reject": "拒绝",
                        "validate": "证实"
                    },
                    "payment": {
                        "download_invoice": "下载发票"
                    },
                    "plan": {
                        "archive": "档案",
                        "draft": "草稿",
                        "publish": "发布"
                    },
                    "point_of_interest": {
                        "deny": "否定",
                        "draft": "草稿",
                        "publish": "证实",
                        "submit": "要求节制"
                    },
                    "post": {
                        "archive": "档案",
                        "draft": "草稿",
                        "publish": "发布",
                        "set_coming_soon": "设置为“即将推出”"
                    },
                    "privacy_policy": {
                        "archive": "档案",
                        "confirmation": {
                            "confirm_button_text": "发布",
                            "confirm_text": "您确定要发布此隐私政策吗？所有用户都将被要求再次验证！"
                        },
                        "errors": {
                            "resources_count": "您不能发布超过一项私人政策！"
                        },
                        "publish": "发布"
                    },
                    "report": {
                        "process": "过程",
                        "reject": "拒绝"
                    },
                    "support_request": {
                        "answer": "回答",
                        "archive": "档案",
                        "cancel": "取消",
                        "pending_support": "等待支持",
                        "pending_user": "等待用户",
                        "resolve": "解决"
                    },
                    "terms_condition": {
                        "archive": "档案",
                        "confirmation": {
                            "confirm_button_text": "发布",
                            "confirm_text": "您确定要发布此条款和条件吗？所有用户都将被要求再次验证！"
                        },
                        "errors": {
                            "resources_count": "您不能发布超过一项条款和条件！"
                        },
                        "publish": "发布"
                    },
                    "user": {
                        "finish": "完成教程"
                    },
                    "user_credential_verification": {
                        "validate": "证实"
                    }
                },
                "cards": {
                    "new_excursions": "新行程",
                    "new_points_of_interest": "新兴趣点",
                    "new_users": "新用户"
                },
                "filters": {
                    "app": "应用",
                    "archived_at_gte": "存档于之后",
                    "archived_at_lte": "存档于之前",
                    "canceled_at_gte": "之后取消",
                    "canceled_at_lte": "之前取消",
                    "created_at_gte": "创建于之后",
                    "created_at_lte": "之前创建过",
                    "dynamic_translations_enabled": "启用动态翻译",
                    "extranet": "外联网",
                    "front": "正面",
                    "group": "团体",
                    "published_at_gte": "发表于",
                    "published_at_lte": "之前发表过",
                    "reservation_date_gte": "预订时间之后",
                    "reservation_date_lte": "之前预约",
                    "resolved_at_gte": "之后解决",
                    "resolved_at_lte": "之前解决过",
                    "static_translations_enabled": "启用静态翻译",
                    "to_validate": "验证"
                },
                "labels": {
                    "institutions": "机构",
                    "point_of_interest_categories": "类别"
                }
            },
            "opening_hours": {
                "additional_info": "附加信息",
                "content": {
                    "date": " {date}",
                    "date_range_from": "从 {from}",
                    "date_range_from_to": "从 {from}到 {to}",
                    "date_range_to": "到 {to}",
                    "hours_range_from": "从 {from}",
                    "hours_range_from_to": "从 {from}到 {to}",
                    "hours_range_to": "到 {to}"
                }
            },
            "partners": {
                "customers": {
                    "title": "我们的顾客"
                },
                "index": {
                    "no_results": "未找到合作伙伴",
                    "our_last_partners": "我们最后的合作伙伴",
                    "title": "我们的伙伴"
                },
                "show": {
                    "link": "访问网站"
                }
            },
            "payments": {
                "checkout": {
                    "cancel": "付款已取消",
                    "custom_amount": "输入自定义金额",
                    "success": "付款成功",
                    "tip_amount": "小费金额"
                },
                "index": {
                    "no_results": "未发现付款"
                },
                "labels": {
                    "payee": "收款人：",
                    "payment_service": "付款人"
                },
                "payable": {
                    "reservation": "预订号码：id"
                },
                "thanks": {
                    "success": "感谢发送！"
                }
            },
            "pdf": {
                "common": {
                    "generating": "正在生成 PDF 文件...",
                    "waiting": "请稍等..."
                },
                "payments": {
                    "period": "从 {from} 到 {to} 的周期",
                    "title": "已收到付款"
                },
                "receipt": {
                    "debited_amount": "借记金额",
                    "paid_amount": "已付金额",
                    "payment_date": "付款日期",
                    "payment_method": "付款方式",
                    "receipt_for": "付款收据至",
                    "receipt_number": "付款收据#{id}",
                    "summary": "概括"
                },
                "share": {
                    "cta": "咨询行程",
                    "details": {
                        "line1": "我们很高兴与您分享您的个性化行程。",
                        "line2": "您将在下面找到您的行程内容。",
                        "line3": "要访问详细信息（带有当地语言地址的音频、交互式地图、\n                图片、完整描述...），单击下面的按钮。"
                    }
                }
            },
            "permissions": {
                "descriptions": {
                    "App\\Enums\\Permissions\\AccessEnum": {
                        "ADMIN": "进入管理",
                        "APP": "访问应用程序",
                        "BYPASS_PERMISSIONS": "忽略与访问相关的权限",
                        "EXTRANET": "进入专业版块",
                        "HORIZON": "访问地平线",
                        "TELESCOPE": "接入望远镜",
                        "VIEW_BUTLER_DASHBOARD": "查看管家仪表板",
                        "VIEW_HOTELIER_DASHBOARD": "查看酒店经营者仪表板",
                        "VIEW_MANAGER_DASHBOARD": "查看经理仪表板",
                        "VIEW_TOURIST_OFFICE_DASHBOARD": "查看旅游局仪表板",
                        "VIEW_TOURIST_OFFICE_EMPLOYEE_DASHBOARD": "查看旅游局员工仪表板",
                        "WITHOUT_CREDENTIAL_VERIFICATION": "无需验证您的凭据即可登录"
                    },
                    "App\\Enums\\Permissions\\ActivityLogEnum": {
                        "BYPASS_PERMISSIONS": "忽略与活动日志相关的权限",
                        "CREATE": "创建活动日志",
                        "DELETE": "删除活动日志",
                        "FORCE_DELETE": "强制删除活动日志",
                        "RESTORE": "恢复活动日志",
                        "UPDATE": "更新活动日志",
                        "VIEW": "查阅活动日志",
                        "VIEW_ANY": "查询活动日志索引"
                    },
                    "App\\Enums\\Permissions\\AddressEnum": {
                        "BYPASS_PERMISSIONS": "忽略与地址相关的权限",
                        "CREATE": "创建一个地址",
                        "DELETE": "删除地址",
                        "FORCE_DELETE": "强制删除地址",
                        "RESTORE": "恢复地址",
                        "UPDATE": "更新地址",
                        "VIEW": "咨询地址",
                        "VIEW_ANY": "查阅地址索引"
                    },
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": {
                        "BYPASS_PERMISSIONS": "忽略银行转账机构相关权限",
                        "VIEW_ANY": "查询银行转账机构索引"
                    },
                    "App\\Enums\\Permissions\\ChangelogEnum": {
                        "ARCHIVE": "存档变更日志",
                        "BYPASS_PERMISSIONS": "忽略与变更日志相关的权限",
                        "CREATE": "创建变更日志",
                        "DELETE": "删除变更日志",
                        "DRAFT": "起草变更日志",
                        "FORCE_DELETE": "强制删除更新日志",
                        "PUBLISH": "发布变更日志",
                        "RESTORE": "恢复变更日志",
                        "UPDATE": "更新变更日志",
                        "VIEW": "查阅变更日志",
                        "VIEW_ANY": "查阅变更日志索引"
                    },
                    "App\\Enums\\Permissions\\ClaimDisputeEnum": {
                        "BYPASS_PERMISSIONS": "忽略与索赔争议相关的权限",
                        "CREATE": "创建索赔争议",
                        "DELETE": "删除索赔争议",
                        "FORCE_DELETE": "强制删除索赔争议",
                        "REJECT": "拒绝索赔争议",
                        "RESTORE": "恢复索赔争议",
                        "UPDATE": "更新索赔争议",
                        "VALIDATE": "验证索赔争议",
                        "VIEW": "咨询理赔纠纷",
                        "VIEW_ANY": "索偿纠纷查询索引"
                    },
                    "App\\Enums\\Permissions\\ClaimEnum": {
                        "BYPASS_PERMISSIONS": "忽略与声明相关的权限",
                        "CREATE": "创建索赔",
                        "DELETE": "删除索赔",
                        "FORCE_DELETE": "强制删除声明",
                        "MISSING_INFO": "拒绝作为缺失信息的索赔",
                        "REJECT": "拒绝索赔",
                        "RESTORE": "恢复索赔",
                        "REVOKE": "撤销索赔",
                        "SHOW_FILE": "显示索赔文件",
                        "UPDATE": "更新索赔",
                        "VALIDATE": "验证声明",
                        "VIEW": "咨询理赔",
                        "VIEW_ANY": "查阅理赔索引"
                    },
                    "App\\Enums\\Permissions\\CountryEnum": {
                        "BYPASS_PERMISSIONS": "忽略与国家相关的权限",
                        "USE_ANY": "使用任何国家"
                    },
                    "App\\Enums\\Permissions\\CurrencyEnum": {
                        "BYPASS_PERMISSIONS": "忽略与货币相关的权限",
                        "CREATE": "创建货币",
                        "DELETE": "删除货币",
                        "FORCE_DELETE": "强制删除货币",
                        "RESTORE": "恢复货币",
                        "UPDATE": "更新货币",
                        "VIEW": "咨询货币",
                        "VIEW_ANY": "查询货币指数"
                    },
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "忽略与 EasyTransac 帐户相关的权限",
                        "HAVE": "拥有 EasyTransac 账户"
                    },
                    "App\\Enums\\Permissions\\ExcursionEnum": {
                        "BYPASS_PERMISSIONS": "忽略与行程相关的权限",
                        "CREATE": "创建行程",
                        "DELETE": "删除行程",
                        "FORCE_DELETE": "强制删除行程",
                        "RESTORE": "恢复行程",
                        "SHARE": "分享行程",
                        "UPDATE": "更新行程",
                        "VIEW": "咨询行程",
                        "VIEW_ANY": "查阅行程索引",
                        "VIEW_KIND_UGOZER_APP": "使用 UgoZER 应用程序查看行程",
                        "VIEW_KIND_UGOZER_PRO": "使用 UgoZER Pro 类型查看行程"
                    },
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": {
                        "BYPASS_PERMISSIONS": "忽略与行程和兴趣点之间的链接相关的权限",
                        "CREATE": "在行程和兴趣点之间创建链接",
                        "DELETE": "删除行程和兴趣点之间的链接",
                        "FORCE_DELETE": "强制删除行程和兴趣点之间的链接",
                        "RESTORE": "恢复行程和兴趣点之间的链接",
                        "UPDATE": "更新行程和兴趣点之间的链接",
                        "VIEW": "查看行程和兴趣点之间的链接",
                        "VIEW_ANY": "查阅行程和兴趣点之间的链接索引"
                    },
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": {
                        "APPROVE": "批准机构员工请求",
                        "BYPASS_PERMISSIONS": "忽略与机构员工相关的权限",
                        "CANCEL": "取消机构员工请求",
                        "CREATE": "创建机构员工",
                        "DECLINE": "拒绝机构员工的请求",
                        "DELETE": "删除机构员工",
                        "FEATURE": "推荐来自机构的员工",
                        "FORCE_DELETE": "强制删除机构员工",
                        "REMOVE": "从机构中删除员工",
                        "RESTORE": "恢复机构员工",
                        "UPDATE": "更新机构员工",
                        "VIEW": "咨询机构员工",
                        "VIEW_ANY": "查阅机构从业人员指数"
                    },
                    "App\\Enums\\Permissions\\InteractionEnum": {
                        "ARCHIVE": "存档交互",
                        "BYPASS_PERMISSIONS": "忽略与交互相关的权限",
                        "CREATE": "创建交互",
                        "DELETE": "删除交互",
                        "DRAFT": "起草互动",
                        "FORCE_DELETE": "强制删除交互",
                        "PUBLISH": "发布交互",
                        "RESTORE": "恢复交互",
                        "UPDATE": "更新交互",
                        "VIEW": "咨询互动",
                        "VIEW_ANY": "查阅互动指数"
                    },
                    "App\\Enums\\Permissions\\LocaleEnum": {
                        "BYPASS_PERMISSIONS": "忽略与语言相关的权限",
                        "CREATE": "创建语言",
                        "DELETE": "删除语言",
                        "FORCE_DELETE": "强制删除语言",
                        "RESTORE": "恢复语言",
                        "UPDATE": "更新语言",
                        "VIEW": "咨询语言",
                        "VIEW_ANY": "查阅语言索引"
                    },
                    "App\\Enums\\Permissions\\MediumEnum": {
                        "BYPASS_PERMISSIONS": "忽略与媒体相关的权限",
                        "CREATE": "创建媒体",
                        "DELETE": "删除媒体",
                        "FORCE_DELETE": "强制删除媒体",
                        "RESTORE": "恢复媒体",
                        "UPDATE": "更新媒体",
                        "VIEW": "咨询媒体",
                        "VIEW_ANY": "查阅媒体索引"
                    },
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": {
                        "INVITATION_ACCEPTED": "接受邀请时收到通知",
                        "PAYMENT_RECEIVED": "收到付款时收到通知",
                        "PAYMENT_THANKS": "确认付款后收到通知",
                        "POINT_OF_INTEREST_EVENT_EXPIRED": "当兴趣点事件到期时收到通知",
                        "RESERVATION_CREATED": "创建预订时收到通知",
                        "SHARE_CREATED": "创建共享时收到通知",
                        "SUPPORT_REQUEST_ARCHIVED": "归档支持请求时收到通知",
                        "SUPPORT_REQUEST_MESSAGE_RECEIVED": "当收到来自支持人员的消息时收到通知\n                要求",
                        "TRAVELLER_FOLDER_APPROVED": "当旅行者档案获得批准时收到通知",
                        "TRAVELLER_FOLDER_ARCHIVED": "归档旅行者文件时收到通知",
                        "TRAVELLER_FOLDER_PENDING": "在等待文件验证旅行者时收到通知",
                        "TRAVELLER_FOLDER_THANKS": "收到通知感谢负责文件旅行者的礼宾部"
                    },
                    "App\\Enums\\Permissions\\PartnerEnum": {
                        "BYPASS_PERMISSIONS": "忽略与合作伙伴相关的权限",
                        "CREATE": "创建合作伙伴",
                        "DELETE": "删除伙伴",
                        "FORCE_DELETE": "强制删除伙伴",
                        "RESTORE": "恢复合作伙伴",
                        "UPDATE": "更新合作伙伴",
                        "VIEW": "咨询合作伙伴",
                        "VIEW_ANY": "合作伙伴咨询索引"
                    },
                    "App\\Enums\\Permissions\\PaymentEnum": {
                        "BYPASS_PERMISSIONS": "忽略与付款相关的权限",
                        "CREATE": "创建付款",
                        "DELETE": "删除付款",
                        "FORCE_DELETE": "强制删除付款",
                        "GET_RECEIPT": "收到付款收据",
                        "RECEIVE": "接收付款",
                        "RESTORE": "恢复付款",
                        "THANK": "收到付款感谢信",
                        "UPDATE": "更新付款",
                        "VIEW": "咨询付款",
                        "VIEW_ANY": "查阅支付指数"
                    },
                    "App\\Enums\\Permissions\\PermissionEnum": {
                        "BYPASS_PERMISSIONS": "忽略与权限相关的权限",
                        "CREATE": "创建权限",
                        "DELETE": "删除权限",
                        "FORCE_DELETE": "强制删除权限",
                        "RESTORE": "恢复权限",
                        "UPDATE": "更新权限",
                        "VIEW": "查阅权限",
                        "VIEW_ANY": "查阅权限索引"
                    },
                    "App\\Enums\\Permissions\\PlanEnum": {
                        "ARCHIVE": "归档订阅计划",
                        "BYPASS_PERMISSIONS": "忽略与订阅计划相关的权限",
                        "CREATE": "创建订阅计划",
                        "DELETE": "删除订阅计划",
                        "DRAFT": "起草认购计划",
                        "FORCE_DELETE": "强制删除订阅计划",
                        "PUBLISH": "发布订阅计划",
                        "RESTORE": "恢复订阅计划",
                        "SUBSCRIBE": "订阅计划",
                        "UPDATE": "更新订阅计划",
                        "VIEW": "咨询订阅计划",
                        "VIEW_ANY": "查阅订阅计划索引"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": {
                        "BYPASS_PERMISSIONS": "忽略与兴趣点类别相关的权限",
                        "CREATE": "创建兴趣点类别",
                        "DELETE": "删除兴趣点类别",
                        "FORCE_DELETE": "强制删除兴趣点类别",
                        "RESTORE": "恢复兴趣点类别",
                        "UPDATE": "更新兴趣点类别",
                        "VIEW": "查阅兴趣点类别",
                        "VIEW_ANY": "查阅兴趣点类别索引"
                    },
                    "App\\Enums\\Permissions\\PointOfInterestEnum": {
                        "ADMIN_ANY": "管理任何兴趣点",
                        "APPLY": "申请成为员工",
                        "BYPASS_PERMISSIONS": "忽略与兴趣点相关的权限",
                        "CLAIM": "声明兴趣点",
                        "CONTEST": "争夺兴趣点",
                        "CONTRIBUTE": "为兴趣点做出贡献",
                        "CREATE": "创建兴趣点",
                        "DELETE": "删除兴趣点",
                        "DENY": "否认兴趣点",
                        "DRAFT": "草拟兴趣点",
                        "FORCE_DELETE": "强制删除兴趣点",
                        "IMPORT": "导入兴趣点",
                        "MODERATE": "调节兴趣点",
                        "PUBLISH": "发布兴趣点",
                        "REPORT": "报告兴趣点",
                        "RESTORE": "恢复兴趣点",
                        "SHARE": "分享兴趣点",
                        "SUBMIT": "提交兴趣点",
                        "UPDATE": "更新兴趣点",
                        "UPDATE_CERTIFIED": "更新兴趣点的认证属性",
                        "VIEW": "咨询一个兴趣点",
                        "VIEW_ANY": "查阅兴趣点索引"
                    },
                    "App\\Enums\\Permissions\\PostCategoryEnum": {
                        "BYPASS_PERMISSIONS": "忽略与帖子类别相关的权限",
                        "CREATE": "创建帖子类别",
                        "DELETE": "删除帖子类别",
                        "FORCE_DELETE": "强制删除帖子类别",
                        "RESTORE": "恢复帖子类别",
                        "UPDATE": "更新帖子类别",
                        "VIEW": "查阅帖子类别",
                        "VIEW_ANY": "查阅帖子类别索引"
                    },
                    "App\\Enums\\Permissions\\PostEnum": {
                        "ARCHIVE": "存档帖子",
                        "BYPASS_PERMISSIONS": "忽略与帖子相关的权限",
                        "CREATE": "创建帖子",
                        "DELETE": "删除帖子",
                        "DRAFT": "起草一个帖子",
                        "FORCE_DELETE": "强制删除帖子",
                        "PUBLISH": "发布帖子",
                        "RESTORE": "恢复帖子",
                        "UPDATE": "更新帖子",
                        "VIEW": "咨询一个帖子",
                        "VIEW_ANY": "参考帖子索引"
                    },
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": {
                        "ARCHIVE": "存档隐私政策",
                        "BYPASS_PERMISSIONS": "忽略与隐私政策相关的权限",
                        "CREATE": "创建隐私政策",
                        "DELETE": "删除隐私政策",
                        "DRAFT": "起草隐私政策",
                        "FORCE_DELETE": "强制删除隐私政策",
                        "PUBLISH": "发布隐私政策",
                        "RESTORE": "恢复隐私政策",
                        "UPDATE": "更新隐私政策",
                        "VIEW": "咨询隐私政策",
                        "VIEW_ANY": "咨询隐私政策"
                    },
                    "App\\Enums\\Permissions\\RegistrationEnum": {
                        "EXTRANET": "使用具有此权限的任何角色注册到专业部分"
                    },
                    "App\\Enums\\Permissions\\ReportEnum": {
                        "ARCHIVE": "存档报告",
                        "BYPASS_PERMISSIONS": "忽略与报告相关的权限",
                        "CREATE": "创建报告",
                        "DELETE": "删除报告",
                        "DRAFT": "起草报告",
                        "FORCE_DELETE": "强制删除报告",
                        "PROCESS_ANY": "处理任何报告",
                        "PUBLISH": "发布报告",
                        "REJECT_ANY": "拒绝任何报告",
                        "RESTORE": "恢复报告",
                        "UPDATE": "更新报告",
                        "VIEW": "查阅报告",
                        "VIEW_ANY": "查阅报告索引"
                    },
                    "App\\Enums\\Permissions\\ReservationEnum": {
                        "ARCHIVE": "存档预订",
                        "BYPASS_PERMISSIONS": "忽略与保留相关的权限",
                        "CANCEL": "取消预订",
                        "CONFIRM": "确认预订",
                        "CREATE": "创建预订",
                        "DELETE": "删除预订",
                        "FORCE_DELETE": "强制删除预订",
                        "RESTORE": "恢复预订",
                        "UPDATE": "更新预订",
                        "VIEW": "咨询预订",
                        "VIEW_ANY": "查询预订索引"
                    },
                    "App\\Enums\\Permissions\\RoleEnum": {
                        "BYPASS_PERMISSIONS": "忽略与角色相关的权限",
                        "CREATE": "创建角色",
                        "DELETE": "删除角色",
                        "FORCE_DELETE": "强制删除角色",
                        "RESTORE": "恢复角色",
                        "UPDATE": "更新角色",
                        "VIEW": "咨询角色",
                        "VIEW_ANY": "查阅角色索引"
                    },
                    "App\\Enums\\Permissions\\ShareEnum": {
                        "BYPASS_PERMISSIONS": "忽略与共享相关的权限",
                        "CREATE": "创建共享",
                        "DELETE": "删除共享",
                        "FORCE_DELETE": "强制删除共享",
                        "RESTORE": "恢复共享",
                        "UPDATE": "更新分享",
                        "VIEW": "咨询分享",
                        "VIEW_ANY": "查阅股指"
                    },
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": {
                        "BYPASS_PERMISSIONS": "忽略与 Stripe 帐户相关的权限",
                        "HAVE": "有一个条纹帐户"
                    },
                    "App\\Enums\\Permissions\\SubscriptionEnum": {
                        "BYPASS_PERMISSIONS": "忽略与订阅相关的权限",
                        "CANCEL": "取消订阅",
                        "CREATE": "创建订阅",
                        "DELETE": "删除订阅",
                        "FORCE_DELETE": "强制删除订阅",
                        "PAY": "支付订阅费用",
                        "RESTORE": "恢复订阅",
                        "UPDATE": "更新订阅",
                        "VIEW": "咨询订阅",
                        "VIEW_ANY": "查阅订阅索引"
                    },
                    "App\\Enums\\Permissions\\SupportCategoryEnum": {
                        "BYPASS_PERMISSIONS": "忽略与支持类别请求相关的权限",
                        "CREATE": "创建支持类别",
                        "DELETE": "删除支持类别",
                        "FORCE_DELETE": "强制删除支持类别",
                        "RESTORE": "恢复支持类别",
                        "UPDATE": "更新支持类别",
                        "VIEW": "咨询支持类别",
                        "VIEW_ANY": "查阅支持类别索引"
                    },
                    "App\\Enums\\Permissions\\SupportMessageEnum": {
                        "BYPASS_PERMISSIONS": "忽略与消息相关的权限",
                        "CREATE": "创建消息",
                        "DELETE": "删除消息",
                        "FORCE_DELETE": "强制删除消息",
                        "RESTORE": "恢复消息",
                        "UPDATE": "更新消息",
                        "VIEW": "咨询留言",
                        "VIEW_ANY": "查阅留言索引"
                    },
                    "App\\Enums\\Permissions\\SupportRequestEnum": {
                        "ADD_MESSAGE": "添加消息",
                        "BYPASS_PERMISSIONS": "忽略与支持请求相关的权限",
                        "CANCEL": "取消支持请求",
                        "CREATE": "创建支持请求",
                        "DELETE": "删除支持请求",
                        "FORCE_DELETE": "强制删除支持请求",
                        "RESTORE": "恢复支持请求",
                        "UPDATE": "更新支持请求",
                        "VIEW": "咨询支持请求",
                        "VIEW_ANY": "咨询支持请求索引"
                    },
                    "App\\Enums\\Permissions\\TagEnum": {
                        "BYPASS_PERMISSIONS": "忽略与标签相关的权限",
                        "CREATE": "创建标签",
                        "DELETE": "删除标签",
                        "FORCE_DELETE": "强制删除标签",
                        "RESTORE": "恢复标签",
                        "UPDATE": "更新标签",
                        "VIEW": "查阅标签",
                        "VIEW_ANY": "查阅标签索引"
                    },
                    "App\\Enums\\Permissions\\TermsConditionEnum": {
                        "ARCHIVE": "存档条款和条件",
                        "BYPASS_PERMISSIONS": "忽略与条款和条件相关的权限",
                        "CREATE": "创建条款和条件",
                        "DELETE": "删除条款和条件",
                        "DRAFT": "起草条款和条件",
                        "FORCE_DELETE": "强制删除条款和条件",
                        "PUBLISH": "发布条款和条件",
                        "RESTORE": "恢复条款和条件",
                        "UPDATE": "更新条款和条件",
                        "VIEW": "查阅条款和条件",
                        "VIEW_ANY": "查阅条款和条件"
                    },
                    "App\\Enums\\Permissions\\TranslatableEnum": {
                        "BYPASS_PERMISSIONS": "忽略与可翻译记录相关的权限",
                        "REQUIRE_ANY": "允许用不一定是英语的语言翻译必填字段"
                    },
                    "App\\Enums\\Permissions\\TravellerEmailEnum": {
                        "BYPASS_PERMISSIONS": "忽略与旅行者电子邮件相关的权限",
                        "CREATE": "创建旅行者电子邮件",
                        "DELETE": "删除旅行者电子邮件",
                        "FORCE_DELETE": "强制删除旅行者电子邮件",
                        "RESTORE": "恢复旅行者电子邮件",
                        "UPDATE": "更新旅客电子邮件",
                        "VIEW": "查阅旅行者电子邮件",
                        "VIEW_ANY": "查阅旅客电子邮件索引"
                    },
                    "App\\Enums\\Permissions\\TravellerFolderEnum": {
                        "APPROVE": "批准旅行者文件夹",
                        "ARCHIVE": "存档旅行者文件夹",
                        "BYPASS_PERMISSIONS": "忽略与旅行者文件夹相关的权限",
                        "CREATE": "创建旅行者文件夹",
                        "DECLINE": "拒绝旅行者文件夹",
                        "DELETE": "删除旅行者文件夹",
                        "FORCE_DELETE": "强制删除旅行者文件夹",
                        "RESTORE": "恢复旅行者文件夹",
                        "THANK": "感谢一位旅客的入住",
                        "UPDATE": "更新旅行者文件夹",
                        "VIEW": "查阅旅行文件夹",
                        "VIEW_ANY": "查阅旅行者文件夹索引"
                    },
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": {
                        "BYPASS_PERMISSIONS": "忽略与用户凭据验证相关的权限",
                        "CREATE": "创建用户凭证验证",
                        "DELETE": "删除用户凭证验证",
                        "FORCE_DELETE": "强制删除用户凭据验证",
                        "RESTORE": "恢复用户凭证验证",
                        "UPDATE": "更新用户凭证验证",
                        "VIEW": "咨询用户凭证验证",
                        "VIEW_ANY": "查阅用户凭证验证索引"
                    },
                    "App\\Enums\\Permissions\\UserEnum": {
                        "ADD_CONTACT": "添加用户作为联系人",
                        "BYPASS_PERMISSIONS": "忽略与用户相关的权限",
                        "CREATE": "创建用户",
                        "DELETE": "删除用户",
                        "FORCE_DELETE": "强制删除用户",
                        "INVITE": "邀请用户",
                        "REPORT": "举报用户",
                        "RESTORE": "恢复用户",
                        "TIP": "提示用户",
                        "UPDATE": "更新一个用户",
                        "UPDATE_BANK_ACCOUNT": "更新银行账户",
                        "VIEW": "咨询用户",
                        "VIEW_ANY": "查阅用户索引",
                        "VIEW_AVATAR": "查看用户头像",
                        "VIEW_CONTACT_EMAIL": "查看用户的联系电子邮件",
                        "VIEW_CONTACT_PHONE": "查看用户的联系电话",
                        "VIEW_REAL_NAME": "查看用户的真实姓名"
                    },
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": {
                        "BYPASS_PERMISSIONS": "忽略与用户 geoloc 设置相关的权限",
                        "CREATE": "创建用户 geoloc 设置",
                        "DELETE": "删除用户 geoloc 设置",
                        "FORCE_DELETE": "强制删除用户 geoloc 设置",
                        "RESTORE": "恢复用户 geoloc 设置",
                        "UPDATE": "更新用户 geoloc 设置",
                        "VIEW": "咨询用户 geoloc 设置",
                        "VIEW_ANY": "查阅用户 geoloc 设置的索引"
                    },
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": {
                        "BYPASS_PERMISSIONS": "忽略与用户通知设置相关的权限",
                        "CREATE": "创建用户通知设置",
                        "DELETE": "删除用户通知设置",
                        "FORCE_DELETE": "强制删除用户通知设置",
                        "RESTORE": "恢复用户通知设置",
                        "UPDATE": "更新用户通知设置",
                        "VIEW": "查阅用户通知设置",
                        "VIEW_ANY": "查阅用户通知设置索引"
                    },
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": {
                        "BYPASS_PERMISSIONS": "忽略与用户隐私设置相关的权限",
                        "CREATE": "创建用户隐私设置",
                        "DELETE": "删除用户隐私设置",
                        "FORCE_DELETE": "强制删除用户隐私设置",
                        "RESTORE": "恢复用户隐私设置",
                        "UPDATE": "更新用户隐私设置",
                        "VIEW": "查阅用户隐私设置",
                        "VIEW_ANY": "查阅用户隐私设置索引"
                    },
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": {
                        "APPROVE_CONTACT": "批准用户关系",
                        "BLOCK_CONTACT": "阻止用户关系",
                        "BYPASS_PERMISSIONS": "忽略与用户关系相关的权限",
                        "CREATE": "创建用户关系",
                        "DECLINE_CONTACT": "拒绝用户关系",
                        "DELETE": "删除用户关系",
                        "FORCE_DELETE": "强制删除用户关系",
                        "RESTORE": "恢复用户关系",
                        "UNBLOCK_CONTACT": "取消阻止用户关系",
                        "UPDATE": "更新用户关系",
                        "VIEW": "咨询用户关系",
                        "VIEW_ANY": "查阅用户关系索引"
                    }
                },
                "groups": {
                    "App\\Enums\\Permissions\\AccessEnum": "使用权",
                    "App\\Enums\\Permissions\\ActivityLogEnum": "活动日志",
                    "App\\Enums\\Permissions\\AddressEnum": "地址",
                    "App\\Enums\\Permissions\\BankTransferInstitutionEnum": "银行转账机构",
                    "App\\Enums\\Permissions\\ChangelogEnum": "变更日志",
                    "App\\Enums\\Permissions\\ClaimEnum": "理赔",
                    "App\\Enums\\Permissions\\CountryEnum": "国家",
                    "App\\Enums\\Permissions\\CurrencyEnum": "货币",
                    "App\\Enums\\Permissions\\EasyTransac\\AccountEnum": "EasyTransac账户",
                    "App\\Enums\\Permissions\\ExcursionEnum": "行程",
                    "App\\Enums\\Permissions\\ExcursionPointOfInterestEnum": "行程和兴趣点之间的联系",
                    "App\\Enums\\Permissions\\InstitutionEmployeeEnum": "事业单位员工",
                    "App\\Enums\\Permissions\\InteractionEnum": "互动",
                    "App\\Enums\\Permissions\\LocaleEnum": "语言",
                    "App\\Enums\\Permissions\\MediumEnum": "媒体",
                    "App\\Enums\\Permissions\\Notification\\ReceiveEnum": "接收通知",
                    "App\\Enums\\Permissions\\PartnerEnum": "伙伴",
                    "App\\Enums\\Permissions\\PaymentEnum": "付款方式",
                    "App\\Enums\\Permissions\\PermissionEnum": "权限",
                    "App\\Enums\\Permissions\\PlanEnum": "订阅计划",
                    "App\\Enums\\Permissions\\PointOfInterestCategoryEnum": "兴趣点类别",
                    "App\\Enums\\Permissions\\PointOfInterestEnum": "兴趣点",
                    "App\\Enums\\Permissions\\PostCategoryEnum": "帖子类别",
                    "App\\Enums\\Permissions\\PostEnum": "帖子",
                    "App\\Enums\\Permissions\\PrivacyPolicyEnum": "隐私政策",
                    "App\\Enums\\Permissions\\RegistrationEnum": "登记",
                    "App\\Enums\\Permissions\\ReportEnum": "报告",
                    "App\\Enums\\Permissions\\ReservationEnum": "预订",
                    "App\\Enums\\Permissions\\RoleEnum": "角色",
                    "App\\Enums\\Permissions\\ShareEnum": "分享",
                    "App\\Enums\\Permissions\\Stripe\\AccountEnum": "条纹账户",
                    "App\\Enums\\Permissions\\SubscriptionEnum": "订阅",
                    "App\\Enums\\Permissions\\SupportCategoryEnum": "支持请求类别",
                    "App\\Enums\\Permissions\\SupportMessageEnum": "支持消息",
                    "App\\Enums\\Permissions\\SupportRequestEnum": "支持请求",
                    "App\\Enums\\Permissions\\TagEnum": "标签",
                    "App\\Enums\\Permissions\\TermsConditionEnum": "条款和条件",
                    "App\\Enums\\Permissions\\TranslatableEnum": "可翻译的记录",
                    "App\\Enums\\Permissions\\TravellerEmailEnum": "旅客电子邮件",
                    "App\\Enums\\Permissions\\TravellerFolderEnum": "旅行文件夹",
                    "App\\Enums\\Permissions\\UserCredentialVerificationEnum": "用户凭证验证",
                    "App\\Enums\\Permissions\\UserEnum": "用户",
                    "App\\Enums\\Permissions\\UserGeolocSettingEnum": "用户地理定位设置",
                    "App\\Enums\\Permissions\\UserNotificationSettingEnum": "用户通知设置",
                    "App\\Enums\\Permissions\\UserPrivacySettingEnum": "用户隐私设置",
                    "App\\Enums\\Permissions\\UsersRelationshipEnum": "用户关系"
                }
            },
            "plans": {
                "archive": {
                    "success": "订阅计划已存档（如果符合条件）"
                },
                "confirmation": {
                    "benefit_current_subscription": "您将继续受益于当前的订阅\n            直到月底已经付款。",
                    "engagement_period": "此优惠包括订婚期\n            第一次付款后 {engagement_period}。",
                    "fee": "此优惠需支付 {fee_percentage}% 的费用，并且您有权\n            收到 {tip_percentage}% 的小费。",
                    "free": "此订阅是免费的，但需创建小费收集帐户。",
                    "free_period": "您将受益于 {free_period} 免费期。",
                    "free_period_used": "您已经用完您的免费期限，并且不会再\n            能够从该优惠提议的 {free_period} 中受益。",
                    "name": "您即将订阅“{name}”优惠。",
                    "name_launch": "您即将订阅“{name}”启动优惠。",
                    "no_fee": "此优惠收取 0% 的费用，并且您可以全额收到小费。",
                    "please_confirm": "请点击下面的按钮确认您的订阅。",
                    "price_summary": "此订阅将花费您\n            {price} 排除。税 / {price_incl_taxes} 包括。每月税。",
                    "replace_current_subscription": "此订阅将自动替换您当前的订阅。"
                },
                "draft": {
                    "success": "认购计划已起草（如果符合条件）"
                },
                "free_period_ended_in": "免费期结束 {in}",
                "publish": {
                    "success": "订阅计划已发布（如果符合条件）"
                },
                "set_coming_soon": {
                    "success": "订阅计划已设置为“即将推出”（如果符合条件）"
                },
                "your_current_plan": "您目前的计划"
            },
            "points_of_interest": {
                "claim": {
                    "confirm_checkbox": "通过选中此框，我证明我是该公司的授权代表\n            机构声称。任何虚假陈述或冒充行为将使我受到民事和刑事处罚\n            根据适用法律的规定进行处罚。",
                    "success": "您的索赔已提交以供验证。"
                },
                "denied": {
                    "for_the_reason": "因以下原因被拒绝",
                    "moderation_again": "您可以编辑兴趣点，系统会再次提交以供审核，以便发布。"
                },
                "deny": {
                    "success": "兴趣点已被拒绝（如果符合条件）"
                },
                "destroy": {
                    "confirm": "您确实要删除此兴趣点： {point_of_interest_name} 吗？",
                    "success": "兴趣点已被删除"
                },
                "draft": {
                    "success": "兴趣点已起草（如果符合条件）"
                },
                "duplicates": {
                    "check_explanation1": "为了创造你的兴趣点，\n            请填写下面的主要字段。",
                    "check_explanation2": "将进行检查以避免\n            输入一个已经存在的点。",
                    "check_explanation3": "验证通过后即可完成\n            您的点的信息并保存。",
                    "check_explanation_title": "确认",
                    "continue_creation": "继续创作",
                    "found_explanation1": "对应一个或多个兴趣点\n            您的输入条件已经存在。",
                    "found_explanation2": "如果您认为这不是重复的，\n            然后您可以继续输入您的新兴趣点。",
                    "match_percentage": "匹配 {percentage}%",
                    "select_duplicate": "单击建议的兴趣点之一以查看详细信息",
                    "use_poi": "使用这个兴趣点",
                    "view_poi": "查看此兴趣点"
                },
                "excursion_add": {
                    "add_poi_to_excursion": "将此兴趣点添加到行程中",
                    "already_in_excursions": "该景点已经是这些行程的一部分",
                    "create_excursion": "创建行程",
                    "or_create_from": "或者从这个兴趣点创建一个新的行程",
                    "select_which": "选择将兴趣点“{point_of_interest_name}”添加到的现有行程",
                    "success": "兴趣点已添加到行程中"
                },
                "form": {
                    "help": {
                        "stars": "仅当类别可以用星级标注时（例如酒店、餐厅......）"
                    }
                },
                "import_source": {
                    "DataTourisme": "数据旅游"
                },
                "index": {
                    "no_results": "未找到兴趣点",
                    "no_results_here": "您的搜索区域中没有可用的兴趣点。",
                    "no_tags": "没有定义标签",
                    "refresh_delayed": "刷新前请等待 {time_remaining}",
                    "search": "搜索兴趣点",
                    "try_more_filters": "您可以更改活动过滤器并扩大搜索范围。"
                },
                "pages": {
                    "show": {
                        "automatic_update": "自动更新",
                        "belongs_to_you": "属于你的",
                        "claim_dispute_pending": {
                            "details": "请求的详细信息： {message}",
                            "notice": "正在进行的索赔纠纷",
                            "title": "就这一点而言，您存在持续的索赔争议。"
                        },
                        "deletion": {
                            "causes": {
                                "event": "原因：过期事件"
                            },
                            "title": "待删除"
                        },
                        "dt_required_datatourisme_fields": "由 {dt_created_by} 从 {import_source} 更新为 {dt_last_update}",
                        "excursions_counter": "已将 {counter} 次添加到行程中",
                        "is_geocoding_1": "兴趣点正在被地理定位。",
                        "is_geocoding_2": "请稍后查看此页面以查看感兴趣的点\n                在地图上。",
                        "is_refreshing_audio_1": "正在生成音频文件。",
                        "is_refreshing_audio_2": "请稍后查看此页面以收听此音频。",
                        "not_current_locale": {
                            "change_locale": "您可以使用上面的选择器更改显示语言",
                            "incomplete_translation": "该兴趣点的某些信息（标题、描述和/或地址）在所选语言中不可用。",
                            "missing_translations": "缺少翻译"
                        },
                        "opening_hours": {
                            "expired": "开放时间已过",
                            "is_deleting": "兴趣点将在 {days} 天后删除",
                            "show": "查看开放时间"
                        },
                        "sections": {
                            "audios": "音频",
                            "gallery": "画廊",
                            "logo": "标识",
                            "main_image": "主图",
                            "map": "地图",
                            "media": "媒体",
                            "poi": "兴趣点",
                            "videos": "视频"
                        },
                        "see_full_page": "查看整页"
                    }
                },
                "print": {
                    "element_not_found": "未找到打印“{id}”的元素！"
                },
                "publish": {
                    "success": "兴趣点已被验证并发布（如果符合条件）"
                },
                "report": {
                    "choose_reason": "选择报告此兴趣点的原因“{point_of_interest_title}”",
                    "heading": "报告兴趣点",
                    "success": "兴趣点已报告！"
                },
                "search": {
                    "creator_id": "只显示我的积分？",
                    "no_results": "没有找到兴趣点...",
                    "tags_mode": {
                        "all": "全部",
                        "all_help": "选择“全部”查找兴趣点\n                与所有选定的标签。",
                        "one": "最后一个",
                        "one_help": "选择“至少一个”，寻找兴趣点\n                至少包含一个选定的标签。"
                    }
                },
                "share": {
                    "heading": "分享这个兴趣点",
                    "select_which_contacts": "选择您想要与其共享兴趣点“{point_of_interest_title}”的联系人",
                    "select_which_users": "选择您想要与其共享兴趣点“{point_of_interest_title}”的用户，或输入电子邮件地址以邀请新旅行者",
                    "select_which_users_alt": "输入您想要与其共享信息的人员的电子邮件地址\n            “{point_of_interest_title}”兴趣点。",
                    "success": "兴趣点已分享！"
                },
                "states": {
                    "draft": "草稿",
                    "pending": "待办的",
                    "published": "已发表"
                },
                "store": {
                    "heading": "创建兴趣点",
                    "success": "兴趣点已创建"
                },
                "submit": {
                    "success": "兴趣点正在等待审核（如果符合条件）"
                },
                "update": {
                    "alert": "更新此点将禁用 {import_source} 导入的自动更新",
                    "confirm": "您确实要更新此兴趣点： {point_of_interest_name} 吗？",
                    "heading": "编辑此兴趣点",
                    "success": "兴趣点已更新"
                }
            },
            "posts": {
                "archive": {
                    "success": "帖子已存档（如果符合条件）"
                },
                "draft": {
                    "success": "帖子已起草（如果符合条件）"
                },
                "publish": {
                    "success": "帖子已发布（如果符合条件）"
                },
                "search": {
                    "type_to_search": "在所选类别中搜索..."
                }
            },
            "prices": {
                "excl_taxes": "不包括。税收",
                "free": "自由的",
                "from": "从",
                "incl_taxes": "包括。税收",
                "per_month": "每月",
                "price_excl_taxes": "价格不含税税收",
                "price_incl_taxes": "价格包括。税收",
                "vat_amount": "增值税金额",
                "vat_percentage": "增值税百分比"
            },
            "privacy_policies": {
                "archive": {
                    "success": "隐私政策已存档（如果符合条件）"
                },
                "errors": {
                    "not_found": "未找到隐私政策！"
                },
                "publish": {
                    "success": "隐私政策已发布"
                },
                "update": {
                    "acceptation": "您应该接受隐私政策才能继续",
                    "new_version": "新版隐私政策已发布",
                    "success": "隐私政策已被接受！"
                }
            },
            "reports": {
                "process": {
                    "success": "报告已处理（如果符合条件）"
                },
                "reject": {
                    "success": "报告已被拒绝（如果符合条件）"
                }
            },
            "reservations": {
                "archive": {
                    "confirm": "您确实要存档此预订吗？",
                    "success": "预订已存档"
                },
                "cancel": {
                    "confirm": "您确实要取消此预订吗？",
                    "success": "预订已被取消"
                },
                "confirm": {
                    "confirm": "您确实要确认此预订吗？",
                    "success": "预订已确认"
                },
                "destroy": {
                    "confirm": "您确实要删除此预订吗？",
                    "success": "预约已被删除"
                },
                "pages": {
                    "index": {
                        "mode": {
                            "first_col_label": {
                                "butler": "游客",
                                "poi": "游客",
                                "traveller": "机构"
                            }
                        }
                    },
                    "show": {
                        "sections": {
                            "butler": "门房",
                            "poi": "兴趣点",
                            "reservation": "预订",
                            "traveller": "游客"
                        }
                    }
                },
                "states": {
                    "archived": "已存档",
                    "canceled": "取消",
                    "confirmed": "确认的",
                    "pending": "待办的"
                },
                "store": {
                    "success": "预订已创建"
                },
                "update": {
                    "success": "预订已更新"
                }
            },
            "settings": {
                "items": {
                    "about": "关于",
                    "geoloc_settings": "地理位置设置",
                    "notification_settings": "通知设置",
                    "privacy_setting": "隐私设置",
                    "profile": "轮廓",
                    "signOut": "登出"
                }
            },
            "shares": {
                "emails": {
                    "select_hint": "您可以点击下面选择以前使用过的电子邮件：",
                    "space_hint": "用空格分隔以共享多封电子邮件"
                },
                "index": {
                    "no_results": "没有找到分享！"
                },
                "link": {
                    "instructions": "复制此链接以邀请旅行者并与他们分享此行程"
                },
                "show": {
                    "shared_by": "通过共享"
                },
                "type": {
                    "excursions": "行程",
                    "pointsofinterest": "兴趣点"
                }
            },
            "states": {
                "changelog_state": {
                    "archived": "已存档",
                    "draft": "草稿",
                    "published": "已发表"
                },
                "claim_dispute_state": {
                    "pending": "待办的",
                    "rejected": "拒绝",
                    "validated": "已验证"
                },
                "claim_state": {
                    "missing-info": "丢失的信息",
                    "pending": "待办的",
                    "rejected": "拒绝",
                    "revoked": "已撤销",
                    "validated": "已验证"
                },
                "payment_state": {
                    "canceled": "取消",
                    "completed": "完全的",
                    "failed": "失败的",
                    "pending": "待办的",
                    "transferred": "转入"
                },
                "plan_state": {
                    "archived": "已存档",
                    "coming_soon": "即将推出",
                    "draft": "草稿",
                    "published": "已发表"
                },
                "point_of_interest_state": {
                    "deleted": "已删除",
                    "denied": "被拒绝",
                    "draft": "草稿",
                    "pending": "待审核",
                    "published": "已发表"
                },
                "post_state": {
                    "archived": "已存档",
                    "draft": "草稿",
                    "published": "已发表"
                },
                "privacy_policy_state": {
                    "archived": "已存档",
                    "draft": "草稿",
                    "published": "已发表"
                },
                "report_state": {
                    "pending": "待办的",
                    "processed": "处理",
                    "rejected": "拒绝"
                },
                "reservation_state": {
                    "archived": "已存档",
                    "canceled": "取消",
                    "confirmed": "确认的",
                    "pending": "待办的"
                },
                "subscription_state": {
                    "active": "积极的",
                    "canceled": "取消",
                    "pending": "待付款",
                    "suspended": "暂停"
                },
                "support_request_state": {
                    "archived": "已存档",
                    "canceled": "取消",
                    "pending-support": "待定支持",
                    "pending-user": "待定用户",
                    "resolved": "解决"
                },
                "terms_condition_state": {
                    "archived": "已存档",
                    "draft": "草稿",
                    "published": "已发表"
                },
                "traveller_folder_state": {
                    "approved": "得到正式认可的",
                    "archived": "已存档",
                    "declined": "拒绝",
                    "pending": "待办的"
                },
                "user_credential_verification_state": {
                    "canceled": "取消",
                    "completed": "完全的",
                    "pending": "待办的"
                },
                "user_tutorial_state": {
                    "claimed": "声称",
                    "done": "完毕",
                    "employee-added": "已添加员工",
                    "pending": "待办的"
                },
                "user_utm_campaign_state": {
                    "always_on": "始终开启"
                },
                "user_utm_medium_state": {
                    "owned_social": "社交网络"
                },
                "user_utm_source_state": {
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "linkedin": "领英"
                },
                "users_relationship_state": {
                    "approved": "得到正式认可的",
                    "blocked": "被阻止",
                    "declined": "拒绝",
                    "pending": "待办的"
                }
            },
            "stripe": {
                "connect": {
                    "account": {
                        "create": {
                            "completed": "Stripe账户配置完成",
                            "link_invalid": "连接 Stripe 失败，请重试"
                        },
                        "must_subscribe": "您必须订阅才能创建您的 Stripe 帐户。",
                        "show": {
                            "account_usage": "您的 Stripe 账户将允许您的机构在 UgoZER 上接收付款。",
                            "can_receive_payments": "您能够收到付款。",
                            "cannot_receive_payments": "您无法收到付款",
                            "fee_percentage": "您收到的每笔付款将收取 {percentage}% 的费用。",
                            "go_to_stripe": "转到 Stripe 仪表板",
                            "has_stripe_account_being_reviewed": "Stripe 正在审核您的帐户。",
                            "no_account_yet": "您还没有 Stripe 帐户。",
                            "should_complete_stripe_onboarding": "请完成您在 Stripe 的注册并​​向他们提供所需的信息。"
                        }
                    }
                },
                "stripe": "条纹"
            },
            "subscriptions": {
                "activated_at": "激活",
                "cancel": "取消",
                "cancelation_success": "考虑取消",
                "canceled_at": "取消",
                "confirm_cancel": "您真的要取消订阅吗？",
                "confirm_cancel_date": "在 {date} 的有效取消日期之前，您将受益于其优势。",
                "confirm_cancel_now": "取消将立即生效。",
                "expiry": "到期",
                "invoices": "发票",
                "my_current_subscription": "我当前的订阅",
                "price_excl_taxes": "价格不含税税收",
                "price_incl_taxes": "价格包括。税收",
                "upcoming_subscription": "即将订阅"
            },
            "support_messages": {
                "show": {
                    "you": "你",
                    "your_question": "你的问题"
                },
                "store": {
                    "heading": "添加消息",
                    "success": {
                        "title": "消息已创建。联系支持人员将在 48 小时内回复。"
                    }
                }
            },
            "support_requests": {
                "actions": {
                    "archived": {
                        "success": "请求已存档"
                    },
                    "canceled": {
                        "success": "请求被取消"
                    },
                    "pending_support": {
                        "success": "等待支持回复"
                    },
                    "pending_user": {
                        "success": "等待用户回答"
                    },
                    "resolved": {
                        "success": "请求已解决"
                    }
                },
                "cancel": {
                    "confirm": "您确实要取消此支持请求： {support_request_title} 吗？",
                    "error": "无法取消支持请求",
                    "success": "支持请求已被取消"
                },
                "index": {
                    "no_results": "您没有任何支持请求",
                    "search": "搜索支持请求",
                    "your_requests": "您的支持请求"
                },
                "pages": {
                    "create": {
                        "notice": "个人资料"
                    },
                    "index": {
                        "search": "搜索支持请求"
                    },
                    "show": {
                        "sections": {
                            "messages": "留言",
                            "request": "要求"
                        }
                    }
                },
                "show": {
                    "add_request": "添加新的支持请求"
                },
                "states": {
                    "archived": "已存档",
                    "canceled": "取消",
                    "pending-support": "待定支持",
                    "pending-user": "待定用户",
                    "resolved": "解决"
                },
                "store": {
                    "heading": "创建新的支持请求",
                    "success": {
                        "title": "请求已创建。联系支持人员将在 48 小时内回复。"
                    }
                }
            },
            "terms_conditions": {
                "archive": {
                    "success": "条款和条件已存档（如果符合条件）"
                },
                "errors": {
                    "not_found": "没有找到条款和条件！"
                },
                "publish": {
                    "success": "条款和条件已发布"
                },
                "update": {
                    "acceptation": "您应该接受条款和条件才能继续",
                    "new_version": "新版本的条款和条件已发布",
                    "success": "条款和条件已被接受！"
                }
            },
            "traveller_folders": {
                "messages": {
                    "approve": {
                        "confirm": "您确实想确认此次入住吗？",
                        "success": "保持确认！"
                    },
                    "archive": {
                        "confirm": "您真的想存档这次住宿吗？",
                        "success": "此住宿已存档！"
                    },
                    "decline": {
                        "confirm": "您真的要拒绝这次住宿吗？",
                        "success": "住宿谢绝！"
                    }
                },
                "no_results": {
                    "approved": "没有批准的住宿",
                    "archived": "没有存档的住宿",
                    "declined": "没有被拒绝的住宿",
                    "pending": "无住宿要求"
                },
                "pages": {
                    "form": {
                        "cannot_attach": {
                            "description": "该用户存在但不被支持...",
                            "title": "无法支持"
                        },
                        "poi_empty_option": "删除兴趣点",
                        "poi_label": "礼宾机构",
                        "verification": {
                            "description": "输入旅客的电子邮件地址。如果旅行者帐户已存在，它将\n                    直接链接，否则您将必须输入一些附加信息才能创建\n                    新的一个。",
                            "title": "确认"
                        }
                    },
                    "index": {
                        "pending_stays": "您待定的住宿",
                        "search": "在我的旅行者中搜索",
                        "travellers_folders": "旅行者文件夹",
                        "your_stays": "您的住宿"
                    },
                    "qr_code_1": "旅行者扫描此二维码即可自动获取旅行者文件夹\n            与您的机构。",
                    "qr_code_2": "如果旅客尚未注册UgoZER，则会重定向至注册页面\n            页面，并将在注册结束时链接到您的机构。",
                    "show": {
                        "fields": {
                            "state": "状态："
                        },
                        "sections": {
                            "butler": "相关礼宾服务",
                            "folder": "文件夹",
                            "hotel": "酒店",
                            "institution": "相关机构",
                            "interactions": "互动",
                            "invitation": "邀请一位旅行者",
                            "payments": "付款方式",
                            "reservations": "预订",
                            "stay": "停留",
                            "thanks": "谢谢",
                            "traveller": "游客"
                        }
                    },
                    "wizard": {
                        "choose_an_institution": "选择机构",
                        "no_linked_institution": "您必须先与某个机构建立联系，然后才能接载旅客。"
                    }
                },
                "placeholders": {
                    "butler": "接待团队"
                },
                "states": {
                    "approved": "公认",
                    "archived": "已存档",
                    "declined": "拒绝",
                    "pending": "待办的",
                    "undefined": "不明确的"
                },
                "store": {
                    "success": "文件夹已创建"
                },
                "tabs": {
                    "approved": "得到正式认可的",
                    "archived": "已存档",
                    "declined": "拒绝",
                    "pending": "待办的"
                },
                "thank": {
                    "success": "感谢信已发送"
                },
                "update": {
                    "success": "文件夹已更新"
                }
            },
            "tutorials": {
                "extranet": {
                    "begin": {
                        "butler": {
                            "description": "为了让您在最佳状态下使用UgoZER应用程序，\n                    我们建议您按照以下步骤进行操作，这些步骤将指导您完成第一步\n                    礼宾空间的处理和参数设置。",
                            "steps": {
                                "institution": {
                                    "content": "使用搜索栏在酒店列表中搜索您的酒店，然后\n                            页面左侧可用的过滤器。一旦你找到了你的\n                            建立，通过点击<strong>申请加入</strong>创建申请\n                            按钮。",
                                    "description": "链接到您的机构",
                                    "step_next": "声称",
                                    "step_state": "待办的",
                                    "subtitle": {
                                        "link": "我的机构",
                                        "part_1": "将我链接到我的酒店",
                                        "part_2": "您可以直接将您的酒店链接到页面"
                                    },
                                    "title": "机构"
                                }
                            }
                        },
                        "done": {
                            "description": "您现在已经完成了设置 UgoZER 应用程序的各个步骤。",
                            "faq": {
                                "link": "常问问题",
                                "part_1": "如果您需要更多帮助，您可以在以下位置找到文章",
                                "part_2": "申请部分"
                            },
                            "help": "您还可以通过某些页面上的<strong>帮助</strong>找到上下文帮助。",
                            "title": "恭喜！"
                        },
                        "hotelier": {
                            "description": "为了让您在最佳状态下使用UgoZER应用程序，\n                    我们建议您按照以下步骤进行操作，这些步骤将指导您完成第一步\n                    酒店经理空间的处理和参数设置。",
                            "steps": {
                                "employees": {
                                    "description": "管理您的礼宾人员",
                                    "step_next": "员工添加",
                                    "step_state": "声称",
                                    "title": "礼宾部"
                                }
                            }
                        },
                        "manager": {
                            "description": "为了让您在最佳状态下使用UgoZER应用程序，\n                    我们建议您按照以下步骤进行操作，这些步骤将指导您完成第一步\n                    您的其他业务经理空间的处理和参数设置。"
                        },
                        "messages": {
                            "claim": "您的主张已得到验证。",
                            "contest": "您的争议已发送至我们的团队。",
                            "payment": "您的银行信息已成功记录。",
                            "point_of_interest": "兴趣点已创建，您现在可以认领它。"
                        },
                        "no_tutorial": {
                            "content": "尚未为此角色设置任何教程，或者您已作为管理员进行连接。",
                            "title": "没有可用的教程"
                        },
                        "notices": {
                            "add_butler": {
                                "content": "要使用该应用程序，您必须为您的机构创建一个礼宾帐户\n                        信息将从中发送给旅行者。",
                                "content_2": "要激活礼宾帐户，您的礼宾人员需要确认他们的电子邮件\n                        地址并创建他们的密码。",
                                "title": "添加礼宾员"
                            },
                            "add_employee": {
                                "content": "要使用该应用程序，您必须为您的机构创建一个员工帐户\n                        信息将从中发送给旅行者。",
                                "content_2": "要激活员工帐户，您的员工需要确认他们的电子邮件\n                        地址并创建他们的密码。",
                                "title": "添加员工"
                            }
                        },
                        "shared": {
                            "actions": {
                                "skip_step": "跳过步骤",
                                "skip_tutorial": "跳过教程"
                            },
                            "claims": {
                                "description": "认领您的机构",
                                "documents": {
                                    "association": {
                                        "identity_piece": "法定代表人身份证明\n                                    有效的",
                                        "statutes": "协会章程",
                                        "title": "如果您是协会"
                                    },
                                    "company": {
                                        "identity_piece": "法定代表人身份证明\n                                    有效的",
                                        "kbis": "KBIS 提取物",
                                        "title": "如果您是一家公司"
                                    },
                                    "description": "要申请您的机构，您需要准备以下内容\n                                文件：",
                                    "individual": {
                                        "identity_piece": "有效身份证件",
                                        "registration_document": "个体工商户登记证书",
                                        "title": "如果您是个体工商户"
                                    },
                                    "label": "文件",
                                    "title": "索赔文件"
                                },
                                "notice": {
                                    "description": "您可以通过单击<strong>帮助</strong>找到此说明\n                                兴趣点列表右上角的按钮。",
                                    "title": "请注意"
                                },
                                "step_next": "声称",
                                "step_state": "待办的",
                                "title": "宣称"
                            },
                            "messages": {
                                "skip_step": {
                                    "confirm": "您确定要跳过此步骤吗？",
                                    "success": "这一步完全被忽略了！"
                                },
                                "skip_tutorial": {
                                    "confirm": "您确定要跳过本教程吗？",
                                    "success": "该教程被忽视了！"
                                }
                            },
                            "payments": {
                                "description": "接收提示",
                                "step_next": "完毕",
                                "step_state": "员工添加",
                                "steps": {
                                    "bank": {
                                        "create": "点击<strong>添加帐户</strong>按钮\n                                    <strong>银行账户</strong>部分"
                                    },
                                    "easytransac": {
                                        "connection": "点击链接\n                                    <strong>转到 EasyTransac 仪表板</strong>并使用您的凭据登录\n                                        通过电子邮件收到",
                                        "create": "点击<strong>创建</strong>按钮\n                                    <strong>EasyTransac</strong> 部分",
                                        "created": "该帐户是根据输入的数据自动创建的\n                                    在你的个人资料上",
                                        "form": "在 EasyTransac 上填写表格以激活您的账户支付账户",
                                        "warning": {
                                            "description": "根据您 EasyTransac 账户的 KYC 级别，您可以\n                                        能够接收付款，但无法将资金转入普通银行\n                                        帐户。资金存入常规银行账户。为此，您需要 KYC 级别\n                                        2、在EasyTransac界面中提供某些文档。易交易\n                                        界面。",
                                            "title": "KYC 级别"
                                        }
                                    },
                                    "my_profile": {
                                        "part_1": "前往页面",
                                        "part_2": "我的简历"
                                    }
                                },
                                "subtitle_1": "为了让您的礼宾人员收到小费，您必须创建一个帐户\n                            在我们的合作伙伴 EasyTransac 的网站上。",
                                "subtitle_2": "去做这个：",
                                "title": "支付"
                            }
                        },
                        "tourist_office": {
                            "description": "为了让您在最佳状态下使用UgoZER应用程序，\n                    我们建议您按照以下步骤进行操作，这些步骤将指导您完成第一步\n                    旅游办公空间的处理和参数设置。",
                            "steps": {
                                "employees": {
                                    "description": "管理您的员工",
                                    "step_next": "员工添加",
                                    "step_state": "声称",
                                    "title": "雇员"
                                }
                            }
                        },
                        "tourist_office_employee": {
                            "description": "为了让您在最佳状态下使用UgoZER应用程序，\n                    我们建议您按照以下步骤进行操作，这些步骤将指导您完成第一步\n                    旅游局员工空间的处理和参数设置。",
                            "steps": {
                                "institution": {
                                    "content": "使用搜索栏在机构列表中搜索您的旅游局\n                            以及页面左侧部分中可用的过滤器。一旦你找到了你的\n                            建立，通过点击<strong>申请加入</strong>创建申请\n                            按钮。",
                                    "description": "链接到您的机构",
                                    "step_next": "声称",
                                    "step_state": "待办的",
                                    "subtitle": {
                                        "link": "我的机构",
                                        "part_1": "将我链接到我的旅游办公室",
                                        "part_2": "您可以直接将您的旅游局链接到该页面"
                                    },
                                    "title": "机构"
                                }
                            }
                        }
                    },
                    "dashboard": {
                        "claim": {
                            "steps": {
                                "claim_form": "然后按照索赔表上的说明进行操作",
                                "claim_validation": "然后我们的团队将验证和验证该声明",
                                "dont_exist": "如果不存在，请单击按钮<strong>创建兴趣点\n                        </strong>然后填写表格。创建兴趣点后，转到其\n                        卡并点击按钮<strong>领取</strong>",
                                "exist": "如果您的机构存在，请转到其文件并单击按钮<strong>\n                        声明</strong>",
                                "filters": "使用过滤器和搜索来查找您的机构",
                                "poi_index": {
                                    "part_1": "前往",
                                    "part_2": "兴趣点列表"
                                }
                            },
                            "subtitle_1": "您可以认领一个（或多个）机构来管理它们。",
                            "subtitle_2": "为此：",
                            "title": "主张对我的机构拥有所有权和管理权"
                        },
                        "employees": {
                            "link": "要创建请求，他们可以转到<strong>我的机构</strong>页面\n                    他们的空间并填写表格。",
                            "manage": "在此页面上，您可以接受或拒绝想要与您联系的员工\n                    机构。",
                            "subtitle": {
                                "part_1": "您可以直接通过页面管理您的员工",
                                "part_2": "我的员工"
                            },
                            "title": "管理我的员工"
                        }
                    },
                    "excursions": {
                        "filters": {
                            "distance": "<strong>地点</strong>：允许您过滤特定位置的点",
                            "main_title": "过滤器",
                            "subtitle": "您可以根据以下条件过滤行程列表：",
                            "title": "筛选行程",
                            "type": {
                                "customized": "<strong>定制：</strong>专为旅行者创建的行程",
                                "explanation": "<strong>类型：</strong>允许您按行程类型进行过滤",
                                "pro": "<strong>UgoZER Pro：</strong>UgoZER提供的行程",
                                "root": "<strong>我的模板：</strong>模型行程"
                            }
                        }
                    },
                    "institution_employee": {
                        "approve": {
                            "content": "此操作允许您接受一名员工的请求，加入其中一名员工\n                    你们的机构。",
                            "main_title": "批准",
                            "title": "批准员工"
                        },
                        "cancel": {
                            "content": "此操作允许您在以下情况下取消员工的邀请请求\n                    例如，发生错误的情况。",
                            "main_title": "取消",
                            "title": "取消员工的申请"
                        },
                        "decline": {
                            "content": "此操作允许您拒绝一名员工加入您的一名员工的请求\n                    机构。",
                            "main_title": "拒绝",
                            "title": "拒绝员工"
                        },
                        "functioning": {
                            "content": {
                                "invite": "在此页面上，您可以邀请员工或接受/拒绝您的请求\n                        雇员。",
                                "section": "此部分允许您管理机构的员工。"
                            },
                            "main_title": "发挥作用",
                            "title": "雇员科的运作"
                        },
                        "invite": {
                            "content": {
                                "button": "通过点击<strong>邀请员工</strong>按钮，您可以邀请您的一位员工\n                        员工，无论他们是否已经拥有 UgoZER 帐户。",
                                "form": "为此，只需按照出现的分步表格上的说明进行操作即可。\n                        出现的形式。"
                            },
                            "main_title": "邀请",
                            "title": "邀请员工"
                        },
                        "remove": {
                            "content": "此操作允许您在以下情况下从员工中删除用户：\n                    员工离开你的公司。如果员工离开您的公司。",
                            "main_title": "消除",
                            "title": "删除员工"
                        }
                    },
                    "points_of_interest": {
                        "claim": "宣称",
                        "filters": {
                            "category": "<strong>类别：</strong>允许您选择特定类别\n                    （酒店、餐厅……）",
                            "city": "<strong>城市</strong>：允许您过滤特定城市",
                            "country": "<strong>国家/地区</strong>：允许您过滤一个或多个特定国家/地区",
                            "distance": "<strong>地点：</strong>允许您过滤特定位置的点",
                            "main_title": "过滤器",
                            "measure_unit": "<strong>测量单位：</strong>允许您更改用于的测量单位\n                    距离过滤器和显示屏",
                            "my_points": "<strong>仅显示我的积分：</strong>仅显示您已创建或的积分\n                    由您认领",
                            "postal_code": "<strong>邮政编码：</strong>允许您按邮政编码过滤",
                            "radius": "<strong>限制半径：</strong>允许过滤特定圆内的点\n                    半径",
                            "subtitle": "您可以根据以下条件过滤兴趣点列表：",
                            "tags": "<strong>细化：</strong>允许您通过标签细化搜索以指定类别。\n                    还可以定义是否要搜索具有以下之一或全部或全部的点\n                    选定的标签",
                            "title": "过滤兴趣点"
                        }
                    }
                }
            },
            "user_credential_verifications": {
                "validate": {
                    "success": "凭证已成功验证（如果符合条件）"
                },
                "verify": {
                    "already_verified": "您的凭据已经过验证",
                    "expired": "链接已过期，求新的",
                    "impossible": "该凭证的验证不再可能",
                    "not_received": "没有收到确认链接？",
                    "sent": {
                        "again": "重新发送",
                        "correct": "更正所选凭证",
                        "email_1": "如果该地址尚未使用，则会向 {email} 发送一封电子邮件。",
                        "email_2": "该电子邮件包含一个链接，可让您确认您的地址。",
                        "email_3": "您访问链接后，您的电子邮件将立即更新。",
                        "refresh": "您点击链接了吗？刷新应用程序",
                        "title": "链接已发送",
                        "wait_before": "请等待 1 秒后再发送"
                    },
                    "success": "您的凭据已成功验证"
                }
            },
            "user_geoloc_settings": {
                "geolocation": {
                    "error": {
                        "allow_browser": "允许您的浏览器在手机设置中对您进行地理定位",
                        "allow_site": "允许 UgoZER 在浏览器设置中对您进行地理定位",
                        "intro": "查看您周围的兴趣点：",
                        "title": "地理定位失败"
                    },
                    "intro": "地理定位可让您找到最近的兴趣点。"
                },
                "not_configured": {
                    "body1": "选择您选择的位置以查看其附近的一系列兴趣点。",
                    "body2": "也可以仅显示您附近的兴趣点。",
                    "do_not_ask_again": "在应用程序重新启动之前不要再问我",
                    "subject": "您尚未定义您的地理位置设置。"
                },
                "update": {
                    "success": "地理位置设置已更新"
                }
            },
            "user_notification_settings": {
                "update": {
                    "success": "通知设置已更新"
                }
            },
            "user_privacy_settings": {
                "update": {
                    "success": "隐私设置已更新"
                }
            },
            "users": {
                "butlers": "礼宾部",
                "finish": {
                    "success": "教程更改为完成状态"
                },
                "index": {
                    "hint": "可以通过用户的昵称或公共 ID 找到用户。",
                    "no_results": "没有找到用户",
                    "search": "搜索用户"
                },
                "pages": {
                    "show": {
                        "sections": {
                            "delete": "删除我的账户？",
                            "profile": "我的简历"
                        }
                    }
                },
                "profile": {
                    "alerts": {
                        "blocked": {
                            "message": "您无法再与该用户交互。",
                            "title": "该用户屏蔽了您..."
                        },
                        "missing_infos": {
                            "message": "为了利用该应用程序的所有功能，请填写以下信息：",
                            "title": "完成您的个人资料"
                        },
                        "pending_validation": {
                            "message": "该用户尚未接受您的需求。",
                            "title": "待验证"
                        }
                    },
                    "avatar": {
                        "defaults": {
                            "female": "女性",
                            "male": "男性"
                        },
                        "section": {
                            "cta": "使用此头像",
                            "description": "您可以从下面的列表中选择预定义的头像",
                            "title": "选择预定义的头像"
                        },
                        "success": "您的头像已成功更新！"
                    },
                    "bank_account_number_missing": "请提供您的银行信息以接收付款。",
                    "delete_account": {
                        "easy_transac": "您的 EasyTransac 帐户不会被删除。",
                        "instructions": "要确认最终删除您的帐户，请在下面的字段中输入“删除”一词，然后单击“确认”按钮。",
                        "success": "您的帐户已被删除。",
                        "title": "帐户删除",
                        "warning": "您的帐户以及与您帐户相关的所有信息将立即被删除，且无法恢复。"
                    },
                    "edit_email": "编辑您的电子邮件",
                    "edit_password": "编辑您的密码",
                    "edit_phone": "编辑您的电话号码",
                    "edit_your_profile": "编辑您的个人资料",
                    "email_missing": "您尚未填写经过验证的电子邮件。",
                    "fill_country": "请指定您所在的国家/地区",
                    "form": {
                        "poi_label": "关联机构"
                    },
                    "manage_my_subscription": "管理我的订阅",
                    "password_missing": "您还没有填写密码。",
                    "phone_missing": "您还没有填写经过验证的电话号码",
                    "preferences": "优先",
                    "public_id": "或者使用这个标识符：",
                    "qr_code": "扫描我查看我的个人资料",
                    "security": "安全",
                    "share_profile": "分享您的个人资料",
                    "subscribe_to_receive_payments": "您必须订阅才能接收付款。",
                    "unnamed": "无名",
                    "your_personal_information": "你的个人信息"
                },
                "report": {
                    "choose_reason": "选择举报此用户的原因“{user_name}”",
                    "heading": "举报用户",
                    "success": "该用户已被举报！"
                },
                "search": {
                    "no_results": "未找到相应的用户"
                },
                "send_link": {
                    "error": "无法发送链接，用户有电子邮件吗？",
                    "success": "申请链接已发送"
                },
                "update": {
                    "success": "个人资料已更新"
                }
            },
            "users_relationships": {
                "index": {
                    "no_results": "未找到联系人",
                    "pending_requests": "您待处理的联系人"
                },
                "messages": {
                    "add": {
                        "confirm": "您确实要添加此联系人吗？",
                        "error": "联系请求期间发生错误...",
                        "success": "联系请求已成功完成！"
                    },
                    "approve": {
                        "confirm": "您确实要接受此联系请求吗？",
                        "success": "联系请求已接受！"
                    },
                    "block": {
                        "confirm": "您真的要阻止该用户吗？",
                        "success": "该联系方式已被屏蔽！"
                    },
                    "decline": {
                        "confirm": "您确实要拒绝此联系请求吗？",
                        "success": "联系请求被拒绝！"
                    },
                    "unblock": {
                        "confirm": "您真的要取消阻止该用户吗？",
                        "success": "该联系人已被解锁！"
                    }
                },
                "no_results": {
                    "approved": "没有批准的联系人",
                    "blocked": "没有被阻止的联系人",
                    "pending": "没有待处理的联系请求",
                    "sent": "没有发送联系请求"
                },
                "search": {
                    "no_results": "未找到联系人"
                },
                "tabs": {
                    "approved": "得到正式认可的",
                    "blocked": "被阻止",
                    "pending": "待办的",
                    "sent": "发送"
                }
            }
        },
        "validation": {
            "accepted": "必须接受 {attribute}。",
            "active_url": " {attribute} 不是有效的 URL。",
            "after": " {attribute} 必须是 {date} 之后的日期。",
            "after_or_equal": " {attribute} 必须是晚于或等于 {date} 的日期。",
            "alpha": " {attribute} 只能包含字母。",
            "alpha_dash": " {attribute} 只能包含字母、数字、破折号和下划线。",
            "alpha_num": " {attribute} 只能包含字母和数字。",
            "array": " {attribute} 必须是一个数组。",
            "attributes": {
                "accounting_file": "会计档案",
                "actions": "行动",
                "activated_at": "激活于",
                "active": "积极的",
                "additional_information": "附加信息",
                "address": "地址",
                "addresses": "地址",
                "admin_message": "信息",
                "administrative_area": "行政区",
                "amount": "数量",
                "app": "应用",
                "app_fees": "费用",
                "approved_at": "批准于",
                "archived_at": "存档于",
                "association_legal_representative_identity_piece": "法定代表人身份证明",
                "association_statutes": "法规",
                "attached_files": "附加的文件",
                "audio": "声音的",
                "audio_refreshing": "音频刷新",
                "audios": "音频",
                "authenticated_once": "已验证一次",
                "auto_renewal_enabled": "自动续订已启用",
                "avatar": "头像",
                "bank_account": "银行账户",
                "bank_account_number": "银行帐号",
                "bank_bic": "商业银行",
                "bank_city": "银行城",
                "bank_country": "银行国家",
                "bank_file": "银行档案",
                "bank_iban": "国际银行账号",
                "bank_name": "银行名",
                "bank_payee": "收款人",
                "bank_transfer": "银行转帐",
                "bank_transfer_institutions": "银行转账机构",
                "bank_transfer_reason": "转让原因",
                "butler": "门房",
                "butler_point_of_interest": "礼宾机构",
                "canceled_at": "取消于",
                "categories": "类别",
                "category": "类别",
                "category_id": "类别编号",
                "causer": "造成者",
                "causer_id": "原因 ID",
                "causer_type": "原因类型",
                "certified": "认证的",
                "city": "城市",
                "claim_confirmation": "索赔确认",
                "code": "代码",
                "collection_name": "集合名称",
                "communication_consent": "个性化沟通",
                "communication_consent_alt": "通讯？",
                "company_legal_representative_identity_piece": "法定代表人身份证明",
                "company_registration_document": "注册文件",
                "company_statutes": "法规",
                "company_type": "公司类型",
                "completed_at": "完成于",
                "contact": "接触",
                "contact_email": "联系电子邮件",
                "contact_phone": "联系电话",
                "content": "内容",
                "contestant": "选手",
                "contestant_company_type": "公司类型",
                "contestant_email": "电子邮件",
                "contestant_first_name": "名",
                "contestant_last_name": "姓",
                "contestant_message": "信息",
                "contestant_phone": "电话",
                "conversions_disk": "转换盘",
                "coords": "坐标",
                "countries": "国家",
                "countries_codes": "国家",
                "country": "国家",
                "country_code": "国家代码",
                "created": "已创建",
                "created_at": "创建于",
                "creator": "创造者",
                "credential": "凭据",
                "currency": "货币",
                "current_password": "当前密码",
                "custom_amount": "定制金额",
                "custom_properties": "自定义属性",
                "customers_qty": "客户数量",
                "date": "日期",
                "dates": "日期",
                "days": "天",
                "declined_at": "拒绝于",
                "deleted_at": "删除于",
                "deletion_confirmation": "删除确认",
                "denial_reason": "拒绝理由",
                "dependent_locality": "附属地点",
                "description": "描述",
                "details": "细节",
                "disk": "磁盘",
                "display": "展示",
                "displayed_name": "显示名称",
                "distance": "距离",
                "documents": "文件",
                "dt_created_by": "由...制作",
                "dt_last_update": "上次 DataTourisme 更新",
                "dynamic_translations_enabled": "启用动态翻译",
                "email": "电子邮件",
                "emails_must_different": "两个帐户不能具有相同的电子邮件地址。",
                "employee": "员工",
                "end_date": "结束日期",
                "engaged_until": "订婚直到",
                "engagement_period": "订婚期",
                "event": "事件",
                "excursion": "行程",
                "existing_user": "现有用户",
                "expirable": "过期的",
                "expired_at": "过期于",
                "expired_on": "停留结束日期",
                "expiry": "过期",
                "expiry_date": "到期日",
                "extranet": "外联网",
                "fallback_locale": "后备区域设置",
                "favorite": "最喜欢的行程",
                "featured": "精选",
                "file": "文件",
                "file_name": "文件名",
                "filter": "筛选",
                "financial": "金融的",
                "first_name": "名",
                "formatted_address": "格式化地址",
                "free_period": "免费时间段",
                "free_period_ended_at": "免费期结束于",
                "front": "正面",
                "gallery": "画廊",
                "gdpr": "通用数据保护条例",
                "general": "一般的",
                "generated_conversions": "产生的转化",
                "gross_amount": "总额",
                "has_employees": "有员工",
                "hour": "小时",
                "hours": "小时",
                "id": "ID",
                "image": "图像",
                "images": "图片",
                "import_source": "导入源",
                "import_synchronizing": "激活自动更新",
                "individual_first_identity_piece": "身份证件",
                "individual_registration_document": "注册文件",
                "individual_second_identity_piece": "第二身份片",
                "information": "信息",
                "institution": "机构",
                "institution_address": "机构地址",
                "institution_id": "机构",
                "institution_title": "机构名称",
                "interaction_type": "交互类型",
                "invitation": "邀请函",
                "invitation_accepted": "接受邀请",
                "invitation_status": "邀请状态",
                "invited_at": "邀请于",
                "invited_by": "受邀",
                "invitees": "受邀者",
                "is_geocoding": "地理定位正在进行中",
                "iso_code": "ISO代码",
                "items": "项目",
                "key": "钥匙",
                "kind": "种类",
                "label": "标签",
                "last_geoloc": "最后的地理位置",
                "last_logged_at": "最后登录",
                "last_login_ip": "最后登录IP",
                "last_name": "姓",
                "last_validated_at": "最后验证",
                "lat": "纬度",
                "latitude": "纬度",
                "line1": "地址第一行",
                "line2": "地址行 2",
                "link": "关联",
                "lng": "经度",
                "locale": "语言",
                "login": "联系",
                "logo": "标识",
                "longitude": "经度",
                "manipulations": "操纵",
                "max_amount_per_checkout": "每次结帐的最大金额",
                "measurement_system": "测量系统",
                "media": "媒体",
                "message": "信息",
                "metadata": "元数据",
                "mime_type": "MIME 类型",
                "min_amount_per_checkout": "每次结账的最低金额",
                "misc": "各种各样的",
                "model": "记录",
                "model_type": "记录类型",
                "name": "姓名",
                "net_amount": "净额",
                "new_password": "新密码",
                "new_password_confirmation": "新密码确认",
                "next_payment_at": "下次付款",
                "nickname": "昵称",
                "not_specified": "未指定",
                "not_validated_activities": "未经验证的活动",
                "notes": "更多信息",
                "opening_hours": "营业时间",
                "order_column": "命令",
                "origin": "起源",
                "owner": "所有者",
                "owner_email": "电子邮件",
                "owner_first_name": "名",
                "owner_last_name": "姓",
                "owner_phone": "电话",
                "password": "密码",
                "password_confirmation": "确认密码",
                "passwords_must_different": "两个帐户不能有相同的密码。",
                "payable": "应付款",
                "payee": "收款人",
                "payee_country": "国家",
                "payee_email": "电子邮件",
                "payee_id": "收款人",
                "payee_name": "收款人姓名",
                "payee_phone": "电话",
                "payer": "付款人",
                "payer_country": "国家",
                "payer_email": "电子邮件",
                "payer_id": "付款人",
                "payer_name": "付款人姓名",
                "payer_phone": "电话",
                "payment_received": "已收到付款",
                "payment_service": "支付服务",
                "payment_service_account_id": "ID支付账号",
                "payment_service_transaction_id": "身份证支付交易",
                "payment_thanks": "付款谢谢",
                "payment_type": "付款方式",
                "period": "时期",
                "phone": "电话",
                "plan": "计划",
                "point_of_interest": "兴趣点",
                "point_of_interest_categories": "类别",
                "point_of_interest_category": "类别",
                "point_of_interest_event_expired": "活动已过期",
                "points_of_interest": "兴趣点",
                "points_of_interest_count": "兴趣点数",
                "post_category": "帖子类别",
                "price": "价格",
                "price_excluding_taxes": "不含税价格",
                "prices": "价格",
                "priority": "优先事项",
                "privacy_policy": "隐私政策",
                "privacy_policy_accepted": "接受隐私政策",
                "privacy_policy_version": "隐私政策版本",
                "private": "私人的",
                "private_title": "私人头衔",
                "pro_file": "轮廓",
                "profile_picture": "头像",
                "properties": "特性",
                "properties_comparison": "更新的属性",
                "provider": "提供者",
                "public": "民众",
                "public_id": "公众号",
                "published_at": "发表于",
                "quantity": "数量",
                "quitted_at": "退出于",
                "radius": "半径",
                "rates": "费率",
                "reason_details": "细节",
                "reason_type": "原因",
                "recipient": "接受者",
                "recipients": "收件人",
                "recipients_alt": "登记旅客",
                "remember": "记住账号",
                "removed_at": "删除于",
                "reports": "报告",
                "requested_at": "要求于",
                "reservation_created": "已创建预订",
                "reservation_date": "日期",
                "reservation_time": "时间",
                "resolved_at": "解决于",
                "responsive_images": "响应图像",
                "roles": "角色",
                "room_number": "房间号",
                "scout_searchable": "可索引的",
                "searchable": "可搜索的",
                "sender": "发件人",
                "senders": "发件人",
                "service": "服务",
                "settings": "设置",
                "share_created": "共享已创建",
                "shareable": "可分享的",
                "show_contact_email": "联系人电子邮件可见性",
                "show_contact_phone": "联系电话可见性",
                "show_profile_picture": "头像的可见性",
                "show_real_name": "名字和姓氏的可见性",
                "siret": "西雷特",
                "size": "尺寸",
                "source": "来源",
                "source_id": "源ID",
                "specified": "指定的",
                "starable": "标有星星",
                "stars": "星星",
                "start_date": "开始日期",
                "state": "状态",
                "states": "状态",
                "static_translations_enabled": "启用静态翻译",
                "status": "地位",
                "subject": "主题",
                "subject_id": "主题编号",
                "subject_type": "学科类型",
                "summary": "概括",
                "support_category": "类别",
                "support_messages_count": "支持消息计数",
                "support_request": "支持请求",
                "support_request_archived": "支持请求存档",
                "support_request_message_received": "在支持请求中收到的答复",
                "suspended_at": "暂停于",
                "tag": "标签",
                "tags": "标签",
                "target": "目标",
                "terms_condition_accepted": "接受条款和条件",
                "terms_condition_version": "条款和条件版本",
                "terms_conditions": "条款和条件",
                "thanked": "谢谢",
                "thanked_at": "感谢日期",
                "thanks": "谢谢",
                "thanks_message": "感谢留言",
                "thanks_sent": "感谢发送",
                "tipping_fees": "小费",
                "tips_enabled": "已启用提示",
                "title": "标题",
                "total_amount": "总金额",
                "traveller": "游客",
                "traveller_folder": "旅行者文件夹",
                "traveller_folder_approved": "旅行者文件夹批准",
                "traveller_folder_archived": "旅行者文件夹存档",
                "traveller_folder_pending": "旅行者文件夹待定",
                "traveller_folder_thanks": "感谢您的入住",
                "tutorial_state": "教程状态",
                "type": "类型",
                "updated_at": "更新于",
                "user": "用户",
                "user_id": "用户",
                "user_report": "用户（报告者）",
                "users": "用户",
                "utm_campaign": "活动",
                "utm_medium": "中等的",
                "utm_settings": "UTM参数",
                "utm_source": "来源",
                "uuid": "uuid",
                "validated_activities": "经过验证的活动",
                "validated_at": "验证于",
                "value": "价值",
                "vat": "增值税",
                "vat_rate": "增值税率",
                "version": "版本",
                "video": "视频",
                "videos": "影片",
                "website": "网站",
                "zip": "压缩"
            },
            "before": " {attribute} 必须是 {date} 之前的日期。",
            "before_or_equal": " {attribute} 的日期必须早于或等于 {date}。",
            "between": {
                "array": " {attribute} 必须有 {min} 到 {max} 之间的项目。",
                "file": " {attribute} 必须介于 {min} 和 {max} 千字节之间。",
                "numeric": " {attribute} 必须介于 {min} 和 {max} 之间。",
                "string": " {attribute} 必须介于 {min} 和 {max} 个字符之间。"
            },
            "boolean": " {attribute} 字段必须为真或假。",
            "confirmed": " {attribute} 不匹配。",
            "currency_scale": " {attribute}的小数点后位数不得超过 {scale}。",
            "current_password": " {attribute} 与您的密码不匹配。",
            "custom": {
                "attribute-name": {
                    "rule-name": "自定义消息"
                },
                "h-captcha-response": {
                    "HCaptcha": "验证码错误！稍后重试或联系网站管理员。",
                    "required": "请确认您不是机器人。"
                }
            },
            "date": " {attribute} 不是有效日期。",
            "date_equals": " {attribute} 必须是等于 {date} 的日期。",
            "date_format": " {attribute} 与格式 {format} 不匹配。",
            "different": " {attribute} 和 {other} 必须不同。",
            "digits": " {attribute} 必须是 {digits} 位。",
            "digits_between": " {attribute} 必须介于 {min} 和 {max} 之间。",
            "dimensions": " {attribute} 的图像尺寸无效。",
            "distinct": " {attribute} 字段具有重复值。",
            "email": " {attribute} 必须是有效的电子邮件地址。",
            "ends_with": " {attribute} 必须以下列之一结尾： {values}。",
            "exists": "所选的 {attribute} 无效。",
            "file": " {attribute} 必须是一个文件。",
            "filled": " {attribute} 字段必须有一个值。",
            "gt": {
                "array": " {attribute} 必须有超过 {value} 个项目。",
                "file": " {attribute} 必须大于 {value} 千字节。",
                "numeric": " {attribute} 必须大于 {value}。",
                "string": " {attribute} 必须大于 {value} 个字符。"
            },
            "gte": {
                "array": " {attribute} 必须有 {value} 项或更多。",
                "file": " {attribute} 必须大于或等于 {value} 千字节。",
                "numeric": " {attribute} 必须大于或等于 {value}。",
                "string": " {attribute} 必须大于或等于 {value} 个字符。"
            },
            "image": " {attribute} 必须是图像。",
            "in": "所选的 {attribute} 无效。",
            "in_array": " {other} 中不存在 {attribute} 字段。",
            "integer": " {attribute} 必须是整数。",
            "ip": " {attribute} 必须是有效的 IP 地址。",
            "ipv4": " {attribute} 必须是有效的 IPv4 地址。",
            "ipv6": " {attribute} 必须是有效的 IPv6 地址。",
            "json": " {attribute} 必须是有效的 JSON 字符串。",
            "lt": {
                "array": " {attribute} 的项目必须少于 {value}。",
                "file": " {attribute} 必须小于 {value} 千字节。",
                "numeric": " {attribute} 必须小于 {value}。",
                "string": " {attribute} 必须少于 {value} 个字符。"
            },
            "lte": {
                "array": " {attribute} 中的项目不得超过 {value} 个。",
                "file": " {attribute} 必须小于或等于 {value} 千字节。",
                "numeric": " {attribute} 必须小于或等于 {value}。",
                "string": " {attribute} 必须小于或等于 {value} 个字符。"
            },
            "luhn": "{attribute} 是无效数字。",
            "max": {
                "array": " {attribute} 的项目不能超过 {max}。",
                "file": " {attribute} 不得大于 {max} 千字节。",
                "height": "{attribute} 的高度不得超过 {max} 像素。",
                "numeric": " {attribute} 不得大于 {max}。",
                "string": " {attribute} 不能大于 {max} 个字符。",
                "width": "{attribute} 的宽度不得超过 {max} 像素。"
            },
            "max_digits": "{attribute} 不得超过 {max} 位。",
            "mimes": " {attribute} 必须具有有效类型。",
            "mimetypes": " {attribute} 必须具有有效类型。",
            "min": {
                "array": " {attribute} 必须至少有 {min} 项。",
                "file": " {attribute} 必须至少为 {min} 千字节。",
                "numeric": " {attribute} 必须至少为 {min}。",
                "string": " {attribute} 必须至少为 {min} 个字符。"
            },
            "min_digits": "{attribute} 必须至少为 {min} 位。",
            "not_in": "所选的 {attribute} 无效。",
            "not_regex": " {attribute} 格式无效。",
            "notification_setting": " {attribute} 格式无效（预期格式为数组 [bool]）。",
            "numeric": " {attribute} 必须是一个数字。",
            "password": "密码不正确。",
            "phone": " {attribute} 必须是有效的电话号码。",
            "present": " {attribute} 字段必须存在。",
            "prohibited_if": "当 {other} 为 {value} 时， {attribute} 字段被禁止。",
            "prohibited_unless": "除非 {other} 在 {values} 中，否则 {attribute} 字段是禁止的。",
            "real_name_characters": ":{attribute} 只能包含字母、空格、点、\n        连字符或撇号。",
            "regex": " {attribute} 格式无效。",
            "required": " {attribute} 字段是必需的。",
            "required_if": "当 {other} 为 {value} 时， {attribute} 字段是必需的。",
            "required_unless": "除非 {other} 在 {values} 中，否则 {attribute} 字段是必需的。",
            "required_with": "当存在 {values} 时， {attribute} 字段是必需的。",
            "required_with_all": "当 {values} 存在时， {attribute} 字段是必需的。",
            "required_without": "当 {values} 不存在时， {attribute} 字段是必需的。",
            "required_without_all": "当 {values} 都不存在时， {attribute} 字段是必需的。",
            "same": " {attribute} 和 {other} 必须匹配。",
            "same_currency": "所有项目必须使用相同的货币。",
            "size": {
                "array": " {attribute} 必须包含 {size} 项。",
                "file": " {attribute} 必须是 {size} 千字节。",
                "numeric": " {attribute} 必须是 {size}。",
                "string": " {attribute} 必须是 {size} 个字符。"
            },
            "starts_with": " {attribute} 必须以下列之一开头： {values}。",
            "string": " {attribute} 必须是字符串。",
            "tag_same_poi_category": "{attribute}应该都属于同一个类别！",
            "timezone": " {attribute} 必须是有效区域。",
            "unique": " {attribute} 已经被拿走了。",
            "uploaded": " {attribute} 上传失败。",
            "url": " {attribute} 格式无效。",
            "uuid": " {attribute} 必须是有效的 UUID。"
        }
    }
}
