import { createI18n } from 'vue-i18n'

import Locales from './vue-i18n-locales.generated'

export default createI18n({
  legacy: false, // use Composition API only
  locale: navigator.language || import.meta.env.VITE_DEFAULT_LOCALE, // use browser locale, or default one
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE, // fallback locale
  messages: Locales, // set locale messages
  warnHtmlMessage: false, // avoid warning for HTML in translations files
})
